import FAQs from "components/common/FAQ";
import Footer from "components/common/Footer";
import CompaniesServed from "components/Home/CompaniesServed";
import Hero from "components/Services/Hero";
import React from "react";
import Steps from "./Steps";
import Categories from "./Categories";
import Offering from "./Offering";
import gotoBecomePartner from "helpers/gotoBecomePartner";

const Partners = () => {
  return (
    <>
      <Hero
        heroTitle={"Partner with Us and Ship for the World"}
        heroDescription={"We’re always looking to expand our network of reliable partners. Join us and enjoy access to more earning opportunities in different parts of the global supply chain industry."}
        buttonColor={"brown"}
        heroMedia={{
          src: require("assets/images/partner/hero.png"),
          type: "image",
        }}
        showClients={false}
        redBg={true}
        primaryButtonCta="Partner With MVX"
        ctaLocation={gotoBecomePartner}
        noSecondaryButton={true}
      />
      <Categories />
      <Steps />
      <Offering />
      <CompaniesServed noMargin={true} />
      <FAQs />
      <Footer />
    </>
  );
};

export default Partners;
