const { PartnerStep1, PartnerStep2, PartnerStep3 } = require("assets/arts");

var steps = [
  {
    title: "Step 1: Register your interest",
    description: `Fill the form below to apply to join our network of partners around the 
    world. It'll take just 2 minutes.`,
    image: <PartnerStep1 className="h-auto w-full" />,
  },
  {
    title: "Step 2: Due diligence",
    description: `We have to cross check and make sure that you're who you say you are. 
    So, someone from our team will reach out to you ASAP to verify your 
    identity and business.`,
    image: <PartnerStep2 className="h-auto w-full" />,
  },
  {
    title: "Step 3: Start receiving orders",
    description: `Once we verify you, you can start receiving orders from our customers 
    and earning from it.`,
    image: <PartnerStep3 className="h-auto w-full" />,
  },
];

export default steps;
