import Header from "components/common/Header";
import NewsletterForm from "components/common/NewsletterForm";
import React from "react";
import styled from "styled-components";

const Hero = () => {
  return (
    <div className="p-0">
      <Header />
      <HeroContainer className="rounded-t-lg sm:rounded-none pt-8 pb-4 px-4">
        <div className="w-[90%] sm:w-4/5 m-auto pb-32">
          <p className="font-catalina text-[2.1rem] sm:text-5xl pt-20 sm:pt-44 pb-10 leading-10">
            Stay in the know of trends in the logistics sector
          </p>
          <p className="pb-4 font-semibold">Subscribe to the latest news</p>
          <NewsletterForm header={true} />
        </div>
      </HeroContainer>
    </div>
  );
};

const HeroContainer = styled.div`
  background: rgba(17, 82, 251, 0.08);
`;

export default Hero;
