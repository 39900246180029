import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
    },
  },
};

const Trend = () => {
  const { shipmentTrends } = useSelector((state) => state.analytics);

  const data = useMemo(() => {
    if (Object.values(shipmentTrends).length) {
      const labels = Object.keys(shipmentTrends);
      return {
        labels,
        datasets: [
          {
            data: Object.values(shipmentTrends),
            backgroundColor: "#1C56F2",
            barThickness: 30,
            borderRadius: 10,
          },
        ],
      };
    }
  }, [shipmentTrends]);

  return (
    <div className="hidden sm:block w-full bg-white border border-gray py-8 px-8 rounded-xl mt-12">
      <div className="flex items-center justify-between">
        <p className="text-lg font-bold mb-6 whitespace-nowrap">
          Shipment Trend
        </p>
      </div>
      <div className="h-[400px] relative w-full">
        {data && <Bar options={options} data={data} />}
      </div>
    </div>
  );
};

export default Trend;
