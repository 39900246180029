export const getShipmentStatus = (statusPassed) => {
  const status = statusPassed.split(/[-, ]/).join(" ");
  if (
    status.toLowerCase() === "delivered" ||
    status.toLowerCase() === "invoice sent" ||
    status.toLowerCase() === "invoice outstanding"
  ) {
    return "completed";
  } else if (status.toLowerCase() === "cancelled") {
    return "cancelled";
  } else if (
    status.toLowerCase() === "live" ||
    status.toLowerCase() === "in transit" ||
    status.toLowerCase() === "picked"
  ) {
    return "active";
  } else if (
    status.toLowerCase() === "created" ||
    status.toLowerCase() === "quote sent" ||
    status.toLowerCase() === "quote approved" ||
    status.toLowerCase() === "quote accepted" ||
    status.toLowerCase() === "quote rejected" ||
    status.toLowerCase() === "awaiting user action" ||
    status.toLowerCase() === "on hold" ||
    status.toLowerCase() === "pending"
  ) {
    return "pending";
  } else {
    return "";
  }
};
