import { QuotesErrorIcon, StatusFlagIcon } from "assets/arts";
import Ellipsis from "components/common/Ellipsis";
import Loading from "components/common/Loading";
import moment from "moment";
import { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";

const Status = () => {
  const {
    selectedShipmentDetails: { status_messages },
    selectedShipmentDetailsLoading: loading,
  } = useSelector((state) => state.shipments);

  const groupedStatusByDay = useMemo(() => {
    if (status_messages?.length) {
      const statusesDate = status_messages?.map((sts) =>
        moment(sts.createdAt).format("L")
      );
      const datesGroups = [...new Set(statusesDate)];
      const statusByDates = {};
      datesGroups.forEach((statusDate) => {
        statusByDates[statusDate] = status_messages?.filter(
          (status) => moment(status?.createdAt).format("L") === statusDate
        );
        statusByDates[statusDate]?.reverse();
      });
      return statusByDates;
    } else {
      return [];
    }
  }, [status_messages]);

  return (
    <div
      className={`mt-[100px] md:mt-[120px] pl-0 overflow-hidden max-h-[700px] pb-24 ${
        loading && "relative pt-32"
      }`}
    >
      {loading ? (
        <Loading size={12} color={"mvx-blue"} />
      ) : (
        <>
          {Object.keys(groupedStatusByDay)?.map((date, idx) => (
            <StatusMessage
              key={`${date}00${idx}`}
              statusArr={groupedStatusByDay[date]}
            />
          ))}
          {(status_messages?.length === 0 || !status_messages) && (
            <div className="w-full flex flex-col items-center justify-center mt-2">
              <QuotesErrorIcon className="w-16 h-16 md:w-20 md:h-20" />
              <p className="text-sm mt-6 text-[#6B6B6B]">
                You do not have any status update {groupedStatusByDay}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Status;

const StatusMessage = ({ statusArr }) => {
  return (
    <div className="mt-7 flex">
      <div className="mb-5">
        <StatusFlagIcon className="h-[26px] w-[26px] self-start mr-[10px] -mt-1" />
        <div className="flex ml-2 mr-4 flex-col translate-x-1 mt-[10px] h-full">
          <div className="w-[2.3px] h-4/6 bg-mvx-blue"></div>
          <Ellipsis width={"2.3px"} className={"h-[15%] mt-[5px]"} />
          <Ellipsis width={"2.3px"} className={"h-[8%] mt-[5px]"} />
          <Ellipsis width={"2.3px"} className={"h-[6%] mt-[5px]"} />
          <Ellipsis width={"2.3px"} className={"h-[3%] mt-[5px]"} />
          <Ellipsis width={"2.3px"} className={"h-[3%] mt-[5px]"} />
          <Ellipsis width={"2.3px"} className={"h-[1%] mt-[5px]"} />
        </div>
      </div>
      <div className="min-w-[70%] max-w-[85%]">
        <p className="bg-[#e9e8e8] rounded-[10px] py-6 pl-4 pr-8 text-sm font-medium text-[#333333] leading-6">
          {statusArr[0].message}.
        </p>
        <span className="text-[#8F8F8F] inline-block font-semibold text-xs mt-3 ml-3">
          {moment(statusArr[0].createdAt).format("DD/MM/YYYY, hh:mm A")}
        </span>
        {statusArr?.map(
          (status, idx) =>
            idx !== 0 && (
              <Fragment key={idx}>
                <StatusMessage statusArr={[status]} />
              </Fragment>
            )
        )}
      </div>
    </div>
  );
};
