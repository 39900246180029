import { Document, Page, pdfjs } from "react-pdf";
import ModalContainer from "components/common/modals/ModalContainer";
import { useState } from "react";
import { CloseModalButton } from "components/Dashboard/Shipments/PopUpCalender";
import Loading from "components/common/Loading";

const OpenPdf = ({ link, closePdf }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  return (
    <ModalContainer closeModal={closePdf}>
      <CloseModalButton onClick={closePdf}>
        <span className="material-icons">close</span>
      </CloseModalButton>
      <nav className="flex justify-center items-center gap-4 mb-2.5">
        {numPages > 1 && (
          <button
            onClick={goToPrevPage}
            className="bg-gray-700/70 text-white text-xs w-10 h-7 flex items-center justify-center rounded-md "
          >
            <span className="material-icons">chevron_left</span>
          </button>
        )}
        <p className="text-white font-medium text-sm">
          Page {pageNumber} of {numPages}
        </p>
        {numPages > 1 && (
          <button
            onClick={goToNextPage}
            className="bg-gray-700/70 text-white text-xs w-10 h-7 flex items-center justify-center rounded-md "
          >
            <span className="material-icons">chevron_right</span>
          </button>
        )}
      </nav>
      <div className="relative bg-white hidden sm:block w-[700px] overflow-auto h-[570px] mx-auto docBody">
        <Document
          file={link}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<LoadingPdf />}
        >
          <Page pageNumber={pageNumber} width={700} />
        </Document>
      </div>
    </ModalContainer>
  );
};
export default OpenPdf;

const LoadingPdf = () => {
  return (
    <div className="top-0 left-0 w-[700px] h-[570px] flex items-center justify-center">
      <Loading size={14} color="mvx-blue" />
    </div>
  );
};
