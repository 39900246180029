import React, { useEffect } from "react";
import countries from "variables/countries";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "actions";

const CountryCodeSelect = ({ data, onChange, setData, name }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  useEffect(() => {
    if (!app.userLocation?.countryCode) {
      dispatch(appActions.getLocation());
    }
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (app.userLocation) {
      setData((data) => ({
        ...data,
        [name]: countries.find(
          (i) => i.abbreviation === app.userLocation?.countryCode
        )?.codes[0],
      }));
    }

    // eslint-disable-next-line
  }, [app.userLocation]);

  return (
    <select
      style={{
        backgroundImage:
          location.pathname === "/dashboard/profile-settings" && "none",
      }}
      className={`border-none bg-transparent outline-0 ${
        location.pathname === "/dashboard/profile-settings"
          ? "text-[12.25px] w-full font-bold "
          : "h-full pl-[10px] w-24 appearance-none text-sm"
      }  ${!data || data === "" ? "text-gray-400" : ""}`}
      name={name}
      onChange={onChange}
      value={data || ""}
    >
      <option value="">Code</option>
      {countries.map((t, i) => (
        <optgroup key={i} label={t.name}>
          {t.codes.map((o, j) => (
            <option value={`${o}`} key={j}>
              {o}
            </option>
          ))}
        </optgroup>
      ))}
    </select>
  );
};

export default CountryCodeSelect;
