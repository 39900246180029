import React from "react";
import styled from "styled-components";

const Numbers = () => {
  return (
    <Container
      url={require("assets/images/subtract3.png")}
      className="bg-mvx-blue2 pt-10 pb-20 relative"
    >
      <Subtract
        className="absolute w-full h-full top-0 left-0 z-0 bg-none sm:bg-[url('assets/images/subtract2.png')]"
      ></Subtract>
      <div className="w-4/5 m-auto relative">
        <p className="font-bold text-3xl mt-4 mb-4 text-white">
          Numbers don’t lie
        </p>
        <p className="text-sm mb-10 text-white">
          Here’s how we have done over the past 3 years. Same reasons why our
          <br />
          customers trust us with their logistics needs.
        </p>
        <div className="flex flex-wrap sm:flex-wrap justify-between text-white">
          <div className="mr-0 sm:mr-6 w-1/2 sm:w-auto mb-6 pr-4 sm:pr-0 sm:mb-0">
            <p className="font-bold text-2xl">600+</p>
            <p className="text-xs">happy customers</p>
          </div>
          <div className="mr-0 sm:mr-6 w-1/2 sm:w-auto mb-6 pr-4 sm:pr-0 sm:mb-0">
            <p className="font-bold text-2xl">$2.3million+</p>
            <p className="text-xs">generated for partners in revenue</p>
          </div>
          <div className="mr-0 sm:mr-6 w-1/2 sm:w-auto mb-6 pr-4 sm:pr-0 sm:mb-0">
            <p className="font-bold text-2xl">5000+</p>
            <p className="text-xs">shipments delivered</p>
          </div>
          <div className="mr-0 w-1/2 sm:w-auto mb-6 pr-4 sm:pr-0 sm:mb-0">
            <p className="font-bold text-2xl">$20M+</p>
            <p className="text-xs">value of shipments moved</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: auto 100%;
  animation-duration: 1.2s;
  animation-name: oscillate;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
`;

const Subtract = styled.div`
  background-repeat: no-repeat;
  background-size: auto 100%;
  animation-duration: 2s;
  animation-name: oscillate2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
`;

export default Numbers;
