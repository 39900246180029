var services = [
  {
    title: "Freight Forwarding",
    description:
      "With a blend of supportive experts, quality freight forwarders and easy to use technology MVX makes the shipping process easy and transparent.",
    image:
      "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/services/freight-forwarding.svg",
    link: "/freight-forwarding",
  },
  {
    title: "Haulage",
    description:
      "MVX offers you convenient haulage with the quickest response time at the most affordable cost. Our network of partners is spread across the continent, ready to move your goods.",
    image:
      "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/services/haulage.svg",
    link: "/haulage",
  },
  {
    title: "Freight Financing",
    description:
      "No upfront fees and minimal paperwork, means you get funding for your business fast. MVX gives you access to purchase order financing, freight financing, and import financing.",
    image:
      "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/services/freight-finance.svg",
    link: "/freight-finance",
  },
  {
    title: "Customs Clearance",
    description:
      "Access to a transparent clearance process and an expert team means your goods can spend the least possible time at the port. MVX makes cargo clearance a breeze for you.",
    image:
      "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/services/customs.svg",
    link: "/customs",
  },
];

export default services;
