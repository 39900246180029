import styled from "styled-components";

const ModalCalenderBody = styled.div`
  background-color: white;
  position: relative;
  top: -1rem;
  padding: 3rem;
  margin: auto;
  min-height: 250px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 640px) {
    width: 90%;
    max-width: 400px;
    padding: 2rem 1.5rem;
    top: -4rem;
  }
  .DateRangePickerInput_ajf2r2-o_O-DateRangePickerInput__withBorder_6y5g83 {
    background-color: rgb(255, 255, 255) !important;
    display: inline-block !important;
    border: none !important;
    max-height: 180px;
  }

  .DateRangePickerInput_arrow_mfgyvi {
    display: none !important;
  }

  .DateInput_input_1r60lyd {
    font-weight: 200 !important;
    font-size: 13px !important;
    line-height: 24px !important;
    color: rgb(72, 72, 72) !important;
    background-color: rgb(255, 255, 255) !important;
    width: 100% !important;
    padding: 11px 14px !important;
    border: 2px solid lightgray !important;
    border-radius: 7px !important;
    margin-bottom: 2rem !important;
  }

  .DateInput_input_1r60lyd-o_O-DateInput_input__focused_1c9eor4 {
    font-weight: 200 !important;
    font-size: 13px !important;
    line-height: 24px !important;
    color: rgb(72, 72, 72) !important;
    background-color: rgb(255, 255, 255) !important;
    width: 100% !important;
    padding: 11px 14px !important;
    border: 2px solid gray !important;
    border-radius: 7px !important;
    margin-bottom: 2rem !important;
  }
`;

export const CloseModalButton = styled.button`
  top: 40px;
  right: 40px;
  position: absolute;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: red;
  color: white;

  &:hover {
    background-color: white;
    color: red;
  }
`;

const PopUpCalender = ({ children, onRequestClose, showError }) => {
  return (
    // <ModalContainer closeModal={onRequestClose}>
    <>
      {" "}
      <CloseModalButton onClick={onRequestClose}>
        <span className="material-icons">close</span>
      </CloseModalButton>
      <ModalCalenderBody onClick={(e) => e.stopPropagation()}>
        {showError && (
          <p className="text-red-500 text-[14px] text-center font-semibold tracking-wide pb-3">
            Fields cannot be empty !
          </p>
        )}
        {children}
      </ModalCalenderBody>
    </>

    // </ModalContainer>
  );
};
export default PopUpCalender;
