import { authService } from "./authService";
import { currentENVData } from "helpers";

export const analyticsService = {
  getUserAnalytics,
  getShipmentTrends,
  getShipmentCount,
};

async function getUserAnalytics() {
  const requestOptions = {
    method: "GET",
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/users/analytics`,
    requestOptions
  );
  return res;
}

async function getShipmentTrends(startYear) {
  const requestOptions = {
    method: "GET",
  };

  const res = await authService.apiGate(
    `${currentENVData.transitURL}/analytics/user/shipment-value${
      startYear && `?start_year=${startYear}`
    }`,
    requestOptions
  );
  return res;
}

async function getShipmentCount(startAndEndDateObj) {
  const requestOptions = {
    method: "GET",
  };

  const res = await authService.apiGate(
    `${currentENVData.transitURL}/analytics/user/count${
      startAndEndDateObj
        ? `?start_date=${startAndEndDateObj.startDate}&end_date=${startAndEndDateObj.endDate}`
        : ""
    }`,
    requestOptions
  );
  return res;
}
