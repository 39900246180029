import {
  BigDutchmanLogo,
  DarexLogo,
  LagroupLogo,
  OneCapitalLogo,
  PragLogo,
  SkipperLogo,
} from "assets/arts";

let testimonials = [
  {
    message:
      "Before MVX, it took our goods three months to leave the shores of Nigeria. With MVX, this process has been smooth, fast and seamless.",
    client: "Ladgroup",
    website: "https://ladgroup.org/",
    logo: <LagroupLogo className="h-16 mb-12" />,
    location: {
      state: "Ikenne-Remo, Ogun State",
      country: "Nigeria",
    },
  },
  {
    message:
      "We were contemplating barging our goods to Cotonou and exporting from there due to the congestion at the Nigerian ports. Thanks to MVX and their reliable service we were able to export directly from Nigeria, quickly.",
    client: "One Capital",
    website: "https://onecapital.ae",
    logo: <OneCapitalLogo className="h-16 mb-12" />,
    location: {
      state: "Fujairah",
      country: "UAE",
    },
  },
  {
    message:
      "We are  highly impressed with MVX services. Their relationship managers are extremely reliable and always available to assist with our shipment. We would recommend MVX anytime!",
    client: "Big Dutchman",
    website: "https://www.bigdutchman.com/",
    logo: <BigDutchmanLogo className="h-16 mb-12" />,
    location: {
      state: "Ikeja, Lagos",
      country: "Nigeria",
    },
  },
  {
    message: `MVX provides an outstanding customer experience and we would recommend them anyday, anytime.`,
    client: "Darex Engineering",
    website: "https://darexengineering.com/",
    logo: <DarexLogo className="h-16 mb-12" />,
    location: {
      state: "Igando, Lagos",
      country: "Nigeria",
    },
  },
  {
    message:
      "Our 9 import containers were delivered safely and swiftly to Tinapa, Nigeria. Kudos to MVX for their amazing service.",
    client: "Skipper",
    website: "https://skipperets.com/",
    logo: <SkipperLogo className="h-16 mb-12" />,
    location: {
      state: "Odogunyan, Ikorodu, Lagos",
      country: "Nigeria",
    },
  },
  {
    message:
      "We are impressed with the level of professionalism and promptness  in handling our shipment.",
    client: "Pragmatic Technologies Limited",
    website: "https://prag.global/pragmatic-technologies/",
    logo: <PragLogo className="h-16 mb-12" />,
    location: {
      state: "Lagos",
      country: "Nigeria",
    },
  },
];

export default testimonials;
