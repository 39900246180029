import CustomToast from "components/common/CustomToast";
import { analyticsConstants } from "constants";
import toast from "react-hot-toast";
import { analyticsService } from "services";

export const analyticsActions = {
  getUserAnalytics,
  getShipmentTrends,
  getShipmentCount,
};

function getUserAnalytics() {
  return (dispatch) => {
    dispatch(request(analyticsConstants.USER_ANALYTICS_REQUEST));
    analyticsService.getUserAnalytics().then(
      (res) => {
        dispatch(success(analyticsConstants.USER_ANALYTICS_SUCCESS, res.data));
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(
            failure(analyticsConstants.USER_ANALYTICS_FAILURE, error.message)
          );
        }
      }
    );
  };
}

function getShipmentTrends(startYear = "") {
  return (dispatch) => {
    dispatch(request(analyticsConstants.SHIPMENT_TRENDS_REQUEST));
    analyticsService.getShipmentTrends(startYear).then(
      (res) => {
        dispatch(success(analyticsConstants.SHIPMENT_TRENDS_SUCCESS, res.data));
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(
            failure(analyticsConstants.SHIPMENT_TRENDS_FAILURE, error.message)
          );
        }
      }
    );
  };
}

function getShipmentCount(startAndEndDateObj = false) {
  return (dispatch) => {
    dispatch(request(analyticsConstants.SHIPMENT_COUNT_REQUEST));
    analyticsService.getShipmentCount(startAndEndDateObj).then(
      (res) => {
        dispatch(success(analyticsConstants.SHIPMENT_COUNT_SUCCESS, res));
      },
      (error) => {
        if (error.message && error.status !== 404) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(
            failure(analyticsConstants.SHIPMENT_COUNT_FAILURE, error.message)
          );
        }
      }
    );
  };
}

function request(type) {
  return { type: type };
}
function success(type, data) {
  return { type: type, payload: data };
}
function failure(type, error) {
  return { type: type, payload: error };
}
