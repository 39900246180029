import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SingleDatePicker } from "react-dates";
import CountryCodeSelect from "components/common/CountryCodeSelect";
import AutocompleteInput from "components/common/AutocompleteInput";
import ports from "variables/ports";
import { appActions } from "actions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/common/Loading";

const Businesses = ({ setModal }) => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const [quote, setQuote] = useState({});
  const [pickupDate, setPickupDate] = useState(null);
  const [focused, setFocused] = useState(false);

  const onChange = (e, parent, val, child) => {
    const { name, value } = e.target;

    if (parent) {
      setQuote((q) => ({
        ...q,
        [parent]: {
          ...q[parent],
          [child || name]: val || value,
        },
      }));
    } else {
      setQuote((quote) => ({
        ...quote,
        [name]: value,
      }));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(appActions.getQuote(quote));
  };

  useEffect(() => {
    if (app.quote) {
      setModal("view-instant-quote");
    }

    // eslint-disable-next-line
  }, [app.quote]);

  return (
    <div className="pl-4">
      <div className="pt-10 pb-10 sm:pb-20 rounded-b-lg">
        <div className="sm:ml-[10%]">
          <span className="text-sm bg-[#005ECD]/10 ml-[5%] sm:ml-0 px-2.5 py-1.5 rounded-lg text-[#1261BF] font-bold">
            For Businesses
          </span>
          <p className="font-catalina text-2xl ml-[5%] sm:ml-0 sm:text-3xl pr-[calc(5%+1rem)] sm:pr-0 mt-4">
            Check out how MVX can help you boost{" "}
            <br className="hidden sm:block" />
            your business. Request an instant quote
          </p>
          <div className="w-full flex flex-wrap sm:flex-nowrap items-center mt-4">
            <QuoteForm
              onSubmit={onSubmit}
              className="w-[calc(100%-(1rem))] sm:w-1/2 min-h-[400px] rounded-2xl mr-0 sm:mr-24 p-6"
            >
              <p className="font-bold mb-6">Quote Details</p>
              <div className="flex flex-wrap mb-6">
                <div className="w-full sm:w-1/2 shrink-0 pr-0 sm:pr-2">
                  <p className="text-xs mb-2 font-bold">Email</p>
                  <input
                    type="text"
                    className="border border-gray-200 mb-4 rounded-lg p-3 outline-0 w-full h-12 text-sm"
                    placeholder="Enter email address"
                    required={true}
                    name="email"
                    onChange={onChange}
                    value={quote.email || ""}
                  />
                </div>
                <div className="w-full sm:w-1/2 shrink-0 pr-0 sm:pl-2">
                  <p className="text-xs mb-2 font-bold">Mobile</p>
                  <div className="w-full shrink-0 h-12 border border-gray-200 rounded-md bg-white flex items-center">
                    <CountryCodeSelect
                      name="extension"
                      onChange={onChange}
                      data={quote.extension}
                      setData={setQuote}
                    />
                    <div className="h-3/5 w-px bg-gray-300"></div>
                    <input
                      type="text"
                      className="py-3 pr-3 pl-2 outline-0 text-sm w-full"
                      placeholder="Mobile"
                      required={true}
                      name="mobile"
                      value={quote.mobile || ""}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="w-full sm:w-1/2 shrink-0 pr-0 sm:pr-2">
                  <p className="text-xs mb-2 font-bold">Mode of Shipment</p>
                  <select
                    required
                    name="service_mode"
                    value={quote.service_mode || ""}
                    onChange={onChange}
                    className={`appearance-none border border-gray-200 mb-4 rounded-md p-3 outline-0 w-full h-12 text-sm ${
                      !quote.service_mode || quote.service_mode === ""
                        ? "text-gray-400"
                        : ""
                    }`}
                  >
                    <option value="" className="text-gray">
                      Select mode
                    </option>
                    <option value="ocean">Ocean</option>
                    <option value="air" disabled>
                      Air
                    </option>
                    <option value="road" disabled>
                      Road
                    </option>
                    <option value="barging" disabled>
                      Barging
                    </option>
                  </select>
                </div>
                <div className="w-full sm:w-1/2 shrink-0 pr-0 sm:pl-2">
                  <p className="text-xs mb-2 font-bold">
                    How do you want to ship?
                  </p>
                  <select
                    required
                    name="service_type"
                    value={quote.service_type || ""}
                    onChange={onChange}
                    className={`appearance-none border border-gray-200 mb-4 rounded-md p-3 outline-0 w-full h-12 text-sm ${
                      !quote.service_type || quote.service_type === ""
                        ? "text-gray-400"
                        : ""
                    }`}
                  >
                    <option value="" className="text-gray">
                      Select service type
                    </option>
                    <option value="import">Import</option>
                    <option value="export">Export</option>
                    <option value="in-country haulage">
                      In-Country Haulage
                    </option>
                    <option value="repair / return (export/re-import)">
                      Repair / Return (Export/Re-import)
                    </option>
                  </select>
                </div>
                <div className="w-full sm:w-1/2 shrink-0 pr-0 sm:pr-2 mb-4 z-1">
                  <p className="text-xs mb-2 font-bold">Shipment Origin</p>
                  <AutocompleteInput name="origin" setData={setQuote} />
                </div>
                <div className="w-full sm:w-1/2 shrink-0 pl-0 sm:pl-2 mb-4 z-1">
                  <p className="text-xs mb-2 font-bold">Shipment Destination</p>
                  <AutocompleteInput name="destination" setData={setQuote} />
                </div>
                <div className="w-full flex flex-wrap sm:flex-nowrap">
                  <div className="w-full sm:w-1/2 shrink-0 pr-0 sm:pr-2 z-1">
                    {quote?.origin?.details?.[0]?.address_components?.find(
                      (add) => add.types.includes("country")
                    ) ? (
                      <>
                        <p className="text-xs mb-2 font-bold">
                          Port of Origin (
                          {
                            quote?.origin?.details?.[0]?.address_components?.find(
                              (add) => add.types.includes("country")
                            )?.long_name
                          }
                          )
                        </p>
                        <select
                          required
                          name="originPort"
                          value={
                            quote.originPort && quote.originPortName
                              ? JSON.stringify({
                                  portName: quote.originPortName,
                                  portCode: quote.originPort,
                                })
                              : ""
                          }
                          onChange={(e) => {
                            let { value } = e.target;
                            value = JSON.parse(value);

                            setQuote((q) => ({
                              ...q,
                              originPort: value.portCode,
                              originPortName: value.portName,
                            }));
                          }}
                          className={`appearance-none border border-gray-200 rounded-md p-3 outline-0 w-full h-12 mb-4 text-sm ${
                            !quote.originPort || quote.originPort === ""
                              ? "text-gray-400"
                              : ""
                          }`}
                        >
                          <option value="">Select port</option>
                          {ports
                            .filter(
                              (port) =>
                                port.country ===
                                quote?.origin?.details?.[0]?.address_components?.find(
                                  (add) => add.types.includes("country")
                                )?.short_name
                            )
                            ?.map((p, index) => (
                              <option
                                key={index}
                                value={JSON.stringify({
                                  portName: p.portName,
                                  portCode: p.portCode,
                                })}
                              >
                                {p.portName}
                              </option>
                            ))}
                        </select>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="w-full sm:w-1/2 shrink-0 pl-0 sm:pl-2 z-1">
                    {quote?.destination?.details?.[0]?.address_components?.find(
                      (add) => add.types.includes("country")
                    ) ? (
                      <>
                        <p className="text-xs mb-2 font-bold">
                          Destination Port (
                          {
                            quote?.destination?.details?.[0]?.address_components?.find(
                              (add) => add.types.includes("country")
                            )?.long_name
                          }
                          )
                        </p>
                        <select
                          required
                          name="destinationPort"
                          value={
                            quote.destinationPort && quote.destinationPortName
                              ? JSON.stringify({
                                  portName: quote.destinationPortName,
                                  portCode: quote.destinationPort,
                                })
                              : ""
                          }
                          onChange={(e) => {
                            let { value } = e.target;
                            value = JSON.parse(value);

                            setQuote((q) => ({
                              ...q,
                              destinationPort: value.portCode,
                              destinationPortName: value.portName,
                            }));
                          }}
                          className={`appearance-none border border-gray-200 rounded-md p-3 outline-0 w-full h-12 mb-4 text-sm ${
                            !quote.destinationPort ||
                            quote.destinationPort === ""
                              ? "text-gray-400"
                              : ""
                          }`}
                        >
                          <option value="">Select port</option>
                          {ports
                            .filter(
                              (port) =>
                                port.country ===
                                quote?.destination?.details?.[0]?.address_components?.find(
                                  (add) => add.types.includes("country")
                                )?.short_name
                            )
                            ?.map((p, index) => (
                              <option
                                key={index}
                                value={JSON.stringify({
                                  portName: p.portName,
                                  portCode: p.portCode,
                                })}
                              >
                                {p.portName}
                              </option>
                            ))}
                        </select>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="w-full sm:w-1/2 shrink-0 pl-0 sm:pr-2 date-picker mb-4">
                  <p className="text-xs mb-2 font-bold">Pickup Date</p>
                  <SingleDatePicker
                    date={pickupDate} // momentPropTypes.momentObj or null
                    onDateChange={(date) => setPickupDate(date)} // PropTypes.func.isRequired
                    focused={focused} // PropTypes.bool
                    onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
                    id="mvx_unique_id"
                    placeholder="Select Date"
                    numberOfMonths={1}
                    hideKeyboardShortcutsPanel={true}
                    showClearDate={true}
                  />
                </div>
                <div className="w-full sm:w-1/2 shrink-0 pr-0 sm:pl-2">
                  <p className="text-xs mb-2 font-bold">
                    What are you Shipping?
                  </p>
                  <select
                    required
                    name="package_type"
                    value={quote.package_type || ""}
                    onChange={onChange}
                    className={`appearance-none border border-gray-200 mb-4 rounded-md p-3 outline-0 w-full h-12 text-sm ${
                      !quote.package_type || quote.package_type === ""
                        ? "text-gray-400"
                        : ""
                    }`}
                  >
                    <option value="">Select type of goods</option>
                    <option value="pallets">Non-Containers</option>
                    <option value="containers">Containers</option>
                  </select>
                </div>
                {quote.package_type === "containers" ? (
                  <>
                    <div className="w-full sm:w-1/2 shrink-0 pr-0 sm:pr-2">
                      <p className="text-xs mb-2 font-bold">
                        Type of container
                      </p>
                      <div className="flex">
                        <div
                          name="size"
                          onClick={(e) =>
                            onChange(e, "container_size", "20", "size")
                          }
                          className={`${
                            quote.container_size?.size === "20"
                              ? "border-[#1261BF] text-[#1261BF] font-bold"
                              : "text-gray-400"
                          } w-1/2 h-12 bg-white duration-300 border border-gray hover:border-[#1261BF] hover:text-[#1261BF] rounded cursor-pointer flex items-center justify-center whitespace-nowrap text-sm mr-2`}
                        >
                          20ft
                        </div>
                        <div
                          onClick={(e) =>
                            onChange(e, "container_size", "40", "size")
                          }
                          className={`${
                            quote.container_size?.size === "40"
                              ? "border-[#1261BF] text-[#1261BF] font-bold"
                              : "text-gray-400"
                          } w-1/2 h-12 bg-white duration-300 border border-gray hover:border-[#1261BF] hover:text-[#1261BF] rounded cursor-pointer flex items-center justify-center whitespace-nowrap text-sm`}
                        >
                          40ft
                        </div>
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2 shrink-0 pl-0 sm:pl-2">
                      <p className="text-xs mb-2 font-bold">
                        Amount of containers
                      </p>
                      <input
                        type="number"
                        className="border border-gray-200 mb-4 rounded-lg p-3 outline-0 w-full h-12 text-sm"
                        placeholder="Enter number of containers"
                        required={true}
                        name="qty"
                        onChange={(e) => onChange(e, "container_size")}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
                {quote.package_type === "pallets" ? (
                  <>
                    <div className="w-full sm:w-1/2 shrink-0 pr-0 sm:pr-2">
                      <p className="text-xs mb-2 font-bold">Package weight</p>
                      <input
                        type="number"
                        className="border border-gray-200 mb-4 rounded-lg p-3 outline-0 w-full h-12 text-sm"
                        placeholder="Enter weight in kgs"
                        required={true}
                        name="weight"
                        onChange={onChange}
                        value={quote.weight || ""}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="bg-[#1261BF] shadow-lg outline-0 rounded-lg w-full sm:w-1/2 h-12 text-white m-auto mb-6 relative"
                >
                  <span className={`${app.requestingQuote && "invisible"}`}>
                    Generate Quote
                  </span>
                  {app.requestingQuote && <Loading />}
                </button>
              </div>
            </QuoteForm>
            <div className="w-1/2 shrink-0 hidden sm:block">
              <img
                src={
                  "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/map.svg"
                }
                className="w-full h-auto"
                alt="MVX Business"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuoteForm = styled.form`
  background-color: rgba(239, 239, 239, 0.8);
`;

export default Businesses;
