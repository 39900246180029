import Footer from "components/common/Footer";
import BecomeAPartner from "components/common/modals/BecomeAPartner";
import ModalContainer from "components/common/modals/ModalContainer";
import ViewInstantQuote from "components/common/modals/ViewInstantQuote";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Businesses from "./Businesses";
import CompaniesServed from "./CompaniesServed";
import Hero from "./Hero";
import News from "./News";
import Partner from "./Partner";
import Products from "./Products";
import Services from "./Services";
import WhyMVX from "./WhyMVX";

const Home = () => {
  const app = useSelector((state) => state.app);
  const [modal, setModal] = useState(false);
  const serviceRef = useRef(null);

  return (
    <>
      {modal !== false ? (
        <ModalContainer closeModal={() => setModal(false)}>
          {modal === "view-instant-quote" ? (
            <ViewInstantQuote
              loading={app.fetchingQuote}
              closeModal={() => setModal(false)}
            />
          ) : (
            ""
          )}
          {modal === "become-a-partner" ? (
            <BecomeAPartner
              closeModal={() => setModal(false)}
            />
          ) : (
            ""
          )}
        </ModalContainer>
      ) : (
        ""
      )}
      <Hero serviceRef={serviceRef} />
      <Services serviceRef={serviceRef} />
      <WhyMVX />
      <Products />
      <CompaniesServed />
      <Businesses setModal={setModal} />
      <Partner setModal={setModal} />
      <News />
      <Footer />
    </>
  );
};

export default Home;
