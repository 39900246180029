import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { authService } from "services";

export const PublicRoutes = ({ children }) => {
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth.user);

  const moveCargoClicked = localStorage.getItem("moveCargo");
  const getFinanceClicked = localStorage.getItem("getFinance");

  console.log("Public: ", accessToken, authService.loggedIn());

  if (authService.loggedIn() && accessToken) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (moveCargoClicked) {
      return <Navigate to={"/dashboard/create-shipment"} replace />;
    } else if (getFinanceClicked) {
      return <Navigate to={"/dashboard/shipments-finance"} replace />;
    } else {
      if (params.redirect) {
        window.location.href = params.redirect;
      } else {
        console.log("Here");
        navigate("/dashboard/home");
      }
    }
  }
  return children;
};
