const ports = [
  {
    lat: "24.1301619",
    lng: "55.8023118",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEAAN",
    portName: "Al Ain",
    portContinent: "Middle East",
  },
  {
    lat: "24.49587",
    lng: "54.3832457",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEABK",
    portName: "Abu Al Bukhoosh (AEABK), United Arab Emirates, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "25.4052165",
    lng: "55.5136433",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEAJM",
    portName: "Ajman",
    portContinent: "Middle East",
  },
  {
    lat: "24.3547081",
    lng: "54.5020243",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEAMF",
    portName: "Musaffah (AEAMF), United Arab Emirates, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "24.789428",
    lng: "52.5612106",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEARZ",
    portName: "Arzanah (AEARZ), United Arab Emirates, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "24.453884",
    lng: "54.3773438",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEAUH",
    portName: "Abu Dhabi",
    portContinent: "Middle East",
  },
  {
    lat: "25.1546848",
    lng: "52.87682119999999",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEDAS",
    portName: "Das Island (AEDAS), United Arab Emirates, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "25.284755",
    lng: "55.2756505",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEDXB",
    portName: "Port Rashid",
    portContinent: "Middle East",
  },
  {
    lat: "25.1288099",
    lng: "56.3264849",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEFJR",
    portName: "Fujairah",
    portContinent: "Middle East",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "AEHAM",
    portName: "Port of Hamriyah",
    portContinent: "Middle East",
  },
  {
    lat: "25.0236111",
    lng: "55.0402778",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEJEA",
    portName: "Port of Jebel Ali",
    portContinent: "Middle East",
  },
  {
    lat: "25.2016428",
    lng: "55.2452567",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEJYH",
    portName: "Jumeirah (AEJYH), United Arab Emirates, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "24.453884",
    lng: "54.3773438",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEKHL",
    portName:
      "KHALIFA PORT, ABU DHABI  (AEKHL), Abu Dhabi, United Arab Emirate",
    portContinent: "Middle East",
  },
  {
    lat: "25.057898",
    lng: "56.3546378",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEKLB",
    portName: "Kalba (AEKLB), United Arab Emirates, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "25.34167",
    lng: "56.34862339999999",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEKLF",
    portName: "Khor Fakkan (AEKLF), United Arab Emirates, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "24.5779154",
    lng: "53.3056266",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEMBS",
    portName: "Mubarraz (AEMBS), United Arab Emirates, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "AEMKH",
    portName: "MINA KHALID",
    portContinent: "Middle East",
  },
  {
    lat: "25.7916667",
    lng: "55.9533333",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEMSA",
    portName: "Mina Saqr (AEMSA), United Arab Emirates, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "24.3547081",
    lng: "54.5020243",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEMUS",
    portName: "Mussafaf City",
    portContinent: "Middle East",
  },
  {
    lat: "23.424076",
    lng: "53.847818",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEMZD",
    portName: "Free Port (AEMZD), United Arab Emirates, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "25.284755",
    lng: "55.2756505",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEPRA",
    portName: "PORT RASHID  (AEPRA), Dubai, United Arab Emirates",
    portContinent: "Middle East",
  },
  {
    lat: "25.5507975",
    lng: "55.5524476",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEQIW",
    portName: "Umm al Qaiwain (AEQIW), Umm al Qaywayn, United Arab Emirates",
    portContinent: "Middle East",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "AERKP",
    portName: "Rak Khor Port",
    portContinent: "Middle East",
  },
  {
    lat: "25.8006926",
    lng: "55.9761994",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AERKT",
    portName: "Ras Al Khaimah (AERKT), United Arab Emirates, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "25.9386196",
    lng: "56.0636253",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AERMC",
    portName: "Rak Maritime City",
    portContinent: "Middle East",
  },
  {
    lat: "24.0947046",
    lng: "52.7345509",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AERUW",
    portName: "Ruwais (AERUW), United Arab Emirates, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "25.3461555",
    lng: "55.42109319999999",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AESHJ",
    portName: "Sharjah (Khalid)",
    portContinent: "Middle East",
  },
  {
    lat: "25.5507975",
    lng: "55.5524476",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEUAQ",
    portName: "Umm Al Quwain",
    portContinent: "Middle East",
  },
  {
    lat: "24.8790692",
    lng: "53.0727658",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "AEZUR",
    portName: "Zirku (AEZUR), United Arab Emirates, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "AFASH",
    portName: "CLIQQ-10",
    portContinent: "Asia",
  },
  {
    lat: "34.5553494",
    lng: "69.207486",
    country: "AF",
    country_long: "Afghanistan",
    portCode: "AFKBL",
    portName: "KABUL",
    portContinent: "Asia",
  },
  {
    lat: "17.0746557",
    lng: "-61.8175207",
    country: "AG",
    country_long: "Antigua and Barbuda",
    portCode: "AGANU",
    portName: "Antigua (AGANU), Antigua and Barbuda, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.6266242",
    lng: "-61.77130279999999",
    country: "AG",
    country_long: "Antigua and Barbuda",
    portCode: "AGBBQ",
    portName: "Barbuda (AGBBQ), Antigua and Barbuda, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.0289837",
    lng: "-61.786081",
    country: "AG",
    country_long: "Antigua and Barbuda",
    portCode: "AGFAM",
    portName: "Falmouth (AGFAM), Antigua and Barbuda, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.1274104",
    lng: "-61.84677199999999",
    country: "AG",
    country_long: "Antigua and Barbuda",
    portCode: "AGSJO",
    portName: "St Johns (AGSJO), Antigua and Barbuda, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.220554",
    lng: "-63.06861499999999",
    country: "AI",
    country_long: "Anguilla",
    portCode: "AIAXA",
    portName: "ANGUILLA",
    portContinent: "North America",
  },
  {
    lat: "18.220554",
    lng: "-63.06861499999999",
    country: "AI",
    country_long: "Anguilla",
    portCode: "AIRBY",
    portName: "Road Bay  (AIRBY), Anguilla, LatAm",
    portContinent: "North America",
  },
  {
    lat: "40.5409817",
    lng: "-74.21650819999999",
    country: "US",
    country_long: "United States",
    portCode: "AIVAL",
    portName: "SANDY GROUND",
    portContinent: "North America",
  },
  {
    lat: "41.313922",
    lng: "19.455799",
    country: "AL",
    country_long: "Albania",
    portCode: "ALDRZ",
    portName: "Port of Durres",
    portContinent: "Europe",
  },
  {
    lat: "40.1034715",
    lng: "19.7502384",
    country: "AL",
    country_long: "Albania",
    portCode: "ALHMR",
    portName: "Himare (ALHMR), Albania, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.8714208",
    lng: "20.0031266",
    country: "AL",
    country_long: "Albania",
    portCode: "ALSAR",
    portName: "Port of Sarande",
    portContinent: "Europe",
  },
  {
    lat: "41.8151558",
    lng: "19.5896557",
    country: "AL",
    country_long: "Albania",
    portCode: "ALSHG",
    portName: "Port of Shengjin",
    portContinent: "Europe",
  },
  {
    lat: "41.3275459",
    lng: "19.8186982",
    country: "AL",
    country_long: "Albania",
    portCode: "ALTIA",
    portName: "Tirana",
    portContinent: "Europe",
  },
  {
    lat: "40.4521886",
    lng: "19.4832659",
    country: "AL",
    country_long: "Albania",
    portCode: "ALVOA",
    portName: "Port of Vlore",
    portContinent: "Europe",
  },
  {
    lat: "40.1872023",
    lng: "44.515209",
    country: "AM",
    country_long: "Armenia",
    portCode: "AMEVN",
    portName: "Yerevan",
    portContinent: "Asia",
  },
  {
    lat: "12.1224221",
    lng: "-68.8824233",
    country: "CW",
    country_long: "Curaçao",
    portCode: "ANCUR",
    portName: "Port of Willemstad",
    portContinent: "Central America",
  },
  {
    lat: "18.0295839",
    lng: "-63.04713709999999",
    country: "SX",
    country_long: "Sint Maarten",
    portCode: "ANPHI",
    portName: "PHILIPSBURG",
    portContinent: "Central America",
  },
  {
    lat: "18.04248",
    lng: "-63.05483",
    country: "SX",
    country_long: "Sint Maarten",
    portCode: "ANSXM",
    portName: "SINT MAARTEN APT",
    portContinent: "Central America",
  },
  {
    lat: "-8.4162307",
    lng: "13.7289167",
    country: "AO",
    country_long: "Angola",
    portCode: "AOBDD",
    portName: "Dande (AOBDD), Angola, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-12.5905158",
    lng: "13.416501",
    country: "AO",
    country_long: "Angola",
    portCode: "AOBUG",
    portName: "Benguela (AOBUG), Angola, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-5.570573899999999",
    lng: "12.197583",
    country: "AO",
    country_long: "Angola",
    portCode: "AOCAB",
    portName: "Cabinda",
    portContinent: "Africa",
  },
  {
    lat: "-8.8146556",
    lng: "13.2301756",
    country: "AO",
    country_long: "Angola",
    portCode: "AOLAD",
    portName: "Luanda",
    portContinent: "Africa",
  },
  {
    lat: "-12.3757287",
    lng: "13.5610451",
    country: "AO",
    country_long: "Angola",
    portCode: "AOLOB",
    portName: "Lobito",
    portContinent: "Africa",
  },
  {
    lat: "-5.3880057",
    lng: "12.2061624",
    country: "AO",
    country_long: "Angola",
    portCode: "AOMAL",
    portName: "Malongo (AOMAL), Angola, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-15.1978317",
    lng: "12.1575544",
    country: "AO",
    country_long: "Angola",
    portCode: "AOMSZ",
    portName: "Namibe",
    portContinent: "Africa",
  },
  {
    lat: "-5.8734941",
    lng: "13.4323929",
    country: "AO",
    country_long: "Angola",
    portCode: "AONQI",
    portName: "Noqui (AONQI), Angola, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-15.1187071",
    lng: "12.1479796",
    country: "AO",
    country_long: "Angola",
    portCode: "AOPSA",
    portName: "Saco (AOPSA), Angola, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-6.2605556",
    lng: "12.3705556",
    country: "AO",
    country_long: "Angola",
    portCode: "AOSOQ",
    portName: "SOYO-QUINFUQUENA TERMINAL (AOSOQ), Angola, Africa",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "AOSZA",
    portName: "Port of Soyo",
    portContinent: "Africa",
  },
  {
    lat: "60.4518126",
    lng: "22.2666303",
    country: "FI",
    country_long: "Finland",
    portCode: "AQABA",
    portName: "Aboa",
    portContinent: "Antarctica",
  },
  {
    lat: "22.5159499",
    lng: "113.3926",
    country: "CN",
    country_long: "China",
    portCode: "AQZGN",
    portName: "ZHONGSHAN",
    portContinent: "Antarctica",
  },
  {
    lat: "-29.7137101",
    lng: "-57.0855601",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARAOL",
    portName: "Paso De Los Libres (ARAOL), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-33.9670128",
    lng: "-59.207291",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARATU",
    portName: "Port of Atucha",
    portContinent: "South America",
  },
  {
    lat: "-33.0574351",
    lng: "-60.61859889999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARAVR",
    portName: "Alvear (ARAVR), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-34.3321464",
    lng: "-58.7831534",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARBDE",
    portName: "Escobar (ARBDE), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-38.7183177",
    lng: "-62.2663478",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARBHI",
    portName: "Bahia Blanca (ARBHI), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-27.4822998",
    lng: "-58.93341059999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARBQS",
    portName: "Port of Barranqueras",
    portContinent: "South America",
  },
  {
    lat: "-34.6177194",
    lng: "-58.3620561",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARBUE",
    portName: "Port of Buenos Aires",
    portContinent: "South America",
  },
  {
    lat: "36.475798",
    lng: "-94.2475489",
    country: "US",
    country_long: "United States",
    portCode: "ARBVC",
    portName: "Bella Vista (ARBVC), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-45.7484597",
    lng: "-67.3776343",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARCLC",
    portName: "Caleta Cordova (ARCLC), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ARCMP",
    portName: "Port of Campana",
    portContinent: "South America",
  },
  {
    lat: "-27.4692131",
    lng: "-58.8306349",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARCNQ",
    portName: "Port of Corrientes",
    portContinent: "South America",
  },
  {
    lat: "-31.3913921",
    lng: "-58.01743399999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARCOC",
    portName: "Concordia (ARCOC), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "37.8881751",
    lng: "-4.7793835",
    country: "ES",
    country_long: "Spain",
    portCode: "ARCOR",
    portName: "CORDOBA",
    portContinent: "South America",
  },
  {
    lat: "-32.4845349",
    lng: "-58.2321416",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARCOU",
    portName: "Port of Concepcion del Uruguay",
    portContinent: "South America",
  },
  {
    lat: "-45.8656149",
    lng: "-67.4822429",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARCRD",
    portName: "Comodoro Rivadavia (ARCRD), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-46.4425647",
    lng: "-67.5171533",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARCVI",
    portName: "Caleta Olivia (ARCVI), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ARDME",
    portName: "Port of Diamante",
    portContinent: "South America",
  },
  {
    lat: "-34.8150044",
    lng: "-58.53482839999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "AREZE",
    portName: "MINISTRO PISTARINI APT/BUENOS AIRES",
    portContinent: "South America",
  },
  {
    lat: "-38.416097",
    lng: "-63.61667199999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARFEN",
    portName: "Federation (ARFEN), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "23.69781",
    lng: "120.960515",
    country: "TW",
    country_long: "Taiwan",
    portCode: "ARFMA",
    portName: "Port of Formosa",
    portContinent: "South America",
  },
  {
    lat: "-33.7434675",
    lng: "-59.1557553",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARIBY",
    portName: "Port of Ibicuy",
    portContinent: "South America",
  },
  {
    lat: "-25.8482523",
    lng: "-54.3467152",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARIGR",
    portName: "Iguazu (ARIGR), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ARLPG",
    portName: "Port of La Plata",
    portContinent: "South America",
  },
  {
    lat: "-30.7589703",
    lng: "-59.63927839999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARLPM",
    portName: "La Paz (ARLPM), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-27.050733",
    lng: "-58.6776801",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARLPS",
    portName: "Las Palmas (ARLPS), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-38.0054771",
    lng: "-57.5426106",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARMDQ",
    portName: "Mar Del Plata (ARMDQ), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-32.8894587",
    lng: "-68.8458386",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARMDZ",
    portName: "Mendoza",
    portContinent: "South America",
  },
  {
    lat: "-38.5544968",
    lng: "-58.73960879999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARNFH",
    portName: "Necochea (ARNFH), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-40.7333763",
    lng: "-64.9535634",
    country: "AR",
    country_long: "Argentina",
    portCode: "AROES",
    portName: "San Antonio Oeste (AROES), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-38.416097",
    lng: "-63.61667199999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "AROYA",
    portName: "Goya (AROYA), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-38.8904419",
    lng: "-62.09804149999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARPBG",
    portName: "Puerto Belgrano (ARPBG), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-38.7183177",
    lng: "-62.2663478",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARPGV",
    portName: "Puerto Galvan (ARPGV), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-42.76362169999999",
    lng: "-65.03483109999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARPMY",
    portName: "Puerto Madryn",
    portContinent: "South America",
  },
  {
    lat: "-31.7413197",
    lng: "-60.51154709999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARPRA",
    portName: "Puerto Parana",
    portContinent: "South America",
  },
  {
    lat: "-38.8992043",
    lng: "-62.0796814",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARPRS",
    portName: "Puerto Rosales (ARPRS), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ARPSS",
    portName: "Port of Posadas",
    portContinent: "South America",
  },
  {
    lat: "-47.75156459999999",
    lng: "-65.9013774",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARPUD",
    portName: "Puerto Deseado (ARPUD), Puerto Deseado, Argentina",
    portContinent: "South America",
  },
  {
    lat: "-33.4850695",
    lng: "-60.0045531",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARRAM",
    portName: "Port of Ramallo",
    portContinent: "South America",
  },
  {
    lat: "-27.4518622",
    lng: "-58.98555469999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARRES",
    portName: "Resistancia (ARRES), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-51.6230485",
    lng: "-69.2168291",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARRGL",
    portName: "Rio Gallegos (ARRGL), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-32.9587022",
    lng: "-60.69304159999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARROS",
    portName: "Puerto Rosario Ente Administrador (ENAPRO)",
    portContinent: "South America",
  },
  {
    lat: "-43.2998581",
    lng: "-65.09948729999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARRWO",
    portName: "Rawson (ARRWO), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-50.0247276",
    lng: "-68.52438149999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARRZA",
    portName: "Puerto Santa Cruz (ARRZA), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-34.4430618",
    lng: "-58.55796429999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARSFE",
    portName: "San Fernando (ARSFE), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "11.1645214",
    lng: "123.8056151",
    country: "PH",
    country_long: "Philippines",
    portCode: "ARSFN",
    portName: "Port of Santa Fe",
    portContinent: "South America",
  },
  {
    lat: "-27.8663111",
    lng: "-55.1374743",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARSJV",
    portName: "San Javier (ARSJV), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ARSLO",
    portName: "Port of San Lorenzo",
    portContinent: "South America",
  },
  {
    lat: "-33.3334669",
    lng: "-60.2110494",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARSNS",
    portName: "San Nicolas (ARSNS), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-33.6757835",
    lng: "-59.66286640000001",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARSPD",
    portName: "San Pedro (ARSPD), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-38.416097",
    lng: "-63.61667199999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARTGR",
    portName: "Tigre (ARTGR), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-49.3068942",
    lng: "-67.72982480000002",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARULA",
    portName: "San Julian (ARULA), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "-54.8019121",
    lng: "-68.3029511",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARUSH",
    portName: "Ushuaia",
    portContinent: "South America",
  },
  {
    lat: "-33.227158",
    lng: "-60.3298704",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARVCN",
    portName: "Port of Villa Constitucion",
    portContinent: "South America",
  },
  {
    lat: "-40.8119087",
    lng: "-62.9962044",
    country: "AR",
    country_long: "Argentina",
    portCode: "ARVDM",
    portName: "Viedma (ARVDM), Argentina, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ARZAE",
    portName: "Port of Zarate",
    portContinent: "South America",
  },
  {
    lat: "-13.8506958",
    lng: "-171.7513551",
    country: "WS",
    country_long: "Samoa",
    portCode: "ASAPI",
    portName: "APIA",
    portContinent: "Oceania",
  },
  {
    lat: "-14.2756319",
    lng: "-170.7020359",
    country: "AS",
    country_long: "American Samoa",
    portCode: "ASPPG",
    portName: "Pago Pago",
    portContinent: "Oceania",
  },
  {
    lat: "48.131873",
    lng: "16.9038849",
    country: "AT",
    country_long: "Austria",
    portCode: "ATBDG",
    portName: "Bad Deutsch (ATBDG), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.50075",
    lng: "9.74231",
    country: "AT",
    country_long: "Austria",
    portCode: "ATBRZ",
    portName: "BREGENZ",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ATENA",
    portName: "Port of Enns",
    portContinent: "Europe",
  },
  {
    lat: "48.34115",
    lng: "15.92255",
    country: "AT",
    country_long: "Austria",
    portCode: "ATEPD",
    portName: "Erpersdorf (ATEPD), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.12068",
    lng: "16.60329",
    country: "AT",
    country_long: "Austria",
    portCode: "ATFIS",
    portName: "Fischamend Dorf (ATFIS), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.24128",
    lng: "9.601899999999999",
    country: "AT",
    country_long: "Austria",
    portCode: "ATFKT",
    portName: "FELDKIRCH",
    portContinent: "Europe",
  },
  {
    lat: "48.1862624",
    lng: "16.4415388",
    country: "AT",
    country_long: "Austria",
    portCode: "ATFNA",
    portName: "Freudenau (ATFNA), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.516231",
    lng: "14.550072",
    country: "AT",
    country_long: "Austria",
    portCode: "ATGNZ",
    portName: "Granz (ATGNZ), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.070714",
    lng: "15.439504",
    country: "AT",
    country_long: "Austria",
    portCode: "ATGRZ",
    portName: "Graz (ATGRZ), Graz, Austria",
    portContinent: "Europe",
  },
  {
    lat: "47.2692124",
    lng: "11.4041024",
    country: "AT",
    country_long: "Austria",
    portCode: "ATINN",
    portName: "Innsbruck",
    portContinent: "Europe",
  },
  {
    lat: "48.34472",
    lng: "16.33149",
    country: "AT",
    country_long: "Austria",
    portCode: "ATKBG",
    portName: "Korneuburg (ATKBG), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.6364598",
    lng: "14.3122246",
    country: "AT",
    country_long: "Austria",
    portCode: "ATKLU",
    portName: "Klagenfurt",
    portContinent: "Europe",
  },
  {
    lat: "48.2202322",
    lng: "15.2188862",
    country: "AT",
    country_long: "Austria",
    portCode: "ATKPO",
    portName: "Kleinpochlarn (ATKPO), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.40999",
    lng: "15.60384",
    country: "AT",
    country_long: "Austria",
    portCode: "ATKRE",
    portName: "Port of Krems",
    portContinent: "Europe",
  },
  {
    lat: "48.33074999999999",
    lng: "16.10801",
    country: "AT",
    country_long: "Austria",
    portCode: "ATLGE",
    portName: "Langenlebarn (ATLGE), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.30694",
    lng: "14.28583",
    country: "AT",
    country_long: "Austria",
    portCode: "ATLNZ",
    portName: "Port of Linz",
    portContinent: "Europe",
  },
  {
    lat: "47.42084260000001",
    lng: "9.6594464",
    country: "AT",
    country_long: "Austria",
    portCode: "ATLTN",
    portName: "LUSTENAU",
    portContinent: "Europe",
  },
  {
    lat: "48.3041049",
    lng: "16.3608632",
    country: "AT",
    country_long: "Austria",
    portCode: "ATLZD",
    portName: "Langenzersdorf (ATLZD), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.216986",
    lng: "15.1525413",
    country: "AT",
    country_long: "Austria",
    portCode: "ATMAB",
    portName: "Marbach An Der Donau (ATMAB), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.855591",
    lng: "13.3434218",
    country: "AT",
    country_long: "Austria",
    portCode: "ATMON",
    portName: "MONDSEE",
    portContinent: "Europe",
  },
  {
    lat: "48.1165278",
    lng: "16.8608194",
    country: "AT",
    country_long: "Austria",
    portCode: "ATPEC",
    portName: "Petronell Carnuntum (ATPEC), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.05009",
    lng: "14.41827",
    country: "AT",
    country_long: "Austria",
    portCode: "ATSER",
    portName: "STEYR",
    portContinent: "Europe",
  },
  {
    lat: "47.7942439",
    lng: "13.0033427",
    country: "AT",
    country_long: "Austria",
    portCode: "ATSZG",
    portName: "SALZBURG (ATSZG), Salzburg, Austria",
    portContinent: "Europe",
  },
  {
    lat: "48.39913",
    lng: "15.68925",
    country: "AT",
    country_long: "Austria",
    portCode: "ATTHS",
    portName: "Theiss (ATTHS), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.3314949",
    lng: "16.060737",
    country: "AT",
    country_long: "Austria",
    portCode: "ATTLN",
    portName: "Tulln (ATTLN), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.2081743",
    lng: "16.3738189",
    country: "AT",
    country_long: "Austria",
    portCode: "ATVDD",
    portName: "VIENNA",
    portContinent: "Europe",
  },
  {
    lat: "48.2081743",
    lng: "16.3738189",
    country: "AT",
    country_long: "Austria",
    portCode: "ATVIE",
    portName: "Wien (ATVIE), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.60856",
    lng: "13.85062",
    country: "AT",
    country_long: "Austria",
    portCode: "ATVLH",
    portName: "Villach",
    portContinent: "Europe",
  },
  {
    lat: "47.3441504",
    lng: "11.6885307",
    country: "AT",
    country_long: "Austria",
    portCode: "ATVMP",
    portName: "VOMP",
    portContinent: "Europe",
  },
  {
    lat: "47.4674195",
    lng: "9.7500038",
    country: "AT",
    country_long: "Austria",
    portCode: "ATWFT",
    portName: "Wolfurt",
    portContinent: "Europe",
  },
  {
    lat: "47.7984673",
    lng: "13.043719",
    country: "AT",
    country_long: "Austria",
    portCode: "ATWLS",
    portName: "WELZ -A - SALZBURG",
    portContinent: "Europe",
  },
  {
    lat: "48.1764209",
    lng: "15.0820859",
    country: "AT",
    country_long: "Austria",
    portCode: "ATYBB",
    portName: "Ybbs An Der Donau (ATYBB), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.32902",
    lng: "16.1749",
    country: "AT",
    country_long: "Austria",
    portCode: "ATZER",
    portName: "Zeiselmauer (ATZER), Austria, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.5428483",
    lng: "16.7606452",
    country: "AT",
    country_long: "Austria",
    portCode: "ATZIS",
    portName: "ZISTERSDORF",
    portContinent: "Europe",
  },
  {
    lat: "-19.8992614",
    lng: "148.0812683",
    country: "AU",
    country_long: "Australia",
    portCode: "AUABP",
    portName: "Abbot Point (AUABP), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-34.8442994",
    lng: "138.505369",
    country: "AU",
    country_long: "Australia",
    portCode: "AUADL",
    portName: "Port of Adelaide",
    portContinent: "Oceania",
  },
  {
    lat: "-35.0268148",
    lng: "117.8837114",
    country: "AU",
    country_long: "Australia",
    portCode: "AUALH",
    portName: "Albany (AUALH), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-33.2767614",
    lng: "115.7249058",
    country: "AU",
    country_long: "Australia",
    portCode: "AUALI",
    portName: "AUSTRALIND",
    portContinent: "Oceania",
  },
  {
    lat: "-35.3093163",
    lng: "148.0656088",
    country: "AU",
    country_long: "Australia",
    portCode: "AUALO",
    portName: "Adelong",
    portContinent: "Oceania",
  },
  {
    lat: "-33.9080273",
    lng: "151.1902576",
    country: "AU",
    country_long: "Australia",
    portCode: "AUALX",
    portName: "Alexandria, New South Wales (AUALX), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-32.0096447",
    lng: "115.8340964",
    country: "AU",
    country_long: "Australia",
    portCode: "AUAPP",
    portName: "Applecross (AUAPP), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-34.425653",
    lng: "137.9139563",
    country: "AU",
    country_long: "Australia",
    portCode: "AUARD",
    portName: "Ardrossan (AUARD), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-32.0249836",
    lng: "115.7996403",
    country: "AU",
    country_long: "Australia",
    portCode: "AUATT",
    portName: "Attadale (AUATT), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBAR",
    portName: "Barry Beach (AUBAR), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBBG",
    portName: "Bingbong (AUBBG), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-24.8661024",
    lng: "152.3488923",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBDB",
    portName: "Bundaberg (AUBDB), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "AUBEL",
    portName: "Port of Bell Bay",
    portContinent: "Oceania",
  },
  {
    lat: "-33.5475372",
    lng: "151.2190601",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBKL",
    portName: "Brooklyn (AUBKL), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-27.3837161",
    lng: "153.1679033",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBNE",
    portName: "Port of Brisbane",
    portContinent: "Oceania",
  },
  {
    lat: "-28.8627985",
    lng: "153.5658162",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBNK",
    portName: "Ballina (AUBNK), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-32.0299295",
    lng: "115.7846258",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBNT",
    portName: "Bicton (AUBNT), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-42.70012810000001",
    lng: "147.2511587",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBSA",
    portName: "Brighton (AUBSA), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-33.9469267",
    lng: "151.1973167",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBTB",
    portName: "Botany (AUBTB), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-33.6516465",
    lng: "115.3473335",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBUS",
    portName: "Busselton (AUBUS), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-20.7804342",
    lng: "115.4022706",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBWB",
    portName: "Barrow Island (AUBWB), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-27.0767851",
    lng: "153.3684001",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBWI",
    portName: "Bulwer (AUBWI), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-41.0559539",
    lng: "145.9098205",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBWT",
    portName: "Port of Burnie",
    portContinent: "Oceania",
  },
  {
    lat: "-33.6624466",
    lng: "151.2993823",
    country: "AU",
    country_long: "Australia",
    portCode: "AUBYV",
    portName: "Bayview (AUBYV), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-34.8268285",
    lng: "138.6006077",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCAV",
    portName: "Cavan,Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-38.1166667",
    lng: "144.4333333",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCBA",
    portName: "Corio Bay (AUCBA), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-40.3982432",
    lng: "148.2014489",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCBI",
    portName: "Cape Barren (AUCBI), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-35.2801903",
    lng: "149.1310038",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCBR",
    portName: "Canberra",
    portContinent: "Oceania",
  },
  {
    lat: "-24.239679",
    lng: "113.393501",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCCU",
    portName: "Cape Cuvier (AUCCU), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-30.2962013",
    lng: "153.1138924",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCFS",
    portName: "Coffs Harbour (AUCFS), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-31.9807386",
    lng: "115.7821812",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCLM",
    portName: "Claremont (AUCLM), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-16.9203476",
    lng: "145.7709529",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCNS",
    portName: "Cairns (AUCNS), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCPN",
    portName: "Cape Preston (AUCPN), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-34.0516553",
    lng: "151.1545063",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCRU",
    portName: "Cronulla (AUCRU), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-31.9752145",
    lng: "115.8213483",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCRW",
    portName: "Crawley (AUCRW), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-15.4758164",
    lng: "145.2470981",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCTN",
    portName: "Cooktown (AUCTN), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-26.8043999",
    lng: "153.1254643",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCUD",
    portName: "Caloundra (AUCUD), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-27.5251556",
    lng: "153.2792833",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCVN",
    portName: "Cleveland (AUCVN), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-24.8838363",
    lng: "113.6570584",
    country: "AU",
    country_long: "Australia",
    portCode: "AUCVQ",
    portName: "Carnarvon (AUCVQ), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-20.6624015",
    lng: "116.7104871",
    country: "AU",
    country_long: "Australia",
    portCode: "AUDAM",
    portName: "Dampier (AUDAM), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-27.1900809",
    lng: "153.0172028",
    country: "AU",
    country_long: "Australia",
    portCode: "AUDEB",
    portName: "Deception Bay (AUDEB), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-33.8784421",
    lng: "151.2421453",
    country: "AU",
    country_long: "Australia",
    portCode: "AUDLB",
    portName: "Double Bay (AUDLB), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.9299413",
    lng: "113.538149",
    country: "AU",
    country_long: "Australia",
    portCode: "AUDNM",
    portName: "Denham (AUDNM), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-41.1832246",
    lng: "146.3617161",
    country: "AU",
    country_long: "Australia",
    portCode: "AUDPO",
    portName: "Port of Devonport",
    portContinent: "Oceania",
  },
  {
    lat: "-17.309288",
    lng: "123.6401839",
    country: "AU",
    country_long: "Australia",
    portCode: "AUDRB",
    portName: "Derby (AUDRB), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-12.4637333",
    lng: "130.8444446",
    country: "AU",
    country_long: "Australia",
    portCode: "AUDRW",
    portName: "Darwin (AUDRW), Darwin, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-33.8613953",
    lng: "121.8912724",
    country: "AU",
    country_long: "Australia",
    portCode: "AUEPR",
    portName: "Esperance (AUEPR), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-33.8143301",
    lng: "151.0562386",
    country: "AU",
    country_long: "Australia",
    portCode: "AUERM",
    portName: "Ermington (AUERM), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "AUFIT",
    portName: "Flinders (AUFIT), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-39.9836386",
    lng: "148.052689",
    country: "AU",
    country_long: "Australia",
    portCode: "AUFLS",
    portName: "Flinders Island (AUFLS), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-32.1795722",
    lng: "152.5117641",
    country: "AU",
    country_long: "Australia",
    portCode: "AUFOT",
    portName: "Forster (AUFOT), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-32.05376831776213",
    lng: "115.7340351026107",
    country: "AU",
    country_long: "Australia",
    portCode: "AUFRE",
    portName: "Fremantle Port",
    portContinent: "Oceania",
  },
  {
    lat: "19.295416",
    lng: "-81.3831715",
    country: "KY",
    country_long: "Cayman Islands",
    portCode: "AUGEE",
    portName: "Port of George Town",
    portContinent: "Oceania",
  },
  {
    lat: "-28.7767396",
    lng: "114.6033546",
    country: "AU",
    country_long: "Australia",
    portCode: "AUGET",
    portName: "Port of Geraldton",
    portContinent: "Oceania",
  },
  {
    lat: "-38.1044343",
    lng: "144.3607409",
    country: "AU",
    country_long: "Australia",
    portCode: "AUGEX",
    portName: "Port of Geelong",
    portContinent: "Oceania",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "AUGLT",
    portName: "Port of Gladstone",
    portContinent: "Oceania",
  },
  {
    lat: "-33.426667",
    lng: "151.341667",
    country: "AU",
    country_long: "Australia",
    portCode: "AUGOS",
    portName: "Gosford (AUGOS), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "AUGOV",
    portName: "Gove (AUGOV), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "AUGRA",
    portName: "Port of Grassy",
    portContinent: "Oceania",
  },
  {
    lat: "-13.8528352",
    lng: "136.4146805",
    country: "AU",
    country_long: "Australia",
    portCode: "AUGTE",
    portName: "Milner Bay (AUGTE), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-38.3734019",
    lng: "145.2218367",
    country: "AU",
    country_long: "Australia",
    portCode: "AUHAS",
    portName: "Port of Hastings",
    portContinent: "Oceania",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "AUHBA",
    portName: "Port of Hobart",
    portContinent: "Oceania",
  },
  {
    lat: "-33.8337",
    lng: "151.14366",
    country: "AU",
    country_long: "Australia",
    portCode: "AUHHW",
    portName: "Hunters Hill (AUHHW), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "AUHID",
    portName: "Horn (AUHID), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-31.8083597",
    lng: "115.7438613",
    country: "AU",
    country_long: "Australia",
    portCode: "AUHLR",
    portName: "Hillarys (AUHLR), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-41.3217945",
    lng: "148.2498127",
    country: "AU",
    country_long: "Australia",
    portCode: "AUHLS",
    portName: "St Helens (AUHLS), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "AUHLT",
    portName: "HAMILTON",
    portContinent: "Oceania",
  },
  {
    lat: "-27.8705041",
    lng: "153.3513884",
    country: "AU",
    country_long: "Australia",
    portCode: "AUHPL",
    portName: "Hope Island (AUHPL), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-21.2927517",
    lng: "149.2558927",
    country: "AU",
    country_long: "Australia",
    portCode: "AUHPT",
    portName: "Hay Point (AUHPT), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-20.3518817",
    lng: "148.9572514",
    country: "AU",
    country_long: "Australia",
    portCode: "AUHTI",
    portName: "Hamilton Island (AUHTI), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.2881539",
    lng: "152.7676633",
    country: "AU",
    country_long: "Australia",
    portCode: "AUHVB",
    portName: "Hervey Bay (AUHVB), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-17.5227345",
    lng: "146.0278075",
    country: "AU",
    country_long: "Australia",
    portCode: "AUIFL",
    portName: "Innisfail (AUIFL), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-34.0044124",
    lng: "150.8630229",
    country: "AU",
    country_long: "Australia",
    portCode: "AUIGN",
    portName: "INGLE BURN",
    portContinent: "Oceania",
  },
  {
    lat: "-20.2867279",
    lng: "148.7900746",
    country: "AU",
    country_long: "Australia",
    portCode: "AUJHQ",
    portName: "Shute Harbour (AUJHQ), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-30.3067032",
    lng: "115.0373581",
    country: "AU",
    country_long: "Australia",
    portCode: "AUJUR",
    portName: "Jurien Bay (AUJUR), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-27.7115397",
    lng: "114.1713913",
    country: "AU",
    country_long: "Australia",
    portCode: "AUKAX",
    portName: "Kalbari (AUKAX), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-39.87534770000001",
    lng: "143.9370758",
    country: "AU",
    country_long: "Australia",
    portCode: "AUKNS",
    portName: "King Island (AUKNS), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-16.131541",
    lng: "123.7573596",
    country: "AU",
    country_long: "Australia",
    portCode: "AUKOI",
    portName: "Koolan (AUKOI), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-17.4855733",
    lng: "140.8461782",
    country: "AU",
    country_long: "Australia",
    portCode: "AUKRB",
    portName: "Karumba (AUKRB), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-34.0108511",
    lng: "151.2103056",
    country: "AU",
    country_long: "Australia",
    portCode: "AUKUR",
    portName: "Kurnell (AUKUR), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-32.2320792",
    lng: "115.7787256",
    country: "AU",
    country_long: "Australia",
    portCode: "AUKWI",
    portName: "Kwinana (AUKWI), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-40.2124642",
    lng: "148.2413424",
    country: "AU",
    country_long: "Australia",
    portCode: "AULAB",
    portName: "Port of Lady Barron",
    portContinent: "Oceania",
  },
  {
    lat: "-42.8465992",
    lng: "147.3526877",
    country: "AU",
    country_long: "Australia",
    portCode: "AULIN",
    portName: "Lindisfarne (AULIN), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-33.8146357",
    lng: "151.1699091",
    country: "AU",
    country_long: "Australia",
    portCode: "AULNC",
    portName: "LANE COVE",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "AULSD",
    portName: "Lonsdale (AULSD), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-41.439068",
    lng: "147.1357671",
    country: "AU",
    country_long: "Australia",
    portCode: "AULST",
    portName: "Launceston",
    portContinent: "Oceania",
  },
  {
    lat: "-18.5281508",
    lng: "146.3314538",
    country: "AU",
    country_long: "Australia",
    portCode: "AULUC",
    portName: "Lucinda (AULUC), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-14.6680433",
    lng: "145.463777",
    country: "AU",
    country_long: "Australia",
    portCode: "AULZR",
    portName: "Lizard Island (AULZR), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.5384947",
    lng: "152.7034191",
    country: "AU",
    country_long: "Australia",
    portCode: "AUMBH",
    portName: "Port of Maryborough",
    portContinent: "Oceania",
  },
  {
    lat: "-26.6001074",
    lng: "153.0885636",
    country: "AU",
    country_long: "Australia",
    portCode: "AUMCY",
    portName: "Sunshine Coast (AUMCY), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-31.6897613",
    lng: "115.7058228",
    country: "AU",
    country_long: "Australia",
    portCode: "AUMDE",
    portName: "Mindarie (AUMDE), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-32.5361038",
    lng: "115.7424077",
    country: "AU",
    country_long: "Australia",
    portCode: "AUMDU",
    portName: "Mandurah (AUMDU), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "AUMEL",
    portName: "Port of Melbourne",
    portContinent: "Oceania",
  },
  {
    lat: "-21.1433971",
    lng: "149.1868281",
    country: "AU",
    country_long: "Australia",
    portCode: "AUMKY",
    portName: "Mackay (AUMKY), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-26.6818215",
    lng: "153.11903",
    country: "AU",
    country_long: "Australia",
    portCode: "AUMLL",
    portName: "Mooloolaba (AUMLL), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-33.6792734",
    lng: "151.3027199",
    country: "AU",
    country_long: "Australia",
    portCode: "AUNMV",
    portName: "Mona Vale (AUNMV), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-26.4135439",
    lng: "153.0505132",
    country: "AU",
    country_long: "Australia",
    portCode: "AUNSV",
    portName: "Noosaville (AUNSV), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-32.9259274",
    lng: "151.7772168",
    country: "AU",
    country_long: "Australia",
    portCode: "AUNTL",
    portName: "Port of Newcastle",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "AUNWP",
    portName: "Newport (AUNWP), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-21.6671744",
    lng: "115.105188",
    country: "AU",
    country_long: "Australia",
    portCode: "AUONS",
    portName: "Onslow (AUONS), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "AUPAE",
    portName: "Port Adelaide",
    portContinent: "Oceania",
  },
  {
    lat: "-43.1396117",
    lng: "147.8506871",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPAU",
    portName: "Port Arthur (AUPAU), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-27.3837161",
    lng: "153.1679033",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPBN",
    portName: "Port of Brisbane",
    portContinent: "Oceania",
  },
  {
    lat: "-33.9759457",
    lng: "151.2154611",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPBT",
    portName: "PORT BOTANY",
    portContinent: "Oceania",
  },
  {
    lat: "-29.2647322",
    lng: "114.9290409",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPDI",
    portName: "Port Denison (AUPDI), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-35.7182457",
    lng: "137.9387322",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPEA",
    portName: "Penneshaw (AUPEA), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-31.9523123",
    lng: "115.861309",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPER",
    portName: "PERTH",
    portContinent: "Oceania",
  },
  {
    lat: "-21.9323088",
    lng: "114.1278654",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPEX",
    portName: "Exmouth (AUPEX), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-35.0222265",
    lng: "137.7618344",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPGI",
    portName: "Port Giles (AUPGI), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-20.3123929",
    lng: "118.6093685",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPHE",
    portName: "Port of Hedland",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPHP",
    portName: "Point Henry (AUPHP), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-34.4827536",
    lng: "150.9033131",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPKL",
    portName: "Port of Port Kembla",
    portContinent: "Oceania",
  },
  {
    lat: "-32.3574993",
    lng: "115.7619591",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPKN",
    portName: "Port Kennedy (AUPKN), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-40.8541918",
    lng: "145.3808967",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPLA",
    portName: "Port Latta",
    portContinent: "Oceania",
  },
  {
    lat: "-34.7226381",
    lng: "135.8597449",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPLO",
    portName: "Port Lincoln (AUPLO), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-33.1773056",
    lng: "138.0086102",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPPI",
    portName: "Port Pirie (AUPPI), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-31.4580174",
    lng: "152.8974657",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPQQ",
    portName: "Port Macquarie (AUPQQ), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-16.4839732",
    lng: "145.4622522",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPTI",
    portName: "Port Douglas (AUPTI), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-30.0002315",
    lng: "136.2091547",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPTJ",
    portName: "Portland (AUPTJ), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-20.5909644",
    lng: "117.1779426",
    country: "AU",
    country_long: "Australia",
    portCode: "AUPWL",
    portName: "Port Walcott (AUPWL), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-35.711905",
    lng: "150.173172",
    country: "AU",
    country_long: "Australia",
    portCode: "AUQBW",
    portName: "Batemans (AUQBW), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-37.0136574",
    lng: "149.9095179",
    country: "AU",
    country_long: "Australia",
    portCode: "AUQDN",
    portName: "Eden (AUQDN), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-36.2343962",
    lng: "150.0678986",
    country: "AU",
    country_long: "Australia",
    portCode: "AUQRX",
    portName: "Narooma (AUQRX), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-35.5265715",
    lng: "138.1897984",
    country: "AU",
    country_long: "Australia",
    portCode: "AURAB",
    portName: "Rapid Bay (AURAB), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "AURDN",
    portName: "Risdon (AURDN), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-23.1681731",
    lng: "150.7806658",
    country: "AU",
    country_long: "Australia",
    portCode: "AURNB",
    portName: "Rosslyn (AURNB), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-32.004113",
    lng: "115.5151009",
    country: "AU",
    country_long: "Australia",
    portCode: "AURTS",
    portName: "Rottnest Island (AURTS), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-27.9718794",
    lng: "153.4063059",
    country: "AU",
    country_long: "Australia",
    portCode: "AUSHQ",
    portName: "Southport Qld (AUSHQ), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-33.8339902",
    lng: "151.0482995",
    country: "AU",
    country_long: "Australia",
    portCode: "AUSIL",
    portName: "Silverwater (AUSIL), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-40.8417721",
    lng: "145.1258454",
    country: "AU",
    country_long: "Australia",
    portCode: "AUSIO",
    portName: "Port of Smithton",
    portContinent: "Oceania",
  },
  {
    lat: "-51.6977129",
    lng: "-57.85166269999999",
    country: "FK",
    country_long: "Falkland Islands (Islas Malvinas)",
    portCode: "AUSTA",
    portName: "Port of Stanley",
    portContinent: "Oceania",
  },
  {
    lat: "46.142188",
    lng: "-60.19903",
    country: "CA",
    country_long: "Canada",
    portCode: "AUSYD",
    portName: "Port of Sydney",
    portContinent: "Oceania",
  },
  {
    lat: "-32.1448338",
    lng: "133.6575544",
    country: "AU",
    country_long: "Australia",
    portCode: "AUTHE",
    portName: "Thevenard (AUTHE), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-28.1786656",
    lng: "153.5370011",
    country: "AU",
    country_long: "Australia",
    portCode: "AUTHS",
    portName: "Tweed Heads (AUTHS), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-19.25194983295243",
    lng: "146.8341861188015",
    country: "AU",
    country_long: "Australia",
    portCode: "AUTSV",
    portName: "Port of Townsville",
    portContinent: "Oceania",
  },
  {
    lat: "-42.870222",
    lng: "147.8579063",
    country: "AU",
    country_long: "Australia",
    portCode: "AUTTS",
    portName: "Blackman Bay (AUTTS), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-35.3572426",
    lng: "150.4613249",
    country: "AU",
    country_long: "Australia",
    portCode: "AUULA",
    portName: "Ulladulla (AUULA), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-41.1571006",
    lng: "146.1774247",
    country: "AU",
    country_long: "Australia",
    portCode: "AUULV",
    portName: "Ulverstone (AUULV), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-26.1333333",
    lng: "113.3833333",
    country: "AU",
    country_long: "Australia",
    portCode: "AUUSL",
    portName: "Useless Loop (AUUSL), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "AUVAR",
    portName: "Varanus (AUVAR), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "AUWAL",
    portName: "Wallaroo (AUWAL), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-12.6492936",
    lng: "141.8469901",
    country: "AU",
    country_long: "Australia",
    portCode: "AUWEI",
    portName: "Weipa (AUWEI), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-38.3563059",
    lng: "145.2480193",
    country: "AU",
    country_long: "Australia",
    portCode: "AUWEP",
    portName: "Westernport (AUWEP), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-27.486534",
    lng: "153.035968",
    country: "AU",
    country_long: "Australia",
    portCode: "AUWGB",
    portName: "Woolloongabba (AUWGB), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-33.7968627",
    lng: "151.2850167",
    country: "AU",
    country_long: "Australia",
    portCode: "AUWGZ",
    portName: "Manly (AUWGZ), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-31.9934404",
    lng: "115.9443544",
    country: "AU",
    country_long: "Australia",
    portCode: "AUWHL",
    portName: "Welshpool (AUWHL), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-20.2734789",
    lng: "148.716904",
    country: "AU",
    country_long: "Australia",
    portCode: "AUWSY",
    portName: "Airlie (AUWSY), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-33.82901990000001",
    lng: "151.0751998",
    country: "AU",
    country_long: "Australia",
    portCode: "AUWWP",
    portName: "Wentworth Point (AUWWP), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-33.034582",
    lng: "137.5756408",
    country: "AU",
    country_long: "Australia",
    portCode: "AUWYA",
    portName: "Whyalla (AUWYA), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-15.5847021",
    lng: "128.2201137",
    country: "AU",
    country_long: "Australia",
    portCode: "AUWYN",
    portName: "Wyndham (AUWYN), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-29.4355601",
    lng: "153.3602079",
    country: "AU",
    country_long: "Australia",
    portCode: "AUYBA",
    portName: "Port of Yamba",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "AUZBO",
    portName: "Bowen (AUZBO), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "12.52111",
    lng: "-69.968338",
    country: "AW",
    country_long: "Aruba",
    portCode: "AWAUA",
    portName: "Aruba",
    portContinent: "North America",
  },
  {
    lat: "12.4830557",
    lng: "-69.9902488",
    country: "AW",
    country_long: "Aruba",
    portCode: "AWBAR",
    portName: "Port of Barcadera",
    portContinent: "North America",
  },
  {
    lat: "12.5092044",
    lng: "-70.0086306",
    country: "AW",
    country_long: "Aruba",
    portCode: "AWORJ",
    portName: "Port of Oranjestad",
    portContinent: "North America",
  },
  {
    lat: "-33.3547911",
    lng: "-60.1703274",
    country: "AR",
    country_long: "Argentina",
    portCode: "AWSNL",
    portName: "Port of San Nicolas",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "AZBAK",
    portName: "Port of Baku",
    portContinent: "Europe",
  },
  {
    lat: "40.2987179",
    lng: "49.70630610000001",
    country: "AZ",
    country_long: "Azerbaijan",
    portCode: "AZQDG",
    portName: "Qaradag (AZQDG), Azerbaijan, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.1973486",
    lng: "49.4765574",
    country: "AZ",
    country_long: "Azerbaijan",
    portCode: "AZSAN",
    portName: "Sangachal (AZSAN), Azerbaijan, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.5854765",
    lng: "49.6317411",
    country: "AZ",
    country_long: "Azerbaijan",
    portCode: "AZSUQ",
    portName: "Sumqayit (AZSUQ), Azerbaijan, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.7721811",
    lng: "17.191",
    country: "BA",
    country_long: "Bosnia and Herzegovina",
    portCode: "BABNX",
    portName: "Banja Luka",
    portContinent: "Europe",
  },
  {
    lat: "43.8562586",
    lng: "18.4130763",
    country: "BA",
    country_long: "Bosnia and Herzegovina",
    portCode: "BABZJ",
    portName: "Sarajevo",
    portContinent: "Europe",
  },
  {
    lat: "43.8562586",
    lng: "18.4130763",
    country: "BA",
    country_long: "Bosnia and Herzegovina",
    portCode: "BASJJ",
    portName: "Sarajevo",
    portContinent: "Europe",
  },
  {
    lat: "44.2034392",
    lng: "17.9077432",
    country: "BA",
    country_long: "Bosnia and Herzegovina",
    portCode: "BAZCA",
    portName: "ZENICA",
    portContinent: "Europe",
  },
  {
    lat: "13.1",
    lng: "-59.6333333",
    country: "N/A",
    country_long: "N/A",
    portCode: "BBBGI",
    portName: "Port of Bridgetown",
    portContinent: "North America",
  },
  {
    lat: "13.0705904",
    lng: "-59.54695439999999",
    country: "BB",
    country_long: "Barbados",
    portCode: "BBOST",
    portName: "Oistins (BBOST), Barbados, LatAm",
    portContinent: "North America",
  },
  {
    lat: "13.253123",
    lng: "-59.6377134",
    country: "BB",
    country_long: "Barbados",
    portCode: "BBSPT",
    portName: "Speightstown (BBSPT), Barbados, LatAm",
    portContinent: "North America",
  },
  {
    lat: "24.848078",
    lng: "89.3729633",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "BDBGU",
    portName: "BOGURA",
    portContinent: "Asia",
  },
  {
    lat: "22.3090995",
    lng: "91.8017675",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "BDCGP",
    portName: "Port of Chittagong",
    portContinent: "Asia",
  },
  {
    lat: "22.356851",
    lng: "91.7831819",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "BDCGX",
    portName: "Chattogram",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "BDCHL",
    portName: "Port of Chalna",
    portContinent: "Asia",
  },
  {
    lat: "21.4272283",
    lng: "92.0058074",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "BDCXB",
    portName: "COXS BAZAR",
    portContinent: "Asia",
  },
  {
    lat: "23.810332",
    lng: "90.4125181",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "BDDAC",
    portName: "Dhaka (BDDAC), Dhaka, Bangladesh",
    portContinent: "Asia",
  },
  {
    lat: "23.1777682",
    lng: "89.1801225",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "BDJSR",
    portName: "JESSORE",
    portContinent: "Asia",
  },
  {
    lat: "27.3931593",
    lng: "80.8156324",
    country: "IN",
    country_long: "India",
    portCode: "BDKAM",
    portName: "Kamlapur",
    portContinent: "Asia",
  },
  {
    lat: "22.845641",
    lng: "89.5403279",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "BDKHL",
    portName: "Port of Khulna",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "BDMGL",
    portName: "Port of Mongla",
    portContinent: "Asia",
  },
  {
    lat: "23.6327767",
    lng: "90.5246511",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "BDNAR",
    portName: "Narayanganj (BDNAR), Bangladesh, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.6525262",
    lng: "90.4573131",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "BDPGN",
    portName: "PANGAON (BDPGN), Dhaka, Bangladesh",
    portContinent: "Asia",
  },
  {
    lat: "24.3745146",
    lng: "88.60416599999999",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "BDRJH",
    portName: "RAJSHAHI",
    portContinent: "Asia",
  },
  {
    lat: "25.7829542",
    lng: "88.8982666",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "BDSPD",
    portName: "SAIDPUR",
    portContinent: "Asia",
  },
  {
    lat: "24.8949294",
    lng: "91.8687063",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "BDZYL",
    portName: "SYLHET",
    portContinent: "Asia",
  },
  {
    lat: "49.5628647",
    lng: "5.834713799999999",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEAAT",
    portName: "Athus",
    portContinent: "Europe",
  },
  {
    lat: "51.2213404",
    lng: "4.4051485",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEABS",
    portName: "ANTWERP..",
    portContinent: "Europe",
  },
  {
    lat: "51.24075990000001",
    lng: "4.4077668",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEANR",
    portName: "Port of Antwerp",
    portContinent: "Europe",
  },
  {
    lat: "50.5672729",
    lng: "3.4513915",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEATO",
    portName: "Antoing (BEATO), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.1729552",
    lng: "5.57886",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEBOC",
    portName: "Bocholt (BEBOC), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "BEBOM",
    portName: "BOOM",
    portContinent: "Europe",
  },
  {
    lat: "51.135044",
    lng: "3.327833",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEBRM",
    portName: "Beernem (BEBRM), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.34287",
    lng: "4.31409",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEBRT",
    portName: "Berendrecht (BEBRT), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.8649982",
    lng: "4.3509731",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEBRU",
    portName: "Port of Brussels",
    portContinent: "Europe",
  },
  {
    lat: "50.4625609",
    lng: "4.4598286",
    country: "BE",
    country_long: "Belgium",
    portCode: "BECRL",
    portName: "Charleroi (BECRL), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.97678579999999",
    lng: "3.526467",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEDEZ",
    portName: "Deinze (BEDEZ), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.5628647",
    lng: "5.834713799999999",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEEAT",
    portName: "ATHUS (BEEAT), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.5813314",
    lng: "5.4046647",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEENG",
    portName: "Engis (BEENG), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.3945192",
    lng: "4.6960707",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEFOS",
    portName: "Fosses la Ville (BEFOS), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.1626132",
    lng: "4.9907929",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEGEL",
    portName: "Geel (BEGEL), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.106388",
    lng: "3.7498191",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEGNE",
    portName: "The Port of Ghent",
    portContinent: "Europe",
  },
  {
    lat: "50.9662102",
    lng: "5.5021822",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEGNK",
    portName: "Genk (BEGNK), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.1771658",
    lng: "4.836376599999999",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEHER",
    portName: "Herentals (BEHER), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.1452459",
    lng: "4.3382869",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEHEX",
    portName: "Hemiksem (BEHEX), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.9326432",
    lng: "5.3425633",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEHSS",
    portName: "Hasselt (BEHSS), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.9180338",
    lng: "3.2133625",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEIZG",
    portName: "Izegem (BEIZG), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.15141999999999",
    lng: "5.82297",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEKSN",
    portName: "Kessenich (BEKSN), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.11824",
    lng: "5.56868",
    country: "BE",
    country_long: "Belgium",
    portCode: "BELEU",
    portName: "Leuven (BELEU), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.6469444",
    lng: "5.5847222",
    country: "BE",
    country_long: "Belgium",
    portCode: "BELGG",
    portName: "Port of Liege",
    portContinent: "Europe",
  },
  {
    lat: "50.76777999999999",
    lng: "4.27707",
    country: "BE",
    country_long: "Belgium",
    portCode: "BELOT",
    portName: "Lot (BELOT), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.132046",
    lng: "5.0770596",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEMEH",
    portName: "MEERHOUT (BEMEH), Antwerpen, Belgium",
    portContinent: "Europe",
  },
  {
    lat: "50.79625490000001",
    lng: "3.1215356",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEMEN",
    portName: "Menen (BEMEN), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.1986273",
    lng: "4.636424700000001",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEMHN",
    portName: "Massenhoven (BEMHN), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.7699733",
    lng: "3.3974267",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEMNO",
    portName: "Moen (BEMNO), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.7430519",
    lng: "3.2138924",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEMOS",
    portName: "MOESKROEN (BEMOS), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.45466340000001",
    lng: "3.952313499999999",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEMQS",
    portName: "Mons (BEMQS), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.2508917",
    lng: "4.4489277",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEMRK",
    portName: "Merksem (BEMRK), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.1284296",
    lng: "2.7480379",
    country: "BE",
    country_long: "Belgium",
    portCode: "BENIE",
    portName: "Port of Nieuwpoort",
    portContinent: "Europe",
  },
  {
    lat: "51.2129972",
    lng: "4.599714899999999",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEOEL",
    portName: "Oelegem (BEOEL), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.16873340000001",
    lng: "4.903903",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEOLN",
    portName: "Olen (BEOLN), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.9205848",
    lng: "3.3287686",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEOSR",
    portName: "Oostrozebeke (BEOSR), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.2262681",
    lng: "2.9313562",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEOST",
    portName: "Port Oostende",
    portContinent: "Europe",
  },
  {
    lat: "50.9498725",
    lng: "3.1292766",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEROE",
    portName: "Roeselare (BEROE), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.1274074",
    lng: "4.2903448",
    country: "BE",
    country_long: "Belgium",
    portCode: "BERPM",
    portName: "Rupelmonde (BERPM), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.054855",
    lng: "4.2050401",
    country: "BE",
    country_long: "Belgium",
    portCode: "BESAS",
    portName: "Sint Amands (BESAS), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.2494528",
    lng: "4.4979217",
    country: "BE",
    country_long: "Belgium",
    portCode: "BESCT",
    portName: "SCHOTEN",
    portContinent: "Europe",
  },
  {
    lat: "50.6056059",
    lng: "3.3880367",
    country: "BE",
    country_long: "Belgium",
    portCode: "BETRN",
    portName: "Tournai (Doornik) (BETRN), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.7840561",
    lng: "3.0449082",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEWER",
    portName: "Wervik (BEWER), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.9099221",
    lng: "3.3726103",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEWIK",
    portName: "Wielsbeke",
    portContinent: "Europe",
  },
  {
    lat: "51.2270747",
    lng: "4.5194994",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEWJG",
    portName: "Wijnegem (BEWJG), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.0621814",
    lng: "4.3610481",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEWLB",
    portName: "WILLEBROEK (BEWLB), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.10672",
    lng: "4.29845",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEWTH",
    portName: "Wintham (BEWTH), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.334565",
    lng: "3.2184712",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEZEE",
    portName: "Port of Bruges-Zeebrugge",
    portContinent: "Europe",
  },
  {
    lat: "51.1982474",
    lng: "3.8124589",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEZEL",
    portName: "Zelzate (BEZEL), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.931987",
    lng: "5.5725111",
    country: "BE",
    country_long: "Belgium",
    portCode: "BEZTL",
    portName: "Zutendaal (BEZTL), Belgium, Europe",
    portContinent: "Europe",
  },
  {
    lat: "10.6400163",
    lng: "-4.7588042",
    country: "BF",
    country_long: "Burkina Faso",
    portCode: "BFBNR",
    portName: "Banfora (BFBNR), Banfora, Burkina Faso",
    portContinent: "Africa",
  },
  {
    lat: "11.1649219",
    lng: "-4.3051542",
    country: "BF",
    country_long: "Burkina Faso",
    portCode: "BFBOY",
    portName: "BOBO DIOULASSO (BFBOY), Bobo Dioulasso, Burkina Faso",
    portContinent: "Africa",
  },
  {
    lat: "12.3714277",
    lng: "-1.5196603",
    country: "BF",
    country_long: "Burkina Faso",
    portCode: "BFOUA",
    portName: "OUAGADOUGOU (BFOUA), Ouagadougou, Burkina Faso",
    portContinent: "Africa",
  },
  {
    lat: "43.4269047",
    lng: "28.1617188",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGBAL",
    portName: "Balchik (BGBAL), Bulgaria, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.50479259999999",
    lng: "27.4626361",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGBOJ",
    portName: "Burgas",
    portContinent: "Europe",
  },
  {
    lat: "42.2020702",
    lng: "25.3248541",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGCPW",
    portName: "CHIRPAN",
    portContinent: "Europe",
  },
  {
    lat: "43.43305609999999",
    lng: "28.3351756",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGKVN",
    portName: "Kavarna (BGKVN), Bulgaria, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.6601365",
    lng: "27.7205593",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGNES",
    portName: "Nessebar (BGNES), Bulgaria, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.1354079",
    lng: "24.7452904",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGPDV",
    portName: "Plovdiv",
    portContinent: "Europe",
  },
  {
    lat: "42.5680485",
    lng: "27.6155704",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGPOR",
    portName: "Pomorie (BGPOR), Bulgaria, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.4170423",
    lng: "24.6066847",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGPVN",
    portName: "PLEVEN",
    portContinent: "Europe",
  },
  {
    lat: "43.83557130000001",
    lng: "25.9656554",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGRDU",
    portName: "Ruse (BGRDU), Bulgaria, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.1147271",
    lng: "27.2671901",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGSLS",
    portName: "Silistra (BGSLS), Bulgaria, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.6977082",
    lng: "23.3218675",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGSOF",
    portName: "Sofia (BGSOF), Sofia, Bulgaria",
    portContinent: "Europe",
  },
  {
    lat: "42.4172628",
    lng: "27.6961748",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGSOZ",
    portName: "Sozopol (BGSOZ), Bulgaria, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.618351",
    lng: "25.3506224",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGSVZ",
    portName: "Svishtov (BGSVZ), Bulgaria, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.2140504",
    lng: "27.9147333",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGVAR",
    portName: "Varna",
    portContinent: "Europe",
  },
  {
    lat: "43.2140504",
    lng: "27.9147333",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGVAZ",
    portName: "Varna-Zapad (BGVAZ), Varna, Bulgaria",
    portContinent: "Europe",
  },
  {
    lat: "43.996159",
    lng: "22.8679302",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "BGVID",
    portName: "Vidin (BGVID), Bulgaria, Med",
    portContinent: "Europe",
  },
  {
    lat: "26.2340617",
    lng: "50.652032",
    country: "BH",
    country_long: "Bahrain",
    portCode: "BHAHD",
    portName: "Hidd (BHAHD), Bahrain, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "26.2235305",
    lng: "50.5875935",
    country: "BH",
    country_long: "Bahrain",
    portCode: "BHAMH",
    portName: "Al Manamah (BHAMH), Manama, Bahrain",
    portContinent: "Middle East",
  },
  {
    lat: "26.0667",
    lng: "50.5577",
    country: "BH",
    country_long: "Bahrain",
    portCode: "BHBAH",
    portName: "Bahrain",
    portContinent: "Middle East",
  },
  {
    lat: "26.2534919",
    lng: "50.60825579999999",
    country: "BH",
    country_long: "Bahrain",
    portCode: "BHGBQ",
    portName: "Muharraq",
    portContinent: "Middle East",
  },
  {
    lat: "26.0667",
    lng: "50.5577",
    country: "BH",
    country_long: "Bahrain",
    portCode: "BHKBS",
    portName: "Khalifa Bin Salman (BHKBS), Bahrain, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "26.2039716",
    lng: "50.6132554",
    country: "BH",
    country_long: "Bahrain",
    portCode: "BHMIN",
    portName: "Port of Mina Salman",
    portContinent: "Middle East",
  },
  {
    lat: "26.2703091",
    lng: "50.6612182",
    country: "BH",
    country_long: "Bahrain",
    portCode: "BHQAL",
    portName: "Galali (BHQAL), Bahrain, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "BHSIT",
    portName: "Port of Sitra",
    portContinent: "Middle East",
  },
  {
    lat: "-3.361378",
    lng: "29.3598782",
    country: "BI",
    country_long: "Burundi",
    portCode: "BIBJM",
    portName: "BUJUMBURA (BIBJM), Bujumbura, Burundi",
    portContinent: "Africa",
  },
  {
    lat: "-3.4272755",
    lng: "29.9246016",
    country: "BI",
    country_long: "Burundi",
    portCode: "BIGID",
    portName: "Gitega",
    portContinent: "Africa",
  },
  {
    lat: "6.3702928",
    lng: "2.3912362",
    country: "BJ",
    country_long: "Benin",
    portCode: "BJCOO",
    portName: "Port of Cotonou",
    portContinent: "Africa",
  },
  {
    lat: "17.8964345",
    lng: "-62.85220099999999",
    country: "BL",
    country_long: "Saint Barthélemy",
    portCode: "BLGUS",
    portName: "Gustavia (BLGUS), Saint Barthelemy, LatAm",
    portContinent: null,
  },
  {
    lat: "17.9",
    lng: "-62.833333",
    country: "BL",
    country_long: "Saint Barthélemy",
    portCode: "BLSBH",
    portName: "Saint-Barthelemy",
    portContinent: null,
  },
  {
    lat: "43.27322059999999",
    lng: "-79.862219",
    country: "CA",
    country_long: "Canada",
    portCode: "BMBDA",
    portName: "Port of Hamilton",
    portContinent: "North America",
  },
  {
    lat: "43.8570065",
    lng: "-70.1031201",
    country: "US",
    country_long: "United States",
    portCode: "BMFPT",
    portName: "Freeport",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "BMHMB",
    portName: "Hamilton (BMHMB), Bermuda, usa",
    portContinent: "North America",
  },
  {
    lat: "12.0560975",
    lng: "-61.7487996",
    country: "GD",
    country_long: "Grenada",
    portCode: "BMSGE",
    portName: "St. George's",
    portContinent: "North America",
  },
  {
    lat: "4.903052199999999",
    lng: "114.939821",
    country: "BN",
    country_long: "Brunei",
    portCode: "BNBWN",
    portName: "Bandar Seri Begawan",
    portContinent: "South East Asia",
  },
  {
    lat: "4.5831986",
    lng: "114.2212174",
    country: "BN",
    country_long: "Brunei",
    portCode: "BNKUB",
    portName: "Kuala Belait (BNKUB), Brunei, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "5.0333206",
    lng: "115.0732883",
    country: "BN",
    country_long: "Brunei",
    portCode: "BNMUA",
    portName: "Muara (BNMUA), Brunei, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-17.4139766",
    lng: "-66.1653224",
    country: "BO",
    country_long: "Bolivia",
    portCode: "BOCBB",
    portName: "COCHABAMBA",
    portContinent: "South America",
  },
  {
    lat: "-16.489689",
    lng: "-68.11929359999999",
    country: "BO",
    country_long: "Bolivia",
    portCode: "BOLPB",
    portName: "LA PAZ, BO",
    portContinent: "South America",
  },
  {
    lat: "-17.9716723",
    lng: "-67.0931378",
    country: "BO",
    country_long: "Bolivia",
    portCode: "BOORU",
    portName: "ORURO",
    portContinent: "South America",
  },
  {
    lat: "-19.5722805",
    lng: "-65.7550063",
    country: "BO",
    country_long: "Bolivia",
    portCode: "BOPOI",
    portName: "POTOSI",
    portContinent: "South America",
  },
  {
    lat: "-18.9720085",
    lng: "-57.8140428",
    country: "BO",
    country_long: "Bolivia",
    portCode: "BOPSZ",
    portName: "Puerto Suarez (BOPSZ), Bolivia, Sam",
    portContinent: "South America",
  },
  {
    lat: "-45.1636777",
    lng: "-73.5259828",
    country: "CL",
    country_long: "Chile",
    portCode: "BOQJR",
    portName: "Puerto Aguirre",
    portContinent: "South America",
  },
  {
    lat: "-17.8145819",
    lng: "-63.1560853",
    country: "BO",
    country_long: "Bolivia",
    portCode: "BOSCS",
    portName: "SANTA CRUZ DE LA SIERRA",
    portContinent: "South America",
  },
  {
    lat: "-19.035345",
    lng: "-65.2592128",
    country: "BO",
    country_long: "Bolivia",
    portCode: "BOSRE",
    portName: "SUCRE, BO",
    portContinent: "South America",
  },
  {
    lat: "36.9741171",
    lng: "-122.0307963",
    country: "US",
    country_long: "United States",
    portCode: "BOSRZ",
    portName: "SANTA CRUZ",
    portContinent: "South America",
  },
  {
    lat: "-21.5177889",
    lng: "-64.72956669999999",
    country: "BO",
    country_long: "Bolivia",
    portCode: "BOTJA",
    portName: "TARIJA",
    portContinent: "South America",
  },
  {
    lat: "17.4867485",
    lng: "-62.9764978",
    country: "BQ",
    country_long: "Caribbean Netherlands",
    portCode: "BQ8OJ",
    portName: "ORANJESTAD (BQ8OJ), Sin Eustatius, LatAm",
    portContinent: "North America",
  },
  {
    lat: "12.2018902",
    lng: "-68.26238219999999",
    country: "BQ",
    country_long: "Caribbean Netherlands",
    portCode: "BQBON",
    portName: "BONAIRE (BQBON), BONAIRE, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.4890306",
    lng: "-62.973555",
    country: "BQ",
    country_long: "Caribbean Netherlands",
    portCode: "BQEUX",
    portName: "ST EUSTATIUS",
    portContinent: "North America",
  },
  {
    lat: "12.1443491",
    lng: "-68.2655456",
    country: "BQ",
    country_long: "Caribbean Netherlands",
    portCode: "BQKRA",
    portName: "KRALENDIJK (BQKRA), BONAIRE, LatAm",
    portContinent: "North America",
  },
  {
    lat: "31.1957234",
    lng: "-98.718098",
    country: "US",
    country_long: "United States",
    portCode: "BQSAB",
    portName: "Fort Bay (BQSAB), SABA, LatAm",
    portContinent: "North America",
  },
  {
    lat: "-23.0069216",
    lng: "-44.3185172",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRADR",
    portName: "Angra Dos Reis (BRADR), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-14.235004",
    lng: "-51.92528",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRALU",
    portName: "Alumar (BRALU), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-4.073212420101655",
    lng: "-50.38961045506282",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRARB",
    portName: "Aratu (BRARB), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-26.4597504",
    lng: "-48.6103199",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRBCS",
    portName: "Balneario Barra Sul (BRBCS), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-1.4563432",
    lng: "-48.501299",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRBEL",
    portName: "Port of Belem",
    portContinent: "South America",
  },
  {
    lat: "-19.919052",
    lng: "-43.9386685",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRBHZ",
    portName: "BELO HORIZONTE",
    portContinent: "South America",
  },
  {
    lat: "-16.4443537",
    lng: "-39.0653656",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRBPS",
    portName: "Porto Seguro (BRBPS), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-1.4669698",
    lng: "-56.3792756",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRBTB",
    portName: "Porto Trombetas (BRBTB), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-19.9697308",
    lng: "-44.2032108",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRBTI",
    portName: "Betim",
    portContinent: "South America",
  },
  {
    lat: "-22.7553281",
    lng: "-41.8833455",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRBZC",
    portName: "Buzios (BRBZC), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-27.0217451",
    lng: "-48.6515664",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRCBU",
    portName: "Camboriu (BRCBU), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-7.0402944",
    lng: "-34.8430206",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRCDO",
    portName: "Cabedelo (BRCDO), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "BRCGB",
    portName: "CUIABï¿½",
    portContinent: "South America",
  },
  {
    lat: "-2.9008418",
    lng: "-40.84209999999999",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRCMC",
    portName: "Camocim (BRCMC), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-19.0051788",
    lng: "-57.6527913",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRCMG",
    portName: "Port of Corumba",
    portContinent: "South America",
  },
  {
    lat: "-19.6299373",
    lng: "-43.989893",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRCNF",
    portName: "CONFINS",
    portContinent: "South America",
  },
  {
    lat: "-25.0105827",
    lng: "-47.92988099999999",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRCNI",
    portName: "Cananeia (BRCNI), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-15.6773461",
    lng: "-38.9451874",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRCNV",
    portName: "Canavieiras (BRCNV), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-22.9099384",
    lng: "-47.0626332",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRCPQ",
    portName: "CAMPINAS",
    portContinent: "South America",
  },
  {
    lat: "-25.4372382",
    lng: "-49.2699727",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRCWB",
    portName: "Curitiba",
    portContinent: "South America",
  },
  {
    lat: "-14.235004",
    lng: "-51.92528",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRFNO",
    portName: "Forno (BRFNO), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-3.7327203",
    lng: "-38.5270134",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRFOR",
    portName: "Fortaleza (BRFOR), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "60.39126279999999",
    lng: "5.3220544",
    country: "NO",
    country_long: "Norway",
    portCode: "BRGEB",
    portName: "Gebig (BRGEB), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-25.2987433",
    lng: "-48.3297216",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRGQE",
    portName: "Guaraquecaba (BRGQE), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-23.4596858",
    lng: "-46.5328559",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRGRU",
    portName: "GUARULHOS APT/SAO PAULO",
    portContinent: "South America",
  },
  {
    lat: "-29.3646459",
    lng: "-51.6657692",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRGSU",
    portName: "RIO GRANDE DO SUL",
    portContinent: "South America",
  },
  {
    lat: "-25.8843865",
    lng: "-48.5762979",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRGTB",
    portName: "Guaratuba (BRGTB), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-5.1075993",
    lng: "-36.3196998",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRGUA",
    portName: "Guamare (BRGUA), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-23.0044755",
    lng: "-44.0365758",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRGUI",
    portName: "Ilha Guaiba (BRGUI), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-28.2408045",
    lng: "-48.6686568",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRIBB",
    portName: "Imbituba (BRIBB), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-23.1520839",
    lng: "-44.2289441",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRIGE",
    portName: "Ilha Grande (BRIGE), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-22.8631923",
    lng: "-43.7779091",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRIGI",
    portName: "ITAGUAI",
    portContinent: "South America",
  },
  {
    lat: "-24.7070203",
    lng: "-47.5574692",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRIGP",
    portName: "Iguape (BRIGP), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-25.5161867",
    lng: "-48.3339796",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRIME",
    portName: "Ilha Do Mel (BRIME), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-26.0713721",
    lng: "-48.6178349",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRIOA",
    portName: "Itapoa",
    portContinent: "South America",
  },
  {
    lat: "-14.7981287",
    lng: "-39.0346984",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRIOS",
    portName: "Ilheus (BRIOS), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-3.135021",
    lng: "-58.438544",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRITA",
    portName: "Itacoatiara (BRITA), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-4.2647341",
    lng: "-55.991954",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRITB",
    portName: "Port of Itaituba",
    portContinent: "South America",
  },
  {
    lat: "-22.8631923",
    lng: "-43.7779091",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRITG",
    portName: "ITAGUAI",
    portContinent: "South America",
  },
  {
    lat: "-24.1840963",
    lng: "-46.7897516",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRITH",
    portName: "Itanhaem (BRITH), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-26.9082782",
    lng: "-48.67751089999999",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRITJ",
    portName: "Itajai",
    portContinent: "South America",
  },
  {
    lat: "-29.1282237",
    lng: "-56.5552712",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRITQ",
    portName: "Itaqui (BRITQ), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-22.3819684",
    lng: "-43.1321582",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRITV",
    portName: "Itaipava (BRITV), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-32.5651057",
    lng: "-53.37792160000001",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRJAG",
    portName: "Jaguarao (BRJAG), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-2.8814563",
    lng: "-41.6659109",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRLCI",
    portName: "Luis Correia (BRLCI), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-28.4816918",
    lng: "-48.7689145",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRLNG",
    portName: "Laguna (BRLNG), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-3.138323",
    lng: "-60.0274844",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRMAO",
    portName: "Port of Manaus",
    portContinent: "South America",
  },
  {
    lat: "0.04052170000000001",
    lng: "-51.0560957",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRMCP",
    portName: "Macapa (BRMCP), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-9.6660417",
    lng: "-35.7352167",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRMCZ",
    portName: "Maceio (BRMCZ), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-12.7439601",
    lng: "-38.6168111",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRMDD",
    portName: "Madre De Deus (BRMDD), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-22.3837126",
    lng: "-41.7827578",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRMEA",
    portName: "Macae (BRMEA), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "BRMGU",
    portName: "Port of Munguba",
    portContinent: "South America",
  },
  {
    lat: "-5.7841695",
    lng: "-35.1999708",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRNAT",
    portName: "Natal (BRNAT), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-29.683463",
    lng: "-51.1337471",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRNHO",
    portName: "NOVO HAMBURGO",
    portContinent: "South America",
  },
  {
    lat: "-22.8807073",
    lng: "-43.1013526",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRNTR",
    portName: "Niteroi (BRNTR), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-26.8954069",
    lng: "-48.6569206",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRNVT",
    portName: "Navegantes",
    portContinent: "South America",
  },
  {
    lat: "39.3604212",
    lng: "-9.1582014",
    country: "PT",
    country_long: "Portugal",
    portCode: "BROBI",
    portName: "Port of Obidos",
    portContinent: "South America",
  },
  {
    lat: "-22.2270778",
    lng: "-45.93937160000001",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRPAR",
    portName: "Pouso Alegre",
    portContinent: "South America",
  },
  {
    lat: "-23.2197106",
    lng: "-44.7167452",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRPAT",
    portName: "Paraty (BRPAT), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-19.8334255",
    lng: "-40.0632553",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRPCL",
    portName: "Portocel (BRPCL), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-3.5495406",
    lng: "-38.81083479999999",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRPEC",
    portName: "Pecem",
    portContinent: "South America",
  },
  {
    lat: "-25.515148",
    lng: "-48.5224133",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRPNG",
    portName: "Paranagua",
    portContinent: "South America",
  },
  {
    lat: "-30.0368176",
    lng: "-51.2089887",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRPOA",
    portName: "Porto Alegre (BRPOA), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "17.4315789",
    lng: "78.39511759999999",
    country: "IN",
    country_long: "India",
    portCode: "BRPOU",
    portName: "Ponta Ubu (BRPOU), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-21.0272927",
    lng: "-48.0352539",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRPTL",
    portName: "Pontal (BRPTL), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-1.7493335",
    lng: "-52.2344342",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRPTQ",
    portName: "Porto De Moz (BRPTQ), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-47.75156459999999",
    lng: "-65.9013774",
    country: "AR",
    country_long: "Argentina",
    portCode: "BRPUD",
    portName: "Puerto Deseado",
    portContinent: "South America",
  },
  {
    lat: "-8.7635576",
    lng: "-63.89717040000001",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRPVH",
    portName: "Porto Velho (BRPVH), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-22.8834591",
    lng: "-42.0252951",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRQCK",
    portName: "Cabo Frio (BRQCK), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-1.3227098",
    lng: "-48.4525125",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRQDO",
    portName: "Icoarachi (BRQDO), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-29.683463",
    lng: "-51.1337471",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRQHV",
    portName: "NOVO HAMBURG",
    portContinent: "South America",
  },
  {
    lat: "-29.9077945",
    lng: "-51.184698",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRQNS",
    portName: "Canoas",
    portContinent: "South America",
  },
  {
    lat: "-9.9740249",
    lng: "-67.8098191",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRRBR",
    portName: "Rio Branco (BRRBR), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-8.0578381",
    lng: "-34.8828969",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRREC",
    portName: "Recife (BRREC), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "BRRIG",
    portName: "Rio Grande",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "BRRIO",
    portName: "Port of Rio de Janeiro",
    portContinent: "South America",
  },
  {
    lat: "-23.6440805",
    lng: "-47.575529",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRSAF",
    portName: "SALTO DE PIRAPORA",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "BRSAN",
    portName: "Port of Santana",
    portContinent: "South America",
  },
  {
    lat: "-23.5557714",
    lng: "-46.6395571",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRSAO",
    portName: "Sao Paulo",
    portContinent: "South America",
  },
  {
    lat: "37.3541079",
    lng: "-121.9552356",
    country: "US",
    country_long: "United States",
    portCode: "BRSCS",
    portName: "Santa Clara (BRSCS), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-22.9674264",
    lng: "-43.7056394",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRSEP",
    portName: "Sepetiba (BRSEP), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-0.7284855000000001",
    lng: "-48.5176875",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRSFK",
    portName: "Soure (BRSFK), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-26.2179063",
    lng: "-48.6659155",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRSFS",
    portName: "Sao Francisco (BRSFS), Sao Francisco do Sul, Brazil",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "BRSLZ",
    portName: "SÃ£o LuÃ­s",
    portContinent: "South America",
  },
  {
    lat: "-22.9674264",
    lng: "-43.7056394",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRSPB",
    portName: "Itaguai Sepetiba",
    portContinent: "South America",
  },
  {
    lat: "-7.1461478",
    lng: "-34.9692045",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRSRR",
    portName: "Santa Rita (BRSRR), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "13.794185",
    lng: "-88.89653",
    country: "SV",
    country_long: "El Salvador",
    portCode: "BRSSA",
    portName: "Salvador",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "BRSSZ",
    portName: "Santos",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "BRSTM",
    portName: "Port of Santarem",
    portContinent: "South America",
  },
  {
    lat: "-8.3536618",
    lng: "-34.9575528",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRSUA",
    portName: "Suape",
    portContinent: "South America",
  },
  {
    lat: "-1.4669698",
    lng: "-56.3792756",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRTMT",
    portName: "Port of Porto Trombetas",
    portContinent: "South America",
  },
  {
    lat: "-29.9838184",
    lng: "-50.1343012",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRTRM",
    portName: "Tramandai (BRTRM), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-14.235004",
    lng: "-51.92528",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRTSQ",
    portName: "Torres (BRTSQ), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "14.93305",
    lng: "-23.5133267",
    country: "CV",
    country_long: "Cape Verde",
    portCode: "BRTUB",
    portName: "Praia (BRTUB), Praia, Cape Verde",
    portContinent: "South America",
  },
  {
    lat: "10.872762",
    lng: "-74.97735399999999",
    country: "CO",
    country_long: "Colombia",
    portCode: "BRTUO",
    portName: "TubarÃ£o",
    portContinent: "South America",
  },
  {
    lat: "-23.4345298",
    lng: "-45.0849154",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRUBT",
    portName: "Ubatuba (BRUBT), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "-21.5560459",
    lng: "-45.4363875",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRVAG",
    portName: "VARGINHA",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "BRVCP",
    portName: "VIRACOPOS INTL AIRPORT",
    portContinent: "South America",
  },
  {
    lat: "19.2601605",
    lng: "-96.57833869999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "BRVCZ",
    portName: "Vera Cruz",
    portContinent: "South America",
  },
  {
    lat: "41.3517302",
    lng: "-8.7478619",
    country: "PT",
    country_long: "Portugal",
    portCode: "BRVDC",
    portName: "Port of Vila do Conde",
    portContinent: "South America",
  },
  {
    lat: "-20.3220154",
    lng: "-40.3372586",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRVIX",
    portName: "Port of Vitoria",
    portContinent: "South America",
  },
  {
    lat: "-14.235004",
    lng: "-51.92528",
    country: "BR",
    country_long: "Brazil",
    portCode: "BRVLC",
    portName: "Vila Do Conde (BRVLC), Brazil, Sam",
    portContinent: "South America",
  },
  {
    lat: "25.8178634",
    lng: "-77.938883",
    country: "BS",
    country_long: "The Bahamas",
    portCode: "BSCOC",
    portName: "Cococay (BSCOC), Bahamas, LatAm",
    portContinent: "North America",
  },
  {
    lat: "24.9314461",
    lng: "-76.18996609999999",
    country: "BS",
    country_long: "The Bahamas",
    portCode: "BSELE",
    portName: "ELEUTHERA",
    portContinent: "North America",
  },
  {
    lat: "26.5333159",
    lng: "-78.6429019",
    country: "BS",
    country_long: "The Bahamas",
    portCode: "BSFPO",
    portName: "Freeport, Grand Bahama",
    portContinent: "North America",
  },
  {
    lat: "26.0936823",
    lng: "-77.5332796",
    country: "BS",
    country_long: "The Bahamas",
    portCode: "BSGOC",
    portName: "Gorda Cay (BSGOC), Bahamas, LatAm",
    portContinent: "North America",
  },
  {
    lat: "25.8248705",
    lng: "-77.9096036",
    country: "BS",
    country_long: "The Bahamas",
    portCode: "BSGSC",
    portName: "Great Stirrup Cay (BSGSC), Bahamas, LatAm",
    portContinent: "North America",
  },
  {
    lat: "26.5241653",
    lng: "-77.09098089999999",
    country: "BS",
    country_long: "The Bahamas",
    portCode: "BSMHH",
    portName: "Marsh Harbor (BSMHH), Bahamas, LatAm",
    portContinent: "North America",
  },
  {
    lat: "25.0443312",
    lng: "-77.3503609",
    country: "BS",
    country_long: "The Bahamas",
    portCode: "BSNAS",
    portName: "Nassau (BSNAS), Bahamas, LatAm",
    portContinent: "North America",
  },
  {
    lat: "26.6468493",
    lng: "-77.9431625",
    country: "BS",
    country_long: "The Bahamas",
    portCode: "BSSRP",
    portName: "Port of South Riding Point",
    portContinent: "North America",
  },
  {
    lat: "53.8980663",
    lng: "30.3325337",
    country: "BY",
    country_long: "Belarus",
    portCode: "BTMVQ",
    portName: "MAGILOV",
    portContinent: "Asia",
  },
  {
    lat: "26.860293",
    lng: "89.39379300000002",
    country: "BT",
    country_long: "Bhutan",
    portCode: "BTPHU",
    portName: "Phuentsholing",
    portContinent: "Asia",
  },
  {
    lat: "27.4712216",
    lng: "89.6339041",
    country: "BT",
    country_long: "Bhutan",
    portCode: "BTTHI",
    portName: "THIMBU",
    portContinent: "Asia",
  },
  {
    lat: "-24.6282079",
    lng: "25.9231471",
    country: "BW",
    country_long: "Botswana",
    portCode: "BWGBE",
    portName: "Gaborone",
    portContinent: "Africa",
  },
  {
    lat: "53.1450386",
    lng: "29.2355135",
    country: "BY",
    country_long: "Belarus",
    portCode: "BYBOB",
    portName: "Bobruysk",
    portContinent: "Europe",
  },
  {
    lat: "48.390394",
    lng: "-4.486076",
    country: "FR",
    country_long: "France",
    portCode: "BYBQT",
    portName: "Brest",
    portContinent: "Europe",
  },
  {
    lat: "53.6687634",
    lng: "23.8222673",
    country: "BY",
    country_long: "Belarus",
    portCode: "BYGDO",
    portName: "GRODNO",
    portContinent: "Europe",
  },
  {
    lat: "52.4313388",
    lng: "30.99367",
    country: "BY",
    country_long: "Belarus",
    portCode: "BYGME",
    portName: "GOMEL",
    portContinent: "Europe",
  },
  {
    lat: "53.8904386",
    lng: "25.3028153",
    country: "BY",
    country_long: "Belarus",
    portCode: "BYLDA",
    portName: "LIDA",
    portContinent: "Europe",
  },
  {
    lat: "52.0366919",
    lng: "29.2195237",
    country: "BY",
    country_long: "Belarus",
    portCode: "BYMAZ",
    portName: "Mazyr",
    portContinent: "Europe",
  },
  {
    lat: "53.9006011",
    lng: "27.558972",
    country: "BY",
    country_long: "Belarus",
    portCode: "BYMSQ",
    portName: "MINSK",
    portContinent: "Europe",
  },
  {
    lat: "53.8980663",
    lng: "30.3325337",
    country: "BY",
    country_long: "Belarus",
    portCode: "BYMVQ",
    portName: "MOGILEV",
    portContinent: "Europe",
  },
  {
    lat: "54.31015379999999",
    lng: "26.844183",
    country: "BY",
    country_long: "Belarus",
    portCode: "BYMZY",
    portName: "MALADZYECHNA",
    portContinent: "Europe",
  },
  {
    lat: "55.5324165",
    lng: "28.6591795",
    country: "BY",
    country_long: "Belarus",
    portCode: "BYNPK",
    portName: "Navapolatsk",
    portContinent: "Europe",
  },
  {
    lat: "54.51256",
    lng: "30.4251008",
    country: "BY",
    country_long: "Belarus",
    portCode: "BYOSH",
    portName: "ORSHA",
    portContinent: "Europe",
  },
  {
    lat: "52.1181686",
    lng: "26.1016778",
    country: "BY",
    country_long: "Belarus",
    portCode: "BYPIK",
    portName: "PINSK",
    portContinent: "Europe",
  },
  {
    lat: "52.786267",
    lng: "27.5243197",
    country: "BY",
    country_long: "Belarus",
    portCode: "BYSHO",
    portName: "SALIHORSK",
    portContinent: "Europe",
  },
  {
    lat: "55.1926809",
    lng: "30.206359",
    country: "BY",
    country_long: "Belarus",
    portCode: "BYVTB",
    portName: "VITEBSK",
    portContinent: "Europe",
  },
  {
    lat: "16.517376",
    lng: "-88.40432299999999",
    country: "BZ",
    country_long: "Belize",
    portCode: "BZBGK",
    portName: "Big Creek (BZBGK), Belize, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.4816717",
    lng: "-88.20223329999999",
    country: "BZ",
    country_long: "Belize",
    portCode: "BZBZE",
    portName: "Port of Belize",
    portContinent: "North America",
  },
  {
    lat: "17.691774",
    lng: "-88.04364439999999",
    country: "BZ",
    country_long: "Belize",
    portCode: "BZCUC",
    portName: "Caye Chapel (BZCUC), Belize, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.7611578",
    lng: "-88.02774869999999",
    country: "BZ",
    country_long: "Belize",
    portCode: "BZCUK",
    portName: "Caye Caulker (BZCUK), Belize, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.4030802",
    lng: "-88.3967536",
    country: "BZ",
    country_long: "Belize",
    portCode: "BZCZH",
    portName: "Corozal (BZCZH), Belize, LatAm",
    portContinent: "North America",
  },
  {
    lat: "16.969561",
    lng: "-88.23151299999999",
    country: "BZ",
    country_long: "Belize",
    portCode: "BZDGA",
    portName: "Dangriga (BZDGA), Belize, LatAm",
    portContinent: "North America",
  },
  {
    lat: "16.5211599",
    lng: "-88.3712796",
    country: "BZ",
    country_long: "Belize",
    portCode: "BZPLJ",
    portName: "Placencia (BZPLJ), Belize, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.189877",
    lng: "-88.49765",
    country: "BZ",
    country_long: "Belize",
    portCode: "BZPND",
    portName: "Punta Gorda (BZPND), Belize, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.9213664",
    lng: "-87.9610905",
    country: "BZ",
    country_long: "Belize",
    portCode: "BZSPR",
    portName: "San Pedro (BZSPR), Belize, LatAm",
    portContinent: "North America",
  },
  {
    lat: "51.2926993",
    lng: "-114.0134073",
    country: "CA",
    country_long: "Canada",
    portCode: "CAAIR",
    portName: "AIRDRIE",
    portContinent: "North America",
  },
  {
    lat: "42.1013779",
    lng: "-83.1086977",
    country: "CA",
    country_long: "Canada",
    portCode: "CAAMH",
    portName: "Port of Amherstburg",
    portContinent: "North America",
  },
  {
    lat: "49.164345",
    lng: "-122.963058",
    country: "CA",
    country_long: "Canada",
    portCode: "CAANI",
    portName: "Annacis Island (CAANI), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "47.75744479999999",
    lng: "-53.9887437",
    country: "CA",
    country_long: "Canada",
    portCode: "CAARC",
    portName: "Arnold's Cove (CAARC), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "CAAXR",
    portName: "Alexandria, ON, Canada (CAAXR), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "-33.4111857",
    lng: "149.578693",
    country: "AU",
    country_long: "Australia",
    portCode: "CABAT",
    portName: "BATHURST",
    portContinent: "North America",
  },
  {
    lat: "47.31851440000001",
    lng: "-52.8155283",
    country: "CA",
    country_long: "Canada",
    portCode: "CABBU",
    portName: "Bay Bulls (CABBU), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "44.5883135",
    lng: "-75.68164809999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CABCK",
    portName: "Brockville Harbour",
    portContinent: "North America",
  },
  {
    lat: "49.2335341",
    lng: "-68.1344024",
    country: "CA",
    country_long: "Canada",
    portCode: "CABCO",
    portName: "Port of Baie-Comeau",
    portContinent: "North America",
  },
  {
    lat: "46.4000233",
    lng: "-72.3795214",
    country: "CA",
    country_long: "Canada",
    portCode: "CABEC",
    portName: "Port of Becancour",
    portContinent: "North America",
  },
  {
    lat: "47.8859489",
    lng: "-65.8114779",
    country: "CA",
    country_long: "Canada",
    portCode: "CABEL",
    portName: "Belledune (CABEL), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "52.3721277",
    lng: "-126.7539346",
    country: "CA",
    country_long: "Canada",
    portCode: "CABLC",
    portName: "Bella Coola (CABLC), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "CABLK",
    portName: "Baker Lake (CABLK), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "56.130366",
    lng: "-106.346771",
    country: "CA",
    country_long: "Canada",
    portCode: "CABLV",
    portName: "Belleville (CABLV), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "43.7315479",
    lng: "-79.7624177",
    country: "CA",
    country_long: "Canada",
    portCode: "CABRP",
    portName: "BRAMPTON",
    portContinent: "North America",
  },
  {
    lat: "47.8357647",
    lng: "-53.8779907",
    country: "CA",
    country_long: "Canada",
    portCode: "CABUA",
    portName: "Bull Arm (CABUA), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "47.613413",
    lng: "-57.610491",
    country: "CA",
    country_long: "Canada",
    portCode: "CABUO",
    portName: "Burgeo (CABUO), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "42.6478043",
    lng: "-80.8003197",
    country: "CA",
    country_long: "Canada",
    portCode: "CABUZ",
    portName: "Port Burwell (CABUZ), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "49.1422983",
    lng: "-55.3440853",
    country: "CA",
    country_long: "Canada",
    portCode: "CABWD",
    portName: "Botwood (CABWD), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "45.3362846",
    lng: "-60.9944422",
    country: "CA",
    country_long: "Canada",
    portCode: "CACAH",
    portName: "Canso (CACAH), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "51.04473309999999",
    lng: "-114.0718831",
    country: "CA",
    country_long: "Canada",
    portCode: "CACAL",
    portName: "CALGARY, AB",
    portContinent: "North America",
  },
  {
    lat: "47.832757",
    lng: "-53.9977096",
    country: "CA",
    country_long: "Canada",
    portCode: "CACBC",
    portName: "Come By Chance (CACBC), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "43.9593373",
    lng: "-78.1677363",
    country: "CA",
    country_long: "Canada",
    portCode: "CACBG",
    portName: "Cobourg (CACBG), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "48.9489967",
    lng: "-57.95027260000001",
    country: "CA",
    country_long: "Canada",
    portCode: "CACBK",
    portName: "Corner Brook (CACBK), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "56.130366",
    lng: "-106.346771",
    country: "CA",
    country_long: "Canada",
    portCode: "CACDN",
    portName: "Cardinal (CACDN), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "46.23824",
    lng: "-63.13107040000001",
    country: "CA",
    country_long: "Canada",
    portCode: "CACHA",
    portName: "Charlottetown (CACHA), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "48.3516735",
    lng: "-71.1385136",
    country: "CA",
    country_long: "Canada",
    portCode: "CACHI",
    portName: "Port of Chicoutimi",
    portContinent: "North America",
  },
  {
    lat: "56.130366",
    lng: "-106.346771",
    country: "CA",
    country_long: "Canada",
    portCode: "CACHV",
    portName: "Churchill (CACHV), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "48.168225",
    lng: "-53.9645533",
    country: "CA",
    country_long: "Canada",
    portCode: "CACLE",
    portName: "Clarenville (CACLE), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "43.4507063",
    lng: "-65.6512144",
    country: "CA",
    country_long: "Canada",
    portCode: "CACLH",
    portName: "Clark's Harbour (CACLH), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "45.8654102",
    lng: "-73.2412825",
    country: "CA",
    country_long: "Canada",
    portCode: "CACOC",
    portName: "Contrecoeur Harbor",
    portContinent: "North America",
  },
  {
    lat: "44.50076869999999",
    lng: "-80.2169047",
    country: "CA",
    country_long: "Canada",
    portCode: "CACOL",
    portName: "Port of Collingwood",
    portContinent: "North America",
  },
  {
    lat: "49.2837626",
    lng: "-122.7932065",
    country: "CA",
    country_long: "Canada",
    portCode: "CACOQ",
    portName: "Coquitlam",
    portContinent: "North America",
  },
  {
    lat: "36.778261",
    lng: "-119.4179324",
    country: "US",
    country_long: "United States",
    portCode: "CACPT",
    portName: "Canaport (CACPT), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "45.4",
    lng: "-73.583333",
    country: "CA",
    country_long: "Canada",
    portCode: "CACSC",
    portName: "Cote Ste Catherine (CACSC), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "44.6647724",
    lng: "-74.9932733",
    country: "US",
    country_long: "United States",
    portCode: "CACSN",
    portName: "Port of Clarkson",
    portContinent: "North America",
  },
  {
    lat: "50.26604709999999",
    lng: "-5.0527125",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "CACWL",
    portName: "Port of Cornwall",
    portContinent: "North America",
  },
  {
    lat: "43.7044406",
    lng: "-72.2886934",
    country: "US",
    country_long: "United States",
    portCode: "CADAR",
    portName: "DARTMOUTH",
    portContinent: "North America",
  },
  {
    lat: "50.069333",
    lng: "-125.284734",
    country: "CA",
    country_long: "Canada",
    portCode: "CADCN",
    portName: "Duncan Bay (CADCN), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "56.130366",
    lng: "-106.346771",
    country: "CA",
    country_long: "Canada",
    portCode: "CADEB",
    portName: "Deception Bay (CADEB), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CADEL",
    portName: "DELTA",
    portContinent: "North America",
  },
  {
    lat: "39.8947843",
    lng: "-75.3472522",
    country: "US",
    country_long: "United States",
    portCode: "CADES",
    portName: "CADES",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "CADHS",
    portName: "Dalhousie (CADHS), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "43.5038998",
    lng: "-81.28660210000001",
    country: "CA",
    country_long: "Canada",
    portCode: "CADUB",
    portName: "Dublin, ON, Canada (CADUB), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "53.5461245",
    lng: "-113.4938229",
    country: "CA",
    country_long: "Canada",
    portCode: "CAEDM",
    portName: "Edmonton",
    portContinent: "North America",
  },
  {
    lat: "53.5461245",
    lng: "-113.4938229",
    country: "CA",
    country_long: "Canada",
    portCode: "CAEDS",
    portName: "Edmonton (CAEDS), Edmonton, Canada",
    portContinent: "North America",
  },
  {
    lat: "48.43584200000001",
    lng: "-123.411234",
    country: "CA",
    country_long: "Canada",
    portCode: "CAESQ",
    portName: "Esquimalt (CAESQ), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "51.214321",
    lng: "-0.798802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "CAFAR",
    portName: "FARNHAM",
    portContinent: "North America",
  },
  {
    lat: "58.102996",
    lng: "-68.41883899999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CAFCM",
    portName: "Kuujjuaq (CAFCM), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "40.5981867",
    lng: "-124.1572756",
    country: "US",
    country_long: "United States",
    portCode: "CAFOR",
    portName: "Fortune (CAFOR), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "45.9635895",
    lng: "-66.6431151",
    country: "CA",
    country_long: "Canada",
    portCode: "CAFRE",
    portName: "Fredericton",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CAFRV",
    portName: "Forestville Harbor",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "CAGAE",
    portName: "Grande Anse (CAGAE), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CAGBT",
    portName: "Port of Godbout",
    portContinent: "North America",
  },
  {
    lat: "47.9164257",
    lng: "-69.5010398",
    country: "CA",
    country_long: "Canada",
    portCode: "CAGCA",
    portName: "Gros Cacouna Harbor",
    portContinent: "North America",
  },
  {
    lat: "44.7063915",
    lng: "-66.8157713",
    country: "CA",
    country_long: "Canada",
    portCode: "CAGMA",
    portName: "Grand Manan (CAGMA), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "43.7474703",
    lng: "-81.72369870000001",
    country: "CA",
    country_long: "Canada",
    portCode: "CAGOH",
    portName: "Port of Goderich",
    portContinent: "North America",
  },
  {
    lat: "44.6475811",
    lng: "-63.5727683",
    country: "CA",
    country_long: "Canada",
    portCode: "CAHAL",
    portName: "Halifax (CAHAL), Halifax, Canada",
    portContinent: "North America",
  },
  {
    lat: "43.27322059999999",
    lng: "-79.862219",
    country: "CA",
    country_long: "Canada",
    portCode: "CAHAM",
    portName: "Hamilton Port",
    portContinent: "North America",
  },
  {
    lat: "47.6951139",
    lng: "-53.2116386",
    country: "CA",
    country_long: "Canada",
    portCode: "CAHRE",
    portName: "Harbour Grace (CAHRE), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "50.236921",
    lng: "-63.60612870000001",
    country: "CA",
    country_long: "Canada",
    portCode: "CAHSP",
    portName: "Port of Havre St Pierre",
    portContinent: "North America",
  },
  {
    lat: "47.3701784",
    lng: "-70.4154539",
    country: "CA",
    country_long: "Canada",
    portCode: "CAIXC",
    portName: "Ile Aux Coudres (CAIXC), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "30.3321838",
    lng: "-81.65565099999999",
    country: "US",
    country_long: "United States",
    portCode: "CAJAV",
    portName: "JACKSONVILLE",
    portContinent: "North America",
  },
  {
    lat: "56.130366",
    lng: "-106.346771",
    country: "CA",
    country_long: "Canada",
    portCode: "CAJOH",
    portName: "Johnstown (CAJOH), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "17.9525424",
    lng: "-76.8050018",
    country: "JM",
    country_long: "Jamaica",
    portCode: "CAKIN",
    portName: "Kingston Harbor",
    portContinent: "North America",
  },
  {
    lat: "54.0632661",
    lng: "-128.637191",
    country: "CA",
    country_long: "Canada",
    portCode: "CAKTM",
    portName: "Kitimat (CAKTM), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "27.5158689",
    lng: "-97.85610899999999",
    country: "US",
    country_long: "United States",
    portCode: "CAKVL",
    portName: "Port of Kingsville",
    portContinent: "North America",
  },
  {
    lat: "45.441347",
    lng: "-73.68858469999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CALAC",
    portName: "LACHINE",
    portContinent: "North America",
  },
  {
    lat: "48.516667",
    lng: "-59.00000000000001",
    country: "CA",
    country_long: "Canada",
    portCode: "CALCV",
    portName: "Lower Cove (CALCV), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "CALMN",
    portName: "Leamington (CALMN), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "38.9464996",
    lng: "-77.1588685",
    country: "US",
    country_long: "United States",
    portCode: "CALNG",
    portName: "LANGLEY",
    portContinent: "North America",
  },
  {
    lat: "46.6203892",
    lng: "-71.9154667",
    country: "CA",
    country_long: "Canada",
    portCode: "CALOT",
    portName: "Lotbiniere (CALOT), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "45.8781705",
    lng: "-73.2848829",
    country: "CA",
    country_long: "Canada",
    portCode: "CALVR",
    portName: "Lavaltrie (CALVR), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "43.521656",
    lng: "-65.734188",
    country: "CA",
    country_long: "Canada",
    portCode: "CALWH",
    portName: "Lower Woods Harbour (CALWH), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "49.2450384",
    lng: "-55.05915479999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CALWP",
    portName: "Lewisporte (CALWP), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "CALZN",
    portName: "Lauzon (CALZN), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CAMAR",
    portName: "Port of Marathon",
    portContinent: "North America",
  },
  {
    lat: "46.9801031",
    lng: "-70.5565719",
    country: "CA",
    country_long: "Canada",
    portCode: "CAMGN",
    portName: "Montmagny (CAMGN), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "48.998648",
    lng: "-66.980884",
    country: "CA",
    country_long: "Canada",
    portCode: "CAMHN",
    portName: "Les Mechins (CAMHN), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "31.9973456",
    lng: "-102.0779146",
    country: "US",
    country_long: "United States",
    portCode: "CAMID",
    portName: "Port of Midland",
    portContinent: "North America",
  },
  {
    lat: "47.9597235",
    lng: "-84.9001813",
    country: "CA",
    country_long: "Canada",
    portCode: "CAMIH",
    portName: "Michipicoten Harbour",
    portContinent: "North America",
  },
  {
    lat: "43.5890452",
    lng: "-79.6441198",
    country: "CA",
    country_long: "Canada",
    portCode: "CAMIS",
    portName: "MISSISSAUGA",
    portContinent: "North America",
  },
  {
    lat: "48.8445157",
    lng: "-67.5305758",
    country: "CA",
    country_long: "Canada",
    portCode: "CAMNE",
    portName: "Matane Harbor",
    portContinent: "North America",
  },
  {
    lat: "46.1284783",
    lng: "-64.8232748",
    country: "CA",
    country_long: "Canada",
    portCode: "CAMNT",
    portName: "Moncton (CAMNT), Moncton, Canada",
    portContinent: "North America",
  },
  {
    lat: "42.508001",
    lng: "2.122672",
    country: "FR",
    country_long: "France",
    portCode: "CAMOL",
    portName: "Mont Louis Harbor",
    portContinent: "North America",
  },
  {
    lat: "45.6320003",
    lng: "-73.5066981",
    country: "CA",
    country_long: "Canada",
    portCode: "CAMRE",
    portName: "Montreal-Est (CAMRE), MontrÐ“Â©al, Canada",
    portContinent: "North America",
  },
  {
    lat: "56.130366",
    lng: "-106.346771",
    country: "CA",
    country_long: "Canada",
    portCode: "CAMRW",
    portName: "Mooretown (CAMRW), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "CAMTN",
    portName: "Marystown (CAMTN), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CAMTR",
    portName: "Port of Montreal",
    portContinent: "North America",
  },
  {
    lat: "42.80907",
    lng: "-80.08228299999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CANAN",
    portName: "Nanticoke (CANAN), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "49.1658836",
    lng: "-123.9400647",
    country: "CA",
    country_long: "Canada",
    portCode: "CANNO",
    portName: "Nanaimo (CANNO), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "46.2064806",
    lng: "-60.25238049999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CANSY",
    portName: "North Sydney (CANSY), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "51.253775",
    lng: "-85.3232139",
    country: "CA",
    country_long: "Canada",
    portCode: "CANWC",
    portName: "Ontario",
    portContinent: "North America",
  },
  {
    lat: "49.2057179",
    lng: "-122.910956",
    country: "CA",
    country_long: "Canada",
    portCode: "CANWE",
    portName: "New Westminster (CANWE), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "47.302052",
    lng: "-53.986824",
    country: "CA",
    country_long: "Canada",
    portCode: "CANWP",
    portName: "Argentia (CANWP), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "CAOAJ",
    portName: "Oakville (CAOAJ), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "52.354021",
    lng: "-127.693746",
    country: "CA",
    country_long: "Canada",
    portCode: "CAOFA",
    portName: "Ocean Falls (CAOFA), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "43.94302652613048",
    lng: "-78.29317052797501",
    country: "CA",
    country_long: "Canada",
    portCode: "CAOPE",
    portName: "Port Hope Harbour",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CAORG",
    portName: "CAORG",
    portContinent: "North America",
  },
  {
    lat: "43.864952",
    lng: "-78.82505030000002",
    country: "CA",
    country_long: "Canada",
    portCode: "CAOSH",
    portName: "Port of Oshawa",
    portContinent: "North America",
  },
  {
    lat: "45.4215296",
    lng: "-75.69719309999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CAOTT",
    portName: "Ottawa",
    portContinent: "North America",
  },
  {
    lat: "44.5690305",
    lng: "-80.9405602",
    country: "CA",
    country_long: "Canada",
    portCode: "CAOWS",
    portName: "Owen Sound Harbor",
    portContinent: "North America",
  },
  {
    lat: "49.2338882",
    lng: "-124.8055494",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPAB",
    portName: "Port Alberni (CAPAB), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "48.32630899999999",
    lng: "-70.900453",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPAF",
    portName: "Port Alfred (CAPAF), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "47.5721149",
    lng: "-59.136429",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPBQ",
    portName: "Port Aux Basques (CAPBQ), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "50.0212524",
    lng: "-66.8760025",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPCA",
    portName: "Port of Port Cartier",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CAPCH",
    portName: "Pritchard",
    portContinent: "North America",
  },
  {
    lat: "42.88652039999999",
    lng: "-79.2508558",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPCO",
    portName: "Port Colborne",
    portContinent: "North America",
  },
  {
    lat: "43.5516151",
    lng: "-79.585628",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPCR",
    portName: "Port Credit (CAPCR), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "48.179863",
    lng: "-64.971037",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPDL",
    portName: "Port Daniel (CAPDL), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "42.7865225",
    lng: "-80.20327790000002",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPDV",
    portName: "Port Dover (CAPDV), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "45.6276473",
    lng: "-61.3585543",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPHW",
    portName: "Port Hawkesbury (CAPHW), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "50.7207083",
    lng: "-127.4968729",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPHY",
    portName: "Port Hardy (CAPHY), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "44.0073128",
    lng: "-77.14236919999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPIC",
    portName: "Picton (CAPIC), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "50.590142",
    lng: "-127.084755",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPMA",
    portName: "Port Mcneill (CAPMA), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "49.818815",
    lng: "-64.352681",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPME",
    portName: "Port Menier (CAPME), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "CAPOW",
    portName: "Powell River (CAPOW), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "47.6569684",
    lng: "-70.152187",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPPC",
    portName: "Pointe-au-Pic Harbor",
    portContinent: "North America",
  },
  {
    lat: "44.7486706",
    lng: "-92.80216229999999",
    country: "US",
    country_long: "United States",
    portCode: "CAPRE",
    portName: "Port of Prescott",
    portContinent: "North America",
  },
  {
    lat: "53.9170641",
    lng: "-122.7496693",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPRG",
    portName: "Prince George (CAPRG), Prince George, Canada",
    portContinent: "North America",
  },
  {
    lat: "54.3185914",
    lng: "-130.3184417",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPRR",
    portName: "Prince Rupert Port",
    portContinent: "North America",
  },
  {
    lat: "45.3272077",
    lng: "-80.0441439",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPRS",
    portName: "Parry Sound Harbour",
    portContinent: "North America",
  },
  {
    lat: "42.6641353",
    lng: "-81.21563290000002",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPST",
    portName: "Port Stanley",
    portContinent: "North America",
  },
  {
    lat: "44.7687678",
    lng: "-79.93368099999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPTH",
    portName: "Penetang (CAPTH), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CAPTN",
    portName: "Portneuf Harbor",
    portContinent: "North America",
  },
  {
    lat: "45.679332",
    lng: "-62.720603",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPTO",
    portName: "Pictou (CAPTO), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "43.2211063",
    lng: "-79.2140972",
    country: "CA",
    country_long: "Canada",
    portCode: "CAPWE",
    portName: "Port Weller",
    portContinent: "North America",
  },
  {
    lat: "46.8210729",
    lng: "-71.2066107",
    country: "CA",
    country_long: "Canada",
    portCode: "CAQUE",
    portName: "Port of Quebec",
    portContinent: "North America",
  },
  {
    lat: "50.4452112",
    lng: "-104.6188944",
    country: "CA",
    country_long: "Canada",
    portCode: "CAREG",
    portName: "Regina",
    portContinent: "North America",
  },
  {
    lat: "48.4389803",
    lng: "-68.5349704",
    country: "CA",
    country_long: "Canada",
    portCode: "CARIM",
    portName: "Rimouski Harbor",
    portContinent: "North America",
  },
  {
    lat: "49.06666670000001",
    lng: "-123.1833333",
    country: "CA",
    country_long: "Canada",
    portCode: "CARTB",
    portName: "Roberts Bank (CARTB), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "52.157902",
    lng: "-106.6701577",
    country: "CA",
    country_long: "Canada",
    portCode: "CASAK",
    portName: "Saskatoon (CASAK), Saskatoon, Canada",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CASAT",
    portName: "Saint-Anthony",
    portContinent: "North America",
  },
  {
    lat: "43.1593745",
    lng: "-79.2468626",
    country: "CA",
    country_long: "Canada",
    portCode: "CASCA",
    portName: "Port of St. Catharines",
    portContinent: "North America",
  },
  {
    lat: "50.1993713",
    lng: "-66.3803123",
    country: "CA",
    country_long: "Canada",
    portCode: "CASEI",
    portName: "Port of Sept-Iles",
    portContinent: "North America",
  },
  {
    lat: "43.4986869",
    lng: "-65.69978",
    country: "CA",
    country_long: "Canada",
    portCode: "CASGH",
    portName: "Shag Harbour (CASGH), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "CASJB",
    portName: "Saint John (CASJB), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "45.305771",
    lng: "-73.2544903",
    country: "CA",
    country_long: "Canada",
    portCode: "CASJD",
    portName: "Saint Jean (CASJD), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "56.130366",
    lng: "-106.346771",
    country: "CA",
    country_long: "Canada",
    portCode: "CASJF",
    portName: "St Johns (CASJF), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "46.8138783",
    lng: "-71.2079809",
    country: "CA",
    country_long: "Canada",
    portCode: "CASJT",
    portName: "Scott Junction (CASJT), Quebec City, Canada",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CASLA",
    portName: "SAINT-LAURENT",
    portContinent: "North America",
  },
  {
    lat: "42.974536",
    lng: "-82.4065901",
    country: "CA",
    country_long: "Canada",
    portCode: "CASNI",
    portName: "Port of Sarnia",
    portContinent: "North America",
  },
  {
    lat: "46.042825",
    lng: "-73.112302",
    country: "CA",
    country_long: "Canada",
    portCode: "CASOR",
    portName: "Port of Sorel",
    portContinent: "North America",
  },
  {
    lat: "46.208526",
    lng: "-82.651843",
    country: "CA",
    country_long: "Canada",
    portCode: "CASPR",
    portName: "Spragge Harbor",
    portContinent: "North America",
  },
  {
    lat: "49.7016339",
    lng: "-123.1558121",
    country: "CA",
    country_long: "Canada",
    portCode: "CASQA",
    portName: "Squamish (CASQA), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CASRO",
    portName: "St. Romuald Harbor",
    portContinent: "North America",
  },
  {
    lat: "46.49771150000001",
    lng: "-84.3475876",
    country: "US",
    country_long: "United States",
    portCode: "CASSM",
    portName: "Saulte Ste Marie Harbor",
    portContinent: "North America",
  },
  {
    lat: "18.3380965",
    lng: "-64.8940946",
    country: "VI",
    country_long: "U.S. Virgin Islands",
    portCode: "CASTH",
    portName: "SAINT THOMAS",
    portContinent: "North America",
  },
  {
    lat: "46.39337769999999",
    lng: "-63.79023309999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CASUM",
    portName: "Summerside",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CASUR",
    portName: "SUMMER BEAVER",
    portContinent: "North America",
  },
  {
    lat: "45.950061",
    lng: "-73.083384",
    country: "CA",
    country_long: "Canada",
    portCode: "CASVD",
    portName: "Sainte Victoir (CASVD), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "45.2500909",
    lng: "-74.1330862",
    country: "CA",
    country_long: "Canada",
    portCode: "CASVF",
    portName: "salaberry de valleyfield",
    portContinent: "North America",
  },
  {
    lat: "49.1336586",
    lng: "-123.179328",
    country: "CA",
    country_long: "Canada",
    portCode: "CASVN",
    portName: "Steveston (CASVN), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "46.1367899",
    lng: "-60.19422399999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CASYD",
    portName: "Sydney Nova Scotia (CASYD), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "46.7708135",
    lng: "-71.26541999999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CASYQ",
    portName: "Sillery (CASYQ), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "48.14597759999999",
    lng: "-69.71283950000002",
    country: "CA",
    country_long: "Canada",
    portCode: "CATAD",
    portName: "Tadoussac Harbor",
    portContinent: "North America",
  },
  {
    lat: "43.1236091",
    lng: "-79.1989299",
    country: "CA",
    country_long: "Canada",
    portCode: "CATHD",
    portName: "Port of Thorold",
    portContinent: "North America",
  },
  {
    lat: "48.406626",
    lng: "-89.21731799999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CATHU",
    portName: "Thunder Bay Port Authority",
    portContinent: "North America",
  },
  {
    lat: "49.1427322",
    lng: "-123.0237182",
    country: "CA",
    country_long: "Canada",
    portCode: "CATLB",
    portName: "Tilbury Island (CATLB), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "43.653226",
    lng: "-79.3831843",
    country: "CA",
    country_long: "Canada",
    portCode: "CATOR",
    portName: "Port of Toronto",
    portContinent: "North America",
  },
  {
    lat: "56.130366",
    lng: "-106.346771",
    country: "CA",
    country_long: "Canada",
    portCode: "CATRR",
    portName: "Three Rivers (CATRR), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "48.9986363",
    lng: "-123.6812002",
    country: "CA",
    country_long: "Canada",
    portCode: "CATTL",
    portName: "Thetis Island (CATTL), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "49.018038",
    lng: "-123.0818253",
    country: "CA",
    country_long: "Canada",
    portCode: "CATWS",
    portName: "Tsawwassen (CATWS), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "48.9415997",
    lng: "-125.5463445",
    country: "CA",
    country_long: "Canada",
    portCode: "CAUCL",
    portName: "Ucluelet (CAUCL), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "49.2827291",
    lng: "-123.1207375",
    country: "CA",
    country_long: "Canada",
    portCode: "CAVAN",
    portName: "Port Metro Vancouver",
    portContinent: "North America",
  },
  {
    lat: "56.130366",
    lng: "-106.346771",
    country: "CA",
    country_long: "Canada",
    portCode: "CAVIC",
    portName: "Victoria (CAVIC), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "45.2245961",
    lng: "-74.0899374",
    country: "CA",
    country_long: "Canada",
    portCode: "CAVLF",
    portName: "Port of Valleyfield",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CAVST",
    portName: "SAINT LAURENT",
    portContinent: "North America",
  },
  {
    lat: "40.5575986",
    lng: "-74.2846022",
    country: "US",
    country_long: "United States",
    portCode: "CAWDB",
    portName: "WOODBRIDGE",
    portContinent: "North America",
  },
  {
    lat: "42.9921579",
    lng: "-79.2482555",
    country: "CA",
    country_long: "Canada",
    portCode: "CAWEL",
    portName: "Port of Welland",
    portContinent: "North America",
  },
  {
    lat: "56.130366",
    lng: "-106.346771",
    country: "CA",
    country_long: "Canada",
    portCode: "CAWHH",
    portName: "Whiffen Head (CAWHH), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "50.1351544",
    lng: "-97.3273965",
    country: "CA",
    country_long: "Canada",
    portCode: "CAWLL",
    portName: "Stonewall, MB",
    portContinent: "North America",
  },
  {
    lat: "42.3022582",
    lng: "-83.0757479",
    country: "CA",
    country_long: "Canada",
    portCode: "CAWND",
    portName: "Port Windsor",
    portContinent: "North America",
  },
  {
    lat: "49.895136",
    lng: "-97.13837439999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CAWNP",
    portName: "Winnipeg (CAWNP), Winnipeg, Canada",
    portContinent: "North America",
  },
  {
    lat: "49.667145",
    lng: "-123.251134",
    country: "CA",
    country_long: "Canada",
    portCode: "CAWOO",
    portName: "Woodfibre harbor",
    portContinent: "North America",
  },
  {
    lat: "49.3286251",
    lng: "-123.1601981",
    country: "CA",
    country_long: "Canada",
    portCode: "CAWVR",
    portName: "West Vancouver",
    portContinent: "North America",
  },
  {
    lat: "51.4264451",
    lng: "-57.1313148",
    country: "CA",
    country_long: "Canada",
    portCode: "CAYBX",
    portName: "Blanc Sablon (CAYBX), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "53.30624330000001",
    lng: "-113.5827895",
    country: "CA",
    country_long: "Canada",
    portCode: "CAYEG",
    portName: "Edmonton Int Apt",
    portContinent: "North America",
  },
  {
    lat: "62.416667",
    lng: "-77.91666699999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CAYIK",
    portName: "Ivujivik (CAYIK), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "60.02001319999999",
    lng: "-70.0161644",
    country: "CA",
    country_long: "Canada",
    portCode: "CAYKG",
    portName: "Kangirsuk (CAYKG), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "43.835205",
    lng: "-66.12239699999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CAYRH",
    portName: "Port of Yarmouth",
    portContinent: "North America",
  },
  {
    lat: "44.7419729",
    lng: "-81.14083440000002",
    country: "CA",
    country_long: "Canada",
    portCode: "CAYVV",
    portName: "Wiarton (CAYVV), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "61.59625999999999",
    lng: "-71.953886",
    country: "CA",
    country_long: "Canada",
    portCode: "CAYWB",
    portName: "Kangiqsujuaq (CAYWB), Canada, usa",
    portContinent: "North America",
  },
  {
    lat: "49.895136",
    lng: "-97.13837439999999",
    country: "CA",
    country_long: "Canada",
    portCode: "CAYWG",
    portName: "Winnipeg Apt",
    portContinent: "North America",
  },
  {
    lat: "51.1215031",
    lng: "-114.0076156",
    country: "CA",
    country_long: "Canada",
    portCode: "CAYYC",
    portName: "Calgary (CAYYC), Calgary, Canada",
    portContinent: "North America",
  },
  {
    lat: "43.67771760000001",
    lng: "-79.6248197",
    country: "CA",
    country_long: "Canada",
    portCode: "CAYYZ",
    portName: "PEARSON INTERNATIONAL APT/TORONTO",
    portContinent: "North America",
  },
  {
    lat: "-2.5123017",
    lng: "28.8480284",
    country: "CD",
    country_long: "Democratic Republic of the Congo",
    portCode: "CDBKY",
    portName: "BUKAVU (CDBKY), Bukavu, Democratic Republic of theÐšCongo",
    portContinent: null,
  },
  {
    lat: "-5.987164099999999",
    lng: "12.3893488",
    country: "CD",
    country_long: "Democratic Republic of the Congo",
    portCode: "CDBNW",
    portName: "Port of Banana",
    portContinent: null,
  },
  {
    lat: "-5.8396417",
    lng: "13.0702646",
    country: "CD",
    country_long: "Democratic Republic of the Congo",
    portCode: "CDBOA",
    portName: "Port of Boma",
    portContinent: null,
  },
  {
    lat: "0.114047",
    lng: "29.3018011",
    country: "CD",
    country_long: "Democratic Republic of the Congo",
    portCode: "CDBUT",
    portName: "Butembo",
    portContinent: "Africa",
  },
  {
    lat: "-11.6876026",
    lng: "27.5026174",
    country: "CD",
    country_long: "Democratic Republic of the Congo",
    portCode: "CDFBM",
    portName:
      "LUBUMBASHI (CDFBM), Lubumbashi, Democratic Republic of theÐšCongo",
    portContinent: null,
  },
  {
    lat: "-4.4419311",
    lng: "15.2662931",
    country: "CD",
    country_long: "Democratic Republic of the Congo",
    portCode: "CDFIH",
    portName: "KINSHASA (CDFIH), Kinshasa, Democratic Republic of theÐšCongo",
    portContinent: null,
  },
  {
    lat: "-1.658501",
    lng: "29.2204548",
    country: "CD",
    country_long: "Democratic Republic of the Congo",
    portCode: "CDGOM",
    portName: "Goma (CDGOM), Goma, Democratic Republic of theÐšCongo",
    portContinent: null,
  },
  {
    lat: "-10.7275273",
    lng: "25.5088914",
    country: "CD",
    country_long: "Democratic Republic of the Congo",
    portCode: "CDKWZ",
    portName: "KOLWEZI (CDKWZ), Kolwezi, Democratic Republic of theÐšCongo",
    portContinent: null,
  },
  {
    lat: "-10.9884148",
    lng: "26.7378585",
    country: "CD",
    country_long: "Democratic Republic of the Congo",
    portCode: "CDLKS",
    portName: "LIKASI",
    portContinent: null,
  },
  {
    lat: "2.1701072",
    lng: "21.4872092",
    country: "CD",
    country_long: "Democratic Republic of the Congo",
    portCode: "CDLS1",
    portName: "LIKASI (CDLS1), Lisala, Democratic Republic of theÐšCongo",
    portContinent: null,
  },
  {
    lat: "-5.8237994",
    lng: "13.4515381",
    country: "CD",
    country_long: "Democratic Republic of the Congo",
    portCode: "CDMAT",
    portName: "Port of Matadi",
    portContinent: null,
  },
  {
    lat: "4.3946735",
    lng: "18.5581899",
    country: "CF",
    country_long: "Central African Republic",
    portCode: "CFBGF",
    portName: "BANGUI (CFBGF), Bangui, Central African Republic",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CGNKO",
    portName: "N'Kossa Terminal",
    portContinent: "Africa",
  },
  {
    lat: "-4.7888303",
    lng: "11.8307311",
    country: "CG",
    country_long: "Republic of the Congo",
    portCode: "CGPNR",
    portName: "Port of Pointe Noire",
    portContinent: "Africa",
  },
  {
    lat: "47.5183198",
    lng: "7.6919889",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHATT",
    portName: "PRATTELN",
    portContinent: "Europe",
  },
  {
    lat: "47.5516505",
    lng: "7.627249900000001",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHBFL",
    portName: "Birsfelden (CHBFL), Switzerland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.9479739",
    lng: "7.4474468",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHBRN",
    portName: "Bern",
    portContinent: "Europe",
  },
  {
    lat: "47.5595986",
    lng: "7.5885761",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHBSL",
    portName: "Basel (CHBSL), Switzerland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.1761539",
    lng: "0.9726119999999999",
    country: "FR",
    country_long: "France",
    portCode: "CHBSS",
    portName: "BOUSSENS",
    portContinent: "Europe",
  },
  {
    lat: "45.8366999",
    lng: "9.0246111",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHCHI",
    portName: "CHIASSO",
    portContinent: "Europe",
  },
  {
    lat: "47.2128386",
    lng: "7.9893353",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHDAE",
    portName: "DAGMERSELLEN",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CHECB",
    portName: "ESCHENBACH",
    portContinent: "Europe",
  },
  {
    lat: "47.5035123",
    lng: "7.7131296",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHFKD",
    portName: "FRENKENDORF (CHFKD), Switzerland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.2369794",
    lng: "6.109088799999999",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHGVA",
    portName: "Geneva (CHGVA), Geneva, Switzerland",
    portContinent: "Europe",
  },
  {
    lat: "47.54033",
    lng: "8.04439",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHKAI",
    portName: "KAISTEN",
    portContinent: "Europe",
  },
  {
    lat: "46.5196535",
    lng: "6.6322734",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHLAU",
    portName: "Lausanne",
    portContinent: "Europe",
  },
  {
    lat: "47.56812430000001",
    lng: "8.3521038",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHME1",
    portName: "MELLIKON, AG (CHME1), Switzerland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.49048570000001",
    lng: "8.5033814",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHNTT",
    portName: "NIEDERGLATT (CHNTT), Switzerland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.4244818",
    lng: "9.3767173",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHQGL",
    portName: "ST. GALLEN",
    portContinent: "Europe",
  },
  {
    lat: "47.4906819",
    lng: "7.5894365",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHRCH",
    portName: "REINACH",
    portContinent: "Europe",
  },
  {
    lat: "47.55219",
    lng: "7.7922914",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHRFD",
    portName: "Rheinfelden (CHRFD), Switzerland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.691887",
    lng: "6.9118086",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHROM",
    portName: "ROMONT",
    portContinent: "Europe",
  },
  {
    lat: "46.215764",
    lng: "6.0332425",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHSAT",
    portName: "SATIGNY",
    portContinent: "Europe",
  },
  {
    lat: "47.1788688",
    lng: "8.130370899999999",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHSEN",
    portName: "SCHENKON",
    portContinent: "Europe",
  },
  {
    lat: "47.520225",
    lng: "8.5952819",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHZET",
    portName: "EMBRACH - EMBRAPORT",
    portContinent: "Europe",
  },
  {
    lat: "46.2043907",
    lng: "6.1431577",
    country: "CH",
    country_long: "Switzerland",
    portCode: "CHZRH",
    portName: "Zurich (CHZRH), Geneva, Switzerland",
    portContinent: "Europe",
  },
  {
    lat: "5.304186400000001",
    lng: "-4.0230708",
    country: "CI",
    country_long: "Côte d'Ivoire",
    portCode: "CIABJ",
    portName: "Port of Abidjan",
    portContinent: "Africa",
  },
  {
    lat: "5.2772518",
    lng: "-3.8858955",
    country: "CI",
    country_long: "Côte d'Ivoire",
    portCode: "CIPBT",
    portName: "Port Bouet Tanker Terminal",
    portContinent: "Africa",
  },
  {
    lat: "33.73954589643058",
    lng: "-118.2599686241527",
    country: "US",
    country_long: "United States",
    portCode: "CISPY",
    portName: "Port of San Pedro",
    portContinent: "Africa",
  },
  {
    lat: "4.951276099999999",
    lng: "-6.0917517",
    country: "CI",
    country_long: "Côte d'Ivoire",
    portCode: "CIZSS",
    portName: "Sassandra (CIZSS), Cote d'Ivoire, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-18.8579523",
    lng: "-159.7852843",
    country: "CK",
    country_long: "Cook Islands",
    portCode: "CKAIT",
    portName: "Aitutaki",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "CKRAR",
    portName: "Rarotonga (CKRAR), Australia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-23.6509279",
    lng: "-70.39750219999999",
    country: "CL",
    country_long: "Chile",
    portCode: "CLANF",
    portName: "Port of Antofagasta",
    portContinent: "South America",
  },
  {
    lat: "-18.4753074",
    lng: "-70.3215866",
    country: "CL",
    country_long: "Chile",
    portCode: "CLARI",
    portName: "Port of Arica",
    portContinent: "South America",
  },
  {
    lat: "-35.675147",
    lng: "-71.542969",
    country: "CL",
    country_long: "Chile",
    portCode: "CLCAA",
    portName: "Calderilla (CLCAA), Chile, Sam",
    portContinent: "South America",
  },
  {
    lat: "-41.330389",
    lng: "-72.6087158",
    country: "CL",
    country_long: "Chile",
    portCode: "CLCBC",
    portName: "Port of Calbuco",
    portContinent: "South America",
  },
  {
    lat: "-41.83035210000001",
    lng: "-73.52551729999999",
    country: "CL",
    country_long: "Chile",
    portCode: "CLCCA",
    portName: "Chacao (CLCCA), Chile, Sam",
    portContinent: "South America",
  },
  {
    lat: "10.3932277",
    lng: "-75.4832311",
    country: "CO",
    country_long: "Colombia",
    portCode: "CLCGN",
    portName: "CARTAGENA",
    portContinent: "South America",
  },
  {
    lat: "-34.6420841",
    lng: "-60.4715188",
    country: "AR",
    country_long: "Argentina",
    portCode: "CLCHB",
    portName: "Chacabuco",
    portContinent: "South America",
  },
  {
    lat: "-55.0360844",
    lng: "-69.2545973",
    country: "CL",
    country_long: "Chile",
    portCode: "CLCHR",
    portName: "Cabo De Hornos (CLCHR), Chile, Sam",
    portContinent: "South America",
  },
  {
    lat: "9.9400553",
    lng: "-84.7276382",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CLCLD",
    portName: "Port of Caldera",
    portContinent: "South America",
  },
  {
    lat: "-52.8898373",
    lng: "-70.13811729999999",
    country: "CL",
    country_long: "Chile",
    portCode: "CLCLR",
    portName: "Port of Caleta Clarencia",
    portContinent: "South America",
  },
  {
    lat: "-37.0264229",
    lng: "-73.1493969",
    country: "CL",
    country_long: "Chile",
    portCode: "CLCNL",
    portName: "Port of Coronel",
    portContinent: "South America",
  },
  {
    lat: "-26.3450046",
    lng: "-70.624193",
    country: "CL",
    country_long: "Chile",
    portCode: "CLCNR",
    portName: "Port of Chanaral",
    portContinent: "South America",
  },
  {
    lat: "35.6627774",
    lng: "-5.294569399999999",
    country: "MA",
    country_long: "Morocco",
    portCode: "CLCNX",
    portName: "Port of Cabo Negro",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CLCOL",
    portName: "Port of Caleta Coloso",
    portContinent: "South America",
  },
  {
    lat: "-32.9299468",
    lng: "-71.51860649999999",
    country: "CL",
    country_long: "Chile",
    portCode: "CLCON",
    portName: "Concon (CLCON), Chile, Sam",
    portContinent: "South America",
  },
  {
    lat: "-29.9590009",
    lng: "-71.3389183",
    country: "CL",
    country_long: "Chile",
    portCode: "CLCQQ",
    portName: "Port of Coquimbo",
    portContinent: "South America",
  },
  {
    lat: "-42.377631",
    lng: "-73.651882",
    country: "CL",
    country_long: "Chile",
    portCode: "CLDCH",
    portName: "Dalcahue (CLDCH), Chile, Sam",
    portContinent: "South America",
  },
  {
    lat: "-49.1262469",
    lng: "-74.4098484",
    country: "CL",
    country_long: "Chile",
    portCode: "CLEDE",
    portName: "Puerto Eden (CLEDE), Chile, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CLGYC",
    portName: "Port of Guayacan",
    portContinent: "South America",
  },
  {
    lat: "-28.4664",
    lng: "-71.2192",
    country: "CL",
    country_long: "Chile",
    portCode: "CLHSO",
    portName: "Port of Huasco",
    portContinent: "South America",
  },
  {
    lat: "-27.112723",
    lng: "-109.3496865",
    country: "CL",
    country_long: "Chile",
    portCode: "CLIPC",
    portName: "Port of Easter Island",
    portContinent: "South America",
  },
  {
    lat: "-20.2307033",
    lng: "-70.1356692",
    country: "CL",
    country_long: "Chile",
    portCode: "CLIQQ",
    portName: "Port of Iquique",
    portContinent: "South America",
  },
  {
    lat: "-33.0153481",
    lng: "-71.55002759999999",
    country: "CL",
    country_long: "Chile",
    portCode: "CLKNA",
    portName: "VINA DEL MAR",
    portContinent: "South America",
  },
  {
    lat: "-36.7060687",
    lng: "-72.9767406",
    country: "CL",
    country_long: "Chile",
    portCode: "CLLQN",
    portName: "Port of Lirquen",
    portContinent: "South America",
  },
  {
    lat: "-29.9026691",
    lng: "-71.2519374",
    country: "CL",
    country_long: "Chile",
    portCode: "CLLSC",
    portName: "LA SERENA",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CLLSQ",
    portName: "LOS ï¿½NGELES",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CLLTA",
    portName: "Port of Lota",
    portContinent: "South America",
  },
  {
    lat: "-23.0985013",
    lng: "-70.4455041",
    country: "CL",
    country_long: "Chile",
    portCode: "CLMJS",
    portName: "Port of Mejillones",
    portContinent: "South America",
  },
  {
    lat: "-23.5871061",
    lng: "-70.3897447",
    country: "CL",
    country_long: "Chile",
    portCode: "CLPAG",
    portName: "Puerto Angamos (CLPAG), Antofagasta, Chile",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CLPCH",
    portName: "Port of Chacabuco",
    portContinent: "South America",
  },
  {
    lat: "-36.7333",
    lng: "-72.9833",
    country: "CL",
    country_long: "Chile",
    portCode: "CLPEO",
    portName: "Penco (CLPEO), Chile, Sam",
    portContinent: "South America",
  },
  {
    lat: "-41.791729",
    lng: "-73.4650359",
    country: "CL",
    country_long: "Chile",
    portCode: "CLPGA",
    portName: "Pargua (CLPGA), Chile, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CLPMC",
    portName: "Port of Montt",
    portContinent: "South America",
  },
  {
    lat: "-51.7308935",
    lng: "-72.4977407",
    country: "CL",
    country_long: "Chile",
    portCode: "CLPNT",
    portName: "Port of Puerto Natales",
    portContinent: "South America",
  },
  {
    lat: "-52.8734543",
    lng: "-70.11941449999999",
    country: "CL",
    country_long: "Chile",
    portCode: "CLPPY",
    portName: "Port of Puerto Percy",
    portContinent: "South America",
  },
  {
    lat: "-43.1169327",
    lng: "-73.6138821",
    country: "CL",
    country_long: "Chile",
    portCode: "CLPTE",
    portName: "Quellon (CLPTE), Chile, Sam",
    portContinent: "South America",
  },
  {
    lat: "30.0555834",
    lng: "-94.1139812",
    country: "US",
    country_long: "United States",
    portCode: "CLPTI",
    portName: "Port of Patillos Cove",
    portContinent: "South America",
  },
  {
    lat: "-53.1638329",
    lng: "-70.9170683",
    country: "CL",
    country_long: "Chile",
    portCode: "CLPUQ",
    portName: "Port of Punta Arenas",
    portContinent: "South America",
  },
  {
    lat: "-42.1447134",
    lng: "-73.4780559",
    country: "CL",
    country_long: "Chile",
    portCode: "CLQMC",
    portName: "Port of Quemchi",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CLQTV",
    portName: "Port of Quintero",
    portContinent: "South America",
  },
  {
    lat: "-43.1169327",
    lng: "-73.6138821",
    country: "CL",
    country_long: "Chile",
    portCode: "CLQUV",
    portName: "Port of Quellon",
    portContinent: "South America",
  },
  {
    lat: "-33.5922807",
    lng: "-71.6055123",
    country: "CL",
    country_long: "Chile",
    portCode: "CLSAI",
    portName: "Puerto San Antonio",
    portContinent: "South America",
  },
  {
    lat: "-33.4488897",
    lng: "-70.6692655",
    country: "CL",
    country_long: "Chile",
    portCode: "CLSCL",
    portName: "Santiago",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CLSGR",
    portName: "Port of San Gregorio",
    portContinent: "South America",
  },
  {
    lat: "18.5094341",
    lng: "122.1496664",
    country: "PH",
    country_long: "Philippines",
    portCode: "CLSVE",
    portName: "Port of San Vicente",
    portContinent: "South America",
  },
  {
    lat: "-36.7247834",
    lng: "-73.1169808",
    country: "CL",
    country_long: "Chile",
    portCode: "CLTAL",
    portName: "Port of Talcahuano",
    portContinent: "South America",
  },
  {
    lat: "-22.0886778",
    lng: "-70.1960541",
    country: "CL",
    country_long: "Chile",
    portCode: "CLTOQ",
    portName: "Port of Tocopilla",
    portContinent: "South America",
  },
  {
    lat: "-47.8015353",
    lng: "-73.5358494",
    country: "CL",
    country_long: "Chile",
    portCode: "CLTOR",
    portName: "Tortel (CLTOR), Chile, Sam",
    portContinent: "South America",
  },
  {
    lat: "-25.406494",
    lng: "-70.4803027",
    country: "CL",
    country_long: "Chile",
    portCode: "CLTTC",
    portName: "Port of Taltal",
    portContinent: "South America",
  },
  {
    lat: "-33.0341902",
    lng: "-71.6292563",
    country: "CL",
    country_long: "Chile",
    portCode: "CLVAP",
    portName: "Empresa Portuaria Valparaiso",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CLWCA",
    portName: "Port of Castro",
    portContinent: "South America",
  },
  {
    lat: "-42.918234",
    lng: "-72.7095437",
    country: "CL",
    country_long: "Chile",
    portCode: "CLWCH",
    portName: "Chaiten (CLWCH), Chile, Sam",
    portContinent: "South America",
  },
  {
    lat: "45.0979976",
    lng: "-64.4100234",
    country: "CA",
    country_long: "Canada",
    portCode: "CLWPU",
    portName: "Port of Port Williams",
    portContinent: "South America",
  },
  {
    lat: "-39.8173788",
    lng: "-73.24253329999999",
    country: "CL",
    country_long: "Chile",
    portCode: "CLZAL",
    portName: "Port of Valdivia",
    portContinent: "South America",
  },
  {
    lat: "-41.8675003",
    lng: "-73.8276965",
    country: "CL",
    country_long: "Chile",
    portCode: "CLZUD",
    portName: "Ancud (CLZUD), Chile, Sam",
    portContinent: "South America",
  },
  {
    lat: "4.0567587",
    lng: "9.6962247",
    country: "CM",
    country_long: "Cameroon",
    portCode: "CMDLA",
    portName: "Port of Douala",
    portContinent: "Africa",
  },
  {
    lat: "2.9405941",
    lng: "9.9101915",
    country: "CM",
    country_long: "Cameroon",
    portCode: "CMKBI",
    portName: "Kribi (CMKBI), Cameroon, Africa",
    portContinent: "Africa",
  },
  {
    lat: "7.369721999999999",
    lng: "12.354722",
    country: "CM",
    country_long: "Cameroon",
    portCode: "CMLIT",
    portName: "Limboh (CMLIT), Cameroon, Africa",
    portContinent: "Africa",
  },
  {
    lat: "3.8480325",
    lng: "11.5020752",
    country: "CM",
    country_long: "Cameroon",
    portCode: "CMYAO",
    portName: "YAOUNDE",
    portContinent: "Africa",
  },
  {
    lat: "3.211033",
    lng: "101.642303",
    country: "MY",
    country_long: "Malaysia",
    portCode: "CN",
    portName: "JINJANG",
    portContinent: "Asia",
  },
  {
    lat: "23.100882",
    lng: "113.460854",
    country: "CN",
    country_long: "China",
    portCode: "CN001",
    portName: "HUANGPU OLD PORT (WUCHONGKOU)",
    portContinent: "Asia",
  },
  {
    lat: "23.056647",
    lng: "113.527253",
    country: "CN",
    country_long: "China",
    portCode: "CN002",
    portName: "HUANGPU NEW PORT",
    portContinent: "Asia",
  },
  {
    lat: "40.3971199",
    lng: "116.68783",
    country: "CN",
    country_long: "China",
    portCode: "CN014",
    portName: "HUAIROU (HEBEI)",
    portContinent: "Asia",
  },
  {
    lat: "32.0607099",
    lng: "118.76295",
    country: "CN",
    country_long: "China",
    portCode: "CN017",
    portName: "JIXING (JIANGSU)",
    portContinent: "Asia",
  },
  {
    lat: "-21.178986",
    lng: "-175.198242",
    country: "TO",
    country_long: "Tonga",
    portCode: "CN042",
    portName: "TONGAN",
    portContinent: "Asia",
  },
  {
    lat: "25.30208",
    lng: "116.46029",
    country: "CN",
    country_long: "China",
    portCode: "CN043",
    portName: "TONGXIAN",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CN555",
    portName: "TONGYU",
    portContinent: "Asia",
  },
  {
    lat: "33.18926",
    lng: "120.10346",
    country: "CN",
    country_long: "China",
    portCode: "CNAGG",
    portName: "DAGANG",
    portContinent: "Asia",
  },
  {
    lat: "33.551052",
    lng: "119.113185",
    country: "CN",
    country_long: "China",
    portCode: "CNAIN",
    portName: "Port of Huaiyin",
    portContinent: "Asia",
  },
  {
    lat: "35.86166",
    lng: "104.195397",
    country: "CN",
    country_long: "China",
    portCode: "CNAJG",
    portName: "Aojiang (CNAJG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.6848599",
    lng: "109.02932",
    country: "CN",
    country_long: "China",
    portCode: "CNAKA",
    portName: "Ankang",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNALI",
    portName: "Dali",
    portContinent: "Asia",
  },
  {
    lat: "30.6382399",
    lng: "119.68219",
    country: "CN",
    country_long: "China",
    portCode: "CNAN1",
    portName: "ANJI (CNAN1), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNANJ",
    portName: "Anji",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNANS",
    portName: "An Shun",
    portContinent: "Asia",
  },
  {
    lat: "27.2388999",
    lng: "111.4677",
    country: "CN",
    country_long: "China",
    portCode: "CNAOY",
    portName: "Shaoyang",
    portContinent: "Asia",
  },
  {
    lat: "30.5429399",
    lng: "117.06354",
    country: "CN",
    country_long: "China",
    portCode: "CNAQG",
    portName: "Anqing Pt",
    portContinent: "Asia",
  },
  {
    lat: "41.1077699",
    lng: "122.9946",
    country: "CN",
    country_long: "China",
    portCode: "CNASN",
    portName: "An Shan",
    portContinent: "Asia",
  },
  {
    lat: "22.7856599",
    lng: "115.3751399",
    country: "CN",
    country_long: "China",
    portCode: "CNASW",
    portName: "Aoshanwei",
    portContinent: "Asia",
  },
  {
    lat: "36.097524",
    lng: "114.3930629",
    country: "CN",
    country_long: "China",
    portCode: "CNAYN",
    portName: "ANYANG",
    portContinent: "Asia",
  },
  {
    lat: "36.097524",
    lng: "114.3930629",
    country: "CN",
    country_long: "China",
    portCode: "CNAYS",
    portName: "Anyang",
    portContinent: "Asia",
  },
  {
    lat: "45.6195999",
    lng: "122.83871",
    country: "CN",
    country_long: "China",
    portCode: "CNBAI",
    portName: "Bai Cheng",
    portContinent: "Asia",
  },
  {
    lat: "25.1120499",
    lng: "99.16181",
    country: "CN",
    country_long: "China",
    portCode: "CNBAN",
    portName: "Bao Shan",
    portContinent: "Asia",
  },
  {
    lat: "40.2267499",
    lng: "122.12169",
    country: "CN",
    country_long: "China",
    portCode: "CNBAY",
    portName: "Bayuquan (CNBAY), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.8739599",
    lng: "115.46459",
    country: "CN",
    country_long: "China",
    portCode: "CNBDZ",
    portName: "BAODING",
    portContinent: "Asia",
  },
  {
    lat: "22.917095",
    lng: "113.034016",
    country: "CN",
    country_long: "China",
    portCode: "CNBEC",
    portName: "BEICUN",
    portContinent: "Asia",
  },
  {
    lat: "29.8988899",
    lng: "121.84431",
    country: "CN",
    country_long: "China",
    portCode: "CNBEI",
    portName: "Beilun",
    portContinent: "Asia",
  },
  {
    lat: "41.2941299",
    lng: "123.7668599",
    country: "CN",
    country_long: "China",
    portCode: "CNBEN",
    portName: "BEN XI CITY",
    portContinent: "Asia",
  },
  {
    lat: "32.9154799",
    lng: "117.38932",
    country: "CN",
    country_long: "China",
    portCode: "CNBFU",
    portName: "Bengbu",
    portContinent: "Asia",
  },
  {
    lat: "30.2066599",
    lng: "114.90143",
    country: "CN",
    country_long: "China",
    portCode: "CNBHT",
    portName: "Tieshan (CNBHT), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "21.4811199",
    lng: "109.12008",
    country: "CN",
    country_long: "China",
    portCode: "CNBHY",
    portName: "Beihai (CNBHY), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.917095",
    lng: "113.034016",
    country: "CN",
    country_long: "China",
    portCode: "CNBIC",
    portName: "BEICUN",
    portContinent: "Asia",
  },
  {
    lat: "21.4811199",
    lng: "109.12008",
    country: "CN",
    country_long: "China",
    portCode: "CNBIH",
    portName: "Beihai Pt",
    portContinent: "Asia",
  },
  {
    lat: "22.928305",
    lng: "113.207187",
    country: "CN",
    country_long: "China",
    portCode: "CNBIJ",
    portName: "Beijiao",
    portContinent: "Asia",
  },
  {
    lat: "37.3821099",
    lng: "117.97279",
    country: "CN",
    country_long: "China",
    portCode: "CNBIN",
    portName: "Binzhou",
    portContinent: "Asia",
  },
  {
    lat: "22.928305",
    lng: "113.207187",
    country: "CN",
    country_long: "China",
    portCode: "CNBJO",
    portName: "Beijiao",
    portContinent: "Asia",
  },
  {
    lat: "39.904211",
    lng: "116.407395",
    country: "CN",
    country_long: "China",
    portCode: "CNBJS",
    portName: "Beijing (CNBJS), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "-8.4095178",
    lng: "115.188916",
    country: "ID",
    country_long: "Indonesia",
    portCode: "CNBLI",
    portName: "Bali",
    portContinent: "Asia",
  },
  {
    lat: "22.4166369",
    lng: "113.3226503",
    country: "CN",
    country_long: "China",
    portCode: "CNBNF",
    portName: "Banfu",
    portContinent: "Asia",
  },
  {
    lat: "34.3631699",
    lng: "107.23775",
    country: "CN",
    country_long: "China",
    portCode: "CNBOJ",
    portName: "Baoji",
    portContinent: "Asia",
  },
  {
    lat: "22.5532899",
    lng: "113.88308",
    country: "CN",
    country_long: "China",
    portCode: "CNBON",
    portName: "BAO'AN",
    portContinent: "Asia",
  },
  {
    lat: "23.1196099",
    lng: "113.88385",
    country: "CN",
    country_long: "China",
    portCode: "CNBOO",
    portName: "BOLUO",
    portContinent: "Asia",
  },
  {
    lat: "40.6578099",
    lng: "109.84021",
    country: "CN",
    country_long: "China",
    portCode: "CNBOT",
    portName: "Baotou",
    portContinent: "Asia",
  },
  {
    lat: "33.8446099",
    lng: "115.77931",
    country: "CN",
    country_long: "China",
    portCode: "CNBOZ",
    portName: "Bozhou",
    portContinent: "Asia",
  },
  {
    lat: "23.9021599",
    lng: "106.61838",
    country: "CN",
    country_long: "China",
    portCode: "CNBSE",
    portName: "Bai Se",
    portContinent: "Asia",
  },
  {
    lat: "41.9440999",
    lng: "126.41433",
    country: "CN",
    country_long: "China",
    portCode: "CNBSH",
    portName: "Baishan",
    portContinent: "Asia",
  },
  {
    lat: "19.0914",
    lng: "108.67024",
    country: "CN",
    country_long: "China",
    portCode: "CNBSP",
    portName: "Basuo",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNBUJ",
    portName: "Buji",
    portContinent: "Asia",
  },
  {
    lat: "41.2941299",
    lng: "123.7668599",
    country: "CN",
    country_long: "China",
    portCode: "CNBXS",
    portName: "Benxi",
    portContinent: "Asia",
  },
  {
    lat: "34.3586",
    lng: "112.41484",
    country: "CN",
    country_long: "China",
    portCode: "CNBYU",
    portName: "Baiyuan",
    portContinent: "Asia",
  },
  {
    lat: "41.5946499",
    lng: "121.79854",
    country: "CN",
    country_long: "China",
    portCode: "CNBZN",
    portName: "Bei Zhen",
    portContinent: "Asia",
  },
  {
    lat: "23.1290799",
    lng: "113.26436",
    country: "CN",
    country_long: "China",
    portCode: "CNCAN",
    portName: "Guangzhou (CNCAN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.8255299",
    lng: "115.54214",
    country: "CN",
    country_long: "China",
    portCode: "CNCAO",
    portName: "Caoxian",
    portContinent: "Asia",
  },
  {
    lat: "29.0315799",
    lng: "111.69854",
    country: "CN",
    country_long: "China",
    portCode: "CNCDE",
    portName: "Changde (CNCDE), Changde, China",
    portContinent: "Asia",
  },
  {
    lat: "37.9213499",
    lng: "120.73645",
    country: "CN",
    country_long: "China",
    portCode: "CNCDO",
    portName: "Changdao (CNCDO), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "30.5722599",
    lng: "104.0665099",
    country: "CN",
    country_long: "China",
    portCode: "CNCDU",
    portName: "CHENGDU",
    portContinent: "Asia",
  },
  {
    lat: "39.2731299",
    lng: "118.46023",
    country: "CN",
    country_long: "China",
    portCode: "CNCFD",
    portName: "Caofeidian (CNCFD), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.8688499",
    lng: "116.14437",
    country: "CN",
    country_long: "China",
    portCode: "CNCFU",
    portName: "Fucheng",
    portContinent: "Asia",
  },
  {
    lat: "43.8160199",
    lng: "125.32357",
    country: "CN",
    country_long: "China",
    portCode: "CNCGC",
    portName: "Changchun",
    portContinent: "Asia",
  },
  {
    lat: "34.7472499",
    lng: "113.62493",
    country: "CN",
    country_long: "China",
    portCode: "CNCGO",
    portName: "Zhengzhou",
    portContinent: "Asia",
  },
  {
    lat: "43.8160199",
    lng: "125.32357",
    country: "CN",
    country_long: "China",
    portCode: "CNCGQ",
    portName: "CHANGCHUN (JILIN)",
    portContinent: "Asia",
  },
  {
    lat: "31.6538099",
    lng: "120.75224",
    country: "CN",
    country_long: "China",
    portCode: "CNCGS",
    portName: "Changshu Pt",
    portContinent: "Asia",
  },
  {
    lat: "31.6538099",
    lng: "120.75224",
    country: "CN",
    country_long: "China",
    portCode: "CNCGU",
    portName: "Changshu",
    portContinent: "Asia",
  },
  {
    lat: "22.3789499",
    lng: "107.36485",
    country: "CN",
    country_long: "China",
    portCode: "CNCGZ",
    portName: "Chong Zuo",
    portContinent: "Asia",
  },
  {
    lat: "23.4661299",
    lng: "116.75608",
    country: "CN",
    country_long: "China",
    portCode: "CNCHG",
    portName: "CHENGHAI",
    portContinent: "Asia",
  },
  {
    lat: "30.66469",
    lng: "117.4914191",
    country: "CN",
    country_long: "China",
    portCode: "CNCHI",
    portName: "CHIZHOU",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNCHN",
    portName: "CHANG'AN",
    portContinent: "Asia",
  },
  {
    lat: "31.6117499",
    lng: "117.85622",
    country: "CN",
    country_long: "China",
    portCode: "CNCHU",
    portName: "CHAOHU",
    portContinent: "Asia",
  },
  {
    lat: "39.867402",
    lng: "116.4882929",
    country: "CN",
    country_long: "China",
    portCode: "CNCHY",
    portName: "CHAOYANG",
    portContinent: "Asia",
  },
  {
    lat: "32.25342500000001",
    lng: "118.328321",
    country: "CN",
    country_long: "China",
    portCode: "CNCHZ",
    portName: "Chuzhou",
    portContinent: "Asia",
  },
  {
    lat: "36.1958099",
    lng: "113.11649",
    country: "CN",
    country_long: "China",
    portCode: "CNCIH",
    portName: "CHANGZHI",
    portContinent: "Asia",
  },
  {
    lat: "23.1317099",
    lng: "113.26627",
    country: "CN",
    country_long: "China",
    portCode: "CNCIV",
    portName: "Guandong/Civet  (CNCIV), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNCIX",
    portName: "Cixi",
    portContinent: "Asia",
  },
  {
    lat: "24.6737899",
    lng: "102.90819",
    country: "CN",
    country_long: "China",
    portCode: "CNCJI",
    portName: "CHENGJIANG",
    portContinent: "Asia",
  },
  {
    lat: "29.5656843",
    lng: "106.5511838",
    country: "CN",
    country_long: "China",
    portCode: "CNCKG",
    portName: "Chongqing",
    portContinent: "Asia",
  },
  {
    lat: "29.43609",
    lng: "113.15889",
    country: "CN",
    country_long: "China",
    portCode: "CNCLJ",
    portName: "CHENGLINGJI (CNCLJ), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "28.2277799",
    lng: "112.93886",
    country: "CN",
    country_long: "China",
    portCode: "CNCNA",
    portName: "Changsha",
    portContinent: "Asia",
  },
  {
    lat: "40.95149989999999",
    lng: "117.9634",
    country: "CN",
    country_long: "China",
    portCode: "CNCNG",
    portName: "Chengde",
    portContinent: "Asia",
  },
  {
    lat: "23.5483499",
    lng: "113.58646",
    country: "CN",
    country_long: "China",
    portCode: "CNCNH",
    portName: "CONGHUA",
    portContinent: "Asia",
  },
  {
    lat: "42.7861299",
    lng: "124.11092",
    country: "CN",
    country_long: "China",
    portCode: "CNCNT",
    portName: "Chang Tu",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNCNX",
    portName: "Xinxu",
    portContinent: "Asia",
  },
  {
    lat: "25.7706299",
    lng: "113.01485",
    country: "CN",
    country_long: "China",
    portCode: "CNCNZ",
    portName: "CHENZHOU",
    portContinent: "Asia",
  },
  {
    lat: "23.4624399",
    lng: "116.67809",
    country: "CN",
    country_long: "China",
    portCode: "CNCON",
    portName: "CHAOAN",
    portContinent: "Asia",
  },
  {
    lat: "23.6566999",
    lng: "116.62296",
    country: "CN",
    country_long: "China",
    portCode: "CNCOZ",
    portName: "Chaozhou (CNCOZ), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.9749",
    lng: "113.993",
    country: "CN",
    country_long: "China",
    portCode: "CNCPG",
    portName: "Changping (CNCPG), Changping, China",
    portContinent: "Asia",
  },
  {
    lat: "29.5656843",
    lng: "106.5511838",
    country: "CN",
    country_long: "China",
    portCode: "CNCQI",
    portName: "Chongqing",
    portContinent: "Asia",
  },
  {
    lat: "23.07617",
    lng: "113.86919",
    country: "CN",
    country_long: "China",
    portCode: "CNCSA",
    portName: "Chashan",
    portContinent: "Asia",
  },
  {
    lat: "28.2277799",
    lng: "112.93886",
    country: "CN",
    country_long: "China",
    portCode: "CNCSX",
    portName: "Changsha",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNCTN",
    portName: "Cuntan",
    portContinent: "Asia",
  },
  {
    lat: "30.5722599",
    lng: "104.0665099",
    country: "CN",
    country_long: "China",
    portCode: "CNCTU",
    portName: "Chengdu",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNCWN",
    portName: "Chiwan Pt",
    portContinent: "Asia",
  },
  {
    lat: "39.60026000000001",
    lng: "121.52359",
    country: "CN",
    country_long: "China",
    portCode: "CNCXD",
    portName: "Changxindao (CNCXD), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.0328799",
    lng: "101.54582",
    country: "CN",
    country_long: "China",
    portCode: "CNCXI",
    portName: "Chu Xiong",
    portContinent: "Asia",
  },
  {
    lat: "31.87164989999999",
    lng: "121.18179",
    country: "CN",
    country_long: "China",
    portCode: "CNCYH",
    portName: "Haimen",
    portContinent: "Asia",
  },
  {
    lat: "39.867402",
    lng: "116.4882929",
    country: "CN",
    country_long: "China",
    portCode: "CNCYN",
    portName: "Chao Yang",
    portContinent: "Asia",
  },
  {
    lat: "30.66469",
    lng: "117.4914191",
    country: "CN",
    country_long: "China",
    portCode: "CNCZ1",
    portName: "CHIZHOU (CNCZ1), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.8107199",
    lng: "119.97365",
    country: "CN",
    country_long: "China",
    portCode: "CNCZX",
    portName: "Changzhou Pt",
    portContinent: "Asia",
  },
  {
    lat: "38.3044099",
    lng: "116.83869",
    country: "CN",
    country_long: "China",
    portCode: "CNCZZ",
    portName: "Cangzhou",
    portContinent: "Asia",
  },
  {
    lat: "38.9139899",
    lng: "121.6147",
    country: "CN",
    country_long: "China",
    portCode: "CNDAL",
    portName: "Dalian",
    portContinent: "Asia",
  },
  {
    lat: "40.0763699",
    lng: "113.30001",
    country: "CN",
    country_long: "China",
    portCode: "CNDAT",
    portName: "Datong",
    portContinent: "Asia",
  },
  {
    lat: "31.2086399",
    lng: "107.46791",
    country: "CN",
    country_long: "China",
    portCode: "CNDAU",
    portName: "DAZHOU",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNDAW",
    portName: "Dawa",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNDAZ",
    portName: "Daze",
    portContinent: "Asia",
  },
  {
    lat: "22.541968",
    lng: "113.862346",
    country: "CN",
    country_long: "China",
    portCode: "CNDCB",
    portName: "Dachanbay",
    portContinent: "Asia",
  },
  {
    lat: "23.24171",
    lng: "111.53926",
    country: "CN",
    country_long: "China",
    portCode: "CNDCG",
    portName: "Ducheng",
    portContinent: "Asia",
  },
  {
    lat: "39.0535764",
    lng: "121.7746213",
    country: "CN",
    country_long: "China",
    portCode: "CNDDC",
    portName: "Dalian Development Area (CNDDC), Dalian, China",
    portContinent: "Asia",
  },
  {
    lat: "39.9997999",
    lng: "124.35601",
    country: "CN",
    country_long: "China",
    portCode: "CNDDG",
    portName: "Dandong (CNDDG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "44.5370899",
    lng: "125.70558",
    country: "CN",
    country_long: "China",
    portCode: "CNDEH",
    portName: "Dehui",
    portContinent: "Asia",
  },
  {
    lat: "30.5426999",
    lng: "119.97728",
    country: "CN",
    country_long: "China",
    portCode: "CNDEQ",
    portName: "DEQING",
    portContinent: "Asia",
  },
  {
    lat: "31.1267899",
    lng: "104.3979",
    country: "CN",
    country_long: "China",
    portCode: "CNDEY",
    portName: "Deyang",
    portContinent: "Asia",
  },
  {
    lat: "37.4354999",
    lng: "116.35927",
    country: "CN",
    country_long: "China",
    portCode: "CNDEZ",
    portName: "Dezhou",
    portContinent: "Asia",
  },
  {
    lat: "19.0961399",
    lng: "108.65367",
    country: "CN",
    country_long: "China",
    portCode: "CNDFA",
    portName: "Dongfang",
    portContinent: "Asia",
  },
  {
    lat: "33.2010699",
    lng: "120.50102",
    country: "CN",
    country_long: "China",
    portCode: "CNDFE",
    portName: "Dafeng (CNDFE), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.2010699",
    lng: "120.50102",
    country: "CN",
    country_long: "China",
    portCode: "CNDFG",
    portName: "Dafeng Pt",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNDFN",
    portName: "Dongfeng",
    portContinent: "Asia",
  },
  {
    lat: "38.9139899",
    lng: "121.6147",
    country: "CN",
    country_long: "China",
    portCode: "CNDFZ",
    portName: "Dalian Free Trade Zone (CNDFZ), Dalian, China",
    portContinent: "Asia",
  },
  {
    lat: "23.0206699",
    lng: "113.75179",
    country: "CN",
    country_long: "China",
    portCode: "CNDGG",
    portName: "Dong Guan (CNDGG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.0206699",
    lng: "113.75179",
    country: "CN",
    country_long: "China",
    portCode: "CNDGM",
    portName: "DONGGUAN CITY LIMITS",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNDGO",
    portName: "Dongguo",
    portContinent: "Asia",
  },
  {
    lat: "32.8669499",
    lng: "120.3205",
    country: "CN",
    country_long: "China",
    portCode: "CNDGT",
    portName: "DONGTAI",
    portContinent: "Asia",
  },
  {
    lat: "37.4336499",
    lng: "118.67466",
    country: "CN",
    country_long: "China",
    portCode: "CNDGY",
    portName: "Dongying (CNDGY), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.151731",
    lng: "113.697755",
    country: "CN",
    country_long: "China",
    portCode: "CNDJG",
    portName: "Dongjiang",
    portContinent: "Asia",
  },
  {
    lat: "35.58841",
    lng: "119.76184",
    country: "CN",
    country_long: "China",
    portCode: "CNDJK",
    portName: "Dongjiangkou",
    portContinent: "Asia",
  },
  {
    lat: "23.004366",
    lng: "113.675231",
    country: "CN",
    country_long: "China",
    portCode: "CNDJO",
    portName: "Daojiao",
    portContinent: "Asia",
  },
  {
    lat: "22.995838",
    lng: "113.933872",
    country: "CN",
    country_long: "China",
    portCode: "CNDKG",
    portName: "Dongkeng",
    portContinent: "Asia",
  },
  {
    lat: "38.9268986",
    lng: "121.6541167",
    country: "CN",
    country_long: "China",
    portCode: "CNDLC",
    portName: "Port of Dalian",
    portContinent: "Asia",
  },
  {
    lat: "24.3255257",
    lng: "109.4154583",
    country: "CN",
    country_long: "China",
    portCode: "CNDLG",
    portName: "Daliang, Liuzhou (CNDLG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.84196",
    lng: "113.25282",
    country: "CN",
    country_long: "China",
    portCode: "CNDLI",
    portName: "Daliang (CNDLI), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.899965",
    lng: "113.842223",
    country: "CN",
    country_long: "China",
    portCode: "CNDLN",
    portName: "Dailingshan",
    portContinent: "Asia",
  },
  {
    lat: "39.02658",
    lng: "121.71224",
    country: "CN",
    country_long: "China",
    portCode: "CNDLW",
    portName: "Dalianwan  (CNDLW), Dalian, China",
    portContinent: "Asia",
  },
  {
    lat: "28.0822199",
    lng: "121.1503399",
    country: "CN",
    country_long: "China",
    portCode: "CNDMY",
    portName: "Damaiyu",
    portContinent: "Asia",
  },
  {
    lat: "25.1719805",
    lng: "121.4433706",
    country: "TW",
    country_long: "Taiwan",
    portCode: "CNDNS",
    portName: "DANSHUI",
    portContinent: "Asia",
  },
  {
    lat: "36.9845473",
    lng: "128.3655186",
    country: "KR",
    country_long: "South Korea",
    portCode: "CNDNY",
    portName: "Danyang",
    portContinent: "Asia",
  },
  {
    lat: "23.051149",
    lng: "112.919228",
    country: "CN",
    country_long: "China",
    portCode: "CNDNZ",
    portName: "Danzao",
    portContinent: "Asia",
  },
  {
    lat: "22.2090699",
    lng: "113.29673",
    country: "CN",
    country_long: "China",
    portCode: "CNDOU",
    portName: "Doumen (CNDOU), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.597612",
    lng: "114.474621",
    country: "CN",
    country_long: "China",
    portCode: "CNDPG",
    portName: "DAPENG",
    portContinent: "Asia",
  },
  {
    lat: "46.58757989999999",
    lng: "125.10307",
    country: "CN",
    country_long: "China",
    portCode: "CNDQG",
    portName: "DAQING",
    portContinent: "Asia",
  },
  {
    lat: "23.7013199",
    lng: "117.42964",
    country: "CN",
    country_long: "China",
    portCode: "CNDSN",
    portName: "Dongshan (CNDSN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "44.3286999",
    lng: "84.88674999999999",
    country: "CN",
    country_long: "China",
    portCode: "CNDSZ",
    portName: "Dushanzi",
    portContinent: "Asia",
  },
  {
    lat: "43.3727799",
    lng: "128.23109",
    country: "CN",
    country_long: "China",
    portCode: "CNDUA",
    portName: "DUNHUA  (JILIN)",
    portContinent: "Asia",
  },
  {
    lat: "43.3727799",
    lng: "128.23109",
    country: "CN",
    country_long: "China",
    portCode: "CNDUH",
    portName: "Dunhua",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNDWA",
    portName: "Dawang",
    portContinent: "Asia",
  },
  {
    lat: "29.2894599",
    lng: "120.24191",
    country: "CN",
    country_long: "China",
    portCode: "CNDYA",
    portName: "DONGYANG",
    portContinent: "Asia",
  },
  {
    lat: "19.5209299",
    lng: "109.58069",
    country: "CN",
    country_long: "China",
    portCode: "CNDZO",
    portName: "Danzhou",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNENY",
    portName: "Yanan",
    portContinent: "Asia",
  },
  {
    lat: "22.1829399",
    lng: "112.30532",
    country: "CN",
    country_long: "China",
    portCode: "CNEPI",
    portName: "Enping",
    portContinent: "Asia",
  },
  {
    lat: "30.2721699",
    lng: "109.48817",
    country: "CN",
    country_long: "China",
    portCode: "CNESN",
    portName: "En Shi",
    portContinent: "Asia",
  },
  {
    lat: "30.3908499",
    lng: "114.89495",
    country: "CN",
    country_long: "China",
    portCode: "CNEZH",
    portName: "Ezhou",
    portContinent: "Asia",
  },
  {
    lat: "21.7691299",
    lng: "108.35342",
    country: "CN",
    country_long: "China",
    portCode: "CNFAN",
    portName: "Fangcheng Pt",
    portContinent: "Asia",
  },
  {
    lat: "39.7346299",
    lng: "116.19186",
    country: "CN",
    country_long: "China",
    portCode: "CNFAS",
    portName: "Fangshan",
    portContinent: "Asia",
  },
  {
    lat: "28.1591999",
    lng: "115.77121",
    country: "CN",
    country_long: "China",
    portCode: "CNFCE",
    portName: "Fengcheng (CNFCE), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.098701",
    lng: "113.23573",
    country: "CN",
    country_long: "China",
    portCode: "CNFCN",
    portName: "FANGCUN",
    portContinent: "Asia",
  },
  {
    lat: "22.74695",
    lng: "114.136944",
    country: "CN",
    country_long: "China",
    portCode: "CNFEN",
    portName: "Fenggang",
    portContinent: "Asia",
  },
  {
    lat: "37.977812",
    lng: "114.55141",
    country: "CN",
    country_long: "China",
    portCode: "CNFGN",
    portName: "FANGCUN (CNFGN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "39.8007399",
    lng: "116.1442",
    country: "CN",
    country_long: "China",
    portCode: "CNFGT",
    portName: "FENGTAI",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNFKC",
    portName: "Changan",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNFKU",
    portName: "Faku",
    portContinent: "Asia",
  },
  {
    lat: "29.7023899",
    lng: "107.38779",
    country: "CN",
    country_long: "China",
    portCode: "CNFLG",
    portName: "FULING (CNFLG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.67147",
    lng: "113.8227",
    country: "CN",
    country_long: "China",
    portCode: "CNFNG",
    portName: "FUYONG (CNFNG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "26.0742099",
    lng: "119.29647",
    country: "CN",
    country_long: "China",
    portCode: "CNFOC",
    portName: "Fuzhou",
    portContinent: "Asia",
  },
  {
    lat: "23.7361099",
    lng: "113.40979",
    country: "CN",
    country_long: "China",
    portCode: "CNFOG",
    portName: "Fogang",
    portContinent: "Asia",
  },
  {
    lat: "23.0218499",
    lng: "113.12192",
    country: "CN",
    country_long: "China",
    portCode: "CNFOS",
    portName: "Foshan (CNFOS), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.7202399",
    lng: "119.38411",
    country: "CN",
    country_long: "China",
    portCode: "CNFQG",
    portName: "Fuqing (CNFQG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.0218499",
    lng: "113.12192",
    country: "CN",
    country_long: "China",
    portCode: "CNFRT",
    portName: "Foshan New Pt",
    portContinent: "Asia",
  },
  {
    lat: "22.6674",
    lng: "113.34967",
    country: "CN",
    country_long: "China",
    portCode: "CNFSA",
    portName: "Fusha",
    portContinent: "Asia",
  },
  {
    lat: "41.87970989999999",
    lng: "123.95722",
    country: "CN",
    country_long: "China",
    portCode: "CNFSN",
    portName: "Fushun",
    portContinent: "Asia",
  },
  {
    lat: "37.8688499",
    lng: "116.14437",
    country: "CN",
    country_long: "China",
    portCode: "CNFUC",
    portName: "Fucheng",
    portContinent: "Asia",
  },
  {
    lat: "22.8458926",
    lng: "113.2533169",
    country: "CN",
    country_long: "China",
    portCode: "CNFUG",
    portName: "FUQING (CNFUG), Foshan, China",
    portContinent: "Asia",
  },
  {
    lat: "26.0998199",
    lng: "119.2965899",
    country: "CN",
    country_long: "China",
    portCode: "CNFUJ",
    portName: "Fujian",
    portContinent: "Asia",
  },
  {
    lat: "39.73204",
    lng: "121.7226",
    country: "CN",
    country_long: "China",
    portCode: "CNFUL",
    portName: "Fuzhoucheng (CNFUL), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.0218499",
    lng: "113.12192",
    country: "CN",
    country_long: "China",
    portCode: "CNFUS",
    portName: "Foshan",
    portContinent: "Asia",
  },
  {
    lat: "42.0216599",
    lng: "121.67011",
    country: "CN",
    country_long: "China",
    portCode: "CNFUX",
    portName: "Fu Xin",
    portContinent: "Asia",
  },
  {
    lat: "32.8896299",
    lng: "115.81495",
    country: "CN",
    country_long: "China",
    portCode: "CNFUY",
    portName: "Fuyang",
    portContinent: "Asia",
  },
  {
    lat: "26.0742099",
    lng: "119.29647",
    country: "CN",
    country_long: "China",
    portCode: "CNFUZ",
    portName: "Fuzhou",
    portContinent: "Asia",
  },
  {
    lat: "26.0742099",
    lng: "119.29647",
    country: "CN",
    country_long: "China",
    portCode: "CNFZH",
    portName: "Fuzhou",
    portContinent: "Asia",
  },
  {
    lat: "40.4005299",
    lng: "122.3488",
    country: "CN",
    country_long: "China",
    portCode: "CNGAI",
    portName: "GAIZHOU (=GAIXIAN)  (LIAONING)",
    portContinent: "Asia",
  },
  {
    lat: "32.3184099",
    lng: "119.88116",
    country: "CN",
    country_long: "China",
    portCode: "CNGAO",
    portName: "Gaogang (CNGAO), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "26.425",
    lng: "117.91067",
    country: "CN",
    country_long: "China",
    portCode: "CNGAS",
    portName: "Gaosha",
    portContinent: "Asia",
  },
  {
    lat: "31.92896",
    lng: "108.287856",
    country: "CN",
    country_long: "China",
    portCode: "CNGAX",
    portName: "Gaixian",
    portContinent: "Asia",
  },
  {
    lat: "23.0258099",
    lng: "112.45839",
    country: "CN",
    country_long: "China",
    portCode: "CNGAY",
    portName: "Gaoyao",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNGBT",
    portName: "Guangbaotong",
    portContinent: "Asia",
  },
  {
    lat: "23.09099",
    lng: "113.7459",
    country: "CN",
    country_long: "China",
    portCode: "CNGBU",
    portName: "Gaobu",
    portContinent: "Asia",
  },
  {
    lat: "41.36987",
    lng: "121.75757",
    country: "CN",
    country_long: "China",
    portCode: "CNGBZ",
    portName: "Goubangzi",
    portContinent: "Asia",
  },
  {
    lat: "47.966038",
    lng: "-1.796747",
    country: "FR",
    country_long: "France",
    portCode: "CNGCG",
    portName: "Guicheng",
    portContinent: "Asia",
  },
  {
    lat: "34.7538099",
    lng: "113.67739",
    country: "CN",
    country_long: "China",
    portCode: "CNGCH",
    portName: "Guancheng",
    portContinent: "Asia",
  },
  {
    lat: "23.0468999",
    lng: "112.46528",
    country: "CN",
    country_long: "China",
    portCode: "CNGGY",
    portName: "GONGYI (CNGGY), Zhaoqing, China",
    portContinent: "Asia",
  },
  {
    lat: "23.1290799",
    lng: "113.26436",
    country: "CN",
    country_long: "China",
    portCode: "CNGGZ",
    portName: "Guangzhou",
    portContinent: "Asia",
  },
  {
    lat: "21.96898",
    lng: "112.7978",
    country: "CN",
    country_long: "China",
    portCode: "CNGHI",
    portName: "Guanghai",
    portContinent: "Asia",
  },
  {
    lat: "30.9770599",
    lng: "104.28249",
    country: "CN",
    country_long: "China",
    portCode: "CNGHN",
    portName: "GUANGHAN",
    portContinent: "Asia",
  },
  {
    lat: "28.6741699",
    lng: "115.91004",
    country: "CN",
    country_long: "China",
    portCode: "CNGJS",
    portName: "Jiangsi",
    portContinent: "Asia",
  },
  {
    lat: "36.3321599",
    lng: "103.94751",
    country: "CN",
    country_long: "China",
    portCode: "CNGLA",
    portName: "Gaolan",
    portContinent: "Asia",
  },
  {
    lat: "23.83067",
    lng: "117.62253",
    country: "CN",
    country_long: "China",
    portCode: "CNGLE",
    portName: "Gulei",
    portContinent: "Asia",
  },
  {
    lat: "36.3321599",
    lng: "103.94751",
    country: "CN",
    country_long: "China",
    portCode: "CNGLN",
    portName: "Gaolan",
    portContinent: "Asia",
  },
  {
    lat: "23.230227",
    lng: "116.786441",
    country: "CN",
    country_long: "China",
    portCode: "CNGNA",
    portName: "Guangao",
    portContinent: "Asia",
  },
  {
    lat: "23.6349299",
    lng: "112.44112",
    country: "CN",
    country_long: "China",
    portCode: "CNGNG",
    portName: "Guangning",
    portContinent: "Asia",
  },
  {
    lat: "23.31457",
    lng: "113.22124",
    country: "CN",
    country_long: "China",
    portCode: "CNGNY",
    portName: "Guanyao",
    portContinent: "Asia",
  },
  {
    lat: "22.7489399",
    lng: "113.93588",
    country: "CN",
    country_long: "China",
    portCode: "CNGOG",
    portName: "GONGMING",
    portContinent: "Asia",
  },
  {
    lat: "37.65835990000001",
    lng: "126.8320201",
    country: "KR",
    country_long: "South Korea",
    portCode: "CNGOI",
    portName: "GOIYANG",
    portContinent: "Asia",
  },
  {
    lat: "22.9002599",
    lng: "112.89262",
    country: "CN",
    country_long: "China",
    portCode: "CNGOM",
    portName: "Gaoming Pt",
    portContinent: "Asia",
  },
  {
    lat: "21.929087",
    lng: "113.269064",
    country: "CN",
    country_long: "China",
    portCode: "CNGON",
    portName: "Gaolan (CNGON), Zhuhai, China",
    portContinent: "Asia",
  },
  {
    lat: "28.2861",
    lng: "112.78481",
    country: "CN",
    country_long: "China",
    portCode: "CNGSH",
    portName: "Guishan (CNGSH), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.1130599",
    lng: "109.59764",
    country: "CN",
    country_long: "China",
    portCode: "CNGUG",
    portName: "GUIGANG (CNGUG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.83067",
    lng: "117.62253",
    country: "CN",
    country_long: "China",
    portCode: "CNGUL",
    portName: "Gulei (CNGUL), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.72143",
    lng: "114.0548",
    country: "CN",
    country_long: "China",
    portCode: "CNGUN",
    portName: "GUANLAN",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNGUO",
    portName: "Guoji",
    portContinent: "Asia",
  },
  {
    lat: "23.1317099",
    lng: "113.26627",
    country: "CN",
    country_long: "China",
    portCode: "CNGUT",
    portName: "GUANGDONG",
    portContinent: "Asia",
  },
  {
    lat: "21.9181199",
    lng: "110.85338",
    country: "CN",
    country_long: "China",
    portCode: "CNGXC",
    portName: "Gaozhou",
    portContinent: "Asia",
  },
  {
    lat: "28.2925199",
    lng: "117.24525",
    country: "CN",
    country_long: "China",
    portCode: "CNGXI",
    portName: "Guixi",
    portContinent: "Asia",
  },
  {
    lat: "26.6475899",
    lng: "106.63019",
    country: "CN",
    country_long: "China",
    portCode: "CNGYA",
    portName: "Gui Yang",
    portContinent: "Asia",
  },
  {
    lat: "37.6149299",
    lng: "114.61148",
    country: "CN",
    country_long: "China",
    portCode: "CNGYI",
    portName: "Gaoyi",
    portContinent: "Asia",
  },
  {
    lat: "32.4354899",
    lng: "105.84357",
    country: "CN",
    country_long: "China",
    portCode: "CNGYS",
    portName: "Guangyuan",
    portContinent: "Asia",
  },
  {
    lat: "22.613406",
    lng: "113.190869",
    country: "CN",
    country_long: "China",
    portCode: "CNGZE",
    portName: "Guzhen (CNGZE), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.8310899",
    lng: "114.93476",
    country: "CN",
    country_long: "China",
    portCode: "CNGZH",
    portName: "Ganzhou",
    portContinent: "Asia",
  },
  {
    lat: "23.2609299",
    lng: "113.8109",
    country: "CN",
    country_long: "China",
    portCode: "CNGZP",
    portName: "Zengcheng",
    portContinent: "Asia",
  },
  {
    lat: "36.7764499",
    lng: "121.15846",
    country: "CN",
    country_long: "China",
    portCode: "CNHAD",
    portName: "Haiyang",
    portContinent: "Asia",
  },
  {
    lat: "28.6496499",
    lng: "121.26295",
    country: "CN",
    country_long: "China",
    portCode: "CNHAG",
    portName: "Huangyan",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNHAH",
    portName: "Haihe",
    portContinent: "Asia",
  },
  {
    lat: "22.9665699",
    lng: "115.32341",
    country: "CN",
    country_long: "China",
    portCode: "CNHAI",
    portName: "Haifeng",
    portContinent: "Asia",
  },
  {
    lat: "20.0442199",
    lng: "110.19989",
    country: "CN",
    country_long: "China",
    portCode: "CNHAK",
    portName: "Haikou (CNHAK), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNHAN",
    portName: "Hangyun",
    portContinent: "Asia",
  },
  {
    lat: "30.2741499",
    lng: "120.15515",
    country: "CN",
    country_long: "China",
    portCode: "CNHAZ",
    portName: "HANGZHOU",
    portContinent: "Asia",
  },
  {
    lat: "34.2784154",
    lng: "-85.1880666",
    country: "US",
    country_long: "United States",
    portCode: "CNHBG",
    portName: "Harbin Pt",
    portContinent: "Asia",
  },
  {
    lat: "33.9547899",
    lng: "116.7983399",
    country: "CN",
    country_long: "China",
    portCode: "CNHBI",
    portName: "HUAIBEI",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNHCH",
    portName: "He Chi",
    portContinent: "Asia",
  },
  {
    lat: "23.4032768",
    lng: "113.218478",
    country: "CN",
    country_long: "China",
    portCode: "CNHDG",
    portName: "Huadu Pt",
    portContinent: "Asia",
  },
  {
    lat: "36.6255599",
    lng: "114.53918",
    country: "CN",
    country_long: "China",
    portCode: "CNHDS",
    portName: "HANDAN",
    portContinent: "Asia",
  },
  {
    lat: "23.4032768",
    lng: "113.218478",
    country: "CN",
    country_long: "China",
    portCode: "CNHDU",
    portName: "Huadu pt",
    portContinent: "Asia",
  },
  {
    lat: "36.6255599",
    lng: "114.53918",
    country: "CN",
    country_long: "China",
    portCode: "CNHDX",
    portName: "HANDAN",
    portContinent: "Asia",
  },
  {
    lat: "37.0705499",
    lng: "114.50443",
    country: "CN",
    country_long: "China",
    portCode: "CNHEB",
    portName: "Hebei ,XING TAI",
    portContinent: "Asia",
  },
  {
    lat: "27.5777099",
    lng: "110.04098",
    country: "CN",
    country_long: "China",
    portCode: "CNHEC",
    portName: "Hecheng",
    portContinent: "Asia",
  },
  {
    lat: "50.2452299",
    lng: "127.52852",
    country: "CN",
    country_long: "China",
    portCode: "CNHEK",
    portName: "HEIHE (HEILONGJIANG)",
    portContinent: "Asia",
  },
  {
    lat: "22.994717",
    lng: "113.608903",
    country: "CN",
    country_long: "China",
    portCode: "CNHEM",
    portName: "Hongmei",
    portContinent: "Asia",
  },
  {
    lat: "40.8414899",
    lng: "111.75199",
    country: "CN",
    country_long: "China",
    portCode: "CNHET",
    portName: "HOHHOT (=HUHEHAOTE)",
    portContinent: "Asia",
  },
  {
    lat: "23.7437299",
    lng: "114.70039",
    country: "CN",
    country_long: "China",
    portCode: "CNHEY",
    portName: "HEYUAN",
    portContinent: "Asia",
  },
  {
    lat: "24.4034599",
    lng: "111.56655",
    country: "CN",
    country_long: "China",
    portCode: "CNHEZ",
    portName: "He Zhou",
    portContinent: "Asia",
  },
  {
    lat: "31.8205699",
    lng: "117.22901",
    country: "CN",
    country_long: "China",
    portCode: "CNHFE",
    portName: "HEFEI (CNHFE), Hefei, China",
    portContinent: "Asia",
  },
  {
    lat: "31.8205699",
    lng: "117.22901",
    country: "CN",
    country_long: "China",
    portCode: "CNHFI",
    portName: "HEFEI",
    portContinent: "Asia",
  },
  {
    lat: "23.131797",
    lng: "113.407143",
    country: "CN",
    country_long: "China",
    portCode: "CNHGC",
    portName: "Huangcun",
    portContinent: "Asia",
  },
  {
    lat: "22.82138",
    lng: "113.51542",
    country: "CN",
    country_long: "China",
    portCode: "CNHGE",
    portName: "Huangge (CNHGE), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "30.2741499",
    lng: "120.15515",
    country: "CN",
    country_long: "China",
    portCode: "CNHGH",
    portName: "HANGZHOU (CNHGH), Hangzhou, China",
    portContinent: "Asia",
  },
  {
    lat: "22.915278",
    lng: "114.003561",
    country: "CN",
    country_long: "China",
    portCode: "CNHGJ",
    portName: "HUANGJIANG",
    portContinent: "Asia",
  },
  {
    lat: "31.177394",
    lng: "121.384604",
    country: "CN",
    country_long: "China",
    portCode: "CNHGO",
    portName: "Hongqiao",
    portContinent: "Asia",
  },
  {
    lat: "22.17691",
    lng: "113.45668",
    country: "CN",
    country_long: "China",
    portCode: "CNHGW",
    portName: "Hongwan (CNHGW), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "40.8414899",
    lng: "111.75199",
    country: "CN",
    country_long: "China",
    portCode: "CNHHH",
    portName: "Hohhot,HU HE HAO TE",
    portContinent: "Asia",
  },
  {
    lat: "18.971187",
    lng: "-72.285215",
    country: "HT",
    country_long: "Haiti",
    portCode: "CNHHT",
    portName: "haitian",
    portContinent: "Asia",
  },
  {
    lat: "25.01843",
    lng: "98.4478",
    country: "CN",
    country_long: "China",
    portCode: "CNHHU",
    portName: "Heshun",
    portContinent: "Asia",
  },
  {
    lat: "33.551052",
    lng: "119.113185",
    country: "CN",
    country_long: "China",
    portCode: "CNHIA",
    portName: "HUAIAN",
    portContinent: "Asia",
  },
  {
    lat: "22.9848599",
    lng: "114.71999",
    country: "CN",
    country_long: "China",
    portCode: "CNHID",
    portName: "HUIDONG",
    portContinent: "Asia",
  },
  {
    lat: "32.6254899",
    lng: "116.9998",
    country: "CN",
    country_long: "China",
    portCode: "CNHII",
    portName: "HUAINAN",
    portContinent: "Asia",
  },
  {
    lat: "30.5093799",
    lng: "120.68102",
    country: "CN",
    country_long: "China",
    portCode: "CNHIN",
    portName: "Haining",
    portContinent: "Asia",
  },
  {
    lat: "37.7388599",
    lng: "115.67054",
    country: "CN",
    country_long: "China",
    portCode: "CNHIS",
    portName: "HENGSHUI",
    portContinent: "Asia",
  },
  {
    lat: "30.5255299",
    lng: "120.94579",
    country: "CN",
    country_long: "China",
    portCode: "CNHIY",
    portName: "HAIYAN (ZHEJIANG)",
    portContinent: "Asia",
  },
  {
    lat: "34.5720299",
    lng: "119.16348",
    country: "CN",
    country_long: "China",
    portCode: "CNHIZ",
    portName: "Haizhou",
    portContinent: "Asia",
  },
  {
    lat: "27.5697399",
    lng: "110.0016",
    country: "CN",
    country_long: "China",
    portCode: "CNHJJ",
    portName: "HUAIHUA",
    portContinent: "Asia",
  },
  {
    lat: "20.0442199",
    lng: "110.19989",
    country: "CN",
    country_long: "China",
    portCode: "CNHKO",
    portName: "Haikou",
    portContinent: "Asia",
  },
  {
    lat: "40.7109999",
    lng: "120.83699",
    country: "CN",
    country_long: "China",
    portCode: "CNHLO",
    portName: "Huludao (CNHLO), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.87164989999999",
    lng: "121.18179",
    country: "CN",
    country_long: "China",
    portCode: "CNHME",
    portName: "Haimen (CNHME), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.86166",
    lng: "104.195397",
    country: "CN",
    country_long: "China",
    portCode: "CNHMN",
    portName: "Humen (CNHMN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.64276",
    lng: "114.19822",
    country: "CN",
    country_long: "China",
    portCode: "CNHNG",
    portName: "HENGGANG",
    portContinent: "Asia",
  },
  {
    lat: "38.6313199",
    lng: "117.23037",
    country: "CN",
    country_long: "China",
    portCode: "CNHNH",
    portName: "Huanghua (CNHNH), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "41.6708279",
    lng: "-88.0921484",
    country: "US",
    country_long: "United States",
    portCode: "CNHNI",
    portName: "Hengli",
    portContinent: "Asia",
  },
  {
    lat: "42.6849899",
    lng: "126.04687",
    country: "CN",
    country_long: "China",
    portCode: "CNHNN",
    portName: "HUINAN  (JILIN)",
    portContinent: "Asia",
  },
  {
    lat: "39.1147099",
    lng: "106.59465",
    country: "CN",
    country_long: "China",
    portCode: "CNHNO",
    portName: "HUINONG",
    portContinent: "Asia",
  },
  {
    lat: "26.8931586",
    lng: "112.5719704",
    country: "CN",
    country_long: "China",
    portCode: "CNHNY",
    portName: "Hengyang",
    portContinent: "Asia",
  },
  {
    lat: "31.2316199",
    lng: "121.48461",
    country: "CN",
    country_long: "China",
    portCode: "CNHOP",
    portName: "HUANGPU",
    portContinent: "Asia",
  },
  {
    lat: "22.936585",
    lng: "113.67114",
    country: "CN",
    country_long: "China",
    portCode: "CNHOU",
    portName: "HOUJIE",
    portContinent: "Asia",
  },
  {
    lat: "45.7567307",
    lng: "126.6424173",
    country: "CN",
    country_long: "China",
    portCode: "CNHRB",
    portName: "HARBIN (CNHRB), Harbin, China",
    portContinent: "Asia",
  },
  {
    lat: "41.6939099",
    lng: "122.12066",
    country: "CN",
    country_long: "China",
    portCode: "CNHSA",
    portName: "Heishan",
    portContinent: "Asia",
  },
  {
    lat: "24.7646799",
    lng: "113.52985",
    country: "CN",
    country_long: "China",
    portCode: "CNHSC",
    portName: "SHAOGUAN (CNHSC), Shaoguan, China",
    portContinent: "Asia",
  },
  {
    lat: "29.7151699",
    lng: "118.33866",
    country: "CN",
    country_long: "China",
    portCode: "CNHSH",
    portName: "huangshan",
    portContinent: "Asia",
  },
  {
    lat: "30.04806",
    lng: "114.7332",
    country: "CN",
    country_long: "China",
    portCode: "CNHSI",
    portName: "Huangshi (CNHSI), Huangshi, China",
    portContinent: "Asia",
  },
  {
    lat: "22.7652999",
    lng: "112.96436",
    country: "CN",
    country_long: "China",
    portCode: "CNHSN",
    portName: "HESHAN (CNHSN), Jiangmen, China",
    portContinent: "Asia",
  },
  {
    lat: "27.8563503",
    lng: "113.1742188",
    country: "CN",
    country_long: "China",
    portCode: "CNHTG",
    portName: "Hetang",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNHUA",
    portName: "Huangpu Pt",
    portContinent: "Asia",
  },
  {
    lat: "26.65249",
    lng: "106.74669",
    country: "CN",
    country_long: "China",
    portCode: "CNHUD",
    portName: "Hua Du (CNHUD), Guiyang, China",
    portContinent: "Asia",
  },
  {
    lat: "30.4534699",
    lng: "114.87238",
    country: "CN",
    country_long: "China",
    portCode: "CNHUG",
    portName: "Huang Gang",
    portContinent: "Asia",
  },
  {
    lat: "23.1125099",
    lng: "114.41552",
    country: "CN",
    country_long: "China",
    portCode: "CNHUI",
    portName: "Huizhou (CNHUI), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.056647",
    lng: "113.527253",
    country: "CN",
    country_long: "China",
    portCode: "CNHUN",
    portName: "HUANGPU NEW PORT",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNHUS",
    portName: "HUSHI",
    portContinent: "Asia",
  },
  {
    lat: "28.6496499",
    lng: "121.26295",
    country: "CN",
    country_long: "China",
    portCode: "CNHYN",
    portName: "Huangyan",
    portContinent: "Asia",
  },
  {
    lat: "33.0676099",
    lng: "107.02377",
    country: "CN",
    country_long: "China",
    portCode: "CNHZG",
    portName: "HANZHONG",
    portContinent: "Asia",
  },
  {
    lat: "30.8930499",
    lng: "120.08805",
    country: "CN",
    country_long: "China",
    portCode: "CNHZH",
    portName: "Huzhou Pt",
    portContinent: "Asia",
  },
  {
    lat: "35.2336299",
    lng: "115.48115",
    country: "CN",
    country_long: "China",
    portCode: "CNHZS",
    portName: "Heze",
    portContinent: "Asia",
  },
  {
    lat: "22.5710072",
    lng: "113.9595943",
    country: "CN",
    country_long: "China",
    portCode: "CNILI",
    portName: "XILI",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNIUA",
    portName: "LIU'AN",
    portContinent: "Asia",
  },
  {
    lat: "31.18099",
    lng: "120.90138",
    country: "CN",
    country_long: "China",
    portCode: "CNIXI",
    portName: "Jinxi",
    portContinent: "Asia",
  },
  {
    lat: "30.753924",
    lng: "120.758543",
    country: "CN",
    country_long: "China",
    portCode: "CNJAX",
    portName: "Jiaxing Pt",
    portContinent: "Asia",
  },
  {
    lat: "29.62473",
    lng: "106.75079",
    country: "CN",
    country_long: "China",
    portCode: "CNJBE",
    portName: "Jiangbei(Yuzui)",
    portContinent: "Asia",
  },
  {
    lat: "29.4747499",
    lng: "119.28121",
    country: "CN",
    country_long: "China",
    portCode: "CNJDE",
    portName: "Jiande",
    portContinent: "Asia",
  },
  {
    lat: "29.29365",
    lng: "117.240648",
    country: "CN",
    country_long: "China",
    portCode: "CNJDZ",
    portName: "JINGDEZHEN (CNJDZ), Jingdezhen, China",
    portContinent: "Asia",
  },
  {
    lat: "29.2901399",
    lng: "106.25936",
    country: "CN",
    country_long: "China",
    portCode: "CNJGJ",
    portName: "Jiangjin",
    portContinent: "Asia",
  },
  {
    lat: "31.0354599",
    lng: "112.19945",
    country: "CN",
    country_long: "China",
    portCode: "CNJGM",
    portName: "JINGMEN",
    portContinent: "Asia",
  },
  {
    lat: "22.480937",
    lng: "113.026209",
    country: "CN",
    country_long: "China",
    portCode: "CNJGU",
    portName: "Jinguzhou (CNJGU), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.9191899",
    lng: "120.28432",
    country: "CN",
    country_long: "China",
    portCode: "CNJGY",
    portName: "JIANGYIN, FJ",
    portContinent: "Asia",
  },
  {
    lat: "30.33478989999999",
    lng: "112.24069",
    country: "CN",
    country_long: "China",
    portCode: "CNJGZ",
    portName: "Jingzhou (CNJGZ), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "29.0781199",
    lng: "119.64759",
    country: "CN",
    country_long: "China",
    portCode: "CNJHA",
    portName: "Jinhua",
    portContinent: "Asia",
  },
  {
    lat: "31.9191899",
    lng: "120.28432",
    country: "CN",
    country_long: "China",
    portCode: "CNJIA",
    portName: "Jiangyin Pt",
    portContinent: "Asia",
  },
  {
    lat: "22.83143",
    lng: "112.99351",
    country: "CN",
    country_long: "China",
    portCode: "CNJIG",
    portName: "Jiujiang Guangdong (CNJIG), Foshan, China",
    portContinent: "Asia",
  },
  {
    lat: "35.4145899",
    lng: "116.58724",
    country: "CN",
    country_long: "China",
    portCode: "CNJII",
    portName: "JINING",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNJIL",
    portName: "JIULI",
    portContinent: "Asia",
  },
  {
    lat: "27.1138199",
    lng: "114.99376",
    country: "CN",
    country_long: "China",
    portCode: "CNJIN",
    portName: "Ji'an",
    portContinent: "Asia",
  },
  {
    lat: "29.6605799",
    lng: "115.9541",
    country: "CN",
    country_long: "China",
    portCode: "CNJIU",
    portName: "Jiujiang Pt",
    portContinent: "Asia",
  },
  {
    lat: "46.7284399",
    lng: "131.14115",
    country: "CN",
    country_long: "China",
    portCode: "CNJIX",
    portName: "Jixian",
    portContinent: "Asia",
  },
  {
    lat: "41.0951499",
    lng: "121.12703",
    country: "CN",
    country_long: "China",
    portCode: "CNJIZ",
    portName: "JIN ZHOU",
    portContinent: "Asia",
  },
  {
    lat: "29.6605799",
    lng: "115.9541",
    country: "CN",
    country_long: "China",
    portCode: "CNJJG",
    portName: "Jiujiang",
    portContinent: "Asia",
  },
  {
    lat: "24.7814399",
    lng: "118.55199",
    country: "CN",
    country_long: "China",
    portCode: "CNJJI",
    portName: "JINJIANG",
    portContinent: "Asia",
  },
  {
    lat: "27.7000556",
    lng: "108.8391897",
    country: "CN",
    country_long: "China",
    portCode: "CNJKA",
    portName: "Jiangkou (CNJKA), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "29.5020699",
    lng: "106.5114",
    country: "CN",
    country_long: "China",
    portCode: "CNJLP",
    portName: "Jiulongpo",
    portContinent: "Asia",
  },
  {
    lat: "22.5786499",
    lng: "113.08161",
    country: "CN",
    country_long: "China",
    portCode: "CNJMN",
    portName: "Jiangmen Pt",
    portContinent: "Asia",
  },
  {
    lat: "36.3893199",
    lng: "120.44715",
    country: "CN",
    country_long: "China",
    portCode: "CNJMO",
    portName: "JIMO",
    portContinent: "Asia",
  },
  {
    lat: "46.7997599",
    lng: "130.31882",
    country: "CN",
    country_long: "China",
    portCode: "CNJMS",
    portName: "Jiamusi",
    portContinent: "Asia",
  },
  {
    lat: "36.6518399",
    lng: "117.12009",
    country: "CN",
    country_long: "China",
    portCode: "CNJNA",
    portName: "Jinan",
    portContinent: "Asia",
  },
  {
    lat: "32.0607099",
    lng: "118.76295",
    country: "CN",
    country_long: "China",
    portCode: "CNJND",
    portName: "Jiangdu (CNJND), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.4145899",
    lng: "116.58724",
    country: "CN",
    country_long: "China",
    portCode: "CNJNG",
    portName: "JINING",
    portContinent: "Asia",
  },
  {
    lat: "40.304292",
    lng: "-74.2511664",
    country: "US",
    country_long: "United States",
    portCode: "CNJNL",
    portName: "Jinli",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNJNS",
    portName: "Jinsha",
    portContinent: "Asia",
  },
  {
    lat: "28.6741699",
    lng: "115.91004",
    country: "CN",
    country_long: "China",
    portCode: "CNJNX",
    portName: "Jiangxi",
    portContinent: "Asia",
  },
  {
    lat: "41.0951499",
    lng: "121.12703",
    country: "CN",
    country_long: "China",
    portCode: "CNJNZ",
    portName: "Jinzhou",
    portContinent: "Asia",
  },
  {
    lat: "36.9821699",
    lng: "111.18107",
    country: "CN",
    country_long: "China",
    portCode: "CNJOK",
    portName: "Jiaokou",
    portContinent: "Asia",
  },
  {
    lat: "1.3367679",
    lng: "103.6941672",
    country: "SG",
    country_long: "Singapore",
    portCode: "CNJRG",
    portName: "JURONG",
    portContinent: "Asia",
  },
  {
    lat: "29.6054299",
    lng: "111.87741",
    country: "CN",
    country_long: "China",
    portCode: "CNJSI",
    portName: "Jinshi",
    portContinent: "Asia",
  },
  {
    lat: "30.8173099",
    lng: "121.04101",
    country: "CN",
    country_long: "China",
    portCode: "CNJSN",
    portName: "Jinshan (CNJSN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.0607099",
    lng: "118.76295",
    country: "CN",
    country_long: "China",
    portCode: "CNJSU",
    portName: "Jiangsiu",
    portContinent: "Asia",
  },
  {
    lat: "29.6605799",
    lng: "115.9541",
    country: "CN",
    country_long: "China",
    portCode: "CNJUJ",
    portName: "Jiujiang",
    portContinent: "Asia",
  },
  {
    lat: "22.705124",
    lng: "113.316811",
    country: "CN",
    country_long: "China",
    portCode: "CNJXN",
    portName: "Jiaoxin",
    portContinent: "Asia",
  },
  {
    lat: "35.0670699",
    lng: "112.60273",
    country: "CN",
    country_long: "China",
    portCode: "CNJYA",
    portName: "JIYUAN",
    portContinent: "Asia",
  },
  {
    lat: "23.5497199",
    lng: "116.37271",
    country: "CN",
    country_long: "China",
    portCode: "CNJYG",
    portName: "Jieyang (CNJYG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.5087899",
    lng: "120.12673",
    country: "CN",
    country_long: "China",
    portCode: "CNJYS",
    portName: "Jiangyan",
    portContinent: "Asia",
  },
  {
    lat: "36.2644499",
    lng: "120.0335399",
    country: "CN",
    country_long: "China",
    portCode: "CNJZH",
    portName: "Jiaozhou",
    portContinent: "Asia",
  },
  {
    lat: "30.33478989999999",
    lng: "112.24069",
    country: "CN",
    country_long: "China",
    portCode: "CNJZK",
    portName: "Jingzhou Pt",
    portContinent: "Asia",
  },
  {
    lat: "35.2156299",
    lng: "113.24201",
    country: "CN",
    country_long: "China",
    portCode: "CNJZO",
    portName: "JIAOZUO",
    portContinent: "Asia",
  },
  {
    lat: "22.23989",
    lng: "113.61842",
    country: "CN",
    country_long: "China",
    portCode: "CNJZU",
    portName: "JIUZHOU (CNJZU), Zhuhai, China",
    portContinent: "Asia",
  },
  {
    lat: "22.3770199",
    lng: "112.69828",
    country: "CN",
    country_long: "China",
    portCode: "CNKAP",
    portName: "KAIPING",
    portContinent: "Asia",
  },
  {
    lat: "30.12659",
    lng: "120.39694",
    country: "CN",
    country_long: "China",
    portCode: "CNKEQ",
    portName: "Keqiao(Qianqing)",
    portContinent: "Asia",
  },
  {
    lat: "34.7972599",
    lng: "114.30731",
    country: "CN",
    country_long: "China",
    portCode: "CNKFG",
    portName: "Kaifeng",
    portContinent: "Asia",
  },
  {
    lat: "28.6820199",
    lng: "115.8579399",
    country: "CN",
    country_long: "China",
    portCode: "CNKHN",
    portName: "Nanchang (CNKHN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "27.23185",
    lng: "112.794944",
    country: "CN",
    country_long: "China",
    portCode: "CNKIY",
    portName: "Kaiyun",
    portContinent: "Asia",
  },
  {
    lat: "24.8796599",
    lng: "102.83322",
    country: "CN",
    country_long: "China",
    portCode: "CNKMG",
    portName: "KUNMING (CNKMG), Kunming, China",
    portContinent: "Asia",
  },
  {
    lat: "25.0452999",
    lng: "102.7097299",
    country: "CN",
    country_long: "China",
    portCode: "CNKML",
    portName: "Yunnan",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNKMN",
    portName: "Kanmen",
    portContinent: "Asia",
  },
  {
    lat: "41.7264299",
    lng: "86.1736899",
    country: "CN",
    country_long: "China",
    portCode: "CNKOL",
    portName: "Korla",
    portContinent: "Asia",
  },
  {
    lat: "22.3770199",
    lng: "112.69828",
    country: "CN",
    country_long: "China",
    portCode: "CNKPN",
    portName: "KAIPING (CNKPN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.1823199",
    lng: "121.00715",
    country: "CN",
    country_long: "China",
    portCode: "CNKUS",
    portName: "KUNSHAN (CNKUS), Suzhou, China",
    portContinent: "Asia",
  },
  {
    lat: "26.6475899",
    lng: "106.63019",
    country: "CN",
    country_long: "China",
    portCode: "CNKWE",
    portName: "GUIYANG(GUIZHOU PROVINCE)",
    portContinent: "Asia",
  },
  {
    lat: "37.1770199",
    lng: "119.94222",
    country: "CN",
    country_long: "China",
    portCode: "CNLAI",
    portName: "LAIZHOU",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNLAY",
    portName: "LAIYING",
    portContinent: "Asia",
  },
  {
    lat: "36.0613799",
    lng: "103.83417",
    country: "CN",
    country_long: "China",
    portCode: "CNLAZ",
    portName: "LANZHOU (GANSU)",
    portContinent: "Asia",
  },
  {
    lat: "23.7520999",
    lng: "109.22238",
    country: "CN",
    country_long: "China",
    portCode: "CNLBI",
    portName: "Lai Bin",
    portContinent: "Asia",
  },
  {
    lat: "22.997358",
    lng: "113.874871",
    country: "CN",
    country_long: "China",
    portCode: "CNLBU",
    portName: "LIAOBU",
    portContinent: "Asia",
  },
  {
    lat: "36.4570199",
    lng: "115.9854899",
    country: "CN",
    country_long: "China",
    portCode: "CNLCH",
    portName: "Liaocheng",
    portContinent: "Asia",
  },
  {
    lat: "23.119172",
    lng: "113.202334",
    country: "CN",
    country_long: "China",
    portCode: "CNLCN",
    portName: "Luocun",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNLCP",
    portName: "Lucheng Pt",
    portContinent: "Asia",
  },
  {
    lat: "27.6972799",
    lng: "111.99458",
    country: "CN",
    country_long: "China",
    portCode: "CNLDI",
    portName: "LOUDI",
    portContinent: "Asia",
  },
  {
    lat: "22.95851",
    lng: "113.08924",
    country: "CN",
    country_long: "China",
    portCode: "CNLEC",
    portName: "Lecong",
    portContinent: "Asia",
  },
  {
    lat: "29.5522099",
    lng: "103.76539",
    country: "CN",
    country_long: "China",
    portCode: "CNLES",
    portName: "LESHAN",
    portContinent: "Asia",
  },
  {
    lat: "39.5377499",
    lng: "116.68376",
    country: "CN",
    country_long: "China",
    portCode: "CNLFG",
    portName: "LANGFANG (HEBEI)",
    portContinent: "Asia",
  },
  {
    lat: "22.9451099",
    lng: "115.64462",
    country: "CN",
    country_long: "China",
    portCode: "CNLFN",
    portName: "LUFENG",
    portContinent: "Asia",
  },
  {
    lat: "39.5377499",
    lng: "116.68376",
    country: "CN",
    country_long: "China",
    portCode: "CNLFZ",
    portName: "LANGFANG",
    portContinent: "Asia",
  },
  {
    lat: "22.7199099",
    lng: "114.24779",
    country: "CN",
    country_long: "China",
    portCode: "CNLGG",
    portName: "LONGGANG",
    portContinent: "Asia",
  },
  {
    lat: "22.7529899",
    lng: "114.03601",
    country: "CN",
    country_long: "China",
    portCode: "CNLGH",
    portName: "Longhua",
    portContinent: "Asia",
  },
  {
    lat: "24.4465799",
    lng: "117.81813",
    country: "CN",
    country_long: "China",
    portCode: "CNLGI",
    portName: "Longhai (CNLGI), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.0750399",
    lng: "117.01722",
    country: "CN",
    country_long: "China",
    portCode: "CNLGY",
    portName: "LONGYAN",
    portContinent: "Asia",
  },
  {
    lat: "29.6526199",
    lng: "91.13775",
    country: "CN",
    country_long: "China",
    portCode: "CNLHA",
    portName: "LHASA",
    portContinent: "Asia",
  },
  {
    lat: "29.74525",
    lng: "122.12219",
    country: "CN",
    country_long: "China",
    portCode: "CNLHE",
    portName: "Liuheng (CNLHE), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.97879",
    lng: "113.504534",
    country: "CN",
    country_long: "China",
    portCode: "CNLHS",
    portName: "LIAN HUA SHAN (GUANGDONG)",
    portContinent: "Asia",
  },
  {
    lat: "36.0613799",
    lng: "103.83417",
    country: "CN",
    country_long: "China",
    portCode: "CNLHW",
    portName: "Lanzhou (CNLHW), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "41.26808990000001",
    lng: "123.2373599",
    country: "CN",
    country_long: "China",
    portCode: "CNLIA",
    portName: "LIAO YANG  (LIAONING)",
    portContinent: "Asia",
  },
  {
    lat: "24.9074999",
    lng: "118.58687",
    country: "CN",
    country_long: "China",
    portCode: "CNLIC",
    portName: "Licheng",
    portContinent: "Asia",
  },
  {
    lat: "36.4570199",
    lng: "115.9854899",
    country: "CN",
    country_long: "China",
    portCode: "CNLIG",
    portName: "LIAOCHENG",
    portContinent: "Asia",
  },
  {
    lat: "22.97879",
    lng: "113.504534",
    country: "CN",
    country_long: "China",
    portCode: "CNLIH",
    portName: "Lian Hua Shan",
    portContinent: "Asia",
  },
  {
    lat: "26.8564799",
    lng: "100.2270999",
    country: "CN",
    country_long: "China",
    portCode: "CNLIJ",
    portName: "Lijiang (CNLIJ), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "30.44082",
    lng: "120.30879",
    country: "CN",
    country_long: "China",
    portCode: "CNLIP",
    portName: "Linping",
    portContinent: "Asia",
  },
  {
    lat: "28.4671999",
    lng: "119.92293",
    country: "CN",
    country_long: "China",
    portCode: "CNLIS",
    portName: "LISHUI (ZHENJIANG)",
    portContinent: "Asia",
  },
  {
    lat: "23.1258599",
    lng: "113.24428",
    country: "CN",
    country_long: "China",
    portCode: "CNLIW",
    portName: "Liwan",
    portContinent: "Asia",
  },
  {
    lat: "36.8880799",
    lng: "120.51777",
    country: "CN",
    country_long: "China",
    portCode: "CNLIX",
    portName: "Lai Xi",
    portContinent: "Asia",
  },
  {
    lat: "38.0427599",
    lng: "114.5143",
    country: "CN",
    country_long: "China",
    portCode: "CNLIZ",
    portName: "Lijiazhuang",
    portContinent: "Asia",
  },
  {
    lat: "22.867668",
    lng: "113.221181",
    country: "CN",
    country_long: "China",
    portCode: "CNLJO",
    portName: "Lunjiao",
    portContinent: "Asia",
  },
  {
    lat: "37.6434499",
    lng: "120.47872",
    country: "CN",
    country_long: "China",
    portCode: "CNLKU",
    portName: "Longkou (CNLKU), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.6778199",
    lng: "117.2552399",
    country: "CN",
    country_long: "China",
    portCode: "CNLLG",
    portName: "Leling",
    portContinent: "Asia",
  },
  {
    lat: "27.6461699",
    lng: "113.49703",
    country: "CN",
    country_long: "China",
    portCode: "CNLLI",
    portName: "LILING (CNLLI), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "47.3373699",
    lng: "123.20483",
    country: "CN",
    country_long: "China",
    portCode: "CNLLJ",
    portName: "Longjiang",
    portContinent: "Asia",
  },
  {
    lat: "34.57949",
    lng: "112.4758",
    country: "CN",
    country_long: "China",
    portCode: "CNLMN",
    portName: "Longmen",
    portContinent: "Asia",
  },
  {
    lat: "30.2338299",
    lng: "119.7248",
    country: "CN",
    country_long: "China",
    portCode: "CNLNA",
    portName: "Linan",
    portContinent: "Asia",
  },
  {
    lat: "28.8583999",
    lng: "121.14495",
    country: "CN",
    country_long: "China",
    portCode: "CNLNH",
    portName: "LINHAI",
    portContinent: "Asia",
  },
  {
    lat: "26.1975299",
    lng: "119.53957",
    country: "CN",
    country_long: "China",
    portCode: "CNLNJ",
    portName: "Lian Jiang (CNLNJ), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.983985",
    lng: "113.107107",
    country: "CN",
    country_long: "China",
    portCode: "CNLNS",
    portName: "Lanshi",
    portContinent: "Asia",
  },
  {
    lat: "29.34328",
    lng: "106.42754",
    country: "CN",
    country_long: "China",
    portCode: "CNLOH",
    portName: "Luohuang",
    portContinent: "Asia",
  },
  {
    lat: "42.8880499",
    lng: "125.14368",
    country: "CN",
    country_long: "China",
    portCode: "CNLOY",
    portName: "Liaoyuan",
    portContinent: "Asia",
  },
  {
    lat: "22.3426199",
    lng: "106.85455",
    country: "CN",
    country_long: "China",
    portCode: "CNLOZ",
    portName: "Longzhou",
    portContinent: "Asia",
  },
  {
    lat: "28.9617299",
    lng: "117.129",
    country: "CN",
    country_long: "China",
    portCode: "CNLPI",
    portName: "Leping",
    portContinent: "Asia",
  },
  {
    lat: "26.5933599",
    lng: "104.83023",
    country: "CN",
    country_long: "China",
    portCode: "CNLPS",
    portName: "Liu Pan Shui",
    portContinent: "Asia",
  },
  {
    lat: "19.966541",
    lng: "110.433027",
    country: "CN",
    country_long: "China",
    portCode: "CNLSA",
    portName: "Lingshan",
    portContinent: "Asia",
  },
  {
    lat: "38.8512899",
    lng: "121.26208",
    country: "CN",
    country_long: "China",
    portCode: "CNLSH",
    portName: "Lushun (CNLSH), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.12377",
    lng: "112.83179",
    country: "CN",
    country_long: "China",
    portCode: "CNLSI",
    portName: "LAN SHI (CNLSI), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.86166",
    lng: "104.195397",
    country: "CN",
    country_long: "China",
    portCode: "CNLSN",
    portName: "Lanshan (CNLSN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "28.4671999",
    lng: "119.92293",
    country: "CN",
    country_long: "China",
    portCode: "CNLSS",
    portName: "Lishui",
    portContinent: "Asia",
  },
  {
    lat: "43.3071699",
    lng: "124.33564",
    country: "CN",
    country_long: "China",
    portCode: "CNLSU",
    portName: "LISHU  (JILIN)",
    portContinent: "Asia",
  },
  {
    lat: "23.35418",
    lng: "112.90686",
    country: "CN",
    country_long: "China",
    portCode: "CNLUA",
    portName: "Lubao(Sanshui)",
    portContinent: "Asia",
  },
  {
    lat: "28.644494",
    lng: "118.303207",
    country: "CN",
    country_long: "China",
    portCode: "CNLUD",
    portName: "LIUDU (CNLUD), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.86166",
    lng: "104.195397",
    country: "CN",
    country_long: "China",
    portCode: "CNLUH",
    portName: "Luhuashan (CNLUH), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.7348799",
    lng: "116.5232399",
    country: "CN",
    country_long: "China",
    portCode: "CNLUN",
    portName: "Lu'an",
    portContinent: "Asia",
  },
  {
    lat: "22.860237",
    lng: "113.121739",
    country: "CN",
    country_long: "China",
    portCode: "CNLUU",
    portName: "LELIU (CNLUU), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "28.8716999",
    lng: "105.44257",
    country: "CN",
    country_long: "China",
    portCode: "CNLUZ",
    portName: "Luzhou",
    portContinent: "Asia",
  },
  {
    lat: "36.2031699",
    lng: "117.65992",
    country: "CN",
    country_long: "China",
    portCode: "CNLWS",
    portName: "Laiwu",
    portContinent: "Asia",
  },
  {
    lat: "36.2031699",
    lng: "117.65992",
    country: "CN",
    country_long: "China",
    portCode: "CNLWU",
    portName: "LAIWU",
    portContinent: "Asia",
  },
  {
    lat: "29.2083799",
    lng: "119.46051",
    country: "CN",
    country_long: "China",
    portCode: "CNLXI",
    portName: "Lanxi",
    portContinent: "Asia",
  },
  {
    lat: "26.4893899",
    lng: "119.5496899",
    country: "CN",
    country_long: "China",
    portCode: "CNLYA",
    portName: "Luoyuan (CNLYA), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.9787999",
    lng: "120.71173",
    country: "CN",
    country_long: "China",
    portCode: "CNLYD",
    portName: "Laiyang",
    portContinent: "Asia",
  },
  {
    lat: "34.5966899",
    lng: "119.22295",
    country: "CN",
    country_long: "China",
    portCode: "CNLYG",
    portName: "Lianyungang",
    portContinent: "Asia",
  },
  {
    lat: "37.21294",
    lng: "116.87086",
    country: "CN",
    country_long: "China",
    portCode: "CNLYI",
    portName: "Linyizhan",
    portContinent: "Asia",
  },
  {
    lat: "41.26808990000001",
    lng: "123.2373599",
    country: "CN",
    country_long: "China",
    portCode: "CNLYS",
    portName: "Liaoyang",
    portContinent: "Asia",
  },
  {
    lat: "34.5966899",
    lng: "119.22295",
    country: "CN",
    country_long: "China",
    portCode: "CNLYZ",
    portName: "Lianyungang Development Zone (CNLYZ), Lianyungang, China",
    portContinent: "Asia",
  },
  {
    lat: "24.3255257",
    lng: "109.4154583",
    country: "CN",
    country_long: "China",
    portCode: "CNLZH",
    portName: "LIUZHOU (CNLZH), Liuzhou, China",
    portContinent: "Asia",
  },
  {
    lat: "37.1770199",
    lng: "119.94222",
    country: "CN",
    country_long: "China",
    portCode: "CNLZO",
    portName: "Laizhou (CNLZO), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "28.8716999",
    lng: "105.44257",
    country: "CN",
    country_long: "China",
    portCode: "CNLZU",
    portName: "LUZHOU",
    portContinent: "Asia",
  },
  {
    lat: "31.6706695",
    lng: "118.50611",
    country: "CN",
    country_long: "China",
    portCode: "CNMAA",
    portName: "Maanshan",
    portContinent: "Asia",
  },
  {
    lat: "34.0288007",
    lng: "-118.2917965",
    country: "US",
    country_long: "United States",
    portCode: "CNMAN",
    portName: "Manas",
    portContinent: "Asia",
  },
  {
    lat: "25.9893899",
    lng: "119.4556",
    country: "CN",
    country_long: "China",
    portCode: "CNMAW",
    portName: "Mawei Pt",
    portContinent: "Asia",
  },
  {
    lat: "23.051137",
    lng: "113.581134",
    country: "CN",
    country_long: "China",
    portCode: "CNMCH",
    portName: "MACHONG (CNMCH), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.86166",
    lng: "104.195397",
    country: "CN",
    country_long: "China",
    portCode: "CNMCI",
    portName: "Mcid (CNMCI), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.2561499",
    lng: "113.3229",
    country: "CN",
    country_long: "China",
    portCode: "CNMCN",
    portName: "Macun (CNMCN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "44.5526899",
    lng: "129.63244",
    country: "CN",
    country_long: "China",
    portCode: "CNMDG",
    portName: "Mudanjiang",
    portContinent: "Asia",
  },
  {
    lat: "24.2884399",
    lng: "116.12264",
    country: "CN",
    country_long: "China",
    portCode: "CNMEZ",
    portName: "MEIZHOU",
    portContinent: "Asia",
  },
  {
    lat: "34.14541",
    lng: "114.22246",
    country: "CN",
    country_long: "China",
    portCode: "CNMFG",
    portName: "MAFANG (CNMFG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.2194569",
    lng: "113.0235861",
    country: "CN",
    country_long: "China",
    portCode: "CNMIN",
    portName: "Mingcheng(Sanshui)",
    portContinent: "Asia",
  },
  {
    lat: "40.56119140000001",
    lng: "116.8203807",
    country: "CN",
    country_long: "China",
    portCode: "CNMIY",
    portName: "Miyun",
    portContinent: "Asia",
  },
  {
    lat: "30.6762",
    lng: "122.418656",
    country: "CN",
    country_long: "China",
    portCode: "CNMJS",
    portName: "Majishan (CNMJS), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.5483499",
    lng: "113.58646",
    country: "CN",
    country_long: "China",
    portCode: "CNMLE",
    portName: "Minle(Conghua)",
    portContinent: "Asia",
  },
  {
    lat: "21.68035",
    lng: "110.94713",
    country: "CN",
    country_long: "China",
    portCode: "CNMMI",
    portName: "MAOMING (CNMMI), Maoming, China",
    portContinent: "Asia",
  },
  {
    lat: "31.1132499",
    lng: "121.38206",
    country: "CN",
    country_long: "China",
    portCode: "CNMNG",
    portName: "Minhang (CNMNG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.198745",
    lng: "113.543873",
    country: "MO",
    country_long: "Macao",
    portCode: "CNMO1",
    portName: "MACAO (CNMO1), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "52.9720899",
    lng: "122.53864",
    country: "CN",
    country_long: "China",
    portCode: "CNMOH",
    portName: "Mohe",
    portContinent: "Asia",
  },
  {
    lat: "32.07062",
    lng: "119.760669",
    country: "CN",
    country_long: "China",
    portCode: "CNMOT",
    portName: "Miaotou",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNMSN",
    portName: "Meishan",
    portContinent: "Asia",
  },
  {
    lat: "23.082944",
    lng: "113.486124",
    country: "CN",
    country_long: "China",
    portCode: "CNMSW",
    portName: "Miaoshawei",
    portContinent: "Asia",
  },
  {
    lat: "37.3869386",
    lng: "121.6004696",
    country: "CN",
    country_long: "China",
    portCode: "CNMUP",
    portName: "MUPING",
    portContinent: "Asia",
  },
  {
    lat: "30.57427",
    lng: "113.33224",
    country: "CN",
    country_long: "China",
    portCode: "CNMWN",
    portName: "Mawan (CNMWN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.4675099",
    lng: "104.6795999",
    country: "CN",
    country_long: "China",
    portCode: "CNMYG",
    portName: "Mianyang",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNMYM",
    portName: "Moupingyangmadao",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNMZU",
    portName: "Muzhou",
    portContinent: "Asia",
  },
  {
    lat: "23.0288199",
    lng: "113.14278",
    country: "CN",
    country_long: "China",
    portCode: "CNNAH",
    portName: "Nanhai (CNNAH), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.4217799",
    lng: "117.02348",
    country: "CN",
    country_long: "China",
    portCode: "CNNAN",
    portName: "Nan Ao (CNNAN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "26.6414099",
    lng: "118.17792",
    country: "CN",
    country_long: "China",
    portCode: "CNNAP",
    portName: "NANPING",
    portContinent: "Asia",
  },
  {
    lat: "29.868336",
    lng: "121.54399",
    country: "CN",
    country_long: "China",
    portCode: "CNNBG",
    portName: "Ningbo Pt (CNNBG), Ningbo, China",
    portContinent: "Asia",
  },
  {
    lat: "29.868336",
    lng: "121.54399",
    country: "CN",
    country_long: "China",
    portCode: "CNNBO",
    portName: "Ningbo",
    portContinent: "Asia",
  },
  {
    lat: "23.4314099",
    lng: "115.84189",
    country: "CN",
    country_long: "China",
    portCode: "CNNCA",
    portName: "Jiexi  (CNNCA), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "28.6820199",
    lng: "115.8579399",
    country: "CN",
    country_long: "China",
    portCode: "CNNCH",
    portName: "Nanchang",
    portContinent: "Asia",
  },
  {
    lat: "30.8379015",
    lng: "106.1105999",
    country: "CN",
    country_long: "China",
    portCode: "CNNCO",
    portName: "Nanchong",
    portContinent: "Asia",
  },
  {
    lat: "26.6657099",
    lng: "119.54819",
    country: "CN",
    country_long: "China",
    portCode: "CNNDE",
    portName: "Ningde (CNNDE), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "47.3543099",
    lng: "123.91796",
    country: "CN",
    country_long: "China",
    portCode: "CNNDG",
    portName: "TSITSIHAR",
    portContinent: "Asia",
  },
  {
    lat: "23.094378",
    lng: "115.641083",
    country: "CN",
    country_long: "China",
    portCode: "CNNEG",
    portName: "Neigang",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNNGB",
    portName: "Port of Ningbo",
    portContinent: "Asia",
  },
  {
    lat: "45.7602099",
    lng: "126.66837",
    country: "CN",
    country_long: "China",
    portCode: "CNNGG",
    portName: "NANGANG (CNNGG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "29.2880699",
    lng: "121.4296099",
    country: "CN",
    country_long: "China",
    portCode: "CNNHE",
    portName: "Ninghai",
    portContinent: "Asia",
  },
  {
    lat: "30.5927599",
    lng: "114.30525",
    country: "CN",
    country_long: "China",
    portCode: "CNNHN",
    portName: "Wuhan",
    portContinent: "Asia",
  },
  {
    lat: "53.58674540000001",
    lng: "-2.4516939",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "CNNHS",
    portName: "Sanshan pt",
    portContinent: "Asia",
  },
  {
    lat: "22.8167299",
    lng: "108.3669",
    country: "CN",
    country_long: "China",
    portCode: "CNNIG",
    portName: "NANNING",
    portContinent: "Asia",
  },
  {
    lat: "29.2880699",
    lng: "121.4296099",
    country: "CN",
    country_long: "China",
    portCode: "CNNIN",
    portName: "Ninghai (CNNIN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.0583799",
    lng: "118.79647",
    country: "CN",
    country_long: "China",
    portCode: "CNNJG",
    portName: "Nanjing (CNNJG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.0583799",
    lng: "118.79647",
    country: "CN",
    country_long: "China",
    portCode: "CNNJI",
    portName: "Nanjing",
    portContinent: "Asia",
  },
  {
    lat: "32.0583799",
    lng: "118.79647",
    country: "CN",
    country_long: "China",
    portCode: "CNNKG",
    portName: "Nanjing",
    portContinent: "Asia",
  },
  {
    lat: "30.8552599",
    lng: "118.41386",
    country: "CN",
    country_long: "China",
    portCode: "CNNLG",
    portName: "NAN LING",
    portContinent: "Asia",
  },
  {
    lat: "22.8167299",
    lng: "108.3669",
    country: "CN",
    country_long: "China",
    portCode: "CNNNG",
    portName: "NANNING (GUANGXI)",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNNNP",
    portName: "NANSHA NEW PORT",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNNSA",
    portName: "Nansha Pt",
    portContinent: "Asia",
  },
  {
    lat: "31.9795799",
    lng: "120.89371",
    country: "CN",
    country_long: "China",
    portCode: "CNNTG",
    portName: "Nantong Pt",
    portContinent: "Asia",
  },
  {
    lat: "23.9609981",
    lng: "120.9718638",
    country: "TW",
    country_long: "Taiwan",
    portCode: "CNNTU",
    portName: "Nantou",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNNWI",
    portName: "nanwei",
    portContinent: "Asia",
  },
  {
    lat: "32.9907299",
    lng: "112.52851",
    country: "CN",
    country_long: "China",
    portCode: "CNNYG",
    portName: "Nanyang",
    portContinent: "Asia",
  },
  {
    lat: "41.1199599",
    lng: "122.07078",
    country: "CN",
    country_long: "China",
    portCode: "CNPAJ",
    portName: "PANJIN CITY  (LIAONING)",
    portContinent: "Asia",
  },
  {
    lat: "33.7660899",
    lng: "113.19241",
    country: "CN",
    country_long: "China",
    portCode: "CNPDE",
    portName: "PINGDINGSHAN",
    portContinent: "Asia",
  },
  {
    lat: "36.7866999",
    lng: "119.95994",
    country: "CN",
    country_long: "China",
    portCode: "CNPDU",
    portName: "Pingdu",
    portContinent: "Asia",
  },
  {
    lat: "23.032234",
    lng: "113.203857",
    country: "CN",
    country_long: "China",
    portCode: "CNPGZ",
    portName: "Pingzhou",
    portContinent: "Asia",
  },
  {
    lat: "27.6228899",
    lng: "113.85427",
    country: "CN",
    country_long: "China",
    portCode: "CNPIN",
    portName: "Ping Xiang",
    portContinent: "Asia",
  },
  {
    lat: "41.1199599",
    lng: "122.07078",
    country: "CN",
    country_long: "China",
    portCode: "CNPJI",
    portName: "Panjin (CNPJI), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.8106399",
    lng: "120.75898",
    country: "CN",
    country_long: "China",
    portCode: "CNPLI",
    portName: "Penglai (CNPLI), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "26.10591",
    lng: "104.60011",
    country: "CN",
    country_long: "China",
    portCode: "CNPND",
    portName: "PINGDI",
    portContinent: "Asia",
  },
  {
    lat: "30.6758499",
    lng: "121.01606",
    country: "CN",
    country_long: "China",
    portCode: "CNPNH",
    portName: "PINGHU",
    portContinent: "Asia",
  },
  {
    lat: "22.69084",
    lng: "114.34632",
    country: "CN",
    country_long: "China",
    portCode: "CNPNS",
    portName: "Pingshan",
    portContinent: "Asia",
  },
  {
    lat: "5.7332654",
    lng: "-0.1843777",
    country: "GH",
    country_long: "Ghana",
    portCode: "CNPNT",
    portName: "Pantang",
    portContinent: "Asia",
  },
  {
    lat: "22.9377199",
    lng: "113.38424",
    country: "CN",
    country_long: "China",
    portCode: "CNPNY",
    portName: "PANYU (CNPNY), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.4539999",
    lng: "119.00771",
    country: "CN",
    country_long: "China",
    portCode: "CNPUT",
    portName: "Putian",
    portContinent: "Asia",
  },
  {
    lat: "35.7618899",
    lng: "115.02932",
    country: "CN",
    country_long: "China",
    portCode: "CNPYG",
    portName: "PUYANG",
    portContinent: "Asia",
  },
  {
    lat: "22.9377199",
    lng: "113.38424",
    country: "CN",
    country_long: "China",
    portCode: "CNPYU",
    portName: "Panyu",
    portContinent: "Asia",
  },
  {
    lat: "26.5822799",
    lng: "101.71872",
    country: "CN",
    country_long: "China",
    portCode: "CNPZI",
    portName: "PANZHIHUA",
    portContinent: "Asia",
  },
  {
    lat: "28.354389",
    lng: "109.990751",
    country: "CN",
    country_long: "China",
    portCode: "CNPZO",
    portName: "Paizhou",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNQAE",
    portName: "Qianwan",
    portContinent: "Asia",
  },
  {
    lat: "31.7964699",
    lng: "121.4469",
    country: "CN",
    country_long: "China",
    portCode: "CNQDG",
    portName: "Qidong (CNQDG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.6820099",
    lng: "113.05615",
    country: "CN",
    country_long: "China",
    portCode: "CNQGY",
    portName: "QINGYUAN (GUANGDONG)",
    portContinent: "Asia",
  },
  {
    lat: "22.51376",
    lng: "113.90819",
    country: "CN",
    country_long: "China",
    portCode: "CNQHI",
    portName: "Qianhai (CNQHI), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.553226",
    lng: "112.968284",
    country: "CN",
    country_long: "China",
    portCode: "CNQIC",
    portName: "Qingcheng(Shijiao)",
    portContinent: "Asia",
  },
  {
    lat: "34.4142699",
    lng: "115.65635",
    country: "CN",
    country_long: "China",
    portCode: "CNQIG",
    portName: "Shangqiu",
    portContinent: "Asia",
  },
  {
    lat: "36.0662299",
    lng: "120.38299",
    country: "CN",
    country_long: "China",
    portCode: "CNQIN",
    portName: "Qingdao",
    portContinent: "Asia",
  },
  {
    lat: "23.0729",
    lng: "114.02204",
    country: "CN",
    country_long: "China",
    portCode: "CNQIS",
    portName: "Qishi",
    portContinent: "Asia",
  },
  {
    lat: "22.809635",
    lng: "114.438164",
    country: "CN",
    country_long: "China",
    portCode: "CNQIU",
    portName: "Qiuchang(Huiyang)",
    portContinent: "Asia",
  },
  {
    lat: "30.4014699",
    lng: "112.8993",
    country: "CN",
    country_long: "China",
    portCode: "CNQJI",
    portName: "QIANJIANG",
    portContinent: "Asia",
  },
  {
    lat: "36.6845599",
    lng: "118.4796599",
    country: "CN",
    country_long: "China",
    portCode: "CNQOU",
    portName: "QINGZHOU",
    portContinent: "Asia",
  },
  {
    lat: "-36.7556063",
    lng: "142.0630449",
    country: "AU",
    country_long: "Australia",
    portCode: "CNQTG",
    portName: "QUANTONG",
    portContinent: "Asia",
  },
  {
    lat: "28.168408",
    lng: "120.482198",
    country: "CN",
    country_long: "China",
    portCode: "CNQTU",
    portName: "Qiaotou",
    portContinent: "Asia",
  },
  {
    lat: "35.4746899",
    lng: "117.24542",
    country: "CN",
    country_long: "China",
    portCode: "CNQUF",
    portName: "Qufu",
    portContinent: "Asia",
  },
  {
    lat: "25.4900199",
    lng: "103.79625",
    country: "CN",
    country_long: "China",
    portCode: "CNQUI",
    portName: "Qu Jing",
    portContinent: "Asia",
  },
  {
    lat: "28.9359199",
    lng: "118.87419",
    country: "CN",
    country_long: "China",
    portCode: "CNQUZ",
    portName: "QUZHOU (ZHENJIANG)",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNQXI",
    portName: "Qingxin",
    portContinent: "Asia",
  },
  {
    lat: "21.9796999",
    lng: "108.65431",
    country: "CN",
    country_long: "China",
    portCode: "CNQZH",
    portName: "Qinzhou Pt",
    portContinent: "Asia",
  },
  {
    lat: "24.8740499",
    lng: "118.67583",
    country: "CN",
    country_long: "China",
    portCode: "CNQZJ",
    portName: "Quanzhou (CNQZJ), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "24.8740499",
    lng: "118.67583",
    country: "CN",
    country_long: "China",
    portCode: "CNQZL",
    portName: "Quanzhou",
    portContinent: "Asia",
  },
  {
    lat: "28.9359199",
    lng: "118.87419",
    country: "CN",
    country_long: "China",
    portCode: "CNQZS",
    portName: "Quzhou",
    portContinent: "Asia",
  },
  {
    lat: "37.1652299",
    lng: "122.48628",
    country: "CN",
    country_long: "China",
    portCode: "CNRCG",
    portName: "Rongcheng",
    portContinent: "Asia",
  },
  {
    lat: "22.354562",
    lng: "112.589374",
    country: "CN",
    country_long: "China",
    portCode: "CNRGU",
    portName: "RONGGUI",
    portContinent: "Asia",
  },
  {
    lat: "27.7783799",
    lng: "120.65518",
    country: "CN",
    country_long: "China",
    portCode: "CNRIA",
    portName: "Rui An (CNRIA), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.32947",
    lng: "113.301255",
    country: "CN",
    country_long: "China",
    portCode: "CNRNH",
    portName: "Renhe",
    portContinent: "Asia",
  },
  {
    lat: "22.780685",
    lng: "113.292442",
    country: "CN",
    country_long: "China",
    portCode: "CNROQ",
    portName: "Rongqi Pt",
    portContinent: "Asia",
  },
  {
    lat: "23.6641199",
    lng: "117.0039",
    country: "CN",
    country_long: "China",
    portCode: "CNRPG",
    portName: "Raoping",
    portContinent: "Asia",
  },
  {
    lat: "36.87117",
    lng: "121.509453",
    country: "CN",
    country_long: "China",
    portCode: "CNRSN",
    portName: "Rushankou",
    portContinent: "Asia",
  },
  {
    lat: "32.3715999",
    lng: "120.57382",
    country: "CN",
    country_long: "China",
    portCode: "CNRUG",
    portName: "Rugao Pt",
    portContinent: "Asia",
  },
  {
    lat: "35.4164599",
    lng: "119.52719",
    country: "CN",
    country_long: "China",
    portCode: "CNRZH",
    portName: "Rizhao (CNRZH), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "45.1845399",
    lng: "126.18618",
    country: "CN",
    country_long: "China",
    portCode: "CNSAC",
    portName: "Shuangcheng",
    portContinent: "Asia",
  },
  {
    lat: "36.6682599",
    lng: "117.02076",
    country: "CN",
    country_long: "China",
    portCode: "CNSAD",
    portName: "Shandong",
    portContinent: "Asia",
  },
  {
    lat: "22.764615",
    lng: "113.848396",
    country: "CN",
    country_long: "China",
    portCode: "CNSAG",
    portName: "Songgang",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNSAL",
    portName: "Shalingzhuang",
    portContinent: "Asia",
  },
  {
    lat: "22.9918",
    lng: "113.12425",
    country: "CN",
    country_long: "China",
    portCode: "CNSAN",
    portName: "Shiwan",
    portContinent: "Asia",
  },
  {
    lat: "22.35232",
    lng: "112.6905",
    country: "CN",
    country_long: "China",
    portCode: "CNSBU",
    portName: "SANBU (CNSBU), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.772543",
    lng: "118.795956",
    country: "CN",
    country_long: "China",
    portCode: "CNSDG",
    portName: "Shuidong Pt",
    portContinent: "Asia",
  },
  {
    lat: "31.230416",
    lng: "121.473701",
    country: "CN",
    country_long: "China",
    portCode: "CNSGH",
    portName: "Shanghai",
    portContinent: "Asia",
  },
  {
    lat: "36.8554099",
    lng: "118.79098",
    country: "CN",
    country_long: "China",
    portCode: "CNSGN",
    portName: "Shouguang (CNSGN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNSGS",
    portName: "Songshu",
    portContinent: "Asia",
  },
  {
    lat: "30.0322699",
    lng: "120.86858",
    country: "CN",
    country_long: "China",
    portCode: "CNSGU",
    portName: "SHANGYU (ZHENJIANG)",
    portContinent: "Asia",
  },
  {
    lat: "29.5884899",
    lng: "120.8217",
    country: "CN",
    country_long: "China",
    portCode: "CNSGZ",
    portName: "SHENGZHOU (=SHENGXIAN) (ZHEJIANG)",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNSHA",
    portName: "Port of Shanghai",
    portContinent: "Asia",
  },
  {
    lat: "37.16516",
    lng: "122.486657",
    country: "CN",
    country_long: "China",
    portCode: "CNSHD",
    portName: "Shidao Pt",
    portContinent: "Asia",
  },
  {
    lat: "41.804778",
    lng: "123.432968",
    country: "CN",
    country_long: "China",
    portCode: "CNSHE",
    portName: "SHENYANG (CNSHE), Shenyeng, China",
    portContinent: "Asia",
  },
  {
    lat: "33.8703599",
    lng: "109.94041",
    country: "CN",
    country_long: "China",
    portCode: "CNSHG",
    portName: "SHANGLUO",
    portContinent: "Asia",
  },
  {
    lat: "24.7318999",
    lng: "118.64792",
    country: "CN",
    country_long: "China",
    portCode: "CNSHH",
    portName: "SHI SHI",
    portContinent: "Asia",
  },
  {
    lat: "25.5787726",
    lng: "91.8932535",
    country: "IN",
    country_long: "India",
    portCode: "CNSHI",
    portName: "Shilong",
    portContinent: "Asia",
  },
  {
    lat: "22.580498",
    lng: "113.860384",
    country: "CN",
    country_long: "China",
    portCode: "CNSHJ",
    portName: "SHAJING",
    portContinent: "Asia",
  },
  {
    lat: "-36.78845889999999",
    lng: "174.7726431",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "CNSHK",
    portName: "Shekou Pt",
    portContinent: "Asia",
  },
  {
    lat: "39.93544989999999",
    lng: "119.59964",
    country: "CN",
    country_long: "China",
    portCode: "CNSHP",
    portName: "Qinhuangdao Pt",
    portContinent: "Asia",
  },
  {
    lat: "22.52066",
    lng: "113.37224",
    country: "CN",
    country_long: "China",
    portCode: "CNSHQ",
    portName: "Shiqi",
    portContinent: "Asia",
  },
  {
    lat: "30.3109799",
    lng: "112.25494",
    country: "CN",
    country_long: "China",
    portCode: "CNSHS",
    portName: "SHASHI (CNSHS), Shashi, China",
    portContinent: "Asia",
  },
  {
    lat: "33.93524",
    lng: "119.62708",
    country: "CN",
    country_long: "China",
    portCode: "CNSHU",
    portName: "SHIHU",
    portContinent: "Asia",
  },
  {
    lat: "23.16498",
    lng: "112.892",
    country: "CN",
    country_long: "China",
    portCode: "CNSHX",
    portName: "Xinan",
    portContinent: "Asia",
  },
  {
    lat: "34.2658138",
    lng: "108.9540936",
    country: "CN",
    country_long: "China",
    portCode: "CNSIA",
    portName: "Xi An",
    portContinent: "Asia",
  },
  {
    lat: "23.3494899",
    lng: "112.87954",
    country: "CN",
    country_long: "China",
    portCode: "CNSIH",
    portName: "SIHUI (CNSIH), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNSIJ",
    portName: "Shijing",
    portContinent: "Asia",
  },
  {
    lat: "23.4605",
    lng: "113.15173",
    country: "CN",
    country_long: "China",
    portCode: "CNSIL",
    portName: "Shiling(Huadu)",
    portContinent: "Asia",
  },
  {
    lat: "22.919615",
    lng: "113.61818",
    country: "CN",
    country_long: "China",
    portCode: "CNSIN",
    portName: "SHATIAN (PSA) DONGGUAN",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNSIQ",
    portName: "Saiqi",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNSJE",
    portName: "Shijie",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNSJI",
    portName: "Sanjiao",
    portContinent: "Asia",
  },
  {
    lat: "23.2194569",
    lng: "113.0235861",
    country: "CN",
    country_long: "China",
    portCode: "CNSJQ",
    portName: "Sanshui",
    portContinent: "Asia",
  },
  {
    lat: "38.1461899",
    lng: "114.57109",
    country: "CN",
    country_long: "China",
    portCode: "CNSJW",
    portName: "Shijiazhuang Zhengding",
    portContinent: "Asia",
  },
  {
    lat: "38.0427599",
    lng: "114.5143",
    country: "CN",
    country_long: "China",
    portCode: "CNSJZ",
    portName: "SHIJAZHUANG",
    portContinent: "Asia",
  },
  {
    lat: "23.982764",
    lng: "115.896865",
    country: "CN",
    country_long: "China",
    portCode: "CNSKO",
    portName: "Shuikou",
    portContinent: "Asia",
  },
  {
    lat: "23.07951",
    lng: "113.35104",
    country: "CN",
    country_long: "China",
    portCode: "CNSLG",
    portName: "Shiliugang",
    portContinent: "Asia",
  },
  {
    lat: "36.9985699",
    lng: "110.83469",
    country: "CN",
    country_long: "China",
    portCode: "CNSLO",
    portName: "Shilou",
    portContinent: "Asia",
  },
  {
    lat: "26.2638499",
    lng: "117.63922",
    country: "CN",
    country_long: "China",
    portCode: "CNSMS",
    portName: "San Ming",
    portContinent: "Asia",
  },
  {
    lat: "34.7727068",
    lng: "111.2000526",
    country: "CN",
    country_long: "China",
    portCode: "CNSMX",
    portName: "SANMENXIA",
    portContinent: "Asia",
  },
  {
    lat: "31.0324099",
    lng: "121.22654",
    country: "CN",
    country_long: "China",
    portCode: "CNSNG",
    portName: "Port of Songjiang",
    portContinent: "Asia",
  },
  {
    lat: "24.61534",
    lng: "118.67529",
    country: "CN",
    country_long: "China",
    portCode: "CNSNH",
    portName: "Shenhu",
    portContinent: "Asia",
  },
  {
    lat: "23.1594051",
    lng: "113.2610841",
    country: "CN",
    country_long: "China",
    portCode: "CNSNL",
    portName: "Sanyuanli",
    portContinent: "Asia",
  },
  {
    lat: "30.5328599",
    lng: "105.59273",
    country: "CN",
    country_long: "China",
    portCode: "CNSNN",
    portName: "SUINING",
    portContinent: "Asia",
  },
  {
    lat: "22.302496",
    lng: "113.363816",
    country: "CN",
    country_long: "China",
    portCode: "CNSNW",
    portName: "Shenwan",
    portContinent: "Asia",
  },
  {
    lat: "40.0577176",
    lng: "116.8665467",
    country: "CN",
    country_long: "China",
    portCode: "CNSNY",
    portName: "Shunyi",
    portContinent: "Asia",
  },
  {
    lat: "22.5428599",
    lng: "114.05956",
    country: "CN",
    country_long: "China",
    portCode: "CNSNZ",
    portName: "Shenzhen (CNSNZ), Shenzhen, China",
    portContinent: "Asia",
  },
  {
    lat: "23.730104",
    lng: "113.593148",
    country: "CN",
    country_long: "China",
    portCode: "CNSON",
    portName: "Songxia (CNSON), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNSPG",
    portName: "Siping",
    portContinent: "Asia",
  },
  {
    lat: "23.08922",
    lng: "113.93997",
    country: "CN",
    country_long: "China",
    portCode: "CNSPI",
    portName: "Shipai",
    portContinent: "Asia",
  },
  {
    lat: "23.0468999",
    lng: "112.46528",
    country: "CN",
    country_long: "China",
    portCode: "CNSRG",
    portName: "SANRONG (CNSRG), Zhaoqing, China",
    portContinent: "Asia",
  },
  {
    lat: "28.4546299",
    lng: "117.9435699",
    country: "CN",
    country_long: "China",
    portCode: "CNSRS",
    portName: "Shangrao",
    portContinent: "Asia",
  },
  {
    lat: "23.12229",
    lng: "113.00744",
    country: "CN",
    country_long: "China",
    portCode: "CNSSA",
    portName: "Shishan",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNSSI",
    portName: "Shanshui",
    portContinent: "Asia",
  },
  {
    lat: "23.3534999",
    lng: "116.68221",
    country: "CN",
    country_long: "China",
    portCode: "CNSTG",
    portName: "Shantou Pt",
    portContinent: "Asia",
  },
  {
    lat: "22.919615",
    lng: "113.61818",
    country: "CN",
    country_long: "China",
    portCode: "CNSTI",
    portName: "Shatian",
    portContinent: "Asia",
  },
  {
    lat: "22.545886",
    lng: "114.221992",
    country: "HK",
    country_long: "Hong Kong",
    portCode: "CNSTJ",
    portName: "SHATOUJIAO",
    portContinent: "Asia",
  },
  {
    lat: "24.5238661",
    lng: "120.9302603",
    country: "TW",
    country_long: "Taiwan",
    portCode: "CNSTN",
    portName: "Shitan",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNSTO",
    portName: "Shatou",
    portContinent: "Asia",
  },
  {
    lat: "22.327084",
    lng: "112.799544",
    country: "CN",
    country_long: "China",
    portCode: "CNSUB",
    portName: "Shuibu",
    portContinent: "Asia",
  },
  {
    lat: "22.8053704",
    lng: "113.293215",
    country: "CN",
    country_long: "China",
    portCode: "CNSUD",
    portName: "Shunde (CNSUD), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.29833989999999",
    lng: "120.58319",
    country: "CN",
    country_long: "China",
    portCode: "CNSUH",
    portName: "SUZHOU",
    portContinent: "Asia",
  },
  {
    lat: "23.0976523",
    lng: "113.4492496",
    country: "CN",
    country_long: "China",
    portCode: "CNSUI",
    portName: "SUIGANG HUANGPU (CNSUI), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.9619299",
    lng: "118.27549",
    country: "CN",
    country_long: "China",
    portCode: "CNSUQ",
    portName: "SUQIAN (JIANGSU)",
    portContinent: "Asia",
  },
  {
    lat: "22.6101",
    lng: "112.47415",
    country: "CN",
    country_long: "China",
    portCode: "CNSUT",
    portName: "Shuitai(Xinxing)",
    portContinent: "Asia",
  },
  {
    lat: "23.3534999",
    lng: "116.68221",
    country: "CN",
    country_long: "China",
    portCode: "CNSWA",
    portName: "Shantou (CNSWA), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.7856599",
    lng: "115.3751399",
    country: "CN",
    country_long: "China",
    portCode: "CNSWE",
    portName: "Shanwei (CNSWE), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "29.4130599",
    lng: "103.55027",
    country: "CN",
    country_long: "China",
    portCode: "CNSWN",
    portName: "SHAWAN",
    portContinent: "Asia",
  },
  {
    lat: "22.35798",
    lng: "113.44142",
    country: "CN",
    country_long: "China",
    portCode: "CNSXA",
    portName: "Sanxiang",
    portContinent: "Asia",
  },
  {
    lat: "29.995762",
    lng: "120.586109",
    country: "CN",
    country_long: "China",
    portCode: "CNSXG",
    portName: "Shaoxing",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNSXJ",
    portName: "SHANSHAN",
    portContinent: "Asia",
  },
  {
    lat: "41.804778",
    lng: "123.432968",
    country: "CN",
    country_long: "China",
    portCode: "CNSYA",
    portName: "Sheyang (CNSYA), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.6291799",
    lng: "110.79801",
    country: "CN",
    country_long: "China",
    portCode: "CNSYN",
    portName: "Shiyan",
    portContinent: "Asia",
  },
  {
    lat: "30.0322699",
    lng: "120.86858",
    country: "CN",
    country_long: "China",
    portCode: "CNSYU",
    portName: "Shangyu",
    portContinent: "Asia",
  },
  {
    lat: "18.2524799",
    lng: "109.51209",
    country: "CN",
    country_long: "China",
    portCode: "CNSYX",
    portName: "Sanya (CNSYX), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.04766",
    lng: "113.35168",
    country: "CN",
    country_long: "China",
    portCode: "CNSZA",
    portName: "Sanzao",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNSZH",
    portName: "Port of Suzhou",
    portContinent: "Asia",
  },
  {
    lat: "30.3046",
    lng: "111.631301",
    country: "CN",
    country_long: "China",
    portCode: "CNSZK",
    portName: "Songzikou",
    portContinent: "Asia",
  },
  {
    lat: "22.5428599",
    lng: "114.05956",
    country: "CN",
    country_long: "China",
    portCode: "CNSZP",
    portName: "SHENZHEN CFS (GUANGDONG)",
    portContinent: "Asia",
  },
  {
    lat: "22.5428599",
    lng: "114.05956",
    country: "CN",
    country_long: "China",
    portCode: "CNSZX",
    portName: "Shenzhen",
    portContinent: "Asia",
  },
  {
    lat: "31.54987989999999",
    lng: "121.25804",
    country: "CN",
    country_long: "China",
    portCode: "CNTAC",
    portName: "Taicang Pt",
    portContinent: "Asia",
  },
  {
    lat: "31.54987989999999",
    lng: "121.25804",
    country: "CN",
    country_long: "China",
    portCode: "CNTAG",
    portName: "Taicang (CNTAG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.1999399",
    lng: "117.0884",
    country: "CN",
    country_long: "China",
    portCode: "CNTAI",
    portName: "Taian",
    portContinent: "Asia",
  },
  {
    lat: "22.25462",
    lng: "113.46785",
    country: "CN",
    country_long: "China",
    portCode: "CNTAN",
    portName: "Tanzhou",
    portContinent: "Asia",
  },
  {
    lat: "36.014071",
    lng: "120.202027",
    country: "CN",
    country_long: "China",
    portCode: "CNTAO",
    portName: "Port of Qingdao",
    portContinent: "Asia",
  },
  {
    lat: "35.86166",
    lng: "104.195397",
    country: "CN",
    country_long: "China",
    portCode: "CNTAP",
    portName: "TAIPING (CNTAP), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "30.9211899",
    lng: "104.41976",
    country: "CN",
    country_long: "China",
    portCode: "CNTAS",
    portName: "Jingtang (CNTAS), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.2945171",
    lng: "119.9488266",
    country: "CN",
    country_long: "China",
    portCode: "CNTAX",
    portName: "Taixing (CNTAX), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNTEN",
    portName: "FENHUA",
    portContinent: "Asia",
  },
  {
    lat: "41.7282899",
    lng: "125.9399",
    country: "CN",
    country_long: "China",
    portCode: "CNTGH",
    portName: "Tonghua",
    portContinent: "Asia",
  },
  {
    lat: "42.378586",
    lng: "-71.049083",
    country: "US",
    country_long: "United States",
    portCode: "CNTGN",
    portName: "Tong Ren",
    portContinent: "Asia",
  },
  {
    lat: "39.6304499",
    lng: "118.18022",
    country: "CN",
    country_long: "China",
    portCode: "CNTGS",
    portName: "TANG SHAN",
    portContinent: "Asia",
  },
  {
    lat: "33.5416899",
    lng: "115.47472",
    country: "CN",
    country_long: "China",
    portCode: "CNTHE",
    portName: "Taihe",
    portContinent: "Asia",
  },
  {
    lat: "34.7688528",
    lng: "137.3916837",
    country: "JP",
    country_long: "Japan",
    portCode: "CNTHS",
    portName: "Toyohashi",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNTIH",
    portName: "TIANHE",
    portContinent: "Asia",
  },
  {
    lat: "42.2861999",
    lng: "123.84241",
    country: "CN",
    country_long: "China",
    portCode: "CNTIL",
    portName: "Tieling",
    portContinent: "Asia",
  },
  {
    lat: "22.2515599",
    lng: "112.7939",
    country: "CN",
    country_long: "China",
    portCode: "CNTIS",
    portName: "Taishan (CNTIS), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "28.6561099",
    lng: "121.42056",
    country: "CN",
    country_long: "China",
    portCode: "CNTIZ",
    portName: "TAIZHOU (ZHEJIANG)",
    portContinent: "Asia",
  },
  {
    lat: "26.0526999",
    lng: "119.31414",
    country: "CN",
    country_long: "China",
    portCode: "CNTJG",
    portName: "TAIJIANG",
    portContinent: "Asia",
  },
  {
    lat: "42.2861999",
    lng: "123.84241",
    country: "CN",
    country_long: "China",
    portCode: "CNTLI",
    portName: "Tie Ling",
    portContinent: "Asia",
  },
  {
    lat: "42.49638202036756",
    lng: "129.5727514781101",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNTME",
    portName: "Tumen",
    portContinent: "Asia",
  },
  {
    lat: "36.6518399",
    lng: "117.12009",
    country: "CN",
    country_long: "China",
    portCode: "CNTNA",
    portName: "JINAN (SHANDONG)",
    portContinent: "Asia",
  },
  {
    lat: "39.085099",
    lng: "117.199369",
    country: "CN",
    country_long: "China",
    portCode: "CNTNJ",
    portName: "Tianjin, 12, CN",
    portContinent: "Asia",
  },
  {
    lat: "30.9448599",
    lng: "117.81232",
    country: "CN",
    country_long: "China",
    portCode: "CNTOL",
    portName: "Tongling Pt",
    portContinent: "Asia",
  },
  {
    lat: "27.03",
    lng: "88.08999999999999",
    country: "IN",
    country_long: "India",
    portCode: "CNTON",
    portName: "Tonglu",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNTOU",
    portName: "Tongyu",
    portContinent: "Asia",
  },
  {
    lat: "39.7485799",
    lng: "116.5629299",
    country: "CN",
    country_long: "China",
    portCode: "CNTOZ",
    portName: "Tongzhou",
    portContinent: "Asia",
  },
  {
    lat: "22.3083934",
    lng: "113.923907",
    country: "HK",
    country_long: "Hong Kong",
    portCode: "CNTPO",
    portName: "Chek Lap Kok (CNTPO), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.583803",
    lng: "113.720918",
    country: "CN",
    country_long: "China",
    portCode: "CNTQO",
    portName: "Tuqiao",
    portContinent: "Asia",
  },
  {
    lat: "32.0149399",
    lng: "120.27454",
    country: "CN",
    country_long: "China",
    portCode: "CNTSI",
    portName: "Jingjiang (CNTSI), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "39.085099",
    lng: "117.199369",
    country: "CN",
    country_long: "China",
    portCode: "CNTSN",
    portName: "Tianjin",
    portContinent: "Asia",
  },
  {
    lat: "22.806965",
    lng: "114.072575",
    country: "CN",
    country_long: "China",
    portCode: "CNTXA",
    portName: "TANGXIA",
    portContinent: "Asia",
  },
  {
    lat: "39.085099",
    lng: "117.199369",
    country: "CN",
    country_long: "China",
    portCode: "CNTXG",
    portName: "Tianjin (CNTXG), Tianjin, China",
    portContinent: "Asia",
  },
  {
    lat: "29.7365199",
    lng: "118.29183",
    country: "CN",
    country_long: "China",
    portCode: "CNTXN",
    portName: "Huangshan Tunxi International",
    portContinent: "Asia",
  },
  {
    lat: "30.6307399",
    lng: "120.56432",
    country: "CN",
    country_long: "China",
    portCode: "CNTXZ",
    portName: "TONGXIANG",
    portContinent: "Asia",
  },
  {
    lat: "24.94557",
    lng: "113.43803",
    country: "CN",
    country_long: "China",
    portCode: "CNTYE",
    portName: "Taoyuan Jiedao (CNTYE), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.8705899",
    lng: "112.55067",
    country: "CN",
    country_long: "China",
    portCode: "CNTYN",
    portName: "TAIYUAN (CNTYN), Taiyuan, China",
    portContinent: "Asia",
  },
  {
    lat: "28.6561099",
    lng: "121.42056",
    country: "CN",
    country_long: "China",
    portCode: "CNTZO",
    portName: "Taizhou",
    portContinent: "Asia",
  },
  {
    lat: "40.9948599",
    lng: "113.13201",
    country: "CN",
    country_long: "China",
    portCode: "CNULA",
    portName: "Ulan Qab",
    portContinent: "Asia",
  },
  {
    lat: "43.8266299",
    lng: "87.61688",
    country: "CN",
    country_long: "China",
    portCode: "CNURM",
    portName: "ÃœrÃ¼mqi, WU LU MU QI",
    portContinent: "Asia",
  },
  {
    lat: "22.554571",
    lng: "114.057378",
    country: "CN",
    country_long: "China",
    portCode: "CNVHS",
    portName: "Lianhuashan",
    portContinent: "Asia",
  },
  {
    lat: "39.6264399",
    lng: "121.97995",
    country: "CN",
    country_long: "China",
    portCode: "CNWAF",
    portName: "Wafangdian",
    portContinent: "Asia",
  },
  {
    lat: "30.82322",
    lng: "108.35855",
    country: "CN",
    country_long: "China",
    portCode: "CNWAH",
    portName: "WANZHOU (CNWAH), Wanzhou, China",
    portContinent: "Asia",
  },
  {
    lat: "22.59241",
    lng: "113.15533",
    country: "CN",
    country_long: "China",
    portCode: "CNWAI",
    portName: "JIANGMEN (WAI HAI TERMINAL)",
    portContinent: "Asia",
  },
  {
    lat: "23.047104",
    lng: "113.738543",
    country: "CN",
    country_long: "China",
    portCode: "CNWAJ",
    portName: "Wanjiang",
    portContinent: "Asia",
  },
  {
    lat: "23.05537",
    lng: "113.65617",
    country: "CN",
    country_long: "China",
    portCode: "CNWAN",
    portName: "Wangniudun",
    portContinent: "Asia",
  },
  {
    lat: "28.1060999",
    lng: "114.44551",
    country: "CN",
    country_long: "China",
    portCode: "CNWAZ",
    portName: "WANZAI (CNWAZ), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.1939699",
    lng: "122.0581",
    country: "CN",
    country_long: "China",
    portCode: "CNWDS",
    portName: "Wendeng",
    portContinent: "Asia",
  },
  {
    lat: "36.7068599",
    lng: "119.16176",
    country: "CN",
    country_long: "China",
    portCode: "CNWEF",
    portName: "WEIFANG",
    portContinent: "Asia",
  },
  {
    lat: "37.5134799",
    lng: "122.12171",
    country: "CN",
    country_long: "China",
    portCode: "CNWEI",
    portName: "Weihai",
    portContinent: "Asia",
  },
  {
    lat: "28.3717999",
    lng: "121.38599",
    country: "CN",
    country_long: "China",
    portCode: "CNWEN",
    portName: "Wenling",
    portContinent: "Asia",
  },
  {
    lat: "36.7068599",
    lng: "119.16176",
    country: "CN",
    country_long: "China",
    portCode: "CNWFG",
    portName: "WEIFANG (CNWFG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNWGQ",
    portName: "Waigaoqiao",
    portContinent: "Asia",
  },
  {
    lat: "31.3524599",
    lng: "118.43313",
    country: "CN",
    country_long: "China",
    portCode: "CNWHI",
    portName: "Wuhu Pt",
    portContinent: "Asia",
  },
  {
    lat: "22.593731",
    lng: "113.147611",
    country: "CN",
    country_long: "China",
    portCode: "CNWIH",
    portName: "Waihai",
    portContinent: "Asia",
  },
  {
    lat: "31.11130989999999",
    lng: "120.84753",
    country: "CN",
    country_long: "China",
    portCode: "CNWJI",
    portName: "Wujiang",
    portContinent: "Asia",
  },
  {
    lat: "34.4999699",
    lng: "109.51015",
    country: "CN",
    country_long: "China",
    portCode: "CNWNA",
    portName: "Weinan",
    portContinent: "Asia",
  },
  {
    lat: "37.15268",
    lng: "116.27655",
    country: "CN",
    country_long: "China",
    portCode: "CNWNC",
    portName: "Encheng",
    portContinent: "Asia",
  },
  {
    lat: "27.9949199",
    lng: "120.69939",
    country: "CN",
    country_long: "China",
    portCode: "CNWNZ",
    portName: "Wenzhou (CNWNZ), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.3985076",
    lng: "104.2150506",
    country: "CN",
    country_long: "China",
    portCode: "CNWSH",
    portName: "Wen Shan",
    portContinent: "Asia",
  },
  {
    lat: "24.805849",
    lng: "118.589485",
    country: "CN",
    country_long: "China",
    portCode: "CNWTU",
    portName: "Weitou (CNWTU), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.517243",
    lng: "116.245127",
    country: "CN",
    country_long: "China",
    portCode: "CNWUC",
    portName: "Wuchongkou",
    portContinent: "Asia",
  },
  {
    lat: "37.9975499",
    lng: "106.19879",
    country: "CN",
    country_long: "China",
    portCode: "CNWUG",
    portName: "WUZHONG",
    portContinent: "Asia",
  },
  {
    lat: "30.5927599",
    lng: "114.30525",
    country: "CN",
    country_long: "China",
    portCode: "CNWUH",
    portName: "Wuhan (CNWUH), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.11130989999999",
    lng: "120.84753",
    country: "CN",
    country_long: "China",
    portCode: "CNWUJ",
    portName: "Port of Wujiang",
    portContinent: "Asia",
  },
  {
    lat: "31.4909899",
    lng: "120.31237",
    country: "CN",
    country_long: "China",
    portCode: "CNWUX",
    portName: "Wuxi",
    portContinent: "Asia",
  },
  {
    lat: "23.4769099",
    lng: "111.27917",
    country: "CN",
    country_long: "China",
    portCode: "CNWUZ",
    portName: "Wuzhou Pt",
    portContinent: "Asia",
  },
  {
    lat: "29.8443299",
    lng: "115.5613",
    country: "CN",
    country_long: "China",
    portCode: "CNWXP",
    portName: "Wuxue (CNWXP), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "27.9949199",
    lng: "120.69939",
    country: "CN",
    country_long: "China",
    portCode: "CNWZO",
    portName: "Wenzhou Pt",
    portContinent: "Asia",
  },
  {
    lat: "24.9132859",
    lng: "120.9991032",
    country: "TW",
    country_long: "Taiwan",
    portCode: "CNXAB",
    portName: "Xinfeng",
    portContinent: "Asia",
  },
  {
    lat: "24.4795099",
    lng: "118.0894799",
    country: "CN",
    country_long: "China",
    portCode: "CNXAM",
    portName: "Xiamen",
    portContinent: "Asia",
  },
  {
    lat: "22.672099",
    lng: "113.250897",
    country: "CN",
    country_long: "China",
    portCode: "CNXAO",
    portName: "Xiaolan",
    portContinent: "Asia",
  },
  {
    lat: "21.1923893",
    lng: "110.3976631",
    country: "CN",
    country_long: "China",
    portCode: "CNXAS",
    portName: "Xiashan",
    portContinent: "Asia",
  },
  {
    lat: "34.2658138",
    lng: "108.9540936",
    country: "CN",
    country_long: "China",
    portCode: "CNXAV",
    portName: "Xi'an",
    portContinent: "Asia",
  },
  {
    lat: "40.6101799",
    lng: "120.75619",
    country: "CN",
    country_long: "China",
    portCode: "CNXCE",
    portName: "XING CHENG  (LIAONING)",
    portContinent: "Asia",
  },
  {
    lat: "22.96101",
    lng: "114.14858",
    country: "CN",
    country_long: "China",
    portCode: "CNXEG",
    portName: "XIEGANG",
    portContinent: "Asia",
  },
  {
    lat: "40.6101799",
    lng: "120.75619",
    country: "CN",
    country_long: "China",
    portCode: "CNXEN",
    portName: "Xingcheng",
    portContinent: "Asia",
  },
  {
    lat: "23.21267",
    lng: "113.42471",
    country: "CN",
    country_long: "China",
    portCode: "CNXFG",
    portName: "Xinfeng (CNXFG), Guangzhou, China",
    portContinent: "Asia",
  },
  {
    lat: "38.99196",
    lng: "117.751",
    country: "CN",
    country_long: "China",
    portCode: "CNXGA",
    portName: "Xingang",
    portContinent: "Asia",
  },
  {
    lat: "30.9248299",
    lng: "113.91645",
    country: "CN",
    country_long: "China",
    portCode: "CNXGN",
    portName: "Xiao Gan",
    portContinent: "Asia",
  },
  {
    lat: "22.7893",
    lng: "113.15208",
    country: "CN",
    country_long: "China",
    portCode: "CNXGT",
    portName: "Xingtan",
    portContinent: "Asia",
  },
  {
    lat: "34.2658138",
    lng: "108.9540936",
    country: "CN",
    country_long: "China",
    portCode: "CNXIA",
    portName: "Xian",
    portContinent: "Asia",
  },
  {
    lat: "27.8944899",
    lng: "102.26305",
    country: "CN",
    country_long: "China",
    portCode: "CNXIC",
    portName: "XICHANG",
    portContinent: "Asia",
  },
  {
    lat: "45.2952399",
    lng: "130.96954",
    country: "CN",
    country_long: "China",
    portCode: "CNXII",
    portName: "Jixi",
    portContinent: "Asia",
  },
  {
    lat: "22.672099",
    lng: "113.250897",
    country: "CN",
    country_long: "China",
    portCode: "CNXIL",
    portName: "Xiaolan",
    portContinent: "Asia",
  },
  {
    lat: "22.4587699",
    lng: "113.03231",
    country: "CN",
    country_long: "China",
    portCode: "CNXIN",
    portName: "Xinhui Pt",
    portContinent: "Asia",
  },
  {
    lat: "38.99196",
    lng: "117.751",
    country: "CN",
    country_long: "China",
    portCode: "CNXIP",
    portName: "Xingang (CNXIP), Tianjin, China",
    portContinent: "Asia",
  },
  {
    lat: "22.938069",
    lng: "112.987173",
    country: "CN",
    country_long: "China",
    portCode: "CNXIQ",
    portName: "XIQIAO",
    portContinent: "Asia",
  },
  {
    lat: "30.1853399",
    lng: "120.26457",
    country: "CN",
    country_long: "China",
    portCode: "CNXIS",
    portName: "Xiaoshan",
    portContinent: "Asia",
  },
  {
    lat: "23.296786",
    lng: "113.823135",
    country: "CN",
    country_long: "China",
    portCode: "CNXIT",
    portName: "Xintang",
    portContinent: "Asia",
  },
  {
    lat: "25.3183899",
    lng: "119.10642",
    country: "CN",
    country_long: "China",
    portCode: "CNXIU",
    portName: "Xiuyu (CNXIU), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.0089999",
    lng: "112.12255",
    country: "CN",
    country_long: "China",
    portCode: "CNXIW",
    portName: "Xiang Yang",
    portContinent: "Asia",
  },
  {
    lat: "20.0075999",
    lng: "110.29357",
    country: "CN",
    country_long: "China",
    portCode: "CNXIY",
    portName: "Xiuying",
    portContinent: "Asia",
  },
  {
    lat: "22.2666599",
    lng: "113.54342",
    country: "CN",
    country_long: "China",
    portCode: "CNXIZ",
    portName: "Xiangzhou (CNXIZ), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "24.5711891",
    lng: "118.0400651",
    country: "CN",
    country_long: "China",
    portCode: "CNXLN",
    portName: "XINGLIN",
    portContinent: "Asia",
  },
  {
    lat: "24.4840499",
    lng: "118.0339399",
    country: "CN",
    country_long: "China",
    portCode: "CNXMH",
    portName: "XIAMEN HAICANG",
    portContinent: "Asia",
  },
  {
    lat: "24.4795099",
    lng: "118.0894799",
    country: "CN",
    country_long: "China",
    portCode: "CNXMN",
    portName: "Xiamen (CNXMN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.944537",
    lng: "113.512998",
    country: "CN",
    country_long: "China",
    portCode: "CNXNA",
    portName: "Xinsha (CNXNA), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "28.337938",
    lng: "112.954825",
    country: "CN",
    country_long: "China",
    portCode: "CNXNG",
    portName: "Xianing (CNXNG), Changsha, China",
    portContinent: "Asia",
  },
  {
    lat: "41.4794738",
    lng: "-81.7151552",
    country: "US",
    country_long: "United States",
    portCode: "CNXNJ",
    portName: "Xinji",
    portContinent: "Asia",
  },
  {
    lat: "36.6172899",
    lng: "101.77782",
    country: "CN",
    country_long: "China",
    portCode: "CNXNT",
    portName: "Xining",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNXNY",
    portName: "Xin Yu",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNXOT",
    portName: "Xiaotang",
    portContinent: "Asia",
  },
  {
    lat: "29.4766499",
    lng: "121.86932",
    country: "CN",
    country_long: "China",
    portCode: "CNXSN",
    portName: "Xiangshan (CNXSN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "29.203205",
    lng: "121.931399",
    country: "CN",
    country_long: "China",
    portCode: "CNXSP",
    portName: "Shipu (CNXSP), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "27.7790399",
    lng: "112.95099",
    country: "CN",
    country_long: "China",
    portCode: "CNXTA",
    portName: "XIANGTAN (CNXTA), Xiangtan, China",
    portContinent: "Asia",
  },
  {
    lat: "23.04162",
    lng: "112.68028",
    country: "CN",
    country_long: "China",
    portCode: "CNXUG",
    portName: "Xiangang",
    portContinent: "Asia",
  },
  {
    lat: "40.2899799",
    lng: "123.28171",
    country: "CN",
    country_long: "China",
    portCode: "CNXUY",
    portName: "XIUYAN  (LIAONING)",
    portContinent: "Asia",
  },
  {
    lat: "34.2043999",
    lng: "117.28577",
    country: "CN",
    country_long: "China",
    portCode: "CNXUZ",
    portName: "Xuzhou Harbour",
    portContinent: "Asia",
  },
  {
    lat: "32.5330799",
    lng: "120.46759",
    country: "CN",
    country_long: "China",
    portCode: "CNXWH",
    portName: "Haian",
    portContinent: "Asia",
  },
  {
    lat: "29.4998299",
    lng: "120.90385",
    country: "CN",
    country_long: "China",
    portCode: "CNXXI",
    portName: "Xinchang",
    portContinent: "Asia",
  },
  {
    lat: "32.9830599",
    lng: "107.76669",
    country: "CN",
    country_long: "China",
    portCode: "CNXXN",
    portName: "Xixiang",
    portContinent: "Asia",
  },
  {
    lat: "24.511031",
    lng: "118.087866",
    country: "CN",
    country_long: "China",
    portCode: "CNXXY",
    portName: "Xiangyu Terminal/ Xiamen",
    portContinent: "Asia",
  },
  {
    lat: "23.035514",
    lng: "113.417643",
    country: "CN",
    country_long: "China",
    portCode: "CNXZO",
    portName: "Xinzao (CNXZO), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "21.8682899",
    lng: "112.0067",
    country: "CN",
    country_long: "China",
    portCode: "CNYAD",
    portName: "Yangdong",
    portContinent: "Asia",
  },
  {
    lat: "24.466675",
    lng: "112.626495",
    country: "CN",
    country_long: "China",
    portCode: "CNYAN",
    portName: "Yangshan (CNYAN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.4635299",
    lng: "121.44801",
    country: "CN",
    country_long: "China",
    portCode: "CNYAT",
    portName: "Yantai",
    portContinent: "Asia",
  },
  {
    lat: "24.0231757",
    lng: "38.1899782",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "CNYBU",
    portName: "Yanbu",
    portContinent: "Asia",
  },
  {
    lat: "30.69177969999999",
    lng: "111.2863313",
    country: "CN",
    country_long: "China",
    portCode: "CNYCG",
    portName: "Yichang Pt",
    portContinent: "Asia",
  },
  {
    lat: "38.4862899",
    lng: "106.23245",
    country: "CN",
    country_long: "China",
    portCode: "CNYCH",
    portName: "Yinchuan",
    portContinent: "Asia",
  },
  {
    lat: "22.1702999",
    lng: "111.79153",
    country: "CN",
    country_long: "China",
    portCode: "CNYCN",
    portName: "YANGCHUN",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNYFU",
    portName: "Yongfu",
    portContinent: "Asia",
  },
  {
    lat: "31.2595599",
    lng: "121.52609",
    country: "CN",
    country_long: "China",
    portCode: "CNYGP",
    portName: "Yangpu",
    portContinent: "Asia",
  },
  {
    lat: "28.2601899",
    lng: "117.06919",
    country: "CN",
    country_long: "China",
    portCode: "CNYGT",
    portName: "YINGTAN (CNYGT), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "30.4187503",
    lng: "120.2985013",
    country: "CN",
    country_long: "China",
    portCode: "CNYHN",
    portName: "Yuhang",
    portContinent: "Asia",
  },
  {
    lat: "33.3495099",
    lng: "120.16164",
    country: "CN",
    country_long: "China",
    portCode: "CNYHZ",
    portName: "YANCHENG",
    portContinent: "Asia",
  },
  {
    lat: "28.6899799",
    lng: "104.53316",
    country: "CN",
    country_long: "China",
    portCode: "CNYIB",
    portName: "Yibin (CNYIB), Yibin, China",
    portContinent: "Asia",
  },
  {
    lat: "30.69177969999999",
    lng: "111.2863313",
    country: "CN",
    country_long: "China",
    portCode: "CNYIC",
    portName: "Yichang",
    portContinent: "Asia",
  },
  {
    lat: "40.6659899",
    lng: "122.23458",
    country: "CN",
    country_long: "China",
    portCode: "CNYIK",
    portName: "YINGKOU",
    portContinent: "Asia",
  },
  {
    lat: "29.30557989999999",
    lng: "120.0746799",
    country: "CN",
    country_long: "China",
    portCode: "CNYIW",
    portName: "Yiwu",
    portContinent: "Asia",
  },
  {
    lat: "31.3404199",
    lng: "119.82346",
    country: "CN",
    country_long: "China",
    portCode: "CNYIX",
    portName: "Port of Yixing",
    portContinent: "Asia",
  },
  {
    lat: "32.2720299",
    lng: "119.18444",
    country: "CN",
    country_long: "China",
    portCode: "CNYIZ",
    portName: "Yizheng (CNYIZ), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNYJA",
    portName: "Yan Ji",
    portContinent: "Asia",
  },
  {
    lat: "21.8582899",
    lng: "111.98256",
    country: "CN",
    country_long: "China",
    portCode: "CNYJI",
    portName: "Yangjiang (CNYJI), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "28.8885099",
    lng: "120.0473099",
    country: "CN",
    country_long: "China",
    portCode: "CNYKN",
    portName: "Yongkang",
    portContinent: "Asia",
  },
  {
    lat: "32.53729",
    lng: "121.01762",
    country: "CN",
    country_long: "China",
    portCode: "CNYKO",
    portName: "Yangkou (CNYKO), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.0218499",
    lng: "113.12192",
    country: "CN",
    country_long: "China",
    portCode: "CNYNF",
    portName: "Yunfu (CNYNF), Foshan, China",
    portContinent: "Asia",
  },
  {
    lat: "32.2792599",
    lng: "119.733",
    country: "CN",
    country_long: "China",
    portCode: "CNYNG",
    portName: "YANGZHONG",
    portContinent: "Asia",
  },
  {
    lat: "42.8910699",
    lng: "129.5091",
    country: "CN",
    country_long: "China",
    portCode: "CNYNJ",
    portName: "Yanji",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNYNT",
    portName: "Port of Yantai",
    portContinent: "Asia",
  },
  {
    lat: "33.3495099",
    lng: "120.16164",
    country: "CN",
    country_long: "China",
    portCode: "CNYNZ",
    portName: "Yancheng",
    portContinent: "Asia",
  },
  {
    lat: "28.1533599",
    lng: "120.6917099",
    country: "CN",
    country_long: "China",
    portCode: "CNYOJ",
    portName: "Yongjia",
    portContinent: "Asia",
  },
  {
    lat: "30.03710989999999",
    lng: "121.15435",
    country: "CN",
    country_long: "China",
    portCode: "CNYOU",
    portName: "Yuyao",
    portContinent: "Asia",
  },
  {
    lat: "31.2595599",
    lng: "121.52609",
    country: "CN",
    country_long: "China",
    portCode: "CNYPG",
    portName: "Yangpu (CNYPG), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.928305",
    lng: "113.207187",
    country: "CN",
    country_long: "China",
    portCode: "CNYQS",
    portName: "Beijiao",
    portContinent: "Asia",
  },
  {
    lat: "39.39946",
    lng: "122.26546",
    country: "CN",
    country_long: "China",
    portCode: "CNYSF",
    portName: "Yangshufang",
    portContinent: "Asia",
  },
  {
    lat: "22.5569999",
    lng: "114.23679",
    country: "CN",
    country_long: "China",
    portCode: "CNYTN",
    portName: "Yantian Pt",
    portContinent: "Asia",
  },
  {
    lat: "37.4635299",
    lng: "121.44801",
    country: "CN",
    country_long: "China",
    portCode: "CNYTP",
    portName: "YANTAI",
    portContinent: "Asia",
  },
  {
    lat: "37.6997799",
    lng: "112.70746",
    country: "CN",
    country_long: "China",
    portCode: "CNYUC",
    portName: "Yuci",
    portContinent: "Asia",
  },
  {
    lat: "28.1128899",
    lng: "120.9833799",
    country: "CN",
    country_long: "China",
    portCode: "CNYUE",
    portName: "Yueqing",
    portContinent: "Asia",
  },
  {
    lat: "22.9152499",
    lng: "112.04453",
    country: "CN",
    country_long: "China",
    portCode: "CNYUF",
    portName: "Yunfu",
    portContinent: "Asia",
  },
  {
    lat: "28.1358899",
    lng: "121.23164",
    country: "CN",
    country_long: "China",
    portCode: "CNYUH",
    portName: "Yuhuan",
    portContinent: "Asia",
  },
  {
    lat: "35.0262799",
    lng: "111.00699",
    country: "CN",
    country_long: "China",
    portCode: "CNYUN",
    portName: "Yuncheng",
    portContinent: "Asia",
  },
  {
    lat: "27.8144299",
    lng: "114.41612",
    country: "CN",
    country_long: "China",
    portCode: "CNYUO",
    portName: "Yichun",
    portContinent: "Asia",
  },
  {
    lat: "33.0052799",
    lng: "97.0065",
    country: "CN",
    country_long: "China",
    portCode: "CNYUS",
    portName: "Yushu",
    portContinent: "Asia",
  },
  {
    lat: "29.3572799",
    lng: "113.12919",
    country: "CN",
    country_long: "China",
    portCode: "CNYUY",
    portName: "Yueyang",
    portContinent: "Asia",
  },
  {
    lat: "29.3572799",
    lng: "113.12919",
    country: "CN",
    country_long: "China",
    portCode: "CNYYA",
    portName: "Yue Yang",
    portContinent: "Asia",
  },
  {
    lat: "32.2792599",
    lng: "119.733",
    country: "CN",
    country_long: "China",
    portCode: "CNYZG",
    portName: "YANGZHONG",
    portContinent: "Asia",
  },
  {
    lat: "32.2730117",
    lng: "119.4428512",
    country: "CN",
    country_long: "China",
    portCode: "CNYZH",
    portName: "Port of Yangzhou",
    portContinent: "Asia",
  },
  {
    lat: "26.4203399",
    lng: "111.61225",
    country: "CN",
    country_long: "China",
    portCode: "CNYZS",
    portName: "YONGZHOU",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CNYZU",
    portName: "YUZHU",
    portContinent: "Asia",
  },
  {
    lat: "39.6803699",
    lng: "122.96611",
    country: "CN",
    country_long: "China",
    portCode: "CNZAH",
    portName: "ZHUANGHE CITY  (LIAONING)",
    portContinent: "Asia",
  },
  {
    lat: "34.8107099",
    lng: "117.32196",
    country: "CN",
    country_long: "China",
    portCode: "CNZAO",
    portName: "ZAOZHUANG",
    portContinent: "Asia",
  },
  {
    lat: "27.3381699",
    lng: "103.7168",
    country: "CN",
    country_long: "China",
    portCode: "CNZAT",
    portName: "Zhao Tong",
    portContinent: "Asia",
  },
  {
    lat: "36.8130999",
    lng: "118.0548",
    country: "CN",
    country_long: "China",
    portCode: "CNZBO",
    portName: "Zibo",
    portContinent: "Asia",
  },
  {
    lat: "35.9958299",
    lng: "119.4098299",
    country: "CN",
    country_long: "China",
    portCode: "CNZCH",
    portName: "Zhucheng",
    portContinent: "Asia",
  },
  {
    lat: "22.5159499",
    lng: "113.3926",
    country: "CN",
    country_long: "China",
    portCode: "CNZGA",
    portName: "Zhongshan",
    portContinent: "Asia",
  },
  {
    lat: "24.1170999",
    lng: "117.61372",
    country: "CN",
    country_long: "China",
    portCode: "CNZGP",
    portName: "ZHANGPU",
    portContinent: "Asia",
  },
  {
    lat: "23.09261",
    lng: "113.65694",
    country: "CN",
    country_long: "China",
    portCode: "CNZGT",
    portName: "Zhongtang",
    portContinent: "Asia",
  },
  {
    lat: "22.914909",
    lng: "114.083278",
    country: "CN",
    country_long: "China",
    portCode: "CNZGU",
    portName: "ZHANGMUTOU",
    portContinent: "Asia",
  },
  {
    lat: "34.7472499",
    lng: "113.62493",
    country: "CN",
    country_long: "China",
    portCode: "CNZGZ",
    portName: "Zhengzhou",
    portContinent: "Asia",
  },
  {
    lat: "21.2713399",
    lng: "110.35894",
    country: "CN",
    country_long: "China",
    portCode: "CNZHA",
    portName: "Zhanjiang (CNZHA), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.1895899",
    lng: "119.425",
    country: "CN",
    country_long: "China",
    portCode: "CNZHE",
    portName: "Zhenjiang Pt",
    portContinent: "Asia",
  },
  {
    lat: "39.6803699",
    lng: "122.96611",
    country: "CN",
    country_long: "China",
    portCode: "CNZHH",
    portName: "ZUANGHE",
    portContinent: "Asia",
  },
  {
    lat: "29.9489899",
    lng: "121.71624",
    country: "CN",
    country_long: "China",
    portCode: "CNZHI",
    portName: "Zhenhai",
    portContinent: "Asia",
  },
  {
    lat: "29.7136399",
    lng: "120.23632",
    country: "CN",
    country_long: "China",
    portCode: "CNZHJ",
    portName: "Zhuji",
    portContinent: "Asia",
  },
  {
    lat: "22.2708599",
    lng: "113.57666",
    country: "CN",
    country_long: "China",
    portCode: "CNZHU",
    portName: "Zhuhai/Zhu Ma Dian",
    portContinent: "Asia",
  },
  {
    lat: "36.8130999",
    lng: "118.0548",
    country: "CN",
    country_long: "China",
    portCode: "CNZIB",
    portName: "ZIBO",
    portContinent: "Asia",
  },
  {
    lat: "30.12858989999999",
    lng: "104.6279799",
    country: "CN",
    country_long: "China",
    portCode: "CNZIY",
    portName: "ZIYANG",
    portContinent: "Asia",
  },
  {
    lat: "40.7686911",
    lng: "114.8855792",
    country: "CN",
    country_long: "China",
    portCode: "CNZJA",
    portName: "ZHANGJIAKOU",
    portContinent: "Asia",
  },
  {
    lat: "31.8754699",
    lng: "120.5555",
    country: "CN",
    country_long: "China",
    portCode: "CNZJG",
    portName: "Zhangjiagang",
    portContinent: "Asia",
  },
  {
    lat: "40.7686911",
    lng: "114.8855792",
    country: "CN",
    country_long: "China",
    portCode: "CNZJK",
    portName: "Zhangjiakou",
    portContinent: "Asia",
  },
  {
    lat: "33.0114199",
    lng: "114.02299",
    country: "CN",
    country_long: "China",
    portCode: "CNZMA",
    portName: "Zhumadian",
    portContinent: "Asia",
  },
  {
    lat: "21.2713399",
    lng: "110.35894",
    country: "CN",
    country_long: "China",
    portCode: "CNZNG",
    portName: "Zhanjiang Pt",
    portContinent: "Asia",
  },
  {
    lat: "27.7254499",
    lng: "106.92723",
    country: "CN",
    country_long: "China",
    portCode: "CNZNY",
    portName: "Zun yi",
    portContinent: "Asia",
  },
  {
    lat: "29.9853899",
    lng: "122.20778",
    country: "CN",
    country_long: "China",
    portCode: "CNZOS",
    portName: "Port of Zhoushan",
    portContinent: "Asia",
  },
  {
    lat: "30.60364",
    lng: "121.09569",
    country: "CN",
    country_long: "China",
    portCode: "CNZPU",
    portName: "ZHAPU",
    portContinent: "Asia",
  },
  {
    lat: "23.0468999",
    lng: "112.46528",
    country: "CN",
    country_long: "China",
    portCode: "CNZQG",
    portName: "Zhaoqing Pt",
    portContinent: "Asia",
  },
  {
    lat: "22.5159499",
    lng: "113.3926",
    country: "CN",
    country_long: "China",
    portCode: "CNZSN",
    portName: "Zhongshan (CNZSN), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.5159499",
    lng: "113.3926",
    country: "CN",
    country_long: "China",
    portCode: "CNZSS",
    portName: "Zhongshan Sinotrans",
    portContinent: "Asia",
  },
  {
    lat: "22.239662",
    lng: "113.593571",
    country: "CN",
    country_long: "China",
    portCode: "CNZUH",
    portName: "Port of Zhuhai",
    portContinent: "Asia",
  },
  {
    lat: "27.8276699",
    lng: "113.13396",
    country: "CN",
    country_long: "China",
    portCode: "CNZUU",
    portName: "Port of Zhuzhou",
    portContinent: "Asia",
  },
  {
    lat: "37.3553099",
    lng: "120.43421",
    country: "CN",
    country_long: "China",
    portCode: "CNZYU",
    portName: "ZHAOYUAN",
    portContinent: "Asia",
  },
  {
    lat: "27.8276699",
    lng: "113.13396",
    country: "CN",
    country_long: "China",
    portCode: "CNZZO",
    portName: "Zhuzhou",
    portContinent: "Asia",
  },
  {
    lat: "34.8107099",
    lng: "117.32196",
    country: "CN",
    country_long: "China",
    portCode: "CNZZS",
    portName: "Zaozhuang",
    portContinent: "Asia",
  },
  {
    lat: "24.5134699",
    lng: "117.64725",
    country: "CN",
    country_long: "China",
    portCode: "CNZZU",
    portName: "Zhangzhou (CNZZU), China, Asia",
    portContinent: "Asia",
  },
  {
    lat: "8.510109",
    lng: "-77.27897999999999",
    country: "CO",
    country_long: "Colombia",
    portCode: "COACD",
    portName: "Acandi (COACD), Colombia, Sam",
    portContinent: "South America",
  },
  {
    lat: "12.5450153",
    lng: "-81.7075787",
    country: "CO",
    country_long: "Colombia",
    portCode: "COADZ",
    portName: "San Andres Island Harbor",
    portContinent: "South America",
  },
  {
    lat: "11.0041072",
    lng: "-74.80698129999999",
    country: "CO",
    country_long: "Colombia",
    portCode: "COBAQ",
    portName: "Port of Barranquilla",
    portContinent: "South America",
  },
  {
    lat: "7.119349",
    lng: "-73.1227416",
    country: "CO",
    country_long: "Colombia",
    portCode: "COBGA",
    portName: "Bucaramanga",
    portContinent: "South America",
  },
  {
    lat: "4.710988599999999",
    lng: "-74.072092",
    country: "CO",
    country_long: "Colombia",
    portCode: "COBOG",
    portName: "Bogota",
    portContinent: "South America",
  },
  {
    lat: "6.222551999999999",
    lng: "-77.40115399999999",
    country: "CO",
    country_long: "Colombia",
    portCode: "COBSC",
    portName: "Bahia Solano (COBSC), Colombia, Sam",
    portContinent: "South America",
  },
  {
    lat: "3.8922947",
    lng: "-77.0722071",
    country: "CO",
    country_long: "Colombia",
    portCode: "COBUN",
    portName: "Port of Sociedad Portuaria Regional de Buenaventura",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "COCIE",
    portName: "Port of Cienaga",
    portContinent: "South America",
  },
  {
    lat: "3.4516467",
    lng: "-76.5319854",
    country: "CO",
    country_long: "Colombia",
    portCode: "COCLO",
    portName: "Cali",
    portContinent: "South America",
  },
  {
    lat: "10.3765223",
    lng: "-75.5042263",
    country: "CO",
    country_long: "Colombia",
    portCode: "COCNR",
    portName: "Contecar",
    portContinent: "South America",
  },
  {
    lat: "10.4063454",
    lng: "-75.5284286",
    country: "CO",
    country_long: "Colombia",
    portCode: "COCTG",
    portName: "Port of Cartagena",
    portContinent: "South America",
  },
  {
    lat: "7.8890971",
    lng: "-72.4966896",
    country: "CO",
    country_long: "Colombia",
    portCode: "COCUC",
    portName: "CUCUTA",
    portContinent: "South America",
  },
  {
    lat: "9.403438999999999",
    lng: "-75.68008669999999",
    country: "CO",
    country_long: "Colombia",
    portCode: "COCVE",
    portName: "Covenas Offshore Terminal",
    portContinent: "South America",
  },
  {
    lat: "6.2476376",
    lng: "-75.56581530000001",
    country: "CO",
    country_long: "Colombia",
    portCode: "COMDE",
    portName: "Medellin",
    portContinent: "South America",
  },
  {
    lat: "-3.2559896",
    lng: "-79.9947956",
    country: "EC",
    country_long: "Ecuador",
    portCode: "COPBO",
    portName: "Puerto Bolivar",
    portContinent: "South America",
  },
  {
    lat: "4.8087174",
    lng: "-75.690601",
    country: "CO",
    country_long: "Colombia",
    portCode: "COPEI",
    portName: "PEREIRA",
    portContinent: "South America",
  },
  {
    lat: "12.576855",
    lng: "-81.705052",
    country: "CO",
    country_long: "Colombia",
    portCode: "COPNM",
    portName: "Puerto Limon (COPNM), San Andres, Colombia",
    portContinent: "South America",
  },
  {
    lat: "11.1646095",
    lng: "-74.2208673",
    country: "CO",
    country_long: "Colombia",
    portCode: "COPOC",
    portName: "Port of Pozos Colorados",
    portContinent: "South America",
  },
  {
    lat: "2.4448143",
    lng: "-76.6147395",
    country: "CO",
    country_long: "Colombia",
    portCode: "COPPN",
    portName: "POPAYAN",
    portContinent: "South America",
  },
  {
    lat: "13.3387613",
    lng: "-81.3729372",
    country: "CO",
    country_long: "Colombia",
    portCode: "COPVA",
    portName: "Providencia (COPVA), Colombia, Sam",
    portContinent: "South America",
  },
  {
    lat: "11.5384151",
    lng: "-72.91678379999999",
    country: "CO",
    country_long: "Colombia",
    portCode: "CORCH",
    portName: "Riohacha (CORCH), Colombia, Sam",
    portContinent: "South America",
  },
  {
    lat: "11.2403547",
    lng: "-74.2110227",
    country: "CO",
    country_long: "Colombia",
    portCode: "COSMR",
    portName: "Port of Santa Marta",
    portContinent: "South America",
  },
  {
    lat: "10.4063421",
    lng: "-75.52779420000002",
    country: "CO",
    country_long: "Colombia",
    portCode: "COSPC",
    portName: "Sociedad Portuaria Regional Cartagena",
    portContinent: "South America",
  },
  {
    lat: "1.787434",
    lng: "-78.791265",
    country: "CO",
    country_long: "Colombia",
    portCode: "COTCO",
    portName: "Port of Tumaco",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "COTLU",
    portName: "Port of Tolu",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "COTRB",
    portName: "Port of Turbo",
    portContinent: "South America",
  },
  {
    lat: "5.695633",
    lng: "-76.649812",
    country: "CO",
    country_long: "Colombia",
    portCode: "COUIB",
    portName: "QUIBDO",
    portContinent: "South America",
  },
  {
    lat: "10.0159394",
    lng: "-84.21417009999999",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRAJU",
    portName: "ALAJUELA",
    portContinent: "North America",
  },
  {
    lat: "37.9838096",
    lng: "23.7275388",
    country: "GR",
    country_long: "Greece",
    portCode: "CRATE",
    portName: "Atenas",
    portContinent: "North America",
  },
  {
    lat: "35.0825706",
    lng: "33.3793315",
    country: "CY",
    country_long: "Cyprus",
    portCode: "CRATM",
    portName: "Altamira",
    portContinent: "North America",
  },
  {
    lat: "9.9400553",
    lng: "-84.7276382",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRCAL",
    portName: "Puerto de Caldera",
    portContinent: "North America",
  },
  {
    lat: "36.8625853",
    lng: "10.3329494",
    country: "TN",
    country_long: "Tunisia",
    portCode: "CRCAR",
    portName: "CARTAGO",
    portContinent: "North America",
  },
  {
    lat: "9.748916999999999",
    lng: "-83.753428",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRFMG",
    portName: "Flamingo (CRFMG), Costa Rica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "10.7267764",
    lng: "-85.80766",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRGDP",
    portName: "Papagayo (CRGDP), Costa Rica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "8.6040618",
    lng: "-83.1133792",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRGLF",
    portName: "Puerto Golfito",
    portContinent: "North America",
  },
  {
    lat: "9.9981413",
    lng: "-84.1197643",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRHER",
    portName: "HEREDIA",
    portContinent: "North America",
  },
  {
    lat: "9.989639799999999",
    lng: "-83.03324169999999",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRLIO",
    portName: "Limon (CRLIO), Costa Rica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "9.9924257",
    lng: "-83.0951852",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRMOB",
    portName: "Puerto Moin",
    portContinent: "North America",
  },
  {
    lat: "9.9778439",
    lng: "-84.82942109999999",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRPAS",
    portName: "Puerto Puntarenas",
    portContinent: "North America",
  },
  {
    lat: "8.527225999999999",
    lng: "-83.30671699999999",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRPJM",
    portName: "Puerto Jimenez (CRPJM), Costa Rica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "9.9924257",
    lng: "-83.0951852",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRPMN",
    portName: "Puerto Moin",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CRPSO",
    portName: "ParaÃ­so",
    portContinent: "North America",
  },
  {
    lat: "9.9400553",
    lng: "-84.7276382",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRPTC",
    portName: "Puerto Caldera",
    portContinent: "North America",
  },
  {
    lat: "9.431868099999999",
    lng: "-84.16190759999999",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRQPS",
    portName: "Puerto Quepos",
    portContinent: "North America",
  },
  {
    lat: "36.9741171",
    lng: "-122.0307963",
    country: "US",
    country_long: "United States",
    portCode: "CRSCZ",
    portName: "Santa Cruz",
    portContinent: "North America",
  },
  {
    lat: "18.4860575",
    lng: "-69.93121169999999",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "CRSDG",
    portName: "Santo Domingo",
    portContinent: "North America",
  },
  {
    lat: "9.9978669",
    lng: "-84.2048202",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRSJO",
    portName: "San Jose (CRSJO), San Jose, Costa Rica",
    portContinent: "North America",
  },
  {
    lat: "9.431868099999999",
    lng: "-84.16190759999999",
    country: "CR",
    country_long: "Costa Rica",
    portCode: "CRXQP",
    portName: "Quepos (CRXQP), Costa Rica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CUANT",
    portName: "Port of Antilla",
    portContinent: "North America",
  },
  {
    lat: "51.3250102",
    lng: "-2.4766241",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "CUBAN",
    portName: "Port of Banes",
    portContinent: "North America",
  },
  {
    lat: "20.3474809",
    lng: "-74.5023819",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUBCA",
    portName: "Port of Baracoa",
    portContinent: "North America",
  },
  {
    lat: "24.6698814",
    lng: "-81.2546366",
    country: "US",
    country_long: "United States",
    portCode: "CUBHO",
    portName: "Port of Bahia Honda",
    portContinent: "North America",
  },
  {
    lat: "26.7490152",
    lng: "-82.2616717",
    country: "US",
    country_long: "United States",
    portCode: "CUBOG",
    portName: "Port of Boca Grande",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CUCAB",
    portName: "Port of Cabanas",
    portContinent: "North America",
  },
  {
    lat: "22.5064672",
    lng: "-79.47601329999999",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUCAI",
    portName: "Port of Caibarien",
    portContinent: "North America",
  },
  {
    lat: "17.927",
    lng: "-102.169",
    country: "MX",
    country_long: "Mexico",
    portCode: "CUCAR",
    portName: "Port of Cardenas",
    portContinent: "North America",
  },
  {
    lat: "21.7533827",
    lng: "-79.9896754",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUCAS",
    portName: "Port of Casilda",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CUCFG",
    portName: "Port of Cienfuegos",
    portContinent: "North America",
  },
  {
    lat: "21.8788609",
    lng: "-82.8101975",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUGER",
    portName: "Port of Nueva Gerona",
    portContinent: "North America",
  },
  {
    lat: "6.210293099999999",
    lng: "-75.5887989",
    country: "CO",
    country_long: "Colombia",
    portCode: "CUGYB",
    portName: "Port of Guayabal",
    portContinent: "North America",
  },
  {
    lat: "23.1135925",
    lng: "-82.3665956",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUHAV",
    portName: "Havana (CUHAV), Havana, Cuba",
    portContinent: "North America",
  },
  {
    lat: "20.6886109",
    lng: "-75.54222680000001",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUICR",
    portName: "Port of Nicaro",
    portContinent: "North America",
  },
  {
    lat: "22.940133",
    lng: "-80.01296789999999",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUIDS",
    portName: "Port of Isabela de Sagua",
    portContinent: "North America",
  },
  {
    lat: "21.6268014",
    lng: "-78.8580815",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUJUC",
    portName: "Port of Jucaro",
    portContinent: "North America",
  },
  {
    lat: "22.9951706",
    lng: "-82.7525817",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUMAR",
    portName: "Port of Mariel",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CUMNT",
    portName: "Port of Manati",
    portContinent: "North America",
  },
  {
    lat: "44.8548651",
    lng: "-93.2422148",
    country: "US",
    country_long: "United States",
    portCode: "CUMOA",
    portName: "Port of Moa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CUMZO",
    portName: "Port of Manzanillo",
    portContinent: "North America",
  },
  {
    lat: "21.5500232",
    lng: "-77.2692503",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUNVT",
    portName: "Port of Nuevitas",
    portContinent: "North America",
  },
  {
    lat: "37.4418834",
    lng: "-122.1430195",
    country: "US",
    country_long: "United States",
    portCode: "CUPAL",
    portName: "Port of Palo Alto",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CUPAS",
    portName: "Port of Pastelillo",
    portContinent: "North America",
  },
  {
    lat: "21.1878548",
    lng: "-76.606611",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUPPA",
    portName: "Port of Puerto Padre",
    portContinent: "North America",
  },
  {
    lat: "23.0303395",
    lng: "-81.5322149",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUQMA",
    portName: "Port of Matanzas",
    portContinent: "North America",
  },
  {
    lat: "20.7156807",
    lng: "-77.9938497",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUSCS",
    portName: "Port of Santa Cruz del Sur",
    portContinent: "North America",
  },
  {
    lat: "20.01693",
    lng: "-75.8301537",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUSCU",
    portName: "Port of Santiago de Cuba",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CUTAN",
    portName: "Port of Tanamo",
    portContinent: "North America",
  },
  {
    lat: "21.6352639",
    lng: "-79.5505094",
    country: "CU",
    country_long: "Cuba",
    portCode: "CUTDZ",
    portName: "Port of Tunas de Zaza",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "CUVIT",
    portName: "Puerto de Vita",
    portContinent: "North America",
  },
  {
    lat: "16.0950108",
    lng: "-22.8078335",
    country: "CV",
    country_long: "Cape Verde",
    portCode: "CVBVC",
    portName: "Boa Vista",
    portContinent: "Africa",
  },
  {
    lat: "38.6409678",
    lng: "-28.0303189",
    country: "PT",
    country_long: "Portugal",
    portCode: "CVFOG",
    portName: "SAO JORGE ISL/FOGO",
    portContinent: "Africa",
  },
  {
    lat: "16.8765457",
    lng: "-24.9813499",
    country: "CV",
    country_long: "Cape Verde",
    portCode: "CVMIN",
    portName: "Mindelo",
    portContinent: "Africa",
  },
  {
    lat: "16.7556822",
    lng: "-22.9832285",
    country: "CV",
    country_long: "Cape Verde",
    portCode: "CVPAL",
    portName: "Porto da Palmeira",
    portContinent: "Africa",
  },
  {
    lat: "16.7632539",
    lng: "-22.8947094",
    country: "CV",
    country_long: "Cape Verde",
    portCode: "CVPLM",
    portName: "Pedra Lume (CVPLM), Cape Verde, Africa",
    portContinent: "Africa",
  },
  {
    lat: "6.4968574",
    lng: "2.6288523",
    country: "BJ",
    country_long: "Benin",
    portCode: "CVPON",
    portName: "Port of Porto Novo",
    portContinent: "Africa",
  },
  {
    lat: "14.9106056",
    lng: "-23.5049302",
    country: "CV",
    country_long: "Cape Verde",
    portCode: "CVRAI",
    portName: "Porto da Praia",
    portContinent: "Africa",
  },
  {
    lat: "16.6000812",
    lng: "-22.9103485",
    country: "CV",
    country_long: "Cape Verde",
    portCode: "CVSAM",
    portName: "Santa Maria (CVSAM), Cape Verde, Africa",
    portContinent: "Africa",
  },
  {
    lat: "16.1791389",
    lng: "-22.9237431",
    country: "CV",
    country_long: "Cape Verde",
    portCode: "CVSAR",
    portName: "Port of Sal Rei",
    portContinent: "Africa",
  },
  {
    lat: "16.7266152",
    lng: "-22.9297109",
    country: "CV",
    country_long: "Cape Verde",
    portCode: "CVSID",
    portName: "ILHA DO SAL (CVSID), Cape Verde, Africa",
    portContinent: "Africa",
  },
  {
    lat: "15.2760578",
    lng: "-23.7484077",
    country: "CV",
    country_long: "Cape Verde",
    portCode: "CVTAR",
    portName: "Port of Tarrafal",
    portContinent: "Africa",
  },
  {
    lat: "16.8280067",
    lng: "-24.9754175",
    country: "CV",
    country_long: "Cape Verde",
    portCode: "CVVXE",
    portName: "Sao Vicente",
    portContinent: "Africa",
  },
  {
    lat: "12.1224221",
    lng: "-68.8824233",
    country: "CW",
    country_long: "Curaçao",
    portCode: "CWWIL",
    portName: "Willemstad",
    portContinent: "North America",
  },
  {
    lat: "34.9887366",
    lng: "34.0002782",
    country: "CY",
    country_long: "Cyprus",
    portCode: "CYAYI",
    portName: "Agia Napa",
    portContinent: "Europe",
  },
  {
    lat: "34.5802786",
    lng: "32.9743145",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "CYDHK",
    portName: "Dhekelia",
    portContinent: "Europe",
  },
  {
    lat: "35.1310446",
    lng: "33.9394607",
    country: "N/A",
    country_long: "N/A",
    portCode: "CYFMG",
    portName: "Port of Famagusta",
    portContinent: "Europe",
  },
  {
    lat: "35.299194",
    lng: "33.2363246",
    country: "N/A",
    country_long: "N/A",
    portCode: "CYKYR",
    portName: "Kyrenia (CYKYR), Cyprus, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.0405881",
    lng: "32.39433870000001",
    country: "CY",
    country_long: "Cyprus",
    portCode: "CYLAT",
    portName: "LATCHI",
    portContinent: "Europe",
  },
  {
    lat: "34.9182222",
    lng: "33.6200625",
    country: "CY",
    country_long: "Cyprus",
    portCode: "CYLCA",
    portName: "Larnaca Port",
    portContinent: "Europe",
  },
  {
    lat: "34.6522095",
    lng: "33.0127645",
    country: "CY",
    country_long: "Cyprus",
    portCode: "CYLMS",
    portName: "Limassol Port",
    portContinent: "Europe",
  },
  {
    lat: "35.1855659",
    lng: "33.38227639999999",
    country: "CY",
    country_long: "Cyprus",
    portCode: "CYNIC",
    portName: "Nicosia",
    portContinent: "Europe",
  },
  {
    lat: "35.0120718",
    lng: "34.0562252",
    country: "CY",
    country_long: "Cyprus",
    portCode: "CYPAR",
    portName: "Protaras",
    portContinent: "Europe",
  },
  {
    lat: "34.77539489999999",
    lng: "32.4217786",
    country: "CY",
    country_long: "Cyprus",
    portCode: "CYPFO",
    portName: "Paphos (CYPFO), Cyprus, Med",
    portContinent: "Europe",
  },
  {
    lat: "49.9881767",
    lng: "17.4636941",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZBNT",
    portName: "BRUNTAL",
    portContinent: "Europe",
  },
  {
    lat: "49.1950602",
    lng: "16.6068371",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZBRQ",
    portName: "Brno (CZBRQ), Brno, Czech Republic",
    portContinent: "Europe",
  },
  {
    lat: "50.0344323",
    lng: "15.418462",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZCHV",
    portName: "Chvaletice (CZCHV), Czech Republic, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.77255630000001",
    lng: "14.2127612",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZDCB",
    portName: "Decin (CZDCB), Czech Republic, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.781138",
    lng: "14.1671734",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZDRS",
    portName: "DOBRIS",
    portContinent: "Europe",
  },
  {
    lat: "50.2920108",
    lng: "16.1600182",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZKRB",
    portName: "Dobruska",
    portContinent: "Europe",
  },
  {
    lat: "50.0905834",
    lng: "17.7030681",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZKRN",
    portName: "KRNOV",
    portContinent: "Europe",
  },
  {
    lat: "49.85665239999999",
    lng: "18.5432186",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZKRV",
    portName: "KARVINA",
    portContinent: "Europe",
  },
  {
    lat: "41.5246053",
    lng: "23.3915096",
    country: "BG",
    country_long: "Bulgaria",
    portCode: "CZMLK",
    portName: "Melnik",
    portContinent: "Europe",
  },
  {
    lat: "49.8209226",
    lng: "18.2625243",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZOSR",
    portName: "Ostrava",
    portContinent: "Europe",
  },
  {
    lat: "50.0343092",
    lng: "15.7811994",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZPAR",
    portName: "PARDUBICE (CZPAR), Pardubice, Czech Republic",
    portContinent: "Europe",
  },
  {
    lat: "49.7384314",
    lng: "13.3736371",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZPLZ",
    portName: "PLZEN (CZPLZ), Pizen, Czech Republic",
    portContinent: "Europe",
  },
  {
    lat: "50.0343092",
    lng: "15.7811994",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZPRB",
    portName: "PARDUBICE",
    portContinent: "Europe",
  },
  {
    lat: "50.0755381",
    lng: "14.4378005",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZPRG",
    portName: "Prague (CZPRG), Czech Republic, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.7317849",
    lng: "18.2903777",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZPSV",
    portName: "Paskov",
    portContinent: "Europe",
  },
  {
    lat: "49.2244365",
    lng: "17.6627635",
    country: "CZ",
    country_long: "Czechia",
    portCode: "CZZLN",
    portName: "ZLIN (CZZLN), Zlin, Czech Republic",
    portContinent: "Europe",
  },
  {
    lat: "54.3660372",
    lng: "10.0495604",
    country: "DE",
    country_long: "Germany",
    portCode: "DE3AO",
    portName: "Altwittenbek (DE3AO), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.9990077",
    lng: "7.842104299999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DE76C",
    portName: "Freiburg",
    portContinent: "Europe",
  },
  {
    lat: "48.75179929999999",
    lng: "8.0082054",
    country: "DE",
    country_long: "Germany",
    portCode: "DE78T",
    portName: "Greffern/Rheinmunste (DE78T), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.31465129999999",
    lng: "7.471996799999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DE82V",
    portName: "Dieblich (DE82V), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.2797338",
    lng: "7.615550799999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DE83D",
    portName: "Rhens (DE83D), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.8405032",
    lng: "8.856752600000002",
    country: "DE",
    country_long: "Germany",
    portCode: "DEA2H",
    portName: "Aach",
    portContinent: "Europe",
  },
  {
    lat: "50.7753455",
    lng: "6.083886800000001",
    country: "DE",
    country_long: "Germany",
    portCode: "DEAAH",
    portName: "AACHEN",
    portContinent: "Europe",
  },
  {
    lat: "53.0528104",
    lng: "7.334298299999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEACF",
    portName: "Aschendorf (DEACF), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.3705449",
    lng: "10.89779",
    country: "DE",
    country_long: "Germany",
    portCode: "DEAGB",
    portName: "AUGSBURG (DEAGB), Augsburg, Germany",
    portContinent: "Europe",
  },
  {
    lat: "51.8803776",
    lng: "7.6091478",
    country: "DE",
    country_long: "Germany",
    portCode: "DEAML",
    portName: "Amelsburen (DEAML), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.426057",
    lng: "7.4086636",
    country: "DE",
    country_long: "Germany",
    portCode: "DEAND",
    portName: "Andernach (DEAND), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.3004246",
    lng: "10.5719357",
    country: "DE",
    country_long: "Germany",
    portCode: "DEANS",
    portName: "ANABACH",
    portContinent: "Europe",
  },
  {
    lat: "50.0574369",
    lng: "7.1274484",
    country: "DE",
    country_long: "Germany",
    portCode: "DEAQF",
    portName: "Alf (DEAQF), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.9806625",
    lng: "9.135555400000001",
    country: "DE",
    country_long: "Germany",
    portCode: "DEASC",
    portName: "Aschaffenburg (DEASC), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.8988135",
    lng: "10.9027636",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBAM",
    portName: "Bamberg (DEBAM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.4125287",
    lng: "12.5316444",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBBG",
    portName: "Brandenburg (DEBBG), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.4267625",
    lng: "7.571062799999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBDF",
    portName: "Bendorf (DEBDF), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.8788454",
    lng: "9.409193199999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBEI",
    portName: "Beidenfleth (DEBEI), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.9197888",
    lng: "7.0626677",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBEK",
    portName: "Bernkastel (DEBEK), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.6759107",
    lng: "7.5772915",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBEN",
    portName: "Bensersiel (DEBEN), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.52000659999999",
    lng: "13.404954",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBER",
    portName: "Berlin (DEBER), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0302285",
    lng: "8.532470800000002",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBFE",
    portName: "BIELEFELD",
    portContinent: "Europe",
  },
  {
    lat: "52.0765185",
    lng: "8.755621999999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBFN",
    portName: "Bad Salzuflen",
    portContinent: "Europe",
  },
  {
    lat: "50.98964789999999",
    lng: "7.1229789",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBGL",
    portName: "BERGISCH GLADBACH",
    portContinent: "Europe",
  },
  {
    lat: "48.1725613",
    lng: "12.8310753",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBHN",
    portName: "Burghausen",
    portContinent: "Europe",
  },
  {
    lat: "49.9870871",
    lng: "8.356219099999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBIM",
    portName: "Bischofsheim (DEBIM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2865475",
    lng: "10.7334861",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBKD",
    portName: "Bleckede (DEBKD), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.3263116",
    lng: "8.4782391",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBKE",
    portName: "Brake (DEBKE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.2262886",
    lng: "10.0017987",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBLM",
    portName: "Blumenthal (DEBLM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.8988135",
    lng: "10.9027636",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBMB",
    portName: "BAMBERG",
    portContinent: "Europe",
  },
  {
    lat: "53.593131",
    lng: "6.704731199999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBMK",
    portName: "Borkum (DEBMK), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.7281887",
    lng: "7.3705802",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBMR",
    portName: "Baltrum (DEBMR), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.4818445",
    lng: "7.216236299999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBOC",
    portName: "BOCHUM",
    portContinent: "Europe",
  },
  {
    lat: "50.2311996",
    lng: "7.58853",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBOD",
    portName: "Boppard (DEBOD), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.3802724",
    lng: "10.7206776",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBOI",
    portName: "Boizenburg (DEBOI), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DEBOL",
    portName: "HAMBURG (GW)",
    portContinent: "Europe",
  },
  {
    lat: "51.4818445",
    lng: "7.216236299999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBOM",
    portName: "BOCHUM",
    portContinent: "Europe",
  },
  {
    lat: "50.73743",
    lng: "7.0982068",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBON",
    portName: "Bonn (DEBON), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.529086",
    lng: "6.9446888",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBOT",
    portName: "Bottrop (DEBOT), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.8988902",
    lng: "9.1338853",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBRB",
    portName: "Brunsbuettel (DEBRB), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DEBRE",
    portName: "Port of Bremen",
    portContinent: "Europe",
  },
  {
    lat: "52.4077183",
    lng: "8.0015624",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBRM",
    portName: "Bramsche (DEBRM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.5395845",
    lng: "8.5809424",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBRV",
    portName: "Port of Bremerhaven",
    portContinent: "Europe",
  },
  {
    lat: "48.0348382",
    lng: "7.5836067",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBSH",
    portName: "Breisach (DEBSH), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.165691",
    lng: "10.451526",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBSK",
    portName: "Burgstaaken (DEBSK), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.1346218",
    lng: "8.858591299999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBUM",
    portName: "Buesum (DEBUM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.644956",
    lng: "9.5033496",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBUZ",
    portName: "Buetzfleth (DEBUZ), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2688736",
    lng: "10.5267696",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBWE",
    portName: "Braunschweig (DEBWE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.22850409999999",
    lng: "9.157138699999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBWF",
    portName: "Bad Wimpfen (DEBWF), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9808093",
    lng: "9.5088879",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBWR",
    portName: "Bodenwerder (DEBWR), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.33038519999999",
    lng: "9.7079788",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBYT",
    portName: "Borgstedt (DEBYT), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.9456399",
    lng: "11.5713346",
    country: "DE",
    country_long: "Germany",
    portCode: "DEBYU",
    portName: "BAYREUTH",
    portContinent: "Europe",
  },
  {
    lat: "52.6263797",
    lng: "10.080865",
    country: "DE",
    country_long: "Germany",
    portCode: "DECEL",
    portName: "CELLE",
    portContinent: "Europe",
  },
  {
    lat: "50.937531",
    lng: "6.9602786",
    country: "DE",
    country_long: "Germany",
    portCode: "DECGN",
    portName: "Koeln (DECGN), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.8282222",
    lng: "12.9208547",
    country: "DE",
    country_long: "Germany",
    portCode: "DECHE",
    portName: "CHEMNITZ",
    portContinent: "Europe",
  },
  {
    lat: "54.12722716882357",
    lng: "9.652762852794092",
    country: "DE",
    country_long: "Germany",
    portCode: "DECKL",
    portName: "Kiel Canal",
    portContinent: "Europe",
  },
  {
    lat: "52.00591799999999",
    lng: "7.648504",
    country: "DE",
    country_long: "Germany",
    portCode: "DECOH",
    portName: "Coerheide (DECOH), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.75631079999999",
    lng: "14.3328679",
    country: "DE",
    country_long: "Germany",
    portCode: "DECOT",
    portName: "COTTBUS",
    portContinent: "Europe",
  },
  {
    lat: "53.859336",
    lng: "8.6879057",
    country: "DE",
    country_long: "Germany",
    portCode: "DECUX",
    portName: "Cuxhaven (DECUX), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.730176",
    lng: "8.7055281",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDAG",
    portName: "Dagebuell (DEDAG), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.8728253",
    lng: "8.6511929",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDAR",
    portName: "Darmstadt",
    portContinent: "Europe",
  },
  {
    lat: "48.8414126",
    lng: "12.9572212",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDEG",
    portName: "Deggendorf (DEDEG), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.83081660000001",
    lng: "12.2423302",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDES",
    portName: "DESSAU",
    portContinent: "Europe",
  },
  {
    lat: "51.3652323",
    lng: "6.7543986",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDHU",
    portName: "Huckingen (DEDHU), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.3137884",
    lng: "7.282122999999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDIT",
    portName: "Ditzum (DEDIT), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.0907133",
    lng: "6.8118573",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDMG",
    portName: "Chempark Dormagen (DEDMG), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2241133",
    lng: "7.680979400000001",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDNT",
    portName: "Dorenthe (DEDNT), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6559681",
    lng: "6.964260599999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDON",
    portName: "Dorsten (DEDON), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.9679574",
    lng: "7.341997199999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDRP",
    portName: "Dorpen (DEDRP), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.0504088",
    lng: "13.7372621",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDRS",
    portName: "Dresden (DEDRS), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5173204",
    lng: "7.6120128",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDTM",
    portName: "Dortmund (DEDTM), Dortmund, Germany",
    portContinent: "Europe",
  },
  {
    lat: "51.4344079",
    lng: "6.762329299999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDUI",
    portName: "Port of Duisburg",
    portContinent: "Europe",
  },
  {
    lat: "51.21785029999999",
    lng: "6.7352443",
    country: "DE",
    country_long: "Germany",
    portCode: "DEDUS",
    portName: "Port of Dusseldorf",
    portContinent: "Europe",
  },
  {
    lat: "49.8081748",
    lng: "9.1587232",
    country: "DE",
    country_long: "Germany",
    portCode: "DEEAM",
    portName: "Erlenbach Am Main (DEEAM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.0858978",
    lng: "7.2110436",
    country: "DE",
    country_long: "Germany",
    portCode: "DEEHM",
    portName: "Senheim (DEEHM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.3355556",
    lng: "7.1836111",
    country: "DE",
    country_long: "Germany",
    portCode: "DEEME",
    portName: "Port of Emden",
    portContinent: "Europe",
  },
  {
    lat: "51.8395929",
    lng: "6.251730299999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEEMM",
    portName: "Emmerich (DEEMM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.98476789999999",
    lng: "11.0298799",
    country: "DE",
    country_long: "Germany",
    portCode: "DEERF",
    portName: "Erfurt",
    portContinent: "Europe",
  },
  {
    lat: "51.4556432",
    lng: "7.0115552",
    country: "DE",
    country_long: "Germany",
    portCode: "DEESS",
    portName: "Essen",
    portContinent: "Europe",
  },
  {
    lat: "51.6184648",
    lng: "6.6221447",
    country: "DE",
    country_long: "Germany",
    portCode: "DEESU",
    portName: "EMMELSUM (DEESU), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.86142599999999",
    lng: "8.567644099999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEFAG",
    portName: "Griesheim (DEFAG), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.9990077",
    lng: "7.842104299999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEFBG",
    portName: "FREIBURG",
    portContinent: "Europe",
  },
  {
    lat: "50.9160943",
    lng: "13.3468175",
    country: "DE",
    country_long: "Germany",
    portCode: "DEFEG",
    portName: "freiberg",
    portContinent: "Europe",
  },
  {
    lat: "52.3472237",
    lng: "14.5505673",
    country: "DE",
    country_long: "Germany",
    portCode: "DEFFO",
    portName: "FRANKFURT (ODER)",
    portContinent: "Europe",
  },
  {
    lat: "51.6293993",
    lng: "6.6579885",
    country: "DE",
    country_long: "Germany",
    portCode: "DEFHL",
    portName: "Friedrichsfeld (DEFHL), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.165691",
    lng: "10.451526",
    country: "DE",
    country_long: "Germany",
    portCode: "DEFHU",
    portName: "Fischerhutte (DEFHU), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.7937431",
    lng: "9.4469964",
    country: "DE",
    country_long: "Germany",
    portCode: "DEFLF",
    portName: "Flensburg (DEFLF), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.1266024",
    lng: "8.7101908",
    country: "DE",
    country_long: "Germany",
    portCode: "DEFRA",
    portName: "Frankfurt am Main (DEFRA), Frankfurt, Germany",
    portContinent: "Europe",
  },
  {
    lat: "50.5558095",
    lng: "9.6808449",
    country: "DE",
    country_long: "Germany",
    portCode: "DEFUL",
    portName: "FULDA",
    portContinent: "Europe",
  },
  {
    lat: "49.4771169",
    lng: "10.988667",
    country: "DE",
    country_long: "Germany",
    portCode: "DEFUR",
    portName: "FURTH",
    portContinent: "Europe",
  },
  {
    lat: "47.4919023",
    lng: "11.0947806",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGAP",
    portName: "GARMISCH-PARTENKIRCHEN",
    portContinent: "Europe",
  },
  {
    lat: "50.8534581",
    lng: "6.9786695",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGDO",
    portName: "Godorf (DEGDO), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DEGEA",
    portName: "Gera",
    portContinent: "Europe",
  },
  {
    lat: "51.5187651",
    lng: "7.0838411",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGEK",
    portName: "GELSENKIRCHEN",
    portContinent: "Europe",
  },
  {
    lat: "49.2140244",
    lng: "8.3668146",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGER",
    portName: "Germersheim",
    portContinent: "Europe",
  },
  {
    lat: "53.4379562",
    lng: "10.3611665",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGET",
    portName: "Geesthacht (DEGET), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.5712278",
    lng: "9.6134066",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGIC",
    portName: "Gruenendeich (DEGIC), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.5840512",
    lng: "8.678403099999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGIE",
    portName: "GIESSEN",
    portContinent: "Europe",
  },
  {
    lat: "49.3029546",
    lng: "8.2593141",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGIH",
    portName: "Geinsheim (DEGIH), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.0818163",
    lng: "8.9839637",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGKG",
    portName: "Grosskrotzenburg (DEGKG), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.7911914",
    lng: "9.4260898",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGLU",
    portName: "Glueckstadt (DEGLU), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.15049",
    lng: "10.9602356",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGMZ",
    portName: "Groemitz (DEGMZ), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.54128040000001",
    lng: "9.915803499999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGOE",
    portName: "Goettingen",
    portContinent: "Europe",
  },
  {
    lat: "52.35942009999999",
    lng: "13.3089427",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGRB",
    portName: "Grossbeeren",
    portContinent: "Europe",
  },
  {
    lat: "53.5001312",
    lng: "7.0950228",
    country: "DE",
    country_long: "Germany",
    portCode: "DEGRE",
    portName: "Greetsiel (DEGRE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.49535669999999",
    lng: "11.9662371",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHAE",
    portName: "Halle",
    portContinent: "Europe",
  },
  {
    lat: "51.3670777",
    lng: "7.4632841",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHAG",
    portName: "HAGEN",
    portContinent: "Europe",
  },
  {
    lat: "52.3758916",
    lng: "9.732010400000002",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHAJ",
    portName: "Hannover (DEHAJ), Hannover, Germany",
    portContinent: "Europe",
  },
  {
    lat: "51.49535669999999",
    lng: "11.9662371",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHAL",
    portName: "HALLE",
    portContinent: "Europe",
  },
  {
    lat: "53.50942977753049",
    lng: "9.96547661656452",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHAM",
    portName: "Port of Hamburg",
    portContinent: "Europe",
  },
  {
    lat: "50.1264123",
    lng: "8.9283105",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHAU",
    portName: "Hanau (DEHAU), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.0256656",
    lng: "7.331016300000001",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHBR",
    portName: "Herbrum (DEHBR), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.10248199999999",
    lng: "8.547457099999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHCS",
    portName: "Hochst (DEHCS), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5368948",
    lng: "7.200914699999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHEE",
    portName: "Herne (DEHEE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.3987524",
    lng: "8.6724335",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHEI",
    portName: "Heidelberg",
    portContinent: "Europe",
  },
  {
    lat: "52.2272927",
    lng: "11.003723",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHEL",
    portName: "HELMSTEDT",
    portContinent: "Europe",
  },
  {
    lat: "49.1426929",
    lng: "9.210879",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHEN",
    portName: "Heilbronn (DEHEN), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.1803268",
    lng: "7.888943799999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHGL",
    portName: "Helgoland (DEHGL), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.36967989999999",
    lng: "10.97561",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHHF",
    portName: "Heiligenhafen (DEHHF), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.154778",
    lng: "9.9579652",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHIL",
    portName: "Hildesheim (DEHIL), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9033219",
    lng: "7.644676299999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHLP",
    portName: "Hiltrup (DEHLP), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.0763954",
    lng: "8.9379086",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHLU",
    portName: "Hainburg (DEHLU), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6738583",
    lng: "7.815981600000001",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHMM",
    portName: "Hamm (DEHMM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.3670777",
    lng: "7.4632841",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHNQ",
    portName: "HAGEN",
    portContinent: "Europe",
  },
  {
    lat: "54.0249809",
    lng: "9.2784639",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHOD",
    portName: "Hochdonn (DEHOD), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.3135391",
    lng: "11.9127814",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHOQ",
    portName: "hof",
    portContinent: "Europe",
  },
  {
    lat: "53.400746",
    lng: "10.1565078",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHPE",
    portName: "Hoopte (DEHPE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.7572205",
    lng: "8.2878325",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHRM",
    portName: "Hoernum (DEHRM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.4196111",
    lng: "8.099701",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHRN",
    portName: "Haren (DEHRN), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.70334219999999",
    lng: "7.807292899999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHSX",
    portName: "Harlesiel (DEHSX), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.5126736",
    lng: "10.0159421",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHTJ",
    portName: "Hamburg-Mitte (DEHTJ), Hamburg, Germany",
    portContinent: "Europe",
  },
  {
    lat: "54.4855642",
    lng: "9.056639600000002",
    country: "DE",
    country_long: "Germany",
    portCode: "DEHUS",
    portName: "Husum (DEHUS), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.6637151",
    lng: "8.3335989",
    country: "DE",
    country_long: "Germany",
    portCode: "DEIAM",
    portName: "Amrum (DEIAM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.7667395",
    lng: "11.4226498",
    country: "DE",
    country_long: "Germany",
    portCode: "DEING",
    portName: "INGOLSTADT",
    portContinent: "Europe",
  },
  {
    lat: "51.7880185",
    lng: "6.3144573",
    country: "DE",
    country_long: "Germany",
    portCode: "DEITH",
    portName: "Grieth (DEITH), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.9317752",
    lng: "9.5051475",
    country: "DE",
    country_long: "Germany",
    portCode: "DEITZ",
    portName: "Itzehoe (DEITZ), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2682267",
    lng: "7.384931900000001",
    country: "DE",
    country_long: "Germany",
    portCode: "DEJEM",
    portName: "Jemgum (DEJEM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.927054",
    lng: "11.5892372",
    country: "DE",
    country_long: "Germany",
    portCode: "DEJEN",
    portName: "JENA",
    portContinent: "Europe",
  },
  {
    lat: "53.681385",
    lng: "7.008163",
    country: "DE",
    country_long: "Germany",
    portCode: "DEJUI",
    portName: "Juist (DEJUI), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.0155556",
    lng: "8.3277778",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKAE",
    portName: "Port of Karlsruhe",
    portContinent: "Europe",
  },
  {
    lat: "51.3127114",
    lng: "9.4797461",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKAS",
    portName: "Kassel",
    portContinent: "Europe",
  },
  {
    lat: "51.3547878",
    lng: "6.6428946",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKDU",
    portName: "Krefeld Uerdingen (DEKDU), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.5788725",
    lng: "7.8160821",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKEH",
    portName: "Port of Kehl",
    portContinent: "Europe",
  },
  {
    lat: "54.3232927",
    lng: "10.1227652",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKEL",
    portName: "Kiel (DEKEL), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.9175771",
    lng: "11.8931689",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKEM",
    portName: "Kelheim (DEKEM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.7285699",
    lng: "10.3157835",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKEN",
    portName: "KEMPTEN",
    portContinent: "Europe",
  },
  {
    lat: "50.130114",
    lng: "8.883417",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKLS",
    portName: "Kesselstadt (DEKLS), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.4400657",
    lng: "7.7491265",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKLT",
    portName: "Kaiserlautern",
    portContinent: "Europe",
  },
  {
    lat: "50.3569429",
    lng: "7.5889959",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKOB",
    portName: "Koblenz (DEKOB), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.6779808",
    lng: "9.1736741",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKON",
    portName: "KONSTANZ",
    portContinent: "Europe",
  },
  {
    lat: "51.165691",
    lng: "10.451526",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKOP",
    portName: "Kaje Omni Pac (DEKOP), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.86510879999999",
    lng: "9.1857957",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKOR",
    portName: "KORNWESTHEIM (DEKOR), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.3421455",
    lng: "6.664984",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKRE",
    portName: "Port of Krefeld",
    portContinent: "Europe",
  },
  {
    lat: "54.1171229",
    lng: "13.7517685",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKSL",
    portName: "Kroslin (DEKSL), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.0042304",
    lng: "9.0658932",
    country: "DE",
    country_long: "Germany",
    portCode: "DEKTM",
    portName: "Kleinostheim (DEKTM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.86335159999999",
    lng: "7.3150725",
    country: "DE",
    country_long: "Germany",
    portCode: "DELAT",
    portName: "Lathen (DELAT), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DELBC",
    portName: "Port of Lubeck",
    portContinent: "Europe",
  },
  {
    lat: "54.1345649",
    lng: "13.6175969",
    country: "DE",
    country_long: "Germany",
    portCode: "DELBM",
    portName: "Lubmin (DELBM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1376843",
    lng: "7.741188200000001",
    country: "DE",
    country_long: "Germany",
    portCode: "DELDB",
    portName: "Ladbergen (DELDB), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.5441917",
    lng: "12.1468532",
    country: "DE",
    country_long: "Germany",
    portCode: "DELDT",
    portName: "LANDSHUT",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DELEE",
    portName: "Port of Leer",
    portContinent: "Europe",
  },
  {
    lat: "51.3396955",
    lng: "12.3730747",
    country: "DE",
    country_long: "Germany",
    portCode: "DELEJ",
    portName: "LEIPZIG (DELEJ), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.04592479999999",
    lng: "7.0192196",
    country: "DE",
    country_long: "Germany",
    portCode: "DELEV",
    portName: "Chempark Leverkusen (DELEV), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.747401",
    lng: "7.492601000000001",
    country: "DE",
    country_long: "Germany",
    portCode: "DELGO",
    portName: "Langeoog (DELGO), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1454016",
    lng: "7.9739032",
    country: "DE",
    country_long: "Germany",
    portCode: "DELIE",
    portName: "Lienen (DELIE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.54030789999999",
    lng: "7.329286199999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DELIG",
    portName: "Lingen (DELIG), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.019488",
    lng: "8.4291796",
    country: "DE",
    country_long: "Germany",
    portCode: "DELIS",
    portName: "List Sylt (DELIS), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.5534983",
    lng: "9.6928605",
    country: "DE",
    country_long: "Germany",
    portCode: "DELIU",
    portName: "Lindau",
    portContinent: "Europe",
  },
  {
    lat: "50.8305258",
    lng: "7.012348599999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DELLS",
    portName: "Lulsdorf (DELLS), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.3762523",
    lng: "10.5496697",
    country: "DE",
    country_long: "Germany",
    portCode: "DELNU",
    portName: "Lauenburg (DELNU), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6104829",
    lng: "7.5285074",
    country: "DE",
    country_long: "Germany",
    portCode: "DELUE",
    portName: "Lunen (DELUE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.8891149",
    lng: "9.1942834",
    country: "DE",
    country_long: "Germany",
    portCode: "DELUG",
    portName: "Ludwigsburg, SH",
    portContinent: "Europe",
  },
  {
    lat: "49.4774018",
    lng: "8.4447451",
    country: "DE",
    country_long: "Germany",
    portCode: "DELUH",
    portName: "Port of Ludwigshafen",
    portContinent: "Europe",
  },
  {
    lat: "48.0464144",
    lng: "10.8718015",
    country: "DE",
    country_long: "Germany",
    portCode: "DELXL",
    portName: "Landsberg am Lech",
    portContinent: "Europe",
  },
  {
    lat: "52.1205333",
    lng: "11.6276237",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMAG",
    portName: "Magdeburg (DEMAG), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.9928617",
    lng: "8.2472526",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMAI",
    portName: "Port of Mainz",
    portContinent: "Europe",
  },
  {
    lat: "51.65912340000001",
    lng: "7.1060218",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMAL",
    portName: "Marl (DEMAL), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.7387148",
    lng: "12.7666597",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMCN",
    portName: "Malchin (DEMCN), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.1941686",
    lng: "6.4315392",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMGL",
    portName: "Monchengladbach",
    portContinent: "Europe",
  },
  {
    lat: "52.1023387",
    lng: "7.701238099999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMHA",
    portName: "Schmedehausen (DEMHA), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.4874592",
    lng: "8.466039499999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMHG",
    portName: "Port of Mannheim",
    portContinent: "Europe",
  },
  {
    lat: "52.2923549",
    lng: "8.9160539",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMID",
    portName: "Minden (DEMID), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.12021679999999",
    lng: "8.8382646",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMLM",
    portName: "Muhlheim Am Main (DEMLM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.4185682",
    lng: "6.884522599999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMMO",
    portName: "MULHEIM",
    portContinent: "Europe",
  },
  {
    lat: "50.77891469999999",
    lng: "7.066664200000001",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMNF",
    portName: "Mondorf (DEMNF), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.6200912",
    lng: "10.6891312",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMOE",
    portName: "Molln (DEMOE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.4516041",
    lng: "6.640814799999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMOS",
    portName: "MOERS",
    portContinent: "Europe",
  },
  {
    lat: "52.3046339",
    lng: "10.0942011",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMRU",
    portName: "Mehrum (DEMRU), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9606649",
    lng: "7.6261347",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMSR",
    portName: "MUENSTER",
    portContinent: "Europe",
  },
  {
    lat: "48.1351253",
    lng: "11.5819806",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMUC",
    portName: "Munich (DEMUC), Munich, Germany",
    portContinent: "Europe",
  },
  {
    lat: "54.5159131",
    lng: "13.6331916",
    country: "DE",
    country_long: "Germany",
    portCode: "DEMUK",
    portName: "Mukran (DEMUK), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.5474083",
    lng: "8.7234746",
    country: "DE",
    country_long: "Germany",
    portCode: "DENAG",
    portName: "NAGOLD",
    portContinent: "Europe",
  },
  {
    lat: "47.82752499999999",
    lng: "11.059972",
    country: "DE",
    country_long: "Germany",
    portCode: "DENDT",
    portName: "Neustadt (DENDT), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.0920323",
    lng: "7.1394752",
    country: "DE",
    country_long: "Germany",
    portCode: "DENFE",
    portName: "Neef (DENFE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.698682",
    lng: "7.6937336",
    country: "DE",
    country_long: "Germany",
    portCode: "DENHS",
    portName: "Neuharlingersiel (DENHS), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.0351677",
    lng: "9.6451405",
    country: "DE",
    country_long: "Germany",
    portCode: "DENNH",
    portName: "Neuendorf (DENNH), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.6166466",
    lng: "7.1587422",
    country: "DE",
    country_long: "Germany",
    portCode: "DENOE",
    portName: "Norddeich (DENOE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.4855968",
    lng: "8.7617683",
    country: "DE",
    country_long: "Germany",
    portCode: "DENOR",
    portName: "Strucklahnungshorn (DENOR), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.71682699999999",
    lng: "7.281028199999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DENRD",
    portName: "Norderney (DENRD), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.2041968",
    lng: "6.6879511",
    country: "DE",
    country_long: "Germany",
    portCode: "DENSS",
    portName: "Port of Neuss",
    portContinent: "Europe",
  },
  {
    lat: "49.4521018",
    lng: "11.0766654",
    country: "DE",
    country_long: "Germany",
    portCode: "DENUE",
    portName: "Nuremberg (DENUE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.4878429",
    lng: "6.863308",
    country: "DE",
    country_long: "Germany",
    portCode: "DEOBE",
    portName: "OBERHAUSEN",
    portContinent: "Europe",
  },
  {
    lat: "50.09563620000001",
    lng: "8.776084299999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEOFF",
    portName: "OFFENBACH",
    portContinent: "Europe",
  },
  {
    lat: "48.473451",
    lng: "7.9498017",
    country: "DE",
    country_long: "Germany",
    portCode: "DEOFG",
    portName: "OFFENBURG",
    portContinent: "Europe",
  },
  {
    lat: "53.1434501",
    lng: "8.214552099999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEOLO",
    portName: "Oldenburg (DEOLO), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.666041",
    lng: "10.000448",
    country: "DE",
    country_long: "Germany",
    portCode: "DEOLP",
    portName: "Olpenitz (DEOLP), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.3284951",
    lng: "7.3406034",
    country: "DE",
    country_long: "Germany",
    portCode: "DEOLU",
    portName: "Oldersum (DEOLU), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2799112",
    lng: "8.0471788",
    country: "DE",
    country_long: "Germany",
    portCode: "DEOSN",
    portName: "OSNABRUCK",
    portContinent: "Europe",
  },
  {
    lat: "53.913535",
    lng: "9.203077",
    country: "DE",
    country_long: "Germany",
    portCode: "DEOSR",
    portName: "Ostermoor (DEOSR), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.165691",
    lng: "10.451526",
    country: "DE",
    country_long: "Germany",
    portCode: "DEOTK",
    portName: "Oortkaten (DEOTK), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2288185",
    lng: "11.7142519",
    country: "DE",
    country_long: "Germany",
    portCode: "DEOWH",
    portName: "Hohenwarthe (DEOWH), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7189205",
    lng: "8.757509299999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEPAD",
    portName: "Paderborn",
    portContinent: "Europe",
  },
  {
    lat: "53.0726142",
    lng: "7.4232678",
    country: "DE",
    country_long: "Germany",
    portCode: "DEPAP",
    portName: "Port of Papenburg",
    portContinent: "Europe",
  },
  {
    lat: "48.5667364",
    lng: "13.4319466",
    country: "DE",
    country_long: "Germany",
    portCode: "DEPAS",
    portName: "Passau (DEPAS), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.1367132",
    lng: "13.7747756",
    country: "DE",
    country_long: "Germany",
    portCode: "DEPEF",
    portName: "Peenemunde (DEPEF), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.3204095",
    lng: "10.243267",
    country: "DE",
    country_long: "Germany",
    portCode: "DEPEI",
    portName: "Peine (DEPEI), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.3905689",
    lng: "13.0644729",
    country: "DE",
    country_long: "Germany",
    portCode: "DEPOT",
    portName: "POTSDAM",
    portContinent: "Europe",
  },
  {
    lat: "54.49659819999999",
    lng: "11.2123327",
    country: "DE",
    country_long: "Germany",
    portCode: "DEPUT",
    portName: "PUTTGARDEN",
    portContinent: "Europe",
  },
  {
    lat: "51.8334809",
    lng: "6.1701567",
    country: "DE",
    country_long: "Germany",
    portCode: "DEPYK",
    portName: "Spyck (DEPYK), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.75612",
    lng: "11.3698021",
    country: "DE",
    country_long: "Germany",
    portCode: "DEPZG",
    portName: "Penzberg  (DEPZG), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2576943",
    lng: "7.626875099999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DERBK",
    portName: "Riesenbeck (DERBK), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.6254387",
    lng: "7.6940529",
    country: "CH",
    country_long: "Switzerland",
    portCode: "DERCA",
    portName: "REICHENBACH",
    portContinent: "Europe",
  },
  {
    lat: "51.6117809",
    lng: "7.197388500000001",
    country: "DE",
    country_long: "Germany",
    portCode: "DEREC",
    portName: "RECKLING-HAUSEN",
    portContinent: "Europe",
  },
  {
    lat: "51.1787418",
    lng: "7.189696199999998",
    country: "DE",
    country_long: "Germany",
    portCode: "DERED",
    portName: "REMSCHEID",
    portContinent: "Europe",
  },
  {
    lat: "49.0134297",
    lng: "12.1016236",
    country: "DE",
    country_long: "Germany",
    portCode: "DEREG",
    portName: "Regensburg (DEREG), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.3080869",
    lng: "9.660699300000001",
    country: "DE",
    country_long: "Germany",
    portCode: "DEREN",
    portName: "Port of Rendsburg",
    portContinent: "Europe",
  },
  {
    lat: "51.7670727",
    lng: "6.4015065",
    country: "DE",
    country_long: "Germany",
    portCode: "DERES",
    portName: "Rees (DERES), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.4912389",
    lng: "9.2046411",
    country: "DE",
    country_long: "Germany",
    portCode: "DEREU",
    portName: "REUTLINGEN",
    portContinent: "Europe",
  },
  {
    lat: "54.0924406",
    lng: "12.0991466",
    country: "DE",
    country_long: "Germany",
    portCode: "DERSK",
    portName: "Rostock (DERSK), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.13786589999999",
    lng: "10.3899126",
    country: "DE",
    country_long: "Germany",
    portCode: "DESAR",
    portName: "Salzgitter (DESAR), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.2381697",
    lng: "6.997547",
    country: "DE",
    country_long: "Germany",
    portCode: "DESCN",
    portName: "Saarbrucken",
    portContinent: "Europe",
  },
  {
    lat: "50.0492047",
    lng: "10.2194228",
    country: "DE",
    country_long: "Germany",
    portCode: "DESCW",
    portName: "Schweinfurt (DESCW), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.0445973",
    lng: "8.1962967",
    country: "DE",
    country_long: "Germany",
    portCode: "DESES",
    portName: "Schierstein (DESES), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.8838492",
    lng: "8.020959099999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DESGE",
    portName: "Siegen",
    portContinent: "Europe",
  },
  {
    lat: "54.5143987",
    lng: "13.1672132",
    country: "DE",
    country_long: "Germany",
    portCode: "DESH2",
    portName: "Schaprode (DESH2), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.7998473",
    lng: "7.2074514",
    country: "DE",
    country_long: "Germany",
    portCode: "DESIG",
    portName: "SIEGBURG",
    portContinent: "Europe",
  },
  {
    lat: "47.7670971",
    lng: "8.8722387",
    country: "DE",
    country_long: "Germany",
    portCode: "DESIN",
    portName: "Singen",
    portContinent: "Europe",
  },
  {
    lat: "51.1652199",
    lng: "7.0671161",
    country: "DE",
    country_long: "Germany",
    portCode: "DESOL",
    portName: "Solingen",
    portContinent: "Europe",
  },
  {
    lat: "52.9846914",
    lng: "9.842125",
    country: "DE",
    country_long: "Germany",
    portCode: "DESOU",
    portName: "soltau",
    portContinent: "Europe",
  },
  {
    lat: "53.7710248",
    lng: "7.693910100000001",
    country: "DE",
    country_long: "Germany",
    portCode: "DESPI",
    portName: "Spiekeroog (DESPI), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.2599509",
    lng: "7.64758",
    country: "DE",
    country_long: "Germany",
    portCode: "DESPY",
    portName: "Spay (DESPY), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.8777333",
    lng: "12.5801538",
    country: "DE",
    country_long: "Germany",
    portCode: "DESTB",
    portName: "Straubing (DESTB), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.30906539999999",
    lng: "13.0770347",
    country: "DE",
    country_long: "Germany",
    portCode: "DESTL",
    portName: "Stralsund (DESTL), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.7758459",
    lng: "9.1829321",
    country: "DE",
    country_long: "Germany",
    portCode: "DESTR",
    portName: "Stuttgart",
    portContinent: "Europe",
  },
  {
    lat: "53.6203259",
    lng: "9.529397399999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DESTS",
    portName: "Stadersand",
    portContinent: "Europe",
  },
  {
    lat: "51.1365517",
    lng: "6.7981817",
    country: "DE",
    country_long: "Germany",
    portCode: "DESUE",
    portName: "Sturzelberg (DESUE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.6355022",
    lng: "11.4012499",
    country: "DE",
    country_long: "Germany",
    portCode: "DESWR",
    portName: "SCHWERIN",
    portContinent: "Europe",
  },
  {
    lat: "49.97011639999999",
    lng: "9.071630100000002",
    country: "DE",
    country_long: "Germany",
    portCode: "DESYS",
    portName: "Stockstadt Am Main (DESYS), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.1804177",
    lng: "7.298449699999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DETKD",
    portName: "Treis Karden (DETKD), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9606649",
    lng: "7.6261347",
    country: "DE",
    country_long: "Germany",
    portCode: "DETMU",
    portName: "Muenster",
    portContinent: "Europe",
  },
  {
    lat: "49.749992",
    lng: "6.6371433",
    country: "DE",
    country_long: "Germany",
    portCode: "DETRI",
    portName: "Trier (DETRI), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.8177471",
    lng: "7.1430246",
    country: "DE",
    country_long: "Germany",
    portCode: "DETRO",
    portName: "Troisdorf",
    portContinent: "Europe",
  },
  {
    lat: "49.95136600000001",
    lng: "7.1168469",
    country: "DE",
    country_long: "Germany",
    portCode: "DETRT",
    portName: "Traben Trarbach (DETRT), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.9600008",
    lng: "10.8535751",
    country: "DE",
    country_long: "Germany",
    portCode: "DETRV",
    portName: "Travemunde (DETRV), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.9652883",
    lng: "10.5573402",
    country: "DE",
    country_long: "Germany",
    portCode: "DEUEL",
    portName: "Uelzen (DEUEL), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.68807959999999",
    lng: "7.9464763",
    country: "DE",
    country_long: "Germany",
    portCode: "DEUEO",
    portName: "Uentrop (DEUEO), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.4010822",
    lng: "9.987607599999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEULM",
    portName: "ULM (DEULM), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.1182859",
    lng: "13.5699575",
    country: "DE",
    country_long: "Germany",
    portCode: "DEVIW",
    portName: "Vierow (DEVIW), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.2556226",
    lng: "6.8588948",
    country: "DE",
    country_long: "Germany",
    portCode: "DEVOE",
    portName: "Volklingen (DEVOE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.55053700000001",
    lng: "13.1057352",
    country: "DE",
    country_long: "Germany",
    portCode: "DEVTT",
    portName: "Hiddensee (DEVTT), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.7988978",
    lng: "12.1567681",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWDH",
    portName: "WINDISCHESCHENBACH",
    portContinent: "Europe",
  },
  {
    lat: "53.58238129999999",
    lng: "9.7137465",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWED",
    portName: "Wedel (DEWED), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.1638205",
    lng: "7.346154",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWEE",
    portName: "Weener (DEWEE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6643079",
    lng: "6.6295679",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWES",
    portName: "Wesel (DEWES), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.8449088",
    lng: "9.4011905",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWEW",
    portName: "Wewelsfleth (DEWEW), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.0782184",
    lng: "8.239760799999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWIB",
    portName: "Wiesbaden",
    portContinent: "Europe",
  },
  {
    lat: "53.8879706",
    lng: "11.4621371",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWIS",
    portName: "Port of Wismar",
    portContinent: "Europe",
  },
  {
    lat: "50.8255624",
    lng: "6.9725385",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWLG",
    portName: "Wesseling (DEWLG), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.5934368",
    lng: "7.6198121",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWLR",
    portName: "Weil Am Rhein (DEWLR), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.41627039999999",
    lng: "7.456775299999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWNU",
    portName: "Weissenthurm (DEWNU), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.0514423",
    lng: "8.257337999999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWOE",
    portName: "Worth am Rhein",
    portContinent: "Europe",
  },
  {
    lat: "54.0496018",
    lng: "13.7665592",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWOL",
    portName: "Wolgast (DEWOL), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.6341372",
    lng: "8.3507182",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWOR",
    portName: "Worms (DEWOR), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.727049",
    lng: "10.7334362",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWTN",
    portName: "Wittingen (DEWTN), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.79130439999999",
    lng: "9.9533548",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWUE",
    portName: "Wurzburg (DEWUE), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.2562128",
    lng: "7.150763599999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWUP",
    portName: "Wuppertal",
    portContinent: "Europe",
  },
  {
    lat: "49.0514423",
    lng: "8.257337999999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWUR",
    portName: "WORTH (DEWUR), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.53234029999999",
    lng: "8.1068722",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWVN",
    portName: "Port of Wilhelmshaven",
    portContinent: "Europe",
  },
  {
    lat: "50.31418770000001",
    lng: "7.517376400000001",
    country: "DE",
    country_long: "Germany",
    portCode: "DEWWG",
    portName: "Winningen (DEWWG), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.0324264",
    lng: "7.196498099999999",
    country: "DE",
    country_long: "Germany",
    portCode: "DEZEL",
    portName: "Zell (Mosel) (DEZEL), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.4357158",
    lng: "12.6888015",
    country: "DE",
    country_long: "Germany",
    portCode: "DEZIS",
    portName: "Zingst (DEZIS), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.7069243",
    lng: "12.4591138",
    country: "DE",
    country_long: "Germany",
    portCode: "DEZWI",
    portName: "ZWICKAU",
    portContinent: "Europe",
  },
  {
    lat: "11.6087133",
    lng: "43.1425491",
    country: "DJ",
    country_long: "Djibouti",
    portCode: "DJJIB",
    portName: "Port of Djibouti",
    portContinent: "Africa",
  },
  {
    lat: "55.040852",
    lng: "9.415158900000002",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKAAB",
    portName: "Port of Aabenraa",
    portContinent: "Europe",
  },
  {
    lat: "57.047794",
    lng: "10.049877",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKAAL",
    portName: "Port of Aalborg",
    portContinent: "Europe",
  },
  {
    lat: "55.3791812",
    lng: "10.0483609",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKAAP",
    portName: "Aarup",
    portContinent: "Europe",
  },
  {
    lat: "56.162939",
    lng: "10.203921",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKAAR",
    portName: "Aarhus",
    portContinent: "Europe",
  },
  {
    lat: "55.2482762",
    lng: "14.7961808",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKAGE",
    portName: "Port of Allinge",
    portContinent: "Europe",
  },
  {
    lat: "55.2707416",
    lng: "9.8883522",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKASN",
    portName: "Assens Havn",
    portContinent: "Europe",
  },
  {
    lat: "54.83578199999999",
    lng: "11.482409",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKBDX",
    portName: "Haven Bandholm",
    portContinent: "Europe",
  },
  {
    lat: "56.377449",
    lng: "9.655211",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKBJB",
    portName: "Bjerringbro",
    portContinent: "Europe",
  },
  {
    lat: "55.728449",
    lng: "9.112366000000002",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKBLL",
    portName: "Billund",
    portContinent: "Europe",
  },
  {
    lat: "55.46917699999999",
    lng: "8.701654999999999",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKBMG",
    portName: "Bramming",
    portContinent: "Europe",
  },
  {
    lat: "55.103261",
    lng: "10.100358",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKBOS",
    portName: "Boejden (DKBOS), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.2493786",
    lng: "10.2525575",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKBOY",
    portName: "Broby",
    portContinent: "Europe",
  },
  {
    lat: "55.9426864",
    lng: "9.1287953",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKBRA",
    portName: "Brande",
    portContinent: "Europe",
  },
  {
    lat: "54.890351",
    lng: "9.669889999999999",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKBRG",
    portName: "Broager",
    portContinent: "Europe",
  },
  {
    lat: "55.6719773772586",
    lng: "12.58618160364453",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKCPH",
    portName: "Port of Copenhagen",
    portContinent: "Europe",
  },
  {
    lat: "55.35665299999999",
    lng: "9.486305",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKCSF",
    portName: "Christiansfeld",
    portContinent: "Europe",
  },
  {
    lat: "56.350851",
    lng: "9.501775",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKDKA",
    portName: "Rodkaersbro",
    portContinent: "Europe",
  },
  {
    lat: "55.594023",
    lng: "12.660503",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKDRA",
    portName: "Dragoer (DKDRA), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.4639695",
    lng: "8.4463381",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKEBJ",
    portName: "Port of Esbjerg",
    portContinent: "Europe",
  },
  {
    lat: "56.196327",
    lng: "10.677857",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKEBT",
    portName: "Port of Ebeltoft",
    portContinent: "Europe",
  },
  {
    lat: "55.7554349",
    lng: "10.2958585",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKEDL",
    portName: "Endelave (DKEDL), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.4345817",
    lng: "9.9393639",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKEJY",
    portName: "Ejby",
    portContinent: "Europe",
  },
  {
    lat: "54.9898541",
    lng: "9.4075866",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKENS",
    portName: "Haven Ensted",
    portContinent: "Europe",
  },
  {
    lat: "55.98526099999999",
    lng: "9.290296999999999",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKETH",
    portName: "Ejstrupholm",
    portContinent: "Europe",
  },
  {
    lat: "55.0930619",
    lng: "10.2402771",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKFAA",
    portName: "Faaborg Havn",
    portContinent: "Europe",
  },
  {
    lat: "55.2211383",
    lng: "12.1710233",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKFAK",
    portName: "Fakse Ladeplads Havn",
    portContinent: "Europe",
  },
  {
    lat: "57.43616499999999",
    lng: "10.548789",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKFDH",
    portName: "Port of Frederikshavn",
    portContinent: "Europe",
  },
  {
    lat: "55.835592",
    lng: "12.062436",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKFDS",
    portName: "Frederikssund Harbour",
    portContinent: "Europe",
  },
  {
    lat: "55.9659716",
    lng: "11.9994727",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKFDV",
    portName: "Frederiksvaerk Havn",
    portContinent: "Europe",
  },
  {
    lat: "55.5689298",
    lng: "9.749516999999999",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKFRC",
    portName: "Fredericia",
    portContinent: "Europe",
  },
  {
    lat: "54.987793",
    lng: "9.981231",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKFYH",
    portName: "Fynshav (DKFYH), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.2084554",
    lng: "14.9716865",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKGDM",
    portName: "Gudhjem (DKGDM), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.574422",
    lng: "11.931194",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKGED",
    portName: "Gedser Fiskerihavn",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DKGFH",
    portName: "Gulfhavn",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DKGIV",
    portName: "Give",
    portContinent: "Europe",
  },
  {
    lat: "55.3988323",
    lng: "9.9733561",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKGLD",
    portName: "Gelsted",
    portContinent: "Europe",
  },
  {
    lat: "56.122828",
    lng: "12.301261",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKGLE",
    portName: "Gilleleje (DKGLE), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.154126",
    lng: "9.907665",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKGLT",
    portName: "Galten",
    portContinent: "Europe",
  },
  {
    lat: "56.4166667",
    lng: "10.9333333",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKGRE",
    portName: "Port of Grenaa",
    portContinent: "Europe",
  },
  {
    lat: "55.47282200000001",
    lng: "8.799455",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKGRG",
    portName: "Goerding",
    portContinent: "Europe",
  },
  {
    lat: "55.7583217",
    lng: "8.9249494",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKGRN",
    portName: "Grindsted",
    portContinent: "Europe",
  },
  {
    lat: "55.250072",
    lng: "9.48911",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHAD",
    portName: "Port of Haderslev",
    portContinent: "Europe",
  },
  {
    lat: "57.112861",
    lng: "8.617668000000002",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHAN",
    portName: "Port of Hanstholm",
    portContinent: "Europe",
  },
  {
    lat: "56.996636",
    lng: "10.308609",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHAS",
    portName: "Hals (DKHAS), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.7146554",
    lng: "11.717578",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHBK",
    portName: "Holbaek (DKHBK), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.6391392",
    lng: "9.797647999999999",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHBO",
    portName: "Port of Hobro",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DKHBY",
    portName: "HÃ¥rby",
    portContinent: "Europe",
  },
  {
    lat: "56.329953",
    lng: "10.047763",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHDS",
    portName: "Hadsten",
    portContinent: "Europe",
  },
  {
    lat: "55.77171800000001",
    lng: "9.70228",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHED",
    portName: "Hedensted",
    portContinent: "Europe",
  },
  {
    lat: "56.138557",
    lng: "8.967322",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHER",
    portName: "Herning",
    portContinent: "Europe",
  },
  {
    lat: "57.5942361",
    lng: "9.9628099",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHIR",
    portName: "Port of Hirtshals",
    portContinent: "Europe",
  },
  {
    lat: "54.965419",
    lng: "8.695165",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHJE",
    portName: "HOJER",
    portContinent: "Europe",
  },
  {
    lat: "56.0333333",
    lng: "12.6166667",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHLS",
    portName: "Helsingor Havn",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DKHOR",
    portName: "Port of Horsens",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DKHSL",
    portName: "Port of Hasle",
    portContinent: "Europe",
  },
  {
    lat: "56.72143699999999",
    lng: "10.112897",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHSU",
    portName: "Port of Hadsund",
    portContinent: "Europe",
  },
  {
    lat: "55.755502",
    lng: "9.855972999999999",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHSY",
    portName: "Hornsyld",
    portContinent: "Europe",
  },
  {
    lat: "56.011793",
    lng: "8.127934999999999",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHVS",
    portName: "Hvide Sande (DKHVS), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.26392",
    lng: "9.501785",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKHWO",
    portName: "Hov (DKHWO), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.708806",
    lng: "9.998833",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKJUE",
    portName: "Juelsminde",
    portContinent: "Europe",
  },
  {
    lat: "55.6724048",
    lng: "11.1022938",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKKAL",
    portName: "Port of Kalundborg",
    portContinent: "Europe",
  },
  {
    lat: "55.481932",
    lng: "10.449774",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKKBG",
    portName: "Klintebjerg (DKKBG), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.4546471",
    lng: "12.1912193",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKKOG",
    portName: "Koege Havn",
    portContinent: "Europe",
  },
  {
    lat: "56.980407",
    lng: "9.424615",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKKOK",
    portName: "Kolby (DKKOK), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.495973",
    lng: "9.4730519",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKKOL",
    portName: "Port of Kolding",
    portContinent: "Europe",
  },
  {
    lat: "55.32576299999999",
    lng: "11.14939",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKKRR",
    portName: "Korsor (DKKRR), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.455882",
    lng: "10.654979",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKKTD",
    portName: "Port of Kerteminde",
    portContinent: "Europe",
  },
  {
    lat: "56.15173799999999",
    lng: "9.808828",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKLAA",
    portName: "LASBY",
    portContinent: "Europe",
  },
  {
    lat: "55.35671600000001",
    lng: "10.586016",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKLGS",
    portName: "Langeskov",
    portContinent: "Europe",
  },
  {
    lat: "55.797506",
    lng: "9.695883",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKLIG",
    portName: "Losning",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DKLIN",
    portName: "Lindoe Terminal",
    portContinent: "Europe",
  },
  {
    lat: "55.48150399999999",
    lng: "9.298242",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKLND",
    portName: "Lunderskov",
    portContinent: "Europe",
  },
  {
    lat: "56.5443443",
    lng: "8.3024871",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKLVG",
    portName: "Port of Lemvig",
    portContinent: "Europe",
  },
  {
    lat: "55.497204",
    lng: "9.747171999999999",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKMID",
    portName: "Terminal Middelfart",
    portContinent: "Europe",
  },
  {
    lat: "56.64999599999999",
    lng: "9.977344000000002",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKMRR",
    portName: "Port of Mariager",
    portContinent: "Europe",
  },
  {
    lat: "54.856099",
    lng: "10.514481",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKMRS",
    portName: "Port of Marstal",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DKMUP",
    portName: "MÃ¸ldrup",
    portContinent: "Europe",
  },
  {
    lat: "54.833406",
    lng: "11.143724",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKNAK",
    portName: "Port of Nakskov",
    portContinent: "Europe",
  },
  {
    lat: "55.324169",
    lng: "10.799561",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKNBG",
    portName: "ADP Nyborg Terminal",
    portContinent: "Europe",
  },
  {
    lat: "55.055721",
    lng: "9.751009",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKNBR",
    portName: "Nordborg",
    portContinent: "Europe",
  },
  {
    lat: "-41.2653466",
    lng: "173.2774862",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "DKNEX",
    portName: "Port of Nekso",
    portContinent: "Europe",
  },
  {
    lat: "55.472362",
    lng: "9.873349",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKNRE",
    portName: "Norre Aaby",
    portContinent: "Europe",
  },
  {
    lat: "55.9650638",
    lng: "9.3927797",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKNSD",
    portName: "NÃ¸rre Snede",
    portContinent: "Europe",
  },
  {
    lat: "54.76542200000001",
    lng: "11.875492",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKNYF",
    portName: "Port of Nykobing Falster",
    portContinent: "Europe",
  },
  {
    lat: "56.7955597",
    lng: "8.857441699999999",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKNYM",
    portName: "Port of Nykobing Mors",
    portContinent: "Europe",
  },
  {
    lat: "55.4084139",
    lng: "10.3814619",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKODE",
    portName: "Port of Odense",
    portContinent: "Europe",
  },
  {
    lat: "55.968146",
    lng: "9.751776",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKOKB",
    portName: "Ostbirk",
    portContinent: "Europe",
  },
  {
    lat: "55.807331",
    lng: "8.618423",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKOLG",
    portName: "OLGOD",
    portContinent: "Europe",
  },
  {
    lat: "55.7200381",
    lng: "8.3429152",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKOP2",
    portName: "Outrup",
    portContinent: "Europe",
  },
  {
    lat: "54.833123",
    lng: "9.356859",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKPAO",
    portName: "Padborg",
    portContinent: "Europe",
  },
  {
    lat: "56.460584",
    lng: "10.036539",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKRAN",
    portName: "Port of Randers",
    portContinent: "Europe",
  },
  {
    lat: "55.69421699999999",
    lng: "9.2476",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKRBL",
    portName: "Randbol",
    portContinent: "Europe",
  },
  {
    lat: "55.07268699999999",
    lng: "9.329015",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKRDK",
    portName: "Rodekro",
    portContinent: "Europe",
  },
  {
    lat: "55.326936",
    lng: "8.774665",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKRIB",
    portName: "Ribe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DKRIN",
    portName: "Ringe",
    portContinent: "Europe",
  },
  {
    lat: "54.937878",
    lng: "10.718582",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKRKB",
    portName: "Port of Rudkobing",
    portContinent: "Europe",
  },
  {
    lat: "56.088054",
    lng: "8.257633000000002",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKRKG",
    portName: "Port of Ringkobing",
    portContinent: "Europe",
  },
  {
    lat: "55.0924657",
    lng: "14.6946078",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKRNN",
    portName: "Ronne Havn",
    portContinent: "Europe",
  },
  {
    lat: "54.662743",
    lng: "11.356621",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKROD",
    portName: "Port of Rodbyhavn",
    portContinent: "Europe",
  },
  {
    lat: "54.888258",
    lng: "12.036023",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSBK",
    portName: "Port of Stubbekobing",
    portContinent: "Europe",
  },
  {
    lat: "54.913811",
    lng: "9.792178",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSGD",
    portName: "Port of Sonderborg",
    portContinent: "Europe",
  },
  {
    lat: "55.9671609",
    lng: "11.3667091",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSJO",
    portName: "Sjaellands Odde (DKSJO), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DKSKA",
    portName: "Skagen Harbour",
    portContinent: "Europe",
  },
  {
    lat: "56.037247",
    lng: "9.9297989",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSKG",
    portName: "Skanderborg",
    portContinent: "Europe",
  },
  {
    lat: "55.944128",
    lng: "8.500266",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSKJ",
    portName: "Skjern",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DKSKS",
    portName: "VojensÂ",
    portContinent: "Europe",
  },
  {
    lat: "56.5651232",
    lng: "9.0309083",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSKV",
    portName: "Port of Skive",
    portContinent: "Europe",
  },
  {
    lat: "56.26392",
    lng: "9.501785",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSLV",
    portName: "Saelvig (DKSLV), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.930597",
    lng: "10.2648669",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSOB",
    portName: "Soeby (DKSOB), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.25382399999999",
    lng: "10.345829",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSSV",
    portName: "Port of Studstrup",
    portContinent: "Europe",
  },
  {
    lat: "57.49375329999999",
    lng: "10.494117",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSTD",
    portName: "Strandby (DKSTD), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.991021",
    lng: "12.286917",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSTE",
    portName: "Stege Havn",
    portContinent: "Europe",
  },
  {
    lat: "55.2169444",
    lng: "11.2680556",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSTG",
    portName: "Stigsnaesvaerkets Havn",
    portContinent: "Europe",
  },
  {
    lat: "56.26392",
    lng: "9.501785",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSTP",
    portName: "Stern (DKSTP), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.48493",
    lng: "8.589933",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSTR",
    portName: "Port of Holstebro - Struer",
    portContinent: "Europe",
  },
  {
    lat: "55.13619300000001",
    lng: "15.140973",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSVA",
    portName: "Port of Svaneke",
    portContinent: "Europe",
  },
  {
    lat: "55.067434",
    lng: "10.607282",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKSVE",
    portName: "Port of Svendborg",
    portContinent: "Europe",
  },
  {
    lat: "55.6460162",
    lng: "12.2979366",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKTAA",
    portName: "Taastrup",
    portContinent: "Europe",
  },
  {
    lat: "56.959168",
    lng: "8.703491999999999",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKTED",
    portName: "Port of Thisted",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DKTHM",
    portName: "Them",
    portContinent: "Europe",
  },
  {
    lat: "56.69983",
    lng: "8.211547",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKTHN",
    portName: "Thyboron (DKTHN), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.538146",
    lng: "9.610191",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKTLV",
    portName: "Taulov",
    portContinent: "Europe",
  },
  {
    lat: "55.9490529",
    lng: "10.4333473",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKTNO",
    portName: "Tuno (DKTNO), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.939615",
    lng: "8.864417",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKTON",
    portName: "Tonder",
    portContinent: "Europe",
  },
  {
    lat: "56.253742",
    lng: "10.148466",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKTRI",
    portName: "Trige",
    portContinent: "Europe",
  },
  {
    lat: "57.38507319999999",
    lng: "10.1140349",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKTRS",
    portName: "Tars (DKTRS), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DKTUB",
    portName: "Port of Tuborg",
    portContinent: "Europe",
  },
  {
    lat: "55.84780499999999",
    lng: "9.589485",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKULD",
    portName: "Uldum",
    portContinent: "Europe",
  },
  {
    lat: "55.623151",
    lng: "8.482149999999999",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKVDE",
    portName: "Varde",
    portContinent: "Europe",
  },
  {
    lat: "55.43001700000001",
    lng: "9.287362",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKVDP",
    portName: "Vamdrup",
    portContinent: "Europe",
  },
  {
    lat: "55.7113112",
    lng: "9.536354099999999",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKVEJ",
    portName: "Port of Vejle",
    portContinent: "Europe",
  },
  {
    lat: "56.45202699999999",
    lng: "9.396347",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKVIB",
    portName: "Viborg",
    portContinent: "Europe",
  },
  {
    lat: "55.4659847",
    lng: "9.0756715",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKVJN",
    portName: "Vejen",
    portContinent: "Europe",
  },
  {
    lat: "55.008925",
    lng: "11.909363",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKVOR",
    portName: "Port of Vordingborg",
    portContinent: "Europe",
  },
  {
    lat: "55.6284685",
    lng: "12.4478539",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKVRE",
    portName: "Avedore (DKVRE), Denmark, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.162939",
    lng: "10.203921",
    country: "DK",
    country_long: "Denmark",
    portCode: "DKXAF",
    portName: "Silkeborg (DKXAF), Aarhus, Denmark",
    portContinent: "Europe",
  },
  {
    lat: "15.414999",
    lng: "-61.37097600000001",
    country: "DM",
    country_long: "Dominica",
    portCode: "DMBEL",
    portName: "Belfast (DMBEL), Dominica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "50.8197675",
    lng: "-1.0879769",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "DMPOR",
    portName: "Port of Portsmouth",
    portContinent: "North America",
  },
  {
    lat: "48.8460926",
    lng: "-95.762766",
    country: "US",
    country_long: "United States",
    portCode: "DMRSU",
    portName: "Port of Roseau",
    portContinent: "North America",
  },
  {
    lat: "19.267656",
    lng: "-69.7304614",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOAZS",
    portName: "Samana (DOAZS), Dominican Republic, LatAm",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DOBCC",
    portName: "Port of Boca Chica",
    portContinent: "North America",
  },
  {
    lat: "18.2063205",
    lng: "-71.0914718",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOBRX",
    portName: "Port of Barahona",
    portContinent: "North America",
  },
  {
    lat: "18.5038611",
    lng: "-69.98179879999999",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOCAU",
    portName: "Caucedo",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DOCBJ",
    portName: "Port of Cabo Rojo",
    portContinent: "North America",
  },
  {
    lat: "18.4141499",
    lng: "-68.935294",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOCDC",
    portName: "Casa De Campo (DOCDC), Dominican Republic, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.4279901",
    lng: "-70.0153299",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOHAI",
    portName: "Port of Rio Haina",
    portContinent: "North America",
  },
  {
    lat: "18.2189698",
    lng: "-70.49228149999999",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOLCS",
    portName: "Las Calderas (DOLCS), Dominican Republic, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.4338645",
    lng: "-68.9658817",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOLRM",
    portName: "Port of La Romana",
    portContinent: "North America",
  },
  {
    lat: "18.9114821",
    lng: "-70.7376623",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOLVC",
    portName: "Constanza",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DOMAN",
    portName: "Port of Manzanillo",
    portContinent: "North America",
  },
  {
    lat: "17.4847697",
    lng: "-92.0458949",
    country: "MX",
    country_long: "Mexico",
    portCode: "DOPAL",
    portName: "Port of Palenque",
    portContinent: "North America",
  },
  {
    lat: "19.7981544",
    lng: "-70.7012239",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOPOP",
    portName: "Port of Puerto Plata",
    portContinent: "North America",
  },
  {
    lat: "18.5632813",
    lng: "-68.36849310000001",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOPUJ",
    portName: "Punta Cana (DOPUJ), Dominican Republic, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.3498977",
    lng: "-70.83633669999999",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOPVA",
    portName: "Port of Puerto Viejo de Azua",
    portContinent: "North America",
  },
  {
    lat: "18.4688537",
    lng: "-69.8833913",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOSDQ",
    portName: "Port of Santo Domingo",
    portContinent: "North America",
  },
  {
    lat: "18.735693",
    lng: "-70.162651",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOSNZ",
    portName: "Sanchez (DOSNZ), Dominican Republic, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.465775",
    lng: "-69.2987279",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOSPM",
    portName: "Port of San Pedro de Macoris",
    portContinent: "North America",
  },
  {
    lat: "19.4791963",
    lng: "-70.6930568",
    country: "DO",
    country_long: "Dominican Republic",
    portCode: "DOSTI",
    portName: "Santiago (DOSTI), Santiago, Dominican Republic",
    portContinent: "North America",
  },
  {
    lat: "36.8974177",
    lng: "7.7498372",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZAAE",
    portName: "Annaba (ex Bone)",
    portContinent: "North Africa",
  },
  {
    lat: "36.753768",
    lng: "3.0587561",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZACS",
    portName: "Algiers Terminal",
    portContinent: "North Africa",
  },
  {
    lat: "36.753768",
    lng: "3.0587561",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZALG",
    portName: "Alger (Algiers)",
    portContinent: "North Africa",
  },
  {
    lat: "36.5906719",
    lng: "2.4433723",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZAZA",
    portName: "Tipaza (DZAZA), Algeria, Med",
    portContinent: "North Africa",
  },
  {
    lat: "35.8577443",
    lng: "-0.3096606",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZAZW",
    portName: "Arzew (DZAZW), Algeria, Med",
    portContinent: "North Africa",
  },
  {
    lat: "36.7429392",
    lng: "5.058345999999999",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZBJA",
    portName: "Bejaia (ex Bougie)",
    portContinent: "North Africa",
  },
  {
    lat: "35.2897861",
    lng: "-1.3768856",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZBSF",
    portName: "Beni Saf (DZBSF), Algeria, Med",
    portContinent: "North Africa",
  },
  {
    lat: "36.5955738",
    lng: "2.2525015",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZCHE",
    portName: "Cherchell (DZCHE), Algeria, Med",
    portContinent: "North Africa",
  },
  {
    lat: "36.9981614",
    lng: "6.5557168",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZCOL",
    portName: "Collo (DZCOL), Algeria, Med",
    portContinent: "North Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "DZCZL",
    portName: "Constantine",
    portContinent: "North Africa",
  },
  {
    lat: "36.9126574",
    lng: "3.9124569",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZDEL",
    portName: "Dellys (DZDEL), Algeria, Med",
    portContinent: "North Africa",
  },
  {
    lat: "36.8221505",
    lng: "5.8855146",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZDJE",
    portName: "Djen Djen (DZDJE), Algeria, Med",
    portContinent: "North Africa",
  },
  {
    lat: "35.0936543",
    lng: "-1.861861",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZGHZ",
    portName: "Ghazaouet (DZGHZ), Algeria, Med",
    portContinent: "North Africa",
  },
  {
    lat: "35.9311454",
    lng: "0.09094139999999999",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZMOS",
    portName: "Mostaganem (DZMOS), Algeria, Med",
    portContinent: "North Africa",
  },
  {
    lat: "35.6987388",
    lng: "-0.6349319",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZORN",
    portName: "Oran",
    portContinent: "North Africa",
  },
  {
    lat: "36.8856754",
    lng: "8.4408187",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZQLK",
    portName: "El Kala (DZQLK), Algeria, Med",
    portContinent: "North Africa",
  },
  {
    lat: "36.8622282",
    lng: "6.905622300000001",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZSKI",
    portName: "Skikda (ex Philippeville)",
    portContinent: "North Africa",
  },
  {
    lat: "36.5081163",
    lng: "1.3078225",
    country: "DZ",
    country_long: "Algeria",
    portCode: "DZTEN",
    portName: "Tenes (DZTEN), Algeria, Med",
    portContinent: "North Africa",
  },
  {
    lat: "-1.215918",
    lng: "-78.4638274",
    country: "EC",
    country_long: "Ecuador",
    portCode: "ECBAQ",
    portName: "Baquerizo Moreno (ECBAQ), Ecuador, Sam",
    portContinent: "South America",
  },
  {
    lat: "-0.6186619",
    lng: "-80.4273644",
    country: "EC",
    country_long: "Ecuador",
    portCode: "ECBHA",
    portName: "Port of Bahia De Caraquez",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ECEBL",
    portName: "Balao Terminal",
    portContinent: "South America",
  },
  {
    lat: "0.9681789",
    lng: "-79.6517202",
    country: "EC",
    country_long: "Ecuador",
    portCode: "ECESM",
    portName: "Portuaria de Esmeraldas",
    portContinent: "South America",
  },
  {
    lat: "-2.2779725",
    lng: "-79.9082239",
    country: "EC",
    country_long: "Ecuador",
    portCode: "ECGYE",
    portName: "Port of Guayaquil",
    portContinent: "South America",
  },
  {
    lat: "33.73954589643058",
    lng: "-118.2599686241527",
    country: "US",
    country_long: "United States",
    portCode: "ECLLD",
    portName: "Port of La Libertad",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ECMEC",
    portName: "Port of Manta",
    portContinent: "South America",
  },
  {
    lat: "-2.0443755",
    lng: "-80.72698539999999",
    country: "EC",
    country_long: "Ecuador",
    portCode: "ECMTV",
    portName: "Monteverde (ECMTV), Ecuador, Sam",
    portContinent: "South America",
  },
  {
    lat: "-3.2559896",
    lng: "-79.9947956",
    country: "EC",
    country_long: "Ecuador",
    portCode: "ECPBO",
    portName: "Port of Puerto Bolivar",
    portContinent: "South America",
  },
  {
    lat: "-2.710949",
    lng: "-80.2516473",
    country: "EC",
    country_long: "Ecuador",
    portCode: "ECPSJ",
    portName: "Posorja",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ECSLR",
    portName: "Port of San Lorenzo",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ECTEP",
    portName: "Tepre Terminal",
    portContinent: "South America",
  },
  {
    lat: "-0.1806532",
    lng: "-78.4678382",
    country: "EC",
    country_long: "Ecuador",
    portCode: "ECUIO",
    portName: "Quito",
    portContinent: "South America",
  },
  {
    lat: "-0.9544574",
    lng: "-90.96414670000001",
    country: "EC",
    country_long: "Ecuador",
    portCode: "ECVIL",
    portName: "Puerto Villamil (ECVIL), Ecuador, Sam",
    portContinent: "South America",
  },
  {
    lat: "-0.459667",
    lng: "-90.27139439999999",
    country: "EC",
    country_long: "Ecuador",
    portCode: "ECWGL",
    portName: "Baltra (ECWGL), Ecuador, Sam",
    portContinent: "South America",
  },
  {
    lat: "58.1499355",
    lng: "22.5055",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEABR",
    portName: "Abruka (EEABR), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.583956",
    lng: "24.7584978",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEAEN",
    portName: "Aegna (EEAEN), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.4541667",
    lng: "24.6647222",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEBEK",
    portName: "Bekkeri (EEBEK), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.20089290000001",
    lng: "23.4952879",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEDIR",
    portName: "Dirhami (EEDIR), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.86330659999999",
    lng: "23.0318813",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEHLT",
    portName: "Heltermaa (EEHLT), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.4555556",
    lng: "24.7027778",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEHUN",
    portName: "Hundipea (EEHUN), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.636044",
    lng: "24.9959282",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEKLN",
    portName: "Kelnase (EEKLN), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.5006139",
    lng: "26.5144401",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEKND",
    portName: "Kunda (EEKND), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.6987575",
    lng: "25.023151",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEKSR",
    portName: "Keri Saar (EEKSR), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.56508249999999",
    lng: "23.3730655",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEKUI",
    portName: "Kuivastu (EEKUI), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.5437959",
    lng: "24.865902",
    country: "EE",
    country_long: "Estonia",
    portCode: "EELEP",
    portName: "Leppneeme (EELEP), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.0649864",
    lng: "22.6484783",
    country: "EE",
    country_long: "Estonia",
    portCode: "EELHT",
    portName: "Lehtma (EELHT), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.58124489999999",
    lng: "25.7213551",
    country: "EE",
    country_long: "Estonia",
    portCode: "EELSA",
    portName: "Loksa (EELSA), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.50157729999999",
    lng: "24.819746",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEMDR",
    portName: "Miiduranna (EEMDR), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.595272",
    lng: "25.013607",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEMRS",
    portName: "Meeruse (EEMRS), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.49499029999999",
    lng: "24.9636009",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEMUG",
    portName: "Muuga (EEMUG), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.3796931",
    lng: "28.179075",
    country: "EE",
    country_long: "Estonia",
    portCode: "EENAR",
    portName: "Narva (EENAR), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.23152899999999",
    lng: "22.3892742",
    country: "EE",
    country_long: "Estonia",
    portCode: "EENAS",
    portName: "Nasva (EENAS), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.4575701",
    lng: "28.0378928",
    country: "EE",
    country_long: "Estonia",
    portCode: "EENJS",
    portName: "Narva Joesuu (EENJS), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.47097669999999",
    lng: "24.7084479",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEPAS",
    portName: "Paljassaare (EEPAS), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.47490000000001",
    lng: "24.8724999",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEPIR",
    portName: "Pirita (EEPIR), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.3528731",
    lng: "24.0551582",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEPLA",
    portName: "PALDISKI (EEPLA), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.38593429999999",
    lng: "24.4878864",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEPRN",
    portName: "Port of Parnu",
    portContinent: "Europe",
  },
  {
    lat: "58.9108964",
    lng: "23.4523952",
    country: "EE",
    country_long: "Estonia",
    portCode: "EERHK",
    portName: "Rohukula (EERHK), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.0250154",
    lng: "22.9005216",
    country: "EE",
    country_long: "Estonia",
    portCode: "EERMS",
    portName: "Roomassaare (EERMS), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.5534",
    lng: "24.8184196",
    country: "EE",
    country_long: "Estonia",
    portCode: "EERON",
    portName: "Rohuneeme (EERON), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.805148",
    lng: "23.2460512",
    country: "EE",
    country_long: "Estonia",
    portCode: "EERUH",
    portName: "Ruhnu (EERUH), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.3995308",
    lng: "27.748088",
    country: "EE",
    country_long: "Estonia",
    portCode: "EESLM",
    portName: "Sillamae",
    portContinent: "Europe",
  },
  {
    lat: "58.4849721",
    lng: "22.6136407",
    country: "EE",
    country_long: "Estonia",
    portCode: "EESMA",
    portName: "Saaremaa (EESMA), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.7015387",
    lng: "22.5111006",
    country: "EE",
    country_long: "Estonia",
    portCode: "EESRU",
    portName: "Soru (EESRU), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.9791667",
    lng: "22.9183333",
    country: "EE",
    country_long: "Estonia",
    portCode: "EESUS",
    portName: "Suursadam (EESUS), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.9909026",
    lng: "23.3040328",
    country: "EE",
    country_long: "Estonia",
    portCode: "EESVI",
    portName: "Sviby Harbor",
    portContinent: "Europe",
  },
  {
    lat: "58.37798299999999",
    lng: "26.7290383",
    country: "EE",
    country_long: "Estonia",
    portCode: "EETAY",
    portName: "TARTU",
    portContinent: "Europe",
  },
  {
    lat: "58.5860251",
    lng: "22.7029971",
    country: "EE",
    country_long: "Estonia",
    portCode: "EETGI",
    portName: "Triigi Harbor",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "EETLL",
    portName: "Port of Tallinn",
    portContinent: "Europe",
  },
  {
    lat: "58.595272",
    lng: "25.013607",
    country: "EE",
    country_long: "Estonia",
    portCode: "EETOI",
    portName: "Toila (EETOI), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.5014859",
    lng: "23.0804371",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEVEE",
    portName: "Veere (EEVEE), Estonia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.5810002",
    lng: "23.541749",
    country: "EE",
    country_long: "Estonia",
    portCode: "EEVIR",
    portName: "Virtsu Harbour",
    portContinent: "Europe",
  },
  {
    lat: "30.0795726",
    lng: "31.3350582",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGABU",
    portName: "Abu Chosoun (EGABU), Egypt, Med",
    portContinent: "North Africa",
  },
  {
    lat: "29.87274",
    lng: "32.467621",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGADA",
    portName: "Port of Adabiya",
    portContinent: "North Africa",
  },
  {
    lat: "30.0130557",
    lng: "31.2088526",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGAGZ",
    portName: "GIZA",
    portContinent: "North Africa",
  },
  {
    lat: "29.6724876",
    lng: "32.3369649",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGAIS",
    portName: "Ain Sukhna Terminal",
    portContinent: "North Africa",
  },
  {
    lat: "31.2242851",
    lng: "29.9466569",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGAKI",
    portName: "Abu Qir (EGAKI), Alexandria, Egypt",
    portContinent: "North Africa",
  },
  {
    lat: "31.2000924",
    lng: "29.9187387",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGALY",
    portName: "El Iskandariya (Alexandria)",
    portContinent: "North Africa",
  },
  {
    lat: "27.1783117",
    lng: "31.1859257",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGAST",
    portName: "Asyut",
    portContinent: "North Africa",
  },
  {
    lat: "24.088938",
    lng: "32.8998293",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGASW",
    portName: "Aswan",
    portContinent: "North Africa",
  },
  {
    lat: "43.476472",
    lng: "-0.38216",
    country: "FR",
    country_long: "France",
    portCode: "EGAUG",
    portName: "AUGA",
    portContinent: "North Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "EGBAN",
    portName: "BANHA",
    portContinent: "North Africa",
  },
  {
    lat: "30.9150146",
    lng: "29.5455699",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGBOR",
    portName: "BORG EL ARAB",
    portContinent: "North Africa",
  },
  {
    lat: "30.0444196",
    lng: "31.2357116",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGCAI",
    portName: "Cairo (EGCAI), Cairo, Egypt",
    portContinent: "North Africa",
  },
  {
    lat: "31.4175388",
    lng: "31.81444339999999",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGDAM",
    portName: "Damietta",
    portContinent: "North Africa",
  },
  {
    lat: "31.123045",
    lng: "29.8178676",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGEDK",
    portName: "El Dekheila",
    portContinent: "North Africa",
  },
  {
    lat: "28.2410098",
    lng: "33.6229813",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGELT",
    portName: "El Tor (EGELT), Egypt, Med",
    portContinent: "North Africa",
  },
  {
    lat: "30.2283408",
    lng: "31.4798948",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGEOB",
    portName: "El Obour",
    portContinent: "North Africa",
  },
  {
    lat: "27.9654198",
    lng: "34.3617769",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGGSQ",
    portName: "SHARM EL",
    portContinent: "North Africa",
  },
  {
    lat: "30.9150146",
    lng: "29.5455699",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGHBE",
    portName: "Borg Al Arab",
    portContinent: "North Africa",
  },
  {
    lat: "29.8402707",
    lng: "31.29821209999999",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGHLW",
    portName: "HELWAN",
    portContinent: "North Africa",
  },
  {
    lat: "27.2578957",
    lng: "33.8116067",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGHRG",
    portName: "Port of Hurghada",
    portContinent: "North Africa",
  },
  {
    lat: "31.3040251",
    lng: "30.306546",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGIKU",
    portName: "Idku (EGIKU), Egypt, Med",
    portContinent: "North Africa",
  },
  {
    lat: "30.5964923",
    lng: "32.2714587",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGISM",
    portName: "Ismailia (EGISM), Egypt, Med",
    portContinent: "North Africa",
  },
  {
    lat: "36.09551",
    lng: "-115.1760672",
    country: "US",
    country_long: "United States",
    portCode: "EGLXR",
    portName: "Luxor",
    portContinent: "North Africa",
  },
  {
    lat: "31.3543445",
    lng: "27.2373159",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGMMU",
    portName: "Mersa Martu (EGMMU), Egypt, Med",
    portContinent: "North Africa",
  },
  {
    lat: "31.0409483",
    lng: "31.3784704",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGMSR",
    portName: "EL MANSORA",
    portContinent: "North Africa",
  },
  {
    lat: "31.3543445",
    lng: "27.2373159",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGMUH",
    portName: "MATROUH",
    portContinent: "North Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "EGPRA",
    portName: "RASHED",
    portContinent: "North Africa",
  },
  {
    lat: "31.2652893",
    lng: "32.3018661",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGPSD",
    portName: "Port Said",
    portContinent: "North Africa",
  },
  {
    lat: "31.2652893",
    lng: "32.3018661",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGPSE",
    portName: "Port Said East",
    portContinent: "North Africa",
  },
  {
    lat: "31.2652893",
    lng: "32.3018661",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGPSW",
    portName: "PORT SAID WEST",
    portContinent: "North Africa",
  },
  {
    lat: "29.9395072",
    lng: "32.5614891",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGPTK",
    portName: "port tawfiq",
    portContinent: "North Africa",
  },
  {
    lat: "30.0566104",
    lng: "31.3301076",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGQAH",
    portName: "Nasr City",
    portContinent: "North Africa",
  },
  {
    lat: "26.155061",
    lng: "32.7160121",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGQEN",
    portName: "Qena",
    portContinent: "North Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "EGRAF",
    portName: "RAFAA",
    portContinent: "North Africa",
  },
  {
    lat: "28.3627136",
    lng: "33.079519",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGRAG",
    portName: "Port of Ras Gharib",
    portContinent: "North Africa",
  },
  {
    lat: "25.0676256",
    lng: "34.8789697",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGRMF",
    portName: "Marsa Alam (EGRMF), Egypt, Med",
    portContinent: "North Africa",
  },
  {
    lat: "28.1353599",
    lng: "33.2731022",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGRSH",
    portName: "Port of Ras Shukheir",
    portContinent: "North Africa",
  },
  {
    lat: "33.3899339",
    lng: "44.4606524",
    country: "IQ",
    country_long: "Iraq",
    portCode: "EGSAD",
    portName: "Sadr City",
    portContinent: "North Africa",
  },
  {
    lat: "30.56667224059973",
    lng: "32.44656711444554",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGSCN",
    portName: "Suez/ Suez Canal",
    portContinent: "North Africa",
  },
  {
    lat: "26.7500171",
    lng: "33.9359756",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGSGA",
    portName: "Port of Safaga",
    portContinent: "North Africa",
  },
  {
    lat: "26.5590737",
    lng: "31.6956705",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGSHG",
    portName: "SOHAG",
    portContinent: "North Africa",
  },
  {
    lat: "29.5",
    lng: "34",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGSIN",
    portName: "SINAI",
    portContinent: "North Africa",
  },
  {
    lat: "29.9285429",
    lng: "30.9187827",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGSOC",
    portName: "6th October",
    portContinent: "North Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "EGSOK",
    portName: "Sokhna Port",
    portContinent: "North Africa",
  },
  {
    lat: "29.9285429",
    lng: "30.9187827",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGSOS",
    portName: "SIXTH OF OCTOBER",
    portContinent: "North Africa",
  },
  {
    lat: "27.9654198",
    lng: "34.3617769",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGSSH",
    portName: "Sharm ash Shaykh",
    portContinent: "North Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "EGSUZ",
    portName: "Suez",
    portContinent: "North Africa",
  },
  {
    lat: "41.8917686",
    lng: "-87.63201440000002",
    country: "US",
    country_long: "United States",
    portCode: "EGTAN",
    portName: "Tanta",
    portContinent: "North Africa",
  },
  {
    lat: "30.2926655",
    lng: "31.7423434",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGTRC",
    portName: "10th of Ramadan City",
    portContinent: "North Africa",
  },
  {
    lat: "28.7185675",
    lng: "33.6184308",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGWAF",
    portName: "Wadi Feiran Terminal",
    portContinent: "North Africa",
  },
  {
    lat: "29.9285429",
    lng: "30.9187827",
    country: "EG",
    country_long: "Egypt",
    portCode: "EGXOO",
    portName: "Sixth of October city",
    portContinent: "North Africa",
  },
  {
    lat: "15.3228767",
    lng: "38.9250517",
    country: "ER",
    country_long: "Eritrea",
    portCode: "ERASM",
    portName: "ASMARA",
    portContinent: "North Africa",
  },
  {
    lat: "15.6080391",
    lng: "39.45310690000001",
    country: "ER",
    country_long: "Eritrea",
    portCode: "ERMSW",
    portName: "Massawa (Mitsiwa) (ERMSW), Massawa, Eritrea",
    portContinent: "North Africa",
  },
  {
    lat: "40.5430578",
    lng: "0.4767476",
    country: "ES",
    country_long: "Spain",
    portCode: "ESACA",
    portName: "Port of Alcanar",
    portContinent: "Europe",
  },
  {
    lat: "28.9651694",
    lng: "-13.5550363",
    country: "ES",
    country_long: "Spain",
    portCode: "ESACE",
    portName: "Lanzarote/Arrecife",
    portContinent: "Europe",
  },
  {
    lat: "36.7479022",
    lng: "-3.0163202",
    country: "ES",
    country_long: "Spain",
    portCode: "ESADR",
    portName: "Adra (ESADR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.46366700000001",
    lng: "-3.74922",
    country: "ES",
    country_long: "Spain",
    portCode: "ESAER",
    portName: "Ares (ESAER), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.816162",
    lng: "-2.5718122",
    country: "ES",
    country_long: "Spain",
    portCode: "ESAGE",
    portName: "Aguadulce (ESAGE), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.6771276",
    lng: "-4.4915685",
    country: "ES",
    country_long: "Spain",
    portCode: "ESAGP",
    portName: "Malaga (ESAGP), Malaga, Spain",
    portContinent: "Europe",
  },
  {
    lat: "42.9804724",
    lng: "-2.6425841",
    country: "ES",
    country_long: "Spain",
    portCode: "ESALA",
    portName: "Villarreal de Alava (ESALA), Valencia, Spain",
    portContinent: "Europe",
  },
  {
    lat: "38.9942576",
    lng: "-1.860173",
    country: "ES",
    country_long: "Spain",
    portCode: "ESALB",
    portName: "Albacete",
    portContinent: "Europe",
  },
  {
    lat: "38.33532905037368",
    lng: "-0.4876976536557515",
    country: "ES",
    country_long: "Spain",
    portCode: "ESALC",
    portName: "Port of Alicante",
    portContinent: "Europe",
  },
  {
    lat: "39.8429965",
    lng: "3.1329618",
    country: "ES",
    country_long: "Spain",
    portCode: "ESALD",
    portName: "Port of Alcudia",
    portContinent: "Europe",
  },
  {
    lat: "36.1407591",
    lng: "-5.456233",
    country: "ES",
    country_long: "Spain",
    portCode: "ESALG",
    portName: "Algeciras",
    portContinent: "Europe",
  },
  {
    lat: "28.3385856",
    lng: "-16.4214477",
    country: "ES",
    country_long: "Spain",
    portCode: "ESARF",
    portName: "Arafo",
    portContinent: "Europe",
  },
  {
    lat: "27.7619672",
    lng: "-15.6833181",
    country: "ES",
    country_long: "Spain",
    portCode: "ESARI",
    portName: "Arguineguin (ESARI), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.5797073",
    lng: "2.5508683",
    country: "ES",
    country_long: "Spain",
    portCode: "ESARN",
    portName: "Arenys De Mar (ESARN), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.3983734",
    lng: "-3.822281",
    country: "ES",
    country_long: "Spain",
    portCode: "ESATO",
    portName: "El Astillero (ESATO), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ESAVI",
    portName: "Ãvila",
    portContinent: "Europe",
  },
  {
    lat: "43.5579523",
    lng: "-5.9246653",
    country: "ES",
    country_long: "Spain",
    portCode: "ESAVL",
    portName: "Aviles (ESAVL), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.2353175",
    lng: "-2.8912652",
    country: "ES",
    country_long: "Spain",
    portCode: "ESBAS",
    portName: "Basauri Vizcaya",
    portContinent: "Europe",
  },
  {
    lat: "41.3461764518267",
    lng: "2.168364487226034",
    country: "ES",
    country_long: "Spain",
    portCode: "ESBCN",
    portName: "Port of Barcelona",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ESBIO",
    portName: "Port of Bilbao",
    portContinent: "Europe",
  },
  {
    lat: "38.8794495",
    lng: "-6.9706535",
    country: "ES",
    country_long: "Spain",
    portCode: "ESBJZ",
    portName: "Badajoz",
    portContinent: "Europe",
  },
  {
    lat: "39.35877591790004",
    lng: "2.735632782011336",
    country: "ES",
    country_long: "Spain",
    portCode: "ESBLR",
    portName: "Balearic Islands",
    portContinent: "Europe",
  },
  {
    lat: "40.4126493",
    lng: "0.4243013",
    country: "ES",
    country_long: "Spain",
    portCode: "ESBNI",
    portName: "Benicarlo (ESBNI), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.6602954",
    lng: "-7.3611092",
    country: "ES",
    country_long: "Spain",
    portCode: "ESBRL",
    portName: "Burela (ESBRL), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.4188248",
    lng: "-2.7269756",
    country: "ES",
    country_long: "Spain",
    portCode: "ESBRM",
    portName: "Bermeo (ESBRM), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.8895925",
    lng: "-0.0848302",
    country: "ES",
    country_long: "Spain",
    portCode: "ESBRX",
    portName: "Port of Burriana",
    portContinent: "Europe",
  },
  {
    lat: "42.3253264",
    lng: "-8.7864436",
    country: "ES",
    country_long: "Spain",
    portCode: "ESBUE",
    portName: "Bueu (ESBUE), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.3504659",
    lng: "-3.689354499999999",
    country: "ES",
    country_long: "Spain",
    portCode: "ESBUR",
    portName: "Burgos",
    portContinent: "Europe",
  },
  {
    lat: "36.5210142",
    lng: "-6.2804565",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCAD",
    portName: "Cadiz (ESCAD), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "10.3932277",
    lng: "-75.4832311",
    country: "CO",
    country_long: "Colombia",
    portCode: "ESCAR",
    portName: "Cartagena",
    portContinent: "Europe",
  },
  {
    lat: "39.9756899",
    lng: "0.0166351",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCAS",
    portName: "Port of Castellon",
    portContinent: "Europe",
  },
  {
    lat: "41.0666754",
    lng: "1.0603606",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCBL",
    portName: "Cambrils (ESCBL), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.9425685",
    lng: "-9.192255500000002",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCCN",
    portName: "Corcubion (ESCCN), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.4752765",
    lng: "-6.3724247",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCCS",
    portName: "Caceres",
    portContinent: "Europe",
  },
  {
    lat: "42.0891611",
    lng: "2.8160067",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCDT",
    portName: "CORNELLA DEL TERRI",
    portContinent: "Europe",
  },
  {
    lat: "43.6607861",
    lng: "-8.0537698",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCED",
    portName: "Cedeira (ESCED), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "28.3556768",
    lng: "-16.3717183",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCEI",
    portName: "Candelaria (ESCEI), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.7100398",
    lng: "3.4566911",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCEM",
    portName: "Cala Ratjada (ESCEM), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.8893874",
    lng: "-5.3213455",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCEU",
    portName: "Ceuta (ESCEU), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.7348614",
    lng: "-6.431699000000001",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCHP",
    portName: "Chipiona (ESCHP), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "29.2314646",
    lng: "-13.5034144",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCHR",
    portName: "Caleta Del Sebo (ESCHR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.6798633",
    lng: "-7.5883459",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCIO",
    portName: "Celeiro (ESCIO), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.9848295",
    lng: "-3.927377799999999",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCIR",
    portName: "Ciudad Real",
    portContinent: "Europe",
  },
  {
    lat: "39.9991163",
    lng: "3.8389047",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCMC",
    portName: "Ciutadella (ESCMC), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.2650806",
    lng: "-8.9630076",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCME",
    portName: "Corme (ESCME), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.3956654",
    lng: "-3.4523589",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCOD",
    portName: "Colindres (ESCOD), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.961384",
    lng: "0.1585692",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCPT",
    portName: "CASTEJON DEL  PUENTE",
    portContinent: "Europe",
  },
  {
    lat: "36.9991171",
    lng: "-1.8921411",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCRS",
    portName: "Carboneras (ESCRS), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.5247676",
    lng: "-7.0277708",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCSO",
    portName: "Castropol (ESCSO), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.887247",
    lng: "-5.6812969",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCST",
    portName: "Casatejeda",
    portContinent: "Europe",
  },
  {
    lat: "-2.9001285",
    lng: "-79.00589649999999",
    country: "EC",
    country_long: "Ecuador",
    portCode: "ESCUE",
    portName: "Cuenca",
    portContinent: "Europe",
  },
  {
    lat: "37.2882628",
    lng: "-6.0533598",
    country: "ES",
    country_long: "Spain",
    portCode: "ESCZR",
    portName: "Coria Del Rio (ESCZR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.53020799999999",
    lng: "-9.016451",
    country: "ES",
    country_long: "Spain",
    portCode: "ESDAA",
    portName: "Carreira Aguino (ESDAA), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.408017",
    lng: "-2.7002508",
    country: "ES",
    country_long: "Spain",
    portCode: "ESDAC",
    portName: "Mundaca (ESDAC), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.834725",
    lng: "0.1200434",
    country: "ES",
    country_long: "Spain",
    portCode: "ESDNA",
    portName: "Port of Denia",
    portContinent: "Europe",
  },
  {
    lat: "42.283333",
    lng: "-8.716667",
    country: "ES",
    country_long: "Spain",
    portCode: "ESDOY",
    portName: "Domayo (ESDOY), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.264185",
    lng: "-8.781991999999999",
    country: "ES",
    country_long: "Spain",
    portCode: "ESDRB",
    portName: "Darbo (ESDRB), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.85755959999999",
    lng: "0.1307797",
    country: "ES",
    country_long: "Spain",
    portCode: "ESDRR",
    portName: "Valderobres (ESDRR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.318334",
    lng: "-1.9812313",
    country: "ES",
    country_long: "Spain",
    portCode: "ESEAS",
    portName: "San Sebastian",
    portContinent: "Europe",
  },
  {
    lat: "36.7731148",
    lng: "-2.8103551",
    country: "ES",
    country_long: "Spain",
    portCode: "ESEEJ",
    portName: "El Ejido",
    portContinent: "Europe",
  },
  {
    lat: "40.21568000000001",
    lng: "-6.8451504",
    country: "ES",
    country_long: "Spain",
    portCode: "ESEJS",
    portName: "Eljas",
    portContinent: "Europe",
  },
  {
    lat: "-2.2602831",
    lng: "-79.8783334",
    country: "EC",
    country_long: "Ecuador",
    portCode: "ESELP",
    portName: "EL PEDREGAL",
    portContinent: "Europe",
  },
  {
    lat: "42.245287",
    lng: "3.1264427",
    country: "ES",
    country_long: "Spain",
    portCode: "ESEMP",
    portName: "Empuriabrava (ESEMP), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.1631959",
    lng: "-0.2537897",
    country: "ES",
    country_long: "Spain",
    portCode: "ESERA",
    portName: "Cullera (ESERA), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "27.9593844",
    lng: "-15.3987545",
    country: "ES",
    country_long: "Spain",
    portCode: "ESERU",
    portName: "El Goro (ESERU), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.8453805",
    lng: "0.0104926",
    country: "ES",
    country_long: "Spain",
    portCode: "ESEVL",
    portName: "El Verger",
    portContinent: "Europe",
  },
  {
    lat: "43.475298",
    lng: "-8.1662448",
    country: "ES",
    country_long: "Spain",
    portCode: "ESFEE",
    portName: "Fene (ESFEE), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.6964006",
    lng: "1.4531355",
    country: "ES",
    country_long: "Spain",
    portCode: "ESFLD",
    portName: "Formentera (ESFLD), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.5363435",
    lng: "-0.3524784",
    country: "ES",
    country_long: "Spain",
    portCode: "ESFOS",
    portName: "Foios (ESFOS), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.48964600000001",
    lng: "-8.2193451",
    country: "ES",
    country_long: "Spain",
    portCode: "ESFRO",
    portName: "FERROL (ESFRO), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "28.500821",
    lng: "-13.8628367",
    country: "ES",
    country_long: "Spain",
    portCode: "ESFUE",
    portName: "Puerto Del Rosario (ESFUE), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ESGAN",
    portName: "Port of Gandia",
    portContinent: "Europe",
  },
  {
    lat: "37.1802068",
    lng: "-1.8233007",
    country: "ES",
    country_long: "Spain",
    portCode: "ESGAR",
    portName: "Garrucha (ESGAR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.3077339",
    lng: "-2.2021494",
    country: "ES",
    country_long: "Spain",
    portCode: "ESGET",
    portName: "Guetaria (ESGET), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.9712748",
    lng: "-3.8082965",
    country: "ES",
    country_long: "Spain",
    portCode: "ESGIB",
    portName: "MENGIBAR",
    portContinent: "Europe",
  },
  {
    lat: "43.5587113",
    lng: "-5.6994807",
    country: "ES",
    country_long: "Spain",
    portCode: "ESGIJ",
    portName: "Port of Gijon",
    portContinent: "Europe",
  },
  {
    lat: "42.10942660000001",
    lng: "3.1428341",
    country: "ES",
    country_long: "Spain",
    portCode: "ESGLE",
    portName: "L'escala (ESGLE), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.46366700000001",
    lng: "-3.74922",
    country: "ES",
    country_long: "Spain",
    portCode: "ESGND",
    portName: "Puerto De Granadilla (ESGND), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.9794005",
    lng: "2.8214264",
    country: "ES",
    country_long: "Spain",
    portCode: "ESGRO",
    portName: "Gerona",
    portContinent: "Europe",
  },
  {
    lat: "37.1774605",
    lng: "-3.5984368",
    country: "ES",
    country_long: "Spain",
    portCode: "ESGRX",
    portName: "Granada",
    portContinent: "Europe",
  },
  {
    lat: "28.2148",
    lng: "-14.0202948",
    country: "ES",
    country_long: "Spain",
    portCode: "ESGTL",
    portName: "Gran Tarajal (ESGTL), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "20.6596988",
    lng: "-103.3496092",
    country: "MX",
    country_long: "Mexico",
    portCode: "ESGUA",
    portName: "GUADALAJARA",
    portContinent: "Europe",
  },
  {
    lat: "27.7866414",
    lng: "-17.9018932",
    country: "ES",
    country_long: "Spain",
    portCode: "ESHIE",
    portName: "Puerto De La Estaca (ESHIE), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.131845",
    lng: "-0.4078058",
    country: "ES",
    country_long: "Spain",
    portCode: "ESHUC",
    portName: "Huesca",
    portContinent: "Europe",
  },
  {
    lat: "37.2619585",
    lng: "-6.9427327",
    country: "ES",
    country_long: "Spain",
    portCode: "ESHUV",
    portName: "Huelva (ESHUV), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.5617975",
    lng: "-8.8681527",
    country: "ES",
    country_long: "Spain",
    portCode: "ESIAL",
    portName: "Isla De Arosa (ESIAL), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.1305138",
    lng: "-9.1828065",
    country: "ES",
    country_long: "Spain",
    portCode: "ESIAS",
    portName: "Camarinas (ESIAS), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.9067339",
    lng: "1.4205983",
    country: "ES",
    country_long: "Spain",
    portCode: "ESIBZ",
    portName: "Port of Eivissa/Ibiza",
    portContinent: "Europe",
  },
  {
    lat: "43.3380969",
    lng: "-1.7888483",
    country: "ES",
    country_long: "Spain",
    portCode: "ESIRU",
    portName: "IRUN",
    portContinent: "Europe",
  },
  {
    lat: "37.7795941",
    lng: "-3.784905699999999",
    country: "ES",
    country_long: "Spain",
    portCode: "ESJAE",
    portName: "Jaen",
    portContinent: "Europe",
  },
  {
    lat: "38.7890109",
    lng: "0.1660813",
    country: "ES",
    country_long: "Spain",
    portCode: "ESJAV",
    portName: "Javea (ESJAV), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.8839435",
    lng: "0.7996491",
    country: "ES",
    country_long: "Spain",
    portCode: "ESKLL",
    portName: "L'ametlla De Mar (ESKLL), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "27.6411981",
    lng: "-17.982073",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLAF",
    portName: "La Restinga (ESLAF), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.3081128",
    lng: "-5.6960562",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLAG",
    portName: "LANGREO",
    portContinent: "Europe",
  },
  {
    lat: "43.2212167",
    lng: "-9.0063506",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLAX",
    portName: "Lage (ESLAX), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.3623436",
    lng: "-8.4115401",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLCG",
    portName: "Coruna (ESLCG), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.879688",
    lng: "4.2891148",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLCL",
    portName: "Villacarlos (ESLCL), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "28.0489062",
    lng: "-16.7115979",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLCR",
    portName: "Los Cristianos (ESLCR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.4096287",
    lng: "-3.428383",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLDO",
    portName: "Laredo (ESLDO), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.834047",
    lng: "-2.4637136",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLEI",
    portName: "Almeria (ESLEI), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ESLEN",
    portName: "Leon",
    portContinent: "Europe",
  },
  {
    lat: "43.3223603",
    lng: "-1.8998001",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLEO",
    portName: "Lezo (ESLEO), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.0097384",
    lng: "-7.5567582",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLGO",
    portName: "LUGO",
    portContinent: "Europe",
  },
  {
    lat: "42.4627195",
    lng: "-2.4449852",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLGR",
    portName: "Logrono",
    portContinent: "Europe",
  },
  {
    lat: "43.3623436",
    lng: "-8.4115401",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLKN",
    portName: "la coruna",
    portContinent: "Europe",
  },
  {
    lat: "39.5811405",
    lng: "-3.8693481",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLOY",
    portName: "Los Yebenes",
    portContinent: "Europe",
  },
  {
    lat: "28.137979428866",
    lng: "-15.41559453802645",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLPA",
    portName: "Port of Las Palmas",
    portContinent: "Europe",
  },
  {
    lat: "28.1008837",
    lng: "-15.4653897",
    country: "ES",
    country_long: "Spain",
    portCode: "ESLPG",
    portName: "Las Palmas",
    portContinent: "Europe",
  },
  {
    lat: "40.4167754",
    lng: "-3.7037902",
    country: "ES",
    country_long: "Spain",
    portCode: "ESMAD",
    portName: "MADRID",
    portContinent: "Europe",
  },
  {
    lat: "39.9496287",
    lng: "4.1104449",
    country: "ES",
    country_long: "Spain",
    portCode: "ESMAH",
    portName: "Menorca",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ESMAR",
    portName: "Marin",
    portContinent: "Europe",
  },
  {
    lat: "39.9496287",
    lng: "4.1104449",
    country: "ES",
    country_long: "Spain",
    portCode: "ESMEN",
    portName: "MENORCA",
    portContinent: "Europe",
  },
  {
    lat: "43.1041888",
    lng: "-9.218291299999999",
    country: "ES",
    country_long: "Spain",
    portCode: "ESMGA",
    portName: "Mugia (ESMGA), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.45994659999999",
    lng: "-8.255815499999999",
    country: "ES",
    country_long: "Spain",
    portCode: "ESMGR",
    portName: "Mugardos (ESMGR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "28.0511096",
    lng: "-14.351552",
    country: "ES",
    country_long: "Spain",
    portCode: "ESMHR",
    portName: "Morro Del Jable (ESMHR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.9922399",
    lng: "-1.1306544",
    country: "ES",
    country_long: "Spain",
    portCode: "ESMJV",
    portName: "Murcia",
    portContinent: "Europe",
  },
  {
    lat: "35.2922775",
    lng: "-2.9380973",
    country: "ES",
    country_long: "Spain",
    portCode: "ESMLN",
    portName: "Melilla",
    portContinent: "Europe",
  },
  {
    lat: "36.7489747",
    lng: "-3.5180363",
    country: "ES",
    country_long: "Spain",
    portCode: "ESMOT",
    portName: "Motril (ESMOT), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.3914848",
    lng: "-8.7020824",
    country: "ES",
    country_long: "Spain",
    portCode: "ESMPG",
    portName: "Marin, Pontevedra",
    portContinent: "Europe",
  },
  {
    lat: "40.0703045",
    lng: "-6.6576219",
    country: "ES",
    country_long: "Spain",
    portCode: "ESMRL",
    portName: "Moraleja",
    portContinent: "Europe",
  },
  {
    lat: "41.5381124",
    lng: "2.4447406",
    country: "ES",
    country_long: "Spain",
    portCode: "ESMTO",
    portName: "Mataro (ESMTO), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.3534739",
    lng: "-2.6817726",
    country: "ES",
    country_long: "Spain",
    portCode: "ESMUT",
    portName: "Murueta (ESMUT), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.53975210000001",
    lng: "-6.724565200000001",
    country: "ES",
    country_long: "Spain",
    portCode: "ESNAV",
    portName: "Navia (ESNAV), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.8881751",
    lng: "-4.7793835",
    country: "ES",
    country_long: "Spain",
    portCode: "ESODB",
    portName: "Cordoba",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ESONA",
    portName: "Onda",
    portContinent: "Europe",
  },
  {
    lat: "43.32302199999999",
    lng: "-2.422308",
    country: "ES",
    country_long: "Spain",
    portCode: "ESOND",
    portName: "Ondarroa (ESOND), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.2887571",
    lng: "3.2779723",
    country: "ES",
    country_long: "Spain",
    portCode: "ESOOP",
    portName: "Cadaques (ESOOP), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.0914103",
    lng: "0.1424781",
    country: "ES",
    country_long: "Spain",
    portCode: "ESOPA",
    portName: "Oropesa Del Mar (ESOPA), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.33578929999999",
    lng: "-7.863880999999998",
    country: "ES",
    country_long: "Spain",
    portCode: "ESORE",
    portName: "ORENSE",
    portContinent: "Europe",
  },
  {
    lat: "43.279531",
    lng: "-2.1290671",
    country: "ES",
    country_long: "Spain",
    portCode: "ESORI",
    portName: "Orio (ESORI), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.3622522",
    lng: "-5.8485461",
    country: "ES",
    country_long: "Spain",
    portCode: "ESOVO",
    portName: "Oviedo",
    portContinent: "Europe",
  },
  {
    lat: "29.2226873",
    lng: "-13.4533444",
    country: "ES",
    country_long: "Spain",
    portCode: "ESOZL",
    portName: "Orzola (ESOZL), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.0096857",
    lng: "-4.5288016",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPAC",
    portName: "Palencia",
    portContinent: "Europe",
  },
  {
    lat: "28.3508903",
    lng: "-14.1081189",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPAF",
    portName: "Pajara Fuerteventura",
    portContinent: "Europe",
  },
  {
    lat: "41.8468787",
    lng: "3.133152",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPAL",
    portName: "Puerto de Palamos",
    portContinent: "Europe",
  },
  {
    lat: "43.32547030000001",
    lng: "-1.9301396",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPAS",
    portName: "Pasajes (ESPAS), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.4298846",
    lng: "-8.6446202",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPEV",
    portName: "PONTEVEDRA",
    portContinent: "Europe",
  },
  {
    lat: "39.55753079534847",
    lng: "2.631956136042742",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPMI",
    portName: "Port of Mallorca",
    portContinent: "Europe",
  },
  {
    lat: "36.17555249999999",
    lng: "-5.4355255",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPMN",
    portName: "Palmones (ESPMN), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.812526",
    lng: "-1.6457745",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPNA",
    portName: "Pamplona",
    portContinent: "Europe",
  },
  {
    lat: "39.5348091",
    lng: "2.5712658",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPNX",
    portName: "Portals Nous (ESPNX), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.3777838",
    lng: "2.1597598",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPNY",
    portName: "Sant Antoni (ESPNY), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "28.0271769",
    lng: "-17.2006914",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPPS",
    portName: "Playa De Santiago (ESPPS), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "28.9192047",
    lng: "-13.7071972",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPRE",
    portName: "Puerto Calero (ESPRE), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "28.1816173",
    lng: "-16.8170755",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPSJ",
    portName: "Playa San Juan (ESPSJ), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.2225427",
    lng: "-3.6075172",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPUL",
    portName: "Pulianas",
    portContinent: "Europe",
  },
  {
    lat: "39.5037093",
    lng: "-0.4431618",
    country: "ES",
    country_long: "Spain",
    portCode: "ESPXA",
    portName: "Paterna",
    portContinent: "Europe",
  },
  {
    lat: "28.2361956",
    lng: "-16.8411272",
    country: "ES",
    country_long: "Spain",
    portCode: "ESQAE",
    portName: "Puerto De Santiago (ESQAE), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.3387346",
    lng: "3.2049594",
    country: "ES",
    country_long: "Spain",
    portCode: "ESQAJ",
    portName: "El Port De La Selva (ESQAJ), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "28.7281552",
    lng: "-13.8633524",
    country: "ES",
    country_long: "Spain",
    portCode: "ESQFU",
    portName: "Corralejo (ESQFU), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.919345",
    lng: "-1.969194",
    country: "ES",
    country_long: "Spain",
    portCode: "ESQKU",
    portName: "Uharte akril",
    portContinent: "Europe",
  },
  {
    lat: "28.8669999",
    lng: "-13.8390162",
    country: "ES",
    country_long: "Spain",
    portCode: "ESQLY",
    portName: "Playa Blanca (ESQLY), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.549778",
    lng: "-8.999934999999999",
    country: "ES",
    country_long: "Spain",
    portCode: "ESRBI",
    portName: "Santa Uxia Ribeira (ESRBI), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.3534526",
    lng: "-3.9500105",
    country: "ES",
    country_long: "Spain",
    portCode: "ESRDP",
    portName: "RENEDO DE  PIELAGOS",
    portContinent: "Europe",
  },
  {
    lat: "27.8584838",
    lng: "-15.3943618",
    country: "ES",
    country_long: "Spain",
    portCode: "ESRGA",
    portName: "Arinaga (ESRGA), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.1870487",
    lng: "1.4604155",
    country: "ES",
    country_long: "Spain",
    portCode: "ESROD",
    portName: "Roda De Bara (ESROD), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.2206942",
    lng: "-7.123824099999999",
    country: "ES",
    country_long: "Spain",
    portCode: "ESROM",
    portName: "El Rompido (ESROM), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ESROS",
    portName: "Port of Rosas",
    portContinent: "Europe",
  },
  {
    lat: "36.6236874",
    lng: "-6.3601441",
    country: "ES",
    country_long: "Spain",
    portCode: "ESROT",
    portName: "Rota (ESROT), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.7636819",
    lng: "-2.6154266",
    country: "ES",
    country_long: "Spain",
    portCode: "ESRQM",
    portName: "Roquetas De Mar (ESRQM), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.3081107",
    lng: "-1.8912823",
    country: "ES",
    country_long: "Spain",
    portCode: "ESRTA",
    portName: "Renteria (ESRTA), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.6513206",
    lng: "-8.8166051",
    country: "ES",
    country_long: "Spain",
    portCode: "ESRXO",
    portName: "Rianxo (ESRXO), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.42668",
    lng: "2.2245813",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSAB",
    portName: "San Adrian De Besos (ESSAB), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.6644972",
    lng: "-0.2268113",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSAG",
    portName: "Port of Sagunto",
    portContinent: "Europe",
  },
  {
    lat: "43.69383089999999",
    lng: "-7.4415158",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSCI",
    portName: "San Ciprian (ESSCI), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.3415647",
    lng: "-3.688176",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSCK",
    portName: "San Cristobal (ESSCK), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.8768606",
    lng: "-8.5441729",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSCQ",
    portName: "Santiago De Compostela",
    portContinent: "Europe",
  },
  {
    lat: "39.0329811",
    lng: "1.5641257",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSCR",
    portName: "San Carlos (ESSCR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "28.47756541472145",
    lng: "-16.2257401370765",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSCT",
    portName: "Puertos de Tenerife",
    portContinent: "Europe",
  },
  {
    lat: "40.46366700000001",
    lng: "-3.74922",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSDR",
    portName: "Santander (ESSDR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.9429032",
    lng: "-4.108806899999999",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSEG",
    portName: "Segovia",
    portContinent: "Europe",
  },
  {
    lat: "36.4718646",
    lng: "-6.196594800000001",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSFO",
    portName: "San Fernando (ESSFO), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.7850237",
    lng: "3.031659",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSFU",
    portName: "Port of San Feliu de Guixols",
    portContinent: "Europe",
  },
  {
    lat: "27.7725105",
    lng: "-15.5415779",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSGT",
    portName: "San Agustin (ESSGT), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.2371851",
    lng: "1.805886",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSIT",
    portName: "Sitges (ESSIT), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.9217535",
    lng: "1.2934954",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSJT",
    portName: "Sant Josep De Sa Tal (ESSJT), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.7725774",
    lng: "-6.3529689",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSLB",
    portName: "Sanlucar De La Barra (ESSLB), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.9701039",
    lng: "-5.663539699999999",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSLM",
    portName: "SALAMANCA",
    portContinent: "Europe",
  },
  {
    lat: "41.0777465",
    lng: "1.1315926",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSLO",
    portName: "Salou (ESSLO), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.7665972",
    lng: "-2.4790306",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSOR",
    portName: "SORIA",
    portContinent: "Europe",
  },
  {
    lat: "28.7133828",
    lng: "-17.9057813",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSPC",
    portName: "La Palma (ESSPC), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.5029445",
    lng: "2.4715422",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSPZ",
    portName: "Santa Ponsa (ESSPZ), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "28.1033035",
    lng: "-17.2193578",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSSG",
    portName: "La Gomera",
    portContinent: "Europe",
  },
  {
    lat: "43.383461",
    lng: "-4.4010599",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSVB",
    portName: "San Vicente Barquera (ESSVB), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.3608161",
    lng: "-5.9939136",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSVQ",
    portName: "Port of Sevilla",
    portContinent: "Europe",
  },
  {
    lat: "42.4033003",
    lng: "-8.811359099999999",
    country: "ES",
    country_long: "Spain",
    portCode: "ESSXX",
    portName: "Sanxenxo (ESSXX), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "28.6473441",
    lng: "-17.943184",
    country: "ES",
    country_long: "Spain",
    portCode: "ESTAL",
    portName: "Tarajal (ESTAL), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "27.9891819",
    lng: "-15.3753284",
    country: "ES",
    country_long: "Spain",
    portCode: "ESTAN",
    portName: "Taliarte (ESTAN), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.09592545817824",
    lng: "1.221755812701811",
    country: "ES",
    country_long: "Spain",
    portCode: "ESTAR",
    portName: "Port of Tarragona",
    portContinent: "Europe",
  },
  {
    lat: "28.2915637",
    lng: "-16.6291304",
    country: "ES",
    country_long: "Spain",
    portCode: "ESTCI",
    portName: "Tenerife",
    portContinent: "Europe",
  },
  {
    lat: "40.3456879",
    lng: "-1.1064345",
    country: "ES",
    country_long: "Spain",
    portCode: "ESTER",
    portName: "Teruel",
    portContinent: "Europe",
  },
  {
    lat: "41.6528052",
    lng: "-83.5378674",
    country: "US",
    country_long: "United States",
    portCode: "ESTOL",
    portName: "TOLEDO",
    portContinent: "Europe",
  },
  {
    lat: "37.9847003",
    lng: "-0.6808233",
    country: "ES",
    country_long: "Spain",
    portCode: "ESTOR",
    portName: "Torrevieja (ESTOR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.0143209",
    lng: "-5.6044497",
    country: "ES",
    country_long: "Spain",
    portCode: "ESTRF",
    portName: "Tarifa (ESTRF), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.14409670000001",
    lng: "1.4009454",
    country: "ES",
    country_long: "Spain",
    portCode: "ESTRR",
    portName: "Torredembarra (ESTRR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.0613816",
    lng: "-1.6063169",
    country: "ES",
    country_long: "Spain",
    portCode: "ESTUD",
    portName: "Tudela",
    portContinent: "Europe",
  },
  {
    lat: "41.4153649",
    lng: "2.1381399",
    country: "ES",
    country_long: "Spain",
    portCode: "ESVCC",
    portName: "Vallcarca (ESVCC), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ESVDH",
    portName: "HIERRO",
    portContinent: "Europe",
  },
  {
    lat: "42.2311226357596",
    lng: "-8.74261091053216",
    country: "ES",
    country_long: "Spain",
    portCode: "ESVGO",
    portName: "Port of Vigo",
    portContinent: "Europe",
  },
  {
    lat: "42.5986025",
    lng: "-8.7371039",
    country: "ES",
    country_long: "Spain",
    portCode: "ESVIL",
    portName: "Villagarcia (ESVIL), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.8531869",
    lng: "-2.6732421",
    country: "ES",
    country_long: "Spain",
    portCode: "ESVIT",
    portName: "VITORIA",
    portContinent: "Europe",
  },
  {
    lat: "43.6642649",
    lng: "-7.594534800000001",
    country: "ES",
    country_long: "Spain",
    portCode: "ESVIV",
    portName: "Viveiro (ESVIV), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.4480851",
    lng: "-0.3169267",
    country: "ES",
    country_long: "Spain",
    portCode: "ESVLC",
    portName: "Port of Valencia",
    portContinent: "Europe",
  },
  {
    lat: "41.2151504",
    lng: "1.727446",
    country: "ES",
    country_long: "Spain",
    portCode: "ESVLG",
    portName: "Port of Vilanova i la Geltru",
    portContinent: "Europe",
  },
  {
    lat: "20.68964",
    lng: "-88.20224879999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "ESVLL",
    portName: "Valladolid",
    portContinent: "Europe",
  },
  {
    lat: "40.46366700000001",
    lng: "-3.74922",
    country: "ES",
    country_long: "Spain",
    portCode: "ESVMG",
    portName: "Velez (ESVMG), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.6488226",
    lng: "-0.8890853",
    country: "ES",
    country_long: "Spain",
    portCode: "ESZAZ",
    portName: "ZARAGOZA (ESZAZ), Zaragoza, Spain",
    portContinent: "Europe",
  },
  {
    lat: "28.0072317",
    lng: "-16.6539744",
    country: "ES",
    country_long: "Spain",
    portCode: "ESZBB",
    portName: "Las Galletas (ESZBB), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "8.9493285",
    lng: "-79.5455917",
    country: "PA",
    country_long: "Panama",
    portCode: "ESZDT",
    portName: "El Chorrillo (ESZDT), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.2005266",
    lng: "-7.323303500000001",
    country: "ES",
    country_long: "Spain",
    portCode: "ESZGA",
    portName: "Isla Cristina (ESZGA), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.1200897",
    lng: "-8.852356200000001",
    country: "ES",
    country_long: "Spain",
    portCode: "ESZHR",
    portName: "Baiona (ESZHR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "27.8234371",
    lng: "-15.7607916",
    country: "ES",
    country_long: "Spain",
    portCode: "ESZIH",
    portName: "Puerto De Mogan (ESZIH), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.6031661",
    lng: "-8.938689900000002",
    country: "ES",
    country_long: "Spain",
    portCode: "ESZJF",
    portName: "Pobra Do Caraminal (ESZJF), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.3938575",
    lng: "-8.8288728",
    country: "ES",
    country_long: "Spain",
    portCode: "ESZJN",
    portName: "Portonovo (ESZJN), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.4946642",
    lng: "2.3591531",
    country: "ES",
    country_long: "Spain",
    portCode: "ESZJP",
    portName: "Premia De Mar (ESZJP), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.46366700000001",
    lng: "-3.74922",
    country: "ES",
    country_long: "Spain",
    portCode: "ESZJR",
    portName: "Pasai San Pedro (ESZJR), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.2298069",
    lng: "-1.8564386",
    country: "ES",
    country_long: "Spain",
    portCode: "ESZKO",
    portName: "Rincon de Soto",
    portContinent: "Europe",
  },
  {
    lat: "42.2605204",
    lng: "3.2080991",
    country: "ES",
    country_long: "Spain",
    portCode: "ESZKQ",
    portName: "Roses (ESZKQ), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.5034712",
    lng: "-5.7467879",
    country: "ES",
    country_long: "Spain",
    portCode: "ESZMR",
    portName: "Zamora",
    portContinent: "Europe",
  },
  {
    lat: "40.989823",
    lng: "-3.6383114",
    country: "ES",
    country_long: "Spain",
    portCode: "ESZOY",
    portName: "Buitrago Del Lozoya (ESZOY), Spain, Med",
    portContinent: "Europe",
  },
  {
    lat: "8.9806034",
    lng: "38.7577605",
    country: "ET",
    country_long: "Ethiopia",
    portCode: "ETADD",
    portName: "Addis Ababa",
    portContinent: "Africa",
  },
  {
    lat: "7.050374199999999",
    lng: "38.4955043",
    country: "ET",
    country_long: "Ethiopia",
    portCode: "ETAWA",
    portName: "Awassa",
    portContinent: "Africa",
  },
  {
    lat: "9.6008747",
    lng: "41.850142",
    country: "ET",
    country_long: "Ethiopia",
    portCode: "ETDIR",
    portName: "Dire Dawa",
    portContinent: "Africa",
  },
  {
    lat: "12.6030181",
    lng: "37.4521319",
    country: "ET",
    country_long: "Ethiopia",
    portCode: "ETGDQ",
    portName: "Gondar",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ETGIB",
    portName: "Gibe",
    portContinent: "Africa",
  },
  {
    lat: "11.0849336",
    lng: "39.7291837",
    country: "ET",
    country_long: "Ethiopia",
    portCode: "ETKOA",
    portName: "Kombolcha",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ETMDP",
    portName: "MODJO",
    portContinent: "Africa",
  },
  {
    lat: "13.4935504",
    lng: "39.465738",
    country: "ET",
    country_long: "Ethiopia",
    portCode: "ETMEK",
    portName: "Mekele",
    portContinent: "Africa",
  },
  {
    lat: "7.197296799999999",
    lng: "38.6005328",
    country: "ET",
    country_long: "Ethiopia",
    portCode: "ETS7O",
    portName: "Shashemene",
    portContinent: "Africa",
  },
  {
    lat: "11.7380766",
    lng: "38.4692211",
    country: "ET",
    country_long: "Ethiopia",
    portCode: "ETW8N",
    portName: "Nefas mewcha",
    portContinent: "Africa",
  },
  {
    lat: "65.67588649999999",
    lng: "24.5807973",
    country: "FI",
    country_long: "Finland",
    portCode: "FIAJO",
    portName: "Ajos (FIAJO), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.2054911",
    lng: "24.6559001",
    country: "FI",
    country_long: "Finland",
    portCode: "FIESP",
    portName: "ESPOO",
    portContinent: "Europe",
  },
  {
    lat: "60.81463189999999",
    lng: "23.6339467",
    country: "FI",
    country_long: "Finland",
    portCode: "FIFSP",
    portName: "FORSSA",
    portContinent: "Europe",
  },
  {
    lat: "60.1617883",
    lng: "24.957132",
    country: "FI",
    country_long: "Finland",
    portCode: "FIHEL",
    portName: "Port of Helsinki",
    portContinent: "Europe",
  },
  {
    lat: "61.92410999999999",
    lng: "25.748151",
    country: "FI",
    country_long: "Finland",
    portCode: "FIHKO",
    portName: "Hanko (FIHKO), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.9928787",
    lng: "24.4590243",
    country: "FI",
    country_long: "Finland",
    portCode: "FIHMA",
    portName: "Haemeenlinna  (FIHMA), Hameenlinna, Finland",
    portContinent: "Europe",
  },
  {
    lat: "59.22053689999999",
    lng: "10.9347012",
    country: "NO",
    country_long: "Norway",
    portCode: "FIHMN",
    portName: "Fredrikshamn (Hamina) (FIHMN), Fredrikstad, Norway",
    portContinent: "Europe",
  },
  {
    lat: "60.62995249999999",
    lng: "24.8580797",
    country: "FI",
    country_long: "Finland",
    portCode: "FIHYV",
    portName: "HYVINKAA",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "FIINK",
    portName: "Port of Inkoo",
    portContinent: "Europe",
  },
  {
    lat: "62.6010155",
    lng: "29.7635719",
    country: "FI",
    country_long: "Finland",
    portCode: "FIJOE",
    portName: "Joensuu (FIJOE), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.2426034",
    lng: "25.7472567",
    country: "FI",
    country_long: "Finland",
    portCode: "FIJYV",
    portName: "JYVASKYLA",
    portContinent: "Europe",
  },
  {
    lat: "64.2221775",
    lng: "27.7278497",
    country: "FI",
    country_long: "Finland",
    portCode: "FIKAJ",
    portName: "KAJAANI",
    portContinent: "Europe",
  },
  {
    lat: "61.92410999999999",
    lng: "25.748151",
    country: "FI",
    country_long: "Finland",
    portCode: "FIKAL",
    portName: "Kalkkiranta (FIKAL), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.3822834",
    lng: "21.2277852",
    country: "FI",
    country_long: "Finland",
    portCode: "FIKAS",
    portName: "Kaskinen (FIKAS), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.8325059",
    lng: "24.9274414",
    country: "FI",
    country_long: "Finland",
    portCode: "FIKEM",
    portName: "Kemi/TorneÃ¥ (Kemi/Tornio)",
    portContinent: "Europe",
  },
  {
    lat: "64.2578615",
    lng: "23.9476279",
    country: "FI",
    country_long: "Finland",
    portCode: "FIKJO",
    portName: "Kalajoki (FIKJO), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.80127030000001",
    lng: "24.5450396",
    country: "FI",
    country_long: "Finland",
    portCode: "FIKMM",
    portName: "Keminmaa",
    portContinent: "Europe",
  },
  {
    lat: "59.92633000000001",
    lng: "22.4143973",
    country: "FI",
    country_long: "Finland",
    portCode: "FIKNA",
    portName: "Kasnas (FIKNA), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.8415002",
    lng: "23.1250337",
    country: "FI",
    country_long: "Finland",
    portCode: "FIKOK",
    portName: "KOKKOLA (FIKOK), Kokkola, Finland",
    portContinent: "Europe",
  },
  {
    lat: "61.92410999999999",
    lng: "25.748151",
    country: "FI",
    country_long: "Finland",
    portCode: "FIKOV",
    portName: "Korrvik (FIKOV), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "FIKTK",
    portName: "Port of Kotka",
    portContinent: "Europe",
  },
  {
    lat: "62.89796999999999",
    lng: "27.6781725",
    country: "FI",
    country_long: "Finland",
    portCode: "FIKUO",
    portName: "Kuopio (FIKUO), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.87877839999999",
    lng: "23.2153699",
    country: "FI",
    country_long: "Finland",
    portCode: "FIKVH",
    portName: "Koverhar (FIKVH), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.05061",
    lng: "22.4621192",
    country: "FI",
    country_long: "Finland",
    portCode: "FILAN",
    portName: "Langnas (FILAN), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.98267490000001",
    lng: "25.6612096",
    country: "FI",
    country_long: "Finland",
    portCode: "FILHI",
    portName: "Lahti",
    portContinent: "Europe",
  },
  {
    lat: "61.05499289999999",
    lng: "28.1896628",
    country: "FI",
    country_long: "Finland",
    portCode: "FILPP",
    portName: "LAPPEENRANTA",
    portContinent: "Europe",
  },
  {
    lat: "61.92410999999999",
    lng: "25.748151",
    country: "FI",
    country_long: "Finland",
    portCode: "FIMHQ",
    portName: "Mariehamn (FIMHQ), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "61.68872709999999",
    lng: "27.2721457",
    country: "FI",
    country_long: "Finland",
    portCode: "FIMIK",
    portName: "MIKKELI",
    portContinent: "Europe",
  },
  {
    lat: "61.59187739999999",
    lng: "21.4822772",
    country: "FI",
    country_long: "Finland",
    portCode: "FIMTY",
    portName: "Mantyluoto (FIMTY), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.46608759999999",
    lng: "22.0250873",
    country: "FI",
    country_long: "Finland",
    portCode: "FINLI",
    portName: "Naantali (FINLI), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "61.2363745",
    lng: "21.4753105",
    country: "FI",
    country_long: "Finland",
    portCode: "FIOLK",
    portName: "Olkiluoto (FIOLK), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.0120888",
    lng: "25.4650773",
    country: "FI",
    country_long: "Finland",
    portCode: "FIOUL",
    portName: "Oulu (UleÃ¥borg)",
    portContinent: "Europe",
  },
  {
    lat: "60.09978659999999",
    lng: "23.5506391",
    country: "FI",
    country_long: "Finland",
    portCode: "FIPOH",
    portName: "Pohjankuru (FIPOH), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "61.4851393",
    lng: "21.7974178",
    country: "FI",
    country_long: "Finland",
    portCode: "FIPOR",
    portName: "PORI",
    portContinent: "Europe",
  },
  {
    lat: "60.3931919",
    lng: "25.6652739",
    country: "FI",
    country_long: "Finland",
    portCode: "FIPRV",
    portName: "Porvoo (FIPRV), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.48591219999999",
    lng: "22.1689264",
    country: "FI",
    country_long: "Finland",
    portCode: "FIRAO",
    portName: "RAISIO",
    portContinent: "Europe",
  },
  {
    lat: "61.1308854",
    lng: "21.5059264",
    country: "FI",
    country_long: "Finland",
    portCode: "FIRAU",
    portName: "Rauma (Raumo)",
    portContinent: "Europe",
  },
  {
    lat: "61.61666669999999",
    lng: "21.4333333",
    country: "FI",
    country_long: "Finland",
    portCode: "FIREP",
    portName: "Reposaari (FIREP), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "61.92410999999999",
    lng: "25.748151",
    country: "FI",
    country_long: "Finland",
    portCode: "FIROY",
    portName: "Roytta (FIROY), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.50394779999999",
    lng: "25.7293905",
    country: "FI",
    country_long: "Finland",
    portCode: "FIRVN",
    portName: "ROVANIEMI",
    portContinent: "Europe",
  },
  {
    lat: "61.8683766",
    lng: "28.8861803",
    country: "FI",
    country_long: "Finland",
    portCode: "FISVL",
    portName: "SAVONLINNA",
    portContinent: "Europe",
  },
  {
    lat: "61.1654624",
    lng: "23.8672639",
    country: "FI",
    country_long: "Finland",
    portCode: "FITJA",
    portName: "Toijala",
    portContinent: "Europe",
  },
  {
    lat: "60.4518126",
    lng: "22.2666303",
    country: "FI",
    country_long: "Finland",
    portCode: "FITKU",
    portName: "Turku (FITKU), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "61.4977524",
    lng: "23.7609535",
    country: "FI",
    country_long: "Finland",
    portCode: "FITMP",
    portName: "Tampere",
    portContinent: "Europe",
  },
  {
    lat: "65.847301",
    lng: "24.1520236",
    country: "FI",
    country_long: "Finland",
    portCode: "FITOR",
    portName: "Tornio (FITOR), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.807601",
    lng: "21.402722",
    country: "FI",
    country_long: "Finland",
    portCode: "FIUKI",
    portName: "Uusikaupunki (FIUKI), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.09508899999999",
    lng: "21.6164564",
    country: "FI",
    country_long: "Finland",
    portCode: "FIVAA",
    portName: "Vaasa (FIVAA), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.29335239999999",
    lng: "25.0377686",
    country: "FI",
    country_long: "Finland",
    portCode: "FIVAT",
    portName: "VANTAA",
    portContinent: "Europe",
  },
  {
    lat: "65.6833333",
    lng: "24.6333333",
    country: "FI",
    country_long: "Finland",
    portCode: "FIVEI",
    portName: "Veitsiluoto (FIVEI), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.3246584",
    lng: "27.8961002",
    country: "FI",
    country_long: "Finland",
    portCode: "FIVRK",
    portName: "VARKAUS",
    portContinent: "Europe",
  },
  {
    lat: "60.5091587",
    lng: "21.2464705",
    country: "FI",
    country_long: "Finland",
    portCode: "FIVSN",
    portName: "Vuosnainen (FIVSN), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.20966869999999",
    lng: "25.1360282",
    country: "FI",
    country_long: "Finland",
    portCode: "FIVSS",
    portName: "Vuosaari (FIVSS), Finland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "-17.7505822",
    lng: "-179.3132061",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJICI",
    portName: "Cicia (FJICI), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-16.4310584",
    lng: "179.3701991",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJLBS",
    portName: "Labasa (FJLBS), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-17.6847541",
    lng: "178.8360964",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJLEV",
    portName: "Port of Levuka",
    portContinent: "Oceania",
  },
  {
    lat: "-17.6044621",
    lng: "177.438519",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJLTK",
    portName: "Port of Lautoka",
    portContinent: "Oceania",
  },
  {
    lat: "-18.58191",
    lng: "179.897282",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJMFJ",
    portName: "Moala (FJMFJ), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-17.6727787",
    lng: "177.0986308",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJMNF",
    portName: "Mana Island (FJMNF), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-17.7765356",
    lng: "177.4356227",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJNAN",
    portName: "Nadi (FJNAN), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-17.7747472",
    lng: "177.1958724",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJPTF",
    portName: "Maolo Lailai Island (FJPTF), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "FJRBI",
    portName: "Rabi (FJRBI), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-18.1416372",
    lng: "177.5074315",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJSIN",
    portName: "Singatoka (FJSIN), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-18.1338566",
    lng: "178.4243578",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJSUV",
    portName: "Port of Suva",
    portContinent: "Oceania",
  },
  {
    lat: "-16.7799114",
    lng: "179.3381105",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJSVU",
    portName: "Port of Savusavu",
    portContinent: "Oceania",
  },
  {
    lat: "-16.958915",
    lng: "177.3748698",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJTTL",
    portName: "Turtle Island (FJTTL), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-17.2284603",
    lng: "-178.9509626",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJVBV",
    portName: "Vanua Mbalavu (FJVBV), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "FJVTF",
    portName: "Vatulele (FJVTF), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-17.6057968",
    lng: "177.5000082",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJVUD",
    portName: "Vuda (FJVUD), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-16.8075135",
    lng: "179.9918393",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJWAI",
    portName: "Wairiki (FJWAI), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-16.7880861",
    lng: "177.5074315",
    country: "FJ",
    country_long: "Fiji",
    portCode: "FJYAS",
    portName: "Yasawa Island (FJYAS), Fiji, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "5.2991829",
    lng: "162.9696779",
    country: "FM",
    country_long: "Federated States of Micronesia",
    portCode: "FMKSA",
    portName: "Kosrae",
    portContinent: "Oceania",
  },
  {
    lat: "6.8921132",
    lng: "158.2146857",
    country: "FM",
    country_long: "Federated States of Micronesia",
    portCode: "FMPNI",
    portName: "Pohnpei (FMPNI), Micronesia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "5.4538907",
    lng: "153.5098019",
    country: "FM",
    country_long: "Federated States of Micronesia",
    portCode: "FMTKK",
    portName: "Chuuk",
    portContinent: "Oceania",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "FMYAP",
    portName: "Tomil Harbour",
    portContinent: "Oceania",
  },
  {
    lat: "64.1997764",
    lng: "-21.795748",
    country: "IS",
    country_long: "Iceland",
    portCode: "FOKOL",
    portName: "Kollafjordur",
    portContinent: "Europe",
  },
  {
    lat: "62.01072479999999",
    lng: "-6.7740852",
    country: "FO",
    country_long: "Faroe Islands",
    portCode: "FOTHO",
    portName: "THORSHAVN (FOTHO), Faroe Island, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.01072479999999",
    lng: "-6.7740852",
    country: "FO",
    country_long: "Faroe Islands",
    portCode: "FOTOR",
    portName: "Torshavn (FOTOR), Faroe Island, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.670616",
    lng: "-1.263766",
    country: "FR",
    country_long: "France",
    portCode: "FR2GX",
    portName: "Barfleur (FR2GX), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.2890933",
    lng: "3.5078433",
    country: "FR",
    country_long: "France",
    portCode: "FRAGK",
    portName: "Le Cap D'agde (FRAGK), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "41.919229",
    lng: "8.738635",
    country: "FR",
    country_long: "France",
    portCode: "FRAJA",
    portName: "Ajaccio (FRAJA), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.894067",
    lng: "2.295753",
    country: "FR",
    country_long: "France",
    portCode: "FRAMI",
    portName: "AMIENS",
    portContinent: "Europe",
  },
  {
    lat: "47.47116159999999",
    lng: "-0.5518257",
    country: "FR",
    country_long: "France",
    portCode: "FRANE",
    portName: "ANGERS",
    portContinent: "Europe",
  },
  {
    lat: "43.58041799999999",
    lng: "7.125102",
    country: "FR",
    country_long: "France",
    portCode: "FRANT",
    portName: "Antibes (FRANT), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.73387",
    lng: "2.2948",
    country: "FR",
    country_long: "France",
    portCode: "FRARQ",
    portName: "Arques (FRARQ), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.94931700000001",
    lng: "4.805528",
    country: "FR",
    country_long: "France",
    portCode: "FRAVN",
    portName: "AVIGNON",
    portContinent: "Europe",
  },
  {
    lat: "44.899835",
    lng: "-0.516238",
    country: "FR",
    country_long: "France",
    portCode: "FRBAS",
    portName: "Bassens (FRBAS), Bordeaux, France",
    portContinent: "Europe",
  },
  {
    lat: "43.492949",
    lng: "-1.474841",
    country: "FR",
    country_long: "France",
    portCode: "FRBAY",
    portName: "Bayonne (FRBAY), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "44.43710756166988",
    lng: "4.867826106843091",
    country: "FR",
    country_long: "France",
    portCode: "FRBEE",
    portName: "Berre (FRBEE), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.390394",
    lng: "-4.486076",
    country: "FR",
    country_long: "France",
    portCode: "FRBES",
    portName: "Brest (FRBES), Brest, France",
    portContinent: "Europe",
  },
  {
    lat: "42.697283",
    lng: "9.450880999999999",
    country: "FR",
    country_long: "France",
    portCode: "FRBIA",
    portName: "Bastia (FRBIA), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.6014849",
    lng: "-2.82492",
    country: "FR",
    country_long: "France",
    portCode: "FRBN2",
    portName: "Binic (FRBN2), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.03745199999999",
    lng: "1.578521",
    country: "FR",
    country_long: "France",
    portCode: "FRBNI",
    portName: "Port of Bonnieres sur Seine",
    portContinent: "Europe",
  },
  {
    lat: "44.9099399",
    lng: "-0.5358774",
    country: "FR",
    country_long: "France",
    portCode: "FRBOD",
    portName: "Port of Bordeaux",
    portContinent: "Europe",
  },
  {
    lat: "50.725231",
    lng: "1.613334",
    country: "FR",
    country_long: "France",
    portCode: "FRBOL",
    portName: "Port of Boulogne-sur-Mer",
    portContinent: "Europe",
  },
  {
    lat: "41.38717399999999",
    lng: "9.159269",
    country: "FR",
    country_long: "France",
    portCode: "FRBON",
    portName: "Bonifacio (FRBON), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.782104",
    lng: "2.4930417",
    country: "FR",
    country_long: "France",
    portCode: "FRBSM",
    portName: "Port de Bonneuil",
    portContinent: "Europe",
  },
  {
    lat: "47.237829",
    lng: "6.024053899999999",
    country: "FR",
    country_long: "France",
    portCode: "FRBSN",
    portName: "BESANCON",
    portContinent: "Europe",
  },
  {
    lat: "49.228056",
    lng: "-0.300062",
    country: "FR",
    country_long: "France",
    portCode: "FRBSO",
    portName: "Blainville Sur Orne (FRBSO), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "45.126841",
    lng: "-0.662941",
    country: "FR",
    country_long: "France",
    portCode: "FRBYE",
    portName: "Blaye (FRBYE), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.7077201",
    lng: "7.334370099999999",
    country: "FR",
    country_long: "France",
    portCode: "FRBZM",
    portName: "Beaulieu Sur Mer (FRBZM), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.344233",
    lng: "3.215795",
    country: "FR",
    country_long: "France",
    portCode: "FRBZR",
    portName: "BEZIERS",
    portContinent: "Europe",
  },
  {
    lat: "48.87997555",
    lng: "-1.8291199",
    country: "FR",
    country_long: "France",
    portCode: "FRC2H",
    portName: "Chausey (FRC2H), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.387537",
    lng: "-1.675432",
    country: "FR",
    country_long: "France",
    portCode: "FRCBP",
    portName: "Ciboure (FRCBP), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.2132151",
    lng: "2.3517703",
    country: "FR",
    country_long: "France",
    portCode: "FRCCF",
    portName: "CARCASSONNE",
    portContinent: "Europe",
  },
  {
    lat: "48.904526",
    lng: "2.304768",
    country: "FR",
    country_long: "France",
    portCode: "FRCCH",
    portName: "Clichy (FRCCH), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.525303",
    lng: "0.7233360000000001",
    country: "FR",
    country_long: "France",
    portCode: "FRCEC",
    portName: "Caudebec En Caux (FRCEC), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.5477068",
    lng: "7.0134482",
    country: "FR",
    country_long: "France",
    portCode: "FRCEQ",
    portName: "Port de Cannes",
    portContinent: "Europe",
  },
  {
    lat: "49.6338979",
    lng: "-1.622224",
    country: "FR",
    country_long: "France",
    portCode: "FRCER",
    portName: "Port of Cherbourg",
    portContinent: "Europe",
  },
  {
    lat: "45.8159588",
    lng: "3.1140581",
    country: "FR",
    country_long: "France",
    portCode: "FRCFE",
    portName: "CLERMONT FERRAND (FRCFE), Clermont-Ferrand, France",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "FRCFR",
    portName: "Port of Caen",
    portContinent: "Europe",
  },
  {
    lat: "49.677307",
    lng: "0.17778",
    country: "FR",
    country_long: "France",
    portCode: "FRCIF",
    portName: "Antifer (FRCIF), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.879859",
    lng: "3.854818",
    country: "FR",
    country_long: "France",
    portCode: "FRCJ9",
    portName: "Etoges (FRCJ9), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "42.567651",
    lng: "8.757221999999999",
    country: "FR",
    country_long: "France",
    portCode: "FRCLY",
    portName: "Calvi (FRCLY), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.7650099",
    lng: "3.006234",
    country: "FR",
    country_long: "France",
    portCode: "FRCMN",
    portName: "Comines (FRCMN), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "45.691046",
    lng: "-0.328744",
    country: "FR",
    country_long: "France",
    portCode: "FRCNG",
    portName: "COGNAC (FRCNG), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.43788199999999",
    lng: "4.945711",
    country: "FR",
    country_long: "France",
    portCode: "FRCOU",
    portName: "Coussoul/Fos-sur-Mer",
    portContinent: "Europe",
  },
  {
    lat: "43.40183442015636",
    lng: "5.02329071991523",
    country: "FR",
    country_long: "France",
    portCode: "FRCOZ",
    portName: "Calal de Caronte",
    portContinent: "Europe",
  },
  {
    lat: "50.9734324",
    lng: "1.8770518",
    country: "FR",
    country_long: "France",
    portCode: "FRCQF",
    portName: "Port of Calais",
    portContinent: "Europe",
  },
  {
    lat: "49.37865",
    lng: "-1.788731",
    country: "FR",
    country_long: "France",
    portCode: "FRCRT",
    portName: "Carteret (FRCRT), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.000275",
    lng: "2.09178",
    country: "FR",
    country_long: "France",
    portCode: "FRCSH",
    portName: "Conflans (FRCSH), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.305666",
    lng: "-1.242834",
    country: "FR",
    country_long: "France",
    portCode: "FRCTA",
    portName: "Carentan (FRCTA), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.67287",
    lng: "-3.346952",
    country: "FR",
    country_long: "France",
    portCode: "FRCVQ",
    portName: "Cavan,France",
    portContinent: "Europe",
  },
  {
    lat: "47.875568",
    lng: "-4.1063729",
    country: "FR",
    country_long: "France",
    portCode: "FRDBE",
    portName: "Benodet (FRDBE), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.4342997",
    lng: "2.9854081",
    country: "FR",
    country_long: "France",
    portCode: "FRDGS",
    portName: "DOURGES (FRDGS), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.322047",
    lng: "5.04148",
    country: "FR",
    country_long: "France",
    portCode: "FRDIJ",
    portName: "Dijon",
    portContinent: "Europe",
  },
  {
    lat: "49.549872",
    lng: "-1.862664",
    country: "FR",
    country_long: "France",
    portCode: "FRDIL",
    portName: "Dielette (FRDIL), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.0418464",
    lng: "2.3733285",
    country: "FR",
    country_long: "France",
    portCode: "FRDKK",
    portName: "Port of Dunkerque",
    portContinent: "Europe",
  },
  {
    lat: "48.515451",
    lng: "2.634831",
    country: "FR",
    country_long: "France",
    portCode: "FRDLL",
    portName: "Port of Dammarie-les-Lys",
    portContinent: "Europe",
  },
  {
    lat: "48.454366",
    lng: "-2.047327",
    country: "FR",
    country_long: "France",
    portCode: "FRDNN",
    portName: "Dinan (FRDNN), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.3643443",
    lng: "0.0714487",
    country: "FR",
    country_long: "France",
    portCode: "FRDOL",
    portName: "Port of Deauville",
    portContinent: "Europe",
  },
  {
    lat: "47.318789",
    lng: "-2.074694",
    country: "FR",
    country_long: "France",
    portCode: "FRDON",
    portName: "Donges (FRDON), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.922992",
    lng: "1.077483",
    country: "FR",
    country_long: "France",
    portCode: "FRDPE",
    portName: "Port of Dieppe",
    portContinent: "Europe",
  },
  {
    lat: "48.093228",
    lng: "-4.328619",
    country: "FR",
    country_long: "France",
    portCode: "FRDRZ",
    portName: "Douarnenez (FRDRZ), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.09819",
    lng: "3.443883",
    country: "FR",
    country_long: "France",
    portCode: "FREBT",
    portName: "Abrest",
    portContinent: "Europe",
  },
  {
    lat: "45.439695",
    lng: "4.3871779",
    country: "FR",
    country_long: "France",
    portCode: "FREBU",
    portName: "Saint-Ã‰tienne",
    portContinent: "Europe",
  },
  {
    lat: "48.692054",
    lng: "6.184417",
    country: "FR",
    country_long: "France",
    portCode: "FRENC",
    portName: "NANCY",
    portContinent: "Europe",
  },
  {
    lat: "48.629828",
    lng: "2.441782",
    country: "FR",
    country_long: "France",
    portCode: "FREVR",
    portName: "Port of Evry",
    portContinent: "Europe",
  },
  {
    lat: "49.755601",
    lng: "0.380774",
    country: "FR",
    country_long: "France",
    portCode: "FRFEC",
    portName: "Port of Fecamp",
    portContinent: "Europe",
  },
  {
    lat: "43.43788199999999",
    lng: "4.945711",
    country: "FR",
    country_long: "France",
    portCode: "FRFOS",
    portName: "Port of Fos-sur-Mer",
    portContinent: "Europe",
  },
  {
    lat: "47.63243900000001",
    lng: "1.284039",
    country: "FR",
    country_long: "France",
    portCode: "FRFS5",
    portName: "Fosse (FRFS5), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.6376934",
    lng: "-3.4629995",
    country: "FR",
    country_long: "France",
    portCode: "FRG2X",
    portName: "Groix (FRG2X), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.388696",
    lng: "-1.041706",
    country: "FR",
    country_long: "France",
    portCode: "FRGCP",
    portName: "Grandcamp (FRGCP), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.2843306",
    lng: "3.4536169",
    country: "FR",
    country_long: "France",
    portCode: "FRGDA",
    portName: "Le Grau D'agde (FRGDA), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.560704",
    lng: "4.086072000000001",
    country: "FR",
    country_long: "France",
    portCode: "FRGDM",
    portName: "La Grande Motte (FRGDM), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.225962",
    lng: "4.968738999999999",
    country: "FR",
    country_long: "France",
    portCode: "FRGEC",
    portName: "GEVREY-CHAMBERTIN",
    portContinent: "Europe",
  },
  {
    lat: "40.06811920000001",
    lng: "-82.51960369999999",
    country: "US",
    country_long: "United States",
    portCode: "FRGFR",
    portName: "Port of Granville",
    portContinent: "Europe",
  },
  {
    lat: "43.5666466",
    lng: "7.077953699999999",
    country: "FR",
    country_long: "France",
    portCode: "FRGJU",
    portName: "Golfe Juan (FRGJU), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "45.188529",
    lng: "5.724524",
    country: "FR",
    country_long: "France",
    portCode: "FRGNB",
    portName: "GRENOBLE",
    portContinent: "Europe",
  },
  {
    lat: "49.7158609",
    lng: "-1.94533",
    country: "FR",
    country_long: "France",
    portCode: "FRGR2",
    portName: "Goury (FRGR2), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.48910799999999",
    lng: "0.567624",
    country: "FR",
    country_long: "France",
    portCode: "FRGRN",
    portName: "Port of Gravenchon",
    portContinent: "Europe",
  },
  {
    lat: "43.660153",
    lng: "6.926492",
    country: "FR",
    country_long: "France",
    portCode: "FRGRS",
    portName: "GRASSE",
    portContinent: "Europe",
  },
  {
    lat: "47.797213",
    lng: "-4.284075",
    country: "FR",
    country_long: "France",
    portCode: "FRGVC",
    portName: "Guilvinec (FRGVC), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "FRGVL",
    portName: "Port of Gennevilliers",
    portContinent: "Europe",
  },
  {
    lat: "47.340082",
    lng: "-2.878658",
    country: "FR",
    country_long: "France",
    portCode: "FRHDO",
    portName: "Hoedic (FRHDO), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.359399",
    lng: "-1.766148",
    country: "FR",
    country_long: "France",
    portCode: "FRHEN",
    portName: "Hendaye (FRHEN), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.69533",
    lng: "2.915396",
    country: "FR",
    country_long: "France",
    portCode: "FRHLS",
    portName: "Houplines",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "FRHON",
    portName: "Port of Honfleur",
    portContinent: "Europe",
  },
  {
    lat: "49.668146",
    lng: "-1.876775",
    country: "FR",
    country_long: "France",
    portCode: "FRHRQ",
    portName: "Herqueville (FRHRQ), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.593723",
    lng: "7.581459000000001",
    country: "FR",
    country_long: "France",
    portCode: "FRHUN",
    portName: "Huningue (FRHUN), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "42.63651",
    lng: "8.937045999999999",
    country: "FR",
    country_long: "France",
    portCode: "FRILR",
    portName: "L'ile Rousse (FRILR), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.0343684",
    lng: "2.3767763",
    country: "FR",
    country_long: "France",
    portCode: "FRIRK",
    portName: "Dunkerque Ouest (FRIRK), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.388051",
    lng: "-1.663055",
    country: "FR",
    country_long: "France",
    portCode: "FRJL2",
    portName: "Saint Jean De Luz (FRJL2), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.427008",
    lng: "3.726749",
    country: "FR",
    country_long: "France",
    portCode: "FRLA3",
    portName: "La Peyrade (FRLA3), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "FRLAV",
    portName: "Port Autonome de Marse",
    portContinent: "Europe",
  },
  {
    lat: "43.17365299999999",
    lng: "5.605155",
    country: "FR",
    country_long: "France",
    portCode: "FRLCT",
    portName: "Port of La Ciotat",
    portContinent: "Europe",
  },
  {
    lat: "46.884681",
    lng: "-2.118506",
    country: "FR",
    country_long: "France",
    portCode: "FRLDM",
    portName: "La Barre De Monts (FRLDM), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.4719317",
    lng: "0.1474997",
    country: "FR",
    country_long: "France",
    portCode: "FRLEH",
    portName: "Port of Le Havre",
    portContinent: "Europe",
  },
  {
    lat: "43.537476",
    lng: "4.137918",
    country: "FR",
    country_long: "France",
    portCode: "FRLGR",
    portName: "Le Grau Du Roi (FRLGR), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.8714786",
    lng: "2.6914552",
    country: "FR",
    country_long: "France",
    portCode: "FRLGY",
    portName: "Port of Lagny-Saint Thibault",
    portContinent: "Europe",
  },
  {
    lat: "45.83361900000001",
    lng: "1.261105",
    country: "FR",
    country_long: "France",
    portCode: "FRLIG",
    portName: "LIMOGES",
    portContinent: "Europe",
  },
  {
    lat: "45.764043",
    lng: "4.835659",
    country: "FR",
    country_long: "France",
    portCode: "FRLIO",
    portName: "Lyon (FRLIO), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.62925",
    lng: "3.057256",
    country: "FR",
    country_long: "France",
    portCode: "FRLLE",
    portName: "LILLE",
    portContinent: "Europe",
  },
  {
    lat: "33.73954589643058",
    lng: "-118.2599686241527",
    country: "US",
    country_long: "United States",
    portCode: "FRLMD",
    portName: "Port of La Mede",
    portContinent: "Europe",
  },
  {
    lat: "48.00611000000001",
    lng: "0.199556",
    country: "FR",
    country_long: "France",
    portCode: "FRLME",
    portName: "LE MANS",
    portContinent: "Europe",
  },
  {
    lat: "49.160569",
    lng: "6.189544000000001",
    country: "FR",
    country_long: "France",
    portCode: "FRLMW",
    portName: "La Maxe (FRLMW), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.971683",
    lng: "1.776713",
    country: "FR",
    country_long: "France",
    portCode: "FRLMY",
    portName: "Port of Limay-Porcheville",
    portContinent: "Europe",
  },
  {
    lat: "46.1625749",
    lng: "-1.205105",
    country: "FR",
    country_long: "France",
    portCode: "FRLPE",
    portName: "La Pallice (FRLPE), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.89321700000001",
    lng: "2.287864",
    country: "FR",
    country_long: "France",
    portCode: "FRLPT",
    portName: "Levallois Perret (FRLPT), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.906515",
    lng: "1.932535",
    country: "FR",
    country_long: "France",
    portCode: "FRLQU",
    portName: "Les Attaques (FRLQU), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "45.095797",
    lng: "-0.717545",
    country: "FR",
    country_long: "France",
    portCode: "FRLR9",
    portName: "Lamarque (FRLR9), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.160329",
    lng: "-1.151139",
    country: "FR",
    country_long: "France",
    portCode: "FRLRH",
    portName: "La Rochelle (FRLRH), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.7482524",
    lng: "-3.3702449",
    country: "FR",
    country_long: "France",
    portCode: "FRLRT",
    portName: "Lorient (FRLRT), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.49295799999999",
    lng: "-1.795493",
    country: "FR",
    country_long: "France",
    portCode: "FRLSO",
    portName: "Les Sables D Olonne (FRLSO), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.05916999999999",
    lng: "1.382472",
    country: "FR",
    country_long: "France",
    portCode: "FRLTR",
    portName: "Port of Le Treport",
    portContinent: "Europe",
  },
  {
    lat: "49.486557",
    lng: "0.804422",
    country: "FR",
    country_long: "France",
    portCode: "FRLTT",
    portName: "Le Trait (FRLTT), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.3887003",
    lng: "-2.9666011",
    country: "FR",
    country_long: "France",
    portCode: "FRLUD",
    portName: "Ile D'houat (FRLUD), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "45.546371",
    lng: "-1.061701",
    country: "FR",
    country_long: "France",
    portCode: "FRLVE",
    portName: "Le Verdon Sur Mer (FRLVE), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.989323",
    lng: "1.714958",
    country: "FR",
    country_long: "France",
    portCode: "FRMAN",
    portName: "Mantes La Jolie (FRMAN), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.227638",
    lng: "2.213749",
    country: "FR",
    country_long: "France",
    portCode: "FRME5",
    portName: "Molene (FRME5), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.947545",
    lng: "2.1423899",
    country: "FR",
    country_long: "France",
    portCode: "FRMFI",
    portName: "Maisons Laffitte (FRMFI), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.920143",
    lng: "8.147461999999999",
    country: "FR",
    country_long: "France",
    portCode: "FRMHU",
    portName: "Munchhausen (FRMHU), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.98543",
    lng: "2.619156",
    country: "FR",
    country_long: "France",
    portCode: "FRMIM",
    portName: "PARIS MITRY",
    portContinent: "Europe",
  },
  {
    lat: "47.750839",
    lng: "7.335888",
    country: "FR",
    country_long: "France",
    portCode: "FRMLH",
    portName: "MULHOUSE",
    portContinent: "Europe",
  },
  {
    lat: "48.315363",
    lng: "-4.289858",
    country: "FR",
    country_long: "France",
    portCode: "FRMM5",
    portName: "Moulin Mer (FRMM5), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.610769",
    lng: "3.876716",
    country: "FR",
    country_long: "France",
    portCode: "FRMPL",
    portName: "MONTPELLIER",
    portContinent: "Europe",
  },
  {
    lat: "49.293819",
    lng: "1.06443",
    country: "FR",
    country_long: "France",
    portCode: "FRMQA",
    portName: "Martot (FRMQA), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "FRMRS",
    portName: "Port of Marseille",
    portContinent: "Europe",
  },
  {
    lat: "48.38473399999999",
    lng: "2.956193",
    country: "FR",
    country_long: "France",
    portCode: "FRMTE",
    portName: "Port of Montereau",
    portContinent: "Europe",
  },
  {
    lat: "47.32705199999999",
    lng: "-2.150431",
    country: "FR",
    country_long: "France",
    portCode: "FRMTX",
    portName: "Montoir-de-Bretagne",
    portContinent: "Europe",
  },
  {
    lat: "48.577613",
    lng: "-3.828228",
    country: "FR",
    country_long: "France",
    portCode: "FRMXN",
    portName: "Port of Morlaix",
    portContinent: "Europe",
  },
  {
    lat: "49.1193089",
    lng: "6.175715599999999",
    country: "FR",
    country_long: "France",
    portCode: "FRMZM",
    portName: "Metz (FRMZM), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.892423",
    lng: "2.215331",
    country: "FR",
    country_long: "France",
    portCode: "FRNAN",
    portName: "Port of Nanterre",
    portContinent: "Europe",
  },
  {
    lat: "43.7101728",
    lng: "7.261953200000001",
    country: "FR",
    country_long: "France",
    portCode: "FRNCE",
    portName: "NICE",
    portContinent: "Europe",
  },
  {
    lat: "45.899247",
    lng: "6.129384",
    country: "FR",
    country_long: "France",
    portCode: "FRNCY",
    portName: "ANNECY",
    portContinent: "Europe",
  },
  {
    lat: "48.018091",
    lng: "7.530145",
    country: "FR",
    country_long: "France",
    portCode: "FRNEF",
    portName: "Neuf Brisach (FRNEF), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.492624",
    lng: "3.498165",
    country: "FR",
    country_long: "France",
    portCode: "FRNGS",
    portName: "Nogent Sur Seine (FRNGS), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.338984",
    lng: "5.256773",
    country: "FR",
    country_long: "France",
    portCode: "FRNLO",
    portName: "Niolon (FRNLO), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.5488256",
    lng: "3.9799375",
    country: "FR",
    country_long: "France",
    portCode: "FRNPG",
    portName: "Carnon Plage (FRNPG), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.884831",
    lng: "2.26851",
    country: "FR",
    country_long: "France",
    portCode: "FRNSS",
    portName: "Neuilly Sur Seine (FRNSS), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.218371",
    lng: "-1.553621",
    country: "FR",
    country_long: "France",
    portCode: "FRNTE",
    portName: "Nantes (FRNTE), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.8396952",
    lng: "2.2399123",
    country: "FR",
    country_long: "France",
    portCode: "FROGB",
    portName: "Boulogne Billancourt (FROGB), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.725892",
    lng: "-3.341016",
    country: "FR",
    country_long: "France",
    portCode: "FROQL",
    portName: "Locmiquelic (FROQL), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.902964",
    lng: "1.909251",
    country: "FR",
    country_long: "France",
    portCode: "FRORR",
    portName: "Orleans",
    portContinent: "Europe",
  },
  {
    lat: "47.78719",
    lng: "7.506512",
    country: "FR",
    country_long: "France",
    portCode: "FROTM",
    portName: "OTTMARSHEIM (FROTM), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "45.9265199",
    lng: "-1.009384",
    country: "FR",
    country_long: "France",
    portCode: "FROUB",
    portName: "Soubise Marina (FROUB), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.276656",
    lng: "-0.258658",
    country: "FR",
    country_long: "France",
    portCode: "FROUI",
    portName: "Ouistreham (FROUI), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.761005",
    lng: "6.129582",
    country: "FR",
    country_long: "France",
    portCode: "FROXA",
    portName: "Frouard (FROXA), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.779782",
    lng: "-3.048828",
    country: "FR",
    country_long: "France",
    portCode: "FRPAI",
    portName: "Port of Paimpol",
    portContinent: "Europe",
  },
  {
    lat: "48.8296267",
    lng: "2.3323816",
    country: "FR",
    country_long: "France",
    portCode: "FRPAR",
    portName: "Paris (FRPAR), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.28741",
    lng: "-2.031545",
    country: "FR",
    country_long: "France",
    portCode: "FRPBF",
    portName: "Paimboeuf (FRPBF), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.405449",
    lng: "4.985931",
    country: "FR",
    country_long: "France",
    portCode: "FRPDB",
    portName: "Port of Port de Bouc",
    portContinent: "Europe",
  },
  {
    lat: "48.383707",
    lng: "7.472530000000001",
    country: "FR",
    country_long: "France",
    portCode: "FRPDY",
    portName: "Saint Pierre Du Vauv (FRPDY), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.385602",
    lng: "1.020078",
    country: "FR",
    country_long: "France",
    portCode: "FRPET",
    portName: "Port of Petit Couronne",
    portContinent: "Europe",
  },
  {
    lat: "48.341425",
    lng: "-4.718711",
    country: "FR",
    country_long: "France",
    portCode: "FRPGO",
    portName: "Plougonvelin (FRPGO), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.380007",
    lng: "-2.544721",
    country: "FR",
    country_long: "France",
    portCode: "FRPIR",
    portName: "Piriac Sur Mer (FRPIR), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.58022400000001",
    lng: "0.340375",
    country: "FR",
    country_long: "France",
    portCode: "FRPIS",
    portName: "POITIERS",
    portContinent: "Europe",
  },
  {
    lat: "49.31862899999999",
    lng: "1.226022",
    country: "FR",
    country_long: "France",
    portCode: "FRPIX",
    portName: "Pitres (FRPIX), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.4890146",
    lng: "0.5676293",
    country: "FR",
    country_long: "France",
    portCode: "FRPJE",
    portName: "Port Jerome (FRPJE), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "45.988162",
    lng: "-1.093442",
    country: "FR",
    country_long: "France",
    portCode: "FRPN6",
    portName: "Fouras Sud Marina (FRPN6), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "42.518487",
    lng: "3.106592",
    country: "FR",
    country_long: "France",
    portCode: "FRPOV",
    portName: "Port Vendres (FRPOV), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.708392",
    lng: "-3.356342",
    country: "FR",
    country_long: "France",
    portCode: "FRPPL",
    portName: "Port Louis (FRPPL), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.387847",
    lng: "4.803224999999999",
    country: "FR",
    country_long: "France",
    portCode: "FRPSL",
    portName: "Port St Louis du Rhone",
    portContinent: "Europe",
  },
  {
    lat: "41.591369",
    lng: "9.278311",
    country: "FR",
    country_long: "France",
    portCode: "FRPTC",
    portName: "Porto Vecchio (FRPTC), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.050966",
    lng: "2.100645",
    country: "FR",
    country_long: "France",
    portCode: "FRPTE",
    portName: "Pontoise (FRPTE), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "45.947898",
    lng: "-1.079237",
    country: "FR",
    country_long: "France",
    portCode: "FRPTQ",
    portName: "Port Des Barques (FRPTQ), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.678784",
    lng: "-2.040899",
    country: "FR",
    country_long: "France",
    portCode: "FRPXN",
    portName: "La Martiniere (Le Pe (FRPXN), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "42.706091",
    lng: "3.009898",
    country: "FR",
    country_long: "France",
    portCode: "FRPYO",
    portName: "Canet En Roussillon (FRPYO), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.482133",
    lng: "-3.121053",
    country: "FR",
    country_long: "France",
    portCode: "FRQUI",
    portName: "Quiberon (FRQUI), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.50673",
    lng: "0.50642",
    country: "FR",
    country_long: "France",
    portCode: "FRRAD",
    portName: "Radicatel (FRRAD), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "45.936698",
    lng: "-0.9616969999999999",
    country: "FR",
    country_long: "France",
    portCode: "FRRCO",
    portName: "Rochefort (FRRCO), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.117266",
    lng: "-1.6777926",
    country: "FR",
    country_long: "France",
    portCode: "FRRNS",
    portName: "RENNES",
    portContinent: "Europe",
  },
  {
    lat: "49.00363",
    lng: "2.516978",
    country: "FR",
    country_long: "France",
    portCode: "FRROI",
    portName: "ROISSY",
    portContinent: "Europe",
  },
  {
    lat: "48.721267",
    lng: "-3.9629044",
    country: "FR",
    country_long: "France",
    portCode: "FRROS",
    portName: "Port of Roscoff-Bloscon",
    portContinent: "Europe",
  },
  {
    lat: "49.3373658",
    lng: "-0.7719714",
    country: "FR",
    country_long: "France",
    portCode: "FRRTB",
    portName: "Port En Bessin (FRRTB), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.803526",
    lng: "4.260054",
    country: "FR",
    country_long: "France",
    portCode: "FRRTL",
    portName: "Bussy Lettree (FRRTL), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.5428405",
    lng: "-2.8948522",
    country: "FR",
    country_long: "France",
    portCode: "FRRZN",
    portName: "Port Du Crouesty (FRRZN), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.51418",
    lng: "-2.765835",
    country: "FR",
    country_long: "France",
    portCode: "FRSBK",
    portName: "Port of Saint Brieuc",
    portContinent: "Europe",
  },
  {
    lat: "43.410459",
    lng: "4.732263",
    country: "FR",
    country_long: "France",
    portCode: "FRSDG",
    portName: "Salin De Giraud (FRSDG), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.4163181",
    lng: "3.7242331",
    country: "FR",
    country_long: "France",
    portCode: "FRSET",
    portName: "Port of Sete",
    portContinent: "Europe",
  },
  {
    lat: "48.970259",
    lng: "2.286571",
    country: "FR",
    country_long: "France",
    portCode: "FRSGO",
    portName: "Saint Gratien (FRSGO), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.227638",
    lng: "2.213749",
    country: "FR",
    country_long: "France",
    portCode: "FRSI3",
    portName: "Sein (FRSI3), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.7142277",
    lng: "2.1452278",
    country: "FR",
    country_long: "France",
    portCode: "FRSLE",
    portName: "Saint Aubin (FRSLE), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.649337",
    lng: "-2.025674",
    country: "FR",
    country_long: "France",
    portCode: "FRSML",
    portName: "Saint Malo (FRSML), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.2734979",
    lng: "-2.213848",
    country: "FR",
    country_long: "France",
    portCode: "FRSNR",
    portName: "St Nazaire (FRSNR), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.6480279",
    lng: "-2.828941",
    country: "FR",
    country_long: "France",
    portCode: "FRSQ2",
    portName: "Saint Quay Portrieux (FRSQ2), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.188648",
    lng: "1.6296949",
    country: "FR",
    country_long: "France",
    portCode: "FRSVM",
    portName: "St Valery Sur Somme (FRSVM), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.52128",
    lng: "0.777029",
    country: "FR",
    country_long: "France",
    portCode: "FRSWD",
    portName: "Saint Wandrille (FRSWD), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.53839869999999",
    lng: "7.7944064",
    country: "FR",
    country_long: "France",
    portCode: "FRSXB",
    portName: "Port of Strasbourg",
    portContinent: "Europe",
  },
  {
    lat: "47.2734979",
    lng: "-2.213848",
    country: "FR",
    country_long: "France",
    portCode: "FRSZI",
    portName: "Saint Nazaire (FRSZI), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.348472",
    lng: "-2.511759",
    country: "FR",
    country_long: "France",
    portCode: "FRTBE",
    portName: "La Turballe (FRTBE), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.886788",
    lng: "-4.155261",
    country: "FR",
    country_long: "France",
    portCode: "FRTBM",
    portName: "Combrit (FRTBM), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.36628899999999",
    lng: "0.08280799999999999",
    country: "FR",
    country_long: "France",
    portCode: "FRTJE",
    portName: "Trouville Sur Mer (FRTJE), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.1229521",
    lng: "5.9261491",
    country: "FR",
    country_long: "France",
    portCode: "FRTLN",
    portName: "Port of Toulon La Seyne",
    portContinent: "Europe",
  },
  {
    lat: "43.604652",
    lng: "1.444209",
    country: "FR",
    country_long: "France",
    portCode: "FRTLS",
    portName: "TOULOUSE",
    portContinent: "Europe",
  },
  {
    lat: "45.943621",
    lng: "-0.891574",
    country: "FR",
    country_long: "France",
    portCode: "FRTON",
    portName: "Tonnay Charente (FRTON), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.7875439",
    lng: "-3.2307769",
    country: "FR",
    country_long: "France",
    portCode: "FRTRE",
    portName: "Port of Treguier",
    portContinent: "Europe",
  },
  {
    lat: "49.4442536",
    lng: "1.0731138",
    country: "FR",
    country_long: "France",
    portCode: "FRURO",
    portName: "Port of Rouen",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "FRVAF",
    portName: "VALENCE",
    portContinent: "Europe",
  },
  {
    lat: "49.587369",
    lng: "-1.268371",
    country: "FR",
    country_long: "France",
    portCode: "FRVH2",
    portName: "St Vaast La Hougue (FRVH2), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.0691205",
    lng: "2.5623193",
    country: "FR",
    country_long: "France",
    portCode: "FRVMB",
    portName: "Vemars",
    portContinent: "Europe",
  },
  {
    lat: "47.658236",
    lng: "-2.760847",
    country: "FR",
    country_long: "France",
    portCode: "FRVNE",
    portName: "Vannes (FRVNE), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.15987639999999",
    lng: "5.3844231",
    country: "FR",
    country_long: "France",
    portCode: "FRVRD",
    portName: "Verdun (FRVRD), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.091958",
    lng: "1.488107",
    country: "FR",
    country_long: "France",
    portCode: "FRVRN",
    portName: "Vernon (FRVRN), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.7481356",
    lng: "2.4674754",
    country: "FR",
    country_long: "France",
    portCode: "FRVTN",
    portName: "VALENTON (FRVTN), Paris, France",
    portContinent: "Europe",
  },
  {
    lat: "46.227638",
    lng: "2.213749",
    country: "FR",
    country_long: "France",
    portCode: "FRWML",
    portName: "Marly (FRWML), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.832479",
    lng: "2.213165",
    country: "FR",
    country_long: "France",
    portCode: "FRWTT",
    portName: "Watten (FRWTT), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.77032",
    lng: "3.0480489",
    country: "FR",
    country_long: "France",
    portCode: "FRWVQ",
    portName: "Wervicq Sud (FRWVQ), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.0817098",
    lng: "1.8780181",
    country: "FR",
    country_long: "France",
    portCode: "FRXRT",
    portName: "ARTENAY",
    portContinent: "Europe",
  },
  {
    lat: "48.5734053",
    lng: "7.752111299999999",
    country: "FR",
    country_long: "France",
    portCode: "FRXSB",
    portName: "Strasbourg (FRXSB), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.102976",
    lng: "5.878219",
    country: "FR",
    country_long: "France",
    portCode: "FRYNE",
    portName: "LA SEYNE (FRYNE), France, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.5710485",
    lng: "3.6116494",
    country: "FR",
    country_long: "France",
    portCode: "FRYUH",
    portName: "Aumelas",
    portContinent: "Europe",
  },
  {
    lat: "-0.7726462",
    lng: "8.7811645",
    country: "GA",
    country_long: "Gabon",
    portCode: "GACLZ",
    portName: "Cap Lopez (GACLZ), Gabon, Africa",
    portContinent: "Africa",
  },
  {
    lat: "0.4004247",
    lng: "9.433793099999999",
    country: "GA",
    country_long: "Gabon",
    portCode: "GALBV",
    portName: "Port of Libreville",
    portContinent: "Africa",
  },
  {
    lat: "13.443182",
    lng: "-15.310139",
    country: "GM",
    country_long: "The Gambia",
    portCode: "GAMYB",
    portName: "MAYUMBA (GAMYB), Gambia, Africa",
    portContinent: "Africa",
  },
  {
    lat: "0.288285",
    lng: "9.510145",
    country: "GA",
    country_long: "Gabon",
    portCode: "GAOWE",
    portName: "Owendo (GAOWE), Gabon, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-0.7351025999999999",
    lng: "8.7591311",
    country: "GA",
    country_long: "Gabon",
    portCode: "GAPOG",
    portName: "Port of Port Gentil",
    portContinent: "Africa",
  },
  {
    lat: "51.961726",
    lng: "1.351255",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GB",
    portName: "Felixstowe, Suffolk, England, United Kingdom",
    portContinent: "Europe",
  },
  {
    lat: "39.86481939999999",
    lng: "-75.4257325",
    country: "US",
    country_long: "United States",
    portCode: "GB2AB",
    portName: "Aston",
    portContinent: "Europe",
  },
  {
    lat: "52.77001199999999",
    lng: "-2.410369",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GB4ED",
    portName: "Edgmond",
    portContinent: "Europe",
  },
  {
    lat: "51.226437",
    lng: "-0.130931",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GB4NS",
    portName: "SouthNutfield",
    portContinent: "Europe",
  },
  {
    lat: "53.50748",
    lng: "-2.315434",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GB5PE",
    portName: "Pendlebury",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GB7TR",
    portName: "Norton",
    portContinent: "Europe",
  },
  {
    lat: "52.23098299999999",
    lng: "-1.078198",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GB87N",
    portName: "WeedonBeck",
    portContinent: "Europe",
  },
  {
    lat: "52.16014699999999",
    lng: "-0.9794219999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GB8IE",
    portName: "Tiffield",
    portContinent: "Europe",
  },
  {
    lat: "54.08262",
    lng: "-6.448939999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GB8MR",
    portName: "Forkhill",
    portContinent: "Europe",
  },
  {
    lat: "53.3252584",
    lng: "-6.3089222",
    country: "IE",
    country_long: "Ireland",
    portCode: "GB8TN",
    portName: "Crumlin",
    portContinent: "Europe",
  },
  {
    lat: "33.6962718",
    lng: "-117.8365644",
    country: "US",
    country_long: "United States",
    portCode: "GBAAB",
    portName: "Abotts",
    portContinent: "Europe",
  },
  {
    lat: "53.146561",
    lng: "-2.367382",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAAP",
    portName: "Sandbach, Cheshire",
    portContinent: "Europe",
  },
  {
    lat: "52.834564",
    lng: "-4.125459999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAAW",
    portName: "Llandanwg",
    portContinent: "Europe",
  },
  {
    lat: "52.242793",
    lng: "-4.2581619",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABA",
    portName: "Port of Aberaeron",
    portContinent: "Europe",
  },
  {
    lat: "51.707275",
    lng: "-0.4183259",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABB",
    portName: "Abbots Langley",
    portContinent: "Europe",
  },
  {
    lat: "51.646985",
    lng: "-3.135657",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABC",
    portName: "Abercarn",
    portContinent: "Europe",
  },
  {
    lat: "57.1498891",
    lng: "-2.0937528",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABD",
    portName: "Aberdeen",
    portContinent: "Europe",
  },
  {
    lat: "51.716154",
    lng: "-3.451816",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABE",
    portName: "Aberdare",
    portContinent: "Europe",
  },
  {
    lat: "52.543963",
    lng: "-4.046093",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABF",
    portName: "Aberdyfi(Aberdovey)",
    portContinent: "Europe",
  },
  {
    lat: "51.6493558",
    lng: "0.1188155",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABG",
    portName: "Abridge",
    portContinent: "Europe",
  },
  {
    lat: "54.0919691",
    lng: "-1.377826",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABH",
    portName: "Aldborough",
    portContinent: "Europe",
  },
  {
    lat: "52.633035",
    lng: "-2.28022",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABI",
    portName: "Albrighton",
    portContinent: "Europe",
  },
  {
    lat: "55.898921",
    lng: "-2.138515",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABJ",
    portName: "SaintAbbs",
    portContinent: "Europe",
  },
  {
    lat: "52.14565",
    lng: "1.191994",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABK",
    portName: "Ashbocking",
    portContinent: "Europe",
  },
  {
    lat: "57.47491100000001",
    lng: "-3.216983",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABL",
    portName: "Aberlour",
    portContinent: "Europe",
  },
  {
    lat: "52.5444779",
    lng: "-3.235731",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABM",
    portName: "Abermule",
    portContinent: "Europe",
  },
  {
    lat: "40.1198332",
    lng: "-75.1253492",
    country: "US",
    country_long: "United States",
    portCode: "GBABN",
    portName: "Abington",
    portContinent: "Europe",
  },
  {
    lat: "50.935768",
    lng: "-0.200037",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABO",
    portName: "Albourne",
    portContinent: "Europe",
  },
  {
    lat: "52.1325069",
    lng: "-4.544851",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABP",
    portName: "Aberporth",
    portContinent: "Europe",
  },
  {
    lat: "53.584356",
    lng: "-2.722712",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABR",
    portName: "Appley Bridge",
    portContinent: "Europe",
  },
  {
    lat: "52.824497",
    lng: "-4.5053599",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABS",
    portName: "AberSÃ´ch(Abersoch)",
    portContinent: "Europe",
  },
  {
    lat: "51.732161",
    lng: "-3.135369",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABT",
    portName: "Abertillery",
    portContinent: "Europe",
  },
  {
    lat: "39.3267715",
    lng: "-76.6744784",
    country: "US",
    country_long: "United States",
    portCode: "GBABU",
    portName: "Ashburton",
    portContinent: "Europe",
  },
  {
    lat: "52.543963",
    lng: "-4.046093",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABV",
    portName: "Aberdovey",
    portContinent: "Europe",
  },
  {
    lat: "52.722873",
    lng: "-4.0560919",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABW",
    portName: "Barmouth(Abermaw)",
    portContinent: "Europe",
  },
  {
    lat: "53.263271",
    lng: "0.183359",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABX",
    portName: "Alford",
    portContinent: "Europe",
  },
  {
    lat: "57.076668",
    lng: "-2.780312",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBABY",
    portName: "Aboyne",
    portContinent: "Europe",
  },
  {
    lat: "56.744487",
    lng: "-5.797639999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBACA",
    portName: "Acharacle",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBACB",
    portName: "Back",
    portContinent: "Europe",
  },
  {
    lat: "51.725747",
    lng: "-3.367402",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBACD",
    portName: "Abercanaid",
    portContinent: "Europe",
  },
  {
    lat: "58.02480999999999",
    lng: "-5.352271",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBACE",
    portName: "Achiltibuie",
    portContinent: "Europe",
  },
  {
    lat: "51.801738",
    lng: "-3.705678",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBACF",
    portName: "Abercraf",
    portContinent: "Europe",
  },
  {
    lat: "52.44558379999999",
    lng: "-1.8308359",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBACG",
    portName: "AcocksGreen/Birmingham",
    portContinent: "Europe",
  },
  {
    lat: "56.300621",
    lng: "-3.700041",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBACH",
    portName: "Auchterarder",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBACI",
    portName: "A'Chill",
    portContinent: "Europe",
  },
  {
    lat: "53.64961659999999",
    lng: "-1.3327497",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBACK",
    portName: "Ackworth",
    portContinent: "Europe",
  },
  {
    lat: "43.8159588",
    lng: "7.776561699999999",
    country: "IT",
    country_long: "Italy",
    portCode: "GBACL",
    portName: "Acle",
    portContinent: "Europe",
  },
  {
    lat: "53.38690459999999",
    lng: "-2.3488983",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBACM",
    portName: "Altrincham",
    portContinent: "Europe",
  },
  {
    lat: "42.4850931",
    lng: "-71.43284",
    country: "US",
    country_long: "United States",
    portCode: "GBACN",
    portName: "Acton",
    portContinent: "Europe",
  },
  {
    lat: "53.753609",
    lng: "-2.37218",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBACR",
    portName: "Accrington",
    portContinent: "Europe",
  },
  {
    lat: "43.02137829999999",
    lng: "-96.6308708",
    country: "US",
    country_long: "United States",
    portCode: "GBACS",
    portName: "Alcester",
    portContinent: "Europe",
  },
  {
    lat: "53.3660176",
    lng: "-2.6247292",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBACT",
    portName: "Acton Grange",
    portContinent: "Europe",
  },
  {
    lat: "51.99782039999999",
    lng: "-2.1046656",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBACU",
    portName: "Ashchurch",
    portContinent: "Europe",
  },
  {
    lat: "57.102949",
    lng: "-2.082979",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBACV",
    portName: "Cove(Aberdeen)",
    portContinent: "Europe",
  },
  {
    lat: "51.64751200000001",
    lng: "-3.3293529",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBACY",
    portName: "Abercynon",
    portContinent: "Europe",
  },
  {
    lat: "52.8455337",
    lng: "-2.3512624",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADA",
    portName: "Adbaston",
    portContinent: "Europe",
  },
  {
    lat: "51.479233",
    lng: "-1.62053",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADB",
    portName: "Aldbourne",
    portContinent: "Europe",
  },
  {
    lat: "53.94454899999999",
    lng: "-1.879299",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADD",
    portName: "Addingham",
    portContinent: "Europe",
  },
  {
    lat: "53.7283214",
    lng: "-1.5255059",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADE",
    portName: "ArdsleyEast=Ardsley",
    portContinent: "Europe",
  },
  {
    lat: "56.18136",
    lng: "-5.5383559",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADF",
    portName: "Ardfern",
    portContinent: "Europe",
  },
  {
    lat: "52.6110324",
    lng: "-1.9142056",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADG",
    portName: "Aldridge",
    portContinent: "Europe",
  },
  {
    lat: "51.8957534",
    lng: "0.7850294999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADH",
    portName: "Aldham",
    portContinent: "Europe",
  },
  {
    lat: "-29.86244689999999",
    lng: "31.0400186",
    country: "ZA",
    country_long: "South Africa",
    portCode: "GBADI",
    portName: "Addington",
    portContinent: "Europe",
  },
  {
    lat: "54.43201",
    lng: "-5.52847",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADK",
    portName: "Ardkeen",
    portContinent: "Europe",
  },
  {
    lat: "53.611055",
    lng: "-2.606365",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADL",
    portName: "Adlington",
    portContinent: "Europe",
  },
  {
    lat: "57.00879500000001",
    lng: "-7.4190635",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADM",
    portName: "AirdMhor(Ardmhor)",
    portContinent: "Europe",
  },
  {
    lat: "51.369813",
    lng: "-0.488641",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADN",
    portName: "Addlestone",
    portContinent: "Europe",
  },
  {
    lat: "52.71285100000001",
    lng: "-2.540365",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADO",
    portName: "Admaston",
    portContinent: "Europe",
  },
  {
    lat: "52.8047289",
    lng: "-4.7115151",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADR",
    portName: "Aberdaron",
    portContinent: "Europe",
  },
  {
    lat: "52.9270812",
    lng: "-1.4426521",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADS",
    portName: "Chaddesden",
    portContinent: "Europe",
  },
  {
    lat: "51.09205799999999",
    lng: "0.9441739999999998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADT",
    portName: "Aldington",
    portContinent: "Europe",
  },
  {
    lat: "51.8740306",
    lng: "-1.9674386",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADV",
    portName: "Andoversford",
    portContinent: "Europe",
  },
  {
    lat: "55.845341",
    lng: "-3.614513",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADW",
    portName: "Addiewell",
    portContinent: "Europe",
  },
  {
    lat: "54.69772099999999",
    lng: "-1.58552",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADX",
    portName: "Spennymore, Durham",
    portContinent: "Europe",
  },
  {
    lat: "52.017428",
    lng: "-1.315045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADY",
    portName: "Adderbury",
    portContinent: "Europe",
  },
  {
    lat: "52.749212",
    lng: "-1.468618",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADZ",
    portName: "AshbydelaZouch",
    portContinent: "Europe",
  },
  {
    lat: "52.749212",
    lng: "-1.468618",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBADZÂ",
    portName: "Ashby-de-la-Zouch",
    portContinent: "Europe",
  },
  {
    lat: "53.4992531",
    lng: "-1.2218979",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAEB",
    portName: "Cadeby",
    portContinent: "Europe",
  },
  {
    lat: "56.009209",
    lng: "-2.859444",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAED",
    portName: "Aberlady",
    portContinent: "Europe",
  },
  {
    lat: "53.284355",
    lng: "-3.581405",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAEE",
    portName: "Abergele",
    portContinent: "Europe",
  },
  {
    lat: "51.709953",
    lng: "-3.143984",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAEG",
    portName: "Aberbeeg",
    portContinent: "Europe",
  },
  {
    lat: "61.1613853",
    lng: "13.2630157",
    country: "SE",
    country_long: "Sweden",
    portCode: "GBAEN",
    portName: "Salen",
    portContinent: "Europe",
  },
  {
    lat: "52.1359767",
    lng: "-1.6650396",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAER",
    portName: "Alderminster",
    portContinent: "Europe",
  },
  {
    lat: "56.046502",
    lng: "-4.912224999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAET",
    portName: "Ardentinny",
    portContinent: "Europe",
  },
  {
    lat: "51.5066369",
    lng: "-0.3383229",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAEW",
    portName: "Hanwell",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBAEY",
    portName: "Arley",
    portContinent: "Europe",
  },
  {
    lat: "51.792888",
    lng: "-3.988459",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAFD",
    portName: "Ammanford, Carmarthenshire",
    portContinent: "Europe",
  },
  {
    lat: "56.1786724",
    lng: "-4.384602800000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAFE",
    portName: "Aberfoyle",
    portContinent: "Europe",
  },
  {
    lat: "51.4492749",
    lng: "-1.1309708",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAFL",
    portName: "Bradfield",
    portContinent: "Europe",
  },
  {
    lat: "53.263271",
    lng: "0.183359",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAFO",
    portName: "Alford",
    portContinent: "Europe",
  },
  {
    lat: "51.7216397",
    lng: "-2.1567205",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAFR",
    portName: "Chalford",
    portContinent: "Europe",
  },
  {
    lat: "-37.6100844",
    lng: "141.4432865",
    country: "AU",
    country_long: "Australia",
    portCode: "GBAFS",
    portName: "Sandford",
    portContinent: "Europe",
  },
  {
    lat: "50.8080492",
    lng: "0.1565208",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAFT",
    portName: "Alfriston",
    portContinent: "Europe",
  },
  {
    lat: "53.19195999999999",
    lng: "-4.463753",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAFW",
    portName: "Aberffraw",
    portContinent: "Europe",
  },
  {
    lat: "56.621752",
    lng: "-3.866969",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAFY",
    portName: "Aberfeldy",
    portContinent: "Europe",
  },
  {
    lat: "54.7389001",
    lng: "-6.0426901",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAG2",
    portName: "Doagh",
    portContinent: "Europe",
  },
  {
    lat: "51.619954",
    lng: "-3.814203",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAGA",
    portName: "Baglan",
    portContinent: "Europe",
  },
  {
    lat: "51.579614",
    lng: "-2.449899",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAGE",
    portName: "Bagstone",
    portContinent: "Europe",
  },
  {
    lat: "53.233669",
    lng: "-4.014381",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAGG",
    portName: "Abergwyngregyn",
    portContinent: "Europe",
  },
  {
    lat: "55.02961",
    lng: "-6.65189",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAGH",
    portName: "Aghadowey",
    portContinent: "Europe",
  },
  {
    lat: "54.2633033",
    lng: "-5.6108327",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAGL",
    portName: "Ardglass (GBAGL), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "36.70983349999999",
    lng: "-81.9773482",
    country: "US",
    country_long: "United States",
    portCode: "GBAGN",
    portName: "Abingdon",
    portContinent: "Europe",
  },
  {
    lat: "56.6241199",
    lng: "-6.5285081",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAGO",
    portName: "Arinagour",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBAGR",
    portName: "Ashley",
    portContinent: "Europe",
  },
  {
    lat: "42.1539141",
    lng: "-88.1361888",
    country: "US",
    country_long: "United States",
    portCode: "GBAGT",
    portName: "Barrington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBAGU",
    portName: "Ardgour",
    portContinent: "Europe",
  },
  {
    lat: "55.9162663",
    lng: "-4.8663946",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAGW",
    portName: "Ardnagowan",
    portContinent: "Europe",
  },
  {
    lat: "51.825366",
    lng: "-3.019423",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAGY",
    portName: "Abergavenny",
    portContinent: "Europe",
  },
  {
    lat: "51.31068399999999",
    lng: "-0.296968",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAHE",
    portName: "Ashtead",
    portContinent: "Europe",
  },
  {
    lat: "55.182978",
    lng: "-1.5659829",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAHG",
    portName: "Ashington",
    portContinent: "Europe",
  },
  {
    lat: "56.525875",
    lng: "-3.091491",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAHH",
    portName: "Auchterhouse",
    portContinent: "Europe",
  },
  {
    lat: "51.674129",
    lng: "0.846443",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAHL",
    portName: "Asheldham",
    portContinent: "Europe",
  },
  {
    lat: "53.7890755",
    lng: "-2.3472485",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAHM",
    portName: "Altham",
    portContinent: "Europe",
  },
  {
    lat: "56.51165100000001",
    lng: "-5.495020999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAHN",
    portName: "Achnacroish",
    portContinent: "Europe",
  },
  {
    lat: "54.1480217",
    lng: "-2.6653649",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAHO",
    portName: "Arkholme",
    portContinent: "Europe",
  },
  {
    lat: "51.4889406",
    lng: "-1.1869991",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAHP",
    portName: "Ashampstead",
    portContinent: "Europe",
  },
  {
    lat: "34.8450984",
    lng: "-95.55748109999999",
    country: "US",
    country_long: "United States",
    portCode: "GBAHR",
    portName: "Hartshorne",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBAHS",
    portName: "Ashurst",
    portContinent: "Europe",
  },
  {
    lat: "-33.8298782",
    lng: "20.0509216",
    country: "ZA",
    country_long: "South Africa",
    portCode: "GBAHT",
    portName: "Ashton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBAHV",
    portName: "AshVale",
    portContinent: "Europe",
  },
  {
    lat: "54.7534021",
    lng: "-2.8258569",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAHW",
    portName: "Calthwaite",
    portContinent: "Europe",
  },
  {
    lat: "53.822643",
    lng: "-2.407684",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAHY",
    portName: "Whalley",
    portContinent: "Europe",
  },
  {
    lat: "57.19448999999999",
    lng: "-3.823812",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAIE",
    portName: "Aviemore",
    portContinent: "Europe",
  },
  {
    lat: "54.189047",
    lng: "-3.203934",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAIF",
    portName: "AskamInFurness",
    portContinent: "Europe",
  },
  {
    lat: "52.633035",
    lng: "-2.28022",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAIG",
    portName: "Albrighton",
    portContinent: "Europe",
  },
  {
    lat: "56.069301",
    lng: "-3.770963",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAIH",
    portName: "Airth",
    portContinent: "Europe",
  },
  {
    lat: "38.7570612",
    lng: "-77.7916599",
    country: "US",
    country_long: "United States",
    portCode: "GBAII",
    portName: "Airlie",
    portContinent: "Europe",
  },
  {
    lat: "53.4869569",
    lng: "-2.642566",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAIM",
    portName: "Ashton-In-Makerfield, Greater Manchester",
    portContinent: "Europe",
  },
  {
    lat: "53.486017",
    lng: "-2.9499065",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAIN",
    portName: "Aintree",
    portContinent: "Europe",
  },
  {
    lat: "51.2926993",
    lng: "-114.0134073",
    country: "CA",
    country_long: "Canada",
    portCode: "GBAIR",
    portName: "Airdrie",
    portContinent: "Europe",
  },
  {
    lat: "50.3125085",
    lng: "-5.2036202",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAIS",
    portName: "St Agnes (GBAIS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.28327900000001",
    lng: "-1.375267",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAIT",
    portName: "Aith (GBAIT), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.54159379999999",
    lng: "-1.263058",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAKA",
    portName: "Acklam",
    portContinent: "Europe",
  },
  {
    lat: "51.641845",
    lng: "-1.930725",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAKE",
    portName: "Ashton Keynes, Wiltshire",
    portContinent: "Europe",
  },
  {
    lat: "56.501369",
    lng: "-3.518331",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAKF",
    portName: "Bankfoot",
    portContinent: "Europe",
  },
  {
    lat: "58.56863199999999",
    lng: "-3.10085",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAKL",
    portName: "Auckengill",
    portContinent: "Europe",
  },
  {
    lat: "42.3711653",
    lng: "-71.0614646",
    country: "US",
    country_long: "United States",
    portCode: "GBAKR",
    portName: "Blackmoor",
    portContinent: "Europe",
  },
  {
    lat: "53.5722088",
    lng: "-1.189055",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAKS",
    portName: "AdwickleStreet",
    portContinent: "Europe",
  },
  {
    lat: "54.4133642",
    lng: "-6.9746522",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAKV",
    portName: "Aughnacloy",
    portContinent: "Europe",
  },
  {
    lat: "51.641845",
    lng: "-1.930725",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAKY",
    portName: "AshtonKeynes",
    portContinent: "Europe",
  },
  {
    lat: "52.1580008",
    lng: "-2.97632",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAL2",
    portName: "Almeley",
    portContinent: "Europe",
  },
  {
    lat: "36.8050308",
    lng: "-98.6664737",
    country: "US",
    country_long: "United States",
    portCode: "GBALA",
    portName: "Alva",
    portContinent: "Europe",
  },
  {
    lat: "52.155357",
    lng: "1.600446",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALB",
    portName: "Aldeburgh",
    portContinent: "Europe",
  },
  {
    lat: "52.47460179999999",
    lng: "1.1735458",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALC",
    portName: "Aslacton",
    portContinent: "Europe",
  },
  {
    lat: "51.248366",
    lng: "-0.7557509",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALD",
    portName: "Aldershot",
    portContinent: "Europe",
  },
  {
    lat: "53.301554",
    lng: "-2.237216",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALE",
    portName: "Alderley Edge",
    portContinent: "Europe",
  },
  {
    lat: "53.09744999999999",
    lng: "-1.382256",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALF",
    portName: "Alfreton",
    portContinent: "Europe",
  },
  {
    lat: "53.095402",
    lng: "-2.304657",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALG",
    portName: "Alsager",
    portContinent: "Europe",
  },
  {
    lat: "51.6714272",
    lng: "-0.3527835",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALH",
    portName: "Aldenham",
    portContinent: "Europe",
  },
  {
    lat: "32.735687",
    lng: "-97.10806559999999",
    country: "US",
    country_long: "United States",
    portCode: "GBALI",
    portName: "Arlington",
    portContinent: "Europe",
  },
  {
    lat: "56.114073",
    lng: "-3.791896",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALL",
    portName: "Alloa",
    portContinent: "Europe",
  },
  {
    lat: "55.3902223",
    lng: "-1.6129047",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALM",
    portName: "Alnmouth",
    portContinent: "Europe",
  },
  {
    lat: "26.2872995",
    lng: "-98.3133594",
    country: "US",
    country_long: "United States",
    portCode: "GBALN",
    portName: "Alton",
    portContinent: "Europe",
  },
  {
    lat: "53.611055",
    lng: "-2.606365",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALO",
    portName: "Adlington",
    portContinent: "Europe",
  },
  {
    lat: "53.577566",
    lng: "-0.7407889999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALP",
    portName: "Althorpe",
    portContinent: "Europe",
  },
  {
    lat: "26.2872995",
    lng: "-98.3133594",
    country: "US",
    country_long: "United States",
    portCode: "GBALQ",
    portName: "Alton",
    portContinent: "Europe",
  },
  {
    lat: "51.3838496",
    lng: "-1.1532939",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALR",
    portName: "Aldermaston",
    portContinent: "Europe",
  },
  {
    lat: "54.812167",
    lng: "-2.438685",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALS",
    portName: "Alston",
    portContinent: "Europe",
  },
  {
    lat: "56.62578",
    lng: "-3.226867",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALT",
    portName: "Alyth",
    portContinent: "Europe",
  },
  {
    lat: "51.80324419999999",
    lng: "-0.6016020999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALU",
    portName: "Aldbury",
    portContinent: "Europe",
  },
  {
    lat: "51.5902879",
    lng: "-2.5327101",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALV",
    portName: "Alveston",
    portContinent: "Europe",
  },
  {
    lat: "55.412744",
    lng: "-1.706299",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALW",
    portName: "Alnwick",
    portContinent: "Europe",
  },
  {
    lat: "31.2000924",
    lng: "29.9187387",
    country: "EG",
    country_long: "Egypt",
    portCode: "GBALX",
    portName: "Alexandria",
    portContinent: "Europe",
  },
  {
    lat: "52.36591199999999",
    lng: "-0.262374",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBALY",
    portName: "Alconbury",
    portContinent: "Europe",
  },
  {
    lat: "51.006175",
    lng: "-1.433779",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAM9",
    portName: "Ampfield",
    portContinent: "Europe",
  },
  {
    lat: "55.331461",
    lng: "-1.585261",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAMB",
    portName: "Amble",
    portContinent: "Europe",
  },
  {
    lat: "55.897742",
    lng: "-3.702162",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAMD",
    portName: "Armadale (GBAMD), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6755179",
    lng: "-0.6072409999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAME",
    portName: "Amersham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBAMF",
    portName: "Bamford",
    portContinent: "Europe",
  },
  {
    lat: "53.06147199999999",
    lng: "-1.483764",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAMG",
    portName: "Ambergate",
    portContinent: "Europe",
  },
  {
    lat: "55.8761024",
    lng: "-5.075406",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAMH",
    portName: "Ardmaleish",
    portContinent: "Europe",
  },
  {
    lat: "54.428736",
    lng: "-2.961333",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAMI",
    portName: "Ambleside, Cumbria",
    portContinent: "Europe",
  },
  {
    lat: "54.428736",
    lng: "-2.961333",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAML",
    portName: "Ambleside",
    portContinent: "Europe",
  },
  {
    lat: "51.792888",
    lng: "-3.988459",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAMM",
    portName: "Ammanford",
    portContinent: "Europe",
  },
  {
    lat: "56.7166667",
    lng: "-6.000000000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAMN",
    portName: "Ardnamurchan",
    portContinent: "Europe",
  },
  {
    lat: "51.395142",
    lng: "-2.320591",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAMO",
    portName: "Bathampton",
    portContinent: "Europe",
  },
  {
    lat: "52.0272503",
    lng: "-0.4951417",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAMP",
    portName: "Ampthill",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBAMR",
    portName: "AirdMaRuibhe",
    portContinent: "Europe",
  },
  {
    lat: "42.85839250000001",
    lng: "-70.93003759999999",
    country: "US",
    country_long: "United States",
    portCode: "GBAMS",
    portName: "Amesbury",
    portContinent: "Europe",
  },
  {
    lat: "53.6418204",
    lng: "-1.344835",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAMT",
    portName: "AckworthMoorTop",
    portContinent: "Europe",
  },
  {
    lat: "56.292291",
    lng: "-3.236092",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAMU",
    portName: "Auchtermuchty",
    portContinent: "Europe",
  },
  {
    lat: "53.410658",
    lng: "-4.345693",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAMW",
    portName: "Amlwch",
    portContinent: "Europe",
  },
  {
    lat: "51.1948099",
    lng: "-0.55903",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAMY",
    portName: "Bramley",
    portContinent: "Europe",
  },
  {
    lat: "43.2177791",
    lng: "-79.98728349999999",
    country: "CA",
    country_long: "Canada",
    portCode: "GBAN2",
    portName: "Ancaster",
    portContinent: "Europe",
  },
  {
    lat: "53.60634049999999",
    lng: "-2.5730822",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAN9",
    portName: "Anderton",
    portContinent: "Europe",
  },
  {
    lat: "57.1418336",
    lng: "-2.1456959",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBANA",
    portName: "Anasuria",
    portContinent: "Europe",
  },
  {
    lat: "53.7452336",
    lng: "-0.4322939",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBANB",
    portName: "Anlaby",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBANC",
    portName: "Barnacle",
    portContinent: "Europe",
  },
  {
    lat: "52.93705989999999",
    lng: "-1.3095132",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAND",
    portName: "StantonbyDale",
    portContinent: "Europe",
  },
  {
    lat: "53.0662226",
    lng: "-1.2494026",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBANE",
    portName: "Annesley",
    portContinent: "Europe",
  },
  {
    lat: "37.4274745",
    lng: "-122.169719",
    country: "US",
    country_long: "United States",
    portCode: "GBANF",
    portName: "Stanford",
    portContinent: "Europe",
  },
  {
    lat: "54.10771",
    lng: "-5.90109",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBANG",
    portName: "Annalong",
    portContinent: "Europe",
  },
  {
    lat: "52.5899605",
    lng: "1.3245128",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBANH",
    portName: "Arminghall",
    portContinent: "Europe",
  },
  {
    lat: "54.990246",
    lng: "-3.259773",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBANN",
    portName: "Annan",
    portContinent: "Europe",
  },
  {
    lat: "18.4286115",
    lng: "-64.6184657",
    country: "VG",
    country_long: "British Virgin Islands",
    portCode: "GBANO",
    portName: "RoadTown",
    portContinent: "Europe",
  },
  {
    lat: "54.8551431",
    lng: "-1.742843",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBANP",
    portName: "AnnfieldPlain",
    portContinent: "Europe",
  },
  {
    lat: "52.465265",
    lng: "-4.022635999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBANR",
    portName: "Llandre",
    portContinent: "Europe",
  },
  {
    lat: "56.22298000000001",
    lng: "-2.700539",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBANS",
    portName: "Anstruther",
    portContinent: "Europe",
  },
  {
    lat: "54.7195338",
    lng: "-6.2072498",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBANT",
    portName: "Antrim",
    portContinent: "Europe",
  },
  {
    lat: "53.07823010000001",
    lng: "-1.2578955",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBANW",
    portName: "AnnesleyWoodhouse",
    portContinent: "Europe",
  },
  {
    lat: "51.694914",
    lng: "-3.220952",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAOD",
    portName: "Aberbargoed",
    portContinent: "Europe",
  },
  {
    lat: "54.62068000000001",
    lng: "-6.51139",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAOE",
    portName: "Arboe(Ardboe)",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBAOF",
    portName: "AlbaOilField",
    portContinent: "Europe",
  },
  {
    lat: "56.20097200000001",
    lng: "-4.745479",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAOH",
    portName: "Arrochar",
    portContinent: "Europe",
  },
  {
    lat: "52.1932328",
    lng: "-0.2030159",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAOL",
    portName: "Abbotsley",
    portContinent: "Europe",
  },
  {
    lat: "52.19364419999999",
    lng: "-1.9586258",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAOM",
    portName: "AbbotsMorton",
    portContinent: "Europe",
  },
  {
    lat: "39.86481939999999",
    lng: "-75.4257325",
    country: "US",
    country_long: "United States",
    portCode: "GBAON",
    portName: "Aston",
    portContinent: "Europe",
  },
  {
    lat: "50.6655598",
    lng: "-2.5995788",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAOT",
    portName: "Abbotsbury",
    portContinent: "Europe",
  },
  {
    lat: "51.479233",
    lng: "-1.62053",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAOU",
    portName: "Aldebourne",
    portContinent: "Europe",
  },
  {
    lat: "51.2111975",
    lng: "-1.4919233",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAOV",
    portName: "Andover, Hampshire",
    portContinent: "Europe",
  },
  {
    lat: "52.2391288",
    lng: "-1.7977135",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAOW",
    portName: "AstonCantlow",
    portContinent: "Europe",
  },
  {
    lat: "51.5531449",
    lng: "-2.5719234",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAOY",
    portName: "Almondsbury",
    portContinent: "Europe",
  },
  {
    lat: "54.578302",
    lng: "-2.489614",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAP2",
    portName: "Appleby",
    portContinent: "Europe",
  },
  {
    lat: "53.30859299999999",
    lng: "0.100114",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAP3",
    portName: "Authorpe",
    portContinent: "Europe",
  },
  {
    lat: "53.195104",
    lng: "0.316201",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAPE",
    portName: "Addlethopre",
    portContinent: "Europe",
  },
  {
    lat: "-37.7805624",
    lng: "145.0322776",
    country: "AU",
    country_long: "Australia",
    portCode: "GBAPH",
    portName: "Alphington",
    portContinent: "Europe",
  },
  {
    lat: "56.561684",
    lng: "-5.3587619",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAPI",
    portName: "Appin",
    portContinent: "Europe",
  },
  {
    lat: "44.2619309",
    lng: "-88.41538469999999",
    country: "US",
    country_long: "United States",
    portCode: "GBAPL",
    portName: "Appleton",
    portContinent: "Europe",
  },
  {
    lat: "51.70990949999999",
    lng: "-1.3610316",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAPLÂ",
    portName: "AppletonOxfordshire",
    portContinent: "Europe",
  },
  {
    lat: "51.539601",
    lng: "-0.2988365",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAPN",
    portName: "Alperton",
    portContinent: "Europe",
  },
  {
    lat: "51.053778",
    lng: "-4.193055999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAPP",
    portName: "Appledore",
    portContinent: "Europe",
  },
  {
    lat: "53.83461579999999",
    lng: "-1.7130695",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAPR",
    portName: "Apperley Bridge, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "57.4319012",
    lng: "-5.809727",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAPS",
    portName: "Applecross",
    portContinent: "Europe",
  },
  {
    lat: "53.3503926",
    lng: "-2.545137",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAPT",
    portName: "AppletonThorn",
    portContinent: "Europe",
  },
  {
    lat: "53.568213",
    lng: "-2.588311",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAPU",
    portName: "Aspull",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBAQO",
    portName: "Ash",
    portContinent: "Europe",
  },
  {
    lat: "53.4541219",
    lng: "-2.2001784",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAQW",
    portName: "Longsight",
    portContinent: "Europe",
  },
  {
    lat: "52.0403499",
    lng: "-4.557244",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAR9",
    portName: "Abercych",
    portContinent: "Europe",
  },
  {
    lat: "56.559107",
    lng: "-2.591543",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARB",
    portName: "Arbroath",
    portContinent: "Europe",
  },
  {
    lat: "51.95891899999999",
    lng: "-5.125257",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARC",
    portName: "Abercastle",
    portContinent: "Europe",
  },
  {
    lat: "55.64388899999999",
    lng: "-4.81182",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARD",
    portName: "Ardrossan (GBARD), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "-32.1515645",
    lng: "116.0140386",
    country: "AU",
    country_long: "Australia",
    portCode: "GBARE",
    portName: "Armadale",
    portContinent: "Europe",
  },
  {
    lat: "56.910099",
    lng: "-5.842724599999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARG",
    portName: "Arisaig",
    portContinent: "Europe",
  },
  {
    lat: "52.741115",
    lng: "-1.886529",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARI",
    portName: "Armitage",
    portContinent: "Europe",
  },
  {
    lat: "53.617273",
    lng: "-1.155753",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARK",
    portName: "Askern",
    portContinent: "Europe",
  },
  {
    lat: "51.0856236",
    lng: "-1.1655575",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARL",
    portName: "NewAlresford",
    portContinent: "Europe",
  },
  {
    lat: "54.3502798",
    lng: "-6.652792",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARM",
    portName: "Armagh",
    portContinent: "Europe",
  },
  {
    lat: "52.5278901",
    lng: "-1.091978",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARN",
    portName: "Arnesby",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBARO",
    portName: "Arnold",
    portContinent: "Europe",
  },
  {
    lat: "51.3150998",
    lng: "-2.6227292",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARP",
    portName: "WestHarptree",
    portContinent: "Europe",
  },
  {
    lat: "35.8681199",
    lng: "-86.7091643",
    country: "US",
    country_long: "United States",
    portCode: "GBARR",
    portName: "Arrington",
    portContinent: "Europe",
  },
  {
    lat: "57.56813299999999",
    lng: "-4.036627",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARS",
    portName: "Ardersier",
    portContinent: "Europe",
  },
  {
    lat: "54.85643",
    lng: "-7.4010099",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBART",
    portName: "Artigarvan",
    portContinent: "Europe",
  },
  {
    lat: "50.855171",
    lng: "-0.555119",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARU",
    portName: "Arundel",
    portContinent: "Europe",
  },
  {
    lat: "57.004991",
    lng: "-7.414587",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARV",
    portName: "Ardveenish",
    portContinent: "Europe",
  },
  {
    lat: "52.73254190000001",
    lng: "-1.7498386",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARW",
    portName: "Alrewas",
    portContinent: "Europe",
  },
  {
    lat: "53.210629",
    lng: "-0.318757",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBARY",
    portName: "Bardney",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBARZ",
    portName: "Abram",
    portContinent: "Europe",
  },
  {
    lat: "51.131683",
    lng: "-2.808896",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAS6",
    portName: "Ashcott",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBAS9",
    portName: "Ashley",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBASA",
    portName: "Saint-Anne's-on-Sea",
    portContinent: "Europe",
  },
  {
    lat: "52.81800200000001",
    lng: "-1.882179",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBASB",
    portName: "AbbotsBromley",
    portContinent: "Europe",
  },
  {
    lat: "51.4062365",
    lng: "-0.6755624",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBASC",
    portName: "Ascot",
    portContinent: "Europe",
  },
  {
    lat: "51.14510070000001",
    lng: "0.8739631",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBASD",
    portName: "Ashford",
    portContinent: "Europe",
  },
  {
    lat: "52.9529489",
    lng: "-1.4925673",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBASE",
    portName: "Allestree",
    portContinent: "Europe",
  },
  {
    lat: "51.14510070000001",
    lng: "0.8739631",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBASF",
    portName: "Ashford",
    portContinent: "Europe",
  },
  {
    lat: "56.010917",
    lng: "-5.450042",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBASG",
    portName: "Ardrishaig",
    portContinent: "Europe",
  },
  {
    lat: "53.016626",
    lng: "-1.732179",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBASH",
    portName: "Ashbourne",
    portContinent: "Europe",
  },
  {
    lat: "53.4876815",
    lng: "-2.0961077",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBASL",
    portName: "Ashton-under-Lyne",
    portContinent: "Europe",
  },
  {
    lat: "53.4869569",
    lng: "-2.642566",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBASM",
    portName: "Ashton in Makerfield",
    portContinent: "Europe",
  },
  {
    lat: "39.86481939999999",
    lng: "-75.4257325",
    country: "US",
    country_long: "United States",
    portCode: "GBASN",
    portName: "Aston",
    portContinent: "Europe",
  },
  {
    lat: "39.86481939999999",
    lng: "-75.4257325",
    country: "US",
    country_long: "United States",
    portCode: "GBASO",
    portName: "Aston",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBASP",
    portName: "Aspall",
    portContinent: "Europe",
  },
  {
    lat: "54.605961",
    lng: "-2.755378",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBASQ",
    portName: "Askham",
    portContinent: "Europe",
  },
  {
    lat: "57.695955",
    lng: "-4.256825",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBASS",
    portName: "Alness",
    portContinent: "Europe",
  },
  {
    lat: "55.182978",
    lng: "-1.5659829",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAST",
    portName: "Ashington",
    portContinent: "Europe",
  },
  {
    lat: "52.042936",
    lng: "-1.9352939",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBASV",
    portName: "AstonSomerville",
    portContinent: "Europe",
  },
  {
    lat: "30.2906861",
    lng: "-97.7279641",
    country: "US",
    country_long: "United States",
    portCode: "GBASW",
    portName: "Ashwell",
    portContinent: "Europe",
  },
  {
    lat: "52.015347",
    lng: "-0.26326",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBASY",
    portName: "Arlesey",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBATA",
    portName: "AthenaFPSO",
    portContinent: "Europe",
  },
  {
    lat: "52.158325",
    lng: "-0.352958",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBATB",
    portName: "GreatBarford",
    portContinent: "Europe",
  },
  {
    lat: "53.3986376",
    lng: "-1.4268725",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBATC",
    portName: "Attercliffe",
    portContinent: "Europe",
  },
  {
    lat: "56.467052",
    lng: "-3.202333",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBATE",
    portName: "Abernyte",
    portContinent: "Europe",
  },
  {
    lat: "55.987721",
    lng: "-2.750891",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBATF",
    portName: "Athelstaneford",
    portContinent: "Europe",
  },
  {
    lat: "52.71159600000001",
    lng: "-4.009899",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBATG",
    portName: "Arthog",
    portContinent: "Europe",
  },
  {
    lat: "52.576614",
    lng: "-1.543763",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBATH",
    portName: "Atherstone",
    portContinent: "Europe",
  },
  {
    lat: "51.525291",
    lng: "-2.279226",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBATL",
    portName: "ActonTurville",
    portContinent: "Europe",
  },
  {
    lat: "52.6784409",
    lng: "-2.6794543",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBATM",
    portName: "Atcham",
    portContinent: "Europe",
  },
  {
    lat: "51.7961637",
    lng: "-0.7277127999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBATN",
    portName: "Aston Clinton",
    portContinent: "Europe",
  },
  {
    lat: "52.071129",
    lng: "0.7593989999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBATO",
    portName: "Acton,Sudbury",
    portContinent: "Europe",
  },
  {
    lat: "53.5352099",
    lng: "-1.0522282",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBATP",
    portName: "Armthorpe",
    portContinent: "Europe",
  },
  {
    lat: "37.4613272",
    lng: "-122.197743",
    country: "US",
    country_long: "United States",
    portCode: "GBATR",
    portName: "Atherton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBATS",
    portName: "Sleat",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBATT",
    portName: "Attenborough",
    portContinent: "Europe",
  },
  {
    lat: "50.371299",
    lng: "-4.805874999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBATW",
    portName: "Carthew",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBATX",
    portName: "Astley",
    portContinent: "Europe",
  },
  {
    lat: "52.4461032",
    lng: "-1.4169835",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBATY",
    portName: "Ansty",
    portContinent: "Europe",
  },
  {
    lat: "56.40898319999999",
    lng: "-5.6695014",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAUC",
    portName: "Auchnacraig",
    portContinent: "Europe",
  },
  {
    lat: "53.473615",
    lng: "-2.125174",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAUD",
    portName: "Audenshaw",
    portContinent: "Europe",
  },
  {
    lat: "55.473388",
    lng: "-4.298566",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAUK",
    portName: "Auchinleck",
    portContinent: "Europe",
  },
  {
    lat: "57.840797",
    lng: "-5.587333",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAUL",
    portName: "Aultbea",
    portContinent: "Europe",
  },
  {
    lat: "53.016626",
    lng: "-1.732179",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAUR",
    portName: "Ashbourne, Derbyshire",
    portContinent: "Europe",
  },
  {
    lat: "52.65568469999999",
    lng: "-1.5655528",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAUS",
    portName: "Austrey",
    portContinent: "Europe",
  },
  {
    lat: "57.566687",
    lng: "-4.174666999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAVC",
    portName: "Avoch",
    portContinent: "Europe",
  },
  {
    lat: "51.499018",
    lng: "0.2519159",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAVE",
    portName: "Aveley",
    portContinent: "Europe",
  },
  {
    lat: "52.351897",
    lng: "-1.96031",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAVH",
    portName: "Alvechurch",
    portContinent: "Europe",
  },
  {
    lat: "52.458424",
    lng: "-2.3556689",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAVL",
    portName: "Alveley",
    portContinent: "Europe",
  },
  {
    lat: "51.5022576",
    lng: "-2.6914364",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAVO",
    portName: "Avonmouth",
    portContinent: "Europe",
  },
  {
    lat: "42.6583356",
    lng: "-71.1367953",
    country: "US",
    country_long: "United States",
    portCode: "GBAVR",
    portName: "Andover",
    portContinent: "Europe",
  },
  {
    lat: "52.4461032",
    lng: "-1.4169835",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAW3",
    portName: "Ansty",
    portContinent: "Europe",
  },
  {
    lat: "51.5119809",
    lng: "-1.1962789",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAW6",
    portName: "Aldworth",
    portContinent: "Europe",
  },
  {
    lat: "50.73136",
    lng: "-4.292116",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAWA",
    portName: "Ashwater",
    portContinent: "Europe",
  },
  {
    lat: "50.886238",
    lng: "-1.268443",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAWC",
    portName: "Swanwick",
    portContinent: "Europe",
  },
  {
    lat: "53.89992489999999",
    lng: "-1.5115257",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAWD",
    portName: "Harewood",
    portContinent: "Europe",
  },
  {
    lat: "51.7229195",
    lng: "0.8947288999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAWE",
    portName: "BradwellonSea",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBAWH",
    portName: "Cawthorne",
    portContinent: "Europe",
  },
  {
    lat: "53.5722088",
    lng: "-1.189055",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAWI",
    portName: "Adwick Le Street, South Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "52.518064",
    lng: "1.015527",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAWL",
    portName: "Attleborough",
    portContinent: "Europe",
  },
  {
    lat: "52.377313",
    lng: "-0.268849",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAWO",
    portName: "AlconburyWeston",
    portContinent: "Europe",
  },
  {
    lat: "52.535068",
    lng: "1.1592579",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAWP",
    portName: "Ashwellthorpe",
    portContinent: "Europe",
  },
  {
    lat: "52.5757236",
    lng: "-0.3528612",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAWR",
    portName: "Ailsworth",
    portContinent: "Europe",
  },
  {
    lat: "52.51638879999999",
    lng: "-3.4287484",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAWS",
    portName: "Caersws",
    portContinent: "Europe",
  },
  {
    lat: "51.2868939",
    lng: "-0.7526149999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAX2",
    portName: "Farnborough",
    portContinent: "Europe",
  },
  {
    lat: "51.28442",
    lng: "-2.819352",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAXB",
    portName: "Axbridge",
    portContinent: "Europe",
  },
  {
    lat: "50.78272699999999",
    lng: "-2.994937",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAXM",
    portName: "Axminster",
    portContinent: "Europe",
  },
  {
    lat: "50.827758",
    lng: "-0.635749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAXN",
    portName: "Barnham",
    portContinent: "Europe",
  },
  {
    lat: "50.71616299999999",
    lng: "-3.0523209",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAXO",
    portName: "Axmouth",
    portContinent: "Europe",
  },
  {
    lat: "54.763442",
    lng: "-3.333129",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAXP",
    portName: "Aspatria",
    portContinent: "Europe",
  },
  {
    lat: "51.9949197",
    lng: "-1.251544",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAY9",
    portName: "Aynho",
    portContinent: "Europe",
  },
  {
    lat: "50.71741249999999",
    lng: "-3.3627941",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAYA",
    portName: "Aylesbeare",
    portContinent: "Europe",
  },
  {
    lat: "53.83461579999999",
    lng: "-1.7130695",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAYB",
    portName: "ApperleyBridge",
    portContinent: "Europe",
  },
  {
    lat: "56.054679",
    lng: "-3.30273",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAYD",
    portName: "Aberdour",
    portContinent: "Europe",
  },
  {
    lat: "51.2251035",
    lng: "1.2008551",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAYE",
    portName: "Aylesham",
    portContinent: "Europe",
  },
  {
    lat: "54.5961775",
    lng: "-1.5630318",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAYF",
    portName: "Aycliffe",
    portContinent: "Europe",
  },
  {
    lat: "52.796672",
    lng: "1.252352",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAYH",
    portName: "Aylsham",
    portContinent: "Europe",
  },
  {
    lat: "51.815606",
    lng: "-0.8084",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAYL",
    portName: "Aylesbury",
    portContinent: "Europe",
  },
  {
    lat: "52.28168489999999",
    lng: "-2.845269",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAYM",
    portName: "Aymestrey",
    portContinent: "Europe",
  },
  {
    lat: "40.9510515",
    lng: "-74.2131284",
    country: "US",
    country_long: "United States",
    portCode: "GBAYN",
    portName: "Haydon",
    portContinent: "Europe",
  },
  {
    lat: "55.458564",
    lng: "-4.629179000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAYR",
    portName: "Ayr",
    portContinent: "Europe",
  },
  {
    lat: "51.304309",
    lng: "0.47806",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAYS",
    portName: "Aylesford",
    portContinent: "Europe",
  },
  {
    lat: "52.5983512",
    lng: "-1.1503472",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAYT",
    portName: "Aylestone",
    portContinent: "Europe",
  },
  {
    lat: "52.41530299999999",
    lng: "-4.08292",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAYW",
    portName: "Aberystwyth",
    portContinent: "Europe",
  },
  {
    lat: "54.578302",
    lng: "-2.489614",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBAZV",
    portName: "Appleby",
    portContinent: "Europe",
  },
  {
    lat: "50.8895432",
    lng: "-1.2654502",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBB39",
    portName: "Burridge",
    portContinent: "Europe",
  },
  {
    lat: "54.108967",
    lng: "-3.218894",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBB4R",
    portName: "Barrow",
    portContinent: "Europe",
  },
  {
    lat: "53.73222209999999",
    lng: "-0.6672549",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBB57",
    portName: "Broomfleet",
    portContinent: "Europe",
  },
  {
    lat: "53.19440299999999",
    lng: "-0.471656",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBB8T",
    portName: "Branston",
    portContinent: "Europe",
  },
  {
    lat: "53.7937996",
    lng: "-1.7563583",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBB9D",
    portName: "Bradford",
    portContinent: "Europe",
  },
  {
    lat: "53.6957522",
    lng: "-2.4682985",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBA5",
    portName: "Blackburn",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBAA",
    portName: "Bala",
    portContinent: "Europe",
  },
  {
    lat: "52.0629009",
    lng: "-1.339775",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAB",
    portName: "Banbury",
    portContinent: "Europe",
  },
  {
    lat: "52.3917812",
    lng: "-1.6502433",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAC",
    portName: "Balsall Common",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBAD",
    portName: "BarryDock",
    portContinent: "Europe",
  },
  {
    lat: "54.75291439999999",
    lng: "-6.001522100000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAE",
    portName: "Ballyclare",
    portContinent: "Europe",
  },
  {
    lat: "53.852041",
    lng: "-2.218286",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAF",
    portName: "Barrowford",
    portContinent: "Europe",
  },
  {
    lat: "52.3722109",
    lng: "-1.493402",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAG",
    portName: "Baginton",
    portContinent: "Europe",
  },
  {
    lat: "54.12313049999999",
    lng: "-114.4019291",
    country: "CA",
    country_long: "Canada",
    portCode: "GBBAH",
    portName: "Barrhead",
    portContinent: "Europe",
  },
  {
    lat: "53.852716",
    lng: "-1.768142",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAI",
    portName: "Baildon",
    portContinent: "Europe",
  },
  {
    lat: "53.91301499999999",
    lng: "-2.186918",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAK",
    portName: "Barnoldswick",
    portContinent: "Europe",
  },
  {
    lat: "51.9895039",
    lng: "-0.189128",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAL",
    portName: "Baldock",
    portContinent: "Europe",
  },
  {
    lat: "40.5637801",
    lng: "-111.9464736",
    country: "US",
    country_long: "United States",
    portCode: "GBBAM",
    portName: "Bingham",
    portContinent: "Europe",
  },
  {
    lat: "44.8016128",
    lng: "-68.7712257",
    country: "US",
    country_long: "United States",
    portCode: "GBBAN",
    portName: "Bangor",
    portContinent: "Europe",
  },
  {
    lat: "52.27048199999999",
    lng: "-1.407135",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAO",
    portName: "Bascote",
    portContinent: "Europe",
  },
  {
    lat: "53.707167",
    lng: "-2.201259",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAP",
    portName: "Bacup",
    portContinent: "Europe",
  },
  {
    lat: "51.4752941",
    lng: "-0.2392052",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAR",
    portName: "Barnes",
    portContinent: "Europe",
  },
  {
    lat: "51.57608399999999",
    lng: "0.488736",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAS",
    portName: "Basildon",
    portContinent: "Europe",
  },
  {
    lat: "51.6228925",
    lng: "0.5705251",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAT",
    portName: "Battlesbridge",
    portContinent: "Europe",
  },
  {
    lat: "51.6187109",
    lng: "-1.5431769",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAU",
    portName: "Baulking",
    portContinent: "Europe",
  },
  {
    lat: "52.165171",
    lng: "-1.852699",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAV",
    portName: "Bidford",
    portContinent: "Europe",
  },
  {
    lat: "51.446649",
    lng: "-2.6145584",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAW",
    portName: "BalticWharf",
    portContinent: "Europe",
  },
  {
    lat: "57.05003",
    lng: "-3.036649",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAX",
    portName: "Ballater",
    portContinent: "Europe",
  },
  {
    lat: "53.717028",
    lng: "-1.635083",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAY",
    portName: "Batley",
    portContinent: "Europe",
  },
  {
    lat: "50.7365929",
    lng: "-1.6632839",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBAZ",
    portName: "BartononSea",
    portContinent: "Europe",
  },
  {
    lat: "52.5121524",
    lng: "-2.2828063",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBB2",
    portName: "Bobbington",
    portContinent: "Europe",
  },
  {
    lat: "52.527666",
    lng: "-1.348303",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBA",
    portName: "Burbage",
    portContinent: "Europe",
  },
  {
    lat: "52.8976283",
    lng: "-1.314868",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBB",
    portName: "Breaston",
    portContinent: "Europe",
  },
  {
    lat: "57.4565187",
    lng: "-7.282038600000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBC",
    portName: "Benbecula",
    portContinent: "Europe",
  },
  {
    lat: "52.6214299",
    lng: "0.528517",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBD",
    portName: "BartonBendish",
    portContinent: "Europe",
  },
  {
    lat: "50.69255039999999",
    lng: "-3.1371728",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBE",
    portName: "Branscombe",
    portContinent: "Europe",
  },
  {
    lat: "54.348729",
    lng: "-6.2704803",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBG",
    portName: "Banbridge",
    portContinent: "Europe",
  },
  {
    lat: "52.971767",
    lng: "-1.2269189",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBH",
    portName: "Bilborough",
    portContinent: "Europe",
  },
  {
    lat: "51.0697365",
    lng: "-2.918264",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBI",
    portName: "Burrowbridge",
    portContinent: "Europe",
  },
  {
    lat: "50.704953",
    lng: "-2.726856",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBK",
    portName: "BurtonBradstock",
    portContinent: "Europe",
  },
  {
    lat: "57.483411",
    lng: "-4.460351",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBL",
    portName: "Beauly",
    portContinent: "Europe",
  },
  {
    lat: "53.930516",
    lng: "-0.817979",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBM",
    portName: "BarmbyMoor",
    portContinent: "Europe",
  },
  {
    lat: "53.6083194",
    lng: "-6.182079799999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBBBN",
    portName: "Balbriggan",
    portContinent: "Europe",
  },
  {
    lat: "50.69223059999999",
    lng: "-1.0993077",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBP",
    portName: "Bembridge Harbour",
    portContinent: "Europe",
  },
  {
    lat: "54.0942662",
    lng: "-1.3953847",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBR",
    portName: "Boroughbridge",
    portContinent: "Europe",
  },
  {
    lat: "53.52758979999999",
    lng: "-6.527255599999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBBBS",
    portName: "Blackbush",
    portContinent: "Europe",
  },
  {
    lat: "54.4905516",
    lng: "-3.589586699999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBT",
    portName: "SaintBees",
    portContinent: "Europe",
  },
  {
    lat: "53.423581",
    lng: "-2.113764",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBU",
    portName: "Bredbury",
    portContinent: "Europe",
  },
  {
    lat: "50.9650791",
    lng: "-4.2523219",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBW",
    portName: "BucklandBrewer",
    portContinent: "Europe",
  },
  {
    lat: "52.91140499999999",
    lng: "-0.909379",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBX",
    portName: "Barnstone",
    portContinent: "Europe",
  },
  {
    lat: "53.4403569",
    lng: "-0.1920159",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBBY",
    portName: "Brookenby",
    portContinent: "Europe",
  },
  {
    lat: "54.545284",
    lng: "-1.923741",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCA",
    portName: "BarnardCastle",
    portContinent: "Europe",
  },
  {
    lat: "50.96570879999999",
    lng: "0.166323",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCB",
    portName: "Blackboys",
    portContinent: "Europe",
  },
  {
    lat: "52.459333",
    lng: "1.566053",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCC",
    portName: "Beccles",
    portContinent: "Europe",
  },
  {
    lat: "-23.5559218",
    lng: "145.2882741",
    country: "AU",
    country_long: "Australia",
    portCode: "GBBCE",
    portName: "Barcaldine",
    portContinent: "Europe",
  },
  {
    lat: "52.755895",
    lng: "-1.5167638",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCF",
    portName: "Blackfordby",
    portContinent: "Europe",
  },
  {
    lat: "51.8545885",
    lng: "-1.2717642",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCG",
    portName: "Bletchingdon",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBCH",
    portName: "Bruichladdich",
    portContinent: "Europe",
  },
  {
    lat: "51.373347",
    lng: "1.306532",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCI",
    portName: "Birchington",
    portContinent: "Europe",
  },
  {
    lat: "52.027411",
    lng: "-1.143152",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCK",
    portName: "Brackley",
    portContinent: "Europe",
  },
  {
    lat: "47.16315729999999",
    lng: "-122.0267787",
    country: "US",
    country_long: "United States",
    portCode: "GBBCL",
    portName: "Buckley",
    portContinent: "Europe",
  },
  {
    lat: "51.05856300000001",
    lng: "-0.12794",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCM",
    portName: "Balcombe",
    portContinent: "Europe",
  },
  {
    lat: "51.9489469",
    lng: "-3.391463",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCN",
    portName: "Brecon",
    portContinent: "Europe",
  },
  {
    lat: "53.5873599",
    lng: "-2.57864",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCO",
    portName: "Blackrod",
    portContinent: "Europe",
  },
  {
    lat: "52.0792779",
    lng: "-2.0279759",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCP",
    portName: "Bricklehampton",
    portContinent: "Europe",
  },
  {
    lat: "50.980892",
    lng: "-4.3533289",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCR",
    portName: "Buck'sCross",
    portContinent: "Europe",
  },
  {
    lat: "52.965483",
    lng: "0.674546",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCS",
    portName: "BrancasterStaithe",
    portContinent: "Europe",
  },
  {
    lat: "51.5156376",
    lng: "0.0799792",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCT",
    portName: "Beckton",
    portContinent: "Europe",
  },
  {
    lat: "56.6739269",
    lng: "-5.133719999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCU",
    portName: "Ballachulish",
    portContinent: "Europe",
  },
  {
    lat: "53.3784821",
    lng: "-1.965927",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCV",
    portName: "BirchVale",
    portContinent: "Europe",
  },
  {
    lat: "53.098458",
    lng: "-2.1114289",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBCW",
    portName: "BlackwoodHill",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBCY",
    portName: "Barton In The Clay",
    portContinent: "Europe",
  },
  {
    lat: "50.8582682",
    lng: "-1.0001658",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBD2",
    portName: "Bedhampton",
    portContinent: "Europe",
  },
  {
    lat: "53.1788254",
    lng: "-4.4166384",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBD9",
    portName: "Bodorgan",
    portContinent: "Europe",
  },
  {
    lat: "35.4392926",
    lng: "-80.8858179",
    country: "US",
    country_long: "United States",
    portCode: "GBBDA",
    portName: "Birkdale",
    portContinent: "Europe",
  },
  {
    lat: "53.5108234",
    lng: "-0.1389532",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDB",
    portName: "BarnoldbyleBeck",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBDC",
    portName: "BordonCamp",
    portContinent: "Europe",
  },
  {
    lat: "51.3692339",
    lng: "-0.1270687",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDD",
    portName: "Beddington",
    portContinent: "Europe",
  },
  {
    lat: "51.115068",
    lng: "0.643338",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDE",
    portName: "Biddenden",
    portContinent: "Europe",
  },
  {
    lat: "57.24139169999999",
    lng: "-5.9096591",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDF",
    portName: "Broadford,IsleofSkye",
    portContinent: "Europe",
  },
  {
    lat: "51.208953",
    lng: "-2.938522",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDG",
    portName: "BasonBridge",
    portContinent: "Europe",
  },
  {
    lat: "53.097907",
    lng: "-1.115293",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDH",
    portName: "Blidworth",
    portContinent: "Europe",
  },
  {
    lat: "55.57511100000001",
    lng: "-5.1451992",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDI",
    portName: "Brodick,IsleofArran",
    portContinent: "Europe",
  },
  {
    lat: "57.1102801",
    lng: "-2.1546215",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDK",
    portName: "Banchory-Devenick",
    portContinent: "Europe",
  },
  {
    lat: "54.2887008",
    lng: "-1.5933179",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDL",
    portName: "Bedale",
    portContinent: "Europe",
  },
  {
    lat: "50.4714961",
    lng: "-4.7188629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDM",
    portName: "Bodmin",
    portContinent: "Europe",
  },
  {
    lat: "54.94315599999999",
    lng: "-1.422718",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDN",
    portName: "Boldon",
    portContinent: "Europe",
  },
  {
    lat: "53.4075644",
    lng: "-3.071427",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDO",
    portName: "Bidston",
    portContinent: "Europe",
  },
  {
    lat: "51.8270733",
    lng: "-2.1062565",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDP",
    portName: "Birdlip",
    portContinent: "Europe",
  },
  {
    lat: "51.517796",
    lng: "-3.399464",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDR",
    portName: "Brynsadler",
    portContinent: "Europe",
  },
  {
    lat: "57.1124",
    lng: "-2.197616",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDS",
    portName: "Bieldside",
    portContinent: "Europe",
  },
  {
    lat: "51.360163",
    lng: "1.432038",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDT",
    portName: "Broadstairs",
    portContinent: "Europe",
  },
  {
    lat: "53.119197",
    lng: "-2.171608",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDU",
    portName: "Biddulph",
    portContinent: "Europe",
  },
  {
    lat: "51.592822",
    lng: "-3.199781",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDW",
    portName: "Bedwas",
    portContinent: "Europe",
  },
  {
    lat: "53.05629099999999",
    lng: "-0.774821",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDX",
    portName: "Baldirton",
    portContinent: "Europe",
  },
  {
    lat: "51.874439",
    lng: "-5.112934999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDY",
    portName: "Brawdy",
    portContinent: "Europe",
  },
  {
    lat: "54.162543",
    lng: "-3.073122",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBDZ",
    portName: "Bardsea",
    portContinent: "Europe",
  },
  {
    lat: "53.818313",
    lng: "-0.9162102999999998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBE2",
    portName: "Bubwith",
    portContinent: "Europe",
  },
  {
    lat: "51.602396",
    lng: "-0.6442409",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEA",
    portName: "Beaconsfield",
    portContinent: "Europe",
  },
  {
    lat: "53.3530146",
    lng: "-3.0075315",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEB",
    portName: "Bebington",
    portContinent: "Europe",
  },
  {
    lat: "53.400091",
    lng: "-0.826004",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEC",
    portName: "Beckingham",
    portContinent: "Europe",
  },
  {
    lat: "51.454446",
    lng: "-0.432817",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBED",
    portName: "Bedfont",
    portContinent: "Europe",
  },
  {
    lat: "52.92392",
    lng: "-1.212394",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEE",
    portName: "Beeston",
    portContinent: "Europe",
  },
  {
    lat: "52.1386394",
    lng: "-0.4667782",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEF",
    portName: "Bedford",
    portContinent: "Europe",
  },
  {
    lat: "52.476726",
    lng: "-1.84427",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEG",
    portName: "BordesleyGreen",
    portContinent: "Europe",
  },
  {
    lat: "50.402047",
    lng: "-3.490313",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEH",
    portName: "BerryHead",
    portContinent: "Europe",
  },
  {
    lat: "55.753797",
    lng: "-4.631787",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEI",
    portName: "Beith",
    portContinent: "Europe",
  },
  {
    lat: "54.447505",
    lng: "-3.516837",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEK",
    portName: "Beckermet",
    portContinent: "Europe",
  },
  {
    lat: "54.59728500000001",
    lng: "-5.93012",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEL",
    portName: "Belfast",
    portContinent: "Europe",
  },
  {
    lat: "53.04573019999999",
    lng: "-2.1967011",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEM",
    portName: "Burslem",
    portContinent: "Europe",
  },
  {
    lat: "31.0560132",
    lng: "-97.46445299999999",
    country: "US",
    country_long: "United States",
    portCode: "GBBEN",
    portName: "Belton",
    portContinent: "Europe",
  },
  {
    lat: "56.52272",
    lng: "-3.299728",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEO",
    portName: "Burrelton",
    portContinent: "Europe",
  },
  {
    lat: "51.661745",
    lng: "-1.1707",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEP",
    portName: "Berinsfield, Oxfordshire",
    portContinent: "Europe",
  },
  {
    lat: "57.0064696",
    lng: "-3.3979439",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEQ",
    portName: "Braemar",
    portContinent: "Europe",
  },
  {
    lat: "51.2744349",
    lng: "0.5789560000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBER",
    portName: "Bearsted",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBES",
    portName: "Benson",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBET",
    portName: "Bethesda",
    portContinent: "Europe",
  },
  {
    lat: "51.973881",
    lng: "0.777277",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEU",
    portName: "Bures",
    portContinent: "Europe",
  },
  {
    lat: "53.841963",
    lng: "-0.435093",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEV",
    portName: "Beverley",
    portContinent: "Europe",
  },
  {
    lat: "50.775016",
    lng: "-4.184284",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEW",
    portName: "Beaworthy",
    portContinent: "Europe",
  },
  {
    lat: "51.439933",
    lng: "0.154327",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEX",
    portName: "Bexley",
    portContinent: "Europe",
  },
  {
    lat: "43.2278531",
    lng: "-72.9387126",
    country: "US",
    country_long: "United States",
    portCode: "GBBEY",
    portName: "Bromley",
    portContinent: "Europe",
  },
  {
    lat: "52.2432515",
    lng: "-1.7343381",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBEZ",
    portName: "Bearley",
    portContinent: "Europe",
  },
  {
    lat: "50.8515808",
    lng: "-2.1637323",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBFA",
    portName: "BlandfordSaintMary",
    portContinent: "Europe",
  },
  {
    lat: "57.504633",
    lng: "-2.4858285",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBFB",
    portName: "Braefoot (GBBFB), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.39867839999999",
    lng: "-1.0568791",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBFC",
    portName: "BurghfieldCommon",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBFD",
    portName: "Brentford",
    portContinent: "Europe",
  },
  {
    lat: "33.0390099",
    lng: "-86.908879",
    country: "US",
    country_long: "United States",
    portCode: "GBBFE",
    portName: "Brierfield",
    portContinent: "Europe",
  },
  {
    lat: "51.17839720000001",
    lng: "-115.5708073",
    country: "CA",
    country_long: "Canada",
    portCode: "GBBFF",
    portName: "Banff",
    portContinent: "Europe",
  },
  {
    lat: "50.481799",
    lng: "-3.779342",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBFG",
    portName: "Buckfastleigh",
    portContinent: "Europe",
  },
  {
    lat: "52.310427",
    lng: "1.5196399",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBFH",
    portName: "Bramfield",
    portContinent: "Europe",
  },
  {
    lat: "51.43148799999999",
    lng: "-0.7880769999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBFI",
    portName: "Binfield",
    portContinent: "Europe",
  },
  {
    lat: "51.661745",
    lng: "-1.1707",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBFL",
    portName: "berinsfield",
    portContinent: "Europe",
  },
  {
    lat: "52.1330363",
    lng: "-2.4957753",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBFM",
    portName: "BishopsFrome",
    portContinent: "Europe",
  },
  {
    lat: "56.071822",
    lng: "-4.3373039",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBFN",
    portName: "Balfron",
    portContinent: "Europe",
  },
  {
    lat: "53.265662",
    lng: "-4.359899",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBFO",
    portName: "Bodffordd",
    portContinent: "Europe",
  },
  {
    lat: "40.49927840000001",
    lng: "-85.31362179999999",
    country: "US",
    country_long: "United States",
    portCode: "GBBFP",
    portName: "Blackford",
    portContinent: "Europe",
  },
  {
    lat: "40.49927840000001",
    lng: "-85.31362179999999",
    country: "US",
    country_long: "United States",
    portCode: "GBBFR",
    portName: "Blackford",
    portContinent: "Europe",
  },
  {
    lat: "54.277654",
    lng: "-3.2112701",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBFU",
    portName: "BroughtonInFurness",
    portContinent: "Europe",
  },
  {
    lat: "52.1386394",
    lng: "-0.4667782",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBFX",
    portName: "Bedford, Bedfordshire",
    portContinent: "Europe",
  },
  {
    lat: "51.632119",
    lng: "-3.824315",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBFY",
    portName: "BritonFerry",
    portContinent: "Europe",
  },
  {
    lat: "51.9084535",
    lng: "0.0327857",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBG4",
    portName: "Braughing",
    portContinent: "Europe",
  },
  {
    lat: "51.071737",
    lng: "-0.358067",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBG6",
    portName: "BroadbridgeHeath",
    portContinent: "Europe",
  },
  {
    lat: "52.458326",
    lng: "-0.608571",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBG9",
    portName: "Brigstock",
    portContinent: "Europe",
  },
  {
    lat: "56.153149",
    lng: "-3.942207999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGA",
    portName: "BridgeofAllan",
    portContinent: "Europe",
  },
  {
    lat: "51.504286",
    lng: "-3.576945",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGD",
    portName: "Bridgend",
    portContinent: "Europe",
  },
  {
    lat: "51.0326404",
    lng: "-0.3939276",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGE",
    portName: "BarnsGreen",
    portContinent: "Europe",
  },
  {
    lat: "57.1887443",
    lng: "-2.1035776",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGF",
    portName: "Bridge of Don",
    portContinent: "Europe",
  },
  {
    lat: "53.55184999999999",
    lng: "-0.491887",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGG",
    portName: "Brigg",
    portContinent: "Europe",
  },
  {
    lat: "53.591359",
    lng: "-2.852196",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGH",
    portName: "Burscough",
    portContinent: "Europe",
  },
  {
    lat: "50.75387",
    lng: "-2.21974",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGI",
    portName: "BereRegis",
    portContinent: "Europe",
  },
  {
    lat: "51.26654",
    lng: "-1.0923964",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGK",
    portName: "Basingstoke",
    portContinent: "Europe",
  },
  {
    lat: "53.267912",
    lng: "-3.174352",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGL",
    portName: "Bagillt",
    portContinent: "Europe",
  },
  {
    lat: "51.2913349",
    lng: "0.305187",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGN",
    portName: "Borough Green",
    portContinent: "Europe",
  },
  {
    lat: "51.684912",
    lng: "-3.229699",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGO",
    portName: "Bargoed",
    portContinent: "Europe",
  },
  {
    lat: "51.733573",
    lng: "-1.66066",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGP",
    portName: "BroughtonPoggs",
    portContinent: "Europe",
  },
  {
    lat: "50.1075875",
    lng: "-5.333853599999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGQ",
    portName: "Breage",
    portContinent: "Europe",
  },
  {
    lat: "51.833318",
    lng: "-0.167917",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGR",
    portName: "BurnhamGreen",
    portContinent: "Europe",
  },
  {
    lat: "51.360343",
    lng: "-0.697894",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGS",
    portName: "Bagshot",
    portContinent: "Europe",
  },
  {
    lat: "51.022909",
    lng: "-0.4514619999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGT",
    portName: "Billingshurst",
    portContinent: "Europe",
  },
  {
    lat: "50.7990322",
    lng: "-4.4549877",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGU",
    portName: "Bridgerule",
    portContinent: "Europe",
  },
  {
    lat: "52.335589",
    lng: "-2.061906",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGV",
    portName: "Bromsgrove",
    portContinent: "Europe",
  },
  {
    lat: "54.50408179999999",
    lng: "-5.7946279",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGW",
    portName: "Ballygowan",
    portContinent: "Europe",
  },
  {
    lat: "55.623396",
    lng: "-3.523964",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGX",
    portName: "Biggar",
    portContinent: "Europe",
  },
  {
    lat: "53.847389",
    lng: "-1.833781",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBGY",
    portName: "Bingley",
    portContinent: "Europe",
  },
  {
    lat: "51.129299",
    lng: "0.754022",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBH2",
    portName: "Bethersden",
    portContinent: "Europe",
  },
  {
    lat: "53.163494",
    lng: "-2.997219",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBH9",
    portName: "Broughton",
    portContinent: "Europe",
  },
  {
    lat: "54.212219",
    lng: "-2.771233",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHA",
    portName: "Beetham",
    portContinent: "Europe",
  },
  {
    lat: "54.49597010000001",
    lng: "-5.4615601",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHB",
    portName: "Ballyhalbert",
    portContinent: "Europe",
  },
  {
    lat: "53.32186100000001",
    lng: "-4.225891",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHC",
    portName: "Benllech",
    portContinent: "Europe",
  },
  {
    lat: "54.6176164",
    lng: "-5.871830399999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHD",
    portName: "GeorgeBestBelfastCityApt/Belfast",
    portContinent: "Europe",
  },
  {
    lat: "52.4554949",
    lng: "-1.8883211",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHE",
    portName: "BalsallHeath",
    portContinent: "Europe",
  },
  {
    lat: "28.478395",
    lng: "-81.45528399999999",
    country: "US",
    country_long: "United States",
    portCode: "GBBHF",
    portName: "Braishfield",
    portContinent: "Europe",
  },
  {
    lat: "54.80824579999999",
    lng: "-6.518924699999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHG",
    portName: "Bellaghy",
    portContinent: "Europe",
  },
  {
    lat: "51.627572",
    lng: "0.034513",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHH",
    portName: "BuckhurstHill",
    portContinent: "Europe",
  },
  {
    lat: "52.7144722",
    lng: "-1.3206111",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHI",
    portName: "BardonHill",
    portContinent: "Europe",
  },
  {
    lat: "52.8968",
    lng: "-1.9098904",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHJ",
    portName: "Bramshall",
    portContinent: "Europe",
  },
  {
    lat: "53.3321915",
    lng: "-2.9772937",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHK",
    portName: "Bromborough",
    portContinent: "Europe",
  },
  {
    lat: "43.6537403",
    lng: "-70.2713605",
    country: "US",
    country_long: "United States",
    portCode: "GBBHL",
    portName: "Bramhall",
    portContinent: "Europe",
  },
  {
    lat: "33.5185892",
    lng: "-86.8103567",
    country: "US",
    country_long: "United States",
    portCode: "GBBHM",
    portName: "Birmingham",
    portContinent: "Europe",
  },
  {
    lat: "54.065748",
    lng: "-1.600128",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHN",
    portName: "BishopThornton",
    portContinent: "Europe",
  },
  {
    lat: "52.28399899999999",
    lng: "-0.898924",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHO",
    portName: "Boughton",
    portContinent: "Europe",
  },
  {
    lat: "51.420481",
    lng: "-1.868855",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHP",
    portName: "Beckhampton",
    portContinent: "Europe",
  },
  {
    lat: "53.693394",
    lng: "-0.392823",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHR",
    portName: "Barrow Haven (GBBHR), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6049673",
    lng: "-0.2076295",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHS",
    portName: "Barnet",
    portContinent: "Europe",
  },
  {
    lat: "51.4658393",
    lng: "0.0090338",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHT",
    portName: "Blackheath/London",
    portContinent: "Europe",
  },
  {
    lat: "51.3308375",
    lng: "0.5759411999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHU",
    portName: "Bredhurst",
    portContinent: "Europe",
  },
  {
    lat: "56.17450100000001",
    lng: "-3.030789",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHV",
    portName: "Buckhaven",
    portContinent: "Europe",
  },
  {
    lat: "54.612537",
    lng: "-1.290961",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHW",
    portName: "Billingham",
    portContinent: "Europe",
  },
  {
    lat: "51.6685469",
    lng: "-0.8030480999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHX",
    portName: "Bradenham",
    portContinent: "Europe",
  },
  {
    lat: "53.080883",
    lng: "-0.281111",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBHY",
    portName: "Billinghay",
    portContinent: "Europe",
  },
  {
    lat: "51.6921659",
    lng: "0.5836884",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIA",
    portName: "Bicknacre",
    portContinent: "Europe",
  },
  {
    lat: "43.1208326",
    lng: "-79.80449569999999",
    country: "CA",
    country_long: "Canada",
    portCode: "GBBIB",
    portName: "Binbrook",
    portContinent: "Europe",
  },
  {
    lat: "51.8999993",
    lng: "-1.1531524",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIC",
    portName: "Bicester",
    portContinent: "Europe",
  },
  {
    lat: "51.016684",
    lng: "-4.206666",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBID",
    portName: "Bideford",
    portContinent: "Europe",
  },
  {
    lat: "54.8980589",
    lng: "-1.5774599",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIE",
    portName: "Birtley",
    portContinent: "Europe",
  },
  {
    lat: "54.108967",
    lng: "-3.218894",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIF",
    portName: "Barrow In Furness",
    portContinent: "Europe",
  },
  {
    lat: "52.677507",
    lng: "-2.029116",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIG",
    portName: "Bridgtown",
    portContinent: "Europe",
  },
  {
    lat: "52.439758",
    lng: "-1.7282776",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIH",
    portName: "Bickenhill",
    portContinent: "Europe",
  },
  {
    lat: "51.8209023",
    lng: "-1.0518395",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBII",
    portName: "Brill",
    portContinent: "Europe",
  },
  {
    lat: "52.854035",
    lng: "1.061784",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIK",
    portName: "Briston",
    portContinent: "Europe",
  },
  {
    lat: "51.627903",
    lng: "0.418397",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIL",
    portName: "Billericay",
    portContinent: "Europe",
  },
  {
    lat: "50.793857",
    lng: "-0.825626",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIM",
    portName: "Birdham",
    portContinent: "Europe",
  },
  {
    lat: "56.16411799999999",
    lng: "-3.328356",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIN",
    portName: "Ballingry",
    portContinent: "Europe",
  },
  {
    lat: "24.9248678",
    lng: "-80.6270287",
    country: "US",
    country_long: "United States",
    portCode: "GBBIO",
    portName: "Bitton",
    portContinent: "Europe",
  },
  {
    lat: "54.663822",
    lng: "-1.678788",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIP",
    portName: "Bishop Auckland",
    portContinent: "Europe",
  },
  {
    lat: "54.8980589",
    lng: "-1.5774599",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIR",
    portName: "Birtley",
    portContinent: "Europe",
  },
  {
    lat: "52.56559499999999",
    lng: "-2.074088",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIS",
    portName: "Bilston",
    portContinent: "Europe",
  },
  {
    lat: "53.7349864",
    lng: "-1.6630839",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIT",
    portName: "Birstall",
    portContinent: "Europe",
  },
  {
    lat: "52.165171",
    lng: "-1.852699",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIV",
    portName: "Bidford On Avon, Warwickshire",
    portContinent: "Europe",
  },
  {
    lat: "52.086938",
    lng: "-0.26422",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBIW",
    portName: "Biggleswade",
    portContinent: "Europe",
  },
  {
    lat: "35.7726003",
    lng: "-86.8926139",
    country: "US",
    country_long: "United States",
    portCode: "GBBIX",
    portName: "Brixworth",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBIY",
    portName: "Bisley",
    portContinent: "Europe",
  },
  {
    lat: "52.0721515",
    lng: "-2.6318021",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBJT",
    portName: "Bartestree",
    portContinent: "Europe",
  },
  {
    lat: "52.018316",
    lng: "-2.037801",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBK2",
    portName: "Beckford",
    portContinent: "Europe",
  },
  {
    lat: "51.2652812",
    lng: "-2.2870732",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBK4",
    portName: "Beckington",
    portContinent: "Europe",
  },
  {
    lat: "51.4658393",
    lng: "0.0090338",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBK5",
    portName: "Blackheath",
    portContinent: "Europe",
  },
  {
    lat: "52.960766",
    lng: "-0.620235",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBK7",
    portName: "Barkston",
    portContinent: "Europe",
  },
  {
    lat: "53.677062",
    lng: "-1.918126",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBK9",
    portName: "Barkisland",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBKA",
    portName: "Buckfast",
    portContinent: "Europe",
  },
  {
    lat: "56.091722",
    lng: "-3.908402",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKB",
    portName: "Bannockburn",
    portContinent: "Europe",
  },
  {
    lat: "59.15605739999999",
    lng: "-2.7601985",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKC",
    portName: "Backaland (Eday) (GBBKC), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2938389",
    lng: "-0.2544088",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKD",
    portName: "Buckden",
    portContinent: "Europe",
  },
  {
    lat: "37.8715226",
    lng: "-122.273042",
    country: "US",
    country_long: "United States",
    portCode: "GBBKE",
    portName: "Berkeley",
    portContinent: "Europe",
  },
  {
    lat: "54.4801114",
    lng: "-8.0914889",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKF",
    portName: "Belleek",
    portContinent: "Europe",
  },
  {
    lat: "51.536563",
    lng: "0.075766",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKG",
    portName: "Barking/London",
    portContinent: "Europe",
  },
  {
    lat: "51.75976600000001",
    lng: "-0.5678569000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKH",
    portName: "Berkhamsted",
    portContinent: "Europe",
  },
  {
    lat: "54.74609299999999",
    lng: "-1.288406",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKI",
    portName: "BlackhallColliery",
    portContinent: "Europe",
  },
  {
    lat: "57.2439753",
    lng: "-5.843908400000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKK",
    portName: "Breakish",
    portContinent: "Europe",
  },
  {
    lat: "-32.0015443",
    lng: "116.0992856",
    country: "AU",
    country_long: "Australia",
    portCode: "GBBKL",
    portName: "Bickley",
    portContinent: "Europe",
  },
  {
    lat: "51.40817",
    lng: "-0.025813",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKM",
    portName: "Beckenham",
    portContinent: "Europe",
  },
  {
    lat: "51.41419819999999",
    lng: "-0.752517",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKN",
    portName: "Bracknell",
    portContinent: "Europe",
  },
  {
    lat: "55.04432",
    lng: "-7.0203601",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKO",
    portName: "BallyKelly",
    portContinent: "Europe",
  },
  {
    lat: "51.71817",
    lng: "-0.199498",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKP",
    portName: "BrookmansPark",
    portContinent: "Europe",
  },
  {
    lat: "52.923523",
    lng: "-0.184278",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKQ",
    portName: "Bicker",
    portContinent: "Europe",
  },
  {
    lat: "51.846509",
    lng: "-2.1608329",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKR",
    portName: "Brockworth",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBKS",
    portName: "Blackness",
    portContinent: "Europe",
  },
  {
    lat: "50.818863",
    lng: "-1.575726",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKT",
    portName: "Brockenhurst",
    portContinent: "Europe",
  },
  {
    lat: "53.215207",
    lng: "-1.676171",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKW",
    portName: "Bakewell",
    portContinent: "Europe",
  },
  {
    lat: "51.948206",
    lng: "0.53021",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKX",
    portName: "BlackmoreEnd",
    portContinent: "Europe",
  },
  {
    lat: "52.014696",
    lng: "-1.763129",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBKY",
    portName: "Blockley",
    portContinent: "Europe",
  },
  {
    lat: "40.49927840000001",
    lng: "-85.31362179999999",
    country: "US",
    country_long: "United States",
    portCode: "GBBLA",
    portName: "Blackford",
    portContinent: "Europe",
  },
  {
    lat: "53.6957522",
    lng: "-2.4682985",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLB",
    portName: "Blackburn",
    portContinent: "Europe",
  },
  {
    lat: "52.953685",
    lng: "1.014356",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLC",
    portName: "Blakeney",
    portContinent: "Europe",
  },
  {
    lat: "54.96122339999999",
    lng: "-1.7181963",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLD",
    portName: "Blaydon",
    portContinent: "Europe",
  },
  {
    lat: "52.002175",
    lng: "-0.7501369999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLE",
    portName: "Bletchley",
    portContinent: "Europe",
  },
  {
    lat: "50.856908",
    lng: "-2.165353",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLF",
    portName: "Blandford Forum",
    portContinent: "Europe",
  },
  {
    lat: "56.512435",
    lng: "-6.175886999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLG",
    portName: "Ballygown",
    portContinent: "Europe",
  },
  {
    lat: "53.4638208",
    lng: "-9.882709",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBBLH",
    portName: "Ballynahinch",
    portContinent: "Europe",
  },
  {
    lat: "57.75613200000001",
    lng: "-3.90923",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLI",
    portName: "Balintore",
    portContinent: "Europe",
  },
  {
    lat: "49.6102662",
    lng: "-114.4511912",
    country: "CA",
    country_long: "Canada",
    portCode: "GBBLJ",
    portName: "Blairmore",
    portContinent: "Europe",
  },
  {
    lat: "53.81674229999999",
    lng: "-3.0370485",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLK",
    portName: "Blackpool",
    portContinent: "Europe",
  },
  {
    lat: "55.816761",
    lng: "-4.026536",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLL",
    portName: "Bellshill",
    portContinent: "Europe",
  },
  {
    lat: "55.0704888",
    lng: "-6.5173708",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLM",
    portName: "Ballymoney",
    portContinent: "Europe",
  },
  {
    lat: "51.240586",
    lng: "-0.099162",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLN",
    portName: "Bletchingley",
    portContinent: "Europe",
  },
  {
    lat: "55.885715",
    lng: "-3.342351",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLO",
    portName: "Balerno",
    portContinent: "Europe",
  },
  {
    lat: "53.0243899",
    lng: "-1.477616",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLP",
    portName: "Belper",
    portContinent: "Europe",
  },
  {
    lat: "31.0560132",
    lng: "-97.46445299999999",
    country: "US",
    country_long: "United States",
    portCode: "GBBLQ",
    portName: "Belton",
    portContinent: "Europe",
  },
  {
    lat: "54.84561",
    lng: "-5.780830099999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLR",
    portName: "Ballylumford",
    portContinent: "Europe",
  },
  {
    lat: "51.816142",
    lng: "1.021399",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLS",
    portName: "Brightlingsea (GBBLS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "44.3726476",
    lng: "-72.87876250000001",
    country: "US",
    country_long: "United States",
    portCode: "GBBLT",
    portName: "Bolton",
    portContinent: "Europe",
  },
  {
    lat: "50.8156265",
    lng: "-1.4531819",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLU",
    portName: "Beaulieu Harbour",
    portContinent: "Europe",
  },
  {
    lat: "48.19147510000001",
    lng: "16.3809322",
    country: "AT",
    country_long: "Austria",
    portCode: "GBBLV",
    portName: "Belvedere",
    portContinent: "Europe",
  },
  {
    lat: "51.668935",
    lng: "-3.195787",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLW",
    portName: "Blackwood",
    portContinent: "Europe",
  },
  {
    lat: "52.617397",
    lng: "-2.0043417",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLX",
    portName: "Bloxwich",
    portContinent: "Europe",
  },
  {
    lat: "55.126957",
    lng: "-1.510277",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLY",
    portName: "Blyth (GBBLY), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.126957",
    lng: "-1.510277",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLYÂ",
    portName: "BlythNorthumberland",
    portContinent: "Europe",
  },
  {
    lat: "53.290083",
    lng: "-1.28749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBLZ",
    portName: "Barlborough",
    portContinent: "Europe",
  },
  {
    lat: "57.03966459999999",
    lng: "-3.2292362",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBM9",
    portName: "Balmoral",
    portContinent: "Europe",
  },
  {
    lat: "51.240139",
    lng: "-2.993858",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMA",
    portName: "Burnham-on-Sea",
    portContinent: "Europe",
  },
  {
    lat: "53.7282629",
    lng: "-2.6606344",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMB",
    portName: "Bamberbridge",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBMBÂ",
    portName: "Bamber",
    portContinent: "Europe",
  },
  {
    lat: "57.284104",
    lng: "-5.638712",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMC",
    portName: "Balmacara",
    portContinent: "Europe",
  },
  {
    lat: "51.657728",
    lng: "-0.272308",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMD",
    portName: "Borehamwood",
    portContinent: "Europe",
  },
  {
    lat: "51.49226549999999",
    lng: "-0.0652005",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBME",
    portName: "Bermondsey/London",
    portContinent: "Europe",
  },
  {
    lat: "52.310427",
    lng: "1.5196399",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMF",
    portName: "Bramfield",
    portContinent: "Europe",
  },
  {
    lat: "53.883033",
    lng: "-1.610585",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMH",
    portName: "Bramhope",
    portContinent: "Europe",
  },
  {
    lat: "52.22259",
    lng: "-0.8647788",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMI",
    portName: "Brackmills",
    portContinent: "Europe",
  },
  {
    lat: "52.94592979999999",
    lng: "0.730548",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMK",
    portName: "BurnhamMarket",
    portContinent: "Europe",
  },
  {
    lat: "51.1948099",
    lng: "-0.55903",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBML",
    portName: "Bramley",
    portContinent: "Europe",
  },
  {
    lat: "52.1511384",
    lng: "-0.5260199999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMM",
    portName: "Bromham",
    portContinent: "Europe",
  },
  {
    lat: "50.810208",
    lng: "-2.743195",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMN",
    portName: "Beaminster",
    portContinent: "Europe",
  },
  {
    lat: "55.8405879",
    lng: "-2.075792",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMO",
    portName: "Burnmouth",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBMP",
    portName: "Brompton",
    portContinent: "Europe",
  },
  {
    lat: "53.265865",
    lng: "-4.09199",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMR",
    portName: "Beaumaris",
    portContinent: "Europe",
  },
  {
    lat: "55.2028537",
    lng: "-6.5172267",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMS",
    portName: "Bushmills",
    portContinent: "Europe",
  },
  {
    lat: "55.378051",
    lng: "-3.435973",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMT",
    portName: "Belmont (GBBMT), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.722873",
    lng: "-4.0560919",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMU",
    portName: "Barmouth",
    portContinent: "Europe",
  },
  {
    lat: "51.795089",
    lng: "-3.175561",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMW",
    portName: "Brynmawr",
    portContinent: "Europe",
  },
  {
    lat: "55.422213",
    lng: "-1.582111",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMX",
    portName: "Boulmer",
    portContinent: "Europe",
  },
  {
    lat: "51.1948099",
    lng: "-0.55903",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBMY",
    portName: "Bramley",
    portContinent: "Europe",
  },
  {
    lat: "51.009905",
    lng: "0.853946",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBN5",
    portName: "Brenzett",
    portContinent: "Europe",
  },
  {
    lat: "51.538891",
    lng: "-3.463139",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBNA",
    portName: "Brynna",
    portContinent: "Europe",
  },
  {
    lat: "55.883335",
    lng: "-6.130126",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBNB",
    portName: "Bunnahabhain",
    portContinent: "Europe",
  },
  {
    lat: "57.053856",
    lng: "-2.49096",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBNC",
    portName: "Banchory",
    portContinent: "Europe",
  },
  {
    lat: "51.0781599",
    lng: "-4.058338",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBND",
    portName: "Barnstaple",
    portContinent: "Europe",
  },
  {
    lat: "51.90099",
    lng: "0.405346",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBNE",
    portName: "Bran End",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBNF",
    portName: "Benfield",
    portContinent: "Europe",
  },
  {
    lat: "44.8016128",
    lng: "-68.7712257",
    country: "US",
    country_long: "United States",
    portCode: "GBBNG",
    portName: "Bangor",
    portContinent: "Europe",
  },
  {
    lat: "53.686675",
    lng: "-0.443368",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBNH",
    portName: "Barton Upon Humber",
    portContinent: "Europe",
  },
  {
    lat: "36.1823602",
    lng: "-80.3381077",
    country: "US",
    country_long: "United States",
    portCode: "GBBNI",
    portName: "Bethania",
    portContinent: "Europe",
  },
  {
    lat: "52.4002207",
    lng: "-1.6965813",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBNJ",
    portName: "Barston",
    portContinent: "Europe",
  },
  {
    lat: "39.1836856",
    lng: "-77.0190228",
    country: "US",
    country_long: "United States",
    portCode: "GBBNK",
    portName: "Brinklow",
    portContinent: "Europe",
  },
  {
    lat: "52.402446",
    lng: "-1.446541",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBNL",
    portName: "Binley",
    portContinent: "Europe",
  },
  {
    lat: "38.3613844",
    lng: "-98.7651482",
    country: "US",
    country_long: "United States",
    portCode: "GBBNM",
    portName: "Barton",
    portContinent: "Europe",
  },
  {
    lat: "51.10803900000001",
    lng: "-4.161014",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBNN",
    portName: "Braunton",
    portContinent: "Europe",
  },
  {
    lat: "52.7646717",
    lng: "-1.7232078",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBNO",
    portName: "Barton-under-Needwood",
    portContinent: "Europe",
  },
  {
    lat: "28.2090155",
    lng: "-82.5212896",
    country: "US",
    country_long: "United States",
    portCode: "GBBNR",
    portName: "Ballantrae",
    portContinent: "Europe",
  },
  {
    lat: "54.106638",
    lng: "-2.790823",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBNS",
    portName: "BoltonleSands",
    portContinent: "Europe",
  },
  {
    lat: "52.53445499999999",
    lng: "-2.424549",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBNT",
    portName: "Bridgnorth",
    portContinent: "Europe",
  },
  {
    lat: "53.427122",
    lng: "-2.660497",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBNW",
    portName: "Burtonwood",
    portContinent: "Europe",
  },
  {
    lat: "36.8044767",
    lng: "-97.2828207",
    country: "US",
    country_long: "United States",
    portCode: "GBBNX",
    portName: "Blackwell",
    portContinent: "Europe",
  },
  {
    lat: "56.003212",
    lng: "-3.887711",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBNY",
    portName: "Bonnybridge",
    portContinent: "Europe",
  },
  {
    lat: "53.7937996",
    lng: "-1.7563583",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBO2",
    portName: "Bradford",
    portContinent: "Europe",
  },
  {
    lat: "51.345178",
    lng: "-2.2525019",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOA",
    portName: "Bradford On Avon",
    portContinent: "Europe",
  },
  {
    lat: "51.628347",
    lng: "0.814539",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOC",
    portName: "Burnham on Crouch",
    portContinent: "Europe",
  },
  {
    lat: "50.7236303",
    lng: "-1.8484642",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOD",
    portName: "Boscombe",
    portContinent: "Europe",
  },
  {
    lat: "53.44325500000001",
    lng: "-2.998895",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOE",
    portName: "Bootle",
    portContinent: "Europe",
  },
  {
    lat: "51.17839720000001",
    lng: "-115.5708073",
    country: "CA",
    country_long: "Canada",
    portCode: "GBBOF",
    portName: "BanffOffshore",
    portContinent: "Europe",
  },
  {
    lat: "55.874917",
    lng: "-3.106337",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOG",
    portName: "Bonnyrigg",
    portContinent: "Europe",
  },
  {
    lat: "50.7220101",
    lng: "-1.8667169",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOH",
    portName: "Bournemouth",
    portContinent: "Europe",
  },
  {
    lat: "53.29669999999999",
    lng: "-2.096652",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOI",
    portName: "Bollington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBOK",
    portName: "Brook",
    portContinent: "Europe",
  },
  {
    lat: "50.9154703",
    lng: "-1.2757473",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOL",
    portName: "Botley",
    portContinent: "Europe",
  },
  {
    lat: "53.2107162",
    lng: "-0.5646012",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOM",
    portName: "Boultham",
    portContinent: "Europe",
  },
  {
    lat: "56.0130357",
    lng: "-3.6035314",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBON",
    portName: "Bo'ness",
    portContinent: "Europe",
  },
  {
    lat: "53.44325500000001",
    lng: "-2.998895",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOO",
    portName: "Bootle",
    portContinent: "Europe",
  },
  {
    lat: "55.90862199999999",
    lng: "-4.504519999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOP",
    portName: "BISHOPTON",
    portContinent: "Europe",
  },
  {
    lat: "53.35961709999999",
    lng: "-6.272958399999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBBOQ",
    portName: "Broadstone",
    portContinent: "Europe",
  },
  {
    lat: "-34.0723631",
    lng: "118.262089",
    country: "AU",
    country_long: "Australia",
    portCode: "GBBOR",
    portName: "Borden",
    portContinent: "Europe",
  },
  {
    lat: "42.3600825",
    lng: "-71.0588801",
    country: "US",
    country_long: "United States",
    portCode: "GBBOS",
    portName: "Boston",
    portContinent: "Europe",
  },
  {
    lat: "52.914931",
    lng: "-4.136272",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOT",
    portName: "Borth-Y-Gest",
    portContinent: "Europe",
  },
  {
    lat: "51.886805",
    lng: "-1.763191",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOU",
    portName: "Bourton-On-The-Water",
    portContinent: "Europe",
  },
  {
    lat: "53.231044",
    lng: "-1.2897209",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOV",
    portName: "Bolsover, Derbyshire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBOW",
    portName: "Bowling",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBOX",
    portName: "Box",
    portContinent: "Europe",
  },
  {
    lat: "50.9154703",
    lng: "-1.2757473",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOY",
    portName: "Botley",
    portContinent: "Europe",
  },
  {
    lat: "52.35698679999999",
    lng: "-1.288994",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBOZ",
    portName: "Bilton",
    portContinent: "Europe",
  },
  {
    lat: "51.947366",
    lng: "-2.060942",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBPC",
    portName: "Bishop'sCleeve",
    portContinent: "Europe",
  },
  {
    lat: "51.723453",
    lng: "-1.549105",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBPD",
    portName: "Bampton",
    portContinent: "Europe",
  },
  {
    lat: "38.7955477",
    lng: "-104.8413626",
    country: "US",
    country_long: "United States",
    portCode: "GBBPE",
    portName: "Broadmoor",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBPF",
    portName: "BerylPlatform",
    portContinent: "Europe",
  },
  {
    lat: "52.21526",
    lng: "-1.4349479",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBPG",
    portName: "BishopsItchington",
    portContinent: "Europe",
  },
  {
    lat: "51.5457627",
    lng: "0.3605997",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBPH",
    portName: "Bulphan",
    portContinent: "Europe",
  },
  {
    lat: "50.970764",
    lng: "-1.335819",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBPK",
    portName: "Bishopstoke",
    portContinent: "Europe",
  },
  {
    lat: "51.5983227",
    lng: "-3.999247499999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBPL",
    portName: "BlackPill",
    portContinent: "Europe",
  },
  {
    lat: "50.871775",
    lng: "-0.517786",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBPM",
    portName: "Burpham",
    portContinent: "Europe",
  },
  {
    lat: "43.7315479",
    lng: "-79.7624177",
    country: "CA",
    country_long: "Canada",
    portCode: "GBBPO",
    portName: "Brampton",
    portContinent: "Europe",
  },
  {
    lat: "51.683466",
    lng: "-4.2561",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBPT",
    portName: "BurryPort",
    portContinent: "Europe",
  },
  {
    lat: "50.9546872",
    lng: "-1.2179669",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBPW",
    portName: "Bishop'sWaltham",
    portContinent: "Europe",
  },
  {
    lat: "51.061425",
    lng: "-3.187069",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBPY",
    portName: "BishopsLydeard",
    portContinent: "Europe",
  },
  {
    lat: "51.312059",
    lng: "0.032802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBQH",
    portName: "Biggin Hill",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBQK",
    portName: "Banks",
    portContinent: "Europe",
  },
  {
    lat: "54.4801114",
    lng: "-8.0914889",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBQL",
    portName: "Belleek",
    portContinent: "Europe",
  },
  {
    lat: "52.889015",
    lng: "-1.5704121",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBQN",
    portName: "Burnaston",
    portContinent: "Europe",
  },
  {
    lat: "50.5388293",
    lng: "-3.7023778",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBQQ",
    portName: "Bickington",
    portContinent: "Europe",
  },
  {
    lat: "31.0560132",
    lng: "-97.46445299999999",
    country: "US",
    country_long: "United States",
    portCode: "GBBQT",
    portName: "Belton",
    portContinent: "Europe",
  },
  {
    lat: "55.558825",
    lng: "-1.634229",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBQX",
    portName: "Beadnell",
    portContinent: "Europe",
  },
  {
    lat: "52.611527",
    lng: "-2.4834109",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBQY",
    portName: "Broseley",
    portContinent: "Europe",
  },
  {
    lat: "50.4714961",
    lng: "-4.7188629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBQZ",
    portName: "Bodmin, Cornwall",
    portContinent: "Europe",
  },
  {
    lat: "51.1948099",
    lng: "-0.55903",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBR9",
    portName: "Bramley",
    portContinent: "Europe",
  },
  {
    lat: "49.848471",
    lng: "-99.9500904",
    country: "CA",
    country_long: "Canada",
    portCode: "GBBRA",
    portName: "Brandon",
    portContinent: "Europe",
  },
  {
    lat: "31.848766",
    lng: "-81.6038617",
    country: "US",
    country_long: "United States",
    portCode: "GBBRB",
    portName: "Bradwell",
    portContinent: "Europe",
  },
  {
    lat: "56.73334200000001",
    lng: "-2.6552889",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRC",
    portName: "Brechin",
    portContinent: "Europe",
  },
  {
    lat: "51.071737",
    lng: "-0.358067",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRD",
    portName: "BROADBRIDGE HEATH, WEST SUSSEX",
    portContinent: "Europe",
  },
  {
    lat: "38.2834315",
    lng: "-77.468263",
    country: "US",
    country_long: "United States",
    portCode: "GBBRE",
    portName: "Braehead",
    portContinent: "Europe",
  },
  {
    lat: "53.7937996",
    lng: "-1.7563583",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRF",
    portName: "Bradford",
    portContinent: "Europe",
  },
  {
    lat: "51.504286",
    lng: "-3.576945",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRG",
    portName: "Bridgend",
    portContinent: "Europe",
  },
  {
    lat: "53.699729",
    lng: "-1.782501",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRH",
    portName: "Brighouse",
    portContinent: "Europe",
  },
  {
    lat: "42.2079017",
    lng: "-71.0040013",
    country: "US",
    country_long: "United States",
    portCode: "GBBRI",
    portName: "Braintree",
    portContinent: "Europe",
  },
  {
    lat: "58.01215699999999",
    lng: "-3.852548",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRJ",
    portName: "Brora",
    portContinent: "Europe",
  },
  {
    lat: "53.38811639999999",
    lng: "-3.0299176",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRK",
    portName: "Birkenhead",
    portContinent: "Europe",
  },
  {
    lat: "35.7402677",
    lng: "-78.5435235",
    country: "US",
    country_long: "United States",
    portCode: "GBBRL",
    portName: "Barwell",
    portContinent: "Europe",
  },
  {
    lat: "51.968701",
    lng: "1.068646",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRM",
    portName: "Brantham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBRN",
    portName: "Burnley",
    portContinent: "Europe",
  },
  {
    lat: "53.399401",
    lng: "-2.358924",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRO",
    portName: "Broadheath",
    portContinent: "Europe",
  },
  {
    lat: "50.71096280250961",
    lng: "-2.762848765041405",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRP",
    portName: "Bridport Harbour",
    portContinent: "Europe",
  },
  {
    lat: "40.6977743",
    lng: "-89.6153484",
    country: "US",
    country_long: "United States",
    portCode: "GBBRQ",
    portName: "Bradley",
    portContinent: "Europe",
  },
  {
    lat: "56.9808838",
    lng: "-7.456795899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRR",
    portName: "Barra (GBBRR), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.454513",
    lng: "-2.58791",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRS",
    portName: "Bristol",
    portContinent: "Europe",
  },
  {
    lat: "54.08535",
    lng: "-0.198802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRT",
    portName: "Bridlington",
    portContinent: "Europe",
  },
  {
    lat: "60.4333333",
    lng: "-0.7500000000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRU",
    portName: "Bruray, Out Skerries",
    portContinent: "Europe",
  },
  {
    lat: "53.693394",
    lng: "-0.392823",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRV",
    portName: "Barrow Haven",
    portContinent: "Europe",
  },
  {
    lat: "51.127889",
    lng: "-3.003632",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRW",
    portName: "Bridgwater",
    portContinent: "Europe",
  },
  {
    lat: "50.3965906",
    lng: "-3.5117774",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRX",
    portName: "Brixham Harbour",
    portContinent: "Europe",
  },
  {
    lat: "51.6169859",
    lng: "-3.7123799",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRY",
    portName: "Bryn",
    portContinent: "Europe",
  },
  {
    lat: "51.148442",
    lng: "0.396615",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBRZ",
    portName: "Brenchley",
    portContinent: "Europe",
  },
  {
    lat: "53.905682",
    lng: "-1.356251",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBS2",
    portName: "BostonSpa",
    portContinent: "Europe",
  },
  {
    lat: "51.3221909",
    lng: "-0.205046",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSA",
    portName: "Banstead",
    portContinent: "Europe",
  },
  {
    lat: "50.68691399999999",
    lng: "-4.692832",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSC",
    portName: "Boscastle",
    portContinent: "Europe",
  },
  {
    lat: "55.92169",
    lng: "-4.335525",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSD",
    portName: "Bearsden",
    portContinent: "Europe",
  },
  {
    lat: "52.24690409999999",
    lng: "0.7112514",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSE",
    portName: "Bury Saint Edmunds",
    portContinent: "Europe",
  },
  {
    lat: "52.9301664",
    lng: "-1.0807952",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSF",
    portName: "Bassingfield",
    portContinent: "Europe",
  },
  {
    lat: "51.4396281",
    lng: "-2.5466573",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSG",
    portName: "Brislington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBSH",
    portName: "Brighton",
    portContinent: "Europe",
  },
  {
    lat: "50.782998",
    lng: "-0.673061",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSI",
    portName: "Bognor Regis",
    portContinent: "Europe",
  },
  {
    lat: "51.532291",
    lng: "-2.546212",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSK",
    portName: "BradleyStoke",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBSL",
    portName: "Bosley",
    portContinent: "Europe",
  },
  {
    lat: "51.886805",
    lng: "-1.763191",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSM",
    portName: "Bourton",
    portContinent: "Europe",
  },
  {
    lat: "60.75834199999999",
    lng: "-0.8588439999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSN",
    portName: "Baltasound (GBBSN), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.231044",
    lng: "-1.2897209",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSO",
    portName: "Bolsover",
    portContinent: "Europe",
  },
  {
    lat: "55.904185",
    lng: "-4.228494",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSP",
    portName: "Bishopbriggs",
    portContinent: "Europe",
  },
  {
    lat: "52.6184591",
    lng: "-0.9399558999999998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSQ",
    portName: "Billesdon",
    portContinent: "Europe",
  },
  {
    lat: "51.4669939",
    lng: "-1.1853677",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSR",
    portName: "Berkshire",
    portContinent: "Europe",
  },
  {
    lat: "56.0130357",
    lng: "-3.6035314",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSS",
    portName: "Borrowstowness",
    portContinent: "Europe",
  },
  {
    lat: "51.8721635",
    lng: "0.1536502",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBST",
    portName: "Bishop'S Stortford",
    portContinent: "Europe",
  },
  {
    lat: "51.414655",
    lng: "-2.618639",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSW",
    portName: "Bishopsworth",
    portContinent: "Europe",
  },
  {
    lat: "50.253229",
    lng: "-3.657526",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSX",
    portName: "Beesands",
    portContinent: "Europe",
  },
  {
    lat: "53.55263",
    lng: "-1.479726",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSY",
    portName: "Barnsley",
    portContinent: "Europe",
  },
  {
    lat: "53.19440299999999",
    lng: "-0.471656",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBSZ",
    portName: "Branston",
    portContinent: "Europe",
  },
  {
    lat: "52.64729",
    lng: "-1.369432",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBT3",
    portName: "Barlestone",
    portContinent: "Europe",
  },
  {
    lat: "51.3576755",
    lng: "-1.1644537",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBT9",
    portName: "Baughurst",
    portContinent: "Europe",
  },
  {
    lat: "52.5252253",
    lng: "-1.2172262",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTA",
    portName: "BroughtonAstley",
    portContinent: "Europe",
  },
  {
    lat: "53.571456",
    lng: "-0.403125",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTB",
    portName: "Barnetby-le-Wold",
    portContinent: "Europe",
  },
  {
    lat: "53.571456",
    lng: "-0.403125",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTBÂ",
    portName: "Barnetby",
    portContinent: "Europe",
  },
  {
    lat: "51.16740679999999",
    lng: "-1.3764225",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTC",
    portName: "BartonStacey",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBTD",
    portName: "Burton",
    portContinent: "Europe",
  },
  {
    lat: "50.917405",
    lng: "0.483679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTE",
    portName: "Battle",
    portContinent: "Europe",
  },
  {
    lat: "51.397643",
    lng: "-2.299443",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTF",
    portName: "Bathford",
    portContinent: "Europe",
  },
  {
    lat: "51.531103",
    lng: "-0.0480805",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTG",
    portName: "BethnalGreen/London",
    portContinent: "Europe",
  },
  {
    lat: "55.9024",
    lng: "-3.643118",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTH",
    portName: "Bathgate",
    portContinent: "Europe",
  },
  {
    lat: "55.8512984",
    lng: "-4.1159938",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTI",
    portName: "Bailleston",
    portContinent: "Europe",
  },
  {
    lat: "52.376198",
    lng: "-2.927013",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTK",
    portName: "Bedstone",
    portContinent: "Europe",
  },
  {
    lat: "56.058809",
    lng: "-3.233915",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTL",
    portName: "Burntisland (GBBTL), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.3781018",
    lng: "-2.3596827",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTM",
    portName: "Bath",
    portContinent: "Europe",
  },
  {
    lat: "55.131664",
    lng: "-1.599454",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTN",
    portName: "Bedlington",
    portContinent: "Europe",
  },
  {
    lat: "51.946217",
    lng: "-0.018547",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTO",
    portName: "Buntingford",
    portContinent: "Europe",
  },
  {
    lat: "53.1417371",
    lng: "-1.0339645",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTP",
    portName: "Bilsthorpe",
    portContinent: "Europe",
  },
  {
    lat: "52.81402809999999",
    lng: "-1.6371359",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTR",
    portName: "BurtonuponTrent",
    portContinent: "Europe",
  },
  {
    lat: "53.415462",
    lng: "-1.053387",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTS",
    portName: "Bircotes",
    portContinent: "Europe",
  },
  {
    lat: "51.47220069999999",
    lng: "-0.165547",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTT",
    portName: "Battersea",
    portContinent: "Europe",
  },
  {
    lat: "52.749282",
    lng: "-3.973008999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTU",
    portName: "Bontddu",
    portContinent: "Europe",
  },
  {
    lat: "51.240523",
    lng: "-0.267364",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTW",
    portName: "Betchworth",
    portContinent: "Europe",
  },
  {
    lat: "50.9154703",
    lng: "-1.2757473",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBTY",
    portName: "Botley",
    portContinent: "Europe",
  },
  {
    lat: "-32.6582499",
    lng: "151.6390827",
    country: "AU",
    country_long: "Australia",
    portCode: "GBBTZ",
    portName: "Butterwick",
    portContinent: "Europe",
  },
  {
    lat: "53.5933498",
    lng: "-2.2966054",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBU2",
    portName: "Bury",
    portContinent: "Europe",
  },
  {
    lat: "50.630998",
    lng: "-3.320214",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUA",
    portName: "Budleigh Salterton",
    portContinent: "Europe",
  },
  {
    lat: "52.209459",
    lng: "-1.008998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUB",
    portName: "Bugbrooke",
    portContinent: "Europe",
  },
  {
    lat: "57.67739199999999",
    lng: "-2.967311",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUC",
    portName: "Buckie (GBBUC), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.826636",
    lng: "-4.543678",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUD",
    portName: "Bude",
    portContinent: "Europe",
  },
  {
    lat: "50.889885",
    lng: "-1.3093472",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUE",
    portName: "Bursledon",
    portContinent: "Europe",
  },
  {
    lat: "58.8507078",
    lng: "-2.9079813",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUF",
    portName: "Burray",
    portContinent: "Europe",
  },
  {
    lat: "52.455457",
    lng: "1.443976",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUG",
    portName: "Bungay",
    portContinent: "Europe",
  },
  {
    lat: "57.700232",
    lng: "-3.489213",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUH",
    portName: "Burghead",
    portContinent: "Europe",
  },
  {
    lat: "52.150023",
    lng: "-3.404592",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUI",
    portName: "Builth Wells",
    portContinent: "Europe",
  },
  {
    lat: "52.986554",
    lng: "-1.037903",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUJ",
    portName: "BurtonJoyce",
    portContinent: "Europe",
  },
  {
    lat: "51.999326",
    lng: "-0.987645",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUK",
    portName: "Buckingham",
    portContinent: "Europe",
  },
  {
    lat: "50.954469",
    lng: "-0.128701",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUL",
    portName: "BurgessHill",
    portContinent: "Europe",
  },
  {
    lat: "51.240139",
    lng: "-2.993858",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUM",
    portName: "Burnham",
    portContinent: "Europe",
  },
  {
    lat: "52.679936",
    lng: "-1.921781",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUN",
    portName: "BURNTWOOD, STAFFORDSHIRE",
    portContinent: "Europe",
  },
  {
    lat: "52.7524019",
    lng: "-1.148755",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUO",
    portName: "Barrow On Soar",
    portContinent: "Europe",
  },
  {
    lat: "51.5457627",
    lng: "0.3605997",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUP",
    portName: "Bulpham",
    portContinent: "Europe",
  },
  {
    lat: "52.76859899999999",
    lng: "-0.378462",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUR",
    portName: "Bourne",
    portContinent: "Europe",
  },
  {
    lat: "53.649532",
    lng: "-0.684465",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUS",
    portName: "BurtonuponStather",
    portContinent: "Europe",
  },
  {
    lat: "52.365906",
    lng: "-0.6782079999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUT",
    portName: "Burton Latimer",
    portContinent: "Europe",
  },
  {
    lat: "51.640213",
    lng: "-0.344442",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUU",
    portName: "Bushey Heath, Hertfordshire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBUW",
    portName: "Burntwood",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBUX",
    portName: "Buxton",
    portContinent: "Europe",
  },
  {
    lat: "53.5933498",
    lng: "-2.2966054",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUY",
    portName: "Bury",
    portContinent: "Europe",
  },
  {
    lat: "50.82642",
    lng: "-1.701727",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBUZ",
    portName: "Burley",
    portContinent: "Europe",
  },
  {
    lat: "51.4982272",
    lng: "-0.1535489",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBVA",
    portName: "Belgravia",
    portContinent: "Europe",
  },
  {
    lat: "51.20118",
    lng: "-4.06155",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBVB",
    portName: "Berrynarbor",
    portContinent: "Europe",
  },
  {
    lat: "51.723391",
    lng: "-0.534632",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBVD",
    portName: "Bovingdon",
    portContinent: "Europe",
  },
  {
    lat: "52.4244044",
    lng: "-1.9338974",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBVE",
    portName: "Bournville/Birmingham",
    portContinent: "Europe",
  },
  {
    lat: "51.772502",
    lng: "-3.085896",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBVN",
    portName: "Blaenavon",
    portContinent: "Europe",
  },
  {
    lat: "60.49937000000001",
    lng: "-1.05559",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBVO",
    portName: "Burravoe",
    portContinent: "Europe",
  },
  {
    lat: "56.290418",
    lng: "-5.610461",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBVR",
    portName: "Balvicar",
    portContinent: "Europe",
  },
  {
    lat: "50.593547",
    lng: "-3.672587",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBVT",
    portName: "BoveyTracey",
    portContinent: "Europe",
  },
  {
    lat: "40.6706333",
    lng: "-75.2655278",
    country: "US",
    country_long: "United States",
    portCode: "GBBVY",
    portName: "Bardon",
    portContinent: "Europe",
  },
  {
    lat: "50.6980043",
    lng: "-2.2285788",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBVZ",
    portName: "BovingtonCamp",
    portContinent: "Europe",
  },
  {
    lat: "52.47682500000001",
    lng: "-1.9701819",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBW6",
    portName: "Bearwood",
    portContinent: "Europe",
  },
  {
    lat: "52.678299",
    lng: "-2.175169",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBW9",
    portName: "Brewood",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBWA",
    portName: "Broadway",
    portContinent: "Europe",
  },
  {
    lat: "52.63347719999999",
    lng: "1.1846454",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWB",
    portName: "Bawburgh",
    portContinent: "Europe",
  },
  {
    lat: "58.74188280000001",
    lng: "-2.9716287",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWC",
    portName: "Burwick (GBBWC), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "34.0521011",
    lng: "-118.4732464",
    country: "US",
    country_long: "United States",
    portCode: "GBBWD",
    portName: "Brentwood",
    portContinent: "Europe",
  },
  {
    lat: "-34.9027533",
    lng: "138.5802196",
    country: "AU",
    country_long: "Australia",
    portCode: "GBBWE",
    portName: "Bowden",
    portContinent: "Europe",
  },
  {
    lat: "51.57100020000001",
    lng: "0.5331467",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWG",
    portName: "BowersGifford",
    portContinent: "Europe",
  },
  {
    lat: "54.03081599999999",
    lng: "-1.634248",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWH",
    portName: "Birstwith",
    portContinent: "Europe",
  },
  {
    lat: "55.856554",
    lng: "-4.577723",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWI",
    portName: "BridgeofWeir",
    portContinent: "Europe",
  },
  {
    lat: "55.770242",
    lng: "-2.005395",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWK",
    portName: "Berwick-Upon-Tweed",
    portContinent: "Europe",
  },
  {
    lat: "39.93989819999999",
    lng: "-82.92652009999999",
    country: "US",
    country_long: "United States",
    portCode: "GBBWKÂ",
    portName: "Berwick",
    portContinent: "Europe",
  },
  {
    lat: "42.6313566",
    lng: "-81.8724505",
    country: "CA",
    country_long: "Canada",
    portCode: "GBBWL",
    portName: "Bothwell",
    portContinent: "Europe",
  },
  {
    lat: "53.200903",
    lng: "-6.1110741",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBBWM",
    portName: "Bray",
    portContinent: "Europe",
  },
  {
    lat: "54.735811",
    lng: "-1.529035",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWN",
    portName: "Bowburn",
    portContinent: "Europe",
  },
  {
    lat: "52.481392",
    lng: "-1.468869",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWO",
    portName: "Bedworth",
    portContinent: "Europe",
  },
  {
    lat: "52.6379062",
    lng: "1.2157969",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWP",
    portName: "Bowthorpe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBWQ",
    portName: "Bow",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBWR",
    portName: "Burrow",
    portContinent: "Europe",
  },
  {
    lat: "52.646054",
    lng: "-1.931056",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWS",
    portName: "Brownhills",
    portContinent: "Europe",
  },
  {
    lat: "53.43125",
    lng: "-1.018515",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWT",
    portName: "Bawtry",
    portContinent: "Europe",
  },
  {
    lat: "52.8532814",
    lng: "-1.4768986",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWU",
    portName: "BarrowuponTrent",
    portContinent: "Europe",
  },
  {
    lat: "50.150076",
    lng: "-5.103587999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWV",
    portName: "BudockWater",
    portContinent: "Europe",
  },
  {
    lat: "51.5275195",
    lng: "-0.0582703",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWW",
    portName: "Brinkworth",
    portContinent: "Europe",
  },
  {
    lat: "52.150791",
    lng: "0.8738258999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWX",
    portName: "Brettenham",
    portContinent: "Europe",
  },
  {
    lat: "53.268061",
    lng: "-3.498458",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWY",
    portName: "Bodelwyddan",
    portContinent: "Europe",
  },
  {
    lat: "52.668263",
    lng: "-1.335385",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBWZ",
    portName: "Bagworth",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBXA",
    portName: "Buxton",
    portContinent: "Europe",
  },
  {
    lat: "50.8499062",
    lng: "0.4662071",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXB",
    portName: "Bexhill",
    portContinent: "Europe",
  },
  {
    lat: "53.0930858",
    lng: "-3.8010354",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXC",
    portName: "Betws-y-Coed",
    portContinent: "Europe",
  },
  {
    lat: "51.962659",
    lng: "0.9093559999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXD",
    portName: "Boxted",
    portContinent: "Europe",
  },
  {
    lat: "51.4935082",
    lng: "-0.1178424",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXE",
    portName: "Lambeth",
    portContinent: "Europe",
  },
  {
    lat: "53.12674999999999",
    lng: "-0.6409110000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXF",
    portName: "Bassingham",
    portContinent: "Europe",
  },
  {
    lat: "53.0853911",
    lng: "-1.6580035",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXG",
    portName: "Brassington",
    portContinent: "Europe",
  },
  {
    lat: "51.45334099999999",
    lng: "0.13822",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXL",
    portName: "Bexleyheath",
    portContinent: "Europe",
  },
  {
    lat: "54.42071",
    lng: "-7.594659999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXM",
    portName: "Ballinamallard",
    portContinent: "Europe",
  },
  {
    lat: "51.74347299999999",
    lng: "-0.021151",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXN",
    portName: "Broxbourne",
    portContinent: "Europe",
  },
  {
    lat: "42.6611604",
    lng: "-70.996726",
    country: "US",
    country_long: "United States",
    portCode: "GBBXO",
    portName: "Boxford",
    portContinent: "Europe",
  },
  {
    lat: "52.6098868",
    lng: "-1.177021",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXQ",
    portName: "Braunstone",
    portContinent: "Europe",
  },
  {
    lat: "41.7816732",
    lng: "-99.1331539",
    country: "US",
    country_long: "United States",
    portCode: "GBBXR",
    portName: "Burwell",
    portContinent: "Europe",
  },
  {
    lat: "50.6445205",
    lng: "-1.3996166",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXS",
    portName: "Brighstone",
    portContinent: "Europe",
  },
  {
    lat: "54.568162",
    lng: "-0.937091",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXT",
    portName: "Brotton",
    portContinent: "Europe",
  },
  {
    lat: "55.934161",
    lng: "-3.467681",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXU",
    portName: "Broxburn",
    portContinent: "Europe",
  },
  {
    lat: "52.6052238",
    lng: "0.4068712",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBXW",
    portName: "Bexwell",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBXX",
    portName: "Beer",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBXY",
    portName: "Bunny",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBY2",
    portName: "BentleyFPSO",
    portContinent: "Europe",
  },
  {
    lat: "53.44498",
    lng: "-0.713575",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBY3",
    portName: "Blyton",
    portContinent: "Europe",
  },
  {
    lat: "52.5707761",
    lng: "-1.1656497",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBY4",
    portName: "Blaby",
    portContinent: "Europe",
  },
  {
    lat: "53.767422",
    lng: "-1.0901019",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBY9",
    portName: "Brayton",
    portContinent: "Europe",
  },
  {
    lat: "53.5122316",
    lng: "-2.1075828",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYA",
    portName: "Bardsley",
    portContinent: "Europe",
  },
  {
    lat: "52.969189",
    lng: "-2.072085",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYB",
    portName: "BlytheBridge",
    portContinent: "Europe",
  },
  {
    lat: "55.2052888",
    lng: "-6.2532947",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYC",
    portName: "Ballycastle",
    portContinent: "Europe",
  },
  {
    lat: "52.190713",
    lng: "-2.50872",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYD",
    portName: "Bromyard",
    portContinent: "Europe",
  },
  {
    lat: "51.640213",
    lng: "-0.344442",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYE",
    portName: "BusheyHeath",
    portContinent: "Europe",
  },
  {
    lat: "51.34012500000001",
    lng: "-0.471357",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYF",
    portName: "Byfleet",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBBYG",
    portName: "Barry",
    portContinent: "Europe",
  },
  {
    lat: "53.2479159",
    lng: "-3.640139",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYH",
    portName: "Betws-yn-rhos",
    portContinent: "Europe",
  },
  {
    lat: "53.180068",
    lng: "-4.270602999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYI",
    portName: "Brynsiencyn",
    portContinent: "Europe",
  },
  {
    lat: "52.9969014",
    lng: "-1.1971811",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYL",
    portName: "Bulwell",
    portContinent: "Europe",
  },
  {
    lat: "54.8652935",
    lng: "-6.280221300000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYM",
    portName: "Ballymena",
    portContinent: "Europe",
  },
  {
    lat: "50.677952",
    lng: "-2.383817",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYN",
    portName: "Broadmayne",
    portContinent: "Europe",
  },
  {
    lat: "55.23636",
    lng: "-6.35914",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYP",
    portName: "Ballintoy",
    portContinent: "Europe",
  },
  {
    lat: "51.64761499999999",
    lng: "-0.35842",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYQ",
    portName: "Bushey",
    portContinent: "Europe",
  },
  {
    lat: "42.33606229999999",
    lng: "-73.2588767",
    country: "US",
    country_long: "United States",
    portCode: "GBBYR",
    portName: "Blantyre",
    portContinent: "Europe",
  },
  {
    lat: "53.983885",
    lng: "-2.672538",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYS",
    portName: "Abbeystead",
    portContinent: "Europe",
  },
  {
    lat: "50.90168200000001",
    lng: "-4.384723999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYT",
    portName: "Bradworthy",
    portContinent: "Europe",
  },
  {
    lat: "51.6693383",
    lng: "-0.5662178",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYU",
    portName: "LittleChalfont",
    portContinent: "Europe",
  },
  {
    lat: "54.46275",
    lng: "-7.0281899",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYW",
    portName: "Ballygawley",
    portContinent: "Europe",
  },
  {
    lat: "56.4692371",
    lng: "-2.8814982",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBYY",
    portName: "BroughtyFerry",
    portContinent: "Europe",
  },
  {
    lat: "54.16326000000001",
    lng: "-4.505250999999999",
    country: "IM",
    country_long: "Isle of Man",
    portCode: "GBBZD",
    portName: "Braddan",
    portContinent: "Europe",
  },
  {
    lat: "55.59933100000001",
    lng: "-1.828824",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBZE",
    portName: "Belford",
    portContinent: "Europe",
  },
  {
    lat: "53.7349864",
    lng: "-1.6630839",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBZL",
    portName: "Birstall",
    portContinent: "Europe",
  },
  {
    lat: "49.9541408",
    lng: "-6.354108399999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBZR",
    portName: "Bryher",
    portContinent: "Europe",
  },
  {
    lat: "53.163494",
    lng: "-2.997219",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBZT",
    portName: "Broughton",
    portContinent: "Europe",
  },
  {
    lat: "51.345809",
    lng: "-0.9313979999999998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBZX",
    portName: "Bramshill",
    portContinent: "Europe",
  },
  {
    lat: "51.334312",
    lng: "0.700427",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBZY",
    portName: "Borden, Kent",
    portContinent: "Europe",
  },
  {
    lat: "51.767658",
    lng: "-1.566767",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBBZZ",
    portName: "BrizeNorton",
    portContinent: "Europe",
  },
  {
    lat: "40.500616",
    lng: "-82.89351460000002",
    country: "US",
    country_long: "United States",
    portCode: "GBC5T",
    portName: "Cardington",
    portContinent: "Europe",
  },
  {
    lat: "52.3086",
    lng: "-0.4195704",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBC6M",
    portName: "Tilbrook",
    portContinent: "Europe",
  },
  {
    lat: "52.6190881",
    lng: "1.2136788",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBC8N",
    portName: "Colney",
    portContinent: "Europe",
  },
  {
    lat: "51.5620309",
    lng: "-114.0969814",
    country: "CA",
    country_long: "Canada",
    portCode: "GBCA9",
    portName: "Carstairs",
    portContinent: "Europe",
  },
  {
    lat: "52.1907489",
    lng: "-0.029674",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAA",
    portName: "Caldecote",
    portContinent: "Europe",
  },
  {
    lat: "57.3009649",
    lng: "-6.3516354",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAB",
    portName: "Carbost",
    portContinent: "Europe",
  },
  {
    lat: "51.088689",
    lng: "-2.5131009",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAC",
    portName: "Castle Cary",
    portContinent: "Europe",
  },
  {
    lat: "52.845016",
    lng: "-1.3373001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAD",
    portName: "Castle Donington",
    portContinent: "Europe",
  },
  {
    lat: "51.578829",
    lng: "-3.218134",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAE",
    portName: "Caerphilly",
    portContinent: "Europe",
  },
  {
    lat: "60.40002500000001",
    lng: "-1.217048",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAF",
    portName: "Collafirth",
    portContinent: "Europe",
  },
  {
    lat: "57.07272769999999",
    lng: "-7.292431000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAG",
    portName: "CeannaGharaidh,Eriskay",
    portContinent: "Europe",
  },
  {
    lat: "54.99032",
    lng: "-5.9924101",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAH",
    portName: "Carnlough",
    portContinent: "Europe",
  },
  {
    lat: "53.497485",
    lng: "-0.315393",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAI",
    portName: "Caistor",
    portContinent: "Europe",
  },
  {
    lat: "55.735435",
    lng: "-3.836552",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAK",
    portName: "Carluke",
    portContinent: "Europe",
  },
  {
    lat: "56.245059",
    lng: "-4.211663",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAL",
    portName: "Callander",
    portContinent: "Europe",
  },
  {
    lat: "41.6597196",
    lng: "-91.5519314",
    country: "US",
    country_long: "United States",
    portCode: "GBCAM",
    portName: "Cambus",
    portContinent: "Europe",
  },
  {
    lat: "51.517083",
    lng: "0.578411",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAN",
    portName: "Canvey Island",
    portContinent: "Europe",
  },
  {
    lat: "45.2941973",
    lng: "-123.1770183",
    country: "US",
    country_long: "United States",
    portCode: "GBCAO",
    portName: "Carlton",
    portContinent: "Europe",
  },
  {
    lat: "51.153747",
    lng: "-0.32109",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAP",
    portName: "Capel",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCAQ",
    portName: "Cam",
    portContinent: "Europe",
  },
  {
    lat: "52.083703",
    lng: "-4.660864",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAR",
    portName: "Cardigan (Aberteifi)",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCARÂ",
    portName: "Cardigan",
    portContinent: "Europe",
  },
  {
    lat: "43.6106223",
    lng: "-73.17983199999999",
    country: "US",
    country_long: "United States",
    portCode: "GBCAS",
    portName: "Castleton",
    portContinent: "Europe",
  },
  {
    lat: "55.50444599999999",
    lng: "-4.329763",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAT",
    portName: "Catrine",
    portContinent: "Europe",
  },
  {
    lat: "57.8507",
    lng: "-5.263107",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAU",
    portName: "Camusnagaul",
    portContinent: "Europe",
  },
  {
    lat: "51.1571969",
    lng: "-0.226967",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAW",
    portName: "Charlwood",
    portContinent: "Europe",
  },
  {
    lat: "40.2010241",
    lng: "-77.20027449999999",
    country: "US",
    country_long: "United States",
    portCode: "GBCAX",
    portName: "Carlisle",
    portContinent: "Europe",
  },
  {
    lat: "51.3353899",
    lng: "-0.742856",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAY",
    portName: "Camberley",
    portContinent: "Europe",
  },
  {
    lat: "55.701201",
    lng: "-3.62357",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCAZ",
    portName: "Carnwath",
    portContinent: "Europe",
  },
  {
    lat: "34.0541463",
    lng: "-118.2499605",
    country: "US",
    country_long: "United States",
    portCode: "GBCB2",
    portName: "Colburn",
    portContinent: "Europe",
  },
  {
    lat: "56.954527",
    lng: "-7.486566",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBA",
    portName: "Castlebay (GBCBA), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.03737599999999",
    lng: "-1.8498111",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBB",
    portName: "CoombeBissett",
    portContinent: "Europe",
  },
  {
    lat: "57.284541",
    lng: "-3.808706999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBD",
    portName: "Carrbridge",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCBE",
    portName: "Camber",
    portContinent: "Europe",
  },
  {
    lat: "54.97404599999999",
    lng: "-2.017505",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBG",
    portName: "Corbridge",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCBH",
    portName: "Cobham",
    portContinent: "Europe",
  },
  {
    lat: "55.079195",
    lng: "-2.954845",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBI",
    portName: "Canonbie",
    portContinent: "Europe",
  },
  {
    lat: "42.5672796",
    lng: "-83.2569161",
    country: "US",
    country_long: "United States",
    portCode: "GBCBK",
    portName: "Cranbrook",
    portContinent: "Europe",
  },
  {
    lat: "57.675319",
    lng: "-1.931415",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBL",
    portName: "Cairnbulg",
    portContinent: "Europe",
  },
  {
    lat: "51.1994789",
    lng: "-4.024285",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBM",
    portName: "CombeMartin",
    portContinent: "Europe",
  },
  {
    lat: "51.1555297",
    lng: "-1.4419509",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBN",
    portName: "Chilbolton",
    portContinent: "Europe",
  },
  {
    lat: "50.9193742",
    lng: "-1.9211723",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBO",
    portName: "Cranborne",
    portContinent: "Europe",
  },
  {
    lat: "51.4837176",
    lng: "-0.5251593",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBR",
    portName: "Colnbrook",
    portContinent: "Europe",
  },
  {
    lat: "55.15618749999999",
    lng: "-1.5278643",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBS",
    portName: "Cambois",
    portContinent: "Europe",
  },
  {
    lat: "55.424117",
    lng: "-5.605373999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBT",
    portName: "Campbeltown",
    portContinent: "Europe",
  },
  {
    lat: "55.820982",
    lng: "-4.164891",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBU",
    portName: "Cambuslang",
    portContinent: "Europe",
  },
  {
    lat: "51.4740846",
    lng: "-0.09301369999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBW",
    portName: "Camberwell",
    portContinent: "Europe",
  },
  {
    lat: "53.7369652",
    lng: "-2.3507632",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBX",
    portName: "Baxenden",
    portContinent: "Europe",
  },
  {
    lat: "52.49229829999999",
    lng: "-0.6842332999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCBY",
    portName: "Corby",
    portContinent: "Europe",
  },
  {
    lat: "43.8363372",
    lng: "-79.87448359999999",
    country: "CA",
    country_long: "Canada",
    portCode: "GBCCA",
    portName: "Caledon",
    portContinent: "Europe",
  },
  {
    lat: "52.291451",
    lng: "-3.116314",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCB",
    portName: "Cascob",
    portContinent: "Europe",
  },
  {
    lat: "52.919565",
    lng: "-4.233645",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCC",
    portName: "Criccieth",
    portContinent: "Europe",
  },
  {
    lat: "56.894097",
    lng: "-2.21828",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCE",
    portName: "Catterline",
    portContinent: "Europe",
  },
  {
    lat: "51.647851",
    lng: "-0.151255",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCF",
    portName: "Cockfosters",
    portContinent: "Europe",
  },
  {
    lat: "52.763998",
    lng: "-1.567278",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCG",
    portName: "ChurchGresley",
    portContinent: "Europe",
  },
  {
    lat: "50.735777",
    lng: "-1.778586",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCH",
    portName: "Christchurch (GBCCH), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.723048",
    lng: "-1.707525",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCK",
    portName: "Cleckheaton",
    portContinent: "Europe",
  },
  {
    lat: "50.1333333",
    lng: "-5.7",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCL",
    portName: "CapeCornwall",
    portContinent: "Europe",
  },
  {
    lat: "52.04965499999999",
    lng: "-1.7832211",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCN",
    portName: "Chipping Campden",
    portContinent: "Europe",
  },
  {
    lat: "50.997853",
    lng: "-1.314116",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCO",
    portName: "ColdenCommon",
    portContinent: "Europe",
  },
  {
    lat: "54.377353",
    lng: "-1.629923",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCR",
    portName: "Catterick",
    portContinent: "Europe",
  },
  {
    lat: "52.45610199999999",
    lng: "0.054012",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCS",
    portName: "Chatteris",
    portContinent: "Europe",
  },
  {
    lat: "35.4331337",
    lng: "-119.0663115",
    country: "US",
    country_long: "United States",
    portCode: "GBCCT",
    portName: "Calcot",
    portContinent: "Europe",
  },
  {
    lat: "51.197553",
    lng: "0.176269",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCU",
    portName: "ChiddingstoneCauseway",
    portContinent: "Europe",
  },
  {
    lat: "54.55566",
    lng: "-5.8489401",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCV",
    portName: "Crossnacreevy",
    portContinent: "Europe",
  },
  {
    lat: "50.298448",
    lng: "-3.8119921",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCW",
    portName: "Churchstow",
    portContinent: "Europe",
  },
  {
    lat: "55.9787906",
    lng: "-3.9488502",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCCY",
    portName: "Castlecary",
    portContinent: "Europe",
  },
  {
    lat: "41.49932",
    lng: "-81.6943605",
    country: "US",
    country_long: "United States",
    portCode: "GBCD3",
    portName: "Cleveland",
    portContinent: "Europe",
  },
  {
    lat: "55.8457824",
    lng: "-4.3429785",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCD9",
    portName: "Cardonald",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCDA",
    portName: "Cheddar",
    portContinent: "Europe",
  },
  {
    lat: "53.4992531",
    lng: "-1.2218979",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDB",
    portName: "Cadeby",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCDC",
    portName: "Caldecott",
    portContinent: "Europe",
  },
  {
    lat: "53.544941",
    lng: "-2.140409",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDD",
    portName: "Chadderton",
    portContinent: "Europe",
  },
  {
    lat: "51.481971",
    lng: "0.369876",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDE",
    portName: "ChadwellSaintMary",
    portContinent: "Europe",
  },
  {
    lat: "51.483707",
    lng: "-3.1680962",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDF",
    portName: "Cardiff",
    portContinent: "Europe",
  },
  {
    lat: "51.46209",
    lng: "-3.449581",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDG",
    portName: "Cowbridge",
    portContinent: "Europe",
  },
  {
    lat: "53.380149",
    lng: "-2.193189",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDH",
    portName: "CheadleHulme",
    portContinent: "Europe",
  },
  {
    lat: "50.792592",
    lng: "-3.651495",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDI",
    portName: "Crediton",
    portContinent: "Europe",
  },
  {
    lat: "50.732402",
    lng: "-2.821459",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDK",
    portName: "Chideock",
    portContinent: "Europe",
  },
  {
    lat: "52.98488400000001",
    lng: "-1.986503",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDL",
    portName: "Cheadle",
    portContinent: "Europe",
  },
  {
    lat: "50.82668940000001",
    lng: "-0.8837579",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDM",
    portName: "Chidham",
    portContinent: "Europe",
  },
  {
    lat: "54.7782341",
    lng: "-6.5579191",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDN",
    portName: "Castledawson",
    portContinent: "Europe",
  },
  {
    lat: "51.954583",
    lng: "-1.784971",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDO",
    portName: "Condicote",
    portContinent: "Europe",
  },
  {
    lat: "50.9213017",
    lng: "-1.2506366",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDR",
    portName: "Curdridge",
    portContinent: "Europe",
  },
  {
    lat: "39.0485163",
    lng: "-84.3493543",
    country: "US",
    country_long: "United States",
    portCode: "GBCDS",
    portName: "Coldstream",
    portContinent: "Europe",
  },
  {
    lat: "51.591518",
    lng: "-2.750868",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDT",
    portName: "Caldicot",
    portContinent: "Europe",
  },
  {
    lat: "50.990827",
    lng: "-0.2728709",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDU",
    portName: "Cowfold",
    portContinent: "Europe",
  },
  {
    lat: "51.5722283",
    lng: "0.141995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDW",
    portName: "ChadwellHeath",
    portContinent: "Europe",
  },
  {
    lat: "51.8478747",
    lng: "-0.6643975000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDX",
    portName: "Cheddington",
    portContinent: "Europe",
  },
  {
    lat: "52.4668996",
    lng: "-2.1017347",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDY",
    portName: "Cradley",
    portContinent: "Europe",
  },
  {
    lat: "52.813165",
    lng: "-0.841475",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCDZ",
    portName: "Chadwell",
    portContinent: "Europe",
  },
  {
    lat: "52.8220882",
    lng: "-2.4962465",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCE2",
    portName: "ChildsErcall",
    portContinent: "Europe",
  },
  {
    lat: "-29.6462",
    lng: "30.4227997",
    country: "ZA",
    country_long: "South Africa",
    portCode: "GBCEA",
    portName: "Cleland",
    portContinent: "Europe",
  },
  {
    lat: "53.412797",
    lng: "-4.451735999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCEB",
    portName: "CemaesBay",
    portContinent: "Europe",
  },
  {
    lat: "51.7934409",
    lng: "-2.6174728",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCED",
    portName: "Coleford",
    portContinent: "Europe",
  },
  {
    lat: "53.5021101",
    lng: "-2.2368975",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCEE",
    portName: "CheethamHill",
    portContinent: "Europe",
  },
  {
    lat: "53.23263439999999",
    lng: "-2.6103157",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCEG",
    portName: "Chester",
    portContinent: "Europe",
  },
  {
    lat: "53.3929402",
    lng: "-2.1846391",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCEH",
    portName: "CheadleHeath",
    portContinent: "Europe",
  },
  {
    lat: "52.21553600000001",
    lng: "-4.359081",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCEI",
    portName: "New Quay",
    portContinent: "Europe",
  },
  {
    lat: "56.22584999999999",
    lng: "-2.688899",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCEL",
    portName: "Cellardyke",
    portContinent: "Europe",
  },
  {
    lat: "53.778598",
    lng: "-2.385428",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCEM",
    portName: "Clayton-Le-Moors",
    portContinent: "Europe",
  },
  {
    lat: "51.439895",
    lng: "-2.261508",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCEN",
    portName: "Colerne",
    portContinent: "Europe",
  },
  {
    lat: "52.208974",
    lng: "-1.614263",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCEO",
    portName: "Charlecote",
    portContinent: "Europe",
  },
  {
    lat: "41.6105937",
    lng: "-87.06419919999999",
    country: "US",
    country_long: "United States",
    portCode: "GBCER",
    portName: "Chesterton",
    portContinent: "Europe",
  },
  {
    lat: "37.3770935",
    lng: "-77.5049863",
    country: "US",
    country_long: "United States",
    portCode: "GBCES",
    portName: "Chesterfield",
    portContinent: "Europe",
  },
  {
    lat: "54.92375999999999",
    lng: "-3.576295",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCET",
    portName: "Carsethorn",
    portContinent: "Europe",
  },
  {
    lat: "51.70264050000001",
    lng: "-0.0343787",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCEU",
    portName: "Cheshunt, Hertfordshire",
    portContinent: "Europe",
  },
  {
    lat: "37.2660235",
    lng: "-96.9209135",
    country: "US",
    country_long: "United States",
    portCode: "GBCEY",
    portName: "Cowley",
    portContinent: "Europe",
  },
  {
    lat: "42.5998139",
    lng: "-71.3672838",
    country: "US",
    country_long: "United States",
    portCode: "GBCFD",
    portName: "Chelmsford",
    portContinent: "Europe",
  },
  {
    lat: "56.37654999999999",
    lng: "-3.841993999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCFE",
    portName: "Crieff",
    portContinent: "Europe",
  },
  {
    lat: "54.5145825",
    lng: "-5.889557",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCFF",
    portName: "Carryduff",
    portContinent: "Europe",
  },
  {
    lat: "54.7261871",
    lng: "-5.8101207",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCFG",
    portName: "Carrickfergus",
    portContinent: "Europe",
  },
  {
    lat: "51.005813",
    lng: "-0.143636",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCFL",
    portName: "Cuckfield",
    portContinent: "Europe",
  },
  {
    lat: "50.7695534",
    lng: "-2.0177914",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCFM",
    portName: "CorfeMullen",
    portContinent: "Europe",
  },
  {
    lat: "50.983953",
    lng: "-1.379259",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCFO",
    portName: "Chandlers Ford",
    portContinent: "Europe",
  },
  {
    lat: "40.8584328",
    lng: "-74.16375529999999",
    country: "US",
    country_long: "United States",
    portCode: "GBCFT",
    portName: "Clifton",
    portContinent: "Europe",
  },
  {
    lat: "52.3827821",
    lng: "-1.3407672",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCFZ",
    portName: "ChurchLawford",
    portContinent: "Europe",
  },
  {
    lat: "54.18044",
    lng: "-6.4106901",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCG3",
    portName: "Camlough",
    portContinent: "Europe",
  },
  {
    lat: "40.6584212",
    lng: "-74.2995928",
    country: "US",
    country_long: "United States",
    portCode: "GBCG9",
    portName: "Cranford",
    portContinent: "Europe",
  },
  {
    lat: "57.491008",
    lng: "-3.189215",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGA",
    portName: "Craigellachie",
    portContinent: "Europe",
  },
  {
    lat: "55.8861059",
    lng: "-2.1582781",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGB",
    portName: "Coldingham",
    portContinent: "Europe",
  },
  {
    lat: "51.360219",
    lng: "-0.340969",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGD",
    portName: "Claygate",
    portContinent: "Europe",
  },
  {
    lat: "56.188226",
    lng: "-3.0585759",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGE",
    portName: "CameronBridge",
    portContinent: "Europe",
  },
  {
    lat: "51.6320667",
    lng: "0.007362700000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGF",
    portName: "Chingford",
    portContinent: "Europe",
  },
  {
    lat: "56.381866",
    lng: "-5.715848",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGG",
    portName: "Croggan",
    portContinent: "Europe",
  },
  {
    lat: "51.141073",
    lng: "-0.483985",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGH",
    portName: "Cranleigh",
    portContinent: "Europe",
  },
  {
    lat: "52.758992",
    lng: "-2.5493774",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGI",
    portName: "Crudgington",
    portContinent: "Europe",
  },
  {
    lat: "55.833684",
    lng: "-5.95385",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGJ",
    portName: "Craighouse,IsleofJura",
    portContinent: "Europe",
  },
  {
    lat: "54.99904",
    lng: "-6.3325399",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGM",
    portName: "Cloughmills",
    portContinent: "Europe",
  },
  {
    lat: "52.236365",
    lng: "-0.789288",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGN",
    portName: "Cogenhoe",
    portContinent: "Europe",
  },
  {
    lat: "51.789844",
    lng: "-1.3485835",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGO",
    portName: "Cassington",
    portContinent: "Europe",
  },
  {
    lat: "53.13",
    lng: "-7.529999999999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBCGP",
    portName: "CloghanPoint/Carrickfergus",
    portContinent: "Europe",
  },
  {
    lat: "57.565426",
    lng: "-4.4364169",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGR",
    portName: "Cononbridge",
    portContinent: "Europe",
  },
  {
    lat: "51.363583",
    lng: "-0.296254",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGS",
    portName: "Chessington",
    portContinent: "Europe",
  },
  {
    lat: "51.5116571",
    lng: "-0.1240436",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGT",
    portName: "Covent Garden",
    portContinent: "Europe",
  },
  {
    lat: "51.666818",
    lng: "-1.078599",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGV",
    portName: "Chalgrove",
    portContinent: "Europe",
  },
  {
    lat: "53.830684",
    lng: "-1.898754",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGW",
    portName: "Cullingworth",
    portContinent: "Europe",
  },
  {
    lat: "51.6458299",
    lng: "-0.452023",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGX",
    portName: "CroxleyGreen",
    portContinent: "Europe",
  },
  {
    lat: "51.370697",
    lng: "-2.810072",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGY",
    portName: "Congresbury",
    portContinent: "Europe",
  },
  {
    lat: "49.987834",
    lng: "-5.18169",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCGZ",
    portName: "Cadgwith",
    portContinent: "Europe",
  },
  {
    lat: "51.8069342",
    lng: "-1.9252795",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCH6",
    portName: "Chedworth",
    portContinent: "Europe",
  },
  {
    lat: "51.8970257",
    lng: "-1.5261995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCH7",
    portName: "Chadlington",
    portContinent: "Europe",
  },
  {
    lat: "51.0117829",
    lng: "-3.9495642",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCH9",
    portName: "Chittlehampton",
    portContinent: "Europe",
  },
  {
    lat: "52.98488400000001",
    lng: "-1.986503",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHA",
    portName: "Cheadle",
    portContinent: "Europe",
  },
  {
    lat: "51.348056",
    lng: "-0.603858",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHB",
    portName: "Chobham",
    portContinent: "Europe",
  },
  {
    lat: "51.54187599999999",
    lng: "-1.041364",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHC",
    portName: "Checkendon",
    portContinent: "Europe",
  },
  {
    lat: "50.3754565",
    lng: "-4.1426565",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHD",
    portName: "Charleston (GBCHD), Plymouth, United Kingdom",
    portContinent: "Europe",
  },
  {
    lat: "53.31297989999999",
    lng: "-3.2692784",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHE",
    portName: "Mostyn",
    portContinent: "Europe",
  },
  {
    lat: "50.332907",
    lng: "-4.759194",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHF",
    portName: "Charlestown Harbor",
    portContinent: "Europe",
  },
  {
    lat: "51.211095",
    lng: "0.7946059999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHG",
    portName: "Charing",
    portContinent: "Europe",
  },
  {
    lat: "51.70264050000001",
    lng: "-0.0343787",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHH",
    portName: "Cheshunt",
    portContinent: "Europe",
  },
  {
    lat: "51.62628100000001",
    lng: "0.080647",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHI",
    portName: "Chigwell",
    portContinent: "Europe",
  },
  {
    lat: "50.738506",
    lng: "-2.903384",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHJ",
    portName: "Charmouth",
    portContinent: "Europe",
  },
  {
    lat: "51.4876272",
    lng: "-0.2671732",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHK",
    portName: "Chiswick",
    portContinent: "Europe",
  },
  {
    lat: "51.5759029",
    lng: "-1.154471",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHL",
    portName: "Cholsey",
    portContinent: "Europe",
  },
  {
    lat: "51.253549",
    lng: "1.0158654",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHM",
    portName: "Chartham",
    portContinent: "Europe",
  },
  {
    lat: "42.13565",
    lng: "-71.970074",
    country: "US",
    country_long: "United States",
    portCode: "GBCHN",
    portName: "Charlton",
    portContinent: "Europe",
  },
  {
    lat: "44.0288803",
    lng: "-88.16287849999999",
    country: "US",
    country_long: "United States",
    portCode: "GBCHO",
    portName: "Chilton",
    portContinent: "Europe",
  },
  {
    lat: "51.943544",
    lng: "-1.542189",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHP",
    portName: "Chipping Norton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCHQ",
    portName: "Chale",
    portContinent: "Europe",
  },
  {
    lat: "53.653511",
    lng: "-2.632596",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHR",
    portName: "Chorley",
    portContinent: "Europe",
  },
  {
    lat: "52.67377279999999",
    lng: "-1.9286819",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHS",
    portName: "Chasetown",
    portContinent: "Europe",
  },
  {
    lat: "51.641856",
    lng: "-2.673804",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHT",
    portName: "Chepstow",
    portContinent: "Europe",
  },
  {
    lat: "50.606943",
    lng: "-3.601208",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHU",
    portName: "Chudleigh",
    portContinent: "Europe",
  },
  {
    lat: "52.314806",
    lng: "-0.545835",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHV",
    portName: "Chelveston",
    portContinent: "Europe",
  },
  {
    lat: "51.655632",
    lng: "-0.5161829",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHW",
    portName: "Chorleywood",
    portContinent: "Europe",
  },
  {
    lat: "52.870758",
    lng: "-1.4428739",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHX",
    portName: "Chellaston/Derby",
    portContinent: "Europe",
  },
  {
    lat: "51.856479",
    lng: "-3.13551",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHY",
    portName: "Crughywel (Crickhowell)",
    portContinent: "Europe",
  },
  {
    lat: "53.2706609",
    lng: "-2.2810579",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCHZ",
    portName: "Chelford",
    portContinent: "Europe",
  },
  {
    lat: "56.3931388",
    lng: "-4.6190155",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIA",
    portName: "Crianlarich",
    portContinent: "Europe",
  },
  {
    lat: "50.9314919",
    lng: "-2.757157",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIB",
    portName: "Chiselborough",
    portContinent: "Europe",
  },
  {
    lat: "50.973419",
    lng: "0.223756",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIC",
    portName: "CrossInHand",
    portContinent: "Europe",
  },
  {
    lat: "56.255885",
    lng: "-4.937012",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCID",
    portName: "Cairndow",
    portContinent: "Europe",
  },
  {
    lat: "56.377967",
    lng: "-3.995171",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIE",
    portName: "Comrie",
    portContinent: "Europe",
  },
  {
    lat: "52.7144714",
    lng: "-1.1038553",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIG",
    portName: "Cossington",
    portContinent: "Europe",
  },
  {
    lat: "50.9654871",
    lng: "-1.4196066",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIH",
    portName: "Chilworth",
    portContinent: "Europe",
  },
  {
    lat: "50.2731939",
    lng: "-3.697187",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCII",
    portName: "Chillington",
    portContinent: "Europe",
  },
  {
    lat: "52.9322792",
    lng: "-3.0567818",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIK",
    portName: "Chirk",
    portContinent: "Europe",
  },
  {
    lat: "41.3431688",
    lng: "-70.74475009999999",
    country: "US",
    country_long: "United States",
    portCode: "GBCIL",
    portName: "Chilmark",
    portContinent: "Europe",
  },
  {
    lat: "50.489797",
    lng: "-4.644602",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIM",
    portName: "Cardinham",
    portContinent: "Europe",
  },
  {
    lat: "51.825303",
    lng: "-2.5009579",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIN",
    portName: "Cinderford",
    portContinent: "Europe",
  },
  {
    lat: "53.4805944",
    lng: "-1.2263372",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIO",
    portName: "Conisbrough",
    portContinent: "Europe",
  },
  {
    lat: "52.606572",
    lng: "0.436092",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIP",
    portName: "Crimplesham",
    portContinent: "Europe",
  },
  {
    lat: "51.718495",
    lng: "-1.968243",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIR",
    portName: "Cirencester",
    portContinent: "Europe",
  },
  {
    lat: "54.369071",
    lng: "-3.07587",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIS",
    portName: "Coniston",
    portContinent: "Europe",
  },
  {
    lat: "57.3477039",
    lng: "-1.9366292",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIT",
    portName: "Collieston",
    portContinent: "Europe",
  },
  {
    lat: "51.513001",
    lng: "-1.732646",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCIW",
    portName: "Chiseldon",
    portContinent: "Europe",
  },
  {
    lat: "50.956954",
    lng: "-0.018524",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCJY",
    portName: "Chailey",
    portContinent: "Europe",
  },
  {
    lat: "51.260089",
    lng: "-0.8406039999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKA",
    portName: "ChurchCrookham",
    portContinent: "Europe",
  },
  {
    lat: "52.606753",
    lng: "0.642176",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKC",
    portName: "CockleyCley",
    portContinent: "Europe",
  },
  {
    lat: "54.715131",
    lng: "-1.743545",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKD",
    portName: "Crook, Durham",
    portContinent: "Europe",
  },
  {
    lat: "51.6380709",
    lng: "-1.856351",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKE",
    portName: "Cricklade",
    portContinent: "Europe",
  },
  {
    lat: "51.558437",
    lng: "-0.707741",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKH",
    portName: "Cookham",
    portContinent: "Europe",
  },
  {
    lat: "58.6061954",
    lng: "-3.6705258",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKK",
    portName: "Crosskirk",
    portContinent: "Europe",
  },
  {
    lat: "52.419277",
    lng: "-2.234163",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKL",
    portName: "Cookley",
    portContinent: "Europe",
  },
  {
    lat: "54.663261",
    lng: "-3.367985",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKM",
    portName: "Cockermouth",
    portContinent: "Europe",
  },
  {
    lat: "58.24288000000001",
    lng: "-5.344998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKN",
    portName: "Culkein",
    portContinent: "Europe",
  },
  {
    lat: "54.5949499",
    lng: "-7.04621",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKO",
    portName: "Carrickmore",
    portContinent: "Europe",
  },
  {
    lat: "55.932112",
    lng: "-2.360664",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKP",
    portName: "Cockburnspath",
    portContinent: "Europe",
  },
  {
    lat: "50.624634",
    lng: "-2.503566",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKR",
    portName: "Chickerell",
    portContinent: "Europe",
  },
  {
    lat: "51.6374183",
    lng: "0.7887406",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKS",
    portName: "Creeksea",
    portContinent: "Europe",
  },
  {
    lat: "51.7067939",
    lng: "-2.670103",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKW",
    portName: "Brockweir",
    portContinent: "Europe",
  },
  {
    lat: "55.900099",
    lng: "-4.404774",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCKX",
    portName: "Clydebank/Exeter",
    portContinent: "Europe",
  },
  {
    lat: "53.9189605",
    lng: "-7.264829799999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBCKY",
    portName: "Crosskeys",
    portContinent: "Europe",
  },
  {
    lat: "35.2977778",
    lng: "-81.9813889",
    country: "US",
    country_long: "United States",
    portCode: "GBCL3",
    portName: "Cleghorn",
    portContinent: "Europe",
  },
  {
    lat: "35.650711",
    lng: "-78.4563914",
    country: "US",
    country_long: "United States",
    portCode: "GBCL4",
    portName: "Clayton",
    portContinent: "Europe",
  },
  {
    lat: "51.9656469",
    lng: "0.1431598",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCL5",
    portName: "Clavering",
    portContinent: "Europe",
  },
  {
    lat: "50.9355606",
    lng: "-1.0000067",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCL7",
    portName: "Clanfield",
    portContinent: "Europe",
  },
  {
    lat: "56.3061675",
    lng: "-3.1556829",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCL8",
    portName: "Collessie",
    portContinent: "Europe",
  },
  {
    lat: "56.107682",
    lng: "-3.752998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLA",
    portName: "Clackmannan",
    portContinent: "Europe",
  },
  {
    lat: "52.75421799999999",
    lng: "-0.532535",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLB",
    portName: "Castle Bytham",
    portContinent: "Europe",
  },
  {
    lat: "53.163795",
    lng: "-1.414784",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLC",
    portName: "ClayCross",
    portContinent: "Europe",
  },
  {
    lat: "55.945668",
    lng: "-3.992534",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLD",
    portName: "Cumbernauld",
    portContinent: "Europe",
  },
  {
    lat: "34.2786409",
    lng: "-112.2329398",
    country: "US",
    country_long: "United States",
    portCode: "GBCLE",
    portName: "Cleator",
    portContinent: "Europe",
  },
  {
    lat: "53.7800142",
    lng: "-0.9971095000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLF",
    portName: "Cliffe (GBCLF), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5581887",
    lng: "-0.2163581",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLG",
    portName: "Cricklewood/London",
    portContinent: "Europe",
  },
  {
    lat: "51.69429299999999",
    lng: "-3.898795",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLH",
    portName: "Clydach",
    portContinent: "Europe",
  },
  {
    lat: "53.871098",
    lng: "-2.393083",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLI",
    portName: "Clitheroe",
    portContinent: "Europe",
  },
  {
    lat: "55.7571583",
    lng: "-5.3931986",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLJ",
    portName: "Claonaig",
    portContinent: "Europe",
  },
  {
    lat: "52.9563599",
    lng: "-1.086378",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLK",
    portName: "Colwick",
    portContinent: "Europe",
  },
  {
    lat: "52.499599",
    lng: "-1.706495",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLL",
    portName: "Coleshill",
    portContinent: "Europe",
  },
  {
    lat: "58.2513049",
    lng: "-5.2178171",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLM",
    portName: "Culkein/Drumbeg",
    portContinent: "Europe",
  },
  {
    lat: "53.85564",
    lng: "-2.176991",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLN",
    portName: "Colne",
    portContinent: "Europe",
  },
  {
    lat: "50.74063",
    lng: "-3.070422",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLO",
    portName: "Colyton",
    portContinent: "Europe",
  },
  {
    lat: "51.4658813",
    lng: "-0.1413263",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLP",
    portName: "Clapham",
    portContinent: "Europe",
  },
  {
    lat: "55.1325802",
    lng: "-6.6646102",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLR",
    portName: "Coleraine",
    portContinent: "Europe",
  },
  {
    lat: "51.789534",
    lng: "1.153035",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLS",
    portName: "Clacton-On-Sea",
    portContinent: "Europe",
  },
  {
    lat: "51.8993855",
    lng: "-2.0782533",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLT",
    portName: "Cheltenham",
    portContinent: "Europe",
  },
  {
    lat: "51.330568",
    lng: "-2.542086",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLU",
    portName: "Clutton",
    portContinent: "Europe",
  },
  {
    lat: "53.829915",
    lng: "-1.684897",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLV",
    portName: "Calverley",
    portContinent: "Europe",
  },
  {
    lat: "37.7791777",
    lng: "-97.5360323",
    country: "US",
    country_long: "United States",
    portCode: "GBCLW",
    portName: "Colwich",
    portContinent: "Europe",
  },
  {
    lat: "58.201502",
    lng: "-6.744959",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLX",
    portName: "Callanish",
    portContinent: "Europe",
  },
  {
    lat: "55.900099",
    lng: "-4.404774",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLY",
    portName: "Clydebank",
    portContinent: "Europe",
  },
  {
    lat: "53.85564",
    lng: "-2.176991",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCLZ",
    portName: "Colne",
    portContinent: "Europe",
  },
  {
    lat: "52.974573",
    lng: "-3.073175",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMA",
    portName: "Cefn-Mawr",
    portContinent: "Europe",
  },
  {
    lat: "50.21277",
    lng: "-5.2947749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMB",
    portName: "Camborne",
    portContinent: "Europe",
  },
  {
    lat: "51.271204",
    lng: "1.014196",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMC",
    portName: "CharthamHatch",
    portContinent: "Europe",
  },
  {
    lat: "55.9695532",
    lng: "-3.3073398",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMD",
    portName: "Cramond",
    portContinent: "Europe",
  },
  {
    lat: "51.3619271",
    lng: "-0.2189255",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCME",
    portName: "Cheam",
    portContinent: "Europe",
  },
  {
    lat: "50.622055",
    lng: "-4.682364",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMF",
    portName: "Camelford",
    portContinent: "Europe",
  },
  {
    lat: "52.1950788",
    lng: "0.1312729",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMG",
    portName: "Cambridge",
    portContinent: "Europe",
  },
  {
    lat: "50.91396599999999",
    lng: "-3.869414999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMH",
    portName: "Chulmleigh",
    portContinent: "Europe",
  },
  {
    lat: "51.75257",
    lng: "-0.77149",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMI",
    portName: "CoombeHill",
    portContinent: "Europe",
  },
  {
    lat: "53.3252584",
    lng: "-6.3089222",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBCML",
    portName: "Crumlin",
    portContinent: "Europe",
  },
  {
    lat: "52.37843400000001",
    lng: "-2.4834679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMM",
    portName: "CleoburyMortimer",
    portContinent: "Europe",
  },
  {
    lat: "54.487235",
    lng: "-0.9816189999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMN",
    portName: "Commondale",
    portContinent: "Europe",
  },
  {
    lat: "50.4320841",
    lng: "-4.9423812",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMO",
    portName: "SaintColumbMajor",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCMP",
    portName: "Crombie",
    portContinent: "Europe",
  },
  {
    lat: "51.85762",
    lng: "-4.312131",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMR",
    portName: "Carmarthen",
    portContinent: "Europe",
  },
  {
    lat: "55.7649659",
    lng: "-4.925628199999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMS",
    portName: "Cumbrae Slip (GBCMS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.086136",
    lng: "-1.5808",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMT",
    portName: "Cramlington",
    portContinent: "Europe",
  },
  {
    lat: "55.45355199999999",
    lng: "-4.265829",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCMY",
    portName: "Cumnock",
    portContinent: "Europe",
  },
  {
    lat: "57.06295280000001",
    lng: "-6.5487042",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNA",
    portName: "Canna (GBCNA), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.280233",
    lng: "1.0789089",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNB",
    portName: "Canterbury",
    portContinent: "Europe",
  },
  {
    lat: "56.090766",
    lng: "-5.558376",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNC",
    portName: "Crinan",
    portContinent: "Europe",
  },
  {
    lat: "55.801765",
    lng: "-2.209192",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCND",
    portName: "Chirnside",
    portContinent: "Europe",
  },
  {
    lat: "51.43933",
    lng: "-2.003884",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNE",
    portName: "Calne",
    portContinent: "Europe",
  },
  {
    lat: "54.127363",
    lng: "-2.768112",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNF",
    portName: "Carnforth",
    portContinent: "Europe",
  },
  {
    lat: "53.10509099999999",
    lng: "-0.175141",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNG",
    portName: "Coningsby",
    portContinent: "Europe",
  },
  {
    lat: "51.291936",
    lng: "-1.060384",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNH",
    portName: "Chineham",
    portContinent: "Europe",
  },
  {
    lat: "57.34712599999999",
    lng: "-4.7630259",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNI",
    portName: "Cannich",
    portContinent: "Europe",
  },
  {
    lat: "52.69994080000001",
    lng: "-2.0218293",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNK",
    portName: "Cannock",
    portContinent: "Europe",
  },
  {
    lat: "45.5661759",
    lng: "-75.782459",
    country: "CA",
    country_long: "Canada",
    portCode: "GBCNL",
    portName: "Cantley",
    portContinent: "Europe",
  },
  {
    lat: "60.3206726",
    lng: "-1.2885775",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNM",
    portName: "CentralMainland(Shetland)",
    portContinent: "Europe",
  },
  {
    lat: "50.502332",
    lng: "-4.315735",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNN",
    portName: "Callington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCNP",
    portName: "SaintCatherine'sPoint",
    portContinent: "Europe",
  },
  {
    lat: "53.22006039999999",
    lng: "-3.0589149",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNQ",
    portName: "Connah'S Quay, Flintshire",
    portContinent: "Europe",
  },
  {
    lat: "53.6318569",
    lng: "-2.670545",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNR",
    portName: "CharnockRichard",
    portContinent: "Europe",
  },
  {
    lat: "56.502565",
    lng: "-2.70325",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNS",
    portName: "Carnoustie",
    portContinent: "Europe",
  },
  {
    lat: "54.851797",
    lng: "-1.833026",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNT",
    portName: "Consett",
    portContinent: "Europe",
  },
  {
    lat: "56.4683152",
    lng: "-5.698121599999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNU",
    portName: "Craignure",
    portContinent: "Europe",
  },
  {
    lat: "53.139551",
    lng: "-4.273911",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNV",
    portName: "Caernarfon Harbor",
    portContinent: "Europe",
  },
  {
    lat: "51.941499",
    lng: "-1.6086339",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNW",
    portName: "Cornwell",
    portContinent: "Europe",
  },
  {
    lat: "50.3311736",
    lng: "-4.2020032",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNX",
    portName: "Cawsand",
    portContinent: "Europe",
  },
  {
    lat: "53.3382689",
    lng: "-1.939154",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNY",
    portName: "Chinley",
    portContinent: "Europe",
  },
  {
    lat: "55.9693996",
    lng: "-2.9626269",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCNZ",
    portName: "Cockenzie",
    portContinent: "Europe",
  },
  {
    lat: "40.9177251",
    lng: "-111.3993634",
    country: "US",
    country_long: "United States",
    portCode: "GBCOA",
    portName: "Coalville",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCOB",
    portName: "Cobham",
    portContinent: "Europe",
  },
  {
    lat: "54.5403238",
    lng: "-6.702247499999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOD",
    portName: "Coalisland",
    portContinent: "Europe",
  },
  {
    lat: "53.27422",
    lng: "-1.2652699",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOE",
    portName: "Clowne",
    portContinent: "Europe",
  },
  {
    lat: "51.7934409",
    lng: "-2.6174728",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOF",
    portName: "Coleford",
    portContinent: "Europe",
  },
  {
    lat: "51.870799",
    lng: "0.692681",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOG",
    portName: "Coggeshall",
    portContinent: "Europe",
  },
  {
    lat: "56.114407",
    lng: "-3.341848",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOH",
    portName: "Cowdenbeath",
    portContinent: "Europe",
  },
  {
    lat: "53.351359",
    lng: "-1.113712",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOI",
    portName: "CarltoninLindrick",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCOK",
    portName: "Crook",
    portContinent: "Europe",
  },
  {
    lat: "51.895927",
    lng: "0.8918740000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOL",
    portName: "Colchester",
    portContinent: "Europe",
  },
  {
    lat: "53.162856",
    lng: "-2.218923",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCON",
    portName: "Congleton",
    portContinent: "Europe",
  },
  {
    lat: "40.0491184",
    lng: "-74.5626697",
    country: "US",
    country_long: "United States",
    portCode: "GBCOO",
    portName: "Cookstown",
    portContinent: "Europe",
  },
  {
    lat: "51.268533",
    lng: "-2.502531",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOP",
    portName: "Chilcompton",
    portContinent: "Europe",
  },
  {
    lat: "53.22006039999999",
    lng: "-3.0589149",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOQ",
    portName: "Connah'sQuay",
    portContinent: "Europe",
  },
  {
    lat: "56.84406509999999",
    lng: "-5.1286669",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOR",
    portName: "Corpach (GBCOR), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.8433024",
    lng: "-1.0742463",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOS",
    portName: "Cosham",
    portContinent: "Europe",
  },
  {
    lat: "55.862241",
    lng: "-4.019337",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOT",
    portName: "Coatbridge",
    portContinent: "Europe",
  },
  {
    lat: "56.045251",
    lng: "-4.869738",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOU",
    portName: "Coulport",
    portContinent: "Europe",
  },
  {
    lat: "50.0243618",
    lng: "-5.0975682",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOV",
    portName: "Coverack",
    portContinent: "Europe",
  },
  {
    lat: "50.7624098",
    lng: "-1.2973223",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOW",
    portName: "Cowes Harbour",
    portContinent: "Europe",
  },
  {
    lat: "55.96113",
    lng: "-4.039556",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOX",
    portName: "Croy",
    portContinent: "Europe",
  },
  {
    lat: "50.63261",
    lng: "-4.174931",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOY",
    portName: "Coryton",
    portContinent: "Europe",
  },
  {
    lat: "51.7404553",
    lng: "-0.264077",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCOZ",
    portName: "Colney Heath",
    portContinent: "Europe",
  },
  {
    lat: "53.22382",
    lng: "0.3370369",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCPD",
    portName: "Chapel Saint Leonards",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCPF",
    portName: "CaptainField",
    portContinent: "Europe",
  },
  {
    lat: "54.5439",
    lng: "-6.927779999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCPG",
    portName: "Cappagh",
    portContinent: "Europe",
  },
  {
    lat: "51.139809",
    lng: "-0.121265",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCPH",
    portName: "Copthorne",
    portContinent: "Europe",
  },
  {
    lat: "53.323988",
    lng: "-1.9129233",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCPL",
    portName: "Chapel En Le Frith",
    portContinent: "Europe",
  },
  {
    lat: "39.2933631",
    lng: "-90.94882199999999",
    country: "US",
    country_long: "United States",
    portCode: "GBCPM",
    portName: "Clopton",
    portContinent: "Europe",
  },
  {
    lat: "51.461514",
    lng: "-2.1195157",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCPN",
    portName: "Chippenham",
    portContinent: "Europe",
  },
  {
    lat: "33.8958492",
    lng: "-118.2200712",
    country: "US",
    country_long: "United States",
    portCode: "GBCPO",
    portName: "Compton",
    portContinent: "Europe",
  },
  {
    lat: "53.8288591",
    lng: "-1.5379202",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCPP",
    portName: "ChapelAllerton",
    portContinent: "Europe",
  },
  {
    lat: "52.1356548",
    lng: "-1.2718584",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCPR",
    portName: "ChippingWarden",
    portContinent: "Europe",
  },
  {
    lat: "53.160813",
    lng: "-1.126034",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCPS",
    portName: "Clipstone",
    portContinent: "Europe",
  },
  {
    lat: "53.160813",
    lng: "-1.126034",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCPT",
    portName: "Clipstone",
    portContinent: "Europe",
  },
  {
    lat: "53.2612738",
    lng: "-2.9518011",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCPU",
    portName: "Capenhurst",
    portContinent: "Europe",
  },
  {
    lat: "54.06341099999999",
    lng: "-1.47055",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCPV",
    portName: "Copgrove",
    portContinent: "Europe",
  },
  {
    lat: "53.8152222",
    lng: "-1.5321797",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCPW",
    portName: "Chapeltown",
    portContinent: "Europe",
  },
  {
    lat: "53.5196352",
    lng: "-2.2399744",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCQM",
    portName: "Crumpsall",
    portContinent: "Europe",
  },
  {
    lat: "50.615488",
    lng: "-3.448586",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCQQ",
    portName: "Cockwood",
    portContinent: "Europe",
  },
  {
    lat: "53.22006039999999",
    lng: "-3.0589149",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCQY",
    portName: "ConnahsQuay",
    portContinent: "Europe",
  },
  {
    lat: "54.29134810000001",
    lng: "-0.8378211",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCR5",
    portName: "Cropton",
    portContinent: "Europe",
  },
  {
    lat: "55.034123",
    lng: "-3.404112",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCR9",
    portName: "Carrutherstown",
    portContinent: "Europe",
  },
  {
    lat: "55.591379",
    lng: "-5.4683",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRA",
    portName: "Carradale",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCRB",
    portName: "Crosby",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCRC",
    portName: "Church",
    portContinent: "Europe",
  },
  {
    lat: "55.647035",
    lng: "-2.223397",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRD",
    portName: "Cornhill-On-Tweed",
    portContinent: "Europe",
  },
  {
    lat: "53.10040499999999",
    lng: "-2.4438209",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRE",
    portName: "Crewe",
    portContinent: "Europe",
  },
  {
    lat: "52.0686347",
    lng: "-0.6087410999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRF",
    portName: "Cranfield",
    portContinent: "Europe",
  },
  {
    lat: "54.450852",
    lng: "-6.3938712",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRG",
    portName: "Craigavon",
    portContinent: "Europe",
  },
  {
    lat: "52.471815",
    lng: "-2.082075",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRH",
    portName: "Cradley Heath",
    portContinent: "Europe",
  },
  {
    lat: "52.347591",
    lng: "-1.136643",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRI",
    portName: "Crick",
    portContinent: "Europe",
  },
  {
    lat: "56.260551",
    lng: "-2.6275909",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRJ",
    portName: "Crail",
    portContinent: "Europe",
  },
  {
    lat: "50.8830079",
    lng: "-2.795877",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRK",
    portName: "Crewkerne",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCRL",
    portName: "Croft",
    portContinent: "Europe",
  },
  {
    lat: "51.997949",
    lng: "-1.2111171",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRM",
    portName: "Croughton",
    portContinent: "Europe",
  },
  {
    lat: "57.680609",
    lng: "-4.034678",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRN",
    portName: "Cromarty (GBCRN), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.3769529",
    lng: "-0.0956895",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRO",
    portName: "Croydon",
    portContinent: "Europe",
  },
  {
    lat: "52.1016791",
    lng: "-2.0033229",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRP",
    portName: "Cropthorne",
    portContinent: "Europe",
  },
  {
    lat: "52.5555071",
    lng: "-3.5305871",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRQ",
    portName: "Carno",
    portContinent: "Europe",
  },
  {
    lat: "52.931448",
    lng: "1.301866",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRR",
    portName: "Cromer",
    portContinent: "Europe",
  },
  {
    lat: "51.43144299999999",
    lng: "-2.189674",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRS",
    portName: "Corsham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCRT",
    portName: "Carrington",
    portContinent: "Europe",
  },
  {
    lat: "-38.1054204",
    lng: "145.2818338",
    country: "AU",
    country_long: "Australia",
    portCode: "GBCRU",
    portName: "Cranbourne",
    portContinent: "Europe",
  },
  {
    lat: "52.451849",
    lng: "1.693904",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRV",
    portName: "Carlton Colville",
    portContinent: "Europe",
  },
  {
    lat: "51.61068520000001",
    lng: "-2.7647246",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRW",
    portName: "Caerwent",
    portContinent: "Europe",
  },
  {
    lat: "55.4721349",
    lng: "-1.593323",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRX",
    portName: "Craster",
    portContinent: "Europe",
  },
  {
    lat: "51.4501131",
    lng: "0.1822046",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCRY",
    portName: "Crayford",
    portContinent: "Europe",
  },
  {
    lat: "56.0809963",
    lng: "-6.2117684",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSA",
    portName: "Colonsay",
    portContinent: "Europe",
  },
  {
    lat: "52.502812",
    lng: "-1.791722",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSB",
    portName: "CastleBromwich",
    portContinent: "Europe",
  },
  {
    lat: "52.6486469",
    lng: "1.724422",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSC",
    portName: "CaisteronSea",
    portContinent: "Europe",
  },
  {
    lat: "51.319941",
    lng: "-0.141267",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSD",
    portName: "Coulsdon",
    portContinent: "Europe",
  },
  {
    lat: "51.4869429",
    lng: "-0.170037",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSE",
    portName: "Chelsea/London",
    portContinent: "Europe",
  },
  {
    lat: "52.6463589",
    lng: "-2.297577",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSF",
    portName: "Cosford",
    portContinent: "Europe",
  },
  {
    lat: "51.6297828",
    lng: "-0.577213",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSG",
    portName: "ChalfontSaintGiles",
    portContinent: "Europe",
  },
  {
    lat: "51.709401",
    lng: "-0.612333",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSH",
    portName: "Chesham",
    portContinent: "Europe",
  },
  {
    lat: "56.32027799999999",
    lng: "-5.978611",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSI",
    portName: "Carsaig",
    portContinent: "Europe",
  },
  {
    lat: "53.9189605",
    lng: "-7.264829799999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBCSK",
    portName: "CrossKeys",
    portContinent: "Europe",
  },
  {
    lat: "51.412626",
    lng: "0.077487",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSL",
    portName: "Chislehurst",
    portContinent: "Europe",
  },
  {
    lat: "51.157751",
    lng: "-2.0463802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSM",
    portName: "Codford Saint Mary",
    portContinent: "Europe",
  },
  {
    lat: "53.351786",
    lng: "-2.894065",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSN",
    portName: "Garston (GBCSN), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5351659",
    lng: "-2.3909379",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSO",
    portName: "ChippingSodbury",
    portContinent: "Europe",
  },
  {
    lat: "51.6123242",
    lng: "-0.5561674",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSP",
    portName: "ChalfontSaintPeter",
    portContinent: "Europe",
  },
  {
    lat: "53.23263439999999",
    lng: "-2.6103157",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSR",
    portName: "Chester",
    portContinent: "Europe",
  },
  {
    lat: "51.85672",
    lng: "0.605984",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSS",
    portName: "Cressing",
    portContinent: "Europe",
  },
  {
    lat: "50.83761000000001",
    lng: "-0.774936",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCST",
    portName: "Chichester",
    portContinent: "Europe",
  },
  {
    lat: "53.42576099999999",
    lng: "-2.442641",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCSU",
    portName: "Cadishead",
    portContinent: "Europe",
  },
  {
    lat: "54.0750026",
    lng: "-4.6547116",
    country: "IM",
    country_long: "Isle of Man",
    portCode: "GBCSW",
    portName: "Castletown",
    portContinent: "Europe",
  },
  {
    lat: "52.728035",
    lng: "1.36535",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTA",
    portName: "Coltishall",
    portContinent: "Europe",
  },
  {
    lat: "54.940415",
    lng: "-3.931414",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTD",
    portName: "CastleDouglas",
    portContinent: "Europe",
  },
  {
    lat: "54.8591161",
    lng: "-1.5740888",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTE",
    portName: "Chester-le-Street",
    portContinent: "Europe",
  },
  {
    lat: "53.72346599999999",
    lng: "-1.345968",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTF",
    portName: "Castleford",
    portContinent: "Europe",
  },
  {
    lat: "52.911411",
    lng: "-1.041255",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTG",
    portName: "Cotgrave",
    portContinent: "Europe",
  },
  {
    lat: "53.557378",
    lng: "-0.029435",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTH",
    portName: "Cleethorpes",
    portContinent: "Europe",
  },
  {
    lat: "55.533635",
    lng: "-1.707851",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTI",
    portName: "Chathill",
    portContinent: "Europe",
  },
  {
    lat: "52.7402665",
    lng: "1.5342458",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTL",
    portName: "Catfield",
    portContinent: "Europe",
  },
  {
    lat: "51.380952",
    lng: "0.52213",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTM",
    portName: "Chatham (GBCTM), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.0750026",
    lng: "-4.6547116",
    country: "IM",
    country_long: "Isle of Man",
    portCode: "GBCTN",
    portName: "Castletown",
    portContinent: "Europe",
  },
  {
    lat: "51.365018",
    lng: "-0.164921",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTO",
    portName: "Carshalton",
    portContinent: "Europe",
  },
  {
    lat: "53.021631",
    lng: "-0.6045819",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTP",
    portName: "Caythorpe",
    portContinent: "Europe",
  },
  {
    lat: "50.939661",
    lng: "-0.9607100000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTQ",
    portName: "Chalton",
    portContinent: "Europe",
  },
  {
    lat: "39.8161865",
    lng: "-105.1639743",
    country: "US",
    country_long: "United States",
    portCode: "GBCTR",
    portName: "Caterham",
    portContinent: "Europe",
  },
  {
    lat: "43.6106223",
    lng: "-73.17983199999999",
    country: "US",
    country_long: "United States",
    portCode: "GBCTS",
    portName: "Castleton",
    portContinent: "Europe",
  },
  {
    lat: "52.5041631",
    lng: "-0.4643229",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTT",
    portName: "Cotterstock",
    portContinent: "Europe",
  },
  {
    lat: "-43.5320214",
    lng: "172.6305589",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBCTU",
    portName: "Christchurch",
    portContinent: "Europe",
  },
  {
    lat: "53.068533",
    lng: "-2.043669",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCTW",
    portName: "Cheddleton",
    portContinent: "Europe",
  },
  {
    lat: "40.90648729999999",
    lng: "-72.74343309999999",
    country: "US",
    country_long: "United States",
    portCode: "GBCTX",
    portName: "Calverton",
    portContinent: "Europe",
  },
  {
    lat: "45.5661759",
    lng: "-75.782459",
    country: "CA",
    country_long: "Canada",
    portCode: "GBCTY",
    portName: "Cantley",
    portContinent: "Europe",
  },
  {
    lat: "53.273029",
    lng: "-1.626236",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCU8",
    portName: "Curbar",
    portContinent: "Europe",
  },
  {
    lat: "56.27384199999999",
    lng: "-5.624787",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUA",
    portName: "CuanFerry,Seil",
    portContinent: "Europe",
  },
  {
    lat: "55.2979489",
    lng: "-6.207279499999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUB",
    portName: "ChurchBay,RathlinIsland",
    portContinent: "Europe",
  },
  {
    lat: "51.005813",
    lng: "-0.143636",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUC",
    portName: "Cuckfield, West Sussex",
    portContinent: "Europe",
  },
  {
    lat: "53.241136",
    lng: "-2.601223",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUD",
    portName: "Cuddington",
    portContinent: "Europe",
  },
  {
    lat: "55.12578",
    lng: "-6.04293",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUE",
    portName: "Cushendun",
    portContinent: "Europe",
  },
  {
    lat: "53.698455",
    lng: "-2.269958",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUF",
    portName: "Cloughfold",
    portContinent: "Europe",
  },
  {
    lat: "51.77594999999999",
    lng: "-1.519134",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUG",
    portName: "Curbridge",
    portContinent: "Europe",
  },
  {
    lat: "51.65209609999999",
    lng: "-1.2702734",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUH",
    portName: "Culham",
    portContinent: "Europe",
  },
  {
    lat: "54.5772323",
    lng: "-2.7974835",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUJ",
    portName: "Cumbria",
    portContinent: "Europe",
  },
  {
    lat: "50.85522899999999",
    lng: "-3.391701",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUL",
    portName: "Cullompton",
    portContinent: "Europe",
  },
  {
    lat: "43.6463353",
    lng: "-79.4089673",
    country: "CA",
    country_long: "Canada",
    portCode: "GBCUM",
    portName: "Cumbraes",
    portContinent: "Europe",
  },
  {
    lat: "57.691635",
    lng: "-2.821937",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUN",
    portName: "Cullen",
    portContinent: "Europe",
  },
  {
    lat: "56.320235",
    lng: "-3.010137",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUP",
    portName: "Cupar",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCUR",
    portName: "Curlew",
    portContinent: "Europe",
  },
  {
    lat: "55.0797085",
    lng: "-6.0577855",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUS",
    portName: "Cushendall",
    portContinent: "Europe",
  },
  {
    lat: "52.31637",
    lng: "-2.177401",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUT",
    portName: "CutnallGreen",
    portContinent: "Europe",
  },
  {
    lat: "60.70540599999999",
    lng: "-1.0111699",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCUV",
    portName: "Cullivoe (GBCUV), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCV9",
    portName: "Cove",
    portContinent: "Europe",
  },
  {
    lat: "55.92186299999999",
    lng: "-5.145398",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCVA",
    portName: "Colintraive",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCVE",
    portName: "Cove",
    portContinent: "Europe",
  },
  {
    lat: "51.91918889999999",
    lng: "-1.1454428",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCVF",
    portName: "Caversfield",
    portContinent: "Europe",
  },
  {
    lat: "52.20647",
    lng: "0.612501",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCVG",
    portName: "Chevington",
    portContinent: "Europe",
  },
  {
    lat: "-64.86733219999999",
    lng: "-62.80723259999999",
    country: "AQ",
    country_long: "Antarctica",
    portCode: "GBCVI",
    portName: "Cove(Leith)",
    portContinent: "Europe",
  },
  {
    lat: "50.9981276",
    lng: "-4.3991182",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCVL",
    portName: "Clovelly",
    portContinent: "Europe",
  },
  {
    lat: "52.2972101",
    lng: "0.5826000000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCVM",
    portName: "Cavenham",
    portContinent: "Europe",
  },
  {
    lat: "51.476798",
    lng: "-0.966547",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCVR",
    portName: "Caversham",
    portContinent: "Europe",
  },
  {
    lat: "52.4128163",
    lng: "-1.5089521",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCVT",
    portName: "Coventry",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCVW",
    portName: "ConvoysWharf",
    portContinent: "Europe",
  },
  {
    lat: "52.222596",
    lng: "0.4626659999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCVY",
    portName: "Cheveley",
    portContinent: "Europe",
  },
  {
    lat: "50.4002411",
    lng: "-4.4879006",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCW2",
    portName: "Duloe",
    portContinent: "Europe",
  },
  {
    lat: "50.26604709999999",
    lng: "-5.0527125",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCW9",
    portName: "Cornwall",
    portContinent: "Europe",
  },
  {
    lat: "53.2808672",
    lng: "-3.8262285",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCWA",
    portName: "Conwy Harbour",
    portContinent: "Europe",
  },
  {
    lat: "51.06085299999999",
    lng: "0.165894",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCWB",
    portName: "Crowborough",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCWD",
    portName: "Cowden",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCWE",
    portName: "Chilwell",
    portContinent: "Europe",
  },
  {
    lat: "51.7107543",
    lng: "-3.0645093",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCWF",
    portName: "CwmFfrwd-Oer",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCWG",
    portName: "Cowling",
    portContinent: "Europe",
  },
  {
    lat: "50.95567",
    lng: "-0.448691",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCWH",
    portName: "WestChiltington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCWI",
    portName: "Cowie",
    portContinent: "Europe",
  },
  {
    lat: "52.079623",
    lng: "-2.363979",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCWL",
    portName: "Colwall",
    portContinent: "Europe",
  },
  {
    lat: "51.6496546",
    lng: "-3.0317889",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCWM",
    portName: "Cwmbran",
    portContinent: "Europe",
  },
  {
    lat: "42.37865559999999",
    lng: "-71.0615977",
    country: "US",
    country_long: "United States",
    portCode: "GBCWN",
    portName: "Charlestown",
    portContinent: "Europe",
  },
  {
    lat: "51.0760414",
    lng: "0.3553244",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCWO",
    portName: "CousleyWood",
    portContinent: "Europe",
  },
  {
    lat: "51.64663969999999",
    lng: "-2.0446332",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCWR",
    portName: "Chelworth",
    portContinent: "Europe",
  },
  {
    lat: "49.1794814",
    lng: "-119.7575417",
    country: "CA",
    country_long: "Canada",
    portCode: "GBCWS",
    portName: "Cawston",
    portContinent: "Europe",
  },
  {
    lat: "51.36690100000001",
    lng: "-0.795353",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCWT",
    portName: "Crowthorne",
    portContinent: "Europe",
  },
  {
    lat: "54.2579967",
    lng: "-5.9418219",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCWX",
    portName: "Castlewellan",
    portContinent: "Europe",
  },
  {
    lat: "51.1091401",
    lng: "-0.1872275",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCWY",
    portName: "Crawley",
    portContinent: "Europe",
  },
  {
    lat: "52.98861789999999",
    lng: "-1.4449729",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCXB",
    portName: "Coxbench",
    portContinent: "Europe",
  },
  {
    lat: "53.1074434",
    lng: "-1.5626182",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCXM",
    portName: "Cromford",
    portContinent: "Europe",
  },
  {
    lat: "40.7398239",
    lng: "-74.0629189",
    country: "US",
    country_long: "United States",
    portCode: "GBCXN",
    portName: "Croxton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCXT",
    portName: "Cottingham",
    portContinent: "Europe",
  },
  {
    lat: "40.6584212",
    lng: "-74.2995928",
    country: "US",
    country_long: "United States",
    portCode: "GBCXX",
    portName: "Cranford",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCXY",
    portName: "Cosby",
    portContinent: "Europe",
  },
  {
    lat: "37.2660235",
    lng: "-96.9209135",
    country: "US",
    country_long: "United States",
    portCode: "GBCY2",
    portName: "Cowley",
    portContinent: "Europe",
  },
  {
    lat: "51.921856",
    lng: "-4.367480899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCY6",
    portName: "CynwylElfed",
    portContinent: "Europe",
  },
  {
    lat: "52.660114",
    lng: "-2.031659",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCYA",
    portName: "CheslynHay",
    portContinent: "Europe",
  },
  {
    lat: "54.88838999999999",
    lng: "-6.3539599",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCYB",
    portName: "Cullybackey",
    portContinent: "Europe",
  },
  {
    lat: "51.84158799999999",
    lng: "-4.729585999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCYD",
    portName: "Clynderwen",
    portContinent: "Europe",
  },
  {
    lat: "53.289936",
    lng: "0.118008",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCYE",
    portName: "Claythorpe",
    portContinent: "Europe",
  },
  {
    lat: "51.9218714",
    lng: "-0.9554354999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCYG",
    portName: "Claydon",
    portContinent: "Europe",
  },
  {
    lat: "52.18977229999999",
    lng: "0.176076",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCYH",
    portName: "CherryHinton",
    portContinent: "Europe",
  },
  {
    lat: "33.9363889",
    lng: "-84.1232686",
    country: "US",
    country_long: "United States",
    portCode: "GBCYK",
    portName: "Corley",
    portContinent: "Europe",
  },
  {
    lat: "51.60171099999999",
    lng: "0.475256",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCYL",
    portName: "CraysHill",
    portContinent: "Europe",
  },
  {
    lat: "50.709266",
    lng: "-3.454358",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCYM",
    portName: "ClystSaintMary",
    portContinent: "Europe",
  },
  {
    lat: "54.976479",
    lng: "-5.0268868",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCYN",
    portName: "Cairnryan (GBCYN), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "35.650711",
    lng: "-78.4563914",
    country: "US",
    country_long: "United States",
    portCode: "GBCYO",
    portName: "Clayton",
    portContinent: "Europe",
  },
  {
    lat: "55.9538356",
    lng: "-4.7638239",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCYP",
    portName: "Clydeport",
    portContinent: "Europe",
  },
  {
    lat: "53.2465649",
    lng: "-3.309278",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCYS",
    portName: "Caerwys",
    portContinent: "Europe",
  },
  {
    lat: "52.9585781",
    lng: "-3.401130900000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCYW",
    portName: "Cynwyd",
    portContinent: "Europe",
  },
  {
    lat: "53.29318199999999",
    lng: "-3.72764",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCYY",
    portName: "ColwynBay(BaeColwyn)",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCZT",
    portName: "Chard",
    portContinent: "Europe",
  },
  {
    lat: "52.420347",
    lng: "-2.925366",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBCZV",
    portName: "Clunbury",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBCZZ",
    portName: "Constantine",
    portContinent: "Europe",
  },
  {
    lat: "57.411359",
    lng: "-3.35851",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDAC",
    portName: "Ballindalloch",
    portContinent: "Europe",
  },
  {
    lat: "22.609811",
    lng: "114.136334",
    country: "CN",
    country_long: "China",
    portCode: "GBDAF",
    portName: "Dafen",
    portContinent: "Europe",
  },
  {
    lat: "51.5402631",
    lng: "0.1482103",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDAG",
    portName: "Dagenham",
    portContinent: "Europe",
  },
  {
    lat: "54.156887",
    lng: "-3.1782",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDAL",
    portName: "Dalton In Furness",
    portContinent: "Europe",
  },
  {
    lat: "51.71552",
    lng: "0.5805159999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDAN",
    portName: "Danbury, Essex",
    portContinent: "Europe",
  },
  {
    lat: "51.4840262",
    lng: "-0.5795205999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDAT",
    portName: "Datchet",
    portContinent: "Europe",
  },
  {
    lat: "53.235657",
    lng: "-2.511178",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDAV",
    portName: "Davenham",
    portContinent: "Europe",
  },
  {
    lat: "52.66069280000001",
    lng: "-2.466391",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDAW",
    portName: "Dawley",
    portContinent: "Europe",
  },
  {
    lat: "51.977034",
    lng: "0.266656",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDBD",
    portName: "Debden",
    portContinent: "Europe",
  },
  {
    lat: "53.703974",
    lng: "-1.14555",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDBE",
    portName: "Eggborough",
    portContinent: "Europe",
  },
  {
    lat: "52.1672769",
    lng: "-1.392069",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDBF",
    portName: "FennyCompton",
    portContinent: "Europe",
  },
  {
    lat: "55.6404722",
    lng: "-6.1082006",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDBG",
    portName: "Ardbeg",
    portContinent: "Europe",
  },
  {
    lat: "53.4053202",
    lng: "-0.0217116",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDBH",
    portName: "Fotherby",
    portContinent: "Europe",
  },
  {
    lat: "37.1272135",
    lng: "-76.5314817",
    country: "US",
    country_long: "United States",
    portCode: "GBDBI",
    portName: "Denbigh",
    portContinent: "Europe",
  },
  {
    lat: "53.993935",
    lng: "-2.7886",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDBJ",
    portName: "Galgate",
    portContinent: "Europe",
  },
  {
    lat: "54.9833305",
    lng: "-6.687552999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDBK",
    portName: "Garvagh",
    portContinent: "Europe",
  },
  {
    lat: "38.9076089",
    lng: "-77.07225849999999",
    country: "US",
    country_long: "United States",
    portCode: "GBDBL",
    portName: "Georgetown",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBDBN",
    portName: "Debenham",
    portContinent: "Europe",
  },
  {
    lat: "50.8534065",
    lng: "-1.4145165",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDBP",
    portName: "DibdenPurlieu",
    portContinent: "Europe",
  },
  {
    lat: "56.002087",
    lng: "-2.516737",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDBR",
    portName: "Dunbar",
    portContinent: "Europe",
  },
  {
    lat: "53.1046782",
    lng: "-1.5623885",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDBS",
    portName: "Derbyshire",
    portContinent: "Europe",
  },
  {
    lat: "55.945287",
    lng: "-4.564554",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDBT",
    portName: "Dumbarton",
    portContinent: "Europe",
  },
  {
    lat: "53.379528",
    lng: "-4.269889399999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDBY",
    portName: "Dulas Bay",
    portContinent: "Europe",
  },
  {
    lat: "53.5771196",
    lng: "-1.0200097",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDCO",
    portName: "Dunscroft",
    portContinent: "Europe",
  },
  {
    lat: "55.9020143",
    lng: "-4.3688823",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDCP",
    portName: "Drumchapel",
    portContinent: "Europe",
  },
  {
    lat: "53.52282",
    lng: "-1.128462",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDCR",
    portName: "Doncaster, South Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "54.5930972",
    lng: "-5.794609599999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDDD",
    portName: "Dundonald",
    portContinent: "Europe",
  },
  {
    lat: "53.165952",
    lng: "-1.596115",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDDE",
    portName: "DarleyDale",
    portContinent: "Europe",
  },
  {
    lat: "52.905463",
    lng: "-1.828354",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDDG",
    portName: "Doveridge",
    portContinent: "Europe",
  },
  {
    lat: "55.914032",
    lng: "-3.114851",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDDH",
    portName: "Danderhall",
    portContinent: "Europe",
  },
  {
    lat: "52.512255",
    lng: "-2.081112",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDDL",
    portName: "Dudley",
    portContinent: "Europe",
  },
  {
    lat: "53.2931758",
    lng: "-6.246175",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBDDM",
    portName: "Dundrum",
    portContinent: "Europe",
  },
  {
    lat: "54.808895",
    lng: "-3.946974",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDDN",
    portName: "Dundrennan",
    portContinent: "Europe",
  },
  {
    lat: "51.9821927",
    lng: "-1.3233086",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDDO",
    portName: "Deddington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBDDT",
    portName: "DurwardDauntless",
    portContinent: "Europe",
  },
  {
    lat: "50.621546",
    lng: "-4.734308",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDEA",
    portName: "Delabole",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBDEB",
    portName: "Denby",
    portContinent: "Europe",
  },
  {
    lat: "54.7935167",
    lng: "-6.7842648",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDEC",
    portName: "Draperstown",
    portContinent: "Europe",
  },
  {
    lat: "51.407249",
    lng: "0.8620909999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDED",
    portName: "Eastchurch",
    portContinent: "Europe",
  },
  {
    lat: "52.669037",
    lng: "-0.285788",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDEE",
    portName: "Deeping St James, Lincolnshire",
    portContinent: "Europe",
  },
  {
    lat: "43.8824677",
    lng: "-78.9425747",
    country: "CA",
    country_long: "Canada",
    portCode: "GBDEI",
    portName: "Deighton",
    portContinent: "Europe",
  },
  {
    lat: "53.567519",
    lng: "-2.021075",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDEL",
    portName: "Delph",
    portContinent: "Europe",
  },
  {
    lat: "56.00568149999999",
    lng: "-2.7864276",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDEM",
    portName: "Drem",
    portContinent: "Europe",
  },
  {
    lat: "53.183906",
    lng: "-3.425002",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDEN",
    portName: "DENBIGH, DENBIGHSHIRE",
    portContinent: "Europe",
  },
  {
    lat: "39.8227054",
    lng: "-75.11951069999999",
    country: "US",
    country_long: "United States",
    portCode: "GBDEP",
    portName: "Deptford",
    portContinent: "Europe",
  },
  {
    lat: "52.68161800000001",
    lng: "0.937827",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDER",
    portName: "Dereham",
    portContinent: "Europe",
  },
  {
    lat: "52.6258102",
    lng: "-1.2943538",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDES",
    portName: "Desford",
    portContinent: "Europe",
  },
  {
    lat: "33.2148412",
    lng: "-97.13306829999999",
    country: "US",
    country_long: "United States",
    portCode: "GBDET",
    portName: "Denton",
    portContinent: "Europe",
  },
  {
    lat: "50.85522899999999",
    lng: "-3.391701",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDEV",
    portName: "Cullompton, Devon",
    portContinent: "Europe",
  },
  {
    lat: "51.0992822",
    lng: "-4.1539865",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDEW",
    portName: "Wrafton",
    portContinent: "Europe",
  },
  {
    lat: "51.222491",
    lng: "1.401661",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDEX",
    portName: "Deal",
    portContinent: "Europe",
  },
  {
    lat: "50.9430702",
    lng: "-1.2640186",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDEY",
    portName: "Durley",
    portContinent: "Europe",
  },
  {
    lat: "51.44621",
    lng: "0.216872",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDFD",
    portName: "Dartford",
    portContinent: "Europe",
  },
  {
    lat: "54.005996",
    lng: "-0.443377",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDFI",
    portName: "Driffield",
    portContinent: "Europe",
  },
  {
    lat: "52.883548",
    lng: "-1.7206867",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDFN",
    portName: "Foston",
    portContinent: "Europe",
  },
  {
    lat: "52.187997",
    lng: "-0.5550759",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDFO",
    portName: "Pavenham",
    portContinent: "Europe",
  },
  {
    lat: "56.071741",
    lng: "-3.452151",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDFR",
    portName: "Dunfermline",
    portContinent: "Europe",
  },
  {
    lat: "51.3167739",
    lng: "0.981424",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDGE",
    portName: "Dargate",
    portContinent: "Europe",
  },
  {
    lat: "51.66369599999999",
    lng: "0.302137",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDGH",
    portName: "Doddinghurst",
    portContinent: "Europe",
  },
  {
    lat: "52.669037",
    lng: "-0.285788",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDGJ",
    portName: "DeepingSaintJames",
    portContinent: "Europe",
  },
  {
    lat: "31.3445471",
    lng: "-109.5453447",
    country: "US",
    country_long: "United States",
    portCode: "GBDGL",
    portName: "Douglas",
    portContinent: "Europe",
  },
  {
    lat: "54.5082725",
    lng: "-6.7669352",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDGN",
    portName: "Dungannon",
    portContinent: "Europe",
  },
  {
    lat: "54.5311101",
    lng: "-6.81305",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDGO",
    portName: "Donaghmore",
    portContinent: "Europe",
  },
  {
    lat: "51.293989",
    lng: "0.168881",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDGR",
    portName: "DuntonGreen",
    portContinent: "Europe",
  },
  {
    lat: "53.965157",
    lng: "-0.9796262000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDGT",
    portName: "Dunnington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBDGU",
    portName: "Dunning",
    portContinent: "Europe",
  },
  {
    lat: "54.9258611",
    lng: "-6.921175700000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDGV",
    portName: "Dungiven",
    portContinent: "Europe",
  },
  {
    lat: "54.6425555",
    lng: "-5.5375995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDHD",
    portName: "Donaghadee",
    portContinent: "Europe",
  },
  {
    lat: "52.052738",
    lng: "-0.891088",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDHG",
    portName: "Deanshanger",
    portContinent: "Europe",
  },
  {
    lat: "53.30128300000001",
    lng: "-0.465403",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDHL",
    portName: "Dunholme",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBDHM",
    portName: "Denham",
    portContinent: "Europe",
  },
  {
    lat: "52.9900093",
    lng: "-2.040626",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDHN",
    portName: "Dilhorne",
    portContinent: "Europe",
  },
  {
    lat: "42.29948479999999",
    lng: "-71.06485289999999",
    country: "US",
    country_long: "United States",
    portCode: "GBDHR",
    portName: "Dorchester",
    portContinent: "Europe",
  },
  {
    lat: "51.2113139",
    lng: "-2.443092",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDHS",
    portName: "Downhead",
    portContinent: "Europe",
  },
  {
    lat: "52.755922",
    lng: "-2.136885",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDHT",
    portName: "DunstonHeath",
    portContinent: "Europe",
  },
  {
    lat: "50.8725794",
    lng: "-1.422686",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDIB",
    portName: "Dibden",
    portContinent: "Europe",
  },
  {
    lat: "51.60804400000001",
    lng: "-1.24484",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDID",
    portName: "Didcot",
    portContinent: "Europe",
  },
  {
    lat: "54.934219",
    lng: "-3.822527",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDIG",
    portName: "Dalbeattie",
    portContinent: "Europe",
  },
  {
    lat: "56.4468273",
    lng: "-6.0634714",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDIH",
    portName: "Dishig",
    portContinent: "Europe",
  },
  {
    lat: "52.14093889999999",
    lng: "-10.2640136",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBDIN",
    portName: "Dingle",
    portContinent: "Europe",
  },
  {
    lat: "51.083183",
    lng: "-1.9807695",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDIO",
    portName: "Dinton",
    portContinent: "Europe",
  },
  {
    lat: "53.35833",
    lng: "-2.042044",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDIR",
    portName: "Disley, Cheshire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBDIS",
    portName: "Diss",
    portContinent: "Europe",
  },
  {
    lat: "52.468728",
    lng: "1.443686",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDIT",
    portName: "Ditchingham",
    portContinent: "Europe",
  },
  {
    lat: "57.595347",
    lng: "-4.428411",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDIW",
    portName: "Dingwall",
    portContinent: "Europe",
  },
  {
    lat: "51.232202",
    lng: "-0.332378",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDKG",
    portName: "Dorking",
    portContinent: "Europe",
  },
  {
    lat: "53.320515",
    lng: "-1.313704",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDKH",
    portName: "Killamarsh",
    portContinent: "Europe",
  },
  {
    lat: "53.478764",
    lng: "-2.094523",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDKI",
    portName: "Dukinfield, Greater Manchester",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBDKQ",
    portName: "Docking",
    portContinent: "Europe",
  },
  {
    lat: "50.86328899999999",
    lng: "-3.221575",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDKW",
    portName: "Dunkeswell",
    portContinent: "Europe",
  },
  {
    lat: "52.74215",
    lng: "-3.884399999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDLG",
    portName: "Dolgellau",
    portContinent: "Europe",
  },
  {
    lat: "42.0433661",
    lng: "-71.9276033",
    country: "US",
    country_long: "United States",
    portCode: "GBDLH",
    portName: "DudleyHill",
    portContinent: "Europe",
  },
  {
    lat: "55.89311799999999",
    lng: "-3.066606",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDLK",
    portName: "Dalkeith",
    portContinent: "Europe",
  },
  {
    lat: "52.195029",
    lng: "0.383648",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDLL",
    portName: "Dullingham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBDLR",
    portName: "Dollar",
    portContinent: "Europe",
  },
  {
    lat: "51.5452153",
    lng: "-0.0749183",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDLS",
    portName: "Dalston",
    portContinent: "Europe",
  },
  {
    lat: "51.5452153",
    lng: "-0.0749183",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDLT",
    portName: "Dalston",
    portContinent: "Europe",
  },
  {
    lat: "51.223215",
    lng: "0.33516",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDLW",
    portName: "Hadlow",
    portContinent: "Europe",
  },
  {
    lat: "55.711116",
    lng: "-4.723153",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDLX",
    portName: "Dalry",
    portContinent: "Europe",
  },
  {
    lat: "40.6977743",
    lng: "-89.6153484",
    country: "US",
    country_long: "United States",
    portCode: "GBDLY",
    portName: "Bradley",
    portContinent: "Europe",
  },
  {
    lat: "57.470373",
    lng: "-1.779524",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDMB",
    portName: "Boddam",
    portContinent: "Europe",
  },
  {
    lat: "51.978689",
    lng: "-2.43732",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDMC",
    portName: "Dymock",
    portContinent: "Europe",
  },
  {
    lat: "50.90504",
    lng: "-1.064882",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDME",
    portName: "Denmead",
    portContinent: "Europe",
  },
  {
    lat: "54.9795785",
    lng: "-7.2715652",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDMH",
    portName: "Drumahoe",
    portContinent: "Europe",
  },
  {
    lat: "54.41449660000001",
    lng: "-6.1493965",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDMO",
    portName: "Dromore",
    portContinent: "Europe",
  },
  {
    lat: "54.61526180000001",
    lng: "-7.4888904",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDMQ",
    portName: "Drumquin",
    portContinent: "Europe",
  },
  {
    lat: "55.9126774",
    lng: "-4.4314652",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDMR",
    portName: "Dalmuir",
    portContinent: "Europe",
  },
  {
    lat: "55.9212798",
    lng: "-3.5365832",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDMT",
    portName: "Dechmont",
    portContinent: "Europe",
  },
  {
    lat: "57.443623",
    lng: "-3.12838",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDMY",
    portName: "Dufftown",
    portContinent: "Europe",
  },
  {
    lat: "41.394817",
    lng: "-73.4540111",
    country: "US",
    country_long: "United States",
    portCode: "GBDNB",
    portName: "Danbury",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBDND",
    portName: "RiversideParkApt/Dundee",
    portContinent: "Europe",
  },
  {
    lat: "51.182267",
    lng: "-3.444893",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDNE",
    portName: "Dunster",
    portContinent: "Europe",
  },
  {
    lat: "59.92114050000001",
    lng: "-1.2956959",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDNF",
    portName: "DunrossnessandFairIsle",
    portContinent: "Europe",
  },
  {
    lat: "51.4158214",
    lng: "-1.3302562",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDNG",
    portName: "Donnington",
    portContinent: "Europe",
  },
  {
    lat: "53.803435",
    lng: "-1.895669",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDNH",
    portName: "Denholme",
    portContinent: "Europe",
  },
  {
    lat: "52.6671096",
    lng: "-2.6214983",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDNI",
    portName: "Donnington, Shropshire",
    portContinent: "Europe",
  },
  {
    lat: "33.2148412",
    lng: "-97.13306829999999",
    country: "US",
    country_long: "United States",
    portCode: "GBDNN",
    portName: "Denton",
    portContinent: "Europe",
  },
  {
    lat: "54.41804",
    lng: "-6.2582599",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDNO",
    portName: "Donaghcloney",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBDNQ",
    portName: "DeanQuarry",
    portContinent: "Europe",
  },
  {
    lat: "50.6900504",
    lng: "-3.6823304",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDNR",
    portName: "Dunsford",
    portContinent: "Europe",
  },
  {
    lat: "58.5685565",
    lng: "-4.7468816",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDNS",
    portName: "Durness",
    portContinent: "Europe",
  },
  {
    lat: "55.4353792",
    lng: "-4.6619967",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDNT",
    portName: "Doonfoot",
    portContinent: "Europe",
  },
  {
    lat: "55.950973",
    lng: "-4.926214",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDNU",
    portName: "Dunoon (GBDNU), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "39.7392358",
    lng: "-104.990251",
    country: "US",
    country_long: "United States",
    portCode: "GBDNV",
    portName: "Denver",
    portContinent: "Europe",
  },
  {
    lat: "52.2766935",
    lng: "1.6265635",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDNW",
    portName: "Dunwich",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBDNY",
    portName: "Denny",
    portContinent: "Europe",
  },
  {
    lat: "57.87907",
    lng: "-4.028026",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDOC",
    portName: "Dornoch",
    portContinent: "Europe",
  },
  {
    lat: "53.325384",
    lng: "-1.5394211",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDOE",
    portName: "Dore",
    portContinent: "Europe",
  },
  {
    lat: "53.22141",
    lng: "-0.6526278999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDOG",
    portName: "Doddington",
    portContinent: "Europe",
  },
  {
    lat: "53.500486",
    lng: "-1.153524",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDOJ",
    portName: "Balby, South Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "53.185394",
    lng: "-3.842482",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDOL",
    portName: "Dolgarrog",
    portContinent: "Europe",
  },
  {
    lat: "53.52282",
    lng: "-1.128462",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDON",
    portName: "Doncaster",
    portContinent: "Europe",
  },
  {
    lat: "50.7487635",
    lng: "-2.3444786",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDOR",
    portName: "Dorset",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBDOS",
    portName: "DaleRoads",
    portContinent: "Europe",
  },
  {
    lat: "38.2950565",
    lng: "-120.2746191",
    country: "US",
    country_long: "United States",
    portCode: "GBDOT",
    portName: "Dorrington",
    portContinent: "Europe",
  },
  {
    lat: "56.191486",
    lng: "-4.055376",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDOU",
    portName: "Doune",
    portContinent: "Europe",
  },
  {
    lat: "52.606612",
    lng: "0.385472",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDOW",
    portName: "Downham Market",
    portContinent: "Europe",
  },
  {
    lat: "53.05394099999999",
    lng: "-3.886577",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDOY",
    portName: "Dolwyddelan",
    portContinent: "Europe",
  },
  {
    lat: "51.304827",
    lng: "-0.707947",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDPC",
    portName: "Deepcut",
    portContinent: "Europe",
  },
  {
    lat: "53.478764",
    lng: "-2.094523",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDQF",
    portName: "Dukinfield",
    portContinent: "Europe",
  },
  {
    lat: "56.06548799999999",
    lng: "-4.44996",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDR3",
    portName: "Drymen",
    portContinent: "Europe",
  },
  {
    lat: "55.0743448",
    lng: "-8.294187299999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBDR9",
    portName: "Derrybeg",
    portContinent: "Europe",
  },
  {
    lat: "52.2399742",
    lng: "0.0253666",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRA",
    portName: "DryDrayton",
    portContinent: "Europe",
  },
  {
    lat: "52.600407",
    lng: "-1.613569",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRD",
    portName: "Dordon",
    portContinent: "Europe",
  },
  {
    lat: "55.406937",
    lng: "-4.757339",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRE",
    portName: "Dunure",
    portContinent: "Europe",
  },
  {
    lat: "54.005996",
    lng: "-0.443377",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRF",
    portName: "Driffield",
    portContinent: "Europe",
  },
  {
    lat: "53.757707",
    lng: "-1.661786",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRG",
    portName: "Drighlington",
    portContinent: "Europe",
  },
  {
    lat: "35.9940329",
    lng: "-78.898619",
    country: "US",
    country_long: "United States",
    portCode: "GBDRH",
    portName: "Durham",
    portContinent: "Europe",
  },
  {
    lat: "54.005996",
    lng: "-0.443377",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRI",
    portName: "Driffield, East Riding Of Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "34.3003526",
    lng: "-79.8757116",
    country: "US",
    country_long: "United States",
    portCode: "GBDRL",
    portName: "Darlington",
    portContinent: "Europe",
  },
  {
    lat: "54.69053599999999",
    lng: "-4.894817",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRM",
    portName: "Drummore",
    portContinent: "Europe",
  },
  {
    lat: "53.30227900000001",
    lng: "-1.467959",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRN",
    portName: "Dronfield",
    portContinent: "Europe",
  },
  {
    lat: "52.2615914",
    lng: "-2.1526355",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRO",
    portName: "Droitwich",
    portContinent: "Europe",
  },
  {
    lat: "53.257239",
    lng: "-0.838606",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRQ",
    portName: "Darlton",
    portContinent: "Europe",
  },
  {
    lat: "51.1993",
    lng: "-1.770831",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRR",
    portName: "Durrington",
    portContinent: "Europe",
  },
  {
    lat: "52.5704506",
    lng: "-2.0354721",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRS",
    portName: "Darlaston",
    portContinent: "Europe",
  },
  {
    lat: "53.372587",
    lng: "-1.203068",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRT",
    portName: "Dinnington",
    portContinent: "Europe",
  },
  {
    lat: "53.7354278",
    lng: "-0.9949147999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRX",
    portName: "Drax",
    portContinent: "Europe",
  },
  {
    lat: "53.480784",
    lng: "-2.148716",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDRY",
    portName: "Droylsden",
    portContinent: "Europe",
  },
  {
    lat: "53.3407186",
    lng: "-2.6329588",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDS2",
    portName: "Daresbury",
    portContinent: "Europe",
  },
  {
    lat: "52.8455802",
    lng: "0.5031257",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSA",
    portName: "Dersingham",
    portContinent: "Europe",
  },
  {
    lat: "52.441465",
    lng: "-0.822557",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSB",
    portName: "Desborough",
    portContinent: "Europe",
  },
  {
    lat: "54.6607742",
    lng: "-1.1935112",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSC",
    portName: "SeatonCarew",
    portContinent: "Europe",
  },
  {
    lat: "53.1997351",
    lng: "-3.0329723",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSE",
    portName: "Deeside",
    portContinent: "Europe",
  },
  {
    lat: "51.1192951",
    lng: "-0.5636118000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSF",
    portName: "Dunsfold",
    portContinent: "Europe",
  },
  {
    lat: "56.34660599999999",
    lng: "-2.9480511",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSI",
    portName: "Dairsie",
    portContinent: "Europe",
  },
  {
    lat: "55.2341",
    lng: "-6.43653",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSK",
    portName: "Dunseverick",
    portContinent: "Europe",
  },
  {
    lat: "53.35833",
    lng: "-2.042044",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSL",
    portName: "Disley",
    portContinent: "Europe",
  },
  {
    lat: "53.7853015",
    lng: "-2.5596762",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSN",
    portName: "Balderstone",
    portContinent: "Europe",
  },
  {
    lat: "51.130349",
    lng: "1.158042",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSO",
    portName: "Densole",
    portContinent: "Europe",
  },
  {
    lat: "52.72610900000001",
    lng: "-0.203461",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSQ",
    portName: "DeepingStNicholas",
    portContinent: "Europe",
  },
  {
    lat: "50.919325",
    lng: "0.9652799999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSS",
    portName: "Dungeness",
    portContinent: "Europe",
  },
  {
    lat: "52.2485399",
    lng: "-0.9453041000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDST",
    portName: "Duston",
    portContinent: "Europe",
  },
  {
    lat: "50.656826",
    lng: "-4.617132",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSV",
    portName: "Davidstow",
    portContinent: "Europe",
  },
  {
    lat: "51.8224051",
    lng: "0.05870489999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSW",
    portName: "Widford",
    portContinent: "Europe",
  },
  {
    lat: "53.510215",
    lng: "-0.527726",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSX",
    portName: "Hibaldstow",
    portContinent: "Europe",
  },
  {
    lat: "53.1220913",
    lng: "-0.6753748",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDSY",
    portName: "NortonDisney",
    portContinent: "Europe",
  },
  {
    lat: "52.596676",
    lng: "-1.68276",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDT2",
    portName: "Dosthill",
    portContinent: "Europe",
  },
  {
    lat: "42.67508979999999",
    lng: "-71.4828433",
    country: "US",
    country_long: "United States",
    portCode: "GBDTE",
    portName: "Dunstable",
    portContinent: "Europe",
  },
  {
    lat: "56.4546794",
    lng: "-5.437433899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDTF",
    portName: "Dunstaffnage",
    portContinent: "Europe",
  },
  {
    lat: "50.452017",
    lng: "-3.712819",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDTG",
    portName: "Dartington",
    portContinent: "Europe",
  },
  {
    lat: "50.918026",
    lng: "-0.115824",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDTH",
    portName: "Ditchling",
    portContinent: "Europe",
  },
  {
    lat: "51.2940153",
    lng: "0.5696183000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDTI",
    portName: "Detling",
    portContinent: "Europe",
  },
  {
    lat: "43.7044406",
    lng: "-72.2886934",
    country: "US",
    country_long: "United States",
    portCode: "GBDTM",
    portName: "Dartmouth",
    portContinent: "Europe",
  },
  {
    lat: "53.1530996",
    lng: "-0.409971",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDTN",
    portName: "Dunston",
    portContinent: "Europe",
  },
  {
    lat: "44.3805577",
    lng: "-83.7946408",
    country: "US",
    country_long: "United States",
    portCode: "GBDTO",
    portName: "Darton",
    portContinent: "Europe",
  },
  {
    lat: "42.29948479999999",
    lng: "-71.06485289999999",
    country: "US",
    country_long: "United States",
    portCode: "GBDTR",
    portName: "Dorchester",
    portContinent: "Europe",
  },
  {
    lat: "51.294626",
    lng: "0.4538530000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDTT",
    portName: "Ditton",
    portContinent: "Europe",
  },
  {
    lat: "51.0343684",
    lng: "2.3767763",
    country: "FR",
    country_long: "France",
    portCode: "GBDU5",
    portName: "Dunkirk",
    portContinent: "Europe",
  },
  {
    lat: "56.183877",
    lng: "-3.967449",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUB",
    portName: "Dunblane",
    portContinent: "Europe",
  },
  {
    lat: "52.33729599999999",
    lng: "-1.289386",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUC",
    portName: "Dunchurch",
    portContinent: "Europe",
  },
  {
    lat: "54.5930972",
    lng: "-5.794609599999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUD",
    portName: "Dundonald",
    portContinent: "Europe",
  },
  {
    lat: "58.250685",
    lng: "-3.433149",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUE",
    portName: "Dunbeath",
    portContinent: "Europe",
  },
  {
    lat: "52.984696",
    lng: "-1.487313",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUF",
    portName: "Duffield",
    portContinent: "Europe",
  },
  {
    lat: "54.5930972",
    lng: "-5.794609599999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUL",
    portName: "Dundonald",
    portContinent: "Europe",
  },
  {
    lat: "55.070859",
    lng: "-3.60512",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUM",
    portName: "Dumfries",
    portContinent: "Europe",
  },
  {
    lat: "56.462018",
    lng: "-2.970721",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUN",
    portName: "Dundee",
    portContinent: "Europe",
  },
  {
    lat: "54.907547",
    lng: "-1.735527",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUO",
    portName: "Burnopsfield",
    portContinent: "Europe",
  },
  {
    lat: "51.678768",
    lng: "-2.350518",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUR",
    portName: "Dursley",
    portContinent: "Europe",
  },
  {
    lat: "55.777794",
    lng: "-2.343461",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUS",
    portName: "Duns",
    portContinent: "Europe",
  },
  {
    lat: "54.0982499",
    lng: "-6.415649999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUT",
    portName: "Drumintee",
    portContinent: "Europe",
  },
  {
    lat: "51.62486999999999",
    lng: "-4.033764",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUV",
    portName: "Dunvant",
    portContinent: "Europe",
  },
  {
    lat: "52.092171",
    lng: "0.159301",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUX",
    portName: "Duxford, Cambridgeshire",
    portContinent: "Europe",
  },
  {
    lat: "54.5498161",
    lng: "-6.007218399999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDUY",
    portName: "Dunmurry",
    portContinent: "Europe",
  },
  {
    lat: "56.58838609999999",
    lng: "-6.185177899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDVI",
    portName: "Dervaig",
    portContinent: "Europe",
  },
  {
    lat: "57.4366037",
    lng: "-6.5813983",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDVN",
    portName: "Dunvegan",
    portContinent: "Europe",
  },
  {
    lat: "51.039649",
    lng: "-3.549685",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDVO",
    portName: "Dulverton",
    portContinent: "Europe",
  },
  {
    lat: "-41.1800656",
    lng: "146.3502862",
    country: "AU",
    country_long: "Australia",
    portCode: "GBDVP",
    portName: "Devonport",
    portContinent: "Europe",
  },
  {
    lat: "51.9270884",
    lng: "1.2348448",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDVQ",
    portName: "Dovercourt",
    portContinent: "Europe",
  },
  {
    lat: "51.1278758",
    lng: "1.3134027",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDVR",
    portName: "Dover",
    portContinent: "Europe",
  },
  {
    lat: "56.14735899999999",
    lng: "-3.739926999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDVS",
    portName: "Devonside",
    portContinent: "Europe",
  },
  {
    lat: "41.5236437",
    lng: "-90.5776367",
    country: "US",
    country_long: "United States",
    portCode: "GBDVT",
    portName: "Davenport",
    portContinent: "Europe",
  },
  {
    lat: "52.25602749999999",
    lng: "-1.1625942",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDVY",
    portName: "Daventry",
    portContinent: "Europe",
  },
  {
    lat: "51.3489069",
    lng: "-1.994828",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDVZ",
    portName: "Devizes",
    portContinent: "Europe",
  },
  {
    lat: "51.76181099999999",
    lng: "-3.347789",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDWA",
    portName: "Dowlais",
    portContinent: "Europe",
  },
  {
    lat: "51.4433257",
    lng: "-0.0675547",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDWC",
    portName: "Dulwich",
    portContinent: "Europe",
  },
  {
    lat: "51.489743",
    lng: "-2.500743",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDWD",
    portName: "Downend",
    portContinent: "Europe",
  },
  {
    lat: "31.848766",
    lng: "-81.6038617",
    country: "US",
    country_long: "United States",
    portCode: "GBDWE",
    portName: "Bradwell",
    portContinent: "Europe",
  },
  {
    lat: "53.540062",
    lng: "-1.524437",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDWH",
    portName: "Dodworth",
    portContinent: "Europe",
  },
  {
    lat: "56.9396522",
    lng: "-4.238517799999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDWI",
    portName: "Dalwhinnie",
    portContinent: "Europe",
  },
  {
    lat: "54.32875139999999",
    lng: "-5.715692199999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDWK",
    portName: "Downpatrick",
    portContinent: "Europe",
  },
  {
    lat: "51.7338143",
    lng: "0.8899517",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDWL",
    portName: "BradwellWaterside",
    portContinent: "Europe",
  },
  {
    lat: "53.894538",
    lng: "-2.3293809",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDWM",
    portName: "Downham",
    portContinent: "Europe",
  },
  {
    lat: "53.695451",
    lng: "-2.46874",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDWN",
    portName: "Darwen",
    portContinent: "Europe",
  },
  {
    lat: "50.994428",
    lng: "-1.751388",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDWT",
    portName: "Downton",
    portContinent: "Europe",
  },
  {
    lat: "53.689833",
    lng: "-1.6296949",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDWY",
    portName: "Dewsbury",
    portContinent: "Europe",
  },
  {
    lat: "52.092171",
    lng: "0.159301",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDXF",
    portName: "Duxford",
    portContinent: "Europe",
  },
  {
    lat: "50.959946",
    lng: "-1.1384976",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDXO",
    portName: "Droxford",
    portContinent: "Europe",
  },
  {
    lat: "52.9225301",
    lng: "-1.4746186",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDXY",
    portName: "Derby",
    portContinent: "Europe",
  },
  {
    lat: "56.03490799999999",
    lng: "-3.35056",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDYB",
    portName: "DalgetyBay",
    portContinent: "Europe",
  },
  {
    lat: "57.203514",
    lng: "-2.190188",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDYC",
    portName: "Dyce",
    portContinent: "Europe",
  },
  {
    lat: "-37.3414092",
    lng: "144.142784",
    country: "AU",
    country_long: "Australia",
    portCode: "GBDYF",
    portName: "Daylesford",
    portContinent: "Europe",
  },
  {
    lat: "54.19624",
    lng: "-7.57145",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDYL",
    portName: "Derrylin",
    portContinent: "Europe",
  },
  {
    lat: "57.335275",
    lng: "-2.573247",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDYN",
    portName: "Ardyne (GBDYN), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.168855",
    lng: "-4.3208529",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBDYR",
    portName: "Dwyran",
    portContinent: "Europe",
  },
  {
    lat: "33.6155465",
    lng: "-112.4053046",
    country: "US",
    country_long: "United States",
    portCode: "GBDYS",
    portName: "Dysart",
    portContinent: "Europe",
  },
  {
    lat: "34.0474582",
    lng: "-118.5250907",
    country: "US",
    country_long: "United States",
    portCode: "GBDYT",
    portName: "Draycott",
    portContinent: "Europe",
  },
  {
    lat: "52.5763906",
    lng: "-1.3113676",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBE5T",
    portName: "EarlShilton",
    portContinent: "Europe",
  },
  {
    lat: "52.138181",
    lng: "-1.6088796",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBE9G",
    portName: "Eatington",
    portContinent: "Europe",
  },
  {
    lat: "51.9189373",
    lng: "0.1671691",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBE9X",
    portName: "Manuden",
    portContinent: "Europe",
  },
  {
    lat: "41.1695747",
    lng: "-81.60919179999999",
    country: "US",
    country_long: "United States",
    portCode: "GBEAA",
    portName: "Easdale,Seil",
    portContinent: "Europe",
  },
  {
    lat: "52.26682",
    lng: "-0.752765",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEAB",
    portName: "Earls Barton, Northamptonshire",
    portContinent: "Europe",
  },
  {
    lat: "51.928775",
    lng: "0.69772",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEAC",
    portName: "Earls Colne, Essex",
    portContinent: "Europe",
  },
  {
    lat: "52.68161800000001",
    lng: "0.937827",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEAD",
    portName: "EastDereham",
    portContinent: "Europe",
  },
  {
    lat: "51.5225588",
    lng: "-1.1488225",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEAE",
    portName: "Streatley",
    portContinent: "Europe",
  },
  {
    lat: "54.5178547",
    lng: "-1.3596383",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEAG",
    portName: "Eaglescliffe, Durham",
    portContinent: "Europe",
  },
  {
    lat: "52.679461",
    lng: "1.370984",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEAH",
    portName: "Rackheath",
    portContinent: "Europe",
  },
  {
    lat: "55.988747",
    lng: "-2.6592539",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEAI",
    portName: "EastLinton",
    portContinent: "Europe",
  },
  {
    lat: "50.967182",
    lng: "-1.374688",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEAL",
    portName: "Eastleigh",
    portContinent: "Europe",
  },
  {
    lat: "52.3154415",
    lng: "-2.502849",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEAM",
    portName: "Eastham (GBEAM), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBEAN",
    portName: "Easton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBEAO",
    portName: "Easton",
    portContinent: "Europe",
  },
  {
    lat: "52.26682",
    lng: "-0.752765",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEAR",
    portName: "Earls Barton",
    portContinent: "Europe",
  },
  {
    lat: "51.287435",
    lng: "0.440026",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEAS",
    portName: "East Malling",
    portContinent: "Europe",
  },
  {
    lat: "50.967182",
    lng: "-1.374688",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEAT",
    portName: "Eastleigh",
    portContinent: "Europe",
  },
  {
    lat: "45.5227212",
    lng: "-122.6473094",
    country: "US",
    country_long: "United States",
    portCode: "GBEAW",
    portName: "Eastburn",
    portContinent: "Europe",
  },
  {
    lat: "51.245154",
    lng: "1.303401",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEAY",
    portName: "Eastry",
    portContinent: "Europe",
  },
  {
    lat: "51.777532",
    lng: "-3.206151",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEBB",
    portName: "Ebbw Vale",
    portContinent: "Europe",
  },
  {
    lat: "54.94141699999999",
    lng: "-1.4514149",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEBD",
    portName: "WestBoldon",
    portContinent: "Europe",
  },
  {
    lat: "51.454446",
    lng: "-0.432817",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEBF",
    portName: "EastBedfont/London",
    portContinent: "Europe",
  },
  {
    lat: "51.261244",
    lng: "-2.554696",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEBG",
    portName: "Emborough",
    portContinent: "Europe",
  },
  {
    lat: "54.94315599999999",
    lng: "-1.422718",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEBL",
    portName: "East Boldon",
    portContinent: "Europe",
  },
  {
    lat: "50.768035",
    lng: "0.290472",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEBO",
    portName: "Eastbourne",
    portContinent: "Europe",
  },
  {
    lat: "51.0944591",
    lng: "-0.9385355",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEBR",
    portName: "Selborne",
    portContinent: "Europe",
  },
  {
    lat: "51.2636149",
    lng: "-2.9160788",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEBT",
    portName: "EastBrent",
    portContinent: "Europe",
  },
  {
    lat: "53.31733879999999",
    lng: "-0.2514401",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEBW",
    portName: "EastBarkwith",
    portContinent: "Europe",
  },
  {
    lat: "50.8005645",
    lng: "-1.3962842",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEBY",
    portName: "Exbury",
    portContinent: "Europe",
  },
  {
    lat: "51.1034765",
    lng: "5.874584",
    country: "NL",
    country_long: "Netherlands",
    portCode: "GBECA",
    portName: "Echt",
    portContinent: "Europe",
  },
  {
    lat: "53.441982",
    lng: "-1.466509",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBECC",
    portName: "Ecclesfield",
    portContinent: "Europe",
  },
  {
    lat: "51.924071",
    lng: "-0.9285053",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBECD",
    portName: "EastClaydon",
    portContinent: "Europe",
  },
  {
    lat: "52.858736",
    lng: "-2.253024",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBECH",
    portName: "Eccleshall",
    portContinent: "Europe",
  },
  {
    lat: "38.9141343",
    lng: "-77.0031863",
    country: "US",
    country_long: "United States",
    portCode: "GBECK",
    portName: "Eckington",
    portContinent: "Europe",
  },
  {
    lat: "52.858736",
    lng: "-2.253024",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBECL",
    portName: "Eccleshall, Staffordshire",
    portContinent: "Europe",
  },
  {
    lat: "51.928775",
    lng: "0.69772",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBECO",
    portName: "EarlsColne",
    portContinent: "Europe",
  },
  {
    lat: "40.76636269999999",
    lng: "-111.8905835",
    country: "US",
    country_long: "United States",
    portCode: "GBECS",
    portName: "Eccles",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBECT",
    portName: "Eccleston",
    portContinent: "Europe",
  },
  {
    lat: "50.759108",
    lng: "-1.285492",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBECW",
    portName: "EastCowes",
    portContinent: "Europe",
  },
  {
    lat: "53.4783182",
    lng: "-1.1933845",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBED3",
    portName: "Edlington",
    portContinent: "Europe",
  },
  {
    lat: "50.5508499",
    lng: "-119.1396705",
    country: "CA",
    country_long: "Canada",
    portCode: "GBEDB",
    portName: "Enderby",
    portContinent: "Europe",
  },
  {
    lat: "51.196259",
    lng: "0.06545100000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEDE",
    portName: "Edenbridge",
    portContinent: "Europe",
  },
  {
    lat: "51.615786",
    lng: "-0.262034",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEDG",
    portName: "Edgware",
    portContinent: "Europe",
  },
  {
    lat: "52.68161800000001",
    lng: "0.937827",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEDH",
    portName: "East Dereham, Norfolk",
    portContinent: "Europe",
  },
  {
    lat: "55.953252",
    lng: "-3.188267",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEDI",
    portName: "Edinburgh",
    portContinent: "Europe",
  },
  {
    lat: "53.72099799999999",
    lng: "-1.540537",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEDL",
    portName: "EastArdsley",
    portContinent: "Europe",
  },
  {
    lat: "53.5461245",
    lng: "-113.4938229",
    country: "CA",
    country_long: "Canada",
    portCode: "GBEDM",
    portName: "Edmonton",
    portContinent: "Europe",
  },
  {
    lat: "52.91328",
    lng: "-1.116497",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEDN",
    portName: "Edwalton",
    portContinent: "Europe",
  },
  {
    lat: "51.27687359999999",
    lng: "-2.1063915",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEDO",
    portName: "Edington",
    portContinent: "Europe",
  },
  {
    lat: "51.502985",
    lng: "-0.4654099999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEDR",
    portName: "EastDrayton",
    portContinent: "Europe",
  },
  {
    lat: "41.8299634",
    lng: "-69.97403609999999",
    country: "US",
    country_long: "United States",
    portCode: "GBEDT",
    portName: "EastHam",
    portContinent: "Europe",
  },
  {
    lat: "51.4545635",
    lng: "-0.0732101",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEDU",
    portName: "EastDulwich",
    portContinent: "Europe",
  },
  {
    lat: "53.19623199999999",
    lng: "-1.066758",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEDW",
    portName: "Edwinstowe",
    portContinent: "Europe",
  },
  {
    lat: "52.1373358",
    lng: "-3.0060341",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEDY",
    portName: "Eardisley",
    portContinent: "Europe",
  },
  {
    lat: "56.806022",
    lng: "-2.655163",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEDZ",
    portName: "Edzell",
    portContinent: "Europe",
  },
  {
    lat: "52.375539",
    lng: "-2.316973",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEEB",
    portName: "Bewdley",
    portContinent: "Europe",
  },
  {
    lat: "51.4637912",
    lng: "-1.3165607",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEEE",
    portName: "Chieveley",
    portContinent: "Europe",
  },
  {
    lat: "55.515888",
    lng: "-4.3777549",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEEH",
    portName: "Mauchline",
    portContinent: "Europe",
  },
  {
    lat: "52.25179600000001",
    lng: "-2.881852",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEEN",
    portName: "Shobdon",
    portContinent: "Europe",
  },
  {
    lat: "58.95",
    lng: "-2.75",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEER",
    portName: "Deerness",
    portContinent: "Europe",
  },
  {
    lat: "52.92392",
    lng: "-1.212394",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEES",
    portName: "Beeston",
    portContinent: "Europe",
  },
  {
    lat: "53.4526171",
    lng: "-2.6420833",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEEW",
    portName: "Earlestown",
    portContinent: "Europe",
  },
  {
    lat: "32.8140043",
    lng: "-96.6608109",
    country: "US",
    country_long: "United States",
    portCode: "GBEFD",
    portName: "Eastfield",
    portContinent: "Europe",
  },
  {
    lat: "53.666917",
    lng: "-2.305379",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEFL",
    portName: "Edenfield",
    portContinent: "Europe",
  },
  {
    lat: "53.5034449",
    lng: "-2.970359",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEFO",
    portName: "Sefton",
    portContinent: "Europe",
  },
  {
    lat: "39.1200418",
    lng: "-88.5433829",
    country: "US",
    country_long: "United States",
    portCode: "GBEGA",
    portName: "Effingham",
    portContinent: "Europe",
  },
  {
    lat: "51.128742",
    lng: "-0.014468",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEGD",
    portName: "EastGrinstead",
    portContinent: "Europe",
  },
  {
    lat: "54.5224541",
    lng: "-1.3547353",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEGG",
    portName: "Egglescliffe",
    portContinent: "Europe",
  },
  {
    lat: "51.428825",
    lng: "-0.547876",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEGH",
    portName: "Egham",
    portContinent: "Europe",
  },
  {
    lat: "59.1522202",
    lng: "-2.9261761",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEGI",
    portName: "Egilsay (GBEGI), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.5178547",
    lng: "-1.3596383",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEGL",
    portName: "Eaglescliffe",
    portContinent: "Europe",
  },
  {
    lat: "53.9205843",
    lng: "-0.9393713",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEGN",
    portName: "Elvington",
    portContinent: "Europe",
  },
  {
    lat: "57.149276",
    lng: "-6.098889",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEGO",
    portName: "Elgol,IsleofSkye",
    portContinent: "Europe",
  },
  {
    lat: "51.128742",
    lng: "-0.014468",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEGR",
    portName: "East Grinstead, West Sussex",
    portContinent: "Europe",
  },
  {
    lat: "52.71392489999999",
    lng: "-1.0499515",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEGS",
    portName: "EastGoscote",
    portContinent: "Europe",
  },
  {
    lat: "42.1777247",
    lng: "-73.4415929",
    country: "US",
    country_long: "United States",
    portCode: "GBEGT",
    portName: "Egremont",
    portContinent: "Europe",
  },
  {
    lat: "51.58844939999999",
    lng: "-2.1740623",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEGY",
    portName: "EastonGrey",
    portContinent: "Europe",
  },
  {
    lat: "52.43992979999999",
    lng: "0.9325681",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEHA",
    portName: "East Harling",
    portContinent: "Europe",
  },
  {
    lat: "51.990117",
    lng: "0.6018789999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEHE",
    portName: "CastleHedingham",
    portContinent: "Europe",
  },
  {
    lat: "51.683487",
    lng: "0.562337",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEHF",
    portName: "EastHanningfield",
    portContinent: "Europe",
  },
  {
    lat: "50.9236126",
    lng: "0.1552988",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEHL",
    portName: "EastHoathly",
    portContinent: "Europe",
  },
  {
    lat: "54.1180014",
    lng: "-2.5090175",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEHM",
    portName: "Bentham",
    portContinent: "Europe",
  },
  {
    lat: "50.444956",
    lng: "-4.558985",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEHS",
    portName: "EastTaphouse",
    portContinent: "Europe",
  },
  {
    lat: "51.8438101",
    lng: "-0.3627679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEHY",
    portName: "EastHyde",
    portContinent: "Europe",
  },
  {
    lat: "55.8289822",
    lng: "-3.6549147",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEIC",
    portName: "Breich",
    portContinent: "Europe",
  },
  {
    lat: "51.474599",
    lng: "-2.702336",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEIG",
    portName: "Easton-in-Gordano",
    portContinent: "Europe",
  },
  {
    lat: "57.52514590000001",
    lng: "-5.6479456",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEIL",
    portName: "Sheildaig",
    portContinent: "Europe",
  },
  {
    lat: "50.9088712",
    lng: "-1.4789947",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEIN",
    portName: "Eling",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBEIR",
    portName: "Weir",
    portContinent: "Europe",
  },
  {
    lat: "55.76435240000001",
    lng: "-4.1769988",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEKB",
    portName: "East Kilbride",
    portContinent: "Europe",
  },
  {
    lat: "52.82906",
    lng: "-1.183133",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEKE",
    portName: "EastLeake",
    portContinent: "Europe",
  },
  {
    lat: "-32.5564724",
    lng: "115.7049902",
    country: "AU",
    country_long: "Australia",
    portCode: "GBEKI",
    portName: "Erskine",
    portContinent: "Europe",
  },
  {
    lat: "53.753933",
    lng: "-0.604252",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEKM",
    portName: "Ellerker",
    portContinent: "Europe",
  },
  {
    lat: "58.00927300000001",
    lng: "-6.535165999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEKN",
    portName: "Eisken",
    portContinent: "Europe",
  },
  {
    lat: "52.3723789",
    lng: "0.4782565999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEKR",
    portName: "BeckRow",
    portContinent: "Europe",
  },
  {
    lat: "50.48878",
    lng: "-5.013084999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEL2",
    portName: "Engollan",
    portContinent: "Europe",
  },
  {
    lat: "53.68350299999999",
    lng: "-1.844929",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBELA",
    portName: "Elland, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "56.294525",
    lng: "-5.650464899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBELB",
    portName: "Ellenabeich,Seil",
    portContinent: "Europe",
  },
  {
    lat: "53.496778",
    lng: "-1.414144",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBELC",
    portName: "Elsecar",
    portContinent: "Europe",
  },
  {
    lat: "42.9921579",
    lng: "-79.2482555",
    country: "CA",
    country_long: "Canada",
    portCode: "GBELD",
    portName: "Welland",
    portContinent: "Europe",
  },
  {
    lat: "51.9142483",
    lng: "0.2291955",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBELE",
    portName: "Elsenham",
    portContinent: "Europe",
  },
  {
    lat: "42.0354084",
    lng: "-88.2825668",
    country: "US",
    country_long: "United States",
    portCode: "GBELG",
    portName: "Elgin",
    portContinent: "Europe",
  },
  {
    lat: "52.5637996",
    lng: "-1.3213726",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBELH",
    portName: "Elmesthorpe",
    portContinent: "Europe",
  },
  {
    lat: "56.190472",
    lng: "-2.822051",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBELI",
    portName: "Elie",
    portContinent: "Europe",
  },
  {
    lat: "53.2724912",
    lng: "-0.9638154999999998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBELK",
    portName: "Elkesley",
    portContinent: "Europe",
  },
  {
    lat: "53.279812",
    lng: "-2.897404",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBELL",
    portName: "Ellesmere Port",
    portContinent: "Europe",
  },
  {
    lat: "80.75039029999999",
    lng: "-72.6651905",
    country: "CA",
    country_long: "Canada",
    portCode: "GBELM",
    portName: "Ellesmere",
    portContinent: "Europe",
  },
  {
    lat: "51.5132537",
    lng: "-0.3043136",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBELN",
    portName: "Ealing",
    portContinent: "Europe",
  },
  {
    lat: "52.702149",
    lng: "-1.369166",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBELO",
    portName: "Ellistown",
    portContinent: "Europe",
  },
  {
    lat: "53.8504503",
    lng: "-0.9312603",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBELR",
    portName: "Ellerton",
    portContinent: "Europe",
  },
  {
    lat: "53.59622599999999",
    lng: "-0.438556",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBELS",
    portName: "Elsham",
    portContinent: "Europe",
  },
  {
    lat: "41.91728260000001",
    lng: "-72.458236",
    country: "US",
    country_long: "United States",
    portCode: "GBELT",
    portName: "Ellington",
    portContinent: "Europe",
  },
  {
    lat: "53.9205843",
    lng: "-0.9393713",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBELV",
    portName: "Elvington",
    portContinent: "Europe",
  },
  {
    lat: "39.2533328",
    lng: "-114.874248",
    country: "US",
    country_long: "United States",
    portCode: "GBELY",
    portName: "Ely",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBEMA",
    portName: "EastMidlandsApt",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBEMB",
    portName: "Emberton",
    portContinent: "Europe",
  },
  {
    lat: "51.7971187",
    lng: "0.9863894",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEME",
    portName: "EastMersea",
    portContinent: "Europe",
  },
  {
    lat: "54.8755707",
    lng: "-1.6454086",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEMH",
    portName: "Beamish",
    portContinent: "Europe",
  },
  {
    lat: "54.24650579999999",
    lng: "-1.0562636",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEML",
    portName: "Helmsley",
    portContinent: "Europe",
  },
  {
    lat: "52.8257098",
    lng: "0.8275638",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEMN",
    portName: "Hempton",
    portContinent: "Europe",
  },
  {
    lat: "51.400766",
    lng: "-0.3562949",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEMO",
    portName: "EastMolesey",
    portContinent: "Europe",
  },
  {
    lat: "51.548497",
    lng: "0.1999531",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEMP",
    portName: "ElmPark",
    portContinent: "Europe",
  },
  {
    lat: "50.440571",
    lng: "-4.0149469",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEMR",
    portName: "LeeMoor",
    portContinent: "Europe",
  },
  {
    lat: "40.5100684",
    lng: "-80.09450059999999",
    country: "US",
    country_long: "United States",
    portCode: "GBEMS",
    portName: "Emsworth",
    portContinent: "Europe",
  },
  {
    lat: "52.643244",
    lng: "0.2078859",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEMT",
    portName: "Emneth",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBEN3",
    portName: "Easton",
    portContinent: "Europe",
  },
  {
    lat: "52.307662",
    lng: "-2.596406",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBENB",
    portName: "Tenbury",
    portContinent: "Europe",
  },
  {
    lat: "53.68350299999999",
    lng: "-1.844929",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEND",
    portName: "Elland",
    portContinent: "Europe",
  },
  {
    lat: "42.0027181",
    lng: "-72.5441445",
    country: "US",
    country_long: "United States",
    portCode: "GBENF",
    portName: "Enfield",
    portContinent: "Europe",
  },
  {
    lat: "51.430044",
    lng: "-0.569947",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBENG",
    portName: "Englefield Green",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBENH",
    portName: "Beenham",
    portContinent: "Europe",
  },
  {
    lat: "52.7013538",
    lng: "-0.4524599",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBENI",
    portName: "Essendine",
    portContinent: "Europe",
  },
  {
    lat: "54.34382429999999",
    lng: "-7.6315336",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBENK",
    portName: "Enniskillen",
    portContinent: "Europe",
  },
  {
    lat: "57.67744700000001",
    lng: "-2.259966",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBENN",
    portName: "Pennan",
    portContinent: "Europe",
  },
  {
    lat: "53.07521500000001",
    lng: "-2.1126649",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBENO",
    portName: "Endon",
    portContinent: "Europe",
  },
  {
    lat: "52.029936",
    lng: "-2.391321",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBENR",
    portName: "Eastnor",
    portContinent: "Europe",
  },
  {
    lat: "51.366456",
    lng: "0.211539",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBENS",
    portName: "Eynsford",
    portContinent: "Europe",
  },
  {
    lat: "51.5672808",
    lng: "-0.2710568",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBENT",
    portName: "Brent",
    portContinent: "Europe",
  },
  {
    lat: "54.12144079999999",
    lng: "-1.191874",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBENW",
    portName: "Easingwold",
    portContinent: "Europe",
  },
  {
    lat: "59.1838044",
    lng: "-2.7709563",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEOI",
    portName: "Eday",
    portContinent: "Europe",
  },
  {
    lat: "57.364597",
    lng: "-2.072972",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEOL",
    portName: "Ellon",
    portContinent: "Europe",
  },
  {
    lat: "50.70531339999999",
    lng: "-3.0718734",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEON",
    portName: "Seaton",
    portContinent: "Europe",
  },
  {
    lat: "52.6672407",
    lng: "-0.5970475",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEPH",
    portName: "Empingham",
    portContinent: "Europe",
  },
  {
    lat: "51.132008",
    lng: "-2.577716",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEPN",
    portName: "EastPennard",
    portContinent: "Europe",
  },
  {
    lat: "43.0534843",
    lng: "-71.0735476",
    country: "US",
    country_long: "United States",
    portCode: "GBEPP",
    portName: "Epping",
    portContinent: "Europe",
  },
  {
    lat: "51.33510039999999",
    lng: "-0.2637125",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEPS",
    portName: "Epsom",
    portContinent: "Europe",
  },
  {
    lat: "42.4450016",
    lng: "-90.9320766",
    country: "US",
    country_long: "United States",
    portCode: "GBEPW",
    portName: "Epworth",
    portContinent: "Europe",
  },
  {
    lat: "53.916286",
    lng: "-2.144158",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBERB",
    portName: "Earby",
    portContinent: "Europe",
  },
  {
    lat: "52.5219314",
    lng: "-1.8446106",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBERD",
    portName: "Erdington",
    portContinent: "Europe",
  },
  {
    lat: "53.990129",
    lng: "-0.9140249",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBERE",
    portName: "StamfordBridge",
    portContinent: "Europe",
  },
  {
    lat: "53.32135599999999",
    lng: "-0.9455030000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBERF",
    portName: "EastRetford",
    portContinent: "Europe",
  },
  {
    lat: "52.353865",
    lng: "0.031129",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBERH",
    portName: "Earith",
    portContinent: "Europe",
  },
  {
    lat: "51.480818",
    lng: "0.174675",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBERI",
    portName: "Erith",
    portContinent: "Europe",
  },
  {
    lat: "43.6777629",
    lng: "-79.4557561",
    country: "CA",
    country_long: "Canada",
    portCode: "GBERL",
    portName: "EarlsCourt",
    portContinent: "Europe",
  },
  {
    lat: "57.77219100000001",
    lng: "-3.952938",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBERN",
    portName: "Fearn",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBERO",
    portName: "Errol",
    portContinent: "Europe",
  },
  {
    lat: "-42.809722",
    lng: "147.254722",
    country: "AU",
    country_long: "Australia",
    portCode: "GBERR",
    portName: "Berriedale",
    portContinent: "Europe",
  },
  {
    lat: "55.639736",
    lng: "-2.678481",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBERS",
    portName: "Earlston",
    portContinent: "Europe",
  },
  {
    lat: "50.163821",
    lng: "-5.42736",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBERT",
    portName: "SaintErth",
    portContinent: "Europe",
  },
  {
    lat: "52.365569",
    lng: "-1.831061",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBERW",
    portName: "Earlswood",
    portContinent: "Europe",
  },
  {
    lat: "51.35248",
    lng: "-0.889119",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBERY",
    portName: "Eversley",
    portContinent: "Europe",
  },
  {
    lat: "50.840092",
    lng: "-0.6551209",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESA",
    portName: "Eastergate",
    portContinent: "Europe",
  },
  {
    lat: "52.979014",
    lng: "-0.9731719999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESB",
    portName: "EastBridgford",
    portContinent: "Europe",
  },
  {
    lat: "52.2147799",
    lng: "-0.2865122",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESC",
    portName: "EatonSocon",
    portContinent: "Europe",
  },
  {
    lat: "51.5476301",
    lng: "-0.2369246",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESD",
    portName: "Willesden",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBESE",
    portName: "Swestern",
    portContinent: "Europe",
  },
  {
    lat: "54.783504",
    lng: "-1.3516487",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESG",
    portName: "Easington",
    portContinent: "Europe",
  },
  {
    lat: "50.8342086",
    lng: "-0.2715558",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESH",
    portName: "Shoreham-by-Sea",
    portContinent: "Europe",
  },
  {
    lat: "51.413541",
    lng: "-0.321331",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESI",
    portName: "HamptonWick",
    portContinent: "Europe",
  },
  {
    lat: "52.81402809999999",
    lng: "-1.6371359",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESJ",
    portName: "Burton on Trent",
    portContinent: "Europe",
  },
  {
    lat: "58.00927300000001",
    lng: "-6.535165999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESK",
    portName: "Eishken",
    portContinent: "Europe",
  },
  {
    lat: "51.57726719999999",
    lng: "-0.3981245",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESL",
    portName: "Eastcote",
    portContinent: "Europe",
  },
  {
    lat: "52.2218329",
    lng: "1.2683278",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESM",
    portName: "EarlSoham",
    portContinent: "Europe",
  },
  {
    lat: "52.92392",
    lng: "-1.212394",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESN",
    portName: "Beeston",
    portContinent: "Europe",
  },
  {
    lat: "51.9199853",
    lng: "-1.4553444",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESO",
    portName: "Enstone",
    portContinent: "Europe",
  },
  {
    lat: "55.891683",
    lng: "-3.468143",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESQ",
    portName: "EastCalder",
    portContinent: "Europe",
  },
  {
    lat: "51.369487",
    lng: "-0.365927",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESR",
    portName: "Esher",
    portContinent: "Europe",
  },
  {
    lat: "60.49078499999999",
    lng: "-1.57504",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESS",
    portName: "Eshaness",
    portContinent: "Europe",
  },
  {
    lat: "52.6359854",
    lng: "-0.6278486",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEST",
    portName: "EdithWeston",
    portContinent: "Europe",
  },
  {
    lat: "50.9085955",
    lng: "0.2494166",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESU",
    portName: "E.Sussex",
    portContinent: "Europe",
  },
  {
    lat: "50.9666679",
    lng: "-1.5652335",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESW",
    portName: "EastWellow",
    portContinent: "Europe",
  },
  {
    lat: "51.57424469999999",
    lng: "0.4856781",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESX",
    portName: "Essex",
    portContinent: "Europe",
  },
  {
    lat: "51.2621139",
    lng: "-0.432844",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBESY",
    portName: "EastHorsley",
    portContinent: "Europe",
  },
  {
    lat: "55.323941",
    lng: "-4.399468",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBET2",
    portName: "Dalmellington",
    portContinent: "Europe",
  },
  {
    lat: "51.0090215",
    lng: "0.4389913",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBETA",
    portName: "Etchingham",
    portContinent: "Europe",
  },
  {
    lat: "52.04483099999999",
    lng: "-2.814976",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBETB",
    portName: "EatonBishop",
    portContinent: "Europe",
  },
  {
    lat: "51.1851783",
    lng: "-0.7034018",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBETD",
    portName: "Elstead",
    portContinent: "Europe",
  },
  {
    lat: "53.551434",
    lng: "-1.070363",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBETE",
    portName: "Edenthorpe",
    portContinent: "Europe",
  },
  {
    lat: "53.761278",
    lng: "-0.796979",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBETG",
    portName: "Eastrington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBETH",
    portName: "Bethesda",
    portContinent: "Europe",
  },
  {
    lat: "54.97154399999999",
    lng: "-2.949855",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBETL",
    portName: "Westlinton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBETM",
    portName: "Westham",
    portContinent: "Europe",
  },
  {
    lat: "51.49573549999999",
    lng: "-0.6044396",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBETN",
    portName: "Eton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBETO",
    portName: "Eaton",
    portContinent: "Europe",
  },
  {
    lat: "51.2123189",
    lng: "0.386213",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBETP",
    portName: "East Peckham",
    portContinent: "Europe",
  },
  {
    lat: "51.644142",
    lng: "-0.298193",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBETR",
    portName: "Elstree",
    portContinent: "Europe",
  },
  {
    lat: "55.419025",
    lng: "-3.15069",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBETT",
    portName: "Etrick Field (GBETT), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "42.900536",
    lng: "-71.31939729999999",
    country: "US",
    country_long: "United States",
    portCode: "GBETY",
    portName: "Betley",
    portContinent: "Europe",
  },
  {
    lat: "52.998337",
    lng: "-3.944893999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEUF",
    portName: "Blaenau-Ffestiniog",
    portContinent: "Europe",
  },
  {
    lat: "51.1481234",
    lng: "-2.5051297",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEVC",
    portName: "Evercreech",
    portContinent: "Europe",
  },
  {
    lat: "39.8522951",
    lng: "-74.88579890000001",
    country: "US",
    country_long: "United States",
    portCode: "GBEVE",
    portName: "Evesham",
    portContinent: "Europe",
  },
  {
    lat: "54.531231",
    lng: "-0.9011709999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEVL",
    portName: "Liverton",
    portContinent: "Europe",
  },
  {
    lat: "57.66287499999999",
    lng: "-4.33728",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEVT",
    portName: "Evanton (GBEVT), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.35248",
    lng: "-0.889119",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEVY",
    portName: "Eversley",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBEWD",
    portName: "Eastwood",
    portContinent: "Europe",
  },
  {
    lat: "51.3506319",
    lng: "-0.2533376",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEWE",
    portName: "Ewell",
    portContinent: "Europe",
  },
  {
    lat: "53.150703",
    lng: "-2.391806",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEWH",
    portName: "Elworth",
    portContinent: "Europe",
  },
  {
    lat: "52.2369982",
    lng: "0.9125416000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEWL",
    portName: "Elmswell",
    portContinent: "Europe",
  },
  {
    lat: "51.6155195",
    lng: "-1.0794576",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEWM",
    portName: "Ewelme",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBEWN",
    portName: "Newton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBEWO",
    portName: "Eastwood",
    portContinent: "Europe",
  },
  {
    lat: "53.3678916",
    lng: "-2.0047714",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEWS",
    portName: "New Mills Derbyshire",
    portContinent: "Europe",
  },
  {
    lat: "50.76927",
    lng: "-0.871516",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEWT",
    portName: "EastWittering",
    portContinent: "Europe",
  },
  {
    lat: "51.15423999999999",
    lng: "-0.442166",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEWU",
    portName: "Ewhurst",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBEXE",
    portName: "Exeter",
    portContinent: "Europe",
  },
  {
    lat: "50.619957",
    lng: "-3.413702",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEXM",
    portName: "Port of Exmouth",
    portContinent: "Europe",
  },
  {
    lat: "52.26584",
    lng: "0.371772",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEXN",
    portName: "Exning",
    portContinent: "Europe",
  },
  {
    lat: "53.669783",
    lng: "-2.674662",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEXT",
    portName: "Euxton",
    portContinent: "Europe",
  },
  {
    lat: "52.26584",
    lng: "0.371772",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEXX",
    portName: "Exning, Suffolk",
    portContinent: "Europe",
  },
  {
    lat: "51.195782",
    lng: "1.266747",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEY2",
    portName: "Eythorne",
    portContinent: "Europe",
  },
  {
    lat: "53.284166",
    lng: "-1.6710995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEYA",
    portName: "Eyam",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBEYE",
    portName: "Eye",
    portContinent: "Europe",
  },
  {
    lat: "51.366456",
    lng: "0.211539",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEYF",
    portName: "Eynsford, Kent",
    portContinent: "Europe",
  },
  {
    lat: "51.781605",
    lng: "-1.376999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEYH",
    portName: "Eynsham",
    portContinent: "Europe",
  },
  {
    lat: "38.002163",
    lng: "-121.2999858",
    country: "US",
    country_long: "United States",
    portCode: "GBEYK",
    portName: "E.Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "55.869058",
    lng: "-2.091068",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEYM",
    portName: "Eyemouth (GBEYM), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.781605",
    lng: "-1.376999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBEYN",
    portName: "Eynsham, Oxfordshire",
    portContinent: "Europe",
  },
  {
    lat: "55.92859",
    lng: "-4.3862629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFA2",
    portName: "Faifley",
    portContinent: "Europe",
  },
  {
    lat: "52.696651",
    lng: "-4.054346",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFAB",
    portName: "Fairbourne",
    portContinent: "Europe",
  },
  {
    lat: "51.70753699999999",
    lng: "-1.785135",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFAF",
    portName: "Fairford, Gloucestershire",
    portContinent: "Europe",
  },
  {
    lat: "51.658477",
    lng: "-1.584679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFAG",
    portName: "Faringdon, Oxfordshire",
    portContinent: "Europe",
  },
  {
    lat: "59.5339001",
    lng: "-1.6332962",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFAI",
    portName: "Fairisle",
    portContinent: "Europe",
  },
  {
    lat: "18.4927542",
    lng: "-77.6496877",
    country: "JM",
    country_long: "Jamaica",
    portCode: "GBFAL",
    portName: "Port of Falmouth",
    portContinent: "Europe",
  },
  {
    lat: "50.8668892",
    lng: "-0.0875363",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFAM",
    portName: "Falmer",
    portContinent: "Europe",
  },
  {
    lat: "51.658477",
    lng: "-1.584679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFAN",
    portName: "Faringdon",
    portContinent: "Europe",
  },
  {
    lat: "53.545838",
    lng: "-2.40396",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFAO",
    portName: "Farnworth, Greater Manchester",
    portContinent: "Europe",
  },
  {
    lat: "51.214321",
    lng: "-0.798802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFAR",
    portName: "Farnham",
    portContinent: "Europe",
  },
  {
    lat: "56.06745489999999",
    lng: "-4.8155789",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFAS",
    portName: "Faslane (GBFAS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.5372525",
    lng: "-0.2285405",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFAT",
    portName: "Farcet",
    portContinent: "Europe",
  },
  {
    lat: "57.1448064",
    lng: "-4.6805166",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFAU",
    portName: "FortAugustus",
    portContinent: "Europe",
  },
  {
    lat: "51.315994",
    lng: "0.889358",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFAV",
    portName: "Faversham",
    portContinent: "Europe",
  },
  {
    lat: "50.8268139",
    lng: "-1.3502269",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFAW",
    portName: "Fawley",
    portContinent: "Europe",
  },
  {
    lat: "51.138998",
    lng: "-0.039724",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFBG",
    portName: "Felbridge",
    portContinent: "Europe",
  },
  {
    lat: "54.116328",
    lng: "-0.124833",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFBH",
    portName: "Flamborough",
    portContinent: "Europe",
  },
  {
    lat: "50.233022",
    lng: "-5.226666",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFBK",
    portName: "Redruth, Cornwall",
    portContinent: "Europe",
  },
  {
    lat: "52.18268",
    lng: "0.222039",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFBN",
    portName: "Fulbourn",
    portContinent: "Europe",
  },
  {
    lat: "51.2868939",
    lng: "-0.7526149999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFBO",
    portName: "Farnborough",
    portContinent: "Europe",
  },
  {
    lat: "51.09523799999999",
    lng: "-2.091109",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFBP",
    portName: "FonthillBishop",
    portContinent: "Europe",
  },
  {
    lat: "50.836429",
    lng: "-0.8202570000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFBU",
    portName: "Fishbourne",
    portContinent: "Europe",
  },
  {
    lat: "54.686798",
    lng: "-3.515417",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFBY",
    portName: "Flimby",
    portContinent: "Europe",
  },
  {
    lat: "57.6142474",
    lng: "-3.0975138",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFCB",
    portName: "Fochabers",
    portContinent: "Europe",
  },
  {
    lat: "50.2043729",
    lng: "-5.050736",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFCK",
    portName: "Feock",
    portContinent: "Europe",
  },
  {
    lat: "51.5971434",
    lng: "-0.1979547",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFCL",
    portName: "Finchley",
    portContinent: "Europe",
  },
  {
    lat: "51.536335",
    lng: "-2.480503",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFCT",
    portName: "FramptonCotterell",
    portContinent: "Europe",
  },
  {
    lat: "52.6268459",
    lng: "-2.1326197",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFDH",
    portName: "Fordhouses",
    portContinent: "Europe",
  },
  {
    lat: "52.882844",
    lng: "-0.046556",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFDK",
    portName: "Fosdyke",
    portContinent: "Europe",
  },
  {
    lat: "43.5250265",
    lng: "-84.1227598",
    country: "US",
    country_long: "United States",
    portCode: "GBFDL",
    portName: "Freeland",
    portContinent: "Europe",
  },
  {
    lat: "40.8620404",
    lng: "-73.88569869999999",
    country: "US",
    country_long: "United States",
    portCode: "GBFDM",
    portName: "Fordham",
    portContinent: "Europe",
  },
  {
    lat: "50.807364",
    lng: "-1.899776",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFDN",
    portName: "Ferndown",
    portContinent: "Europe",
  },
  {
    lat: "50.868561",
    lng: "-0.407111",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFDO",
    portName: "Findon",
    portContinent: "Europe",
  },
  {
    lat: "51.214321",
    lng: "-0.798802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFDR",
    portName: "Farnham",
    portContinent: "Europe",
  },
  {
    lat: "53.2967045",
    lng: "-2.7245643",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFDS",
    portName: "Frodsham",
    portContinent: "Europe",
  },
  {
    lat: "56.876579",
    lng: "-2.412157",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFDU",
    portName: "Fordoun",
    portContinent: "Europe",
  },
  {
    lat: "52.717456",
    lng: "-1.764688",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFDY",
    portName: "Fradley",
    portContinent: "Europe",
  },
  {
    lat: "53.709984",
    lng: "-1.697018",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFDZ",
    portName: "Liversedge, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "51.858068",
    lng: "0.4368824",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFE6",
    portName: "Felsted",
    portContinent: "Europe",
  },
  {
    lat: "60.6166667",
    lng: "-0.8666667000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFEA",
    portName: "Fetlar",
    portContinent: "Europe",
  },
  {
    lat: "53.7098553",
    lng: "-1.2709415",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFEB",
    portName: "Ferrybridge",
    portContinent: "Europe",
  },
  {
    lat: "43.00948229999999",
    lng: "-88.2171886",
    country: "US",
    country_long: "United States",
    portCode: "GBFED",
    portName: "Hadfield",
    portContinent: "Europe",
  },
  {
    lat: "41.88808849999999",
    lng: "-87.6829089",
    country: "US",
    country_long: "United States",
    portCode: "GBFEE",
    portName: "Fairlie",
    portContinent: "Europe",
  },
  {
    lat: "51.851512",
    lng: "0.716502",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFEG",
    portName: "Feering",
    portContinent: "Europe",
  },
  {
    lat: "52.90833749999999",
    lng: "1.2820344",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFEI",
    portName: "Felbrigg",
    portContinent: "Europe",
  },
  {
    lat: "52.48637799999999",
    lng: "0.519412",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFEL",
    portName: "Feltwell",
    portContinent: "Europe",
  },
  {
    lat: "51.44414800000001",
    lng: "-0.410772",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFEM",
    portName: "Feltham",
    portContinent: "Europe",
  },
  {
    lat: "42.7978061",
    lng: "-83.7049498",
    country: "US",
    country_long: "United States",
    portCode: "GBFEN",
    portName: "Fenton",
    portContinent: "Europe",
  },
  {
    lat: "55.8467097",
    lng: "-6.0899152",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFEO",
    portName: "FeolinFerry",
    portContinent: "Europe",
  },
  {
    lat: "50.7970201",
    lng: "-0.6504314",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFEP",
    portName: "Felpham",
    portContinent: "Europe",
  },
  {
    lat: "52.403383",
    lng: "1.034179",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFER",
    portName: "Fersfield",
    portContinent: "Europe",
  },
  {
    lat: "53.674814",
    lng: "-1.356949",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFES",
    portName: "Featherstone, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBFET",
    portName: "Fleet",
    portContinent: "Europe",
  },
  {
    lat: "51.636471",
    lng: "-2.459793",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFFD",
    portName: "Falfield",
    portContinent: "Europe",
  },
  {
    lat: "52.959065",
    lng: "-3.933458",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFFE",
    portName: "Ffestiniog",
    portContinent: "Europe",
  },
  {
    lat: "52.0066779",
    lng: "-3.31889",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFFH",
    portName: "Felinfach",
    portContinent: "Europe",
  },
  {
    lat: "51.70753699999999",
    lng: "-1.785135",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFFO",
    portName: "Fairford",
    portContinent: "Europe",
  },
  {
    lat: "57.58505899999999",
    lng: "-4.539683999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFFR",
    portName: "Strathpeffer",
    portContinent: "Europe",
  },
  {
    lat: "50.9258359",
    lng: "-1.792526",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFGB",
    portName: "Fordingbridge",
    portContinent: "Europe",
  },
  {
    lat: "51.5215324",
    lng: "-0.1042414",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFGD",
    portName: "Farringdon",
    portContinent: "Europe",
  },
  {
    lat: "53.02372099999999",
    lng: "-1.966844",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFGH",
    portName: "Froghall",
    portContinent: "Europe",
  },
  {
    lat: "51.36541",
    lng: "0.277746",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFGN",
    portName: "FawkhamGreen",
    portContinent: "Europe",
  },
  {
    lat: "51.37339900000001",
    lng: "-0.9209209999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFH9",
    portName: "FarleyHill",
    portContinent: "Europe",
  },
  {
    lat: "51.62435499999999",
    lng: "-1.582347",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFHA",
    portName: "Fernham",
    portContinent: "Europe",
  },
  {
    lat: "50.8548464",
    lng: "-1.1865868",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFHM",
    portName: "Fareham",
    portContinent: "Europe",
  },
  {
    lat: "52.831333",
    lng: "0.849324",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFHO",
    portName: "Fakenham",
    portContinent: "Europe",
  },
  {
    lat: "57.697877",
    lng: "-2.902275",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFID",
    portName: "Findochty",
    portContinent: "Europe",
  },
  {
    lat: "59.5339001",
    lng: "-1.6332962",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFIE",
    portName: "Fair Isle (GBFIE), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.4863102",
    lng: "-0.9843103",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFIG",
    portName: "Finningley, South Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "55.94578806529537",
    lng: "-3.067459387347731",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFIH",
    portName: "Fisherrow/Musselburgh",
    portContinent: "Europe",
  },
  {
    lat: "52.004605",
    lng: "-0.4979453",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFIL",
    portName: "Flitwick, Bedfordshire",
    portContinent: "Europe",
  },
  {
    lat: "57.65916499999999",
    lng: "-3.607587699999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFIN",
    portName: "Findhorn",
    portContinent: "Europe",
  },
  {
    lat: "56.324882",
    lng: "-6.369892",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFIO",
    portName: "Fionnphort,IsleofMull",
    portContinent: "Europe",
  },
  {
    lat: "52.872992",
    lng: "-1.5441126",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFIR",
    portName: "Findern",
    portContinent: "Europe",
  },
  {
    lat: "51.993927",
    lng: "-4.975989000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFIS",
    portName: "Fishguard",
    portContinent: "Europe",
  },
  {
    lat: "56.63623399999999",
    lng: "-2.670945",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFKH",
    portName: "Friockheim",
    portContinent: "Europe",
  },
  {
    lat: "56.00187750000001",
    lng: "-3.7839131",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFKK",
    portName: "Falkirk",
    portContinent: "Europe",
  },
  {
    lat: "-51.796253",
    lng: "-59.523613",
    country: "FK",
    country_long: "Falkland Islands (Islas Malvinas)",
    portCode: "GBFKL",
    portName: "Falkland",
    portContinent: "Europe",
  },
  {
    lat: "52.831333",
    lng: "0.849324",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFKM",
    portName: "Fakenham",
    portContinent: "Europe",
  },
  {
    lat: "52.5370169",
    lng: "-1.045987",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFKY",
    portName: "Fleckney",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBFL8",
    portName: "Mountfield",
    portContinent: "Europe",
  },
  {
    lat: "51.597627",
    lng: "-0.7061160000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLA",
    portName: "FlackwellHeath",
    portContinent: "Europe",
  },
  {
    lat: "52.662396",
    lng: "1.645657",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLB",
    portName: "Filby",
    portContinent: "Europe",
  },
  {
    lat: "52.004605",
    lng: "-0.4979453",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLC",
    portName: "Flitwick",
    portContinent: "Europe",
  },
  {
    lat: "53.916661",
    lng: "-3.035673",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLE",
    portName: "Fleetwood",
    portContinent: "Europe",
  },
  {
    lat: "53.9371762",
    lng: "-1.0688195",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLF",
    portName: "Fulford",
    portContinent: "Europe",
  },
  {
    lat: "51.324825",
    lng: "-0.0353679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLG",
    portName: "Farleigh",
    portContinent: "Europe",
  },
  {
    lat: "58.828675",
    lng: "-3.1154803",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLH",
    portName: "Flotta (GBFLH), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.0125274",
    lng: "-83.6874562",
    country: "US",
    country_long: "United States",
    portCode: "GBFLI",
    portName: "Flint",
    portContinent: "Europe",
  },
  {
    lat: "54.17407",
    lng: "-2.97277",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLK",
    portName: "Flookburgh",
    portContinent: "Europe",
  },
  {
    lat: "54.94970379999999",
    lng: "-1.5691831",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLL",
    portName: "Felling",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBFLM",
    portName: "Fulham",
    portContinent: "Europe",
  },
  {
    lat: "48.89863889999999",
    lng: "-102.3923913",
    country: "US",
    country_long: "United States",
    portCode: "GBFLN",
    portName: "Flaxton",
    portContinent: "Europe",
  },
  {
    lat: "54.225099",
    lng: "-2.710841",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLO",
    portName: "Farleton",
    portContinent: "Europe",
  },
  {
    lat: "34.08374540000001",
    lng: "-118.2737893",
    country: "US",
    country_long: "United States",
    portCode: "GBFLR",
    portName: "Flore",
    portContinent: "Europe",
  },
  {
    lat: "53.508131",
    lng: "-2.164444",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLS",
    portName: "Failsworth",
    portContinent: "Europe",
  },
  {
    lat: "37.6178239",
    lng: "-94.82774409999999",
    country: "US",
    country_long: "United States",
    portCode: "GBFLT",
    portName: "Farlington",
    portContinent: "Europe",
  },
  {
    lat: "40.7674987",
    lng: "-73.833079",
    country: "US",
    country_long: "United States",
    portCode: "GBFLU",
    portName: "Flushing",
    portContinent: "Europe",
  },
  {
    lat: "53.625194",
    lng: "-0.6850229999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLW",
    portName: "Flixborough",
    portContinent: "Europe",
  },
  {
    lat: "53.625194",
    lng: "-0.6850229999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLX",
    portName: "Flixborough (GBFLX), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.210076",
    lng: "-0.290594",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFLY",
    portName: "Filey",
    portContinent: "Europe",
  },
  {
    lat: "51.230751",
    lng: "-2.320096",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFME",
    portName: "Frome",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBFMF",
    portName: "FulmarField",
    portContinent: "Europe",
  },
  {
    lat: "51.537668",
    lng: "-0.6170869",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFMR",
    portName: "FarnhamRoyal",
    portContinent: "Europe",
  },
  {
    lat: "51.0545617",
    lng: "0.4460292",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFMW",
    portName: "Flimwell",
    portContinent: "Europe",
  },
  {
    lat: "51.316221",
    lng: "-0.743239",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFMY",
    portName: "Frimley",
    portContinent: "Europe",
  },
  {
    lat: "37.0513374",
    lng: "-122.0732973",
    country: "US",
    country_long: "United States",
    portCode: "GBFN2",
    portName: "Felton",
    portContinent: "Europe",
  },
  {
    lat: "51.3624409",
    lng: "-0.858333",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFNC",
    portName: "Finchampstead",
    portContinent: "Europe",
  },
  {
    lat: "52.339868",
    lng: "-0.645921",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFND",
    portName: "Finedon",
    portContinent: "Europe",
  },
  {
    lat: "52.19024899999999",
    lng: "-1.103068",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFNE",
    portName: "Farthingstone",
    portContinent: "Europe",
  },
  {
    lat: "52.0066779",
    lng: "-3.31889",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFNF",
    portName: "Felinfach",
    portContinent: "Europe",
  },
  {
    lat: "56.506704",
    lng: "-5.8267896",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFNH",
    portName: "Fishnish (GBFNH), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.4218217",
    lng: "-2.0034771",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFNK",
    portName: "Frankley",
    portContinent: "Europe",
  },
  {
    lat: "53.4863102",
    lng: "-0.9843103",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFNL",
    portName: "Finningley",
    portContinent: "Europe",
  },
  {
    lat: "50.868561",
    lng: "-0.407111",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFNO",
    portName: "Findon",
    portContinent: "Europe",
  },
  {
    lat: "57.2436496",
    lng: "-2.4599974",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFNR",
    portName: "Fetternear",
    portContinent: "Europe",
  },
  {
    lat: "56.0992308",
    lng: "-4.839102599999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFNT",
    portName: "Finnart (GBFNT), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.049478",
    lng: "-0.723391",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFNU",
    portName: "Fernhurst",
    portContinent: "Europe",
  },
  {
    lat: "58.40000000000001",
    lng: "-4.883333299999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFNV",
    portName: "Foinaven",
    portContinent: "Europe",
  },
  {
    lat: "53.545838",
    lng: "-2.40396",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFNW",
    portName: "Farnworth",
    portContinent: "Europe",
  },
  {
    lat: "60.1312669",
    lng: "-2.0695406",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFOA",
    portName: "Foula",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBFOD",
    portName: "Ford",
    portContinent: "Europe",
  },
  {
    lat: "51.09739099999999",
    lng: "0.033222",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFOE",
    portName: "ForestRow",
    portContinent: "Europe",
  },
  {
    lat: "56.643558",
    lng: "-2.889062",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFOF",
    portName: "Forfar",
    portContinent: "Europe",
  },
  {
    lat: "51.18272899999999",
    lng: "0.357502",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFOG",
    portName: "FiveOakGreen",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBFOH",
    portName: "Ford",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBFOI",
    portName: "Foindlemore-LochGlendhu",
    portContinent: "Europe",
  },
  {
    lat: "35.4448836",
    lng: "-78.4269489",
    country: "US",
    country_long: "United States",
    portCode: "GBFOK",
    portName: "FourOaks",
    portContinent: "Europe",
  },
  {
    lat: "51.081397",
    lng: "1.169456",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFOL",
    portName: "Folkestone",
    portContinent: "Europe",
  },
  {
    lat: "58.36679",
    lng: "-3.895491199999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFON",
    portName: "Forsinard",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBFOR",
    portName: "Forth",
    portContinent: "Europe",
  },
  {
    lat: "52.883548",
    lng: "-1.7206867",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFOS",
    portName: "Foston",
    portContinent: "Europe",
  },
  {
    lat: "52.6735372",
    lng: "-2.1219659",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFOU",
    portName: "FourAshes",
    portContinent: "Europe",
  },
  {
    lat: "52.7525467",
    lng: "-0.9712873999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFOW",
    portName: "FrisbyontheWreake",
    portContinent: "Europe",
  },
  {
    lat: "50.33499",
    lng: "-4.636525",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFOY",
    portName: "Port of Fowey",
    portContinent: "Europe",
  },
  {
    lat: "51.48161899999999",
    lng: "-2.523631",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFPS",
    portName: "Fishponds",
    portContinent: "Europe",
  },
  {
    lat: "51.6092549",
    lng: "0.0405521",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFQW",
    portName: "Woodford Green, Essex",
    portContinent: "Europe",
  },
  {
    lat: "50.9644881",
    lng: "0.128199",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFR2",
    portName: "Framfield",
    portContinent: "Europe",
  },
  {
    lat: "54.796664",
    lng: "-1.591859",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFR3",
    portName: "Framwellgate",
    portContinent: "Europe",
  },
  {
    lat: "51.1657735",
    lng: "-0.7946867999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFR4",
    portName: "Frensham",
    portContinent: "Europe",
  },
  {
    lat: "51.4106986",
    lng: "-1.5724802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFR5",
    portName: "Froxfield",
    portContinent: "Europe",
  },
  {
    lat: "52.222147",
    lng: "1.342105",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRA",
    portName: "Framlingham",
    portContinent: "Europe",
  },
  {
    lat: "57.693352",
    lng: "-2.007631",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRB",
    portName: "Fraserburgh",
    portContinent: "Europe",
  },
  {
    lat: "51.55999600000001",
    lng: "-0.6115895",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRC",
    portName: "FarnhamCommon",
    portContinent: "Europe",
  },
  {
    lat: "42.4605917",
    lng: "-83.1346478",
    country: "US",
    country_long: "United States",
    portCode: "GBFRD",
    portName: "Ferndale",
    portContinent: "Europe",
  },
  {
    lat: "52.0926024",
    lng: "0.0723306",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRE",
    portName: "Fowlmere",
    portContinent: "Europe",
  },
  {
    lat: "51.5439215",
    lng: "0.0264272",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRG",
    portName: "ForestGate",
    portContinent: "Europe",
  },
  {
    lat: "51.84374099999999",
    lng: "0.9450000000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRH",
    portName: "Fingringhoe",
    portContinent: "Europe",
  },
  {
    lat: "51.830433",
    lng: "1.247224",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRI",
    portName: "Frinton-On-Sea",
    portContinent: "Europe",
  },
  {
    lat: "53.071593",
    lng: "-1.465795",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRL",
    portName: "Fritchley",
    portContinent: "Europe",
  },
  {
    lat: "53.558271",
    lng: "-3.068743",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRM",
    portName: "Formby",
    portContinent: "Europe",
  },
  {
    lat: "51.499804",
    lng: "-2.5212724",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRN",
    portName: "Frenchay",
    portContinent: "Europe",
  },
  {
    lat: "50.8069064",
    lng: "-1.0728536",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRO",
    portName: "Fratton",
    portContinent: "Europe",
  },
  {
    lat: "51.5215324",
    lng: "-0.1042414",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRR",
    portName: "Farringdon",
    portContinent: "Europe",
  },
  {
    lat: "57.60979099999999",
    lng: "-3.61998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRS",
    portName: "Forres",
    portContinent: "Europe",
  },
  {
    lat: "51.0961209",
    lng: "0.2705081",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRT",
    portName: "Frant",
    portContinent: "Europe",
  },
  {
    lat: "51.771893",
    lng: "-2.359766",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRV",
    portName: "Frampton On Severn",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBFRW",
    portName: "Freshwater",
    portContinent: "Europe",
  },
  {
    lat: "53.7098553",
    lng: "-1.2709415",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRX",
    portName: "Ferrybridge",
    portContinent: "Europe",
  },
  {
    lat: "54.68803399999999",
    lng: "-1.553164",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRY",
    portName: "Ferryhill",
    portContinent: "Europe",
  },
  {
    lat: "54.540489",
    lng: "-3.495363",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFRZ",
    portName: "Frizington",
    portContinent: "Europe",
  },
  {
    lat: "51.79853199999999",
    lng: "-1.883667",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFSE",
    portName: "Fossebridge",
    portContinent: "Europe",
  },
  {
    lat: "52.207139",
    lng: "-0.5526538999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFSH",
    portName: "Felmersham",
    portContinent: "Europe",
  },
  {
    lat: "53.15479999999999",
    lng: "-1.157234",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFSN",
    portName: "ForestTown",
    portContinent: "Europe",
  },
  {
    lat: "53.98764",
    lng: "-0.8660452",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFST",
    portName: "FullSutton",
    portContinent: "Europe",
  },
  {
    lat: "53.076276",
    lng: "0.1803819",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFSY",
    portName: "Friskney",
    portContinent: "Europe",
  },
  {
    lat: "51.4453954",
    lng: "-0.0160913",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFT8",
    portName: "Catford",
    portContinent: "Europe",
  },
  {
    lat: "51.288834",
    lng: "-0.356219",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFTC",
    portName: "Fetcham",
    portContinent: "Europe",
  },
  {
    lat: "51.647732",
    lng: "-3.985082",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFTF",
    portName: "Fforest-Fach",
    portContinent: "Europe",
  },
  {
    lat: "51.5439215",
    lng: "0.0264272",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFTFÂ",
    portName: "Forest Gate London",
    portContinent: "Europe",
  },
  {
    lat: "13.7297797",
    lng: "100.5930306",
    country: "TH",
    country_long: "Thailand",
    portCode: "GBFTH",
    portName: "Featherstone",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBFTI",
    portName: "TheForties",
    portContinent: "Europe",
  },
  {
    lat: "52.70988",
    lng: "1.3229449",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFTM",
    portName: "Frettenham",
    portContinent: "Europe",
  },
  {
    lat: "52.976577",
    lng: "-0.7233550999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFTN",
    portName: "Foston (GBFTN), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.005727",
    lng: "-2.1757769",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFTO",
    portName: "Fourstones",
    portContinent: "Europe",
  },
  {
    lat: "57.58155799999999",
    lng: "-4.130675",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFTR",
    portName: "Fortrose",
    portContinent: "Europe",
  },
  {
    lat: "51.830433",
    lng: "1.247224",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFTS",
    portName: "FrintonOnSea",
    portContinent: "Europe",
  },
  {
    lat: "57.553105",
    lng: "-2.015331",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFTU",
    portName: "Fetterangus",
    portContinent: "Europe",
  },
  {
    lat: "50.9641195",
    lng: "-0.5598525999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFTW",
    portName: "Fittleworth",
    portContinent: "Europe",
  },
  {
    lat: "52.18268",
    lng: "0.222039",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFUB",
    portName: "Fulbourn, Cambridgeshire",
    portContinent: "Europe",
  },
  {
    lat: "53.6926028",
    lng: "-2.25317",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFUC",
    portName: "Waterfoot",
    portContinent: "Europe",
  },
  {
    lat: "52.404533",
    lng: "-0.077056",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFUK",
    portName: "Warboys",
    portContinent: "Europe",
  },
  {
    lat: "51.5614826",
    lng: "-0.5597067",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFUL",
    portName: "Fulmer",
    portContinent: "Europe",
  },
  {
    lat: "51.5905794",
    lng: "0.8842626",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFUN",
    portName: "FoulnessIsland",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBFUR",
    portName: "Furnace",
    portContinent: "Europe",
  },
  {
    lat: "53.546071",
    lng: "-1.315757",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFUU",
    portName: "Thurnscoe",
    portContinent: "Europe",
  },
  {
    lat: "53.788019",
    lng: "-2.7126175",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFUW",
    portName: "Fulwood",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBFWC",
    portName: "Fenwick",
    portContinent: "Europe",
  },
  {
    lat: "55.0148188",
    lng: "-1.6450032",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFWD",
    portName: "Fawdon",
    portContinent: "Europe",
  },
  {
    lat: "51.649382",
    lng: "-4.865176",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFWE",
    portName: "FreshwaterEast",
    portContinent: "Europe",
  },
  {
    lat: "51.36541",
    lng: "0.277746",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFWK",
    portName: "FawhamGreen",
    portContinent: "Europe",
  },
  {
    lat: "50.8511223",
    lng: "-0.6536280999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFWL",
    portName: "Fontwell",
    portContinent: "Europe",
  },
  {
    lat: "56.81981700000001",
    lng: "-5.105218",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFWM",
    portName: "Fort William (GBFWM), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.32135599999999",
    lng: "-0.9455030000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFWR",
    portName: "Retford, Nottinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "-40.4776271",
    lng: "175.2835204",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBFXF",
    portName: "Foxton",
    portContinent: "Europe",
  },
  {
    lat: "52.077468",
    lng: "-2.332173",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFXP",
    portName: "Malvern Wells, Worcestershire",
    portContinent: "Europe",
  },
  {
    lat: "51.961726",
    lng: "1.351255",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFXT",
    portName: "Felixstowe",
    portContinent: "Europe",
  },
  {
    lat: "54.01664599999999",
    lng: "-1.397728",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFXY",
    portName: "Flaxby",
    portContinent: "Europe",
  },
  {
    lat: "50.137639",
    lng: "-119.495877",
    country: "CA",
    country_long: "Canada",
    portCode: "GBFY3",
    portName: "Fintry",
    portContinent: "Europe",
  },
  {
    lat: "52.1672769",
    lng: "-1.392069",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFYC",
    portName: "Fenny Compton, Warwickshire",
    portContinent: "Europe",
  },
  {
    lat: "51.76764900000001",
    lng: "-4.3688969",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFYD",
    portName: "Ferryside",
    portContinent: "Europe",
  },
  {
    lat: "51.0950023",
    lng: "-0.262389",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFYT",
    portName: "Faygate",
    portContinent: "Europe",
  },
  {
    lat: "50.6543557",
    lng: "-2.0983202",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFZE",
    portName: "Furzebrook",
    portContinent: "Europe",
  },
  {
    lat: "51.319664",
    lng: "-2.208853",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFZJ",
    portName: "Trowbridge, Wiltshire",
    portContinent: "Europe",
  },
  {
    lat: "51.507864",
    lng: "-2.576467",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFZO",
    portName: "Filton",
    portContinent: "Europe",
  },
  {
    lat: "52.615283",
    lng: "-1.699152",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBFZY",
    portName: "Fazeley",
    portContinent: "Europe",
  },
  {
    lat: "56.082071",
    lng: "-4.8357849",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAD",
    portName: "Garelochhead",
    portContinent: "Europe",
  },
  {
    lat: "58.927585",
    lng: "-3.2903267",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAE",
    portName: "Graemsay",
    portContinent: "Europe",
  },
  {
    lat: "52.155551",
    lng: "-0.19286",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAG",
    portName: "Gamlingay",
    portContinent: "Europe",
  },
  {
    lat: "55.623728",
    lng: "-2.814449",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAH",
    portName: "Galashiels",
    portContinent: "Europe",
  },
  {
    lat: "53.400575",
    lng: "-0.774465",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAI",
    portName: "Gainsborough",
    portContinent: "Europe",
  },
  {
    lat: "52.233295",
    lng: "-1.8230019",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAL",
    portName: "GreatAlne",
    portContinent: "Europe",
  },
  {
    lat: "51.79352369999999",
    lng: "-0.0155528",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAM",
    portName: "GreatAmwell",
    portContinent: "Europe",
  },
  {
    lat: "57.248664",
    lng: "-3.753322",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAN",
    portName: "Boat Of Garten",
    portContinent: "Europe",
  },
  {
    lat: "57.72822000000001",
    lng: "-5.693912",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAR",
    portName: "Gairloch",
    portContinent: "Europe",
  },
  {
    lat: "56.8785829",
    lng: "-6.1397243",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAS",
    portName: "Galmisdale (GBGAS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.95268",
    lng: "-1.603411",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAT",
    portName: "Gateshead",
    portContinent: "Europe",
  },
  {
    lat: "57.617104",
    lng: "-4.689967",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAV",
    portName: "Garve",
    portContinent: "Europe",
  },
  {
    lat: "53.22199",
    lng: "-4.281381",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAW",
    portName: "Gaerwen",
    portContinent: "Europe",
  },
  {
    lat: "52.1835531",
    lng: "-1.4699465",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGAY",
    portName: "Gaydon",
    portContinent: "Europe",
  },
  {
    lat: "51.60614409999999",
    lng: "0.4292132",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGB9",
    portName: "GreatBurstead",
    portContinent: "Europe",
  },
  {
    lat: "53.75439799999999",
    lng: "-0.740441",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBD",
    portName: "Gilberdyke",
    portContinent: "Europe",
  },
  {
    lat: "52.84127",
    lng: "-2.17281",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBF",
    portName: "GreatBridgeford",
    portContinent: "Europe",
  },
  {
    lat: "54.450984",
    lng: "-1.154817",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBG",
    portName: "GreatBroughton",
    portContinent: "Europe",
  },
  {
    lat: "52.29872109999999",
    lng: "-1.281004",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBH",
    portName: "Grandborough",
    portContinent: "Europe",
  },
  {
    lat: "51.94824999999999",
    lng: "0.4373280000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBI",
    portName: "GreatBardfield",
    portContinent: "Europe",
  },
  {
    lat: "51.2783826",
    lng: "-0.3733532",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBK",
    portName: "GreatBookham",
    portContinent: "Europe",
  },
  {
    lat: "52.259406",
    lng: "-0.8138966999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBL",
    portName: "Great Billing",
    portContinent: "Europe",
  },
  {
    lat: "51.2783826",
    lng: "-0.3733532",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBM",
    portName: "Great Bookham, Surrey",
    portContinent: "Europe",
  },
  {
    lat: "53.93499749999999",
    lng: "-2.2634157",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBN",
    portName: "Gisburn",
    portContinent: "Europe",
  },
  {
    lat: "52.28399899999999",
    lng: "-0.898924",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBO",
    portName: "Boughton",
    portContinent: "Europe",
  },
  {
    lat: "55.836732",
    lng: "-3.046099",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBR",
    portName: "Gorebridge",
    portContinent: "Europe",
  },
  {
    lat: "50.8129769",
    lng: "-0.422028",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBS",
    portName: "Goring-by-Sea",
    portContinent: "Europe",
  },
  {
    lat: "52.2686149",
    lng: "0.770647",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBT",
    portName: "GreatBarton",
    portContinent: "Europe",
  },
  {
    lat: "53.29436099999999",
    lng: "-2.502757",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBU",
    portName: "GreatBudworth",
    portContinent: "Europe",
  },
  {
    lat: "-35.6753278",
    lng: "174.349435",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBGBV",
    portName: "Glenbervie",
    portContinent: "Europe",
  },
  {
    lat: "52.89762899999999",
    lng: "-3.040359",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBW",
    portName: "Gobowen",
    portContinent: "Europe",
  },
  {
    lat: "51.797598",
    lng: "0.702525",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBX",
    portName: "GreatBraxted",
    portContinent: "Europe",
  },
  {
    lat: "51.855588",
    lng: "1.061692",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGBY",
    portName: "GreatBentley",
    portContinent: "Europe",
  },
  {
    lat: "52.0295518",
    lng: "0.07195939999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGCH",
    portName: "GreatChishill",
    portContinent: "Europe",
  },
  {
    lat: "53.57058600000001",
    lng: "-0.138773",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGCS",
    portName: "GreatCoates",
    portContinent: "Europe",
  },
  {
    lat: "53.57058600000001",
    lng: "-0.138773",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGCT",
    portName: "GreatCoates",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBGCU",
    portName: "GoldenCross",
    portContinent: "Europe",
  },
  {
    lat: "53.268083",
    lng: "-3.7935",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGCW",
    portName: "GlanConwy",
    portContinent: "Europe",
  },
  {
    lat: "38.0534867",
    lng: "-78.585247",
    country: "US",
    country_long: "United States",
    portCode: "GBGCX",
    portName: "Greencroft",
    portContinent: "Europe",
  },
  {
    lat: "56.364235",
    lng: "-2.8926849",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGDB",
    portName: "Guardbridge",
    portContinent: "Europe",
  },
  {
    lat: "51.23622",
    lng: "-0.570409",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGDD",
    portName: "Guildford",
    portContinent: "Europe",
  },
  {
    lat: "54.005996",
    lng: "-0.443377",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGDF",
    portName: "GreatDriffield",
    portContinent: "Europe",
  },
  {
    lat: "51.5740013",
    lng: "-0.1987725",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGDG",
    portName: "GoldersGreen",
    portContinent: "Europe",
  },
  {
    lat: "52.8145879",
    lng: "0.09221789999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGDK",
    portName: "GedneyDyke",
    portContinent: "Europe",
  },
  {
    lat: "54.12643600000001",
    lng: "-0.271603",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGDL",
    portName: "Grindale",
    portContinent: "Europe",
  },
  {
    lat: "52.319427",
    lng: "-0.175166",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGDM",
    portName: "Godmanchester",
    portContinent: "Europe",
  },
  {
    lat: "57.6722271",
    lng: "-2.337199",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGDN",
    portName: "Gardenstown",
    portContinent: "Europe",
  },
  {
    lat: "52.2350526",
    lng: "-0.7139795999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGDO",
    portName: "Grendon",
    portContinent: "Europe",
  },
  {
    lat: "51.5792473",
    lng: "0.2076649",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGDP",
    portName: "GideaPark",
    portContinent: "Europe",
  },
  {
    lat: "53.759594",
    lng: "-1.632524",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGDR",
    portName: "Gildersome",
    portContinent: "Europe",
  },
  {
    lat: "51.222252",
    lng: "-0.048366",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGDS",
    portName: "South Godstone Surrey",
    portContinent: "Europe",
  },
  {
    lat: "52.43844499999999",
    lng: "-0.690703",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGDT",
    portName: "Geddington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBGE2",
    portName: "Gelli",
    portContinent: "Europe",
  },
  {
    lat: "53.3772565",
    lng: "-3.1197139",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGEA",
    portName: "Greasby",
    portContinent: "Europe",
  },
  {
    lat: "55.706534",
    lng: "-2.463809",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGEE",
    portName: "Greenlaw",
    portContinent: "Europe",
  },
  {
    lat: "51.8581564",
    lng: "0.5241528",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGEN",
    portName: "GreatNotley",
    portContinent: "Europe",
  },
  {
    lat: "52.527231",
    lng: "-0.7496079999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGET",
    portName: "Great Easton",
    portContinent: "Europe",
  },
  {
    lat: "51.9741586",
    lng: "-0.8777727",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGEW",
    portName: "GreatHorwood",
    portContinent: "Europe",
  },
  {
    lat: "42.9614039",
    lng: "-88.0125865",
    country: "US",
    country_long: "United States",
    portCode: "GBGFD",
    portName: "Greenfield",
    portContinent: "Europe",
  },
  {
    lat: "55.800047",
    lng: "-4.295033",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGFF",
    portName: "Giffnock",
    portContinent: "Europe",
  },
  {
    lat: "56.2789453",
    lng: "-3.4005869",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGFG",
    portName: "Glenfarg",
    portContinent: "Europe",
  },
  {
    lat: "55.00827899999999",
    lng: "-1.6188777",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGFH",
    portName: "Gosforth",
    portContinent: "Europe",
  },
  {
    lat: "42.9614039",
    lng: "-88.0125865",
    country: "US",
    country_long: "United States",
    portCode: "GBGFI",
    portName: "Greenfield",
    portContinent: "Europe",
  },
  {
    lat: "50.9498413",
    lng: "-0.680526",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGFM",
    portName: "Graffham",
    portContinent: "Europe",
  },
  {
    lat: "56.8714325",
    lng: "-5.4383094",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGFN",
    portName: "Glenfinnan",
    portContinent: "Europe",
  },
  {
    lat: "51.709119",
    lng: "-0.086642",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGFO",
    portName: "Goff'sOak",
    portContinent: "Europe",
  },
  {
    lat: "40.9439477",
    lng: "-80.79146899999999",
    country: "US",
    country_long: "United States",
    portCode: "GBGFR",
    portName: "Greenford",
    portContinent: "Europe",
  },
  {
    lat: "55.00827899999999",
    lng: "-1.6188777",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGFT",
    portName: "Gosforth",
    portContinent: "Europe",
  },
  {
    lat: "52.973145",
    lng: "-3.261154",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGFY",
    portName: "Glyndyfrdwy",
    portContinent: "Europe",
  },
  {
    lat: "55.7391539",
    lng: "-4.677565299999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGGC",
    portName: "Glengarnock",
    portContinent: "Europe",
  },
  {
    lat: "51.790444",
    lng: "-0.5082974",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGGD",
    portName: "GreatGaddesden",
    portContinent: "Europe",
  },
  {
    lat: "54.767818",
    lng: "-2.3978959",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGGI",
    portName: "Garrigill",
    portContinent: "Europe",
  },
  {
    lat: "51.664336",
    lng: "-3.251143",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGGR",
    portName: "Gelligaer",
    portContinent: "Europe",
  },
  {
    lat: "52.36319",
    lng: "-0.5916239999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGGT",
    portName: "GreatAddington",
    portContinent: "Europe",
  },
  {
    lat: "56.0097152",
    lng: "-3.7227698",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGGU",
    portName: "Grangemounth",
    portContinent: "Europe",
  },
  {
    lat: "53.984331",
    lng: "-2.101438",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGGV",
    portName: "Gargrave",
    portContinent: "Europe",
  },
  {
    lat: "39.7749066",
    lng: "-75.5778801",
    country: "US",
    country_long: "United States",
    portCode: "GBGH9",
    portName: "Hagley",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBGHA",
    portName: "Garth",
    portContinent: "Europe",
  },
  {
    lat: "51.386322",
    lng: "0.551438",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGHC",
    portName: "Gillingham, Kent",
    portContinent: "Europe",
  },
  {
    lat: "54.882438",
    lng: "-4.184215",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGHF",
    portName: "GatehouseofFleet",
    portContinent: "Europe",
  },
  {
    lat: "52.222946",
    lng: "-0.8420139999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGHH",
    portName: "GreatHoughton",
    portContinent: "Europe",
  },
  {
    lat: "51.4489903",
    lng: "0.284623",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGHI",
    portName: "Greenhithe",
    portContinent: "Europe",
  },
  {
    lat: "52.6137622",
    lng: "0.0674857",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGHN",
    portName: "Guyhirn",
    portContinent: "Europe",
  },
  {
    lat: "53.34999999999999",
    lng: "-1.216667",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGHO",
    portName: "Beighton",
    portContinent: "Europe",
  },
  {
    lat: "54.4143001",
    lng: "-7.1645201",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGHR",
    portName: "Clogher",
    portContinent: "Europe",
  },
  {
    lat: "52.807607",
    lng: "-2.000361",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGHW",
    portName: "GreatHaywood",
    portContinent: "Europe",
  },
  {
    lat: "51.71124589999999",
    lng: "-1.0705222",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGHY",
    portName: "Great Haseley",
    portContinent: "Europe",
  },
  {
    lat: "43.5475771",
    lng: "-71.4067385",
    country: "US",
    country_long: "United States",
    portCode: "GBGI9",
    portName: "Gilford",
    portContinent: "Europe",
  },
  {
    lat: "54.99010209999999",
    lng: "-2.572867",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGIA",
    portName: "Gilsland",
    portContinent: "Europe",
  },
  {
    lat: "53.75439799999999",
    lng: "-0.740441",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGIB",
    portName: "Gilberdyke, East Riding Of Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "55.676607",
    lng: "-5.742300999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGIG",
    portName: "Ardminish,GighaIsland",
    portContinent: "Europe",
  },
  {
    lat: "51.8315099",
    lng: "0.325417",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGIH",
    portName: "HighRoding",
    portContinent: "Europe",
  },
  {
    lat: "51.386322",
    lng: "0.551438",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGIL",
    portName: "Gillingham",
    portContinent: "Europe",
  },
  {
    lat: "52.7723889",
    lng: "0.5489516",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGIM",
    portName: "Grimston",
    portContinent: "Europe",
  },
  {
    lat: "55.240164",
    lng: "-4.854217999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGIR",
    portName: "Girvan",
    portContinent: "Europe",
  },
  {
    lat: "54.789343",
    lng: "-4.3718389",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGIS",
    portName: "Garlieston Harbor",
    portContinent: "Europe",
  },
  {
    lat: "51.820017",
    lng: "-3.0688399",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGIV",
    portName: "Govilon",
    portContinent: "Europe",
  },
  {
    lat: "51.7910809",
    lng: "-2.2700494",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGL7",
    portName: "Haresfield",
    portContinent: "Europe",
  },
  {
    lat: "53.843964",
    lng: "-2.986281",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGL9",
    portName: "Poulton",
    portContinent: "Europe",
  },
  {
    lat: "39.7485114",
    lng: "-104.9843972",
    country: "US",
    country_long: "United States",
    portCode: "GBGLA",
    portName: "Glenarm",
    portContinent: "Europe",
  },
  {
    lat: "54.879954",
    lng: "-4.8087989",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLC",
    portName: "Glenluce",
    portContinent: "Europe",
  },
  {
    lat: "53.997847",
    lng: "-2.850347",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLD",
    portName: "Glasson Dock (GBGLD), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.096553",
    lng: "0.6685219",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLE",
    portName: "Glemsford",
    portContinent: "Europe",
  },
  {
    lat: "40.5181236",
    lng: "-80.130335",
    country: "US",
    country_long: "United States",
    portCode: "GBGLF",
    portName: "Glenfield",
    portContinent: "Europe",
  },
  {
    lat: "56.827595",
    lng: "-5.817474",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLG",
    portName: "Glenuig",
    portContinent: "Europe",
  },
  {
    lat: "56.0589433",
    lng: "-4.8299161",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLH",
    portName: "Gareloch",
    portContinent: "Europe",
  },
  {
    lat: "53.7144597",
    lng: "-1.346025",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLK",
    portName: "Glasshoughton",
    portContinent: "Europe",
  },
  {
    lat: "51.386322",
    lng: "0.551438",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLL",
    portName: "Gillingham",
    portContinent: "Europe",
  },
  {
    lat: "52.096553",
    lng: "0.6685219",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLM",
    portName: "Glemsford, Suffolk",
    portContinent: "Europe",
  },
  {
    lat: "55.422988",
    lng: "-1.8902579",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLN",
    portName: "Glanton",
    portContinent: "Europe",
  },
  {
    lat: "42.6159285",
    lng: "-70.6619888",
    country: "US",
    country_long: "United States",
    portCode: "GBGLO",
    portName: "Gloucester",
    portContinent: "Europe",
  },
  {
    lat: "57.973406",
    lng: "-3.976086",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLP",
    portName: "Golspie",
    portContinent: "Europe",
  },
  {
    lat: "56.20084199999999",
    lng: "-3.15986",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLS",
    portName: "Glenrothes",
    portContinent: "Europe",
  },
  {
    lat: "55.601592",
    lng: "-4.382581",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLT",
    portName: "Galston",
    portContinent: "Europe",
  },
  {
    lat: "55.864237",
    lng: "-4.251806",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLW",
    portName: "Glasgow",
    portContinent: "Europe",
  },
  {
    lat: "50.8886569",
    lng: "0.2468259",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGLY",
    portName: "Hellingly",
    portContinent: "Europe",
  },
  {
    lat: "56.1263988",
    lng: "-4.8175798",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGMA",
    portName: "Glen Mallan (GBGMA), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1116462",
    lng: "-2.32607",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGML",
    portName: "GreatMalvern",
    portContinent: "Europe",
  },
  {
    lat: "52.1116462",
    lng: "-2.32607",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGMLÂ",
    portName: "Malvern",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBGMN",
    portName: "Gt Manchester",
    portContinent: "Europe",
  },
  {
    lat: "56.143923",
    lng: "-4.3803919",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGMR",
    portName: "Gartmore",
    portContinent: "Europe",
  },
  {
    lat: "53.729406",
    lng: "-1.682883",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGMS",
    portName: "Gomersal",
    portContinent: "Europe",
  },
  {
    lat: "52.928952",
    lng: "-1.097941",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGMT",
    portName: "Gamston",
    portContinent: "Europe",
  },
  {
    lat: "39.6312139",
    lng: "-76.8624777",
    country: "US",
    country_long: "United States",
    portCode: "GBGMU",
    portName: "Greenmount",
    portContinent: "Europe",
  },
  {
    lat: "52.1116462",
    lng: "-2.32607",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGMV",
    portName: "Great Malvern, Worcestershire",
    portContinent: "Europe",
  },
  {
    lat: "57.48333330000001",
    lng: "-7.2333333",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGMY",
    portName: "Grimsay",
    portContinent: "Europe",
  },
  {
    lat: "40.086097",
    lng: "-105.9394597",
    country: "US",
    country_long: "United States",
    portCode: "GBGNB",
    portName: "Granby",
    portContinent: "Europe",
  },
  {
    lat: "54.68872",
    lng: "-7.0755",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGNC",
    portName: "Greencastle (GBGNC), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.9277266",
    lng: "-4.1334836",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGND",
    portName: "Gwynedd",
    portContinent: "Europe",
  },
  {
    lat: "51.4939421",
    lng: "-2.4809537",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGNE",
    portName: "Emersons Green",
    portContinent: "Europe",
  },
  {
    lat: "51.5227526",
    lng: "-1.1333445",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGNG",
    portName: "Goring",
    portContinent: "Europe",
  },
  {
    lat: "51.4933675",
    lng: "0.0098214",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGNH",
    portName: "Greenwich, London Postal",
    portContinent: "Europe",
  },
  {
    lat: "39.2682882",
    lng: "-76.9943449",
    country: "US",
    country_long: "United States",
    portCode: "GBGNL",
    portName: "Glenelg",
    portContinent: "Europe",
  },
  {
    lat: "55.886356",
    lng: "-3.996026",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGNM",
    portName: "Glenmavis",
    portContinent: "Europe",
  },
  {
    lat: "52.98977980000001",
    lng: "-0.9886852",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGNQ",
    portName: "Gunthorpe",
    portContinent: "Europe",
  },
  {
    lat: "53.439279",
    lng: "-1.496972",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGNS",
    portName: "Grenoside",
    portContinent: "Europe",
  },
  {
    lat: "41.0262417",
    lng: "-73.6281964",
    country: "US",
    country_long: "United States",
    portCode: "GBGNW",
    portName: "Greenwich",
    portContinent: "Europe",
  },
  {
    lat: "52.87059199999999",
    lng: "-0.161466",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGO6",
    portName: "Gosberton",
    portContinent: "Europe",
  },
  {
    lat: "51.1013679",
    lng: "-3.064444",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGO7",
    portName: "Goathurst",
    portContinent: "Europe",
  },
  {
    lat: "52.46111",
    lng: "-0.7228279999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOA",
    portName: "GreatOakley",
    portContinent: "Europe",
  },
  {
    lat: "53.30035299999999",
    lng: "-4.236241",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOC",
    portName: "Llanbedrgoch",
    portContinent: "Europe",
  },
  {
    lat: "51.18573199999999",
    lng: "-0.612808",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOD",
    portName: "Godalming",
    portContinent: "Europe",
  },
  {
    lat: "52.685873",
    lng: "0.09599099999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOF",
    portName: "Gorefield",
    portContinent: "Europe",
  },
  {
    lat: "53.6777539",
    lng: "-0.3309549",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOH",
    portName: "Goxhill",
    portContinent: "Europe",
  },
  {
    lat: "52.004502",
    lng: "-4.996011",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOI",
    portName: "Goodwick",
    portContinent: "Europe",
  },
  {
    lat: "53.47542499999999",
    lng: "-2.598247",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOL",
    portName: "Golborne",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBGON",
    portName: "Gordon",
    portContinent: "Europe",
  },
  {
    lat: "53.7044721",
    lng: "-0.874562",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOO",
    portName: "Goole",
    portContinent: "Europe",
  },
  {
    lat: "51.669543",
    lng: "-4.041545",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOQ",
    portName: "Gorseinon",
    portContinent: "Europe",
  },
  {
    lat: "50.24184529999999",
    lng: "-4.7940786",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOR",
    portName: "Gorran Haven",
    portContinent: "Europe",
  },
  {
    lat: "39.3508793",
    lng: "-86.6669472",
    country: "US",
    country_long: "United States",
    portCode: "GBGOS",
    portName: "Gosport",
    portContinent: "Europe",
  },
  {
    lat: "51.247571",
    lng: "-0.067578",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOT",
    portName: "Godstone, Surrey",
    portContinent: "Europe",
  },
  {
    lat: "51.11306",
    lng: "0.457947",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOU",
    portName: "Goudhurst",
    portContinent: "Europe",
  },
  {
    lat: "51.6480279",
    lng: "-4.042929",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOW",
    portName: "Gowerton",
    portContinent: "Europe",
  },
  {
    lat: "53.6777539",
    lng: "-0.3309549",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOX",
    portName: "Goxhill",
    portContinent: "Europe",
  },
  {
    lat: "57.33041",
    lng: "-3.609628",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGOY",
    portName: "Grantown-on-Spey",
    portContinent: "Europe",
  },
  {
    lat: "53.407621",
    lng: "-1.441425",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGPE",
    portName: "Grimesthorpe",
    portContinent: "Europe",
  },
  {
    lat: "53.284355",
    lng: "-3.581405",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGPJ",
    portName: "Abergele, Conwy",
    portContinent: "Europe",
  },
  {
    lat: "55.1604107",
    lng: "-1.6068038",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGPO",
    portName: "GuidePost",
    portContinent: "Europe",
  },
  {
    lat: "53.22199",
    lng: "-4.281381",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGQY",
    portName: "Gaerwen",
    portContinent: "Europe",
  },
  {
    lat: "51.1449189",
    lng: "0.8341122",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGR5",
    portName: "GreatChart",
    portContinent: "Europe",
  },
  {
    lat: "51.37982",
    lng: "-1.602843",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGR9",
    portName: "GreatBedwyn",
    portContinent: "Europe",
  },
  {
    lat: "52.1859312",
    lng: "-0.1463079",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRA",
    portName: "Gransden",
    portContinent: "Europe",
  },
  {
    lat: "52.112998",
    lng: "1.0928389",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRB",
    portName: "GreatBlakenham",
    portContinent: "Europe",
  },
  {
    lat: "51.582362",
    lng: "-0.5575",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRC",
    portName: "Gerrards Cross",
    portContinent: "Europe",
  },
  {
    lat: "51.873148",
    lng: "0.35792",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRD",
    portName: "Great Dunmow",
    portContinent: "Europe",
  },
  {
    lat: "51.241649",
    lng: "-2.541652",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRE",
    portName: "GurneySlade",
    portContinent: "Europe",
  },
  {
    lat: "53.792149",
    lng: "-1.389877",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRF",
    portName: "Garforth",
    portContinent: "Europe",
  },
  {
    lat: "56.0097152",
    lng: "-3.7227698",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRG",
    portName: "Grangemouth",
    portContinent: "Europe",
  },
  {
    lat: "53.781914",
    lng: "-2.400249",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRH",
    portName: "Great Harwood",
    portContinent: "Europe",
  },
  {
    lat: "53.805675",
    lng: "-2.630807",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRI",
    portName: "Grimsargh",
    portContinent: "Europe",
  },
  {
    lat: "51.3577444",
    lng: "0.7376158",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRJ",
    portName: "GrovehurstJetty/Sittingbourne",
    portContinent: "Europe",
  },
  {
    lat: "55.95647599999999",
    lng: "-4.771983",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRK",
    portName: "Greenock (GBGRK), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.570645",
    lng: "1.734211",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRL",
    portName: "Gorleston-on-Sea",
    portContinent: "Europe",
  },
  {
    lat: "51.702655",
    lng: "-0.70319",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRM",
    portName: "Great Missenden",
    portContinent: "Europe",
  },
  {
    lat: "44.5888512",
    lng: "-90.46124689999999",
    country: "US",
    country_long: "United States",
    portCode: "GBGRN",
    portName: "Granton",
    portContinent: "Europe",
  },
  {
    lat: "54.6730001",
    lng: "-5.614309899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRO",
    portName: "Groomsport",
    portContinent: "Europe",
  },
  {
    lat: "54.22393",
    lng: "-0.331444",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRP",
    portName: "Gristhorpe",
    portContinent: "Europe",
  },
  {
    lat: "52.8083261",
    lng: "0.906191",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRR",
    portName: "Great Ryburgh",
    portContinent: "Europe",
  },
  {
    lat: "53.391619",
    lng: "-2.642842",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRS",
    portName: "GreatSankey",
    portContinent: "Europe",
  },
  {
    lat: "41.14045489999999",
    lng: "-96.24368419999999",
    country: "US",
    country_long: "United States",
    portCode: "GBGRT",
    portName: "Gretna",
    portContinent: "Europe",
  },
  {
    lat: "59.8730669",
    lng: "-1.2785563",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRU",
    portName: "Grutness",
    portContinent: "Europe",
  },
  {
    lat: "55.927598",
    lng: "-2.658761",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRV",
    portName: "Garvald",
    portContinent: "Europe",
  },
  {
    lat: "39.8532057",
    lng: "-82.88827599999999",
    country: "US",
    country_long: "United States",
    portCode: "GBGRW",
    portName: "GrovePort",
    portContinent: "Europe",
  },
  {
    lat: "52.1859312",
    lng: "-0.1463079",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRX",
    portName: "Great Gransden",
    portContinent: "Europe",
  },
  {
    lat: "51.147427",
    lng: "-2.718454",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRY",
    portName: "Glastonbury",
    portContinent: "Europe",
  },
  {
    lat: "52.147141",
    lng: "0.138272",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGRZ",
    portName: "GreatShelford",
    portContinent: "Europe",
  },
  {
    lat: "56.5626311",
    lng: "-5.5480111",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGSA",
    portName: "Glensanda (GBGSA), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.536241",
    lng: "-1.049544",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGSB",
    portName: "Guisborough",
    portContinent: "Europe",
  },
  {
    lat: "54.191009",
    lng: "-2.910688",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGSD",
    portName: "Grange-over-Sands",
    portContinent: "Europe",
  },
  {
    lat: "51.93713899999999",
    lng: "0.592839",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGSF",
    portName: "Gosfield",
    portContinent: "Europe",
  },
  {
    lat: "52.2680606",
    lng: "-0.3562674",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGSG",
    portName: "GreatStauhgton",
    portContinent: "Europe",
  },
  {
    lat: "51.21841269999999",
    lng: "-0.4520262",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGSH",
    portName: "Gomshall",
    portContinent: "Europe",
  },
  {
    lat: "51.9022179",
    lng: "0.4720356",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGSL",
    portName: "GreatSaling",
    portContinent: "Europe",
  },
  {
    lat: "55.601592",
    lng: "-4.382581",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGSN",
    portName: "Galston",
    portContinent: "Europe",
  },
  {
    lat: "53.4433284",
    lng: "-1.948907",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGSP",
    portName: "Glossop",
    portContinent: "Europe",
  },
  {
    lat: "35.2309875",
    lng: "-80.7686157",
    country: "US",
    country_long: "United States",
    portCode: "GBGSR",
    portName: "Aldersgate",
    portContinent: "Europe",
  },
  {
    lat: "53.4608655",
    lng: "-2.8932894",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGSS",
    portName: "Gillmoss",
    portContinent: "Europe",
  },
  {
    lat: "51.4155487",
    lng: "-2.1681898",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGST",
    portName: "Gastard",
    portContinent: "Europe",
  },
  {
    lat: "53.272124",
    lng: "-2.93838",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGSU",
    portName: "GreatSutton",
    portContinent: "Europe",
  },
  {
    lat: "53.567471",
    lng: "-0.080784",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGSY",
    portName: "Grimsby",
    portContinent: "Europe",
  },
  {
    lat: "53.90302699999999",
    lng: "-2.772196",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTA",
    portName: "Garstang",
    portContinent: "Europe",
  },
  {
    lat: "36.6997813",
    lng: "-76.2594981",
    country: "US",
    country_long: "United States",
    portCode: "GBGTB",
    portName: "GreatBridge",
    portContinent: "Europe",
  },
  {
    lat: "55.88730700000001",
    lng: "-4.084701",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTC",
    portName: "Gartcosh",
    portContinent: "Europe",
  },
  {
    lat: "51.4745655",
    lng: "-1.4493005",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTD",
    portName: "GreatShefford",
    portContinent: "Europe",
  },
  {
    lat: "44.1700735",
    lng: "-69.6253222",
    country: "US",
    country_long: "United States",
    portCode: "GBGTF",
    portName: "Whitefield",
    portContinent: "Europe",
  },
  {
    lat: "51.2889424",
    lng: "0.5036942999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTG",
    portName: "Allington",
    portContinent: "Europe",
  },
  {
    lat: "51.073229",
    lng: "-0.8899969",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTH",
    portName: "Greatham",
    portContinent: "Europe",
  },
  {
    lat: "53.686494",
    lng: "-1.866303",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTL",
    portName: "Greetland",
    portContinent: "Europe",
  },
  {
    lat: "-27.5707973",
    lng: "152.2024469",
    country: "AU",
    country_long: "Australia",
    portCode: "GBGTM",
    portName: "Grantham",
    portContinent: "Europe",
  },
  {
    lat: "53.3537902",
    lng: "-2.9036397",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTN",
    portName: "Port of Garston",
    portContinent: "Europe",
  },
  {
    lat: "50.9546459",
    lng: "-4.135998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTO",
    portName: "GreatTorrington",
    portContinent: "Europe",
  },
  {
    lat: "53.53460099999999",
    lng: "-1.307202",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTP",
    portName: "Goldthorpe",
    portContinent: "Europe",
  },
  {
    lat: "51.962945",
    lng: "-0.6855697",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTR",
    portName: "GreatBrickhill",
    portContinent: "Europe",
  },
  {
    lat: "52.873663",
    lng: "0.8914170000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTS",
    portName: "GreatSnoring",
    portContinent: "Europe",
  },
  {
    lat: "51.9000448",
    lng: "0.7479248",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTT",
    portName: "GreatTey",
    portContinent: "Europe",
  },
  {
    lat: "51.955499",
    lng: "-2.9899079",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTW",
    portName: "Longtown",
    portContinent: "Europe",
  },
  {
    lat: "52.598233",
    lng: "1.728047",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGTY",
    portName: "Great Yarmouth",
    portContinent: "Europe",
  },
  {
    lat: "54.536241",
    lng: "-1.049544",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGUB",
    portName: "Guisborough, North Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "43.719923",
    lng: "6.979337999999999",
    country: "FR",
    country_long: "France",
    portCode: "GBGUD",
    portName: "Gourdon",
    portContinent: "Europe",
  },
  {
    lat: "53.877118",
    lng: "-1.706645",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGUE",
    portName: "Guiseley, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "53.877118",
    lng: "-1.706645",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGUI",
    portName: "Guiseley",
    portContinent: "Europe",
  },
  {
    lat: "50.52431559999999",
    lng: "-4.2135524",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGUM",
    portName: "Gunnislake",
    portContinent: "Europe",
  },
  {
    lat: "37.40252",
    lng: "-122.1334165",
    country: "US",
    country_long: "United States",
    portCode: "GBGUN",
    portName: "Gunn",
    portContinent: "Europe",
  },
  {
    lat: "55.9591984",
    lng: "-4.8168799",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGUR",
    portName: "Gourock (GBGUR), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.6979538",
    lng: "-2.9124057",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGUS",
    portName: "Gunness (GBGUS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.669449",
    lng: "-1.0072239",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGUT",
    portName: "Gutcher,Yell",
    portContinent: "Europe",
  },
  {
    lat: "53.58733849999999",
    lng: "-0.7303995999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGUW",
    portName: "GunnessWharf",
    portContinent: "Europe",
  },
  {
    lat: "51.9360176",
    lng: "-0.2104155",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGV2",
    portName: "Graveley",
    portContinent: "Europe",
  },
  {
    lat: "55.8615833",
    lng: "-4.3128871",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGVA",
    portName: "Govan",
    portContinent: "Europe",
  },
  {
    lat: "50.132605",
    lng: "-5.523923",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGVL",
    portName: "Gulval",
    portContinent: "Europe",
  },
  {
    lat: "50.30524699999999",
    lng: "-3.750376",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGVO",
    portName: "Goveton",
    portContinent: "Europe",
  },
  {
    lat: "40.5968007",
    lng: "-73.9749909",
    country: "US",
    country_long: "United States",
    portCode: "GBGVS",
    portName: "Gravesend",
    portContinent: "Europe",
  },
  {
    lat: "54.59308129999999",
    lng: "-6.214135199999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGVY",
    portName: "Glenavy",
    portContinent: "Europe",
  },
  {
    lat: "51.5523453",
    lng: "0.8109006",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGWG",
    portName: "GreatWakering",
    portContinent: "Europe",
  },
  {
    lat: "50.0967779",
    lng: "-5.208082099999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGWK",
    portName: "Gweek",
    portContinent: "Europe",
  },
  {
    lat: "54.580842",
    lng: "-1.144222",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGWN",
    portName: "Grangetown",
    portContinent: "Europe",
  },
  {
    lat: "51.82351",
    lng: "-3.097385",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGWR",
    portName: "Gilwern",
    portContinent: "Europe",
  },
  {
    lat: "52.65928",
    lng: "-2.010228",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGWY",
    portName: "GreatWyrley",
    portContinent: "Europe",
  },
  {
    lat: "53.6777539",
    lng: "-0.3309549",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGXH",
    portName: "Goxhill",
    portContinent: "Europe",
  },
  {
    lat: "52.015846",
    lng: "0.563322",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGYD",
    portName: "Great Yeldham Essex",
    portContinent: "Europe",
  },
  {
    lat: "56.4006214",
    lng: "-5.480748",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGYL",
    portName: "Argyll",
    portContinent: "Europe",
  },
  {
    lat: "51.74876",
    lng: "-3.617823",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGYN",
    portName: "GlynNeath",
    portContinent: "Europe",
  },
  {
    lat: "51.4784037",
    lng: "0.3230151",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGYS",
    portName: "Grays",
    portContinent: "Europe",
  },
  {
    lat: "51.111707",
    lng: "-0.7535609999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGYT",
    portName: "Grayshott",
    portContinent: "Europe",
  },
  {
    lat: "53.46147370000001",
    lng: "-2.5132896",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGZB",
    portName: "Glazebury",
    portContinent: "Europe",
  },
  {
    lat: "53.005306",
    lng: "-1.280636",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGZL",
    portName: "Giltbrook",
    portContinent: "Europe",
  },
  {
    lat: "54.069208",
    lng: "-1.999407",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGZT",
    portName: "Grassington",
    portContinent: "Europe",
  },
  {
    lat: "51.39478010000001",
    lng: "-0.9979935",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBGZY",
    portName: "Grazeley",
    portContinent: "Europe",
  },
  {
    lat: "51.5310622",
    lng: "-0.0866091",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBH7T",
    portName: "Hoxton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHA2",
    portName: "Ashley",
    portContinent: "Europe",
  },
  {
    lat: "54.8812286",
    lng: "-2.9101881",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAA",
    portName: "Harraby",
    portContinent: "Europe",
  },
  {
    lat: "56.07964579999999",
    lng: "-3.4147952",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAB",
    portName: "Halbeath",
    portContinent: "Europe",
  },
  {
    lat: "52.858894",
    lng: "-4.107858",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAC",
    portName: "Harlech",
    portContinent: "Europe",
  },
  {
    lat: "55.95867399999999",
    lng: "-2.774864",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAD",
    portName: "Haddington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHAE",
    portName: "Hale",
    portContinent: "Europe",
  },
  {
    lat: "52.05639799999999",
    lng: "-2.715974",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAF",
    portName: "Hereford",
    portContinent: "Europe",
  },
  {
    lat: "54.183601",
    lng: "-2.183842",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAG",
    portName: "HaltonGill",
    portContinent: "Europe",
  },
  {
    lat: "50.999041",
    lng: "-0.106333",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAH",
    portName: "Haywards Heath",
    portContinent: "Europe",
  },
  {
    lat: "50.864612",
    lng: "0.255234",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAI",
    portName: "Hailsham",
    portContinent: "Europe",
  },
  {
    lat: "53.813713",
    lng: "-2.207051",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAK",
    portName: "Harle Syke",
    portContinent: "Europe",
  },
  {
    lat: "42.7762015",
    lng: "-71.0772796",
    country: "US",
    country_long: "United States",
    portCode: "GBHAL",
    portName: "Haverhill",
    portContinent: "Europe",
  },
  {
    lat: "50.8527514",
    lng: "-1.3071209",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAM",
    portName: "Hamble Harbour",
    portContinent: "Europe",
  },
  {
    lat: "52.300713",
    lng: "-1.6462357",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAN",
    portName: "Hatton",
    portContinent: "Europe",
  },
  {
    lat: "51.4489479",
    lng: "-0.3829269",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAO",
    portName: "Hanworth",
    portContinent: "Europe",
  },
  {
    lat: "53.7785929",
    lng: "-2.3171867",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAP",
    portName: "Hapton",
    portContinent: "Europe",
  },
  {
    lat: "50.837635",
    lng: "-1.3936163",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAR",
    portName: "Hardley",
    portContinent: "Europe",
  },
  {
    lat: "52.402852",
    lng: "1.298286",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAS",
    portName: "Harleston",
    portContinent: "Europe",
  },
  {
    lat: "40.27982739999999",
    lng: "-75.2993417",
    country: "US",
    country_long: "United States",
    portCode: "GBHAT",
    portName: "Hatfield",
    portContinent: "Europe",
  },
  {
    lat: "55.168499",
    lng: "-1.862917",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAU",
    portName: "Hartburn",
    portContinent: "Europe",
  },
  {
    lat: "50.8518324",
    lng: "-0.9847131999999998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAV",
    portName: "Havant",
    portContinent: "Europe",
  },
  {
    lat: "51.66781539999999",
    lng: "-0.1776114",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAW",
    portName: "HadleyWood",
    portContinent: "Europe",
  },
  {
    lat: "52.148822",
    lng: "0.1036303",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAX",
    portName: "Hauxton",
    portContinent: "Europe",
  },
  {
    lat: "50.185467",
    lng: "-5.42091",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHAY",
    portName: "Hayle",
    portContinent: "Europe",
  },
  {
    lat: "41.7658043",
    lng: "-72.6733723",
    country: "US",
    country_long: "United States",
    portCode: "GBHAZ",
    portName: "Hartford",
    portContinent: "Europe",
  },
  {
    lat: "52.008332",
    lng: "-0.6050061",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBC",
    portName: "HusborneCrawley",
    portContinent: "Europe",
  },
  {
    lat: "54.975823",
    lng: "-2.249734",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBD",
    portName: "HaydonBridge",
    portContinent: "Europe",
  },
  {
    lat: "54.1180014",
    lng: "-2.5090175",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBE",
    portName: "High Bentham",
    portContinent: "Europe",
  },
  {
    lat: "53.74330399999999",
    lng: "-2.013021",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBG",
    portName: "HebdenBridge",
    portContinent: "Europe",
  },
  {
    lat: "27.9903597",
    lng: "-82.3017728",
    country: "US",
    country_long: "United States",
    portCode: "GBHBH",
    portName: "Hillsborough",
    portContinent: "Europe",
  },
  {
    lat: "54.08971",
    lng: "-2.8116979",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBK",
    portName: "Hest Bank",
    portContinent: "Europe",
  },
  {
    lat: "52.082766",
    lng: "0.440945",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBL",
    portName: "Haverhill, Suffolk",
    portContinent: "Europe",
  },
  {
    lat: "51.2767662",
    lng: "-1.45307",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBN",
    portName: "HurstburneTarrant",
    portContinent: "Europe",
  },
  {
    lat: "52.091054",
    lng: "-1.835207",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBO",
    portName: "Honeybourne",
    portContinent: "Europe",
  },
  {
    lat: "52.0402809",
    lng: "-2.6459731",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBP",
    portName: "HamptonBishop",
    portContinent: "Europe",
  },
  {
    lat: "50.9926676",
    lng: "-2.8757563",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBR",
    portName: "Hambridge",
    portContinent: "Europe",
  },
  {
    lat: "53.532174",
    lng: "-0.025742",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBS",
    portName: "Humberston",
    portContinent: "Europe",
  },
  {
    lat: "54.2958639",
    lng: "-1.313536",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBT",
    portName: "Hambleton",
    portContinent: "Europe",
  },
  {
    lat: "50.980056",
    lng: "-2.995982",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBU",
    portName: "HatchBeauchamp",
    portContinent: "Europe",
  },
  {
    lat: "52.5143881",
    lng: "-1.9392497",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBW",
    portName: "Handsworth",
    portContinent: "Europe",
  },
  {
    lat: "50.8287858",
    lng: "-1.3837093",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBX",
    portName: "Holbury",
    portContinent: "Europe",
  },
  {
    lat: "53.424653",
    lng: "-1.2432857",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHBY",
    portName: "Hellaby",
    portContinent: "Europe",
  },
  {
    lat: "53.507929",
    lng: "-0.062802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHCA",
    portName: "HoltonleClay",
    portContinent: "Europe",
  },
  {
    lat: "51.8523228",
    lng: "-2.1783901",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHCE",
    portName: "Hucclecote",
    portContinent: "Europe",
  },
  {
    lat: "50.7408861",
    lng: "-1.7024219",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHCF",
    portName: "Highcliffe",
    portContinent: "Europe",
  },
  {
    lat: "53.201306",
    lng: "-2.356242",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHCH",
    portName: "HolmeChapel",
    portContinent: "Europe",
  },
  {
    lat: "51.5436387",
    lng: "-0.0553621",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHCK",
    portName: "Hackney",
    portContinent: "Europe",
  },
  {
    lat: "51.94921",
    lng: "-0.283414",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHCN",
    portName: "Hitchin",
    portContinent: "Europe",
  },
  {
    lat: "53.207307",
    lng: "-0.11245",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHCT",
    portName: "Horncastle, Lincolnshire",
    portContinent: "Europe",
  },
  {
    lat: "30.026861",
    lng: "-95.8458562",
    country: "US",
    country_long: "United States",
    portCode: "GBHCY",
    portName: "Hockley",
    portContinent: "Europe",
  },
  {
    lat: "52.2195691",
    lng: "-1.2454765",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHD2",
    portName: "Hellidon",
    portContinent: "Europe",
  },
  {
    lat: "51.8089334",
    lng: "0.0557743",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHD3",
    portName: "Hunsdon",
    portContinent: "Europe",
  },
  {
    lat: "52.8790158",
    lng: "-1.6337417",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDB",
    portName: "Hilton/Derby",
    portContinent: "Europe",
  },
  {
    lat: "42.3490548",
    lng: "-72.1986558",
    country: "US",
    country_long: "United States",
    portCode: "GBHDC",
    portName: "Hardwick",
    portContinent: "Europe",
  },
  {
    lat: "53.73366309999999",
    lng: "-0.8509374",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDD",
    portName: "Howdendyke (GBHDD), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.648409",
    lng: "1.250905",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDE",
    portName: "Hellesdon",
    portContinent: "Europe",
  },
  {
    lat: "53.645792",
    lng: "-1.785035",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDF",
    portName: "Huddersfield",
    portContinent: "Europe",
  },
  {
    lat: "51.76264630000001",
    lng: "-1.1985742",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDG",
    portName: "Headington",
    portContinent: "Europe",
  },
  {
    lat: "51.77334",
    lng: "-0.9259649999999998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDH",
    portName: "Haddenham",
    portContinent: "Europe",
  },
  {
    lat: "52.0450779",
    lng: "0.952647",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDI",
    portName: "Hadleigh",
    portContinent: "Europe",
  },
  {
    lat: "53.46761619999999",
    lng: "-2.6849601",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDK",
    portName: "Haydock",
    portContinent: "Europe",
  },
  {
    lat: "52.0450779",
    lng: "0.952647",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDL",
    portName: "Hadleigh",
    portContinent: "Europe",
  },
  {
    lat: "53.7008264",
    lng: "-2.3261002",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDN",
    portName: "Haslingden",
    portContinent: "Europe",
  },
  {
    lat: "51.5860901",
    lng: "-0.2307061",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDO",
    portName: "Hendon",
    portContinent: "Europe",
  },
  {
    lat: "50.09766251441562",
    lng: "-5.15326516560014",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDR",
    portName: "HelfordRiver",
    portContinent: "Europe",
  },
  {
    lat: "51.806132",
    lng: "1.189651",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDS",
    portName: "HollandonSea",
    portContinent: "Europe",
  },
  {
    lat: "51.5237407",
    lng: "0.4058616",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDT",
    portName: "HorndonontheHill",
    portContinent: "Europe",
  },
  {
    lat: "53.986172",
    lng: "-2.2383989",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHDW",
    portName: "HaltonWest",
    portContinent: "Europe",
  },
  {
    lat: "42.3417565",
    lng: "-72.58842220000001",
    country: "US",
    country_long: "United States",
    portCode: "GBHDY",
    portName: "Hadley",
    portContinent: "Europe",
  },
  {
    lat: "43.43441809999999",
    lng: "-81.5038917",
    country: "CA",
    country_long: "Canada",
    portCode: "GBHE2",
    portName: "Hensall",
    portContinent: "Europe",
  },
  {
    lat: "52.9067246",
    lng: "0.4888306",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEA",
    portName: "Heacham",
    portContinent: "Europe",
  },
  {
    lat: "54.972283",
    lng: "-1.52199",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEB",
    portName: "Hebburn",
    portContinent: "Europe",
  },
  {
    lat: "52.7497911",
    lng: "-2.604053",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEC",
    portName: "High Ercall",
    portContinent: "Europe",
  },
  {
    lat: "51.16943000000001",
    lng: "0.619856",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHED",
    portName: "Headcorn",
    portContinent: "Europe",
  },
  {
    lat: "52.555074",
    lng: "1.191918",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEE",
    portName: "Hethel",
    portContinent: "Europe",
  },
  {
    lat: "36.3761772",
    lng: "-77.0564464",
    country: "US",
    country_long: "United States",
    portCode: "GBHEF",
    portName: "Hertford",
    portContinent: "Europe",
  },
  {
    lat: "53.52301500000001",
    lng: "-2.543523",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEG",
    portName: "HindleyGreen",
    portContinent: "Europe",
  },
  {
    lat: "53.61542799999999",
    lng: "-1.352693",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEH",
    portName: "Hemsworth",
    portContinent: "Europe",
  },
  {
    lat: "54.595873",
    lng: "-1.619374",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEI",
    portName: "Heighington",
    portContinent: "Europe",
  },
  {
    lat: "53.706976",
    lng: "-1.670679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEK",
    portName: "Heckmondwike",
    portContinent: "Europe",
  },
  {
    lat: "50.366191",
    lng: "-3.722739",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEL",
    portName: "Halwell",
    portContinent: "Europe",
  },
  {
    lat: "40.7062128",
    lng: "-73.6187397",
    country: "US",
    country_long: "United States",
    portCode: "GBHEM",
    portName: "Hempstead",
    portContinent: "Europe",
  },
  {
    lat: "51.535764",
    lng: "-0.902894",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEN",
    portName: "Henley-On-Thames",
    portContinent: "Europe",
  },
  {
    lat: "51.11620199999999",
    lng: "-0.800412",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEO",
    portName: "Headley Down",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHEQ",
    portName: "Heaton",
    portContinent: "Europe",
  },
  {
    lat: "50.934439",
    lng: "-0.179902",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHER",
    portName: "Hurstpierpoint",
    portContinent: "Europe",
  },
  {
    lat: "51.48420170000001",
    lng: "-0.3719867",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHES",
    portName: "Heston",
    portContinent: "Europe",
  },
  {
    lat: "-27.4481676",
    lng: "153.0247958",
    country: "AU",
    country_long: "Australia",
    portCode: "GBHET",
    portName: "Herston",
    portContinent: "Europe",
  },
  {
    lat: "50.8279319",
    lng: "-0.168749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEV",
    portName: "Hove",
    portContinent: "Europe",
  },
  {
    lat: "52.02984499999999",
    lng: "-0.285839",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEW",
    portName: "Henlow",
    portContinent: "Europe",
  },
  {
    lat: "54.972665",
    lng: "-2.1121439",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEX",
    portName: "Hexham, Northumberland",
    portContinent: "Europe",
  },
  {
    lat: "51.386491",
    lng: "-0.509456",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHEY",
    portName: "Chertsey",
    portContinent: "Europe",
  },
  {
    lat: "50.9667401",
    lng: "0.2564428",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHF5",
    portName: "Heathfield",
    portContinent: "Europe",
  },
  {
    lat: "51.85762",
    lng: "-4.312131",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHFB",
    portName: "Carmarthen, Carmarthenshire",
    portContinent: "Europe",
  },
  {
    lat: "52.150143",
    lng: "0.05503400000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHFD",
    portName: "Haslingfield",
    portContinent: "Europe",
  },
  {
    lat: "53.753799",
    lng: "-1.874804",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHFI",
    portName: "Holmfield",
    portContinent: "Europe",
  },
  {
    lat: "53.350181",
    lng: "-2.215772",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHFO",
    portName: "Handforth",
    portContinent: "Europe",
  },
  {
    lat: "51.4013671",
    lng: "-0.4228134",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHFR",
    portName: "Halliford",
    portContinent: "Europe",
  },
  {
    lat: "44.6475811",
    lng: "-63.5727683",
    country: "CA",
    country_long: "Canada",
    portCode: "GBHFX",
    portName: "Halifax",
    portContinent: "Europe",
  },
  {
    lat: "53.65806000000001",
    lng: "-1.333347",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGA",
    portName: "HighAckworth",
    portContinent: "Europe",
  },
  {
    lat: "52.7497911",
    lng: "-2.604053",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGC",
    portName: "HighErcall",
    portContinent: "Europe",
  },
  {
    lat: "51.7497786",
    lng: "0.1554757",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGD",
    portName: "Hastingwood",
    portContinent: "Europe",
  },
  {
    lat: "50.91364280000001",
    lng: "-1.3038762",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGE",
    portName: "Hedge End",
    portContinent: "Europe",
  },
  {
    lat: "52.307374",
    lng: "-0.5928409",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGF",
    portName: "HighamFerrers",
    portContinent: "Europe",
  },
  {
    lat: "51.5717035",
    lng: "-0.1501241",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGG",
    portName: "Highgate/London",
    portContinent: "Europe",
  },
  {
    lat: "51.413861",
    lng: "0.4571989999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGH",
    portName: "Higham",
    portContinent: "Europe",
  },
  {
    lat: "54.996483",
    lng: "-2.817147",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGI",
    portName: "Hethersgill",
    portContinent: "Europe",
  },
  {
    lat: "51.103651",
    lng: "0.7055279999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGL",
    portName: "HighHalden",
    portContinent: "Europe",
  },
  {
    lat: "42.2418172",
    lng: "-70.889759",
    country: "US",
    country_long: "United States",
    portCode: "GBHGM",
    portName: "Hingham",
    portContinent: "Europe",
  },
  {
    lat: "53.8365781",
    lng: "-2.4796924",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGN",
    portName: "HurstGreen",
    portContinent: "Europe",
  },
  {
    lat: "51.632861",
    lng: "-1.710398",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGO",
    portName: "Highworth",
    portContinent: "Europe",
  },
  {
    lat: "53.225707",
    lng: "0.298206",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGP",
    portName: "Hogsthorpe",
    portContinent: "Europe",
  },
  {
    lat: "51.9038619",
    lng: "-0.524733",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGR",
    portName: "HoughtonRegis",
    portContinent: "Europe",
  },
  {
    lat: "52.24055329999999",
    lng: "0.1857508",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGS",
    portName: "Horningsea",
    portContinent: "Europe",
  },
  {
    lat: "51.48729600000001",
    lng: "-0.434749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGT",
    portName: "Harlington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHGW",
    portName: "Hightown",
    portContinent: "Europe",
  },
  {
    lat: "52.990721",
    lng: "-0.6793389999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHGX",
    portName: "Hougham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHGY",
    portName: "HougharryandLochEport",
    portContinent: "Europe",
  },
  {
    lat: "52.492704",
    lng: "1.398584",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHH3",
    portName: "Hedenham",
    portContinent: "Europe",
  },
  {
    lat: "51.685034",
    lng: "0.002723",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHHA",
    portName: "Waltham Abbey",
    portContinent: "Europe",
  },
  {
    lat: "51.113618",
    lng: "-0.732073",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHHD",
    portName: "Hindhead",
    portContinent: "Europe",
  },
  {
    lat: "51.753241",
    lng: "-0.448632",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHHE",
    portName: "Hemel Hempstead",
    portContinent: "Europe",
  },
  {
    lat: "50.9667401",
    lng: "0.2564428",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHHF",
    portName: "Heathfield",
    portContinent: "Europe",
  },
  {
    lat: "53.353745",
    lng: "-2.450785",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHHG",
    portName: "HighLegh",
    portContinent: "Europe",
  },
  {
    lat: "55.0963978",
    lng: "-3.577691",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHHH",
    portName: "Heathhall",
    portContinent: "Europe",
  },
  {
    lat: "51.597638",
    lng: "0.2225735",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHHI",
    portName: "Harold Hill",
    portContinent: "Europe",
  },
  {
    lat: "51.5706321",
    lng: "-0.3396363",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHHL",
    portName: "HarrowontheHill",
    portContinent: "Europe",
  },
  {
    lat: "50.947914",
    lng: "-0.517219",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHHM",
    portName: "Hardham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHHN",
    portName: "Holehaven",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHHO",
    portName: "Hawthorn",
    portContinent: "Europe",
  },
  {
    lat: "53.3300114",
    lng: "-1.6563548",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHHS",
    portName: "Hathersage",
    portContinent: "Europe",
  },
  {
    lat: "50.954609",
    lng: "-1.295117",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHHT",
    portName: "HortonHeath",
    portContinent: "Europe",
  },
  {
    lat: "53.12897299999999",
    lng: "-1.3073809",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHHW",
    portName: "Huthwaite",
    portContinent: "Europe",
  },
  {
    lat: "52.292549",
    lng: "-1.779866",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHIA",
    portName: "Henley-in-Arden",
    portContinent: "Europe",
  },
  {
    lat: "54.3416724",
    lng: "-6.5421167",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHIB",
    portName: "HamiltonsBawn",
    portContinent: "Europe",
  },
  {
    lat: "55.8853988",
    lng: "-3.1979884",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHID",
    portName: "Hillend",
    portContinent: "Europe",
  },
  {
    lat: "41.88686980000001",
    lng: "-88.3036462",
    country: "US",
    country_long: "United States",
    portCode: "GBHIE",
    portName: "Herrington",
    portContinent: "Europe",
  },
  {
    lat: "40.836916",
    lng: "-73.9271294",
    country: "US",
    country_long: "United States",
    portCode: "GBHIG",
    portName: "Highbridge",
    portContinent: "Europe",
  },
  {
    lat: "53.598398",
    lng: "-2.554762",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHIH",
    portName: "Horwich",
    portContinent: "Europe",
  },
  {
    lat: "46.015",
    lng: "-92.9388889",
    country: "US",
    country_long: "United States",
    portCode: "GBHIL",
    portName: "Hinckley",
    portContinent: "Europe",
  },
  {
    lat: "52.3120489",
    lng: "-0.5767074999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHIM",
    portName: "Higham Ferres, Northamptonshire",
    portContinent: "Europe",
  },
  {
    lat: "50.799468",
    lng: "-3.188683",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHIN",
    portName: "Honiton",
    portContinent: "Europe",
  },
  {
    lat: "50.91023389999999",
    lng: "-2.8250995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHIO",
    portName: "HintonSaintGeorge",
    portContinent: "Europe",
  },
  {
    lat: "54.69479399999999",
    lng: "-1.247156",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHIR",
    portName: "HighThroston",
    portContinent: "Europe",
  },
  {
    lat: "52.2513031",
    lng: "0.1185319",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHIS",
    portName: "Histon",
    portContinent: "Europe",
  },
  {
    lat: "50.9654871",
    lng: "-1.4196066",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHIW",
    portName: "Chilworth",
    portContinent: "Europe",
  },
  {
    lat: "53.532996",
    lng: "-2.580635",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHIY",
    portName: "Hindley",
    portContinent: "Europe",
  },
  {
    lat: "53.745894",
    lng: "-0.868877",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHJE",
    portName: "Howden, East Riding Of Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "51.3751002",
    lng: "-0.1556286",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHKB",
    portName: "Hackbridge",
    portContinent: "Europe",
  },
  {
    lat: "51.1675398",
    lng: "-0.1871781",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHKD",
    portName: "Hookwood",
    portContinent: "Europe",
  },
  {
    lat: "52.983613",
    lng: "-0.299062",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHKG",
    portName: "Heckington",
    portContinent: "Europe",
  },
  {
    lat: "49.2834532",
    lng: "-123.1192387",
    country: "CA",
    country_long: "Canada",
    portCode: "GBHKH",
    portName: "Hawksworth",
    portContinent: "Europe",
  },
  {
    lat: "58.51417199999999",
    lng: "-3.491715",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHKI",
    portName: "Halkirk",
    portContinent: "Europe",
  },
  {
    lat: "53.034152",
    lng: "-1.20289",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHKL",
    portName: "Hucknall",
    portContinent: "Europe",
  },
  {
    lat: "54.335944",
    lng: "-1.627842",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHKO",
    portName: "Hackforth",
    portContinent: "Europe",
  },
  {
    lat: "53.3435986",
    lng: "-1.3729276",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHKP",
    portName: "Hackenthorpe",
    portContinent: "Europe",
  },
  {
    lat: "49.2834532",
    lng: "-123.1192387",
    country: "CA",
    country_long: "Canada",
    portCode: "GBHKR",
    portName: "Hawksworth",
    portContinent: "Europe",
  },
  {
    lat: "53.567411",
    lng: "-2.971962",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHKS",
    portName: "Haskayne",
    portContinent: "Europe",
  },
  {
    lat: "51.9946709",
    lng: "-1.481903",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHKT",
    portName: "HookNorton",
    portContinent: "Europe",
  },
  {
    lat: "52.464547",
    lng: "0.542152",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHKW",
    portName: "HockwoldcumWilton",
    portContinent: "Europe",
  },
  {
    lat: "30.026861",
    lng: "-95.8458562",
    country: "US",
    country_long: "United States",
    portCode: "GBHKY",
    portName: "Hockley",
    portContinent: "Europe",
  },
  {
    lat: "-37.900242",
    lng: "145.139394",
    country: "AU",
    country_long: "Australia",
    portCode: "GBHLA",
    portName: "Henley",
    portContinent: "Europe",
  },
  {
    lat: "27.9903597",
    lng: "-82.3017728",
    country: "US",
    country_long: "United States",
    portCode: "GBHLB",
    portName: "Hillsborough",
    portContinent: "Europe",
  },
  {
    lat: "53.201306",
    lng: "-2.356242",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLC",
    portName: "Holmes Chapel",
    portContinent: "Europe",
  },
  {
    lat: "51.5910397",
    lng: "0.2328613",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLD",
    portName: "Harold Wood",
    portContinent: "Europe",
  },
  {
    lat: "53.34948199999999",
    lng: "-2.776185",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLE",
    portName: "Hale Bank",
    portContinent: "Europe",
  },
  {
    lat: "55.59805799999999",
    lng: "-4.451739000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLF",
    portName: "Hurlford",
    portContinent: "Europe",
  },
  {
    lat: "51.352063",
    lng: "0.441702",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLG",
    portName: "Halling",
    portContinent: "Europe",
  },
  {
    lat: "54.827971",
    lng: "-1.454298",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLH",
    portName: "HettonleHole",
    portContinent: "Europe",
  },
  {
    lat: "34.0071907",
    lng: "-117.8795013",
    country: "US",
    country_long: "United States",
    portCode: "GBHLI",
    portName: "Hollingworth",
    portContinent: "Europe",
  },
  {
    lat: "51.5351832",
    lng: "-0.4481378",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLL",
    portName: "Hillingdon",
    portContinent: "Europe",
  },
  {
    lat: "52.0884459",
    lng: "-1.1446513",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLM",
    portName: "Helmdon",
    portContinent: "Europe",
  },
  {
    lat: "56.002318",
    lng: "-4.734014",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLN",
    portName: "Helensburgh",
    portContinent: "Europe",
  },
  {
    lat: "55.97873999999999",
    lng: "-4.946314999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLO",
    portName: "Sandbank (GBHLO), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.6351802",
    lng: "-0.3440619",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLP",
    portName: "Helpston, Cambridgeshire",
    portContinent: "Europe",
  },
  {
    lat: "43.53253720000001",
    lng: "-79.87448359999999",
    country: "CA",
    country_long: "Canada",
    portCode: "GBHLQ",
    portName: "Halton",
    portContinent: "Europe",
  },
  {
    lat: "53.571744",
    lng: "-1.786292",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLR",
    portName: "Holmfirth",
    portContinent: "Europe",
  },
  {
    lat: "54.841016",
    lng: "-1.468691",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLS",
    portName: "Houghton le Spring",
    portContinent: "Europe",
  },
  {
    lat: "52.90935899999999",
    lng: "1.086747",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLT",
    portName: "Holt",
    portContinent: "Europe",
  },
  {
    lat: "53.7800419",
    lng: "-1.532429",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLU",
    portName: "Hunslet/Leeds",
    portContinent: "Europe",
  },
  {
    lat: "53.7800419",
    lng: "-1.532429",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLUÂ",
    portName: "Hunslet",
    portContinent: "Europe",
  },
  {
    lat: "40.7295443",
    lng: "-73.909482",
    country: "US",
    country_long: "United States",
    portCode: "GBHLV",
    portName: "Hallen",
    portContinent: "Europe",
  },
  {
    lat: "53.370573",
    lng: "-2.8209749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLW",
    portName: "Halewood",
    portContinent: "Europe",
  },
  {
    lat: "53.309441",
    lng: "-4.633038",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHLY",
    portName: "Holyhead",
    portContinent: "Europe",
  },
  {
    lat: "58.11726099999999",
    lng: "-3.653533",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHMA",
    portName: "Helmsdale",
    portContinent: "Europe",
  },
  {
    lat: "51.5725454",
    lng: "-0.8697048",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHMB",
    portName: "Hambleden",
    portContinent: "Europe",
  },
  {
    lat: "53.189988",
    lng: "-1.354538",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHMD",
    portName: "Holmewood",
    portContinent: "Europe",
  },
  {
    lat: "52.4749409",
    lng: "-0.2511751",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHME",
    portName: "Holme",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHMI",
    portName: "Hayes",
    portContinent: "Europe",
  },
  {
    lat: "54.38367599999999",
    lng: "-3.420524",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHMK",
    portName: "Holmrook",
    portContinent: "Europe",
  },
  {
    lat: "54.2958639",
    lng: "-1.313536",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHML",
    portName: "Hambleton",
    portContinent: "Europe",
  },
  {
    lat: "51.491187",
    lng: "-0.223731",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHMM",
    portName: "Hammersmith",
    portContinent: "Europe",
  },
  {
    lat: "51.4766206",
    lng: "-1.9718785",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHMN",
    portName: "Hilmarton",
    portContinent: "Europe",
  },
  {
    lat: "50.7204928",
    lng: "-2.0101295",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHMO",
    portName: "Hamworthy",
    portContinent: "Europe",
  },
  {
    lat: "37.0298687",
    lng: "-76.34522179999999",
    country: "US",
    country_long: "United States",
    portCode: "GBHMP",
    portName: "Hampton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHMR",
    portName: "Humber",
    portContinent: "Europe",
  },
  {
    lat: "51.5556461",
    lng: "-0.1761749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHMS",
    portName: "Hampstead",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHMT",
    portName: "Hamilton",
    portContinent: "Europe",
  },
  {
    lat: "60.10586900000001",
    lng: "-1.334997",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHMV",
    portName: "Hamnavoe,BurraIsle",
    portContinent: "Europe",
  },
  {
    lat: "51.487453",
    lng: "-0.475554",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHMW",
    portName: "Harmondsworth",
    portContinent: "Europe",
  },
  {
    lat: "52.695889",
    lng: "1.684487",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHMY",
    portName: "Hemsby",
    portContinent: "Europe",
  },
  {
    lat: "52.794724",
    lng: "-1.260395",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHN2",
    portName: "Hathern",
    portContinent: "Europe",
  },
  {
    lat: "53.201155",
    lng: "-3.4632683",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNA",
    portName: "Henllan",
    portContinent: "Europe",
  },
  {
    lat: "51.216814",
    lng: "0.239516",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNB",
    portName: "Hildenborough",
    portContinent: "Europe",
  },
  {
    lat: "52.5143881",
    lng: "-1.9392497",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHND",
    portName: "Handsworth",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHNE",
    portName: "Horne",
    portContinent: "Europe",
  },
  {
    lat: "50.929798",
    lng: "-0.269507",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNF",
    portName: "Henfield",
    portContinent: "Europe",
  },
  {
    lat: "51.646519",
    lng: "-3.23131",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNG",
    portName: "Hengoed",
    portContinent: "Europe",
  },
  {
    lat: "51.164744",
    lng: "0.997556",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNH",
    portName: "Hastingleigh",
    portContinent: "Europe",
  },
  {
    lat: "53.0271687",
    lng: "-2.1775308",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNL",
    portName: "Hanley",
    portContinent: "Europe",
  },
  {
    lat: "51.5860901",
    lng: "-0.2307061",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNN",
    portName: "Hendon",
    portContinent: "Europe",
  },
  {
    lat: "51.714938",
    lng: "-0.896488",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNO",
    portName: "Henton",
    portContinent: "Europe",
  },
  {
    lat: "53.013807",
    lng: "-1.35377",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNR",
    portName: "Heanor",
    portContinent: "Europe",
  },
  {
    lat: "51.4828358",
    lng: "-0.3882062",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNS",
    portName: "Hamars Ness (GBHNS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.9389139",
    lng: "0.491032",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNT",
    portName: "Hunstanton",
    portContinent: "Europe",
  },
  {
    lat: "51.6083149",
    lng: "0.119312",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNU",
    portName: "Hainault",
    portContinent: "Europe",
  },
  {
    lat: "51.5066369",
    lng: "-0.3383229",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNW",
    portName: "Hanwell",
    portContinent: "Europe",
  },
  {
    lat: "52.0487963",
    lng: "-0.198262",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHNX",
    portName: "Hinxworth",
    portContinent: "Europe",
  },
  {
    lat: "42.0787089",
    lng: "-76.8449783",
    country: "US",
    country_long: "United States",
    portCode: "GBHNY",
    portName: "Hendy",
    portContinent: "Europe",
  },
  {
    lat: "50.509207",
    lng: "-4.097841",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOB",
    portName: "Horrabridge",
    portContinent: "Europe",
  },
  {
    lat: "51.562254",
    lng: "0.218605",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOC",
    portName: "Hornchurch, Essex",
    portContinent: "Europe",
  },
  {
    lat: "53.6432788",
    lng: "-2.8564957",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOD",
    portName: "Holmeswood",
    portContinent: "Europe",
  },
  {
    lat: "50.86948049999999",
    lng: "0.4044934",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOE",
    portName: "Hooe",
    portContinent: "Europe",
  },
  {
    lat: "32.9390323",
    lng: "-96.67049440000001",
    country: "US",
    country_long: "United States",
    portCode: "GBHOF",
    portName: "Holford",
    portContinent: "Europe",
  },
  {
    lat: "47.12113309999999",
    lng: "-88.5694182",
    country: "US",
    country_long: "United States",
    portCode: "GBHOG",
    portName: "Houghton",
    portContinent: "Europe",
  },
  {
    lat: "40.1784422",
    lng: "-75.1285061",
    country: "US",
    country_long: "United States",
    portCode: "GBHOH",
    portName: "Horsham",
    portContinent: "Europe",
  },
  {
    lat: "50.8782",
    lng: "0.545623",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOI",
    portName: "Hollington",
    portContinent: "Europe",
  },
  {
    lat: "54.99702869999999",
    lng: "-1.5022018",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOJ",
    portName: "Howdon",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHOK",
    portName: "Hook",
    portContinent: "Europe",
  },
  {
    lat: "52.803991",
    lng: "0.013434",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOL",
    portName: "Holbeach",
    portContinent: "Europe",
  },
  {
    lat: "53.6208877",
    lng: "9.6764394",
    country: "DE",
    country_long: "Germany",
    portCode: "GBHOM",
    portName: "Holm",
    portContinent: "Europe",
  },
  {
    lat: "51.76000699999999",
    lng: "-0.015042",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHON",
    portName: "Hoddesdon",
    portContinent: "Europe",
  },
  {
    lat: "25.097564",
    lng: "55.1565326",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "GBHOO",
    portName: "Hoo",
    portContinent: "Europe",
  },
  {
    lat: "57.70624999999999",
    lng: "-3.434834",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOP",
    portName: "Hopeman",
    portContinent: "Europe",
  },
  {
    lat: "50.915062",
    lng: "-1.0016844",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOR",
    portName: "Horndean",
    portContinent: "Europe",
  },
  {
    lat: "59.996995",
    lng: "-1.258223",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOS",
    portName: "Hoswick",
    portContinent: "Europe",
  },
  {
    lat: "45.01728110000001",
    lng: "-93.20685",
    country: "US",
    country_long: "United States",
    portCode: "GBHOT",
    portName: "Horton",
    portContinent: "Europe",
  },
  {
    lat: "51.46092179999999",
    lng: "-0.373149",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOU",
    portName: "Hounslow",
    portContinent: "Europe",
  },
  {
    lat: "60.33060879999999",
    lng: "-1.6949336",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOV",
    portName: "HousaVoe,PapaStour",
    portContinent: "Europe",
  },
  {
    lat: "52.90935899999999",
    lng: "1.086747",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOW",
    portName: "Holt",
    portContinent: "Europe",
  },
  {
    lat: "53.2999249",
    lng: "-2.9571327",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOX",
    portName: "Hooton",
    portContinent: "Europe",
  },
  {
    lat: "51.5872715",
    lng: "-0.1103806",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOY",
    portName: "Hornsey",
    portContinent: "Europe",
  },
  {
    lat: "53.20024489999999",
    lng: "-2.8649651",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHOZ",
    portName: "Hoole",
    portContinent: "Europe",
  },
  {
    lat: "51.073229",
    lng: "-0.8899969",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHP2",
    portName: "Greatham",
    portContinent: "Europe",
  },
  {
    lat: "50.2469893",
    lng: "-3.8560166",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHPC",
    portName: "HopeCove",
    portContinent: "Europe",
  },
  {
    lat: "51.81845999999999",
    lng: "-0.358953",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHPD",
    portName: "Harpenden",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHPE",
    portName: "Hope",
    portContinent: "Europe",
  },
  {
    lat: "53.1001621",
    lng: "-1.5335352",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHPJ",
    portName: "HighpeakJunction",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHPK",
    portName: "HighPeak",
    portContinent: "Europe",
  },
  {
    lat: "51.427313",
    lng: "-0.351431",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHPL",
    portName: "HamptonHill",
    portContinent: "Europe",
  },
  {
    lat: "54.019576",
    lng: "-1.155738",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHPN",
    portName: "Shipton",
    portContinent: "Europe",
  },
  {
    lat: "53.5836929",
    lng: "-1.2338849",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHPO",
    portName: "Hampole",
    portContinent: "Europe",
  },
  {
    lat: "52.8244176",
    lng: "1.5312848",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHPP",
    portName: "Happisburgh, Norfolk",
    portContinent: "Europe",
  },
  {
    lat: "53.72664200000001",
    lng: "-1.8069322",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHPR",
    portName: "Hipperholme",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHPS",
    portName: "Hampshire",
    portContinent: "Europe",
  },
  {
    lat: "56.0059099",
    lng: "-3.3591557",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHPT",
    portName: "Hound Point Terminal (GBHPT), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.21185",
    lng: "-1.625881",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHPV",
    portName: "HamptonLucy",
    portContinent: "Europe",
  },
  {
    lat: "51.9241098",
    lng: "-2.2935285",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHPY",
    portName: "Hartpury",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHQP",
    portName: "Hope",
    portContinent: "Europe",
  },
  {
    lat: "50.93360999999999",
    lng: "0.243262",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRA",
    portName: "Horam",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHRB",
    portName: "Hornby",
    portContinent: "Europe",
  },
  {
    lat: "51.562254",
    lng: "0.218605",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRC",
    portName: "Hornchurch",
    portContinent: "Europe",
  },
  {
    lat: "51.53710090000001",
    lng: "-0.2427341",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRD",
    portName: "Harlesden",
    portContinent: "Europe",
  },
  {
    lat: "55.8550931",
    lng: "-2.8648461",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRE",
    portName: "Humbie",
    portContinent: "Europe",
  },
  {
    lat: "51.60314",
    lng: "-0.484045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRF",
    portName: "Harefield",
    portContinent: "Europe",
  },
  {
    lat: "42.6224992",
    lng: "-97.2644985",
    country: "US",
    country_long: "United States",
    portCode: "GBHRG",
    portName: "Hartington",
    portContinent: "Europe",
  },
  {
    lat: "51.337015",
    lng: "0.4405579",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRH",
    portName: "Holborough",
    portContinent: "Europe",
  },
  {
    lat: "-45.8381714",
    lng: "169.2746821",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBHRI",
    portName: "Heriot",
    portContinent: "Europe",
  },
  {
    lat: "35.4709197",
    lng: "-93.4676878",
    country: "US",
    country_long: "United States",
    portCode: "GBHRK",
    portName: "Hardwicke",
    portContinent: "Europe",
  },
  {
    lat: "45.5119199",
    lng: "-122.6261743",
    country: "US",
    country_long: "United States",
    portCode: "GBHRL",
    portName: "Harlow",
    portContinent: "Europe",
  },
  {
    lat: "44.81007",
    lng: "-68.9133724",
    country: "US",
    country_long: "United States",
    portCode: "GBHRM",
    portName: "Hermon",
    portContinent: "Europe",
  },
  {
    lat: "51.370922",
    lng: "1.127771",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRN",
    portName: "Herne Bay",
    portContinent: "Europe",
  },
  {
    lat: "51.580559",
    lng: "-0.341995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRO",
    portName: "Harrow",
    portContinent: "Europe",
  },
  {
    lat: "51.81845999999999",
    lng: "-0.358953",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRP",
    portName: "Harpenden, Hertfordshire",
    portContinent: "Europe",
  },
  {
    lat: "45.82721129999999",
    lng: "-74.633839",
    country: "CA",
    country_long: "Canada",
    portCode: "GBHRR",
    portName: "Harrington",
    portContinent: "Europe",
  },
  {
    lat: "51.139154",
    lng: "0.4301213",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRS",
    portName: "Horsmonden",
    portContinent: "Europe",
  },
  {
    lat: "53.99212",
    lng: "-1.541812",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRT",
    portName: "Harrogate",
    portContinent: "Europe",
  },
  {
    lat: "32.8234621",
    lng: "-97.1705678",
    country: "US",
    country_long: "United States",
    portCode: "GBHRU",
    portName: "Hurst",
    portContinent: "Europe",
  },
  {
    lat: "51.94820603507224",
    lng: "1.303405964841194",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRW",
    portName: "Harwich Harbor",
    portContinent: "Europe",
  },
  {
    lat: "51.173516",
    lng: "-0.172109",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRY",
    portName: "Horley",
    portContinent: "Europe",
  },
  {
    lat: "53.9104",
    lng: "-0.173953",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHRZ",
    portName: "Hornsea",
    portContinent: "Europe",
  },
  {
    lat: "53.5857",
    lng: "-2.952327",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSA",
    portName: "Halsall",
    portContinent: "Europe",
  },
  {
    lat: "53.273014",
    lng: "-2.769426",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSB",
    portName: "Helsby",
    portContinent: "Europe",
  },
  {
    lat: "38.0014004",
    lng: "-97.5086518",
    country: "US",
    country_long: "United States",
    portCode: "GBHSD",
    portName: "Halstead",
    portContinent: "Europe",
  },
  {
    lat: "53.723805",
    lng: "-0.434858",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSE",
    portName: "Hessle",
    portContinent: "Europe",
  },
  {
    lat: "53.842177",
    lng: "-1.636099",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSF",
    portName: "Horsforth",
    portContinent: "Europe",
  },
  {
    lat: "53.100077",
    lng: "-2.395321",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSG",
    portName: "Haslington",
    portContinent: "Europe",
  },
  {
    lat: "51.36801699999999",
    lng: "-0.400788",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSH",
    portName: "Hersham",
    portContinent: "Europe",
  },
  {
    lat: "53.9474028",
    lng: "-1.0475209",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSI",
    portName: "Heslington",
    portContinent: "Europe",
  },
  {
    lat: "50.9246755",
    lng: "-0.1460982",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSK",
    portName: "Hassocks",
    portContinent: "Europe",
  },
  {
    lat: "51.090856",
    lng: "-0.7133729999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSL",
    portName: "Haslemere",
    portContinent: "Europe",
  },
  {
    lat: "53.8357396",
    lng: "-0.7774072",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSM",
    portName: "Holme Upon Spalding Moor",
    portContinent: "Europe",
  },
  {
    lat: "52.449845",
    lng: "-2.050526",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSN",
    portName: "Halesowen",
    portContinent: "Europe",
  },
  {
    lat: "53.68897130000001",
    lng: "-2.332052",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSO",
    portName: "Helmshore",
    portContinent: "Europe",
  },
  {
    lat: "53.248442",
    lng: "-1.665236",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSP",
    portName: "Hassop",
    portContinent: "Europe",
  },
  {
    lat: "52.213484",
    lng: "-1.822602",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSR",
    portName: "Haselor",
    portContinent: "Europe",
  },
  {
    lat: "55.7233313",
    lng: "-4.8983287",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHST",
    portName: "Hunterston (GBHST), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.343264",
    lng: "1.502642",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSW",
    portName: "Halesworth",
    portContinent: "Europe",
  },
  {
    lat: "50.889301",
    lng: "0.322373",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSX",
    portName: "Herstmonceux",
    portContinent: "Europe",
  },
  {
    lat: "52.309145",
    lng: "-1.664127",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHSY",
    portName: "Haseley",
    portContinent: "Europe",
  },
  {
    lat: "42.1680796",
    lng: "-88.4281415",
    country: "US",
    country_long: "United States",
    portCode: "GBHT8",
    portName: "Huntley",
    portContinent: "Europe",
  },
  {
    lat: "51.4307467",
    lng: "-0.1294977",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTA",
    portName: "Streatham",
    portContinent: "Europe",
  },
  {
    lat: "52.337686",
    lng: "-2.229982",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTB",
    portName: "Hartlebury",
    portContinent: "Europe",
  },
  {
    lat: "38.0014004",
    lng: "-97.5086518",
    country: "US",
    country_long: "United States",
    portCode: "GBHTD",
    portName: "Halstead",
    portContinent: "Europe",
  },
  {
    lat: "51.6104236",
    lng: "-0.370708",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTE",
    portName: "HatchEnd",
    portContinent: "Europe",
  },
  {
    lat: "32.3481521",
    lng: "-90.0487136",
    country: "US",
    country_long: "United States",
    portCode: "GBHTF",
    portName: "Hartfield",
    portContinent: "Europe",
  },
  {
    lat: "50.854259",
    lng: "0.573453",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTG",
    portName: "Hastings",
    portContinent: "Europe",
  },
  {
    lat: "51.071739",
    lng: "1.081937",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTH",
    portName: "Hythe",
    portContinent: "Europe",
  },
  {
    lat: "50.81917499999999",
    lng: "-4.072401",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTI",
    portName: "Hatherleigh",
    portContinent: "Europe",
  },
  {
    lat: "57.445936",
    lng: "-2.7878059",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTL",
    portName: "Huntly",
    portContinent: "Europe",
  },
  {
    lat: "52.797208",
    lng: "0.5525869999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTN",
    portName: "Hillington",
    portContinent: "Europe",
  },
  {
    lat: "52.300713",
    lng: "-1.6462357",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTO",
    portName: "Hatton",
    portContinent: "Europe",
  },
  {
    lat: "54.691745",
    lng: "-1.212926",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTP",
    portName: "Hartlepool",
    portContinent: "Europe",
  },
  {
    lat: "53.734717",
    lng: "-2.767678",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTQ",
    portName: "Hutton",
    portContinent: "Europe",
  },
  {
    lat: "53.020169",
    lng: "-2.473987",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTR",
    portName: "Hatherton",
    portContinent: "Europe",
  },
  {
    lat: "57.676859",
    lng: "-2.580352",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTS",
    portName: "Whitehills",
    portContinent: "Europe",
  },
  {
    lat: "54.0248297",
    lng: "-2.0606397",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTT",
    portName: "Hetton",
    portContinent: "Europe",
  },
  {
    lat: "34.3528825",
    lng: "-82.932087",
    country: "US",
    country_long: "United States",
    portCode: "GBHTW",
    portName: "Hartwell",
    portContinent: "Europe",
  },
  {
    lat: "52.344031",
    lng: "1.525848",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTX",
    portName: "Holton",
    portContinent: "Europe",
  },
  {
    lat: "51.1825471",
    lng: "-2.1046695",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHTY",
    portName: "Heytesbury",
    portContinent: "Europe",
  },
  {
    lat: "52.008332",
    lng: "-0.6050061",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHUB",
    portName: "Husborne, Bedfordshire",
    portContinent: "Europe",
  },
  {
    lat: "53.955363",
    lng: "-0.442819",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHUC",
    portName: "Hutton Cranswick, East Riding Of Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "40.48479589999999",
    lng: "-78.0102813",
    country: "US",
    country_long: "United States",
    portCode: "GBHUD",
    portName: "Huntingdon",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHUE",
    portName: "Hurley",
    portContinent: "Europe",
  },
  {
    lat: "51.41234499999999",
    lng: "-1.517995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHUF",
    portName: "Hungerford",
    portContinent: "Europe",
  },
  {
    lat: "47.12113309999999",
    lng: "-88.5694182",
    country: "US",
    country_long: "United States",
    portCode: "GBHUG",
    portName: "Houghton",
    portContinent: "Europe",
  },
  {
    lat: "53.055818",
    lng: "-0.9721449999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHUH",
    portName: "Halloughton",
    portContinent: "Europe",
  },
  {
    lat: "53.76762360000001",
    lng: "-0.3274198",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHUL",
    portName: "Hull",
    portContinent: "Europe",
  },
  {
    lat: "51.41234499999999",
    lng: "-1.517995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHUM",
    portName: "Hungerford, Berkshire",
    portContinent: "Europe",
  },
  {
    lat: "54.687329",
    lng: "-1.707013",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHUN",
    portName: "Hunwick",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHUO",
    portName: "Hunton",
    portContinent: "Europe",
  },
  {
    lat: "32.8234621",
    lng: "-97.1705678",
    country: "US",
    country_long: "United States",
    portCode: "GBHUR",
    portName: "Hurst",
    portContinent: "Europe",
  },
  {
    lat: "51.0260323",
    lng: "-1.3913619",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHUS",
    portName: "Hursley",
    portContinent: "Europe",
  },
  {
    lat: "55.867832",
    lng: "-4.551068",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHUT",
    portName: "Houton (GBHUT), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "40.9750104",
    lng: "-97.1725378",
    country: "US",
    country_long: "United States",
    portCode: "GBHUU",
    portName: "Staplehurst",
    portContinent: "Europe",
  },
  {
    lat: "54.687329",
    lng: "-1.707013",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHUW",
    portName: "Hunwick, Durham",
    portContinent: "Europe",
  },
  {
    lat: "53.734717",
    lng: "-2.767678",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHUX",
    portName: "Hutton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHUY",
    portName: "HumbersideApt",
    portContinent: "Europe",
  },
  {
    lat: "54.1726798",
    lng: "-0.9783045999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHV9",
    portName: "Hovingham",
    portContinent: "Europe",
  },
  {
    lat: "50.8279319",
    lng: "-0.168749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHVE",
    portName: "Hove, East Sussex",
    portContinent: "Europe",
  },
  {
    lat: "52.743896",
    lng: "1.253324",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHVG",
    portName: "Hevingham",
    portContinent: "Europe",
  },
  {
    lat: "53.0135878",
    lng: "-0.9591747",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHVM",
    portName: "Hoveringham",
    portContinent: "Europe",
  },
  {
    lat: "51.532886",
    lng: "-2.156164",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHVN",
    portName: "Hullavington",
    portContinent: "Europe",
  },
  {
    lat: "60.13357310000001",
    lng: "-2.0487172",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHVO",
    portName: "HamVoe,Foula",
    portContinent: "Europe",
  },
  {
    lat: "51.800475",
    lng: "-4.971318999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHVR",
    portName: "Haverfordwest",
    portContinent: "Europe",
  },
  {
    lat: "52.714772",
    lng: "1.4119679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHVT",
    portName: "Hoveton",
    portContinent: "Europe",
  },
  {
    lat: "50.7731888",
    lng: "-4.2310268",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHW9",
    portName: "Halwill",
    portContinent: "Europe",
  },
  {
    lat: "53.18569",
    lng: "-3.0298075",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWA",
    portName: "Hawarden, Flintshire",
    portContinent: "Europe",
  },
  {
    lat: "55.42706",
    lng: "-2.780914",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWC",
    portName: "Hawick",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHWD",
    portName: "How",
    portContinent: "Europe",
  },
  {
    lat: "54.3038282",
    lng: "-2.1968694",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWE",
    portName: "Hawes",
    portContinent: "Europe",
  },
  {
    lat: "51.8386915",
    lng: "0.4007525",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWG",
    portName: "HounslowGreen",
    portContinent: "Europe",
  },
  {
    lat: "51.45925099999999",
    lng: "-0.7419279999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWH",
    portName: "HawthornHill",
    portContinent: "Europe",
  },
  {
    lat: "51.305296",
    lng: "-0.9040600000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWI",
    portName: "HartleyWintney",
    portContinent: "Europe",
  },
  {
    lat: "51.04673500000001",
    lng: "0.50947",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWK",
    portName: "Hawkhurst",
    portContinent: "Europe",
  },
  {
    lat: "53.27600899999999",
    lng: "-3.225103",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWL",
    portName: "Holywell",
    portContinent: "Europe",
  },
  {
    lat: "42.9963772",
    lng: "-96.4891976",
    country: "US",
    country_long: "United States",
    portCode: "GBHWN",
    portName: "Hawarden",
    portContinent: "Europe",
  },
  {
    lat: "50.80921499999999",
    lng: "-4.353993",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWO",
    portName: "Holsworthy",
    portContinent: "Europe",
  },
  {
    lat: "53.41721399999999",
    lng: "-1.077409",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWR",
    portName: "Harworth",
    portContinent: "Europe",
  },
  {
    lat: "54.972237",
    lng: "-2.460856",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWS",
    portName: "Haltwhistle",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHWT",
    portName: "Haworth",
    portContinent: "Europe",
  },
  {
    lat: "51.737733",
    lng: "-3.510887",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWU",
    portName: "Hirwaun",
    portContinent: "Europe",
  },
  {
    lat: "55.810615",
    lng: "-4.554573",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWV",
    portName: "Howwood",
    portContinent: "Europe",
  },
  {
    lat: "51.5921904",
    lng: "0.6736734999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWW",
    portName: "Hawkwell",
    portContinent: "Europe",
  },
  {
    lat: "51.4489479",
    lng: "-0.3829269",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWX",
    portName: "Hanworth",
    portContinent: "Europe",
  },
  {
    lat: "51.628611",
    lng: "-0.7482289999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWY",
    portName: "High Wycombe",
    portContinent: "Europe",
  },
  {
    lat: "49.91477399999999",
    lng: "-6.311132",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHWZ",
    portName: "Hugh Town (GBHWZ), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.972665",
    lng: "-2.1121439",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHXH",
    portName: "Hexham",
    portContinent: "Europe",
  },
  {
    lat: "53.6777539",
    lng: "-0.3309549",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHXL",
    portName: "Goxhill",
    portContinent: "Europe",
  },
  {
    lat: "50.992934",
    lng: "-0.404202",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHXM",
    portName: "Coolham",
    portContinent: "Europe",
  },
  {
    lat: "53.416942",
    lng: "-122.582528",
    country: "CA",
    country_long: "Canada",
    portCode: "GBHXN",
    portName: "Hixon",
    portContinent: "Europe",
  },
  {
    lat: "52.797435",
    lng: "-1.149684",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHXO",
    portName: "Hoton",
    portContinent: "Europe",
  },
  {
    lat: "51.9605209",
    lng: "-0.3911461",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHXT",
    portName: "Hexton",
    portContinent: "Europe",
  },
  {
    lat: "53.7394596",
    lng: "-0.2006222",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHXX",
    portName: "Hedon",
    portContinent: "Europe",
  },
  {
    lat: "52.8708979",
    lng: "-0.895212",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHXY",
    portName: "Harby",
    portContinent: "Europe",
  },
  {
    lat: "52.2354928",
    lng: "-1.4568556",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHY2",
    portName: "Harbury",
    portContinent: "Europe",
  },
  {
    lat: "52.0838014",
    lng: "-2.2744677",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYA",
    portName: "HanleySwan",
    portContinent: "Europe",
  },
  {
    lat: "53.3284285",
    lng: "-3.0987304",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYB",
    portName: "Heswall",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHYD",
    portName: "Hyde",
    portContinent: "Europe",
  },
  {
    lat: "51.119916",
    lng: "-0.8261200000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYE",
    portName: "Headley",
    portContinent: "Europe",
  },
  {
    lat: "50.7947565",
    lng: "-0.9760186999999998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYG",
    portName: "HaylingIsland",
    portContinent: "Europe",
  },
  {
    lat: "50.87016570000001",
    lng: "-1.3998939",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYH",
    portName: "Hythe, Hampshire",
    portContinent: "Europe",
  },
  {
    lat: "55.6807718",
    lng: "-1.800859",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYI",
    portName: "HolyIsland",
    portContinent: "Europe",
  },
  {
    lat: "50.101593",
    lng: "-5.274996",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYJ",
    portName: "Helston",
    portContinent: "Europe",
  },
  {
    lat: "50.91135",
    lng: "-3.227796",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYK",
    portName: "Hemyock",
    portContinent: "Europe",
  },
  {
    lat: "53.393238",
    lng: "-3.1762801",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYL",
    portName: "Hoylake",
    portContinent: "Europe",
  },
  {
    lat: "54.04199",
    lng: "-2.894475",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYM",
    portName: "Heysham",
    portContinent: "Europe",
  },
  {
    lat: "53.4154747",
    lng: "-2.8257456",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYN",
    portName: "Huyton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHYO",
    portName: "Hoy",
    portContinent: "Europe",
  },
  {
    lat: "51.489956",
    lng: "-0.714087",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYP",
    portName: "Holyport",
    portContinent: "Europe",
  },
  {
    lat: "51.7416569",
    lng: "0.689677",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYR",
    portName: "Heybridge",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBHYS",
    portName: "Haynes",
    portContinent: "Europe",
  },
  {
    lat: "51.071739",
    lng: "1.081937",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYT",
    portName: "Hythe",
    portContinent: "Europe",
  },
  {
    lat: "53.592628",
    lng: "-2.22565",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYW",
    portName: "Heywood",
    portContinent: "Europe",
  },
  {
    lat: "53.2695651",
    lng: "-4.0964146",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHYY",
    portName: "Henllys",
    portContinent: "Europe",
  },
  {
    lat: "50.8277016",
    lng: "-1.0736491",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHZA",
    portName: "Hilsea",
    portContinent: "Europe",
  },
  {
    lat: "51.314939",
    lng: "1.165705",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHZD",
    portName: "Hersden",
    portContinent: "Europe",
  },
  {
    lat: "53.376299",
    lng: "-2.112004",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHZE",
    portName: "Hazel Grove, Greater Manchester",
    portContinent: "Europe",
  },
  {
    lat: "-33.6656432",
    lng: "149.8933866",
    country: "AU",
    country_long: "Australia",
    portCode: "GBHZG",
    portName: "HazelGrove",
    portContinent: "Europe",
  },
  {
    lat: "51.651046",
    lng: "-0.710842",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHZL",
    portName: "Hazlemere",
    portContinent: "Europe",
  },
  {
    lat: "-25.7777598",
    lng: "28.2577787",
    country: "ZA",
    country_long: "South Africa",
    portCode: "GBHZO",
    portName: "Hazelwood",
    portContinent: "Europe",
  },
  {
    lat: "51.312869",
    lng: "-2.520639",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBHZZ",
    portName: "Hallatrow",
    portContinent: "Europe",
  },
  {
    lat: "54.63341699999999",
    lng: "-1.654915",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIAA",
    portName: "Shildon, Durham",
    portContinent: "Europe",
  },
  {
    lat: "50.9827808",
    lng: "-2.9269698",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIAB",
    portName: "IsleAbbotts",
    portContinent: "Europe",
  },
  {
    lat: "51.5492828",
    lng: "-2.4607061",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIAO",
    portName: "IronActon",
    portContinent: "Europe",
  },
  {
    lat: "56.9808838",
    lng: "-7.456795899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIAR",
    portName: "IsleofBarra",
    portContinent: "Europe",
  },
  {
    lat: "57.675319",
    lng: "-1.931415",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIAY",
    portName: "Inverallochy",
    portContinent: "Europe",
  },
  {
    lat: "53.813959",
    lng: "-2.532928",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIBC",
    portName: "Ribchester",
    portContinent: "Europe",
  },
  {
    lat: "52.62838499999999",
    lng: "-2.481324",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIBG",
    portName: "Ironbridge",
    portContinent: "Europe",
  },
  {
    lat: "52.520886",
    lng: "0.669167",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIBH",
    portName: "Ickburgh",
    portContinent: "Europe",
  },
  {
    lat: "52.69464499999999",
    lng: "-1.401395",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIBS",
    portName: "Ibstock, Leicestershire",
    portContinent: "Europe",
  },
  {
    lat: "52.2145659",
    lng: "-1.9798934",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBICD",
    portName: "Inkberrow",
    portContinent: "Europe",
  },
  {
    lat: "56.445134",
    lng: "-3.166953",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBICH",
    portName: "Inchture",
    portContinent: "Europe",
  },
  {
    lat: "50.91744139999999",
    lng: "0.6716584",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBICK",
    portName: "Icklesham",
    portContinent: "Europe",
  },
  {
    lat: "39.2465918",
    lng: "-76.7738672",
    country: "US",
    country_long: "United States",
    portCode: "GBICT",
    portName: "Ilchester",
    portContinent: "Europe",
  },
  {
    lat: "52.096853",
    lng: "-1.5863179",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIDC",
    portName: "Idlicote",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBIDL",
    portName: "Idle",
    portContinent: "Europe",
  },
  {
    lat: "53.789918",
    lng: "-0.459778",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIDY",
    portName: "Skidby",
    portContinent: "Europe",
  },
  {
    lat: "51.556619",
    lng: "0.076251",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIFD",
    portName: "Ilford",
    portContinent: "Europe",
  },
  {
    lat: "52.66792",
    lng: "-2.3745599",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIFN",
    portName: "Shifnal",
    portContinent: "Europe",
  },
  {
    lat: "53.493004",
    lng: "-2.713315",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIGE",
    portName: "Billinge",
    portContinent: "Europe",
  },
  {
    lat: "56.8936761",
    lng: "-6.1533074",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIGG",
    portName: "Eigg",
    portContinent: "Europe",
  },
  {
    lat: "53.34999999999999",
    lng: "-1.216667",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIGH",
    portName: "Beighton",
    portContinent: "Europe",
  },
  {
    lat: "53.193572",
    lng: "0.334345",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIGM",
    portName: "Ingoldmells",
    portContinent: "Europe",
  },
  {
    lat: "51.364344",
    lng: "-2.7652",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIGN",
    portName: "Wrington",
    portContinent: "Europe",
  },
  {
    lat: "51.673794",
    lng: "0.3899059",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIGT",
    portName: "Ingatestone",
    portContinent: "Europe",
  },
  {
    lat: "42.6468943",
    lng: "-84.3542049",
    country: "US",
    country_long: "United States",
    portCode: "GBIHM",
    portName: "Ingham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBIHO",
    portName: "Hooke",
    portContinent: "Europe",
  },
  {
    lat: "54.49581",
    lng: "-1.2864344",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIHT",
    portName: "Hilton, North Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "52.32559999999999",
    lng: "-0.613059",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIIB",
    portName: "Irthlingborough",
    portContinent: "Europe",
  },
  {
    lat: "51.205163",
    lng: "-4.126761999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIIF",
    portName: "Ilfracombe, Devon",
    portContinent: "Europe",
  },
  {
    lat: "53.925486",
    lng: "-1.822817",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIIK",
    portName: "Ilkley, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBIKL",
    portName: "Essendon",
    portContinent: "Europe",
  },
  {
    lat: "53.706976",
    lng: "-1.670679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIKO",
    portName: "Heckmondwike, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "52.96792490000001",
    lng: "-1.3072669",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIKS",
    portName: "Ilkeston",
    portContinent: "Europe",
  },
  {
    lat: "51.480818",
    lng: "0.174675",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIKT",
    portName: "Erith, Greater London",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBILE",
    portName: "Shirley",
    portContinent: "Europe",
  },
  {
    lat: "51.205163",
    lng: "-4.126761999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBILF",
    portName: "Ilfracombe (GBILF), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.5876369",
    lng: "-0.9568101",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBILH",
    portName: "IllstonontheHill",
    portContinent: "Europe",
  },
  {
    lat: "40.7298207",
    lng: "-73.2103934",
    country: "US",
    country_long: "United States",
    portCode: "GBILI",
    portName: "Islip",
    portContinent: "Europe",
  },
  {
    lat: "53.925486",
    lng: "-1.822817",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBILK",
    portName: "Ilkley",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBILL",
    portName: "Gills",
    portContinent: "Europe",
  },
  {
    lat: "50.928494",
    lng: "-2.911062",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBILM",
    portName: "Illminster, Somerset",
    portContinent: "Europe",
  },
  {
    lat: "50.9539977",
    lng: "-2.9236795",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBILN",
    portName: "Ilton",
    portContinent: "Europe",
  },
  {
    lat: "50.9539977",
    lng: "-2.9236795",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBILO",
    portName: "Ilton",
    portContinent: "Europe",
  },
  {
    lat: "40.7298207",
    lng: "-73.2103934",
    country: "US",
    country_long: "United States",
    portCode: "GBILP",
    portName: "Islip",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBILY",
    portName: "IslayApt",
    portContinent: "Europe",
  },
  {
    lat: "54.82047",
    lng: "-5.720949999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIMG",
    portName: "Islandmagee",
    portContinent: "Europe",
  },
  {
    lat: "53.536413",
    lng: "-2.619493",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIMK",
    portName: "Ince-in-Makerfield",
    portContinent: "Europe",
  },
  {
    lat: "53.614012",
    lng: "-0.215913",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIMM",
    portName: "Immingham",
    portContinent: "Europe",
  },
  {
    lat: "52.2513031",
    lng: "0.1185319",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIMP",
    portName: "Impington",
    portContinent: "Europe",
  },
  {
    lat: "50.928494",
    lng: "-2.911062",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIMR",
    portName: "Ilminster",
    portContinent: "Europe",
  },
  {
    lat: "53.3397288",
    lng: "-0.5775104999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBINA",
    portName: "Ingham, Lincolnshire",
    portContinent: "Europe",
  },
  {
    lat: "52.2145659",
    lng: "-1.9798934",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBINB",
    portName: "Inkberrow, Worcestershire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBINC",
    portName: "Ince",
    portContinent: "Europe",
  },
  {
    lat: "56.1361835",
    lng: "-5.025663799999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIND",
    portName: "Invernoaden",
    portContinent: "Europe",
  },
  {
    lat: "52.402446",
    lng: "-1.446541",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBINE",
    portName: "Binley",
    portContinent: "Europe",
  },
  {
    lat: "42.6468943",
    lng: "-84.3542049",
    country: "US",
    country_long: "United States",
    portCode: "GBING",
    portName: "Ingham",
    portContinent: "Europe",
  },
  {
    lat: "55.893245",
    lng: "-4.440016",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBINH",
    portName: "Inchinnan",
    portContinent: "Europe",
  },
  {
    lat: "56.030043",
    lng: "-3.398795",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBINK",
    portName: "Inverkeithing",
    portContinent: "Europe",
  },
  {
    lat: "54.152513",
    lng: "-2.470249",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBINL",
    portName: "Ingleton",
    portContinent: "Europe",
  },
  {
    lat: "55.52528599999999",
    lng: "-1.7344531",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBINM",
    portName: "Ellingham",
    portContinent: "Europe",
  },
  {
    lat: "51.2957453",
    lng: "-1.2272351",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBINN",
    portName: "Hannington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBINO",
    portName: "Swinton",
    portContinent: "Europe",
  },
  {
    lat: "57.036675",
    lng: "-5.681573",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBINR",
    portName: "Inverie",
    portContinent: "Europe",
  },
  {
    lat: "51.0534821",
    lng: "-4.1768451",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBINS",
    portName: "Instow",
    portContinent: "Europe",
  },
  {
    lat: "54.1479368",
    lng: "-0.6512975999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBINT",
    portName: "Wintringham",
    portContinent: "Europe",
  },
  {
    lat: "57.477773",
    lng: "-4.224721",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBINV",
    portName: "Inverness",
    portContinent: "Europe",
  },
  {
    lat: "57.070951",
    lng: "-4.800261",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBINY",
    portName: "Invergarry",
    portContinent: "Europe",
  },
  {
    lat: "50.9348353",
    lng: "0.0650862",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIOD",
    portName: "Isfield",
    portContinent: "Europe",
  },
  {
    lat: "51.4554777",
    lng: "0.7153189",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIOG",
    portName: "ISLE OF GRAIN  (GBIOG), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.330733",
    lng: "-6.394864999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBION",
    portName: "BaileMor,Iona",
    portContinent: "Europe",
  },
  {
    lat: "50.5475363",
    lng: "-2.4343209",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIOP",
    portName: "IsleofPortland",
    portContinent: "Europe",
  },
  {
    lat: "51.3964896",
    lng: "0.8365233",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIOS",
    portName: "IsleofSheppey",
    portContinent: "Europe",
  },
  {
    lat: "53.045403",
    lng: "-1.97147",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIPN",
    portName: "Ipstones",
    portContinent: "Europe",
  },
  {
    lat: "42.6791832",
    lng: "-70.8411558",
    country: "US",
    country_long: "United States",
    portCode: "GBIPS",
    portName: "Ipswich",
    portContinent: "Europe",
  },
  {
    lat: "50.793857",
    lng: "-0.825626",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIRA",
    portName: "Birdham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBIRB",
    portName: "Irby",
    portContinent: "Europe",
  },
  {
    lat: "52.278228",
    lng: "-0.655462",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIRC",
    portName: "Irchester, Northamptonshire",
    portContinent: "Europe",
  },
  {
    lat: "52.5033",
    lng: "-1.2226649",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIRE",
    portName: "Leire",
    portContinent: "Europe",
  },
  {
    lat: "56.250273",
    lng: "-4.712378",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIRG",
    portName: "Inveruglas",
    portContinent: "Europe",
  },
  {
    lat: "53.444266",
    lng: "-2.42142",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIRL",
    portName: "Irlam",
    portContinent: "Europe",
  },
  {
    lat: "53.656213",
    lng: "-0.601631",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIRO",
    portName: "Winterton",
    portContinent: "Europe",
  },
  {
    lat: "52.278228",
    lng: "-0.655462",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIRT",
    portName: "Irchester",
    portContinent: "Europe",
  },
  {
    lat: "33.6845673",
    lng: "-117.8265049",
    country: "US",
    country_long: "United States",
    portCode: "GBIRV",
    portName: "Irvine",
    portContinent: "Europe",
  },
  {
    lat: "51.38964600000001",
    lng: "1.3868339",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIRY",
    portName: "Margate, Kent",
    portContinent: "Europe",
  },
  {
    lat: "50.59382000000001",
    lng: "-4.829978",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBISA",
    portName: "Port Isaac (GBISA), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBISC",
    portName: "IslesofScilly",
    portContinent: "Europe",
  },
  {
    lat: "52.946095",
    lng: "-0.162996",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBISD",
    portName: "Swineshead",
    portContinent: "Europe",
  },
  {
    lat: "57.34140499999999",
    lng: "-2.611882",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBISH",
    portName: "Insch",
    portContinent: "Europe",
  },
  {
    lat: "57.2736278",
    lng: "-6.2155022",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBISK",
    portName: "IsleofSkye",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBISL",
    portName: "Bisley",
    portContinent: "Europe",
  },
  {
    lat: "53.082096",
    lng: "-2.474755",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBISN",
    portName: "Wistaston",
    portContinent: "Europe",
  },
  {
    lat: "52.0098065",
    lng: "-0.4250984",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBISO",
    portName: "Silsoe",
    portContinent: "Europe",
  },
  {
    lat: "51.0427301",
    lng: "-0.8917763999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBISS",
    portName: "Liss",
    portContinent: "Europe",
  },
  {
    lat: "51.538621",
    lng: "-0.1028346",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIST",
    portName: "Islington",
    portContinent: "Europe",
  },
  {
    lat: "28.484454",
    lng: "-81.52265539999999",
    country: "US",
    country_long: "United States",
    portCode: "GBISW",
    portName: "Isleworth",
    portContinent: "Europe",
  },
  {
    lat: "55.7362535",
    lng: "-6.177067",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBISY",
    portName: "Islay",
    portContinent: "Europe",
  },
  {
    lat: "50.72848519999999",
    lng: "-1.1888479",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBITA",
    portName: "Binstead",
    portContinent: "Europe",
  },
  {
    lat: "50.806531",
    lng: "-0.866487",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBITC",
    portName: "Itchenor",
    portContinent: "Europe",
  },
  {
    lat: "51.4368102",
    lng: "-1.0709569",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBITG",
    portName: "Theale, Berkshire",
    portContinent: "Europe",
  },
  {
    lat: "55.624015",
    lng: "-3.064912",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBITH",
    portName: "Innerleithen",
    portContinent: "Europe",
  },
  {
    lat: "50.9087081",
    lng: "-1.3583035",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBITN",
    portName: "Itchen",
    portContinent: "Europe",
  },
  {
    lat: "51.4293102",
    lng: "-0.9629688000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBITY",
    portName: "Whitley",
    portContinent: "Europe",
  },
  {
    lat: "51.46131099999999",
    lng: "-0.303742",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIUT",
    portName: "Richmond, Greater London",
    portContinent: "Europe",
  },
  {
    lat: "56.84495",
    lng: "-2.279823",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIVB",
    portName: "Inverbervie",
    portContinent: "Europe",
  },
  {
    lat: "51.5201939",
    lng: "-0.509572",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIVE",
    portName: "Iver",
    portContinent: "Europe",
  },
  {
    lat: "57.688106",
    lng: "-4.172882",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIVG",
    portName: "Invergordon",
    portContinent: "Europe",
  },
  {
    lat: "51.5201939",
    lng: "-0.509572",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIVH",
    portName: "IverHeath",
    portContinent: "Europe",
  },
  {
    lat: "57.28347400000001",
    lng: "-2.373995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIVI",
    portName: "Inverurie",
    portContinent: "Europe",
  },
  {
    lat: "55.907032",
    lng: "-4.870339",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIVK",
    portName: "Inverkip",
    portContinent: "Europe",
  },
  {
    lat: "53.0601674",
    lng: "-1.3498518",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIVL",
    portName: "Ironville",
    portContinent: "Europe",
  },
  {
    lat: "56.2317854",
    lng: "-5.075410199999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIVR",
    portName: "Inveraray",
    portContinent: "Europe",
  },
  {
    lat: "50.20838579999999",
    lng: "-5.490886400000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIVS",
    portName: "Saint Ives",
    portContinent: "Europe",
  },
  {
    lat: "54.4714885",
    lng: "-7.6336596",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIVT",
    portName: "Irvinestown",
    portContinent: "Europe",
  },
  {
    lat: "57.217549",
    lng: "-4.620841",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIVV",
    portName: "Invermoriston",
    portContinent: "Europe",
  },
  {
    lat: "50.390202",
    lng: "-3.920431",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIVY",
    portName: "Ivybridge",
    portContinent: "Europe",
  },
  {
    lat: "52.59625699999999",
    lng: "-2.08341",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIWF",
    portName: "Wednesfield, West Midlands",
    portContinent: "Europe",
  },
  {
    lat: "51.810437",
    lng: "-0.028177",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIWH",
    portName: "Ware, Hertfordshire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBIWN",
    portName: "Winsor",
    portContinent: "Europe",
  },
  {
    lat: "52.29966169999999",
    lng: "0.8337756",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBIXW",
    portName: "Ixworth",
    portContinent: "Europe",
  },
  {
    lat: "54.980297",
    lng: "-1.482757",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJAR",
    portName: "Jarrow",
    portContinent: "Europe",
  },
  {
    lat: "55.477721",
    lng: "-2.5549369",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJBG",
    portName: "Jedburgh",
    portContinent: "Europe",
  },
  {
    lat: "52.9236509",
    lng: "-1.9109031",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJBH",
    portName: "Beamhurst",
    portContinent: "Europe",
  },
  {
    lat: "53.04573019999999",
    lng: "-2.1967011",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJBL",
    portName: "Burslem, Staffordshire",
    portContinent: "Europe",
  },
  {
    lat: "36.6753258",
    lng: "-90.242076",
    country: "US",
    country_long: "United States",
    portCode: "GBJBY",
    portName: "Broseley, Cornwall",
    portContinent: "Europe",
  },
  {
    lat: "52.471815",
    lng: "-2.082075",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJCE",
    portName: "Cradley Heath, West Midlands",
    portContinent: "Europe",
  },
  {
    lat: "51.386491",
    lng: "-0.509456",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJCT",
    portName: "Chertsey, Surrey",
    portContinent: "Europe",
  },
  {
    lat: "50.99899300000001",
    lng: "-0.470492",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJDV",
    portName: "Adversane",
    portContinent: "Europe",
  },
  {
    lat: "55.905355",
    lng: "-3.286457",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJGR",
    portName: "JuniperGreen",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBJHT",
    portName: "Johnstone",
    portContinent: "Europe",
  },
  {
    lat: "56.795146",
    lng: "-2.3359589",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJHV",
    portName: "Johnshaven",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBJHW",
    portName: "CrownDry-Dock",
    portContinent: "Europe",
  },
  {
    lat: "51.239256",
    lng: "0.7206549999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJLM",
    portName: "Lenham, Kent",
    portContinent: "Europe",
  },
  {
    lat: "37.2116383",
    lng: "-76.7752102",
    country: "US",
    country_long: "United States",
    portCode: "GBJMS",
    portName: "Jamestown",
    portContinent: "Europe",
  },
  {
    lat: "51.402869",
    lng: "-0.166709",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJMZ",
    portName: "Mitcham, Greater London",
    portContinent: "Europe",
  },
  {
    lat: "58.6373368",
    lng: "-3.0688997",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJOG",
    portName: "JohnO'Groats",
    portContinent: "Europe",
  },
  {
    lat: "51.069358",
    lng: "0.689122",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJRD",
    portName: "Tenterden, Kent",
    portContinent: "Europe",
  },
  {
    lat: "50.8993938",
    lng: "-0.2762585",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJSD",
    portName: "Small Dole, West Sussex",
    portContinent: "Europe",
  },
  {
    lat: "51.4191935",
    lng: "-0.4191503",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJSH",
    portName: "Sunbury-On-Thames",
    portContinent: "Europe",
  },
  {
    lat: "55.2121571",
    lng: "-3.4209491",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJTB",
    portName: "Johnstonebridge",
    portContinent: "Europe",
  },
  {
    lat: "53.873712",
    lng: "-3.0213709",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJTC",
    portName: "Thornton-Cleveleys",
    portContinent: "Europe",
  },
  {
    lat: "53.2062839",
    lng: "-0.282001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJTM",
    portName: "Tupholme",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBJUR",
    portName: "Jura",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBJWD",
    portName: "Wardle",
    portContinent: "Europe",
  },
  {
    lat: "50.8004646",
    lng: "-1.9830004",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBJWU",
    portName: "Wimborne Minster, Dorset",
    portContinent: "Europe",
  },
  {
    lat: "56.299032",
    lng: "-2.6607141",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBK2N",
    portName: "Kingsbarns",
    portContinent: "Europe",
  },
  {
    lat: "53.656688",
    lng: "-1.7297697",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBK3T",
    portName: "Kirkheaton",
    portContinent: "Europe",
  },
  {
    lat: "53.656688",
    lng: "-1.7297697",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBK4N",
    portName: "Kirkheaton",
    portContinent: "Europe",
  },
  {
    lat: "53.144535",
    lng: "-0.147476",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBK7K",
    portName: "KirkbyonBain",
    portContinent: "Europe",
  },
  {
    lat: "51.0609928",
    lng: "-3.1145714",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBK8S",
    portName: "KingstonSaintMary",
    portContinent: "Europe",
  },
  {
    lat: "57.48333330000001",
    lng: "-7.2333333",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKAL",
    portName: "Kallin(Ceallan),IsleofGrimsay",
    portContinent: "Europe",
  },
  {
    lat: "56.1168249",
    lng: "-3.158137",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKAR",
    portName: "Karcaldy",
    portContinent: "Europe",
  },
  {
    lat: "53.100963",
    lng: "-1.269612",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKBA",
    portName: "Kirkby in Ashfield",
    portContinent: "Europe",
  },
  {
    lat: "58.458818",
    lng: "-5.049151999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKBE",
    portName: "Kinlochbervie",
    portContinent: "Europe",
  },
  {
    lat: "51.4991195",
    lng: "-0.1644394",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKBG",
    portName: "Knightsbridge",
    portContinent: "Europe",
  },
  {
    lat: "53.734299",
    lng: "-0.1410848",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKBK",
    portName: "Burstwick",
    portContinent: "Europe",
  },
  {
    lat: "54.2036229",
    lng: "-0.8059468999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKBM",
    portName: "KirkbyMisperton",
    portContinent: "Europe",
  },
  {
    lat: "51.539813",
    lng: "-0.1985291",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKBN",
    portName: "Kilburn",
    portContinent: "Europe",
  },
  {
    lat: "52.296978",
    lng: "-0.386705",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKBO",
    portName: "Kimbolton",
    portContinent: "Europe",
  },
  {
    lat: "51.682046",
    lng: "-1.4126783",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKBP",
    portName: "KingstonBagpuize",
    portContinent: "Europe",
  },
  {
    lat: "53.900579",
    lng: "-2.152071",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKBR",
    portName: "Kelbrook",
    portContinent: "Europe",
  },
  {
    lat: "54.83756899999999",
    lng: "-4.04878",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKBT",
    portName: "Kirkcudbright Harbor",
    portContinent: "Europe",
  },
  {
    lat: "53.608535",
    lng: "-1.701087",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKBU",
    portName: "Kirkburton",
    portContinent: "Europe",
  },
  {
    lat: "51.8655339",
    lng: "-0.182981",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKBW",
    portName: "Knebworth",
    portContinent: "Europe",
  },
  {
    lat: "53.310802",
    lng: "-3.519355",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKBY",
    portName: "Kinmel",
    portContinent: "Europe",
  },
  {
    lat: "55.803296",
    lng: "-5.4736237",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKCG",
    portName: "Kennacraig",
    portContinent: "Europe",
  },
  {
    lat: "55.75532459999999",
    lng: "-6.453362599999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKCH",
    portName: "Kilchiaran",
    portContinent: "Europe",
  },
  {
    lat: "53.7900638",
    lng: "-8.921465999999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBKCK",
    portName: "Knock",
    portContinent: "Europe",
  },
  {
    lat: "54.4874205",
    lng: "-5.533044299999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKCN",
    portName: "Kircubbin",
    portContinent: "Europe",
  },
  {
    lat: "52.2288509",
    lng: "-1.8802929",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKCO",
    portName: "KingsCoughton",
    portContinent: "Europe",
  },
  {
    lat: "44.1752954",
    lng: "-81.6365715",
    country: "CA",
    country_long: "Canada",
    portCode: "GBKDE",
    portName: "Kincardine",
    portContinent: "Europe",
  },
  {
    lat: "55.0436072",
    lng: "-3.1947294",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKDG",
    portName: "Kirtlebridge",
    portContinent: "Europe",
  },
  {
    lat: "52.388596",
    lng: "-2.2496839",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKDM",
    portName: "Kidderminster",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBKDN",
    portName: "Kingsdown",
    portContinent: "Europe",
  },
  {
    lat: "51.0916623",
    lng: "0.4267922",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKDW",
    portName: "Kilndown",
    portContinent: "Europe",
  },
  {
    lat: "54.2485059",
    lng: "-6.705043099999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKDY",
    portName: "Keady",
    portContinent: "Europe",
  },
  {
    lat: "53.59669299999999",
    lng: "-0.73951",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKEA",
    portName: "Keadby",
    portContinent: "Europe",
  },
  {
    lat: "54.601276",
    lng: "-3.134706",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKEC",
    portName: "Keswick, Cumbria",
    portContinent: "Europe",
  },
  {
    lat: "54.32800599999999",
    lng: "-2.74629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKED",
    portName: "Kendal, Cumbria",
    portContinent: "Europe",
  },
  {
    lat: "52.8360143",
    lng: "-1.2787993",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKEG",
    portName: "Kegworth",
    portContinent: "Europe",
  },
  {
    lat: "52.087739",
    lng: "-0.4949951",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKEH",
    portName: "Kempston Hardwick",
    portContinent: "Europe",
  },
  {
    lat: "53.867795",
    lng: "-1.912358",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKEI",
    portName: "Keighley",
    portContinent: "Europe",
  },
  {
    lat: "38.0134293",
    lng: "-78.3586224",
    country: "US",
    country_long: "United States",
    portCode: "GBKEK",
    portName: "Keswick",
    portContinent: "Europe",
  },
  {
    lat: "47.6103865",
    lng: "-122.3422223",
    country: "US",
    country_long: "United States",
    portCode: "GBKEL",
    portName: "Kells",
    portContinent: "Europe",
  },
  {
    lat: "51.67479489999999",
    lng: "-2.0191706",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKEM",
    portName: "Kemble",
    portContinent: "Europe",
  },
  {
    lat: "51.5010095",
    lng: "-0.1932794",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKEN",
    portName: "Kensington/London",
    portContinent: "Europe",
  },
  {
    lat: "58.53440699999999",
    lng: "-3.123227",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKES",
    portName: "Keiss",
    portContinent: "Europe",
  },
  {
    lat: "59.23143499999999",
    lng: "-2.6011099",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKET",
    portName: "Kettletoft, Sanday",
    portContinent: "Europe",
  },
  {
    lat: "50.722688",
    lng: "-1.5700858",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKEV",
    portName: "Keyhaven",
    portContinent: "Europe",
  },
  {
    lat: "51.475251",
    lng: "-0.2848908",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKEW",
    portName: "Kew",
    portContinent: "Europe",
  },
  {
    lat: "50.922497",
    lng: "-0.1332444",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKEY",
    portName: "Keymer",
    portContinent: "Europe",
  },
  {
    lat: "52.188902",
    lng: "0.9977119999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKFA",
    portName: "Stowmarket, Suffolk",
    portContinent: "Europe",
  },
  {
    lat: "52.06256699999999",
    lng: "1.233907",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKGA",
    portName: "Kesgrave",
    portContinent: "Europe",
  },
  {
    lat: "51.5853499",
    lng: "-0.2782546",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKGB",
    portName: "Kingsbury",
    portContinent: "Europe",
  },
  {
    lat: "52.5643608",
    lng: "-0.5171892",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKGC",
    portName: "King'sCliffe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBKGE",
    portName: "Kellingley",
    portContinent: "Europe",
  },
  {
    lat: "53.639527",
    lng: "-0.2742339",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKGH",
    portName: "Killingholme",
    portContinent: "Europe",
  },
  {
    lat: "51.716249",
    lng: "-0.456157",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKGL",
    portName: "KingsLangley",
    portContinent: "Europe",
  },
  {
    lat: "52.41209809999999",
    lng: "-1.9191849",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKGN",
    portName: "Kings Norton",
    portContinent: "Europe",
  },
  {
    lat: "50.772927",
    lng: "-3.027721",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKGO",
    portName: "Kilmington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBKGS",
    portName: "Kingsley",
    portContinent: "Europe",
  },
  {
    lat: "57.081409",
    lng: "-4.054468",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKGU",
    portName: "Kingussie",
    portContinent: "Europe",
  },
  {
    lat: "52.4981719",
    lng: "-2.169995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKGW",
    portName: "Kingswinford",
    portContinent: "Europe",
  },
  {
    lat: "51.5853499",
    lng: "-0.2782546",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKGY",
    portName: "Kingsbury",
    portContinent: "Europe",
  },
  {
    lat: "50.9459177",
    lng: "-0.4289607",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKHA",
    portName: "Thakeham",
    portContinent: "Europe",
  },
  {
    lat: "52.544013",
    lng: "-0.9986794",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKHC",
    portName: "KibworthHarcourt",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBKHI",
    portName: "KennyHill",
    portContinent: "Europe",
  },
  {
    lat: "53.9975984",
    lng: "-1.287702",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKHM",
    portName: "KirkHammerton",
    portContinent: "Europe",
  },
  {
    lat: "56.071231",
    lng: "-3.174329",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKHN",
    portName: "Kinghorn",
    portContinent: "Europe",
  },
  {
    lat: "51.2304351",
    lng: "-1.1452697",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKHT",
    portName: "Kempshott",
    portContinent: "Europe",
  },
  {
    lat: "54.4726542",
    lng: "-5.6754973",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKHY",
    portName: "Killinchy",
    portContinent: "Europe",
  },
  {
    lat: "58.25704400000001",
    lng: "-3.938403",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIA",
    portName: "Kinbrace",
    portContinent: "Europe",
  },
  {
    lat: "40.8833912",
    lng: "-73.9051185",
    country: "US",
    country_long: "United States",
    portCode: "GBKIB",
    portName: "Kingsbridge",
    portContinent: "Europe",
  },
  {
    lat: "55.985505",
    lng: "-4.824147",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIC",
    portName: "Kilcreggan",
    portContinent: "Europe",
  },
  {
    lat: "51.823398",
    lng: "-1.290459",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKID",
    portName: "Kidlington, Oxfordshire",
    portContinent: "Europe",
  },
  {
    lat: "57.17455",
    lng: "-5.839398",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIE",
    portName: "Drumfearn",
    portContinent: "Europe",
  },
  {
    lat: "54.2319843",
    lng: "-3.1809121",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIF",
    portName: "KirkbyinFurness",
    portContinent: "Europe",
  },
  {
    lat: "51.4879753",
    lng: "-0.1119362",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIG",
    portName: "Kennington/London",
    portContinent: "Europe",
  },
  {
    lat: "53.78216399999999",
    lng: "-2.871765",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIH",
    portName: "Kirkham",
    portContinent: "Europe",
  },
  {
    lat: "56.53231779999999",
    lng: "-6.2336522",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKII",
    portName: "Kilninian",
    portContinent: "Europe",
  },
  {
    lat: "53.78216399999999",
    lng: "-2.871765",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIK",
    portName: "Kirkham",
    portContinent: "Europe",
  },
  {
    lat: "53.1986734",
    lng: "-6.4669351",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBKIL",
    portName: "Kilbride",
    portContinent: "Europe",
  },
  {
    lat: "50.617738",
    lng: "-2.117085",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIM",
    portName: "Kimmeridge",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBKIN",
    portName: "Kingswood",
    portContinent: "Europe",
  },
  {
    lat: "54.25085",
    lng: "-5.640079999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIO",
    portName: "Killough",
    portContinent: "Europe",
  },
  {
    lat: "54.88005099999999",
    lng: "-3.814892",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIP",
    portName: "Kippford",
    portContinent: "Europe",
  },
  {
    lat: "55.886883",
    lng: "-3.425237",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIR",
    portName: "Kirknewton",
    portContinent: "Europe",
  },
  {
    lat: "57.3846838",
    lng: "-5.6407855",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIS",
    portName: "Kishorn",
    portContinent: "Europe",
  },
  {
    lat: "53.0480299",
    lng: "-1.602",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIT",
    portName: "KirkIreton",
    portContinent: "Europe",
  },
  {
    lat: "51.5853499",
    lng: "-0.2782546",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKIU",
    portName: "Kingsbury, Greater London",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBKIY",
    portName: "Wilton",
    portContinent: "Europe",
  },
  {
    lat: "51.2889424",
    lng: "0.5036942999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKK6",
    portName: "Allington",
    portContinent: "Europe",
  },
  {
    lat: "53.48121",
    lng: "-2.891012",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKKB",
    portName: "Kirkby",
    portContinent: "Europe",
  },
  {
    lat: "56.1168249",
    lng: "-3.158137",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKKD",
    portName: "Kirkcaldy",
    portContinent: "Europe",
  },
  {
    lat: "53.697954",
    lng: "-1.546971",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKKG",
    portName: "Kirkhamgate",
    portContinent: "Europe",
  },
  {
    lat: "53.656688",
    lng: "-1.7297697",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKKH",
    portName: "Kirkheaton",
    portContinent: "Europe",
  },
  {
    lat: "55.95697200000001",
    lng: "-3.401411",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKKI",
    portName: "Kirkliston",
    portContinent: "Europe",
  },
  {
    lat: "54.204919",
    lng: "-2.60171",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKKL",
    portName: "Kirkby Lonsdale",
    portContinent: "Europe",
  },
  {
    lat: "55.38747",
    lng: "-4.001596",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKKN",
    portName: "Kirkconnel",
    portContinent: "Europe",
  },
  {
    lat: "51.311086",
    lng: "0.1062",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKKO",
    portName: "Knockholt",
    portContinent: "Europe",
  },
  {
    lat: "53.56375999999999",
    lng: "-1.0712219",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKKS",
    portName: "KirkSandall",
    portContinent: "Europe",
  },
  {
    lat: "55.9392401",
    lng: "-4.1561656",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKKT",
    portName: "Kirkintilloch",
    portContinent: "Europe",
  },
  {
    lat: "51.32633",
    lng: "-0.102494",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKL2",
    portName: "Kenley",
    portContinent: "Europe",
  },
  {
    lat: "56.69521400000001",
    lng: "-4.378614",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKL3",
    portName: "Killichonan",
    portContinent: "Europe",
  },
  {
    lat: "55.838112",
    lng: "-4.554189",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKLB",
    portName: "Kilbarchan",
    portContinent: "Europe",
  },
  {
    lat: "53.717311",
    lng: "-1.169685",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKLG",
    portName: "Kellington",
    portContinent: "Europe",
  },
  {
    lat: "54.0596902",
    lng: "-5.993312899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKLK",
    portName: "Kilkeel Harbour",
    portContinent: "Europe",
  },
  {
    lat: "54.022929",
    lng: "-1.563955",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKLL",
    portName: "Killinghall",
    portContinent: "Europe",
  },
  {
    lat: "52.7516798",
    lng: "0.4022963000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKLN",
    portName: "King's Lynn",
    portContinent: "Europe",
  },
  {
    lat: "46.146779",
    lng: "-122.9084445",
    country: "US",
    country_long: "United States",
    portCode: "GBKLO",
    portName: "Kelso",
    portContinent: "Europe",
  },
  {
    lat: "54.72555939999999",
    lng: "-5.767678999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKLR",
    portName: "Kilroot (GBKLR), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.334853",
    lng: "-1.18045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKLS",
    portName: "Kilsby",
    portContinent: "Europe",
  },
  {
    lat: "55.979788",
    lng: "-4.056933",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKLT",
    portName: "Kilsyth",
    portContinent: "Europe",
  },
  {
    lat: "38.9095355",
    lng: "-76.9398195",
    country: "US",
    country_long: "United States",
    portCode: "GBKLW",
    portName: "Kenilworth",
    portContinent: "Europe",
  },
  {
    lat: "51.731792",
    lng: "-4.717631",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKLY",
    portName: "Kilgetty",
    portContinent: "Europe",
  },
  {
    lat: "43.0875609",
    lng: "-70.8090041",
    country: "US",
    country_long: "United States",
    portCode: "GBKMB",
    portName: "Kemberton",
    portContinent: "Europe",
  },
  {
    lat: "53.5869631",
    lng: "-0.331663",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKMG",
    portName: "Kirmington",
    portContinent: "Europe",
  },
  {
    lat: "51.305953",
    lng: "0.228754",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKMI",
    portName: "Kemsing",
    portContinent: "Europe",
  },
  {
    lat: "37.7104135",
    lng: "-76.37966899999999",
    country: "US",
    country_long: "United States",
    portCode: "GBKMK",
    portName: "Kilmarnock",
    portContinent: "Europe",
  },
  {
    lat: "56.7223489",
    lng: "-3.5041293",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKML",
    portName: "Kirkmichael",
    portContinent: "Europe",
  },
  {
    lat: "55.893756",
    lng: "-4.626772",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKMN",
    portName: "Kilmacolm",
    portContinent: "Europe",
  },
  {
    lat: "52.6306148",
    lng: "-1.2331436",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKMO",
    portName: "KirbyMuxloe",
    portContinent: "Europe",
  },
  {
    lat: "51.67108020000001",
    lng: "-1.7736808",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKMP",
    portName: "Kempsford",
    portContinent: "Europe",
  },
  {
    lat: "54.2695646",
    lng: "-0.9312499999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKMS",
    portName: "KirbyMoorside",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBKMT",
    portName: "Kimpton",
    portContinent: "Europe",
  },
  {
    lat: "54.0074396",
    lng: "-1.4645728",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNA",
    portName: "Knaresborough",
    portContinent: "Europe",
  },
  {
    lat: "52.6316523",
    lng: "-0.4120742",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNB",
    portName: "Barnack",
    portContinent: "Europe",
  },
  {
    lat: "56.713145",
    lng: "-4.963824",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNC",
    portName: "Kinlochleven",
    portContinent: "Europe",
  },
  {
    lat: "54.32800599999999",
    lng: "-2.74629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKND",
    portName: "Kendal",
    portContinent: "Europe",
  },
  {
    lat: "52.155786",
    lng: "-1.510704",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNE",
    portName: "Kineton",
    portContinent: "Europe",
  },
  {
    lat: "53.3005939",
    lng: "-2.371833",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNF",
    portName: "Knutsford, Cheshire",
    portContinent: "Europe",
  },
  {
    lat: "50.549083",
    lng: "-3.598421",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNG",
    portName: "Kingsteignton",
    portContinent: "Europe",
  },
  {
    lat: "52.4324467",
    lng: "-1.8931188",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNH",
    portName: "KingsHeath",
    portContinent: "Europe",
  },
  {
    lat: "51.117936",
    lng: "0.863398",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNK",
    portName: "Kingsnorth",
    portContinent: "Europe",
  },
  {
    lat: "57.636228",
    lng: "-3.563316",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNL",
    portName: "Kinloss",
    portContinent: "Europe",
  },
  {
    lat: "41.1536674",
    lng: "-81.3578859",
    country: "US",
    country_long: "United States",
    portCode: "GBKNM",
    portName: "Kent",
    portContinent: "Europe",
  },
  {
    lat: "52.43232399999999",
    lng: "1.0010429",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNN",
    portName: "Kenninghall",
    portContinent: "Europe",
  },
  {
    lat: "53.707105",
    lng: "-1.2437419",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNO",
    portName: "Knottingley",
    portContinent: "Europe",
  },
  {
    lat: "-31.7214195",
    lng: "115.7411131",
    country: "AU",
    country_long: "Australia",
    portCode: "GBKNR",
    portName: "Kinross",
    portContinent: "Europe",
  },
  {
    lat: "51.41233",
    lng: "-0.300689",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNS",
    portName: "Kingston",
    portContinent: "Europe",
  },
  {
    lat: "40.6469982",
    lng: "-83.60965159999999",
    country: "US",
    country_long: "United States",
    portCode: "GBKNT",
    portName: "Kenton",
    portContinent: "Europe",
  },
  {
    lat: "52.4492649",
    lng: "-2.236386",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNV",
    portName: "Kinver",
    portContinent: "Europe",
  },
  {
    lat: "53.454594",
    lng: "-2.852907",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNW",
    portName: "Knowsley",
    portContinent: "Europe",
  },
  {
    lat: "52.783489",
    lng: "-2.9830739",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKNY",
    portName: "Kinnerley",
    portContinent: "Europe",
  },
  {
    lat: "56.697686",
    lng: "-6.113414",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKOA",
    portName: "Kilchoan (GBKOA), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.586623",
    lng: "-3.386135",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKOH",
    portName: "Kinloch (GBKOH), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.341009",
    lng: "-3.046971",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKON",
    portName: "Knighton",
    portContinent: "Europe",
  },
  {
    lat: "57.233247",
    lng: "-2.346088",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKOR",
    portName: "Kintore",
    portContinent: "Europe",
  },
  {
    lat: "52.28883700000001",
    lng: "0.124287",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKOT",
    portName: "Cottenham",
    portContinent: "Europe",
  },
  {
    lat: "52.386387",
    lng: "-1.732061",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKOW",
    portName: "Knowle",
    portContinent: "Europe",
  },
  {
    lat: "54.72555939999999",
    lng: "-5.767678999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKPS",
    portName: "KillrootPowerStationJetty",
    portContinent: "Europe",
  },
  {
    lat: "52.8552309",
    lng: "1.4264467",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKPT",
    portName: "Knapton",
    portContinent: "Europe",
  },
  {
    lat: "56.1168249",
    lng: "-3.158137",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKRA",
    portName: "Kilkardy",
    portContinent: "Europe",
  },
  {
    lat: "54.430176",
    lng: "-2.840161",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKRE",
    portName: "Kentmere",
    portContinent: "Europe",
  },
  {
    lat: "52.58196299999999",
    lng: "1.1883669",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKRH",
    portName: "Ketteringham",
    portContinent: "Europe",
  },
  {
    lat: "54.474279",
    lng: "-2.348925",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKRK",
    portName: "KirkbyStephen",
    portContinent: "Europe",
  },
  {
    lat: "58.54153099999999",
    lng: "-4.158088",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKRM",
    portName: "Kirtomy",
    portContinent: "Europe",
  },
  {
    lat: "30.505336",
    lng: "-84.247129",
    country: "US",
    country_long: "United States",
    portCode: "GBKRN",
    portName: "Killearn",
    portContinent: "Europe",
  },
  {
    lat: "56.675117",
    lng: "-3.003501",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKRR",
    portName: "Kirriemuir",
    portContinent: "Europe",
  },
  {
    lat: "53.4772178",
    lng: "-0.5935155999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKRT",
    portName: "KirtoninLindsey",
    portContinent: "Europe",
  },
  {
    lat: "53.4772178",
    lng: "-0.5935155999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKRTÂ",
    portName: "Kirton-In-Lindsey",
    portContinent: "Europe",
  },
  {
    lat: "52.4516314",
    lng: "-1.5331017",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKRY",
    portName: "Keresley",
    portContinent: "Europe",
  },
  {
    lat: "53.75574899999999",
    lng: "-1.690844",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKSA",
    portName: "Birkenshaw",
    portContinent: "Europe",
  },
  {
    lat: "50.8649011",
    lng: "-3.3264269",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKSB",
    portName: "Kentisbeare",
    portContinent: "Europe",
  },
  {
    lat: "53.3005939",
    lng: "-2.371833",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKSF",
    portName: "Knutsford",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBKSH",
    portName: "Kesh",
    portContinent: "Europe",
  },
  {
    lat: "53.6250264",
    lng: "-1.3701458",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKSL",
    portName: "Kinsley",
    portContinent: "Europe",
  },
  {
    lat: "51.39835069999999",
    lng: "-2.8606264",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKSM",
    portName: "KingstonSeymour",
    portContinent: "Europe",
  },
  {
    lat: "51.3654502",
    lng: "0.0221778",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKST",
    portName: "Keston",
    portContinent: "Europe",
  },
  {
    lat: "53.630955",
    lng: "-0.265186",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKSU",
    portName: "SouthKillingholme",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBKSW",
    portName: "Kingswood",
    portContinent: "Europe",
  },
  {
    lat: "43.05484269999999",
    lng: "-83.6135572",
    country: "US",
    country_long: "United States",
    portCode: "GBKSY",
    portName: "Kearsley",
    portContinent: "Europe",
  },
  {
    lat: "53.2718459",
    lng: "-0.7281759999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKTE",
    portName: "Kettlethorpe",
    portContinent: "Europe",
  },
  {
    lat: "52.4728028",
    lng: "-1.7597182",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKTG",
    portName: "KittGreen",
    portContinent: "Europe",
  },
  {
    lat: "57.543094",
    lng: "-2.951526",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKTH",
    portName: "Keith",
    portContinent: "Europe",
  },
  {
    lat: "52.203742",
    lng: "-3.029048",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKTN",
    portName: "KINGTON",
    portContinent: "Europe",
  },
  {
    lat: "39.68950359999999",
    lng: "-84.1688274",
    country: "US",
    country_long: "United States",
    portCode: "GBKTR",
    portName: "Kettering",
    portContinent: "Europe",
  },
  {
    lat: "52.58196299999999",
    lng: "1.1883669",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKTT",
    portName: "Ketteringham",
    portContinent: "Europe",
  },
  {
    lat: "51.5468507",
    lng: "-0.1466131",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKTW",
    portName: "KentishTownWest",
    portContinent: "Europe",
  },
  {
    lat: "51.41233",
    lng: "-0.300689",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKUT",
    portName: "KingstonuponThames",
    portContinent: "Europe",
  },
  {
    lat: "53.472646",
    lng: "-1.3035177",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKUZ",
    portName: "Kilnhurst, South Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "51.8329499",
    lng: "0.700921",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKVD",
    portName: "Kelvedon",
    portContinent: "Europe",
  },
  {
    lat: "38.0134293",
    lng: "-78.3586224",
    country: "US",
    country_long: "United States",
    portCode: "GBKWC",
    portName: "Keswick",
    portContinent: "Europe",
  },
  {
    lat: "55.378212",
    lng: "-4.754921",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKWD",
    portName: "Knoweside",
    portContinent: "Europe",
  },
  {
    lat: "50.348717",
    lng: "-3.567631",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKWE",
    portName: "Kingswear",
    portContinent: "Europe",
  },
  {
    lat: "55.65591999999999",
    lng: "-4.703117000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKWG",
    portName: "Kilwinning",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBKWH",
    portName: "Hatston",
    portContinent: "Europe",
  },
  {
    lat: "58.98467400000001",
    lng: "-2.962249",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKWL",
    portName: "Kirkwall (GBKWL), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5490952",
    lng: "-0.144084",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKWN",
    portName: "KentishTown",
    portContinent: "Europe",
  },
  {
    lat: "52.8360143",
    lng: "-1.2787993",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKWO",
    portName: "Kegworth",
    portContinent: "Europe",
  },
  {
    lat: "41.3581869",
    lng: "-72.5645602",
    country: "US",
    country_long: "United States",
    portCode: "GBKWR",
    portName: "Killingworth",
    portContinent: "Europe",
  },
  {
    lat: "57.1579117",
    lng: "-2.2236443",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKWS",
    portName: "Kingswells",
    portContinent: "Europe",
  },
  {
    lat: "51.851766",
    lng: "-0.5079509999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKWT",
    portName: "Kensworth",
    portContinent: "Europe",
  },
  {
    lat: "51.6223384",
    lng: "-0.6564772",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKXG",
    portName: "KnottyGreen",
    portContinent: "Europe",
  },
  {
    lat: "50.51929879999999",
    lng: "-4.3157023",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKYB",
    portName: "KellyBray",
    portContinent: "Europe",
  },
  {
    lat: "55.8541446",
    lng: "-5.1970819",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKYE",
    portName: "KylesofBute",
    portContinent: "Europe",
  },
  {
    lat: "54.3999861",
    lng: "-5.6507954",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKYH",
    portName: "Killyleagh",
    portContinent: "Europe",
  },
  {
    lat: "57.2826065",
    lng: "-5.7143293",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKYL",
    portName: "Kyle Of Lochalsh (GBKYL), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.272469",
    lng: "-5.728813",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKYN",
    portName: "Kyleakin (GBKYN), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.415059",
    lng: "-2.502526",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKYS",
    portName: "Keynsham",
    portContinent: "Europe",
  },
  {
    lat: "58.25585599999999",
    lng: "-5.018689999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKYU",
    portName: "Kylesku",
    portContinent: "Europe",
  },
  {
    lat: "52.870947",
    lng: "-1.090254",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKYW",
    portName: "Keyworth",
    portContinent: "Europe",
  },
  {
    lat: "51.823398",
    lng: "-1.290459",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBKZO",
    portName: "Kidlington",
    portContinent: "Europe",
  },
  {
    lat: "53.341036",
    lng: "-4.255672",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAA",
    portName: "Llanallgo",
    portContinent: "Europe",
  },
  {
    lat: "52.74035",
    lng: "-4.074871",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAB",
    portName: "Llanaber",
    portContinent: "Europe",
  },
  {
    lat: "51.6721379",
    lng: "0.718923",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAC",
    portName: "Latchingdon",
    portContinent: "Europe",
  },
  {
    lat: "52.351268",
    lng: "-0.591797",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAD",
    portName: "LittleAddington",
    portContinent: "Europe",
  },
  {
    lat: "52.78937",
    lng: "-4.103739",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAE",
    portName: "Llanenddwyn",
    portContinent: "Europe",
  },
  {
    lat: "52.951762",
    lng: "-4.051456",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAF",
    portName: "Llanfrothen",
    portContinent: "Europe",
  },
  {
    lat: "50.828179",
    lng: "-0.328092",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAG",
    portName: "Lancing",
    portContinent: "Europe",
  },
  {
    lat: "55.5382332",
    lng: "-5.1215712",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAH",
    portName: "Lamlash",
    portContinent: "Europe",
  },
  {
    lat: "51.5723132",
    lng: "0.4216646000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAI",
    portName: "Laindon",
    portContinent: "Europe",
  },
  {
    lat: "52.413917",
    lng: "0.5218539999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAK",
    portName: "Lakenheath",
    portContinent: "Europe",
  },
  {
    lat: "38.9464996",
    lng: "-77.1588685",
    country: "US",
    country_long: "United States",
    portCode: "GBLAL",
    portName: "Langley",
    portContinent: "Europe",
  },
  {
    lat: "52.886703",
    lng: "-3.250643",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAM",
    portName: "Llanarmon",
    portContinent: "Europe",
  },
  {
    lat: "40.0378755",
    lng: "-76.3055144",
    country: "US",
    country_long: "United States",
    portCode: "GBLAN",
    portName: "Lancaster",
    portContinent: "Europe",
  },
  {
    lat: "51.6424587",
    lng: "-3.938221999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAO",
    portName: "Landore",
    portContinent: "Europe",
  },
  {
    lat: "54.8578003",
    lng: "-5.8236224",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAR",
    portName: "Larne (GBLAR), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.431876",
    lng: "-2.647401",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAS",
    portName: "LongAshton",
    portContinent: "Europe",
  },
  {
    lat: "51.038134",
    lng: "-2.828719",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAT",
    portName: "Langport",
    portContinent: "Europe",
  },
  {
    lat: "-41.439068",
    lng: "147.1357671",
    country: "AU",
    country_long: "Australia",
    portCode: "GBLAU",
    portName: "Launceston",
    portContinent: "Europe",
  },
  {
    lat: "52.10758939999999",
    lng: "0.7954572",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAV",
    portName: "Lavenham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLAW",
    portName: "Law",
    portContinent: "Europe",
  },
  {
    lat: "60.3536975",
    lng: "-1.197488",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAX",
    portName: "Laxo (GBLAX), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2734499",
    lng: "-0.408386",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLAZ",
    portName: "Langworth, Lincolnshire",
    portContinent: "Europe",
  },
  {
    lat: "55.923289",
    lng: "-4.587828399999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLB9",
    portName: "Langbank",
    portContinent: "Europe",
  },
  {
    lat: "53.8007554",
    lng: "-1.5490774",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLBA",
    portName: "Leeds",
    portContinent: "Europe",
  },
  {
    lat: "57.70358239999999",
    lng: "-4.0242689",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLBC",
    portName: "Balnabruaich",
    portContinent: "Europe",
  },
  {
    lat: "57.153218",
    lng: "-7.308005899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLBD",
    portName: "Lochboisdale (GBLBD), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.355292",
    lng: "-5.858964",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLBE",
    portName: "LochBuie(Mull)",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLBH",
    portName: "Lamb Head",
    portContinent: "Europe",
  },
  {
    lat: "53.1180329",
    lng: "-4.127549",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLBI",
    portName: "Llanberis",
    portContinent: "Europe",
  },
  {
    lat: "51.5540666",
    lng: "0.134017",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLBK",
    portName: "Barking",
    portContinent: "Europe",
  },
  {
    lat: "55.0119467",
    lng: "-1.590343",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLBN",
    portName: "Longbenton",
    portContinent: "Europe",
  },
  {
    lat: "52.427612",
    lng: "-2.93051",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLBO",
    portName: "LittleBrampton",
    portContinent: "Europe",
  },
  {
    lat: "51.507954",
    lng: "-1.531531",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLBR",
    portName: "Lambourn",
    portContinent: "Europe",
  },
  {
    lat: "53.1839199",
    lng: "-2.6014067",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLBT",
    portName: "LittleBudworth",
    portContinent: "Europe",
  },
  {
    lat: "52.303646",
    lng: "-1.0799049",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLBU",
    portName: "LongBuckby",
    portContinent: "Europe",
  },
  {
    lat: "51.9196839",
    lng: "-0.6606569999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLBZ",
    portName: "Leighton Buzzard",
    portContinent: "Europe",
  },
  {
    lat: "56.297453",
    lng: "-5.2276149",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCA",
    portName: "Lochawe",
    portContinent: "Europe",
  },
  {
    lat: "57.368341",
    lng: "-7.297515",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCC",
    portName: "LochCarnan",
    portContinent: "Europe",
  },
  {
    lat: "57.575914",
    lng: "-5.7429564",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCD",
    portName: "LochTorridon",
    portContinent: "Europe",
  },
  {
    lat: "53.7632254",
    lng: "-2.7044052",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCE",
    portName: "Lancashire",
    portContinent: "Europe",
  },
  {
    lat: "56.0343969",
    lng: "-4.8855803",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCG",
    portName: "LochLong",
    portContinent: "Europe",
  },
  {
    lat: "55.7958077",
    lng: "-4.6302458",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCH",
    portName: "Lochwinnoch",
    portContinent: "Europe",
  },
  {
    lat: "56.878871",
    lng: "-5.665675999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCI",
    portName: "Lochailort",
    portContinent: "Europe",
  },
  {
    lat: "52.0373939",
    lng: "-0.9418669",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCK",
    portName: "Leckhampstead",
    portContinent: "Europe",
  },
  {
    lat: "51.7225824",
    lng: "-0.2993177",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCL",
    portName: "LondonColney",
    portContinent: "Europe",
  },
  {
    lat: "50.7358387",
    lng: "-2.0570446",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCM",
    portName: "LytchettMinster",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLCN",
    portName: "Lincoln",
    portContinent: "Europe",
  },
  {
    lat: "53.02596",
    lng: "-1.371497",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCO",
    portName: "Loscoe",
    portContinent: "Europe",
  },
  {
    lat: "57.397704",
    lng: "-5.503079",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCR",
    portName: "Lochcarron",
    portContinent: "Europe",
  },
  {
    lat: "52.6368778",
    lng: "-1.1397592",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCS",
    portName: "Leicester",
    portContinent: "Europe",
  },
  {
    lat: "51.5048437",
    lng: "0.049518",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCT",
    portName: "London City Airport",
    portContinent: "Europe",
  },
  {
    lat: "51.4738288",
    lng: "-3.2090818",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCW",
    portName: "Leckwith",
    portContinent: "Europe",
  },
  {
    lat: "56.130163",
    lng: "-3.306033",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLCY",
    portName: "Lochgelly",
    portContinent: "Europe",
  },
  {
    lat: "52.0414502",
    lng: "-0.5589797",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLD7",
    portName: "Lidlington",
    portContinent: "Europe",
  },
  {
    lat: "60.1767055",
    lng: "24.7470112",
    country: "FI",
    country_long: "Finland",
    portCode: "GBLDA",
    portName: "Leadon",
    portContinent: "Europe",
  },
  {
    lat: "51.8208259",
    lng: "-4.007789",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDB",
    portName: "Llandybie",
    portContinent: "Europe",
  },
  {
    lat: "50.3200419",
    lng: "-4.9612001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDC",
    portName: "Ladock",
    portContinent: "Europe",
  },
  {
    lat: "51.5313241",
    lng: "-1.703744",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDD",
    portName: "Liddington",
    portContinent: "Europe",
  },
  {
    lat: "53.7305041",
    lng: "-1.940159",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDE",
    portName: "Luddenden",
    portContinent: "Europe",
  },
  {
    lat: "50.9678908",
    lng: "-1.6350106",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDF",
    portName: "Landford",
    portContinent: "Europe",
  },
  {
    lat: "52.3884454",
    lng: "-1.977676",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDG",
    portName: "Longbridge/Birmingham",
    portContinent: "Europe",
  },
  {
    lat: "51.5617469",
    lng: "0.421311",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDH",
    portName: "LangdonHills",
    portContinent: "Europe",
  },
  {
    lat: "51.01123399999999",
    lng: "-0.082082",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDI",
    portName: "Lindfield",
    portContinent: "Europe",
  },
  {
    lat: "50.8802058",
    lng: "0.2230923",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDK",
    portName: "Lower Dicker",
    portContinent: "Europe",
  },
  {
    lat: "42.1600936",
    lng: "-72.47591899999999",
    country: "US",
    country_long: "United States",
    portCode: "GBLDL",
    portName: "Ludlow",
    portContinent: "Europe",
  },
  {
    lat: "54.95506",
    lng: "-1.400665",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDN",
    portName: "Cleadon",
    portContinent: "Europe",
  },
  {
    lat: "53.324061",
    lng: "-3.827609",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDO",
    portName: "Llandudno",
    portContinent: "Europe",
  },
  {
    lat: "52.532432",
    lng: "1.483164",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDQ",
    portName: "Loddon",
    portContinent: "Europe",
  },
  {
    lat: "53.05629099999999",
    lng: "-0.774821",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDR",
    portName: "Balderton",
    portContinent: "Europe",
  },
  {
    lat: "50.85563819999999",
    lng: "0.5525772",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDS",
    portName: "SaintLeonards-on-Sea",
    portContinent: "Europe",
  },
  {
    lat: "53.529677",
    lng: "-2.953688",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDT",
    portName: "Lydiate",
    portContinent: "Europe",
  },
  {
    lat: "51.997395",
    lng: "-3.797508",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDV",
    portName: "Llandovery",
    portContinent: "Europe",
  },
  {
    lat: "52.24169999999999",
    lng: "-3.377742",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDW",
    portName: "LlandrindodWells",
    portContinent: "Europe",
  },
  {
    lat: "52.24169999999999",
    lng: "-3.377742",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDWÂ",
    portName: "Llandrindod",
    portContinent: "Europe",
  },
  {
    lat: "51.382208",
    lng: "-0.315263",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDX",
    portName: "LongDitton",
    portContinent: "Europe",
  },
  {
    lat: "54.9966124",
    lng: "-7.3085748",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLDY",
    portName: "Londonderry",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLE7",
    portName: "Lee",
    portContinent: "Europe",
  },
  {
    lat: "52.898446",
    lng: "-1.269778",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLEA",
    portName: "Long Eaton",
    portContinent: "Europe",
  },
  {
    lat: "51.6188538",
    lng: "-0.8345037999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLEB",
    portName: "Lane End",
    portContinent: "Europe",
  },
  {
    lat: "51.6997759",
    lng: "-1.6914523",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLEC",
    portName: "Lechlade",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLED",
    portName: "Ledbury",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLEE",
    portName: "LeedsICD",
    portContinent: "Europe",
  },
  {
    lat: "-36.292358",
    lng: "174.8036249",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBLEG",
    portName: "Leigh",
    portContinent: "Europe",
  },
  {
    lat: "51.29640699999999",
    lng: "-0.33112",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLEH",
    portName: "Leatherhead",
    portContinent: "Europe",
  },
  {
    lat: "55.97552779999999",
    lng: "-3.1665102",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLEI",
    portName: "Leith (GBLEI), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLEK",
    portName: "Leek",
    portContinent: "Europe",
  },
  {
    lat: "51.239256",
    lng: "0.7206549999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLEM",
    portName: "Lenham",
    portContinent: "Europe",
  },
  {
    lat: "52.209044",
    lng: "1.57408",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLEN",
    portName: "Leiston",
    portContinent: "Europe",
  },
  {
    lat: "42.5250906",
    lng: "-71.759794",
    country: "US",
    country_long: "United States",
    portCode: "GBLEO",
    portName: "Leominster",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLEQ",
    portName: "Land'S End",
    portContinent: "Europe",
  },
  {
    lat: "60.1529871",
    lng: "-1.1492932",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLER",
    portName: "Lerwick (GBLER), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.8020939",
    lng: "-1.202352",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLES",
    portName: "Lee-on-the-Solent",
    portContinent: "Europe",
  },
  {
    lat: "51.92675999999999",
    lng: "-4.999115",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLET",
    portName: "Letterston",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLEV",
    portName: "Leven",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLEW",
    portName: "Edgeware",
    portContinent: "Europe",
  },
  {
    lat: "54.309591",
    lng: "-1.826792",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLEY",
    portName: "Leyburn",
    portContinent: "Europe",
  },
  {
    lat: "53.252813",
    lng: "-3.975109",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLFC",
    portName: "Llanfairfechan",
    portContinent: "Europe",
  },
  {
    lat: "51.2598739",
    lng: "0.0131674",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLFD",
    portName: "Limpsfield",
    portContinent: "Europe",
  },
  {
    lat: "51.7732829",
    lng: "-4.3899889",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLFF",
    portName: "Llansteffan",
    portContinent: "Europe",
  },
  {
    lat: "51.1117739",
    lng: "-0.9947168999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLFG",
    portName: "LowerFarringdon",
    portContinent: "Europe",
  },
  {
    lat: "51.95137399999999",
    lng: "-4.591070999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLFH",
    portName: "Llanfyrnach",
    portContinent: "Europe",
  },
  {
    lat: "53.2246219",
    lng: "-4.197995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLFP",
    portName: "Llanfairpwllgwyngyll",
    portContinent: "Europe",
  },
  {
    lat: "50.645627",
    lng: "-4.282801",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLFT",
    portName: "Lifton",
    portContinent: "Europe",
  },
  {
    lat: "52.39512999999999",
    lng: "-3.1078651",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLFW",
    portName: "Llanvair Waterdine",
    portContinent: "Europe",
  },
  {
    lat: "52.76623499999999",
    lng: "-3.275786",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLFY",
    portName: "Llanfyllin",
    portContinent: "Europe",
  },
  {
    lat: "51.8876571",
    lng: "-2.686058",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGA",
    portName: "Llangarron",
    portContinent: "Europe",
  },
  {
    lat: "51.82197900000001",
    lng: "-1.39436",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGB",
    portName: "LongHanborough",
    portContinent: "Europe",
  },
  {
    lat: "42.4035343",
    lng: "-71.0816626",
    country: "US",
    country_long: "United States",
    portCode: "GBLGC",
    portName: "Longcross",
    portContinent: "Europe",
  },
  {
    lat: "52.1805359",
    lng: "-0.140239",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGD",
    portName: "LittleGransden",
    portContinent: "Europe",
  },
  {
    lat: "51.3491744",
    lng: "-0.6754844999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGE",
    portName: "Lightwater",
    portContinent: "Europe",
  },
  {
    lat: "51.3983789",
    lng: "0.3020645",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGF",
    portName: "Longfield",
    portContinent: "Europe",
  },
  {
    lat: "51.1308073",
    lng: "0.2140076",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGG",
    portName: "LangtonGreen",
    portContinent: "Europe",
  },
  {
    lat: "-36.292358",
    lng: "174.8036249",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBLGH",
    portName: "Leigh",
    portContinent: "Europe",
  },
  {
    lat: "53.255785",
    lng: "-4.311402",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGI",
    portName: "Llangefni",
    portContinent: "Europe",
  },
  {
    lat: "51.847941",
    lng: "-3.14729",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGK",
    portName: "Llangattock",
    portContinent: "Europe",
  },
  {
    lat: "53.01827",
    lng: "-1.338362",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGL",
    portName: "LangleyMill",
    portContinent: "Europe",
  },
  {
    lat: "51.655942",
    lng: "0.068161",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGN",
    portName: "Loughton",
    portContinent: "Europe",
  },
  {
    lat: "27.9094665",
    lng: "-82.7873244",
    country: "US",
    country_long: "United States",
    portCode: "GBLGO",
    portName: "Largo",
    portContinent: "Europe",
  },
  {
    lat: "51.51139879999999",
    lng: "0.4803207",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGP",
    portName: "London Gateway",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLGR",
    portName: "Langar",
    portContinent: "Europe",
  },
  {
    lat: "55.79333500000001",
    lng: "-4.867278000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGS",
    portName: "Largs (GBLGS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "37.3778244",
    lng: "-96.0808215",
    country: "US",
    country_long: "United States",
    portCode: "GBLGT",
    portName: "Longton",
    portContinent: "Europe",
  },
  {
    lat: "56.629253",
    lng: "-2.77054",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGU",
    portName: "Letham",
    portContinent: "Europe",
  },
  {
    lat: "51.1536621",
    lng: "-0.1820629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGW",
    portName: "GatwickApt/London",
    portContinent: "Europe",
  },
  {
    lat: "51.51139879999999",
    lng: "0.4803207",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGX",
    portName: "LondonGatewayPark",
    portContinent: "Europe",
  },
  {
    lat: "56.130163",
    lng: "-3.306033",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLGY",
    portName: "LochGelly",
    portContinent: "Europe",
  },
  {
    lat: "57.411359",
    lng: "-3.35851",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHC",
    portName: "Ballindalloch",
    portContinent: "Europe",
  },
  {
    lat: "52.681602",
    lng: "-1.831672",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHF",
    portName: "Lichfield",
    portContinent: "Europe",
  },
  {
    lat: "55.439743",
    lng: "-1.632965",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHG",
    portName: "LittleHoughton",
    portContinent: "Europe",
  },
  {
    lat: "51.4544279",
    lng: "-1.0588218",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHH",
    portName: "LittleHeath",
    portContinent: "Europe",
  },
  {
    lat: "51.698253",
    lng: "-3.128681",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHI",
    portName: "Llanhilleth",
    portContinent: "Europe",
  },
  {
    lat: "51.8424551",
    lng: "0.1733278",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHL",
    portName: "LittleHallingbury",
    portContinent: "Europe",
  },
  {
    lat: "51.4096047",
    lng: "-0.4881632999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHM",
    portName: "Laleham",
    portContinent: "Europe",
  },
  {
    lat: "51.655942",
    lng: "0.068161",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHN",
    portName: "Loughton",
    portContinent: "Europe",
  },
  {
    lat: "58.79904879999999",
    lng: "-3.2036726",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHO",
    portName: "Longhope,Hoy",
    portContinent: "Europe",
  },
  {
    lat: "51.866547",
    lng: "-2.454864",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHP",
    portName: "Longhope (GBLHP), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.4700223",
    lng: "-0.4542955",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHR",
    portName: "Heathrow",
    portContinent: "Europe",
  },
  {
    lat: "51.4700223",
    lng: "-0.4542955",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHRÂ",
    portName: "Heathrow",
    portContinent: "Europe",
  },
  {
    lat: "55.8818755",
    lng: "-5.067297",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHS",
    portName: "Loch Striven (GBLHS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.53428",
    lng: "-2.424937",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHT",
    portName: "LittleHulton",
    portContinent: "Europe",
  },
  {
    lat: "53.53428",
    lng: "-2.424937",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHU",
    portName: "Little Hulton, Greater Manchester",
    portContinent: "Europe",
  },
  {
    lat: "51.076482",
    lng: "-0.308697",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHV",
    portName: "LittleHaven",
    portContinent: "Europe",
  },
  {
    lat: "52.112489",
    lng: "-3.5553171",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLHW",
    portName: "LlangammarchWells",
    portContinent: "Europe",
  },
  {
    lat: "53.4677477",
    lng: "-3.2436527",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLIB",
    portName: "Liverpool Bay Termin (GBLIB), Liverpool, United Kingdom",
    portContinent: "Europe",
  },
  {
    lat: "52.681602",
    lng: "-1.831672",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLIC",
    portName: "Lichfield, Staffordshire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLIE",
    portName: "Lye",
    portContinent: "Europe",
  },
  {
    lat: "52.883548",
    lng: "-1.7206867",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLIF",
    portName: "Foston",
    portContinent: "Europe",
  },
  {
    lat: "52.1998422",
    lng: "-1.5042965",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLIG",
    portName: "Lighthorne",
    portContinent: "Europe",
  },
  {
    lat: "52.3051781",
    lng: "-1.3500461",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLIH",
    portName: "LeamingtonHastings",
    portContinent: "Europe",
  },
  {
    lat: "51.915887",
    lng: "1.222613",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLIK",
    portName: "LittleOakley",
    portContinent: "Europe",
  },
  {
    lat: "55.0454563",
    lng: "-6.9336758",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLIM",
    portName: "Limavady",
    portContinent: "Europe",
  },
  {
    lat: "54.2528935",
    lng: "-7.442869600000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLIN",
    portName: "Lisnaskea",
    portContinent: "Europe",
  },
  {
    lat: "39.3398382",
    lng: "-74.5751557",
    country: "US",
    country_long: "United States",
    portCode: "GBLIO",
    portName: "Linwood",
    portContinent: "Europe",
  },
  {
    lat: "51.876722",
    lng: "-1.7246964",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLIR",
    portName: "Little Rissington",
    portContinent: "Europe",
  },
  {
    lat: "54.516246",
    lng: "-6.058010599999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLIS",
    portName: "Lisburn",
    portContinent: "Europe",
  },
  {
    lat: "50.811057",
    lng: "-0.5386609",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLIT",
    portName: "Littlehampton",
    portContinent: "Europe",
  },
  {
    lat: "53.4083714",
    lng: "-2.9915726",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLIV",
    portName: "Liverpool",
    portContinent: "Europe",
  },
  {
    lat: "50.884871",
    lng: "-1.7371757",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLIW",
    portName: "Linwood, Hampshire",
    portContinent: "Europe",
  },
  {
    lat: "53.286781",
    lng: "-3.807032",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLJC",
    portName: "LlandudnoJunction",
    portContinent: "Europe",
  },
  {
    lat: "39.613321",
    lng: "-105.0166498",
    country: "US",
    country_long: "United States",
    portCode: "GBLJN",
    portName: "Littleton",
    portContinent: "Europe",
  },
  {
    lat: "53.571292",
    lng: "-2.497125",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLJU",
    portName: "LostockJunction",
    portContinent: "Europe",
  },
  {
    lat: "52.618553",
    lng: "1.300052",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLKA",
    portName: "Lakenham",
    portContinent: "Europe",
  },
  {
    lat: "55.12224500000001",
    lng: "-3.349008",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLKB",
    portName: "Lockerbie",
    portContinent: "Europe",
  },
  {
    lat: "50.45552",
    lng: "-4.464719",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLKD",
    portName: "Liskeard",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLKG",
    portName: "Locking",
    portContinent: "Europe",
  },
  {
    lat: "50.864437",
    lng: "-1.2707",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLKH",
    portName: "LOCKS HEATH",
    portContinent: "Europe",
  },
  {
    lat: "56.831345",
    lng: "-2.472517",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLKK",
    portName: "Laurencekirk",
    portContinent: "Europe",
  },
  {
    lat: "51.19884099999999",
    lng: "-1.813649",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLKL",
    portName: "Larkhill",
    portContinent: "Europe",
  },
  {
    lat: "51.75702399999999",
    lng: "-0.8036540999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLKM",
    portName: "LittleKimble",
    portContinent: "Europe",
  },
  {
    lat: "52.953685",
    lng: "1.014356",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLKN",
    portName: "Blakeney",
    portContinent: "Europe",
  },
  {
    lat: "53.153217",
    lng: "-2.982026",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLKT",
    portName: "LowerKinnerton",
    portContinent: "Europe",
  },
  {
    lat: "52.714453",
    lng: "-1.631981",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLL3",
    portName: "Lullington",
    portContinent: "Europe",
  },
  {
    lat: "52.156899",
    lng: "-3.393514",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLL9",
    portName: "Llanelwedd",
    portContinent: "Europe",
  },
  {
    lat: "51.88469",
    lng: "-3.99143",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLA",
    portName: "Llandeilo",
    portContinent: "Europe",
  },
  {
    lat: "53.646141",
    lng: "-2.093367",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLB",
    portName: "Littleborough",
    portContinent: "Europe",
  },
  {
    lat: "53.347291",
    lng: "0.1006746",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLC",
    portName: "LittleCarlton",
    portContinent: "Europe",
  },
  {
    lat: "53.287553",
    lng: "-3.642969",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLD",
    portName: "Port of Llanddulas",
    portContinent: "Europe",
  },
  {
    lat: "53.29530099999999",
    lng: "-4.087893",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLE",
    portName: "Llangoed",
    portContinent: "Europe",
  },
  {
    lat: "51.92225999999999",
    lng: "-3.346746",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLF",
    portName: "Llanfrynach",
    portContinent: "Europe",
  },
  {
    lat: "51.69616200000001",
    lng: "-4.083864",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLG",
    portName: "Llangennech",
    portContinent: "Europe",
  },
  {
    lat: "51.8846051",
    lng: "0.0899758",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLH",
    portName: "LittleHadham",
    portContinent: "Europe",
  },
  {
    lat: "51.5767993",
    lng: "-3.5674561",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLI",
    portName: "Llangeinor",
    portContinent: "Europe",
  },
  {
    lat: "54.95854",
    lng: "-1.686244",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLL",
    portName: "Swalwell",
    portContinent: "Europe",
  },
  {
    lat: "51.7046005",
    lng: "-1.1048343",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLM",
    portName: "LittleMilton",
    portContinent: "Europe",
  },
  {
    lat: "51.680886",
    lng: "-4.160248",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLN",
    portName: "Llanelli",
    portContinent: "Europe",
  },
  {
    lat: "52.2817522",
    lng: "-4.1771996",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLO",
    portName: "Llanon",
    portContinent: "Europe",
  },
  {
    lat: "52.456703",
    lng: "0.3073659",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLP",
    portName: "Littleport",
    portContinent: "Europe",
  },
  {
    lat: "50.945251",
    lng: "0.09031",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLR",
    portName: "LittleHorsted",
    portContinent: "Europe",
  },
  {
    lat: "51.7732829",
    lng: "-4.3899889",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLS",
    portName: "Llanstephan",
    portContinent: "Europe",
  },
  {
    lat: "51.5429188",
    lng: "-3.3749525",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLT",
    portName: "Llantrisant",
    portContinent: "Europe",
  },
  {
    lat: "51.2774659",
    lng: "1.166108",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLU",
    portName: "Littlebourne",
    portContinent: "Europe",
  },
  {
    lat: "53.56398799999999",
    lng: "-2.377596",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLV",
    portName: "LittleLever",
    portContinent: "Europe",
  },
  {
    lat: "55.9716266",
    lng: "-3.6025847",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLW",
    portName: "Linlithgow",
    portContinent: "Europe",
  },
  {
    lat: "53.092664",
    lng: "-3.003691",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLY",
    portName: "Llay",
    portContinent: "Europe",
  },
  {
    lat: "52.44779399999999",
    lng: "-3.540195",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLLZ",
    portName: "Llanidloes",
    portContinent: "Europe",
  },
  {
    lat: "57.6045975",
    lng: "-7.162211699999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLMA",
    portName: "Lochmaddy (GBLMA), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.10018549999999",
    lng: "0.3919487",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLMB",
    portName: "Lamberhurst",
    portContinent: "Europe",
  },
  {
    lat: "52.07435599999999",
    lng: "0.71633",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLMD",
    portName: "LongMelford",
    portContinent: "Europe",
  },
  {
    lat: "56.0342419",
    lng: "-3.479036",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLMK",
    portName: "Limekilns",
    portContinent: "Europe",
  },
  {
    lat: "53.381724",
    lng: "-2.480044",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLMM",
    portName: "Lymm",
    portContinent: "Europe",
  },
  {
    lat: "54.08314",
    lng: "-5.9510401",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLMN",
    portName: "Ballymartin",
    portContinent: "Europe",
  },
  {
    lat: "55.21222599999999",
    lng: "-1.539334",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLMO",
    portName: "Lynemouth",
    portContinent: "Europe",
  },
  {
    lat: "39.9901409",
    lng: "-76.2398721",
    country: "US",
    country_long: "United States",
    portCode: "GBLMP",
    portName: "Lampeter",
    portContinent: "Europe",
  },
  {
    lat: "50.83287",
    lng: "-0.542975",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLMR",
    portName: "Lyminster",
    portContinent: "Europe",
  },
  {
    lat: "50.6093043",
    lng: "-2.0046696",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLMS",
    portName: "LangtonMatravers",
    portContinent: "Europe",
  },
  {
    lat: "51.664173",
    lng: "-3.900516",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLMT",
    portName: "Llansamlet",
    portContinent: "Europe",
  },
  {
    lat: "55.637634",
    lng: "-3.88657",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLMW",
    portName: "Lesmahagow",
    portContinent: "Europe",
  },
  {
    lat: "53.0966063",
    lng: "-3.2109489",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLMY",
    portName: "Llanarmon-yn-IÃ¢l",
    portContinent: "Europe",
  },
  {
    lat: "56.388758",
    lng: "-4.286112999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNA",
    portName: "Lochearnhead",
    portContinent: "Europe",
  },
  {
    lat: "52.8197179",
    lng: "-4.101434999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNB",
    portName: "Llanbedr",
    portContinent: "Europe",
  },
  {
    lat: "50.828179",
    lng: "-0.328092",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNC",
    portName: "Lancing, West Sussex",
    portContinent: "Europe",
  },
  {
    lat: "51.4491338",
    lng: "-3.5221746",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLND",
    portName: "Llandow",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLNE",
    portName: "Lyne",
    portContinent: "Europe",
  },
  {
    lat: "51.176981",
    lng: "-0.014126",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNF",
    portName: "Lingfield",
    portContinent: "Europe",
  },
  {
    lat: "51.938106",
    lng: "-3.882858999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNG",
    portName: "Llangadog",
    portContinent: "Europe",
  },
  {
    lat: "55.876406",
    lng: "-3.149354",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNH",
    portName: "Loanhead",
    portContinent: "Europe",
  },
  {
    lat: "52.996266",
    lng: "-0.760111",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNI",
    portName: "LongBennington",
    portContinent: "Europe",
  },
  {
    lat: "55.673865",
    lng: "-3.782138",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNK",
    portName: "Lanark",
    portContinent: "Europe",
  },
  {
    lat: "52.969215",
    lng: "-3.17166",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNL",
    portName: "Llangollen",
    portContinent: "Europe",
  },
  {
    lat: "51.2295747",
    lng: "-3.8291697",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNM",
    portName: "Lynmouth",
    portContinent: "Europe",
  },
  {
    lat: "53.571456",
    lng: "-0.403125",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNN",
    portName: "BarnetbyleWold",
    portContinent: "Europe",
  },
  {
    lat: "55.153062",
    lng: "-2.998913",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNO",
    portName: "Langholm",
    portContinent: "Europe",
  },
  {
    lat: "39.3142827",
    lng: "-74.5248756",
    country: "US",
    country_long: "United States",
    portCode: "GBLNP",
    portName: "Longport",
    portContinent: "Europe",
  },
  {
    lat: "51.53864799999999",
    lng: "-3.439496",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNR",
    portName: "Llanharan",
    portContinent: "Europe",
  },
  {
    lat: "39.3398382",
    lng: "-74.5751557",
    country: "US",
    country_long: "United States",
    portCode: "GBLNW",
    portName: "Linwood",
    portContinent: "Europe",
  },
  {
    lat: "38.9464996",
    lng: "-77.1588685",
    country: "US",
    country_long: "United States",
    portCode: "GBLNY",
    portName: "Langley",
    portContinent: "Europe",
  },
  {
    lat: "55.9235791",
    lng: "-4.1556413",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLNZ",
    portName: "Lenzie",
    portContinent: "Europe",
  },
  {
    lat: "54.867755",
    lng: "-5.013194899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOA",
    portName: "Lochans",
    portContinent: "Europe",
  },
  {
    lat: "51.9649841",
    lng: "-1.742665",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOB",
    portName: "Longborough",
    portContinent: "Europe",
  },
  {
    lat: "51.773991",
    lng: "-1.001586",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOC",
    portName: "Long Crendon",
    portContinent: "Europe",
  },
  {
    lat: "52.5348461",
    lng: "1.6907619",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOD",
    portName: "Lound",
    portContinent: "Europe",
  },
  {
    lat: "50.3562269",
    lng: "-4.455232",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOE",
    portName: "Looe",
    portContinent: "Europe",
  },
  {
    lat: "53.7276296",
    lng: "-7.7932573",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBLOF",
    portName: "Longford",
    portContinent: "Europe",
  },
  {
    lat: "53.571292",
    lng: "-2.497125",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOG",
    portName: "Lostock",
    portContinent: "Europe",
  },
  {
    lat: "56.03829200000001",
    lng: "-5.432345000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOH",
    portName: "Lochgilphead",
    portContinent: "Europe",
  },
  {
    lat: "52.28407",
    lng: "-1.394391",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOI",
    portName: "LongItchington",
    portContinent: "Europe",
  },
  {
    lat: "51.915887",
    lng: "1.222613",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOK",
    portName: "LittleOakley",
    portContinent: "Europe",
  },
  {
    lat: "56.53486299999999",
    lng: "-5.777343",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOL",
    portName: "Lochaline (GBLOL), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8320214",
    lng: "-0.6979016",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOM",
    portName: "Long Marston Hertfordshire",
    portContinent: "Europe",
  },
  {
    lat: "51.5072178",
    lng: "-0.1275862",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLON",
    portName: "London",
    portContinent: "Europe",
  },
  {
    lat: "52.57287729999999",
    lng: "-0.2856446",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOO",
    portName: "Longthorpe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLOP",
    portName: "Lopen",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLOQ",
    portName: "Longley",
    portContinent: "Europe",
  },
  {
    lat: "53.2734499",
    lng: "-0.408386",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOR",
    portName: "Langworth",
    portContinent: "Europe",
  },
  {
    lat: "51.5423959",
    lng: "0.6535069",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOS",
    portName: "Leigh-On-Sea",
    portContinent: "Europe",
  },
  {
    lat: "53.95080000000001",
    lng: "-6.54062",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBLOT",
    portName: "Louth",
    portContinent: "Europe",
  },
  {
    lat: "52.772099",
    lng: "-1.206166",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOU",
    portName: "Loughborough",
    portContinent: "Europe",
  },
  {
    lat: "58.14622499999999",
    lng: "-5.2426102",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOV",
    portName: "Lochinver (GBLOV), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.48113799999999",
    lng: "1.753449",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOW",
    portName: "Lowestoft",
    portContinent: "Europe",
  },
  {
    lat: "60.229499",
    lng: "-1.563204",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOY",
    portName: "Walls (GBLOY), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2418391",
    lng: "0.2436207",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLOZ",
    portName: "Lode",
    portContinent: "Europe",
  },
  {
    lat: "52.5702333",
    lng: "-2.1973662",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLPE",
    portName: "LowerPenn",
    portContinent: "Europe",
  },
  {
    lat: "50.860581",
    lng: "-3.799168",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLPF",
    portName: "Lapford",
    portContinent: "Europe",
  },
  {
    lat: "51.07661",
    lng: "-0.801777",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLPH",
    portName: "Liphook",
    portContinent: "Europe",
  },
  {
    lat: "51.28424889999999",
    lng: "-2.9545891",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLPM",
    portName: "Lympsham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLPN",
    portName: "Clapton",
    portContinent: "Europe",
  },
  {
    lat: "56.44893",
    lng: "-3.3447351",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLPR",
    portName: "Balbeggie",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLPT",
    portName: "Lepton",
    portContinent: "Europe",
  },
  {
    lat: "52.339016",
    lng: "-1.762603",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLPW",
    portName: "Lapworth",
    portContinent: "Europe",
  },
  {
    lat: "50.3858991",
    lng: "-4.561301",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRA",
    portName: "Lanreath",
    portContinent: "Europe",
  },
  {
    lat: "56.0196754",
    lng: "-3.8360606",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRB",
    portName: "Larbert",
    portContinent: "Europe",
  },
  {
    lat: "53.4720649",
    lng: "-2.9963154",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRD",
    portName: "Litherland",
    portContinent: "Europe",
  },
  {
    lat: "53.831915",
    lng: "-2.598802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRE",
    portName: "Longridge",
    portContinent: "Europe",
  },
  {
    lat: "58.022058",
    lng: "-4.402413999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRG",
    portName: "Lairg",
    portContinent: "Europe",
  },
  {
    lat: "51.19884099999999",
    lng: "-1.813649",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRH",
    portName: "LarkHill",
    portContinent: "Europe",
  },
  {
    lat: "52.465016",
    lng: "0.9137839999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRI",
    portName: "Larling",
    portContinent: "Europe",
  },
  {
    lat: "40.863874",
    lng: "-73.3204949",
    country: "US",
    country_long: "United States",
    portCode: "GBLRK",
    portName: "Larkfield",
    portContinent: "Europe",
  },
  {
    lat: "55.737744",
    lng: "-3.971699",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRL",
    portName: "Larkhall",
    portContinent: "Europe",
  },
  {
    lat: "53.33105",
    lng: "-4.3769681",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRM",
    portName: "Llanerchymedd",
    portContinent: "Europe",
  },
  {
    lat: "51.950821",
    lng: "-2.718304",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRN",
    portName: "Llanwarne",
    portContinent: "Europe",
  },
  {
    lat: "54.9506208",
    lng: "-5.038728000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRP",
    portName: "LochRyanPt",
    portContinent: "Europe",
  },
  {
    lat: "54.4765",
    lng: "-7.699459999999998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRR",
    portName: "Lisnarrick",
    portContinent: "Europe",
  },
  {
    lat: "53.137033",
    lng: "-3.795732",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRS",
    portName: "Llanwrst",
    portContinent: "Europe",
  },
  {
    lat: "52.487476",
    lng: "1.237279",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRT",
    portName: "LongStratton",
    portContinent: "Europe",
  },
  {
    lat: "53.137033",
    lng: "-3.795732",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRW",
    portName: "Llanrwst",
    portContinent: "Europe",
  },
  {
    lat: "55.703024",
    lng: "-5.2907209",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLRZ",
    portName: "Lochranza (GBLRZ), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.255202",
    lng: "-1.621289",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSA",
    portName: "Ludgershall",
    portContinent: "Europe",
  },
  {
    lat: "56.36211249999999",
    lng: "-6.0840964",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSC",
    portName: "LochScridain(IsleMull)",
    portContinent: "Europe",
  },
  {
    lat: "53.823713",
    lng: "-1.859404",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSD",
    portName: "Wilsden",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLSE",
    portName: "Lees",
    portContinent: "Europe",
  },
  {
    lat: "55.518147",
    lng: "-2.7393301",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSF",
    portName: "Lilliesleaf",
    portContinent: "Europe",
  },
  {
    lat: "51.607214",
    lng: "-2.899949",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSH",
    portName: "Langstone",
    portContinent: "Europe",
  },
  {
    lat: "59.869131",
    lng: "-1.287109",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSI",
    portName: "Sumburgh (GBLSI), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.916477",
    lng: "-2.893365",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSK",
    portName: "Linstock",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLSL",
    portName: "Leslie",
    portContinent: "Europe",
  },
  {
    lat: "56.5005914",
    lng: "-5.5263342",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSM",
    portName: "LismoreIsland",
    portContinent: "Europe",
  },
  {
    lat: "52.7849778",
    lng: "0.1204287",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSN",
    portName: "Long Sutton Lincolnshire",
    portContinent: "Europe",
  },
  {
    lat: "52.134166",
    lng: "-1.778136",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSO",
    portName: "LongMarston",
    portContinent: "Europe",
  },
  {
    lat: "52.2851905",
    lng: "-1.5200789",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSP",
    portName: "LeamingtonSpa",
    portContinent: "Europe",
  },
  {
    lat: "51.090905",
    lng: "-1.455497",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSR",
    portName: "LittleSomborne",
    portContinent: "Europe",
  },
  {
    lat: "57.72157899999999",
    lng: "-3.280325",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSS",
    portName: "Lossiemouth",
    portContinent: "Europe",
  },
  {
    lat: "54.0059345",
    lng: "-0.2533733",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLST",
    portName: "Lissett",
    portContinent: "Europe",
  },
  {
    lat: "50.406021",
    lng: "-4.6750679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSW",
    portName: "Lostwithiel",
    portContinent: "Europe",
  },
  {
    lat: "53.7884063",
    lng: "-1.7167487",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLSY",
    portName: "Laisterdyke",
    portContinent: "Europe",
  },
  {
    lat: "39.0347666",
    lng: "-87.1658458",
    country: "US",
    country_long: "United States",
    portCode: "GBLT2",
    portName: "Linton",
    portContinent: "Europe",
  },
  {
    lat: "39.1753256",
    lng: "-89.65425789999999",
    country: "US",
    country_long: "United States",
    portCode: "GBLTC",
    portName: "Litchfield",
    portContinent: "Europe",
  },
  {
    lat: "50.2166667",
    lng: "-3.7833333",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLTD",
    portName: "BoltHead",
    portContinent: "Europe",
  },
  {
    lat: "52.9693051",
    lng: "-1.4604305",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLTE",
    portName: "LittleEaton",
    portContinent: "Europe",
  },
  {
    lat: "52.696822",
    lng: "-0.339742",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLTF",
    portName: "Langtoft",
    portContinent: "Europe",
  },
  {
    lat: "51.979074",
    lng: "-0.226624",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLTH",
    portName: "Letchworth",
    portContinent: "Europe",
  },
  {
    lat: "51.4852797",
    lng: "0.341383",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLTK",
    portName: "LittleThurrock",
    portContinent: "Europe",
  },
  {
    lat: "40.3636577",
    lng: "-74.63799209999999",
    country: "US",
    country_long: "United States",
    portCode: "GBLTL",
    portName: "Littlebrook",
    portContinent: "Europe",
  },
  {
    lat: "51.409259",
    lng: "-3.485246",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLTM",
    portName: "Llantwit",
    portContinent: "Europe",
  },
  {
    lat: "39.0347666",
    lng: "-87.1658458",
    country: "US",
    country_long: "United States",
    portCode: "GBLTN",
    portName: "Linton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLTO",
    portName: "Litton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLTP",
    portName: "LondonThamesport",
    portContinent: "Europe",
  },
  {
    lat: "51.633987",
    lng: "-3.002402",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLTR",
    portName: "Llantarnam",
    portContinent: "Europe",
  },
  {
    lat: "59.190834",
    lng: "-2.6940901",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLTS",
    portName: "Loth (Sanday) (GBLTS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.45599499999999",
    lng: "-1.199159",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLTT",
    portName: "Lutterworth",
    portContinent: "Europe",
  },
  {
    lat: "51.955499",
    lng: "-2.9899079",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLTW",
    portName: "Longtown",
    portContinent: "Europe",
  },
  {
    lat: "52.0059409",
    lng: "1.252257",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLTX",
    portName: "Levington",
    portContinent: "Europe",
  },
  {
    lat: "51.8614841",
    lng: "-4.131084",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLTY",
    portName: "Llanarthney",
    portContinent: "Europe",
  },
  {
    lat: "56.7745611",
    lng: "-4.8432368",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLUB",
    portName: "Luibeilt",
    portContinent: "Europe",
  },
  {
    lat: "55.7444879",
    lng: "-4.5295216",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLUG",
    portName: "Lugton",
    portContinent: "Europe",
  },
  {
    lat: "50.8984408",
    lng: "0.1346615",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLUH",
    portName: "Laughton",
    portContinent: "Europe",
  },
  {
    lat: "56.264643",
    lng: "-5.633634",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLUI",
    portName: "SouthCuan,Luing",
    portContinent: "Europe",
  },
  {
    lat: "50.6325915",
    lng: "-2.2345805",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLUL",
    portName: "LulworthCamp",
    portContinent: "Europe",
  },
  {
    lat: "52.7705703",
    lng: "-1.4286753",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLUN",
    portName: "Lount",
    portContinent: "Europe",
  },
  {
    lat: "51.8786707",
    lng: "-0.4200255",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLUT",
    portName: "Luton",
    portContinent: "Europe",
  },
  {
    lat: "54.765845",
    lng: "-1.43712",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLUW",
    portName: "Ludworth",
    portContinent: "Europe",
  },
  {
    lat: "50.3902632",
    lng: "-4.745778899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLUX",
    portName: "Luxulyan",
    portContinent: "Europe",
  },
  {
    lat: "51.69531199999999",
    lng: "-0.394772",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLVG",
    portName: "LeavesdenGreen",
    portContinent: "Europe",
  },
  {
    lat: "50.442047",
    lng: "-4.761795",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLVI",
    portName: "Lanivet",
    portContinent: "Europe",
  },
  {
    lat: "59.97463200000001",
    lng: "-1.269779",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLVK",
    portName: "Levenwick",
    portContinent: "Europe",
  },
  {
    lat: "53.8808253",
    lng: "-3.0389598",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLVL",
    portName: "Cleveleys",
    portContinent: "Europe",
  },
  {
    lat: "53.4488443",
    lng: "-2.1931977",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLVN",
    portName: "Levenshulme",
    portContinent: "Europe",
  },
  {
    lat: "57.76945199999999",
    lng: "-7.008350999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLVR",
    portName: "Leverburgh (GBLVR), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.709984",
    lng: "-1.697018",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLVS",
    portName: "Liversedge",
    portContinent: "Europe",
  },
  {
    lat: "52.287822",
    lng: "-2.1693699",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLVT",
    portName: "HamptonLovett",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLVY",
    portName: "Leven",
    portContinent: "Europe",
  },
  {
    lat: "51.968201",
    lng: "-3.874428",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLW9",
    portName: "Llanwrda",
    portContinent: "Europe",
  },
  {
    lat: "51.61044099999999",
    lng: "-0.701444",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWA",
    portName: "Loudwater",
    portContinent: "Europe",
  },
  {
    lat: "50.6182125",
    lng: "-2.2470177",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWC",
    portName: "LulworthCove",
    portContinent: "Europe",
  },
  {
    lat: "52.0498619",
    lng: "0.2529939",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWD",
    portName: "LittleWalden",
    portContinent: "Europe",
  },
  {
    lat: "38.7745565",
    lng: "-75.13934979999999",
    country: "US",
    country_long: "United States",
    portCode: "GBLWE",
    portName: "Lewes",
    portContinent: "Europe",
  },
  {
    lat: "52.6662869",
    lng: "-4.0843239",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWG",
    portName: "Llwyngwril",
    portContinent: "Europe",
  },
  {
    lat: "52.8959389",
    lng: "0.8753991",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWH",
    portName: "LittleWalsingham",
    portContinent: "Europe",
  },
  {
    lat: "52.271172",
    lng: "-2.316274",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWI",
    portName: "LittleWitley",
    portContinent: "Europe",
  },
  {
    lat: "58.274768",
    lng: "-3.382873",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWL",
    portName: "Latheronwheel",
    portContinent: "Europe",
  },
  {
    lat: "53.753942",
    lng: "-1.757768",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWM",
    portName: "LowMoor",
    portContinent: "Europe",
  },
  {
    lat: "53.47395599999999",
    lng: "-2.582859",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWN",
    portName: "Lowton",
    portContinent: "Europe",
  },
  {
    lat: "51.481143",
    lng: "-1.542899",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWO",
    portName: "Lambourn Woodlands",
    portContinent: "Europe",
  },
  {
    lat: "51.2712662",
    lng: "-2.8453899",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWR",
    portName: "LowerWeare",
    portContinent: "Europe",
  },
  {
    lat: "51.46115090000001",
    lng: "-0.0073177",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWS",
    portName: "Lewisham",
    portContinent: "Europe",
  },
  {
    lat: "51.48708240000001",
    lng: "0.2805913",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWT",
    portName: "West Thurrock",
    portContinent: "Europe",
  },
  {
    lat: "54.69523",
    lng: "-2.6668179",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLWY",
    portName: "Langwathby",
    portContinent: "Europe",
  },
  {
    lat: "51.131417",
    lng: "-3.467666",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLXG",
    portName: "Luxborough",
    portContinent: "Europe",
  },
  {
    lat: "53.19552299999999",
    lng: "-0.9202929",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLXT",
    portName: "Laxton",
    portContinent: "Europe",
  },
  {
    lat: "52.0014899",
    lng: "-0.319401",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLXW",
    portName: "LowerStondon",
    portContinent: "Europe",
  },
  {
    lat: "30.6182476",
    lng: "-87.75304539999999",
    country: "US",
    country_long: "United States",
    portCode: "GBLXY",
    portName: "Loxley",
    portContinent: "Europe",
  },
  {
    lat: "51.1629607",
    lng: "1.2406802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLY9",
    portName: "Lydden",
    portContinent: "Europe",
  },
  {
    lat: "53.756629",
    lng: "-3.0213193",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYA",
    portName: "Lytham StAnnes",
    portContinent: "Europe",
  },
  {
    lat: "58.304216",
    lng: "-3.285232",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYB",
    portName: "Lybster",
    portContinent: "Europe",
  },
  {
    lat: "52.7813159",
    lng: "-3.09031",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYC",
    portName: "Llanymynech",
    portContinent: "Europe",
  },
  {
    lat: "51.72913",
    lng: "-2.530504",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYD",
    portName: "Lydney",
    portContinent: "Europe",
  },
  {
    lat: "51.51402299999999",
    lng: "-1.97945",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYE",
    portName: "Lyneham",
    portContinent: "Europe",
  },
  {
    lat: "41.5200523",
    lng: "-81.4887315",
    country: "US",
    country_long: "United States",
    portCode: "GBLYH",
    portName: "Lyndhurst",
    portContinent: "Europe",
  },
  {
    lat: "53.381724",
    lng: "-2.480044",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYI",
    portName: "Lymm, Cheshire",
    portContinent: "Europe",
  },
  {
    lat: "56.280831",
    lng: "-3.12671",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYK",
    portName: "Ladybank",
    portContinent: "Europe",
  },
  {
    lat: "53.697931",
    lng: "-2.695478",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYL",
    portName: "Leyland",
    portContinent: "Europe",
  },
  {
    lat: "50.758531",
    lng: "-1.5419099",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYM",
    portName: "Lymington",
    portContinent: "Europe",
  },
  {
    lat: "51.075215",
    lng: "1.025608",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYN",
    portName: "Lympne",
    portContinent: "Europe",
  },
  {
    lat: "51.5702225",
    lng: "-0.0146938",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYO",
    portName: "Leyton",
    portContinent: "Europe",
  },
  {
    lat: "50.725156",
    lng: "-2.936639",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYR",
    portName: "Lyme Regis",
    portContinent: "Europe",
  },
  {
    lat: "58.83330400000001",
    lng: "-3.207505",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYS",
    portName: "Lyness (GBLYS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.2296539",
    lng: "-3.840102",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYT",
    portName: "Lynton",
    portContinent: "Europe",
  },
  {
    lat: "52.04167500000001",
    lng: "-4.309197999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYU",
    portName: "Llandysul",
    portContinent: "Europe",
  },
  {
    lat: "54.5427301",
    lng: "-5.4843701",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYW",
    portName: "Ballywalter",
    portContinent: "Europe",
  },
  {
    lat: "50.950945",
    lng: "0.9065892",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYX",
    portName: "Lydd",
    portContinent: "Europe",
  },
  {
    lat: "52.07488499999999",
    lng: "-4.157826",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLYY",
    portName: "Llanybydder",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBLZD",
    portName: "Lizard",
    portContinent: "Europe",
  },
  {
    lat: "52.0543369",
    lng: "-0.271574",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLZF",
    portName: "Langford",
    portContinent: "Europe",
  },
  {
    lat: "51.5000406",
    lng: "-0.5430353",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBLZZ",
    portName: "Langley, Berkshire",
    portContinent: "Europe",
  },
  {
    lat: "51.7780541",
    lng: "-2.09214",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBM89",
    portName: "Miserden",
    portContinent: "Europe",
  },
  {
    lat: "50.96669499999999",
    lng: "-2.3109099",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMA9",
    portName: "Marnhull",
    portContinent: "Europe",
  },
  {
    lat: "50.819012",
    lng: "-2.2706499",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAB",
    portName: "Milton Abbas",
    portContinent: "Europe",
  },
  {
    lat: "55.42245699999999",
    lng: "-5.735069999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAC",
    portName: "Machrihanish",
    portContinent: "Europe",
  },
  {
    lat: "55.971619",
    lng: "-3.698494",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAD",
    portName: "Maddiston",
    portContinent: "Europe",
  },
  {
    lat: "52.636284",
    lng: "-2.4536222",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAE",
    portName: "Madeley",
    portContinent: "Europe",
  },
  {
    lat: "51.14817",
    lng: "0.366846",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAF",
    portName: "Matfield",
    portContinent: "Europe",
  },
  {
    lat: "51.5815849",
    lng: "-2.829734",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAG",
    portName: "Magor",
    portContinent: "Europe",
  },
  {
    lat: "51.52182089999999",
    lng: "-0.7242048",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAH",
    portName: "Maidenhead",
    portContinent: "Europe",
  },
  {
    lat: "55.333113",
    lng: "-4.818632",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAI",
    portName: "Maidens",
    portContinent: "Europe",
  },
  {
    lat: "39.6255652",
    lng: "-104.8833173",
    country: "US",
    country_long: "United States",
    portCode: "GBMAK",
    portName: "Marrick",
    portContinent: "Europe",
  },
  {
    lat: "51.73180499999999",
    lng: "0.6714479999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAL",
    portName: "Maldon",
    portContinent: "Europe",
  },
  {
    lat: "52.3413262",
    lng: "-2.4575159",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAM",
    portName: "Mamble, Worcestershire",
    portContinent: "Europe",
  },
  {
    lat: "53.4807593",
    lng: "-2.2426305",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAN",
    portName: "Manchester",
    portContinent: "Europe",
  },
  {
    lat: "52.482462",
    lng: "-1.9010755",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAO",
    portName: "Marston",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBMAR",
    portName: "March",
    portContinent: "Europe",
  },
  {
    lat: "54.22334",
    lng: "-1.655749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAS",
    portName: "Masham",
    portContinent: "Europe",
  },
  {
    lat: "51.286842",
    lng: "-1.977487",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAT",
    portName: "Market Lavington",
    portContinent: "Europe",
  },
  {
    lat: "52.1280537",
    lng: "-2.3169075",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAV",
    portName: "Malvern Link, Worcestershire",
    portContinent: "Europe",
  },
  {
    lat: "50.8918843",
    lng: "-1.4483102",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMAW",
    portName: "Marchwood",
    portContinent: "Europe",
  },
  {
    lat: "36.7417235",
    lng: "-88.6367154",
    country: "US",
    country_long: "United States",
    portCode: "GBMAY",
    portName: "Mayfield",
    portContinent: "Europe",
  },
  {
    lat: "51.1501132",
    lng: "-2.2858047",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMBD",
    portName: "MaidenBradley",
    portContinent: "Europe",
  },
  {
    lat: "-37.8136276",
    lng: "144.9630576",
    country: "AU",
    country_long: "Australia",
    portCode: "GBMBE",
    portName: "Melbourne",
    portContinent: "Europe",
  },
  {
    lat: "-37.8008004",
    lng: "144.9485049",
    country: "AU",
    country_long: "Australia",
    portCode: "GBMBEÂ",
    portName: "MelbourneNorth",
    portContinent: "Europe",
  },
  {
    lat: "50.2441699",
    lng: "-3.81208",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMBH",
    portName: "Malborough",
    portContinent: "Europe",
  },
  {
    lat: "50.8004646",
    lng: "-1.9830004",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMBN",
    portName: "Wimeborne",
    portContinent: "Europe",
  },
  {
    lat: "51.5188746",
    lng: "-0.1498955",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMBO",
    portName: "Marylebone",
    portContinent: "Europe",
  },
  {
    lat: "52.6900954",
    lng: "-2.7614919",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMBR",
    portName: "MeoleBrace",
    portContinent: "Europe",
  },
  {
    lat: "52.2052973",
    lng: "0.1218195",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMBS",
    portName: "Cambs",
    portContinent: "Europe",
  },
  {
    lat: "52.623465",
    lng: "-1.40784",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMBT",
    portName: "MarketBosworth",
    portContinent: "Europe",
  },
  {
    lat: "50.821726",
    lng: "-3.029326",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMBU",
    portName: "Membury",
    portContinent: "Europe",
  },
  {
    lat: "52.76692689999999",
    lng: "-0.8879085999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMBY",
    portName: "Melton Mowbray",
    portContinent: "Europe",
  },
  {
    lat: "51.856054",
    lng: "0.071866",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMCA",
    portName: "MuchHadham",
    portContinent: "Europe",
  },
  {
    lat: "57.67058999999999",
    lng: "-2.497849",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMCD",
    portName: "Macduff (GBMCD), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "37.1586954",
    lng: "-93.2951468",
    country: "US",
    country_long: "United States",
    portCode: "GBMCE",
    portName: "Mitchem",
    portContinent: "Europe",
  },
  {
    lat: "53.019049",
    lng: "-2.7673359",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMCH",
    portName: "Malpas",
    portContinent: "Europe",
  },
  {
    lat: "53.258663",
    lng: "-2.119287",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMCL",
    portName: "Macclesfield",
    portContinent: "Europe",
  },
  {
    lat: "55.941553",
    lng: "-2.911599",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMCM",
    portName: "Macmerry",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBMCN",
    portName: "Machen",
    portContinent: "Europe",
  },
  {
    lat: "39.7904071",
    lng: "-75.2378013",
    country: "US",
    country_long: "United States",
    portCode: "GBMCO",
    portName: "Mickleton",
    portContinent: "Europe",
  },
  {
    lat: "52.56878099999999",
    lng: "-1.5290565",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMCR",
    portName: "Mancetter",
    portContinent: "Europe",
  },
  {
    lat: "43.09721740000001",
    lng: "-89.5042876",
    country: "US",
    country_long: "United States",
    portCode: "GBMD3",
    portName: "Middleton",
    portContinent: "Europe",
  },
  {
    lat: "55.5524166",
    lng: "-4.0923888",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMDD",
    portName: "Middlefield Law",
    portContinent: "Europe",
  },
  {
    lat: "51.270363",
    lng: "0.522699",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMDE",
    portName: "Maidstone, Kent",
    portContinent: "Europe",
  },
  {
    lat: "50.9868979",
    lng: "-0.737274",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMDH",
    portName: "Midhurst",
    portContinent: "Europe",
  },
  {
    lat: "53.4680055",
    lng: "-2.2168807",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMDI",
    portName: "Ardwick",
    portContinent: "Europe",
  },
  {
    lat: "54.285318",
    lng: "-1.8056301",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMDL",
    portName: "Middleham",
    portContinent: "Europe",
  },
  {
    lat: "43.09721740000001",
    lng: "-89.5042876",
    country: "US",
    country_long: "United States",
    portCode: "GBMDN",
    portName: "Middleton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBMDO",
    portName: "Milden",
    portContinent: "Europe",
  },
  {
    lat: "52.6795904",
    lng: "-0.3216218",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMDP",
    portName: "Market Deeping",
    portContinent: "Europe",
  },
  {
    lat: "51.1485524",
    lng: "-1.2666839",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMDS",
    portName: "Micheldever",
    portContinent: "Europe",
  },
  {
    lat: "51.270363",
    lng: "0.522699",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMDT",
    portName: "Maidstone",
    portContinent: "Europe",
  },
  {
    lat: "51.5299092",
    lng: "-0.1860307",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMDV",
    portName: "Maida Vale/London",
    portContinent: "Europe",
  },
  {
    lat: "53.19256499999999",
    lng: "-2.443833",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMDW",
    portName: "Middlewich",
    portContinent: "Europe",
  },
  {
    lat: "52.0454665",
    lng: "-2.8497994",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMDX",
    portName: "Madley",
    portContinent: "Europe",
  },
  {
    lat: "52.636284",
    lng: "-2.4536222",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMDY",
    portName: "Madeley",
    portContinent: "Europe",
  },
  {
    lat: "40.572603",
    lng: "-74.4926541",
    country: "US",
    country_long: "United States",
    portCode: "GBMDZ",
    portName: "Middlesex",
    portContinent: "Europe",
  },
  {
    lat: "51.259521",
    lng: "-0.15567",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBME5",
    portName: "Merstham",
    portContinent: "Europe",
  },
  {
    lat: "52.484755",
    lng: "0.180177",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMEA",
    portName: "Manea",
    portContinent: "Europe",
  },
  {
    lat: "53.222607",
    lng: "-4.165577",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMEB",
    portName: "MenaiBridge",
    portContinent: "Europe",
  },
  {
    lat: "52.857864",
    lng: "1.038239",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMEC",
    portName: "Melton Constable, Norfolk",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBMED",
    portName: "Med",
    portContinent: "Europe",
  },
  {
    lat: "51.60856200000001",
    lng: "-3.660466",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMEE",
    portName: "Maesteg",
    portContinent: "Europe",
  },
  {
    lat: "36.4540178",
    lng: "-94.1914844",
    country: "US",
    country_long: "United States",
    portCode: "GBMEF",
    portName: "Metfield",
    portContinent: "Europe",
  },
  {
    lat: "50.912859",
    lng: "-2.7959109",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMEI",
    portName: "Merriott",
    portContinent: "Europe",
  },
  {
    lat: "51.370447",
    lng: "-2.137629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMEK",
    portName: "Melksham, Wiltshire",
    portContinent: "Europe",
  },
  {
    lat: "52.083869",
    lng: "0.016009",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMEL",
    portName: "Melbourn",
    portContinent: "Europe",
  },
  {
    lat: "54.731336",
    lng: "-2.5994201",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMEM",
    portName: "Melmerby",
    portContinent: "Europe",
  },
  {
    lat: "52.249163",
    lng: "1.07976",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMEN",
    portName: "Mendlesham",
    portContinent: "Europe",
  },
  {
    lat: "43.1466739",
    lng: "-88.3067614",
    country: "US",
    country_long: "United States",
    portCode: "GBMEO",
    portName: "Merton",
    portContinent: "Europe",
  },
  {
    lat: "43.1466739",
    lng: "-88.3067614",
    country: "US",
    country_long: "United States",
    portCode: "GBMER",
    portName: "Merton",
    portContinent: "Europe",
  },
  {
    lat: "51.128381",
    lng: "-1.060312",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMES",
    portName: "Medstead",
    portContinent: "Europe",
  },
  {
    lat: "51.74872999999999",
    lng: "-3.381646",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMET",
    portName: "Merthyr Tydfil",
    portContinent: "Europe",
  },
  {
    lat: "53.493099",
    lng: "-1.280452",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMEX",
    portName: "Mexborough, South Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "51.676466",
    lng: "-3.483457",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMEY",
    portName: "Maerdy",
    portContinent: "Europe",
  },
  {
    lat: "32.5631924",
    lng: "-97.1416768",
    country: "US",
    country_long: "United States",
    portCode: "GBMFD",
    portName: "Mansfield",
    portContinent: "Europe",
  },
  {
    lat: "52.689224",
    lng: "-1.277134",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMFL",
    portName: "Markfield",
    portContinent: "Europe",
  },
  {
    lat: "52.934569",
    lng: "-4.555834",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMFN",
    portName: "MorfaNefyn",
    portContinent: "Europe",
  },
  {
    lat: "53.352276",
    lng: "-4.237343",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMFR",
    portName: "Moelfre",
    portContinent: "Europe",
  },
  {
    lat: "50.725731",
    lng: "-1.5897619",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMFS",
    portName: "Milford-on-Sea",
    portContinent: "Europe",
  },
  {
    lat: "54.75537300000001",
    lng: "-6.6079805",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMFT",
    portName: "Magherafelt",
    portContinent: "Europe",
  },
  {
    lat: "57.858269",
    lng: "-4.143667",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMFY",
    portName: "MeikleFerry",
    portContinent: "Europe",
  },
  {
    lat: "51.9025804",
    lng: "-1.061146",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMGB",
    portName: "MarshGibbon",
    portContinent: "Europe",
  },
  {
    lat: "54.84453500000001",
    lng: "-6.6733816",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMGH",
    portName: "Maghera",
    portContinent: "Europe",
  },
  {
    lat: "53.5156922",
    lng: "-2.945368",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMGL",
    portName: "Maghull",
    portContinent: "Europe",
  },
  {
    lat: "51.5467364",
    lng: "-3.7011936",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMGM",
    portName: "Margam",
    portContinent: "Europe",
  },
  {
    lat: "54.9399899",
    lng: "-7.379460099999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMGN",
    portName: "Magheramason",
    portContinent: "Europe",
  },
  {
    lat: "54.81499",
    lng: "-5.77452",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMGO",
    portName: "Magheramorne",
    portContinent: "Europe",
  },
  {
    lat: "55.187134",
    lng: "-6.957675999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMGP",
    portName: "MagilliganPointFerryTerminal,LoughFoyle",
    portContinent: "Europe",
  },
  {
    lat: "51.756977",
    lng: "0.185144",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMGR",
    portName: "MagdalenLaver",
    portContinent: "Europe",
  },
  {
    lat: "26.2445263",
    lng: "-80.206436",
    country: "US",
    country_long: "United States",
    portCode: "GBMGT",
    portName: "Margate",
    portContinent: "Europe",
  },
  {
    lat: "54.84453500000001",
    lng: "-6.6733816",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMHA",
    portName: "Maghera",
    portContinent: "Europe",
  },
  {
    lat: "51.20428",
    lng: "-3.481152",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMHD",
    portName: "Minehead",
    portContinent: "Europe",
  },
  {
    lat: "53.290322",
    lng: "-3.764077",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMHE",
    portName: "Mochdre",
    portContinent: "Europe",
  },
  {
    lat: "52.475769",
    lng: "-0.9215169999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMHH",
    portName: "Market Harborough, Leicestershire",
    portContinent: "Europe",
  },
  {
    lat: "51.726715",
    lng: "-3.0075759",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMHI",
    portName: "Mamhilad",
    portContinent: "Europe",
  },
  {
    lat: "52.0171769",
    lng: "-0.339999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMHJ",
    portName: "Meppershall",
    portContinent: "Europe",
  },
  {
    lat: "40.2170376",
    lng: "-74.75930900000002",
    country: "US",
    country_long: "United States",
    portCode: "GBMHL",
    portName: "MillHill",
    portContinent: "Europe",
  },
  {
    lat: "52.073269",
    lng: "1.280565",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMHM",
    portName: "Martlesham",
    portContinent: "Europe",
  },
  {
    lat: "53.7023861",
    lng: "-2.802705",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMHO",
    portName: "MuchHoole",
    portContinent: "Europe",
  },
  {
    lat: "52.06197299999999",
    lng: "1.27046",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMHT",
    portName: "MartleshamHeath",
    portContinent: "Europe",
  },
  {
    lat: "57.549071",
    lng: "-4.262589999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMHY",
    portName: "Munlochy",
    portContinent: "Europe",
  },
  {
    lat: "54.574227",
    lng: "-1.234956",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMID",
    portName: "Middlesbrough",
    portContinent: "Europe",
  },
  {
    lat: "53.545921",
    lng: "-2.2019479",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMIE",
    portName: "Middleton, Greater Manchester",
    portContinent: "Europe",
  },
  {
    lat: "51.20428",
    lng: "-3.481152",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMIH",
    portName: "Minehead, Somerset",
    portContinent: "Europe",
  },
  {
    lat: "52.0406224",
    lng: "-0.7594171",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMIK",
    portName: "Milton Keynes",
    portContinent: "Europe",
  },
  {
    lat: "42.2495321",
    lng: "-71.0661653",
    country: "US",
    country_long: "United States",
    portCode: "GBMIL",
    portName: "Milton",
    portContinent: "Europe",
  },
  {
    lat: "51.9914181",
    lng: "-1.7028526",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMIM",
    portName: "Moreton-In-Marsh",
    portContinent: "Europe",
  },
  {
    lat: "52.527793",
    lng: "-1.772577",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMIN",
    portName: "Minworth",
    portContinent: "Europe",
  },
  {
    lat: "51.628284",
    lng: "-1.296668",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMIO",
    portName: "Milton, Abingdon",
    portContinent: "Europe",
  },
  {
    lat: "54.226867",
    lng: "-2.773161",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMIP",
    portName: "Milnthorpe, Cumbria",
    portContinent: "Europe",
  },
  {
    lat: "53.680122",
    lng: "-1.696713",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMIR",
    portName: "Mirfield",
    portContinent: "Europe",
  },
  {
    lat: "51.94562500000001",
    lng: "1.0773489",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMIS",
    portName: "Mistley (GBMIS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.862693",
    lng: "-2.489453",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMIT",
    portName: "Mitcheldean",
    portContinent: "Europe",
  },
  {
    lat: "53.12762499999999",
    lng: "-1.559366",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMKB",
    portName: "MatlockBath",
    portContinent: "Europe",
  },
  {
    lat: "52.903552",
    lng: "-2.483485",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMKD",
    portName: "Market Drayton",
    portContinent: "Europe",
  },
  {
    lat: "52.21289489999999",
    lng: "1.1251511",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMKE",
    portName: "Mickfield",
    portContinent: "Europe",
  },
  {
    lat: "39.1753377",
    lng: "-84.29438209999999",
    country: "US",
    country_long: "United States",
    portCode: "GBMKF",
    portName: "Milford",
    portContinent: "Europe",
  },
  {
    lat: "52.475769",
    lng: "-0.9215169999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMKH",
    portName: "Market Harborough",
    portContinent: "Europe",
  },
  {
    lat: "55.523464",
    lng: "-4.066396999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMKI",
    portName: "Muirkirk",
    portContinent: "Europe",
  },
  {
    lat: "54.2969635",
    lng: "-6.5217383",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMKL",
    portName: "MarketHill",
    portContinent: "Europe",
  },
  {
    lat: "39.5781625",
    lng: "-76.6145058",
    country: "US",
    country_long: "United States",
    portCode: "GBMKO",
    portName: "Monkton",
    portContinent: "Europe",
  },
  {
    lat: "53.387762",
    lng: "-0.333285",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMKR",
    portName: "Market Rasen",
    portContinent: "Europe",
  },
  {
    lat: "51.878823",
    lng: "0.793001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMKS",
    portName: "MarksTey",
    portContinent: "Europe",
  },
  {
    lat: "53.219016",
    lng: "-2.835151",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMKT",
    portName: "MickleTrafford",
    portContinent: "Europe",
  },
  {
    lat: "54.591928",
    lng: "-1.019708",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMKY",
    portName: "Marske-by-the-Sea",
    portContinent: "Europe",
  },
  {
    lat: "53.59235899999999",
    lng: "-1.851332",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLA",
    portName: "Meltham",
    portContinent: "Europe",
  },
  {
    lat: "41.7850937",
    lng: "-73.6940175",
    country: "US",
    country_long: "United States",
    portCode: "GBMLB",
    portName: "Millbrook",
    portContinent: "Europe",
  },
  {
    lat: "55.965089",
    lng: "-4.161484",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLC",
    portName: "MiltonofCampsie",
    portContinent: "Europe",
  },
  {
    lat: "52.099618",
    lng: "0.006113",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLD",
    portName: "Meldreth",
    portContinent: "Europe",
  },
  {
    lat: "52.238319",
    lng: "-2.3654951",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLE",
    portName: "Martley",
    portContinent: "Europe",
  },
  {
    lat: "51.714306",
    lng: "-5.042697",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLF",
    portName: "Milford Haven",
    portContinent: "Europe",
  },
  {
    lat: "57.00381300000001",
    lng: "-5.827173",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLG",
    portName: "Mallaig (GBMLG), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.370447",
    lng: "-2.137629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLH",
    portName: "Melksham",
    portContinent: "Europe",
  },
  {
    lat: "50.027991",
    lng: "-5.240468",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLI",
    portName: "Mullion",
    portContinent: "Europe",
  },
  {
    lat: "53.393676",
    lng: "-2.015536",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLJ",
    portName: "Mellor",
    portContinent: "Europe",
  },
  {
    lat: "54.67978919999999",
    lng: "-6.0040492",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLK",
    portName: "Mallusk/Newtownabbey",
    portContinent: "Europe",
  },
  {
    lat: "54.67978919999999",
    lng: "-6.0040492",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLKÂ",
    portName: "Mallusk",
    portContinent: "Europe",
  },
  {
    lat: "52.347568",
    lng: "0.5156094999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLL",
    portName: "Mildenhall",
    portContinent: "Europe",
  },
  {
    lat: "54.2106147",
    lng: "-3.2718854",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLM",
    portName: "Millom",
    portContinent: "Europe",
  },
  {
    lat: "54.136836",
    lng: "-0.7978970000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLN",
    portName: "Malton",
    portContinent: "Europe",
  },
  {
    lat: "53.61071099999999",
    lng: "-2.11657",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLO",
    portName: "Milnrow",
    portContinent: "Europe",
  },
  {
    lat: "55.7535865",
    lng: "-4.9286167",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLP",
    portName: "Millport,GreatCumbrae",
    portContinent: "Europe",
  },
  {
    lat: "55.598676",
    lng: "-2.72191",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLR",
    portName: "Melrose",
    portContinent: "Europe",
  },
  {
    lat: "52.3717901",
    lng: "-0.4258441",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLS",
    portName: "Molesworth",
    portContinent: "Europe",
  },
  {
    lat: "56.22665",
    lng: "-3.421039",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLT",
    portName: "Milnathort",
    portContinent: "Europe",
  },
  {
    lat: "-37.8136276",
    lng: "144.9630576",
    country: "AU",
    country_long: "Australia",
    portCode: "GBMLU",
    portName: "Melbourne",
    portContinent: "Europe",
  },
  {
    lat: "55.943304",
    lng: "-4.316987",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMLV",
    portName: "Milngavie",
    portContinent: "Europe",
  },
  {
    lat: "34.6481318",
    lng: "-97.9580933",
    country: "US",
    country_long: "United States",
    portCode: "GBMLW",
    portName: "Marlow",
    portContinent: "Europe",
  },
  {
    lat: "47.8050984",
    lng: "-122.1131816",
    country: "US",
    country_long: "United States",
    portCode: "GBMLY",
    portName: "Maltby",
    portContinent: "Europe",
  },
  {
    lat: "53.302051",
    lng: "-2.19328",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMMA",
    portName: "MottramSaintAndrew",
    portContinent: "Europe",
  },
  {
    lat: "51.586357",
    lng: "-2.102834",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMMB",
    portName: "Malmesbury",
    portContinent: "Europe",
  },
  {
    lat: "52.0625663",
    lng: "-0.5488514999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMMC",
    portName: "MarstonMoretaine",
    portContinent: "Europe",
  },
  {
    lat: "51.50575809999999",
    lng: "0.1100586",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMMD",
    portName: "Thamesmead",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBMME",
    portName: "Teesside",
    portContinent: "Europe",
  },
  {
    lat: "50.9995369",
    lng: "-2.5837979",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMMG",
    portName: "MarstonMagna",
    portContinent: "Europe",
  },
  {
    lat: "51.5521902",
    lng: "-0.8401274",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMMH",
    portName: "Medmenham",
    portContinent: "Europe",
  },
  {
    lat: "57.25279699999999",
    lng: "-2.05753",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMMI",
    portName: "Balmedie",
    portContinent: "Europe",
  },
  {
    lat: "54.731336",
    lng: "-2.5994201",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMML",
    portName: "Melmerby",
    portContinent: "Europe",
  },
  {
    lat: "40.2802758",
    lng: "-74.0054114",
    country: "US",
    country_long: "United States",
    portCode: "GBMMO",
    portName: "Monmouth",
    portContinent: "Europe",
  },
  {
    lat: "51.992766",
    lng: "-2.498764",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMMQ",
    portName: "MuchMarcle",
    portContinent: "Europe",
  },
  {
    lat: "56.091084",
    lng: "-3.310554",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMMR",
    portName: "Mossmoran",
    portContinent: "Europe",
  },
  {
    lat: "52.56279",
    lng: "-3.149331",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMMY",
    portName: "MontgomeryTrefaldwyn",
    portContinent: "Europe",
  },
  {
    lat: "52.482462",
    lng: "-1.9010755",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMN8",
    portName: "Marston",
    portContinent: "Europe",
  },
  {
    lat: "51.704417",
    lng: "-2.187054",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMNA",
    portName: "Minchinhampton",
    portContinent: "Europe",
  },
  {
    lat: "53.4807593",
    lng: "-2.2426305",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMNC",
    portName: "Manchester",
    portContinent: "Europe",
  },
  {
    lat: "51.6924849",
    lng: "0.700109",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMND",
    portName: "Mundon",
    portContinent: "Europe",
  },
  {
    lat: "50.949438",
    lng: "-2.7131408",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMNE",
    portName: "Montacute",
    portContinent: "Europe",
  },
  {
    lat: "53.147195",
    lng: "-1.198674",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMNF",
    portName: "Mansfield, Nottinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "52.590273",
    lng: "-3.853485",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMNL",
    portName: "Machynlleth",
    portContinent: "Europe",
  },
  {
    lat: "51.945407",
    lng: "1.062086",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMNN",
    portName: "Manningtree",
    portContinent: "Europe",
  },
  {
    lat: "39.5781625",
    lng: "-76.6145058",
    country: "US",
    country_long: "United States",
    portCode: "GBMNO",
    portName: "Monkton",
    portContinent: "Europe",
  },
  {
    lat: "54.226867",
    lng: "-2.773161",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMNP",
    portName: "Milnthorpe",
    portContinent: "Europe",
  },
  {
    lat: "58.9457425",
    lng: "-3.2718805",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMNS",
    portName: "Moaness (GBMNS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.857864",
    lng: "1.038239",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMNT",
    portName: "MeltonConstable",
    portContinent: "Europe",
  },
  {
    lat: "52.106399",
    lng: "-2.722881",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMNU",
    portName: "MoretononLugg",
    portContinent: "Europe",
  },
  {
    lat: "57.524395",
    lng: "-2.001277",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMNW",
    portName: "Mintlaw",
    portContinent: "Europe",
  },
  {
    lat: "53.359614",
    lng: "0.099465",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMNY",
    portName: "Manby",
    portContinent: "Europe",
  },
  {
    lat: "54.4797358",
    lng: "-6.228553",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMOA",
    portName: "Moira",
    portContinent: "Europe",
  },
  {
    lat: "53.31283699999999",
    lng: "-2.318855",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMOB",
    portName: "Mobberley",
    portContinent: "Europe",
  },
  {
    lat: "54.074166",
    lng: "-2.864968",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMOC",
    portName: "Morecambe",
    portContinent: "Europe",
  },
  {
    lat: "49.192329",
    lng: "-98.0977324",
    country: "CA",
    country_long: "Canada",
    portCode: "GBMOD",
    portName: "Morden",
    portContinent: "Europe",
  },
  {
    lat: "52.068742",
    lng: "-0.673373",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMOE",
    portName: "Moulsoe",
    portContinent: "Europe",
  },
  {
    lat: "55.33520799999999",
    lng: "-3.440337",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMOF",
    portName: "Moffat",
    portContinent: "Europe",
  },
  {
    lat: "51.5170923",
    lng: "-0.0887609",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMOG",
    portName: "Moorgate",
    portContinent: "Europe",
  },
  {
    lat: "51.8116533",
    lng: "-2.7163045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMOH",
    portName: "Monmouth, Monmouthshire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBMOL",
    portName: "Mold",
    portContinent: "Europe",
  },
  {
    lat: "38.4783198",
    lng: "-107.8761738",
    country: "US",
    country_long: "United States",
    portCode: "GBMON",
    portName: "Montrose",
    portContinent: "Europe",
  },
  {
    lat: "57.518417",
    lng: "-4.461075",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMOO",
    portName: "Muir of Ord",
    portContinent: "Europe",
  },
  {
    lat: "54.074166",
    lng: "-2.864968",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMOP",
    portName: "Morecambe, Lancashire",
    portContinent: "Europe",
  },
  {
    lat: "55.16875",
    lng: "-1.687493",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMOR",
    portName: "Morpeth",
    portContinent: "Europe",
  },
  {
    lat: "53.516043",
    lng: "-2.1780497",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMOS",
    portName: "Moston",
    portContinent: "Europe",
  },
  {
    lat: "-27.0946084",
    lng: "152.9205918",
    country: "AU",
    country_long: "Australia",
    portCode: "GBMOT",
    portName: "Moreton",
    portContinent: "Europe",
  },
  {
    lat: "34.4812062",
    lng: "-87.29335329999999",
    country: "US",
    country_long: "United States",
    portCode: "GBMOU",
    portName: "Moulton",
    portContinent: "Europe",
  },
  {
    lat: "52.738772",
    lng: "-0.6849029999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMOV",
    portName: "MarketOverton",
    portContinent: "Europe",
  },
  {
    lat: "55.78320919999999",
    lng: "-3.9810968",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMOW",
    portName: "Motherwell",
    portContinent: "Europe",
  },
  {
    lat: "-9.443800399999999",
    lng: "147.1802671",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "GBMOY",
    portName: "Moresby",
    portContinent: "Europe",
  },
  {
    lat: "53.397349",
    lng: "-2.061683",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMPE",
    portName: "Marple, Greater Manchester",
    portContinent: "Europe",
  },
  {
    lat: "53.49239499999999",
    lng: "-2.2139287",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMPG",
    portName: "MilesPlatting",
    portContinent: "Europe",
  },
  {
    lat: "52.13627289999999",
    lng: "-1.160095",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMPI",
    portName: "MoretonPinkney",
    portContinent: "Europe",
  },
  {
    lat: "51.370723",
    lng: "0.3604859",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMPM",
    portName: "Meopham",
    portContinent: "Europe",
  },
  {
    lat: "43.7315479",
    lng: "-79.7624177",
    country: "CA",
    country_long: "Canada",
    portCode: "GBMPO",
    portName: "Brampton",
    portContinent: "Europe",
  },
  {
    lat: "51.723453",
    lng: "-1.549105",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMPT",
    portName: "Bampton",
    portContinent: "Europe",
  },
  {
    lat: "54.4797358",
    lng: "-6.228553",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMQR",
    portName: "Moira",
    portContinent: "Europe",
  },
  {
    lat: "60.1578674",
    lng: "-1.1204545",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRB",
    portName: "Maryfield,Bressay",
    portContinent: "Europe",
  },
  {
    lat: "52.8742929",
    lng: "-1.8035555",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRC",
    portName: "Marchington",
    portContinent: "Europe",
  },
  {
    lat: "51.173613",
    lng: "0.490709",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRD",
    portName: "Marden",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBMRE",
    portName: "Mere",
    portContinent: "Europe",
  },
  {
    lat: "53.7518691",
    lng: "-0.268988",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRF",
    portName: "Marfleet",
    portContinent: "Europe",
  },
  {
    lat: "52.466561",
    lng: "-1.744538",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRG",
    portName: "MarstonGreen",
    portContinent: "Europe",
  },
  {
    lat: "54.2969635",
    lng: "-6.5217383",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRH",
    portName: "Markethill",
    portContinent: "Europe",
  },
  {
    lat: "52.91449859999999",
    lng: "-4.0973316",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRI",
    portName: "Portmeirion",
    portContinent: "Europe",
  },
  {
    lat: "56.203595",
    lng: "-3.1317689",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRK",
    portName: "Markinch",
    portContinent: "Europe",
  },
  {
    lat: "42.3459271",
    lng: "-71.5522874",
    country: "US",
    country_long: "United States",
    portCode: "GBMRL",
    portName: "Marlborough",
    portContinent: "Europe",
  },
  {
    lat: "52.70538699999999",
    lng: "1.628141",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRM",
    portName: "Martham",
    portContinent: "Europe",
  },
  {
    lat: "52.482462",
    lng: "-1.9010755",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRN",
    portName: "Marston",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBMRO",
    portName: "Morton",
    portContinent: "Europe",
  },
  {
    lat: "54.714441",
    lng: "-3.49494",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRP",
    portName: "Maryport, Cumbria",
    portContinent: "Europe",
  },
  {
    lat: "41.5381535",
    lng: "-72.80704349999999",
    country: "US",
    country_long: "United States",
    portCode: "GBMRQ",
    portName: "Meriden",
    portContinent: "Europe",
  },
  {
    lat: "53.352276",
    lng: "-4.237343",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRR",
    portName: "Moelfre Roads",
    portContinent: "Europe",
  },
  {
    lat: "52.954244",
    lng: "0.9868331",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRS",
    portName: "Morston",
    portContinent: "Europe",
  },
  {
    lat: "-27.0946084",
    lng: "152.9205918",
    country: "AU",
    country_long: "Australia",
    portCode: "GBMRT",
    portName: "Moreton",
    portContinent: "Europe",
  },
  {
    lat: "33.5831662",
    lng: "-84.33936829999999",
    country: "US",
    country_long: "United States",
    portCode: "GBMRW",
    portName: "MoorRow",
    portContinent: "Europe",
  },
  {
    lat: "54.714441",
    lng: "-3.49494",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRY",
    portName: "Maryport",
    portContinent: "Europe",
  },
  {
    lat: "50.125077",
    lng: "-5.463954999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMRZ",
    portName: "Marazion",
    portContinent: "Europe",
  },
  {
    lat: "52.710459",
    lng: "-1.502566",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSA",
    portName: "Measham",
    portContinent: "Europe",
  },
  {
    lat: "49.9388405",
    lng: "-105.9644355",
    country: "CA",
    country_long: "Canada",
    portCode: "GBMSB",
    portName: "Mossbank",
    portContinent: "Europe",
  },
  {
    lat: "53.48752349999999",
    lng: "-2.2901264",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSC",
    portName: "ManchesterShipCanal,Salford",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBMSD",
    portName: "MaitresseIsland",
    portContinent: "Europe",
  },
  {
    lat: "51.345814",
    lng: "1.37035",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSE",
    portName: "Manston",
    portContinent: "Europe",
  },
  {
    lat: "53.5275869",
    lng: "-0.6511979999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSG",
    portName: "Messingham",
    portContinent: "Europe",
  },
  {
    lat: "50.084245",
    lng: "-5.539061999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSH",
    portName: "Mousehole",
    portContinent: "Europe",
  },
  {
    lat: "52.7734551",
    lng: "0.6642098",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSI",
    portName: "GreatMassingham",
    portContinent: "Europe",
  },
  {
    lat: "52.66145179999999",
    lng: "0.2473181",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSJ",
    portName: "MarshlandSaintJames",
    portContinent: "Europe",
  },
  {
    lat: "53.4071116",
    lng: "-0.60054",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSL",
    portName: "Hemswell",
    portContinent: "Europe",
  },
  {
    lat: "51.285199",
    lng: "-2.485936",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSM",
    portName: "Midsomer Norton",
    portContinent: "Europe",
  },
  {
    lat: "53.599752",
    lng: "-1.930075",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSN",
    portName: "Marsden",
    portContinent: "Europe",
  },
  {
    lat: "52.17453399999999",
    lng: "1.401269",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSO",
    portName: "Marlesford",
    portContinent: "Europe",
  },
  {
    lat: "56.15111599999999",
    lng: "-3.854673999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSR",
    portName: "Menstrie",
    portContinent: "Europe",
  },
  {
    lat: "53.37723099999999",
    lng: "-2.912298",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSS",
    portName: "MossleyHill",
    portContinent: "Europe",
  },
  {
    lat: "53.446531",
    lng: "-0.8482919999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMST",
    portName: "Misterton",
    portContinent: "Europe",
  },
  {
    lat: "51.852582",
    lng: "-2.324798",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSW",
    portName: "Minsterworth",
    portContinent: "Europe",
  },
  {
    lat: "36.7417235",
    lng: "-88.6367154",
    country: "US",
    country_long: "United States",
    portCode: "GBMSX",
    portName: "Mayfield",
    portContinent: "Europe",
  },
  {
    lat: "53.97649000000001",
    lng: "-1.984725",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMSY",
    portName: "Embsay",
    portContinent: "Europe",
  },
  {
    lat: "51.6872829",
    lng: "-3.3915433",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTA",
    portName: "MountainAsh(Aberpennar)",
    portContinent: "Europe",
  },
  {
    lat: "57.104266",
    lng: "-2.23433",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTB",
    portName: "Milltimber",
    portContinent: "Europe",
  },
  {
    lat: "51.402869",
    lng: "-0.166709",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTC",
    portName: "Mitcham",
    portContinent: "Europe",
  },
  {
    lat: "51.024859",
    lng: "-1.272683",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTD",
    portName: "Morestead",
    portContinent: "Europe",
  },
  {
    lat: "51.7772499",
    lng: "0.228374",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTG",
    portName: "MatchingGreen",
    portContinent: "Europe",
  },
  {
    lat: "56.184843",
    lng: "-3.0164019",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTH",
    portName: "Methil",
    portContinent: "Europe",
  },
  {
    lat: "53.846123",
    lng: "-2.442151",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTI",
    portName: "Mitton",
    portContinent: "Europe",
  },
  {
    lat: "57.425211",
    lng: "-2.241669",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTK",
    portName: "Methlick",
    portContinent: "Europe",
  },
  {
    lat: "53.137156",
    lng: "-1.551774",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTL",
    portName: "Matlock",
    portContinent: "Europe",
  },
  {
    lat: "51.3770932",
    lng: "-1.0629894",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTM",
    portName: "MortimerCommon",
    portContinent: "Europe",
  },
  {
    lat: "56.694026",
    lng: "-2.517451",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTN",
    portName: "Maryton",
    portContinent: "Europe",
  },
  {
    lat: "-37.6835831",
    lng: "144.5779508",
    country: "AU",
    country_long: "Australia",
    portCode: "GBMTO",
    portName: "Melton",
    portContinent: "Europe",
  },
  {
    lat: "53.34088200000001",
    lng: "0.261071",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTP",
    portName: "Mablethorpe",
    portContinent: "Europe",
  },
  {
    lat: "32.3792233",
    lng: "-86.3077368",
    country: "US",
    country_long: "United States",
    portCode: "GBMTR",
    portName: "Montgomery",
    portContinent: "Europe",
  },
  {
    lat: "52.482462",
    lng: "-1.9010755",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTS",
    portName: "Marston",
    portContinent: "Europe",
  },
  {
    lat: "52.465656",
    lng: "-0.9838220000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTT",
    portName: "MarstonTrussell",
    portContinent: "Europe",
  },
  {
    lat: "-43.6324759",
    lng: "171.6471548",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBMTV",
    portName: "Methven",
    portContinent: "Europe",
  },
  {
    lat: "53.8437771",
    lng: "-1.5376533",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTW",
    portName: "Moortown",
    portContinent: "Europe",
  },
  {
    lat: "53.396717",
    lng: "-0.964872",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTY",
    portName: "Mattersey",
    portContinent: "Europe",
  },
  {
    lat: "51.4687363",
    lng: "-0.2627417",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMTZ",
    portName: "Mortlake",
    portContinent: "Europe",
  },
  {
    lat: "55.599765",
    lng: "-5.684601",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMUA",
    portName: "Muasdale",
    portContinent: "Europe",
  },
  {
    lat: "52.0307869",
    lng: "-0.4695991",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMUD",
    portName: "Maulden",
    portContinent: "Europe",
  },
  {
    lat: "52.5971339",
    lng: "-2.5592959",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMUH",
    portName: "Much Wenlock, Shropshire",
    portContinent: "Europe",
  },
  {
    lat: "54.70102",
    lng: "-6.18411",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMUK",
    portName: "Muckamore",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBMUL",
    portName: "MullApt",
    portContinent: "Europe",
  },
  {
    lat: "55.941941",
    lng: "-3.053918",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMUS",
    portName: "Musselburgh",
    portContinent: "Europe",
  },
  {
    lat: "56.3307849",
    lng: "-3.8328891",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMUT",
    portName: "Muthill",
    portContinent: "Europe",
  },
  {
    lat: "51.78779600000001",
    lng: "-2.318363",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMVC",
    portName: "MoretonValence",
    portContinent: "Europe",
  },
  {
    lat: "50.2702259",
    lng: "-4.787523",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMVG",
    portName: "Mevagissey (GBMVG), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.798368",
    lng: "-1.538407",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMVL",
    portName: "MinsterLovell",
    portContinent: "Europe",
  },
  {
    lat: "52.077468",
    lng: "-2.332173",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMVW",
    portName: "MalvernWells",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBMWA",
    portName: "Millwall",
    portContinent: "Europe",
  },
  {
    lat: "52.52525800000001",
    lng: "0.5543779999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMWD",
    portName: "Methwold",
    portContinent: "Europe",
  },
  {
    lat: "53.8648392",
    lng: "-0.6677465",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMWE",
    portName: "MarketWeighton",
    portContinent: "Europe",
  },
  {
    lat: "50.081066",
    lng: "-5.203021",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMWG",
    portName: "Mawgan",
    portContinent: "Europe",
  },
  {
    lat: "51.258461",
    lng: "0.380712",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMWH",
    portName: "Mereworth",
    portContinent: "Europe",
  },
  {
    lat: "53.021057",
    lng: "-2.957083",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMWI",
    portName: "Marchwiel",
    portContinent: "Europe",
  },
  {
    lat: "52.5971339",
    lng: "-2.5592959",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMWK",
    portName: "MuchWenlock",
    portContinent: "Europe",
  },
  {
    lat: "53.8648392",
    lng: "-0.6677465",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMWN",
    portName: "Market Weighton, East Riding Of Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "53.166472",
    lng: "-1.189765",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMWO",
    portName: "MansfieldWoodhouse",
    portContinent: "Europe",
  },
  {
    lat: "53.8655869",
    lng: "-2.8199879",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMWY",
    portName: "SaintMichaelsOnWyre",
    portContinent: "Europe",
  },
  {
    lat: "53.493099",
    lng: "-1.280452",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMXB",
    portName: "Mexborough",
    portContinent: "Europe",
  },
  {
    lat: "53.744513",
    lng: "-1.598045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMXK",
    portName: "Morley",
    portContinent: "Europe",
  },
  {
    lat: "35.3395079",
    lng: "-97.48670279999999",
    country: "US",
    country_long: "United States",
    portCode: "GBMXO",
    portName: "Moore",
    portContinent: "Europe",
  },
  {
    lat: "55.35302",
    lng: "-4.679055",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMYB",
    portName: "Maybole",
    portContinent: "Europe",
  },
  {
    lat: "53.3976041",
    lng: "-2.9436643",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMYD",
    portName: "Merseyside",
    portContinent: "Europe",
  },
  {
    lat: "60.59990699999999",
    lng: "-1.068302",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMYE",
    portName: "Mid Yell (GBMYE), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5116269",
    lng: "-0.147806",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMYF",
    portName: "Mayfair",
    portContinent: "Europe",
  },
  {
    lat: "54.48282",
    lng: "-6.75299",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMYG",
    portName: "Moygashel",
    portContinent: "Europe",
  },
  {
    lat: "50.177118",
    lng: "-5.058071",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMYL",
    portName: "Mylor (GBMYL), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.0293001",
    lng: "-7.2442099",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMYN",
    portName: "Maydown",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBMYO",
    portName: "Moy",
    portContinent: "Europe",
  },
  {
    lat: "51.6876226",
    lng: "0.7560136",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMYS",
    portName: "Maylandsea",
    portContinent: "Europe",
  },
  {
    lat: "51.6876226",
    lng: "0.7560136",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMYSÂ",
    portName: "MaylandEssex",
    portContinent: "Europe",
  },
  {
    lat: "53.7309278",
    lng: "-1.9837091",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMYT",
    portName: "Mytholmroyd",
    portContinent: "Europe",
  },
  {
    lat: "51.64218",
    lng: "-3.223086",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMYW",
    portName: "Maesycwmmer",
    portContinent: "Europe",
  },
  {
    lat: "54.30507859999999",
    lng: "-1.5599204",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMZB",
    portName: "LeemingBar",
    portContinent: "Europe",
  },
  {
    lat: "53.397349",
    lng: "-2.061683",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBMZL",
    portName: "Marple",
    portContinent: "Europe",
  },
  {
    lat: "52.5735743",
    lng: "-1.208212",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNAB",
    portName: "Narborough, Leicestershire",
    portContinent: "Europe",
  },
  {
    lat: "52.0168305",
    lng: "1.2347831",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNAC",
    portName: "Nacton, Suffolk",
    portContinent: "Europe",
  },
  {
    lat: "51.0856236",
    lng: "-1.1655575",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNAD",
    portName: "Alresford",
    portContinent: "Europe",
  },
  {
    lat: "57.58642200000001",
    lng: "-3.868475",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNAI",
    portName: "Nairn",
    portContinent: "Europe",
  },
  {
    lat: "54.3377838",
    lng: "-1.4310027",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNAL",
    portName: "Northallerton",
    portContinent: "Europe",
  },
  {
    lat: "55.57716910000001",
    lng: "-5.150513699999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNAR",
    portName: "Brodick (GBNAR), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "-17.6741697",
    lng: "141.0740445",
    country: "AU",
    country_long: "Australia",
    portCode: "GBNAT",
    portName: "Normanton",
    portContinent: "Europe",
  },
  {
    lat: "54.25444599999999",
    lng: "-0.9882909999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNAW",
    portName: "Nawton",
    portContinent: "Europe",
  },
  {
    lat: "54.61598799999999",
    lng: "-1.575577",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNAY",
    portName: "Newton Aycliffe",
    portContinent: "Europe",
  },
  {
    lat: "51.740644",
    lng: "0.048348",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNAZ",
    portName: "Nazeing",
    portContinent: "Europe",
  },
  {
    lat: "50.52890499999999",
    lng: "-3.608359999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNBA",
    portName: "NewtonAbbot",
    portContinent: "Europe",
  },
  {
    lat: "55.1850534",
    lng: "-1.51225",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNBC",
    portName: "NewbigginbytheSea",
    portContinent: "Europe",
  },
  {
    lat: "52.5735743",
    lng: "-1.208212",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNBG",
    portName: "Narborough",
    portContinent: "Europe",
  },
  {
    lat: "40.0084456",
    lng: "-75.26046",
    country: "US",
    country_long: "United States",
    portCode: "GBNBH",
    portName: "Narberth",
    portContinent: "Europe",
  },
  {
    lat: "57.263644",
    lng: "-3.654089",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNBI",
    portName: "NethyBridge",
    portContinent: "Europe",
  },
  {
    lat: "55.2082542",
    lng: "-2.0784138",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNBL",
    portName: "Northumberland",
    portContinent: "Europe",
  },
  {
    lat: "50.842745",
    lng: "-0.883044",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNBN",
    portName: "Nutbourne",
    portContinent: "Europe",
  },
  {
    lat: "52.5735743",
    lng: "-1.208212",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNBR",
    portName: "Narborough",
    portContinent: "Europe",
  },
  {
    lat: "55.520638",
    lng: "-1.6294699",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNBS",
    portName: "NewtonbytheSea",
    portContinent: "Europe",
  },
  {
    lat: "51.649276",
    lng: "-0.176187",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNBT",
    portName: "NewBarnet",
    portContinent: "Europe",
  },
  {
    lat: "41.5034271",
    lng: "-74.0104178",
    country: "US",
    country_long: "United States",
    portCode: "GBNBU",
    portName: "Newburgh",
    portContinent: "Europe",
  },
  {
    lat: "56.058363",
    lng: "-2.719646",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNBW",
    portName: "NorthBerwick",
    portContinent: "Europe",
  },
  {
    lat: "42.7649497",
    lng: "-70.8714528",
    country: "US",
    country_long: "United States",
    portCode: "GBNBY",
    portName: "Newbury",
    portContinent: "Europe",
  },
  {
    lat: "51.0453169",
    lng: "-2.5220215",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNCB",
    portName: "NorthCadbury",
    portContinent: "Europe",
  },
  {
    lat: "53.013208",
    lng: "-2.2273002",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNCE",
    portName: "Newcastle-under-Lyme",
    portContinent: "Europe",
  },
  {
    lat: "54.978252",
    lng: "-1.61778",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNCL",
    portName: "Newcastle upon Tyne",
    portContinent: "Europe",
  },
  {
    lat: "52.669549",
    lng: "-1.979539",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNCN",
    portName: "NortonCanes",
    portContinent: "Europe",
  },
  {
    lat: "53.08760299999999",
    lng: "-2.205594",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNCP",
    portName: "NewChapel",
    portContinent: "Europe",
  },
  {
    lat: "54.21614",
    lng: "-5.8920445",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNCS",
    portName: "New Castle Northern Ireland",
    portContinent: "Europe",
  },
  {
    lat: "52.0168305",
    lng: "1.2347831",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNCT",
    portName: "Nacton",
    portContinent: "Europe",
  },
  {
    lat: "35.5626559",
    lng: "-97.6403478",
    country: "US",
    country_long: "United States",
    portCode: "GBNCU",
    portName: "NEWCHURCH",
    portContinent: "Europe",
  },
  {
    lat: "53.781902",
    lng: "-0.644551",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNCV",
    portName: "NorthCave",
    portContinent: "Europe",
  },
  {
    lat: "52.09240399999999",
    lng: "-0.652578",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNCW",
    portName: "NorthCrawley",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBNCX",
    portName: "NewCross",
    portContinent: "Europe",
  },
  {
    lat: "52.037442",
    lng: "-4.468711",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNCY",
    portName: "NewcastleEmlyn",
    portContinent: "Europe",
  },
  {
    lat: "51.5614009",
    lng: "-0.2540177",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNDE",
    portName: "Neasden",
    portContinent: "Europe",
  },
  {
    lat: "51.066525",
    lng: "0.5817559999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNDN",
    portName: "Benenden",
    portContinent: "Europe",
  },
  {
    lat: "52.2583971",
    lng: "-0.9586872000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNDS",
    portName: "NewDuston",
    portContinent: "Europe",
  },
  {
    lat: "59.2458295",
    lng: "-2.5492163",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNDY",
    portName: "Sanday",
    portContinent: "Europe",
  },
  {
    lat: "51.656991",
    lng: "-3.805476",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNEA",
    portName: "Neath",
    portContinent: "Europe",
  },
  {
    lat: "39.9312544",
    lng: "-75.1705897",
    country: "US",
    country_long: "United States",
    portCode: "GBNEB",
    portName: "Newbold",
    portContinent: "Europe",
  },
  {
    lat: "54.978252",
    lng: "-1.61778",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNEC",
    portName: "Newcastle",
    portContinent: "Europe",
  },
  {
    lat: "52.037442",
    lng: "-4.468711",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNEE",
    portName: "Newcastle Emlyn, Carmarthenshire",
    portContinent: "Europe",
  },
  {
    lat: "52.9356549",
    lng: "-4.520111",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNEF",
    portName: "Nefyn",
    portContinent: "Europe",
  },
  {
    lat: "55.865652",
    lng: "-3.066151",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNEG",
    portName: "NewtonGrange",
    portContinent: "Europe",
  },
  {
    lat: "53.608683",
    lng: "-0.7011379999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNEH",
    portName: "NeapHouse",
    portContinent: "Europe",
  },
  {
    lat: "53.3678916",
    lng: "-2.0047714",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNEM",
    portName: "New Mills, Derbyshire",
    portContinent: "Europe",
  },
  {
    lat: "57.066379",
    lng: "-4.121542",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNEN",
    portName: "Newtonmore",
    portContinent: "Europe",
  },
  {
    lat: "50.480655",
    lng: "-4.560243",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNEO",
    portName: "SaintNeot",
    portContinent: "Europe",
  },
  {
    lat: "41.4901024",
    lng: "-71.3128285",
    country: "US",
    country_long: "United States",
    portCode: "GBNEP",
    portName: "Newport",
    portContinent: "Europe",
  },
  {
    lat: "53.290584",
    lng: "-3.069102",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNES",
    portName: "Neston",
    portContinent: "Europe",
  },
  {
    lat: "52.962732",
    lng: "-1.074676",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNET",
    portName: "Netherfield, Nottinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "52.02347",
    lng: "-4.795869",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNEV",
    portName: "Nevern",
    portContinent: "Europe",
  },
  {
    lat: "52.24487999999999",
    lng: "0.407962",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNEW",
    portName: "NEWMARKET, SUFFOLK",
    portContinent: "Europe",
  },
  {
    lat: "54.586215",
    lng: "-2.631072",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNEY",
    portName: "Newby",
    portContinent: "Europe",
  },
  {
    lat: "51.6135087",
    lng: "-0.1754198",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNFC",
    portName: "NorthFinchley",
    portContinent: "Europe",
  },
  {
    lat: "51.6426869",
    lng: "0.6739440999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNFE",
    portName: "NorthFambridge",
    portContinent: "Europe",
  },
  {
    lat: "54.01943",
    lng: "-0.3901839",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNFF",
    portName: "Nafferton",
    portContinent: "Europe",
  },
  {
    lat: "53.22844199999999",
    lng: "-4.492722",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNFG",
    portName: "Llanfaelog",
    portContinent: "Europe",
  },
  {
    lat: "50.8857",
    lng: "0.425741",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNFI",
    portName: "Ninfield",
    portContinent: "Europe",
  },
  {
    lat: "52.714772",
    lng: "1.4119679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNFK",
    portName: "Hoverton",
    portContinent: "Europe",
  },
  {
    lat: "52.962732",
    lng: "-1.074676",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNFL",
    portName: "Netherfield",
    portContinent: "Europe",
  },
  {
    lat: "52.57584190000001",
    lng: "-0.412227",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNFO",
    portName: "Wansford",
    portContinent: "Europe",
  },
  {
    lat: "53.1432292",
    lng: "-1.7459756",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNFR",
    portName: "Friden",
    portContinent: "Europe",
  },
  {
    lat: "51.44107200000001",
    lng: "0.33694",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNFT",
    portName: "Northfleet",
    portContinent: "Europe",
  },
  {
    lat: "51.027515",
    lng: "-3.144923",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNFZ",
    portName: "NortonFitzwarren",
    portContinent: "Europe",
  },
  {
    lat: "57.717838",
    lng: "-4.007803",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNGG",
    portName: "Nigg (GBNGG), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.164868",
    lng: "-4.359065",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNGH",
    portName: "Newborough",
    portContinent: "Europe",
  },
  {
    lat: "55.1850534",
    lng: "-1.51225",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNGI",
    portName: "Newbiggin",
    portContinent: "Europe",
  },
  {
    lat: "41.6972996",
    lng: "-72.7228293",
    country: "US",
    country_long: "United States",
    portCode: "GBNGO",
    portName: "Newington",
    portContinent: "Europe",
  },
  {
    lat: "51.5620843",
    lng: "-3.2670293",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNGR",
    portName: "Nantgarw",
    portContinent: "Europe",
  },
  {
    lat: "53.670843",
    lng: "-1.0710449",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNGT",
    portName: "Pollington",
    portContinent: "Europe",
  },
  {
    lat: "52.215687",
    lng: "-3.442604",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNGW",
    portName: "NewbridgeonWye",
    portContinent: "Europe",
  },
  {
    lat: "55.076387",
    lng: "-4.141362",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNGY",
    portName: "NewGalloway",
    portContinent: "Europe",
  },
  {
    lat: "51.9932363",
    lng: "0.043421",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNH9",
    portName: "Nuthampstead",
    portContinent: "Europe",
  },
  {
    lat: "50.823134",
    lng: "-0.9631529999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNHA",
    portName: "NorthHayling",
    portContinent: "Europe",
  },
  {
    lat: "34.1795478",
    lng: "-84.10030139999999",
    country: "US",
    country_long: "United States",
    portCode: "GBNHC",
    portName: "NorthCove",
    portContinent: "Europe",
  },
  {
    lat: "52.736843",
    lng: "1.465231",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNHD",
    portName: "Neatishead",
    portContinent: "Europe",
  },
  {
    lat: "43.0402983",
    lng: "-76.1359624",
    country: "US",
    country_long: "United States",
    portCode: "GBNHE",
    portName: "Newhouse",
    portContinent: "Europe",
  },
  {
    lat: "52.219796",
    lng: "-1.0336939",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNHF",
    portName: "NetherHeyford",
    portContinent: "Europe",
  },
  {
    lat: "50.99459599999999",
    lng: "0.599765",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNHI",
    portName: "Northiam",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBNHL",
    portName: "Notting Hill",
    portContinent: "Europe",
  },
  {
    lat: "52.155608",
    lng: "1.049566",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNHM",
    portName: "NeedhamMarket",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBNHO",
    portName: "NewHolland",
    portContinent: "Europe",
  },
  {
    lat: "42.3250896",
    lng: "-72.64120129999999",
    country: "US",
    country_long: "United States",
    portCode: "GBNHP",
    portName: "Northampton",
    portContinent: "Europe",
  },
  {
    lat: "30.0050601",
    lng: "-95.3817213",
    country: "US",
    country_long: "United States",
    portCode: "GBNHS",
    portName: "NorthHarris",
    portContinent: "Europe",
  },
  {
    lat: "51.546615",
    lng: "-0.373103",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNHT",
    portName: "Northolt",
    portContinent: "Europe",
  },
  {
    lat: "41.308274",
    lng: "-72.9278835",
    country: "US",
    country_long: "United States",
    portCode: "GBNHV",
    portName: "Newhaven",
    portContinent: "Europe",
  },
  {
    lat: "53.18257",
    lng: "-0.5870959",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNHY",
    portName: "NorthHykeham",
    portContinent: "Europe",
  },
  {
    lat: "52.9505859",
    lng: "-1.1258177",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNIO",
    portName: "Sneinton",
    portContinent: "Europe",
  },
  {
    lat: "53.639527",
    lng: "-0.2742339",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNKH",
    portName: "NorthKillingholme",
    portContinent: "Europe",
  },
  {
    lat: "43.0828551",
    lng: "-70.9350382",
    country: "US",
    country_long: "United States",
    portCode: "GBNKT",
    portName: "Newmarket",
    portContinent: "Europe",
  },
  {
    lat: "52.4455349",
    lng: "-1.0937624",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNKW",
    portName: "NorthKilworth",
    portContinent: "Europe",
  },
  {
    lat: "54.751075",
    lng: "-1.436305",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNLE",
    portName: "Thornley",
    portContinent: "Europe",
  },
  {
    lat: "52.6209445",
    lng: "-0.621524",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNLF",
    portName: "NorthLuffenham",
    portContinent: "Europe",
  },
  {
    lat: "51.830777",
    lng: "-1.837254",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNLH",
    portName: "Northleach",
    portContinent: "Europe",
  },
  {
    lat: "53.639527",
    lng: "-0.2742339",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNLI",
    portName: "North Killingholme, Lincolnshire",
    portContinent: "Europe",
  },
  {
    lat: "-41.2985321",
    lng: "173.2443635",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBNLN",
    portName: "Nelson",
    portContinent: "Europe",
  },
  {
    lat: "54.55124",
    lng: "-6.7405",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNLS",
    portName: "NewMills",
    portContinent: "Europe",
  },
  {
    lat: "51.97390799999999",
    lng: "-0.767583",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNLV",
    portName: "NewtonLongville",
    portContinent: "Europe",
  },
  {
    lat: "53.452889",
    lng: "-2.63508",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNLW",
    portName: "Newton-le-Willows",
    portContinent: "Europe",
  },
  {
    lat: "53.3899571",
    lng: "-2.8409376",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNLY",
    portName: "Netherley",
    portContinent: "Europe",
  },
  {
    lat: "51.40066299999999",
    lng: "-0.259263",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNMA",
    portName: "New Malden",
    portContinent: "Europe",
  },
  {
    lat: "48.87987039999999",
    lng: "0.1712529",
    country: "FR",
    country_long: "France",
    portCode: "GBNMD",
    portName: "Normandy",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBNME",
    portName: "Northmavine",
    portContinent: "Europe",
  },
  {
    lat: "50.7531235",
    lng: "-1.6550806",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNMI",
    portName: "New Milton, Hampshire",
    portContinent: "Europe",
  },
  {
    lat: "53.12020039999999",
    lng: "-0.810991",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNMK",
    portName: "NorthMuskham",
    portContinent: "Europe",
  },
  {
    lat: "55.608353",
    lng: "-4.338353",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNML",
    portName: "Newmilns",
    portContinent: "Europe",
  },
  {
    lat: "50.7531235",
    lng: "-1.6550806",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNMN",
    portName: "NewMilton",
    portContinent: "Europe",
  },
  {
    lat: "-17.6741697",
    lng: "141.0740445",
    country: "AU",
    country_long: "Australia",
    portCode: "GBNMO",
    portName: "Normanton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBNMS",
    portName: "Nomansland",
    portContinent: "Europe",
  },
  {
    lat: "51.8986741",
    lng: "-0.8765294",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNMT",
    portName: "NorthMarston",
    portContinent: "Europe",
  },
  {
    lat: "50.8260599",
    lng: "0.3943801",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNMY",
    portName: "NormansBay",
    portContinent: "Europe",
  },
  {
    lat: "52.639578",
    lng: "-1.844023",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNNE",
    portName: "Shenstone",
    portContinent: "Europe",
  },
  {
    lat: "52.2485021",
    lng: "-1.3183686",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNNH",
    portName: "NaptonontheHill",
    portContinent: "Europe",
  },
  {
    lat: "53.452889",
    lng: "-2.63508",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNNL",
    portName: "Newton-Le-Willows, Merseyside",
    portContinent: "Europe",
  },
  {
    lat: "55.771176",
    lng: "-4.336615",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNNM",
    portName: "NewtonMearns",
    portContinent: "Europe",
  },
  {
    lat: "51.9089823",
    lng: "-1.8326496",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNNO",
    portName: "Naunton",
    portContinent: "Europe",
  },
  {
    lat: "52.71079810000001",
    lng: "-1.4425597",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNNR",
    portName: "NormantonleHeath",
    portContinent: "Europe",
  },
  {
    lat: "53.4959352",
    lng: "-2.1682447",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNNT",
    portName: "Newton Heath",
    portContinent: "Europe",
  },
  {
    lat: "52.540168",
    lng: "-0.6716059999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNO2",
    portName: "Gretton",
    portContinent: "Europe",
  },
  {
    lat: "53.18257",
    lng: "-0.5870959",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNOC",
    portName: "North Hykeham, Lincolnshire",
    portContinent: "Europe",
  },
  {
    lat: "53.372407",
    lng: "-0.422282",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNOF",
    portName: "NewtonbyToft",
    portContinent: "Europe",
  },
  {
    lat: "52.084585",
    lng: "-0.734583",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNOG",
    portName: "Newport Pagnell, Buckinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBNOH",
    portName: "NorthHaven,FairIsle",
    portContinent: "Europe",
  },
  {
    lat: "42.1615157",
    lng: "-70.7927832",
    country: "US",
    country_long: "United States",
    portCode: "GBNOL",
    portName: "Norwell",
    portContinent: "Europe",
  },
  {
    lat: "-31.6539634",
    lng: "116.6619872",
    country: "AU",
    country_long: "Australia",
    portCode: "GBNOM",
    portName: "Northam",
    portContinent: "Europe",
  },
  {
    lat: "53.700876",
    lng: "-1.417148",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNON",
    portName: "Normanton, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBNOO",
    portName: "Norton",
    portContinent: "Europe",
  },
  {
    lat: "56.0101001",
    lng: "-3.3945959",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNOQ",
    portName: "North Queensferry (GBNOQ), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "-17.6741697",
    lng: "141.0740445",
    country: "AU",
    country_long: "Australia",
    portCode: "GBNOR",
    portName: "Normanton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBNOT",
    portName: "Norton",
    portContinent: "Europe",
  },
  {
    lat: "50.7724104",
    lng: "-4.122929399999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNOW",
    portName: "Northlew",
    portContinent: "Europe",
  },
  {
    lat: "50.370862",
    lng: "-4.871473",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNPA",
    portName: "Nanpean",
    portContinent: "Europe",
  },
  {
    lat: "41.4901024",
    lng: "-71.3128285",
    country: "US",
    country_long: "United States",
    portCode: "GBNPD",
    portName: "Newport",
    portContinent: "Europe",
  },
  {
    lat: "50.880995",
    lng: "-2.7490009",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNPE",
    portName: "North Perrott, Somerset",
    portContinent: "Europe",
  },
  {
    lat: "50.782998",
    lng: "-0.673061",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNPF",
    portName: "Bognor Regis, West Sussex",
    portContinent: "Europe",
  },
  {
    lat: "33.6188829",
    lng: "-117.9298493",
    country: "US",
    country_long: "United States",
    portCode: "GBNPO",
    portName: "Newport Harbour",
    portContinent: "Europe",
  },
  {
    lat: "41.4901024",
    lng: "-71.3128285",
    country: "US",
    country_long: "United States",
    portCode: "GBNPR",
    portName: "Newport",
    portContinent: "Europe",
  },
  {
    lat: "41.4901024",
    lng: "-71.3128285",
    country: "US",
    country_long: "United States",
    portCode: "GBNPT",
    portName: "Newport",
    portContinent: "Europe",
  },
  {
    lat: "41.4901024",
    lng: "-71.3128285",
    country: "US",
    country_long: "United States",
    portCode: "GBNPW",
    portName: "Newport",
    portContinent: "Europe",
  },
  {
    lat: "50.4399636",
    lng: "-5.0088135",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNQY",
    portName: "Newquay (GBNQY), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "39.495706",
    lng: "-75.985916",
    country: "US",
    country_long: "United States",
    portCode: "GBNRB",
    portName: "Northbay",
    portContinent: "Europe",
  },
  {
    lat: "51.3723635",
    lng: "-0.2259143",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNRC",
    portName: "NorthCheam",
    portContinent: "Europe",
  },
  {
    lat: "51.61028899999999",
    lng: "-0.428278",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNRD",
    portName: "Northwood, Greater London",
    portContinent: "Europe",
  },
  {
    lat: "53.722777",
    lng: "-0.5068969999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNRF",
    portName: "North Ferriby",
    portContinent: "Europe",
  },
  {
    lat: "51.0571992",
    lng: "-0.6429290999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNRH",
    portName: "Northchapel",
    portContinent: "Europe",
  },
  {
    lat: "40.735657",
    lng: "-74.1723667",
    country: "US",
    country_long: "United States",
    portCode: "GBNRK",
    portName: "Newark",
    portContinent: "Europe",
  },
  {
    lat: "50.83982",
    lng: "-0.326195",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNRL",
    portName: "North Lancing",
    portContinent: "Europe",
  },
  {
    lat: "52.822699",
    lng: "1.3859729",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNRM",
    portName: "North Walsham",
    portContinent: "Europe",
  },
  {
    lat: "50.985117",
    lng: "0.9394730000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNRN",
    portName: "New Romney",
    portContinent: "Europe",
  },
  {
    lat: "59.372008",
    lng: "-2.4188716",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNRO",
    portName: "North Ronaldsay (GBNRO), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.476399",
    lng: "-1.489076",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNRR",
    portName: "NorthRoetoHillswick",
    portContinent: "Europe",
  },
  {
    lat: "59.372008",
    lng: "-2.4188716",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNRS",
    portName: "NorthRonaldsayApt",
    portContinent: "Europe",
  },
  {
    lat: "41.4901024",
    lng: "-71.3128285",
    country: "US",
    country_long: "United States",
    portCode: "GBNRT",
    portName: "Newport",
    portContinent: "Europe",
  },
  {
    lat: "52.6292567",
    lng: "1.2978802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNRW",
    portName: "Norwich",
    portContinent: "Europe",
  },
  {
    lat: "54.1751024",
    lng: "-6.3402299",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNRY",
    portName: "Newry",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBNSA",
    portName: "Ness",
    portContinent: "Europe",
  },
  {
    lat: "52.3954519",
    lng: "-0.9885334",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNSB",
    portName: "Naseby",
    portContinent: "Europe",
  },
  {
    lat: "52.0025162",
    lng: "-0.8609886",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNSBÂ",
    portName: "Nash Buckinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "55.57635459999999",
    lng: "-1.6677643",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNSD",
    portName: "NorthSunderland",
    portContinent: "Europe",
  },
  {
    lat: "51.43175600000001",
    lng: "-2.756287",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNSE",
    portName: "Nailsea",
    portContinent: "Europe",
  },
  {
    lat: "51.51957480000001",
    lng: "-0.08733579999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNSF",
    portName: "Finsbury/GreaterLondon",
    portContinent: "Europe",
  },
  {
    lat: "51.65502189999999",
    lng: "0.3560628",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNSG",
    portName: "Mountnessing",
    portContinent: "Europe",
  },
  {
    lat: "55.010762",
    lng: "-1.449138",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNSH",
    portName: "North Shields",
    portContinent: "Europe",
  },
  {
    lat: "55.78549520000001",
    lng: "-4.424883599999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNSN",
    portName: "Neilston",
    portContinent: "Europe",
  },
  {
    lat: "52.800762",
    lng: "-1.229402",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNSO",
    portName: "NormantononSoar",
    portContinent: "Europe",
  },
  {
    lat: "52.8263851",
    lng: "-1.584243",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNSR",
    portName: "NewtonSolney",
    portContinent: "Europe",
  },
  {
    lat: "52.1822741",
    lng: "1.612971",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNSS",
    portName: "Thorpeness",
    portContinent: "Europe",
  },
  {
    lat: "43.0212772",
    lng: "-78.52065",
    country: "US",
    country_long: "United States",
    portCode: "GBNST",
    portName: "Newstead",
    portContinent: "Europe",
  },
  {
    lat: "54.96029900000001",
    lng: "-4.483163999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNSW",
    portName: "NewtonStewart",
    portContinent: "Europe",
  },
  {
    lat: "54.6792422",
    lng: "-5.9591102",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTA",
    portName: "Newtownabbey",
    portContinent: "Europe",
  },
  {
    lat: "51.073804",
    lng: "0.9104639999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTB",
    portName: "Bilsington",
    portContinent: "Europe",
  },
  {
    lat: "55.8976558",
    lng: "-4.3285063",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTE",
    portName: "Netherton",
    portContinent: "Europe",
  },
  {
    lat: "50.3161546",
    lng: "-4.036691",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTF",
    portName: "NewtonFerrers",
    portContinent: "Europe",
  },
  {
    lat: "52.9540223",
    lng: "-1.1549892",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTG",
    portName: "Nottingham",
    portContinent: "Europe",
  },
  {
    lat: "53.25868029999999",
    lng: "-2.5181321",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTH",
    portName: "Northwich",
    portContinent: "Europe",
  },
  {
    lat: "51.242116",
    lng: "-1.674818",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTI",
    portName: "NorthTidworth",
    portContinent: "Europe",
  },
  {
    lat: "52.9266999",
    lng: "-0.06080099999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTK",
    portName: "Kirton",
    portContinent: "Europe",
  },
  {
    lat: "58.1415488",
    lng: "-6.4513861",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTL",
    portName: "NorthLochs",
    portContinent: "Europe",
  },
  {
    lat: "52.9540223",
    lng: "-1.1549892",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTM",
    portName: "Nottingham, Nottinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "52.520489",
    lng: "-1.465382",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTN",
    portName: "Nuneaton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBNTO",
    portName: "Newton",
    portContinent: "Europe",
  },
  {
    lat: "53.9846893",
    lng: "-1.1483393",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTP",
    portName: "NetherPoppleton",
    portContinent: "Europe",
  },
  {
    lat: "51.654745",
    lng: "-3.491629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTR",
    portName: "Pentre",
    portContinent: "Europe",
  },
  {
    lat: "54.5913787",
    lng: "-5.693170299999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTS",
    portName: "Newtownards",
    portContinent: "Europe",
  },
  {
    lat: "51.5768063",
    lng: "-0.9894874",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTT",
    portName: "Nettlebed",
    portContinent: "Europe",
  },
  {
    lat: "53.06718499999999",
    lng: "-2.524102",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTW",
    portName: "Nantwich",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBNTY",
    portName: "Bentley",
    portContinent: "Europe",
  },
  {
    lat: "50.800602",
    lng: "-3.897245",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNTZ",
    portName: "NorthTawton",
    portContinent: "Europe",
  },
  {
    lat: "57.5704006",
    lng: "-7.2811991",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNUI",
    portName: "NorthUist",
    portContinent: "Europe",
  },
  {
    lat: "53.013208",
    lng: "-2.2273002",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNUL",
    portName: "Newcastle under Lyme",
    portContinent: "Europe",
  },
  {
    lat: "54.526824",
    lng: "-1.168795",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNUN",
    portName: "Nunthorpe",
    portContinent: "Europe",
  },
  {
    lat: "50.9459664",
    lng: "-1.4618632",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNUR",
    portName: "Nursling",
    portContinent: "Europe",
  },
  {
    lat: "53.0700391",
    lng: "-0.80657",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNUT",
    portName: "NewarkuponTrent",
    portContinent: "Europe",
  },
  {
    lat: "51.08663600000001",
    lng: "-0.966854",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNVL",
    portName: "NewtonValence",
    portContinent: "Europe",
  },
  {
    lat: "55.81480269999999",
    lng: "-3.938536",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNWA",
    portName: "Newarthill",
    portContinent: "Europe",
  },
  {
    lat: "51.665206",
    lng: "-3.144116",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNWB",
    portName: "Newbridge",
    portContinent: "Europe",
  },
  {
    lat: "50.975252",
    lng: "0.014796",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNWC",
    portName: "Newick",
    portContinent: "Europe",
  },
  {
    lat: "51.61028899999999",
    lng: "-0.428278",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNWD",
    portName: "Northwood",
    portContinent: "Europe",
  },
  {
    lat: "51.929281",
    lng: "-2.404578",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNWE",
    portName: "Newent",
    portContinent: "Europe",
  },
  {
    lat: "55.9802049",
    lng: "-3.1929771",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNWF",
    portName: "Newhaven/Edinburgh",
    portContinent: "Europe",
  },
  {
    lat: "51.693878",
    lng: "-2.216293",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNWH",
    portName: "Nailsworth",
    portContinent: "Europe",
  },
  {
    lat: "40.735657",
    lng: "-74.1723667",
    country: "US",
    country_long: "United States",
    portCode: "GBNWK",
    portName: "Newark",
    portContinent: "Europe",
  },
  {
    lat: "51.7146468",
    lng: "0.1631669",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNWL",
    portName: "North Weald Bassett Essex",
    portContinent: "Europe",
  },
  {
    lat: "57.266414",
    lng: "-2.190301",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNWM",
    portName: "Newmachar",
    portContinent: "Europe",
  },
  {
    lat: "41.4141172",
    lng: "-73.3035651",
    country: "US",
    country_long: "United States",
    portCode: "GBNWN",
    portName: "Newtown",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBNWO",
    portName: "Newton",
    portContinent: "Europe",
  },
  {
    lat: "52.084585",
    lng: "-0.734583",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNWP",
    portName: "Newport Pagnell",
    portContinent: "Europe",
  },
  {
    lat: "51.665206",
    lng: "-3.144116",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNWR",
    portName: "Newbridge",
    portContinent: "Europe",
  },
  {
    lat: "41.4141172",
    lng: "-73.3035651",
    country: "US",
    country_long: "United States",
    portCode: "GBNWT",
    portName: "Newtown",
    portContinent: "Europe",
  },
  {
    lat: "54.7161761",
    lng: "-7.3804931",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNWW",
    portName: "Newtownstewart",
    portContinent: "Europe",
  },
  {
    lat: "40.7127753",
    lng: "-74.0059728",
    country: "US",
    country_long: "United States",
    portCode: "GBNWY",
    portName: "NEW YORK",
    portContinent: "Europe",
  },
  {
    lat: "52.2938389",
    lng: "-0.2544088",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNY2",
    portName: "Buckden",
    portContinent: "Europe",
  },
  {
    lat: "51.4085878",
    lng: "-0.1213899",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNYB",
    portName: "Norbury",
    portContinent: "Europe",
  },
  {
    lat: "35.9550195",
    lng: "-83.9250108",
    country: "US",
    country_long: "United States",
    portCode: "GBNYD",
    portName: "Neyland",
    portContinent: "Europe",
  },
  {
    lat: "51.3105126",
    lng: "0.447421",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNYH",
    portName: "NewHythe",
    portContinent: "Europe",
  },
  {
    lat: "53.9614205",
    lng: "-1.0739108",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNYK",
    portName: "New York, United Kingdom (GBNYK), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.10188609999999",
    lng: "-5.5458604",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNYL",
    portName: "Newlyn Harbour",
    portContinent: "Europe",
  },
  {
    lat: "54.403465",
    lng: "-1.732618",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNYS",
    portName: "North Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "54.0784014",
    lng: "-0.8501554",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBNYW",
    portName: "Westow",
    portContinent: "Europe",
  },
  {
    lat: "52.5991535",
    lng: "-1.0783725",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOAB",
    portName: "Oadby, Leicestershire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBOAK",
    portName: "Oakhamness",
    portContinent: "Europe",
  },
  {
    lat: "51.760948",
    lng: "0.548168",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOAM",
    portName: "Boreham",
    portContinent: "Europe",
  },
  {
    lat: "51.3299242",
    lng: "0.8785913999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOAR",
    portName: "Oare",
    portContinent: "Europe",
  },
  {
    lat: "56.415157",
    lng: "-5.471047",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOBA",
    portName: "Oban (GBOBA), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.43986899999999",
    lng: "-1.731563",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOBM",
    portName: "OgbourneMaizey",
    portContinent: "Europe",
  },
  {
    lat: "51.503728",
    lng: "-0.1116764",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOBN",
    portName: "SouthBank",
    portContinent: "Europe",
  },
  {
    lat: "51.003742",
    lng: "-1.348111",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOBO",
    portName: "Otterbourne",
    portContinent: "Europe",
  },
  {
    lat: "52.470111",
    lng: "1.704104",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOBR",
    portName: "OultonBroad",
    portContinent: "Europe",
  },
  {
    lat: "52.504923",
    lng: "-2.015907",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOBY",
    portName: "Oldbury",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBOCH",
    portName: "Lochs",
    portContinent: "Europe",
  },
  {
    lat: "51.1465149",
    lng: "-0.3625906",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOCL",
    portName: "Ockley",
    portContinent: "Europe",
  },
  {
    lat: "42.3886688",
    lng: "-79.4411573",
    country: "US",
    country_long: "United States",
    portCode: "GBOCT",
    portName: "Brocton",
    portContinent: "Europe",
  },
  {
    lat: "52.2888499",
    lng: "-0.213368",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOCY",
    portName: "OffordCluny",
    portContinent: "Europe",
  },
  {
    lat: "60.6166667",
    lng: "-0.8666667000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBODD",
    portName: "Oddsta,Fetlar",
    portContinent: "Europe",
  },
  {
    lat: "52.2418391",
    lng: "0.2436207",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBODE",
    portName: "Lode",
    portContinent: "Europe",
  },
  {
    lat: "53.5409298",
    lng: "-2.1113659",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBODH",
    portName: "Oldham",
    portContinent: "Europe",
  },
  {
    lat: "53.167203",
    lng: "-3.141903",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBODJ",
    portName: "Mold, Flintshire",
    portContinent: "Europe",
  },
  {
    lat: "52.805229",
    lng: "-1.001836",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBODL",
    portName: "OldDalby",
    portContinent: "Europe",
  },
  {
    lat: "51.254383",
    lng: "-0.9395249999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBODM",
    portName: "Odiham",
    portContinent: "Europe",
  },
  {
    lat: "51.115613",
    lng: "-0.861611",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBODN",
    portName: "Bordon",
    portContinent: "Europe",
  },
  {
    lat: "58.0426268",
    lng: "-5.4223517",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBODO",
    portName: "OldDornie",
    portContinent: "Europe",
  },
  {
    lat: "51.929844",
    lng: "-1.6720171",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBODT",
    portName: "Oddington",
    portContinent: "Europe",
  },
  {
    lat: "51.694914",
    lng: "-3.220952",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOED",
    portName: "Aberbargoed, Caerphilly",
    portContinent: "Europe",
  },
  {
    lat: "52.753166",
    lng: "-1.406571",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOET",
    portName: "Coleorton",
    portContinent: "Europe",
  },
  {
    lat: "53.584533",
    lng: "-1.18485",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOFT",
    portName: "Carcroft",
    portContinent: "Europe",
  },
  {
    lat: "52.6194115",
    lng: "-2.2809729",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOGA",
    portName: "Boningale",
    portContinent: "Europe",
  },
  {
    lat: "53.434881",
    lng: "-1.538494",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOGB",
    portName: "Oughtibridge",
    portContinent: "Europe",
  },
  {
    lat: "54.526852",
    lng: "-2.312733",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOGH",
    portName: "Brough",
    portContinent: "Europe",
  },
  {
    lat: "52.770328",
    lng: "-1.3693896",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOGP",
    portName: "Osgathorpe",
    portContinent: "Europe",
  },
  {
    lat: "51.7095315",
    lng: "0.2427558",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOGR",
    portName: "Ongar",
    portContinent: "Europe",
  },
  {
    lat: "52.948048",
    lng: "-2.768994",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOGT",
    portName: "Bronington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBOHA",
    portName: "Ockham",
    portContinent: "Europe",
  },
  {
    lat: "53.901785",
    lng: "-1.988246",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOHI",
    portName: "CrossHills",
    portContinent: "Europe",
  },
  {
    lat: "52.8017859",
    lng: "-1.4005783",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOHL",
    portName: "BreedonontheHill",
    portContinent: "Europe",
  },
  {
    lat: "50.829002",
    lng: "-0.8576779999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOHM",
    portName: "Bosham",
    portContinent: "Europe",
  },
  {
    lat: "54.289444",
    lng: "-2.669919",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOHN",
    portName: "OldHutton",
    portContinent: "Europe",
  },
  {
    lat: "55.8238816",
    lng: "-3.8063955",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOHT",
    portName: "Shotts",
    portContinent: "Europe",
  },
  {
    lat: "52.663742",
    lng: "-2.6801731",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOHU",
    portName: "CrossHouses",
    portContinent: "Europe",
  },
  {
    lat: "54.841016",
    lng: "-1.468691",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOHZ",
    portName: "Houghton-Le-Spring, Tyne & Wear",
    portContinent: "Europe",
  },
  {
    lat: "51.115613",
    lng: "-0.861611",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOJD",
    portName: "Bordon, Hampshire",
    portContinent: "Europe",
  },
  {
    lat: "53.284626",
    lng: "-0.824754",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOKE",
    portName: "Stokeham",
    portContinent: "Europe",
  },
  {
    lat: "51.112876",
    lng: "-0.902258",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOKG",
    portName: "Oakhanger",
    portContinent: "Europe",
  },
  {
    lat: "50.738308",
    lng: "-4.004263",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOKH",
    portName: "Okehampton",
    portContinent: "Europe",
  },
  {
    lat: "55.92534",
    lng: "-4.4558289",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOKK",
    portName: "OldKilpatrick",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBOKL",
    portName: "Oakley",
    portContinent: "Europe",
  },
  {
    lat: "42.35271050000001",
    lng: "-72.0449783",
    country: "US",
    country_long: "United States",
    portCode: "GBOKM",
    portName: "Oakham",
    portContinent: "Europe",
  },
  {
    lat: "53.847426",
    lng: "-1.950299",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOKW",
    portName: "Oakworth",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBOLD",
    portName: "Old",
    portContinent: "Europe",
  },
  {
    lat: "52.652349",
    lng: "0.4769978999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOLH",
    portName: "Shouldham",
    portContinent: "Europe",
  },
  {
    lat: "56.6246136",
    lng: "-6.5618783",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOLL",
    portName: "Coll (GBOLL), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.15473799999999",
    lng: "-0.7013779",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOLM",
    portName: "Olney, Buckinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "40.0397758",
    lng: "-75.1242995",
    country: "US",
    country_long: "United States",
    portCode: "GBOLN",
    portName: "Olney",
    portContinent: "Europe",
  },
  {
    lat: "51.489312",
    lng: "-0.3520834",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOLP",
    portName: "OsterleyPark",
    portContinent: "Europe",
  },
  {
    lat: "53.20409799999999",
    lng: "-1.020581",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOLR",
    portName: "Ollerton",
    portContinent: "Europe",
  },
  {
    lat: "51.3025064",
    lng: "-0.5458661",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOLW",
    portName: "Old Woking",
    portContinent: "Europe",
  },
  {
    lat: "54.63444699999999",
    lng: "-5.846255699999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOLY",
    portName: "Holywood",
    portContinent: "Europe",
  },
  {
    lat: "54.5977149",
    lng: "-7.3099596",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOMA",
    portName: "Omagh",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBOMB",
    portName: "Combe",
    portContinent: "Europe",
  },
  {
    lat: "53.796976",
    lng: "-1.332058",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOMF",
    portName: "Micklefield",
    portContinent: "Europe",
  },
  {
    lat: "53.568935",
    lng: "-2.885057",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOMK",
    portName: "Ormskirk",
    portContinent: "Europe",
  },
  {
    lat: "54.3685315",
    lng: "-1.2993878",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOML",
    portName: "Osmotherley",
    portContinent: "Europe",
  },
  {
    lat: "54.1463194",
    lng: "-0.7783344",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOMN",
    portName: "OldMalton",
    portContinent: "Europe",
  },
  {
    lat: "51.5478609",
    lng: "-0.0425903",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOMR",
    portName: "Homerton",
    portContinent: "Europe",
  },
  {
    lat: "52.993739",
    lng: "-1.7027593",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOMT",
    portName: "Osmaston",
    portContinent: "Europe",
  },
  {
    lat: "57.33513800000001",
    lng: "-2.321806",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOMU",
    portName: "Oldmeldrum",
    portContinent: "Europe",
  },
  {
    lat: "51.6805907",
    lng: "0.2914049",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOMY",
    portName: "StondonMassey",
    portContinent: "Europe",
  },
  {
    lat: "52.1449969",
    lng: "1.1154561",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBONA",
    portName: "Coddenham",
    portContinent: "Europe",
  },
  {
    lat: "52.48084069999999",
    lng: "-0.4687516",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOND",
    portName: "Oundle",
    portContinent: "Europe",
  },
  {
    lat: "54.8730813",
    lng: "-6.474459599999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBONE",
    portName: "Portglenone",
    portContinent: "Europe",
  },
  {
    lat: "52.79423999999999",
    lng: "0.341536",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBONH",
    portName: "OngarHill",
    portContinent: "Europe",
  },
  {
    lat: "53.602267",
    lng: "-1.794173",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBONL",
    portName: "Honley",
    portContinent: "Europe",
  },
  {
    lat: "51.77275299999999",
    lng: "-1.6158971",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBONS",
    portName: "Shilton",
    portContinent: "Europe",
  },
  {
    lat: "51.6721022",
    lng: "0.6699647",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBONT",
    portName: "ColdNorton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBOOK",
    portName: "Hook",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBOON",
    portName: "Coton",
    portContinent: "Europe",
  },
  {
    lat: "51.946217",
    lng: "-0.018547",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOOR",
    portName: "Buntingford",
    portContinent: "Europe",
  },
  {
    lat: "53.8618379",
    lng: "-0.816854",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOOS",
    portName: "SeatonRoss",
    portContinent: "Europe",
  },
  {
    lat: "53.2999249",
    lng: "-2.9571327",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOOT",
    portName: "Hooton",
    portContinent: "Europe",
  },
  {
    lat: "50.53449999999999",
    lng: "-4.445349999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOOW",
    portName: "Henwood",
    portContinent: "Europe",
  },
  {
    lat: "50.990404",
    lng: "0.8954239999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOOY",
    portName: "OldRomney",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBOPE",
    portName: "Hope",
    portContinent: "Europe",
  },
  {
    lat: "51.374843",
    lng: "0.09421399999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOPF",
    portName: "Orpington, Greater London",
    portContinent: "Europe",
  },
  {
    lat: "51.374843",
    lng: "0.09421399999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOPG",
    portName: "Orpington",
    portContinent: "Europe",
  },
  {
    lat: "51.8721635",
    lng: "0.1536502",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOPM",
    portName: "Bishop'S Stortford, Hertfordshire",
    portContinent: "Europe",
  },
  {
    lat: "53.621543",
    lng: "-2.662958",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOPP",
    portName: "Coppull",
    portContinent: "Europe",
  },
  {
    lat: "50.809558",
    lng: "-3.745186",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOPS",
    portName: "Copplestone",
    portContinent: "Europe",
  },
  {
    lat: "53.181041",
    lng: "0.229381",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBORB",
    portName: "Orby",
    portContinent: "Europe",
  },
  {
    lat: "56.514058",
    lng: "-4.766082",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBORC",
    portName: "BridgeofOrchy",
    portContinent: "Europe",
  },
  {
    lat: "36.8507689",
    lng: "-76.28587259999999",
    country: "US",
    country_long: "United States",
    portCode: "GBORF",
    portName: "Norfolk",
    portContinent: "Europe",
  },
  {
    lat: "52.0947402",
    lng: "1.5341196",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBORH",
    portName: "Orford",
    portContinent: "Europe",
  },
  {
    lat: "58.9809401",
    lng: "-2.9605206",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBORK",
    portName: "Orkney",
    portContinent: "Europe",
  },
  {
    lat: "54.5448022",
    lng: "-1.1721427",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBORM",
    portName: "Ormesby",
    portContinent: "Europe",
  },
  {
    lat: "53.167462",
    lng: "-0.80411",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBORN",
    portName: "CarltononTrent",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBORO",
    portName: "Orton",
    portContinent: "Europe",
  },
  {
    lat: "50.987767",
    lng: "-3.079995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBORP",
    portName: "OrchardPortman",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBORR",
    portName: "Corran",
    portContinent: "Europe",
  },
  {
    lat: "52.962221",
    lng: "-0.8532689999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBORS",
    portName: "Orston",
    portContinent: "Europe",
  },
  {
    lat: "51.5158",
    lng: "0.3663699",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBORT",
    portName: "Orsett",
    portContinent: "Europe",
  },
  {
    lat: "52.980051",
    lng: "-3.369242",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBORW",
    portName: "Corwen",
    portContinent: "Europe",
  },
  {
    lat: "53.4761956",
    lng: "-2.1757028",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOS9",
    portName: "Openshaw",
    portContinent: "Europe",
  },
  {
    lat: "52.676419",
    lng: "1.688281",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOSA",
    portName: "OrmesbySaintMargaret",
    portContinent: "Europe",
  },
  {
    lat: "50.75117820000001",
    lng: "-3.279352",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOSM",
    portName: "OtterySaintMary",
    portContinent: "Europe",
  },
  {
    lat: "43.6595262",
    lng: "-79.4246583",
    country: "CA",
    country_long: "Canada",
    portCode: "GBOSN",
    portName: "Ossington",
    portContinent: "Europe",
  },
  {
    lat: "51.6985249",
    lng: "-2.587131",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOSO",
    portName: "Woolaston",
    portContinent: "Europe",
  },
  {
    lat: "52.7063657",
    lng: "-2.7417849",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOSP",
    portName: "Shropshire",
    portContinent: "Europe",
  },
  {
    lat: "53.681091",
    lng: "-1.578877",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOSS",
    portName: "Ossett",
    portContinent: "Europe",
  },
  {
    lat: "53.681091",
    lng: "-1.578877",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOST",
    portName: "Ossett, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "52.857148",
    lng: "-3.056412",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOSW",
    portName: "Oswestry, Shropshire",
    portContinent: "Europe",
  },
  {
    lat: "53.514681",
    lng: "-2.038679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOSY",
    portName: "Mossley",
    portContinent: "Europe",
  },
  {
    lat: "51.46664759999999",
    lng: "-2.5953418",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOTA",
    portName: "Cotham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBOTC",
    portName: "SouthLochs",
    portContinent: "Europe",
  },
  {
    lat: "51.919899",
    lng: "0.293473",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOTD",
    portName: "Broxted",
    portContinent: "Europe",
  },
  {
    lat: "53.905825",
    lng: "-1.691786",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOTE",
    portName: "Otley, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "53.4630589",
    lng: "-2.2913401",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOTF",
    portName: "OldTrafford",
    portContinent: "Europe",
  },
  {
    lat: "42.2505976",
    lng: "-87.83763069999999",
    country: "US",
    country_long: "United States",
    portCode: "GBOTG",
    portName: "Gorton",
    portContinent: "Europe",
  },
  {
    lat: "51.252156",
    lng: "0.5743779999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOTH",
    portName: "Otham",
    portContinent: "Europe",
  },
  {
    lat: "53.905825",
    lng: "-1.691786",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOTL",
    portName: "Otley",
    portContinent: "Europe",
  },
  {
    lat: "51.150085",
    lng: "-2.868685",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOTM",
    portName: "Catcott",
    portContinent: "Europe",
  },
  {
    lat: "53.4045839",
    lng: "-2.1381333",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOTN",
    portName: "Offerton",
    portContinent: "Europe",
  },
  {
    lat: "42.2682728",
    lng: "-71.0185755",
    country: "US",
    country_long: "United States",
    portCode: "GBOTO",
    portName: "Wollaston",
    portContinent: "Europe",
  },
  {
    lat: "57.5704006",
    lng: "-7.2811991",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOTR",
    portName: "Otternish,NorthUist",
    portContinent: "Europe",
  },
  {
    lat: "55.8238816",
    lng: "-3.8063955",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOTS",
    portName: "Shotts",
    portContinent: "Europe",
  },
  {
    lat: "51.3633979",
    lng: "-0.5303019999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOTT",
    portName: "Ottershaw",
    portContinent: "Europe",
  },
  {
    lat: "53.905825",
    lng: "-1.691786",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOTY",
    portName: "Otley",
    portContinent: "Europe",
  },
  {
    lat: "51.74347299999999",
    lng: "-0.021151",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOUA",
    portName: "Broxbourne, Hertfordshire",
    portContinent: "Europe",
  },
  {
    lat: "52.48084069999999",
    lng: "-0.4687516",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOUD",
    portName: "Oundle, Northamptonshire",
    portContinent: "Europe",
  },
  {
    lat: "53.59673",
    lng: "-1.2831",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOUE",
    portName: "SouthElmsall",
    portContinent: "Europe",
  },
  {
    lat: "50.429962",
    lng: "-3.837397",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOUH",
    portName: "SouthBrent",
    portContinent: "Europe",
  },
  {
    lat: "60.42266110000001",
    lng: "-0.7618746",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOUK",
    portName: "OuterSkerries",
    portContinent: "Europe",
  },
  {
    lat: "51.642387",
    lng: "-3.235099",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOUO",
    portName: "Ystrad Mynach, Caerphilly",
    portContinent: "Europe",
  },
  {
    lat: "56.547918",
    lng: "-3.265548",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOUP",
    portName: "CouparAngus",
    portContinent: "Europe",
  },
  {
    lat: "51.062883",
    lng: "-0.325858",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOUQ",
    portName: "Horsham, West Sussex",
    portContinent: "Europe",
  },
  {
    lat: "57.2508291",
    lng: "-3.3780425",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOUT",
    portName: "Tomintoul",
    portContinent: "Europe",
  },
  {
    lat: "51.243196",
    lng: "-1.26328",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOVE",
    portName: "Overton",
    portContinent: "Europe",
  },
  {
    lat: "53.7425206",
    lng: "-1.8815653",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOVF",
    portName: "Ovenden",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBOVR",
    portName: "Over",
    portContinent: "Europe",
  },
  {
    lat: "52.735499",
    lng: "-1.565376",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOVS",
    portName: "Overseal, Derbyshire",
    portContinent: "Europe",
  },
  {
    lat: "51.3614279",
    lng: "-0.193961",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOWC",
    portName: "Sutton, Greater London",
    portContinent: "Europe",
  },
  {
    lat: "33.5378885",
    lng: "-85.2532818",
    country: "US",
    country_long: "United States",
    portCode: "GBOWD",
    portName: "Bowdon",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBOWE",
    portName: "Ower",
    portContinent: "Europe",
  },
  {
    lat: "53.266281",
    lng: "-1.425575",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOWG",
    portName: "OldWhittington",
    portContinent: "Europe",
  },
  {
    lat: "50.162291",
    lng: "-5.295861899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOWN",
    portName: "Crowan",
    portContinent: "Europe",
  },
  {
    lat: "52.857148",
    lng: "-3.056412",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOWR",
    portName: "Oswestry",
    portContinent: "Europe",
  },
  {
    lat: "43.8573387",
    lng: "-70.0941174",
    country: "US",
    country_long: "United States",
    portCode: "GBOWS",
    portName: "BowStreet",
    portContinent: "Europe",
  },
  {
    lat: "52.0643523",
    lng: "-0.8256378",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOWT",
    portName: "Old Wolverton",
    portContinent: "Europe",
  },
  {
    lat: "53.742244",
    lng: "-2.406538",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOWW",
    portName: "Oswaldtwistle",
    portContinent: "Europe",
  },
  {
    lat: "51.255714",
    lng: "-0.000003",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOXD",
    portName: "Oxted",
    portContinent: "Europe",
  },
  {
    lat: "51.7520209",
    lng: "-1.2577263",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOXF",
    portName: "Oxford",
    portContinent: "Europe",
  },
  {
    lat: "50.9355606",
    lng: "-1.0000067",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOXH",
    portName: "Clanfield",
    portContinent: "Europe",
  },
  {
    lat: "52.1079859",
    lng: "-1.5404951",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOXI",
    portName: "Oxhill",
    portContinent: "Europe",
  },
  {
    lat: "55.7736321",
    lng: "-2.803112",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOXO",
    portName: "Oxton",
    portContinent: "Europe",
  },
  {
    lat: "52.2300836",
    lng: "-0.265103",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOXS",
    portName: "St Neots, Cambridgeshire",
    portContinent: "Europe",
  },
  {
    lat: "51.334488",
    lng: "-0.35836",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOXT",
    portName: "Oxshott",
    portContinent: "Europe",
  },
  {
    lat: "52.805229",
    lng: "-1.001836",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOXY",
    portName: "Old Dalby, Leicestershire",
    portContinent: "Europe",
  },
  {
    lat: "51.4754669",
    lng: "-0.5118773000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOYL",
    portName: "Poyle",
    portContinent: "Europe",
  },
  {
    lat: "55.8197452",
    lng: "-3.9748068",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOYT",
    portName: "Holytown",
    portContinent: "Europe",
  },
  {
    lat: "53.27600899999999",
    lng: "-3.225103",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBOYW",
    portName: "Holywell",
    portContinent: "Europe",
  },
  {
    lat: "54.426053",
    lng: "-1.27168",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBP89",
    portName: "Potto",
    portContinent: "Europe",
  },
  {
    lat: "38.6889917",
    lng: "-80.43591839999999",
    country: "US",
    country_long: "United States",
    portCode: "GBP9G",
    portName: "Poling",
    portContinent: "Europe",
  },
  {
    lat: "51.4832554",
    lng: "-1.087386",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPAB",
    portName: "Pangbourne",
    portContinent: "Europe",
  },
  {
    lat: "50.54206199999999",
    lng: "-4.939017",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPAD",
    portName: "PadstowCornwall",
    portContinent: "Europe",
  },
  {
    lat: "53.281821",
    lng: "-4.216287900000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPAE",
    portName: "Pentraeth",
    portContinent: "Europe",
  },
  {
    lat: "51.5973273",
    lng: "0.7901665",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPAG",
    portName: "Paglesham",
    portContinent: "Europe",
  },
  {
    lat: "50.43508",
    lng: "-3.564235",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPAI",
    portName: "Paignton, Devon",
    portContinent: "Europe",
  },
  {
    lat: "55.84809079999999",
    lng: "-6.1055031",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPAK",
    portName: "PortAskaig",
    portContinent: "Europe",
  },
  {
    lat: "54.89191",
    lng: "-3.840674999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPAL",
    portName: "Palnackie",
    portContinent: "Europe",
  },
  {
    lat: "52.1113247",
    lng: "0.1835698",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPAM",
    portName: "Pampisford, Cambridgeshire",
    portContinent: "Europe",
  },
  {
    lat: "39.0468118",
    lng: "-95.6757564",
    country: "US",
    country_long: "United States",
    portCode: "GBPAN",
    portName: "Pennant",
    portContinent: "Europe",
  },
  {
    lat: "53.2085425",
    lng: "-1.289426",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPAO",
    portName: "Palterton",
    portContinent: "Europe",
  },
  {
    lat: "56.553917",
    lng: "-5.408119999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPAP",
    portName: "PortAppin",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBPAR",
    portName: "Par",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBPAS",
    portName: "Paisley",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBPAT",
    portName: "Paddington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBPAY",
    portName: "Paisley",
    portContinent: "Europe",
  },
  {
    lat: "55.21550010000001",
    lng: "-6.5470001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPBA",
    portName: "Portballintrae",
    portContinent: "Europe",
  },
  {
    lat: "52.22022",
    lng: "-2.891345",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPBD",
    portName: "Pembridge",
    portContinent: "Europe",
  },
  {
    lat: "51.721203",
    lng: "-3.355544",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPBH",
    portName: "Pentrebach",
    portContinent: "Europe",
  },
  {
    lat: "51.2913179",
    lng: "-0.645506",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPBI",
    portName: "Pirbright",
    portContinent: "Europe",
  },
  {
    lat: "52.813675",
    lng: "-0.158833",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPBK",
    portName: "Pinchbeck",
    portContinent: "Europe",
  },
  {
    lat: "53.593769",
    lng: "-2.770559",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPBL",
    portName: "Parbold",
    portContinent: "Europe",
  },
  {
    lat: "53.371416",
    lng: "-3.0390229",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPBM",
    portName: "Prenton/Birkenhead",
    portContinent: "Europe",
  },
  {
    lat: "52.5194287",
    lng: "-1.9006033",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPBR",
    portName: "PerryBarr",
    portContinent: "Europe",
  },
  {
    lat: "53.174675",
    lng: "0.09143989999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPBY",
    portName: "Spilsby",
    portContinent: "Europe",
  },
  {
    lat: "39.6878991",
    lng: "-75.7553774",
    country: "US",
    country_long: "United States",
    portCode: "GBPCD",
    portName: "Pencader",
    portContinent: "Europe",
  },
  {
    lat: "52.08493",
    lng: "-4.13931",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPCG",
    portName: "Pencarreg",
    portContinent: "Europe",
  },
  {
    lat: "55.9101",
    lng: "-2.894661",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPCI",
    portName: "Pencaitland",
    portContinent: "Europe",
  },
  {
    lat: "51.47032369999999",
    lng: "-0.0673872",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPCK",
    portName: "Peckham",
    portContinent: "Europe",
  },
  {
    lat: "54.24659450000001",
    lng: "-1.470554",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPCL",
    portName: "Pickhill",
    portContinent: "Europe",
  },
  {
    lat: "52.8742138",
    lng: "-4.6836572",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPCO",
    portName: "PorthColmon",
    portContinent: "Europe",
  },
  {
    lat: "51.8899929",
    lng: "0.014997",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPCR",
    portName: "Puckeridge",
    portContinent: "Europe",
  },
  {
    lat: "53.428674",
    lng: "-2.804564",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPCT",
    portName: "Prescot",
    portContinent: "Europe",
  },
  {
    lat: "55.830932",
    lng: "-3.224533",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPCU",
    portName: "Penicuik",
    portContinent: "Europe",
  },
  {
    lat: "51.47896799999999",
    lng: "-3.705163",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPCW",
    portName: "Porthcawl",
    portContinent: "Europe",
  },
  {
    lat: "51.6419",
    lng: "-4.100125999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPDD",
    portName: "Pen-Clawdd",
    portContinent: "Europe",
  },
  {
    lat: "52.38224599999999",
    lng: "-0.045715",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPDE",
    portName: "Pidley",
    portContinent: "Europe",
  },
  {
    lat: "52.2530519",
    lng: "-0.6222529",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPDG",
    portName: "Podington",
    portContinent: "Europe",
  },
  {
    lat: "53.802132",
    lng: "-2.315723",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPDH",
    portName: "Padiham",
    portContinent: "Europe",
  },
  {
    lat: "53.181052",
    lng: "-4.212695",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPDI",
    portName: "PortDinorwic",
    portContinent: "Europe",
  },
  {
    lat: "51.712266",
    lng: "-4.037071",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPDL",
    portName: "Pontardulais",
    portContinent: "Europe",
  },
  {
    lat: "50.748707",
    lng: "-2.348238",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPDN",
    portName: "Puddletown",
    portContinent: "Europe",
  },
  {
    lat: "51.81452600000001",
    lng: "0.886578",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPDO",
    portName: "Peldon",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBPDT",
    portName: "Pendleton",
    portContinent: "Europe",
  },
  {
    lat: "51.1813239",
    lng: "0.38477",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPDW",
    portName: "Paddock Wood",
    portContinent: "Europe",
  },
  {
    lat: "53.795766",
    lng: "-1.6761082",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPDY",
    portName: "Pudsey",
    portContinent: "Europe",
  },
  {
    lat: "53.3893402",
    lng: "-4.3169297",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEA",
    portName: "Penysarn",
    portContinent: "Europe",
  },
  {
    lat: "51.52108699999999",
    lng: "-3.49837",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEC",
    portName: "Pencoed, Bridgend",
    portContinent: "Europe",
  },
  {
    lat: "51.691894",
    lng: "-4.943763",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPED",
    portName: "Pembroke Dock",
    portContinent: "Europe",
  },
  {
    lat: "55.653071",
    lng: "-3.193642",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEE",
    portName: "Peebles",
    portContinent: "Europe",
  },
  {
    lat: "53.0487499",
    lng: "-3.6808851",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEF",
    portName: "PentreFoelas",
    portContinent: "Europe",
  },
  {
    lat: "55.99320909999999",
    lng: "-3.4074815",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEG",
    portName: "PortEdgar",
    portContinent: "Europe",
  },
  {
    lat: "51.438798",
    lng: "-3.173507",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEH",
    portName: "Penarth, Vale Of Glamorgan",
    portContinent: "Europe",
  },
  {
    lat: "53.526141",
    lng: "-1.6255329",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEI",
    portName: "Penistone, South Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "52.5851201",
    lng: "-3.9215791",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEL",
    portName: "Pennal",
    portContinent: "Europe",
  },
  {
    lat: "51.69542920000001",
    lng: "-4.951929499999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEM",
    portName: "Pembroke Port",
    portContinent: "Europe",
  },
  {
    lat: "50.1183202",
    lng: "-5.5314147",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEN",
    portName: "Penzance Harbour",
    portContinent: "Europe",
  },
  {
    lat: "56.51939969999999",
    lng: "-5.898743899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEO",
    portName: "Pennygown",
    portContinent: "Europe",
  },
  {
    lat: "51.0832565",
    lng: "-0.2028485",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEP",
    portName: "Pease Pottage",
    portContinent: "Europe",
  },
  {
    lat: "-31.9523123",
    lng: "115.861309",
    country: "AU",
    country_long: "Australia",
    portCode: "GBPER",
    portName: "Perth",
    portContinent: "Europe",
  },
  {
    lat: "50.8209317",
    lng: "0.3397311",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPES",
    portName: "Pevensey",
    portContinent: "Europe",
  },
  {
    lat: "52.57031689999999",
    lng: "-0.2407996999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPET",
    portName: "Peterborough",
    portContinent: "Europe",
  },
  {
    lat: "52.24797",
    lng: "-0.116833",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEV",
    portName: "PapworthEverard",
    portContinent: "Europe",
  },
  {
    lat: "50.9867009",
    lng: "-0.6107241999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEW",
    portName: "Petworth, West Sussex",
    portContinent: "Europe",
  },
  {
    lat: "52.1444579",
    lng: "1.353191",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPEX",
    portName: "Pettistree",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBPEY",
    portName: "Penderyn",
    portContinent: "Europe",
  },
  {
    lat: "51.007591",
    lng: "-0.93899",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPFD",
    portName: "Petersfield, Hampshire",
    portContinent: "Europe",
  },
  {
    lat: "51.6452225",
    lng: "-0.04863729999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPFF",
    portName: "PondersEnd",
    portContinent: "Europe",
  },
  {
    lat: "51.48671059999999",
    lng: "0.2433122",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPFT",
    portName: "Purfleet",
    portContinent: "Europe",
  },
  {
    lat: "54.38173519999999",
    lng: "-5.5469817",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPFY",
    portName: "Portaferry",
    portContinent: "Europe",
  },
  {
    lat: "50.7691574",
    lng: "-0.7468665",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPGA",
    portName: "Pagham",
    portContinent: "Europe",
  },
  {
    lat: "57.66356589999999",
    lng: "-3.0150179",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPGD",
    portName: "PortGordon",
    portContinent: "Europe",
  },
  {
    lat: "55.932291",
    lng: "-4.679806999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPGG",
    portName: "Port Glasgow (GBPGG), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.947171",
    lng: "-5.180185",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPGI",
    portName: "Porthgain",
    portContinent: "Europe",
  },
  {
    lat: "52.590622",
    lng: "-2.267975",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPGM",
    portName: "Pattingham",
    portContinent: "Europe",
  },
  {
    lat: "50.43508",
    lng: "-3.564235",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPGN",
    portName: "Paignton",
    portContinent: "Europe",
  },
  {
    lat: "51.3339",
    lng: "-1.127691",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPGR",
    portName: "PamberGreen",
    portContinent: "Europe",
  },
  {
    lat: "50.824012",
    lng: "0.243813",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPGT",
    portName: "Polegate",
    portContinent: "Europe",
  },
  {
    lat: "50.59252300000001",
    lng: "-4.822687999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPGV",
    portName: "PortGaverne",
    portContinent: "Europe",
  },
  {
    lat: "51.654745",
    lng: "-3.491629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPGW",
    portName: "Pentre-bÃ¢ch",
    portContinent: "Europe",
  },
  {
    lat: "57.50812299999999",
    lng: "-1.784066",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPHD",
    portName: "Peterhead",
    portContinent: "Europe",
  },
  {
    lat: "52.7228319",
    lng: "1.573341",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPHG",
    portName: "PotterHeigham",
    portContinent: "Europe",
  },
  {
    lat: "55.868646",
    lng: "-2.973874",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPHH",
    portName: "Pathhead",
    portContinent: "Europe",
  },
  {
    lat: "44.6561639",
    lng: "-76.71665800000001",
    country: "CA",
    country_long: "Canada",
    portCode: "GBPHM",
    portName: "Parham",
    portContinent: "Europe",
  },
  {
    lat: "50.739407",
    lng: "-3.473854",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPHO",
    portName: "Pinhoe",
    portContinent: "Europe",
  },
  {
    lat: "51.783198",
    lng: "-4.042148",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPHR",
    portName: "CapelHendre",
    portContinent: "Europe",
  },
  {
    lat: "50.793209",
    lng: "-0.005174",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPHV",
    portName: "Peacehaven",
    portContinent: "Europe",
  },
  {
    lat: "51.804439",
    lng: "0.411261",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPHY",
    portName: "Pleshey",
    portContinent: "Europe",
  },
  {
    lat: "51.0517546",
    lng: "-2.783409",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPI7",
    portName: "Pitney",
    portContinent: "Europe",
  },
  {
    lat: "54.243925",
    lng: "-0.7775529999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPIC",
    portName: "Pickering, North Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "59.31852",
    lng: "-2.99125",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPIE",
    portName: "Pierowall,Westray",
    portContinent: "Europe",
  },
  {
    lat: "54.5772323",
    lng: "-2.7974835",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPIH",
    portName: "Penrith, Cumbria",
    portContinent: "Europe",
  },
  {
    lat: "55.495587",
    lng: "-4.6142139",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPIK",
    portName: "Prestwick",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBPIL",
    portName: "Pilling",
    portContinent: "Europe",
  },
  {
    lat: "51.595172",
    lng: "-0.378002",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPIN",
    portName: "Pinner",
    portContinent: "Europe",
  },
  {
    lat: "53.675254",
    lng: "-1.939549",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPIR",
    portName: "Ripponden, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "56.307038",
    lng: "-2.943688",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPIS",
    portName: "Pitscottie",
    portContinent: "Europe",
  },
  {
    lat: "51.5487013",
    lng: "0.7072254",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPIT",
    portName: "Prittlewell",
    portContinent: "Europe",
  },
  {
    lat: "42.7789649",
    lng: "-74.06457089999999",
    country: "US",
    country_long: "United States",
    portCode: "GBPIW",
    portName: "Princetown",
    portContinent: "Europe",
  },
  {
    lat: "53.091157",
    lng: "-1.322114",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPIX",
    portName: "Pinxton, Derbyshire",
    portContinent: "Europe",
  },
  {
    lat: "58.56402699999999",
    lng: "-3.9336499",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPKA",
    portName: "Portskerra",
    portContinent: "Europe",
  },
  {
    lat: "52.72515500000001",
    lng: "-2.116372",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPKD",
    portName: "Penkridge",
    portContinent: "Europe",
  },
  {
    lat: "52.72515500000001",
    lng: "-2.116372",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPKE",
    portName: "Penkridge, Staffordshire",
    portContinent: "Europe",
  },
  {
    lat: "53.4496194",
    lng: "-1.3448797",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPKG",
    portName: "ParkGate",
    portContinent: "Europe",
  },
  {
    lat: "53.9300565",
    lng: "-0.7794059",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPKL",
    portName: "Pocklington",
    portContinent: "Europe",
  },
  {
    lat: "57.703032",
    lng: "-2.859311",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPKN",
    portName: "Portknockie",
    portContinent: "Europe",
  },
  {
    lat: "54.243925",
    lng: "-0.7775529999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPKR",
    portName: "Pickering",
    portContinent: "Europe",
  },
  {
    lat: "47.48983",
    lng: "-68.785777",
    country: "CA",
    country_long: "Canada",
    portCode: "GBPKT",
    portName: "Packington",
    portContinent: "Europe",
  },
  {
    lat: "51.1771537",
    lng: "0.7560578",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPKY",
    portName: "Pluckley",
    portContinent: "Europe",
  },
  {
    lat: "51.670418",
    lng: "-4.007427",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLA",
    portName: "Penllergaer",
    portContinent: "Europe",
  },
  {
    lat: "56.704361",
    lng: "-3.729711",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLC",
    portName: "Pitlochry",
    portContinent: "Europe",
  },
  {
    lat: "52.00734010000001",
    lng: "0.9025373999999998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLD",
    portName: "Polstead",
    portContinent: "Europe",
  },
  {
    lat: "51.4754669",
    lng: "-0.5118773000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLE",
    portName: "Poyle",
    portContinent: "Europe",
  },
  {
    lat: "53.843964",
    lng: "-2.986281",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLF",
    portName: "Poulton-le-Fylde",
    portContinent: "Europe",
  },
  {
    lat: "51.6214872",
    lng: "-0.1075657",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLG",
    portName: "PalmersGreen",
    portContinent: "Europe",
  },
  {
    lat: "55.8427824",
    lng: "-4.269419099999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLL",
    portName: "Pollokshields",
    portContinent: "Europe",
  },
  {
    lat: "40.3840568",
    lng: "-75.11951069999999",
    country: "US",
    country_long: "United States",
    portCode: "GBPLM",
    portName: "Plumstead",
    portContinent: "Europe",
  },
  {
    lat: "55.6301806",
    lng: "-6.1874153",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLN",
    portName: "PortEllen",
    portContinent: "Europe",
  },
  {
    lat: "57.3406167",
    lng: "-5.6490887",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLO",
    portName: "Plockton",
    portContinent: "Europe",
  },
  {
    lat: "50.3310267",
    lng: "-4.520718",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLP",
    portName: "Polperro",
    portContinent: "Europe",
  },
  {
    lat: "50.32738699999999",
    lng: "-4.633501",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLR",
    portName: "Polruan",
    portContinent: "Europe",
  },
  {
    lat: "52.60705600000001",
    lng: "-1.2990564",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLT",
    portName: "Peckleton",
    portContinent: "Europe",
  },
  {
    lat: "50.085305",
    lng: "-5.315853",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLV",
    portName: "Porthleven (GBPLV), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "42.8364781",
    lng: "-71.0947805",
    country: "US",
    country_long: "United States",
    portCode: "GBPLW",
    portName: "Plaistow",
    portContinent: "Europe",
  },
  {
    lat: "51.9938746",
    lng: "-0.4548222",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPLX",
    portName: "Pulloxhill",
    portContinent: "Europe",
  },
  {
    lat: "41.9584457",
    lng: "-70.6672621",
    country: "US",
    country_long: "United States",
    portCode: "GBPLY",
    portName: "Plymouth",
    portContinent: "Europe",
  },
  {
    lat: "51.1474345",
    lng: "0.3288283",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPMB",
    portName: "Pembury",
    portContinent: "Europe",
  },
  {
    lat: "52.924522",
    lng: "-4.126833",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPMD",
    portName: "Porthmadog Harbor",
    portContinent: "Europe",
  },
  {
    lat: "43.0717552",
    lng: "-70.7625532",
    country: "US",
    country_long: "United States",
    portCode: "GBPME",
    portName: "Portsmouth",
    portContinent: "Europe",
  },
  {
    lat: "50.9020516",
    lng: "-0.0618475",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPMG",
    portName: "PlumptonGreen",
    portContinent: "Europe",
  },
  {
    lat: "50.978169",
    lng: "0.6779459999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPMH",
    portName: "Peasmarsh",
    portContinent: "Europe",
  },
  {
    lat: "55.645136",
    lng: "-5.382107",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPMI",
    portName: "Pirnmill",
    portContinent: "Europe",
  },
  {
    lat: "57.835818",
    lng: "-3.827526199999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPMK",
    portName: "Portmahomack",
    portContinent: "Europe",
  },
  {
    lat: "53.266577",
    lng: "-3.92065",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPMM",
    portName: "Penmaenmawr",
    portContinent: "Europe",
  },
  {
    lat: "17.9611269",
    lng: "-76.88939069999999",
    country: "JM",
    country_long: "Jamaica",
    portCode: "GBPMO",
    portName: "Portmore",
    portContinent: "Europe",
  },
  {
    lat: "56.833275",
    lng: "-6.224184999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPMR",
    portName: "Port Mor (GBPMR), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.978169",
    lng: "0.6779459999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPMS",
    portName: "Peasmarsh",
    portContinent: "Europe",
  },
  {
    lat: "41.95589529999999",
    lng: "-70.8148435",
    country: "US",
    country_long: "United States",
    portCode: "GBPMT",
    portName: "Plympton",
    portContinent: "Europe",
  },
  {
    lat: "51.69157300000001",
    lng: "-4.286182999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPMY",
    portName: "Pembrey",
    portContinent: "Europe",
  },
  {
    lat: "25.5940947",
    lng: "85.1375645",
    country: "IN",
    country_long: "India",
    portCode: "GBPNA",
    portName: "Patna",
    portContinent: "Europe",
  },
  {
    lat: "50.0474836",
    lng: "-5.6291091",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPNB",
    portName: "Penberth",
    portContinent: "Europe",
  },
  {
    lat: "51.521579",
    lng: "-3.393295",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPNC",
    portName: "Pontyclun",
    portContinent: "Europe",
  },
  {
    lat: "51.52108699999999",
    lng: "-3.49837",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPND",
    portName: "Pencoed",
    portContinent: "Europe",
  },
  {
    lat: "50.151696",
    lng: "-5.664421",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPNE",
    portName: "Pendeen",
    portContinent: "Europe",
  },
  {
    lat: "57.327313",
    lng: "-6.402561",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPNG",
    portName: "Portnalong",
    portContinent: "Europe",
  },
  {
    lat: "51.438798",
    lng: "-3.173507",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPNH",
    portName: "Penarth",
    portContinent: "Europe",
  },
  {
    lat: "50.503952",
    lng: "-4.40257",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPNI",
    portName: "Pensilva",
    portContinent: "Europe",
  },
  {
    lat: "55.049871",
    lng: "-1.739844",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPNL",
    portName: "Ponteland",
    portContinent: "Europe",
  },
  {
    lat: "41.2033216",
    lng: "-77.1945247",
    country: "US",
    country_long: "United States",
    portCode: "GBPNN",
    portName: "Penn",
    portContinent: "Europe",
  },
  {
    lat: "53.371416",
    lng: "-3.0390229",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPNO",
    portName: "Prenton",
    portContinent: "Europe",
  },
  {
    lat: "50.344434",
    lng: "-5.154444",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPNP",
    portName: "Perranporth (GBPNP), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "-33.7506759",
    lng: "150.687674",
    country: "AU",
    country_long: "Australia",
    portCode: "GBPNR",
    portName: "Penrith",
    portContinent: "Europe",
  },
  {
    lat: "53.526141",
    lng: "-1.6255329",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPNS",
    portName: "Penistone",
    portContinent: "Europe",
  },
  {
    lat: "51.656335",
    lng: "-3.182134",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPNT",
    portName: "Pontllanfraith",
    portContinent: "Europe",
  },
  {
    lat: "55.681117",
    lng: "-6.507585",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPNV",
    portName: "Portnahaven",
    portContinent: "Europe",
  },
  {
    lat: "51.60077399999999",
    lng: "-3.342314",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPNY",
    portName: "Pontypridd",
    portContinent: "Europe",
  },
  {
    lat: "51.72043499999999",
    lng: "-3.853214999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOA",
    portName: "Pontardawe",
    portContinent: "Europe",
  },
  {
    lat: "51.696636",
    lng: "-0.175948",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOB",
    portName: "Potters Bar",
    portContinent: "Europe",
  },
  {
    lat: "65.2619771",
    lng: "-166.8471534",
    country: "US",
    country_long: "United States",
    portCode: "GBPOC",
    portName: "PortClarence",
    portContinent: "Europe",
  },
  {
    lat: "57.232011",
    lng: "-2.091164",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOE",
    portName: "Potterton",
    portContinent: "Europe",
  },
  {
    lat: "53.691688",
    lng: "-1.308648",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOF",
    portName: "Pontefract",
    portContinent: "Europe",
  },
  {
    lat: "54.723977",
    lng: "-4.956053",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOG",
    portName: "Port Logan",
    portContinent: "Europe",
  },
  {
    lat: "50.055567",
    lng: "-5.067445999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOH",
    portName: "Porthoustock",
    portContinent: "Europe",
  },
  {
    lat: "56.5005914",
    lng: "-5.5263342",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOI",
    portName: "Point,IslandofLismore",
    portContinent: "Europe",
  },
  {
    lat: "52.471803",
    lng: "-0.428378",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOL",
    portName: "Polebrook",
    portContinent: "Europe",
  },
  {
    lat: "54.59431550000001",
    lng: "-6.9290099",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOM",
    portName: "Pomeroy",
    portContinent: "Europe",
  },
  {
    lat: "51.324181",
    lng: "-2.008973",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPON",
    portName: "Potterne",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBPOO",
    portName: "Poole",
    portContinent: "Europe",
  },
  {
    lat: "45.083333",
    lng: "-72.36666699999999",
    country: "CA",
    country_long: "Canada",
    portCode: "GBPOP",
    portName: "Potton",
    portContinent: "Europe",
  },
  {
    lat: "51.613554",
    lng: "-3.407184",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOR",
    portName: "Porth",
    portContinent: "Europe",
  },
  {
    lat: "51.485155",
    lng: "-2.767948",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOS",
    portName: "Portishead, Somerset",
    portContinent: "Europe",
  },
  {
    lat: "57.061681",
    lng: "-2.129468",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOT",
    portName: "Portlethen",
    portContinent: "Europe",
  },
  {
    lat: "53.843964",
    lng: "-2.986281",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOU",
    portName: "Poulton-Le-Flyde, Lancashire",
    portContinent: "Europe",
  },
  {
    lat: "51.47896799999999",
    lng: "-3.705163",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOW",
    portName: "Porthcawl, Bridgend",
    portContinent: "Europe",
  },
  {
    lat: "53.348654",
    lng: "-2.121572",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPOY",
    portName: "Poynton",
    portContinent: "Europe",
  },
  {
    lat: "40.7156083",
    lng: "-74.65910099999999",
    country: "US",
    country_long: "United States",
    portCode: "GBPPC",
    portName: "Peapack",
    portContinent: "Europe",
  },
  {
    lat: "52.92924",
    lng: "-4.067134",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPPE",
    portName: "Penrhyndeudreath",
    portContinent: "Europe",
  },
  {
    lat: "52.9629632",
    lng: "-2.3913297",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPPG",
    portName: "PipeGate",
    portContinent: "Europe",
  },
  {
    lat: "54.84279",
    lng: "-5.116057",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPPK",
    portName: "Portpatrick",
    portContinent: "Europe",
  },
  {
    lat: "51.5123792",
    lng: "-0.0087975",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPPL",
    portName: "Poplar",
    portContinent: "Europe",
  },
  {
    lat: "57.3923285",
    lng: "-7.247601400000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPPO",
    portName: "Saint Peter Port (GBPPO), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.95953799999999",
    lng: "-2.984467",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPPS",
    portName: "Prestonpans",
    portContinent: "Europe",
  },
  {
    lat: "59.3506882",
    lng: "-2.9003583",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPPW",
    portName: "Papa Westray (GBPPW), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.101679",
    lng: "-0.952023",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPPY",
    portName: "Paulerspury",
    portContinent: "Europe",
  },
  {
    lat: "51.712266",
    lng: "-4.037071",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPQI",
    portName: "Pontarddulais, Swansea",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBPQL",
    portName: "Pool",
    portContinent: "Europe",
  },
  {
    lat: "50.553664",
    lng: "-3.543173",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPQT",
    portName: "Bishopsteignton",
    portContinent: "Europe",
  },
  {
    lat: "52.898764",
    lng: "-2.664916",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRA",
    portName: "Prees, Shropshire",
    portContinent: "Europe",
  },
  {
    lat: "53.3159407",
    lng: "-2.6536604",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRB",
    portName: "Preston Brook",
    portContinent: "Europe",
  },
  {
    lat: "41.0017643",
    lng: "-73.66568339999999",
    country: "US",
    country_long: "United States",
    portCode: "GBPRC",
    portName: "Portchester",
    portContinent: "Europe",
  },
  {
    lat: "70.2268843",
    lng: "-148.400021",
    country: "US",
    country_long: "United States",
    portCode: "GBPRD",
    portName: "Prudhoe",
    portContinent: "Europe",
  },
  {
    lat: "53.763201",
    lng: "-2.70309",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRE",
    portName: "Preston",
    portContinent: "Europe",
  },
  {
    lat: "53.480299",
    lng: "-2.351027",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRF",
    portName: "Patricroft",
    portContinent: "Europe",
  },
  {
    lat: "50.958975",
    lng: "-0.302653",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRG",
    portName: "PartridgeGreen",
    portContinent: "Europe",
  },
  {
    lat: "50.58420520000001",
    lng: "-2.4462857",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRH",
    portName: "PortlandHarbour",
    portContinent: "Europe",
  },
  {
    lat: "51.724376",
    lng: "-0.834518",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRI",
    portName: "Princes Risborough, Buckinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "50.260642",
    lng: "-5.288665",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRJ",
    portName: "Portreath (GBPRJ), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5908656",
    lng: "-2.7211736",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRK",
    portName: "Portskewett",
    portContinent: "Europe",
  },
  {
    lat: "50.219505",
    lng: "-4.8909921",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRL",
    portName: "Portloe",
    portContinent: "Europe",
  },
  {
    lat: "50.8197675",
    lng: "-1.0879769",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRM",
    portName: "Portsmouth, Hampshire",
    portContinent: "Europe",
  },
  {
    lat: "58.25706099999999",
    lng: "-6.16345",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRN",
    portName: "PortnaguranandNess",
    portContinent: "Europe",
  },
  {
    lat: "51.58755799999999",
    lng: "-1.876232",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRO",
    portName: "Purton",
    portContinent: "Europe",
  },
  {
    lat: "56.099427",
    lng: "-4.844295",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRP",
    portName: "Portincaple",
    portContinent: "Europe",
  },
  {
    lat: "51.724376",
    lng: "-0.834518",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRR",
    portName: "PrincesRisborough",
    portContinent: "Europe",
  },
  {
    lat: "53.336512",
    lng: "-3.407613",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRS",
    portName: "Prestatyn",
    portContinent: "Europe",
  },
  {
    lat: "57.412474",
    lng: "-6.196022999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRT",
    portName: "Portree",
    portContinent: "Europe",
  },
  {
    lat: "51.4742156",
    lng: "-2.7211087",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRU",
    portName: "Portbury (GBPRU), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.532222",
    lng: "-0.324477",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRV",
    portName: "Perivale",
    portContinent: "Europe",
  },
  {
    lat: "53.52703899999999",
    lng: "-2.28064",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRW",
    portName: "Prestwich",
    portContinent: "Europe",
  },
  {
    lat: "38.8519794",
    lng: "-121.1692868",
    country: "US",
    country_long: "United States",
    portCode: "GBPRY",
    portName: "Penryn Harbor",
    portContinent: "Europe",
  },
  {
    lat: "51.2090109",
    lng: "-3.595055",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPRZ",
    portName: "Porlock",
    portContinent: "Europe",
  },
  {
    lat: "53.917914",
    lng: "-2.965968",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPSA",
    portName: "Preesall",
    portContinent: "Europe",
  },
  {
    lat: "52.769383",
    lng: "0.18895",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPSB",
    portName: "Sutton Bridge (GBPSB), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.17943289999999",
    lng: "-4.975047",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPSC",
    portName: "Portscatho",
    portContinent: "Europe",
  },
  {
    lat: "55.9693996",
    lng: "-2.9626269",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPSE",
    portName: "PortSeton",
    portContinent: "Europe",
  },
  {
    lat: "52.27203100000001",
    lng: "-3.005157",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPSG",
    portName: "Presteigne",
    portContinent: "Europe",
  },
  {
    lat: "52.109994",
    lng: "-2.074814",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPSH",
    portName: "Pershore",
    portContinent: "Europe",
  },
  {
    lat: "53.308327",
    lng: "-2.084669",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPSI",
    portName: "PottShrigley",
    portContinent: "Europe",
  },
  {
    lat: "54.07611",
    lng: "-4.739268",
    country: "IM",
    country_long: "Isle of Man",
    portCode: "GBPSM",
    portName: "PortSaintMary",
    portContinent: "Europe",
  },
  {
    lat: "53.763201",
    lng: "-2.70309",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPSO",
    portName: "Preston",
    portContinent: "Europe",
  },
  {
    lat: "53.2948359",
    lng: "-3.578062",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPSR",
    portName: "Pensarn",
    portContinent: "Europe",
  },
  {
    lat: "51.94448",
    lng: "1.255846",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPST",
    portName: "ParkestonQuay",
    portContinent: "Europe",
  },
  {
    lat: "53.3489608",
    lng: "-2.9879639",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPSU",
    portName: "PortSunlight",
    portContinent: "Europe",
  },
  {
    lat: "60.33060879999999",
    lng: "-1.6949336",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPSV",
    portName: "PapaStour",
    portContinent: "Europe",
  },
  {
    lat: "55.1868773",
    lng: "-6.717104099999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPSW",
    portName: "Portstewart",
    portContinent: "Europe",
  },
  {
    lat: "57.681023",
    lng: "-2.688412",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPSY",
    portName: "Portsoy",
    portContinent: "Europe",
  },
  {
    lat: "51.834248",
    lng: "-0.6427919999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPT2",
    portName: "Pitstone",
    portContinent: "Europe",
  },
  {
    lat: "52.620606",
    lng: "-1.6155139",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPT5",
    portName: "Polesworth",
    portContinent: "Europe",
  },
  {
    lat: "55.669835",
    lng: "-3.662689",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTA",
    portName: "Pettinain",
    portContinent: "Europe",
  },
  {
    lat: "51.5946799",
    lng: "-3.784097",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTB",
    portName: "Port Talbot",
    portContinent: "Europe",
  },
  {
    lat: "56.062641",
    lng: "-3.177638",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTC",
    portName: "Pettycur",
    portContinent: "Europe",
  },
  {
    lat: "52.927842",
    lng: "-4.133384",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTD",
    portName: "Porthmadog",
    portContinent: "Europe",
  },
  {
    lat: "54.762441",
    lng: "-1.32895",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTE",
    portName: "Peterlee",
    portContinent: "Europe",
  },
  {
    lat: "51.007591",
    lng: "-0.93899",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTF",
    portName: "Petersfield",
    portContinent: "Europe",
  },
  {
    lat: "50.0377386",
    lng: "-5.6723732",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTG",
    portName: "Porthgwarra",
    portContinent: "Europe",
  },
  {
    lat: "51.485155",
    lng: "-2.767948",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTH",
    portName: "Portishead",
    portContinent: "Europe",
  },
  {
    lat: "51.941736",
    lng: "-2.87804",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTI",
    portName: "Pontrilas",
    portContinent: "Europe",
  },
  {
    lat: "45.6422482",
    lng: "-122.7514897",
    country: "US",
    country_long: "United States",
    portCode: "GBPTL",
    portName: "Portland Port",
    portContinent: "Europe",
  },
  {
    lat: "51.2219535",
    lng: "-0.6684163",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTN",
    portName: "Puttenham",
    portContinent: "Europe",
  },
  {
    lat: "38.9424059",
    lng: "-77.02557999999999",
    country: "US",
    country_long: "United States",
    portCode: "GBPTO",
    portName: "Petworth",
    portContinent: "Europe",
  },
  {
    lat: "51.700369",
    lng: "-3.045268",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTP",
    portName: "Pontypool",
    portContinent: "Europe",
  },
  {
    lat: "55.2041945",
    lng: "-6.652670899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTR",
    portName: "Portrush",
    portContinent: "Europe",
  },
  {
    lat: "50.842729",
    lng: "-0.21423",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTS",
    portName: "Portslade",
    portContinent: "Europe",
  },
  {
    lat: "53.418359",
    lng: "-2.428085",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTT",
    portName: "Partington",
    portContinent: "Europe",
  },
  {
    lat: "54.4203331",
    lng: "-6.4548387",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPTW",
    portName: "Portadown",
    portContinent: "Europe",
  },
  {
    lat: "35.9468937",
    lng: "-84.1135603",
    country: "US",
    country_long: "United States",
    portCode: "GBPTX",
    portName: "Porton",
    portContinent: "Europe",
  },
  {
    lat: "42.975268",
    lng: "-72.5217237",
    country: "US",
    country_long: "United States",
    portCode: "GBPTY",
    portName: "Putney",
    portContinent: "Europe",
  },
  {
    lat: "53.795766",
    lng: "-1.6761082",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPUD",
    portName: "Pudsey, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "50.957304",
    lng: "-0.510671",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPUL",
    portName: "Pulborough",
    portContinent: "Europe",
  },
  {
    lat: "50.87698",
    lng: "-2.863086",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPUN",
    portName: "Purtington",
    portContinent: "Europe",
  },
  {
    lat: "51.306015",
    lng: "-2.503056",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPUO",
    portName: "Paulton, Somerset",
    portContinent: "Europe",
  },
  {
    lat: "51.339228",
    lng: "-0.119711",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPUR",
    portName: "Purley",
    portContinent: "Europe",
  },
  {
    lat: "55.87354699999999",
    lng: "-5.31034",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPVD",
    portName: "Portavadie",
    portContinent: "Europe",
  },
  {
    lat: "51.532222",
    lng: "-0.324477",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPVE",
    portName: "Perivale, Greater London",
    portContinent: "Europe",
  },
  {
    lat: "54.46208009999999",
    lng: "-5.447429899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPVG",
    portName: "Portavogie (GBPVG), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.48481",
    lng: "-2.912533",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPW5",
    portName: "Plowden",
    portContinent: "Europe",
  },
  {
    lat: "52.34191329999999",
    lng: "-0.3446047",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPWC",
    portName: "Spaldwick",
    portContinent: "Europe",
  },
  {
    lat: "51.694539",
    lng: "-0.7375469",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPWD",
    portName: "Prestwood",
    portContinent: "Europe",
  },
  {
    lat: "57.765127",
    lng: "-5.605190899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPWE",
    portName: "Poolewe",
    portContinent: "Europe",
  },
  {
    lat: "54.759602",
    lng: "-4.5827271",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPWI",
    portName: "PortWilliam",
    portContinent: "Europe",
  },
  {
    lat: "50.364143",
    lng: "-4.3139481",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPWK",
    portName: "Portwrinkle",
    portContinent: "Europe",
  },
  {
    lat: "52.888816",
    lng: "-4.4176339",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPWL",
    portName: "Pwllheli",
    portContinent: "Europe",
  },
  {
    lat: "56.214",
    lng: "-2.732285",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPWM",
    portName: "Pittenweem",
    portContinent: "Europe",
  },
  {
    lat: "51.339277",
    lng: "-1.76478",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPWS",
    portName: "Pewsey",
    portContinent: "Europe",
  },
  {
    lat: "51.3926045",
    lng: "-1.1133804",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPWT",
    portName: "Padworth",
    portContinent: "Europe",
  },
  {
    lat: "51.5316526",
    lng: "-2.5765851",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPWY",
    portName: "Patchway",
    portContinent: "Europe",
  },
  {
    lat: "51.2584656",
    lng: "0.2954812",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPXT",
    portName: "Plaxtol",
    portContinent: "Europe",
  },
  {
    lat: "51.5278587",
    lng: "-0.2618239",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPYA",
    portName: "Park Royal London",
    portContinent: "Europe",
  },
  {
    lat: "53.0715797",
    lng: "-1.3438788",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPYB",
    portName: "PyeBridge",
    portContinent: "Europe",
  },
  {
    lat: "51.810283",
    lng: "-3.677291",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPYC",
    portName: "Pen-y-cae",
    portContinent: "Europe",
  },
  {
    lat: "51.4754669",
    lng: "-0.5118773000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPYE",
    portName: "Poyle",
    portContinent: "Europe",
  },
  {
    lat: "50.97842",
    lng: "-2.4994799",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPYG",
    portName: "Poyntington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBPYL",
    portName: "Pyle",
    portContinent: "Europe",
  },
  {
    lat: "51.3144751",
    lng: "-0.5089739999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBPYR",
    portName: "Pyrford",
    portContinent: "Europe",
  },
  {
    lat: "55.412744",
    lng: "-1.706299",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQAK",
    portName: "Alnwick, Northumberland",
    portContinent: "Europe",
  },
  {
    lat: "52.702546",
    lng: "-1.040374",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQBG",
    portName: "Queniborough",
    portContinent: "Europe",
  },
  {
    lat: "51.363583",
    lng: "-0.296254",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQCH",
    portName: "Chessington, Surrey",
    portContinent: "Europe",
  },
  {
    lat: "53.6318569",
    lng: "-2.670545",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQCR",
    portName: "Charnock Richard, Lancashire",
    portContinent: "Europe",
  },
  {
    lat: "51.823669",
    lng: "-2.277564",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQDL",
    portName: "Quedgeley",
    portContinent: "Europe",
  },
  {
    lat: "53.695451",
    lng: "-2.46874",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQDW",
    portName: "Darwen, Lancashire",
    portContinent: "Europe",
  },
  {
    lat: "55.99000299999999",
    lng: "-3.399045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQFR",
    portName: "Queensferry",
    portContinent: "Europe",
  },
  {
    lat: "51.7368873",
    lng: "-1.7894722",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQGO",
    portName: "Quenington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBQGT",
    portName: "WallesbourneHastings",
    portContinent: "Europe",
  },
  {
    lat: "51.1777448",
    lng: "-1.034798",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQLA",
    portName: "Lasham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBQLT",
    portName: "Elton",
    portContinent: "Europe",
  },
  {
    lat: "51.113338",
    lng: "-2.452895",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQMV",
    portName: "Bruton",
    portContinent: "Europe",
  },
  {
    lat: "55.1074711",
    lng: "-1.664263",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQNO",
    portName: "Stannington",
    portContinent: "Europe",
  },
  {
    lat: "50.9144122",
    lng: "-1.5656228",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQRA",
    portName: "Bartley",
    portContinent: "Europe",
  },
  {
    lat: "52.488674",
    lng: "-2.046085",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQRO",
    portName: "Rowley Regis, West Midlands",
    portContinent: "Europe",
  },
  {
    lat: "53.706709",
    lng: "-1.913411",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQSA",
    portName: "Sowerby Bridge, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "51.873908",
    lng: "-0.9158850000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQTN",
    portName: "Quainton",
    portContinent: "Europe",
  },
  {
    lat: "51.772619",
    lng: "-3.246775",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQTR",
    portName: "Tredegar, Blaenau Gwent",
    portContinent: "Europe",
  },
  {
    lat: "51.4172853",
    lng: "0.7470207",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQUB",
    portName: "Queenborough",
    portContinent: "Europe",
  },
  {
    lat: "51.823669",
    lng: "-2.277564",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQUD",
    portName: "Quedgeley, Gloucestershire",
    portContinent: "Europe",
  },
  {
    lat: "53.208293",
    lng: "-3.020103",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQUE",
    portName: "Queensferry, Flintshire",
    portContinent: "Europe",
  },
  {
    lat: "50.858064",
    lng: "-0.7548509999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQUG",
    portName: "GoodwoodAirport",
    portContinent: "Europe",
  },
  {
    lat: "52.335443",
    lng: "-0.12806",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQUY",
    portName: "Wyton",
    portContinent: "Europe",
  },
  {
    lat: "51.07450300000001",
    lng: "1.1391534",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQVG",
    portName: "Sandgate",
    portContinent: "Europe",
  },
  {
    lat: "50.824734",
    lng: "-0.322893",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQVY",
    portName: "SouthLancing",
    portContinent: "Europe",
  },
  {
    lat: "52.771318",
    lng: "-1.554997",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQWH",
    portName: "Swadlincote",
    portContinent: "Europe",
  },
  {
    lat: "38.0614198",
    lng: "-105.0944196",
    country: "US",
    country_long: "United States",
    portCode: "GBQWR",
    portName: "Bishops Castle",
    portContinent: "Europe",
  },
  {
    lat: "52.20499179999999",
    lng: "-0.2948223",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQWY",
    portName: "Wyboston",
    portContinent: "Europe",
  },
  {
    lat: "51.4284337",
    lng: "-2.8280061",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBQZL",
    portName: "Clevedon",
    portContinent: "Europe",
  },
  {
    lat: "57.46101100000001",
    lng: "-6.009466",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAA",
    portName: "Arnish",
    portContinent: "Europe",
  },
  {
    lat: "53.6836372",
    lng: "-0.9431679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAC",
    portName: "Rawcliffe Bridge, East Riding Of Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRAD",
    portName: "Radley",
    portContinent: "Europe",
  },
  {
    lat: "53.561449",
    lng: "-2.326769",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAF",
    portName: "Radcliffe, Greater Manchester",
    portContinent: "Europe",
  },
  {
    lat: "51.517732",
    lng: "0.194831",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAH",
    portName: "Rainham",
    portContinent: "Europe",
  },
  {
    lat: "51.517732",
    lng: "0.194831",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAI",
    portName: "Rainham, Essex",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRAK",
    portName: "Rake",
    portContinent: "Europe",
  },
  {
    lat: "52.392562",
    lng: "-1.996504",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAL",
    portName: "Rednal",
    portContinent: "Europe",
  },
  {
    lat: "53.4616366",
    lng: "-1.3442806",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAM",
    portName: "Rawmarsh",
    portContinent: "Europe",
  },
  {
    lat: "30.234925",
    lng: "-92.26846169999999",
    country: "US",
    country_long: "United States",
    portCode: "GBRAN",
    portName: "Rayne",
    portContinent: "Europe",
  },
  {
    lat: "59.255413",
    lng: "-2.857578",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAP",
    portName: "Rapness (GBRAP), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.7241659",
    lng: "-1.0375956",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAR",
    portName: "Rearsby",
    portContinent: "Europe",
  },
  {
    lat: "57.39695880000001",
    lng: "-6.0480785",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAS",
    portName: "Clachan,Raasay",
    portContinent: "Europe",
  },
  {
    lat: "54.2381042",
    lng: "-6.161147799999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAT",
    portName: "Rathfriland",
    portContinent: "Europe",
  },
  {
    lat: "52.34169499999999",
    lng: "-0.541032",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAU",
    portName: "Raunds, Northamptonshire",
    portContinent: "Europe",
  },
  {
    lat: "53.6778978",
    lng: "-1.6672555",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAV",
    portName: "RavensthorpeDewsbury",
    portContinent: "Europe",
  },
  {
    lat: "53.7004862",
    lng: "-2.3014596",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAW",
    portName: "Rawtenstall",
    portContinent: "Europe",
  },
  {
    lat: "55.835963",
    lng: "-5.055793",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRAY",
    portName: "Rothesay,IsleofBute",
    portContinent: "Europe",
  },
  {
    lat: "54.4343549",
    lng: "-0.5350049",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRBB",
    portName: "RobinHoodsBay",
    portContinent: "Europe",
  },
  {
    lat: "53.6836372",
    lng: "-0.9431679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRBD",
    portName: "RawcliffeBridge",
    portContinent: "Europe",
  },
  {
    lat: "51.58861210000001",
    lng: "0.08239819999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRBG",
    portName: "Redbridge/London",
    portContinent: "Europe",
  },
  {
    lat: "50.4419174",
    lng: "-4.1098416",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRBH",
    portName: "Roborough",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRBL",
    portName: "Erribol",
    portContinent: "Europe",
  },
  {
    lat: "51.7989701",
    lng: "-0.4007992",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRBN",
    portName: "Redbourn",
    portContinent: "Europe",
  },
  {
    lat: "53.6997483",
    lng: "-1.7038108",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRBO",
    portName: "Roberttown",
    portContinent: "Europe",
  },
  {
    lat: "50.985538",
    lng: "0.4729209999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRBR",
    portName: "Robertsbridge",
    portContinent: "Europe",
  },
  {
    lat: "51.9387441",
    lng: "1.171391",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRBS",
    portName: "Wrabness",
    portContinent: "Europe",
  },
  {
    lat: "53.770689",
    lng: "-2.674641",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRBT",
    portName: "Ribbleton",
    portContinent: "Europe",
  },
  {
    lat: "53.487042",
    lng: "-0.5357879999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRBU",
    portName: "Redbourne",
    portContinent: "Europe",
  },
  {
    lat: "52.39609799999999",
    lng: "-2.016368",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRBY",
    portName: "RUBERY",
    portContinent: "Europe",
  },
  {
    lat: "57.894609",
    lng: "-5.389823",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRCB",
    portName: "RhuCoigach-Badluachrach",
    portContinent: "Europe",
  },
  {
    lat: "53.6097136",
    lng: "-2.1561",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRCD",
    portName: "Rochdale, Greater Manchester",
    portContinent: "Europe",
  },
  {
    lat: "54.53682999999999",
    lng: "-1.677401",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRCE",
    portName: "Piercebridge",
    portContinent: "Europe",
  },
  {
    lat: "37.5407246",
    lng: "-77.4360481",
    country: "US",
    country_long: "United States",
    portCode: "GBRCH",
    portName: "Richmond",
    portContinent: "Europe",
  },
  {
    lat: "51.7073923",
    lng: "-0.366965",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRCK",
    portName: "BricketWood",
    portContinent: "Europe",
  },
  {
    lat: "53.833319",
    lng: "-1.0595972",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRCL",
    portName: "Riccall",
    portContinent: "Europe",
  },
  {
    lat: "52.950304",
    lng: "-1.839295",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRCR",
    portName: "Rocester",
    portContinent: "Europe",
  },
  {
    lat: "43.15657789999999",
    lng: "-77.6088465",
    country: "US",
    country_long: "United States",
    portCode: "GBRCS",
    portName: "Rochester",
    portContinent: "Europe",
  },
  {
    lat: "51.583768",
    lng: "-3.428319",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRCT",
    portName: "Tonyrefail",
    portContinent: "Europe",
  },
  {
    lat: "51.46131099999999",
    lng: "-0.303742",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRCU",
    portName: "RichmonduponThames",
    portContinent: "Europe",
  },
  {
    lat: "53.70645099999999",
    lng: "-2.279366",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRDA",
    portName: "Rossendale",
    portContinent: "Europe",
  },
  {
    lat: "51.7989701",
    lng: "-0.4007992",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRDB",
    portName: "Redbourn",
    portContinent: "Europe",
  },
  {
    lat: "42.3762091",
    lng: "-71.12220789999999",
    country: "US",
    country_long: "United States",
    portCode: "GBRDC",
    portName: "Radcliffe",
    portContinent: "Europe",
  },
  {
    lat: "52.30897",
    lng: "-1.940936",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRDD",
    portName: "Redditch",
    portContinent: "Europe",
  },
  {
    lat: "42.3944486",
    lng: "-83.2973732",
    country: "US",
    country_long: "United States",
    portCode: "GBRDF",
    portName: "Redford",
    portContinent: "Europe",
  },
  {
    lat: "53.069159",
    lng: "-1.3688229",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRDG",
    portName: "Riddings",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRDH",
    portName: "Rudh Re - Red Point",
    portContinent: "Europe",
  },
  {
    lat: "40.5865396",
    lng: "-122.3916754",
    country: "US",
    country_long: "United States",
    portCode: "GBRDI",
    portName: "Redding",
    portContinent: "Europe",
  },
  {
    lat: "51.68680800000001",
    lng: "-0.3173869",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRDL",
    portName: "Radlett",
    portContinent: "Europe",
  },
  {
    lat: "52.0152061",
    lng: "-0.578712",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRDM",
    portName: "Ridgmont",
    portContinent: "Europe",
  },
  {
    lat: "51.45512009999999",
    lng: "-0.9787475",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRDN",
    portName: "Reading",
    portContinent: "Europe",
  },
  {
    lat: "52.894929",
    lng: "-1.1515709",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRDO",
    portName: "Ruddington",
    portContinent: "Europe",
  },
  {
    lat: "51.6356584",
    lng: "0.4724831",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRDQ",
    portName: "RamsdenHeath",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRDS",
    portName: "Reddish",
    portContinent: "Europe",
  },
  {
    lat: "54.75005179999999",
    lng: "-6.320274",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRDT",
    portName: "Randalstown",
    portContinent: "Europe",
  },
  {
    lat: "46.0447377",
    lng: "-73.71061209999999",
    country: "CA",
    country_long: "Canada",
    portCode: "GBRDW",
    portName: "Rawdon",
    portContinent: "Europe",
  },
  {
    lat: "51.855473",
    lng: "-2.552145",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRDX",
    portName: "Ruardean",
    portContinent: "Europe",
  },
  {
    lat: "51.9357999",
    lng: "-1.2237869",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRDY",
    portName: "Ardley",
    portContinent: "Europe",
  },
  {
    lat: "52.444817",
    lng: "0.8762759999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRDZ",
    portName: "Roudham",
    portContinent: "Europe",
  },
  {
    lat: "58.55920099999999",
    lng: "-3.777376",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBREA",
    portName: "Reay",
    portContinent: "Europe",
  },
  {
    lat: "51.4769544",
    lng: "-2.7907038",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBREB",
    portName: "RedcliffBay",
    portContinent: "Europe",
  },
  {
    lat: "34.4398202",
    lng: "-88.1408747",
    country: "US",
    country_long: "United States",
    portCode: "GBRED",
    portName: "Red Bay",
    portContinent: "Europe",
  },
  {
    lat: "52.7490035",
    lng: "-1.821137",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBREE",
    portName: "KingsBromley",
    portContinent: "Europe",
  },
  {
    lat: "51.812119",
    lng: "0.74656",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBREF",
    portName: "Tiptree, Essex",
    portContinent: "Europe",
  },
  {
    lat: "51.239208",
    lng: "-0.16988",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBREH",
    portName: "Redhill",
    portContinent: "Europe",
  },
  {
    lat: "51.237276",
    lng: "-0.205883",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBREI",
    portName: "Reigate, Surrey",
    portContinent: "Europe",
  },
  {
    lat: "50.3633764",
    lng: "-5.0914773",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBREJ",
    portName: "Rejerrah",
    portContinent: "Europe",
  },
  {
    lat: "52.392562",
    lng: "-1.996504",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBREL",
    portName: "Rednal",
    portContinent: "Europe",
  },
  {
    lat: "52.560618",
    lng: "1.566159",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBREM",
    portName: "Reedham",
    portContinent: "Europe",
  },
  {
    lat: "45.4749199",
    lng: "-76.6877186",
    country: "CA",
    country_long: "Canada",
    portCode: "GBREN",
    portName: "Renfrew",
    portContinent: "Europe",
  },
  {
    lat: "54.5974636",
    lng: "-1.0779515",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRER",
    portName: "Redcar",
    portContinent: "Europe",
  },
  {
    lat: "52.898764",
    lng: "-2.664916",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRES",
    portName: "Prees",
    portContinent: "Europe",
  },
  {
    lat: "65.33482839999999",
    lng: "-166.4888757",
    country: "US",
    country_long: "United States",
    portCode: "GBREV",
    portName: "Braevig",
    portContinent: "Europe",
  },
  {
    lat: "52.761515",
    lng: "-1.935967",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBREY",
    portName: "Rugeley",
    portContinent: "Europe",
  },
  {
    lat: "51.582071",
    lng: "0.706515",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRFD",
    portName: "Rochford",
    portContinent: "Europe",
  },
  {
    lat: "54.088156",
    lng: "-1.4185401",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRFF",
    portName: "Roecliffe",
    portContinent: "Europe",
  },
  {
    lat: "42.1225482",
    lng: "-72.2005059",
    country: "US",
    country_long: "United States",
    portCode: "GBRFL",
    portName: "Brimfield",
    portContinent: "Europe",
  },
  {
    lat: "53.32135599999999",
    lng: "-0.9455030000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRFO",
    portName: "Retford",
    portContinent: "Europe",
  },
  {
    lat: "53.3657664",
    lng: "-3.0152812",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRFR",
    portName: "Rock Ferry",
    portContinent: "Europe",
  },
  {
    lat: "52.22108120000001",
    lng: "0.8037514",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRG9",
    portName: "Rougham",
    portContinent: "Europe",
  },
  {
    lat: "-37.8232497",
    lng: "174.8905914",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBRGA",
    portName: "Raglan",
    portContinent: "Europe",
  },
  {
    lat: "53.377121",
    lng: "-4.390969",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRGC",
    portName: "Rhos-Goch",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRGH",
    portName: "Burgh",
    portContinent: "Europe",
  },
  {
    lat: "54.924707",
    lng: "-1.74265",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRGI",
    portName: "RowlandsGill",
    portContinent: "Europe",
  },
  {
    lat: "54.3562679",
    lng: "-3.4058201",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRGL",
    portName: "Ravenglass",
    portContinent: "Europe",
  },
  {
    lat: "54.94938",
    lng: "-1.972495",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRGM",
    portName: "RidingMill",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRGN",
    portName: "MidGlamorgan",
    portContinent: "Europe",
  },
  {
    lat: "52.8845633",
    lng: "1.2979265",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRGO",
    portName: "Roughton",
    portContinent: "Europe",
  },
  {
    lat: "43.6603228",
    lng: "-79.3620439",
    country: "CA",
    country_long: "Canada",
    portCode: "GBRGP",
    portName: "Regent'sPark",
    portContinent: "Europe",
  },
  {
    lat: "50.892596",
    lng: "0.054667",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRGR",
    portName: "Ringmer",
    portContinent: "Europe",
  },
  {
    lat: "51.237276",
    lng: "-0.205883",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRGT",
    portName: "Reigate",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRGV",
    portName: "Redgrave",
    portContinent: "Europe",
  },
  {
    lat: "51.09579799999999",
    lng: "-0.444344",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRGW",
    portName: "Rudgwick",
    portContinent: "Europe",
  },
  {
    lat: "51.5906113",
    lng: "-0.1109709",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRGY",
    portName: "Haringey",
    portContinent: "Europe",
  },
  {
    lat: "51.239208",
    lng: "-0.16988",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRH9",
    portName: "Redhill",
    portContinent: "Europe",
  },
  {
    lat: "55.915981",
    lng: "-5.157033",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRHB",
    portName: "Rhubodach,IsleofBute",
    portContinent: "Europe",
  },
  {
    lat: "51.79430499999999",
    lng: "-4.087759999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRHD",
    portName: "CrossHands",
    portContinent: "Europe",
  },
  {
    lat: "53.256941",
    lng: "-2.482447",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRHE",
    portName: "Rudheath",
    portContinent: "Europe",
  },
  {
    lat: "51.741755",
    lng: "-3.5625549",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRHG",
    portName: "Rhigos",
    portContinent: "Europe",
  },
  {
    lat: "58.038397",
    lng: "-4.12169",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRHI",
    portName: "Rhilochan",
    portContinent: "Europe",
  },
  {
    lat: "41.31569959999999",
    lng: "-75.78231079999999",
    country: "US",
    country_long: "United States",
    portCode: "GBRHL",
    portName: "BarHill",
    portContinent: "Europe",
  },
  {
    lat: "52.125279",
    lng: "1.412014",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRHM",
    portName: "Rendlesham",
    portContinent: "Europe",
  },
  {
    lat: "53.11487349999999",
    lng: "-3.3103108",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRHN",
    portName: "Ruthin",
    portContinent: "Europe",
  },
  {
    lat: "51.6160411",
    lng: "-3.4250598",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRHO",
    portName: "Rhondda",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRHS",
    portName: "RhÃ´s-on-Sea",
    portContinent: "Europe",
  },
  {
    lat: "53.76850899999999",
    lng: "-2.42362",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRHT",
    portName: "Rishton",
    portContinent: "Europe",
  },
  {
    lat: "56.01342200000001",
    lng: "-4.773518",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRHU",
    portName: "Rhu (GBRHU), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "-27.2120899",
    lng: "153.053791",
    country: "AU",
    country_long: "Australia",
    portCode: "GBRHW",
    portName: "Rothwell",
    portContinent: "Europe",
  },
  {
    lat: "53.31914099999999",
    lng: "-3.491634",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRHY",
    portName: "Rhyl",
    portContinent: "Europe",
  },
  {
    lat: "53.770689",
    lng: "-2.674641",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRIB",
    portName: "Ribbleton, Lancashire",
    portContinent: "Europe",
  },
  {
    lat: "51.29589060000001",
    lng: "1.3248543",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRIC",
    portName: "Richborough",
    portContinent: "Europe",
  },
  {
    lat: "55.378051",
    lng: "-3.435973",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRID",
    portName: "Ridham Dock (GBRID), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.35177270000001",
    lng: "-5.152063399999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRIE",
    portName: "Scourie",
    portContinent: "Europe",
  },
  {
    lat: "51.8748081",
    lng: "1.1145037",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRIG",
    portName: "Tendring",
    portContinent: "Europe",
  },
  {
    lat: "52.4821669",
    lng: "-2.120309",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRIH",
    portName: "Brierley Hill",
    portContinent: "Europe",
  },
  {
    lat: "51.638763",
    lng: "-0.4741309999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRIK",
    portName: "Rickmansworth, Hertfordshire",
    portContinent: "Europe",
  },
  {
    lat: "53.42553119999999",
    lng: "-2.5227008",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRIL",
    portName: "Risley",
    portContinent: "Europe",
  },
  {
    lat: "50.6387969",
    lng: "-2.3545569",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRIN",
    portName: "Ringstead",
    portContinent: "Europe",
  },
  {
    lat: "43.8422049",
    lng: "-88.8359447",
    country: "US",
    country_long: "United States",
    portCode: "GBRIP",
    portName: "Ripon",
    portContinent: "Europe",
  },
  {
    lat: "53.42553119999999",
    lng: "-2.5227008",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRIS",
    portName: "Risley",
    portContinent: "Europe",
  },
  {
    lat: "41.1134289",
    lng: "-74.2454254",
    country: "US",
    country_long: "United States",
    portCode: "GBRIW",
    portName: "Ringwood",
    portContinent: "Europe",
  },
  {
    lat: "51.266802",
    lng: "-2.907277",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRKB",
    portName: "RooksBridge",
    portContinent: "Europe",
  },
  {
    lat: "53.04439199999999",
    lng: "-0.387468",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRKO",
    portName: "Ruskington",
    portContinent: "Europe",
  },
  {
    lat: "51.638763",
    lng: "-0.4741309999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRKW",
    portName: "Rickmansworth",
    portContinent: "Europe",
  },
  {
    lat: "54.23913",
    lng: "-7.172449899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRLA",
    portName: "Rosslea",
    portContinent: "Europe",
  },
  {
    lat: "53.800885",
    lng: "-1.041382",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRLB",
    portName: "Barlby",
    portContinent: "Europe",
  },
  {
    lat: "51.05104799999999",
    lng: "0.630622",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRLD",
    portName: "Rolvenden",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRLG",
    portName: "BarLight",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRLH",
    portName: "Rayleigh",
    portContinent: "Europe",
  },
  {
    lat: "-27.2120899",
    lng: "153.053791",
    country: "AU",
    country_long: "Australia",
    portCode: "GBRLL",
    portName: "Rothwell",
    portContinent: "Europe",
  },
  {
    lat: "52.30523489999999",
    lng: "0.487189",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRLO",
    portName: "Red Lodge Suffolk IP",
    portContinent: "Europe",
  },
  {
    lat: "52.2851905",
    lng: "-1.5200789",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRLP",
    portName: "RoyalLeamingtonSpa",
    portContinent: "Europe",
  },
  {
    lat: "52.193935",
    lng: "0.8933909",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRLS",
    portName: "Rattlesden",
    portContinent: "Europe",
  },
  {
    lat: "58.28348",
    lng: "-6.767703999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRLW",
    portName: "Carloway",
    portContinent: "Europe",
  },
  {
    lat: "51.079446",
    lng: "-1.089307",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRLY",
    portName: "Ropley",
    portContinent: "Europe",
  },
  {
    lat: "53.648457",
    lng: "-2.32049",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRMB",
    portName: "Ramsbottom",
    portContinent: "Europe",
  },
  {
    lat: "52.484544",
    lng: "-0.1078931",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRMD",
    portName: "RamseyMereside",
    portContinent: "Europe",
  },
  {
    lat: "57.59143",
    lng: "-4.114783",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRME",
    portName: "Rosemarkie",
    portContinent: "Europe",
  },
  {
    lat: "51.577076",
    lng: "0.178319",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRMF",
    portName: "Romford",
    portContinent: "Europe",
  },
  {
    lat: "51.335545",
    lng: "1.419895",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRMG",
    portName: "Ramsgate",
    portContinent: "Europe",
  },
  {
    lat: "51.55162499999999",
    lng: "-0.886496",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRMH",
    portName: "Remenham",
    portContinent: "Europe",
  },
  {
    lat: "51.313304",
    lng: "-1.155097",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRML",
    portName: "Ramsdell",
    portContinent: "Europe",
  },
  {
    lat: "53.208367",
    lng: "-3.176684",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRMO",
    portName: "Rhosesmor",
    portContinent: "Europe",
  },
  {
    lat: "43.7315479",
    lng: "-79.7624177",
    country: "CA",
    country_long: "Canada",
    portCode: "GBRMP",
    portName: "Brampton",
    portContinent: "Europe",
  },
  {
    lat: "50.98893",
    lng: "-1.49658",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRMS",
    portName: "Romsey",
    portContinent: "Europe",
  },
  {
    lat: "51.98045800000001",
    lng: "-2.361327",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRMY",
    portName: "RedmarleyD'Abitot",
    portContinent: "Europe",
  },
  {
    lat: "51.517732",
    lng: "0.194831",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRNA",
    portName: "RAINHAM",
    portContinent: "Europe",
  },
  {
    lat: "53.68708600000001",
    lng: "-2.565101",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRNC",
    portName: "Brinscall",
    portContinent: "Europe",
  },
  {
    lat: "53.228996",
    lng: "-4.519761",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRNE",
    portName: "Rhosneigr",
    portContinent: "Europe",
  },
  {
    lat: "53.5023923",
    lng: "-2.7883808",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRNF",
    portName: "Rainford",
    portContinent: "Europe",
  },
  {
    lat: "50.803",
    lng: "-0.51249",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRNG",
    portName: "Rustington",
    portContinent: "Europe",
  },
  {
    lat: "53.415625",
    lng: "-2.7650229",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRNH",
    portName: "Rainhill",
    portContinent: "Europe",
  },
  {
    lat: "58.217489",
    lng: "-6.8360411",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRNN",
    portName: "Bernera(NUist)",
    portContinent: "Europe",
  },
  {
    lat: "53.3937006",
    lng: "-2.7676978",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRNO",
    portName: "Cronton",
    portContinent: "Europe",
  },
  {
    lat: "58.217489",
    lng: "-6.8360411",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRNR",
    portName: "Bernera(Lewis)",
    portContinent: "Europe",
  },
  {
    lat: "53.380335",
    lng: "-1.016643",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRNS",
    portName: "Ranskill",
    portContinent: "Europe",
  },
  {
    lat: "52.94341",
    lng: "-1.034344",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRNT",
    portName: "RadcliffeonTrent",
    portContinent: "Europe",
  },
  {
    lat: "51.91445",
    lng: "-2.582446",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRNW",
    portName: "Ross-on-wye",
    portContinent: "Europe",
  },
  {
    lat: "52.164642",
    lng: "-0.892523",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBROA",
    portName: "Roade",
    portContinent: "Europe",
  },
  {
    lat: "56.893712",
    lng: "-4.837988999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBROB",
    portName: "Roybridge",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBROC",
    portName: "Rock",
    portContinent: "Europe",
  },
  {
    lat: "45.1940511",
    lng: "-72.57150349999999",
    country: "CA",
    country_long: "Canada",
    portCode: "GBROE",
    portName: "Brome",
    portContinent: "Europe",
  },
  {
    lat: "51.592432",
    lng: "-3.0599949",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBROG",
    portName: "Rogerstone",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBROH",
    portName: "Roche",
    portContinent: "Europe",
  },
  {
    lat: "55.85587",
    lng: "-3.164189",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBROI",
    portName: "Roslin",
    portContinent: "Europe",
  },
  {
    lat: "54.865793",
    lng: "-3.798171",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBROK",
    portName: "Rockcliffe",
    portContinent: "Europe",
  },
  {
    lat: "53.6097136",
    lng: "-2.1561",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBROL",
    portName: "Rochdale",
    portContinent: "Europe",
  },
  {
    lat: "50.996993",
    lng: "0.9590754999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBROM",
    portName: "Romney Marsh",
    portContinent: "Europe",
  },
  {
    lat: "52.2468167",
    lng: "-1.2109743",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRON",
    portName: "Staverton",
    portContinent: "Europe",
  },
  {
    lat: "51.91445",
    lng: "-2.582446",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBROO",
    portName: "Ross-On-Wye, Herefordshire",
    portContinent: "Europe",
  },
  {
    lat: "54.10073",
    lng: "-6.2003999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBROR",
    portName: "Rostrevor",
    portContinent: "Europe",
  },
  {
    lat: "34.2870503",
    lng: "-83.11014829999999",
    country: "US",
    country_long: "United States",
    portCode: "GBROS",
    portName: "Royston",
    portContinent: "Europe",
  },
  {
    lat: "57.527809",
    lng: "-3.207355",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBROT",
    portName: "Rothes",
    portContinent: "Europe",
  },
  {
    lat: "59.1495155",
    lng: "-3.0202105",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBROU",
    portName: "Rousay (GBROU), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.857704",
    lng: "0.9460989999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBROW",
    portName: "Rowhedge",
    portContinent: "Europe",
  },
  {
    lat: "56.03646999999999",
    lng: "-3.423064",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBROY",
    portName: "Port of Rosyth",
    portContinent: "Europe",
  },
  {
    lat: "53.16022",
    lng: "-2.0948284",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRPC",
    portName: "RushtonSpencer",
    portContinent: "Europe",
  },
  {
    lat: "51.3781018",
    lng: "-2.3596827",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRPD",
    portName: "Bath, Somerset",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRPE",
    portName: "Ripley",
    portContinent: "Europe",
  },
  {
    lat: "52.76245900000001",
    lng: "1.1107419",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRPH",
    portName: "Reepham",
    portContinent: "Europe",
  },
  {
    lat: "53.235048",
    lng: "-1.421629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRPO",
    portName: "Chesterfield, Derbyshire",
    portContinent: "Europe",
  },
  {
    lat: "53.675254",
    lng: "-1.939549",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRPP",
    portName: "Ripponden",
    portContinent: "Europe",
  },
  {
    lat: "43.7315479",
    lng: "-79.7624177",
    country: "CA",
    country_long: "Canada",
    portCode: "GBRPT",
    portName: "Brampton",
    portContinent: "Europe",
  },
  {
    lat: "53.05281900000001",
    lng: "-1.405792",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRPY",
    portName: "RipleyDerbyshire",
    portContinent: "Europe",
  },
  {
    lat: "58.21829199999999",
    lng: "-6.246216",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRRB",
    portName: "Garrabost",
    portContinent: "Europe",
  },
  {
    lat: "51.9199853",
    lng: "-1.4553444",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRRC",
    portName: "Enstone, Oxfordshire",
    portContinent: "Europe",
  },
  {
    lat: "50.44350156646816",
    lng: "-3.720977667271459",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRRD",
    portName: "RiverDart",
    portContinent: "Europe",
  },
  {
    lat: "50.808996",
    lng: "-0.442571",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRRG",
    portName: "Ferring",
    portContinent: "Europe",
  },
  {
    lat: "53.023818",
    lng: "-3.5618711",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRRI",
    portName: "Cerrigdrudion",
    portContinent: "Europe",
  },
  {
    lat: "51.855809",
    lng: "-2.518649",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRRK",
    portName: "Drybrook",
    portContinent: "Europe",
  },
  {
    lat: "56.0259532",
    lng: "-3.7913557",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRRN",
    portName: "Carron",
    portContinent: "Europe",
  },
  {
    lat: "56.0259532",
    lng: "-3.7913557",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRRO",
    portName: "Carron",
    portContinent: "Europe",
  },
  {
    lat: "52.3124517",
    lng: "-2.452097600000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRRR",
    portName: "Eardiston",
    portContinent: "Europe",
  },
  {
    lat: "50.975582",
    lng: "-2.395336",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRRS",
    portName: "Henstridge",
    portContinent: "Europe",
  },
  {
    lat: "50.233022",
    lng: "-5.226666",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRRU",
    portName: "Redruth",
    portContinent: "Europe",
  },
  {
    lat: "52.0405536",
    lng: "-2.6911516",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRRW",
    portName: "Rotherwas",
    portContinent: "Europe",
  },
  {
    lat: "54.9966124",
    lng: "-7.3085748",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRRY",
    portName: "Derry",
    portContinent: "Europe",
  },
  {
    lat: "51.388917",
    lng: "-3.349358",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRS8",
    portName: "Rhoose",
    portContinent: "Europe",
  },
  {
    lat: "51.80108999999999",
    lng: "-3.2193679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRSA",
    portName: "Rassau",
    portContinent: "Europe",
  },
  {
    lat: "59.132713",
    lng: "-3.297437",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRSB",
    portName: "Birsay",
    portContinent: "Europe",
  },
  {
    lat: "51.611827",
    lng: "-3.1018",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRSC",
    portName: "Risca",
    portContinent: "Europe",
  },
  {
    lat: "53.47670400000001",
    lng: "-1.059747",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRSD",
    portName: "Rossington",
    portContinent: "Europe",
  },
  {
    lat: "52.25419249999999",
    lng: "-0.4742848",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRSE",
    portName: "Riseley",
    portContinent: "Europe",
  },
  {
    lat: "50.9425071",
    lng: "0.31515",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRSG",
    portName: "RushlakeGreen",
    portContinent: "Europe",
  },
  {
    lat: "56.00995",
    lng: "-4.801394",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRSH",
    portName: "Rosneath",
    portContinent: "Europe",
  },
  {
    lat: "51.29201699999999",
    lng: "-2.446092",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRSK",
    portName: "Radstock",
    portContinent: "Europe",
  },
  {
    lat: "51.56874699999999",
    lng: "0.5959439999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRSL",
    portName: "Thundersley",
    portContinent: "Europe",
  },
  {
    lat: "34.3140211",
    lng: "-83.8150766",
    country: "US",
    country_long: "United States",
    portCode: "GBRSN",
    portName: "Rushton",
    portContinent: "Europe",
  },
  {
    lat: "51.12022469999999",
    lng: "-0.2796452",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRSP",
    portName: "Rusper",
    portContinent: "Europe",
  },
  {
    lat: "52.7129923",
    lng: "-0.3506032",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRSR",
    portName: "Baston",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRSS",
    portName: "Ross",
    portContinent: "Europe",
  },
  {
    lat: "58.2195364",
    lng: "-6.7429577",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRST",
    portName: "Breasclete",
    portContinent: "Europe",
  },
  {
    lat: "52.4096982",
    lng: "-1.6395168",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRSW",
    portName: "Berkswell",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRSY",
    portName: "Ramsey",
    portContinent: "Europe",
  },
  {
    lat: "52.47722",
    lng: "0.8251529999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRTA",
    portName: "Wretham",
    portContinent: "Europe",
  },
  {
    lat: "52.366783",
    lng: "-1.4347129",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRTD",
    portName: "RytononDunsmore",
    portContinent: "Europe",
  },
  {
    lat: "53.34666",
    lng: "-2.3861987",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRTE",
    portName: "Rostherne",
    portContinent: "Europe",
  },
  {
    lat: "52.50537600000001",
    lng: "-1.113519",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRTG",
    portName: "Bruntingthorpe",
    portContinent: "Europe",
  },
  {
    lat: "53.4326035",
    lng: "-1.3635009",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRTH",
    portName: "Rotherham",
    portContinent: "Europe",
  },
  {
    lat: "57.41366899999999",
    lng: "-1.846107",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRTL",
    portName: "PortErroll",
    portContinent: "Europe",
  },
  {
    lat: "53.567124",
    lng: "-2.12132",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRTN",
    portName: "Royton",
    portContinent: "Europe",
  },
  {
    lat: "51.65525",
    lng: "0.552936",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRTO",
    portName: "Rettendon",
    portContinent: "Europe",
  },
  {
    lat: "51.046062",
    lng: "0.2194",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRTR",
    portName: "Rotherfield",
    portContinent: "Europe",
  },
  {
    lat: "53.11047199999999",
    lng: "-2.950822",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRTT",
    portName: "Rossett",
    portContinent: "Europe",
  },
  {
    lat: "54.10073",
    lng: "-6.2003999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRTV",
    portName: "Rostrevor",
    portContinent: "Europe",
  },
  {
    lat: "51.132377",
    lng: "0.263695",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRTW",
    portName: "Royal Tunbridge Wells",
    portContinent: "Europe",
  },
  {
    lat: "57.695531",
    lng: "-2.118062",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRTY",
    portName: "Rosehearty",
    portContinent: "Europe",
  },
  {
    lat: "50.9382669",
    lng: "0.7621909",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRU7",
    portName: "RyeHarbour",
    portContinent: "Europe",
  },
  {
    lat: "56.324354",
    lng: "-6.406553",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRUA",
    portName: "Ruanaich",
    portContinent: "Europe",
  },
  {
    lat: "52.34169499999999",
    lng: "-0.541032",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRUD",
    portName: "Raunds",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRUG",
    portName: "Rugby",
    portContinent: "Europe",
  },
  {
    lat: "52.338379",
    lng: "-2.171902",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRUH",
    portName: "Rushock",
    portContinent: "Europe",
  },
  {
    lat: "51.5758719",
    lng: "-0.421236",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRUI",
    portName: "Ruislip",
    portContinent: "Europe",
  },
  {
    lat: "52.338379",
    lng: "-2.171902",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRUK",
    portName: "Rushock",
    portContinent: "Europe",
  },
  {
    lat: "52.6090771",
    lng: "-1.9621922",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRUL",
    portName: "Rushall",
    portContinent: "Europe",
  },
  {
    lat: "49.993555",
    lng: "-5.181832",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRUM",
    portName: "RuanMinor",
    portContinent: "Europe",
  },
  {
    lat: "53.342078",
    lng: "-2.729673",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRUN",
    portName: "Runcorn",
    portContinent: "Europe",
  },
  {
    lat: "52.289125",
    lng: "-0.600363",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRUS",
    portName: "Rushden",
    portContinent: "Europe",
  },
  {
    lat: "43.6106237",
    lng: "-72.9726065",
    country: "US",
    country_long: "United States",
    portCode: "GBRUT",
    portName: "Rutland",
    portContinent: "Europe",
  },
  {
    lat: "56.624751",
    lng: "-3.166621",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRUV",
    portName: "Ruthven",
    portContinent: "Europe",
  },
  {
    lat: "52.98654399999999",
    lng: "-3.040724",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRUW",
    portName: "Ruabon",
    portContinent: "Europe",
  },
  {
    lat: "43.80534859999999",
    lng: "-71.8125811",
    country: "US",
    country_long: "United States",
    portCode: "GBRUY",
    portName: "Rumney",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRVD",
    portName: "Ravenfield",
    portContinent: "Europe",
  },
  {
    lat: "50.44883941535738",
    lng: "-4.584027374301636",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRVF",
    portName: "RiverFowey",
    portContinent: "Europe",
  },
  {
    lat: "52.3258515",
    lng: "-1.0213071",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRVH",
    portName: "Ravensthorpe",
    portContinent: "Europe",
  },
  {
    lat: "52.35762",
    lng: "0.2670351",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRWA",
    portName: "Barway",
    portContinent: "Europe",
  },
  {
    lat: "51.184664",
    lng: "-0.8235859999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRWE",
    portName: "Rowledge",
    portContinent: "Europe",
  },
  {
    lat: "52.30198",
    lng: "1.1281441",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRWH",
    portName: "Braiseworth",
    portContinent: "Europe",
  },
  {
    lat: "37.32790540000001",
    lng: "-76.5761402",
    country: "US",
    country_long: "United States",
    portCode: "GBRWL",
    portName: "Rosewell",
    portContinent: "Europe",
  },
  {
    lat: "56.1574134",
    lng: "-4.6438649",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRWN",
    portName: "Rowardennan",
    portContinent: "Europe",
  },
  {
    lat: "50.4208318",
    lng: "-3.9640786",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRWO",
    portName: "Cornwood",
    portContinent: "Europe",
  },
  {
    lat: "51.6102001",
    lng: "0.5754663",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRWR",
    portName: "Rawreth",
    portContinent: "Europe",
  },
  {
    lat: "52.25755299999999",
    lng: "1.115033",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRWS",
    portName: "Wetheringsett",
    portContinent: "Europe",
  },
  {
    lat: "51.1091401",
    lng: "-0.1872275",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRWY",
    portName: "Crawley",
    portContinent: "Europe",
  },
  {
    lat: "55.5693544",
    lng: "-2.4760237",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRXG",
    portName: "Roxburgh",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBRXN",
    portName: "Rixton",
    portContinent: "Europe",
  },
  {
    lat: "52.2667691",
    lng: "0.6311816",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRY9",
    portName: "Risby",
    portContinent: "Europe",
  },
  {
    lat: "52.301537",
    lng: "-3.510641",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRYA",
    portName: "Rhayader",
    portContinent: "Europe",
  },
  {
    lat: "52.104056",
    lng: "-2.7293569",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRYD",
    portName: "Ryde (GBRYD), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.949708",
    lng: "0.7372599999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRYE",
    portName: "Rye",
    portContinent: "Europe",
  },
  {
    lat: "53.0850042",
    lng: "-2.3361828",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRYG",
    portName: "RadwayGreen",
    portContinent: "Europe",
  },
  {
    lat: "54.8682155",
    lng: "-1.3653906",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRYH",
    portName: "Ryhope",
    portContinent: "Europe",
  },
  {
    lat: "57.333126",
    lng: "-2.835357",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRYI",
    portName: "Rhynie",
    portContinent: "Europe",
  },
  {
    lat: "51.5874649",
    lng: "-4.1957219",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRYL",
    portName: "Reynoldston",
    portContinent: "Europe",
  },
  {
    lat: "53.1962",
    lng: "-3.1908581",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRYM",
    portName: "Rhydymwyn",
    portContinent: "Europe",
  },
  {
    lat: "54.9728909",
    lng: "-1.7629735",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRYN",
    portName: "Ryton",
    portContinent: "Europe",
  },
  {
    lat: "35.6536584",
    lng: "-99.9190017",
    country: "US",
    country_long: "United States",
    portCode: "GBRYO",
    portName: "Reydon",
    portContinent: "Europe",
  },
  {
    lat: "52.488674",
    lng: "-2.046085",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRYR",
    portName: "RowleyRegis/WestBromwich",
    portContinent: "Europe",
  },
  {
    lat: "42.7167483",
    lng: "-70.8787277",
    country: "US",
    country_long: "United States",
    portCode: "GBRYRÂ",
    portName: "Rowley",
    portContinent: "Europe",
  },
  {
    lat: "52.366783",
    lng: "-1.4347129",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRYT",
    portName: "Ryton On Dunsmore, Warwickshire",
    portContinent: "Europe",
  },
  {
    lat: "51.759452",
    lng: "-3.2853629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRYY",
    portName: "Rhymney",
    portContinent: "Europe",
  },
  {
    lat: "52.190713",
    lng: "-2.50872",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRZF",
    portName: "Bromyard, Herefordshire",
    portContinent: "Europe",
  },
  {
    lat: "51.068785",
    lng: "-1.794472",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBRZY",
    portName: "Salisbury, Wiltshire",
    portContinent: "Europe",
  },
  {
    lat: "52.907932",
    lng: "-2.144045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBS2T",
    portName: "Stone",
    portContinent: "Europe",
  },
  {
    lat: "51.736261",
    lng: "-4.3074709",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBS3R",
    portName: "Kidwelly",
    portContinent: "Europe",
  },
  {
    lat: "51.1401713",
    lng: "-2.8330263",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBS6W",
    portName: "Shapwick",
    portContinent: "Europe",
  },
  {
    lat: "52.5221703",
    lng: "-1.2906778",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBS7X",
    portName: "Sharnford",
    portContinent: "Europe",
  },
  {
    lat: "51.5459269",
    lng: "0.7077123",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAA",
    portName: "Southend-on-Sea",
    portContinent: "Europe",
  },
  {
    lat: "53.834234",
    lng: "-2.338558",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAB",
    portName: "Sabden",
    portContinent: "Europe",
  },
  {
    lat: "50.107815",
    lng: "-5.611154",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAC",
    portName: "Sancreed",
    portContinent: "Europe",
  },
  {
    lat: "-37.6100844",
    lng: "141.4432865",
    country: "AU",
    country_long: "Australia",
    portCode: "GBSAD",
    portName: "Sandford",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSAE",
    portName: "Sale",
    portContinent: "Europe",
  },
  {
    lat: "52.022593",
    lng: "0.239215",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAF",
    portName: "Saffron Walden, Essex",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSAG",
    portName: "SaintAgnes",
    portContinent: "Europe",
  },
  {
    lat: "43.7355927",
    lng: "-70.5509356",
    country: "US",
    country_long: "United States",
    portCode: "GBSAH",
    portName: "Standish",
    portContinent: "Europe",
  },
  {
    lat: "50.63467499999999",
    lng: "-1.175199",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAI",
    portName: "Shanklin",
    portContinent: "Europe",
  },
  {
    lat: "55.368078",
    lng: "-3.926828",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAJ",
    portName: "Sanquhar",
    portContinent: "Europe",
  },
  {
    lat: "51.8202982",
    lng: "-0.7970613",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAK",
    portName: "Stocklake",
    portContinent: "Europe",
  },
  {
    lat: "52.8271988",
    lng: "-0.7398195",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAL",
    portName: "Saltby",
    portContinent: "Europe",
  },
  {
    lat: "53.7643642",
    lng: "-2.6067158",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAM",
    portName: "Samlesbury",
    portContinent: "Europe",
  },
  {
    lat: "53.146561",
    lng: "-2.367382",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAN",
    portName: "Sandbach",
    portContinent: "Europe",
  },
  {
    lat: "43.0428562",
    lng: "-72.9109334",
    country: "US",
    country_long: "United States",
    portCode: "GBSAO",
    portName: "Stratton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSAR",
    portName: "Star",
    portContinent: "Europe",
  },
  {
    lat: "52.122665",
    lng: "0.169775",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAS",
    portName: "Sawston",
    portContinent: "Europe",
  },
  {
    lat: "50.40920999999999",
    lng: "-4.21643",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAT",
    portName: "Saltash",
    portContinent: "Europe",
  },
  {
    lat: "51.70937900000001",
    lng: "-4.699726",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAU",
    portName: "Saundersfoot",
    portContinent: "Europe",
  },
  {
    lat: "52.19173",
    lng: "-1.708298",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAV",
    portName: "Stratford-upon-Avon",
    portContinent: "Europe",
  },
  {
    lat: "52.022593",
    lng: "0.239215",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAW",
    portName: "Saffron Walden",
    portContinent: "Europe",
  },
  {
    lat: "52.242058",
    lng: "0.6353409999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAX",
    portName: "Saxham",
    portContinent: "Europe",
  },
  {
    lat: "51.396531",
    lng: "0.177326",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSAY",
    portName: "Swanley",
    portContinent: "Europe",
  },
  {
    lat: "43.6546148",
    lng: "-94.7268677",
    country: "US",
    country_long: "United States",
    portCode: "GBSB3",
    portName: "Sherburn",
    portContinent: "Europe",
  },
  {
    lat: "52.428293",
    lng: "-0.5814739",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSB4",
    portName: "Sudborough",
    portContinent: "Europe",
  },
  {
    lat: "51.6419973",
    lng: "0.1651586",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBA",
    portName: "StaplefordAbbots",
    portContinent: "Europe",
  },
  {
    lat: "53.48380599999999",
    lng: "-2.0500759",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBB",
    portName: "Stalybridge, Greater Manchester",
    portContinent: "Europe",
  },
  {
    lat: "51.562038",
    lng: "-2.6616749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBC",
    portName: "SevernBeach",
    portContinent: "Europe",
  },
  {
    lat: "33.5442781",
    lng: "-84.23380929999999",
    country: "US",
    country_long: "United States",
    portCode: "GBSBD",
    portName: "Stockbridge",
    portContinent: "Europe",
  },
  {
    lat: "55.570844",
    lng: "-2.6475399",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBE",
    portName: "SaintBoswells",
    portContinent: "Europe",
  },
  {
    lat: "51.5673417",
    lng: "0.5505197",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBF",
    portName: "Benfleet",
    portContinent: "Europe",
  },
  {
    lat: "53.482769",
    lng: "-1.593719",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBG",
    portName: "Stocksbridge",
    portContinent: "Europe",
  },
  {
    lat: "51.980859",
    lng: "0.5903269999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBH",
    portName: "SibleHedingham",
    portContinent: "Europe",
  },
  {
    lat: "52.64299099999999",
    lng: "0.3631151",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBI",
    portName: "Stowbridge",
    portContinent: "Europe",
  },
  {
    lat: "53.611268",
    lng: "-2.93512",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBK",
    portName: "Scarisbrick",
    portContinent: "Europe",
  },
  {
    lat: "53.796994",
    lng: "-1.249604",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBL",
    portName: "Sherburn In Elmet",
    portContinent: "Europe",
  },
  {
    lat: "51.0748159",
    lng: "-0.0412761",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBM",
    portName: "Sharpthorne",
    portContinent: "Europe",
  },
  {
    lat: "43.6546148",
    lng: "-94.7268677",
    country: "US",
    country_long: "United States",
    portCode: "GBSBN",
    portName: "Sherburn",
    portContinent: "Europe",
  },
  {
    lat: "52.820702",
    lng: "-1.251989",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBO",
    portName: "SuttonBonnington",
    portContinent: "Europe",
  },
  {
    lat: "53.133685",
    lng: "-1.379287",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBQ",
    portName: "Stonebroom",
    portContinent: "Europe",
  },
  {
    lat: "42.2959267",
    lng: "-71.71284709999999",
    country: "US",
    country_long: "United States",
    portCode: "GBSBR",
    portName: "Shrewsbury",
    portContinent: "Europe",
  },
  {
    lat: "56.0130357",
    lng: "-3.6035314",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBS",
    portName: "Borrowstounness",
    portContinent: "Europe",
  },
  {
    lat: "50.824707",
    lng: "-1.2134268",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBT",
    portName: "Stubbington",
    portContinent: "Europe",
  },
  {
    lat: "53.932725",
    lng: "-1.621875",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBU",
    portName: "Stainburn",
    portContinent: "Europe",
  },
  {
    lat: "55.577436",
    lng: "-2.6745359",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSBW",
    portName: "NewtownSaintBoswells",
    portContinent: "Europe",
  },
  {
    lat: "40.862585",
    lng: "-76.79441039999999",
    country: "US",
    country_long: "United States",
    portCode: "GBSBY",
    portName: "Sunbury",
    portContinent: "Europe",
  },
  {
    lat: "54.283113",
    lng: "-0.399752",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCA",
    portName: "Scarborough",
    portContinent: "Europe",
  },
  {
    lat: "50.2335243",
    lng: "-3.767968999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCB",
    portName: "Salcombe Harbour",
    portContinent: "Europe",
  },
  {
    lat: "57.8666667",
    lng: "-6.6666667",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCC",
    portName: "Scalpay,OuterHebridies",
    portContinent: "Europe",
  },
  {
    lat: "52.023706",
    lng: "-0.789482",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCE",
    portName: "ShenleyChurchEnd",
    portContinent: "Europe",
  },
  {
    lat: "58.962408",
    lng: "-2.973343",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCF",
    portName: "Scapa Pier (GBSCF), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.067941",
    lng: "-6.188045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCG",
    portName: "Scalasaig,Colonsay",
    portContinent: "Europe",
  },
  {
    lat: "57.8166667",
    lng: "-6.8166667",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCH",
    portName: "Stockinish",
    portContinent: "Europe",
  },
  {
    lat: "37.9577016",
    lng: "-121.2907796",
    country: "US",
    country_long: "United States",
    portCode: "GBSCK",
    portName: "Stockton",
    portContinent: "Europe",
  },
  {
    lat: "51.81934099999999",
    lng: "-4.498791",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCL",
    portName: "SaintClears",
    portContinent: "Europe",
  },
  {
    lat: "57.657156",
    lng: "-1.910084",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCM",
    portName: "SaintCombsandCharleston",
    portContinent: "Europe",
  },
  {
    lat: "56.5016806",
    lng: "-6.8077971",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCN",
    portName: "Scarinish (GBSCN), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.311956",
    lng: "-6.115864999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCO",
    portName: "Sconser,IsleofSkye",
    portContinent: "Europe",
  },
  {
    lat: "53.588646",
    lng: "-0.654413",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCP",
    portName: "Scunthorpe",
    portContinent: "Europe",
  },
  {
    lat: "58.61102599999999",
    lng: "-3.552627",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCR",
    portName: "Scrabster (GBSCR), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.43630950000001",
    lng: "-1.2941219",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCS",
    portName: "Scatsta",
    portContinent: "Europe",
  },
  {
    lat: "37.9577016",
    lng: "-121.2907796",
    country: "US",
    country_long: "United States",
    portCode: "GBSCT",
    portName: "Stockton",
    portContinent: "Europe",
  },
  {
    lat: "51.3541821",
    lng: "1.2509338",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCW",
    portName: "SaintNicholasatWade",
    portContinent: "Europe",
  },
  {
    lat: "52.4283674",
    lng: "-1.9179323",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSCY",
    portName: "Stirchley",
    portContinent: "Europe",
  },
  {
    lat: "53.565174",
    lng: "-0.8787769999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSD5",
    portName: "Sandtoft",
    portContinent: "Europe",
  },
  {
    lat: "51.829654",
    lng: "-0.514969",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDA",
    portName: "Studham",
    portContinent: "Europe",
  },
  {
    lat: "53.1571245",
    lng: "-0.7033762",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDB",
    portName: "Swinderby",
    portContinent: "Europe",
  },
  {
    lat: "51.4264863",
    lng: "0.1009392",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDC",
    portName: "Sidcup",
    portContinent: "Europe",
  },
  {
    lat: "51.615508",
    lng: "-3.280356",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDD",
    portName: "Senghenydd",
    portContinent: "Europe",
  },
  {
    lat: "48.936181",
    lng: "2.357443",
    country: "FR",
    country_long: "France",
    portCode: "GBSDE",
    portName: "Saint Dennis",
    portContinent: "Europe",
  },
  {
    lat: "52.456891",
    lng: "-2.148731",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDG",
    portName: "Stourbridge",
    portContinent: "Europe",
  },
  {
    lat: "51.231764",
    lng: "-1.658755",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDH",
    portName: "SouthTidworth",
    portContinent: "Europe",
  },
  {
    lat: "52.926499",
    lng: "-1.288894",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDI",
    portName: "Sandiacre",
    portContinent: "Europe",
  },
  {
    lat: "52.318362",
    lng: "1.273321",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDK",
    portName: "Stradbroke",
    portContinent: "Europe",
  },
  {
    lat: "51.3313",
    lng: "0.445068",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDL",
    portName: "Snodland",
    portContinent: "Europe",
  },
  {
    lat: "50.67865",
    lng: "-3.23756",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDM",
    portName: "Sidmouth",
    portContinent: "Europe",
  },
  {
    lat: "54.63341699999999",
    lng: "-1.654915",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDN",
    portName: "Shildon",
    portContinent: "Europe",
  },
  {
    lat: "42.928698",
    lng: "-71.1870053",
    country: "US",
    country_long: "United States",
    portCode: "GBSDO",
    portName: "Sandown",
    portContinent: "Europe",
  },
  {
    lat: "52.1579675",
    lng: "-1.8908469",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDP",
    portName: "SalfordPriors",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSDQ",
    portName: "Sheldon",
    portContinent: "Europe",
  },
  {
    lat: "51.33421310000001",
    lng: "-0.0754487",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDR",
    portName: "Sanderstead",
    portContinent: "Europe",
  },
  {
    lat: "54.221134",
    lng: "-2.799025",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDS",
    portName: "Sandside",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSDT",
    portName: "Bradstone",
    portContinent: "Europe",
  },
  {
    lat: "50.960257",
    lng: "-3.268866",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDU",
    portName: "SampfordArundel",
    portContinent: "Europe",
  },
  {
    lat: "55.0711452",
    lng: "-1.5276765",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDV",
    portName: "SeatonDelaval",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSDW",
    portName: "Sandwich",
    portContinent: "Europe",
  },
  {
    lat: "51.745734",
    lng: "-2.217758",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDX",
    portName: "Stroud",
    portContinent: "Europe",
  },
  {
    lat: "40.5649781",
    lng: "-111.8389726",
    country: "US",
    country_long: "United States",
    portCode: "GBSDY",
    portName: "Sandy",
    portContinent: "Europe",
  },
  {
    lat: "54.503006",
    lng: "-0.6712389999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSDZ",
    portName: "Sandsend",
    portContinent: "Europe",
  },
  {
    lat: "54.840346",
    lng: "-1.337517",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEA",
    portName: "Seaham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSEB",
    portName: "Send",
    portContinent: "Europe",
  },
  {
    lat: "35.2125577",
    lng: "-80.85877719999999",
    country: "US",
    country_long: "United States",
    portCode: "GBSEC",
    portName: "Southend",
    portContinent: "Europe",
  },
  {
    lat: "53.7465342",
    lng: "-0.2388213",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSED",
    portName: "SaltEnd",
    portContinent: "Europe",
  },
  {
    lat: "54.236816",
    lng: "-0.441258",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEE",
    portName: "Seamer",
    portContinent: "Europe",
  },
  {
    lat: "53.46751980000001",
    lng: "-3.0127627",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEF",
    portName: "Seaforth",
    portContinent: "Europe",
  },
  {
    lat: "50.99974109999999",
    lng: "-1.585672",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEG",
    portName: "Sherfield English",
    portContinent: "Europe",
  },
  {
    lat: "54.323629",
    lng: "-2.528174",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEH",
    portName: "Sedbergh",
    portContinent: "Europe",
  },
  {
    lat: "55.872386",
    lng: "-4.354386",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEI",
    portName: "Shieldhall",
    portContinent: "Europe",
  },
  {
    lat: "51.26817459999999",
    lng: "0.2389479",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEK",
    portName: "Seal, Kent",
    portContinent: "Europe",
  },
  {
    lat: "51.93136399999999",
    lng: "-1.726444",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEL",
    portName: "Stow On The Wold",
    portContinent: "Europe",
  },
  {
    lat: "52.4138878",
    lng: "1.434789",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEM",
    portName: "Saint Peter South Elmham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSEN",
    portName: "SouthendMunicipalApt",
    portContinent: "Europe",
  },
  {
    lat: "51.34372279999999",
    lng: "-0.061975",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEO",
    portName: "Selsdon",
    portContinent: "Europe",
  },
  {
    lat: "51.407485",
    lng: "-1.348017",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEP",
    portName: "Speen",
    portContinent: "Europe",
  },
  {
    lat: "52.4756799",
    lng: "0.931012",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSER",
    portName: "Snetterton",
    portContinent: "Europe",
  },
  {
    lat: "50.7853827",
    lng: "-0.7903726",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSES",
    portName: "Sidlesham",
    portContinent: "Europe",
  },
  {
    lat: "54.06824599999999",
    lng: "-2.277655",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSET",
    portName: "Settle",
    portContinent: "Europe",
  },
  {
    lat: "51.27241000000001",
    lng: "0.190898",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEV",
    portName: "Sevenoaks",
    portContinent: "Europe",
  },
  {
    lat: "51.2741584",
    lng: "0.8810247999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEW",
    portName: "Sheldwich",
    portContinent: "Europe",
  },
  {
    lat: "53.070277",
    lng: "-1.316374",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEX",
    portName: "Selston",
    portContinent: "Europe",
  },
  {
    lat: "51.0419598",
    lng: "-2.1556553",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEY",
    portName: "Semley",
    portContinent: "Europe",
  },
  {
    lat: "51.46610339999999",
    lng: "-0.2656604",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSEZ",
    portName: "Sheen",
    portContinent: "Europe",
  },
  {
    lat: "52.1945636",
    lng: "1.313319",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSF3",
    portName: "Kettleburgh",
    portContinent: "Europe",
  },
  {
    lat: "53.2132511",
    lng: "-1.2608197",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSF5",
    portName: "Scarcliffe",
    portContinent: "Europe",
  },
  {
    lat: "53.446622",
    lng: "-2.308713",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFD",
    portName: "Stretford",
    portContinent: "Europe",
  },
  {
    lat: "52.568723",
    lng: "0.5139161",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFE",
    portName: "StokeFerry",
    portContinent: "Europe",
  },
  {
    lat: "54.0891597",
    lng: "-1.0067805",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFF",
    portName: "SheriffHutton",
    portContinent: "Europe",
  },
  {
    lat: "51.514121",
    lng: "0.42854",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFH",
    portName: "Stanford-Le-Hope, Essex",
    portContinent: "Europe",
  },
  {
    lat: "44.2948178",
    lng: "-68.2893455",
    country: "US",
    country_long: "United States",
    portCode: "GBSFI",
    portName: "Swallowfield",
    portContinent: "Europe",
  },
  {
    lat: "58.649309",
    lng: "-3.272971",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFK",
    portName: "Scarfskerry",
    portContinent: "Europe",
  },
  {
    lat: "52.016203",
    lng: "-0.232293",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFL",
    portName: "Stotfold",
    portContinent: "Europe",
  },
  {
    lat: "52.60723549999999",
    lng: "-0.6122280999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFM",
    portName: "SouthLuffenham",
    portContinent: "Europe",
  },
  {
    lat: "52.66792",
    lng: "-2.3745599",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFN",
    portName: "Shifnal, Shropshire",
    portContinent: "Europe",
  },
  {
    lat: "52.9286389",
    lng: "-1.272719",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFO",
    portName: "Stapleford",
    portContinent: "Europe",
  },
  {
    lat: "40.6659344",
    lng: "-73.4881809",
    country: "US",
    country_long: "United States",
    portCode: "GBSFR",
    portName: "Seaford",
    portContinent: "Europe",
  },
  {
    lat: "51.2008676",
    lng: "-0.166959",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFS",
    portName: "Salfords",
    portContinent: "Europe",
  },
  {
    lat: "51.7114454",
    lng: "-1.2259955",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFT",
    portName: "SandfordOnThames",
    portContinent: "Europe",
  },
  {
    lat: "57.5025309",
    lng: "-2.0446159",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFU",
    portName: "Stuartfield",
    portContinent: "Europe",
  },
  {
    lat: "51.641581",
    lng: "-1.505106",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFV",
    portName: "StanfordintheVale",
    portContinent: "Europe",
  },
  {
    lat: "58.9",
    lng: "-3.05",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFW",
    portName: "Scapa Flow (GBSFW), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.48752349999999",
    lng: "-2.2901264",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSFY",
    portName: "Salford",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSGC",
    portName: "Badminton",
    portContinent: "Europe",
  },
  {
    lat: "51.3924544",
    lng: "-0.6301363",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGD",
    portName: "Sunningdale",
    portContinent: "Europe",
  },
  {
    lat: "50.9165688",
    lng: "-1.2002866",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGE",
    portName: "Shedfield",
    portContinent: "Europe",
  },
  {
    lat: "51.6329374",
    lng: "-1.1402651",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGF",
    portName: "Shillingford",
    portContinent: "Europe",
  },
  {
    lat: "52.8217343",
    lng: "-2.1772449",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGH",
    portName: "Seighford",
    portContinent: "Europe",
  },
  {
    lat: "55.06032099999999",
    lng: "-1.554178",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGI",
    portName: "Seghill",
    portContinent: "Europe",
  },
  {
    lat: "51.4042049",
    lng: "-0.6543268999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGL",
    portName: "Sunninghill",
    portContinent: "Europe",
  },
  {
    lat: "52.94442100000001",
    lng: "1.2109589",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGM",
    portName: "Sheringham",
    portContinent: "Europe",
  },
  {
    lat: "51.617386",
    lng: "-0.6010289999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGN",
    portName: "SeerGreen",
    portContinent: "Europe",
  },
  {
    lat: "52.5711781",
    lng: "-1.4118527",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGO",
    portName: "StokeGolding",
    portContinent: "Europe",
  },
  {
    lat: "58.82488499999999",
    lng: "-2.9593909",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGP",
    portName: "St Margaret's Hope (GBSGP), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.3974044",
    lng: "-5.7627853",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGR",
    portName: "Crossgar",
    portContinent: "Europe",
  },
  {
    lat: "54.102794",
    lng: "-1.1107581",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGT",
    portName: "Stillington",
    portContinent: "Europe",
  },
  {
    lat: "52.5596585",
    lng: "-0.2273585",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGU",
    portName: "Stanground",
    portContinent: "Europe",
  },
  {
    lat: "54.569978",
    lng: "-0.9001159999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGV",
    portName: "Skinningrove",
    portContinent: "Europe",
  },
  {
    lat: "55.9339697",
    lng: "-3.3093391",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSGY",
    portName: "SouthGyle",
    portContinent: "Europe",
  },
  {
    lat: "53.3950533",
    lng: "-2.2527361",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSH2",
    portName: "Sharston",
    portContinent: "Europe",
  },
  {
    lat: "51.600693",
    lng: "-1.656749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHA",
    portName: "Shrivenham",
    portContinent: "Europe",
  },
  {
    lat: "51.0046",
    lng: "-2.198083",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHB",
    portName: "Shaftesbury",
    portContinent: "Europe",
  },
  {
    lat: "51.685591",
    lng: "-2.38732",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHC",
    portName: "Stinchcombe",
    portContinent: "Europe",
  },
  {
    lat: "51.4036829",
    lng: "0.2472888",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHD",
    portName: "South Darenth",
    portContinent: "Europe",
  },
  {
    lat: "36.0950743",
    lng: "-80.2788466",
    country: "US",
    country_long: "United States",
    portCode: "GBSHE",
    portName: "Sheffield",
    portContinent: "Europe",
  },
  {
    lat: "52.038699",
    lng: "-0.333947",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHF",
    portName: "Shefford",
    portContinent: "Europe",
  },
  {
    lat: "50.787522",
    lng: "-0.977454",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHG",
    portName: "SouthHayling",
    portContinent: "Europe",
  },
  {
    lat: "52.629152",
    lng: "0.88747",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHH",
    portName: "Shipdham",
    portContinent: "Europe",
  },
  {
    lat: "53.835927",
    lng: "-1.776978",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHI",
    portName: "Shipley",
    portContinent: "Europe",
  },
  {
    lat: "53.170882",
    lng: "-0.601082",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHJ",
    portName: "SouthHykeham",
    portContinent: "Europe",
  },
  {
    lat: "42.0548167",
    lng: "-72.7703724",
    country: "US",
    country_long: "United States",
    portCode: "GBSHK",
    portName: "Southwick",
    portContinent: "Europe",
  },
  {
    lat: "51.629698",
    lng: "0.319184",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHL",
    portName: "Shenfield",
    portContinent: "Europe",
  },
  {
    lat: "51.1909",
    lng: "-2.547886",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHM",
    portName: "Shepton Mallet",
    portContinent: "Europe",
  },
  {
    lat: "35.6552464",
    lng: "-120.3754325",
    country: "US",
    country_long: "United States",
    portCode: "GBSHN",
    portName: "Shandon",
    portContinent: "Europe",
  },
  {
    lat: "50.8311111",
    lng: "-0.2380556",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHO",
    portName: "Shoreham Port",
    portContinent: "Europe",
  },
  {
    lat: "51.5051913",
    lng: "-0.22469",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHP",
    portName: "Shepherds Bush",
    portContinent: "Europe",
  },
  {
    lat: "50.806907",
    lng: "0.054577",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHQ",
    portName: "SouthHeighton",
    portContinent: "Europe",
  },
  {
    lat: "50.947822",
    lng: "-2.5144829",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHR",
    portName: "Sherborne",
    portContinent: "Europe",
  },
  {
    lat: "51.44011",
    lng: "0.764158",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHS",
    portName: "Sheerness",
    portContinent: "Europe",
  },
  {
    lat: "53.2115447",
    lng: "-3.0380242",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHT",
    portName: "Shotton",
    portContinent: "Europe",
  },
  {
    lat: "55.581148",
    lng: "-1.651159",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHU",
    portName: "Seahouses",
    portContinent: "Europe",
  },
  {
    lat: "-35.0810677",
    lng: "150.4892292",
    country: "AU",
    country_long: "Australia",
    portCode: "GBSHV",
    portName: "ShellHaven",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSHW",
    portName: "Shaw",
    portContinent: "Europe",
  },
  {
    lat: "57.52514590000001",
    lng: "-5.6479456",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHX",
    portName: "Shieldaig",
    portContinent: "Europe",
  },
  {
    lat: "46.19140059999999",
    lng: "-122.1955509",
    country: "US",
    country_long: "United States",
    portCode: "GBSHY",
    portName: "Saint Helens",
    portContinent: "Europe",
  },
  {
    lat: "52.3439962",
    lng: "-3.4860012",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSHZ",
    portName: "SaintHarmon",
    portContinent: "Europe",
  },
  {
    lat: "51.2194368",
    lng: "-0.4648081",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIA",
    portName: "Shere",
    portContinent: "Europe",
  },
  {
    lat: "51.980859",
    lng: "0.5903269999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIB",
    portName: "Sible Hedingham, Essex",
    portContinent: "Europe",
  },
  {
    lat: "53.79325799999999",
    lng: "-2.786723",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIC",
    portName: "Salwick",
    portContinent: "Europe",
  },
  {
    lat: "52.1636301",
    lng: "1.4958726",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSID",
    portName: "Snape Bridge",
    portContinent: "Europe",
  },
  {
    lat: "50.89891000000001",
    lng: "-2.24982",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIE",
    portName: "Shillingstone",
    portContinent: "Europe",
  },
  {
    lat: "56.1165227",
    lng: "-3.9369029",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIG",
    portName: "Stirling",
    portContinent: "Europe",
  },
  {
    lat: "51.403971",
    lng: "-3.416546",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIH",
    portName: "SaintAthan",
    portContinent: "Europe",
  },
  {
    lat: "53.76846399999999",
    lng: "-1.416674",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSII",
    portName: "Swillington",
    portContinent: "Europe",
  },
  {
    lat: "42.0548167",
    lng: "-72.7703724",
    country: "US",
    country_long: "United States",
    portCode: "GBSIK",
    portName: "Southwick",
    portContinent: "Europe",
  },
  {
    lat: "54.868394",
    lng: "-3.389471",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIL",
    portName: "Silloth",
    portContinent: "Europe",
  },
  {
    lat: "52.766087",
    lng: "-0.627912",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIM",
    portName: "South Witham",
    portContinent: "Europe",
  },
  {
    lat: "-32.6135516",
    lng: "151.0471355",
    country: "AU",
    country_long: "Australia",
    portCode: "GBSIN",
    portName: "Singleton",
    portContinent: "Europe",
  },
  {
    lat: "53.2019189",
    lng: "-1.217961",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIO",
    portName: "Shirebrook",
    portContinent: "Europe",
  },
  {
    lat: "51.941641",
    lng: "0.116062",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIP",
    portName: "StockingPelham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSIR",
    portName: "SaintLawrence",
    portContinent: "Europe",
  },
  {
    lat: "52.091477",
    lng: "-1.0280609",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIS",
    portName: "Silverstone, Northamptonshire",
    portContinent: "Europe",
  },
  {
    lat: "51.340402",
    lng: "0.7315959999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIT",
    portName: "Sittingbourne",
    portContinent: "Europe",
  },
  {
    lat: "50.478559",
    lng: "-4.384289",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIV",
    portName: "Saint Ive",
    portContinent: "Europe",
  },
  {
    lat: "52.28201379999999",
    lng: "0.4072279",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSIW",
    portName: "Snailwell",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSIY",
    portName: "Shirley",
    portContinent: "Europe",
  },
  {
    lat: "52.4736723",
    lng: "-1.8552069",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSJJ",
    portName: "SmallHeath",
    portContinent: "Europe",
  },
  {
    lat: "54.36979",
    lng: "-5.5556899",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSJL",
    portName: "Strangford",
    portContinent: "Europe",
  },
  {
    lat: "43.6584973",
    lng: "4.1156556",
    country: "FR",
    country_long: "France",
    portCode: "GBSJU",
    portName: "SaintJust",
    portContinent: "Europe",
  },
  {
    lat: "50.6419069",
    lng: "-1.9527631",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSJY",
    portName: "Studland",
    portContinent: "Europe",
  },
  {
    lat: "50.770687",
    lng: "-3.507055",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKC",
    portName: "StokeCanon",
    portContinent: "Europe",
  },
  {
    lat: "53.549284",
    lng: "-2.774735",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKD",
    portName: "Skelmersdale",
    portContinent: "Europe",
  },
  {
    lat: "53.146403",
    lng: "0.337881",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKE",
    portName: "Skegness",
    portContinent: "Europe",
  },
  {
    lat: "51.516917",
    lng: "-2.5435089",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKG",
    portName: "Stoke Gifford",
    portContinent: "Europe",
  },
  {
    lat: "51.658232",
    lng: "-0.8962720000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKH",
    portName: "Stokenchurch",
    portContinent: "Europe",
  },
  {
    lat: "53.9628495",
    lng: "-2.0162787",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKI",
    portName: "Skipton",
    portContinent: "Europe",
  },
  {
    lat: "55.550658",
    lng: "-2.838524",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKK",
    portName: "Selkirk",
    portContinent: "Europe",
  },
  {
    lat: "54.561954",
    lng: "-0.9895099999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKL",
    portName: "Skelton",
    portContinent: "Europe",
  },
  {
    lat: "55.868522",
    lng: "-4.88983",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKM",
    portName: "Skelmorlie",
    portContinent: "Europe",
  },
  {
    lat: "51.51000209999999",
    lng: "-3.7019188",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKN",
    portName: "SouthCornelly",
    portContinent: "Europe",
  },
  {
    lat: "51.2014962",
    lng: "-0.6639911",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKO",
    portName: "Shackleford",
    portContinent: "Europe",
  },
  {
    lat: "54.213094",
    lng: "-1.435049",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKP",
    portName: "SkiptononSwale",
    portContinent: "Europe",
  },
  {
    lat: "58.535576",
    lng: "-4.303823",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKR",
    portName: "Skerray",
    portContinent: "Europe",
  },
  {
    lat: "54.561954",
    lng: "-0.9895099999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKT",
    portName: "Skelton",
    portContinent: "Europe",
  },
  {
    lat: "50.954064",
    lng: "-2.749254",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKU",
    portName: "Stoke-sub-Hambon",
    portContinent: "Europe",
  },
  {
    lat: "50.0500538",
    lng: "-5.087932299999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKV",
    portName: "SaintKeverne",
    portContinent: "Europe",
  },
  {
    lat: "51.660932",
    lng: "-3.841789",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKW",
    portName: "Skewen",
    portContinent: "Europe",
  },
  {
    lat: "57.2736278",
    lng: "-6.2155022",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKY",
    portName: "IsleofSkyeApt",
    portContinent: "Europe",
  },
  {
    lat: "60.188334",
    lng: "-1.4408669",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSKZ",
    portName: "Skeld",
    portContinent: "Europe",
  },
  {
    lat: "50.85563819999999",
    lng: "0.5525772",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLA",
    portName: "Saint Leonards",
    portContinent: "Europe",
  },
  {
    lat: "53.78352400000001",
    lng: "-1.067189",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLB",
    portName: "Selby",
    portContinent: "Europe",
  },
  {
    lat: "55.637652",
    lng: "-4.7834899",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLC",
    portName: "Saltcoats",
    portContinent: "Europe",
  },
  {
    lat: "47.6453242",
    lng: "-118.157089",
    country: "US",
    country_long: "United States",
    portCode: "GBSLD",
    portName: "Stockland",
    portContinent: "Europe",
  },
  {
    lat: "53.0003079",
    lng: "-0.40965",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLE",
    portName: "Sleaford",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSLF",
    portName: "Shelf",
    portContinent: "Europe",
  },
  {
    lat: "53.58584",
    lng: "-0.186296",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLG",
    portName: "Stallingborough",
    portContinent: "Europe",
  },
  {
    lat: "51.514121",
    lng: "0.42854",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLH",
    portName: "Stanford-Le-Hope",
    portContinent: "Europe",
  },
  {
    lat: "51.070901",
    lng: "-0.404297",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLI",
    portName: "Slinfold",
    portContinent: "Europe",
  },
  {
    lat: "51.50082399999999",
    lng: "-0.8996234000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLK",
    portName: "Shiplake",
    portContinent: "Europe",
  },
  {
    lat: "51.511073",
    lng: "-0.377232",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLL",
    portName: "Southall",
    portContinent: "Europe",
  },
  {
    lat: "52.06606129999999",
    lng: "-0.1251438",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLM",
    portName: "Steeple Morden",
    portContinent: "Europe",
  },
  {
    lat: "54.58156",
    lng: "-0.9751340000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLN",
    portName: "Saltburn-by-the-Sea",
    portContinent: "Europe",
  },
  {
    lat: "51.51053839999999",
    lng: "-0.5950405999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLO",
    portName: "Slough",
    portContinent: "Europe",
  },
  {
    lat: "51.55507919999999",
    lng: "-0.399575",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLP",
    portName: "SouthRuislipWard",
    portContinent: "Europe",
  },
  {
    lat: "51.3538459",
    lng: "-1.1005385",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLR",
    portName: "Silchester",
    portContinent: "Europe",
  },
  {
    lat: "38.3606736",
    lng: "-75.5993692",
    country: "US",
    country_long: "United States",
    portCode: "GBSLS",
    portName: "Salisbury",
    portContinent: "Europe",
  },
  {
    lat: "53.176631",
    lng: "-2.930295",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLT",
    portName: "Saltney",
    portContinent: "Europe",
  },
  {
    lat: "51.8739786",
    lng: "-5.1953135",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLV",
    portName: "Solva(Solfach)",
    portContinent: "Europe",
  },
  {
    lat: "52.541313",
    lng: "-0.8542759999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLW",
    portName: "Slawston",
    portContinent: "Europe",
  },
  {
    lat: "52.4933803",
    lng: "-1.8530914",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLX",
    portName: "Saltley",
    portContinent: "Europe",
  },
  {
    lat: "52.729949",
    lng: "-1.110268",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSLY",
    portName: "Sileby",
    portContinent: "Europe",
  },
  {
    lat: "47.2150945",
    lng: "-123.1007066",
    country: "US",
    country_long: "United States",
    portCode: "GBSLZ",
    portName: "Shelton",
    portContinent: "Europe",
  },
  {
    lat: "-36.8451557",
    lng: "174.7463795",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBSMA",
    portName: "Saint Marys Bay",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSMB",
    portName: "Somersby",
    portContinent: "Europe",
  },
  {
    lat: "56.204812",
    lng: "-2.765718",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMC",
    portName: "SaintMonance(SaintMonans)",
    portContinent: "Europe",
  },
  {
    lat: "50.8993938",
    lng: "-0.2762585",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMD",
    portName: "SmallDole",
    portContinent: "Europe",
  },
  {
    lat: "52.492401",
    lng: "-1.965207",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSME",
    portName: "Smethwick",
    portContinent: "Europe",
  },
  {
    lat: "51.171526",
    lng: "-0.116778",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMF",
    portName: "Smallfield",
    portContinent: "Europe",
  },
  {
    lat: "51.58505299999999",
    lng: "-1.753417",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMG",
    portName: "StrattonSaintMargaret",
    portContinent: "Europe",
  },
  {
    lat: "58.82488499999999",
    lng: "-2.9593909",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMH",
    portName: "SaintMargaret'sHope,SouthRonaldsay",
    portContinent: "Europe",
  },
  {
    lat: "51.69479870000001",
    lng: "-0.2323459",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMI",
    portName: "SouthMimms",
    portContinent: "Europe",
  },
  {
    lat: "52.9198579",
    lng: "-1.8830748",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSML",
    portName: "Stramshall",
    portContinent: "Europe",
  },
  {
    lat: "50.1171529",
    lng: "-5.477807299999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMM",
    portName: "SaintMichael'sMount",
    portContinent: "Europe",
  },
  {
    lat: "18.0708298",
    lng: "-63.0500809",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSMN",
    portName: "SaintMartin",
    portContinent: "Europe",
  },
  {
    lat: "53.80665699999999",
    lng: "-2.351814",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMO",
    portName: "Simonstone",
    portContinent: "Europe",
  },
  {
    lat: "50.362143",
    lng: "-4.642262",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMP",
    portName: "SaintSampson(Golant)",
    portContinent: "Europe",
  },
  {
    lat: "32.5964404",
    lng: "-114.709677",
    country: "US",
    country_long: "United States",
    portCode: "GBSMR",
    portName: "Somerton",
    portContinent: "Europe",
  },
  {
    lat: "51.105097",
    lng: "-2.9262307",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMS",
    portName: "Somerset",
    portContinent: "Europe",
  },
  {
    lat: "51.149254",
    lng: "0.686871",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMT",
    portName: "Smarden",
    portContinent: "Europe",
  },
  {
    lat: "50.366871",
    lng: "-4.971385",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMU",
    portName: "Summercourt",
    portContinent: "Europe",
  },
  {
    lat: "50.158551",
    lng: "-5.014632",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMV",
    portName: "Saint Mawes (GBSMV), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.5020041",
    lng: "-2.8777401",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSMW",
    portName: "Simonswood",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSMY",
    portName: "SaintMarysHoo",
    portContinent: "Europe",
  },
  {
    lat: "50.3403779",
    lng: "-4.7834252",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNA",
    portName: "St Austell, Cornwall",
    portContinent: "Europe",
  },
  {
    lat: "51.5064218",
    lng: "-2.082623",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNB",
    portName: "SuttonBenger",
    portContinent: "Europe",
  },
  {
    lat: "50.0755813",
    lng: "-5.702987299999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNC",
    portName: "SennenCove",
    portContinent: "Europe",
  },
  {
    lat: "35.2125577",
    lng: "-80.85877719999999",
    country: "US",
    country_long: "United States",
    portCode: "GBSND",
    portName: "Southend",
    portContinent: "Europe",
  },
  {
    lat: "51.561175",
    lng: "-0.08349229999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNE",
    portName: "StokeNewington",
    portContinent: "Europe",
  },
  {
    lat: "53.195068",
    lng: "-2.999372",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNF",
    portName: "Sandycroft",
    portContinent: "Europe",
  },
  {
    lat: "-32.6135516",
    lng: "151.0471355",
    country: "AU",
    country_long: "Australia",
    portCode: "GBSNG",
    portName: "Singleton",
    portContinent: "Europe",
  },
  {
    lat: "51.7494494",
    lng: "-1.4037817",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNH",
    portName: "StantonHarcourt",
    portContinent: "Europe",
  },
  {
    lat: "52.8296856",
    lng: "0.5124889",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNI",
    portName: "Sandringham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSNJ",
    portName: "Sand",
    portContinent: "Europe",
  },
  {
    lat: "60.29833099999999",
    lng: "-1.65257",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNK",
    portName: "Sandness",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSNL",
    portName: "Stanley",
    portContinent: "Europe",
  },
  {
    lat: "-29.6966609",
    lng: "31.0234757",
    country: "ZA",
    country_long: "South Africa",
    portCode: "GBSNM",
    portName: "Stanmore",
    portContinent: "Europe",
  },
  {
    lat: "51.5583777",
    lng: "-1.7809759",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNN",
    portName: "Swindon",
    portContinent: "Europe",
  },
  {
    lat: "53.1081689",
    lng: "-1.340708",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNO",
    portName: "South Normanton",
    portContinent: "Europe",
  },
  {
    lat: "51.39813359999999",
    lng: "-0.0716",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNR",
    portName: "SouthNorwood",
    portContinent: "Europe",
  },
  {
    lat: "58.96619399999999",
    lng: "-3.296495",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNS",
    portName: "Stromness",
    portContinent: "Europe",
  },
  {
    lat: "52.2300836",
    lng: "-0.265103",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNT",
    portName: "Saint Neots",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSNU",
    portName: "StantonuderBardon",
    portContinent: "Europe",
  },
  {
    lat: "50.55877220000001",
    lng: "-4.8731629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNV",
    portName: "SaintMinver",
    portContinent: "Europe",
  },
  {
    lat: "26.3847142",
    lng: "-80.14702989999999",
    country: "US",
    country_long: "United States",
    portCode: "GBSNW",
    portName: "SaintAndrews",
    portContinent: "Europe",
  },
  {
    lat: "51.989734",
    lng: "0.8945569999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSNY",
    portName: "Stoke By Nayland",
    portContinent: "Europe",
  },
  {
    lat: "-27.6148449",
    lng: "153.0669558",
    country: "AU",
    country_long: "Australia",
    portCode: "GBSNZ",
    portName: "Stretton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSOA",
    portName: "Stroma",
    portContinent: "Europe",
  },
  {
    lat: "42.3056501",
    lng: "-71.5245087",
    country: "US",
    country_long: "United States",
    portCode: "GBSOB",
    portName: "Southborough",
    portContinent: "Europe",
  },
  {
    lat: "54.947653",
    lng: "-1.906376",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOC",
    portName: "Stocksfield",
    portContinent: "Europe",
  },
  {
    lat: "52.9286389",
    lng: "-1.272719",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOD",
    portName: "Stapleford",
    portContinent: "Europe",
  },
  {
    lat: "53.0033369",
    lng: "-2.1827408",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOE",
    portName: "Stoke",
    portContinent: "Europe",
  },
  {
    lat: "51.415951",
    lng: "0.32011",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOF",
    portName: "Southfleet",
    portContinent: "Europe",
  },
  {
    lat: "51.222252",
    lng: "-0.048366",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOG",
    portName: "South Godstone, Surrey",
    portContinent: "Europe",
  },
  {
    lat: "52.2505049",
    lng: "-1.389719",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOH",
    portName: "Southam",
    portContinent: "Europe",
  },
  {
    lat: "53.592424",
    lng: "-1.322734",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOK",
    portName: "South Kirkby",
    portContinent: "Europe",
  },
  {
    lat: "52.411811",
    lng: "-1.77761",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOL",
    portName: "Solihull",
    portContinent: "Europe",
  },
  {
    lat: "53.081385",
    lng: "-1.370777",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOM",
    portName: "Somercotes",
    portContinent: "Europe",
  },
  {
    lat: "51.88368999999999",
    lng: "0.02607",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSON",
    portName: "Standon",
    portContinent: "Europe",
  },
  {
    lat: "51.521164",
    lng: "0.2943529",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOO",
    portName: "SouthOckendon",
    portContinent: "Europe",
  },
  {
    lat: "40.9028765",
    lng: "-74.7090517",
    country: "US",
    country_long: "United States",
    portCode: "GBSOP",
    portName: "Stanhope",
    portContinent: "Europe",
  },
  {
    lat: "55.99000299999999",
    lng: "-3.399045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOQ",
    portName: "South Queensferry",
    portContinent: "Europe",
  },
  {
    lat: "52.461721",
    lng: "-2.2040958",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOR",
    portName: "Stourton",
    portContinent: "Europe",
  },
  {
    lat: "51.799152",
    lng: "1.075842",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOS",
    portName: "SaintOsyth",
    portContinent: "Europe",
  },
  {
    lat: "53.0033369",
    lng: "-2.1827408",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOT",
    portName: "Stoke-on-Trent",
    portContinent: "Europe",
  },
  {
    lat: "50.9105468",
    lng: "-1.4049018",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOU",
    portName: "Southampton",
    portContinent: "Europe",
  },
  {
    lat: "55.864237",
    lng: "-4.251806",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOW",
    portName: "Stanlow (GBSOW), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSOX",
    portName: "Stanton",
    portContinent: "Europe",
  },
  {
    lat: "59.1068844",
    lng: "-2.6000637",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSOY",
    portName: "Stronsay",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSPA",
    portName: "Spalding",
    portContinent: "Europe",
  },
  {
    lat: "52.04272599999999",
    lng: "0.446688",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPB",
    portName: "Steeple Bumpstead",
    portContinent: "Europe",
  },
  {
    lat: "52.7701009",
    lng: "-1.292427",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPD",
    portName: "Shepshed",
    portContinent: "Europe",
  },
  {
    lat: "51.835742",
    lng: "0.154689",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPE",
    portName: "Spellbrook",
    portContinent: "Europe",
  },
  {
    lat: "52.9286389",
    lng: "-1.272719",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPF",
    portName: "Stapleford",
    portContinent: "Europe",
  },
  {
    lat: "51.5477849",
    lng: "-0.5851327",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPG",
    portName: "StokePoges",
    portContinent: "Europe",
  },
  {
    lat: "53.2577125",
    lng: "-3.4418762",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPH",
    portName: "Saint Asaph",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSPI",
    portName: "SandhavenandPitullie",
    portContinent: "Europe",
  },
  {
    lat: "53.3422624",
    lng: "-2.8512675",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPK",
    portName: "Speke",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSPL",
    portName: "Stapleton",
    portContinent: "Europe",
  },
  {
    lat: "51.31406",
    lng: "-2.798756",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPM",
    portName: "Shipham",
    portContinent: "Europe",
  },
  {
    lat: "54.69772099999999",
    lng: "-1.58552",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPN",
    portName: "Spennymoor",
    portContinent: "Europe",
  },
  {
    lat: "52.92481730000001",
    lng: "-1.4076398",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPO",
    portName: "Spondon",
    portContinent: "Europe",
  },
  {
    lat: "51.389617",
    lng: "-0.4526529999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPP",
    portName: "Shepperton",
    portContinent: "Europe",
  },
  {
    lat: "51.6237942",
    lng: "-4.9003315",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPQ",
    portName: "StackpoleQuay",
    portContinent: "Europe",
  },
  {
    lat: "51.03400689999999",
    lng: "-2.5679205",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPR",
    portName: "Sparkford",
    portContinent: "Europe",
  },
  {
    lat: "52.3417839",
    lng: "-2.277593",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPS",
    portName: "StourportonSevern",
    portContinent: "Europe",
  },
  {
    lat: "52.3417839",
    lng: "-2.277593",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPSÂ",
    portName: "Stourport-O-Sever",
    portContinent: "Europe",
  },
  {
    lat: "50.829533",
    lng: "-0.337416",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPT",
    portName: "Sompting",
    portContinent: "Europe",
  },
  {
    lat: "52.2501237",
    lng: "-1.6182911",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPU",
    portName: "Sherbourne",
    portContinent: "Europe",
  },
  {
    lat: "51.3958362",
    lng: "-0.9776282",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPW",
    portName: "SpencersWood",
    portContinent: "Europe",
  },
  {
    lat: "59.0441401",
    lng: "-2.8530747",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSPY",
    portName: "Shapinsay (GBSPY), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.0407318",
    lng: "-122.8154094",
    country: "US",
    country_long: "United States",
    portCode: "GBSQK",
    portName: "SaintMartins",
    portContinent: "Europe",
  },
  {
    lat: "51.4667969",
    lng: "-0.9131513",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSQN",
    portName: "Sonning",
    portContinent: "Europe",
  },
  {
    lat: "50.783928",
    lng: "-1.607339",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSQW",
    portName: "Sway (GBSQW), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.3038979",
    lng: "-0.5757059999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSQZ",
    portName: "Scampton",
    portContinent: "Europe",
  },
  {
    lat: "57.199078",
    lng: "-3.07456",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRA",
    portName: "Strathdon",
    portContinent: "Europe",
  },
  {
    lat: "44.04765829999999",
    lng: "-92.63485279999999",
    country: "US",
    country_long: "United States",
    portCode: "GBSRB",
    portName: "Somerby",
    portContinent: "Europe",
  },
  {
    lat: "57.424497",
    lng: "-5.428393",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRC",
    portName: "Strathcarron",
    portContinent: "Europe",
  },
  {
    lat: "52.806693",
    lng: "-2.12066",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRD",
    portName: "Stafford",
    portContinent: "Europe",
  },
  {
    lat: "53.185446",
    lng: "-0.811975",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRE",
    portName: "Sutton On Trent",
    portContinent: "Europe",
  },
  {
    lat: "53.8202133",
    lng: "-1.4674614",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRF",
    portName: "Seacroft",
    portContinent: "Europe",
  },
  {
    lat: "45.7690306",
    lng: "-79.3963867",
    country: "CA",
    country_long: "Canada",
    portCode: "GBSRG",
    portName: "Sundridge",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSRH",
    portName: "Strathaird",
    portContinent: "Europe",
  },
  {
    lat: "50.958624",
    lng: "-2.378667",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRI",
    portName: "Stalbridge",
    portContinent: "Europe",
  },
  {
    lat: "52.22599659999999",
    lng: "-0.5442775",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRK",
    portName: "Sharnbrook",
    portContinent: "Europe",
  },
  {
    lat: "51.5229106",
    lng: "-0.0777472",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRL",
    portName: "Shoreditch",
    portContinent: "Europe",
  },
  {
    lat: "50.918329",
    lng: "-0.454313",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRN",
    portName: "Storrington",
    portContinent: "Europe",
  },
  {
    lat: "52.8688379",
    lng: "-1.717209",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRO",
    portName: "Scropton",
    portContinent: "Europe",
  },
  {
    lat: "52.4971931",
    lng: "0.918423",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRP",
    portName: "Shropham",
    portContinent: "Europe",
  },
  {
    lat: "54.87322",
    lng: "-3.59692",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRQ",
    portName: "Southerness",
    portContinent: "Europe",
  },
  {
    lat: "50.925142",
    lng: "-2.304642",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRR",
    portName: "SturminsterNewton",
    portContinent: "Europe",
  },
  {
    lat: "50.925142",
    lng: "-2.304642",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRRÂ",
    portName: "Sturminster",
    portContinent: "Europe",
  },
  {
    lat: "51.573559",
    lng: "-2.211445",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRS",
    portName: "Sherston",
    portContinent: "Europe",
  },
  {
    lat: "33.9182092",
    lng: "-78.01930109999999",
    country: "US",
    country_long: "United States",
    portCode: "GBSRT",
    portName: "Southport",
    portContinent: "Europe",
  },
  {
    lat: "50.873288",
    lng: "-1.287294",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRU",
    portName: "Sarisbury",
    portContinent: "Europe",
  },
  {
    lat: "52.8710133",
    lng: "-1.35992",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRW",
    portName: "Shardlow",
    portContinent: "Europe",
  },
  {
    lat: "49.4300745",
    lng: "-2.3666256",
    country: "GG",
    country_long: "Guernsey",
    portCode: "GBSRX",
    portName: "Sark",
    portContinent: "Europe",
  },
  {
    lat: "58.8068266",
    lng: "-2.9472862",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRY",
    portName: "SouthRonaldsay",
    portContinent: "Europe",
  },
  {
    lat: "50.3098609",
    lng: "-3.629856",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSRZ",
    portName: "Strete",
    portContinent: "Europe",
  },
  {
    lat: "51.788598",
    lng: "0.009547",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSA",
    portName: "StansteadAbbots",
    portContinent: "Europe",
  },
  {
    lat: "51.03931499999999",
    lng: "-2.931395",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSB",
    portName: "StokeSaintGregory",
    portContinent: "Europe",
  },
  {
    lat: "54.396774",
    lng: "-3.481608",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSC",
    portName: "Seascale",
    portContinent: "Europe",
  },
  {
    lat: "53.911897",
    lng: "-1.9377286",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSD",
    portName: "Silsden",
    portContinent: "Europe",
  },
  {
    lat: "53.17209",
    lng: "-1.249295",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSE",
    portName: "Pleasley",
    portContinent: "Europe",
  },
  {
    lat: "51.11552229999999",
    lng: "0.5826608999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSG",
    portName: "Sissinghurst",
    portContinent: "Europe",
  },
  {
    lat: "54.999424",
    lng: "-1.427406",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSH",
    portName: "South Shields",
    portContinent: "Europe",
  },
  {
    lat: "52.745573",
    lng: "0.07360499999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSJ",
    portName: "SuttonSaintJames",
    portContinent: "Europe",
  },
  {
    lat: "55.518079",
    lng: "-5.309890999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSK",
    portName: "Shiskine",
    portContinent: "Europe",
  },
  {
    lat: "54.471908",
    lng: "-1.18531",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSL",
    portName: "Stokesley",
    portContinent: "Europe",
  },
  {
    lat: "51.90423999999999",
    lng: "0.194739",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSM",
    portName: "Stanstead Mountfitchet",
    portContinent: "Europe",
  },
  {
    lat: "55.641817",
    lng: "-4.760409999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSN",
    portName: "Stevenston",
    portContinent: "Europe",
  },
  {
    lat: "45.1941651",
    lng: "-67.2755622",
    country: "CA",
    country_long: "Canada",
    portCode: "GBSSP",
    portName: "SaintStephen",
    portContinent: "Europe",
  },
  {
    lat: "35.1102191",
    lng: "-80.8565855",
    country: "US",
    country_long: "United States",
    portCode: "GBSSR",
    portName: "Southminster",
    portContinent: "Europe",
  },
  {
    lat: "51.7236003",
    lng: "-2.4674595",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSS",
    portName: "Sharpness",
    portContinent: "Europe",
  },
  {
    lat: "52.639578",
    lng: "-1.844023",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSST",
    portName: "Shenstone",
    portContinent: "Europe",
  },
  {
    lat: "52.0606549",
    lng: "-1.622814",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSU",
    portName: "Shipston on Stour",
    portContinent: "Europe",
  },
  {
    lat: "55.2346119",
    lng: "-1.539995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSW",
    portName: "Cresswell",
    portContinent: "Europe",
  },
  {
    lat: "50.730991",
    lng: "-0.793704",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSSY",
    portName: "Selsey",
    portContinent: "Europe",
  },
  {
    lat: "52.48396",
    lng: "-1.807598",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBST2",
    portName: "Stechford",
    portContinent: "Europe",
  },
  {
    lat: "53.90089200000001",
    lng: "-2.953414",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBST3",
    portName: "Stalmine",
    portContinent: "Europe",
  },
  {
    lat: "39.945599",
    lng: "-75.171786",
    country: "US",
    country_long: "United States",
    portCode: "GBST7",
    portName: "Southgate",
    portContinent: "Europe",
  },
  {
    lat: "51.022149",
    lng: "-0.353682",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTA",
    portName: "Southwater",
    portContinent: "Europe",
  },
  {
    lat: "54.8273865",
    lng: "-7.4633103",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTB",
    portName: "Strabane",
    portContinent: "Europe",
  },
  {
    lat: "53.768138",
    lng: "-0.600799",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTC",
    portName: "South Cave",
    portContinent: "Europe",
  },
  {
    lat: "51.3932587",
    lng: "0.475344",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTD",
    portName: "Strood",
    portContinent: "Europe",
  },
  {
    lat: "43.0689903",
    lng: "-70.77332009999999",
    country: "US",
    country_long: "United States",
    portCode: "GBSTE",
    portName: "Street",
    portContinent: "Europe",
  },
  {
    lat: "41.18454149999999",
    lng: "-73.1331651",
    country: "US",
    country_long: "United States",
    portCode: "GBSTF",
    portName: "Stratford",
    portContinent: "Europe",
  },
  {
    lat: "52.527206",
    lng: "1.416447",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTG",
    portName: "Seething",
    portContinent: "Europe",
  },
  {
    lat: "52.9517699",
    lng: "1.092768",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTH",
    portName: "Salthouse",
    portContinent: "Europe",
  },
  {
    lat: "50.20838579999999",
    lng: "-5.490886400000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTI",
    portName: "Saint Ives",
    portContinent: "Europe",
  },
  {
    lat: "40.0419568",
    lng: "-75.3404886",
    country: "US",
    country_long: "United States",
    portCode: "GBSTJ",
    portName: "Stoneleigh",
    portContinent: "Europe",
  },
  {
    lat: "53.41063159999999",
    lng: "-2.1575332",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTK",
    portName: "Stockport",
    portContinent: "Europe",
  },
  {
    lat: "50.3403779",
    lng: "-4.7834252",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTL",
    portName: "Saint Austell",
    portContinent: "Europe",
  },
  {
    lat: "-29.6966609",
    lng: "31.0234757",
    country: "ZA",
    country_long: "South Africa",
    portCode: "GBSTM",
    portName: "Stanmore",
    portContinent: "Europe",
  },
  {
    lat: "51.8860181",
    lng: "0.2388661",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTN",
    portName: "Stansted Apt/London",
    portContinent: "Europe",
  },
  {
    lat: "58.209435",
    lng: "-6.3848692",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTO",
    portName: "Stornoway (GBSTO), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.482181",
    lng: "-2.49934",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTP",
    portName: "Staple Hill",
    portContinent: "Europe",
  },
  {
    lat: "55.482684",
    lng: "-4.573181",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTQ",
    portName: "Saint Quivox",
    portContinent: "Europe",
  },
  {
    lat: "54.903367",
    lng: "-5.024055",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTR",
    portName: "Stranraer",
    portContinent: "Europe",
  },
  {
    lat: "51.43148",
    lng: "-0.515525",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTS",
    portName: "Staines",
    portContinent: "Europe",
  },
  {
    lat: "54.5704551",
    lng: "-1.3289821",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTT",
    portName: "Stockton-on-Tees",
    portContinent: "Europe",
  },
  {
    lat: "52.270696",
    lng: "-1.893509",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTU",
    portName: "Studley",
    portContinent: "Europe",
  },
  {
    lat: "51.903761",
    lng: "-0.196612",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTV",
    portName: "Stevenage",
    portContinent: "Europe",
  },
  {
    lat: "52.188902",
    lng: "0.9977119999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTW",
    portName: "Stowmarket",
    portContinent: "Europe",
  },
  {
    lat: "52.907932",
    lng: "-2.144045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTX",
    portName: "Stone",
    portContinent: "Europe",
  },
  {
    lat: "53.48380599999999",
    lng: "-2.0500759",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTY",
    portName: "Stalybridge",
    portContinent: "Europe",
  },
  {
    lat: "54.556434",
    lng: "-0.795478",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSTZ",
    portName: "Staithes",
    portContinent: "Europe",
  },
  {
    lat: "53.1253529",
    lng: "-1.2602057",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUA",
    portName: "Sutton In Ashfield",
    portContinent: "Europe",
  },
  {
    lat: "53.1253529",
    lng: "-1.2602057",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUAÂ",
    portName: "Sutton-in-Ashfield",
    portContinent: "Europe",
  },
  {
    lat: "51.3462419",
    lng: "-0.804268",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUB",
    portName: "Sandhurst",
    portContinent: "Europe",
  },
  {
    lat: "52.57038499999999",
    lng: "-1.824042",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUC",
    portName: "SuttonColdfield",
    portContinent: "Europe",
  },
  {
    lat: "46.4917317",
    lng: "-80.99302899999999",
    country: "CA",
    country_long: "Canada",
    portCode: "GBSUD",
    portName: "Sudbury",
    portContinent: "Europe",
  },
  {
    lat: "53.079044",
    lng: "-0.9599209999999998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUE",
    portName: "Southwell",
    portContinent: "Europe",
  },
  {
    lat: "42.3522866",
    lng: "-71.0504229",
    country: "US",
    country_long: "United States",
    portCode: "GBSUF",
    portName: "Suffolk",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSUH",
    portName: "Spurn Head",
    portContinent: "Europe",
  },
  {
    lat: "42.0548167",
    lng: "-72.7703724",
    country: "US",
    country_long: "United States",
    portCode: "GBSUI",
    portName: "Southwick",
    portContinent: "Europe",
  },
  {
    lat: "52.88626199999999",
    lng: "0.7633169999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUK",
    portName: "SouthCreake",
    portContinent: "Europe",
  },
  {
    lat: "60.44187600000001",
    lng: "-1.355765",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUL",
    portName: "Sullom Voe (GBSUL), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.016818",
    lng: "-3.832119",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUM",
    portName: "South Molton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSUN",
    portName: "Sunderland",
    portContinent: "Europe",
  },
  {
    lat: "45.105894",
    lng: "-72.6166646",
    country: "CA",
    country_long: "Canada",
    portCode: "GBSUO",
    portName: "Sutton",
    portContinent: "Europe",
  },
  {
    lat: "50.948076",
    lng: "-2.810525",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUP",
    portName: "South Petherton",
    portContinent: "Europe",
  },
  {
    lat: "52.030733",
    lng: "-1.030189",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUQ",
    portName: "StoweBucks",
    portContinent: "Europe",
  },
  {
    lat: "51.39400999999999",
    lng: "-0.302662",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUR",
    portName: "Surbiton",
    portContinent: "Europe",
  },
  {
    lat: "45.105894",
    lng: "-72.6166646",
    country: "CA",
    country_long: "Canada",
    portCode: "GBSUS",
    portName: "SUTTON",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSUT",
    portName: "South Arne",
    portContinent: "Europe",
  },
  {
    lat: "41.5228832",
    lng: "-73.20179759999999",
    country: "US",
    country_long: "United States",
    portCode: "GBSUU",
    portName: "Soulbury",
    portContinent: "Europe",
  },
  {
    lat: "51.21326699999999",
    lng: "0.595379",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUV",
    portName: "SuttonValence",
    portContinent: "Europe",
  },
  {
    lat: "53.7098944",
    lng: "-1.8300199",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUW",
    portName: "Southowram",
    portContinent: "Europe",
  },
  {
    lat: "51.31475930000001",
    lng: "-0.5599501",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSUY",
    portName: "Surrey",
    portContinent: "Europe",
  },
  {
    lat: "37.811941",
    lng: "-107.6645057",
    country: "US",
    country_long: "United States",
    portCode: "GBSVE",
    portName: "Silverton",
    portContinent: "Europe",
  },
  {
    lat: "53.06278100000001",
    lng: "-2.448187",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSVG",
    portName: "Shavington",
    portContinent: "Europe",
  },
  {
    lat: "51.1281204",
    lng: "0.9081123",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSVI",
    portName: "Sevington",
    portContinent: "Europe",
  },
  {
    lat: "51.5636064",
    lng: "0.098511",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSVK",
    portName: "SevenKings",
    portContinent: "Europe",
  },
  {
    lat: "52.984648",
    lng: "-0.803396",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSVL",
    portName: "StauntonintheVale",
    portContinent: "Europe",
  },
  {
    lat: "56.96366099999999",
    lng: "-2.209288",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSVN",
    portName: "Stonehaven",
    portContinent: "Europe",
  },
  {
    lat: "52.2468167",
    lng: "-1.2109743",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSVR",
    portName: "Staverton",
    portContinent: "Europe",
  },
  {
    lat: "31.9042517",
    lng: "-110.2142399",
    country: "US",
    country_long: "United States",
    portCode: "GBSVS",
    portName: "SaintDavid's",
    portContinent: "Europe",
  },
  {
    lat: "51.5025179",
    lng: "0.0403209",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSVT",
    portName: "Silvertown",
    portContinent: "Europe",
  },
  {
    lat: "50.719848",
    lng: "-1.11181",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSVW",
    portName: "Seaview",
    portContinent: "Europe",
  },
  {
    lat: "54.377897",
    lng: "-2.817677",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSVX",
    portName: "Staveley",
    portContinent: "Europe",
  },
  {
    lat: "54.377897",
    lng: "-2.817677",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSVY",
    portName: "Staveley",
    portContinent: "Europe",
  },
  {
    lat: "45.832987",
    lng: "0.3885739",
    country: "FR",
    country_long: "France",
    portCode: "GBSVZ",
    portName: "SaintMary's",
    portContinent: "Europe",
  },
  {
    lat: "52.3010639",
    lng: "-0.004534",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSW4",
    portName: "Swavesey",
    portContinent: "Europe",
  },
  {
    lat: "51.5583777",
    lng: "-1.7809759",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSW8",
    portName: "Swindon",
    portContinent: "Europe",
  },
  {
    lat: "51.62144",
    lng: "-3.943645999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWA",
    portName: "Swansea",
    portContinent: "Europe",
  },
  {
    lat: "51.4452254",
    lng: "0.3031822",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWB",
    portName: "Swanscombe",
    portContinent: "Europe",
  },
  {
    lat: "60.00107919999999",
    lng: "-1.2335307",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWC",
    portName: "Sandwick",
    portContinent: "Europe",
  },
  {
    lat: "52.32562799999999",
    lng: "1.680181",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWD",
    portName: "Southwold",
    portContinent: "Europe",
  },
  {
    lat: "50.608277",
    lng: "-1.960769",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWE",
    portName: "Swanage (GBSWE), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6465",
    lng: "0.614665",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWF",
    portName: "South Woodham Ferrers",
    portContinent: "Europe",
  },
  {
    lat: "54.90802",
    lng: "-6.6668201",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWG",
    portName: "Swatragh",
    portContinent: "Europe",
  },
  {
    lat: "52.64746599999999",
    lng: "0.687481",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWH",
    portName: "Swaffham",
    portContinent: "Europe",
  },
  {
    lat: "52.5811667",
    lng: "-1.1324904",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWI",
    portName: "South Wigston",
    portContinent: "Europe",
  },
  {
    lat: "52.32873799999999",
    lng: "-0.562508",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWJ",
    portName: "Stanwick",
    portContinent: "Europe",
  },
  {
    lat: "50.886238",
    lng: "-1.268443",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWK",
    portName: "Swanwick",
    portContinent: "Europe",
  },
  {
    lat: "-23.4849933",
    lng: "150.3253367",
    country: "AU",
    country_long: "Australia",
    portCode: "GBSWL",
    portName: "Stanwell",
    portContinent: "Europe",
  },
  {
    lat: "50.9440983",
    lng: "-1.1802134",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWM",
    portName: "Swanmore",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSWN",
    portName: "Swinton",
    portContinent: "Europe",
  },
  {
    lat: "51.68135299999999",
    lng: "-0.985879",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWO",
    portName: "SouthWeston",
    portContinent: "Europe",
  },
  {
    lat: "39.9382626",
    lng: "-75.1472414",
    country: "US",
    country_long: "United States",
    portCode: "GBSWR",
    portName: "Southwark",
    portContinent: "Europe",
  },
  {
    lat: "53.76846399999999",
    lng: "-1.416674",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWT",
    portName: "SWILLINGTON, WEST YORKSHIRE",
    portContinent: "Europe",
  },
  {
    lat: "51.221619",
    lng: "-0.9684832000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWU",
    portName: "SouthWarnborough",
    portContinent: "Europe",
  },
  {
    lat: "53.3098637",
    lng: "-2.685287",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWV",
    portName: "SuttonWeaver",
    portContinent: "Europe",
  },
  {
    lat: "51.81554",
    lng: "0.14721",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWW",
    portName: "Sawbridgeworth",
    portContinent: "Europe",
  },
  {
    lat: "55.67995699999999",
    lng: "-4.513587",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWX",
    portName: "Stewarton",
    portContinent: "Europe",
  },
  {
    lat: "60.137715",
    lng: "-1.282203",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSWY",
    portName: "Scalloway",
    portContinent: "Europe",
  },
  {
    lat: "53.270689",
    lng: "-0.666012",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSXB",
    portName: "Saxilby",
    portContinent: "Europe",
  },
  {
    lat: "54.4601231",
    lng: "-5.8360999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSXD",
    portName: "Saintfield",
    portContinent: "Europe",
  },
  {
    lat: "58.45415099999999",
    lng: "-3.059185",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSXE",
    portName: "Staxigoe",
    portContinent: "Europe",
  },
  {
    lat: "52.215855",
    lng: "1.488056",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSXM",
    portName: "Saxmundham",
    portContinent: "Europe",
  },
  {
    lat: "51.228457",
    lng: "-1.2201169",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSXN",
    portName: "Steventon",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSXX",
    portName: "Seal",
    portContinent: "Europe",
  },
  {
    lat: "53.835927",
    lng: "-1.776978",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSXY",
    portName: "Shipley",
    portContinent: "Europe",
  },
  {
    lat: "50.991181",
    lng: "-0.051961",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSY4",
    portName: "ScaynesHill",
    portContinent: "Europe",
  },
  {
    lat: "54.166906",
    lng: "-0.9322051000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSY5",
    portName: "Slingsby",
    portContinent: "Europe",
  },
  {
    lat: "52.5506147",
    lng: "-1.2777766",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYA",
    portName: "StoneyStanton",
    portContinent: "Europe",
  },
  {
    lat: "53.706709",
    lng: "-1.913411",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYB",
    portName: "Sowerby Bridge",
    portContinent: "Europe",
  },
  {
    lat: "50.9497345",
    lng: "-0.1978106",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYC",
    portName: "SayersCommon",
    portContinent: "Europe",
  },
  {
    lat: "57.1910312",
    lng: "-2.1782003",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYD",
    portName: "Stoneywood",
    portContinent: "Europe",
  },
  {
    lat: "52.101065",
    lng: "-2.929798",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYE",
    portName: "StauntononWye",
    portContinent: "Europe",
  },
  {
    lat: "50.887403",
    lng: "-0.326676",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYG",
    portName: "Steyning",
    portContinent: "Europe",
  },
  {
    lat: "54.03208",
    lng: "-0.8840330000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYH",
    portName: "Scrayingham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBSYI",
    portName: "Sidley",
    portContinent: "Europe",
  },
  {
    lat: "53.9590858",
    lng: "-1.0792403",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYK",
    portName: "S.Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "58.09299699999999",
    lng: "-4.480007",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYL",
    portName: "Shinness",
    portContinent: "Europe",
  },
  {
    lat: "60.341001",
    lng: "-1.024927",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYM",
    portName: "Symbister (GBSYM), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5354901",
    lng: "0.7905700999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYN",
    portName: "Shoeburyness",
    portContinent: "Europe",
  },
  {
    lat: "53.277288",
    lng: "-1.658657",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYO",
    portName: "StonyMiddleton",
    portContinent: "Europe",
  },
  {
    lat: "51.385993",
    lng: "0.113061",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYQ",
    portName: "SaintMaryCray",
    portContinent: "Europe",
  },
  {
    lat: "53.7743225",
    lng: "-1.5018158",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYR",
    portName: "Stourton/Leeds",
    portContinent: "Europe",
  },
  {
    lat: "52.6992685",
    lng: "-1.0729984",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYS",
    portName: "Syston",
    portContinent: "Europe",
  },
  {
    lat: "50.9396865",
    lng: "-1.3793283",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYT",
    portName: "Swaythling",
    portContinent: "Europe",
  },
  {
    lat: "52.2969869",
    lng: "-0.801134",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYW",
    portName: "Sywell",
    portContinent: "Europe",
  },
  {
    lat: "55.5993533",
    lng: "-3.5948339",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYX",
    portName: "Symington",
    portContinent: "Europe",
  },
  {
    lat: "51.72975899999999",
    lng: "-5.017754",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSYY",
    portName: "Steynton",
    portContinent: "Europe",
  },
  {
    lat: "52.2084458",
    lng: "1.6230982",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSZB",
    portName: "SizewellBeach",
    portContinent: "Europe",
  },
  {
    lat: "44.975431",
    lng: "2.661836",
    country: "FR",
    country_long: "France",
    portCode: "GBSZC",
    portName: "SaintClement",
    portContinent: "Europe",
  },
  {
    lat: "53.58584",
    lng: "-0.186296",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSZH",
    portName: "Stallingborough, Lincolnshire",
    portContinent: "Europe",
  },
  {
    lat: "52.335212",
    lng: "0.3375079",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSZM",
    portName: "Soham",
    portContinent: "Europe",
  },
  {
    lat: "57.4766128",
    lng: "-6.2914429",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSZT",
    portName: "Snizort",
    portContinent: "Europe",
  },
  {
    lat: "53.645708",
    lng: "-3.010113",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBSZU",
    portName: "Southport, Merseyside",
    portContinent: "Europe",
  },
  {
    lat: "52.78537739999999",
    lng: "1.4131609",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBT6D",
    portName: "Worstead",
    portContinent: "Europe",
  },
  {
    lat: "52.1306607",
    lng: "-3.7837117",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBT6V",
    portName: "Wales",
    portContinent: "Europe",
  },
  {
    lat: "53.23161",
    lng: "-0.9824359999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBT6VÂ",
    portName: "Walesby",
    portContinent: "Europe",
  },
  {
    lat: "52.9041271",
    lng: "-4.062969100000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTAA",
    portName: "Talsarnau",
    portContinent: "Europe",
  },
  {
    lat: "55.863246",
    lng: "-5.415608",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTAB",
    portName: "Tarbert (GBTAB), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.947518",
    lng: "-4.148059",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTAD",
    portName: "Taddiport",
    portContinent: "Europe",
  },
  {
    lat: "41.0534302",
    lng: "-73.5387341",
    country: "US",
    country_long: "United States",
    portCode: "GBTAF",
    portName: "Stamford",
    portContinent: "Europe",
  },
  {
    lat: "52.06398600000001",
    lng: "-2.1357179",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTAH",
    portName: "Strensham",
    portContinent: "Europe",
  },
  {
    lat: "55.652587",
    lng: "-5.662299",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTAI",
    portName: "Tayinloan",
    portContinent: "Europe",
  },
  {
    lat: "51.4368102",
    lng: "-1.0709569",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTAL",
    portName: "Theale",
    portContinent: "Europe",
  },
  {
    lat: "53.4805828",
    lng: "-2.0809891",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTAM",
    portName: "Tameside",
    portContinent: "Europe",
  },
  {
    lat: "52.665836",
    lng: "-3.909282",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTAN",
    portName: "Tal-y-llyn",
    portContinent: "Europe",
  },
  {
    lat: "55.863246",
    lng: "-5.415608",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTAR",
    portName: "Tarbert",
    portContinent: "Europe",
  },
  {
    lat: "39.8762246",
    lng: "-75.0296144",
    country: "US",
    country_long: "United States",
    portCode: "GBTAS",
    portName: "Tavistock",
    portContinent: "Europe",
  },
  {
    lat: "51.0562529",
    lng: "-2.8944731",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTAT",
    portName: "Stathe",
    portContinent: "Europe",
  },
  {
    lat: "41.900101",
    lng: "-71.0897674",
    country: "US",
    country_long: "United States",
    portCode: "GBTAU",
    portName: "Taunton",
    portContinent: "Europe",
  },
  {
    lat: "52.678335",
    lng: "1.194086",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTAV",
    portName: "Taverham",
    portContinent: "Europe",
  },
  {
    lat: "43.8597958",
    lng: "-71.2631263",
    country: "US",
    country_long: "United States",
    portCode: "GBTAW",
    portName: "Tamworth",
    portContinent: "Europe",
  },
  {
    lat: "56.447996",
    lng: "-2.880912",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTAY",
    portName: "Tayport",
    portContinent: "Europe",
  },
  {
    lat: "54.43499300000001",
    lng: "-2.588579",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTBA",
    portName: "Tebay",
    portContinent: "Europe",
  },
  {
    lat: "-37.759569",
    lng: "145.0004556",
    country: "AU",
    country_long: "Australia",
    portCode: "GBTBB",
    portName: "Thornbury",
    portContinent: "Europe",
  },
  {
    lat: "54.8114746",
    lng: "-6.707056",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTBE",
    portName: "Tobermore",
    portContinent: "Europe",
  },
  {
    lat: "51.319664",
    lng: "-2.208853",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTBG",
    portName: "Trowbridge",
    portContinent: "Europe",
  },
  {
    lat: "51.9957988",
    lng: "-0.9156127000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTBH",
    portName: "Thornborough",
    portContinent: "Europe",
  },
  {
    lat: "51.752725",
    lng: "-0.339436",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTBL",
    portName: "Saint Albans",
    portContinent: "Europe",
  },
  {
    lat: "56.216925",
    lng: "-5.631715",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTBN",
    portName: "Toberonochy",
    portContinent: "Europe",
  },
  {
    lat: "49.1875396",
    lng: "-2.1847371",
    country: "JE",
    country_long: "Jersey",
    portCode: "GBTBR",
    portName: "SaintBrelade",
    portContinent: "Europe",
  },
  {
    lat: "53.140349",
    lng: "-1.346878",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTBS",
    portName: "Tibshelf",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTBU",
    portName: "Turnberry",
    portContinent: "Europe",
  },
  {
    lat: "50.43508",
    lng: "-3.564235",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTBY",
    portName: "Torbay/Paignton",
    portContinent: "Europe",
  },
  {
    lat: "53.39146599999999",
    lng: "-1.2521199",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTCF",
    portName: "Thurcroft",
    portContinent: "Europe",
  },
  {
    lat: "51.04619",
    lng: "0.409067",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTCH",
    portName: "Ticehurst",
    portContinent: "Europe",
  },
  {
    lat: "52.69464499999999",
    lng: "-1.401395",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTCK",
    portName: "Ibstock",
    portContinent: "Europe",
  },
  {
    lat: "51.09739099999999",
    lng: "0.033222",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTCQ",
    portName: "Forest Row, East Sussex",
    portContinent: "Europe",
  },
  {
    lat: "52.9758246",
    lng: "-1.2813207",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTCS",
    portName: "Cossall",
    portContinent: "Europe",
  },
  {
    lat: "53.9661194",
    lng: "-1.2928458",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTCW",
    portName: "Tockwith, North Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "51.502985",
    lng: "-0.4654099999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTCX",
    portName: "West Drayton, Greater London",
    portContinent: "Europe",
  },
  {
    lat: "54.2366438",
    lng: "-0.7191111",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTDA",
    portName: "ThorntonleDale",
    portContinent: "Europe",
  },
  {
    lat: "50.979067",
    lng: "-2.286782",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTDB",
    portName: "Todber",
    portContinent: "Europe",
  },
  {
    lat: "51.4268694",
    lng: "-0.3338681",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTDD",
    portName: "Teddington",
    portContinent: "Europe",
  },
  {
    lat: "54.3561049",
    lng: "-6.4147568",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTDG",
    portName: "Tandragee",
    portContinent: "Europe",
  },
  {
    lat: "52.899919",
    lng: "-4.621257",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTDI",
    portName: "Tudweiliog",
    portContinent: "Europe",
  },
  {
    lat: "53.716344",
    lng: "-2.0987969",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTDM",
    portName: "Todmorden",
    portContinent: "Europe",
  },
  {
    lat: "51.9929387",
    lng: "-1.9493289",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTDN",
    portName: "Toddington",
    portContinent: "Europe",
  },
  {
    lat: "54.504523",
    lng: "-1.354759",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTDQ",
    portName: "Yarm, North Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "51.772619",
    lng: "-3.246775",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTDR",
    portName: "Tredegar",
    portContinent: "Europe",
  },
  {
    lat: "53.883551",
    lng: "-1.260889",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTDS",
    portName: "Tadcaster",
    portContinent: "Europe",
  },
  {
    lat: "51.389686",
    lng: "-0.339189",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTDT",
    portName: "ThamesDitton",
    portContinent: "Europe",
  },
  {
    lat: "51.2914048",
    lng: "-0.2363114",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTDW",
    portName: "Tadworth",
    portContinent: "Europe",
  },
  {
    lat: "51.343801",
    lng: "-1.132169",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTDY",
    portName: "Tadley",
    portContinent: "Europe",
  },
  {
    lat: "51.992265",
    lng: "-2.15796",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTEB",
    portName: "Tewkesbury",
    portContinent: "Europe",
  },
  {
    lat: "45.0126146",
    lng: "-72.0957682",
    country: "CA",
    country_long: "Canada",
    portCode: "GBTED",
    portName: "Stanstead",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTEE",
    portName: "Teesport",
    portContinent: "Europe",
  },
  {
    lat: "52.6775873",
    lng: "-2.4672611",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTEF",
    portName: "Telford",
    portContinent: "Europe",
  },
  {
    lat: "53.1507291",
    lng: "-3.82576",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTEI",
    portName: "Trefriw",
    portContinent: "Europe",
  },
  {
    lat: "54.6358319",
    lng: "-2.6017091",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTEM",
    portName: "TempleSowerby",
    portContinent: "Europe",
  },
  {
    lat: "51.6713807",
    lng: "-4.698185",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTEN",
    portName: "Tenby (Dinbych-Y-Pysgod)",
    portContinent: "Europe",
  },
  {
    lat: "51.66497200000001",
    lng: "-3.30586",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTER",
    portName: "Treharris",
    portContinent: "Europe",
  },
  {
    lat: "51.639295",
    lng: "-2.15818",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTET",
    portName: "Tetbury",
    portContinent: "Europe",
  },
  {
    lat: "52.307662",
    lng: "-2.596406",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTEW",
    portName: "Tenbury Wells, Worcestershire",
    portContinent: "Europe",
  },
  {
    lat: "51.992265",
    lng: "-2.15796",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTEY",
    portName: "Tewkesbury, Gloucestershire",
    portContinent: "Europe",
  },
  {
    lat: "51.536704",
    lng: "-3.386005",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTEZ",
    portName: "TalbotGreen",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTFD",
    portName: "Thetford",
    portContinent: "Europe",
  },
  {
    lat: "51.3172381",
    lng: "0.351548",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTFF",
    portName: "Trottiscliffe",
    portContinent: "Europe",
  },
  {
    lat: "53.47072360000001",
    lng: "-2.3230574",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTFK",
    portName: "Trafford Park",
    portContinent: "Europe",
  },
  {
    lat: "51.1842962",
    lng: "-0.7496174",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTFO",
    portName: "Tilford",
    portContinent: "Europe",
  },
  {
    lat: "51.5928968",
    lng: "-3.3279837",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTFR",
    portName: "Trefforest",
    portContinent: "Europe",
  },
  {
    lat: "51.5928968",
    lng: "-3.3279837",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTFS",
    portName: "Treforest",
    portContinent: "Europe",
  },
  {
    lat: "52.18654979999999",
    lng: "-0.0078878",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTFT",
    portName: "Toft (GBTFT), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.545383",
    lng: "-3.27196",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTFW",
    portName: "Taff'sWell",
    portContinent: "Europe",
  },
  {
    lat: "51.7242781",
    lng: "-0.0251206",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTFX",
    portName: "Turnford",
    portContinent: "Europe",
  },
  {
    lat: "54.5403076",
    lng: "-1.2936359",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTGB",
    portName: "Thornaby On Tees, North Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "51.48034209999999",
    lng: "-0.6157136999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTGD",
    portName: "Windsor, Berkshire",
    portContinent: "Europe",
  },
  {
    lat: "51.9903713",
    lng: "-1.0439434",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTGE",
    portName: "Tingewick",
    portContinent: "Europe",
  },
  {
    lat: "51.233476",
    lng: "-0.731719",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTGH",
    portName: "Tongham",
    portContinent: "Europe",
  },
  {
    lat: "50.847972",
    lng: "-0.716345",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTGM",
    portName: "Tangmere",
    portContinent: "Europe",
  },
  {
    lat: "51.84049",
    lng: "1.026629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTGN",
    portName: "Thorrington",
    portContinent: "Europe",
  },
  {
    lat: "54.7143352",
    lng: "-1.4254016",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTGR",
    portName: "TrimdonGrange",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTGU",
    portName: "Tongue",
    portContinent: "Europe",
  },
  {
    lat: "53.6456971",
    lng: "-0.3565611",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTH7",
    portName: "ThorntonCurtis",
    portContinent: "Europe",
  },
  {
    lat: "51.40580500000001",
    lng: "-1.266468",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTHA",
    portName: "Thatcham",
    portContinent: "Europe",
  },
  {
    lat: "37.2614207",
    lng: "-121.8115386",
    country: "US",
    country_long: "United States",
    portCode: "GBTHB",
    portName: "Thornbridge",
    portContinent: "Europe",
  },
  {
    lat: "50.8498301",
    lng: "-1.884612",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTHC",
    portName: "ThreeLeggedCross",
    portContinent: "Europe",
  },
  {
    lat: "52.412856",
    lng: "0.751657",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTHD",
    portName: "Thetford, Norfolk",
    portContinent: "Europe",
  },
  {
    lat: "51.746997",
    lng: "-0.9741879999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTHE",
    portName: "Thame",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTHF",
    portName: "ThistleField",
    portContinent: "Europe",
  },
  {
    lat: "51.396747",
    lng: "-0.105523",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTHH",
    portName: "Thornton Heath",
    portContinent: "Europe",
  },
  {
    lat: "54.233849",
    lng: "-1.341377",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTHI",
    portName: "Thirsk, North Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "52.98488400000001",
    lng: "-1.986503",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTHJ",
    portName: "Cheadle, Staffordshire",
    portContinent: "Europe",
  },
  {
    lat: "46.8645747",
    lng: "-122.7695986",
    country: "US",
    country_long: "United States",
    portCode: "GBTHK",
    portName: "Thurston",
    portContinent: "Europe",
  },
  {
    lat: "52.642699",
    lng: "-0.899984",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTHL",
    portName: "Tilton On The Hill",
    portContinent: "Europe",
  },
  {
    lat: "43.9275435",
    lng: "-69.9759459",
    country: "US",
    country_long: "United States",
    portCode: "GBTHM",
    portName: "Topsham",
    portContinent: "Europe",
  },
  {
    lat: "39.8680412",
    lng: "-104.9719243",
    country: "US",
    country_long: "United States",
    portCode: "GBTHN",
    portName: "Thornton",
    portContinent: "Europe",
  },
  {
    lat: "54.16787189999999",
    lng: "-1.2459351",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTHO",
    portName: "Thormanby",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTHP",
    portName: "Thamesport",
    portContinent: "Europe",
  },
  {
    lat: "58.593566",
    lng: "-3.52208",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTHR",
    portName: "Thurso",
    portContinent: "Europe",
  },
  {
    lat: "51.5089329",
    lng: "0.5061443",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTHS",
    portName: "THAMES HAVEN",
    portContinent: "Europe",
  },
  {
    lat: "57.264214",
    lng: "-7.33121",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTHT",
    portName: "SouthUistandEriskay",
    portContinent: "Europe",
  },
  {
    lat: "52.6214197",
    lng: "-0.1080679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTHY",
    portName: "Thorney",
    portContinent: "Europe",
  },
  {
    lat: "50.66301000000001",
    lng: "-4.750660000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTIA",
    portName: "Tintagel",
    portContinent: "Europe",
  },
  {
    lat: "53.4329349",
    lng: "-1.1093635",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTIC",
    portName: "Tickhill",
    portContinent: "Europe",
  },
  {
    lat: "50.8485889",
    lng: "-1.241713",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTID",
    portName: "Titchfield",
    portContinent: "Europe",
  },
  {
    lat: "55.9069394",
    lng: "-5.2329105",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTIG",
    portName: "Tighnabruaich",
    portContinent: "Europe",
  },
  {
    lat: "52.4061069",
    lng: "-1.5755469",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTIH",
    portName: "TileHill",
    portContinent: "Europe",
  },
  {
    lat: "51.463024",
    lng: "0.360498",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTIL",
    portName: "Tilbury",
    portContinent: "Europe",
  },
  {
    lat: "51.325321",
    lng: "-2.477119",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTIM",
    portName: "Timsbury",
    portContinent: "Europe",
  },
  {
    lat: "57.811501",
    lng: "-4.05523",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTIN",
    portName: "Tain",
    portContinent: "Europe",
  },
  {
    lat: "51.812119",
    lng: "0.74656",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTIP",
    portName: "Tiptree",
    portContinent: "Europe",
  },
  {
    lat: "51.721017",
    lng: "-3.2497729",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTIR",
    portName: "Tirphil",
    portContinent: "Europe",
  },
  {
    lat: "51.063138",
    lng: "-2.079703",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTIS",
    portName: "Tisbury, Wiltshire",
    portContinent: "Europe",
  },
  {
    lat: "56.153916",
    lng: "-3.740211",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTIT",
    portName: "Tillicoultry",
    portContinent: "Europe",
  },
  {
    lat: "50.902049",
    lng: "-3.491207",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTIV",
    portName: "Tiverton, Devon",
    portContinent: "Europe",
  },
  {
    lat: "53.847426",
    lng: "-1.950299",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTJJ",
    portName: "Oakworth, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "53.034152",
    lng: "-1.20289",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTJT",
    portName: "Hucknall, Nottinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "51.4309209",
    lng: "-0.0936496",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTJX",
    portName: "Staines, Greater London",
    portContinent: "Europe",
  },
  {
    lat: "51.318697",
    lng: "-0.384045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTKD",
    portName: "StokeD'Abernon",
    portContinent: "Europe",
  },
  {
    lat: "51.954408",
    lng: "-0.053443",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTKG",
    portName: "Throcking",
    portContinent: "Europe",
  },
  {
    lat: "35.4233308",
    lng: "-94.32279940000001",
    country: "US",
    country_long: "United States",
    portCode: "GBTKL",
    portName: "Tankersley",
    portContinent: "Europe",
  },
  {
    lat: "51.4431859",
    lng: "-2.8057714",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTKM",
    portName: "Tickenham",
    portContinent: "Europe",
  },
  {
    lat: "33.1787153",
    lng: "-99.17757560000001",
    country: "US",
    country_long: "United States",
    portCode: "GBTKN",
    portName: "Throckmorton",
    portContinent: "Europe",
  },
  {
    lat: "51.5511451",
    lng: "-1.0226753",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTKR",
    portName: "StokeRow",
    portContinent: "Europe",
  },
  {
    lat: "-37.2960445",
    lng: "144.9520417",
    country: "AU",
    country_long: "Australia",
    portCode: "GBTKV",
    portName: "Kilmore",
    portContinent: "Europe",
  },
  {
    lat: "53.9661194",
    lng: "-1.2928458",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTKW",
    portName: "Tockwith",
    portContinent: "Europe",
  },
  {
    lat: "51.8678841",
    lng: "0.2664294",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTKY",
    portName: "Takeley",
    portContinent: "Europe",
  },
  {
    lat: "56.13340299999999",
    lng: "-3.837698999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLB",
    portName: "Tullibody",
    portContinent: "Europe",
  },
  {
    lat: "54.45168",
    lng: "-7.4871899",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLC",
    portName: "TRILLICK",
    portContinent: "Europe",
  },
  {
    lat: "60.5296507",
    lng: "-1.2659408",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLD",
    portName: "Shetland",
    portContinent: "Europe",
  },
  {
    lat: "46.19140059999999",
    lng: "-122.1955509",
    country: "US",
    country_long: "United States",
    portCode: "GBTLE",
    portName: "SaintHelens",
    portContinent: "Europe",
  },
  {
    lat: "54.17792679999999",
    lng: "-1.3875191",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLF",
    portName: "Topcliffe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTLG",
    portName: "Tylers Green",
    portContinent: "Europe",
  },
  {
    lat: "52.2148976",
    lng: "-0.4588026",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLH",
    portName: "Thurleigh",
    portContinent: "Europe",
  },
  {
    lat: "56.024944",
    lng: "-5.627589",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLI",
    portName: "Tayvallich",
    portContinent: "Europe",
  },
  {
    lat: "51.77049299999999",
    lng: "0.751769",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLL",
    portName: "Tolleshunt Major, Essex",
    portContinent: "Europe",
  },
  {
    lat: "50.958487",
    lng: "0.6776976",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLM",
    portName: "Tillingham",
    portContinent: "Europe",
  },
  {
    lat: "58.531724",
    lng: "-4.432752",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLN",
    portName: "Talmine",
    portContinent: "Europe",
  },
  {
    lat: "51.8999993",
    lng: "-1.1531524",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLP",
    portName: "Bicester, Oxfordshire",
    portContinent: "Europe",
  },
  {
    lat: "50.9141239",
    lng: "-1.4985934",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLQ",
    portName: "Totton",
    portContinent: "Europe",
  },
  {
    lat: "53.5293144",
    lng: "-1.6506973",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLS",
    portName: "Thurlstone",
    portContinent: "Europe",
  },
  {
    lat: "53.681461",
    lng: "-2.835089",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLT",
    portName: "Tarleton",
    portContinent: "Europe",
  },
  {
    lat: "51.94921",
    lng: "-0.283414",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLU",
    portName: "Hitchin, Hertfordshire",
    portContinent: "Europe",
  },
  {
    lat: "51.532169",
    lng: "-0.68796",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTLW",
    portName: "Taplow",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTLY",
    portName: "TrimleyHeath",
    portContinent: "Europe",
  },
  {
    lat: "51.77049299999999",
    lng: "0.751769",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTMJ",
    portName: "TolleshuntMajor",
    portContinent: "Europe",
  },
  {
    lat: "54.1992584",
    lng: "-2.9496168",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTML",
    portName: "Cartmel",
    portContinent: "Europe",
  },
  {
    lat: "51.50575809999999",
    lng: "0.1100586",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTMM",
    portName: "Thamesmead, London Postal",
    portContinent: "Europe",
  },
  {
    lat: "53.39672700000001",
    lng: "-2.31927",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTMP",
    portName: "Timperley",
    portContinent: "Europe",
  },
  {
    lat: "51.4619357",
    lng: "-0.5029039",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTMR",
    portName: "StanwellMoor",
    portContinent: "Europe",
  },
  {
    lat: "52.67197710000001",
    lng: "-1.0911742",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTMS",
    portName: "Thurmaston",
    portContinent: "Europe",
  },
  {
    lat: "51.990256",
    lng: "1.309577",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTMT",
    portName: "TrimleySt.Martin",
    portContinent: "Europe",
  },
  {
    lat: "51.195043",
    lng: "0.27568",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTNB",
    portName: "Tonbridge",
    portContinent: "Europe",
  },
  {
    lat: "51.725308",
    lng: "-1.428075",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTND",
    portName: "Standlake",
    portContinent: "Europe",
  },
  {
    lat: "60.1659305",
    lng: "-1.2266412",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTNG",
    portName: "Tingwall (GBTNG), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.0605166",
    lng: "-2.2011938",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTNL",
    portName: "Tunstall",
    portContinent: "Europe",
  },
  {
    lat: "50.54703300000001",
    lng: "-3.496688",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTNM",
    portName: "Teignmouth",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTNN",
    portName: "Hilton",
    portContinent: "Europe",
  },
  {
    lat: "40.7080621",
    lng: "-73.9205073",
    country: "US",
    country_long: "United States",
    portCode: "GBTNO",
    portName: "Tong",
    portContinent: "Europe",
  },
  {
    lat: "49.1805019",
    lng: "-2.103233",
    country: "JE",
    country_long: "Jersey",
    portCode: "GBTNR",
    portName: "SaintHelier",
    portContinent: "Europe",
  },
  {
    lat: "55.94450999999999",
    lng: "-2.954526",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTNT",
    portName: "Tranent",
    portContinent: "Europe",
  },
  {
    lat: "54.9951837",
    lng: "-1.5666991",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTNW",
    portName: "Tyne & Wear",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTNY",
    portName: "Stanley",
    portContinent: "Europe",
  },
  {
    lat: "57.33966460000001",
    lng: "-4.0088617",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTOA",
    portName: "Tomatin",
    portContinent: "Europe",
  },
  {
    lat: "56.6227813",
    lng: "-6.0723004",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTOB",
    portName: "Tobermory (GBTOB), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "37.8751707",
    lng: "-122.2782549",
    country: "US",
    country_long: "United States",
    portCode: "GBTOD",
    portName: "Totland",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTOE",
    portName: "Thorne",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTOF",
    portName: "Toft",
    portContinent: "Europe",
  },
  {
    lat: "40.7080621",
    lng: "-73.9205073",
    country: "US",
    country_long: "United States",
    portCode: "GBTOG",
    portName: "Tong",
    portContinent: "Europe",
  },
  {
    lat: "41.9152058",
    lng: "-87.8407378",
    country: "US",
    country_long: "United States",
    portCode: "GBTOI",
    portName: "Triton",
    portContinent: "Europe",
  },
  {
    lat: "53.716344",
    lng: "-2.0987969",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTOJ",
    portName: "Todmorden, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "53.0033369",
    lng: "-2.1827408",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTOK",
    portName: "Stoke",
    portContinent: "Europe",
  },
  {
    lat: "51.75798700000001",
    lng: "0.837317",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTOL",
    portName: "Tollesbury",
    portContinent: "Europe",
  },
  {
    lat: "51.7716374",
    lng: "0.7949977",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTOM",
    portName: "TolleshuntD'Arcy",
    portContinent: "Europe",
  },
  {
    lat: "40.7862871",
    lng: "-74.3300842",
    country: "US",
    country_long: "United States",
    portCode: "GBTON",
    portName: "Livingston",
    portContinent: "Europe",
  },
  {
    lat: "54.754",
    lng: "-6.46131",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTOO",
    portName: "Toomebridge",
    portContinent: "Europe",
  },
  {
    lat: "50.4619209",
    lng: "-3.525315",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTOR",
    portName: "Torquay",
    portContinent: "Europe",
  },
  {
    lat: "54.5403076",
    lng: "-1.2936359",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTOT",
    portName: "Thornaby On Tees",
    portContinent: "Europe",
  },
  {
    lat: "54.5403076",
    lng: "-1.2936359",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTOTÂ",
    portName: "Thornaby",
    portContinent: "Europe",
  },
  {
    lat: "52.135107",
    lng: "-0.9896879999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTOW",
    portName: "Towcester",
    portContinent: "Europe",
  },
  {
    lat: "51.263484",
    lng: "-0.4567809999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTOY",
    portName: "West Horsley",
    portContinent: "Europe",
  },
  {
    lat: "51.304309",
    lng: "0.47806",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTOZ",
    portName: "Aylesford, Kent",
    portContinent: "Europe",
  },
  {
    lat: "51.5342335",
    lng: "0.7608574",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTPB",
    portName: "Thorpe Bay",
    portContinent: "Europe",
  },
  {
    lat: "50.9960639",
    lng: "-2.415668",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTPC",
    portName: "Templecombe",
    portContinent: "Europe",
  },
  {
    lat: "52.99936",
    lng: "-2.839106",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTPD",
    portName: "Threapwood",
    portContinent: "Europe",
  },
  {
    lat: "55.9334661",
    lng: "-3.6542949",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTPH",
    portName: "Torphichen",
    portContinent: "Europe",
  },
  {
    lat: "57.104876",
    lng: "-2.622312",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTPI",
    portName: "Torphins",
    portContinent: "Europe",
  },
  {
    lat: "53.160142",
    lng: "-2.669436",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTPL",
    portName: "Tarporley",
    portContinent: "Europe",
  },
  {
    lat: "40.2822598",
    lng: "-86.04109799999999",
    country: "US",
    country_long: "United States",
    portCode: "GBTPN",
    portName: "Tipton",
    portContinent: "Europe",
  },
  {
    lat: "50.37529",
    lng: "-4.194344",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTPO",
    portName: "Torpoint (GBTPO), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8386787",
    lng: "-1.3040402",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTPP",
    portName: "Thrupp",
    portContinent: "Europe",
  },
  {
    lat: "52.394588",
    lng: "-0.535859",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTPS",
    portName: "Thrapston",
    portContinent: "Europe",
  },
  {
    lat: "53.183162",
    lng: "-1.422504",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTPT",
    portName: "Tupton",
    portContinent: "Europe",
  },
  {
    lat: "51.62435199999999",
    lng: "-3.458067",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTPY",
    portName: "Tonypandy",
    portContinent: "Europe",
  },
  {
    lat: "53.3783943",
    lng: "-3.0164463",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRA",
    portName: "Tranmere (GBTRA), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.73826409999999",
    lng: "-0.3802246",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRB",
    portName: "Thurlby",
    portContinent: "Europe",
  },
  {
    lat: "52.5713893",
    lng: "-2.2248907",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRC",
    portName: "Trescott",
    portContinent: "Europe",
  },
  {
    lat: "57.54687669999999",
    lng: "-5.5133569",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRD",
    portName: "Torridon",
    portContinent: "Europe",
  },
  {
    lat: "56.49323760000001",
    lng: "-6.906971400000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRE",
    portName: "Tiree",
    portContinent: "Europe",
  },
  {
    lat: "57.538932",
    lng: "-2.4623",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRF",
    portName: "Turriff",
    portContinent: "Europe",
  },
  {
    lat: "52.2199979",
    lng: "-3.934385",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRG",
    portName: "Tregaron",
    portContinent: "Europe",
  },
  {
    lat: "51.675007",
    lng: "-3.537165",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRH",
    portName: "Treherbert",
    portContinent: "Europe",
  },
  {
    lat: "51.79607799999999",
    lng: "-0.655879",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRI",
    portName: "Tring",
    portContinent: "Europe",
  },
  {
    lat: "51.4934557",
    lng: "0.3529197",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRK",
    portName: "Thurrock",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTRL",
    portName: "Thornhill",
    portContinent: "Europe",
  },
  {
    lat: "52.96076499999999",
    lng: "0.5808571",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRM",
    portName: "Thornham",
    portContinent: "Europe",
  },
  {
    lat: "55.5459022",
    lng: "-4.6826324",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRN",
    portName: "Port of Troon",
    portContinent: "Europe",
  },
  {
    lat: "52.992992",
    lng: "-4.4274809",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRO",
    portName: "Trefor",
    portContinent: "Europe",
  },
  {
    lat: "53.0516787",
    lng: "-1.7625394",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRP",
    portName: "Thorpe",
    portContinent: "Europe",
  },
  {
    lat: "53.2821508",
    lng: "-4.6172413",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRR",
    portName: "Trearddur",
    portContinent: "Europe",
  },
  {
    lat: "57.9932604",
    lng: "-6.8736215",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRS",
    portName: "SouthHarris",
    portContinent: "Europe",
  },
  {
    lat: "39.8680412",
    lng: "-104.9719243",
    country: "US",
    country_long: "United States",
    portCode: "GBTRT",
    portName: "Thornton",
    portContinent: "Europe",
  },
  {
    lat: "42.0031966",
    lng: "-70.053449",
    country: "US",
    country_long: "United States",
    portCode: "GBTRU",
    portName: "Truro",
    portContinent: "Europe",
  },
  {
    lat: "53.196499",
    lng: "-2.761987",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRV",
    portName: "Tarvin",
    portContinent: "Europe",
  },
  {
    lat: "53.3068821",
    lng: "-3.3665917",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRW",
    portName: "Trelawnyd",
    portContinent: "Europe",
  },
  {
    lat: "51.659719",
    lng: "-3.506101",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRY",
    portName: "Treorchy",
    portContinent: "Europe",
  },
  {
    lat: "50.36924399999999",
    lng: "-4.893918",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTRZ",
    portName: "Treviscoe",
    portContinent: "Europe",
  },
  {
    lat: "52.634218",
    lng: "1.344794",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTSA",
    portName: "ThorpeSaintAndrew",
    portContinent: "Europe",
  },
  {
    lat: "52.737564",
    lng: "1.408978",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTSE",
    portName: "Tunstead",
    portContinent: "Europe",
  },
  {
    lat: "51.6525869",
    lng: "-2.664751",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTSI",
    portName: "Tutshill",
    portContinent: "Europe",
  },
  {
    lat: "54.233849",
    lng: "-1.341377",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTSK",
    portName: "Thirsk",
    portContinent: "Europe",
  },
  {
    lat: "53.276798",
    lng: "-1.77589",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTSL",
    portName: "Tideswell",
    portContinent: "Europe",
  },
  {
    lat: "52.440439",
    lng: "1.2016659",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTSM",
    portName: "TivetshallSaintMargaret",
    portContinent: "Europe",
  },
  {
    lat: "49.9514366",
    lng: "-6.332985499999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTSO",
    portName: "Tresco",
    portContinent: "Europe",
  },
  {
    lat: "51.4974948",
    lng: "-0.1356583",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTSR",
    portName: "Westminster",
    portContinent: "Europe",
  },
  {
    lat: "52.736571",
    lng: "-1.0409451",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTSS",
    portName: "Thrussington",
    portContinent: "Europe",
  },
  {
    lat: "52.5096655",
    lng: "1.2119984",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTST",
    portName: "Tharston",
    portContinent: "Europe",
  },
  {
    lat: "51.9085279",
    lng: "-0.5837789999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTSW",
    portName: "Tilsworth",
    portContinent: "Europe",
  },
  {
    lat: "41.4558899",
    lng: "-70.6141097",
    country: "US",
    country_long: "United States",
    portCode: "GBTSY",
    portName: "Tisbury",
    portContinent: "Europe",
  },
  {
    lat: "51.069358",
    lng: "0.689122",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTTD",
    portName: "Tenterden",
    portContinent: "Europe",
  },
  {
    lat: "52.45599499999999",
    lng: "-1.199159",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTTE",
    portName: "Lutterworth, Leicestershire",
    portContinent: "Europe",
  },
  {
    lat: "51.290898",
    lng: "0.02795",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTTF",
    portName: "Tatsfield",
    portContinent: "Europe",
  },
  {
    lat: "53.120933",
    lng: "-2.771825",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTTH",
    portName: "Tattenhall",
    portContinent: "Europe",
  },
  {
    lat: "52.23614",
    lng: "0.8639530999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTTK",
    portName: "Tostock",
    portContinent: "Europe",
  },
  {
    lat: "53.49021699999999",
    lng: "-0.0248789",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTTL",
    portName: "TetneyTerminal",
    portContinent: "Europe",
  },
  {
    lat: "52.9697123",
    lng: "-2.207646200000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTTM",
    portName: "Trentham",
    portContinent: "Europe",
  },
  {
    lat: "52.82774999999999",
    lng: "1.4532757",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTTN",
    portName: "Witton",
    portContinent: "Europe",
  },
  {
    lat: "50.987659",
    lng: "-0.627333",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTTO",
    portName: "Tillington",
    portContinent: "Europe",
  },
  {
    lat: "40.3578365",
    lng: "-79.5065836",
    country: "US",
    country_long: "United States",
    portCode: "GBTTR",
    portName: "Totteridge",
    portContinent: "Europe",
  },
  {
    lat: "50.433741",
    lng: "-3.685797",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTTS",
    portName: "Totnes Harbor",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTTT",
    portName: "Tottenham",
    portContinent: "Europe",
  },
  {
    lat: "51.7109614",
    lng: "-1.0076838",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTTW",
    portName: "Tetsworth",
    portContinent: "Europe",
  },
  {
    lat: "51.132377",
    lng: "0.263695",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTUB",
    portName: "TunbridgeWell",
    portContinent: "Europe",
  },
  {
    lat: "51.705037",
    lng: "-0.11246",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTUF",
    portName: "Cuffley",
    portContinent: "Europe",
  },
  {
    lat: "56.653474",
    lng: "-3.628404999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTUL",
    portName: "Tulliemet",
    portContinent: "Europe",
  },
  {
    lat: "51.132377",
    lng: "0.263695",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTUN",
    portName: "Tunbridge Wells, Kent",
    portContinent: "Europe",
  },
  {
    lat: "51.216845",
    lng: "-1.344168",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTUO",
    portName: "Tufton",
    portContinent: "Europe",
  },
  {
    lat: "52.8543675",
    lng: "-1.6882087",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTUT",
    portName: "Tutbury",
    portContinent: "Europe",
  },
  {
    lat: "52.1626108",
    lng: "-0.624502",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTUV",
    portName: "Turvey",
    portContinent: "Europe",
  },
  {
    lat: "51.132377",
    lng: "0.263695",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTUW",
    portName: "Tunbridge Wells",
    portContinent: "Europe",
  },
  {
    lat: "53.229497",
    lng: "-0.898207",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTUX",
    portName: "Tuxford",
    portContinent: "Europe",
  },
  {
    lat: "53.229497",
    lng: "-0.898207",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTUZ",
    portName: "Tuxford, Nottinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "52.511107",
    lng: "-2.0432929",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTVD",
    portName: "Tividale",
    portContinent: "Europe",
  },
  {
    lat: "52.2031566",
    lng: "0.189746",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTVH",
    portName: "Teversham",
    portContinent: "Europe",
  },
  {
    lat: "51.262733",
    lng: "0.5135339999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTVL",
    portName: "Tovil",
    portContinent: "Europe",
  },
  {
    lat: "41.6259211",
    lng: "-71.2134231",
    country: "US",
    country_long: "United States",
    portCode: "GBTVN",
    portName: "Tiverton",
    portContinent: "Europe",
  },
  {
    lat: "50.32613",
    lng: "-5.1745359",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTVS",
    portName: "Trevellas",
    portContinent: "Europe",
  },
  {
    lat: "-27.6148449",
    lng: "153.0669558",
    country: "AU",
    country_long: "Australia",
    portCode: "GBTVT",
    portName: "Stretton",
    portContinent: "Europe",
  },
  {
    lat: "54.9235965",
    lng: "-1.6180674",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTVY",
    portName: "TeamValley/Gateshead",
    portContinent: "Europe",
  },
  {
    lat: "51.4453043",
    lng: "-0.3375429",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTWC",
    portName: "Twickenham",
    portContinent: "Europe",
  },
  {
    lat: "53.843872",
    lng: "-2.138032",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTWD",
    portName: "Trawden",
    portContinent: "Europe",
  },
  {
    lat: "51.4800695",
    lng: "-0.8627693",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTWF",
    portName: "Twyford",
    portContinent: "Europe",
  },
  {
    lat: "52.3317089",
    lng: "-1.835778",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTWH",
    portName: "Tanworth",
    portContinent: "Europe",
  },
  {
    lat: "60.1659305",
    lng: "-1.2266412",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTWL",
    portName: "Tingwall",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBTWM",
    portName: "ThornwoodCommon",
    portContinent: "Europe",
  },
  {
    lat: "51.750985",
    lng: "-2.927396",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTWN",
    portName: "BettwsNewydd",
    portContinent: "Europe",
  },
  {
    lat: "51.05069599999999",
    lng: "-4.062927",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTWO",
    portName: "Tawstock",
    portContinent: "Europe",
  },
  {
    lat: "52.344978",
    lng: "-2.4211239",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTWR",
    portName: "ClowsTop",
    portContinent: "Europe",
  },
  {
    lat: "51.3801004",
    lng: "-0.2861237",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTWT",
    portName: "Tolworth",
    portContinent: "Europe",
  },
  {
    lat: "54.74285",
    lng: "-1.811502",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTWW",
    portName: "TowLaw",
    portContinent: "Europe",
  },
  {
    lat: "51.4800695",
    lng: "-0.8627693",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTWY",
    portName: "Twyford",
    portContinent: "Europe",
  },
  {
    lat: "51.9532618",
    lng: "0.3446183",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTXD",
    portName: "Thaxted",
    portContinent: "Europe",
  },
  {
    lat: "52.775188",
    lng: "-4.092684999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTYB",
    portName: "Tal-y-bont",
    portContinent: "Europe",
  },
  {
    lat: "51.778717",
    lng: "-4.0202669",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTYC",
    portName: "Tycroes",
    portContinent: "Europe",
  },
  {
    lat: "51.881227",
    lng: "-5.265995",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTYD",
    portName: "Tyddewi(StDavid's)",
    portContinent: "Europe",
  },
  {
    lat: "51.4800695",
    lng: "-0.8627693",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTYF",
    portName: "Twyford",
    portContinent: "Europe",
  },
  {
    lat: "51.329413",
    lng: "0.8045319999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTYH",
    portName: "Teynham",
    portContinent: "Europe",
  },
  {
    lat: "53.514508",
    lng: "-2.464474",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTYL",
    portName: "Tyldesley",
    portContinent: "Europe",
  },
  {
    lat: "55.0175869",
    lng: "-1.4255819",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTYM",
    portName: "Tynemouth",
    portContinent: "Europe",
  },
  {
    lat: "54.97702071911486",
    lng: "-1.774347842674298",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTYN",
    portName: "Tyne (GBTYN), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.45086209999999",
    lng: "-1.8439555",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTYS",
    portName: "Tyseley, West Midlands",
    portContinent: "Europe",
  },
  {
    lat: "53.27725830000001",
    lng: "-2.1274176",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTYT",
    portName: "Tytherington",
    portContinent: "Europe",
  },
  {
    lat: "56.429137",
    lng: "-5.239309",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTYU",
    portName: "Taynuilt",
    portContinent: "Europe",
  },
  {
    lat: "52.307662",
    lng: "-2.596406",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTYW",
    portName: "TenburyWells",
    portContinent: "Europe",
  },
  {
    lat: "52.45086209999999",
    lng: "-1.8439555",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTYY",
    portName: "Tyseley",
    portContinent: "Europe",
  },
  {
    lat: "52.831652",
    lng: "-1.998441",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTZR",
    portName: "Hixon, Staffordshire",
    portContinent: "Europe",
  },
  {
    lat: "50.52890499999999",
    lng: "-3.608359999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTZS",
    portName: "Newton Abbott, Devon",
    portContinent: "Europe",
  },
  {
    lat: "51.214321",
    lng: "-0.798802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBTZT",
    portName: "Farnham, Surrey",
    portContinent: "Europe",
  },
  {
    lat: "54.59914800000001",
    lng: "-3.536008",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUBA",
    portName: "Distington",
    portContinent: "Europe",
  },
  {
    lat: "54.5371776",
    lng: "-6.2051263",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUBL",
    portName: "UpperBallinderry",
    portContinent: "Europe",
  },
  {
    lat: "52.527666",
    lng: "-1.348303",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUBR",
    portName: "Burbage",
    portContinent: "Europe",
  },
  {
    lat: "51.753241",
    lng: "-0.448632",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUBY",
    portName: "Hemel Hempstead, Hertfordshire",
    portContinent: "Europe",
  },
  {
    lat: "50.966414",
    lng: "0.09591300000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUCK",
    portName: "Uckfield",
    portContinent: "Europe",
  },
  {
    lat: "52.36591199999999",
    lng: "-0.262374",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUCM",
    portName: "Alconbury, Cambridgeshire",
    portContinent: "Europe",
  },
  {
    lat: "55.378051",
    lng: "-3.435973",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUCN",
    portName: "Buchan (GBUCN), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.59914800000001",
    lng: "-3.536008",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUCO",
    portName: "Distington, Cumbria",
    portContinent: "Europe",
  },
  {
    lat: "53.91301499999999",
    lng: "-2.186918",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUDC",
    portName: "Barnoldswick, Lancashire",
    portContinent: "Europe",
  },
  {
    lat: "55.815735",
    lng: "-4.079127",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUDD",
    portName: "Uddingston",
    portContinent: "Europe",
  },
  {
    lat: "51.7978049",
    lng: "0.6372179",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUDM",
    portName: "Witham, Essex",
    portContinent: "Europe",
  },
  {
    lat: "53.195068",
    lng: "-2.999372",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUEF",
    portName: "Sandycroft, Flintshire",
    portContinent: "Europe",
  },
  {
    lat: "51.6100108",
    lng: "-0.0474714",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUEM",
    portName: "UpperEdmonton",
    portContinent: "Europe",
  },
  {
    lat: "48.911856",
    lng: "2.333764",
    country: "FR",
    country_long: "France",
    portCode: "GBUEN",
    portName: "SaintOuen",
    portContinent: "Europe",
  },
  {
    lat: "51.627903",
    lng: "0.418397",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUEU",
    portName: "Billericay, Essex",
    portContinent: "Europe",
  },
  {
    lat: "52.4821669",
    lng: "-2.120309",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUEV",
    portName: "Brierley Hill, West Midlands",
    portContinent: "Europe",
  },
  {
    lat: "50.906813",
    lng: "-3.329822000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUFC",
    portName: "Uffculme",
    portContinent: "Europe",
  },
  {
    lat: "51.807083",
    lng: "-1.63679",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUFD",
    portName: "Burford",
    portContinent: "Europe",
  },
  {
    lat: "51.6010754",
    lng: "-1.5601798",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUFG",
    portName: "Uffington",
    portContinent: "Europe",
  },
  {
    lat: "52.710459",
    lng: "-1.502566",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUFW",
    portName: "Measham, Leicestershire",
    portContinent: "Europe",
  },
  {
    lat: "51.18627",
    lng: "-0.9247091",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUFY",
    portName: "UpperFroyle",
    portContinent: "Europe",
  },
  {
    lat: "52.891371",
    lng: "-0.341884",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUGH",
    portName: "Billingborough",
    portContinent: "Europe",
  },
  {
    lat: "53.444266",
    lng: "-2.42142",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUGM",
    portName: "Irlam, Greater Manchester",
    portContinent: "Europe",
  },
  {
    lat: "53.163494",
    lng: "-2.997219",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUGT",
    portName: "Broughton",
    portContinent: "Europe",
  },
  {
    lat: "55.929632",
    lng: "-3.506609",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUHA",
    portName: "Uphall",
    portContinent: "Europe",
  },
  {
    lat: "51.092987",
    lng: "0.09161989999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUHD",
    portName: "UpperHartfield",
    portContinent: "Europe",
  },
  {
    lat: "51.929405",
    lng: "-1.279624",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUHF",
    portName: "UpperHeyford",
    portContinent: "Europe",
  },
  {
    lat: "51.5652158",
    lng: "-0.1334871",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUHL",
    portName: "UpperHolloway",
    portContinent: "Europe",
  },
  {
    lat: "53.984331",
    lng: "-2.101438",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUHS",
    portName: "Gargrave, North Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "52.761515",
    lng: "-1.935967",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUHU",
    portName: "Rugeley, Staffordshire",
    portContinent: "Europe",
  },
  {
    lat: "56.02092",
    lng: "-4.975331",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUIG",
    portName: "Uig (GBUIG), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.069159",
    lng: "-1.3688229",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUKB",
    portName: "Riddings, Derbyshire",
    portContinent: "Europe",
  },
  {
    lat: "52.4765609",
    lng: "-1.424272",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUKO",
    portName: "Bulkington",
    portContinent: "Europe",
  },
  {
    lat: "52.041047",
    lng: "0.726706",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUKW",
    portName: "Sudbury, Suffolk",
    portContinent: "Europe",
  },
  {
    lat: "56.4826317",
    lng: "-6.1457387",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBULF",
    portName: "UlvaFerry",
    portContinent: "Europe",
  },
  {
    lat: "57.895418",
    lng: "-5.161313",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBULL",
    portName: "Ullapool (GBULL), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.5251008",
    lng: "-1.3825191",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBULN",
    portName: "UrlayNook",
    portContinent: "Europe",
  },
  {
    lat: "57.487719",
    lng: "-4.142093",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBULO",
    portName: "Culloden",
    portContinent: "Europe",
  },
  {
    lat: "60.508886",
    lng: "-1.155522",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBULS",
    portName: "Ulsta (Yell) (GBULS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.195138",
    lng: "-3.09267",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBULV",
    portName: "Ulverston",
    portContinent: "Europe",
  },
  {
    lat: "53.617983",
    lng: "-0.330649",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBULY",
    portName: "Ulceby",
    portContinent: "Europe",
  },
  {
    lat: "50.996942",
    lng: "-3.984979899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUMB",
    portName: "Umberleigh",
    portContinent: "Europe",
  },
  {
    lat: "53.252322",
    lng: "-0.171328",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUMR",
    portName: "Baumber",
    portContinent: "Europe",
  },
  {
    lat: "55.7046601",
    lng: "13.1910073",
    country: "SE",
    country_long: "Sweden",
    portCode: "GBUND",
    portName: "Lund",
    portContinent: "Europe",
  },
  {
    lat: "52.14677589999999",
    lng: "-0.3172629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUNH",
    portName: "Blunham",
    portContinent: "Europe",
  },
  {
    lat: "56.315101",
    lng: "-6.235297",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUNS",
    portName: "Bunessan",
    portContinent: "Europe",
  },
  {
    lat: "60.7416304",
    lng: "-0.9048548999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUNT",
    portName: "Unst",
    portContinent: "Europe",
  },
  {
    lat: "-33.3270366",
    lng: "115.6408605",
    country: "AU",
    country_long: "Australia",
    portCode: "GBUNY",
    portName: "Bunbury",
    portContinent: "Europe",
  },
  {
    lat: "51.880087",
    lng: "0.5509269",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUOS",
    portName: "Braintree, Essex",
    portContinent: "Europe",
  },
  {
    lat: "42.1744878",
    lng: "-71.6022583",
    country: "US",
    country_long: "United States",
    portCode: "GBUOT",
    portName: "Upton",
    portContinent: "Europe",
  },
  {
    lat: "50.9824664",
    lng: "-1.2353103",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUP3",
    portName: "Upham",
    portContinent: "Europe",
  },
  {
    lat: "52.9538188",
    lng: "-1.9856635",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUPA",
    portName: "UpperTean",
    portContinent: "Europe",
  },
  {
    lat: "50.871927",
    lng: "0.213126",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUPD",
    portName: "UpperDicker",
    portContinent: "Europe",
  },
  {
    lat: "53.541714",
    lng: "-2.73097",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUPH",
    portName: "Upholland",
    portContinent: "Europe",
  },
  {
    lat: "52.59122499999999",
    lng: "-0.718484",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUPI",
    portName: "Uppingham",
    portContinent: "Europe",
  },
  {
    lat: "53.978361",
    lng: "-1.1520503",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUPL",
    portName: "UpperPoppleton",
    portContinent: "Europe",
  },
  {
    lat: "51.55591399999999",
    lng: "0.248894",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUPM",
    portName: "Upminster",
    portContinent: "Europe",
  },
  {
    lat: "42.1744878",
    lng: "-71.6022583",
    country: "US",
    country_long: "United States",
    portCode: "GBUPN",
    portName: "Upton",
    portContinent: "Europe",
  },
  {
    lat: "42.1744878",
    lng: "-71.6022583",
    country: "US",
    country_long: "United States",
    portCode: "GBUPO",
    portName: "Upton",
    portContinent: "Europe",
  },
  {
    lat: "52.297348",
    lng: "-0.4657261",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUPP",
    portName: "Upper Dean",
    portContinent: "Europe",
  },
  {
    lat: "51.4102425",
    lng: "0.5273734",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUPR",
    portName: "Upnor",
    portContinent: "Europe",
  },
  {
    lat: "51.6929709",
    lng: "0.04539729999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUPS",
    portName: "Upshire",
    portContinent: "Europe",
  },
  {
    lat: "42.1744878",
    lng: "-71.6022583",
    country: "US",
    country_long: "United States",
    portCode: "GBUPT",
    portName: "Upton",
    portContinent: "Europe",
  },
  {
    lat: "51.292877",
    lng: "-1.809178",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUPV",
    portName: "Upavon",
    portContinent: "Europe",
  },
  {
    lat: "52.431483",
    lng: "-0.1466939",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUPW",
    portName: "Upwood",
    portContinent: "Europe",
  },
  {
    lat: "52.1701989",
    lng: "-3.423661",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBURD",
    portName: "BuilthRoad",
    portContinent: "Europe",
  },
  {
    lat: "51.87569209999999",
    lng: "-1.7017511",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBURG",
    portName: "UpperRissington",
    portContinent: "Europe",
  },
  {
    lat: "54.4635261",
    lng: "-6.3345506",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBURL",
    portName: "Lurgan",
    portContinent: "Europe",
  },
  {
    lat: "53.448717",
    lng: "-2.374697",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBURM",
    portName: "Urmston, Greater Manchester",
    portContinent: "Europe",
  },
  {
    lat: "54.25779009999999",
    lng: "-1.5269839",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBURO",
    portName: "Burneston",
    portContinent: "Europe",
  },
  {
    lat: "53.448717",
    lng: "-2.374697",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBURS",
    portName: "Urmston",
    portContinent: "Europe",
  },
  {
    lat: "53.313868",
    lng: "-0.666558",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBURT",
    portName: "SturtonbyStow",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBURY",
    portName: "Hurley",
    portContinent: "Europe",
  },
  {
    lat: "-34.9377936",
    lng: "138.6690795",
    country: "AU",
    country_long: "Australia",
    portCode: "GBUSD",
    portName: "Burnside",
    portContinent: "Europe",
  },
  {
    lat: "48.3131053",
    lng: "-117.2818642",
    country: "US",
    country_long: "United States",
    portCode: "GBUSK",
    portName: "Usk",
    portContinent: "Europe",
  },
  {
    lat: "50.88274699999999",
    lng: "-4.352873",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUTC",
    portName: "Sutcombe",
    portContinent: "Europe",
  },
  {
    lat: "52.2505049",
    lng: "-1.389719",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUTH",
    portName: "Southam",
    portContinent: "Europe",
  },
  {
    lat: "42.1744878",
    lng: "-71.6022583",
    country: "US",
    country_long: "United States",
    portCode: "GBUTM",
    portName: "Upton",
    portContinent: "Europe",
  },
  {
    lat: "42.1744878",
    lng: "-71.6022583",
    country: "US",
    country_long: "United States",
    portCode: "GBUTN",
    portName: "Upton",
    portContinent: "Europe",
  },
  {
    lat: "52.40486000000001",
    lng: "1.1415169",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUTO",
    portName: "Burston",
    portContinent: "Europe",
  },
  {
    lat: "51.3501719",
    lng: "-2.3647367",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUTS",
    portName: "SouthStrome",
    portContinent: "Europe",
  },
  {
    lat: "52.89811599999999",
    lng: "-1.865801",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUTT",
    portName: "Uttoxeter, Staffordshire",
    portContinent: "Europe",
  },
  {
    lat: "52.89811599999999",
    lng: "-1.865801",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUTX",
    portName: "Uttoxeter",
    portContinent: "Europe",
  },
  {
    lat: "51.87286599999999",
    lng: "0.3577569",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUUP",
    portName: "Dunmow",
    portContinent: "Europe",
  },
  {
    lat: "50.7728115",
    lng: "-1.8202897",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUUR",
    portName: "Hurn",
    portContinent: "Europe",
  },
  {
    lat: "52.065357",
    lng: "-2.217407",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUUS",
    portName: "Upton Upon Severn",
    portContinent: "Europe",
  },
  {
    lat: "52.5327521",
    lng: "-1.736794",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUWH",
    portName: "Curdworth",
    portContinent: "Europe",
  },
  {
    lat: "42.07724289999999",
    lng: "-71.6302532",
    country: "US",
    country_long: "United States",
    portCode: "GBUXB",
    portName: "Uxbridge",
    portContinent: "Europe",
  },
  {
    lat: "51.5485",
    lng: "-0.479611",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUXE",
    portName: "Uxbridge, Greater London",
    portContinent: "Europe",
  },
  {
    lat: "60.689507",
    lng: "-0.918598",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBUYE",
    portName: "Uyeasound (GBUYE), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.845016",
    lng: "-1.3373001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVAG",
    portName: "Castle Donington, Leicestershire",
    portContinent: "Europe",
  },
  {
    lat: "52.446542",
    lng: "0.622533",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVAJ",
    portName: "Brandon, Suffolk",
    portContinent: "Europe",
  },
  {
    lat: "51.9270884",
    lng: "1.2348448",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVAK",
    portName: "Dovercourt, Essex",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBVAL",
    portName: "ValeofLeven",
    portContinent: "Europe",
  },
  {
    lat: "53.0605166",
    lng: "-2.2011938",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVAM",
    portName: "Tunstall, Staffordshire",
    portContinent: "Europe",
  },
  {
    lat: "53.3298746",
    lng: "-1.9841743",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVAO",
    portName: "Whaley Bridge, Derbyshire",
    portContinent: "Europe",
  },
  {
    lat: "51.866547",
    lng: "-2.454864",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVAW",
    portName: "Longhope, Gloucestershire",
    portContinent: "Europe",
  },
  {
    lat: "53.69109",
    lng: "-1.023942",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVBC",
    portName: "Snaith",
    portContinent: "Europe",
  },
  {
    lat: "52.5150823",
    lng: "-1.7860187",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVCL",
    portName: "CastleVale",
    portContinent: "Europe",
  },
  {
    lat: "52.7849778",
    lng: "0.1204287",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVDM",
    portName: "LongSutton",
    portContinent: "Europe",
  },
  {
    lat: "51.5661153",
    lng: "-3.2775059",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVEL",
    portName: "Velindre",
    portContinent: "Europe",
  },
  {
    lat: "54.3775293",
    lng: "-7.317595099999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVEM",
    portName: "Fivemiletown",
    portContinent: "Europe",
  },
  {
    lat: "39.3403942",
    lng: "-74.4773735",
    country: "US",
    country_long: "United States",
    portCode: "GBVEN",
    portName: "Ventnor",
    portContinent: "Europe",
  },
  {
    lat: "50.8789783",
    lng: "-1.8654487",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVER",
    portName: "Verwood",
    portContinent: "Europe",
  },
  {
    lat: "51.5201939",
    lng: "-0.509572",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVES",
    portName: "Iver, Buckinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "53.3412582",
    lng: "-1.2678044",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVET",
    portName: "Kiveton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBVGI",
    portName: "Vargfield",
    portContinent: "Europe",
  },
  {
    lat: "51.411111",
    lng: "-0.6027779999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVGN",
    portName: "Virginia Water",
    portContinent: "Europe",
  },
  {
    lat: "60.369444",
    lng: "-1.141512",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVID",
    portName: "Vidlin",
    portContinent: "Europe",
  },
  {
    lat: "52.5219314",
    lng: "-1.8446106",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVIH",
    portName: "Erdington, West Midlands",
    portContinent: "Europe",
  },
  {
    lat: "57.652022",
    lng: "-2.439119",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVJL",
    portName: "Longmanhill",
    portContinent: "Europe",
  },
  {
    lat: "53.265325",
    lng: "-4.429140299999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVLY",
    portName: "Anglesey",
    portContinent: "Europe",
  },
  {
    lat: "52.44008400000001",
    lng: "-2.8332799",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVMA",
    portName: "Craven Arms",
    portContinent: "Europe",
  },
  {
    lat: "51.2420212",
    lng: "-2.421974",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVOB",
    portName: "Vobster",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBVOE",
    portName: "Voe",
    portContinent: "Europe",
  },
  {
    lat: "52.0733006",
    lng: "-1.0146634",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVST",
    portName: "Silverstone",
    portContinent: "Europe",
  },
  {
    lat: "51.9932363",
    lng: "0.043421",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVTJ",
    portName: "Nuthempstead",
    portContinent: "Europe",
  },
  {
    lat: "40.90648729999999",
    lng: "-72.74343309999999",
    country: "US",
    country_long: "United States",
    portCode: "GBVTN",
    portName: "Calverton",
    portContinent: "Europe",
  },
  {
    lat: "51.228457",
    lng: "-1.2201169",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBVTO",
    portName: "Steventon",
    portContinent: "Europe",
  },
  {
    lat: "-37.9112468",
    lng: "145.3571402",
    country: "AU",
    country_long: "Australia",
    portCode: "GBVVE",
    portName: "Belgrave",
    portContinent: "Europe",
  },
  {
    lat: "53.69909029999999",
    lng: "-1.1587752",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBW4E",
    portName: "Whitleybridge",
    portContinent: "Europe",
  },
  {
    lat: "51.263428",
    lng: "-0.5058699999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBW5C",
    portName: "WestClandon",
    portContinent: "Europe",
  },
  {
    lat: "52.57584190000001",
    lng: "-0.412227",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBW5F",
    portName: "Wansford",
    portContinent: "Europe",
  },
  {
    lat: "51.645079",
    lng: "-1.010546",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAA",
    portName: "Watlington",
    portContinent: "Europe",
  },
  {
    lat: "50.516654",
    lng: "-4.836456",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAB",
    portName: "Wadebridge",
    portContinent: "Europe",
  },
  {
    lat: "51.68584389999999",
    lng: "-0.0330964",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAC",
    portName: "Waltham Cross",
    portContinent: "Europe",
  },
  {
    lat: "54.631243",
    lng: "-1.724749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAD",
    portName: "West Auckland, Durham",
    portContinent: "Europe",
  },
  {
    lat: "43.0948033",
    lng: "-71.7306313",
    country: "US",
    country_long: "United States",
    portCode: "GBWAE",
    portName: "Weare",
    portContinent: "Europe",
  },
  {
    lat: "51.66112829999999",
    lng: "-0.3970199",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAF",
    portName: "Watford",
    portContinent: "Europe",
  },
  {
    lat: "51.363978",
    lng: "-0.151845",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAG",
    portName: "Wallington, Greater London",
    portContinent: "Europe",
  },
  {
    lat: "51.075974",
    lng: "-0.056686",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAH",
    portName: "West Hoathly",
    portContinent: "Europe",
  },
  {
    lat: "51.6128337",
    lng: "-1.6367845",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAI",
    portName: "Watchfield",
    portContinent: "Europe",
  },
  {
    lat: "54.991512",
    lng: "-1.529375",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAJ",
    portName: "Wallsend, Tyne & Wear",
    portContinent: "Europe",
  },
  {
    lat: "51.9228596",
    lng: "0.7553249999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAK",
    portName: "WakesColne",
    portContinent: "Europe",
  },
  {
    lat: "52.586214",
    lng: "-1.982919",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAL",
    portName: "Walsall",
    portContinent: "Europe",
  },
  {
    lat: "40.2053679",
    lng: "-75.0903154",
    country: "US",
    country_long: "United States",
    portCode: "GBWAM",
    portName: "Warminster",
    portContinent: "Europe",
  },
  {
    lat: "51.45837299999999",
    lng: "-0.1891356",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAN",
    portName: "Wandsworth",
    portContinent: "Europe",
  },
  {
    lat: "40.8531553",
    lng: "-74.11375370000002",
    country: "US",
    country_long: "United States",
    portCode: "GBWAO",
    portName: "Wallington",
    portContinent: "Europe",
  },
  {
    lat: "42.2598457",
    lng: "-72.2398566",
    country: "US",
    country_long: "United States",
    portCode: "GBWAR",
    portName: "Ware",
    portContinent: "Europe",
  },
  {
    lat: "52.1712335",
    lng: "-2.1175639",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAS",
    portName: "White Ladies Aston",
    portContinent: "Europe",
  },
  {
    lat: "51.181769",
    lng: "-3.329177",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAT",
    portName: "Watchet",
    portContinent: "Europe",
  },
  {
    lat: "55.625474",
    lng: "-3.015892",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAU",
    portName: "Walkerburn",
    portContinent: "Europe",
  },
  {
    lat: "52.3147499",
    lng: "-1.116561",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAV",
    portName: "Watford Village",
    portContinent: "Europe",
  },
  {
    lat: "52.28231599999999",
    lng: "-1.584927",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAW",
    portName: "Warwick",
    portContinent: "Europe",
  },
  {
    lat: "54.631243",
    lng: "-1.724749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAX",
    portName: "West Auckland",
    portContinent: "Europe",
  },
  {
    lat: "53.426521",
    lng: "-3.066215",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWAY",
    portName: "Wallasey",
    portContinent: "Europe",
  },
  {
    lat: "51.483494",
    lng: "-0.196802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWB7",
    portName: "WestBrompton",
    portContinent: "Europe",
  },
  {
    lat: "52.5335279",
    lng: "-2.1873269",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWBC",
    portName: "Wombourne",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWBD",
    portName: "Wimbledon",
    portContinent: "Europe",
  },
  {
    lat: "52.26558199999999",
    lng: "0.191002",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWBE",
    portName: "Waterbeach",
    portContinent: "Europe",
  },
  {
    lat: "60.30127899999999",
    lng: "-1.536864",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWBF",
    portName: "West Burrafirth",
    portContinent: "Europe",
  },
  {
    lat: "40.5575986",
    lng: "-74.2846022",
    country: "US",
    country_long: "United States",
    portCode: "GBWBG",
    portName: "Woodbridge",
    portContinent: "Europe",
  },
  {
    lat: "52.00704",
    lng: "0.314631",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWBH",
    portName: "Wimbish",
    portContinent: "Europe",
  },
  {
    lat: "53.5427002",
    lng: "-0.7438070999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWBK",
    portName: "WestButterwick",
    portContinent: "Europe",
  },
  {
    lat: "52.51379499999999",
    lng: "0.07967289999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWBL",
    portName: "Wimblington",
    portContinent: "Europe",
  },
  {
    lat: "42.4792618",
    lng: "-71.1522765",
    country: "US",
    country_long: "United States",
    portCode: "GBWBN",
    portName: "Woburn",
    portContinent: "Europe",
  },
  {
    lat: "50.844731",
    lng: "-0.619031",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWBO",
    portName: "Walberton",
    portContinent: "Europe",
  },
  {
    lat: "51.3716269",
    lng: "-0.457904",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWBR",
    portName: "Weybridge",
    portContinent: "Europe",
  },
  {
    lat: "53.9510479",
    lng: "-0.8847231",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWBS",
    portName: "Wilberfoss",
    portContinent: "Europe",
  },
  {
    lat: "51.337352",
    lng: "-0.503315",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWBT",
    portName: "WestByfleet",
    portContinent: "Europe",
  },
  {
    lat: "55.867674",
    lng: "-3.682519",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWBU",
    portName: "Whitburn",
    portContinent: "Europe",
  },
  {
    lat: "53.521016",
    lng: "-1.399319",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWBW",
    portName: "Wombwell",
    portContinent: "Europe",
  },
  {
    lat: "51.55602469999999",
    lng: "-0.2796177",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWBY",
    portName: "Wembley",
    portContinent: "Europe",
  },
  {
    lat: "55.852154",
    lng: "-3.566167",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWC5",
    portName: "WestCalder",
    portContinent: "Europe",
  },
  {
    lat: "51.5148198",
    lng: "-0.0650534",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWC8",
    portName: "Whitechapel",
    portContinent: "Europe",
  },
  {
    lat: "50.92497210000001",
    lng: "0.7095541",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWCA",
    portName: "Winchelsea",
    portContinent: "Europe",
  },
  {
    lat: "50.69020099999999",
    lng: "-2.402222",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWCB",
    portName: "Whitcombe",
    portContinent: "Europe",
  },
  {
    lat: "52.968716",
    lng: "-2.682045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWCC",
    portName: "Whitchurch",
    portContinent: "Europe",
  },
  {
    lat: "51.98206709999999",
    lng: "-8.5067835",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBWCCÂ",
    portName: "Whitechurch",
    portContinent: "Europe",
  },
  {
    lat: "52.098004",
    lng: "-1.5615009",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWCE",
    portName: "Whatcote",
    portContinent: "Europe",
  },
  {
    lat: "52.386073",
    lng: "0.210535",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWCF",
    portName: "Witchford",
    portContinent: "Europe",
  },
  {
    lat: "53.2712549",
    lng: "-2.493087",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWCH",
    portName: "Wincham",
    portContinent: "Europe",
  },
  {
    lat: "39.8366528",
    lng: "-105.0372046",
    country: "US",
    country_long: "United States",
    portCode: "GBWCI",
    portName: "CityofWestminster",
    portContinent: "Europe",
  },
  {
    lat: "58.438936",
    lng: "-3.093716",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWCK",
    portName: "Wick",
    portContinent: "Europe",
  },
  {
    lat: "51.043574",
    lng: "-3.313501",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWCM",
    portName: "Wiveliscombe",
    portContinent: "Europe",
  },
  {
    lat: "43.0414992",
    lng: "-76.1232976",
    country: "US",
    country_long: "United States",
    portCode: "GBWCO",
    portName: "Westcott",
    portContinent: "Europe",
  },
  {
    lat: "51.055688",
    lng: "-2.416007",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWCT",
    portName: "Wincanton",
    portContinent: "Europe",
  },
  {
    lat: "52.968716",
    lng: "-2.682045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWCU",
    portName: "Whitchurch",
    portContinent: "Europe",
  },
  {
    lat: "51.5935261",
    lng: "-1.472305",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWCW",
    portName: "WestChallow",
    portContinent: "Europe",
  },
  {
    lat: "50.9157812",
    lng: "-0.7989198",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWD9",
    portName: "WestDean",
    portContinent: "Europe",
  },
  {
    lat: "53.187106",
    lng: "-0.179214",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWDA",
    portName: "Woodhall",
    portContinent: "Europe",
  },
  {
    lat: "44.8644999",
    lng: "-75.20410190000001",
    country: "US",
    country_long: "United States",
    portCode: "GBWDD",
    portName: "Waddington",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWDE",
    portName: "Woodley",
    portContinent: "Europe",
  },
  {
    lat: "51.8224051",
    lng: "0.05870489999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWDF",
    portName: "Widford",
    portContinent: "Europe",
  },
  {
    lat: "57.204617",
    lng: "-4.507264",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWDG",
    portName: "WhiteBridge",
    portContinent: "Europe",
  },
  {
    lat: "53.151585",
    lng: "-0.218147",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWDH",
    portName: "WoodhallSpa",
    portContinent: "Europe",
  },
  {
    lat: "51.36456099999999",
    lng: "-0.665847",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWDL",
    portName: "Windlesham",
    portContinent: "Europe",
  },
  {
    lat: "54.380685",
    lng: "-2.906785",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWDM",
    portName: "Windermere",
    portContinent: "Europe",
  },
  {
    lat: "53.361024",
    lng: "-2.733637",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWDN",
    portName: "Widnes",
    portContinent: "Europe",
  },
  {
    lat: "40.739097",
    lng: "-114.073345",
    country: "US",
    country_long: "United States",
    portCode: "GBWDO",
    portName: "Wendover",
    portContinent: "Europe",
  },
  {
    lat: "34.8964186",
    lng: "-94.0909925",
    country: "US",
    country_long: "United States",
    portCode: "GBWDQ",
    portName: "Waldron",
    portContinent: "Europe",
  },
  {
    lat: "51.502985",
    lng: "-0.4654099999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWDR",
    portName: "West Drayton London",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWDS",
    portName: "Woodstock",
    portContinent: "Europe",
  },
  {
    lat: "51.061756",
    lng: "0.33802",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWDU",
    portName: "Wadhurst",
    portContinent: "Europe",
  },
  {
    lat: "52.8262722",
    lng: "-2.2986492",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWDV",
    portName: "Woodseaves",
    portContinent: "Europe",
  },
  {
    lat: "52.537329",
    lng: "-0.4711101",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWDW",
    portName: "Woodnewton",
    portContinent: "Europe",
  },
  {
    lat: "51.5960603",
    lng: "-0.3334858",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEA",
    portName: "Wealdstone",
    portContinent: "Europe",
  },
  {
    lat: "52.517664",
    lng: "-1.995159",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEB",
    portName: "West Bromwich",
    portContinent: "Europe",
  },
  {
    lat: "51.53587899999999",
    lng: "0.696966",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEC",
    portName: "Westcliff-On-Sea, Essex",
    portContinent: "Europe",
  },
  {
    lat: "52.552888",
    lng: "-2.02208",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWED",
    portName: "Wednesbury",
    portContinent: "Europe",
  },
  {
    lat: "27.8475889",
    lng: "-82.60866299999999",
    country: "US",
    country_long: "United States",
    portCode: "GBWEE",
    portName: "Weedon",
    portContinent: "Europe",
  },
  {
    lat: "52.59625699999999",
    lng: "-2.08341",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEF",
    portName: "Wednesfield",
    portContinent: "Europe",
  },
  {
    lat: "44.5116774",
    lng: "-89.5484341",
    country: "US",
    country_long: "United States",
    portCode: "GBWEG",
    portName: "Welling",
    portContinent: "Europe",
  },
  {
    lat: "51.6320279",
    lng: "-0.09580219999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEH",
    portName: "Winchmore Hill",
    portContinent: "Europe",
  },
  {
    lat: "50.880939",
    lng: "-1.24068",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEI",
    portName: "Whiteley",
    portContinent: "Europe",
  },
  {
    lat: "50.77850919999999",
    lng: "-2.1973871",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEK",
    portName: "Winterbourne Kingston",
    portContinent: "Europe",
  },
  {
    lat: "52.302419",
    lng: "-0.6939639999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEL",
    portName: "Wellingborough",
    portContinent: "Europe",
  },
  {
    lat: "51.73473",
    lng: "-0.216951",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEM",
    portName: "WelhamGreen",
    portContinent: "Europe",
  },
  {
    lat: "-27.4809778",
    lng: "153.0120685",
    country: "AU",
    country_long: "Australia",
    portCode: "GBWEN",
    portName: "West End",
    portContinent: "Europe",
  },
  {
    lat: "51.228772",
    lng: "-2.81108",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEO",
    portName: "Wedmore",
    portContinent: "Europe",
  },
  {
    lat: "43.0414992",
    lng: "-76.1232976",
    country: "US",
    country_long: "United States",
    portCode: "GBWEQ",
    portName: "Westcott",
    portContinent: "Europe",
  },
  {
    lat: "51.266969",
    lng: "0.071827",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWER",
    portName: "Westerham",
    portContinent: "Europe",
  },
  {
    lat: "26.1003654",
    lng: "-80.3997748",
    country: "US",
    country_long: "United States",
    portCode: "GBWES",
    portName: "Weston",
    portContinent: "Europe",
  },
  {
    lat: "53.92705600000001",
    lng: "-1.384816",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWET",
    portName: "Wetherby",
    portContinent: "Europe",
  },
  {
    lat: "54.12442799999999",
    lng: "-0.5229881",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEV",
    portName: "Weaverthorpe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWEW",
    portName: "Wentworth",
    portContinent: "Europe",
  },
  {
    lat: "53.73125599999999",
    lng: "0.032889",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEX",
    portName: "Withernsea",
    portContinent: "Europe",
  },
  {
    lat: "50.606898",
    lng: "-2.4523353",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEY",
    portName: "Weymouth Harbour",
    portContinent: "Europe",
  },
  {
    lat: "53.915057",
    lng: "-1.5667261",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWEZ",
    portName: "Weeton",
    portContinent: "Europe",
  },
  {
    lat: "51.66112829999999",
    lng: "-0.3970199",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWF2",
    portName: "Watford",
    portContinent: "Europe",
  },
  {
    lat: "53.1092419",
    lng: "0.1917761",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWFB",
    portName: "WainfleetBank",
    portContinent: "Europe",
  },
  {
    lat: "53.683298",
    lng: "-1.505924",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWFD",
    portName: "Wakefield",
    portContinent: "Europe",
  },
  {
    lat: "32.2354256",
    lng: "-90.0720293",
    country: "US",
    country_long: "United States",
    portCode: "GBWFE",
    portName: "Whitfield",
    portContinent: "Europe",
  },
  {
    lat: "51.618718",
    lng: "-3.178721",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWFH",
    portName: "Cwmfelinfach",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWFI",
    portName: "Westfield",
    portContinent: "Europe",
  },
  {
    lat: "37.7826737",
    lng: "-122.410428",
    country: "US",
    country_long: "United States",
    portCode: "GBWFL",
    portName: "Warfield",
    portContinent: "Europe",
  },
  {
    lat: "51.53587899999999",
    lng: "0.696966",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWFO",
    portName: "WestcliffeonSea",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWFR",
    portName: "Willford",
    portContinent: "Europe",
  },
  {
    lat: "53.6926028",
    lng: "-2.25317",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWFT",
    portName: "Waterfoot",
    portContinent: "Europe",
  },
  {
    lat: "52.2505779",
    lng: "-0.8518665999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWFV",
    portName: "WestonFavell",
    portContinent: "Europe",
  },
  {
    lat: "53.2646464",
    lng: "-2.5321749",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWG9",
    portName: "Winnington",
    portContinent: "Europe",
  },
  {
    lat: "51.8031689",
    lng: "-0.208661",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGC",
    portName: "Welwyn Garden City",
    portContinent: "Europe",
  },
  {
    lat: "51.128742",
    lng: "-0.014468",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGD",
    portName: "WestGrinstead",
    portContinent: "Europe",
  },
  {
    lat: "41.256843",
    lng: "-74.6166192",
    country: "US",
    country_long: "United States",
    portCode: "GBWGE",
    portName: "Wantage",
    portContinent: "Europe",
  },
  {
    lat: "52.935673",
    lng: "-1.134017",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGF",
    portName: "West Bridgford",
    portContinent: "Europe",
  },
  {
    lat: "54.909388",
    lng: "-3.20736",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGG",
    portName: "Whitrigg",
    portContinent: "Europe",
  },
  {
    lat: "51.27505",
    lng: "1.21501",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGH",
    portName: "Wingham",
    portContinent: "Europe",
  },
  {
    lat: "50.9071349",
    lng: "-1.955197",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGI",
    portName: "WimborneSaintGiles",
    portContinent: "Europe",
  },
  {
    lat: "53.776585",
    lng: "-2.9161069",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGL",
    portName: "WreaGreen",
    portContinent: "Europe",
  },
  {
    lat: "52.5863179",
    lng: "-1.1063539",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGM",
    portName: "Wigston, Leicestershire",
    portContinent: "Europe",
  },
  {
    lat: "53.5450645",
    lng: "-2.6325074",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGN",
    portName: "Wigan",
    portContinent: "Europe",
  },
  {
    lat: "53.79153299999999",
    lng: "-0.5030089",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGO",
    portName: "LittleWeighton",
    portContinent: "Europe",
  },
  {
    lat: "53.00403499999999",
    lng: "-2.909421",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGR",
    portName: "Bangor-is-y-coed",
    portContinent: "Europe",
  },
  {
    lat: "52.5863179",
    lng: "-1.1063539",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGS",
    portName: "Wigston",
    portContinent: "Europe",
  },
  {
    lat: "54.825544",
    lng: "-3.161231",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGT",
    portName: "Wigton",
    portContinent: "Europe",
  },
  {
    lat: "51.500294",
    lng: "-0.8645275",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGV",
    portName: "Wargrave",
    portContinent: "Europe",
  },
  {
    lat: "52.4304614",
    lng: "-1.441105",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWGW",
    portName: "WalsgraveonSowe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWH9",
    portName: "Wharton",
    portContinent: "Europe",
  },
  {
    lat: "52.945776",
    lng: "-0.892034",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHA",
    portName: "Whatton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWHB",
    portName: "WhiteboothRoads",
    portContinent: "Europe",
  },
  {
    lat: "52.968716",
    lng: "-2.682045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHC",
    portName: "Whitchurch",
    portContinent: "Europe",
  },
  {
    lat: "54.7541261",
    lng: "-2.2253389",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHD",
    portName: "Wear Head",
    portContinent: "Europe",
  },
  {
    lat: "42.3630932",
    lng: "-71.089587",
    country: "US",
    country_long: "United States",
    portCode: "GBWHE",
    portName: "Whitehead",
    portContinent: "Europe",
  },
  {
    lat: "41.7063537",
    lng: "-72.6596244",
    country: "US",
    country_long: "United States",
    portCode: "GBWHF",
    portName: "Wethersfield",
    portContinent: "Europe",
  },
  {
    lat: "47.7510741",
    lng: "-120.7401386",
    country: "US",
    country_long: "United States",
    portCode: "GBWHG",
    portName: "Washington",
    portContinent: "Europe",
  },
  {
    lat: "54.70071970000001",
    lng: "-4.3657047",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHH",
    portName: "Isle Of Whithorn",
    portContinent: "Europe",
  },
  {
    lat: "51.819677",
    lng: "-4.614612999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHI",
    portName: "Whitland",
    portContinent: "Europe",
  },
  {
    lat: "55.8751408",
    lng: "-4.340368700000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHJ",
    portName: "Whiteinch/Glasgow",
    portContinent: "Europe",
  },
  {
    lat: "59.14101299999999",
    lng: "-2.5972179",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHL",
    portName: "WhitehallVillage,Stronsay",
    portContinent: "Europe",
  },
  {
    lat: "52.227872",
    lng: "0.9405739999999998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHM",
    portName: "Wetherden",
    portContinent: "Europe",
  },
  {
    lat: "51.6341044",
    lng: "-1.4149107",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHN",
    portName: "West Hanney",
    portContinent: "Europe",
  },
  {
    lat: "51.5680539",
    lng: "0.340133",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHO",
    portName: "West Horndon",
    portContinent: "Europe",
  },
  {
    lat: "51.8114109",
    lng: "-0.2938223",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHP",
    portName: "Wheathampstead",
    portContinent: "Europe",
  },
  {
    lat: "51.2981808",
    lng: "0.3406612",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHR",
    portName: "WrothamHeath",
    portContinent: "Europe",
  },
  {
    lat: "51.5901769",
    lng: "-0.0173437",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHS",
    portName: "Walthamstow",
    portContinent: "Europe",
  },
  {
    lat: "51.4538478",
    lng: "-0.3732651",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHT",
    portName: "Whitton",
    portContinent: "Europe",
  },
  {
    lat: "52.36691099999999",
    lng: "-0.06951399999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHU",
    portName: "Woodhurst",
    portContinent: "Europe",
  },
  {
    lat: "54.549699",
    lng: "-3.589233",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHV",
    portName: "Whitehaven Harbour",
    portContinent: "Europe",
  },
  {
    lat: "55.773543",
    lng: "-3.919354",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHW",
    portName: "Wishaw",
    portContinent: "Europe",
  },
  {
    lat: "52.774977",
    lng: "0.8439253999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHX",
    portName: "Whissonsett",
    portContinent: "Europe",
  },
  {
    lat: "60.35226110000001",
    lng: "-0.9819779000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWHY",
    portName: "Whalsay",
    portContinent: "Europe",
  },
  {
    lat: "34.9843176",
    lng: "-80.4492319",
    country: "US",
    country_long: "United States",
    portCode: "GBWIA",
    portName: "Wingate",
    portContinent: "Europe",
  },
  {
    lat: "51.95720499999999",
    lng: "-1.969204",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIB",
    portName: "Winchcombe",
    portContinent: "Europe",
  },
  {
    lat: "58.438936",
    lng: "-3.093716",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIC",
    portName: "Wick",
    portContinent: "Europe",
  },
  {
    lat: "41.5722602",
    lng: "-71.448489",
    country: "US",
    country_long: "United States",
    portCode: "GBWID",
    portName: "Wickford",
    portContinent: "Europe",
  },
  {
    lat: "51.3491996",
    lng: "-1.9927105",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIE",
    portName: "Wiltshire",
    portContinent: "Europe",
  },
  {
    lat: "53.19426199999999",
    lng: "-2.51967",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIF",
    portName: "Winsford",
    portContinent: "Europe",
  },
  {
    lat: "54.867159",
    lng: "-4.444234",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIG",
    portName: "Wigtown",
    portContinent: "Europe",
  },
  {
    lat: "40.0774198",
    lng: "-86.47356719999999",
    country: "US",
    country_long: "United States",
    portCode: "GBWIH",
    portName: "Witham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWII",
    portName: "Willen",
    portContinent: "Europe",
  },
  {
    lat: "52.152667",
    lng: "1.363068",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIK",
    portName: "Wickham Market, Suffolk",
    portContinent: "Europe",
  },
  {
    lat: "50.886118",
    lng: "-3.372925",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIL",
    portName: "Willand",
    portContinent: "Europe",
  },
  {
    lat: "50.8004646",
    lng: "-1.9830004",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIM",
    portName: "Wimborne Minster",
    portContinent: "Europe",
  },
  {
    lat: "51.7859365",
    lng: "-1.4850544",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIN",
    portName: "Witney",
    portContinent: "Europe",
  },
  {
    lat: "53.418071",
    lng: "-2.791738",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIO",
    portName: "Whiston",
    portContinent: "Europe",
  },
  {
    lat: "53.082819",
    lng: "-1.570677",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIR",
    portName: "Wirksworth",
    portContinent: "Europe",
  },
  {
    lat: "52.666317",
    lng: "0.158797",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIS",
    portName: "Wisbech",
    portContinent: "Europe",
  },
  {
    lat: "51.16219400000001",
    lng: "-3.3207049",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIT",
    portName: "Williton",
    portContinent: "Europe",
  },
  {
    lat: "51.944859",
    lng: "-0.878737",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIU",
    portName: "Winslow, Buckinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "51.857997",
    lng: "0.9652740000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIV",
    portName: "Wivenhoe",
    portContinent: "Europe",
  },
  {
    lat: "53.430243",
    lng: "-2.597693",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIW",
    portName: "Winwick",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWIX",
    portName: "Wix",
    portContinent: "Europe",
  },
  {
    lat: "52.054479",
    lng: "-1.8475751",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWIY",
    portName: "Willersey",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWIZ",
    portName: "Wilton",
    portContinent: "Europe",
  },
  {
    lat: "51.56682199999999",
    lng: "-1.234038",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWJH",
    portName: "Blewbury",
    portContinent: "Europe",
  },
  {
    lat: "52.34150589999999",
    lng: "-0.8231267999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWJV",
    portName: "Walgrave",
    portContinent: "Europe",
  },
  {
    lat: "50.510496",
    lng: "-4.070536",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWKA",
    portName: "Walkhampton",
    portContinent: "Europe",
  },
  {
    lat: "55.695149",
    lng: "-4.857158",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWKB",
    portName: "WestKilbride",
    portContinent: "Europe",
  },
  {
    lat: "53.5237032",
    lng: "-2.3986825",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWKD",
    portName: "Walkden",
    portContinent: "Europe",
  },
  {
    lat: "53.7638092",
    lng: "-0.3891688",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWKE",
    portName: "Wyke",
    portContinent: "Europe",
  },
  {
    lat: "51.4420486",
    lng: "-0.6975153",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWKF",
    portName: "Winkfield",
    portContinent: "Europe",
  },
  {
    lat: "51.316774",
    lng: "-0.5600349",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWKG",
    portName: "Woking",
    portContinent: "Europe",
  },
  {
    lat: "52.0700255",
    lng: "-1.9011249",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWKH",
    portName: "Wickhamford",
    portContinent: "Europe",
  },
  {
    lat: "53.3723866",
    lng: "-3.1842948",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWKI",
    portName: "WestKirby",
    portContinent: "Europe",
  },
  {
    lat: "-41.2969022",
    lng: "146.8500514",
    country: "AU",
    country_long: "Australia",
    portCode: "GBWKL",
    portName: "Winkleigh",
    portContinent: "Europe",
  },
  {
    lat: "50.898199",
    lng: "-1.188434",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWKM",
    portName: "Wickham",
    portContinent: "Europe",
  },
  {
    lat: "51.9181389",
    lng: "-0.1269299",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWKN",
    portName: "Walkern",
    portContinent: "Europe",
  },
  {
    lat: "55.34427900000001",
    lng: "-1.609709",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWKO",
    portName: "Warkworth",
    portContinent: "Europe",
  },
  {
    lat: "51.342412",
    lng: "0.261927",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWKS",
    portName: "WestKingsdown",
    portContinent: "Europe",
  },
  {
    lat: "53.82041299999999",
    lng: "-0.48754",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWKT",
    portName: "Walkington",
    portContinent: "Europe",
  },
  {
    lat: "52.314234",
    lng: "0.05790199999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWL2",
    portName: "Willingham",
    portContinent: "Europe",
  },
  {
    lat: "51.59999999999999",
    lng: "0.8333332999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLA",
    portName: "Wallasea",
    portContinent: "Europe",
  },
  {
    lat: "54.414131",
    lng: "-1.3873869",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLB",
    portName: "Welbury",
    portContinent: "Europe",
  },
  {
    lat: "50.9355413",
    lng: "-1.2009289",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLC",
    portName: "WalthamChase",
    portContinent: "Europe",
  },
  {
    lat: "52.414995",
    lng: "-1.056133",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLD",
    portName: "Welford",
    portContinent: "Europe",
  },
  {
    lat: "51.38847",
    lng: "-0.416969",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLE",
    portName: "Walton-on-Thames",
    portContinent: "Europe",
  },
  {
    lat: "47.6614222",
    lng: "-122.3421188",
    country: "US",
    country_long: "United States",
    portCode: "GBWLF",
    portName: "Wallingford",
    portContinent: "Europe",
  },
  {
    lat: "58.347593",
    lng: "-3.162675",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLG",
    portName: "Whaligoe",
    portContinent: "Europe",
  },
  {
    lat: "50.582285",
    lng: "-3.464402",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLH",
    portName: "Dawlish",
    portContinent: "Europe",
  },
  {
    lat: "54.97154399999999",
    lng: "-2.949855",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLI",
    portName: "WestLinton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWLK",
    portName: "Walker",
    portContinent: "Europe",
  },
  {
    lat: "52.585017",
    lng: "-2.057635",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLL",
    portName: "Willenhall",
    portContinent: "Europe",
  },
  {
    lat: "52.585017",
    lng: "-2.057635",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLLÂ",
    portName: "WillenhallWalsall",
    portContinent: "Europe",
  },
  {
    lat: "51.17255",
    lng: "-4.206596",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLM",
    portName: "Woolacombe",
    portContinent: "Europe",
  },
  {
    lat: "51.8031689",
    lng: "-0.208661",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLN",
    portName: "Welwyn",
    portContinent: "Europe",
  },
  {
    lat: "33.9892669",
    lng: "-84.440469",
    country: "US",
    country_long: "United States",
    portCode: "GBWLO",
    portName: "Walton",
    portContinent: "Europe",
  },
  {
    lat: "52.2247577",
    lng: "0.8879366999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLP",
    portName: "Woolpit",
    portContinent: "Europe",
  },
  {
    lat: "39.7492101",
    lng: "-104.9853043",
    country: "US",
    country_long: "United States",
    portCode: "GBWLQ",
    portName: "Welton",
    portContinent: "Europe",
  },
  {
    lat: "55.54684",
    lng: "-2.012239",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLR",
    portName: "Wooler",
    portContinent: "Europe",
  },
  {
    lat: "51.21049860000001",
    lng: "-2.646776",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLS",
    portName: "Wells",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWLT",
    portName: "Wilton",
    portContinent: "Europe",
  },
  {
    lat: "53.075441",
    lng: "-0.558843",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLU",
    portName: "Welbourn",
    portContinent: "Europe",
  },
  {
    lat: "50.89031199999999",
    lng: "-1.028751",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLV",
    portName: "Waterlooville",
    portContinent: "Europe",
  },
  {
    lat: "51.4894752",
    lng: "0.06758829999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLW",
    portName: "Woolwich",
    portContinent: "Europe",
  },
  {
    lat: "52.48603010000001",
    lng: "-1.9578181",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWLY",
    portName: "Warley",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWLZ",
    portName: "Walls",
    portContinent: "Europe",
  },
  {
    lat: "55.8913375",
    lng: "-4.889574",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWMB",
    portName: "Wemyss Bay (GBWMB), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWMD",
    portName: "WestMainland",
    portContinent: "Europe",
  },
  {
    lat: "51.73218869999999",
    lng: "-0.0221709",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWME",
    portName: "Wormley",
    portContinent: "Europe",
  },
  {
    lat: "51.2047669",
    lng: "1.393192",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWMF",
    portName: "Walmer",
    portContinent: "Europe",
  },
  {
    lat: "51.851928",
    lng: "-0.179152",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWMG",
    portName: "Woolmer Green",
    portContinent: "Europe",
  },
  {
    lat: "51.7721664",
    lng: "-1.0716128",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWMH",
    portName: "Worminghall",
    portContinent: "Europe",
  },
  {
    lat: "51.77134",
    lng: "-2.3244639",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWMI",
    portName: "Whitminster",
    portContinent: "Europe",
  },
  {
    lat: "52.152667",
    lng: "1.363068",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWMK",
    portName: "WickhamMarket",
    portContinent: "Europe",
  },
  {
    lat: "51.2944249",
    lng: "0.408784",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWML",
    portName: "WestMalling",
    portContinent: "Europe",
  },
  {
    lat: "51.0138921",
    lng: "-1.0858859",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWMN",
    portName: "West Meon",
    portContinent: "Europe",
  },
  {
    lat: "51.400766",
    lng: "-0.3562949",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWMO",
    portName: "West Molesey",
    portContinent: "Europe",
  },
  {
    lat: "50.825211",
    lng: "-1.889216",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWMR",
    portName: "WestMoors",
    portContinent: "Europe",
  },
  {
    lat: "51.777645",
    lng: "0.9288209999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWMS",
    portName: "WestMersea",
    portContinent: "Europe",
  },
  {
    lat: "34.2103894",
    lng: "-77.8868117",
    country: "US",
    country_long: "United States",
    portCode: "GBWMT",
    portName: "Wilmington",
    portContinent: "Europe",
  },
  {
    lat: "50.5975109",
    lng: "-2.0373499",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWMV",
    portName: "WorthMatravers",
    portContinent: "Europe",
  },
  {
    lat: "52.80600099999999",
    lng: "-1.105156",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWMW",
    portName: "Wymeswold",
    portContinent: "Europe",
  },
  {
    lat: "51.45952699999999",
    lng: "-2.474226",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWMY",
    portName: "Warmley",
    portContinent: "Europe",
  },
  {
    lat: "51.54139499999999",
    lng: "-1.902318",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWNB",
    portName: "WoottonBassett",
    portContinent: "Europe",
  },
  {
    lat: "52.6085546",
    lng: "-1.667122",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWNC",
    portName: "Wilnecote",
    portContinent: "Europe",
  },
  {
    lat: "52.21137299999999",
    lng: "-2.164704",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWND",
    portName: "Warndon",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWNE",
    portName: "Winchester",
    portContinent: "Europe",
  },
  {
    lat: "51.2845836",
    lng: "-0.9017638000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWNF",
    portName: "Winchfield",
    portContinent: "Europe",
  },
  {
    lat: "-41.2923814",
    lng: "174.7787463",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBWNG",
    portName: "Wellington",
    portContinent: "Europe",
  },
  {
    lat: "51.3093239",
    lng: "-0.055901",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWNH",
    portName: "Warlingham",
    portContinent: "Europe",
  },
  {
    lat: "51.428089",
    lng: "-0.879365",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWNI",
    portName: "Winnersh",
    portContinent: "Europe",
  },
  {
    lat: "53.4124157",
    lng: "-1.4320278",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWNK",
    portName: "Wincobank",
    portContinent: "Europe",
  },
  {
    lat: "57.389307",
    lng: "-1.866632",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWNL",
    portName: "Whinnyfold",
    portContinent: "Europe",
  },
  {
    lat: "47.7510741",
    lng: "-120.7401386",
    country: "US",
    country_long: "United States",
    portCode: "GBWNN",
    portName: "Washington",
    portContinent: "Europe",
  },
  {
    lat: "51.303663",
    lng: "-2.847222",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWNO",
    portName: "Winscombe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWNR",
    portName: "Wem",
    portContinent: "Europe",
  },
  {
    lat: "52.954641",
    lng: "0.848927",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWNS",
    portName: "Wells Next The Sea (GBWNS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "-41.2923814",
    lng: "174.7787463",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBWNT",
    portName: "Wellington",
    portContinent: "Europe",
  },
  {
    lat: "50.6596985",
    lng: "-2.2758743",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWNW",
    portName: "WinfrithNewburgh",
    portContinent: "Europe",
  },
  {
    lat: "53.82779799999999",
    lng: "-0.399977",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWNY",
    portName: "Woodmansey",
    portContinent: "Europe",
  },
  {
    lat: "51.848186",
    lng: "1.267736",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWNZ",
    portName: "Walton-on-the-Naze",
    portContinent: "Europe",
  },
  {
    lat: "39.0768275",
    lng: "-77.183204",
    country: "US",
    country_long: "United States",
    portCode: "GBWO9",
    portName: "Wootton",
    portContinent: "Europe",
  },
  {
    lat: "52.165993",
    lng: "-1.785768",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOA",
    portName: "WelfordonAvon",
    portContinent: "Europe",
  },
  {
    lat: "52.865643",
    lng: "-2.498621",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOB",
    portName: "Suttonheath",
    portContinent: "Europe",
  },
  {
    lat: "51.7188789",
    lng: "-2.2314371",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOC",
    portName: "Woodchester",
    portContinent: "Europe",
  },
  {
    lat: "51.2860299",
    lng: "-0.033712",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOD",
    portName: "Woldingham",
    portContinent: "Europe",
  },
  {
    lat: "51.3603116",
    lng: "-2.9292854",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOE",
    portName: "Worle",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWOF",
    portName: "Woodford",
    portContinent: "Europe",
  },
  {
    lat: "51.5971722",
    lng: "-0.1098021",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOG",
    portName: "Wood Green",
    portContinent: "Europe",
  },
  {
    lat: "52.169339",
    lng: "-1.204683",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOH",
    portName: "WoodfordHalse",
    portContinent: "Europe",
  },
  {
    lat: "50.81787",
    lng: "-0.372882",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOI",
    portName: "Worthing",
    portContinent: "Europe",
  },
  {
    lat: "50.8935081",
    lng: "-1.3748443",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOJ",
    portName: "Woolston",
    portContinent: "Europe",
  },
  {
    lat: "51.410457",
    lng: "-0.833861",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOK",
    portName: "Wokingham",
    portContinent: "Europe",
  },
  {
    lat: "51.5008658",
    lng: "0.0626916",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOL",
    portName: "NorthWoolwich",
    portContinent: "Europe",
  },
  {
    lat: "52.5335279",
    lng: "-2.1873269",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOM",
    portName: "Wombourne, Staffordshire",
    portContinent: "Europe",
  },
  {
    lat: "52.82774999999999",
    lng: "1.4532757",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWON",
    portName: "Witton",
    portContinent: "Europe",
  },
  {
    lat: "52.3349699",
    lng: "0.4873949",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOO",
    portName: "Worlington",
    portContinent: "Europe",
  },
  {
    lat: "53.309302",
    lng: "-1.122745",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOP",
    portName: "Worksop, Nottinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "54.643569",
    lng: "-3.542752",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOR",
    portName: "Workington",
    portContinent: "Europe",
  },
  {
    lat: "52.0121808",
    lng: "-0.6473247999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOS",
    portName: "WoburnSands",
    portContinent: "Europe",
  },
  {
    lat: "50.81787",
    lng: "-0.372882",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOT",
    portName: "Worthing",
    portContinent: "Europe",
  },
  {
    lat: "52.5868159",
    lng: "-2.1256587",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOV",
    portName: "Wolverhampton",
    portContinent: "Europe",
  },
  {
    lat: "50.7415297",
    lng: "-1.8976168",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOW",
    portName: "Wallisdown",
    portContinent: "Europe",
  },
  {
    lat: "52.114431",
    lng: "-1.282862",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWOX",
    portName: "Wardington",
    portContinent: "Europe",
  },
  {
    lat: "41.3645382",
    lng: "-74.1059775",
    country: "US",
    country_long: "United States",
    portCode: "GBWOY",
    portName: "Woodbury",
    portContinent: "Europe",
  },
  {
    lat: "42.2682728",
    lng: "-71.0185755",
    country: "US",
    country_long: "United States",
    portCode: "GBWOZ",
    portName: "Wollaston",
    portContinent: "Europe",
  },
  {
    lat: "51.013156",
    lng: "-1.647986",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWPH",
    portName: "Whiteparish",
    portContinent: "Europe",
  },
  {
    lat: "51.38029",
    lng: "-0.24468",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWPK",
    portName: "WorcesterPark",
    portContinent: "Europe",
  },
  {
    lat: "50.767246",
    lng: "-3.353146",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWPL",
    portName: "Whimple",
    portContinent: "Europe",
  },
  {
    lat: "54.42512",
    lng: "-7.646409999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWPN",
    portName: "White Hill Point (GBWPN), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.660348",
    lng: "-3.146407",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWPS",
    portName: "Welshpool",
    portContinent: "Europe",
  },
  {
    lat: "54.1002952",
    lng: "-6.2574656",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWPT",
    portName: "Port of Warrenpoint",
    portContinent: "Europe",
  },
  {
    lat: "53.086191",
    lng: "-2.240836",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWQB",
    portName: "Kidsgrove, Staffordshire",
    portContinent: "Europe",
  },
  {
    lat: "51.746997",
    lng: "-0.9741879999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWQC",
    portName: "Thame, Oxfordshire",
    portContinent: "Europe",
  },
  {
    lat: "53.4332463",
    lng: "-2.2329203",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWQG",
    portName: "Withington",
    portContinent: "Europe",
  },
  {
    lat: "55.0877613",
    lng: "-2.2210585",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWQK",
    portName: "Wark",
    portContinent: "Europe",
  },
  {
    lat: "-41.2923814",
    lng: "174.7787463",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBWQL",
    portName: "Wellington",
    portContinent: "Europe",
  },
  {
    lat: "39.7492101",
    lng: "-104.9853043",
    country: "US",
    country_long: "United States",
    portCode: "GBWQT",
    portName: "Welton",
    portContinent: "Europe",
  },
  {
    lat: "51.090843",
    lng: "-0.34712",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRA",
    portName: "Warnham",
    portContinent: "Europe",
  },
  {
    lat: "55.786817",
    lng: "-5.5258414",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRB",
    portName: "WestLochTarbert",
    portContinent: "Europe",
  },
  {
    lat: "52.193636",
    lng: "-2.221575",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRC",
    portName: "Worcester, Worcestershire",
    portContinent: "Europe",
  },
  {
    lat: "51.6092549",
    lng: "0.0405521",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRD",
    portName: "Woodford Green",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWRE",
    portName: "Wardle",
    portContinent: "Europe",
  },
  {
    lat: "52.2593197",
    lng: "-7.110070299999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "GBWRF",
    portName: "Waterford",
    portContinent: "Europe",
  },
  {
    lat: "30.0290692",
    lng: "-95.3867205",
    country: "US",
    country_long: "United States",
    portCode: "GBWRG",
    portName: "Werrington",
    portContinent: "Europe",
  },
  {
    lat: "41.7614511",
    lng: "-70.71973419999999",
    country: "US",
    country_long: "United States",
    portCode: "GBWRH",
    portName: "Wareham",
    portContinent: "Europe",
  },
  {
    lat: "53.3727181",
    lng: "-3.073754",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRI",
    portName: "Wirral",
    portContinent: "Europe",
  },
  {
    lat: "53.309302",
    lng: "-1.122745",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRK",
    portName: "Worksop",
    portContinent: "Europe",
  },
  {
    lat: "53.7505427",
    lng: "-2.9080006",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRL",
    portName: "Warton",
    portContinent: "Europe",
  },
  {
    lat: "51.7721664",
    lng: "-1.0716128",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRM",
    portName: "Worminghall, Buckinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "40.2492741",
    lng: "-75.1340604",
    country: "US",
    country_long: "United States",
    portCode: "GBWRN",
    portName: "Warrington",
    portContinent: "Europe",
  },
  {
    lat: "51.30777",
    lng: "0.308506",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRO",
    portName: "Wrotham",
    portContinent: "Europe",
  },
  {
    lat: "51.38029",
    lng: "-0.24468",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRP",
    portName: "Worcester Park, Greater London",
    portContinent: "Europe",
  },
  {
    lat: "55.0877613",
    lng: "-2.2210585",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRQ",
    portName: "Wark",
    portContinent: "Europe",
  },
  {
    lat: "51.022706",
    lng: "-0.504633",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRR",
    portName: "WisboroughGreen",
    portContinent: "Europe",
  },
  {
    lat: "53.50754",
    lng: "-2.391199",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRS",
    portName: "Worsley",
    portContinent: "Europe",
  },
  {
    lat: "53.7505427",
    lng: "-2.9080006",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRT",
    portName: "Warton",
    portContinent: "Europe",
  },
  {
    lat: "51.427905",
    lng: "-0.1002486",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRW",
    portName: "WestNorwood",
    portContinent: "Europe",
  },
  {
    lat: "59.294215",
    lng: "-2.9800762",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWRY",
    portName: "Westray",
    portContinent: "Europe",
  },
  {
    lat: "55.9070198",
    lng: "-3.5517167",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWS9",
    portName: "WestLothian",
    portContinent: "Europe",
  },
  {
    lat: "50.6926459",
    lng: "-3.402454",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWSA",
    portName: "WoodburySalterton",
    portContinent: "Europe",
  },
  {
    lat: "51.454957",
    lng: "-0.55494",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWSB",
    portName: "Wraysbury",
    portContinent: "Europe",
  },
  {
    lat: "53.6943317",
    lng: "-2.1004209",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWSD",
    portName: "Walsden",
    portContinent: "Europe",
  },
  {
    lat: "51.382807",
    lng: "1.334003",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWSE",
    portName: "Westgate On Sea",
    portContinent: "Europe",
  },
  {
    lat: "50.705099",
    lng: "-2.3876821",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWSF",
    portName: "WestStafford",
    portContinent: "Europe",
  },
  {
    lat: "51.849998",
    lng: "0.4939859999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWSG",
    portName: "WillowsGreen",
    portContinent: "Europe",
  },
  {
    lat: "51.01105",
    lng: "0.7032969",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWSH",
    portName: "Wittersham",
    portContinent: "Europe",
  },
  {
    lat: "43.0293098",
    lng: "-76.20285539999999",
    country: "US",
    country_long: "United States",
    portCode: "GBWSI",
    portName: "Westhill",
    portContinent: "Europe",
  },
  {
    lat: "35.0241873",
    lng: "-110.6973571",
    country: "US",
    country_long: "United States",
    portCode: "GBWSL",
    portName: "Winslow",
    portContinent: "Europe",
  },
  {
    lat: "51.345606",
    lng: "-2.9612624",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWSM",
    portName: "Weston-Super-Mare",
    portContinent: "Europe",
  },
  {
    lat: "26.1003654",
    lng: "-80.3997748",
    country: "US",
    country_long: "United States",
    portCode: "GBWSN",
    portName: "Weston",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWSO",
    portName: "Whetstone",
    portContinent: "Europe",
  },
  {
    lat: "38.885521",
    lng: "-94.65084700000001",
    country: "US",
    country_long: "United States",
    portCode: "GBWSP",
    portName: "Port of Weston Point",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWSR",
    portName: "Windsor",
    portContinent: "Europe",
  },
  {
    lat: "50.8505718",
    lng: "-1.2982052",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWSS",
    portName: "Warsash",
    portContinent: "Europe",
  },
  {
    lat: "50.8935081",
    lng: "-1.3748443",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWST",
    portName: "Woolston",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWSU",
    portName: "W.Sussex",
    portContinent: "Europe",
  },
  {
    lat: "53.328",
    lng: "-2.229409",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWSW",
    portName: "Wilmslow",
    portContinent: "Europe",
  },
  {
    lat: "50.995881",
    lng: "-0.203378",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWSX",
    portName: "Bolney",
    portContinent: "Europe",
  },
  {
    lat: "40.7556561",
    lng: "-73.5876273",
    country: "US",
    country_long: "United States",
    portCode: "GBWSY",
    portName: "Westbury",
    portContinent: "Europe",
  },
  {
    lat: "42.095061",
    lng: "-82.4629497",
    country: "CA",
    country_long: "Canada",
    portCode: "GBWT3",
    portName: "Wheatley",
    portContinent: "Europe",
  },
  {
    lat: "53.169225",
    lng: "-2.821476",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWT4",
    portName: "Waverton",
    portContinent: "Europe",
  },
  {
    lat: "53.490489",
    lng: "-1.5377701",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWT9",
    portName: "Wortley",
    portContinent: "Europe",
  },
  {
    lat: "52.579716",
    lng: "0.450993",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTA",
    portName: "WestDereham",
    portContinent: "Europe",
  },
  {
    lat: "54.486335",
    lng: "-0.613347",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTB",
    portName: "Whitby",
    portContinent: "Europe",
  },
  {
    lat: "52.968716",
    lng: "-2.682045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTC",
    portName: "Whitchurch",
    portContinent: "Europe",
  },
  {
    lat: "51.5767971",
    lng: "0.0249881",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTD",
    portName: "Wanstead",
    portContinent: "Europe",
  },
  {
    lat: "40.616296",
    lng: "-74.1386767",
    country: "US",
    country_long: "United States",
    portCode: "GBWTE",
    portName: "Westerleigh",
    portContinent: "Europe",
  },
  {
    lat: "52.109299",
    lng: "0.156023",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTF",
    portName: "Whittlesford",
    portContinent: "Europe",
  },
  {
    lat: "53.54896",
    lng: "-2.525881",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTG",
    portName: "Westhoughton",
    portContinent: "Europe",
  },
  {
    lat: "53.69071599999999",
    lng: "-2.555034",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTH",
    portName: "Withnell",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWTI",
    portName: "Westfield",
    portContinent: "Europe",
  },
  {
    lat: "55.046389",
    lng: "-1.4512989",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTL",
    portName: "Whitley Bay",
    portContinent: "Europe",
  },
  {
    lat: "52.966667",
    lng: "-1.357334",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTM",
    portName: "West Hallam",
    portContinent: "Europe",
  },
  {
    lat: "44.8644999",
    lng: "-75.20410190000001",
    country: "US",
    country_long: "United States",
    portCode: "GBWTN",
    portName: "Waddington",
    portContinent: "Europe",
  },
  {
    lat: "52.517221",
    lng: "-1.741686",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTO",
    portName: "Water Orton",
    portContinent: "Europe",
  },
  {
    lat: "54.691745",
    lng: "-1.212926",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTP",
    portName: "WestHartlepool",
    portContinent: "Europe",
  },
  {
    lat: "26.1003654",
    lng: "-80.3997748",
    country: "US",
    country_long: "United States",
    portCode: "GBWTQ",
    portName: "Weston",
    portContinent: "Europe",
  },
  {
    lat: "42.2625932",
    lng: "-71.8022934",
    country: "US",
    country_long: "United States",
    portCode: "GBWTR",
    portName: "Worcester",
    portContinent: "Europe",
  },
  {
    lat: "51.361047",
    lng: "1.024256",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTS",
    portName: "Whitstable",
    portContinent: "Europe",
  },
  {
    lat: "52.570848",
    lng: "0.828023",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTT",
    portName: "Watton",
    portContinent: "Europe",
  },
  {
    lat: "43.558668",
    lng: "-80.468687",
    country: "CA",
    country_long: "Canada",
    portCode: "GBWTU",
    portName: "Winterbourne",
    portContinent: "Europe",
  },
  {
    lat: "54.991512",
    lng: "-1.529375",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTW",
    portName: "Wallsend",
    portContinent: "Europe",
  },
  {
    lat: "52.5581179",
    lng: "-0.1300956",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTX",
    portName: "Whittlesey",
    portContinent: "Europe",
  },
  {
    lat: "51.149517",
    lng: "-0.646547",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWTY",
    portName: "Witley",
    portContinent: "Europe",
  },
  {
    lat: "53.5029116",
    lng: "-1.339493",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWUD",
    portName: "WathuponDearne",
    portContinent: "Europe",
  },
  {
    lat: "51.63802500000001",
    lng: "-2.351248",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWUE",
    portName: "Wotton-under-Edge",
    portContinent: "Europe",
  },
  {
    lat: "52.48665459999999",
    lng: "-1.3691375",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWUF",
    portName: "Wolvey",
    portContinent: "Europe",
  },
  {
    lat: "51.588221",
    lng: "-0.679696",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWUG",
    portName: "WooburnGreen",
    portContinent: "Europe",
  },
  {
    lat: "51.072755",
    lng: "0.7763899999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWUH",
    portName: "Woodchurch",
    portContinent: "Europe",
  },
  {
    lat: "52.9384749",
    lng: "1.244207",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWUN",
    portName: "WestRunton",
    portContinent: "Europe",
  },
  {
    lat: "-37.7530064",
    lng: "145.6987403",
    country: "AU",
    country_long: "Australia",
    portCode: "GBWUR",
    portName: "Warburton",
    portContinent: "Europe",
  },
  {
    lat: "52.8486033",
    lng: "-1.401343",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWUT",
    portName: "WestonuponTrent",
    portContinent: "Europe",
  },
  {
    lat: "53.024186",
    lng: "-2.609324",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWUY",
    portName: "Wrenbury",
    portContinent: "Europe",
  },
  {
    lat: "51.78311720000001",
    lng: "-1.2945403",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWVC",
    portName: "Wolvercote",
    portContinent: "Europe",
  },
  {
    lat: "50.96342019999999",
    lng: "-0.075227",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWVF",
    portName: "WivelsfieldGreen",
    portContinent: "Europe",
  },
  {
    lat: "52.5868159",
    lng: "-2.1256587",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWVH",
    portName: "Wolverhampton, West Midlands",
    portContinent: "Europe",
  },
  {
    lat: "30.7753454",
    lng: "-94.4148791",
    country: "US",
    country_long: "United States",
    portCode: "GBWVL",
    portName: "Woodville",
    portContinent: "Europe",
  },
  {
    lat: "54.6244921",
    lng: "-1.3010292",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWVS",
    portName: "Wolviston",
    portContinent: "Europe",
  },
  {
    lat: "52.062458",
    lng: "-0.816221",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWVT",
    portName: "Wolverton",
    portContinent: "Europe",
  },
  {
    lat: "52.48665459999999",
    lng: "-1.3691375",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWVY",
    portName: "Wolvey",
    portContinent: "Europe",
  },
  {
    lat: "51.1303601",
    lng: "-0.4010941",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWW9",
    portName: "Walliswood",
    portContinent: "Europe",
  },
  {
    lat: "52.9903989",
    lng: "-2.67639",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWA",
    portName: "Wirswall",
    portContinent: "Europe",
  },
  {
    lat: "51.375798",
    lng: "-0.014861",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWC",
    portName: "West Wickham",
    portContinent: "Europe",
  },
  {
    lat: "51.639227",
    lng: "-4.014005",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWD",
    portName: "Waunarlwydd",
    portContinent: "Europe",
  },
  {
    lat: "56.1399217",
    lng: "-3.0862397",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWE",
    portName: "WestWemyss",
    portContinent: "Europe",
  },
  {
    lat: "50.7805449",
    lng: "-0.8959389999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWG",
    portName: "WestWittering",
    portContinent: "Europe",
  },
  {
    lat: "51.4873785",
    lng: "-0.09530680000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWH",
    portName: "Walworth/London",
    portContinent: "Europe",
  },
  {
    lat: "52.73980100000001",
    lng: "-1.3529659",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWI",
    portName: "Whitwick",
    portContinent: "Europe",
  },
  {
    lat: "52.2671353",
    lng: "-1.4675216",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWK",
    portName: "Warwickshire",
    portContinent: "Europe",
  },
  {
    lat: "53.6930069",
    lng: "-1.0591543",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWL",
    portName: "Gowdall",
    portContinent: "Europe",
  },
  {
    lat: "51.4883152",
    lng: "-0.7784245999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWM",
    portName: "WhiteWaltham",
    portContinent: "Europe",
  },
  {
    lat: "51.6350178",
    lng: "-3.4212165",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWN",
    portName: "Wattstown",
    portContinent: "Europe",
  },
  {
    lat: "52.02629400000001",
    lng: "-1.9409271",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWO",
    portName: "Wormington",
    portContinent: "Europe",
  },
  {
    lat: "51.593722",
    lng: "-2.40123",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWR",
    portName: "Wickwar",
    portContinent: "Europe",
  },
  {
    lat: "53.491059",
    lng: "-0.8694839999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWS",
    portName: "Westwoodside",
    portContinent: "Europe",
  },
  {
    lat: "51.9687294",
    lng: "-2.7406028",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWT",
    portName: "WormelowTump",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWWU",
    portName: "Winterborne",
    portContinent: "Europe",
  },
  {
    lat: "47.75487280000001",
    lng: "-117.4178623",
    country: "US",
    country_long: "United States",
    portCode: "GBWWV",
    portName: "Whitworth",
    portContinent: "Europe",
  },
  {
    lat: "53.955363",
    lng: "-0.442819",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWW",
    portName: "HuttonCranswick",
    portContinent: "Europe",
  },
  {
    lat: "53.714443",
    lng: "-1.3773864",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWX",
    portName: "Whitwood",
    portContinent: "Europe",
  },
  {
    lat: "50.874296",
    lng: "-3.90147",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWWY",
    portName: "Wembworthy",
    portContinent: "Europe",
  },
  {
    lat: "51.44107899999999",
    lng: "-2.729798",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWXA",
    portName: "Wraxall",
    portContinent: "Europe",
  },
  {
    lat: "53.745894",
    lng: "-0.868877",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWXD",
    portName: "Howden",
    portContinent: "Europe",
  },
  {
    lat: "51.645079",
    lng: "-1.010546",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWXF",
    portName: "Watlington",
    portContinent: "Europe",
  },
  {
    lat: "53.04304",
    lng: "-2.992494",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWXH",
    portName: "Wrexham",
    portContinent: "Europe",
  },
  {
    lat: "53.292612",
    lng: "-3.00753",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWXN",
    portName: "Willaston",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWXR",
    portName: "Worth",
    portContinent: "Europe",
  },
  {
    lat: "52.3449256",
    lng: "-1.9718103",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWXT",
    portName: "W.Midlands",
    portContinent: "Europe",
  },
  {
    lat: "33.5342095",
    lng: "-112.2592655",
    country: "US",
    country_long: "United States",
    portCode: "GBWXW",
    portName: "Westgate",
    portContinent: "Europe",
  },
  {
    lat: "51.4586929",
    lng: "-1.042536",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWXY",
    portName: "Tilehurst",
    portContinent: "Europe",
  },
  {
    lat: "54.230816",
    lng: "-0.520954",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWYA",
    portName: "Wykeham",
    portContinent: "Europe",
  },
  {
    lat: "53.3298746",
    lng: "-1.9841743",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWYB",
    portName: "Whaley Bridge",
    portContinent: "Europe",
  },
  {
    lat: "52.2923981",
    lng: "-2.1147587",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWYC",
    portName: "Wychbold",
    portContinent: "Europe",
  },
  {
    lat: "52.569354",
    lng: "1.115305",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWYD",
    portName: "Wymondham",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWYE",
    portName: "Wye",
    portContinent: "Europe",
  },
  {
    lat: "53.401455",
    lng: "-2.2712861",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWYH",
    portName: "Wythenshawe",
    portContinent: "Europe",
  },
  {
    lat: "53.9590858",
    lng: "-1.0792403",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWYK",
    portName: "W. Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "51.3017577",
    lng: "-0.079251",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWYL",
    portName: "Whyteleafe",
    portContinent: "Europe",
  },
  {
    lat: "33.503995",
    lng: "-86.9248483",
    country: "US",
    country_long: "United States",
    portCode: "GBWYM",
    portName: "Wylam",
    portContinent: "Europe",
  },
  {
    lat: "52.9433269",
    lng: "1.141423",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWYO",
    portName: "Weybourne",
    portContinent: "Europe",
  },
  {
    lat: "53.89960989999999",
    lng: "-2.8093014",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWYR",
    portName: "Wyre",
    portContinent: "Europe",
  },
  {
    lat: "51.8259321",
    lng: "-2.4152321",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWYS",
    portName: "WestburyonSevern",
    portContinent: "Europe",
  },
  {
    lat: "53.401455",
    lng: "-2.2712861",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWYT",
    portName: "Wythenshawe, Greater Manchester",
    portContinent: "Europe",
  },
  {
    lat: "52.1818219",
    lng: "0.36651",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWYW",
    portName: "WestleyWaterless",
    portContinent: "Europe",
  },
  {
    lat: "27.9127752",
    lng: "-82.3454074",
    country: "US",
    country_long: "United States",
    portCode: "GBWYX",
    portName: "WestBay",
    portContinent: "Europe",
  },
  {
    lat: "53.045721",
    lng: "-2.453573",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBWYY",
    portName: "Wybunbury",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBWZD",
    portName: "Weald",
    portContinent: "Europe",
  },
  {
    lat: "53.63623699999999",
    lng: "-0.502388",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXAS",
    portName: "Saxby-All-Sts",
    portContinent: "Europe",
  },
  {
    lat: "52.945556",
    lng: "-2.158722",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXBR",
    portName: "Barlaston",
    portContinent: "Europe",
  },
  {
    lat: "53.574585",
    lng: "-1.6529609",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXBY",
    portName: "DenbyDale",
    portContinent: "Europe",
  },
  {
    lat: "52.2277474",
    lng: "-2.2185812",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXCA",
    portName: "Claines",
    portContinent: "Europe",
  },
  {
    lat: "52.628089",
    lng: "-2.192079",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXCD",
    portName: "Codsall",
    portContinent: "Europe",
  },
  {
    lat: "53.611325",
    lng: "-0.8321590000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXCE",
    portName: "Crowle",
    portContinent: "Europe",
  },
  {
    lat: "51.24389679999999",
    lng: "0.9658722999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXCH",
    portName: "Chilham",
    portContinent: "Europe",
  },
  {
    lat: "54.52190599999999",
    lng: "-3.517168",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXCL",
    portName: "CleatorMoor",
    portContinent: "Europe",
  },
  {
    lat: "54.5504547",
    lng: "-5.7456751",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXCM",
    portName: "Comber",
    portContinent: "Europe",
  },
  {
    lat: "52.676305",
    lng: "-0.1669214",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXCN",
    portName: "Crowland",
    portContinent: "Europe",
  },
  {
    lat: "52.803453",
    lng: "-0.6206610000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXCO",
    portName: "Colsterworth",
    portContinent: "Europe",
  },
  {
    lat: "51.1254769",
    lng: "-0.081519",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXCR",
    portName: "CrawleyDown",
    portContinent: "Europe",
  },
  {
    lat: "54.0774066",
    lng: "-6.6087567",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXCS",
    portName: "Crossmaglen",
    portContinent: "Europe",
  },
  {
    lat: "51.443654",
    lng: "-1.29622",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXCU",
    portName: "Curridge",
    portContinent: "Europe",
  },
  {
    lat: "52.919911",
    lng: "-4.330451",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXCW",
    portName: "Chwilog",
    portContinent: "Europe",
  },
  {
    lat: "53.3582209",
    lng: "-3.1635413",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXCY",
    portName: "Caldy",
    portContinent: "Europe",
  },
  {
    lat: "52.18961299999999",
    lng: "0.616926",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXDB",
    portName: "Chedburgh",
    portContinent: "Europe",
  },
  {
    lat: "56.5676532",
    lng: "-3.5862204",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXDK",
    portName: "Dunkeld",
    portContinent: "Europe",
  },
  {
    lat: "52.498265",
    lng: "-2.576398",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXDT",
    portName: "DittonPriors",
    portContinent: "Europe",
  },
  {
    lat: "53.49169",
    lng: "-1.2563548",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXDY",
    portName: "Denaby",
    portContinent: "Europe",
  },
  {
    lat: "53.594366",
    lng: "-0.815685",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXED",
    portName: "Ealand",
    portContinent: "Europe",
  },
  {
    lat: "51.876812",
    lng: "-0.593461",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXET",
    portName: "EatonBray",
    portContinent: "Europe",
  },
  {
    lat: "52.0886332",
    lng: "-2.1192638",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXFF",
    portName: "Defford",
    portContinent: "Europe",
  },
  {
    lat: "33.9821302",
    lng: "-118.190878",
    country: "US",
    country_long: "United States",
    portCode: "GBXFH",
    portName: "Fishburn",
    portContinent: "Europe",
  },
  {
    lat: "54.4967772",
    lng: "-7.3146075",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXFI",
    portName: "Fintona",
    portContinent: "Europe",
  },
  {
    lat: "53.2862979",
    lng: "-1.7127689",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXFO",
    portName: "Foolow",
    portContinent: "Europe",
  },
  {
    lat: "52.348099",
    lng: "1.318336",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXFR",
    portName: "Fressingfield",
    portContinent: "Europe",
  },
  {
    lat: "52.540168",
    lng: "-0.6716059999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXGE",
    portName: "Gretton",
    portContinent: "Europe",
  },
  {
    lat: "55.0050199",
    lng: "-3.0626071",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXGG",
    portName: "GretnaGreen",
    portContinent: "Europe",
  },
  {
    lat: "53.2438239",
    lng: "-1.7012789",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXGL",
    portName: "GreatLongstone",
    portContinent: "Europe",
  },
  {
    lat: "54.191009",
    lng: "-2.910688",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXGO",
    portName: "GrangeoverSands",
    portContinent: "Europe",
  },
  {
    lat: "50.2992589",
    lng: "-4.8984499",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXGR",
    portName: "Grampound",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBXGT",
    portName: "Gotham",
    portContinent: "Europe",
  },
  {
    lat: "52.242334",
    lng: "-2.241903",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXGY",
    portName: "Grimley",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBXHA",
    portName: "Bethesda",
    portContinent: "Europe",
  },
  {
    lat: "52.7115579",
    lng: "-2.0003829",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXHD",
    portName: "Hednesford",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBXHE",
    portName: "Hele",
    portContinent: "Europe",
  },
  {
    lat: "51.2090308",
    lng: "-1.0515644",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXHI",
    portName: "Herriard",
    portContinent: "Europe",
  },
  {
    lat: "54.69594499999999",
    lng: "-1.751564",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXHN",
    portName: "HowdenleWear",
    portContinent: "Europe",
  },
  {
    lat: "53.207307",
    lng: "-0.11245",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXHO",
    portName: "Horncastle",
    portContinent: "Europe",
  },
  {
    lat: "51.2428529",
    lng: "0.677922",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXHR",
    portName: "Harrietsham",
    portContinent: "Europe",
  },
  {
    lat: "52.075697",
    lng: "-3.125908",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXHW",
    portName: "Hay-on-Wye",
    portContinent: "Europe",
  },
  {
    lat: "55.755363",
    lng: "-4.685976999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXKI",
    portName: "Kilbirnie",
    portContinent: "Europe",
  },
  {
    lat: "54.2695646",
    lng: "-0.9312499999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXKK",
    portName: "Kirkbymoorside",
    portContinent: "Europe",
  },
  {
    lat: "54.95158900000001",
    lng: "-6.5555797",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXKL",
    portName: "Kilrea",
    portContinent: "Europe",
  },
  {
    lat: "51.1362388",
    lng: "0.3487021",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXKP",
    portName: "KippingsCross",
    portContinent: "Europe",
  },
  {
    lat: "51.939622",
    lng: "-3.258123",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXLG",
    portName: "Llangorse",
    portContinent: "Europe",
  },
  {
    lat: "53.1748488",
    lng: "-3.3362961",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXLN",
    portName: "Llandyrnog",
    portContinent: "Europe",
  },
  {
    lat: "52.27773999999999",
    lng: "0.048734",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXLO",
    portName: "Longstanton",
    portContinent: "Europe",
  },
  {
    lat: "55.88198",
    lng: "-3.114017",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXLS",
    portName: "Lasswade",
    portContinent: "Europe",
  },
  {
    lat: "54.4093603",
    lng: "-6.6029059",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXLU",
    portName: "Loughgall",
    portContinent: "Europe",
  },
  {
    lat: "51.589659",
    lng: "-2.914446",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXLW",
    portName: "Llanwern",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBXLY",
    portName: "Kingsley",
    portContinent: "Europe",
  },
  {
    lat: "43.68963220000001",
    lng: "-70.2317808",
    country: "US",
    country_long: "United States",
    portCode: "GBXMA",
    portName: "Mackworth",
    portContinent: "Europe",
  },
  {
    lat: "53.140035",
    lng: "-0.408263",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXME",
    portName: "Metheringham",
    portContinent: "Europe",
  },
  {
    lat: "51.9334636",
    lng: "0.2519622",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXMH",
    portName: "Henham",
    portContinent: "Europe",
  },
  {
    lat: "55.597703",
    lng: "-2.101248",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXMI",
    portName: "Milfield",
    portContinent: "Europe",
  },
  {
    lat: "51.4823063",
    lng: "-2.4881605",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXMN",
    portName: "Mangotsfield",
    portContinent: "Europe",
  },
  {
    lat: "53.23263439999999",
    lng: "-2.6103157",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXMO",
    portName: "Cheshire",
    portContinent: "Europe",
  },
  {
    lat: "50.659678",
    lng: "-3.767892999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXMR",
    portName: "Moretonhampstead",
    portContinent: "Europe",
  },
  {
    lat: "43.5658099",
    lng: "-80.9221654",
    country: "CA",
    country_long: "Canada",
    portCode: "GBXMV",
    portName: "Milverton",
    portContinent: "Europe",
  },
  {
    lat: "52.4998687",
    lng: "-1.8645558",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXNE",
    portName: "Nechells",
    portContinent: "Europe",
  },
  {
    lat: "51.49062",
    lng: "-3.703659",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXNG",
    portName: "Nottage",
    portContinent: "Europe",
  },
  {
    lat: "52.962732",
    lng: "-1.074676",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXNH",
    portName: "Netherfield",
    portContinent: "Europe",
  },
  {
    lat: "54.19361070000001",
    lng: "-6.5763283",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXNN",
    portName: "Newtownhamilton",
    portContinent: "Europe",
  },
  {
    lat: "54.18207109999999",
    lng: "-7.3606364",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXNT",
    portName: "Newtownbutler",
    portContinent: "Europe",
  },
  {
    lat: "51.2397662",
    lng: "-0.1251628",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXNU",
    portName: "Nutfield",
    portContinent: "Europe",
  },
  {
    lat: "51.72152699999999",
    lng: "-3.24018",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXNW",
    portName: "NewTredegar",
    portContinent: "Europe",
  },
  {
    lat: "52.5991535",
    lng: "-1.0783725",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXOA",
    portName: "Oadby",
    portContinent: "Europe",
  },
  {
    lat: "53.0022938",
    lng: "-1.920433",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXOK",
    portName: "Oakamoor",
    portContinent: "Europe",
  },
  {
    lat: "52.735499",
    lng: "-1.565376",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXOV",
    portName: "Overseal",
    portContinent: "Europe",
  },
  {
    lat: "53.811949",
    lng: "-1.950671",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXOX",
    portName: "Oxenhope",
    portContinent: "Europe",
  },
  {
    lat: "51.76816820000001",
    lng: "-2.5567906",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXPA",
    portName: "Parkend",
    portContinent: "Europe",
  },
  {
    lat: "53.3155609",
    lng: "-2.6412425",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXPB",
    portName: "PrestonBrook",
    portContinent: "Europe",
  },
  {
    lat: "51.8580018",
    lng: "-0.3423826",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXPE",
    portName: "PetersGreen",
    portContinent: "Europe",
  },
  {
    lat: "53.091157",
    lng: "-1.322114",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXPI",
    portName: "Pinxton",
    portContinent: "Europe",
  },
  {
    lat: "51.70411199999999",
    lng: "-0.499173",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXPP",
    portName: "Chipperfield",
    portContinent: "Europe",
  },
  {
    lat: "51.56491",
    lng: "0.505078",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXPT",
    portName: "Pitsea",
    portContinent: "Europe",
  },
  {
    lat: "56.164592",
    lng: "-3.580639",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXPW",
    portName: "Powmill",
    portContinent: "Europe",
  },
  {
    lat: "54.57215464301117",
    lng: "-1.80989341751621",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXSS",
    portName: "Tees (GBXSS), United Kingdom, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.89571369999999",
    lng: "-1.9482641",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXST",
    portName: "Steeton",
    portContinent: "Europe",
  },
  {
    lat: "54.82232",
    lng: "-1.740305",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXTL",
    portName: "Lanchester",
    portContinent: "Europe",
  },
  {
    lat: "52.637896",
    lng: "-2.531244",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBXXQ",
    portName: "Buildwas",
    portContinent: "Europe",
  },
  {
    lat: "51.54158899999999",
    lng: "-2.414323",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYAA",
    portName: "Yate, Bristol",
    portContinent: "Europe",
  },
  {
    lat: "51.444076",
    lng: "-1.905759",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYAE",
    portName: "Yatesbury, Wiltshire",
    portContinent: "Europe",
  },
  {
    lat: "51.22364",
    lng: "0.430255",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYAL",
    portName: "Yalding",
    portContinent: "Europe",
  },
  {
    lat: "50.819068",
    lng: "-0.616725",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYAP",
    portName: "Yapton",
    portContinent: "Europe",
  },
  {
    lat: "52.4676406",
    lng: "-1.8065903",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYAR",
    portName: "Yardley, West Midlands",
    portContinent: "Europe",
  },
  {
    lat: "51.54158899999999",
    lng: "-2.414323",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYAT",
    portName: "Yate",
    portContinent: "Europe",
  },
  {
    lat: "50.45130810000001",
    lng: "-4.1712389",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYBF",
    portName: "BereFerrers",
    portContinent: "Europe",
  },
  {
    lat: "53.31921999999999",
    lng: "-4.260397",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYBG",
    portName: "Brynteg",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBYBR",
    portName: "Kirby",
    portContinent: "Europe",
  },
  {
    lat: "52.97786900000001",
    lng: "-4.265809",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYBY",
    portName: "Bryncir",
    portContinent: "Europe",
  },
  {
    lat: "52.467804",
    lng: "-2.9539369",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYDB",
    portName: "LydburyNorth",
    portContinent: "Europe",
  },
  {
    lat: "50.950945",
    lng: "0.9065892",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYDD",
    portName: "Lydd, Kent",
    portContinent: "Europe",
  },
  {
    lat: "51.79674199999999",
    lng: "-2.079405",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYDE",
    portName: "Syde",
    portContinent: "Europe",
  },
  {
    lat: "51.52291",
    lng: "-0.3880247000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYDG",
    portName: "Yeading",
    portContinent: "Europe",
  },
  {
    lat: "39.9390017",
    lng: "-75.2554605",
    country: "US",
    country_long: "United States",
    portCode: "GBYDN",
    portName: "Yeadon",
    portContinent: "Europe",
  },
  {
    lat: "53.865153",
    lng: "-1.684203",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYEA",
    portName: "Yeadon, West Yorkshire",
    portContinent: "Europe",
  },
  {
    lat: "51.5765108",
    lng: "-0.7100694",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYEB",
    portName: "Bourne End, Buckinghamshire",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBYEL",
    portName: "Hailey",
    portContinent: "Europe",
  },
  {
    lat: "50.942061",
    lng: "-2.633308",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYEO",
    portName: "Yeovil",
    portContinent: "Europe",
  },
  {
    lat: "51.709972",
    lng: "0.2446185",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYET",
    portName: "ChippingOngar",
    portContinent: "Europe",
  },
  {
    lat: "53.221233",
    lng: "-2.420962",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYEY",
    portName: "Byley",
    portContinent: "Europe",
  },
  {
    lat: "60.6166667",
    lng: "-0.8666667000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYFR",
    portName: "YellandFetlar",
    portContinent: "Europe",
  },
  {
    lat: "53.176166",
    lng: "-1.682148",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYGV",
    portName: "Youlgreave",
    portContinent: "Europe",
  },
  {
    lat: "52.19438359999999",
    lng: "-2.9705892",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYHL",
    portName: "Lyonshall",
    portContinent: "Europe",
  },
  {
    lat: "55.8881232",
    lng: "-4.3772624",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYKE",
    portName: "Yoker",
    portContinent: "Europe",
  },
  {
    lat: "42.0548167",
    lng: "-72.7703724",
    country: "US",
    country_long: "United States",
    portCode: "GBYKH",
    portName: "Southwick",
    portContinent: "Europe",
  },
  {
    lat: "51.766823",
    lng: "-3.159627",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYKJ",
    portName: "Blaina",
    portContinent: "Europe",
  },
  {
    lat: "51.068751",
    lng: "-4.1523521",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYLL",
    portName: "Yelland",
    portContinent: "Europe",
  },
  {
    lat: "50.493452",
    lng: "-4.085603",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYLT",
    portName: "Yelverton",
    portContinent: "Europe",
  },
  {
    lat: "54.63444699999999",
    lng: "-5.846255699999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYLW",
    portName: "Holywood",
    portContinent: "Europe",
  },
  {
    lat: "51.385147",
    lng: "0.3049830000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYLY",
    portName: "Hartley",
    portContinent: "Europe",
  },
  {
    lat: "50.96594",
    lng: "-2.6493668",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYMA",
    portName: "YeovilMarsh",
    portContinent: "Europe",
  },
  {
    lat: "51.642387",
    lng: "-3.235099",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYMH",
    portName: "Ystrad Mynach",
    portContinent: "Europe",
  },
  {
    lat: "51.586357",
    lng: "-2.102834",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYMM",
    portName: "Malmesbury, Wiltshire",
    portContinent: "Europe",
  },
  {
    lat: "50.70514",
    lng: "-1.5027467",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYMO",
    portName: "Yarmouth Harbour",
    portContinent: "Europe",
  },
  {
    lat: "52.4623867",
    lng: "-1.8313533",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYMS",
    portName: "HayMills",
    portContinent: "Europe",
  },
  {
    lat: "50.894559",
    lng: "-2.574939",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYMT",
    portName: "Yetminster",
    portContinent: "Europe",
  },
  {
    lat: "51.552236",
    lng: "-3.575482",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYMY",
    portName: "Brynmenyn",
    portContinent: "Europe",
  },
  {
    lat: "40.7395877",
    lng: "-74.0088629",
    country: "US",
    country_long: "United States",
    portCode: "GBYNT",
    portName: "Whitney",
    portContinent: "Europe",
  },
  {
    lat: "51.803514",
    lng: "-3.689440899999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYNY",
    portName: "Ynyswen",
    portContinent: "Europe",
  },
  {
    lat: "54.01638999999999",
    lng: "-1.0621415",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYOH",
    portName: "Haxby",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBYON",
    portName: "Huntington",
    portContinent: "Europe",
  },
  {
    lat: "50.943817",
    lng: "0.9678739999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYOS",
    portName: "LyddonSea",
    portContinent: "Europe",
  },
  {
    lat: "40.245664",
    lng: "-74.8459972",
    country: "US",
    country_long: "United States",
    portCode: "GBYRD",
    portName: "Yardley",
    portContinent: "Europe",
  },
  {
    lat: "53.9614205",
    lng: "-1.0739108",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYRK",
    portName: "York",
    portContinent: "Europe",
  },
  {
    lat: "54.504523",
    lng: "-1.354759",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYRM",
    portName: "Yarm",
    portContinent: "Europe",
  },
  {
    lat: "52.917091",
    lng: "-2.2210431",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYRO",
    portName: "Swynnerton",
    portContinent: "Europe",
  },
  {
    lat: "51.488889",
    lng: "-3.424781",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYSD",
    portName: "Ystradowen",
    portContinent: "Europe",
  },
  {
    lat: "54.90441509999999",
    lng: "-1.4472095",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYSH",
    portName: "SouthHylton",
    portContinent: "Europe",
  },
  {
    lat: "51.779621",
    lng: "-3.753603",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYST",
    portName: "Ystradgynlais",
    portContinent: "Europe",
  },
  {
    lat: "51.3426285",
    lng: "-0.8262729",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYTE",
    portName: "Yateley",
    portContinent: "Europe",
  },
  {
    lat: "55.126957",
    lng: "-1.510277",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYTH",
    portName: "Blyth",
    portContinent: "Europe",
  },
  {
    lat: "51.388168",
    lng: "-2.822503",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYTN",
    portName: "Yatton",
    portContinent: "Europe",
  },
  {
    lat: "51.3426285",
    lng: "-0.8262729",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYTY",
    portName: "Yateley, Hampshire",
    portContinent: "Europe",
  },
  {
    lat: "52.540698",
    lng: "-2.803484",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYUR",
    portName: "Church Stretton",
    portContinent: "Europe",
  },
  {
    lat: "52.374505",
    lng: "-1.1240013",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYVT",
    portName: "Yelvertoft",
    portContinent: "Europe",
  },
  {
    lat: "51.74388099999999",
    lng: "-2.279028",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYVZ",
    portName: "Stonehouse",
    portContinent: "Europe",
  },
  {
    lat: "52.7679287",
    lng: "-1.7910391",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYXA",
    portName: "Yoxall",
    portContinent: "Europe",
  },
  {
    lat: "52.544158",
    lng: "-2.0889858",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBYXL",
    portName: "Coseley",
    portContinent: "Europe",
  },
  {
    lat: "54.42792",
    lng: "-7.13232",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAA",
    portName: "Augher",
    portContinent: "Europe",
  },
  {
    lat: "37.8715226",
    lng: "-122.273042",
    country: "US",
    country_long: "United States",
    portCode: "GBZAB",
    portName: "Berkley",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBZAC",
    portName: "Birchwood",
    portContinent: "Europe",
  },
  {
    lat: "51.369813",
    lng: "-0.488641",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAD",
    portName: "Addlestone, Surrey",
    portContinent: "Europe",
  },
  {
    lat: "51.4492749",
    lng: "-1.1309708",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAE",
    portName: "Bradfield",
    portContinent: "Europe",
  },
  {
    lat: "51.65642219999999",
    lng: "-0.0272713",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAF",
    portName: "Brimsdown",
    portContinent: "Europe",
  },
  {
    lat: "57.1785036",
    lng: "-2.176764",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAH",
    portName: "Bucksburn",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBZAI",
    portName: "Bugle",
    portContinent: "Europe",
  },
  {
    lat: "53.391801",
    lng: "-0.4961359999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAJ",
    portName: "Caenby",
    portContinent: "Europe",
  },
  {
    lat: "51.5189494",
    lng: "0.0132",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAK",
    portName: "CanningTown",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBZAKÂ",
    portName: "Canning",
    portContinent: "Europe",
  },
  {
    lat: "44.3502163",
    lng: "-79.0386639",
    country: "CA",
    country_long: "Canada",
    portCode: "GBZAL",
    portName: "Cannington",
    portContinent: "Europe",
  },
  {
    lat: "-41.0232115",
    lng: "175.5291391",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "GBZAM",
    portName: "Carterton",
    portContinent: "Europe",
  },
  {
    lat: "51.701181",
    lng: "-0.908629",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAN",
    portName: "Chinnor",
    portContinent: "Europe",
  },
  {
    lat: "54.093356",
    lng: "-2.6674041",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAO",
    portName: "Claughton",
    portContinent: "Europe",
  },
  {
    lat: "35.650711",
    lng: "-78.4563914",
    country: "US",
    country_long: "United States",
    portCode: "GBZAP",
    portName: "Clayton",
    portContinent: "Europe",
  },
  {
    lat: "52.499599",
    lng: "-1.706495",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAQ",
    portName: "Coleshill",
    portContinent: "Europe",
  },
  {
    lat: "52.419277",
    lng: "-2.234163",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAR",
    portName: "Cookley",
    portContinent: "Europe",
  },
  {
    lat: "44.05647769999999",
    lng: "-71.11012389999999",
    country: "US",
    country_long: "United States",
    portCode: "GBZAS",
    portName: "Cranmore",
    portContinent: "Europe",
  },
  {
    lat: "52.347591",
    lng: "-1.136643",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAT",
    portName: "Crick",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBZAU",
    portName: "Crook",
    portContinent: "Europe",
  },
  {
    lat: "55.96113",
    lng: "-4.039556",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAV",
    portName: "Croy",
    portContinent: "Europe",
  },
  {
    lat: "56.0551806",
    lng: "-3.6298127",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZAX",
    portName: "Culross",
    portContinent: "Europe",
  },
  {
    lat: "34.7698021",
    lng: "-84.9702228",
    country: "US",
    country_long: "United States",
    portCode: "GBZAY",
    portName: "Dalton",
    portContinent: "Europe",
  },
  {
    lat: "31.1268636",
    lng: "-97.77616580000002",
    country: "US",
    country_long: "United States",
    portCode: "GBZBA",
    portName: "Darnall",
    portContinent: "Europe",
  },
  {
    lat: "52.274275",
    lng: "1.547176",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBB",
    portName: "Darsham",
    portContinent: "Europe",
  },
  {
    lat: "55.609843",
    lng: "-4.286513",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBC",
    portName: "Darvel",
    portContinent: "Europe",
  },
  {
    lat: "55.533791",
    lng: "-3.887129",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBD",
    portName: "Glespin",
    portContinent: "Europe",
  },
  {
    lat: "52.720415",
    lng: "-0.628297",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBE",
    portName: "Greetham",
    portContinent: "Europe",
  },
  {
    lat: "56.036342",
    lng: "-2.827051",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBF",
    portName: "Gullane",
    portContinent: "Europe",
  },
  {
    lat: "51.77334",
    lng: "-0.9259649999999998",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBG",
    portName: "Haddenham",
    portContinent: "Europe",
  },
  {
    lat: "51.5066369",
    lng: "-0.3383229",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBH",
    portName: "Hanwell",
    portContinent: "Europe",
  },
  {
    lat: "51.6052581",
    lng: "-0.3380395",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBI",
    portName: "HarrowWeald",
    portContinent: "Europe",
  },
  {
    lat: "51.775127",
    lng: "0.5930099999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBJ",
    portName: "HatfieldPeverel",
    portContinent: "Europe",
  },
  {
    lat: "53.97572899999999",
    lng: "-1.2010701",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBK",
    portName: "Hessay",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBZBL",
    portName: "Hilton",
    portContinent: "Europe",
  },
  {
    lat: "45.01728110000001",
    lng: "-93.20685",
    country: "US",
    country_long: "United States",
    portCode: "GBZBM",
    portName: "Horton",
    portContinent: "Europe",
  },
  {
    lat: "52.11791479999999",
    lng: "-0.4967915000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBN",
    portName: "Kempston",
    portContinent: "Europe",
  },
  {
    lat: "51.742506",
    lng: "-1.633169",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBO",
    portName: "Kencot",
    portContinent: "Europe",
  },
  {
    lat: "52.272541",
    lng: "0.504041",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBP",
    portName: "Kentford",
    portContinent: "Europe",
  },
  {
    lat: "51.689428",
    lng: "-0.9289000000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBQ",
    portName: "KingstonBlount",
    portContinent: "Europe",
  },
  {
    lat: "55.886883",
    lng: "-3.425237",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBR",
    portName: "Kirknewton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBZBT",
    portName: "Lauder",
    portContinent: "Europe",
  },
  {
    lat: "53.058598",
    lng: "-0.5819468999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBU",
    portName: "Leadenham",
    portContinent: "Europe",
  },
  {
    lat: "42.0531634",
    lng: "-82.5998874",
    country: "CA",
    country_long: "Canada",
    portCode: "GBZBV",
    portName: "Leamington",
    portContinent: "Europe",
  },
  {
    lat: "53.8007554",
    lng: "-1.5490774",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBW",
    portName: "Leeds",
    portContinent: "Europe",
  },
  {
    lat: "52.72121",
    lng: "1.1150884",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBX",
    portName: "Lenwade",
    portContinent: "Europe",
  },
  {
    lat: "54.75537300000001",
    lng: "-6.6079805",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZBY",
    portName: "Magherfelt",
    portContinent: "Europe",
  },
  {
    lat: "52.1280537",
    lng: "-2.3169075",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCA",
    portName: "MalvernLink",
    portContinent: "Europe",
  },
  {
    lat: "54.47084210000001",
    lng: "-1.6951769",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCB",
    portName: "Melsonby",
    portContinent: "Europe",
  },
  {
    lat: "55.91219299999999",
    lng: "-4.092665",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCC",
    portName: "Moodiesburn",
    portContinent: "Europe",
  },
  {
    lat: "49.192329",
    lng: "-98.0977324",
    country: "CA",
    country_long: "Canada",
    portCode: "GBZCE",
    portName: "Morden",
    portContinent: "Europe",
  },
  {
    lat: "55.8195463",
    lng: "-4.0076506",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCG",
    portName: "Mossend",
    portContinent: "Europe",
  },
  {
    lat: "34.4812062",
    lng: "-87.29335329999999",
    country: "US",
    country_long: "United States",
    portCode: "GBZCH",
    portName: "Moulton",
    portContinent: "Europe",
  },
  {
    lat: "51.40066299999999",
    lng: "-0.259263",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCI",
    portName: "NewMaldon",
    portContinent: "Europe",
  },
  {
    lat: "54.55124",
    lng: "-6.7405",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCJ",
    portName: "NewMills",
    portContinent: "Europe",
  },
  {
    lat: "50.9773532",
    lng: "-1.4461376",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCK",
    portName: "NorthBaddesley",
    portContinent: "Europe",
  },
  {
    lat: "53.192817",
    lng: "-0.7259639000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCL",
    portName: "NorthScarle",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBZCM",
    portName: "Norton",
    portContinent: "Europe",
  },
  {
    lat: "42.19439089999999",
    lng: "-71.19896949999999",
    country: "US",
    country_long: "United States",
    portCode: "GBZCN",
    portName: "Norwood",
    portContinent: "Europe",
  },
  {
    lat: "53.193366",
    lng: "0.1094009",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCO",
    portName: "Partney",
    portContinent: "Europe",
  },
  {
    lat: "57.09756900000001",
    lng: "-2.265216",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCP",
    portName: "Peterculter",
    portContinent: "Europe",
  },
  {
    lat: "41.7827545",
    lng: "-88.4236367",
    country: "US",
    country_long: "United States",
    portCode: "GBZCR",
    portName: "Prestbury",
    portContinent: "Europe",
  },
  {
    lat: "51.48664600000001",
    lng: "-2.436695",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCS",
    portName: "Pucklechurch",
    portContinent: "Europe",
  },
  {
    lat: "51.77296",
    lng: "0.03961",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCT",
    portName: "Roydon",
    portContinent: "Europe",
  },
  {
    lat: "34.2870503",
    lng: "-83.11014829999999",
    country: "US",
    country_long: "United States",
    portCode: "GBZCU",
    portName: "Royston",
    portContinent: "Europe",
  },
  {
    lat: "56.200677",
    lng: "-3.310495",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCV",
    portName: "Scotlandwell",
    portContinent: "Europe",
  },
  {
    lat: "52.4408809",
    lng: "-1.9385408",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCW",
    portName: "SellyOak",
    portContinent: "Europe",
  },
  {
    lat: "53.036938",
    lng: "0.013961",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZCX",
    portName: "Sibsey",
    portContinent: "Europe",
  },
  {
    lat: "50.783565",
    lng: "-1.085559",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZDA",
    portName: "Southsea",
    portContinent: "Europe",
  },
  {
    lat: "53.808028",
    lng: "-1.6587396",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZDB",
    portName: "Stanningley",
    portContinent: "Europe",
  },
  {
    lat: "52.907932",
    lng: "-2.144045",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZDC",
    portName: "Stone",
    portContinent: "Europe",
  },
  {
    lat: "51.111281",
    lng: "-0.6281829999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZDD",
    portName: "Chiddingfold",
    portContinent: "Europe",
  },
  {
    lat: "55.675627",
    lng: "-4.064323000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZDE",
    portName: "Strathaven",
    portContinent: "Europe",
  },
  {
    lat: "53.8705252",
    lng: "-0.9495869000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZDF",
    portName: "Thorganby",
    portContinent: "Europe",
  },
  {
    lat: "53.8705252",
    lng: "-0.9495869000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZDG",
    portName: "Thorganby",
    portContinent: "Europe",
  },
  {
    lat: "53.616189",
    lng: "-2.342527",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZDH",
    portName: "Tottington",
    portContinent: "Europe",
  },
  {
    lat: "35.2014631",
    lng: "-85.5191323",
    country: "US",
    country_long: "United States",
    portCode: "GBZDI",
    portName: "Whitwell",
    portContinent: "Europe",
  },
  {
    lat: "41.8809434",
    lng: "-72.2600655",
    country: "US",
    country_long: "United States",
    portCode: "GBZDJ",
    portName: "Willington",
    portContinent: "Europe",
  },
  {
    lat: "52.0807965",
    lng: "-0.4486715000000001",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZDK",
    portName: "Wilstead",
    portContinent: "Europe",
  },
  {
    lat: "53.19426199999999",
    lng: "-2.51967",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZDL",
    portName: "Winsford",
    portContinent: "Europe",
  },
  {
    lat: "39.0768275",
    lng: "-77.183204",
    country: "US",
    country_long: "United States",
    portCode: "GBZDM",
    portName: "Wootton",
    portContinent: "Europe",
  },
  {
    lat: "52.704308",
    lng: "1.4073119",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZDN",
    portName: "Wroxham",
    portContinent: "Europe",
  },
  {
    lat: "51.804213",
    lng: "-1.310194",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZDO",
    portName: "Yarnton",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GBZDP",
    portName: "Yaxley",
    portContinent: "Europe",
  },
  {
    lat: "55.8504121",
    lng: "-3.1991562",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZDQ",
    portName: "MiltonBridge",
    portContinent: "Europe",
  },
  {
    lat: "42.4731744",
    lng: "-96.3584236",
    country: "US",
    country_long: "United States",
    portCode: "GBZEF",
    portName: "Morningside",
    portContinent: "Europe",
  },
  {
    lat: "34.4812062",
    lng: "-87.29335329999999",
    country: "US",
    country_long: "United States",
    portCode: "GBZGH",
    portName: "Moulton",
    portContinent: "Europe",
  },
  {
    lat: "54.85326999999999",
    lng: "-6.36604",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZHG",
    portName: "Ahoghill",
    portContinent: "Europe",
  },
  {
    lat: "56.5894057",
    lng: "-3.3378318",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZLD",
    portName: "Blairgowrie",
    portContinent: "Europe",
  },
  {
    lat: "-34.3693539",
    lng: "-58.86974060000001",
    country: "AR",
    country_long: "Argentina",
    portCode: "GBZLH",
    portName: "ZelahÂ",
    portContinent: "Europe",
  },
  {
    lat: "51.084021",
    lng: "-2.315551",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZLS",
    portName: "Zeals",
    portContinent: "Europe",
  },
  {
    lat: "55.818195",
    lng: "-6.160956",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZLY",
    portName: "Ballygrant",
    portContinent: "Europe",
  },
  {
    lat: "44.951598",
    lng: "-64.160574",
    country: "CA",
    country_long: "Canada",
    portCode: "GBZOR",
    portName: "Martock",
    portContinent: "Europe",
  },
  {
    lat: "51.1096999",
    lng: "-2.9309949",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZOY",
    portName: "Westonzoyland",
    portContinent: "Europe",
  },
  {
    lat: "52.4597635",
    lng: "-1.8712972",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZPB",
    portName: "Sparkbrook",
    portContinent: "Europe",
  },
  {
    lat: "55.8713417",
    lng: "-3.13071",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZPO",
    portName: "Polton",
    portContinent: "Europe",
  },
  {
    lat: "56.9702539",
    lng: "-5.7560159",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZRC",
    portName: "Bracora",
    portContinent: "Europe",
  },
  {
    lat: "56.895695",
    lng: "-4.915134",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZSB",
    portName: "SpeanBridge",
    portContinent: "Europe",
  },
  {
    lat: "50.948076",
    lng: "-2.810525",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZSP",
    portName: "South Petherton, Somerset",
    portContinent: "Europe",
  },
  {
    lat: "54.1956601",
    lng: "-6.394229999999999",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZSS",
    portName: "Bessbrook",
    portContinent: "Europe",
  },
  {
    lat: "54.7097899",
    lng: "-7.5936714",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZST",
    portName: "Castlederg",
    portContinent: "Europe",
  },
  {
    lat: "56.768184",
    lng: "-3.843385",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZTH",
    portName: "BlairAtholl",
    portContinent: "Europe",
  },
  {
    lat: "53.91167",
    lng: "-0.304005",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZTO",
    portName: "Brandesburton",
    portContinent: "Europe",
  },
  {
    lat: "52.94023199999999",
    lng: "-0.802883",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZTT",
    portName: "Bottesford",
    portContinent: "Europe",
  },
  {
    lat: "57.47491100000001",
    lng: "-3.216983",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZWN",
    portName: "CharlestownofAberlour",
    portContinent: "Europe",
  },
  {
    lat: "53.40725500000001",
    lng: "-1.375539",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "GBZWT",
    portName: "Brinsworth",
    portContinent: "Europe",
  },
  {
    lat: "12.1165",
    lng: "-61.67899999999999",
    country: "GD",
    country_long: "Grenada",
    portCode: "GDGRE",
    portName: "Grenville (GDGRE), Grenada, LatAm",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GDSTG",
    portName: "Port of Saint George's",
    portContinent: "North America",
  },
  {
    lat: "51.55599489999999",
    lng: "12.2087874",
    country: "DE",
    country_long: "Germany",
    portCode: "GEBRH",
    portName: "Brehna  (GEBRH), Germany, Europe",
    portContinent: "Europe",
  },
  {
    lat: "41.6465381",
    lng: "41.6504856",
    country: "GE",
    country_long: "Georgia",
    portCode: "GEBUS",
    portName: "Port of Batumi",
    portContinent: "Europe",
  },
  {
    lat: "42.2754843",
    lng: "41.6734034",
    country: "GE",
    country_long: "Georgia",
    portCode: "GEKUL",
    portName: "Kulevi (GEKUL), Georgia, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.2662428",
    lng: "42.7180019",
    country: "GE",
    country_long: "Georgia",
    portCode: "GEKUT",
    portName: "KUTAISI",
    portContinent: "Europe",
  },
  {
    lat: "42.1543527",
    lng: "41.6650915",
    country: "GE",
    country_long: "Georgia",
    portCode: "GEPTI",
    portName: "Port of Poti",
    portContinent: "Europe",
  },
  {
    lat: "43.0015252",
    lng: "41.0234153",
    country: "N/A",
    country_long: "N/A",
    portCode: "GESUI",
    portName: "Port of Sukhumi",
    portContinent: "Europe",
  },
  {
    lat: "42.0438901",
    lng: "41.8051552",
    country: "GE",
    country_long: "Georgia",
    portCode: "GESUP",
    portName: "Supsa (GESUP), Georgia, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.7151377",
    lng: "44.827096",
    country: "GE",
    country_long: "Georgia",
    portCode: "GETBS",
    portName: "Tbilisi",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GFCAY",
    portName: "Port of Cayenne",
    portContinent: "South America",
  },
  {
    lat: "4.856104999999999",
    lng: "-52.28007909999999",
    country: "GF",
    country_long: "French Guiana",
    portCode: "GFDDC",
    portName: "Degrad des Cannes (GFDDC), French Guiana, car",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GFLVT",
    portName: "Port of Le Larivot",
    portContinent: "South America",
  },
  {
    lat: "5.1611312",
    lng: "-52.6493342",
    country: "GF",
    country_long: "French Guiana",
    portCode: "GFQKR",
    portName: "Port of Kourou",
    portContinent: "South America",
  },
  {
    lat: "5.483880399999999",
    lng: "-54.0248906",
    country: "GF",
    country_long: "French Guiana",
    portCode: "GFSLM",
    portName: "Port of St Laurent du Maroni",
    portContinent: "South America",
  },
  {
    lat: "5.6037168",
    lng: "-0.1869644",
    country: "GH",
    country_long: "Ghana",
    portCode: "GHACC",
    portName: "Accra (GHACC), Ghana, Africa",
    portContinent: "Africa",
  },
  {
    lat: "6.2668028",
    lng: "0.0443217",
    country: "GH",
    country_long: "Ghana",
    portCode: "GHASB",
    portName: "Akosombo",
    portContinent: "Africa",
  },
  {
    lat: "6.6666004",
    lng: "-1.6162709",
    country: "GH",
    country_long: "Ghana",
    portCode: "GHKMS",
    portName: "Kumasi",
    portContinent: "Africa",
  },
  {
    lat: "6.0784427",
    lng: "-0.2713944",
    country: "GH",
    country_long: "Ghana",
    portCode: "GHKOF",
    portName: "Koforidua",
    portContinent: "Africa",
  },
  {
    lat: "6.2012073",
    lng: "-1.6912512",
    country: "GH",
    country_long: "Ghana",
    portCode: "GHOBU",
    portName: "OBUASI",
    portContinent: "Africa",
  },
  {
    lat: "5.437319899999999",
    lng: "-2.1401139",
    country: "GH",
    country_long: "Ghana",
    portCode: "GHPRT",
    portName: "Prestea",
    portContinent: "Africa",
  },
  {
    lat: "4.9015794",
    lng: "-1.7830973",
    country: "GH",
    country_long: "Ghana",
    portCode: "GHSEK",
    portName: "Sekondi Takoradi (GHSEK), Ghana, Africa",
    portContinent: "Africa",
  },
  {
    lat: "5.2023106",
    lng: "-1.0514312",
    country: "GH",
    country_long: "Ghana",
    portCode: "GHSPD",
    portName: "Port of Saltpond",
    portContinent: "Africa",
  },
  {
    lat: "5.633333299999999",
    lng: "0.0166667",
    country: "GH",
    country_long: "Ghana",
    portCode: "GHTEM",
    portName: "Port of Tema",
    portContinent: "Africa",
  },
  {
    lat: "4.884835900471604",
    lng: "-1.740498573571108",
    country: "GH",
    country_long: "Ghana",
    portCode: "GHTKD",
    portName: "Port of Takoradi",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GHTML",
    portName: "TAMALE",
    portContinent: "Africa",
  },
  {
    lat: "5.301832",
    lng: "-1.9930466",
    country: "GH",
    country_long: "Ghana",
    portCode: "GHTWA",
    portName: "TARKWA",
    portContinent: "Africa",
  },
  {
    lat: "36.1333333",
    lng: "-5.35",
    country: "GI",
    country_long: "Gibraltar",
    portCode: "GIGIB",
    portName: "Port of Gibraltar",
    portContinent: "Europe",
  },
  {
    lat: "65.6134561",
    lng: "-37.6335696",
    country: "GL",
    country_long: "Greenland",
    portCode: "GLAGM",
    portName: "Tasiilaq",
    portContinent: "North America",
  },
  {
    lat: "64.18140989999999",
    lng: "-51.694138",
    country: "GL",
    country_long: "Greenland",
    portCode: "GLGOH",
    portName: "Nuuk (Godthaab)",
    portContinent: "North America",
  },
  {
    lat: "68.70697659999999",
    lng: "-52.85912219999999",
    country: "GL",
    country_long: "Greenland",
    portCode: "GLJEG",
    portName: "Aasiaat (Egedesminde)",
    portContinent: "North America",
  },
  {
    lat: "61.99411509999999",
    lng: "-49.6665709",
    country: "GL",
    country_long: "Greenland",
    portCode: "GLJFR",
    portName: "Paamiut (Fredrikshaab)",
    portContinent: "North America",
  },
  {
    lat: "66.9394722",
    lng: "-53.6733857",
    country: "GL",
    country_long: "Greenland",
    portCode: "GLJHS",
    portName: "Sisimiut (Holsteinsborg)",
    portContinent: "North America",
  },
  {
    lat: "60.71898640000001",
    lng: "-46.03534579999999",
    country: "GL",
    country_long: "Greenland",
    portCode: "GLJJU",
    portName: "Qaqortoq (Julianehaab)",
    portContinent: "North America",
  },
  {
    lat: "60.1424955",
    lng: "-45.239495",
    country: "GL",
    country_long: "Greenland",
    portCode: "GLJNN",
    portName: "Nanortalik",
    portContinent: "North America",
  },
  {
    lat: "60.9129622",
    lng: "-46.050483",
    country: "GL",
    country_long: "Greenland",
    portCode: "GLJNS",
    portName: "Narsaq",
    portContinent: "North America",
  },
  {
    lat: "13.4446884",
    lng: "-16.5714357",
    country: "GM",
    country_long: "The Gambia",
    portCode: "GMBJL",
    portName: "Port of Banjul",
    portContinent: "Africa",
  },
  {
    lat: "9.641185499999999",
    lng: "-13.5784012",
    country: "GN",
    country_long: "Guinea",
    portCode: "GNCKA",
    portName: "Conakry Eco",
    portContinent: "Africa",
  },
  {
    lat: "9.5145863",
    lng: "-13.7188085",
    country: "GN",
    country_long: "Guinea",
    portCode: "GNCKY",
    portName: "Port of Conakry",
    portContinent: "Africa",
  },
  {
    lat: "10.6637208",
    lng: "-14.5844395",
    country: "GN",
    country_long: "Guinea",
    portCode: "GNKMR",
    portName: "Kamsar (GNKMR), Guinea, Africa",
    portContinent: "Africa",
  },
  {
    lat: "15.9965719",
    lng: "-61.73172150000001",
    country: "GP",
    country_long: "Guadeloupe",
    portCode: "GPBBR",
    portName: "Port of Basse-Terre",
    portContinent: "North America",
  },
  {
    lat: "17.8964345",
    lng: "-62.85220099999999",
    country: "BL",
    country_long: "Saint Barthélemy",
    portCode: "GPGUS",
    portName: "Port of Gustavia",
    portContinent: "North America",
  },
  {
    lat: "18.0675189",
    lng: "-63.0824656",
    country: "MF",
    country_long: "Saint Martin",
    portCode: "GPMSB",
    portName: "MARIGOT",
    portContinent: "North America",
  },
  {
    lat: "16.2370504",
    lng: "-61.53762719999999",
    country: "GP",
    country_long: "Guadeloupe",
    portCode: "GPPTP",
    portName: "Pointe-a-Pitre (GPPTP), Guadeloupe, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.9",
    lng: "-62.833333",
    country: "BL",
    country_long: "Saint Barthélemy",
    portCode: "GPSBH",
    portName: "Saint Barthelemy",
    portContinent: "North America",
  },
  {
    lat: "-1.4268782",
    lng: "5.6352801",
    country: "GQ",
    country_long: "Equatorial Guinea",
    portCode: "GQABU",
    portName: "Annobon (GQABU), Equatorial Guinea, Africa",
    portContinent: "Africa",
  },
  {
    lat: "1.650801",
    lng: "10.267895",
    country: "GQ",
    country_long: "Equatorial Guinea",
    portCode: "GQATE",
    portName: "Aseng Fpso (GQATE), Equatorial Guinea, Africa",
    portContinent: "Africa",
  },
  {
    lat: "1.8533177",
    lng: "9.779022699999999",
    country: "GQ",
    country_long: "Equatorial Guinea",
    portCode: "GQBSG",
    portName: "Bata",
    portContinent: "Africa",
  },
  {
    lat: "17.3141801",
    lng: "120.6890055",
    country: "PH",
    country_long: "Philippines",
    portCode: "GQLUB",
    portName: "Luba",
    portContinent: "Africa",
  },
  {
    lat: "3.7657651",
    lng: "8.7095789",
    country: "GQ",
    country_long: "Equatorial Guinea",
    portCode: "GQPET",
    portName: "Punta Europa Termina (GQPET), Equatorial Guinea, Africa",
    portContinent: "Africa",
  },
  {
    lat: "3.7576371",
    lng: "8.780825799999999",
    country: "GQ",
    country_long: "Equatorial Guinea",
    portCode: "GQSSG",
    portName: "Port of Malabo",
    portContinent: "Africa",
  },
  {
    lat: "38.8876001",
    lng: "22.8082055",
    country: "GR",
    country_long: "Greece",
    portCode: "GRACL",
    portName: "Port of Achladi",
    portContinent: "Europe",
  },
  {
    lat: "37.7408815",
    lng: "23.5014213",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAEG",
    portName: "Aegina (GRAEG), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.2505451",
    lng: "22.0810942",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAEN",
    portName: "Aigio (GRAEN), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.75",
    lng: "19.683333",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAGE",
    portName: "Agios Georgios (GRAGE), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.1899597",
    lng: "25.7164166",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAGF",
    portName: "Agios Nikolaos (GRAGF), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.7093576",
    lng: "23.3466705",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAGG",
    portName: "Agistri (GRAGG), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.4145",
    lng: "22.5243",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAGK",
    portName: "Agiokampos Larisas (GRAGK), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.7438552",
    lng: "23.537821",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAGM",
    portName: "Port of Aghia Marina",
    portContinent: "Europe",
  },
  {
    lat: "37.4577997",
    lng: "26.972125",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAGN",
    portName: "Agathonisi (GRAGN), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.5328915",
    lng: "25.0121584",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAGO",
    portName: "Agios Efstratios (GRAGO), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.9282447",
    lng: "23.1433311",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAGT",
    portName: "Port of Agioi Theodoroi",
    portContinent: "Europe",
  },
  {
    lat: "38.8792503",
    lng: "23.0461387",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAID",
    portName: "Aidipsos (GRAID), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.9064014",
    lng: "25.9974176",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAIG",
    portName: "Aigiali (GRAIG), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.6162112",
    lng: "26.2954532",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAKI",
    portName: "Agios Kirykos (GRAKI), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.5488889",
    lng: "26.4044444",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAKM",
    portName: "Agia Kyriaki (GRAKM), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.1968615",
    lng: "23.8941657",
    country: "GR",
    country_long: "Greece",
    portCode: "GRALO",
    portName: "Alonnisos (GRALO), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.6279023",
    lng: "22.444571",
    country: "GR",
    country_long: "Greece",
    portCode: "GRALX",
    portName: "Alexandreia (GRALX), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.8632567",
    lng: "21.1666182",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAMF",
    portName: "Port of Amfilochia",
    portContinent: "Europe",
  },
  {
    lat: "40.3234145",
    lng: "23.9149848",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAMI",
    portName: "Ammouliani (GRAMI), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.363178",
    lng: "25.7693535",
    country: "GR",
    country_long: "Greece",
    portCode: "GRANA",
    portName: "Anafi (GRANA), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GRANI",
    portName: "Port of Ayios Nikolaos",
    portContinent: "Europe",
  },
  {
    lat: "39.0839234",
    lng: "23.7122186",
    country: "GR",
    country_long: "Greece",
    portCode: "GRANL",
    portName: "Agnontas (GRANL), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.1674403",
    lng: "22.8888347",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAPL",
    portName: "Amaliapolis (GRAPL), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.74299999999999",
    lng: "23.033092",
    country: "GR",
    country_long: "Greece",
    portCode: "GRARK",
    portName: "Arkitsa (GRARK), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.1731682",
    lng: "20.4899734",
    country: "GR",
    country_long: "Greece",
    portCode: "GRARM",
    portName: "Port of Argostoli",
    portContinent: "Europe",
  },
  {
    lat: "38.0609063",
    lng: "23.5910603",
    country: "GR",
    country_long: "Greece",
    portCode: "GRASS",
    portName: "Port of Aspropyrgos",
    portContinent: "Europe",
  },
  {
    lat: "38.5357192",
    lng: "21.0820678",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAST",
    portName: "Port of Astakos",
    portContinent: "Europe",
  },
  {
    lat: "37.9838096",
    lng: "23.7275388",
    country: "GR",
    country_long: "Greece",
    portCode: "GRATH",
    portName: "ATHENS",
    portContinent: "Europe",
  },
  {
    lat: "35.1763598",
    lng: "25.8948954",
    country: "GR",
    country_long: "Greece",
    portCode: "GRATS",
    portName: "Altsi (GRATS), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "29.9533869",
    lng: "-90.0657171",
    country: "US",
    country_long: "United States",
    portCode: "GRATT",
    portName: "Attiki",
    portContinent: "Europe",
  },
  {
    lat: "40.8461617",
    lng: "25.8845952",
    country: "GR",
    country_long: "Greece",
    portCode: "GRAXD",
    portName: "Port of Alexandroupolis",
    portContinent: "Europe",
  },
  {
    lat: "39.6290665",
    lng: "19.901143",
    country: "GR",
    country_long: "Greece",
    portCode: "GRCFU",
    portName: "Port of Corfu",
    portContinent: "Europe",
  },
  {
    lat: "38.4686919",
    lng: "23.6228734",
    country: "GR",
    country_long: "Greece",
    portCode: "GRCLK",
    portName: "Port of Chalkis",
    portContinent: "Europe",
  },
  {
    lat: "37.9498129",
    lng: "23.734784",
    country: "GR",
    country_long: "Greece",
    portCode: "GRDAF",
    portName: "Dafni (GRDAF), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.1037794",
    lng: "25.8107681",
    country: "GR",
    country_long: "Greece",
    portCode: "GRDON",
    portName: "Donousa (GRDON), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.3222338",
    lng: "21.8531095",
    country: "GR",
    country_long: "Greece",
    portCode: "GRDRE",
    portName: "Drepano (GRDRE), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.0412852",
    lng: "23.5417553",
    country: "GR",
    country_long: "Greece",
    portCode: "GRELE",
    portName: "Elefsis (GRELE), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.8453499",
    lng: "24.2520267",
    country: "GR",
    country_long: "Greece",
    portCode: "GRELT",
    portName: "Eleftheres (GRELT), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.5958432",
    lng: "23.0768899",
    country: "GR",
    country_long: "Greece",
    portCode: "GREPI",
    portName: "Epidavros (GREPI), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.3927202",
    lng: "23.7930669",
    country: "GR",
    country_long: "Greece",
    portCode: "GRERE",
    portName: "Eretria (GRERE), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.6315616",
    lng: "26.1777245",
    country: "GR",
    country_long: "Greece",
    portCode: "GREYD",
    portName: "Evdilos (GREYD), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.0731747",
    lng: "24.8314581",
    country: "GR",
    country_long: "Greece",
    portCode: "GRFNR",
    portName: "Faneromeni (GRFNR), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "34.846225",
    lng: "24.0844785",
    country: "GR",
    country_long: "Greece",
    portCode: "GRGAD",
    portName: "Gavdos (GRGAD), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.88444580000001",
    lng: "24.7370121",
    country: "GR",
    country_long: "Greece",
    portCode: "GRGAV",
    portName: "Gavrio (GRGAV), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.17563980000001",
    lng: "23.6155786",
    country: "GR",
    country_long: "Greece",
    portCode: "GRGLO",
    portName: "Glossa (GRGLO), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.3772936",
    lng: "22.3832495",
    country: "GR",
    country_long: "Greece",
    portCode: "GRGLX",
    portName: "Galaxidi (GRGLX), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.2271767",
    lng: "21.7212677",
    country: "GR",
    country_long: "Greece",
    portCode: "GRGPA",
    portName: "Port of Patras",
    portContinent: "Europe",
  },
  {
    lat: "36.761028",
    lng: "22.563999",
    country: "GR",
    country_long: "Greece",
    portCode: "GRGYT",
    portName: "Port of Gythion",
    portContinent: "Europe",
  },
  {
    lat: "39.074208",
    lng: "21.824312",
    country: "GR",
    country_long: "Greece",
    portCode: "GRHER",
    portName: "Heraclio (GRHER), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.3387352",
    lng: "25.1442126",
    country: "GR",
    country_long: "Greece",
    portCode: "GRHKL",
    portName: "Heraklion",
    portContinent: "Europe",
  },
  {
    lat: "36.8440887",
    lng: "25.4555858",
    country: "GR",
    country_long: "Greece",
    portCode: "GRHRK",
    portName: "Iraklia (GRHRK), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.3287894",
    lng: "23.4716567",
    country: "GR",
    country_long: "Greece",
    portCode: "GRHYD",
    portName: "Hydra (GRHYD), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.0118955",
    lng: "25.7407452",
    country: "GR",
    country_long: "Greece",
    portCode: "GRIER",
    portName: "Ierapetra (GRIER), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.5061499",
    lng: "20.2655339",
    country: "GR",
    country_long: "Greece",
    portCode: "GRIGO",
    portName: "Port of Igoumenitsa",
    portContinent: "Europe",
  },
  {
    lat: "39.074208",
    lng: "21.824312",
    country: "GR",
    country_long: "Greece",
    portCode: "GRIOS",
    portName: "Ios (GRIOS), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GRITA",
    portName: "Port of Itea",
    portContinent: "Europe",
  },
  {
    lat: "38.4284603",
    lng: "20.6764877",
    country: "GR",
    country_long: "Greece",
    portCode: "GRITH",
    portName: "Ithaki (GRITH), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.37307759999999",
    lng: "26.140002",
    country: "GR",
    country_long: "Greece",
    portCode: "GRJKH",
    portName: "Port of Chios",
    portContinent: "Europe",
  },
  {
    lat: "37.1035665",
    lng: "25.3776734",
    country: "GR",
    country_long: "Greece",
    portCode: "GRJNX",
    portName: "Naxos (GRJNX), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.2086503",
    lng: "26.1052329",
    country: "GR",
    country_long: "Greece",
    portCode: "GRJSH",
    portName: "Sitia (GRJSH), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.16352",
    lng: "23.4901667",
    country: "GR",
    country_long: "Greece",
    portCode: "GRJSI",
    portName: "Skiathos (GRJSI), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.4003185",
    lng: "25.4776476",
    country: "GR",
    country_long: "Greece",
    portCode: "GRJTR",
    portName: "Thira (GRJTR), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.5489003",
    lng: "26.3524437",
    country: "GR",
    country_long: "Greece",
    portCode: "GRJTY",
    portName: "Astypalea (GRJTY), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.6452636",
    lng: "21.3183143",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKAK",
    portName: "Port of Katakolon",
    portContinent: "Europe",
  },
  {
    lat: "37.7920861",
    lng: "26.7049",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKAR",
    portName: "Port of Karlovassi",
    portContinent: "Europe",
  },
  {
    lat: "36.1495227",
    lng: "29.5934415",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKAS",
    portName: "Kastellorizo (GRKAS), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.60758",
    lng: "24.3103718",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKEA",
    portName: "Kea (GRKEA), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.2969687",
    lng: "23.1641217",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKEM",
    portName: "Kosta (GRKEM), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GRKGS",
    portName: "Port of Kos",
    portContinent: "Europe",
  },
  {
    lat: "38.0126545",
    lng: "22.750171",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKIO",
    portName: "Kiato (GRKIO), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.4945873",
    lng: "23.6537459",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKIS",
    portName: "Kissamos (GRKIS), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.074208",
    lng: "21.824312",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKLI",
    portName: "Klima (GRKLI), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "34.9319377",
    lng: "24.8021903",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKLL",
    portName: "Kali Limenes (GRKLL), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GRKLM",
    portName: "Port of Kalamaki",
    portContinent: "Europe",
  },
  {
    lat: "37.0366386",
    lng: "22.1143716",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKLX",
    portName: "Port of Kalamata",
    portContinent: "Europe",
  },
  {
    lat: "36.9533092",
    lng: "26.9798133",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKMI",
    portName: "Port of Kalymnos",
    portContinent: "Europe",
  },
  {
    lat: "36.9322993",
    lng: "25.6012116",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKOF",
    portName: "Koufonisi (GRKOF), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.96795729999999",
    lng: "24.7024179",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKRE",
    portName: "Sifnos (GRKRE), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.943087",
    lng: "22.9321488",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKRT",
    portName: "Port of Korinthos",
    portContinent: "Europe",
  },
  {
    lat: "38.5707337",
    lng: "20.9106559",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKSI",
    portName: "Kastos (GRKSI), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.415985",
    lng: "26.9225419",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKSJ",
    portName: "Kasos (GRKSJ), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.013999",
    lng: "24.4198995",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKST",
    portName: "Karystos (GRKST), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.8265829",
    lng: "25.8634674",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKTP",
    portName: "Katapola (GRKTP), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.937607",
    lng: "24.412866",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKVA",
    portName: "Kavala Central Port Authority",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GRKYM",
    portName: "Port of Kymassi",
    portContinent: "Europe",
  },
  {
    lat: "37.3933984",
    lng: "24.4173321",
    country: "GR",
    country_long: "Greece",
    portCode: "GRKYT",
    portName: "Kythnos (GRKYT), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "47.2374255",
    lng: "-88.4431725",
    country: "US",
    country_long: "United States",
    portCode: "GRLAV",
    portName: "Port of Laurium",
    portContinent: "Europe",
  },
  {
    lat: "36.0918486",
    lng: "28.0856654",
    country: "GR",
    country_long: "Greece",
    portCode: "GRLDR",
    portName: "Lindos (GRLDR), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.3222338",
    lng: "21.8531095",
    country: "GR",
    country_long: "Greece",
    portCode: "GRLEV",
    portName: "Port of Drepano",
    portContinent: "Europe",
  },
  {
    lat: "37.3011088",
    lng: "26.7437518",
    country: "GR",
    country_long: "Greece",
    portCode: "GRLIP",
    portName: "Lipsi (GRLIP), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.2022209",
    lng: "20.4369672",
    country: "GR",
    country_long: "Greece",
    portCode: "GRLIX",
    portName: "Port of Lixouri",
    portContinent: "Europe",
  },
  {
    lat: "37.1409141",
    lng: "26.8488427",
    country: "GR",
    country_long: "Greece",
    portCode: "GRLRS",
    portName: "Port of Leros Island",
    portContinent: "Europe",
  },
  {
    lat: "38.5658197",
    lng: "23.284911",
    country: "GR",
    country_long: "Greece",
    portCode: "GRLRY",
    portName: "Larimna (GRLRY), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.2000045",
    lng: "24.0786653",
    country: "GR",
    country_long: "Greece",
    portCode: "GRLTR",
    portName: "Loutro (GRLTR), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.8743467",
    lng: "25.2690527",
    country: "GR",
    country_long: "Greece",
    portCode: "GRMDR",
    portName: "Moudros (GRMDR), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.3686739",
    lng: "21.430415",
    country: "GR",
    country_long: "Greece",
    portCode: "GRMEL",
    portName: "Port of Mesolongion",
    portContinent: "Europe",
  },
  {
    lat: "38.6585747",
    lng: "20.7588058",
    country: "GR",
    country_long: "Greece",
    portCode: "GRMGN",
    portName: "Spartochori (GRMGN), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GRMGR",
    portName: "Megara Oil Terminal",
    portContinent: "Europe",
  },
  {
    lat: "39.1045777",
    lng: "26.5632097",
    country: "GR",
    country_long: "Greece",
    portCode: "GRMJT",
    portName: "Port of Mitylene",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GRMLO",
    portName: "Port of Adamas",
    portContinent: "Europe",
  },
  {
    lat: "36.8718378",
    lng: "27.1493341",
    country: "GR",
    country_long: "Greece",
    portCode: "GRMRM",
    portName: "Marmari (GRMRM), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.9198413",
    lng: "25.141484",
    country: "GR",
    country_long: "Greece",
    portCode: "GRMYR",
    portName: "Limnos (GRMYR), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.5673173",
    lng: "22.8015531",
    country: "GR",
    country_long: "Greece",
    portCode: "GRNAF",
    portName: "Nafplio (GRNAF), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.3387352",
    lng: "25.1442126",
    country: "GR",
    country_long: "Greece",
    portCode: "GRNAI",
    portName: "Port of Iraklion",
    portContinent: "Europe",
  },
  {
    lat: "38.7099952",
    lng: "20.7141164",
    country: "GR",
    country_long: "Greece",
    portCode: "GRNID",
    portName: "Nydri (GRNID), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.5954188",
    lng: "27.1617685",
    country: "GR",
    country_long: "Greece",
    portCode: "GRNIS",
    portName: "Nisyros (GRNIS), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.3131226",
    lng: "23.0632227",
    country: "GR",
    country_long: "Greece",
    portCode: "GRNKA",
    portName: "Nea Kallikrateia (GRNKA), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.9624179",
    lng: "24.5082696",
    country: "GR",
    country_long: "Greece",
    portCode: "GRNKV",
    portName: "Nea Karvali (GRNKV), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.2409723",
    lng: "23.2865614",
    country: "GR",
    country_long: "Greece",
    portCode: "GRNMA",
    portName: "Nea Moudania (GRNMA), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.5673173",
    lng: "22.8015531",
    country: "GR",
    country_long: "Greece",
    portCode: "GRNPL",
    portName: "Port of Nauplia",
    portContinent: "Europe",
  },
  {
    lat: "38.1795427",
    lng: "24.208438",
    country: "GR",
    country_long: "Greece",
    portCode: "GRNST",
    portName: "Nea Stira (GRNST), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.08539409999999",
    lng: "25.1515337",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPAS",
    portName: "Port of Paros",
    portContinent: "Europe",
  },
  {
    lat: "39.1973712",
    lng: "20.1851948",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPAX",
    portName: "Gaios (GRPAX), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.9656089",
    lng: "23.5677089",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPER",
    portName: "Port of Perama",
    portContinent: "Europe",
  },
  {
    lat: "37.9656089",
    lng: "23.5677089",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPGM",
    portName: "Perama Geras (GRPGM), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.3821236",
    lng: "21.3478068",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPIA",
    portName: "Platania (GRPIA), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.9405556",
    lng: "23.6333333",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPIR",
    portName: "Port of Piraeus",
    portContinent: "Europe",
  },
  {
    lat: "38.3476171",
    lng: "20.6851238",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPIS",
    portName: "Pissaetos (GRPIS), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.074208",
    lng: "21.824312",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPME",
    portName: "Pachi (GRPME), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.323297",
    lng: "26.5452687",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPMS",
    portName: "Port of Patmos",
    portContinent: "Europe",
  },
  {
    lat: "35.8620412",
    lng: "23.3049491",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPOA",
    portName: "Antikithira (GRPOA), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.7408774",
    lng: "24.5772111",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPPI",
    portName: "Prinos (GRPPI), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.4062304",
    lng: "24.1682235",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPRE",
    portName: "Petries (GRPRE), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.9592649",
    lng: "20.7517155",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPVK",
    portName: "Port of Preveza",
    portContinent: "Europe",
  },
  {
    lat: "36.9144592",
    lng: "21.6952592",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPYL",
    portName: "Port of Pylos",
    portContinent: "Europe",
  },
  {
    lat: "37.68994310000001",
    lng: "26.9426018",
    country: "GR",
    country_long: "Greece",
    portCode: "GRPYT",
    portName: "Port of Pythagorio",
    portContinent: "Europe",
  },
  {
    lat: "38.0202437",
    lng: "24.0071775",
    country: "GR",
    country_long: "Greece",
    portCode: "GRRAF",
    portName: "Port of Rafina",
    portContinent: "Europe",
  },
  {
    lat: "35.36555450000001",
    lng: "24.4919838",
    country: "GR",
    country_long: "Greece",
    portCode: "GRRET",
    portName: "Rethymno (GRRET), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.9625",
    lng: "23.4011111",
    country: "GR",
    country_long: "Greece",
    portCode: "GRREV",
    portName: "Revithoussa (GRREV), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.4340533",
    lng: "28.2176379",
    country: "GR",
    country_long: "Greece",
    portCode: "GRRHO",
    portName: "Port of Rhodes",
    portContinent: "Europe",
  },
  {
    lat: "39.074208",
    lng: "21.824312",
    country: "GR",
    country_long: "Greece",
    portCode: "GRRIO",
    portName: "Rio Gr (GRRIO), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.230268",
    lng: "23.9606578",
    country: "GR",
    country_long: "Greece",
    portCode: "GRROU",
    portName: "Agia Roumeli (GRROU), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.9290786",
    lng: "23.5133267",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSAL",
    portName: "Salamina (GRSAL), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.4476834",
    lng: "25.5917918",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSAM",
    portName: "Samothraki (GRSAM), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.4874818",
    lng: "24.0742105",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSDH",
    portName: "Souda (GRSDH), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GRSER",
    portName: "Port of Seriphos",
    portContinent: "Europe",
  },
  {
    lat: "39.074208",
    lng: "21.824312",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSHA",
    portName: "Stavros (GRSHA), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.87004",
    lng: "25.5181245",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSHC",
    portName: "Schinousa (GRSHC), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.6759691",
    lng: "25.1059054",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSII",
    portName: "Sikinos (GRSII), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.0113874",
    lng: "23.6027231",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSKA",
    portName: "Skaramagas (GRSKA), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.63485439999999",
    lng: "22.9230442",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSKG",
    portName: "Port of Thessaloniki",
    portContinent: "Europe",
  },
  {
    lat: "39.1251493",
    lng: "23.6799766",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSKO",
    portName: "Skopelos (GRSKO), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.8162965",
    lng: "24.5955356",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSKU",
    portName: "Skyros (GRSKU), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.7547857",
    lng: "26.9777701",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSMI",
    portName: "Port of Samos",
    portContinent: "Europe",
  },
  {
    lat: "39.074208",
    lng: "21.824312",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSOU",
    portName: "Sousaki (GRSOU), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.2625973",
    lng: "23.1322437",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSPE",
    portName: "Spetses (GRSPE), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.5141742",
    lng: "23.8254879",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSTI",
    portName: "Port of Stratoni",
    portContinent: "Europe",
  },
  {
    lat: "35.4885462",
    lng: "24.0745783",
    country: "GR",
    country_long: "Greece",
    portCode: "GRSUD",
    portName: "Port of Souda",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GRSYS",
    portName: "Port of Stylis",
    portContinent: "Europe",
  },
  {
    lat: "38.2577273",
    lng: "22.969588",
    country: "GR",
    country_long: "Greece",
    portCode: "GRTHI",
    portName: "Thisvi (GRTHI), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.4547347",
    lng: "27.3453689",
    country: "GR",
    country_long: "Greece",
    portCode: "GRTIL",
    portName: "Tilos (GRTIL), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.5389864",
    lng: "25.1561289",
    country: "GR",
    country_long: "Greece",
    portCode: "GRTIN",
    portName: "Port of Tinos",
    portContinent: "Europe",
  },
  {
    lat: "39.1002506",
    lng: "23.0771373",
    country: "GR",
    country_long: "Greece",
    portCode: "GRTRK",
    portName: "Trikeri (GRTRK), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.4365762",
    lng: "25.3370947",
    country: "GR",
    country_long: "Greece",
    portCode: "GRTRS",
    portName: "Thirassia (GRTRS), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.7375255",
    lng: "24.4278046",
    country: "GR",
    country_long: "Greece",
    portCode: "GRTRY",
    portName: "Trypiti (GRTRY), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.3333333",
    lng: "23.4166667",
    country: "GR",
    country_long: "Greece",
    portCode: "GRTSI",
    portName: "Tsingeli (GRTSI), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.6844991",
    lng: "24.6483706",
    country: "GR",
    country_long: "Greece",
    portCode: "GRTSO",
    portName: "Thasos (GRTSO), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.3587455",
    lng: "22.9445281",
    country: "GR",
    country_long: "Greece",
    portCode: "GRVOL",
    portName: "Port of Volos",
    portContinent: "Europe",
  },
  {
    lat: "40.6203216",
    lng: "22.4040665",
    country: "GR",
    country_long: "Greece",
    portCode: "GRVRI",
    portName: "Vrisakia (GRVRI), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.6287203",
    lng: "20.6090484",
    country: "GR",
    country_long: "Greece",
    portCode: "GRVSS",
    portName: "Vassiliki (GRVSS), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.2689678",
    lng: "23.4497467",
    country: "GR",
    country_long: "Greece",
    portCode: "GRYER",
    portName: "Port of Yerakini",
    portContinent: "Europe",
  },
  {
    lat: "36.6602322",
    lng: "27.111768",
    country: "GR",
    country_long: "Greece",
    portCode: "GRYLI",
    portName: "Gyali (GRYLI), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.754438",
    lng: "20.886614",
    country: "GR",
    country_long: "Greece",
    portCode: "GRZTH",
    portName: "Zakynthos (GRZTH), Greece, Med",
    portContinent: "Europe",
  },
  {
    lat: "14.479498",
    lng: "-90.6218751",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTAMT",
    portName: "Amatitlan, guatemala (GTAMT), Guatemala, LatAm",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GTCBV",
    portName: "Coban",
    portContinent: "North America",
  },
  {
    lat: "14.6589734",
    lng: "-90.8245242",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTCHI",
    portName: "Chimaltenango",
    portContinent: "North America",
  },
  {
    lat: "14.2974022",
    lng: "-91.9087026",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTCHR",
    portName: "Port of Champerico",
    portContinent: "North America",
  },
  {
    lat: "14.6349149",
    lng: "-90.5068824",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTGUA",
    portName: "GUATEMALA CITY",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GTIZ4",
    portName: "Santo TomÃ¡s de Castilla",
    portContinent: "North America",
  },
  {
    lat: "15.8269363",
    lng: "-88.75300759999999",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTLIV",
    portName: "Livingston (GTLIV), Guatemala, LatAm",
    portContinent: "North America",
  },
  {
    lat: "14.633333",
    lng: "-90.6",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTMIX",
    portName: "Mixco",
    portContinent: "North America",
  },
  {
    lat: "15.6969984",
    lng: "-88.5855064",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTPBR",
    portName: "Puerto Barrios",
    portContinent: "North America",
  },
  {
    lat: "13.9306292",
    lng: "-90.7580342",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTPRQ",
    portName: "Puerto Quetzal",
    portContinent: "North America",
  },
  {
    lat: "14.5447785",
    lng: "-90.41016189999999",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTSJP",
    portName: "SAN JOSE PINULA",
    portContinent: "North America",
  },
  {
    lat: "14.7191153",
    lng: "-90.6464802",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTSJS",
    portName: "SAN JUAN SACATEPEQUEZ",
    portContinent: "North America",
  },
  {
    lat: "13.930382",
    lng: "-90.8341624",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTSNJ",
    portName: "Port of San Jose",
    portContinent: "North America",
  },
  {
    lat: "14.6841391",
    lng: "-90.64174009999999",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTSPS",
    portName: "SAN PEDRO SACATEPEQUEZ",
    portContinent: "North America",
  },
  {
    lat: "15.69018",
    lng: "-88.620407",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTSTC",
    portName: "Puerto Santo Tomas de Castilla (GTSTC), Guatemala, Guatemala",
    portContinent: "North America",
  },
  {
    lat: "14.6488783",
    lng: "-90.73664889999999",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTSUM",
    portName: "SUMPANGO",
    portContinent: "North America",
  },
  {
    lat: "14.5305465",
    lng: "-90.59584989999999",
    country: "GT",
    country_long: "Guatemala",
    portCode: "GTVIN",
    portName: "Villa Nueva",
    portContinent: "North America",
  },
  {
    lat: "13.4762824",
    lng: "144.7502228",
    country: "GU",
    country_long: "Guam",
    portCode: "GUAPR",
    portName: "APRA (AGANA)",
    portContinent: "Oceania",
  },
  {
    lat: "13.444304",
    lng: "144.793731",
    country: "GU",
    country_long: "Guam",
    portCode: "GUGUM",
    portName: "Guam (GUGUM), Guam, Asia",
    portContinent: "Oceania",
  },
  {
    lat: "11.5770712",
    lng: "-15.4800382",
    country: "GW",
    country_long: "Guinea-Bissau",
    portCode: "GWBOL",
    portName: "Port of Bolama",
    portContinent: "Africa",
  },
  {
    lat: "11.283333",
    lng: "-15.833333",
    country: "GW",
    country_long: "Guinea-Bissau",
    portCode: "GWBQE",
    portName: "Bubaque (GWBQE), Guinea-Bissau, Africa",
    portContinent: "Africa",
  },
  {
    lat: "11.8598207",
    lng: "-15.5767391",
    country: "GW",
    country_long: "Guinea-Bissau",
    portCode: "GWOXB",
    portName: "Port of Bissau",
    portContinent: "Africa",
  },
  {
    lat: "33.376834",
    lng: "-79.2944964",
    country: "US",
    country_long: "United States",
    portCode: "GYGEO",
    portName: "Port of Georgetown",
    portContinent: "South America",
  },
  {
    lat: "6.4043217",
    lng: "-58.625825",
    country: "GY",
    country_long: "Guyana",
    portCode: "GYGFO",
    portName: "Bartica (GYGFO), Guyana, LatAm",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GYLDN",
    portName: "Port of Linden",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "GYNAM",
    portName: "Port of New Amsterdam",
    portContinent: "South America",
  },
  {
    lat: "5.870049100000001",
    lng: "-57.1492443",
    country: "SR",
    country_long: "Suriname",
    portCode: "GYSKM",
    portName: "Skeldon (GYSKM), Guyana, LatAm",
    portContinent: "South America",
  },
  {
    lat: "18.0403091",
    lng: "-63.0824656",
    country: "SX",
    country_long: "Sint Maarten",
    portCode: "HK001",
    portName: "COLEBAY",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "HKHKC",
    portName: "Hongkong International Terminals",
    portContinent: "Asia",
  },
  {
    lat: "22.30744579369529",
    lng: "114.1677101505829",
    country: "HK",
    country_long: "Hong Kong",
    portCode: "HKHKG",
    portName: "Port of HongKong",
    portContinent: "Asia",
  },
  {
    lat: "22.3578109",
    lng: "114.1298151",
    country: "HK",
    country_long: "Hong Kong",
    portCode: "HKKCO",
    portName: "Kwai Pong",
    portContinent: "Asia",
  },
  {
    lat: "22.3185673",
    lng: "114.1796057",
    country: "HK",
    country_long: "Hong Kong",
    portCode: "HKKWN",
    portName: "Kowloon",
    portContinent: "Asia",
  },
  {
    lat: "22.3699122",
    lng: "114.1144306",
    country: "HK",
    country_long: "Hong Kong",
    portCode: "HKZTW",
    portName: "Tsuen Wan",
    portContinent: "Asia",
  },
  {
    lat: "13.2916538",
    lng: "-87.65194729999999",
    country: "HN",
    country_long: "Honduras",
    portCode: "HNAMP",
    portName: "Amapala (HNAMP), Honduras, LatAm",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "HNCFR",
    portName: "Cofradia",
    portContinent: "North America",
  },
  {
    lat: "15.6054565",
    lng: "-87.9559371",
    country: "HN",
    country_long: "Honduras",
    portCode: "HNCHL",
    portName: "Choloma (HNCHL), Honduras, LatAm",
    portContinent: "North America",
  },
  {
    lat: "15.6054565",
    lng: "-87.9559371",
    country: "HN",
    country_long: "Honduras",
    portCode: "HNCHO",
    portName: "CHOLOMA",
    portContinent: "North America",
  },
  {
    lat: "14.4490149",
    lng: "-87.6482474",
    country: "HN",
    country_long: "Honduras",
    portCode: "HNCMY",
    portName: "Comayagua",
    portContinent: "North America",
  },
  {
    lat: "33.73954589643058",
    lng: "-118.2599686241527",
    country: "US",
    country_long: "United States",
    portCode: "HNLCE",
    portName: "Port of La Ceiba",
    portContinent: "North America",
  },
  {
    lat: "15.7790394",
    lng: "-88.0265257",
    country: "HN",
    country_long: "Honduras",
    portCode: "HNOMO",
    portName: "Omoa (HNOMO), Honduras, LatAm",
    portContinent: "North America",
  },
  {
    lat: "16.0173268",
    lng: "-85.9585544",
    country: "HN",
    country_long: "Honduras",
    portCode: "HNPCA",
    portName: "Puerto Castilla",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "HNPCR",
    portName: "Puerto CortÃ©s",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "HNPRO",
    portName: "EL PROGRESO",
    portContinent: "North America",
  },
  {
    lat: "16.3297608",
    lng: "-86.5299673",
    country: "HN",
    country_long: "Honduras",
    portCode: "HNRTB",
    portName: "Roatan (HNRTB), Honduras, LatAm",
    portContinent: "North America",
  },
  {
    lat: "15.5038827",
    lng: "-88.01386190000001",
    country: "HN",
    country_long: "Honduras",
    portCode: "HNSAP",
    portName: "San Pedro Sula (HNSAP), San Pedro Sula, Honduras",
    portContinent: "North America",
  },
  {
    lat: "13.4326204",
    lng: "-87.45544319999999",
    country: "HN",
    country_long: "Honduras",
    portCode: "HNSLO",
    portName: "San Lorenzo (HNSLO), Honduras, LatAm",
    portContinent: "North America",
  },
  {
    lat: "15.7732601",
    lng: "-87.46535019999999",
    country: "HN",
    country_long: "Honduras",
    portCode: "HNTEA",
    portName: "Tela (HNTEA), Honduras, LatAm",
    portContinent: "North America",
  },
  {
    lat: "14.065049",
    lng: "-87.1715002",
    country: "HN",
    country_long: "Honduras",
    portCode: "HNTGU",
    portName: "TEGUCIGALPA (HNTGU), Tegucigalpa, Honduras",
    portContinent: "North America",
  },
  {
    lat: "15.9116789",
    lng: "-85.9534465",
    country: "HN",
    country_long: "Honduras",
    portCode: "HNTJI",
    portName: "Trujillo (HNTJI), Honduras, LatAm",
    portContinent: "North America",
  },
  {
    lat: "16.0949604",
    lng: "-86.9273532",
    country: "HN",
    country_long: "Honduras",
    portCode: "HNUII",
    portName: "Utila (HNUII), Honduras, LatAm",
    portContinent: "North America",
  },
  {
    lat: "45.5290033",
    lng: "18.9491813",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRALJ",
    portName: "Aljmas (HRALJ), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.0731738",
    lng: "15.2838313",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRBBJ",
    portName: "Bibinje (HRBBJ), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.2622347",
    lng: "16.6541264",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRBOL",
    portName: "Bol (HRBOL), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.0811509",
    lng: "14.999902",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRBRB",
    portName: "Brbinj (HRBRB), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.8569977",
    lng: "14.3975424",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRCRS",
    portName: "Cres (HRCRS), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.0470411",
    lng: "15.3150024",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRDAL",
    portName: "Sukosan (HRDAL), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.66259609999999",
    lng: "18.0828922",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRDBV",
    portName: "Port of Dubrovnik",
    portContinent: "Europe",
  },
  {
    lat: "42.67593340000001",
    lng: "18.0094835",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRDNC",
    portName: "Kolocep (HRDNC), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.1560519",
    lng: "17.2463201",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRDRK",
    portName: "Drvenik (HRDRK), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.4438142",
    lng: "16.6418",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRDUR",
    portName: "Port of Dugi Rat",
    portContinent: "Europe",
  },
  {
    lat: "44.928039",
    lng: "13.8042445",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRFNA",
    portName: "Port of Fazana",
    portContinent: "Europe",
  },
  {
    lat: "46.10984879999999",
    lng: "16.3916706",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRGRC",
    portName: "Gornja Rijeka",
    portContinent: "Europe",
  },
  {
    lat: "43.1722449",
    lng: "16.4408069",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRHVA",
    portName: "Port of Hvar",
    portContinent: "Europe",
  },
  {
    lat: "44.7066141",
    lng: "14.8980117",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRJAB",
    portName: "Jablanac (HRJAB), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.726389",
    lng: "15.841944",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRJDR",
    portName: "Jadrija (HRJDR), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.1617132",
    lng: "16.6926372",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRJSA",
    portName: "Jelsa (HRJSA), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.1",
    lng: "15.2",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRKAL",
    portName: "Kali (HRKAL), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.9297182",
    lng: "16.888644",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRKOR",
    portName: "Port of Korcula",
    portContinent: "Europe",
  },
  {
    lat: "43.6934705",
    lng: "15.7028457",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRKPR",
    portName: "Kaprije (HRKPR), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.1",
    lng: "15.2",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRKRA",
    portName: "Kraijevica (HRKRA), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.0278995",
    lng: "14.5752114",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRKRK",
    portName: "Krk (HRKRK), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.9683634",
    lng: "14.1220627",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRKRM",
    portName: "Koromacno (HRKRM), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.6855183",
    lng: "17.9502207",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRLPD",
    portName: "Lopud (HRLPD), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.8311328",
    lng: "14.7299603",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRLPR",
    portName: "Lopar (HRLPR), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.5315814",
    lng: "14.4720423",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRLSZ",
    portName: "Mali Losinj (HRLSZ), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.2937769",
    lng: "17.0215239",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRMAK",
    portName: "Makarska (HRMAK), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "HRMAS",
    portName: "Port of Maslenica",
    portContinent: "Europe",
  },
  {
    lat: "43.05328009999999",
    lng: "17.6493431",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRMET",
    portName: "Port of Metkovic",
    portContinent: "Europe",
  },
  {
    lat: "43.327073",
    lng: "16.4492436",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRMIL",
    portName: "Milna (HRMIL), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.7041049",
    lng: "14.8634861",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRMNK",
    portName: "Misnjak (HRMNK), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.9760839",
    lng: "14.4421766",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRMRG",
    portName: "Merag (HRMRG), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.6504743",
    lng: "15.6588485",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRMRJ",
    portName: "Zirje (HRMRJ), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.3156571",
    lng: "13.5619471",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRNVG",
    portName: "Novigrad (HRNVG), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.2110039",
    lng: "14.5569253",
    country: "HR",
    country_long: "Croatia",
    portCode: "HROMI",
    portName: "Port of Omisalj",
    portContinent: "Europe",
  },
  {
    lat: "45.2604317",
    lng: "19.170648",
    country: "HR",
    country_long: "Croatia",
    portCode: "HROPT",
    portName: "Opatovac (HROPT), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.9757655",
    lng: "17.1778637",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRORB",
    portName: "Orebic (HRORB), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.5549624",
    lng: "18.6955144",
    country: "HR",
    country_long: "Croatia",
    portCode: "HROSI",
    portName: "OSIJEK",
    portContinent: "Europe",
  },
  {
    lat: "43.0402778",
    lng: "17.4211111",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRPLE",
    portName: "Port of Ploce",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "HRPLM",
    portName: "Port of Plomin",
    portContinent: "Europe",
  },
  {
    lat: "45.020739",
    lng: "14.6315962",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRPNT",
    portName: "Punat (HRPNT), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.22716519999999",
    lng: "13.5947399",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRPOR",
    portName: "Porec (HRPOR), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.624843",
    lng: "14.9716379",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRPRN",
    portName: "Prizna (HRPRN), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.8631153",
    lng: "13.8291142",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRPUY",
    portName: "Port of Pula",
    portContinent: "Europe",
  },
  {
    lat: "44.7569096",
    lng: "14.7599675",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRRAB",
    portName: "Rab (HRRAB), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "HRRAS",
    portName: "Port of Rasa",
    portContinent: "Europe",
  },
  {
    lat: "45.0781668",
    lng: "14.1617033",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRRBC",
    portName: "Port of Rabac",
    portContinent: "Europe",
  },
  {
    lat: "43.3957557",
    lng: "16.2974268",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRRGC",
    portName: "Rogac (HRRGC), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.3273933",
    lng: "14.4362813",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRRJK",
    portName: "Port of Rijeka",
    portContinent: "Europe",
  },
  {
    lat: "45.0811661",
    lng: "13.6387067",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRROV",
    portName: "Port of Rovinj",
    portContinent: "Europe",
  },
  {
    lat: "42.7109509",
    lng: "17.9081282",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRSDR",
    portName: "Sudurad (HRSDR), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.9893604",
    lng: "14.9036052",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRSEN",
    portName: "Port of Senj",
    portContinent: "Europe",
  },
  {
    lat: "43.18399580000001",
    lng: "16.5992505",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRSGD",
    portName: "Stari Grad (HRSGD), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.7350196",
    lng: "15.8952045",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRSIB",
    portName: "Port of Sibenik",
    portContinent: "Europe",
  },
  {
    lat: "43.4833333",
    lng: "16.4333333",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRSPU",
    portName: "Port of Split",
    portContinent: "Europe",
  },
  {
    lat: "43.1265922",
    lng: "17.187691",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRSUC",
    portName: "Sucuraj (HRSUC), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.51638699999999",
    lng: "16.2501894",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRTRO",
    portName: "Trogir (HRTRO), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.7441",
    lng: "16.8280363",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRUBL",
    portName: "Ubli (HRUBL), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.4372062",
    lng: "13.5257205",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRUMG",
    portName: "Umag (HRUMG), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.2886116",
    lng: "14.5267688",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRUNJ",
    portName: "Unije (HRUNJ), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.0602104",
    lng: "16.1828781",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRVIS",
    portName: "Vis (HRVIS), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.04525599999999",
    lng: "14.480406",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRVLB",
    portName: "Valbiska (HRVLB), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.1499462",
    lng: "13.6052578",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRVRR",
    portName: "Vrsar (HRVRR), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.3452377",
    lng: "19.0010204",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRVUK",
    portName: "Port of Vukovar",
    portContinent: "Europe",
  },
  {
    lat: "44.0931034",
    lng: "15.2585751",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRZAD",
    portName: "Port of Zadar",
    portContinent: "Europe",
  },
  {
    lat: "45.8150108",
    lng: "15.981919",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRZAG",
    portName: "ZAGREB (HRZAG), Zagreb, Croatia",
    portContinent: "Europe",
  },
  {
    lat: "44.56",
    lng: "14.89",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRZGL",
    portName: "Zigljen (HRZGL), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.68955769999999",
    lng: "15.8466437",
    country: "HR",
    country_long: "Croatia",
    portCode: "HRZLR",
    portName: "Zlarin (HRZLR), Croatia, Med",
    portContinent: "Europe",
  },
  {
    lat: "18.2042851",
    lng: "-73.7536695",
    country: "HT",
    country_long: "Haiti",
    portCode: "HTACA",
    portName: "Port of Les Cayes",
    portContinent: "North America",
  },
  {
    lat: "19.7370362",
    lng: "-72.20676809999999",
    country: "HT",
    country_long: "Haiti",
    portCode: "HTCAP",
    portName: "Cap Haitien (HTCAP), Haiti, LatAm",
    portContinent: "North America",
  },
  {
    lat: "19.6616066",
    lng: "-71.8363794",
    country: "HT",
    country_long: "Haiti",
    portCode: "HTFLI",
    portName: "Fort Liberte (HTFLI), Haiti, LatAm",
    portContinent: "North America",
  },
  {
    lat: "19.4511496",
    lng: "-72.6801576",
    country: "HT",
    country_long: "Haiti",
    portCode: "HTGVS",
    portName: "Port of Gonaives",
    portContinent: "North America",
  },
  {
    lat: "18.2430063",
    lng: "-72.52639839999999",
    country: "HT",
    country_long: "Haiti",
    portCode: "HTJAK",
    portName: "Port of Jacmel",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "HTJEE",
    portName: "Port of Jeremie",
    portContinent: "North America",
  },
  {
    lat: "19.77205",
    lng: "-72.2474823",
    country: "HT",
    country_long: "Haiti",
    portCode: "HTLAB",
    portName: "Labadie (HTLAB), Haiti, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.6947675",
    lng: "-72.35331169999999",
    country: "HT",
    country_long: "Haiti",
    portCode: "HTLFF",
    portName: "Lafiteau (HTLFF), Haiti, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.4395252",
    lng: "-73.08627349999999",
    country: "HT",
    country_long: "Haiti",
    portCode: "HTMIR",
    portName: "Port of Miragoane",
    portContinent: "North America",
  },
  {
    lat: "18.594395",
    lng: "-72.3074326",
    country: "HT",
    country_long: "Haiti",
    portCode: "HTPAP",
    portName: "Port of Port-au-Prince",
    portContinent: "North America",
  },
  {
    lat: "19.9348887",
    lng: "-72.8308692",
    country: "HT",
    country_long: "Haiti",
    portCode: "HTPDP",
    portName: "Port of Port de Paix",
    portContinent: "North America",
  },
  {
    lat: "18.4311407",
    lng: "-72.8545019",
    country: "HT",
    country_long: "Haiti",
    portCode: "HTPEG",
    portName: "Port of Petit Goave",
    portContinent: "North America",
  },
  {
    lat: "18.6947675",
    lng: "-72.35331169999999",
    country: "HT",
    country_long: "Haiti",
    portCode: "HTPLH",
    portName: "LAFITO",
    portContinent: "North America",
  },
  {
    lat: "19.104927",
    lng: "-72.69493729999999",
    country: "HT",
    country_long: "Haiti",
    portCode: "HTSMC",
    portName: "Saint Marc (HTSMC), Haiti, LatAm",
    portContinent: "North America",
  },
  {
    lat: "47.119831",
    lng: "18.8612469",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUADY",
    portName: "Adony (HUADY), Hungary, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.1817951",
    lng: "18.9543051",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUBAA",
    portName: "Baja (HUBAA), Hungary, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "HUBLK",
    portName: "BILK / Metrans railterminal",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "HUBUC",
    portName: "Mahart /BUCS depots",
    portContinent: "Europe",
  },
  {
    lat: "47.497912",
    lng: "19.040235",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUBUD",
    portName: "Port of BUDAPEST",
    portContinent: "Europe",
  },
  {
    lat: "47.4243579",
    lng: "19.066142",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUCPE",
    portName: "Csepel",
    portContinent: "Europe",
  },
  {
    lat: "47.5288879",
    lng: "21.6254485",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUDEB",
    portName: "Debrecen",
    portContinent: "Europe",
  },
  {
    lat: "46.806202",
    lng: "18.9167877",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUDFV",
    portName: "Dunafoldvar (HUDFV), Hungary, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.9619059",
    lng: "18.9355227",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUDUU",
    portName: "Dunaujvaros (HUDUU), Hungary, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.9133047",
    lng: "18.9731873",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUDVE",
    portName: "Dunavecse (HUDVE), Hungary, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.7883949",
    lng: "18.7434452",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUEST",
    portName: "Esztergom (HUEST), Hungary, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.5268759",
    lng: "18.9196874",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUFKT",
    portName: "Fokto (HUFKT), Hungary, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.7334344",
    lng: "17.8243403",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUGNY",
    portName: "Gonyu (HUGNY), Hungary, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.6874569",
    lng: "17.6503974",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUGYO",
    portName: "GYOR (HUGYO), Gyor, Hungary",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "HUMAH",
    portName: "Mahart rail terminal",
    portContinent: "Europe",
  },
  {
    lat: "46.0046295",
    lng: "18.6794304",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUMOH",
    portName: "Mohacs (HUMOH), Hungary, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.6060722",
    lng: "18.8546832",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUPAK",
    portName: "Paks (HUPAK), Hungary, Europe",
    portContinent: "Europe",
  },
  {
    lat: "46.8021967",
    lng: "19.0108147",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUSOL",
    portName: "Solt (HUSOL), Hungary, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.16213550000001",
    lng: "20.1824712",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUSZL",
    portName: "SZOLNOK (HUSZL), Szolnok, Hungary",
    portContinent: "Europe",
  },
  {
    lat: "47.1892795",
    lng: "18.4243894",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUSZR",
    portName: "SZEKESFEHERVAR (HUSZR), Szekesfehervar, Hungary",
    portContinent: "Europe",
  },
  {
    lat: "47.2306851",
    lng: "16.6218441",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUSZY",
    portName: "SZOMBATHELY (HUSZY), Szombathely, Hungary",
    portContinent: "Europe",
  },
  {
    lat: "47.4384914",
    lng: "18.9108101",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUTKB",
    portName: "TOROKBALINT (HUTKB), Hungary, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.56489149999999",
    lng: "19.0913148",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUUPS",
    portName: "Ujpest (HUUPS), Hungary, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.4068857",
    lng: "19.2624362",
    country: "HU",
    country_long: "Hungary",
    portCode: "HUVEC",
    portName: "Vecses",
    portContinent: "Europe",
  },
  {
    lat: "-6.307923199999999",
    lng: "107.172085",
    country: "ID",
    country_long: "Indonesia",
    portCode: "ID3KG",
    portName: "CIKARANG (ID3KG), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "5.3781421",
    lng: "95.8769366",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDADB",
    portName: "Adang Bay (IDADB), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-3.3393615",
    lng: "128.9229993",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDAHI",
    portName: "Amahai (IDAHI), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-6.460046999999999",
    lng: "105.896559",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDAMA",
    portName: "Amamapare (IDAMA), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.5659258",
    lng: "116.0763813",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDAMP",
    portName: "Ampenan, Bali",
    portContinent: "South East Asia",
  },
  {
    lat: "-3.7051005",
    lng: "128.0885813",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDAMQ",
    portName: "Ambon (IDAMQ), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-3.3186067",
    lng: "114.5943784",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBDJ",
    portName: "Banjarmasin (IDBDJ), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-6.9174639",
    lng: "107.6191228",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBDO",
    portName: "BANDUNG (IDBDO), Bandung, Indonesia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.8937001",
    lng: "116.7673237",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBEN",
    portName: "Benete (IDBEN), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-5.6882921",
    lng: "119.6904746",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBIR",
    portName: "Biringkassi (IDBIR), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "1.4403744",
    lng: "125.1216524",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBIT",
    portName: "Bitung, Sulawesi",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.2192335",
    lng: "114.3692267",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBJU",
    portName: "Banyuwangi (IDBJU), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-3.792845099999999",
    lng: "102.2607641",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBKS",
    portName: "Bengkulu (IDBKS), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "3.784303",
    lng: "98.694221",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBLW",
    portName: "Port of Belawan",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.4642661",
    lng: "118.7449028",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBMU",
    portName: "Bima (IDBMU), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-6.9174639",
    lng: "107.6191228",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBNU",
    portName: "Bandung, Java (IDBNU), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.7815919",
    lng: "115.1787738",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBOA",
    portName: "Benoa",
    portContinent: "South East Asia",
  },
  {
    lat: "-1.2379274",
    lng: "116.8528526",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBPN",
    portName: "Balikpapan (IDBPN), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "1.1336033",
    lng: "104.1175578",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBTH",
    portName: "Batam (Ex Batu Besar)",
    portContinent: "South East Asia",
  },
  {
    lat: "5.5178712",
    lng: "95.4173524",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBTJ",
    portName: "Banda Aceh (IDBTJ), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "1.0828276",
    lng: "104.0304535",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBTM",
    portName: "Batam Island (IDBTM), Tanjungpinang, Indonesia",
    portContinent: "South East Asia",
  },
  {
    lat: "-3.4499661",
    lng: "116.0021221",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBTW",
    portName: "Batulicin (IDBTW), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0.736198",
    lng: "101.818901",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBUA",
    portName: "Buatan (IDBUA), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0.736198",
    lng: "101.818901",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBUN",
    portName: "BUATAN (IDBUN), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "1.171904",
    lng: "104.019403",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBUR",
    portName: "Batu Ampar (IDBUR), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-5.507078",
    lng: "122.596901",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBUW",
    portName: "Baubau (IDBUW), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0.120863",
    lng: "117.4800445",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDBXT",
    portName: "Bontang (IDBXT), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-6.7320229",
    lng: "108.5523164",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDCBN",
    portName: "Cirebon (IDCBN), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.1989471",
    lng: "114.8522973",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDCEB",
    portName: "Celukan Bawang (IDCEB), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-6.0186516",
    lng: "106.0558218",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDCGN",
    portName: "Cilegon (IDCGN), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "IDCHN",
    portName: "Duncannon Harbor",
    portContinent: "South East Asia",
  },
  {
    lat: "-6.278397099999999",
    lng: "106.11399",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDCIG",
    portName: "Cigading (IDCIG), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-6.015323899999999",
    lng: "105.9763804",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDCIW",
    portName: "Ciwandan (IDCIW), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-6.307923199999999",
    lng: "107.172085",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDCKR",
    portName: "CIKARANG",
    portContinent: "South East Asia",
  },
  {
    lat: "-1.6101229",
    lng: "103.6131203",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDDJB",
    portName: "Jambi, Sumatra",
    portContinent: "South East Asia",
  },
  {
    lat: "-2.5916025",
    lng: "140.6689995",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDDJJ",
    portName: "Jayapura (IDDJJ), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-5.7754461",
    lng: "134.2093597",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDDOB",
    portName: "Dobo (IDDOB), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.670458199999999",
    lng: "115.2126293",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDDPS",
    portName: "DENPASAR",
    portContinent: "South East Asia",
  },
  {
    lat: "1.6666349",
    lng: "101.4001855",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDDUM",
    portName: "Dumai (IDDUM), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-2.9280437",
    lng: "132.2917307",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDFKQ",
    portName: "Fak Fak (IDFKQ), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-0.789275",
    lng: "113.921327",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDFTG",
    portName: "Futong (IDFTG), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.169761000000001",
    lng: "114.4338",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDGIL",
    portName: "Gilimanuk (IDGIL), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "1.7991745",
    lng: "127.9013278",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDGLX",
    portName: "Galela (IDGLX), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-7.1652437",
    lng: "112.6519882",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDGRE",
    portName: "Gresik (IDGRE), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0.5435441999999999",
    lng: "123.0567693",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDGTO",
    portName: "Gorontalo (IDGTO), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-2.1316266",
    lng: "106.1169299",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDIQP",
    portName: "Pangkal pinang (IDIQP), Pangkalpinang, Indonesia",
    portContinent: "South East Asia",
  },
  {
    lat: "1.0703109",
    lng: "104.2192475",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDITN",
    portName: "Tanjung Uban (IDITN), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "IDJBK",
    portName: "Â Jababeka",
    portContinent: "South East Asia",
  },
  {
    lat: "-6.1382799",
    lng: "106.8665166",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDJKT",
    portName: "Jakarta(Tanjung Priok) (IDJKT), Jakarta, Indonesia",
    portContinent: "South East Asia",
  },
  {
    lat: "-7.5360639",
    lng: "112.2384017",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDJWA",
    portName: "Jawa Timur",
    portContinent: "South East Asia",
  },
  {
    lat: "1.0591691",
    lng: "104.1175578",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKAB",
    portName: "Kabil (IDKAB), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.213998",
    lng: "124.518169",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKBH",
    portName: "Kalabahi (IDKBH), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-3.0029841",
    lng: "115.9467997",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKBU",
    portName: "Kotabaru (IDKBU), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-3.9984597",
    lng: "122.5129742",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKDI",
    portName: "Kendari (IDKDI), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "5.2472574",
    lng: "97.0256505",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKGH",
    portName: "Krueng Geukueh (IDKGH), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.2546683",
    lng: "116.2597842",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKHY",
    portName: "Kahyangan (IDKHY), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0.8533655000000001",
    lng: "104.5946633",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKID",
    portName: "Kidjang,Bintan",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "IDKIJ",
    portName: "Kijang",
    portContinent: "South East Asia",
  },
  {
    lat: "-10.1771997",
    lng: "123.6070329",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKOE",
    portName: "Kupang (IDKOE), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-4.0513522",
    lng: "121.5997835",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKOL",
    portName: "Kolaka (IDKOL), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-1.0989332",
    lng: "116.8203511",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKRG",
    portName: "Kariangau (IDKRG), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-7.6248611",
    lng: "114.0128537",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKSO",
    portName: "Kalbut (IDKSO), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-1.8168237",
    lng: "109.961836",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKTG",
    portName: "Ketapang (IDKTG), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "3.3609702",
    lng: "99.4526259",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKTJ",
    portName: "Port of KUALA TANJUNG",
    portContinent: "South East Asia",
  },
  {
    lat: "-1.5957437",
    lng: "103.6225939",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKTK",
    portName: "Kuala Tungkal",
    portContinent: "South East Asia",
  },
  {
    lat: "4.523242499999999",
    lng: "98.00834139999999",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKUA",
    portName: "Port of Kuala Langsa",
    portContinent: "South East Asia",
  },
  {
    lat: "-2.9116926",
    lng: "111.9045541",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDKUM",
    portName: "Kumai (IDKUM), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-7.1181109",
    lng: "112.4149811",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDLAM",
    portName: "Lamongan (IDLAM), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.488586399999999",
    lng: "119.8874316",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDLBJ",
    portName: "Labuanabajo (IDLBJ), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "5.4776299",
    lng: "95.24422679999999",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDLHK",
    portName: "Lhoknga (IDLHK), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.315393",
    lng: "123.0166592",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDLKA",
    portName: "Laratunka (IDLKA), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "5.1811638",
    lng: "97.14132219999999",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDLSW",
    portName: "Port of Lhokseumawe",
    portContinent: "South East Asia",
  },
  {
    lat: "-1.0409894",
    lng: "122.7714034",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDLUW",
    portName: "Luwuk (IDLUW), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.390401899999999",
    lng: "123.3991028",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDLWE",
    portName: "Lewoleba (IDLWE), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-5.147665099999999",
    lng: "119.4327314",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMAK",
    portName: "Makassar",
    portContinent: "South East Asia",
  },
  {
    lat: "-2.991968",
    lng: "114.758797",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMAR",
    portName: "Marabahan (IDMAR), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "1.4748305",
    lng: "124.8420794",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMDC",
    portName: "Manado (IDMDC), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "4.1436951",
    lng: "96.12811459999999",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMEQ",
    portName: "Meulaboh (IDMEQ), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "3.5951956",
    lng: "98.6722227",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMES",
    portName: "Medan",
    portContinent: "South East Asia",
  },
  {
    lat: "-0.789275",
    lng: "113.921327",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMGB",
    portName: "Manggis (IDMGB), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.4991117",
    lng: "140.4049814",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMKQ",
    portName: "Merauke (IDMKQ), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-0.8614531",
    lng: "134.0620421",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMKW",
    portName: "Manokwari (IDMKW), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-2.645306",
    lng: "121.104301",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMLI",
    portName: "Malili (IDMLI), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-6.460046999999999",
    lng: "105.896559",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMNA",
    portName: "Melonguanne (IDMNA), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.624551499999999",
    lng: "122.2146964",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMOF",
    portName: "Maumere (IDMOF), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-0.789275",
    lng: "113.921327",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMPN",
    portName: "Muara Pantai (IDMPN), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "IDMRK",
    portName: "Merak, Java",
    portContinent: "South East Asia",
  },
  {
    lat: "1.7383836",
    lng: "117.9951862",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMUB",
    portName: "Muara Berau (IDMUB), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-0.8265393999999999",
    lng: "117.2340069",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMUD",
    portName: "Muara Jawa (IDMUD), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-2.0651651",
    lng: "105.1643012",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDMUO",
    portName: "Muntok (IDMUO), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "20.593684",
    lng: "78.96288",
    country: "IN",
    country_long: "India",
    portCode: "IDNDA",
    portName: "Banda Neira (IDNDA), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "4.1393382",
    lng: "117.6496257",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDNNX",
    portName: "Nunukan (IDNNX), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-7.7212958",
    lng: "113.4971033",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPAI",
    portName: "Paiton (IDPAI), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-0.9002914999999999",
    lng: "119.8779987",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPAL",
    portName: "PALU (IDPAL), Palu, Indonesia",
    portContinent: "South East Asia",
  },
  {
    lat: "-4.0096221",
    lng: "119.6290617",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPAP",
    portName: "Pare Pare (IDPAP), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-0.789275",
    lng: "113.921327",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPBB",
    portName: "Badas (IDPBB), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-0.9470831999999999",
    lng: "100.417181",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPDG",
    portName: "Padang",
    portContinent: "South East Asia",
  },
  {
    lat: "0.6658056",
    lng: "101.5958703",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPER",
    portName: "Perawang",
    portContinent: "South East Asia",
  },
  {
    lat: "-2.1011198",
    lng: "106.1289974",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPGX",
    portName: "Pangkal Balam (IDPGX), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "4.1136443",
    lng: "98.2164248",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPKS",
    portName: "Port of Pangkalan Susu",
    portContinent: "South East Asia",
  },
  {
    lat: "0.5070677",
    lng: "101.4477793",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPKU",
    portName: "Pekanbaru (IDPKU), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-2.9760735",
    lng: "104.7754307",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPLM",
    portName: "Palembang, Sumatra",
    portContinent: "South East Asia",
  },
  {
    lat: "-4.755778",
    lng: "136.7671967",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPMK",
    portName: "Pomako (IDPMK), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-5.4750401",
    lng: "105.3219948",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPNJ",
    portName: "Panjang",
    portContinent: "South East Asia",
  },
  {
    lat: "-0.0263303",
    lng: "109.3425039",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPNK",
    portName: "Pontianak (IDPNK), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-8.5423484",
    lng: "116.7791086",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPOT",
    portName: "Poto Tano (IDPOT), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-3.0016343",
    lng: "120.1985141",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPPO",
    portName: "Palopo (IDPPO), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-2.6849607",
    lng: "113.9536466",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPPS",
    portName: "Pulang Pisau (IDPPS), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-0.673877",
    lng: "119.8641634",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPTL",
    portName: "Pantoloan, Sv",
    portContinent: "South East Asia",
  },
  {
    lat: "-4.1752344",
    lng: "121.6149846",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPUM",
    portName: "Pomalaa (IDPUM), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0.6658056",
    lng: "101.5958703",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDPWG",
    portName: "Perawang (IDPWG), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-4.832396999999999",
    lng: "122.7211",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDRAQ",
    portName: "Raha (IDRAQ), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0.2933469",
    lng: "101.7068294",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDRAU",
    portName: "RIAU",
    portContinent: "South East Asia",
  },
  {
    lat: "5.8926053",
    lng: "95.3237608",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSBG",
    portName: "Port of Sabang",
    portContinent: "South East Asia",
  },
  {
    lat: "1.3501405",
    lng: "102.0775411",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSEQ",
    portName: "Port of Sungai Pakning",
    portContinent: "South East Asia",
  },
  {
    lat: "1.1126548",
    lng: "103.977878",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSKP",
    portName: "Sekupang",
    portContinent: "South East Asia",
  },
  {
    lat: "1.7368371",
    lng: "98.78511209999999",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSLG",
    portName: "Sibolga (IDSLG), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-5.6448937",
    lng: "104.7287607",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSMB",
    portName: "Teluk Semangka (IDSMB), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-2.5394654",
    lng: "112.9586863",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSMQ",
    portName: "Sampit (IDSMQ), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-0.8903221",
    lng: "131.2906297",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSOQ",
    portName: "Sorong (IDSOQ), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0.9826920000000001",
    lng: "102.701401",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSPA",
    portName: "Port of Selat Panjang",
    portContinent: "South East Asia",
  },
  {
    lat: "-7.0051453",
    lng: "110.4381254",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSRG",
    portName: "Semarang",
    portContinent: "South East Asia",
  },
  {
    lat: "-0.5016166",
    lng: "117.1264753",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSRI",
    portName: "Samarinda (IDSRI), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-6.0186817",
    lng: "106.0558218",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSRL",
    portName: "Suralaya (IDSRL), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-3.6395683",
    lng: "115.4259675",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSTU",
    portName: "Muara Satui (IDSTU), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-7.2574719",
    lng: "112.7520883",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSUB",
    portName: "Surabaya",
    portContinent: "South East Asia",
  },
  {
    lat: "0.293591",
    lng: "103.614502",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSUQ",
    portName: "SUNGAI GUNTUNG (IDSUQ), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-7.984966999999999",
    lng: "131.341705",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDSXK",
    portName: "Saumlaki (IDSXK), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-3.2448324",
    lng: "116.0768508",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTAR",
    portName: "Tarjun (IDTAR), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0.558782",
    lng: "117.6414567",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTBA",
    portName: "Tanjung Bara (IDTBA), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-6.8949099",
    lng: "112.0416754",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTBN",
    portName: "Tuban (IDTBN), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "1.7275162",
    lng: "128.0111694",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTBO",
    portName: "Tobelo (IDTBO), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "2.0288684",
    lng: "117.2911248",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTBR",
    portName: "Telukbayur (IDTBR), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "2.8374582",
    lng: "117.3742288",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTJS",
    portName: "Tanjung Selor (IDTJS), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-5.238413",
    lng: "104.3237",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTKG",
    portName: "Bandar Lampung (IDTKG), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "1.1216862",
    lng: "120.7935153",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTLI",
    portName: "Tolitoli (IDTLI), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0.9217677",
    lng: "104.5267991",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTNJ",
    portName: "Tanjung Pinang (IDTNJ), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-3.2166667",
    lng: "116.2666667",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTPN",
    portName: "Tanjung Pemancingan (IDTPN), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-6.1382799",
    lng: "106.8665166",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTPP",
    portName: "Tanjung Priok",
    portContinent: "South East Asia",
  },
  {
    lat: "2.1153547",
    lng: "99.54509739999999",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTRA",
    portName: "Sumatera Utara",
    portContinent: "South East Asia",
  },
  {
    lat: "-5.557225",
    lng: "105.372398",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTRH",
    portName: "Tarahan (IDTRH), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "3.3273599",
    lng: "117.5785049",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTRK",
    portName: "Tarakan (IDTRK), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0.7957999",
    lng: "127.3613533",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTTE",
    portName: "Ternate (IDTTE), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "3.3262879",
    lng: "99.1566855",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTTI",
    portName: "Tebingtinggi",
    portContinent: "South East Asia",
  },
  {
    lat: "-4.106199399999999",
    lng: "120.0954076",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDTUA",
    portName: "Tual (IDTUA), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-5.147665099999999",
    lng: "119.4327314",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDUPG",
    portName: "UJUNG PANDANG",
    portContinent: "South East Asia",
  },
  {
    lat: "0.338879",
    lng: "127.868698",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDWED",
    portName: "Weda (IDWED), Indonesia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "-9.6646545",
    lng: "120.2498483",
    country: "ID",
    country_long: "Indonesia",
    portCode: "IDWGP",
    portName: "Port of Waingapu",
    portContinent: "South East Asia",
  },
  {
    lat: "52.79769349999999",
    lng: "-6.1599293",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEARK",
    portName: "Port of Arklow",
    portContinent: "Europe",
  },
  {
    lat: "52.6211111",
    lng: "-9.066111099999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEAUG",
    portName: "Aughinish (IEAUG), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.59728500000001",
    lng: "-5.93012",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "IEBEF",
    portName: "Belfast",
    portContinent: "Europe",
  },
  {
    lat: "52.2484749",
    lng: "-6.964192",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEBHK",
    portName: "Ballyhack (IEBHK), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "-28.8627985",
    lng: "153.5658162",
    country: "AU",
    country_long: "Australia",
    portCode: "IEBLL",
    portName: "Port of Ballina",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "IEBTM",
    portName: "Port of Baltimore",
    portContinent: "Europe",
  },
  {
    lat: "54.9845286",
    lng: "-8.4326581",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEBUR",
    portName: "Port of Burtonport",
    portContinent: "Europe",
  },
  {
    lat: "51.6802441",
    lng: "-9.4521743",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEBYT",
    portName: "Bantry Bay Harbour",
    portContinent: "Europe",
  },
  {
    lat: "54.0468677",
    lng: "-6.190181",
    country: "IE",
    country_long: "Ireland",
    portCode: "IECAR",
    portName: "Carlingford Harbor",
    portContinent: "Europe",
  },
  {
    lat: "53.9897186",
    lng: "-7.3633319",
    country: "IE",
    country_long: "Ireland",
    portCode: "IECAV",
    portName: "Cavan,Ireland",
    portContinent: "Europe",
  },
  {
    lat: "55.0379106",
    lng: "-8.3416556",
    country: "IE",
    country_long: "Ireland",
    portCode: "IECFN",
    portName: "Donegal (IECFN), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8011059",
    lng: "-8.293153799999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IECHN",
    portName: "Crosshaven (IECHN), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2472036",
    lng: "-6.1828521",
    country: "IE",
    country_long: "Ireland",
    portCode: "IECKM",
    portName: "Carrickmines",
    portContinent: "Europe",
  },
  {
    lat: "52.8147376",
    lng: "-8.9642515",
    country: "IE",
    country_long: "Ireland",
    portCode: "IECLC",
    portName: "Clarecastle Harbour",
    portContinent: "Europe",
  },
  {
    lat: "53.7916777",
    lng: "-6.2388506",
    country: "IE",
    country_long: "Ireland",
    portCode: "IECLG",
    portName: "Clogherhead (IECLG), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8503357",
    lng: "-8.294285799999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IECOB",
    portName: "Cobh Harbor",
    portContinent: "Europe",
  },
  {
    lat: "51.65149539999999",
    lng: "-9.910330199999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IECSW",
    portName: "Castletown Bearhaven (IECSW), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.65149539999999",
    lng: "-9.910330199999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IECTB",
    portName: "Castletownbere Harbor",
    portContinent: "Europe",
  },
  {
    lat: "39.2568596",
    lng: "-76.5338356",
    country: "US",
    country_long: "United States",
    portCode: "IEDDK",
    portName: "Port of Dundalk",
    portContinent: "Europe",
  },
  {
    lat: "53.347797",
    lng: "-6.198757",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEDFT",
    portName: "Dublin-Dublin Ferryport Terminals (IEDFT), Dublin, Ireland",
    portContinent: "Europe",
  },
  {
    lat: "52.0936763",
    lng: "-7.620362699999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEDGV",
    portName: "Port of Dungarvan",
    portContinent: "Europe",
  },
  {
    lat: "53.2985045",
    lng: "-6.1311955",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEDLG",
    portName: "Dun Laoghaire Harbour",
    portContinent: "Europe",
  },
  {
    lat: "52.1527666",
    lng: "-6.994803999999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEDNM",
    portName: "Port of Dunmore East",
    portContinent: "Europe",
  },
  {
    lat: "53.71747850000001",
    lng: "-6.3368686",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEDRO",
    portName: "Drogheda Port",
    portContinent: "Europe",
  },
  {
    lat: "53.3494386",
    lng: "-6.2021736",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEDUB",
    portName: "Dublin Port",
    portContinent: "Europe",
  },
  {
    lat: "52.2833333",
    lng: "-9.85",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEFEN",
    portName: "Fenit Harbour",
    portContinent: "Europe",
  },
  {
    lat: "52.61252899999999",
    lng: "-9.112028",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEFOV",
    portName: "Port of Foynes",
    portContinent: "Europe",
  },
  {
    lat: "52.28414",
    lng: "-7.153449999999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEGNA",
    portName: "Grannagh (IEGNA), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.203361",
    lng: "-6.9875478",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEGNC",
    portName: "Greencastle (IEGNC), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.0327239",
    lng: "-6.1339084",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEGRN",
    portName: "Port of Greenore",
    portContinent: "Europe",
  },
  {
    lat: "53.1491175",
    lng: "-6.0793414",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEGRY",
    portName: "Greystones (IEGRY), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2689657",
    lng: "-9.0473498",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEGWY",
    portName: "Port of Galway",
    portContinent: "Europe",
  },
  {
    lat: "52.0501915",
    lng: "-7.549860299999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEHEL",
    portName: "Helvick (IEHEL), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.39166669999999",
    lng: "-6.0716667",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEHOW",
    portName: "Howth Harbour",
    portContinent: "Europe",
  },
  {
    lat: "53.0855036",
    lng: "-9.5859717",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEIIA",
    portName: "Inishmaan (IEIIA), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.6311",
    lng: "-8.44366",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEKBS",
    portName: "Killybegs Harbor",
    portContinent: "Europe",
  },
  {
    lat: "53.1232125",
    lng: "-9.670372799999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEKIN",
    portName: "Kilronan Harbor",
    portContinent: "Europe",
  },
  {
    lat: "54.2125146",
    lng: "-9.220999899999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEKLA",
    portName: "Killala Harbour",
    portContinent: "Europe",
  },
  {
    lat: "51.69933",
    lng: "-8.511911399999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEKLN",
    portName: "Port of Kinsale",
    portContinent: "Europe",
  },
  {
    lat: "52.6406999",
    lng: "-9.4859374",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEKLR",
    portName: "Port of Kilrush",
    portContinent: "Europe",
  },
  {
    lat: "52.17571940000001",
    lng: "-6.5863919",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEKRL",
    portName: "Kilmore Quay (IEKRL), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.6541608",
    lng: "-8.6531591",
    country: "IE",
    country_long: "Ireland",
    portCode: "IELMK",
    portName: "Limerick Dock",
    portContinent: "Europe",
  },
  {
    lat: "52.61136279999999",
    lng: "-9.4355342",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEMOT",
    portName: "Moneypoint Jetty",
    portContinent: "Europe",
  },
  {
    lat: "55.1890182",
    lng: "-7.040053299999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEMOV",
    portName: "Moville (IEMOV), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.394669",
    lng: "-6.9464673",
    country: "IE",
    country_long: "Ireland",
    portCode: "IENRS",
    portName: "Port of New Ross",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "IEORK",
    portName: "Port of Cork",
    portContinent: "Europe",
  },
  {
    lat: "52.2396938",
    lng: "-6.974394999999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEPAS",
    portName: "Passage East (IEPAS), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.0970707",
    lng: "-7.5363061",
    country: "IE",
    country_long: "Ireland",
    portCode: "IERAT",
    portName: "Rathmullan (IERAT), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.83042829999999",
    lng: "-8.3219487",
    country: "IE",
    country_long: "Ireland",
    portCode: "IERIN",
    portName: "Port of Ringaskiddy",
    portContinent: "Europe",
  },
  {
    lat: "52.2541667",
    lng: "-6.347777799999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEROE",
    portName: "Rosslare Europort",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "IERUS",
    portName: "Port of Rushbrooke",
    portContinent: "Europe",
  },
  {
    lat: "53.5805519",
    lng: "-6.1078779",
    country: "IE",
    country_long: "Ireland",
    portCode: "IESKE",
    portName: "Skerries (IESKE), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.28284069999999",
    lng: "-8.4934167",
    country: "IE",
    country_long: "Ireland",
    portCode: "IESLI",
    portName: "Sligo Harbour",
    portContinent: "Europe",
  },
  {
    lat: "52.6996573",
    lng: "-8.914691099999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IESNN",
    portName: "Shannon Airport Jetty",
    portContinent: "Europe",
  },
  {
    lat: "52.2664584",
    lng: "-7.0369951",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEWAT",
    portName: "Port of Waterford",
    portContinent: "Europe",
  },
  {
    lat: "52.2593197",
    lng: "-7.110070299999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEWCQ",
    portName: "Waterford City (IEWCQ), Ireland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.80054999999999",
    lng: "-9.548029999999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEWES",
    portName: "Westport Harbour",
    portContinent: "Europe",
  },
  {
    lat: "40.6266318",
    lng: "-80.05497679999999",
    country: "US",
    country_long: "United States",
    portCode: "IEWEX",
    portName: "Port of Wexford",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "IEWHI",
    portName: "Port of Whitegate",
    portContinent: "Europe",
  },
  {
    lat: "52.9808207",
    lng: "-6.044588999999999",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEWIC",
    portName: "Port of Wicklow",
    portContinent: "Europe",
  },
  {
    lat: "51.9542704",
    lng: "-7.8471707",
    country: "IE",
    country_long: "Ireland",
    portCode: "IEYOU",
    portName: "Port of Youghal",
    portContinent: "Europe",
  },
  {
    lat: "31.768319",
    lng: "35.21371",
    country: "IL",
    country_long: "Israel",
    portCode: "IL001",
    portName: "JERUSALEM",
    portContinent: "Middle East",
  },
  {
    lat: "32.933052",
    lng: "35.082678",
    country: "IL",
    country_long: "Israel",
    portCode: "ILACR",
    portName: "Acre (ILACR), Israel, Med",
    portContinent: "Middle East",
  },
  {
    lat: "31.6687885",
    lng: "34.5742523",
    country: "IL",
    country_long: "Israel",
    portCode: "ILAKL",
    portName: "ASHKELON",
    portContinent: "Middle East",
  },
  {
    lat: "31.804381",
    lng: "34.655314",
    country: "IL",
    country_long: "Israel",
    portCode: "ILASH",
    portName: "Ashdod",
    portContinent: "Middle East",
  },
  {
    lat: "32.497103",
    lng: "35.497336",
    country: "IL",
    country_long: "Israel",
    portCode: "ILBEI",
    portName: "BEITH SHEAN",
    portContinent: "Middle East",
  },
  {
    lat: "31.252973",
    lng: "34.791462",
    country: "IL",
    country_long: "Israel",
    portCode: "ILBEV",
    portName: "Beer Sheba",
    portContinent: "Middle East",
  },
  {
    lat: "32.519016",
    lng: "34.904544",
    country: "IL",
    country_long: "Israel",
    portCode: "ILCAE",
    portName: "Caesarea (ILCAE), Israel, Med",
    portContinent: "Middle East",
  },
  {
    lat: "31.069419",
    lng: "35.033363",
    country: "IL",
    country_long: "Israel",
    portCode: "ILDIM",
    portName: "DIMONA",
    portContinent: "Middle East",
  },
  {
    lat: "29.557669",
    lng: "34.951925",
    country: "IL",
    country_long: "Israel",
    portCode: "ILETH",
    portName: "Eilat (ILETH), Israel, Med",
    portContinent: "Middle East",
  },
  {
    lat: "32.4340458",
    lng: "34.9196518",
    country: "IL",
    country_long: "Israel",
    portCode: "ILHAD",
    portName: "Hadera (ILHAD), Israel, Med",
    portContinent: "Middle East",
  },
  {
    lat: "32.7940463",
    lng: "34.989571",
    country: "IL",
    country_long: "Israel",
    portCode: "ILHFA",
    portName: "Haifa",
    portContinent: "Middle East",
  },
  {
    lat: "32.015833",
    lng: "34.787384",
    country: "IL",
    country_long: "Israel",
    portCode: "ILHOL",
    portName: "HOLON",
    portContinent: "Middle East",
  },
  {
    lat: "32.162413",
    lng: "34.844675",
    country: "IL",
    country_long: "Israel",
    portCode: "ILIHA",
    portName: "Herziliyya (ILIHA), Israel, Med",
    portContinent: "Middle East",
  },
  {
    lat: "31.951014",
    lng: "34.888075",
    country: "IL",
    country_long: "Israel",
    portCode: "ILLOD",
    portName: "LOD",
    portContinent: "Middle East",
  },
  {
    lat: "32.8135081",
    lng: "35.0331041",
    country: "IL",
    country_long: "Israel",
    portCode: "ILMSP",
    portName: "Haifa Israel Shipyards Port (ILMSP), Haifa, Israel",
    portContinent: "Middle East",
  },
  {
    lat: "32.321458",
    lng: "34.853196",
    country: "IL",
    country_long: "Israel",
    portCode: "ILNAT",
    portName: "NETANYA",
    portContinent: "Middle East",
  },
  {
    lat: "32.084041",
    lng: "34.887762",
    country: "IL",
    country_long: "Israel",
    portCode: "ILPTQ",
    portName: "PETAH TIQWA",
    portContinent: "Middle East",
  },
  {
    lat: "32.137793",
    lng: "34.840278",
    country: "IL",
    country_long: "Israel",
    portCode: "ILRAM",
    portName: "RAMAT HASHARON",
    portContinent: "Middle East",
  },
  {
    lat: "31.892773",
    lng: "34.811272",
    country: "IL",
    country_long: "Israel",
    portCode: "ILREH",
    portName: "REHOVOT",
    portContinent: "Middle East",
  },
  {
    lat: "31.9730015",
    lng: "34.7925013",
    country: "IL",
    country_long: "Israel",
    portCode: "ILRLZ",
    portName: "RISHON LETSIYON",
    portContinent: "Middle East",
  },
  {
    lat: "32.0852999",
    lng: "34.78176759999999",
    country: "IL",
    country_long: "Israel",
    portCode: "ILTLV",
    portName: "Tel Aviv Yafo (ILTLV), Israel, Med",
    portContinent: "Middle East",
  },
  {
    lat: "32.492613",
    lng: "34.89315",
    country: "IL",
    country_long: "Israel",
    portCode: "ILYTD",
    portName: "Sedot Yam (ILYTD), Israel, Med",
    portContinent: "Middle East",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "IN5PA",
    portName: "Paneli",
    portContinent: "Asia",
  },
  {
    lat: "23.1919624",
    lng: "70.1649298",
    country: "IN",
    country_long: "India",
    portCode: "IN9BH",
    portName: "Bhimasar",
    portContinent: "Asia",
  },
  {
    lat: "19.6640624",
    lng: "78.5320107",
    country: "IN",
    country_long: "India",
    portCode: "INABD",
    portName: "Adilabad",
    portContinent: "Asia",
  },
  {
    lat: "18.6583989",
    lng: "72.8773346",
    country: "IN",
    country_long: "India",
    portCode: "INABG",
    portName: "Alibag",
    portContinent: "Asia",
  },
  {
    lat: "30.146861",
    lng: "74.2008185",
    country: "IN",
    country_long: "India",
    portCode: "INABH",
    portName: "Abohar",
    portContinent: "Asia",
  },
  {
    lat: "19.1825167",
    lng: "73.19260229999999",
    country: "IN",
    country_long: "India",
    portCode: "INABN",
    portName: "Ambernath",
    portContinent: "Asia",
  },
  {
    lat: "24.4640731",
    lng: "72.7717775",
    country: "IN",
    country_long: "India",
    portCode: "INABU",
    portName: "Abu Road",
    portContinent: "Asia",
  },
  {
    lat: "16.2037594",
    lng: "73.44361850000001",
    country: "IN",
    country_long: "India",
    portCode: "INACH",
    portName: "Achra",
    portContinent: "Asia",
  },
  {
    lat: "12.4077243",
    lng: "79.8170251",
    country: "IN",
    country_long: "India",
    portCode: "INACK",
    portName: "Acharapakkam",
    portContinent: "Asia",
  },
  {
    lat: "30.3610314",
    lng: "76.8485468",
    country: "IN",
    country_long: "India",
    portCode: "INACT",
    portName: "Ambala Cantonment",
    portContinent: "Asia",
  },
  {
    lat: "23.022505",
    lng: "72.5713621",
    country: "IN",
    country_long: "India",
    portCode: "INADC",
    portName: "Ccipl-SEZ/Ahmadabad",
    portContinent: "Asia",
  },
  {
    lat: "19.1178548",
    lng: "72.8631304",
    country: "IN",
    country_long: "India",
    portCode: "INADE",
    portName: "Andheri East",
    portContinent: "Asia",
  },
  {
    lat: "23.2228521",
    lng: "72.64725159999999",
    country: "IN",
    country_long: "India",
    portCode: "INADG",
    portName: "Gipl-SEZ/Ahmadabad",
    portContinent: "Asia",
  },
  {
    lat: "10.8126222",
    lng: "73.68010919999999",
    country: "IN",
    country_long: "India",
    portCode: "INADI",
    portName: "Androth Is",
    portContinent: "Asia",
  },
  {
    lat: "12.9880288",
    lng: "80.20471330000001",
    country: "IN",
    country_long: "India",
    portCode: "INADK",
    portName: "Adambakkam",
    portContinent: "Asia",
  },
  {
    lat: "10.0115043",
    lng: "76.9527836",
    country: "IN",
    country_long: "India",
    portCode: "INADL",
    portName: "Adimali",
    portContinent: "Asia",
  },
  {
    lat: "23.022505",
    lng: "72.5713621",
    country: "IN",
    country_long: "India",
    portCode: "INADM",
    portName: "Mrpl-SEZ/Ahmadabad",
    portContinent: "Asia",
  },
  {
    lat: "23.0732453",
    lng: "70.08807039999999",
    country: "IN",
    country_long: "India",
    portCode: "INADP",
    portName: "Adipur",
    portContinent: "Asia",
  },
  {
    lat: "23.022505",
    lng: "72.5713621",
    country: "IN",
    country_long: "India",
    portCode: "INADR",
    portName: "Cgrpl-SEZ/Ahmadabad",
    portContinent: "Asia",
  },
  {
    lat: "13.0011774",
    lng: "80.2564957",
    country: "IN",
    country_long: "India",
    portCode: "INADY",
    portName: "Adyar",
    portContinent: "Asia",
  },
  {
    lat: "25.8210232",
    lng: "74.7278544",
    country: "IN",
    country_long: "India",
    portCode: "INAGA",
    portName: "Agucha",
    portContinent: "Asia",
  },
  {
    lat: "27.1766701",
    lng: "78.00807449999999",
    country: "IN",
    country_long: "India",
    portCode: "INAGR",
    portName: "Agra",
    portContinent: "Asia",
  },
  {
    lat: "8.8646356",
    lng: "77.4960078",
    country: "IN",
    country_long: "India",
    portCode: "INAGU",
    portName: "Alangulam",
    portContinent: "Asia",
  },
  {
    lat: "10.8575607",
    lng: "72.1934421",
    country: "IN",
    country_long: "India",
    portCode: "INAGX",
    portName: "Agatti Island",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INAII",
    portName: "IGGSL SEZ/Thane",
    portContinent: "Asia",
  },
  {
    lat: "28.2489801",
    lng: "77.854903",
    country: "IN",
    country_long: "India",
    portCode: "INAIK",
    portName: "Ibrahimpur Icd/Khurja",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INAIR",
    portName: "SPPL SEZ/Thane",
    portContinent: "Asia",
  },
  {
    lat: "11.114153",
    lng: "72.720379",
    country: "IN",
    country_long: "India",
    portCode: "INAIS",
    portName: "Amini Island",
    portContinent: "Asia",
  },
  {
    lat: "23.1255718",
    lng: "70.0727481",
    country: "IN",
    country_long: "India",
    portCode: "INAJE",
    portName: "Welspun Anjar-SEZ/Anjar",
    portContinent: "Asia",
  },
  {
    lat: "26.4498954",
    lng: "74.6399163",
    country: "IN",
    country_long: "India",
    portCode: "INAJI",
    portName: "Ajmer",
    portContinent: "Asia",
  },
  {
    lat: "23.7307175",
    lng: "92.71731059999999",
    country: "IN",
    country_long: "India",
    portCode: "INAJL",
    portName: "Aizawl",
    portContinent: "Asia",
  },
  {
    lat: "23.0333675",
    lng: "72.56669",
    country: "IN",
    country_long: "India",
    portCode: "INAJM",
    portName: "Mundra Port SEZ",
    portContinent: "Asia",
  },
  {
    lat: "17.7083025",
    lng: "83.1526789",
    country: "IN",
    country_long: "India",
    portCode: "INAKB",
    portName: "BIACPL SEZ/Visakhapatnam",
    portContinent: "Asia",
  },
  {
    lat: "20.7002159",
    lng: "77.0081678",
    country: "IN",
    country_long: "India",
    portCode: "INAKD",
    portName: "Akola",
    portContinent: "Asia",
  },
  {
    lat: "13.0628291",
    lng: "77.44007739999999",
    country: "IN",
    country_long: "India",
    portCode: "INAKM",
    portName: "Adakamaranahalli",
    portContinent: "Asia",
  },
  {
    lat: "17.6868159",
    lng: "83.2184815",
    country: "IN",
    country_long: "India",
    portCode: "INAKP",
    portName: "APIICL SEZ/Visakhapatnam",
    portContinent: "Asia",
  },
  {
    lat: "17.7083025",
    lng: "83.1526789",
    country: "IN",
    country_long: "India",
    portCode: "INAKR",
    portName: "RPCIPL SEZ/Visakhapatnam",
    portContinent: "Asia",
  },
  {
    lat: "21.6264236",
    lng: "73.0151984",
    country: "IN",
    country_long: "India",
    portCode: "INAKV",
    portName: "Ankleshwar",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INALA",
    portName: "Alang SBY",
    portContinent: "Asia",
  },
  {
    lat: "9.498066699999999",
    lng: "76.3388484",
    country: "IN",
    country_long: "India",
    portCode: "INALF",
    portName: "Alleppey",
    portContinent: "Asia",
  },
  {
    lat: "27.8973944",
    lng: "78.0880129",
    country: "IN",
    country_long: "India",
    portCode: "INALG",
    portName: "Aligarh, IN",
    portContinent: "Asia",
  },
  {
    lat: "21.394581",
    lng: "72.17672139999999",
    country: "IN",
    country_long: "India",
    portCode: "INALI",
    portName: "Alang Sby  (INALI), Bhavnagar, India",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INALP",
    portName: "Alpl Cfs/Dadri",
    portContinent: "Asia",
  },
  {
    lat: "27.5529907",
    lng: "76.6345735",
    country: "IN",
    country_long: "India",
    portCode: "INALW",
    portName: "Alwar",
    portContinent: "Asia",
  },
  {
    lat: "18.0719301",
    lng: "75.34413289999999",
    country: "IN",
    country_long: "India",
    portCode: "INAMB",
    portName: "Ambad",
    portContinent: "Asia",
  },
  {
    lat: "23.022505",
    lng: "72.5713621",
    country: "IN",
    country_long: "India",
    portCode: "INAMD",
    portName: "Ahmedabad",
    portContinent: "Asia",
  },
  {
    lat: "26.1846572",
    lng: "91.6672238",
    country: "IN",
    country_long: "India",
    portCode: "INAMG",
    portName: "Amingaon (Gauhati)",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INAMI",
    portName: "Andorth Is",
    portContinent: "Asia",
  },
  {
    lat: "10.2760334",
    lng: "77.9203165",
    country: "IN",
    country_long: "India",
    portCode: "INAMR",
    portName: "Ambatturai (Ambathurai)",
    portContinent: "Asia",
  },
  {
    lat: "26.1540538",
    lng: "81.814238",
    country: "IN",
    country_long: "India",
    portCode: "INAMT",
    portName: "Amethi",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INANA",
    portName: "Ana",
    portContinent: "Asia",
  },
  {
    lat: "8.673439",
    lng: "76.7581794",
    country: "IN",
    country_long: "India",
    portCode: "INANG",
    portName: "Anijengo",
    portContinent: "Asia",
  },
  {
    lat: "19.4600623",
    lng: "72.7475415",
    country: "IN",
    country_long: "India",
    portCode: "INANL",
    portName: "Arnala",
    portContinent: "Asia",
  },
  {
    lat: "20.8444033",
    lng: "85.1510818",
    country: "IN",
    country_long: "India",
    portCode: "INANO",
    portName: "Angul",
    portContinent: "Asia",
  },
  {
    lat: "23.1136554",
    lng: "81.69762899999999",
    country: "IN",
    country_long: "India",
    portCode: "INANU",
    portName: "Anuppur",
    portContinent: "Asia",
  },
  {
    lat: "14.6818877",
    lng: "77.6005911",
    country: "IN",
    country_long: "India",
    portCode: "INAPH",
    portName: "Anantapur",
    portContinent: "Asia",
  },
  {
    lat: "23.022505",
    lng: "72.5713621",
    country: "IN",
    country_long: "India",
    portCode: "INAPI",
    portName: "Aap-SEZ/Ahmedabad",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INAPL",
    portName: "Acpl Cfs/Dadri",
    portContinent: "Asia",
  },
  {
    lat: "22.5251784",
    lng: "88.3308634",
    country: "IN",
    country_long: "India",
    portCode: "INAPR",
    portName: "Alipore",
    portContinent: "Asia",
  },
  {
    lat: "16.4694549",
    lng: "76.61527699999999",
    country: "IN",
    country_long: "India",
    portCode: "INAPT",
    portName: "Anaparti",
    portContinent: "Asia",
  },
  {
    lat: "11.1400585",
    lng: "79.0786281",
    country: "IN",
    country_long: "India",
    portCode: "INARI",
    portName: "Ariyalur",
    portContinent: "Asia",
  },
  {
    lat: "13.0840593",
    lng: "79.6704128",
    country: "IN",
    country_long: "India",
    portCode: "INARK",
    portName: "Arakkonam",
    portContinent: "Asia",
  },
  {
    lat: "9.878191",
    lng: "76.3038672",
    country: "IN",
    country_long: "India",
    portCode: "INARR",
    portName: "Aroor",
    portContinent: "Asia",
  },
  {
    lat: "31.2355318",
    lng: "76.5012734",
    country: "IN",
    country_long: "India",
    portCode: "INASH",
    portName: "Anandpur Sahib",
    portContinent: "Asia",
  },
  {
    lat: "22.9146173",
    lng: "72.5970569",
    country: "IN",
    country_long: "India",
    portCode: "INASL",
    portName: "Aslali",
    portContinent: "Asia",
  },
  {
    lat: "31.6339793",
    lng: "74.8722642",
    country: "IN",
    country_long: "India",
    portCode: "INATQ",
    portName: "Amritsar(ICD/CFS/SEZ) (INATQ), Amritsar, India",
    portContinent: "Asia",
  },
  {
    lat: "31.6572225",
    lng: "74.6392649",
    country: "IN",
    country_long: "India",
    portCode: "INATR",
    portName: "Attari Road",
    portContinent: "Asia",
  },
  {
    lat: "34.0557328",
    lng: "-118.4415378",
    country: "US",
    country_long: "United States",
    portCode: "INATT",
    portName: "Attari",
    portContinent: "Asia",
  },
  {
    lat: "11.5983184",
    lng: "78.59743519999999",
    country: "IN",
    country_long: "India",
    portCode: "INATU",
    portName: "Attur",
    portContinent: "Asia",
  },
  {
    lat: "26.4605377",
    lng: "79.5112528",
    country: "IN",
    country_long: "India",
    portCode: "INAUR",
    portName: "Auraiya",
    portContinent: "Asia",
  },
  {
    lat: "19.8761653",
    lng: "75.3433139",
    country: "IN",
    country_long: "India",
    portCode: "INAWM",
    portName: "Aurangabad",
    portContinent: "Asia",
  },
  {
    lat: "19.8761653",
    lng: "75.3433139",
    country: "IN",
    country_long: "India",
    portCode: "INAWW",
    portName: "WIDL SEZ / AURANGABAD",
    portContinent: "Asia",
  },
  {
    lat: "11.9170647",
    lng: "75.3353875",
    country: "IN",
    country_long: "India",
    portCode: "INAZK",
    portName: "Azhikkal",
    portContinent: "Asia",
  },
  {
    lat: "24.9698841",
    lng: "76.7336521",
    country: "IN",
    country_long: "India",
    portCode: "INBAA",
    portName: "Baran",
    portContinent: "Asia",
  },
  {
    lat: "31.13514159999999",
    lng: "76.3586636",
    country: "IN",
    country_long: "India",
    portCode: "INBAD",
    portName: "Bhaddi",
    portContinent: "Asia",
  },
  {
    lat: "23.2324214",
    lng: "87.8614793",
    country: "IN",
    country_long: "India",
    portCode: "INBAE",
    portName: "Bardhaman",
    portContinent: "Asia",
  },
  {
    lat: "20.7011108",
    lng: "83.4846069",
    country: "IN",
    country_long: "India",
    portCode: "INBAG",
    portName: "Balangir",
    portContinent: "Asia",
  },
  {
    lat: "28.6913756",
    lng: "76.9314064",
    country: "IN",
    country_long: "India",
    portCode: "INBAH",
    portName: "Bahadurgarh",
    portContinent: "Asia",
  },
  {
    lat: "18.972009",
    lng: "73.0348229",
    country: "IN",
    country_long: "India",
    portCode: "INBAI",
    portName: "IT-ITES-B-SEZ/Ulwe",
    portContinent: "Asia",
  },
  {
    lat: "25.8302463",
    lng: "72.24556989999999",
    country: "IN",
    country_long: "India",
    portCode: "INBAL",
    portName: "Balotra",
    portContinent: "Asia",
  },
  {
    lat: "18.972009",
    lng: "73.0348229",
    country: "IN",
    country_long: "India",
    portCode: "INBAM",
    portName: "Multi Services-SEZ/Ulwe",
    portContinent: "Asia",
  },
  {
    lat: "23.5461394",
    lng: "74.4349761",
    country: "IN",
    country_long: "India",
    portCode: "INBAN",
    portName: "Banswara",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBAP",
    portName: "Multi Services-SEZ/Dronagiri",
    portContinent: "Asia",
  },
  {
    lat: "18.903867",
    lng: "73.014335",
    country: "IN",
    country_long: "India",
    portCode: "INBAP6",
    portName: "Dronagiri Node",
    portContinent: "Asia",
  },
  {
    lat: "30.3819446",
    lng: "75.5467979",
    country: "IN",
    country_long: "India",
    portCode: "INBAR",
    portName: "Barnala",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBAS",
    portName: "Basti",
    portContinent: "Asia",
  },
  {
    lat: "18.972009",
    lng: "73.0348229",
    country: "IN",
    country_long: "India",
    portCode: "INBAT",
    portName: "IT-ITES-C-SEZ/Ulwe",
    portContinent: "Asia",
  },
  {
    lat: "18.972009",
    lng: "73.0348229",
    country: "IN",
    country_long: "India",
    portCode: "INBAU",
    portName: "IT-ITES-A-SEZ/Ulwe",
    portContinent: "Asia",
  },
  {
    lat: "28.1094308",
    lng: "76.6025423",
    country: "IN",
    country_long: "India",
    portCode: "INBAW",
    portName: "Bawal",
    portContinent: "Asia",
  },
  {
    lat: "32.63652190000001",
    lng: "74.9141351",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBBH",
    portName: "Bari Brahmana",
    portContinent: "Asia",
  },
  {
    lat: "20.2960587",
    lng: "85.8245398",
    country: "IN",
    country_long: "India",
    portCode: "INBBI",
    portName: "Bhubaneswar",
    portContinent: "Asia",
  },
  {
    lat: "15.918622",
    lng: "75.676082",
    country: "IN",
    country_long: "India",
    portCode: "INBBM",
    portName: "Badami",
    portContinent: "Asia",
  },
  {
    lat: "21.5154125",
    lng: "87.0774107",
    country: "IN",
    country_long: "India",
    portCode: "INBBP",
    portName: "Bahabal Pur",
    portContinent: "Asia",
  },
  {
    lat: "23.2930388",
    lng: "70.339045",
    country: "IN",
    country_long: "India",
    portCode: "INBCO",
    portName: "Bhachau",
    portContinent: "Asia",
  },
  {
    lat: "19.0595596",
    lng: "72.8295287",
    country: "IN",
    country_long: "India",
    portCode: "INBDA",
    portName: "Bandra",
    portContinent: "Asia",
  },
  {
    lat: "19.065884",
    lng: "72.86536699999999",
    country: "IN",
    country_long: "India",
    portCode: "INBDB",
    portName: "Precious Cargo Customs Clearance Centre/Bandra",
    portContinent: "Asia",
  },
  {
    lat: "11.601558",
    lng: "75.5919758",
    country: "IN",
    country_long: "India",
    portCode: "INBDG",
    portName: "Badagara",
    portContinent: "Asia",
  },
  {
    lat: "25.3804531",
    lng: "82.56769810000002",
    country: "IN",
    country_long: "India",
    portCode: "INBDH",
    portName: "Bhadohi",
    portContinent: "Asia",
  },
  {
    lat: "30.9578258",
    lng: "76.79135579999999",
    country: "IN",
    country_long: "India",
    portCode: "INBDI",
    portName: "Baddi",
    portContinent: "Asia",
  },
  {
    lat: "29.1724036",
    lng: "77.0283798",
    country: "IN",
    country_long: "India",
    portCode: "INBDM",
    portName: "ICD SONEPAT(PANCHI GUJARAN) (INBDM), Delhi, India",
    portContinent: "Asia",
  },
  {
    lat: "22.3071588",
    lng: "73.1812187",
    country: "IN",
    country_long: "India",
    portCode: "INBDQ",
    portName: "Vadodara",
    portContinent: "Asia",
  },
  {
    lat: "13.8685178",
    lng: "74.6341875",
    country: "IN",
    country_long: "India",
    portCode: "INBDR",
    portName: "Baindur",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBED",
    portName: "Bedi",
    portContinent: "Asia",
  },
  {
    lat: "18.990088",
    lng: "75.7531324",
    country: "IN",
    country_long: "India",
    portCode: "INBEE",
    portName: "Beed",
    portContinent: "Asia",
  },
  {
    lat: "28.3670355",
    lng: "79.4304381",
    country: "IN",
    country_long: "India",
    portCode: "INBEK",
    portName: "Bareilly",
    portContinent: "Asia",
  },
  {
    lat: "21.9011601",
    lng: "77.8960201",
    country: "IN",
    country_long: "India",
    portCode: "INBEL",
    portName: "Betul",
    portContinent: "Asia",
  },
  {
    lat: "15.1393932",
    lng: "76.9214428",
    country: "IN",
    country_long: "India",
    portCode: "INBEP",
    portName: "Bellary",
    portContinent: "Asia",
  },
  {
    lat: "21.9011601",
    lng: "77.8960201",
    country: "IN",
    country_long: "India",
    portCode: "INBET",
    portName: "Betul",
    portContinent: "Asia",
  },
  {
    lat: "11.173585",
    lng: "75.8040022",
    country: "IN",
    country_long: "India",
    portCode: "INBEY",
    portName: "Beypore (INBEY), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "28.2687913",
    lng: "77.3055772",
    country: "IN",
    country_long: "India",
    portCode: "INBFR",
    portName: "GRFL ICD/Ballabhgarh/Piyala, Faridabad, India",
    portContinent: "Asia",
  },
  {
    lat: "28.338774",
    lng: "77.3205629",
    country: "IN",
    country_long: "India",
    portCode: "INBGH",
    portName: "Ballabgarh",
    portContinent: "Asia",
  },
  {
    lat: "26.2475439",
    lng: "73.0222378",
    country: "IN",
    country_long: "India",
    portCode: "INBGK",
    portName: "Jodhpur-Bhagat Ki Kothi",
    portContinent: "Asia",
  },
  {
    lat: "25.1934906",
    lng: "90.634631",
    country: "IN",
    country_long: "India",
    portCode: "INBGM",
    portName: "Baghamara",
    portContinent: "Asia",
  },
  {
    lat: "15.8496953",
    lng: "74.4976741",
    country: "IN",
    country_long: "India",
    portCode: "INBGQ",
    portName: "Quest-SEZ/Belgaum",
    portContinent: "Asia",
  },
  {
    lat: "26.7360933",
    lng: "85.2774207",
    country: "IN",
    country_long: "India",
    portCode: "INBGU",
    portName: "Bairgania",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBGW",
    portName: "Bhagwa",
    portContinent: "Asia",
  },
  {
    lat: "27.2151863",
    lng: "77.5029996",
    country: "IN",
    country_long: "India",
    portCode: "INBHA",
    portName: "Bharatpur",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBHC",
    portName: "Jubilant-Chemical-SEZ/Vilayat",
    portContinent: "Asia",
  },
  {
    lat: "23.2226521",
    lng: "72.6466925",
    country: "IN",
    country_long: "India",
    portCode: "INBHD",
    portName: "Dahez SEZ",
    portContinent: "Asia",
  },
  {
    lat: "27.5705152",
    lng: "81.59766719999999",
    country: "IN",
    country_long: "India",
    portCode: "INBHH",
    portName: "Bahraich",
    portContinent: "Asia",
  },
  {
    lat: "21.1938475",
    lng: "81.3509416",
    country: "IN",
    country_long: "India",
    portCode: "INBHI",
    portName: "Bhilai",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBHJ",
    portName: "Bhuj",
    portContinent: "Asia",
  },
  {
    lat: "21.0573616",
    lng: "86.4962996",
    country: "IN",
    country_long: "India",
    portCode: "INBHK",
    portName: "Bhadrakh",
    portContinent: "Asia",
  },
  {
    lat: "25.3407388",
    lng: "74.63131829999999",
    country: "IN",
    country_long: "India",
    portCode: "INBHL",
    portName: "Bhilwara",
    portContinent: "Asia",
  },
  {
    lat: "17.9287283",
    lng: "83.4265542",
    country: "IN",
    country_long: "India",
    portCode: "INBHM",
    portName: "Port of Bheemunipatnam",
    portContinent: "Asia",
  },
  {
    lat: "26.5637768",
    lng: "78.78609159999999",
    country: "IN",
    country_long: "India",
    portCode: "INBHN",
    portName: "Bhind",
    portContinent: "Asia",
  },
  {
    lat: "23.2599333",
    lng: "77.412615",
    country: "IN",
    country_long: "India",
    portCode: "INBHO",
    portName: "Bhopal",
    portContinent: "Asia",
  },
  {
    lat: "29.941693",
    lng: "77.8141191",
    country: "IN",
    country_long: "India",
    portCode: "INBHP",
    portName: "Bhagwanpur",
    portContinent: "Asia",
  },
  {
    lat: "22.1788304",
    lng: "72.791016",
    country: "IN",
    country_long: "India",
    portCode: "INBHS",
    portName: "Sterling-SEZ/Bharuch",
    portContinent: "Asia",
  },
  {
    lat: "21.7644725",
    lng: "72.15193040000001",
    country: "IN",
    country_long: "India",
    portCode: "INBHU",
    portName: "Port of Bhavnagar",
    portContinent: "Asia",
  },
  {
    lat: "30.261393",
    lng: "76.0332686",
    country: "IN",
    country_long: "India",
    portCode: "INBHW",
    portName: "Bhawanigarh",
    portContinent: "Asia",
  },
  {
    lat: "17.9103939",
    lng: "77.51990789999999",
    country: "IN",
    country_long: "India",
    portCode: "INBID",
    portName: "Bidar",
    portContinent: "Asia",
  },
  {
    lat: "20.2767183",
    lng: "72.885432",
    country: "IN",
    country_long: "India",
    portCode: "INBIL",
    portName: "Bhilad",
    portContinent: "Asia",
  },
  {
    lat: "16.8301708",
    lng: "75.710031",
    country: "IN",
    country_long: "India",
    portCode: "INBJP",
    portName: "Bijapur",
    portContinent: "Asia",
  },
  {
    lat: "28.0229348",
    lng: "73.3119159",
    country: "IN",
    country_long: "India",
    portCode: "INBKB",
    portName: "Bikaner",
    portContinent: "Asia",
  },
  {
    lat: "23.2312686",
    lng: "87.07838749999999",
    country: "IN",
    country_long: "India",
    portCode: "INBKR",
    portName: "Bankura",
    portContinent: "Asia",
  },
  {
    lat: "17.979315",
    lng: "73.0486446",
    country: "IN",
    country_long: "India",
    portCode: "INBKT",
    portName: "Bankot",
    portContinent: "Asia",
  },
  {
    lat: "21.4933578",
    lng: "86.9134794",
    country: "IN",
    country_long: "India",
    portCode: "INBLE",
    portName: "Concor ICD/Balasore",
    portContinent: "Asia",
  },
  {
    lat: "17.4813993",
    lng: "78.44899350000001",
    country: "IN",
    country_long: "India",
    portCode: "INBLG",
    portName: "Balanagar",
    portContinent: "Asia",
  },
  {
    lat: "14.7068533",
    lng: "74.2663499",
    country: "IN",
    country_long: "India",
    portCode: "INBLK",
    portName: "Belekeri (INBLK), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.763119",
    lng: "84.14962200000001",
    country: "IN",
    country_long: "India",
    portCode: "INBLL",
    portName: "Ballia",
    portContinent: "Asia",
  },
  {
    lat: "20.7702311",
    lng: "72.9823779",
    country: "IN",
    country_long: "India",
    portCode: "INBLM",
    portName: "Bilimora",
    portContinent: "Asia",
  },
  {
    lat: "19.0237174",
    lng: "73.04039329999999",
    country: "IN",
    country_long: "India",
    portCode: "INBLP",
    portName: "Belapur",
    portContinent: "Asia",
  },
  {
    lat: "12.9715987",
    lng: "77.5945627",
    country: "IN",
    country_long: "India",
    portCode: "INBLR",
    portName: "Bangalore",
    portContinent: "Asia",
  },
  {
    lat: "21.4933578",
    lng: "86.9134794",
    country: "IN",
    country_long: "India",
    portCode: "INBLS",
    portName: "Balasore",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBLT",
    portName: "Balet",
    portContinent: "Asia",
  },
  {
    lat: "24.1991832",
    lng: "82.4522573",
    country: "IN",
    country_long: "India",
    portCode: "INBMP",
    portName: "Bargawan",
    portContinent: "Asia",
  },
  {
    lat: "25.7521467",
    lng: "71.3966865",
    country: "IN",
    country_long: "India",
    portCode: "INBMR",
    portName: "Barmer",
    portContinent: "Asia",
  },
  {
    lat: "16.544893",
    lng: "81.521241",
    country: "IN",
    country_long: "India",
    portCode: "INBMV",
    portName: "Bhimavaram",
    portContinent: "Asia",
  },
  {
    lat: "12.9715987",
    lng: "77.5945627",
    country: "IN",
    country_long: "India",
    portCode: "INBNC",
    portName: "Kbits-SEZ/Bangalore",
    portContinent: "Asia",
  },
  {
    lat: "3.1626191",
    lng: "99.32452339999999",
    country: "ID",
    country_long: "Indonesia",
    portCode: "INBND",
    portName: "Banddar",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBNI",
    portName: "Barauni",
    portContinent: "Asia",
  },
  {
    lat: "22.5057199",
    lng: "88.50817649999999",
    country: "IN",
    country_long: "India",
    portCode: "INBNK",
    portName: "Kolkata IT Park/Bantala",
    portContinent: "Asia",
  },
  {
    lat: "26.5124933",
    lng: "86.9586539",
    country: "IN",
    country_long: "India",
    portCode: "INBNR",
    portName: "Bhimnagar",
    portContinent: "Asia",
  },
  {
    lat: "22.572646",
    lng: "88.36389500000001",
    country: "IN",
    country_long: "India",
    portCode: "INBNW",
    portName: "Wipro SEZ/Kolkata",
    portContinent: "Asia",
  },
  {
    lat: "24.3341103",
    lng: "85.19588110000001",
    country: "IN",
    country_long: "India",
    portCode: "INBNY",
    portName: "Berhni",
    portContinent: "Asia",
  },
  {
    lat: "20.8594763",
    lng: "78.96983139999999",
    country: "IN",
    country_long: "India",
    portCode: "INBOK",
    portName: "Borkhedi",
    portContinent: "Asia",
  },
  {
    lat: "27.1928957",
    lng: "78.0231062",
    country: "IN",
    country_long: "India",
    portCode: "INBOL",
    portName: "Bolanganj",
    portContinent: "Asia",
  },
  {
    lat: "19.0759837",
    lng: "72.8776559",
    country: "IN",
    country_long: "India",
    portCode: "INBOM",
    portName: "Port of Mumbai",
    portContinent: "Asia",
  },
  {
    lat: "26.5030072",
    lng: "90.5535724",
    country: "IN",
    country_long: "India",
    portCode: "INBON",
    portName: "Bongaigaon",
    portContinent: "Asia",
  },
  {
    lat: "17.6623074",
    lng: "78.3636555",
    country: "IN",
    country_long: "India",
    portCode: "INBOP",
    portName: "Bonthapally",
    portContinent: "Asia",
  },
  {
    lat: "21.7097919",
    lng: "72.9927463",
    country: "IN",
    country_long: "India",
    portCode: "INBOU",
    portName: "Bauda",
    portContinent: "Asia",
  },
  {
    lat: "23.2599333",
    lng: "77.412615",
    country: "IN",
    country_long: "India",
    portCode: "INBPL",
    portName: "FPO BHOPAL/BHOPAL",
    portContinent: "Asia",
  },
  {
    lat: "26.2901608",
    lng: "86.9666397",
    country: "IN",
    country_long: "India",
    portCode: "INBPQ",
    portName: "Pratapganj",
    portContinent: "Asia",
  },
  {
    lat: "22.7926791",
    lng: "86.176548",
    country: "IN",
    country_long: "India",
    portCode: "INBPR",
    portName: "Bistupur",
    portContinent: "Asia",
  },
  {
    lat: "19.0759837",
    lng: "72.8776559",
    country: "IN",
    country_long: "India",
    portCode: "INBPS",
    portName: "Air Parcel Sorting Office/Mumbai",
    portContinent: "Asia",
  },
  {
    lat: "12.9923861",
    lng: "78.17675030000001",
    country: "IN",
    country_long: "India",
    portCode: "INBPT",
    portName: "Bangarapet",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBRA",
    portName: "Barsora",
    portContinent: "Asia",
  },
  {
    lat: "22.3071588",
    lng: "73.1812187",
    country: "IN",
    country_long: "India",
    portCode: "INBRC",
    portName: "Baroda",
    portContinent: "Asia",
  },
  {
    lat: "21.3470154",
    lng: "83.6320212",
    country: "IN",
    country_long: "India",
    portCode: "INBRG",
    portName: "Bargarh",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBRH",
    portName: "Broach",
    portContinent: "Asia",
  },
  {
    lat: "28.0229348",
    lng: "73.3119159",
    country: "IN",
    country_long: "India",
    portCode: "INBRI",
    portName: "Bikaner",
    portContinent: "Asia",
  },
  {
    lat: "22.3071588",
    lng: "73.1812187",
    country: "IN",
    country_long: "India",
    portCode: "INBRL6",
    portName: "Vadodara",
    portContinent: "Asia",
  },
  {
    lat: "18.5063813",
    lng: "72.9172209",
    country: "IN",
    country_long: "India",
    portCode: "INBRM",
    portName: "Borliai-Mandla",
    portContinent: "Asia",
  },
  {
    lat: "26.1735211",
    lng: "72.9342451",
    country: "IN",
    country_long: "India",
    portCode: "INBRN",
    portName: "Boranada, Jodhpur",
    portContinent: "Asia",
  },
  {
    lat: "26.3304209",
    lng: "91.0040547",
    country: "IN",
    country_long: "India",
    portCode: "INBRP",
    portName: "Barpeta",
    portContinent: "Asia",
  },
  {
    lat: "23.611174",
    lng: "83.610896",
    country: "IN",
    country_long: "India",
    portCode: "INBRR",
    portName: "Balrampur",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBRS",
    portName: "SE&C-LTD-SEZ/Waghodia",
    portContinent: "Asia",
  },
  {
    lat: "18.1791791",
    lng: "74.6077971",
    country: "IN",
    country_long: "India",
    portCode: "INBRT",
    portName: "Baramati",
    portContinent: "Asia",
  },
  {
    lat: "21.7051358",
    lng: "72.9958748",
    country: "IN",
    country_long: "India",
    portCode: "INBRU",
    portName: "Bharuch",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBRY",
    portName: "Borya",
    portContinent: "Asia",
  },
  {
    lat: "28.9910774",
    lng: "80.0800325",
    country: "IN",
    country_long: "India",
    portCode: "INBSA",
    portName: "Banbasa",
    portContinent: "Asia",
  },
  {
    lat: "23.6692956",
    lng: "86.15111200000001",
    country: "IN",
    country_long: "India",
    portCode: "INBSC",
    portName: "Bokaro Steel City",
    portContinent: "Asia",
  },
  {
    lat: "21.0417707",
    lng: "75.7875517",
    country: "IN",
    country_long: "India",
    portCode: "INBSL",
    portName: "Bhusawal",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBSN",
    portName: "Bassain",
    portContinent: "Asia",
  },
  {
    lat: "20.5992349",
    lng: "72.9342451",
    country: "IN",
    country_long: "India",
    portCode: "INBSR",
    portName: "Bulsar",
    portContinent: "Asia",
  },
  {
    lat: "30.210994",
    lng: "74.9454745",
    country: "IN",
    country_long: "India",
    portCode: "INBTD",
    portName: "Bathinda",
    portContinent: "Asia",
  },
  {
    lat: "13.999345",
    lng: "74.5407673",
    country: "IN",
    country_long: "India",
    portCode: "INBTK",
    portName: "Bhatkal",
    portContinent: "Asia",
  },
  {
    lat: "26.1286243",
    lng: "91.8012675",
    country: "IN",
    country_long: "India",
    portCode: "INBTL",
    portName: "Beltola",
    portContinent: "Asia",
  },
  {
    lat: "26.6296109",
    lng: "85.7699684",
    country: "N/A",
    country_long: "N/A",
    portCode: "INBTM",
    portName: "Bhithamore (Sursnad)",
    portContinent: "Asia",
  },
  {
    lat: "26.1175719",
    lng: "91.78431499999999",
    country: "IN",
    country_long: "India",
    portCode: "INBTP",
    portName: "Bhetapara",
    portContinent: "Asia",
  },
  {
    lat: "11.55",
    lng: "72.14999999999999",
    country: "IN",
    country_long: "India",
    portCode: "INBTR",
    portName: "Bitra Is",
    portContinent: "Asia",
  },
  {
    lat: "22.4736564",
    lng: "88.1739326",
    country: "IN",
    country_long: "India",
    portCode: "INBUD",
    portName: "Budge-Budge",
    portContinent: "Asia",
  },
  {
    lat: "31.53090379999999",
    lng: "74.9807852",
    country: "IN",
    country_long: "India",
    portCode: "INBUN",
    portName: "Bundala",
    portContinent: "Asia",
  },
  {
    lat: "30.210994",
    lng: "74.9454745",
    country: "IN",
    country_long: "India",
    portCode: "INBUP",
    portName: "Bhatinda",
    portContinent: "Asia",
  },
  {
    lat: "24.0982607",
    lng: "88.268411",
    country: "IN",
    country_long: "India",
    portCode: "INBUR",
    portName: "Baharampur",
    portContinent: "Asia",
  },
  {
    lat: "28.0311101",
    lng: "79.1271229",
    country: "IN",
    country_long: "India",
    portCode: "INBUU",
    portName: "Budaun",
    portContinent: "Asia",
  },
  {
    lat: "28.338774",
    lng: "77.3205629",
    country: "IN",
    country_long: "India",
    portCode: "INBVC",
    portName: "Concor-ICD/Ballabhgarh",
    portContinent: "Asia",
  },
  {
    lat: "28.7974684",
    lng: "76.1322058",
    country: "IN",
    country_long: "India",
    portCode: "INBW2",
    portName: "Bhiwani",
    portContinent: "Asia",
  },
  {
    lat: "28.2014149",
    lng: "76.8275503",
    country: "IN",
    country_long: "India",
    portCode: "INBWD",
    portName: "Bhiwadi",
    portContinent: "Asia",
  },
  {
    lat: "29.3641876",
    lng: "75.9056753",
    country: "IN",
    country_long: "India",
    portCode: "INBWL",
    portName: "Barwala",
    portContinent: "Asia",
  },
  {
    lat: "19.2812547",
    lng: "73.0482912",
    country: "IN",
    country_long: "India",
    portCode: "INBWN",
    portName: "Bhiwandi",
    portContinent: "Asia",
  },
  {
    lat: "27.0266498",
    lng: "74.6836958",
    country: "IN",
    country_long: "India",
    portCode: "INBWR",
    portName: "Borawar",
    portContinent: "Asia",
  },
  {
    lat: "28.5261267",
    lng: "77.079966",
    country: "IN",
    country_long: "India",
    portCode: "INBWS",
    portName: "Afs/Kapashera",
    portContinent: "Asia",
  },
  {
    lat: "19.2990485",
    lng: "72.8547727",
    country: "IN",
    country_long: "India",
    portCode: "INBYD",
    portName: "Bhayandar",
    portContinent: "Asia",
  },
  {
    lat: "22.4535669",
    lng: "69.1011415",
    country: "IN",
    country_long: "India",
    portCode: "INBYT",
    portName: "Beyt (INBYT), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "20.3097149",
    lng: "85.8192643",
    country: "IN",
    country_long: "India",
    portCode: "INCAG",
    portName: "Tata Steel SEZ/Ganjam",
    portContinent: "Asia",
  },
  {
    lat: "22.3180817",
    lng: "72.6189845",
    country: "IN",
    country_long: "India",
    portCode: "INCAM",
    portName: "Cambay",
    portContinent: "Asia",
  },
  {
    lat: "18.3386627",
    lng: "84.121084",
    country: "IN",
    country_long: "India",
    portCode: "INCAP",
    portName: "Port of Calingapatnam",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INCAR",
    portName: "Carijam",
    portContinent: "Asia",
  },
  {
    lat: "19.387389",
    lng: "85.0515413",
    country: "IN",
    country_long: "India",
    portCode: "INCAS",
    portName: "SAPL SEZ/Ganjam",
    portContinent: "Asia",
  },
  {
    lat: "11.1016976",
    lng: "76.9887377",
    country: "IN",
    country_long: "India",
    portCode: "INCBC",
    portName: "CHIPL SEZ/Coimbatore",
    portContinent: "Asia",
  },
  {
    lat: "9.1573175",
    lng: "92.7580701",
    country: "IN",
    country_long: "India",
    portCode: "INCBD",
    portName: "Car Nicobar",
    portContinent: "Asia",
  },
  {
    lat: "20.7739916",
    lng: "86.7436951",
    country: "IN",
    country_long: "India",
    portCode: "INCBL",
    portName: "Chandbali",
    portContinent: "Asia",
  },
  {
    lat: "11.1071056",
    lng: "76.9909093",
    country: "IN",
    country_long: "India",
    portCode: "INCBS",
    portName: "SE&C LTD-SEZ/Coimbatore",
    portContinent: "Asia",
  },
  {
    lat: "11.1071056",
    lng: "76.9909093",
    country: "IN",
    country_long: "India",
    portCode: "INCBT",
    portName: "TDPL SEZ/Coimbatore",
    portContinent: "Asia",
  },
  {
    lat: "18.5912716",
    lng: "73.73890899999999",
    country: "IN",
    country_long: "India",
    portCode: "INCCI",
    portName: "Wipro Sez/Hinjewadi",
    portContinent: "Asia",
  },
  {
    lat: "11.2587531",
    lng: "75.78041",
    country: "IN",
    country_long: "India",
    portCode: "INCCJ",
    portName: "Kozhikode (ex Calicut)",
    portContinent: "Asia",
  },
  {
    lat: "11.2587531",
    lng: "75.78041",
    country: "IN",
    country_long: "India",
    portCode: "INCCT",
    portName: "Kinfrafp-SEZ/Kozhikkode",
    portContinent: "Asia",
  },
  {
    lat: "22.5460718",
    lng: "88.3148903",
    country: "IN",
    country_long: "India",
    portCode: "INCCU",
    portName: "Port of Kolkata",
    portContinent: "Asia",
  },
  {
    lat: "30.7321429",
    lng: "76.788799",
    country: "IN",
    country_long: "India",
    portCode: "INCDC",
    portName: "DCA-II SEZ/Chandigarh",
    portContinent: "Asia",
  },
  {
    lat: "30.7321429",
    lng: "76.788799",
    country: "IN",
    country_long: "India",
    portCode: "INCDD",
    portName: "DCA-I SEZ/Chandigarh",
    portContinent: "Asia",
  },
  {
    lat: "11.7480419",
    lng: "79.7713687",
    country: "IN",
    country_long: "India",
    portCode: "INCDL",
    portName: "Port of Cuddalore",
    portContinent: "Asia",
  },
  {
    lat: "11.4070449",
    lng: "79.6912455",
    country: "IN",
    country_long: "India",
    portCode: "INCDM",
    portName: "Chidambaram",
    portContinent: "Asia",
  },
  {
    lat: "14.4673154",
    lng: "78.8242089",
    country: "IN",
    country_long: "India",
    portCode: "INCDP",
    portName: "Cuddapah",
    portContinent: "Asia",
  },
  {
    lat: "11.1071056",
    lng: "76.9909093",
    country: "IN",
    country_long: "India",
    portCode: "INCEC",
    portName: "ECTN SEZ/Coimbatore",
    portContinent: "Asia",
  },
  {
    lat: "12.818456",
    lng: "79.6946586",
    country: "IN",
    country_long: "India",
    portCode: "INCFI",
    portName: "FIPL SEZ/Kancheepuram",
    portContinent: "Asia",
  },
  {
    lat: "12.6819372",
    lng: "79.98884129999999",
    country: "IN",
    country_long: "India",
    portCode: "INCGA",
    portName: "MWCDL-Apparels-SEZ/Chengalpattu",
    portContinent: "Asia",
  },
  {
    lat: "12.818456",
    lng: "79.6946586",
    country: "IN",
    country_long: "India",
    portCode: "INCGE",
    portName: "ETA SEZ/Kancheepuram",
    portContinent: "Asia",
  },
  {
    lat: "12.6819372",
    lng: "79.98884129999999",
    country: "IN",
    country_long: "India",
    portCode: "INCGI",
    portName: "MWCDL-IT-SEZ/Chengalpattu",
    portContinent: "Asia",
  },
  {
    lat: "12.6819372",
    lng: "79.98884129999999",
    country: "IN",
    country_long: "India",
    portCode: "INCGL",
    portName: "MWCDL-Auto-Ancillaries-SEZ/Chengalpattu",
    portContinent: "Asia",
  },
  {
    lat: "9.318327499999999",
    lng: "76.61108449999999",
    country: "IN",
    country_long: "India",
    portCode: "INCGN",
    portName: "Chengannur",
    portContinent: "Asia",
  },
  {
    lat: "19.9615398",
    lng: "79.2961468",
    country: "IN",
    country_long: "India",
    portCode: "INCHA",
    portName: "Chandrapur",
    portContinent: "Asia",
  },
  {
    lat: "9.446494099999999",
    lng: "76.54021670000002",
    country: "IN",
    country_long: "India",
    portCode: "INCHG",
    portName: "Changanacherry",
    portContinent: "Asia",
  },
  {
    lat: "24.9167821",
    lng: "79.5910058",
    country: "IN",
    country_long: "India",
    portCode: "INCHH",
    portName: "Chhatarpur",
    portContinent: "Asia",
  },
  {
    lat: "13.4354985",
    lng: "77.7315344",
    country: "IN",
    country_long: "India",
    portCode: "INCHI",
    portName: "Chikaballur",
    portContinent: "Asia",
  },
  {
    lat: "20.7246547",
    lng: "78.6027544",
    country: "IN",
    country_long: "India",
    portCode: "INCHJ",
    portName: "WWIL ICD/Wardha",
    portContinent: "Asia",
  },
  {
    lat: "13.0918726",
    lng: "80.264191",
    country: "IN",
    country_long: "India",
    portCode: "INCHL",
    portName: "Choolai",
    portContinent: "Asia",
  },
  {
    lat: "26.8114533",
    lng: "89.0437577",
    country: "IN",
    country_long: "India",
    portCode: "INCHM",
    portName: "Chamurci",
    portContinent: "Asia",
  },
  {
    lat: "22.3632712",
    lng: "73.1658033",
    country: "IN",
    country_long: "India",
    portCode: "INCHN",
    portName: "Chhani/Vadodora",
    portContinent: "Asia",
  },
  {
    lat: "24.8829177",
    lng: "74.6229699",
    country: "IN",
    country_long: "India",
    portCode: "INCHO",
    portName: "Chittorgarh",
    portContinent: "Asia",
  },
  {
    lat: "23.4565708",
    lng: "93.32819289999999",
    country: "IN",
    country_long: "India",
    portCode: "INCHP",
    portName: "Champai",
    portContinent: "Asia",
  },
  {
    lat: "15.6021217",
    lng: "73.7433727",
    country: "IN",
    country_long: "India",
    portCode: "INCHR",
    portName: "Chapora",
    portContinent: "Asia",
  },
  {
    lat: "11.0517908",
    lng: "79.4169356",
    country: "IN",
    country_long: "India",
    portCode: "INCHS",
    portName: "Cholapuram",
    portContinent: "Asia",
  },
  {
    lat: "13.217176",
    lng: "79.1003289",
    country: "IN",
    country_long: "India",
    portCode: "INCHT",
    portName: "Chittoor",
    portContinent: "Asia",
  },
  {
    lat: "28.2925364",
    lng: "74.9707262",
    country: "IN",
    country_long: "India",
    portCode: "INCHU",
    portName: "Churu",
    portContinent: "Asia",
  },
  {
    lat: "18.6386517",
    lng: "73.7951573",
    country: "IN",
    country_long: "India",
    portCode: "INCHV",
    portName: "Chinchvad",
    portContinent: "Asia",
  },
  {
    lat: "28.2415999",
    lng: "75.6499025",
    country: "IN",
    country_long: "India",
    portCode: "INCHW",
    portName: "Chirawa",
    portContinent: "Asia",
  },
  {
    lat: "15.5855375",
    lng: "79.8671813",
    country: "IN",
    country_long: "India",
    portCode: "INCHY",
    portName: "Chimakurthy",
    portContinent: "Asia",
  },
  {
    lat: "12.818456",
    lng: "79.6946586",
    country: "IN",
    country_long: "India",
    portCode: "INCJA",
    portName: "AEIP SEZ/Kancheepuram",
    portContinent: "Asia",
  },
  {
    lat: "11.0168445",
    lng: "76.9558321",
    country: "IN",
    country_long: "India",
    portCode: "INCJB",
    portName: "Coimbatore",
    portContinent: "Asia",
  },
  {
    lat: "12.818456",
    lng: "79.6946586",
    country: "IN",
    country_long: "India",
    portCode: "INCJC",
    portName: "NCTL(ENGG) SEZ/Kancheepuram",
    portContinent: "Asia",
  },
  {
    lat: "12.818456",
    lng: "79.6946586",
    country: "IN",
    country_long: "India",
    portCode: "INCJD",
    portName: "DLFC SEZ/Kancheepuram",
    portContinent: "Asia",
  },
  {
    lat: "12.818456",
    lng: "79.6946586",
    country: "IN",
    country_long: "India",
    portCode: "INCJE",
    portName: "ECTN SEZ/Kancheepuram",
    portContinent: "Asia",
  },
  {
    lat: "12.9624975",
    lng: "79.94527479999999",
    country: "IN",
    country_long: "India",
    portCode: "INCJF",
    portName: "Ftil-SEZ/Sriperumbudur",
    portContinent: "Asia",
  },
  {
    lat: "12.9624975",
    lng: "79.94527479999999",
    country: "IN",
    country_long: "India",
    portCode: "INCJG",
    portName: "JGHIPL SEZ/Sriperumbudur",
    portContinent: "Asia",
  },
  {
    lat: "12.818456",
    lng: "79.6946586",
    country: "IN",
    country_long: "India",
    portCode: "INCJI",
    portName: "IG3I SEZ/Kancheepuram",
    portContinent: "Asia",
  },
  {
    lat: "13.0231254",
    lng: "79.96321569999999",
    country: "IN",
    country_long: "India",
    portCode: "INCJJ",
    portName: "JMFTZ-SEZ/Mannur",
    portContinent: "Asia",
  },
  {
    lat: "12.9624975",
    lng: "79.94527479999999",
    country: "IN",
    country_long: "India",
    portCode: "INCJN",
    portName: "Nipl-SEZ/Sriperumbudur",
    portContinent: "Asia",
  },
  {
    lat: "12.6482834",
    lng: "80.0386783",
    country: "IN",
    country_long: "India",
    portCode: "INCJO",
    portName: "Sipcot-Hi-Tech-SEZ-Oragadam/Sriperumbudur",
    portContinent: "Asia",
  },
  {
    lat: "12.9213826",
    lng: "79.88214529999999",
    country: "IN",
    country_long: "India",
    portCode: "INCJS",
    portName: "Sipcot-Hi-Tech-SEZ/Sriperumbudur",
    portContinent: "Asia",
  },
  {
    lat: "12.818456",
    lng: "79.6946586",
    country: "IN",
    country_long: "India",
    portCode: "INCJV",
    portName: "VTPL SEZ/Kancheepuram",
    portContinent: "Asia",
  },
  {
    lat: "23.747887",
    lng: "86.7868768",
    country: "IN",
    country_long: "India",
    portCode: "INCKA",
    portName: "Chirkunda",
    portContinent: "Asia",
  },
  {
    lat: "19.8852192",
    lng: "75.382629",
    country: "IN",
    country_long: "India",
    portCode: "INCKH",
    portName: "Chikalthana",
    portContinent: "Asia",
  },
  {
    lat: "18.7632075",
    lng: "73.8613203",
    country: "IN",
    country_long: "India",
    portCode: "INCKN",
    portName: "Chakan",
    portContinent: "Asia",
  },
  {
    lat: "15.8166616",
    lng: "80.35869",
    country: "IN",
    country_long: "India",
    portCode: "INCLX",
    portName: "Chirala",
    portContinent: "Asia",
  },
  {
    lat: "28.1371007",
    lng: "76.3470861",
    country: "IN",
    country_long: "India",
    portCode: "INCML",
    portName: "Kathuwas (INCML), Alwar, India",
    portContinent: "Asia",
  },
  {
    lat: "12.818456",
    lng: "79.6946586",
    country: "IN",
    country_long: "India",
    portCode: "INCNC",
    portName: "NCTL SEZ/Kancheepuram",
    portContinent: "Asia",
  },
  {
    lat: "11.8744775",
    lng: "75.37036619999999",
    country: "IN",
    country_long: "India",
    portCode: "INCNN",
    portName: "Cannanore",
    portContinent: "Asia",
  },
  {
    lat: "11.3530022",
    lng: "76.7959095",
    country: "IN",
    country_long: "India",
    portCode: "INCNR",
    portName: "Coonoor",
    portContinent: "Asia",
  },
  {
    lat: "16.9463553",
    lng: "82.2383986",
    country: "IN",
    country_long: "India",
    portCode: "INCOA",
    portName: "Kspl-SEZ/Kakinada",
    portContinent: "Asia",
  },
  {
    lat: "26.3452397",
    lng: "89.4482079",
    country: "IN",
    country_long: "India",
    portCode: "INCOH",
    portName: "Cooch Behar",
    portContinent: "Asia",
  },
  {
    lat: "9.9546345",
    lng: "76.2678251",
    country: "IN",
    country_long: "India",
    portCode: "INCOK",
    portName: "Port of Cochin",
    portContinent: "Asia",
  },
  {
    lat: "8.1786199",
    lng: "77.2560974",
    country: "IN",
    country_long: "India",
    portCode: "INCOL",
    portName: "Colochel",
    portContinent: "Asia",
  },
  {
    lat: "21.778109",
    lng: "87.7517427",
    country: "IN",
    country_long: "India",
    portCode: "INCON",
    portName: "Contai",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INCOO",
    portName: "Coondapur (Ganguly)",
    portContinent: "Asia",
  },
  {
    lat: "17.1255193",
    lng: "82.3669027",
    country: "IN",
    country_long: "India",
    portCode: "INCOP",
    portName: "Picpl-SEZ/Kakinada",
    portContinent: "Asia",
  },
  {
    lat: "22.6235612",
    lng: "88.3724567",
    country: "IN",
    country_long: "India",
    portCode: "INCOS",
    portName: "Cossipore",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INCPC",
    portName: "Cgml Cfs/Dadri",
    portContinent: "Asia",
  },
  {
    lat: "17.5319037",
    lng: "73.5151341",
    country: "IN",
    country_long: "India",
    portCode: "INCPL",
    portName: "Chiplun",
    portContinent: "Asia",
  },
  {
    lat: "30.7597959",
    lng: "76.1275872",
    country: "IN",
    country_long: "India",
    portCode: "INCPR",
    portName: "Chawapail ICD/Samrala",
    portContinent: "Asia",
  },
  {
    lat: "12.6819372",
    lng: "79.98884129999999",
    country: "IN",
    country_long: "India",
    portCode: "INCPU",
    portName: "Chengalpattu",
    portContinent: "Asia",
  },
  {
    lat: "26.4241471",
    lng: "88.9150408",
    country: "IN",
    country_long: "India",
    portCode: "INCRB",
    portName: "Changrabandha",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INCRL",
    portName: "Rga Sez/Chikkankannelli",
    portContinent: "Asia",
  },
  {
    lat: "-37.0124082",
    lng: "174.6044672",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "INCRN",
    portName: "Cornwallis",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INCRX",
    portName: "Loksan Lcs",
    portContinent: "Asia",
  },
  {
    lat: "19.0903694",
    lng: "72.86275189999999",
    country: "IN",
    country_long: "India",
    portCode: "INCSI",
    portName: "Chattrapati Shivaji International Apt",
    portContinent: "Asia",
  },
  {
    lat: "23.7192097",
    lng: "72.1090303",
    country: "IN",
    country_long: "India",
    portCode: "INCSM",
    portName: "Chanasma",
    portContinent: "Asia",
  },
  {
    lat: "12.818456",
    lng: "79.6946586",
    country: "IN",
    country_long: "India",
    portCode: "INCSP",
    portName: "SIPL SEZ/Kancheepuram",
    portContinent: "Asia",
  },
  {
    lat: "18.639101",
    lng: "73.7942015",
    country: "IN",
    country_long: "India",
    portCode: "INCST",
    portName: "Chinchwad Station",
    portContinent: "Asia",
  },
  {
    lat: "11.0510005",
    lng: "76.9821726",
    country: "IN",
    country_long: "India",
    portCode: "INCSV",
    portName: "SVPL SEZ/Coimbatore",
    portContinent: "Asia",
  },
  {
    lat: "11.6907064",
    lng: "72.7111939",
    country: "IN",
    country_long: "India",
    portCode: "INCTI",
    portName: "Chetlat Is",
    portContinent: "Asia",
  },
  {
    lat: "20.462521",
    lng: "85.8829895",
    country: "IN",
    country_long: "India",
    portCode: "INCUT",
    portName: "Cuttack",
    portContinent: "Asia",
  },
  {
    lat: "20.1808672",
    lng: "73.0169135",
    country: "IN",
    country_long: "India",
    portCode: "INDAD",
    portName: "Dadra",
    portContinent: "Asia",
  },
  {
    lat: "27.9853068",
    lng: "94.22190289999999",
    country: "N/A",
    country_long: "N/A",
    portCode: "INDAE",
    portName: "Daparizo",
    portContinent: "Asia",
  },
  {
    lat: "21.7132151",
    lng: "72.5842502",
    country: "IN",
    country_long: "India",
    portCode: "INDAH",
    portName: "Port of Dahej",
    portContinent: "Asia",
  },
  {
    lat: "27.0410218",
    lng: "88.2662745",
    country: "IN",
    country_long: "India",
    portCode: "INDAI",
    portName: "Darjeeling",
    portContinent: "Asia",
  },
  {
    lat: "20.3973736",
    lng: "72.8327991",
    country: "IN",
    country_long: "India",
    portCode: "INDAM",
    portName: "Daman",
    portContinent: "Asia",
  },
  {
    lat: "23.8323022",
    lng: "79.4386591",
    country: "IN",
    country_long: "India",
    portCode: "INDAO",
    portName: "Damoh",
    portContinent: "Asia",
  },
  {
    lat: "28.5919008",
    lng: "77.4368585",
    country: "IN",
    country_long: "India",
    portCode: "INDAR",
    portName: "Artha Sez/Greater Noida",
    portContinent: "Asia",
  },
  {
    lat: "25.6653168",
    lng: "78.4609182",
    country: "IN",
    country_long: "India",
    portCode: "INDAT",
    portName: "Datia",
    portContinent: "Asia",
  },
  {
    lat: "26.8996953",
    lng: "76.332411",
    country: "IN",
    country_long: "India",
    portCode: "INDAU",
    portName: "Dausa",
    portContinent: "Asia",
  },
  {
    lat: "30.5886835",
    lng: "76.8470955",
    country: "IN",
    country_long: "India",
    portCode: "INDBA",
    portName: "Dera Bassi",
    portContinent: "Asia",
  },
  {
    lat: "23.7956531",
    lng: "86.43038589999999",
    country: "IN",
    country_long: "India",
    portCode: "INDBD",
    portName: "Dhanbad",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INDBS",
    portName: "Santa-SEZ/Muppireddipally",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INDCT",
    portName: "Dadri Container Terminal",
    portContinent: "Asia",
  },
  {
    lat: "30.8795368",
    lng: "75.9157905",
    country: "IN",
    country_long: "India",
    portCode: "INDDL",
    portName: "PSWC ICD/Dhandari Kalan",
    portContinent: "Asia",
  },
  {
    lat: "30.8636071",
    lng: "75.9194278",
    country: "IN",
    country_long: "India",
    portCode: "INDDL6",
    portName: "Dhandarikalan",
    portContinent: "Asia",
  },
  {
    lat: "21.5383136",
    lng: "84.7289442",
    country: "IN",
    country_long: "India",
    portCode: "INDEB",
    portName: "Deogarh",
    portContinent: "Asia",
  },
  {
    lat: "30.3164945",
    lng: "78.03219179999999",
    country: "IN",
    country_long: "India",
    portCode: "INDED",
    portName: "Dehra Dun",
    portContinent: "Asia",
  },
  {
    lat: "24.256893",
    lng: "72.1824293",
    country: "IN",
    country_long: "India",
    portCode: "INDEE",
    portName: "Deesa (Disa)",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INDEF",
    portName: "Infosys Sez/Dadri",
    portContinent: "Asia",
  },
  {
    lat: "16.3910713",
    lng: "73.5122928",
    country: "IN",
    country_long: "India",
    portCode: "INDEG",
    portName: "Deogad",
    portContinent: "Asia",
  },
  {
    lat: "28.7040592",
    lng: "77.10249019999999",
    country: "IN",
    country_long: "India",
    portCode: "INDEL",
    portName: "Delhi",
    portContinent: "Asia",
  },
  {
    lat: "26.5024286",
    lng: "83.7791283",
    country: "IN",
    country_long: "India",
    portCode: "INDEO",
    portName: "Deoria",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INDEP",
    portName: "Deparizo",
    portContinent: "Asia",
  },
  {
    lat: "28.5525772",
    lng: "77.55481",
    country: "IN",
    country_long: "India",
    portCode: "INDER",
    portName: "Dadri ICD/Noida(ICD/CFS/SEZ) (INDER), Delhi, India",
    portContinent: "Asia",
  },
  {
    lat: "22.9675929",
    lng: "76.0534454",
    country: "IN",
    country_long: "India",
    portCode: "INDEW",
    portName: "Dewas",
    portContinent: "Asia",
  },
  {
    lat: "23.841668",
    lng: "73.7146623",
    country: "IN",
    country_long: "India",
    portCode: "INDGP",
    portName: "Dungapur",
    portContinent: "Asia",
  },
  {
    lat: "22.6437515",
    lng: "75.71679619999999",
    country: "IN",
    country_long: "India",
    portCode: "INDHA",
    portName: "Dhannad",
    portContinent: "Asia",
  },
  {
    lat: "26.0206982",
    lng: "89.9743463",
    country: "IN",
    country_long: "India",
    portCode: "INDHB",
    portName: "Dhubri Steamerghat",
    portContinent: "Asia",
  },
  {
    lat: "27.4811106",
    lng: "94.55728470000001",
    country: "IN",
    country_long: "India",
    portCode: "INDHE",
    portName: "Dhemaji",
    portContinent: "Asia",
  },
  {
    lat: "22.7349352",
    lng: "72.4402368",
    country: "IN",
    country_long: "India",
    portCode: "INDHK",
    portName: "Dholka",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INDHL",
    portName: "Dhalaighat",
    portContinent: "Asia",
  },
  {
    lat: "32.219042",
    lng: "76.3234037",
    country: "IN",
    country_long: "India",
    portCode: "INDHM",
    portName: "Dharamsala",
    portContinent: "Asia",
  },
  {
    lat: "26.6965521",
    lng: "77.8907576",
    country: "IN",
    country_long: "India",
    portCode: "INDHO",
    portName: "Dholpur",
    portContinent: "Asia",
  },
  {
    lat: "17.5872656",
    lng: "73.1747877",
    country: "IN",
    country_long: "India",
    portCode: "INDHP",
    portName: "Dabhol (INDHP), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "22.2498636",
    lng: "72.1934421",
    country: "IN",
    country_long: "India",
    portCode: "INDHR",
    portName: "Port of Dholera",
    portContinent: "Asia",
  },
  {
    lat: "19.9902668",
    lng: "72.7396586",
    country: "IN",
    country_long: "India",
    portCode: "INDHU",
    portName: "Dahanu",
    portContinent: "Asia",
  },
  {
    lat: "15.4589236",
    lng: "75.007808",
    country: "IN",
    country_long: "India",
    portCode: "INDHW",
    portName: "Dharwad",
    portContinent: "Asia",
  },
  {
    lat: "27.4728327",
    lng: "94.9119621",
    country: "IN",
    country_long: "India",
    portCode: "INDIB",
    portName: "Dibrugarh",
    portContinent: "Asia",
  },
  {
    lat: "19.1382514",
    lng: "77.3209555",
    country: "IN",
    country_long: "India",
    portCode: "INDID",
    portName: "Nanded",
    portContinent: "Asia",
  },
  {
    lat: "18.6148698",
    lng: "73.8729867",
    country: "IN",
    country_long: "India",
    portCode: "INDIG",
    portName: "Dighi (Pune) (INDIG), Pune, India",
    portContinent: "Asia",
  },
  {
    lat: "10.3623794",
    lng: "77.9694579",
    country: "IN",
    country_long: "India",
    portCode: "INDIL",
    portName: "Dindigul",
    portContinent: "Asia",
  },
  {
    lat: "22.9417931",
    lng: "81.07684549999999",
    country: "IN",
    country_long: "India",
    portCode: "INDIN",
    portName: "Dindori",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INDIU",
    portName: "Diu",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INDIV",
    portName: "Div",
    portContinent: "Asia",
  },
  {
    lat: "29.8473422",
    lng: "80.5369058",
    country: "IN",
    country_long: "India",
    portCode: "INDLA",
    portName: "Dharchula",
    portContinent: "Asia",
  },
  {
    lat: "19.946133",
    lng: "75.22165199999999",
    country: "IN",
    country_long: "India",
    portCode: "INDLB",
    portName: "Daulatabad",
    portContinent: "Asia",
  },
  {
    lat: "20.9042201",
    lng: "74.7748979",
    country: "IN",
    country_long: "India",
    portCode: "INDLE",
    portName: "Dhule",
    portContinent: "Asia",
  },
  {
    lat: "12.9715987",
    lng: "77.5945627",
    country: "IN",
    country_long: "India",
    portCode: "INDLH",
    portName: "Apllp Sez/Bangalore",
    portContinent: "Asia",
  },
  {
    lat: "26.7057814",
    lng: "89.13725269999999",
    country: "IN",
    country_long: "India",
    portCode: "INDLO",
    portName: "Birpara Lcs",
    portContinent: "Asia",
  },
  {
    lat: "25.20839",
    lng: "90.230948",
    country: "IN",
    country_long: "India",
    portCode: "INDLU",
    portName: "Dalu",
    portContinent: "Asia",
  },
  {
    lat: "20.7953311",
    lng: "86.8966588",
    country: "IN",
    country_long: "India",
    portCode: "INDMA",
    portName: "Dhamara",
    portContinent: "Asia",
  },
  {
    lat: "20.8236875",
    lng: "86.9640469",
    country: "IN",
    country_long: "India",
    portCode: "INDMQ",
    portName: "Dhamra Port",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INDMR",
    portName: "Demagir",
    portContinent: "Asia",
  },
  {
    lat: "18.777136",
    lng: "72.9637728",
    country: "IN",
    country_long: "India",
    portCode: "INDMT",
    portName: "Dharamtar (INDMT), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.9091406",
    lng: "93.72656049999999",
    country: "IN",
    country_long: "India",
    portCode: "INDMU",
    portName: "Dimapur",
    portContinent: "Asia",
  },
  {
    lat: "20.6504753",
    lng: "85.5981223",
    country: "IN",
    country_long: "India",
    portCode: "INDNK",
    portName: "Dhenkanal",
    portContinent: "Asia",
  },
  {
    lat: "12.1210997",
    lng: "78.1582143",
    country: "IN",
    country_long: "India",
    portCode: "INDPI",
    portName: "Dharmapuri",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INDPM",
    portName: "Dharapuram",
    portContinent: "Asia",
  },
  {
    lat: "30.5209905",
    lng: "76.81406930000001",
    country: "IN",
    country_long: "India",
    portCode: "INDPR",
    portName: "Dappar",
    portContinent: "Asia",
  },
  {
    lat: "28.6035503",
    lng: "77.0872644",
    country: "IN",
    country_long: "India",
    portCode: "INDRA",
    portName: "Dashrath Puri",
    portContinent: "Asia",
  },
  {
    lat: "26.7076994",
    lng: "91.5646989",
    country: "N/A",
    country_long: "N/A",
    portCode: "INDRG",
    portName: "Darranga",
    portContinent: "Asia",
  },
  {
    lat: "28.2056606",
    lng: "76.7914639",
    country: "IN",
    country_long: "India",
    portCode: "INDRH",
    portName: "Dharuhera",
    portContinent: "Asia",
  },
  {
    lat: "28.5525772",
    lng: "77.55481",
    country: "IN",
    country_long: "India",
    portCode: "INDRI",
    portName: "Dadri",
    portContinent: "Asia",
  },
  {
    lat: "22.2441975",
    lng: "68.96845619999999",
    country: "IN",
    country_long: "India",
    portCode: "INDRK",
    portName: "Dwarka (Rupen)",
    portContinent: "Asia",
  },
  {
    lat: "15.390198",
    lng: "73.8547036",
    country: "IN",
    country_long: "India",
    portCode: "INDRL",
    portName: "Dabolim",
    portContinent: "Asia",
  },
  {
    lat: "9.179371999999999",
    lng: "79.4183427",
    country: "IN",
    country_long: "India",
    portCode: "INDSK",
    portName: "Dhanu-shkodi",
    portContinent: "Asia",
  },
  {
    lat: "10.8249701",
    lng: "78.701559",
    country: "IN",
    country_long: "India",
    portCode: "INDTM",
    portName: "Devathanam/Tiruchirappalli",
    portContinent: "Asia",
  },
  {
    lat: "25.2400875",
    lng: "73.32518189999999",
    country: "IN",
    country_long: "India",
    portCode: "INDTW",
    portName: "Dantiwara",
    portContinent: "Asia",
  },
  {
    lat: "23.5204443",
    lng: "87.3119227",
    country: "IN",
    country_long: "India",
    portCode: "INDUR",
    portName: "Durgapur",
    portContinent: "Asia",
  },
  {
    lat: "22.2441975",
    lng: "68.96845619999999",
    country: "IN",
    country_long: "India",
    portCode: "INDWA",
    portName: "Dwarka (INDWA), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.1840249",
    lng: "92.0248701",
    country: "IN",
    country_long: "India",
    portCode: "INDWK",
    portName: "Dawki",
    portContinent: "Asia",
  },
  {
    lat: "29.29909899999999",
    lng: "77.0031863",
    country: "IN",
    country_long: "India",
    portCode: "INDWN",
    portName: "Samalkha ICD/Panipat/ Jattipur",
    portContinent: "Asia",
  },
  {
    lat: "15.3647083",
    lng: "75.1239547",
    country: "IN",
    country_long: "India",
    portCode: "INDWR",
    portName: "Il Sez/Hubli",
    portContinent: "Asia",
  },
  {
    lat: "10.0260688",
    lng: "76.3124753",
    country: "IN",
    country_long: "India",
    portCode: "INEDP",
    portName: "Edappally",
    portContinent: "Asia",
  },
  {
    lat: "9.9816358",
    lng: "76.2998842",
    country: "IN",
    country_long: "India",
    portCode: "INEKM",
    portName: "Ernakulam",
    portContinent: "Asia",
  },
  {
    lat: "16.7106604",
    lng: "81.0952431",
    country: "IN",
    country_long: "India",
    portCode: "INELU",
    portName: "Eluru",
    portContinent: "Asia",
  },
  {
    lat: "13.0975198",
    lng: "80.2935024",
    country: "IN",
    country_long: "India",
    portCode: "INENR",
    portName: "Port of Ennore",
    portContinent: "Asia",
  },
  {
    lat: "10.0669103",
    lng: "76.2989896",
    country: "IN",
    country_long: "India",
    portCode: "INEOR",
    portName: "Eloor",
    portContinent: "Asia",
  },
  {
    lat: "11.3410364",
    lng: "77.7171642",
    country: "IN",
    country_long: "India",
    portCode: "INERO",
    portName: "Erode",
    portContinent: "Asia",
  },
  {
    lat: "9.9950339",
    lng: "76.2246941",
    country: "IN",
    country_long: "India",
    portCode: "INERP",
    portName: "Puthuvypeen-SEZ/Ernakulam",
    portContinent: "Asia",
  },
  {
    lat: "9.9994138",
    lng: "76.2537052",
    country: "IN",
    country_long: "India",
    portCode: "INERV",
    portName: "Vallarpadom-SEZ/Ernakulam",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INESH",
    portName: "Elphinstone Harbour",
    portContinent: "Asia",
  },
  {
    lat: "78.31666700000001",
    lng: "-72.6333329",
    country: "GL",
    country_long: "Greenland",
    portCode: "INETA",
    portName: "Etah",
    portContinent: "Asia",
  },
  {
    lat: "24.7797393",
    lng: "87.9166676",
    country: "IN",
    country_long: "India",
    portCode: "INFAR",
    portName: "Farakka",
    portContinent: "Asia",
  },
  {
    lat: "28.4089123",
    lng: "77.3177894",
    country: "IN",
    country_long: "India",
    portCode: "INFBD",
    portName: "Faridabad (INFBD), Faridabad, India",
    portContinent: "Asia",
  },
  {
    lat: "13.0826802",
    lng: "80.2707184",
    country: "IN",
    country_long: "India",
    portCode: "INFCH",
    portName: "Fpo(Sea)/Chennai",
    portContinent: "Asia",
  },
  {
    lat: "30.9331348",
    lng: "74.6224755",
    country: "IN",
    country_long: "India",
    portCode: "INFIR",
    portName: "Firozpur",
    portContinent: "Asia",
  },
  {
    lat: "22.3019986",
    lng: "88.1284383",
    country: "IN",
    country_long: "India",
    portCode: "INFLT",
    portName: "Falta (INFLT), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "17.3891379",
    lng: "77.8367282",
    country: "IN",
    country_long: "India",
    portCode: "INFMA",
    portName: "APIICL-SEZ/Ranga Reddy",
    portContinent: "Asia",
  },
  {
    lat: "17.3891379",
    lng: "77.8367282",
    country: "IN",
    country_long: "India",
    portCode: "INFMH",
    portName: "Hgsezl-SEZ/Ranga Reddy",
    portContinent: "Asia",
  },
  {
    lat: "30.6774058",
    lng: "74.7539266",
    country: "IN",
    country_long: "India",
    portCode: "INFRD",
    portName: "Faridkot",
    portContinent: "Asia",
  },
  {
    lat: "25.9209503",
    lng: "80.7996035",
    country: "IN",
    country_long: "India",
    portCode: "INFTP",
    portName: "Fatehpur",
    portContinent: "Asia",
  },
  {
    lat: "30.6435345",
    lng: "76.39703",
    country: "IN",
    country_long: "India",
    portCode: "INFTS",
    portName: "Fatehgarh Sahib",
    portContinent: "Asia",
  },
  {
    lat: "27.1591961",
    lng: "78.3957331",
    country: "IN",
    country_long: "India",
    portCode: "INFZD",
    portName: "Firozabad",
    portContinent: "Asia",
  },
  {
    lat: "34.2164955",
    lng: "74.7719431",
    country: "N/A",
    country_long: "N/A",
    portCode: "INGAB",
    portName: "Ganderbal",
    portContinent: "Asia",
  },
  {
    lat: "22.7788044",
    lng: "73.6142795",
    country: "IN",
    country_long: "India",
    portCode: "INGAH",
    portName: "Godhra",
    portContinent: "Asia",
  },
  {
    lat: "28.6810257",
    lng: "80.55347359999999",
    country: "IN",
    country_long: "India",
    portCode: "INGAI",
    portName: "Gauriphanta",
    portContinent: "Asia",
  },
  {
    lat: "19.387389",
    lng: "85.0515413",
    country: "IN",
    country_long: "India",
    portCode: "INGAJ",
    portName: "Ganjam",
    portContinent: "Asia",
  },
  {
    lat: "26.5264815",
    lng: "88.1157149",
    country: "IN",
    country_long: "India",
    portCode: "INGAL",
    portName: "Galgalia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INGAN",
    portName: "Gandhar",
    portContinent: "Asia",
  },
  {
    lat: "23.075297",
    lng: "70.133673",
    country: "IN",
    country_long: "India",
    portCode: "INGAO",
    portName: "OPGS SEZ/Gandhidham",
    portContinent: "Asia",
  },
  {
    lat: "27.3313512",
    lng: "88.6138113",
    country: "IN",
    country_long: "India",
    portCode: "INGAT",
    portName: "Gangtok",
    portContinent: "Asia",
  },
  {
    lat: "26.1445169",
    lng: "91.7362365",
    country: "IN",
    country_long: "India",
    portCode: "INGAU",
    portName: "Gauhati (Panidi)",
    portContinent: "Asia",
  },
  {
    lat: "26.1445169",
    lng: "91.7362365",
    country: "IN",
    country_long: "India",
    portCode: "INGAW",
    portName: "Guwahati",
    portContinent: "Asia",
  },
  {
    lat: "24.7913957",
    lng: "85.0002336",
    country: "IN",
    country_long: "India",
    portCode: "INGAY",
    portName: "Gaya",
    portContinent: "Asia",
  },
  {
    lat: "17.329731",
    lng: "76.8342957",
    country: "IN",
    country_long: "India",
    portCode: "INGBA",
    portName: "Gulbarga",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INGBN",
    portName: "Ingau",
    portContinent: "Asia",
  },
  {
    lat: "21.4549477",
    lng: "80.19607119999999",
    country: "IN",
    country_long: "India",
    portCode: "INGDA",
    portName: "Gondia",
    portContinent: "Asia",
  },
  {
    lat: "23.2156354",
    lng: "72.63694149999999",
    country: "IN",
    country_long: "India",
    portCode: "INGDH",
    portName: "Gandhinagar",
    portContinent: "Asia",
  },
  {
    lat: "13.1230558",
    lng: "79.9119526",
    country: "IN",
    country_long: "India",
    portCode: "INGDP",
    portName: "Fllpl-SEZ/Thirruvallur",
    portContinent: "Asia",
  },
  {
    lat: "32.0413917",
    lng: "75.403086",
    country: "IN",
    country_long: "India",
    portCode: "INGDR",
    portName: "Gurdaspur",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INGED",
    portName: "Gede",
    portContinent: "Asia",
  },
  {
    lat: "52.3146549",
    lng: "20.9546916",
    country: "PL",
    country_long: "Poland",
    portCode: "INGGA",
    portName: "Gogha",
    portContinent: "Asia",
  },
  {
    lat: "28.4594965",
    lng: "77.0266383",
    country: "IN",
    country_long: "India",
    portCode: "INGGN",
    portName: "Gurgaon",
    portContinent: "Asia",
  },
  {
    lat: "29.9093759",
    lng: "73.87998050000002",
    country: "IN",
    country_long: "India",
    portCode: "INGGR",
    portName: "Ganganagar",
    portContinent: "Asia",
  },
  {
    lat: "17.6322406",
    lng: "83.2365482",
    country: "IN",
    country_long: "India",
    portCode: "INGGV",
    portName: "Gangavaram",
    portContinent: "Asia",
  },
  {
    lat: "21.6869791",
    lng: "72.2748153",
    country: "IN",
    country_long: "India",
    portCode: "INGHA",
    portName: "Ghogha (INGHA), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.224232",
    lng: "90.37699900000001",
    country: "IN",
    country_long: "India",
    portCode: "INGHP",
    portName: "Ghasuapara",
    portContinent: "Asia",
  },
  {
    lat: "28.4496908",
    lng: "76.9326167",
    country: "IN",
    country_long: "India",
    portCode: "INGHR",
    portName: "Garhi Harsaru (INGHR), Gurgaon, Hariyana",
    portContinent: "Asia",
  },
  {
    lat: "23.075297",
    lng: "70.133673",
    country: "IN",
    country_long: "India",
    portCode: "INGIN",
    portName: "Gandhidham",
    portContinent: "Asia",
  },
  {
    lat: "30.1804212",
    lng: "80.8564602",
    country: "N/A",
    country_long: "N/A",
    portCode: "INGJI",
    portName: "Gunji",
    portContinent: "Asia",
  },
  {
    lat: "19.1912221",
    lng: "84.1857115",
    country: "IN",
    country_long: "India",
    portCode: "INGJP",
    portName: "Gajapati",
    portContinent: "Asia",
  },
  {
    lat: "22.2411608",
    lng: "73.085595",
    country: "IN",
    country_long: "India",
    portCode: "INGJT",
    portName: "Padra",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INGJX",
    portName: "Ghojadanga",
    portContinent: "Asia",
  },
  {
    lat: "26.1055618",
    lng: "89.8311875",
    country: "IN",
    country_long: "India",
    portCode: "INGKJ",
    portName: "Golakganj",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INGLY",
    portName: "APIICL-SEZ/Mahabaoobnagar",
    portContinent: "Asia",
  },
  {
    lat: "13.4109557",
    lng: "80.11697079999999",
    country: "IN",
    country_long: "India",
    portCode: "INGMM",
    portName: "Gummidipundi",
    portContinent: "Asia",
  },
  {
    lat: "13.0732259",
    lng: "80.2609209",
    country: "IN",
    country_long: "India",
    portCode: "INGMR",
    portName: "Egmore",
    portContinent: "Asia",
  },
  {
    lat: "28.4743879",
    lng: "77.50399039999999",
    country: "IN",
    country_long: "India",
    portCode: "INGND",
    portName: "Greater Nodia",
    portContinent: "Asia",
  },
  {
    lat: "23.2224762",
    lng: "72.64616989999999",
    country: "IN",
    country_long: "India",
    portCode: "INGNG",
    portName: "Gidc-SEZ/Gandhinagar",
    portContinent: "Asia",
  },
  {
    lat: "19.0330488",
    lng: "73.0296625",
    country: "IN",
    country_long: "India",
    portCode: "INGNL",
    portName: "LIPD SEZ/Navi Mumbai",
    portContinent: "Asia",
  },
  {
    lat: "28.4743879",
    lng: "77.50399039999999",
    country: "IN",
    country_long: "India",
    portCode: "INGNO",
    portName: "Greater Noida",
    portContinent: "Asia",
  },
  {
    lat: "28.4594965",
    lng: "77.0266383",
    country: "IN",
    country_long: "India",
    portCode: "INGNP",
    portName: "GPRPL SEZ/Gurgaon",
    portContinent: "Asia",
  },
  {
    lat: "16.3066525",
    lng: "80.4365402",
    country: "IN",
    country_long: "India",
    portCode: "INGNR",
    portName: "Guntur (INGNR), Guntur, India",
    portContinent: "Asia",
  },
  {
    lat: "23.2156354",
    lng: "72.63694149999999",
    country: "IN",
    country_long: "India",
    portCode: "INGNS",
    portName: "Srehpl-SEZ/Gandhinagar",
    portContinent: "Asia",
  },
  {
    lat: "23.2156354",
    lng: "72.63694149999999",
    country: "IN",
    country_long: "India",
    portCode: "INGNT",
    portName: "TCS-SEZ/Gandhinagar",
    portContinent: "Asia",
  },
  {
    lat: "28.338333",
    lng: "77.6077865",
    country: "IN",
    country_long: "India",
    portCode: "INGNU",
    portName: "Gautam Budhh Nagar",
    portContinent: "Asia",
  },
  {
    lat: "26.1641342",
    lng: "90.62515409999999",
    country: "IN",
    country_long: "India",
    portCode: "INGOA",
    portName: "Goalpara",
    portContinent: "Asia",
  },
  {
    lat: "15.3889087",
    lng: "73.81656679999999",
    country: "IN",
    country_long: "India",
    portCode: "INGOI",
    portName: "GOA/Mormugao (INGOI), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "26.5238515",
    lng: "93.96233699999999",
    country: "IN",
    country_long: "India",
    portCode: "INGOL",
    portName: "Golaghat",
    portContinent: "Asia",
  },
  {
    lat: "27.1339874",
    lng: "81.96189790000001",
    country: "IN",
    country_long: "India",
    portCode: "INGON",
    portName: "Gonda",
    portContinent: "Asia",
  },
  {
    lat: "26.7605545",
    lng: "83.3731675",
    country: "IN",
    country_long: "India",
    portCode: "INGOP",
    portName: "Gorakhpur",
    portContinent: "Asia",
  },
  {
    lat: "19.2647208",
    lng: "84.8620054",
    country: "IN",
    country_long: "India",
    portCode: "INGPP",
    portName: "Gopalpur",
    portContinent: "Asia",
  },
  {
    lat: "19.3039133",
    lng: "84.9654987",
    country: "IN",
    country_long: "India",
    portCode: "INGPR",
    portName: "Port of Gopalpur",
    portContinent: "Asia",
  },
  {
    lat: "22.5485456",
    lng: "88.2876393",
    country: "IN",
    country_long: "India",
    portCode: "INGRR",
    portName: "Garden Reach",
    portContinent: "Asia",
  },
  {
    lat: "18.8002852",
    lng: "73.77357599999999",
    country: "IN",
    country_long: "India",
    portCode: "INGRW",
    portName: "Apm Icd/Bhamboli-Khed",
    portContinent: "Asia",
  },
  {
    lat: "26.0241869",
    lng: "89.4753992",
    country: "IN",
    country_long: "India",
    portCode: "INGTG",
    portName: "Gitaldah Road",
    portContinent: "Asia",
  },
  {
    lat: "17.3891379",
    lng: "77.8367282",
    country: "IN",
    country_long: "India",
    portCode: "INGTI",
    portName: "ITL SEZ/Ranga Reddy",
    portContinent: "Asia",
  },
  {
    lat: "16.3066525",
    lng: "80.4365402",
    country: "IN",
    country_long: "India",
    portCode: "INGTR",
    portName: "Guntur",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INGTZ",
    portName: "Getandah",
    portContinent: "Asia",
  },
  {
    lat: "23.0441295",
    lng: "84.53794549999999",
    country: "IN",
    country_long: "India",
    portCode: "INGUA",
    portName: "Gumla",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INGUD",
    portName: "GÃ¼dÃ¼r",
    portContinent: "Asia",
  },
  {
    lat: "28.8381644",
    lng: "78.24218119999999",
    country: "IN",
    country_long: "India",
    portCode: "INGUJ",
    portName: "Gajraula",
    portContinent: "Asia",
  },
  {
    lat: "25.9031773",
    lng: "74.66053649999999",
    country: "IN",
    country_long: "India",
    portCode: "INGUL",
    portName: "Gulabpura",
    portContinent: "Asia",
  },
  {
    lat: "13.4109557",
    lng: "80.11697079999999",
    country: "IN",
    country_long: "India",
    portCode: "INGUM",
    portName: "Gummidipoondi",
    portContinent: "Asia",
  },
  {
    lat: "24.6455069",
    lng: "77.2864879",
    country: "IN",
    country_long: "India",
    portCode: "INGUX",
    portName: "Guna",
    portContinent: "Asia",
  },
  {
    lat: "24.1548982",
    lng: "83.7995617",
    country: "IN",
    country_long: "India",
    portCode: "INGWA",
    portName: "Garhwa",
    portContinent: "Asia",
  },
  {
    lat: "26.2124007",
    lng: "78.1772053",
    country: "IN",
    country_long: "India",
    portCode: "INGWL",
    portName: "Gwalior",
    portContinent: "Asia",
  },
  {
    lat: "16.523838",
    lng: "80.7914172",
    country: "IN",
    country_long: "India",
    portCode: "INGWM",
    portName: "Vijayawada International Airport/Gannavaram",
    portContinent: "Asia",
  },
  {
    lat: "28.6691565",
    lng: "77.45375779999999",
    country: "IN",
    country_long: "India",
    portCode: "INGZB",
    portName: "Ghaziabad",
    portContinent: "Asia",
  },
  {
    lat: "22.3466702",
    lng: "77.0889583",
    country: "IN",
    country_long: "India",
    portCode: "INHAA",
    portName: "Harda",
    portContinent: "Asia",
  },
  {
    lat: "18.0820208",
    lng: "73.42244099999999",
    country: "IN",
    country_long: "India",
    portCode: "INHAD",
    portName: "Mahad",
    portContinent: "Asia",
  },
  {
    lat: "24.6811018",
    lng: "92.5638055",
    country: "IN",
    country_long: "India",
    portCode: "INHAI",
    portName: "Hailakandi",
    portContinent: "Asia",
  },
  {
    lat: "22.0446336",
    lng: "88.08904439999999",
    country: "IN",
    country_long: "India",
    portCode: "INHAL",
    portName: "Port of Haldia",
    portContinent: "Asia",
  },
  {
    lat: "26.7341201",
    lng: "77.02968369999999",
    country: "IN",
    country_long: "India",
    portCode: "INHAN",
    portName: "Hindaun ICD/Karauli",
    portContinent: "Asia",
  },
  {
    lat: "27.3965071",
    lng: "80.1250479",
    country: "IN",
    country_long: "India",
    portCode: "INHAO",
    portName: "Hardoi",
    portContinent: "Asia",
  },
  {
    lat: "29.0587757",
    lng: "76.085601",
    country: "IN",
    country_long: "India",
    portCode: "INHAR",
    portName: "Haryana",
    portContinent: "Asia",
  },
  {
    lat: "13.0033234",
    lng: "76.1003894",
    country: "IN",
    country_long: "India",
    portCode: "INHAS",
    portName: "Hassan",
    portContinent: "Asia",
  },
  {
    lat: "12.9715987",
    lng: "77.5945627",
    country: "IN",
    country_long: "India",
    portCode: "INHBB",
    portName: "Sdpl Sez/Bangalore",
    portContinent: "Asia",
  },
  {
    lat: "15.3647083",
    lng: "75.1239547",
    country: "IN",
    country_long: "India",
    portCode: "INHBX",
    portName: "Hubli",
    portContinent: "Asia",
  },
  {
    lat: "29.036202",
    lng: "79.4017702",
    country: "IN",
    country_long: "India",
    portCode: "INHDD",
    portName: "Sidcul Icd/Pantnagar",
    portContinent: "Asia",
  },
  {
    lat: "13.8222599",
    lng: "77.5009298",
    country: "IN",
    country_long: "India",
    portCode: "INHDR",
    portName: "Hindupur",
    portContinent: "Asia",
  },
  {
    lat: "12.9715987",
    lng: "77.5945627",
    country: "IN",
    country_long: "India",
    portCode: "INHEB",
    portName: "GOCL SEZ/Bangalore",
    portContinent: "Asia",
  },
  {
    lat: "29.5815012",
    lng: "74.32941989999999",
    country: "IN",
    country_long: "India",
    portCode: "INHGH",
    portName: "Hanumangarh",
    portContinent: "Asia",
  },
  {
    lat: "22.4613097",
    lng: "88.9877315",
    country: "IN",
    country_long: "India",
    portCode: "INHGL",
    portName: "Hingalganj",
    portContinent: "Asia",
  },
  {
    lat: "13.4671736",
    lng: "74.7492098",
    country: "IN",
    country_long: "India",
    portCode: "INHGT",
    portName: "Hangarkatta",
    portContinent: "Asia",
  },
  {
    lat: "28.4594965",
    lng: "77.0266383",
    country: "IN",
    country_long: "India",
    portCode: "INHHG",
    portName: "Gurgaon(ICD/CFS/SEZ) (INHHG), Gurgaon, India",
    portContinent: "Asia",
  },
  {
    lat: "19.7173703",
    lng: "77.1493722",
    country: "IN",
    country_long: "India",
    portCode: "INHIN",
    portName: "Hingoli",
    portContinent: "Asia",
  },
  {
    lat: "31.1048294",
    lng: "77.17339009999999",
    country: "IN",
    country_long: "India",
    portCode: "INHIP",
    portName: "Himachal Pradesh",
    portContinent: "Asia",
  },
  {
    lat: "21.1702401",
    lng: "72.83106070000001",
    country: "IN",
    country_long: "India",
    portCode: "INHIR",
    portName: "SHB ICD/Surat",
    portContinent: "Asia",
  },
  {
    lat: "24.8318452",
    lng: "79.91985489999999",
    country: "IN",
    country_long: "India",
    portCode: "INHJR",
    portName: "Khajuraho",
    portContinent: "Asia",
  },
  {
    lat: "26.3345442",
    lng: "88.7806578",
    country: "IN",
    country_long: "India",
    portCode: "INHLD",
    portName: "Haldibari",
    portContinent: "Asia",
  },
  {
    lat: "12.8787673",
    lng: "77.6376676",
    country: "IN",
    country_long: "India",
    portCode: "INHLE",
    portName: "Infosys Electronic City Sez/Begur Hobli",
    portContinent: "Asia",
  },
  {
    lat: "25.2774334",
    lng: "89.0012007",
    country: "IN",
    country_long: "India",
    portCode: "INHLI",
    portName: "Hilli",
    portContinent: "Asia",
  },
  {
    lat: "22.5045713",
    lng: "73.4713666",
    country: "IN",
    country_long: "India",
    portCode: "INHLO",
    portName: "Halol",
    portContinent: "Asia",
  },
  {
    lat: "22.2143724",
    lng: "88.9820696",
    country: "IN",
    country_long: "India",
    portCode: "INHND",
    portName: "Hemnagar Lcs",
    portContinent: "Asia",
  },
  {
    lat: "23.5968857",
    lng: "72.9630378",
    country: "IN",
    country_long: "India",
    portCode: "INHNG",
    portName: "Himatnagar",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INHOI",
    portName: "OPTO INFRA SEZ/Hassan",
    portContinent: "Asia",
  },
  {
    lat: "14.2797502",
    lng: "74.4439252",
    country: "IN",
    country_long: "India",
    portCode: "INHON",
    portName: "Honavar",
    portContinent: "Asia",
  },
  {
    lat: "31.5143178",
    lng: "75.911483",
    country: "IN",
    country_long: "India",
    portCode: "INHOS",
    portName: "Hoshiarpur",
    portContinent: "Asia",
  },
  {
    lat: "22.5957689",
    lng: "88.26363940000002",
    country: "IN",
    country_long: "India",
    portCode: "INHOW",
    portName: "Howrah",
    portContinent: "Asia",
  },
  {
    lat: "29.2104232",
    lng: "78.9618845",
    country: "IN",
    country_long: "India",
    portCode: "INHPI",
    portName: "Sandkhera ICD/Kashipur",
    portContinent: "Asia",
  },
  {
    lat: "20.2260671",
    lng: "85.87676739999999",
    country: "IN",
    country_long: "India",
    portCode: "INHPR",
    portName: "Hirapur",
    portContinent: "Asia",
  },
  {
    lat: "15.2688542",
    lng: "76.39092409999999",
    country: "IN",
    country_long: "India",
    portCode: "INHPT",
    portName: "Hospet",
    portContinent: "Asia",
  },
  {
    lat: "22.5957689",
    lng: "88.26363940000002",
    country: "IN",
    country_long: "India",
    portCode: "INHRA",
    portName: "Haora",
    portContinent: "Asia",
  },
  {
    lat: "22.6012922",
    lng: "75.3024655",
    country: "IN",
    country_long: "India",
    portCode: "INHRD",
    portName: "Dhar",
    portContinent: "Asia",
  },
  {
    lat: "24.9061239",
    lng: "84.191193",
    country: "IN",
    country_long: "India",
    portCode: "INHRI",
    portName: "Dehri",
    portContinent: "Asia",
  },
  {
    lat: "17.8153734",
    lng: "73.0980773",
    country: "IN",
    country_long: "India",
    portCode: "INHRN",
    portName: "Harnai (INHRN), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "12.5265661",
    lng: "78.2149575",
    country: "IN",
    country_long: "India",
    portCode: "INHSA",
    portName: "ECTN SEZ/Krishnagiri",
    portContinent: "Asia",
  },
  {
    lat: "13.1741689",
    lng: "77.7313163",
    country: "IN",
    country_long: "India",
    portCode: "INHSF",
    portName: "Kiadbfp-SEZ/Hassan",
    portContinent: "Asia",
  },
  {
    lat: "12.5265661",
    lng: "78.2149575",
    country: "IN",
    country_long: "India",
    portCode: "INHSI",
    portName: "SIPC SEZ/Krishnagiri",
    portContinent: "Asia",
  },
  {
    lat: "13.1741689",
    lng: "77.7313163",
    country: "IN",
    country_long: "India",
    portCode: "INHSP",
    portName: "Kiadbp-SEZ/Hassan",
    portContinent: "Asia",
  },
  {
    lat: "12.7409127",
    lng: "77.8252923",
    country: "IN",
    country_long: "India",
    portCode: "INHSR",
    portName: "Hosur",
    portContinent: "Asia",
  },
  {
    lat: "29.1491875",
    lng: "75.7216527",
    country: "IN",
    country_long: "India",
    portCode: "INHSS",
    portName: "Hissar",
    portContinent: "Asia",
  },
  {
    lat: "13.1741689",
    lng: "77.7313163",
    country: "IN",
    country_long: "India",
    portCode: "INHST",
    portName: "Kiadbt-SEZ/Hassan",
    portContinent: "Asia",
  },
  {
    lat: "12.7568866",
    lng: "77.80695779999999",
    country: "IN",
    country_long: "India",
    portCode: "INHSU",
    portName: "HOSUR ICD",
    portContinent: "Asia",
  },
  {
    lat: "21.4269087",
    lng: "83.5016887",
    country: "IN",
    country_long: "India",
    portCode: "INHTS",
    portName: "Hatisar",
    portContinent: "Asia",
  },
  {
    lat: "14.2797502",
    lng: "74.4439252",
    country: "IN",
    country_long: "India",
    portCode: "INHWR",
    portName: "Honawar",
    portContinent: "Asia",
  },
  {
    lat: "17.385044",
    lng: "78.486671",
    country: "IN",
    country_long: "India",
    portCode: "INHYD",
    portName: "Hyderabad",
    portContinent: "Asia",
  },
  {
    lat: "21.1784184",
    lng: "72.6587856",
    country: "IN",
    country_long: "India",
    portCode: "INHZA",
    portName: "Hazira Port Surat (INHZA), Surat, India",
    portContinent: "Asia",
  },
  {
    lat: "29.0587757",
    lng: "76.085601",
    country: "IN",
    country_long: "India",
    portCode: "INHZR",
    portName: "Hazira Haryana",
    portContinent: "Asia",
  },
  {
    lat: "28.6139391",
    lng: "77.2090212",
    country: "IN",
    country_long: "India",
    portCode: "INICD",
    portName: "New Delhi(ICD/CFS/SEZ) (INICD), New Delhi, India",
    portContinent: "Asia",
  },
  {
    lat: "22.7195687",
    lng: "75.8577258",
    country: "IN",
    country_long: "India",
    portCode: "INIDR",
    portName: "Indore",
    portContinent: "Asia",
  },
  {
    lat: "9.8583987",
    lng: "76.9527836",
    country: "IN",
    country_long: "India",
    portCode: "INIDU",
    portName: "Idukki",
    portContinent: "Asia",
  },
  {
    lat: "12.9948978",
    lng: "80.00147059999999",
    country: "IN",
    country_long: "India",
    portCode: "INILP",
    portName: "Irungattukottai-Ilp-ICD",
    portContinent: "Asia",
  },
  {
    lat: "24.8170111",
    lng: "93.9368439",
    country: "IN",
    country_long: "India",
    portCode: "INIMF",
    portName: "Imphal",
    portContinent: "Asia",
  },
  {
    lat: "23.2982074",
    lng: "72.40481460000001",
    country: "IN",
    country_long: "India",
    portCode: "ININD",
    portName: "Indrad",
    portContinent: "Asia",
  },
  {
    lat: "22.7187589",
    lng: "75.9027715",
    country: "IN",
    country_long: "India",
    portCode: "ININI",
    portName: "IIPL SEZ/Indore(ICD/CFS/SEZ) (ININI), Indore, India",
    portContinent: "Asia",
  },
  {
    lat: "22.7195687",
    lng: "75.8577258",
    country: "IN",
    country_long: "India",
    portCode: "ININN",
    portName: "INFOSYS SEZ/Indore",
    portContinent: "Asia",
  },
  {
    lat: "22.7195687",
    lng: "75.8577258",
    country: "IN",
    country_long: "India",
    portCode: "ININT",
    portName: "TCS SEZ/Indore",
    portContinent: "Asia",
  },
  {
    lat: "12.9948978",
    lng: "80.00147059999999",
    country: "IN",
    country_long: "India",
    portCode: "INIRU",
    portName: "Irungattukottai",
    portContinent: "Asia",
  },
  {
    lat: "19.9974533",
    lng: "73.78980229999999",
    country: "IN",
    country_long: "India",
    portCode: "INISK",
    portName: "Nasik",
    portContinent: "Asia",
  },
  {
    lat: "26.2554319",
    lng: "88.2009408",
    country: "IN",
    country_long: "India",
    portCode: "INISP",
    portName: "Islampur",
    portContinent: "Asia",
  },
  {
    lat: "27.0843682",
    lng: "93.6053163",
    country: "N/A",
    country_long: "N/A",
    portCode: "INITA",
    portName: "Itanagar",
    portContinent: "Asia",
  },
  {
    lat: "23.831457",
    lng: "91.2867777",
    country: "IN",
    country_long: "India",
    portCode: "INIXA",
    portName: "Agartala",
    portContinent: "Asia",
  },
  {
    lat: "26.6986114",
    lng: "88.31169949999999",
    country: "IN",
    country_long: "India",
    portCode: "INIXB",
    portName: "Bagdogra",
    portContinent: "Asia",
  },
  {
    lat: "30.7333148",
    lng: "76.7794179",
    country: "IN",
    country_long: "India",
    portCode: "INIXC",
    portName: "Chandigarh",
    portContinent: "Asia",
  },
  {
    lat: "25.4358011",
    lng: "81.846311",
    country: "IN",
    country_long: "India",
    portCode: "INIXD",
    portName: "Allahabad",
    portContinent: "Asia",
  },
  {
    lat: "12.9141417",
    lng: "74.8559568",
    country: "IN",
    country_long: "India",
    portCode: "INIXE",
    portName: "Mangalore (INIXE), Mangalore, India",
    portContinent: "Asia",
  },
  {
    lat: "15.8496953",
    lng: "74.4976741",
    country: "IN",
    country_long: "India",
    portCode: "INIXG",
    portName: "Belgaum",
    portContinent: "Asia",
  },
  {
    lat: "24.3268117",
    lng: "92.0126276",
    country: "IN",
    country_long: "India",
    portCode: "INIXH",
    portName: "Kailashahar",
    portContinent: "Asia",
  },
  {
    lat: "27.2874694",
    lng: "94.093004",
    country: "IN",
    country_long: "India",
    portCode: "INIXI",
    portName: "Lilabari",
    portContinent: "Asia",
  },
  {
    lat: "32.7266016",
    lng: "74.8570259",
    country: "N/A",
    country_long: "N/A",
    portCode: "INIXJ",
    portName: "Jammu",
    portContinent: "Asia",
  },
  {
    lat: "21.2997164",
    lng: "70.2492882",
    country: "IN",
    country_long: "India",
    portCode: "INIXK",
    portName: "Keshod",
    portContinent: "Asia",
  },
  {
    lat: "34.1525864",
    lng: "77.57705349999999",
    country: "N/A",
    country_long: "N/A",
    portCode: "INIXL",
    portName: "Leh",
    portContinent: "Asia",
  },
  {
    lat: "9.8267124",
    lng: "78.0988129",
    country: "IN",
    country_long: "India",
    portCode: "INIXM",
    portName: "Madurai(ICD/CFS/SEZ) (INIXM), Madurai, India",
    portContinent: "Asia",
  },
  {
    lat: "24.0671796",
    lng: "91.60567180000001",
    country: "IN",
    country_long: "India",
    portCode: "INIXN",
    portName: "Khowai",
    portContinent: "Asia",
  },
  {
    lat: "32.2733352",
    lng: "75.6522066",
    country: "IN",
    country_long: "India",
    portCode: "INIXP",
    portName: "Pathankot",
    portContinent: "Asia",
  },
  {
    lat: "24.1968764",
    lng: "91.8330525",
    country: "IN",
    country_long: "India",
    portCode: "INIXQ",
    portName: "Kamalpur",
    portContinent: "Asia",
  },
  {
    lat: "23.3440997",
    lng: "85.309562",
    country: "IN",
    country_long: "India",
    portCode: "INIXR",
    portName: "Ranchi",
    portContinent: "Asia",
  },
  {
    lat: "24.8332708",
    lng: "92.7789054",
    country: "IN",
    country_long: "India",
    portCode: "INIXS",
    portName: "Silchar",
    portContinent: "Asia",
  },
  {
    lat: "28.0631741",
    lng: "95.32391969999999",
    country: "N/A",
    country_long: "N/A",
    portCode: "INIXT",
    portName: "Pasighat",
    portContinent: "Asia",
  },
  {
    lat: "19.8761653",
    lng: "75.3433139",
    country: "IN",
    country_long: "India",
    portCode: "INIXU",
    portName: "Aurangabad",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INIXV",
    portName: "Along Apt",
    portContinent: "Asia",
  },
  {
    lat: "22.8045665",
    lng: "86.2028754",
    country: "IN",
    country_long: "India",
    portCode: "INIXW",
    portName: "Jamshedpur",
    portContinent: "Asia",
  },
  {
    lat: "23.008141",
    lng: "70.1872551",
    country: "IN",
    country_long: "India",
    portCode: "INIXY",
    portName: "Port of Kandla",
    portContinent: "Asia",
  },
  {
    lat: "11.6233774",
    lng: "92.7264828",
    country: "IN",
    country_long: "India",
    portCode: "INIXZ",
    portName: "Port Blair (INIXZ), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.2132649",
    lng: "84.9853322",
    country: "IN",
    country_long: "India",
    portCode: "INJAD",
    portName: "Jehanabad",
    portContinent: "Asia",
  },
  {
    lat: "20.2548998",
    lng: "86.1706221",
    country: "IN",
    country_long: "India",
    portCode: "INJAG",
    portName: "Jagatsinghpur",
    portContinent: "Asia",
  },
  {
    lat: "26.9124336",
    lng: "75.7872709",
    country: "IN",
    country_long: "India",
    portCode: "INJAI",
    portName: "Jaipur",
    portContinent: "Asia",
  },
  {
    lat: "20.8341019",
    lng: "86.3326058",
    country: "IN",
    country_long: "India",
    portCode: "INJAJ",
    portName: "Jajpur",
    portContinent: "Asia",
  },
  {
    lat: "23.2424383",
    lng: "68.60791329999999",
    country: "IN",
    country_long: "India",
    portCode: "INJAK",
    portName: "Port of Jakhau",
    portContinent: "Asia",
  },
  {
    lat: "21.0076578",
    lng: "75.5626039",
    country: "IN",
    country_long: "India",
    portCode: "INJAL",
    portName: "Jalgaon",
    portContinent: "Asia",
  },
  {
    lat: "19.8346659",
    lng: "75.88163449999999",
    country: "IN",
    country_long: "India",
    portCode: "INJAN",
    portName: "Jalna",
    portContinent: "Asia",
  },
  {
    lat: "25.3444799",
    lng: "72.6253763",
    country: "IN",
    country_long: "India",
    portCode: "INJAO",
    portName: "Jalore",
    portContinent: "Asia",
  },
  {
    lat: "12.9307735",
    lng: "77.5838302",
    country: "IN",
    country_long: "India",
    portCode: "INJAY",
    portName: "Jayanagar",
    portContinent: "Asia",
  },
  {
    lat: "23.684994",
    lng: "90.356331",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "INJBD",
    portName: "Jafrabad (INJBD), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "21.6015242",
    lng: "71.2203555",
    country: "IN",
    country_long: "India",
    portCode: "INJBL",
    portName: "E-Complex-SEZ/Amreli",
    portContinent: "Asia",
  },
  {
    lat: "26.3991195",
    lng: "87.2568579",
    country: "IN",
    country_long: "India",
    portCode: "INJBN",
    portName: "Jogbani",
    portContinent: "Asia",
  },
  {
    lat: "16.7629646",
    lng: "78.1442814",
    country: "IN",
    country_long: "India",
    portCode: "INJCH",
    portName: "Jadcheria",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INJDA",
    portName: "Jodia",
    portContinent: "Asia",
  },
  {
    lat: "26.2389469",
    lng: "73.02430939999999",
    country: "IN",
    country_long: "India",
    portCode: "INJDG",
    portName: "Jodhpur",
    portContinent: "Asia",
  },
  {
    lat: "26.2389469",
    lng: "73.02430939999999",
    country: "IN",
    country_long: "India",
    portCode: "INJDH",
    portName: "Jodhpur",
    portContinent: "Asia",
  },
  {
    lat: "22.4707019",
    lng: "70.05773",
    country: "IN",
    country_long: "India",
    portCode: "INJGA",
    portName: "Jamnagar",
    portContinent: "Asia",
  },
  {
    lat: "19.0861377",
    lng: "82.0188426",
    country: "IN",
    country_long: "India",
    portCode: "INJGB",
    portName: "Jagdalpur",
    portContinent: "Asia",
  },
  {
    lat: "17.2942578",
    lng: "73.21976910000001",
    country: "IN",
    country_long: "India",
    portCode: "INJGD",
    portName: "Jaigad (INJGD), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "21.719148",
    lng: "73.14975489999999",
    country: "IN",
    country_long: "India",
    portCode: "INJHA",
    portName: "Jhagadia",
    portContinent: "Asia",
  },
  {
    lat: "22.769666",
    lng: "74.5920921",
    country: "IN",
    country_long: "India",
    portCode: "INJHB",
    portName: "Jhabua",
    portContinent: "Asia",
  },
  {
    lat: "25.2132649",
    lng: "84.9853322",
    country: "IN",
    country_long: "India",
    portCode: "INJHD",
    portName: "Jahanabad",
    portContinent: "Asia",
  },
  {
    lat: "29.568686",
    lng: "80.3018283",
    country: "IN",
    country_long: "India",
    portCode: "INJHO",
    portName: "Jhulaghat (Pithoragarh)",
    portContinent: "Asia",
  },
  {
    lat: "21.8554375",
    lng: "84.0061661",
    country: "IN",
    country_long: "India",
    portCode: "INJHR",
    portName: "Jharsuguda",
    portContinent: "Asia",
  },
  {
    lat: "21.8554375",
    lng: "84.0061661",
    country: "IN",
    country_long: "India",
    portCode: "INJHV",
    portName: "Vedanta SEZ/Jharsuguda",
    portContinent: "Asia",
  },
  {
    lat: "26.8366914",
    lng: "89.3843206",
    country: "IN",
    country_long: "India",
    portCode: "INJIG",
    portName: "Jaigaon",
    portContinent: "Asia",
  },
  {
    lat: "29.325508",
    lng: "76.29979089999999",
    country: "IN",
    country_long: "India",
    portCode: "INJIN",
    portName: "Jind",
    portContinent: "Asia",
  },
  {
    lat: "20.8341019",
    lng: "86.3326058",
    country: "IN",
    country_long: "India",
    portCode: "INJJK",
    portName: "ICD JSL/JAJPUR",
    portContinent: "Asia",
  },
  {
    lat: "28.1317038",
    lng: "75.4022233",
    country: "IN",
    country_long: "India",
    portCode: "INJJN",
    portName: "Jhunjhunu",
    portContinent: "Asia",
  },
  {
    lat: "28.6054875",
    lng: "76.6537749",
    country: "IN",
    country_long: "India",
    portCode: "INJJR",
    portName: "Jhajjar",
    portContinent: "Asia",
  },
  {
    lat: "23.1481068",
    lng: "72.0325865",
    country: "IN",
    country_long: "India",
    portCode: "INJKA",
    portName: "Sachana ICD/Viramgam",
    portContinent: "Asia",
  },
  {
    lat: "23.181467",
    lng: "79.9864071",
    country: "IN",
    country_long: "India",
    portCode: "INJLR",
    portName: "Jabalpur",
    portContinent: "Asia",
  },
  {
    lat: "24.5973494",
    lng: "76.1609838",
    country: "IN",
    country_long: "India",
    portCode: "INJLW",
    portName: "Jhalawar",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INJNJ",
    portName: "NPLP SEZ/Thane",
    portContinent: "Asia",
  },
  {
    lat: "28.3403807",
    lng: "76.8021727",
    country: "IN",
    country_long: "India",
    portCode: "INJNL",
    portName: "Janaula",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INJNP",
    portName: "JAWAHARLAL NEHRU",
    portContinent: "Asia",
  },
  {
    lat: "26.5214579",
    lng: "88.7195567",
    country: "IN",
    country_long: "India",
    portCode: "INJPG",
    portName: "Jalpaiguri",
    portContinent: "Asia",
  },
  {
    lat: "26.7870947",
    lng: "75.82742979999999",
    country: "IN",
    country_long: "India",
    portCode: "INJPI",
    portName: "Riico SEZ-I/Sitapura",
    portContinent: "Asia",
  },
  {
    lat: "25.7464145",
    lng: "82.68370329999999",
    country: "IN",
    country_long: "India",
    portCode: "INJPR",
    portName: "Jaunpur",
    portContinent: "Asia",
  },
  {
    lat: "26.8191641",
    lng: "75.7659513",
    country: "IN",
    country_long: "India",
    portCode: "INJPW",
    portName: "Sanganer",
    portContinent: "Asia",
  },
  {
    lat: "26.7509207",
    lng: "94.2036696",
    country: "IN",
    country_long: "India",
    portCode: "INJRH",
    portName: "Jorhat",
    portContinent: "Asia",
  },
  {
    lat: "26.9157487",
    lng: "70.9083443",
    country: "IN",
    country_long: "India",
    portCode: "INJSA",
    portName: "Jaisalmer",
    portContinent: "Asia",
  },
  {
    lat: "21.8554375",
    lng: "84.0061661",
    country: "IN",
    country_long: "India",
    portCode: "INJSG",
    portName: "Concor ICD/Jharsuguda",
    portContinent: "Asia",
  },
  {
    lat: "21.4668716",
    lng: "83.9811665",
    country: "IN",
    country_long: "India",
    portCode: "INJSJ",
    portName: "Sambalpur",
    portContinent: "Asia",
  },
  {
    lat: "26.7750872",
    lng: "75.8513938",
    country: "IN",
    country_long: "India",
    portCode: "INJSZ",
    portName: "Jaipur-Sitapura",
    portContinent: "Asia",
  },
  {
    lat: "22.8683714",
    lng: "88.4032503",
    country: "IN",
    country_long: "India",
    portCode: "INJTD",
    portName: "Jagatdal",
    portContinent: "Asia",
  },
  {
    lat: "16.6261984",
    lng: "73.369396",
    country: "IN",
    country_long: "India",
    portCode: "INJTP",
    portName: "Jaitapur",
    portContinent: "Asia",
  },
  {
    lat: "31.3260152",
    lng: "75.57618289999999",
    country: "IN",
    country_long: "India",
    portCode: "INJUC",
    portName: "Jalandhar",
    portContinent: "Asia",
  },
  {
    lat: "21.5222203",
    lng: "70.4579436",
    country: "IN",
    country_long: "India",
    portCode: "INJUN",
    portName: "Junagadh",
    portContinent: "Asia",
  },
  {
    lat: "26.9124336",
    lng: "75.7872709",
    country: "IN",
    country_long: "India",
    portCode: "INJUX",
    portName: "Basni(ICD/CFS/SEZ) (INJUX), Jaipur, India",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INJWA",
    portName: "Jarwa",
    portContinent: "Asia",
  },
  {
    lat: "30.4992306",
    lng: "77.20702589999999",
    country: "IN",
    country_long: "India",
    portCode: "INKAA",
    portName: "Kala Amb",
    portContinent: "Asia",
  },
  {
    lat: "16.5886465",
    lng: "74.3085454",
    country: "IN",
    country_long: "India",
    portCode: "INKAG",
    portName: "Kagal",
    portContinent: "Asia",
  },
  {
    lat: "23.8343441",
    lng: "80.38938139999999",
    country: "IN",
    country_long: "India",
    portCode: "INKAI",
    portName: "Katni",
    portContinent: "Asia",
  },
  {
    lat: "16.9398602",
    lng: "82.2492434",
    country: "IN",
    country_long: "India",
    portCode: "INKAK",
    portName: "Port of Kakinada",
    portContinent: "Asia",
  },
  {
    lat: "11.2352408",
    lng: "75.7938309",
    country: "IN",
    country_long: "India",
    portCode: "INKAL",
    portName: "Kallai",
    portContinent: "Asia",
  },
  {
    lat: "26.1734944",
    lng: "92.6611233",
    country: "IN",
    country_long: "India",
    portCode: "INKAM",
    portName: "Kampur",
    portContinent: "Asia",
  },
  {
    lat: "25.8456764",
    lng: "93.43775839999999",
    country: "IN",
    country_long: "India",
    portCode: "INKAN",
    portName: "Karbi Anglong",
    portContinent: "Asia",
  },
  {
    lat: "21.2187655",
    lng: "72.8745776",
    country: "IN",
    country_long: "India",
    portCode: "INKAP",
    portName: "Kapadra (Surat)",
    portContinent: "Asia",
  },
  {
    lat: "10.9600778",
    lng: "78.07660360000001",
    country: "IN",
    country_long: "India",
    portCode: "INKAR",
    portName: "Karur",
    portContinent: "Asia",
  },
  {
    lat: "13.0826802",
    lng: "80.2707184",
    country: "IN",
    country_long: "India",
    portCode: "INKAT",
    portName: "Kattupalli (INKAT), Chennai, India",
    portContinent: "Asia",
  },
  {
    lat: "26.488323",
    lng: "77.01614359999999",
    country: "IN",
    country_long: "India",
    portCode: "INKAU",
    portName: "Karauli",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKBC",
    portName: "Kril ICD",
    portContinent: "Asia",
  },
  {
    lat: "10.9601852",
    lng: "79.3844976",
    country: "IN",
    country_long: "India",
    portCode: "INKBM",
    portName: "Kumbakonam",
    portContinent: "Asia",
  },
  {
    lat: "22.3180817",
    lng: "72.6189845",
    country: "IN",
    country_long: "India",
    portCode: "INKBT",
    portName: "Khambhat",
    portContinent: "Asia",
  },
  {
    lat: "16.8273424",
    lng: "80.1985984",
    country: "IN",
    country_long: "India",
    portCode: "INKCI",
    portName: "Konakanchi",
    portContinent: "Asia",
  },
  {
    lat: "12.818456",
    lng: "79.6946586",
    country: "IN",
    country_long: "India",
    portCode: "INKCP",
    portName: "Kanchipuram",
    portContinent: "Asia",
  },
  {
    lat: "24.4676805",
    lng: "85.59336449999999",
    country: "IN",
    country_long: "India",
    portCode: "INKDA",
    portName: "Kodarma (Koderma)",
    portContinent: "Asia",
  },
  {
    lat: "15.1877594",
    lng: "80.0604774",
    country: "IN",
    country_long: "India",
    portCode: "INKDD",
    portName: "Karedu",
    portContinent: "Asia",
  },
  {
    lat: "11.2240587",
    lng: "72.77620990000001",
    country: "IN",
    country_long: "India",
    portCode: "INKDI",
    portName: "Kadmat Is",
    portContinent: "Asia",
  },
  {
    lat: "23.034006",
    lng: "70.1340878",
    country: "IN",
    country_long: "India",
    portCode: "INKDL",
    portName: "Kandla-SEZ/Gandhidham",
    portContinent: "Asia",
  },
  {
    lat: "13.0521019",
    lng: "80.22552859999999",
    country: "IN",
    country_long: "India",
    portCode: "INKDM",
    portName: "Kodambakkam",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKDP",
    portName: "Kondiapetnam",
    portContinent: "Asia",
  },
  {
    lat: "10.2276998",
    lng: "76.1971221",
    country: "IN",
    country_long: "India",
    portCode: "INKDR",
    portName: "Kodungallur",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKEL",
    portName: "Kel Sahar Subdivision",
    portContinent: "Asia",
  },
  {
    lat: "21.628933",
    lng: "85.5816847",
    country: "IN",
    country_long: "India",
    portCode: "INKEO",
    portName: "Keonjhar",
    portContinent: "Asia",
  },
  {
    lat: "20.7131695",
    lng: "76.5650829",
    country: "IN",
    country_long: "India",
    portCode: "INKGA",
    portName: "Khamgaon",
    portContinent: "Asia",
  },
  {
    lat: "12.5265661",
    lng: "78.2149575",
    country: "IN",
    country_long: "India",
    portCode: "INKGI",
    portName: "Krishnagiri",
    portContinent: "Asia",
  },
  {
    lat: "20.3757807",
    lng: "72.9013911",
    country: "IN",
    country_long: "India",
    portCode: "INKGM",
    portName: "Kachigam",
    portContinent: "Asia",
  },
  {
    lat: "17.6966354",
    lng: "74.1607179",
    country: "IN",
    country_long: "India",
    portCode: "INKGN",
    portName: "Koregaon",
    portContinent: "Asia",
  },
  {
    lat: "22.34601",
    lng: "87.2319753",
    country: "IN",
    country_long: "India",
    portCode: "INKGR",
    portName: "Kharagpur",
    portContinent: "Asia",
  },
  {
    lat: "28.9209151",
    lng: "79.96962769999999",
    country: "IN",
    country_long: "India",
    portCode: "INKHA",
    portName: "Khatima",
    portContinent: "Asia",
  },
  {
    lat: "23.022505",
    lng: "72.5713621",
    country: "IN",
    country_long: "India",
    portCode: "INKHD",
    portName: "Kheda (INKHD), Ahmedabad, India",
    portContinent: "Asia",
  },
  {
    lat: "22.750651",
    lng: "72.68466579999999",
    country: "IN",
    country_long: "India",
    portCode: "INKHE",
    portName: "Kheda",
    portContinent: "Asia",
  },
  {
    lat: "22.2052603",
    lng: "69.6587765",
    country: "IN",
    country_long: "India",
    portCode: "INKHI",
    portName: "Khambhaliya",
    portContinent: "Asia",
  },
  {
    lat: "20.1342042",
    lng: "84.01674229999999",
    country: "IN",
    country_long: "India",
    portCode: "INKHL",
    portName: "Kandhamal",
    portContinent: "Asia",
  },
  {
    lat: "17.2472528",
    lng: "80.1514447",
    country: "IN",
    country_long: "India",
    portCode: "INKHM",
    portName: "Khammam",
    portContinent: "Asia",
  },
  {
    lat: "19.9137363",
    lng: "83.1649001",
    country: "IN",
    country_long: "India",
    portCode: "INKHN",
    portName: "Kalahandi",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKHO",
    portName: "Khodiyar",
    portContinent: "Asia",
  },
  {
    lat: "19.2013699",
    lng: "72.9724459",
    country: "IN",
    country_long: "India",
    portCode: "INKHP",
    portName: "Khopta",
    portContinent: "Asia",
  },
  {
    lat: "21.628933",
    lng: "85.5816847",
    country: "IN",
    country_long: "India",
    portCode: "INKHR",
    portName: "Kendujhar",
    portContinent: "Asia",
  },
  {
    lat: "28.4222734",
    lng: "76.99992979999999",
    country: "IN",
    country_long: "India",
    portCode: "INKHS",
    portName: "Khandsha",
    portContinent: "Asia",
  },
  {
    lat: "29.2772598",
    lng: "77.7338472",
    country: "IN",
    country_long: "India",
    portCode: "INKHU",
    portName: "Khatauli",
    portContinent: "Asia",
  },
  {
    lat: "24.8649128",
    lng: "92.3591531",
    country: "IN",
    country_long: "India",
    portCode: "INKIM",
    portName: "Karimganj",
    portContinent: "Asia",
  },
  {
    lat: "33.3115906",
    lng: "75.76621949999999",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKIT",
    portName: "Kishtwar",
    portContinent: "Asia",
  },
  {
    lat: "19.6147926",
    lng: "72.7363158",
    country: "IN",
    country_long: "India",
    portCode: "INKIW",
    portName: "Kelwa",
    portContinent: "Asia",
  },
  {
    lat: "26.6031252",
    lng: "85.9253439",
    country: "IN",
    country_long: "India",
    portCode: "INKJI",
    portName: "Pipraun Lcs",
    portContinent: "Asia",
  },
  {
    lat: "12.9715987",
    lng: "77.5945627",
    country: "IN",
    country_long: "India",
    portCode: "INKJM",
    portName: "Bd Sez/Bangalore",
    portContinent: "Asia",
  },
  {
    lat: "12.9689968",
    lng: "77.72088529999999",
    country: "IN",
    country_long: "India",
    portCode: "INKJR",
    portName: "Bbrel Sez/Kundalahalli",
    portContinent: "Asia",
  },
  {
    lat: "12.8978723",
    lng: "77.7120441",
    country: "IN",
    country_long: "India",
    portCode: "INKJW",
    portName: "Wipro Sez/Kodathi",
    portContinent: "Asia",
  },
  {
    lat: "10.0158605",
    lng: "76.3418666",
    country: "IN",
    country_long: "India",
    portCode: "INKKK",
    portName: "Kakkanad",
    portContinent: "Asia",
  },
  {
    lat: "16.5527225",
    lng: "81.2129365",
    country: "IN",
    country_long: "India",
    portCode: "INKKL",
    portName: "Kaikalur",
    portContinent: "Asia",
  },
  {
    lat: "8.0883064",
    lng: "77.5384507",
    country: "IN",
    country_long: "India",
    portCode: "INKKM",
    portName: "Kanyakumari (INKKM), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "12.5462442",
    lng: "77.4198823",
    country: "IN",
    country_long: "India",
    portCode: "INKKP",
    portName: "Kanakapura",
    portContinent: "Asia",
  },
  {
    lat: "9.2343379",
    lng: "78.7836027",
    country: "IN",
    country_long: "India",
    portCode: "INKKR",
    portName: "Kilakari",
    portContinent: "Asia",
  },
  {
    lat: "26.9238427",
    lng: "75.7156603",
    country: "IN",
    country_long: "India",
    portCode: "INKKU",
    portName: "Jaipur-Kanakpura",
    portContinent: "Asia",
  },
  {
    lat: "19.032803",
    lng: "73.10121529999999",
    country: "IN",
    country_long: "India",
    portCode: "INKLE",
    portName: "Kalamboli",
    portContinent: "Asia",
  },
  {
    lat: "16.7049873",
    lng: "74.24325270000001",
    country: "IN",
    country_long: "India",
    portCode: "INKLH",
    portName: "KolhÃ¥pur",
    portContinent: "Asia",
  },
  {
    lat: "19.032803",
    lng: "73.10121529999999",
    country: "IN",
    country_long: "India",
    portCode: "INKLM",
    portName: "Multi Services-SEZ/Kalamboli",
    portContinent: "Asia",
  },
  {
    lat: "19.032803",
    lng: "73.10121529999999",
    country: "IN",
    country_long: "India",
    portCode: "INKLN",
    portName: "IT-ITES-SEZ/Kalamboli",
    portContinent: "Asia",
  },
  {
    lat: "18.8305523",
    lng: "73.2846445",
    country: "IN",
    country_long: "India",
    portCode: "INKLP",
    portName: "Khalapur",
    portContinent: "Asia",
  },
  {
    lat: "13.1362143",
    lng: "78.12909859999999",
    country: "IN",
    country_long: "India",
    portCode: "INKLR",
    portName: "Kolar",
    portContinent: "Asia",
  },
  {
    lat: "19.2403305",
    lng: "73.1305395",
    country: "IN",
    country_long: "India",
    portCode: "INKLY",
    portName: "Kalyan",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKMA",
    portName: "Kulkuli Lcs",
    portContinent: "Asia",
  },
  {
    lat: "17.6499977",
    lng: "75.988412",
    country: "IN",
    country_long: "India",
    portCode: "INKMB",
    portName: "Kumbharu",
    portContinent: "Asia",
  },
  {
    lat: "22.5429215",
    lng: "72.90176029999999",
    country: "IN",
    country_long: "India",
    portCode: "INKMD",
    portName: "Karamsad",
    portContinent: "Asia",
  },
  {
    lat: "25.593192",
    lng: "85.1851427",
    country: "IN",
    country_long: "India",
    portCode: "INKMH",
    portName: "Kumhrar",
    portContinent: "Asia",
  },
  {
    lat: "12.6289062",
    lng: "78.10922839999999",
    country: "IN",
    country_long: "India",
    portCode: "INKML",
    portName: "Deipl Sez/Kurubarapalli",
    portContinent: "Asia",
  },
  {
    lat: "18.4385553",
    lng: "79.1288412",
    country: "IN",
    country_long: "India",
    portCode: "INKMR",
    portName: "Karimnagar",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKND",
    portName: "Kankudy",
    portContinent: "Asia",
  },
  {
    lat: "10.2679003",
    lng: "76.354748",
    country: "IN",
    country_long: "India",
    portCode: "INKNK",
    portName: "INFOPARK SEZ/Koratty",
    portContinent: "Asia",
  },
  {
    lat: "26.4449926",
    lng: "86.76928869999999",
    country: "IN",
    country_long: "India",
    portCode: "INKNL",
    portName: "Kunauli",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKNM",
    portName: "Startrack",
    portContinent: "Asia",
  },
  {
    lat: "11.8744775",
    lng: "75.37036619999999",
    country: "IN",
    country_long: "India",
    portCode: "INKNN",
    portName: "Kannur",
    portContinent: "Asia",
  },
  {
    lat: "20.4969108",
    lng: "86.4288534",
    country: "IN",
    country_long: "India",
    portCode: "INKNP",
    portName: "Kendrapara",
    portContinent: "Asia",
  },
  {
    lat: "26.449923",
    lng: "80.3318736",
    country: "IN",
    country_long: "India",
    portCode: "INKNU",
    portName: "Kanpur/ JRY",
    portContinent: "Asia",
  },
  {
    lat: "9.9312328",
    lng: "76.26730409999999",
    country: "IN",
    country_long: "India",
    portCode: "INKOC",
    portName: "Kochi",
    portContinent: "Asia",
  },
  {
    lat: "20.8009246",
    lng: "70.6960306",
    country: "IN",
    country_long: "India",
    portCode: "INKOD",
    portName: "Kodinar",
    portContinent: "Asia",
  },
  {
    lat: "18.0529357",
    lng: "78.261853",
    country: "IN",
    country_long: "India",
    portCode: "INKOH",
    portName: "Rll-SEZ/Medak",
    portContinent: "Asia",
  },
  {
    lat: "8.996334599999999",
    lng: "76.5242554",
    country: "IN",
    country_long: "India",
    portCode: "INKOI",
    portName: "Koilthottum",
    portContinent: "Asia",
  },
  {
    lat: "47.4845919",
    lng: "19.5798512",
    country: "HU",
    country_long: "Hungary",
    portCode: "INKOK",
    portName: "Koka",
    portContinent: "Asia",
  },
  {
    lat: "21.8035242",
    lng: "84.1738463",
    country: "IN",
    country_long: "India",
    portCode: "INKOL",
    portName: "Kolabira",
    portContinent: "Asia",
  },
  {
    lat: "25.364009",
    lng: "60.39934890000001",
    country: "IR",
    country_long: "Iran",
    portCode: "INKON",
    portName: "Konarak",
    portContinent: "Asia",
  },
  {
    lat: "26.4014362",
    lng: "90.266699",
    country: "IN",
    country_long: "India",
    portCode: "INKOR",
    portName: "Kokrajhar",
    portContinent: "Asia",
  },
  {
    lat: "17.5559725",
    lng: "80.6169577",
    country: "IN",
    country_long: "India",
    portCode: "INKOT",
    portName: "Kothagudem",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKOY",
    portName: "Lkoyali",
    portContinent: "Asia",
  },
  {
    lat: "10.0665104",
    lng: "73.6447908",
    country: "IN",
    country_long: "India",
    portCode: "INKPI",
    portName: "Kalpeni Island",
    portContinent: "Asia",
  },
  {
    lat: "21.0589527",
    lng: "79.0306032",
    country: "IN",
    country_long: "India",
    portCode: "INKPK",
    portName: "Concor Icd/Mihan",
    portContinent: "Asia",
  },
  {
    lat: "18.7939054",
    lng: "73.334643",
    country: "IN",
    country_long: "India",
    portCode: "INKPL",
    portName: "Khopoli",
    portContinent: "Asia",
  },
  {
    lat: "31.3722571",
    lng: "75.4017654",
    country: "IN",
    country_long: "India",
    portCode: "INKPT",
    portName: "Kapurthala",
    portContinent: "Asia",
  },
  {
    lat: "27.8941595",
    lng: "79.19717419999999",
    country: "IN",
    country_long: "India",
    portCode: "INKRA",
    portName: "Kakrala",
    portContinent: "Asia",
  },
  {
    lat: "19.1362362",
    lng: "75.21873459999999",
    country: "IN",
    country_long: "India",
    portCode: "INKRG",
    portName: "Karegaon",
    portContinent: "Asia",
  },
  {
    lat: "14.2545822",
    lng: "80.109357",
    country: "IN",
    country_long: "India",
    portCode: "INKRI",
    portName: "Port of Krishnapatnam",
    portContinent: "Asia",
  },
  {
    lat: "10.9254398",
    lng: "79.8380056",
    country: "IN",
    country_long: "India",
    portCode: "INKRK",
    portName: "Karaikal (INKRK), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "29.6856929",
    lng: "76.9904825",
    country: "IN",
    country_long: "India",
    portCode: "INKRL",
    portName: "Karnal",
    portContinent: "Asia",
  },
  {
    lat: "21.0489512",
    lng: "79.0348416",
    country: "IN",
    country_long: "India",
    portCode: "INKRM",
    portName: "MADCL SEZ/Nagpur",
    portContinent: "Asia",
  },
  {
    lat: "20.4827357",
    lng: "77.4816693",
    country: "IN",
    country_long: "India",
    portCode: "INKRN",
    portName: "Karanja",
    portContinent: "Asia",
  },
  {
    lat: "15.7356122",
    lng: "73.71756549999999",
    country: "IN",
    country_long: "India",
    portCode: "INKRP",
    portName: "Kiranpani",
    portContinent: "Asia",
  },
  {
    lat: "18.813487",
    lng: "82.71233269999999",
    country: "IN",
    country_long: "India",
    portCode: "INKRT",
    portName: "Koraput",
    portContinent: "Asia",
  },
  {
    lat: "14.7994475",
    lng: "74.1150035",
    country: "IN",
    country_long: "India",
    portCode: "INKRW",
    portName: "Port of Karwar",
    portContinent: "Asia",
  },
  {
    lat: "12.4995966",
    lng: "74.9869276",
    country: "IN",
    country_long: "India",
    portCode: "INKSG",
    portName: "Kasargod",
    portContinent: "Asia",
  },
  {
    lat: "17.9224042",
    lng: "73.0611882",
    country: "IN",
    country_long: "India",
    portCode: "INKSH",
    portName: "Kelshi",
    portContinent: "Asia",
  },
  {
    lat: "8.3988908",
    lng: "78.05236099999999",
    country: "IN",
    country_long: "India",
    portCode: "INKSP",
    portName: "Kulasekarapanam",
    portContinent: "Asia",
  },
  {
    lat: "29.2104232",
    lng: "78.9618845",
    country: "IN",
    country_long: "India",
    portCode: "INKSR",
    portName: "Kashipur",
    portContinent: "Asia",
  },
  {
    lat: "24.3581619",
    lng: "73.1762626",
    country: "IN",
    country_long: "India",
    portCode: "INKTD",
    portName: "Kotda",
    portContinent: "Asia",
  },
  {
    lat: "28.335926",
    lng: "81.12352969999999",
    country: "IN",
    country_long: "India",
    portCode: "INKTG",
    portName: "Katarniyaghat",
    portContinent: "Asia",
  },
  {
    lat: "11.4838729",
    lng: "73.00658159999999",
    country: "IN",
    country_long: "India",
    portCode: "INKTI",
    portName: "Kiltan Is",
    portContinent: "Asia",
  },
  {
    lat: "15.2026796",
    lng: "76.6586928",
    country: "IN",
    country_long: "India",
    portCode: "INKTK",
    portName: "Toranagallu",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKTR",
    portName: "Kathinhar",
    portContinent: "Asia",
  },
  {
    lat: "12.9682126",
    lng: "80.2599427",
    country: "IN",
    country_long: "India",
    portCode: "INKTT",
    portName: "Kottivakkam",
    portContinent: "Asia",
  },
  {
    lat: "25.2138156",
    lng: "75.8647527",
    country: "IN",
    country_long: "India",
    portCode: "INKTU",
    portName: "Kota",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKTW",
    portName: "Koheshwar",
    portContinent: "Asia",
  },
  {
    lat: "13.6235274",
    lng: "74.6917418",
    country: "IN",
    country_long: "India",
    portCode: "INKUD",
    portName: "Kundapur",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKUI",
    portName: "Kundli",
    portContinent: "Asia",
  },
  {
    lat: "8.8932118",
    lng: "76.6141396",
    country: "IN",
    country_long: "India",
    portCode: "INKUK",
    portName: "Kollam (INKUK), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.6449908",
    lng: "75.018031",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKUL",
    portName: "Kulgam",
    portContinent: "Asia",
  },
  {
    lat: "24.3489553",
    lng: "75.297485",
    country: "IN",
    country_long: "India",
    portCode: "INKUN",
    portName: "Kundla",
    portContinent: "Asia",
  },
  {
    lat: "20.1862584",
    lng: "85.62227419999999",
    country: "IN",
    country_long: "India",
    portCode: "INKUR",
    portName: "OIIDC SEZ/Khurda",
    portContinent: "Asia",
  },
  {
    lat: "26.7398787",
    lng: "83.8869698",
    country: "IN",
    country_long: "India",
    portCode: "INKUS",
    portName: "Kushinagar",
    portContinent: "Asia",
  },
  {
    lat: "39.090245",
    lng: "33.08052800000001",
    country: "TR",
    country_long: "Turkey",
    portCode: "INKUU",
    portName: "Kulu",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKVI",
    portName: "Kavi",
    portContinent: "Asia",
  },
  {
    lat: "8.3988128",
    lng: "76.9820149",
    country: "IN",
    country_long: "India",
    portCode: "INKVL",
    portName: "Kovalam",
    portContinent: "Asia",
  },
  {
    lat: "17.0125618",
    lng: "81.727383",
    country: "IN",
    country_long: "India",
    portCode: "INKVR",
    portName: "WFPML-SEZ/Kovvur",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKVT",
    portName: "Kavaram Is",
    portContinent: "Asia",
  },
  {
    lat: "27.4561429",
    lng: "82.9951851",
    country: "IN",
    country_long: "India",
    portCode: "INKWA",
    portName: "Khunwa",
    portContinent: "Asia",
  },
  {
    lat: "22.120897",
    lng: "75.611128",
    country: "IN",
    country_long: "India",
    portCode: "INKWD",
    portName: "Kasrawad",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INKWG",
    portName: "Khowaighat",
    portContinent: "Asia",
  },
  {
    lat: "25.6068239",
    lng: "81.77425889999999",
    country: "IN",
    country_long: "India",
    portCode: "INKWH",
    portName: "Kakarahawa Lcs",
    portContinent: "Asia",
  },
  {
    lat: "13.0693568",
    lng: "80.1948314",
    country: "IN",
    country_long: "India",
    portCode: "INKYD",
    portName: "Koyambedu",
    portContinent: "Asia",
  },
  {
    lat: "9.591566799999999",
    lng: "76.5221531",
    country: "IN",
    country_long: "India",
    portCode: "INKYM",
    portName: "Kottayam",
    portContinent: "Asia",
  },
  {
    lat: "27.9064649",
    lng: "80.79624919999999",
    country: "IN",
    country_long: "India",
    portCode: "INLAH",
    portName: "Kheri",
    portContinent: "Asia",
  },
  {
    lat: "29.0676175",
    lng: "79.5181884",
    country: "IN",
    country_long: "India",
    portCode: "INLAK",
    portName: "Lalakua",
    portContinent: "Asia",
  },
  {
    lat: "30.4928634",
    lng: "76.8019683",
    country: "IN",
    country_long: "India",
    portCode: "INLAL",
    portName: "Lalru",
    portContinent: "Asia",
  },
  {
    lat: "18.4087934",
    lng: "76.5603828",
    country: "IN",
    country_long: "India",
    portCode: "INLAT",
    portName: "Latur",
    portContinent: "Asia",
  },
  {
    lat: "26.7910343",
    lng: "81.0242561",
    country: "IN",
    country_long: "India",
    portCode: "INLCH",
    portName: "HCL SEZ/Lucknow",
    portContinent: "Asia",
  },
  {
    lat: "25.1785773",
    lng: "88.24611829999999",
    country: "IN",
    country_long: "India",
    portCode: "INLDA",
    portName: "Malda",
    portContinent: "Asia",
  },
  {
    lat: "30.900965",
    lng: "75.8572758",
    country: "IN",
    country_long: "India",
    portCode: "INLDH",
    portName: "LUDHIANA",
    portContinent: "Asia",
  },
  {
    lat: "24.4133349",
    lng: "88.26071879999999",
    country: "IN",
    country_long: "India",
    portCode: "INLGL",
    portName: "Lalgola Town",
    portContinent: "Asia",
  },
  {
    lat: "27.9462395",
    lng: "80.7787163",
    country: "IN",
    country_long: "India",
    portCode: "INLIM",
    portName: "Lakhimpur",
    portContinent: "Asia",
  },
  {
    lat: "18.4878505",
    lng: "74.0234138",
    country: "IN",
    country_long: "India",
    portCode: "INLKH",
    portName: "Loni Kalbhor",
    portContinent: "Asia",
  },
  {
    lat: "26.8466937",
    lng: "80.94616599999999",
    country: "IN",
    country_long: "India",
    portCode: "INLKO",
    portName: "Lucknow",
    portContinent: "Asia",
  },
  {
    lat: "26.5498118",
    lng: "86.4667951",
    country: "IN",
    country_long: "India",
    portCode: "INLKQ",
    portName: "Laukaha Lcs",
    portContinent: "Asia",
  },
  {
    lat: "21.7236434",
    lng: "84.0154204",
    country: "IN",
    country_long: "India",
    portCode: "INLNG",
    portName: "Lapanga",
    portContinent: "Asia",
  },
  {
    lat: "28.7333526",
    lng: "77.2986264",
    country: "IN",
    country_long: "India",
    portCode: "INLNI",
    portName: "Loni",
    portContinent: "Asia",
  },
  {
    lat: "23.4409325",
    lng: "84.6845216",
    country: "IN",
    country_long: "India",
    portCode: "INLOH",
    portName: "Lohardaga",
    portContinent: "Asia",
  },
  {
    lat: "28.7333526",
    lng: "77.2986264",
    country: "IN",
    country_long: "India",
    portCode: "INLON",
    portName: "Loni",
    portContinent: "Asia",
  },
  {
    lat: "22.4707019",
    lng: "70.05773",
    country: "IN",
    country_long: "India",
    portCode: "INLPJ",
    portName: "Reliance SEZ/Jamnagar",
    portContinent: "Asia",
  },
  {
    lat: "8.1108535",
    lng: "77.5571787",
    country: "IN",
    country_long: "India",
    portCode: "INLPR",
    portName: "Leapuram",
    portContinent: "Asia",
  },
  {
    lat: "17.3891379",
    lng: "77.8367282",
    country: "IN",
    country_long: "India",
    portCode: "INLPS",
    portName: "DSIL SEZ/Ranga Reddy",
    portContinent: "Asia",
  },
  {
    lat: "24.8406395",
    lng: "92.25631779999999",
    country: "IN",
    country_long: "India",
    portCode: "INLTB",
    portName: "Latu Bazar",
    portContinent: "Asia",
  },
  {
    lat: "30.900965",
    lng: "75.8572758",
    country: "IN",
    country_long: "India",
    portCode: "INLUH",
    portName: "Ludhiana",
    portContinent: "Asia",
  },
  {
    lat: "10.32802650733173",
    lng: "72.784633527575",
    country: "IN",
    country_long: "India",
    portCode: "INLWP",
    portName: "Lakshadweep",
    portContinent: "Asia",
  },
  {
    lat: "13.0826802",
    lng: "80.2707184",
    country: "IN",
    country_long: "India",
    portCode: "INMAA",
    portName: "Chennai (ex Madras)",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INMAB",
    portName: "Kanara",
    portContinent: "Asia",
  },
  {
    lat: "8.8588183",
    lng: "77.7802041",
    country: "IN",
    country_long: "India",
    portCode: "INMAE",
    portName: "ECTNL SEZ/Gangaikondan",
    portContinent: "Asia",
  },
  {
    lat: "12.9576756",
    lng: "77.22614519999999",
    country: "IN",
    country_long: "India",
    portCode: "INMAG",
    portName: "Magadi",
    portContinent: "Asia",
  },
  {
    lat: "11.7002703",
    lng: "75.5424843",
    country: "IN",
    country_long: "India",
    portCode: "INMAH",
    portName: "Mahe (INMAH), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "12.9141417",
    lng: "74.8559568",
    country: "IN",
    country_long: "India",
    portCode: "INMAI",
    portName: "Mangalore",
    portContinent: "Asia",
  },
  {
    lat: "27.1446035",
    lng: "83.5621737",
    country: "IN",
    country_long: "India",
    portCode: "INMAJ",
    portName: "Maharajganj",
    portContinent: "Asia",
  },
  {
    lat: "13.3554792",
    lng: "74.70444250000001",
    country: "IN",
    country_long: "India",
    portCode: "INMAL",
    portName: "Malpe (INMAL), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "24.0734356",
    lng: "75.0679018",
    country: "IN",
    country_long: "India",
    portCode: "INMAN",
    portName: "Mandsaur",
    portContinent: "Asia",
  },
  {
    lat: "16.1808917",
    lng: "81.1302716",
    country: "IN",
    country_long: "India",
    portCode: "INMAP",
    portName: "Masulipatnam",
    portContinent: "Asia",
  },
  {
    lat: "12.9825489",
    lng: "74.8636763",
    country: "IN",
    country_long: "India",
    portCode: "INMAQ",
    portName: "Mangalore SEZ",
    portContinent: "Asia",
  },
  {
    lat: "26.2528853",
    lng: "92.33695499999999",
    country: "IN",
    country_long: "India",
    portCode: "INMAR",
    portName: "Morigaon",
    portContinent: "Asia",
  },
  {
    lat: "12.9076668",
    lng: "80.2192698",
    country: "IN",
    country_long: "India",
    portCode: "INMAS",
    portName: "TIPL SEZ/Chennai",
    portContinent: "Asia",
  },
  {
    lat: "27.4924134",
    lng: "77.673673",
    country: "IN",
    country_long: "India",
    portCode: "INMAT",
    portName: "Mathura",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INMAU",
    portName: "Mau",
    portContinent: "Asia",
  },
  {
    lat: "12.9497802",
    lng: "77.58977300000001",
    country: "IN",
    country_long: "India",
    portCode: "INMAV",
    portName: "Mavalli",
    portContinent: "Asia",
  },
  {
    lat: "28.826312",
    lng: "78.6720593",
    country: "IN",
    country_long: "India",
    portCode: "INMBD",
    portName: "Pakwara (Moradabad)",
    portContinent: "Asia",
  },
  {
    lat: "25.4358011",
    lng: "81.846311",
    country: "IN",
    country_long: "India",
    portCode: "INMBS",
    portName: "Madhosingh/Allahabad",
    portContinent: "Asia",
  },
  {
    lat: "22.5435708",
    lng: "88.26793400000001",
    country: "IN",
    country_long: "India",
    portCode: "INMBZ",
    portName: "Metia Bruz",
    portContinent: "Asia",
  },
  {
    lat: "30.9141349",
    lng: "76.19286400000001",
    country: "IN",
    country_long: "India",
    portCode: "INMCC",
    portName: "Machhiwara",
    portContinent: "Asia",
  },
  {
    lat: "8.273962",
    lng: "73.04958599999999",
    country: "IN",
    country_long: "India",
    portCode: "INMCI",
    portName: "Minicoi I",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INMDA",
    portName: "Port of Magdalla",
    portContinent: "Asia",
  },
  {
    lat: "9.9252007",
    lng: "78.1197754",
    country: "IN",
    country_long: "India",
    portCode: "INMDC",
    portName: "ECTN SEZ/Madurai-II",
    portContinent: "Asia",
  },
  {
    lat: "23.0992796",
    lng: "77.5114731",
    country: "IN",
    country_long: "India",
    portCode: "INMDD",
    portName: "Mandideep (INMDD), Bhopal, India",
    portContinent: "Asia",
  },
  {
    lat: "18.6725047",
    lng: "78.09408669999999",
    country: "IN",
    country_long: "India",
    portCode: "INMDE",
    portName: "APIICL-SEZ/Medak(ICD/CFS/SEZ) (INMDE), Nizamabad, India",
    portContinent: "Asia",
  },
  {
    lat: "20.7688994",
    lng: "70.6662915",
    country: "IN",
    country_long: "India",
    portCode: "INMDK",
    portName: "Muldwarka (INMDK), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "9.277039199999999",
    lng: "79.1252174",
    country: "IN",
    country_long: "India",
    portCode: "INMDP",
    portName: "Mandapam",
    portContinent: "Asia",
  },
  {
    lat: "9.9252007",
    lng: "78.1197754",
    country: "IN",
    country_long: "India",
    portCode: "INMDR",
    portName: "RTPL SEZ/Madurai",
    portContinent: "Asia",
  },
  {
    lat: "12.5089244",
    lng: "79.88473549999999",
    country: "IN",
    country_long: "India",
    portCode: "INMDT",
    portName: "Maduranthakam",
    portContinent: "Asia",
  },
  {
    lat: "9.8267124",
    lng: "78.0988129",
    country: "IN",
    country_long: "India",
    portCode: "INMDU",
    portName: "Kern Icd/Madurai",
    portContinent: "Asia",
  },
  {
    lat: "22.833334",
    lng: "69.3554783",
    country: "IN",
    country_long: "India",
    portCode: "INMDV",
    portName: "Port of Mandvi",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INMDW",
    portName: "Meadows",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INMEA",
    portName: "APIIC-SEZ/Lalgadi",
    portContinent: "Asia",
  },
  {
    lat: "9.9252007",
    lng: "78.1197754",
    country: "IN",
    country_long: "India",
    portCode: "INMEC",
    portName: "ECTN SEZ/Madurai-I",
    portContinent: "Asia",
  },
  {
    lat: "22.4256613",
    lng: "87.3198819",
    country: "IN",
    country_long: "India",
    portCode: "INMED",
    portName: "Medinipur",
    portContinent: "Asia",
  },
  {
    lat: "28.9844618",
    lng: "77.7064137",
    country: "IN",
    country_long: "India",
    portCode: "INMER",
    portName: "Meerut",
    portContinent: "Asia",
  },
  {
    lat: "25.298502",
    lng: "89.85284159999999",
    country: "IN",
    country_long: "India",
    portCode: "INMGH",
    portName: "Mahendraganj",
    portContinent: "Asia",
  },
  {
    lat: "26.5839309",
    lng: "74.8496692",
    country: "IN",
    country_long: "India",
    portCode: "INMGJ",
    portName: "Madanganj",
    portContinent: "Asia",
  },
  {
    lat: "21.1171698",
    lng: "70.11581129999999",
    country: "IN",
    country_long: "India",
    portCode: "INMGR",
    portName: "Mangrol (INMGR), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "21.0921598",
    lng: "71.7704631",
    country: "IN",
    country_long: "India",
    portCode: "INMHA",
    portName: "Mahuva",
    portContinent: "Asia",
  },
  {
    lat: "16.7488379",
    lng: "78.00351719999999",
    country: "IN",
    country_long: "India",
    portCode: "INMHB",
    portName: "Mahbubnagar",
    portContinent: "Asia",
  },
  {
    lat: "26.7491274",
    lng: "83.4045008",
    country: "IN",
    country_long: "India",
    portCode: "INMHD",
    portName: "Kotawalighat (Mohedipur)",
    portContinent: "Asia",
  },
  {
    lat: "27.8474039",
    lng: "82.6993866",
    country: "NP",
    country_long: "Nepal",
    portCode: "INMHG",
    portName: "Mahurighat",
    portContinent: "Asia",
  },
  {
    lat: "30.7046486",
    lng: "76.71787259999999",
    country: "IN",
    country_long: "India",
    portCode: "INMHI",
    portName: "Mohali",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INMHN",
    portName: "Mahisasan",
    portContinent: "Asia",
  },
  {
    lat: "23.7661639",
    lng: "90.3588734",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "INMHP",
    portName: "Muhammadpur",
    portContinent: "Asia",
  },
  {
    lat: "23.6397083",
    lng: "90.7034053",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "INMKC",
    portName: "Manikarchar",
    portContinent: "Asia",
  },
  {
    lat: "13.0212637",
    lng: "80.18324580000001",
    country: "IN",
    country_long: "India",
    portCode: "INMKK",
    portName: "Manapakkam",
    portContinent: "Asia",
  },
  {
    lat: "10.2922744",
    lng: "76.1653315",
    country: "IN",
    country_long: "India",
    portCode: "INMKM",
    portName: "Mathilakam",
    portContinent: "Asia",
  },
  {
    lat: "27.0285581",
    lng: "74.714621",
    country: "IN",
    country_long: "India",
    portCode: "INMKN",
    portName: "Makrana",
    portContinent: "Asia",
  },
  {
    lat: "21.0238715",
    lng: "72.88855290000001",
    country: "IN",
    country_long: "India",
    portCode: "INMLI",
    portName: "Maroli",
    portContinent: "Asia",
  },
  {
    lat: "11.1111928",
    lng: "76.17837390000001",
    country: "IN",
    country_long: "India",
    portCode: "INMLP",
    portName: "Mallipuram",
    portContinent: "Asia",
  },
  {
    lat: "13.0037225",
    lng: "77.9383033",
    country: "IN",
    country_long: "India",
    portCode: "INMLR",
    portName: "Malur",
    portContinent: "Asia",
  },
  {
    lat: "16.0630693",
    lng: "73.471054",
    country: "IN",
    country_long: "India",
    portCode: "INMLW",
    portName: "Malwan",
    portContinent: "Asia",
  },
  {
    lat: "25.7446275",
    lng: "70.2780362",
    country: "IN",
    country_long: "India",
    portCode: "INMNB",
    portName: "Munabao",
    portContinent: "Asia",
  },
  {
    lat: "32.2431872",
    lng: "77.1891761",
    country: "IN",
    country_long: "India",
    portCode: "INMNI",
    portName: "Manali",
    portContinent: "Asia",
  },
  {
    lat: "19.2077161",
    lng: "72.7833095",
    country: "IN",
    country_long: "India",
    portCode: "INMNR",
    portName: "Manori",
    portContinent: "Asia",
  },
  {
    lat: "29.9995069",
    lng: "75.3936808",
    country: "IN",
    country_long: "India",
    portCode: "INMNS",
    portName: "Mansa",
    portContinent: "Asia",
  },
  {
    lat: "10.6368867",
    lng: "76.2804955",
    country: "IN",
    country_long: "India",
    portCode: "INMNT",
    portName: "Manalithara",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INMNU",
    portName: "Manu",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INMNW",
    portName: "Mandwa",
    portContinent: "Asia",
  },
  {
    lat: "22.8251874",
    lng: "70.84908089999999",
    country: "IN",
    country_long: "India",
    portCode: "INMOB",
    portName: "Morbi",
    portContinent: "Asia",
  },
  {
    lat: "26.4947172",
    lng: "77.9940222",
    country: "IN",
    country_long: "India",
    portCode: "INMOE",
    portName: "Morena",
    portContinent: "Asia",
  },
  {
    lat: "30.8230114",
    lng: "75.17344709999999",
    country: "IN",
    country_long: "India",
    portCode: "INMOG",
    portName: "Moga",
    portContinent: "Asia",
  },
  {
    lat: "27.4728327",
    lng: "94.9119621",
    country: "IN",
    country_long: "India",
    portCode: "INMOH",
    portName: "Mohanbari",
    portContinent: "Asia",
  },
  {
    lat: "28.8386481",
    lng: "78.7733286",
    country: "IN",
    country_long: "India",
    portCode: "INMOR",
    portName: "Moradabad",
    portContinent: "Asia",
  },
  {
    lat: "18.0529357",
    lng: "78.261853",
    country: "IN",
    country_long: "India",
    portCode: "INMOV",
    portName: "VBTL-SEZ/Medak",
    portContinent: "Asia",
  },
  {
    lat: "27.2281937",
    lng: "79.02516589999999",
    country: "IN",
    country_long: "India",
    portCode: "INMPI",
    portName: "Mainpuri",
    portContinent: "Asia",
  },
  {
    lat: "26.3670378",
    lng: "78.2874772",
    country: "IN",
    country_long: "India",
    portCode: "INMPR",
    portName: "Malanpur",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INMRA",
    portName: "Mora",
    portContinent: "Asia",
  },
  {
    lat: "24.251281",
    lng: "94.3013043",
    country: "IN",
    country_long: "India",
    portCode: "INMRE",
    portName: "Moreh",
    portContinent: "Asia",
  },
  {
    lat: "15.2832187",
    lng: "73.98619099999999",
    country: "IN",
    country_long: "India",
    portCode: "INMRG",
    portName: "Madgaon",
    portContinent: "Asia",
  },
  {
    lat: "22.82582",
    lng: "69.3443039",
    country: "IN",
    country_long: "India",
    portCode: "INMRI",
    portName: "Mithivirdi",
    portContinent: "Asia",
  },
  {
    lat: "16.8222363",
    lng: "74.6508894",
    country: "IN",
    country_long: "India",
    portCode: "INMRJ",
    portName: "Miraj",
    portContinent: "Asia",
  },
  {
    lat: "16.8222363",
    lng: "74.6508894",
    country: "IN",
    country_long: "India",
    portCode: "INMRJ6",
    portName: "Miraj",
    portContinent: "Asia",
  },
  {
    lat: "11.0509762",
    lng: "76.0710967",
    country: "IN",
    country_long: "India",
    portCode: "INMRK",
    portName: "Malappuram",
    portContinent: "Asia",
  },
  {
    lat: "15.3889087",
    lng: "73.81656679999999",
    country: "IN",
    country_long: "India",
    portCode: "INMRM",
    portName: "Port of Mormugao",
    portContinent: "Asia",
  },
  {
    lat: "23.5879607",
    lng: "72.36932519999999",
    country: "IN",
    country_long: "India",
    portCode: "INMSA",
    portName: "Mahesana",
    portContinent: "Asia",
  },
  {
    lat: "29.4264658",
    lng: "74.926942",
    country: "IN",
    country_long: "India",
    portCode: "INMSH",
    portName: "Madho Singhana",
    portContinent: "Asia",
  },
  {
    lat: "11.7862533",
    lng: "77.8007853",
    country: "IN",
    country_long: "India",
    portCode: "INMTR",
    portName: "Mettur",
    portContinent: "Asia",
  },
  {
    lat: "11.3027849",
    lng: "76.9383385",
    country: "IN",
    country_long: "India",
    portCode: "INMTT",
    portName: "Mettupalayam",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INMTW",
    portName: "Metwad",
    portContinent: "Asia",
  },
  {
    lat: "19.1765331",
    lng: "72.94753299999999",
    country: "IN",
    country_long: "India",
    portCode: "INMUC",
    portName: "SCPL SEZ/Mulund",
    portContinent: "Asia",
  },
  {
    lat: "25.3707993",
    lng: "86.47339029999999",
    country: "IN",
    country_long: "India",
    portCode: "INMUG",
    portName: "Munger",
    portContinent: "Asia",
  },
  {
    lat: "30.4761773",
    lng: "74.5121599",
    country: "IN",
    country_long: "India",
    portCode: "INMUK",
    portName: "Sri Muktsar Sahib",
    portContinent: "Asia",
  },
  {
    lat: "14.4644085",
    lng: "75.921758",
    country: "IN",
    country_long: "India",
    portCode: "INMUL",
    portName: "Mulgund(ICD/CFS/SEZ) (INMUL), Davangere, India",
    portContinent: "Asia",
  },
  {
    lat: "19.0948287",
    lng: "74.74797889999999",
    country: "IN",
    country_long: "India",
    portCode: "INMUM",
    portName: "Ahmednagar",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INMUN",
    portName: "Port of Mundra",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INMUR",
    portName: "Murad",
    portContinent: "Asia",
  },
  {
    lat: "19.1765331",
    lng: "72.94753299999999",
    country: "IN",
    country_long: "India",
    portCode: "INMUU",
    portName: "Mulund",
    portContinent: "Asia",
  },
  {
    lat: "28.8344396",
    lng: "77.5698527",
    country: "IN",
    country_long: "India",
    portCode: "INMUZ",
    portName: "Modinagar Icd/Ghaziabad",
    portContinent: "Asia",
  },
  {
    lat: "19.9287072",
    lng: "75.22165199999999",
    country: "IN",
    country_long: "India",
    portCode: "INMWA",
    portName: "Maliwada",
    portContinent: "Asia",
  },
  {
    lat: "12.9130566",
    lng: "92.8977431",
    country: "IN",
    country_long: "India",
    portCode: "INMYB",
    portName: "Mayabandar",
    portContinent: "Asia",
  },
  {
    lat: "22.0086978",
    lng: "86.41873079999999",
    country: "IN",
    country_long: "India",
    portCode: "INMYN",
    portName: "Mayurbhanj",
    portContinent: "Asia",
  },
  {
    lat: "12.2958104",
    lng: "76.6393805",
    country: "IN",
    country_long: "India",
    portCode: "INMYQ",
    portName: "Mysore",
    portContinent: "Asia",
  },
  {
    lat: "13.0367914",
    lng: "80.2676303",
    country: "IN",
    country_long: "India",
    portCode: "INMYR",
    portName: "Mylapore",
    portContinent: "Asia",
  },
  {
    lat: "29.4726817",
    lng: "77.7085091",
    country: "IN",
    country_long: "India",
    portCode: "INMZA",
    portName: "Muzaffarnagar",
    portContinent: "Asia",
  },
  {
    lat: "26.1196607",
    lng: "85.390982",
    country: "IN",
    country_long: "India",
    portCode: "INMZU",
    portName: "Muzaffarpur",
    portContinent: "Asia",
  },
  {
    lat: "31.0460685",
    lng: "76.7025709",
    country: "IN",
    country_long: "India",
    portCode: "INNAA",
    portName: "Nalagarh",
    portContinent: "Asia",
  },
  {
    lat: "21.1458004",
    lng: "79.0881546",
    country: "IN",
    country_long: "India",
    portCode: "INNAG",
    portName: "Nagpur",
    portContinent: "Asia",
  },
  {
    lat: "26.4446185",
    lng: "91.4410527",
    country: "IN",
    country_long: "India",
    portCode: "INNAL",
    portName: "Nalbari",
    portContinent: "Asia",
  },
  {
    lat: "7.976840399999999",
    lng: "93.5509074",
    country: "IN",
    country_long: "India",
    portCode: "INNAN",
    portName: "Nancowrie",
    portContinent: "Asia",
  },
  {
    lat: "29.3919202",
    lng: "79.4542033",
    country: "IN",
    country_long: "India",
    portCode: "INNAT",
    portName: "Nainital",
    portContinent: "Asia",
  },
  {
    lat: "27.1983368",
    lng: "73.7493272",
    country: "IN",
    country_long: "India",
    portCode: "INNAU",
    portName: "Nagaur",
    portContinent: "Asia",
  },
  {
    lat: "22.9574143",
    lng: "70.4528608",
    country: "IN",
    country_long: "India",
    portCode: "INNAV",
    portName: "Port of Navlakhi",
    portContinent: "Asia",
  },
  {
    lat: "28.5355161",
    lng: "77.3910265",
    country: "IN",
    country_long: "India",
    portCode: "INNDA",
    portName: "Noida(ICD/CFS/SEZ) (INNDA), India",
    portContinent: "Asia",
  },
  {
    lat: "19.1382514",
    lng: "77.3209555",
    country: "IN",
    country_long: "India",
    portCode: "INNDC",
    portName: "Nanded",
    portContinent: "Asia",
  },
  {
    lat: "22.6915853",
    lng: "72.86336349999999",
    country: "IN",
    country_long: "India",
    portCode: "INNDD",
    portName: "Nadiad",
    portContinent: "Asia",
  },
  {
    lat: "27.72726",
    lng: "77.3812494",
    country: "IN",
    country_long: "India",
    portCode: "INNDG",
    portName: "Nandgaon",
    portContinent: "Asia",
  },
  {
    lat: "18.1186943",
    lng: "75.0234074",
    country: "IN",
    country_long: "India",
    portCode: "INNDP",
    portName: "Indapur",
    portContinent: "Asia",
  },
  {
    lat: "8.944319799999999",
    lng: "76.5401527",
    country: "IN",
    country_long: "India",
    portCode: "INNEE",
    portName: "Nindakara",
    portContinent: "Asia",
  },
  {
    lat: "14.4425987",
    lng: "79.98645599999999",
    country: "IN",
    country_long: "India",
    portCode: "INNEL",
    portName: "Nellore",
    portContinent: "Asia",
  },
  {
    lat: "24.4737663",
    lng: "74.8726497",
    country: "IN",
    country_long: "India",
    portCode: "INNEM",
    portName: "Neemuch",
    portContinent: "Asia",
  },
  {
    lat: "27.9796711",
    lng: "76.3961899",
    country: "IN",
    country_long: "India",
    portCode: "INNER",
    portName: "Neemrana",
    portContinent: "Asia",
  },
  {
    lat: "26.348009",
    lng: "92.6838111",
    country: "IN",
    country_long: "India",
    portCode: "INNGA",
    portName: "Nagaon",
    portContinent: "Asia",
  },
  {
    lat: "20.9276401",
    lng: "79.00399039999999",
    country: "IN",
    country_long: "India",
    portCode: "INNGB",
    portName: "Butibori ICD/Nagpur",
    portContinent: "Asia",
  },
  {
    lat: "8.183285699999999",
    lng: "77.4118996",
    country: "IN",
    country_long: "India",
    portCode: "INNGC",
    portName: "Nagercoil",
    portContinent: "Asia",
  },
  {
    lat: "11.4916043",
    lng: "76.7336521",
    country: "IN",
    country_long: "India",
    portCode: "INNGI",
    portName: "Nilgiris, Tamil Nadu",
    portContinent: "Asia",
  },
  {
    lat: "26.8809465",
    lng: "88.90785269999999",
    country: "IN",
    country_long: "India",
    portCode: "INNGK",
    portName: "Nagarkata Lcs",
    portContinent: "Asia",
  },
  {
    lat: "28.0547578",
    lng: "81.614468",
    country: "NP",
    country_long: "Nepal",
    portCode: "INNGR",
    portName: "Nepalgunj",
    portContinent: "Asia",
  },
  {
    lat: "31.8318692",
    lng: "78.72988889999999",
    country: "N/A",
    country_long: "N/A",
    portCode: "INNGS",
    portName: "Village Namaya Shipkila",
    portContinent: "Asia",
  },
  {
    lat: "18.4343482",
    lng: "73.0139616",
    country: "IN",
    country_long: "India",
    portCode: "INNHV",
    portName: "Nhave",
    portContinent: "Asia",
  },
  {
    lat: "16.8970864",
    lng: "81.6634478",
    country: "IN",
    country_long: "India",
    portCode: "INNID",
    portName: "Nidadavole",
    portContinent: "Asia",
  },
  {
    lat: "18.6725047",
    lng: "78.09408669999999",
    country: "IN",
    country_long: "India",
    portCode: "INNIZ",
    portName: "Nizamabad",
    portContinent: "Asia",
  },
  {
    lat: "29.6123401",
    lng: "78.3441643",
    country: "IN",
    country_long: "India",
    portCode: "INNJD",
    portName: "Najibabad",
    portContinent: "Asia",
  },
  {
    lat: "19.8479813",
    lng: "73.9889645",
    country: "IN",
    country_long: "India",
    portCode: "INNKI",
    portName: "Sinnar",
    portContinent: "Asia",
  },
  {
    lat: "21.7699486",
    lng: "88.23151299999999",
    country: "IN",
    country_long: "India",
    portCode: "INNKN",
    portName: "Namkhana",
    portContinent: "Asia",
  },
  {
    lat: "29.8398938",
    lng: "76.9317422",
    country: "IN",
    country_long: "India",
    portCode: "INNKR",
    portName: "Nilokheri",
    portContinent: "Asia",
  },
  {
    lat: "19.0330488",
    lng: "73.0296625",
    country: "IN",
    country_long: "India",
    portCode: "INNMB",
    portName: "Port of Navi Mumbai",
    portContinent: "Asia",
  },
  {
    lat: "23.3297763",
    lng: "85.37659029999999",
    country: "IN",
    country_long: "India",
    portCode: "INNMK",
    portName: "Namkum",
    portContinent: "Asia",
  },
  {
    lat: "12.9477532",
    lng: "74.82146929999999",
    country: "IN",
    country_long: "India",
    portCode: "INNML",
    portName: "New Mangalore",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INNMT",
    portName: "Neamari Stemerghat",
    portContinent: "Asia",
  },
  {
    lat: "8.4961056",
    lng: "77.6464534",
    country: "IN",
    country_long: "India",
    portCode: "INNNN",
    portName: "Amrlitcl-SEZ/Nanguneri",
    portContinent: "Asia",
  },
  {
    lat: "28.7471579",
    lng: "77.3136979",
    country: "IN",
    country_long: "India",
    portCode: "INNOI",
    portName: "Loni ICD/Ghaziabad(ICD/CFS/SEZ) (INNOI), Delhi, India",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INNOL",
    portName: "Panoli",
    portContinent: "Asia",
  },
  {
    lat: "27.293463",
    lng: "96.12583",
    country: "IN",
    country_long: "India",
    portCode: "INNPG",
    portName: "Nampong",
    portContinent: "Asia",
  },
  {
    lat: "18.8783677",
    lng: "76.4404011",
    country: "IN",
    country_long: "India",
    portCode: "INNPR",
    portName: "Nagapur",
    portContinent: "Asia",
  },
  {
    lat: "10.7629714",
    lng: "79.84914669999999",
    country: "IN",
    country_long: "India",
    portCode: "INNPT",
    portName: "Port of Nagapatinam",
    portContinent: "Asia",
  },
  {
    lat: "17.6868159",
    lng: "83.2184815",
    country: "IN",
    country_long: "India",
    portCode: "INNRP",
    portName: "Aal-SEZ/Vishakhapatnam",
    portContinent: "Asia",
  },
  {
    lat: "18.9499361",
    lng: "72.9511875",
    country: "IN",
    country_long: "India",
    portCode: "INNSA",
    portName: "Jawaharlal Nehru (Nhava Sheva) INNSA / INNHV",
    portContinent: "Asia",
  },
  {
    lat: "27.8236356",
    lng: "88.55653099999999",
    country: "IN",
    country_long: "India",
    portCode: "INNSI",
    portName: "North Sikkim",
    portContinent: "Asia",
  },
  {
    lat: "27.3779813",
    lng: "88.81969749999999",
    country: "IN",
    country_long: "India",
    portCode: "INNTL",
    portName: "Sherathang Lcs",
    portContinent: "Asia",
  },
  {
    lat: "8.764166099999999",
    lng: "78.1348361",
    country: "IN",
    country_long: "India",
    portCode: "INNTU",
    portName: "New Tuticorin",
    portContinent: "Asia",
  },
  {
    lat: "27.0575829",
    lng: "83.6245336",
    country: "IN",
    country_long: "India",
    portCode: "INNTV",
    portName: "Thootibari Lcs",
    portContinent: "Asia",
  },
  {
    lat: "20.8060184",
    lng: "82.5361393",
    country: "IN",
    country_long: "India",
    portCode: "INNUA",
    portName: "Nuapada",
    portContinent: "Asia",
  },
  {
    lat: "28.1023894",
    lng: "76.99310740000001",
    country: "IN",
    country_long: "India",
    portCode: "INNUH",
    portName: "Nuh",
    portContinent: "Asia",
  },
  {
    lat: "20.7397804",
    lng: "71.0720619",
    country: "IN",
    country_long: "India",
    portCode: "INNVB",
    portName: "Navabandar (INNVB), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INNVP",
    portName: "Navaspur",
    portContinent: "Asia",
  },
  {
    lat: "15.9446644",
    lng: "73.5211286",
    country: "IN",
    country_long: "India",
    portCode: "INNVT",
    portName: "Nivti",
    portContinent: "Asia",
  },
  {
    lat: "11.5389807",
    lng: "79.4793861",
    country: "IN",
    country_long: "India",
    portCode: "INNVY",
    portName: "Neyveli",
    portContinent: "Asia",
  },
  {
    lat: "28.6139391",
    lng: "77.2090212",
    country: "IN",
    country_long: "India",
    portCode: "INNWD",
    portName: "New Delhi (NCT OF DELHI)",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INNWP",
    portName: "Newapur",
    portContinent: "Asia",
  },
  {
    lat: "20.1231332",
    lng: "85.1038426",
    country: "IN",
    country_long: "India",
    portCode: "INNYG",
    portName: "Nayagarh",
    portContinent: "Asia",
  },
  {
    lat: "22.4724071",
    lng: "69.0810034",
    country: "IN",
    country_long: "India",
    portCode: "INOKH",
    portName: "Port of Okha",
    portContinent: "Asia",
  },
  {
    lat: "18.185332",
    lng: "76.0419642",
    country: "IN",
    country_long: "India",
    portCode: "INOMN",
    portName: "Osmanabad",
    portContinent: "Asia",
  },
  {
    lat: "15.5057232",
    lng: "80.049922",
    country: "IN",
    country_long: "India",
    portCode: "INONG",
    portName: "Ongole",
    portContinent: "Asia",
  },
  {
    lat: "20.8234227",
    lng: "72.8426858",
    country: "IN",
    country_long: "India",
    portCode: "INONJ",
    portName: "Onjal",
    portContinent: "Asia",
  },
  {
    lat: "20.9516658",
    lng: "85.0985236",
    country: "IN",
    country_long: "India",
    portCode: "INORS",
    portName: "Orissa",
    portContinent: "Asia",
  },
  {
    lat: "28.3699975",
    lng: "-81.4270935",
    country: "US",
    country_long: "United States",
    portCode: "INPAA",
    portName: "Panna",
    portContinent: "Asia",
  },
  {
    lat: "22.0796545",
    lng: "82.1409152",
    country: "IN",
    country_long: "India",
    portCode: "INPAB",
    portName: "Bilaspur",
    portContinent: "Asia",
  },
  {
    lat: "10.7867303",
    lng: "76.6547932",
    country: "IN",
    country_long: "India",
    portCode: "INPAD",
    portName: "Palakkad",
    portContinent: "Asia",
  },
  {
    lat: "25.7780623",
    lng: "73.331147",
    country: "IN",
    country_long: "India",
    portCode: "INPAI",
    portName: "Pali",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INPAJ",
    portName: "Pal",
    portContinent: "Asia",
  },
  {
    lat: "12.0972416",
    lng: "75.1933906",
    country: "IN",
    country_long: "India",
    portCode: "INPAK",
    portName: "Payyannur",
    portContinent: "Asia",
  },
  {
    lat: "10.7867303",
    lng: "76.6547932",
    country: "IN",
    country_long: "India",
    portCode: "INPAL",
    portName: "Palghat",
    portContinent: "Asia",
  },
  {
    lat: "24.1286106",
    lng: "84.1857115",
    country: "IN",
    country_long: "India",
    portCode: "INPAM",
    portName: "Palamu",
    portContinent: "Asia",
  },
  {
    lat: "15.4909301",
    lng: "73.8278496",
    country: "IN",
    country_long: "India",
    portCode: "INPAN",
    portName: "Port of Panaji",
    portContinent: "Asia",
  },
  {
    lat: "29.3909464",
    lng: "76.9635023",
    country: "IN",
    country_long: "India",
    portCode: "INPAP",
    portName: "Panipat",
    portContinent: "Asia",
  },
  {
    lat: "19.2608384",
    lng: "76.774776",
    country: "IN",
    country_long: "India",
    portCode: "INPAR",
    portName: "Parbhani",
    portContinent: "Asia",
  },
  {
    lat: "25.5940947",
    lng: "85.1375645",
    country: "IN",
    country_long: "India",
    portCode: "INPAT",
    portName: "Patna",
    portContinent: "Asia",
  },
  {
    lat: "20.972121",
    lng: "71.558785",
    country: "IN",
    country_long: "India",
    portCode: "INPAV",
    portName: "Pipavav (Victor) Port (INPAV), Bhavnagar, India",
    portContinent: "Asia",
  },
  {
    lat: "21.6214386",
    lng: "69.58867839999999",
    country: "IN",
    country_long: "India",
    portCode: "INPBD",
    portName: "Port of Porbandar",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INPBL",
    portName: "Kamardwisa Lcs",
    portContinent: "Asia",
  },
  {
    lat: "11.2342104",
    lng: "78.8806852",
    country: "IN",
    country_long: "India",
    portCode: "INPBR",
    portName: "Perambalur",
    portContinent: "Asia",
  },
  {
    lat: "13.1408219",
    lng: "74.77208879999999",
    country: "IN",
    country_long: "India",
    portCode: "INPDD",
    portName: "Padubidri",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INPDI",
    portName: "Padi",
    portContinent: "Asia",
  },
  {
    lat: "13.0285133",
    lng: "77.5196763",
    country: "IN",
    country_long: "India",
    portCode: "INPEE",
    portName: "Peenya",
    portContinent: "Asia",
  },
  {
    lat: "18.5204303",
    lng: "73.8567437",
    country: "IN",
    country_long: "India",
    portCode: "INPEK",
    portName: "EKIPL SEZ/Pune",
    portContinent: "Asia",
  },
  {
    lat: "11.2746098",
    lng: "77.58270069999999",
    country: "IN",
    country_long: "India",
    portCode: "INPER",
    portName: "Perundurai",
    portContinent: "Asia",
  },
  {
    lat: "29.0221538",
    lng: "79.4908489",
    country: "IN",
    country_long: "India",
    portCode: "INPGH",
    portName: "Pantnagar",
    portContinent: "Asia",
  },
  {
    lat: "19.6967136",
    lng: "72.769885",
    country: "IN",
    country_long: "India",
    portCode: "INPGR",
    portName: "Palghar",
    portContinent: "Asia",
  },
  {
    lat: "25.4962932",
    lng: "88.9533563",
    country: "BD",
    country_long: "Bangladesh",
    portCode: "INPHB",
    portName: "Phulbari",
    portContinent: "Asia",
  },
  {
    lat: "18.4726725",
    lng: "73.9784573",
    country: "IN",
    country_long: "India",
    portCode: "INPHU",
    portName: "Phursungi",
    portContinent: "Asia",
  },
  {
    lat: "20.593684",
    lng: "78.96288",
    country: "IN",
    country_long: "India",
    portCode: "INPID",
    portName: "Port Indai",
    portContinent: "Asia",
  },
  {
    lat: "28.62494",
    lng: "79.8075272",
    country: "IN",
    country_long: "India",
    portCode: "INPIL",
    portName: "Pilibhit",
    portContinent: "Asia",
  },
  {
    lat: "22.226041",
    lng: "69.2579536",
    country: "IN",
    country_long: "India",
    portCode: "INPIN",
    portName: "Pindhara",
    portContinent: "Asia",
  },
  {
    lat: "22.6098447",
    lng: "75.7284267",
    country: "IN",
    country_long: "India",
    portCode: "INPIR",
    portName: "Pithampur /Tihi",
    portContinent: "Asia",
  },
  {
    lat: "18.5204303",
    lng: "73.8567437",
    country: "IN",
    country_long: "India",
    portCode: "INPIT",
    portName: "ITL SEZ/Pune",
    portContinent: "Asia",
  },
  {
    lat: "23.4367496",
    lng: "72.8528118",
    country: "IN",
    country_long: "India",
    portCode: "INPJT",
    portName: "Parantij",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INPKD",
    portName: "Igel Sez/Kodur-Settipali",
    portContinent: "Asia",
  },
  {
    lat: "15.348463",
    lng: "79.560344",
    country: "IN",
    country_long: "India",
    portCode: "INPKM",
    portName: "Prakasam",
    portContinent: "Asia",
  },
  {
    lat: "10.4500374",
    lng: "77.5161356",
    country: "IN",
    country_long: "India",
    portCode: "INPKN",
    portName: "Palani",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INPKR",
    portName: "KRIL ICD/Pali",
    portContinent: "Asia",
  },
  {
    lat: "23.011157",
    lng: "72.5630675",
    country: "IN",
    country_long: "India",
    portCode: "INPLD",
    portName: "Paldi",
    portContinent: "Asia",
  },
  {
    lat: "21.9207932",
    lng: "73.0751633",
    country: "IN",
    country_long: "India",
    portCode: "INPLJ",
    portName: "Palej",
    portContinent: "Asia",
  },
  {
    lat: "28.3802101",
    lng: "75.6091696",
    country: "IN",
    country_long: "India",
    portCode: "INPLN",
    portName: "Pilani",
    portContinent: "Asia",
  },
  {
    lat: "18.7747161",
    lng: "84.40938760000002",
    country: "IN",
    country_long: "India",
    portCode: "INPLS",
    portName: "Palasa",
    portContinent: "Asia",
  },
  {
    lat: "14.4222318",
    lng: "78.2263388",
    country: "IN",
    country_long: "India",
    portCode: "INPLV",
    portName: "Pulivendula",
    portContinent: "Asia",
  },
  {
    lat: "13.3104613",
    lng: "80.04703339999999",
    country: "IN",
    country_long: "India",
    portCode: "INPLY",
    portName: "Periyapalayam",
    portContinent: "Asia",
  },
  {
    lat: "9.2797601",
    lng: "79.2291055",
    country: "IN",
    country_long: "India",
    portCode: "INPMB",
    portName: "Pamban",
    portContinent: "Asia",
  },
  {
    lat: "18.6297811",
    lng: "73.7997094",
    country: "IN",
    country_long: "India",
    portCode: "INPMP",
    portName: "Pimpri",
    portContinent: "Asia",
  },
  {
    lat: "18.5204303",
    lng: "73.8567437",
    country: "IN",
    country_long: "India",
    portCode: "INPMT",
    portName: "MTDCCL SEZ/Pune",
    portContinent: "Asia",
  },
  {
    lat: "30.6942091",
    lng: "76.860565",
    country: "IN",
    country_long: "India",
    portCode: "INPNA",
    portName: "Panchkula",
    portContinent: "Asia",
  },
  {
    lat: "18.5204303",
    lng: "73.8567437",
    country: "IN",
    country_long: "India",
    portCode: "INPNB",
    portName: "BTSL SEZ/Pune",
    portContinent: "Asia",
  },
  {
    lat: "25.5940947",
    lng: "85.1375645",
    country: "IN",
    country_long: "India",
    portCode: "INPNF",
    portName: "FPO PATNA/PATNA",
    portContinent: "Asia",
  },
  {
    lat: "29.3909464",
    lng: "76.9635023",
    country: "IN",
    country_long: "India",
    portCode: "INPNI",
    portName: "Panipat (INPNI), Panipat, India",
    portContinent: "Asia",
  },
  {
    lat: "15.4909301",
    lng: "73.8278496",
    country: "IN",
    country_long: "India",
    portCode: "INPNJ",
    portName: "Panjim",
    portContinent: "Asia",
  },
  {
    lat: "26.4584064",
    lng: "80.2462797",
    country: "IN",
    country_long: "India",
    portCode: "INPNK",
    portName: "Klppl-ICD/Panki",
    portContinent: "Asia",
  },
  {
    lat: "21.8974003",
    lng: "83.39496319999999",
    country: "IN",
    country_long: "India",
    portCode: "INPNL",
    portName: "PDPL SEZ/Raigarh",
    portContinent: "Asia",
  },
  {
    lat: "12.9530213",
    lng: "74.8011783",
    country: "IN",
    country_long: "India",
    portCode: "INPNM",
    portName: "Panambur, Va Mangalore",
    portContinent: "Asia",
  },
  {
    lat: "10.7677201",
    lng: "75.92590129999999",
    country: "IN",
    country_long: "India",
    portCode: "INPNN",
    portName: "Ponnani (INPNN), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "28.6849266",
    lng: "77.2824253",
    country: "IN",
    country_long: "India",
    portCode: "INPNP",
    portName: "Babarpur",
    portContinent: "Asia",
  },
  {
    lat: "18.5204303",
    lng: "73.8567437",
    country: "IN",
    country_long: "India",
    portCode: "INPNQ",
    portName: "Pune",
    portContinent: "Asia",
  },
  {
    lat: "18.2376278",
    lng: "73.4445392",
    country: "IN",
    country_long: "India",
    portCode: "INPNS",
    portName: "SVRL SEZ/Raigad",
    portContinent: "Asia",
  },
  {
    lat: "26.6399338",
    lng: "88.1699952",
    country: "IN",
    country_long: "India",
    portCode: "INPNT",
    portName: "Panitankit (Nazalbari)",
    portContinent: "Asia",
  },
  {
    lat: "18.9894007",
    lng: "73.1175162",
    country: "IN",
    country_long: "India",
    portCode: "INPNV",
    portName: "Arshiya International Ltd.-SEZ/Panvel",
    portContinent: "Asia",
  },
  {
    lat: "11.9171541",
    lng: "79.8245592",
    country: "IN",
    country_long: "India",
    portCode: "INPNY",
    portName: "Port of Pondicherry",
    portContinent: "Asia",
  },
  {
    lat: "13.3339385",
    lng: "80.19432789999999",
    country: "IN",
    country_long: "India",
    portCode: "INPON",
    portName: "Ponneri",
    portContinent: "Asia",
  },
  {
    lat: "28.6347308",
    lng: "77.30457109999999",
    country: "IN",
    country_long: "India",
    portCode: "INPPG",
    portName: "New Delhi (Patparganj)",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INPPJ",
    portName: "Pellet Plant Jetty/Shiroda",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INPPT",
    portName: "Paradip Port",
    portContinent: "Asia",
  },
  {
    lat: "21.0120728",
    lng: "71.5341894",
    country: "IN",
    country_long: "India",
    portCode: "INPPV",
    portName: "Port Pipavav",
    portContinent: "Asia",
  },
  {
    lat: "16.812691",
    lng: "73.30914299999999",
    country: "IN",
    country_long: "India",
    portCode: "INPRG",
    portName: "Purangad",
    portContinent: "Asia",
  },
  {
    lat: "22.7064962",
    lng: "77.7398432",
    country: "IN",
    country_long: "India",
    portCode: "INPRK",
    portName: "Powarkheda ICD/Hoshangabad",
    portContinent: "Asia",
  },
  {
    lat: "15.7198284",
    lng: "73.79632219999999",
    country: "IN",
    country_long: "India",
    portCode: "INPRM",
    portName: "Pernem",
    portContinent: "Asia",
  },
  {
    lat: "43.5611908",
    lng: "13.599937",
    country: "IT",
    country_long: "Italy",
    portCode: "INPRN",
    portName: "Portonovo",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INPRT",
    portName: "Port of Paradip",
    portContinent: "Asia",
  },
  {
    lat: "17.4427912",
    lng: "73.208709",
    country: "IN",
    country_long: "India",
    portCode: "INPSH",
    portName: "Palshet",
    portContinent: "Asia",
  },
  {
    lat: "18.5626697",
    lng: "73.8318516",
    country: "IN",
    country_long: "India",
    portCode: "INPSI",
    portName: "SIPL SEZ/Pune",
    portContinent: "Asia",
  },
  {
    lat: "22.3947855",
    lng: "69.17303629999999",
    country: "IN",
    country_long: "India",
    portCode: "INPST",
    portName: "Positra",
    portContinent: "Asia",
  },
  {
    lat: "25.9149697",
    lng: "81.9800561",
    country: "IN",
    country_long: "India",
    portCode: "INPTG",
    portName: "Pratapgarh",
    portContinent: "Asia",
  },
  {
    lat: "23.4367496",
    lng: "72.8528118",
    country: "IN",
    country_long: "India",
    portCode: "INPTJ",
    portName: "Prantij",
    portContinent: "Asia",
  },
  {
    lat: "29.9700862",
    lng: "78.6476751",
    country: "IN",
    country_long: "India",
    portCode: "INPTL",
    portName: "Patli",
    portContinent: "Asia",
  },
  {
    lat: "23.0359532",
    lng: "88.8766899",
    country: "IN",
    country_long: "India",
    portCode: "INPTP",
    portName: "Petrapole Road",
    portContinent: "Asia",
  },
  {
    lat: "30.3397809",
    lng: "76.3868797",
    country: "IN",
    country_long: "India",
    portCode: "INPTQ",
    portName: "Patiala",
    portContinent: "Asia",
  },
  {
    lat: "17.5287427",
    lng: "78.266725",
    country: "IN",
    country_long: "India",
    portCode: "INPTU",
    portName: "Patancheru",
    portContinent: "Asia",
  },
  {
    lat: "11.9415915",
    lng: "79.8083133",
    country: "IN",
    country_long: "India",
    portCode: "INPUD",
    portName: "Puducherry",
    portContinent: "Asia",
  },
  {
    lat: "18.5537932",
    lng: "73.9478662",
    country: "IN",
    country_long: "India",
    portCode: "INPUE",
    portName: "Eon SEZ/Kharadi",
    portContinent: "Asia",
  },
  {
    lat: "18.5538241",
    lng: "73.9476689",
    country: "IN",
    country_long: "India",
    portCode: "INPUI",
    portName: "Kippl-Gdpl Sez/Kharadi",
    portContinent: "Asia",
  },
  {
    lat: "10.3832867",
    lng: "78.800129",
    country: "IN",
    country_long: "India",
    portCode: "INPUK",
    portName: "Pudukkottai",
    portContinent: "Asia",
  },
  {
    lat: "13.4177382",
    lng: "80.3185252",
    country: "IN",
    country_long: "India",
    portCode: "INPUL",
    portName: "Pulicat",
    portContinent: "Asia",
  },
  {
    lat: "19.8134554",
    lng: "85.8312359",
    country: "IN",
    country_long: "India",
    portCode: "INPUR",
    portName: "Puri",
    portContinent: "Asia",
  },
  {
    lat: "14.1669963",
    lng: "77.8091496",
    country: "IN",
    country_long: "India",
    portCode: "INPUT",
    portName: "Puttaparthi",
    portContinent: "Asia",
  },
  {
    lat: "13.071373",
    lng: "80.2416576",
    country: "IN",
    country_long: "India",
    portCode: "INPUZ",
    portName: "Chetpet",
    portContinent: "Asia",
  },
  {
    lat: "18.9894007",
    lng: "73.1175162",
    country: "IN",
    country_long: "India",
    portCode: "INPVL",
    portName: "Panvel",
    portContinent: "Asia",
  },
  {
    lat: "12.9249308",
    lng: "80.1000026",
    country: "IN",
    country_long: "India",
    portCode: "INPVS",
    portName: "Snpip Sez/Tambaram",
    portContinent: "Asia",
  },
  {
    lat: "28.1427381",
    lng: "77.32838149999999",
    country: "IN",
    country_long: "India",
    portCode: "INPWL",
    portName: "Palwal ICD",
    portContinent: "Asia",
  },
  {
    lat: "30.8405752",
    lng: "76.9583881",
    country: "IN",
    country_long: "India",
    portCode: "INPWN",
    portName: "Parwanoo",
    portContinent: "Asia",
  },
  {
    lat: "18.8606126",
    lng: "82.5510469",
    country: "IN",
    country_long: "India",
    portCode: "INPYB",
    portName: "Jeypore",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INPYS",
    portName: "Sipcot-SEZ/Erode",
    portContinent: "Asia",
  },
  {
    lat: "31.3260152",
    lng: "75.57618289999999",
    country: "IN",
    country_long: "India",
    portCode: "INQJU",
    portName: "Jullundur",
    portContinent: "Asia",
  },
  {
    lat: "22.5645175",
    lng: "72.928871",
    country: "IN",
    country_long: "India",
    portCode: "INQNB",
    portName: "Anand",
    portContinent: "Asia",
  },
  {
    lat: "31.1255583",
    lng: "76.118642",
    country: "IN",
    country_long: "India",
    portCode: "INQNW",
    portName: "Nawanshahar",
    portContinent: "Asia",
  },
  {
    lat: "30.762428",
    lng: "75.82288989999999",
    country: "IN",
    country_long: "India",
    portCode: "INQRP",
    portName: "Adani Icd/Kila Raipur",
    portContinent: "Asia",
  },
  {
    lat: "30.9084245",
    lng: "77.09990309999999",
    country: "IN",
    country_long: "India",
    portCode: "INQSL",
    portName: "Solan",
    portContinent: "Asia",
  },
  {
    lat: "8.8932118",
    lng: "76.6141396",
    country: "IN",
    country_long: "India",
    portCode: "INQUI",
    portName: "Quilon",
    portContinent: "Asia",
  },
  {
    lat: "17.4983928",
    lng: "78.46368389999999",
    country: "IN",
    country_long: "India",
    portCode: "INQUT",
    portName: "Quthbullapur",
    portContinent: "Asia",
  },
  {
    lat: "18.2376278",
    lng: "73.4445392",
    country: "IN",
    country_long: "India",
    portCode: "INRA7",
    portName: "Raigad",
    portContinent: "Asia",
  },
  {
    lat: "33.2463875",
    lng: "75.1938909",
    country: "N/A",
    country_long: "N/A",
    portCode: "INRAB",
    portName: "Ramban",
    portContinent: "Asia",
  },
  {
    lat: "25.0583257",
    lng: "73.88600339999999",
    country: "IN",
    country_long: "India",
    portCode: "INRAD",
    portName: "Rajsamand",
    portContinent: "Asia",
  },
  {
    lat: "23.6362593",
    lng: "85.5124209",
    country: "IN",
    country_long: "India",
    portCode: "INRAG",
    portName: "Ramgarh",
    portContinent: "Asia",
  },
  {
    lat: "23.8508581",
    lng: "76.7336521",
    country: "IN",
    country_long: "India",
    portCode: "INRAH",
    portName: "Rajgarh",
    portContinent: "Asia",
  },
  {
    lat: "23.3327329",
    lng: "77.7823973",
    country: "IN",
    country_long: "India",
    portCode: "INRAI",
    portName: "Raisen",
    portContinent: "Asia",
  },
  {
    lat: "22.3038945",
    lng: "70.80215989999999",
    country: "IN",
    country_long: "India",
    portCode: "INRAJ",
    portName: "Rajkot (INRAJ), Rajkot, India",
    portContinent: "Asia",
  },
  {
    lat: "9.2876254",
    lng: "79.31292909999999",
    country: "IN",
    country_long: "India",
    portCode: "INRAM",
    portName: "Rameshwaram (INRAM), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "29.52551619999999",
    lng: "74.83609179999999",
    country: "IN",
    country_long: "India",
    portCode: "INRAN",
    portName: "Rania",
    portContinent: "Asia",
  },
  {
    lat: "28.798299",
    lng: "79.02202869999999",
    country: "IN",
    country_long: "India",
    portCode: "INRAP",
    portName: "Rampur",
    portContinent: "Asia",
  },
  {
    lat: "16.216018",
    lng: "77.3565608",
    country: "IN",
    country_long: "India",
    portCode: "INRAR",
    portName: "Raichur",
    portContinent: "Asia",
  },
  {
    lat: "9.780733099999999",
    lng: "77.9660129",
    country: "IN",
    country_long: "India",
    portCode: "INRAY",
    portName: "Rayapalayam",
    portContinent: "Asia",
  },
  {
    lat: "26.2144806",
    lng: "81.25281389999999",
    country: "IN",
    country_long: "India",
    portCode: "INRBL",
    portName: "Rae Bareli",
    portContinent: "Asia",
  },
  {
    lat: "25.6432275",
    lng: "88.4479386",
    country: "IN",
    country_long: "India",
    portCode: "INRDP",
    portName: "Radhikapur",
    portContinent: "Asia",
  },
  {
    lat: "28.1919738",
    lng: "76.6190774",
    country: "IN",
    country_long: "India",
    portCode: "INREA",
    portName: "Rewari",
    portContinent: "Asia",
  },
  {
    lat: "28.1919738",
    lng: "76.6190774",
    country: "IN",
    country_long: "India",
    portCode: "INREA6",
    portName: "Rewari",
    portContinent: "Asia",
  },
  {
    lat: "15.7480913",
    lng: "73.65527879999999",
    country: "IN",
    country_long: "India",
    portCode: "INRED",
    portName: "Port of Redi",
    portContinent: "Asia",
  },
  {
    lat: "24.5362477",
    lng: "81.30369460000001",
    country: "IN",
    country_long: "India",
    portCode: "INREW",
    portName: "Rewa",
    portContinent: "Asia",
  },
  {
    lat: "21.8974003",
    lng: "83.39496319999999",
    country: "IN",
    country_long: "India",
    portCode: "INRGA",
    portName: "Raigarh",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INRGB",
    portName: "Old Raghna Bazar",
    portContinent: "Asia",
  },
  {
    lat: "25.2372834",
    lng: "88.7830612",
    country: "IN",
    country_long: "India",
    portCode: "INRGH",
    portName: "Balurghat",
    portContinent: "Asia",
  },
  {
    lat: "25.6187398",
    lng: "88.12896850000001",
    country: "IN",
    country_long: "India",
    portCode: "INRGJ",
    portName: "Raiganj Railway Station Lcs",
    portContinent: "Asia",
  },
  {
    lat: "13.6373232",
    lng: "79.5036674",
    country: "IN",
    country_long: "India",
    portCode: "INRGN",
    portName: "Renigunta",
    portContinent: "Asia",
  },
  {
    lat: "22.2709322",
    lng: "86.1700894",
    country: "IN",
    country_long: "India",
    portCode: "INRGP",
    portName: "Rairangpur",
    portContinent: "Asia",
  },
  {
    lat: "23.2365305",
    lng: "88.82029779999999",
    country: "IN",
    country_long: "India",
    portCode: "INRGT",
    portName: "Ranghat Bay",
    portContinent: "Asia",
  },
  {
    lat: "25.191044",
    lng: "91.565834",
    country: "IN",
    country_long: "India",
    portCode: "INRGU",
    portName: "Ryngku",
    portContinent: "Asia",
  },
  {
    lat: "18.4389511",
    lng: "73.1167514",
    country: "IN",
    country_long: "India",
    portCode: "INRHA",
    portName: "Roha",
    portContinent: "Asia",
  },
  {
    lat: "26.2389469",
    lng: "73.02430939999999",
    country: "IN",
    country_long: "India",
    portCode: "INRIC",
    portName: "Johdpur ICD",
    portContinent: "Asia",
  },
  {
    lat: "21.8974003",
    lng: "83.39496319999999",
    country: "IN",
    country_long: "India",
    portCode: "INRIG",
    portName: "Raigarh",
    portContinent: "Asia",
  },
  {
    lat: "25.0172573",
    lng: "85.4161642",
    country: "IN",
    country_long: "India",
    portCode: "INRIR",
    portName: "Rajgir",
    portContinent: "Asia",
  },
  {
    lat: "17.0005383",
    lng: "81.8040345",
    country: "IN",
    country_long: "India",
    portCode: "INRJA",
    portName: "Rajahmundry",
    portContinent: "Asia",
  },
  {
    lat: "33.3716143",
    lng: "74.315191",
    country: "N/A",
    country_long: "N/A",
    portCode: "INRJI",
    portName: "Rajouri",
    portContinent: "Asia",
  },
  {
    lat: "21.0976046",
    lng: "81.0337066",
    country: "IN",
    country_long: "India",
    portCode: "INRJN",
    portName: "LSPL SEZ/Rajnandgaon",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INRJP",
    portName: "Rajpara/Navabunder",
    portContinent: "Asia",
  },
  {
    lat: "18.3037267",
    lng: "72.970047",
    country: "IN",
    country_long: "India",
    portCode: "INRJR",
    portName: "Rajpuri",
    portContinent: "Asia",
  },
  {
    lat: "21.0411065",
    lng: "71.4488241",
    country: "IN",
    country_long: "India",
    portCode: "INRJU",
    portName: "Rajula",
    portContinent: "Asia",
  },
  {
    lat: "8.1290297",
    lng: "77.36402570000001",
    country: "IN",
    country_long: "India",
    portCode: "INRKG",
    portName: "Rajakkamangalam",
    portContinent: "Asia",
  },
  {
    lat: "24.2182429",
    lng: "83.03460729999999",
    country: "IN",
    country_long: "India",
    portCode: "INRKO",
    portName: "Renukoot",
    portContinent: "Asia",
  },
  {
    lat: "18.7518652",
    lng: "79.5133701",
    country: "IN",
    country_long: "India",
    portCode: "INRMD",
    portName: "Ramagundam",
    portContinent: "Asia",
  },
  {
    lat: "21.1610957",
    lng: "81.78640879999999",
    country: "IN",
    country_long: "India",
    portCode: "INRML",
    portName: "Concor Icd/Nayaraipur",
    portContinent: "Asia",
  },
  {
    lat: "9.3639356",
    lng: "78.8394819",
    country: "IN",
    country_long: "India",
    portCode: "INRMP",
    portName: "Ramanathapuram",
    portContinent: "Asia",
  },
  {
    lat: "21.3928618",
    lng: "79.3268397",
    country: "IN",
    country_long: "India",
    portCode: "INRMT",
    portName: "Ramtek",
    portContinent: "Asia",
  },
  {
    lat: "23.3440997",
    lng: "85.309562",
    country: "IN",
    country_long: "India",
    portCode: "INRNC",
    portName: "FPO RANCHI/RANCHI",
    portContinent: "Asia",
  },
  {
    lat: "23.1744548",
    lng: "88.5605732",
    country: "IN",
    country_long: "India",
    portCode: "INRNG",
    portName: "Ranaghat",
    portContinent: "Asia",
  },
  {
    lat: "18.2024216",
    lng: "83.6906836",
    country: "IN",
    country_long: "India",
    portCode: "INRNM",
    portName: "Ranasthalam",
    portContinent: "Asia",
  },
  {
    lat: "21.0976046",
    lng: "81.0337066",
    country: "IN",
    country_long: "India",
    portCode: "INRNN",
    portName: "Raj Nandgaon",
    portContinent: "Asia",
  },
  {
    lat: "21.833652",
    lng: "69.6720974",
    country: "IN",
    country_long: "India",
    portCode: "INRNR",
    portName: "Ranpar (INRNR), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "28.8955152",
    lng: "76.606611",
    country: "IN",
    country_long: "India",
    portCode: "INROH",
    portName: "Rohtak",
    portContinent: "Asia",
  },
  {
    lat: "29.8542626",
    lng: "77.8880002",
    country: "IN",
    country_long: "India",
    portCode: "INROO",
    portName: "Roorkee",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INROZ",
    portName: "Rozi",
    portContinent: "Asia",
  },
  {
    lat: "11.11446",
    lng: "79.16588899999999",
    country: "IN",
    country_long: "India",
    portCode: "INRPL",
    portName: "Raddipalam",
    portContinent: "Asia",
  },
  {
    lat: "30.9661003",
    lng: "76.5230961",
    country: "IN",
    country_long: "India",
    portCode: "INRPN",
    portName: "Rupnagar",
    portContinent: "Asia",
  },
  {
    lat: "21.2513844",
    lng: "81.62964130000002",
    country: "IN",
    country_long: "India",
    portCode: "INRPR",
    portName: "Raipur",
    portContinent: "Asia",
  },
  {
    lat: "20.2172792",
    lng: "72.8552448",
    country: "IN",
    country_long: "India",
    portCode: "INRQL",
    portName: "Navkar Icd Tumb",
    portContinent: "Asia",
  },
  {
    lat: "22.1053397",
    lng: "85.3789642",
    country: "IN",
    country_long: "India",
    portCode: "INRRA",
    portName: "Barbil",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INRRI",
    portName: "Raili",
    portContinent: "Asia",
  },
  {
    lat: "22.260423",
    lng: "84.8535844",
    country: "IN",
    country_long: "India",
    portCode: "INRRK",
    portName: "Rourkela",
    portContinent: "Asia",
  },
  {
    lat: "16.990215",
    lng: "73.31202329999999",
    country: "IN",
    country_long: "India",
    portCode: "INRTC",
    portName: "Port of Ratnagiri",
    portContinent: "Asia",
  },
  {
    lat: "23.3315103",
    lng: "75.0366677",
    country: "IN",
    country_long: "India",
    portCode: "INRTM",
    portName: "Ratlam",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INRTR",
    portName: "Dasrath",
    portContinent: "Asia",
  },
  {
    lat: "28.9875082",
    lng: "79.4141214",
    country: "IN",
    country_long: "India",
    portCode: "INRUD",
    portName: "Rudrapur",
    portContinent: "Asia",
  },
  {
    lat: "26.1379199",
    lng: "89.9082822",
    country: "IN",
    country_long: "India",
    portCode: "INRUP",
    portName: "Rupsi",
    portContinent: "Asia",
  },
  {
    lat: "18.5582119",
    lng: "72.925386",
    country: "IN",
    country_long: "India",
    portCode: "INRVD",
    portName: "Revdanda",
    portContinent: "Asia",
  },
  {
    lat: "26.9797677",
    lng: "84.8515804",
    country: "IN",
    country_long: "India",
    portCode: "INRXL",
    portName: "Raxaul",
    portContinent: "Asia",
  },
  {
    lat: "13.1137008",
    lng: "80.2954115",
    country: "IN",
    country_long: "India",
    portCode: "INRYP",
    portName: "Royapuram",
    portContinent: "Asia",
  },
  {
    lat: "22.9919637",
    lng: "72.3772986",
    country: "IN",
    country_long: "India",
    portCode: "INSAA",
    portName: "Sanand",
    portContinent: "Asia",
  },
  {
    lat: "23.0097804",
    lng: "91.7198215",
    country: "IN",
    country_long: "India",
    portCode: "INSAB",
    portName: "Sabroom",
    portContinent: "Asia",
  },
  {
    lat: "21.0847018",
    lng: "72.88216059999999",
    country: "IN",
    country_long: "India",
    portCode: "INSAC",
    portName: "Sachin (Surat)",
    portContinent: "Asia",
  },
  {
    lat: "17.4562544",
    lng: "78.4439295",
    country: "IN",
    country_long: "India",
    portCode: "INSAD",
    portName: "Sanathnagar",
    portContinent: "Asia",
  },
  {
    lat: "16.8523973",
    lng: "74.5814773",
    country: "IN",
    country_long: "India",
    portCode: "INSAG",
    portName: "Sangli",
    portContinent: "Asia",
  },
  {
    lat: "20.2172792",
    lng: "72.8552448",
    country: "IN",
    country_long: "India",
    portCode: "INSAJ",
    portName: "NAVKAR ICD/Umbergaon",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSAL",
    portName: "Port of Salaya",
    portContinent: "Asia",
  },
  {
    lat: "17.0500453",
    lng: "82.1679386",
    country: "IN",
    country_long: "India",
    portCode: "INSAM",
    portName: "Samalkot",
    portContinent: "Asia",
  },
  {
    lat: "30.2457963",
    lng: "75.8420716",
    country: "IN",
    country_long: "India",
    portCode: "INSAN",
    portName: "Sangrur",
    portContinent: "Asia",
  },
  {
    lat: "15.2880962",
    lng: "74.7633318",
    country: "IN",
    country_long: "India",
    portCode: "INSAP",
    portName: "Siddapur",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSAR",
    portName: "Sardar",
    portContinent: "Asia",
  },
  {
    lat: "30.7046486",
    lng: "76.71787259999999",
    country: "IN",
    country_long: "India",
    portCode: "INSAS",
    portName: "Infosys Sez/Mohali",
    portContinent: "Asia",
  },
  {
    lat: "22.9919637",
    lng: "72.3772986",
    country: "IN",
    country_long: "India",
    portCode: "INSAU",
    portName: "Sanand (INSAU), Ahmedabad, India",
    portContinent: "Asia",
  },
  {
    lat: "12.9715987",
    lng: "77.5945627",
    country: "IN",
    country_long: "India",
    portCode: "INSBC",
    portName: "Biocon-SEZ/Bangalore",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSBH",
    portName: "Sinbhour",
    portContinent: "Asia",
  },
  {
    lat: "23.56600138878394",
    lng: "72.94526293395025",
    country: "IN",
    country_long: "India",
    portCode: "INSBT",
    portName: "Sabarmati",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSBZ",
    portName: "Shella Bazar",
    portContinent: "Asia",
  },
  {
    lat: "21.1702401",
    lng: "72.83106070000001",
    country: "IN",
    country_long: "India",
    portCode: "INSCH",
    portName: "Sap-SEZ/Surat",
    portContinent: "Asia",
  },
  {
    lat: "27.2990659",
    lng: "83.0927827",
    country: "IN",
    country_long: "India",
    portCode: "INSDD",
    portName: "Siddharthnagar",
    portContinent: "Asia",
  },
  {
    lat: "23.2032399",
    lng: "77.0844044",
    country: "IN",
    country_long: "India",
    portCode: "INSEH",
    portName: "Sehore",
    portContinent: "Asia",
  },
  {
    lat: "30.8288909",
    lng: "75.98674489999999",
    country: "IN",
    country_long: "India",
    portCode: "INSGF",
    portName: "GRFL ICD/Sahnwewal",
    portContinent: "Asia",
  },
  {
    lat: "23.838805",
    lng: "78.7378068",
    country: "IN",
    country_long: "India",
    portCode: "INSGR",
    portName: "Sagar",
    portContinent: "Asia",
  },
  {
    lat: "29.4501986",
    lng: "77.3172046",
    country: "IN",
    country_long: "India",
    portCode: "INSH2",
    portName: "Shamli",
    portContinent: "Asia",
  },
  {
    lat: "19.4520777",
    lng: "73.3279586",
    country: "IN",
    country_long: "India",
    portCode: "INSHA",
    portName: "Shahapur",
    portContinent: "Asia",
  },
  {
    lat: "28.6620078",
    lng: "77.33668159999999",
    country: "IN",
    country_long: "India",
    portCode: "INSHB",
    portName: "Sahibabad",
    portContinent: "Asia",
  },
  {
    lat: "23.300232",
    lng: "81.3568619",
    country: "IN",
    country_long: "India",
    portCode: "INSHD",
    portName: "Shahdol",
    portContinent: "Asia",
  },
  {
    lat: "25.67282",
    lng: "76.6961305",
    country: "IN",
    country_long: "India",
    portCode: "INSHE",
    portName: "Sheopur",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSHH",
    portName: "Shahada",
    portContinent: "Asia",
  },
  {
    lat: "22.0573564",
    lng: "73.3789745",
    country: "IN",
    country_long: "India",
    portCode: "INSHI",
    portName: "Shirola",
    portContinent: "Asia",
  },
  {
    lat: "23.4272938",
    lng: "76.2729839",
    country: "IN",
    country_long: "India",
    portCode: "INSHJ",
    portName: "Shajapur",
    portContinent: "Asia",
  },
  {
    lat: "25.5787726",
    lng: "91.8932535",
    country: "IN",
    country_long: "India",
    portCode: "INSHL",
    portName: "Shillong",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSHM",
    portName: "Shalimar",
    portContinent: "Asia",
  },
  {
    lat: "33.7170033",
    lng: "74.83609179999999",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSHN",
    portName: "Shopian",
    portContinent: "Asia",
  },
  {
    lat: "21.7197378",
    lng: "71.9597023",
    country: "IN",
    country_long: "India",
    portCode: "INSHO",
    portName: "Sihor",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSHP",
    portName: "Simbhour Port",
    portContinent: "Asia",
  },
  {
    lat: "13.1115847",
    lng: "79.4310052",
    country: "IN",
    country_long: "India",
    portCode: "INSHR",
    portName: "Sholinghur",
    portContinent: "Asia",
  },
  {
    lat: "18.8249767",
    lng: "74.3775524",
    country: "IN",
    country_long: "India",
    portCode: "INSHU",
    portName: "Shirur",
    portContinent: "Asia",
  },
  {
    lat: "25.4320478",
    lng: "77.66438149999999",
    country: "IN",
    country_long: "India",
    portCode: "INSHV",
    portName: "Shivpuri",
    portContinent: "Asia",
  },
  {
    lat: "21.6818986",
    lng: "75.0942651",
    country: "IN",
    country_long: "India",
    portCode: "INSHW",
    portName: "Sendhwa",
    portContinent: "Asia",
  },
  {
    lat: "26.9826098",
    lng: "94.6424521",
    country: "IN",
    country_long: "India",
    portCode: "INSIB",
    portName: "Sibsagar",
    portContinent: "Asia",
  },
  {
    lat: "24.3956038",
    lng: "81.88252790000001",
    country: "IN",
    country_long: "India",
    portCode: "INSID",
    portName: "Sidhi",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSIK",
    portName: "Sika",
    portContinent: "Asia",
  },
  {
    lat: "26.7271012",
    lng: "88.39528609999999",
    country: "IN",
    country_long: "India",
    portCode: "INSIL",
    portName: "Siliguri",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSIM",
    portName: "Sima/Navabunder",
    portContinent: "Asia",
  },
  {
    lat: "16.3492193",
    lng: "73.55941279999999",
    country: "IN",
    country_long: "India",
    portCode: "INSIN",
    portName: "Sindhudurg",
    portContinent: "Asia",
  },
  {
    lat: "24.8851548",
    lng: "72.8574558",
    country: "IN",
    country_long: "India",
    portCode: "INSIO",
    portName: "Sirohi",
    portContinent: "Asia",
  },
  {
    lat: "11.000658",
    lng: "77.0295709",
    country: "IN",
    country_long: "India",
    portCode: "INSIR",
    portName: "Singanallur",
    portContinent: "Asia",
  },
  {
    lat: "27.5680156",
    lng: "80.6789519",
    country: "IN",
    country_long: "India",
    portCode: "INSIT",
    portName: "Sitapur",
    portContinent: "Asia",
  },
  {
    lat: "20.2753406",
    lng: "73.0072986",
    country: "IN",
    country_long: "India",
    portCode: "INSIV",
    portName: "Silvassa",
    portContinent: "Asia",
  },
  {
    lat: "27.8753399",
    lng: "79.9147268",
    country: "IN",
    country_long: "India",
    portCode: "INSJN",
    portName: "Shahjahanpur",
    portContinent: "Asia",
  },
  {
    lat: "28.5126976",
    lng: "77.48418579999999",
    country: "IN",
    country_long: "India",
    portCode: "INSJP",
    portName: "SURAJPUR, UP (INSJP), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "27.6049415",
    lng: "78.0672949",
    country: "IN",
    country_long: "India",
    portCode: "INSKA",
    portName: "Sokhana",
    portContinent: "Asia",
  },
  {
    lat: "27.5329718",
    lng: "88.5122178",
    country: "IN",
    country_long: "India",
    portCode: "INSKK",
    portName: "Sikkim",
    portContinent: "Asia",
  },
  {
    lat: "26.7671755",
    lng: "83.03613759999999",
    country: "IN",
    country_long: "India",
    portCode: "INSKN",
    portName: "St Kabir Nagar",
    portContinent: "Asia",
  },
  {
    lat: "26.9983947",
    lng: "88.16694749999999",
    country: "IN",
    country_long: "India",
    portCode: "INSKP",
    portName: "Sukhia Pokhari",
    portContinent: "Asia",
  },
  {
    lat: "27.6093912",
    lng: "75.1397935",
    country: "IN",
    country_long: "India",
    portCode: "INSKR",
    portName: "Sikar",
    portContinent: "Asia",
  },
  {
    lat: "24.196032",
    lng: "82.66758449999999",
    country: "IN",
    country_long: "India",
    portCode: "INSLI",
    portName: "Singrauli",
    portContinent: "Asia",
  },
  {
    lat: "40.7607793",
    lng: "-111.8910474",
    country: "US",
    country_long: "United States",
    portCode: "INSLT",
    portName: "Salt Lake",
    portContinent: "Asia",
  },
  {
    lat: "30.5178932",
    lng: "-90.4710715",
    country: "US",
    country_long: "United States",
    portCode: "INSLU",
    portName: "Selu",
    portContinent: "Asia",
  },
  {
    lat: "31.1048145",
    lng: "77.17340329999999",
    country: "IN",
    country_long: "India",
    portCode: "INSLV",
    portName: "Simla",
    portContinent: "Asia",
  },
  {
    lat: "13.9299299",
    lng: "75.568101",
    country: "IN",
    country_long: "India",
    portCode: "INSMK",
    portName: "Shimoga",
    portContinent: "Asia",
  },
  {
    lat: "26.164346",
    lng: "91.7630095",
    country: "IN",
    country_long: "India",
    portCode: "INSMP",
    portName: "Srimantapur",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSMR",
    portName: "Simor",
    portContinent: "Asia",
  },
  {
    lat: "25.6942734",
    lng: "86.7078725",
    country: "IN",
    country_long: "India",
    portCode: "INSNB",
    portName: "Sonabarsa",
    portContinent: "Asia",
  },
  {
    lat: "17.4562544",
    lng: "78.4439295",
    country: "IN",
    country_long: "India",
    portCode: "INSNF",
    portName: "Sanath Nagar",
    portContinent: "Asia",
  },
  {
    lat: "24.9149735",
    lng: "88.291924",
    country: "IN",
    country_long: "India",
    portCode: "INSNG",
    portName: "Singhabad",
    portContinent: "Asia",
  },
  {
    lat: "30.8307642",
    lng: "76.0087258",
    country: "IN",
    country_long: "India",
    portCode: "INSNI",
    portName: "Kanech ICD/Sahnewal",
    portContinent: "Asia",
  },
  {
    lat: "27.471295",
    lng: "83.46568889999999",
    country: "IN",
    country_long: "India",
    portCode: "INSNL",
    portName: "Sonauli",
    portContinent: "Asia",
  },
  {
    lat: "17.6599188",
    lng: "75.9063906",
    country: "IN",
    country_long: "India",
    portCode: "INSOL",
    portName: "Solapur",
    portContinent: "Asia",
  },
  {
    lat: "28.99308229999999",
    lng: "77.0150735",
    country: "IN",
    country_long: "India",
    portCode: "INSON",
    portName: "Sonipat",
    portContinent: "Asia",
  },
  {
    lat: "17.7083025",
    lng: "83.1526789",
    country: "IN",
    country_long: "India",
    portCode: "INSPC",
    portName: "Cclpil Sez/Visakhapatnam",
    portContinent: "Asia",
  },
  {
    lat: "14.4425987",
    lng: "79.98645599999999",
    country: "IN",
    country_long: "India",
    portCode: "INSPE",
    portName: "Asdipl-SEZ/Nellore",
    portContinent: "Asia",
  },
  {
    lat: "21.3443489",
    lng: "82.1903963",
    country: "IN",
    country_long: "India",
    portCode: "INSPR",
    portName: "Sirpur",
    portContinent: "Asia",
  },
  {
    lat: "29.9680035",
    lng: "77.55520659999999",
    country: "IN",
    country_long: "India",
    portCode: "INSRE",
    portName: "Saharanpur",
    portContinent: "Asia",
  },
  {
    lat: "9.674136299999999",
    lng: "76.3400963",
    country: "IN",
    country_long: "India",
    portCode: "INSRI",
    portName: "Shertallai",
    portContinent: "Asia",
  },
  {
    lat: "21.1894506",
    lng: "72.88587869999999",
    country: "IN",
    country_long: "India",
    portCode: "INSRL",
    portName: "Saroli",
    portContinent: "Asia",
  },
  {
    lat: "12.9624975",
    lng: "79.94527479999999",
    country: "IN",
    country_long: "India",
    portCode: "INSRP",
    portName: "Sriperumbudur",
    portContinent: "Asia",
  },
  {
    lat: "16.4922316",
    lng: "82.09722169999999",
    country: "IN",
    country_long: "India",
    portCode: "INSRV",
    portName: "Surasani-Yanam",
    portContinent: "Asia",
  },
  {
    lat: "17.6599188",
    lng: "75.9063906",
    country: "IN",
    country_long: "India",
    portCode: "INSSE",
    portName: "Sholapur",
    portContinent: "Asia",
  },
  {
    lat: "27.28584",
    lng: "88.3945669",
    country: "IN",
    country_long: "India",
    portCode: "INSSI",
    portName: "South Sikkim",
    portContinent: "Asia",
  },
  {
    lat: "17.6804639",
    lng: "74.018261",
    country: "IN",
    country_long: "India",
    portCode: "INSTA",
    portName: "Satara",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSTF",
    portName: "Sikta Lcs",
    portContinent: "Asia",
  },
  {
    lat: "28.9235479",
    lng: "79.70072110000001",
    country: "IN",
    country_long: "India",
    portCode: "INSTG",
    portName: "Sitarganj",
    portContinent: "Asia",
  },
  {
    lat: "26.0486643",
    lng: "89.3203233",
    country: "IN",
    country_long: "India",
    portCode: "INSTI",
    portName: "Sitai",
    portContinent: "Asia",
  },
  {
    lat: "17.6804639",
    lng: "74.018261",
    country: "IN",
    country_long: "India",
    portCode: "INSTM",
    portName: "Satara",
    portContinent: "Asia",
  },
  {
    lat: "24.6005075",
    lng: "80.8322428",
    country: "IN",
    country_long: "India",
    portCode: "INSTN",
    portName: "Satna",
    portContinent: "Asia",
  },
  {
    lat: "19.7211729",
    lng: "72.7067604",
    country: "IN",
    country_long: "India",
    portCode: "INSTP",
    portName: "Satpati",
    portContinent: "Asia",
  },
  {
    lat: "24.8680789",
    lng: "92.2495011",
    country: "IN",
    country_long: "India",
    portCode: "INSTR",
    portName: "Sutarkandi",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSTT",
    portName: "Sttpl Cfs/Dadri",
    portContinent: "Asia",
  },
  {
    lat: "18.0891148",
    lng: "73.9793298",
    country: "IN",
    country_long: "India",
    portCode: "INSTU",
    portName: "MIDC SEZ / KESURDE",
    portContinent: "Asia",
  },
  {
    lat: "21.1702401",
    lng: "72.83106070000001",
    country: "IN",
    country_long: "India",
    portCode: "INSTV",
    portName: "Surat (INSTV), Surat, India",
    portContinent: "Asia",
  },
  {
    lat: "13.4322706",
    lng: "79.9612368",
    country: "IN",
    country_long: "India",
    portCode: "INSTY",
    portName: "Satyavedu",
    portContinent: "Asia",
  },
  {
    lat: "20.8480141",
    lng: "83.8950042",
    country: "IN",
    country_long: "India",
    portCode: "INSUB",
    portName: "Sonepur",
    portContinent: "Asia",
  },
  {
    lat: "30.1305581",
    lng: "75.8013975",
    country: "IN",
    country_long: "India",
    portCode: "INSUM",
    portName: "Sunam",
    portContinent: "Asia",
  },
  {
    lat: "22.1240025",
    lng: "84.043175",
    country: "IN",
    country_long: "India",
    portCode: "INSUN",
    portName: "Sundargarh",
    portContinent: "Asia",
  },
  {
    lat: "21.1702401",
    lng: "72.83106070000001",
    country: "IN",
    country_long: "India",
    portCode: "INSUR",
    portName: "Surat",
    portContinent: "Asia",
  },
  {
    lat: "9.4532852",
    lng: "77.80241699999999",
    country: "IN",
    country_long: "India",
    portCode: "INSVK",
    portName: "Sivakasi",
    portContinent: "Asia",
  },
  {
    lat: "30.8375323",
    lng: "75.9720904",
    country: "IN",
    country_long: "India",
    portCode: "INSWA",
    portName: "SAHNEWAL",
    portContinent: "Asia",
  },
  {
    lat: "18.0594018",
    lng: "73.0228173",
    country: "IN",
    country_long: "India",
    portCode: "INSWD",
    portName: "Shriwardhan",
    portContinent: "Asia",
  },
  {
    lat: "11.6999416",
    lng: "78.1056758",
    country: "IN",
    country_long: "India",
    portCode: "INSXE",
    portName: "ECTN SEZ/Salem",
    portContinent: "Asia",
  },
  {
    lat: "34.0836708",
    lng: "74.7972825",
    country: "N/A",
    country_long: "N/A",
    portCode: "INSXR",
    portName: "Srinagar",
    portContinent: "Asia",
  },
  {
    lat: "42.51954",
    lng: "-70.8967155",
    country: "US",
    country_long: "United States",
    portCode: "INSXV",
    portName: "Salem",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INTAD",
    portName: "Tadri",
    portContinent: "Asia",
  },
  {
    lat: "31.9311287",
    lng: "75.8940592",
    country: "IN",
    country_long: "India",
    portCode: "INTAL",
    portName: "Talwara",
    portContinent: "Asia",
  },
  {
    lat: "11.1271225",
    lng: "78.6568942",
    country: "IN",
    country_long: "India",
    portCode: "INTAM",
    portName: "Tamilnada",
    portContinent: "Asia",
  },
  {
    lat: "13.4322706",
    lng: "79.9612368",
    country: "IN",
    country_long: "India",
    portCode: "INTAS",
    portName: "Sri City Pvt Ltd-SEZ/Satyavedu",
    portContinent: "Asia",
  },
  {
    lat: "12.8352188",
    lng: "80.2010804",
    country: "IN",
    country_long: "India",
    portCode: "INTBC",
    portName: "CTSI SEZ/Siruseri",
    portContinent: "Asia",
  },
  {
    lat: "12.818456",
    lng: "79.6946586",
    country: "IN",
    country_long: "India",
    portCode: "INTBH",
    portName: "HIRL SEZ/Kancheepuram",
    portContinent: "Asia",
  },
  {
    lat: "12.818456",
    lng: "79.6946586",
    country: "IN",
    country_long: "India",
    portCode: "INTBM",
    portName: "Phpl-SEZ/Kancheepuram",
    portContinent: "Asia",
  },
  {
    lat: "12.904759",
    lng: "80.0890842",
    country: "IN",
    country_long: "India",
    portCode: "INTBP",
    portName: "SPIPL SEZ/Perungalathur",
    portContinent: "Asia",
  },
  {
    lat: "12.8352188",
    lng: "80.2010804",
    country: "IN",
    country_long: "India",
    portCode: "INTBS",
    portName: "HTL SEZ/Siruseri",
    portContinent: "Asia",
  },
  {
    lat: "12.8352188",
    lng: "80.2010804",
    country: "IN",
    country_long: "India",
    portCode: "INTBT",
    portName: "TCSL SEZ/Siruseri",
    portContinent: "Asia",
  },
  {
    lat: "11.3806005",
    lng: "77.8943515",
    country: "IN",
    country_long: "India",
    portCode: "INTCH",
    portName: "Tiruchengodu",
    portContinent: "Asia",
  },
  {
    lat: "10.2922744",
    lng: "76.1653315",
    country: "IN",
    country_long: "India",
    portCode: "INTCR",
    portName: "Tics ICD/Mathilakam",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INTDE",
    portName: "TudiyalÃ¼r",
    portContinent: "Asia",
  },
  {
    lat: "27.9276542",
    lng: "96.1533094",
    country: "IN",
    country_long: "India",
    portCode: "INTEI",
    portName: "Tezu",
    portContinent: "Asia",
  },
  {
    lat: "11.7491424",
    lng: "75.4890346",
    country: "IN",
    country_long: "India",
    portCode: "INTEL",
    portName: "Tellicherry",
    portContinent: "Asia",
  },
  {
    lat: "8.825873999999999",
    lng: "77.755301",
    country: "IN",
    country_long: "India",
    portCode: "INTEN",
    portName: "Sipcot-Gangaikondan-SEZ/Tirunelveli",
    portContinent: "Asia",
  },
  {
    lat: "8.713912600000002",
    lng: "77.7566523",
    country: "IN",
    country_long: "India",
    portCode: "INTEV",
    portName: "VVM SEZ/Tirunelveli",
    portContinent: "Asia",
  },
  {
    lat: "13.1584711",
    lng: "80.2036881",
    country: "IN",
    country_long: "India",
    portCode: "INTEY",
    portName: "Puzhal",
    portContinent: "Asia",
  },
  {
    lat: "26.6528495",
    lng: "92.7925592",
    country: "IN",
    country_long: "India",
    portCode: "INTEZ",
    portName: "Tezpur",
    portContinent: "Asia",
  },
  {
    lat: "18.7375763",
    lng: "73.67466259999999",
    country: "IN",
    country_long: "India",
    portCode: "INTGD",
    portName: "Talegaon Dabhade",
    portContinent: "Asia",
  },
  {
    lat: "26.2389469",
    lng: "73.02430939999999",
    country: "IN",
    country_long: "India",
    portCode: "INTHA",
    portName: "Jodhpur-Thar",
    portContinent: "Asia",
  },
  {
    lat: "10.0079322",
    lng: "77.4735441",
    country: "IN",
    country_long: "India",
    portCode: "INTHE",
    portName: "Theni",
    portContinent: "Asia",
  },
  {
    lat: "47.078596",
    lng: "15.3552061",
    country: "AT",
    country_long: "Austria",
    portCode: "INTHL",
    portName: "Thal",
    portContinent: "Asia",
  },
  {
    lat: "19.2183307",
    lng: "72.9780897",
    country: "IN",
    country_long: "India",
    portCode: "INTHN",
    portName: "Thane",
    portContinent: "Asia",
  },
  {
    lat: "10.5276416",
    lng: "76.2144349",
    country: "IN",
    country_long: "India",
    portCode: "INTHR",
    portName: "Thrissur",
    portContinent: "Asia",
  },
  {
    lat: "24.7456147",
    lng: "78.8320779",
    country: "IN",
    country_long: "India",
    portCode: "INTIK",
    portName: "Tikamgarh",
    portContinent: "Asia",
  },
  {
    lat: "13.6287557",
    lng: "79.4191795",
    country: "IN",
    country_long: "India",
    portCode: "INTIR",
    portName: "Tirupati",
    portContinent: "Asia",
  },
  {
    lat: "-2.5517413",
    lng: "-44.3272037",
    country: "BR",
    country_long: "Brazil",
    portCode: "INTIV",
    portName: "Tiviri",
    portContinent: "Asia",
  },
  {
    lat: "21.3514348",
    lng: "72.0327247",
    country: "IN",
    country_long: "India",
    portCode: "INTJA",
    portName: "Talaja",
    portContinent: "Asia",
  },
  {
    lat: "10.7869994",
    lng: "79.13782739999999",
    country: "IN",
    country_long: "India",
    portCode: "INTJV",
    portName: "Thanjavur",
    portContinent: "Asia",
  },
  {
    lat: "28.5052081",
    lng: "77.2778944",
    country: "IN",
    country_long: "India",
    portCode: "INTKD",
    portName: "Tughlakabad",
    portContinent: "Asia",
  },
  {
    lat: "29.2235113",
    lng: "79.5303979",
    country: "IN",
    country_long: "India",
    portCode: "INTKN",
    portName: "Tikonia",
    portContinent: "Asia",
  },
  {
    lat: "8.956400799999999",
    lng: "77.3152492",
    country: "IN",
    country_long: "India",
    portCode: "INTKS",
    portName: "Tenkasi",
    portContinent: "Asia",
  },
  {
    lat: "12.035137",
    lng: "75.36109359999999",
    country: "IN",
    country_long: "India",
    portCode: "INTLB",
    portName: "Taliparamba",
    portContinent: "Asia",
  },
  {
    lat: "18.7375763",
    lng: "73.67466259999999",
    country: "IN",
    country_long: "India",
    portCode: "INTLG",
    portName: "Talegoan",
    portContinent: "Asia",
  },
  {
    lat: "13.1230558",
    lng: "79.9119526",
    country: "IN",
    country_long: "India",
    portCode: "INTLT",
    portName: "LTSL SEZ/Tiruvallur",
    portContinent: "Asia",
  },
  {
    lat: "19.0093414",
    lng: "72.8984317",
    country: "IN",
    country_long: "India",
    portCode: "INTMP",
    portName: "Trombay",
    portContinent: "Asia",
  },
  {
    lat: "17.1623203",
    lng: "78.28353129999999",
    country: "IN",
    country_long: "India",
    portCode: "INTMX",
    portName: "CMTL ICD/Thimmapur",
    portContinent: "Asia",
  },
  {
    lat: "12.9487403",
    lng: "79.318978",
    country: "IN",
    country_long: "India",
    portCode: "INTN9",
    portName: "Ranipet",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INTNA",
    portName: "Thana",
    portContinent: "Asia",
  },
  {
    lat: "8.764166099999999",
    lng: "78.1348361",
    country: "IN",
    country_long: "India",
    portCode: "INTNC",
    portName: "Ccclil-SEZ/Tuticorin",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INTND",
    portName: "Tondi",
    portContinent: "Asia",
  },
  {
    lat: "25.1636489",
    lng: "85.5457689",
    country: "IN",
    country_long: "India",
    portCode: "INTNG",
    portName: "Tungi",
    portContinent: "Asia",
  },
  {
    lat: "17.7083025",
    lng: "83.1526789",
    country: "IN",
    country_long: "India",
    portCode: "INTNI",
    portName: "Hipl SEZ/Visakhapatnam",
    portContinent: "Asia",
  },
  {
    lat: "21.9903577",
    lng: "72.6661161",
    country: "IN",
    country_long: "India",
    portCode: "INTNK",
    portName: "Tankari",
    portContinent: "Asia",
  },
  {
    lat: "11.2193848",
    lng: "78.16784179999999",
    country: "IN",
    country_long: "India",
    portCode: "INTNN",
    portName: "Namakkal",
    portContinent: "Asia",
  },
  {
    lat: "8.764166099999999",
    lng: "78.1348361",
    country: "IN",
    country_long: "India",
    portCode: "INTNS",
    portName: "SSL SEZ/Tuticorin",
    portContinent: "Asia",
  },
  {
    lat: "10.4002311",
    lng: "79.8478808",
    country: "IN",
    country_long: "India",
    portCode: "INTPH",
    portName: "Thopputhurai",
    portContinent: "Asia",
  },
  {
    lat: "10.7904833",
    lng: "78.7046725",
    country: "IN",
    country_long: "India",
    portCode: "INTPJ",
    portName: "ECTN SEZ/Tiruchirapalli",
    portContinent: "Asia",
  },
  {
    lat: "14.9780088",
    lng: "74.0419464",
    country: "IN",
    country_long: "India",
    portCode: "INTPN",
    portName: "Talpona",
    portContinent: "Asia",
  },
  {
    lat: "9.9355961",
    lng: "76.2583277",
    country: "IN",
    country_long: "India",
    portCode: "INTPP",
    portName: "Thoppumpady",
    portContinent: "Asia",
  },
  {
    lat: "55.6830201",
    lng: "12.584753",
    country: "DK",
    country_long: "Denmark",
    portCode: "INTRA",
    portName: "Tranquebar",
    portContinent: "Asia",
  },
  {
    lat: "10.7904833",
    lng: "78.7046725",
    country: "IN",
    country_long: "India",
    portCode: "INTRI",
    portName: "Tiruchirappalli",
    portContinent: "Asia",
  },
  {
    lat: "13.1230558",
    lng: "79.9119526",
    country: "IN",
    country_long: "India",
    portCode: "INTRL",
    portName: "Tiruvallur",
    portContinent: "Asia",
  },
  {
    lat: "19.7831533",
    lng: "72.7444429",
    country: "IN",
    country_long: "India",
    portCode: "INTRP",
    portName: "Tarapur / BOISAR",
    portContinent: "Asia",
  },
  {
    lat: "10.9166586",
    lng: "75.92451179999999",
    country: "IN",
    country_long: "India",
    portCode: "INTRU",
    portName: "Tirur",
    portContinent: "Asia",
  },
  {
    lat: "8.5241391",
    lng: "76.9366376",
    country: "IN",
    country_long: "India",
    portCode: "INTRV",
    portName: "Port of Trivandrum",
    portContinent: "Asia",
  },
  {
    lat: "10.7904833",
    lng: "78.7046725",
    country: "IN",
    country_long: "India",
    portCode: "INTRZ",
    portName: "Tiruchirapalli",
    portContinent: "Asia",
  },
  {
    lat: "8.8213396",
    lng: "77.3740732",
    country: "IN",
    country_long: "India",
    portCode: "INTSI",
    portName: "HVCPL SEZ/KADAYAM",
    portContinent: "Asia",
  },
  {
    lat: "27.4885539",
    lng: "95.355758",
    country: "IN",
    country_long: "India",
    portCode: "INTSK",
    portName: "Tinsukia",
    portContinent: "Asia",
  },
  {
    lat: "26.012373",
    lng: "76.3560109",
    country: "IN",
    country_long: "India",
    portCode: "INTSM",
    portName: "Sawai Madhopur",
    portContinent: "Asia",
  },
  {
    lat: "31.4538668",
    lng: "74.9267599",
    country: "IN",
    country_long: "India",
    portCode: "INTT8",
    portName: "Tarn Taran",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INTTP",
    portName: "Ttpl Cfs/Dadri",
    portContinent: "Asia",
  },
  {
    lat: "22.5466651",
    lng: "88.31476169999999",
    country: "IN",
    country_long: "India",
    portCode: "INTTS",
    portName: "T.T.Shed",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INTUB",
    portName: "TUMB",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INTUN",
    portName: "Tuna",
    portContinent: "Asia",
  },
  {
    lat: "11.1085242",
    lng: "77.3410656",
    country: "IN",
    country_long: "India",
    portCode: "INTUN1",
    portName: "Tirupuri",
    portContinent: "Asia",
  },
  {
    lat: "11.1085242",
    lng: "77.3410656",
    country: "IN",
    country_long: "India",
    portCode: "INTUP",
    portName: "Tiruppur",
    portContinent: "Asia",
  },
  {
    lat: "11.1085242",
    lng: "77.3410656",
    country: "IN",
    country_long: "India",
    portCode: "INTUP3",
    portName: "Tiruppur",
    portContinent: "Asia",
  },
  {
    lat: "11.1085242",
    lng: "77.3410656",
    country: "IN",
    country_long: "India",
    portCode: "INTUP6",
    portName: "Tiruppur",
    portContinent: "Asia",
  },
  {
    lat: "8.7562613",
    lng: "78.17907799999999",
    country: "IN",
    country_long: "India",
    portCode: "INTUT",
    portName: "Port of Tuticorin",
    portContinent: "Asia",
  },
  {
    lat: "8.5241391",
    lng: "76.9366376",
    country: "IN",
    country_long: "India",
    portCode: "INTVC",
    portName: "Kinfraa-SEZ/Thiruvananthapuram",
    portContinent: "Asia",
  },
  {
    lat: "12.2252841",
    lng: "79.07469569999999",
    country: "IN",
    country_long: "India",
    portCode: "INTVI",
    portName: "Tiruvannamalai",
    portContinent: "Asia",
  },
  {
    lat: "10.7661312",
    lng: "79.6343691",
    country: "IN",
    country_long: "India",
    portCode: "INTVR",
    portName: "Thiruvarur",
    portContinent: "Asia",
  },
  {
    lat: "13.1261062",
    lng: "80.2879959",
    country: "IN",
    country_long: "India",
    portCode: "INTVT",
    portName: "Tondiarpet",
    portContinent: "Asia",
  },
  {
    lat: "11.0749861",
    lng: "79.80586009999999",
    country: "IN",
    country_long: "India",
    portCode: "INTYR",
    portName: "Tirukkadayyur",
    portContinent: "Asia",
  },
  {
    lat: "32.9159847",
    lng: "75.1416173",
    country: "N/A",
    country_long: "N/A",
    portCode: "INUDH",
    portName: "Udhampur",
    portContinent: "Asia",
  },
  {
    lat: "18.5372467",
    lng: "73.8860685",
    country: "IN",
    country_long: "India",
    portCode: "INUDI",
    portName: "Synefra-SEZ/Udupi",
    portContinent: "Asia",
  },
  {
    lat: "21.0769484",
    lng: "72.8792399",
    country: "IN",
    country_long: "India",
    portCode: "INUDN",
    portName: "Ghb-SEZ/Surat",
    portContinent: "Asia",
  },
  {
    lat: "24.585445",
    lng: "73.712479",
    country: "IN",
    country_long: "India",
    portCode: "INUDR",
    portName: "Udaipur",
    portContinent: "Asia",
  },
  {
    lat: "23.1764665",
    lng: "75.7885163",
    country: "IN",
    country_long: "India",
    portCode: "INUJJ",
    portName: "Ujjain",
    portContinent: "Asia",
  },
  {
    lat: "11.3410364",
    lng: "77.7171642",
    country: "IN",
    country_long: "India",
    portCode: "INUKL",
    portName: "Etlisl-SEZ/Erode",
    portContinent: "Asia",
  },
  {
    lat: "22.8780889",
    lng: "83.28131619999999",
    country: "IN",
    country_long: "India",
    portCode: "INULP",
    portName: "Ultapani",
    portContinent: "Asia",
  },
  {
    lat: "18.972009",
    lng: "73.0348229",
    country: "IN",
    country_long: "India",
    portCode: "INULW",
    portName: "Ulwa",
    portContinent: "Asia",
  },
  {
    lat: "23.5309797",
    lng: "80.82947469999999",
    country: "IN",
    country_long: "India",
    portCode: "INUMA",
    portName: "Umaria",
    portContinent: "Asia",
  },
  {
    lat: "20.1738123",
    lng: "72.7639662",
    country: "IN",
    country_long: "India",
    portCode: "INUMB",
    portName: "Umbergoan",
    portContinent: "Asia",
  },
  {
    lat: "20.1738123",
    lng: "72.7639662",
    country: "IN",
    country_long: "India",
    portCode: "INUMG",
    portName: "Umbergaon",
    portContinent: "Asia",
  },
  {
    lat: "20.5245802",
    lng: "72.8943768",
    country: "IN",
    country_long: "India",
    portCode: "INUMR",
    portName: "Umarsadi",
    portContinent: "Asia",
  },
  {
    lat: "34.807836",
    lng: "-87.6804676",
    country: "US",
    country_long: "United States",
    portCode: "INUNA",
    portName: "Una",
    portContinent: "Asia",
  },
  {
    lat: "17.4399295",
    lng: "78.4982741",
    country: "IN",
    country_long: "India",
    portCode: "INUND",
    portName: "Secunderabad",
    portContinent: "Asia",
  },
  {
    lat: "30.9225523",
    lng: "76.8317325",
    country: "IN",
    country_long: "India",
    portCode: "INUPH",
    portName: "Barotiwala",
    portContinent: "Asia",
  },
  {
    lat: "18.8772346",
    lng: "72.9283391",
    country: "IN",
    country_long: "India",
    portCode: "INURA",
    portName: "Uran",
    portContinent: "Asia",
  },
  {
    lat: "17.1962923",
    lng: "78.4857581",
    country: "IN",
    country_long: "India",
    portCode: "INURF",
    portName: "FAB City SPV-SEZ/Srinagar&Raviryal",
    portContinent: "Asia",
  },
  {
    lat: "17.2541312",
    lng: "78.4496168",
    country: "IN",
    country_long: "India",
    portCode: "INURG",
    portName: "GMR Hyderabad Aviation-SEZ/Mamidipally",
    portContinent: "Asia",
  },
  {
    lat: "19.2683186",
    lng: "72.8101887",
    country: "IN",
    country_long: "India",
    portCode: "INUTN",
    portName: "Uttan",
    portContinent: "Asia",
  },
  {
    lat: "11.2427777",
    lng: "76.9586532",
    country: "IN",
    country_long: "India",
    portCode: "INUTQ",
    portName: "Karamadi",
    portContinent: "Asia",
  },
  {
    lat: "22.3976013",
    lng: "69.719757",
    country: "IN",
    country_long: "India",
    portCode: "INVAD",
    portName: "Vadinar (INVAD), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "20.9166872",
    lng: "73.24999749999999",
    country: "IN",
    country_long: "India",
    portCode: "INVAL",
    portName: "Valvada",
    portContinent: "Asia",
  },
  {
    lat: "15.8514136",
    lng: "73.6389038",
    country: "IN",
    country_long: "India",
    portCode: "INVEN",
    portName: "Port of Vengurla",
    portContinent: "Asia",
  },
  {
    lat: "8.7431179",
    lng: "78.1229523",
    country: "IN",
    country_long: "India",
    portCode: "INVEP",
    portName: "Veppalodai",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INVER",
    portName: "Verna",
    portContinent: "Asia",
  },
  {
    lat: "16.5061743",
    lng: "80.6480153",
    country: "IN",
    country_long: "India",
    portCode: "INVGA",
    portName: "Vijayawada",
    portContinent: "Asia",
  },
  {
    lat: "9.5680116",
    lng: "77.96244349999999",
    country: "IN",
    country_long: "India",
    portCode: "INVGH",
    portName: "Virudhunagar",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INVGM",
    portName: "Vasco da Gama",
    portContinent: "Asia",
  },
  {
    lat: "23.1481068",
    lng: "72.0325865",
    country: "IN",
    country_long: "India",
    portCode: "INVGR",
    portName: "Bhojva Icd/Viramgam",
    portContinent: "Asia",
  },
  {
    lat: "23.5235719",
    lng: "77.81397179999999",
    country: "IN",
    country_long: "India",
    portCode: "INVHA",
    portName: "Vidisha",
    portContinent: "Asia",
  },
  {
    lat: "23.5609405",
    lng: "72.7510921",
    country: "IN",
    country_long: "India",
    portCode: "INVIA",
    portName: "Vijapur",
    portContinent: "Asia",
  },
  {
    lat: "22.5459097",
    lng: "72.92939439999999",
    country: "IN",
    country_long: "India",
    portCode: "INVID",
    portName: "Vidyanagar",
    portContinent: "Asia",
  },
  {
    lat: "17.6868159",
    lng: "83.2184815",
    country: "IN",
    country_long: "India",
    portCode: "INVIG",
    portName: "Vizagapatanam",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INVIJ",
    portName: "Vijayanagar",
    portContinent: "Asia",
  },
  {
    lat: "11.9401378",
    lng: "79.4861449",
    country: "IN",
    country_long: "India",
    portCode: "INVIL",
    portName: "Viluppuram",
    portContinent: "Asia",
  },
  {
    lat: "17.6868159",
    lng: "83.2184815",
    country: "IN",
    country_long: "India",
    portCode: "INVIZ",
    portName: "Vizag",
    portContinent: "Asia",
  },
  {
    lat: "19.1250028",
    lng: "72.87325179999999",
    country: "IN",
    country_long: "India",
    portCode: "INVKH",
    portName: "HNB SEZ/Mumbai",
    portContinent: "Asia",
  },
  {
    lat: "9.1841353",
    lng: "78.6370367",
    country: "IN",
    country_long: "India",
    portCode: "INVKM",
    portName: "Valinokkam",
    portContinent: "Asia",
  },
  {
    lat: "22.7168763",
    lng: "72.271512",
    country: "IN",
    country_long: "India",
    portCode: "INVLD",
    portName: "Dishman-Pharmaceutical-SEZ/Kalyangadh",
    portContinent: "Asia",
  },
  {
    lat: "22.8365498",
    lng: "72.3627976",
    country: "IN",
    country_long: "India",
    portCode: "INVLN",
    portName: "NG Realty-SEZ/Bavla",
    portContinent: "Asia",
  },
  {
    lat: "12.9165167",
    lng: "79.13249859999999",
    country: "IN",
    country_long: "India",
    portCode: "INVLR",
    portName: "SIPC SEZ/Vellore",
    portContinent: "Asia",
  },
  {
    lat: "11.0510089",
    lng: "75.9825348",
    country: "IN",
    country_long: "India",
    portCode: "INVNG",
    portName: "Vemgira",
    portContinent: "Asia",
  },
  {
    lat: "25.3176452",
    lng: "82.9739144",
    country: "IN",
    country_long: "India",
    portCode: "INVNS",
    portName: "Varanasi",
    portContinent: "Asia",
  },
  {
    lat: "20.3893155",
    lng: "72.9106202",
    country: "IN",
    country_long: "India",
    portCode: "INVPI",
    portName: "Vapi",
    portContinent: "Asia",
  },
  {
    lat: "9.172669599999999",
    lng: "77.8714879",
    country: "IN",
    country_long: "India",
    portCode: "INVPT",
    portName: "Kovilpatti",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INVRD",
    portName: "Varavda",
    portContinent: "Asia",
  },
  {
    lat: "15.8160735",
    lng: "80.3885206",
    country: "IN",
    country_long: "India",
    portCode: "INVRU",
    portName: "Vadarevu",
    portContinent: "Asia",
  },
  {
    lat: "20.5992349",
    lng: "72.9342451",
    country: "IN",
    country_long: "India",
    portCode: "INVSD",
    portName: "Valsad",
    portContinent: "Asia",
  },
  {
    lat: "23.7023775",
    lng: "72.5425606",
    country: "IN",
    country_long: "India",
    portCode: "INVSG",
    portName: "Visnagar",
    portContinent: "Asia",
  },
  {
    lat: "20.9670482",
    lng: "72.7519148",
    country: "IN",
    country_long: "India",
    portCode: "INVSI",
    portName: "Vansi-Borsi",
    portContinent: "Asia",
  },
  {
    lat: "17.7336261",
    lng: "83.31680709999999",
    country: "IN",
    country_long: "India",
    portCode: "INVSP",
    portName: "Wipro Sez/Resapuvanipalem",
    portContinent: "Asia",
  },
  {
    lat: "52.2296756",
    lng: "21.0122287",
    country: "PL",
    country_long: "Poland",
    portCode: "INVSV",
    portName: "Varsova",
    portContinent: "Asia",
  },
  {
    lat: "12.9165167",
    lng: "79.13249859999999",
    country: "IN",
    country_long: "India",
    portCode: "INVTC",
    portName: "Cheyyar-SEZ/Vellore",
    portContinent: "Asia",
  },
  {
    lat: "17.7016945",
    lng: "83.2897108",
    country: "IN",
    country_long: "India",
    portCode: "INVTZ",
    portName: "Port of Visakhapatnam (Vizag)",
    portContinent: "Asia",
  },
  {
    lat: "20.9158979",
    lng: "70.3628516",
    country: "IN",
    country_long: "India",
    portCode: "INVVA",
    portName: "Port of Veraval",
    portContinent: "Asia",
  },
  {
    lat: "16.5518794",
    lng: "73.3353863",
    country: "IN",
    country_long: "India",
    portCode: "INVYD",
    portName: "Vijaydurg (INVYD), India, Asia",
    portContinent: "Asia",
  },
  {
    lat: "8.393220099999999",
    lng: "77.004626",
    country: "IN",
    country_long: "India",
    portCode: "INVZJ",
    portName: "Vazhinjam",
    portContinent: "Asia",
  },
  {
    lat: "17.7083025",
    lng: "83.1526789",
    country: "IN",
    country_long: "India",
    portCode: "INVZM",
    portName: "Dll SEZ/Visakhapatnam",
    portContinent: "Asia",
  },
  {
    lat: "18.2949165",
    lng: "83.89380179999999",
    country: "IN",
    country_long: "India",
    portCode: "INVZR",
    portName: "REDDY R-SEZ/SRIKAKULA",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INWAD",
    portName: "Wada",
    portContinent: "Asia",
  },
  {
    lat: "17.9527562",
    lng: "73.8885528",
    country: "IN",
    country_long: "India",
    portCode: "INWAI",
    portName: "Wai",
    portContinent: "Asia",
  },
  {
    lat: "19.8429547",
    lng: "75.2333128",
    country: "IN",
    country_long: "India",
    portCode: "INWAL",
    portName: "Waluj (Aurangabad)",
    portContinent: "Asia",
  },
  {
    lat: "20.250596",
    lng: "79.0163018",
    country: "IN",
    country_long: "India",
    portCode: "INWAR",
    portName: "Warora",
    portContinent: "Asia",
  },
  {
    lat: "20.1119123",
    lng: "77.1312586",
    country: "IN",
    country_long: "India",
    portCode: "INWAS",
    portName: "Washim",
    portContinent: "Asia",
  },
  {
    lat: "44.1700735",
    lng: "-69.6253222",
    country: "US",
    country_long: "United States",
    portCode: "INWFD",
    portName: "Whitefield",
    portContinent: "Asia",
  },
  {
    lat: "12.9858421",
    lng: "77.7355549",
    country: "IN",
    country_long: "India",
    portCode: "INWFT",
    portName: "Itpl Sezii/Whitefield",
    portContinent: "Asia",
  },
  {
    lat: "17.9689008",
    lng: "79.59405439999999",
    country: "IN",
    country_long: "India",
    portCode: "INWGC",
    portName: "Warrangal",
    portContinent: "Asia",
  },
  {
    lat: "17.9689008",
    lng: "79.59405439999999",
    country: "IN",
    country_long: "India",
    portCode: "INWRA",
    portName: "Warangal",
    portContinent: "Asia",
  },
  {
    lat: "22.7829722",
    lng: "85.8255855",
    country: "IN",
    country_long: "India",
    portCode: "INWSI",
    portName: "Kharsawan",
    portContinent: "Asia",
  },
  {
    lat: "27.3031986",
    lng: "88.2071598",
    country: "IN",
    country_long: "India",
    portCode: "INWSS",
    portName: "West Sikkim",
    portContinent: "Asia",
  },
  {
    lat: "16.7271912",
    lng: "82.21757749999999",
    country: "IN",
    country_long: "India",
    portCode: "INYAN",
    portName: "Yanam",
    portContinent: "Asia",
  },
  {
    lat: "20.3899385",
    lng: "78.1306846",
    country: "IN",
    country_long: "India",
    portCode: "INYAV",
    portName: "Yavatmal",
    portContinent: "Asia",
  },
  {
    lat: "11.7747924",
    lng: "78.2097052",
    country: "IN",
    country_long: "India",
    portCode: "INYCD",
    portName: "Yercaud",
    portContinent: "Asia",
  },
  {
    lat: "13.1154662",
    lng: "77.6069977",
    country: "IN",
    country_long: "India",
    portCode: "INYMA",
    portName: "Modern Asset Sezii/Yelahanka Hobli",
    portContinent: "Asia",
  },
  {
    lat: "13.1154662",
    lng: "77.6069977",
    country: "IN",
    country_long: "India",
    portCode: "INYNA",
    portName: "Modern Asset Sezi/Yelahanka Hobli",
    portContinent: "Asia",
  },
  {
    lat: "12.9715987",
    lng: "77.5945627",
    country: "IN",
    country_long: "India",
    portCode: "INYNL",
    portName: "Landt Cel Sezii/Bangalore North",
    portContinent: "Asia",
  },
  {
    lat: "12.9715987",
    lng: "77.5945627",
    country: "IN",
    country_long: "India",
    portCode: "INYNT",
    portName: "Landt Cel Sezi/Bangalore North",
    portContinent: "Asia",
  },
  {
    lat: "20.3899385",
    lng: "78.1306846",
    country: "IN",
    country_long: "India",
    portCode: "INYVT",
    portName: "Yavatamal",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "INZER",
    portName: "Zero (Ziro)",
    portContinent: "Asia",
  },
  {
    lat: "23.022505",
    lng: "72.5713621",
    country: "IN",
    country_long: "India",
    portCode: "INZIP",
    portName: "ZIPL-SEZ/Ahmedabad",
    portContinent: "Asia",
  },
  {
    lat: "30.6425496",
    lng: "76.8173359",
    country: "IN",
    country_long: "India",
    portCode: "INZIR",
    portName: "Zirakpur",
    portContinent: "Asia",
  },
  {
    lat: "13.3378762",
    lng: "77.117325",
    country: "IN",
    country_long: "India",
    portCode: "INZUM",
    portName: "Tumkur",
    portContinent: "Asia",
  },
  {
    lat: "33.223191",
    lng: "43.679291",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQALF",
    portName: "Abu Fulus (IQALF), Iraq, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "33.2997418",
    lng: "44.2999955",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQAMA",
    portName: "AL-AMIRIYA",
    portContinent: "Middle East",
  },
  {
    lat: "30.0623794",
    lng: "47.9337956",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQBGT",
    portName: "Basra Gateway Terminal",
    portContinent: "Middle East",
  },
  {
    lat: "33.315241",
    lng: "44.3660671",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQBGW",
    portName: "Baghdad",
    portContinent: "Middle East",
  },
  {
    lat: "30.5257657",
    lng: "47.77379699999999",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQBSR",
    portName: "Basra (IQBSR), Basra, Iraq",
    portContinent: "Middle East",
  },
  {
    lat: "36.190073",
    lng: "43.9930303",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQEBL",
    portName: "ERBIL",
    portContinent: "Middle East",
  },
  {
    lat: "29.9811696",
    lng: "48.4675659",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQFAW",
    portName: "Al Faw (IQFAW), Iraq, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "30.2331397",
    lng: "47.7686889",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQKAZ",
    portName: "Port of Khor Al Zubair",
    portContinent: "Middle East",
  },
  {
    lat: "35.4666329",
    lng: "44.3798895",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQKIK",
    portName: "KIRKUK",
    portContinent: "Middle East",
  },
  {
    lat: "36.3489278",
    lng: "43.157736",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQOSM",
    portName: "MOSUL",
    portContinent: "Middle East",
  },
  {
    lat: "35.5557603",
    lng: "45.4351181",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQSYH",
    portName: "Sulaymaniyah",
    portContinent: "Middle East",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "IQTRI",
    portName: "Traibel",
    portContinent: "Middle East",
  },
  {
    lat: "30.0397683",
    lng: "47.9267674",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQUQN",
    portName: "Umm Qasr North",
    portContinent: "Middle East",
  },
  {
    lat: "30.0397683",
    lng: "47.9267674",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQUQR",
    portName: "umm qasr",
    portContinent: "Middle East",
  },
  {
    lat: "30.0397683",
    lng: "47.9267674",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQUQS",
    portName: "UMM QASR SOUTH",
    portContinent: "Middle East",
  },
  {
    lat: "37.1504622",
    lng: "42.6726771",
    country: "IQ",
    country_long: "Iraq",
    portCode: "IQZAO",
    portName: "ZAKHO",
    portContinent: "Middle East",
  },
  {
    lat: "30.3666414",
    lng: "48.2754711",
    country: "IR",
    country_long: "Iran",
    portCode: "IRABD",
    portName: "Abadan (IRABD), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "25.8860289",
    lng: "55.028307",
    country: "N/A",
    country_long: "N/A",
    portCode: "IRAEU",
    portName: "Abu Musa (IRAEU), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "32.427908",
    lng: "53.688046",
    country: "IR",
    country_long: "Iran",
    portCode: "IRAMP",
    portName: "Amirabad (IRAMP), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "27.472075",
    lng: "52.6146057",
    country: "IR",
    country_long: "Iran",
    portCode: "IRASA",
    portName: "Asaluyeh (IRASA), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "38.4224562",
    lng: "48.8687149",
    country: "IR",
    country_long: "Iran",
    portCode: "IRASR",
    portName: "Astara (IRASR), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "27.472075",
    lng: "52.6146057",
    country: "IR",
    country_long: "Iran",
    portCode: "IRBAH",
    portName: "ASSALUYEH",
    portContinent: "Middle East",
  },
  {
    lat: "37.4639089",
    lng: "49.4798635",
    country: "IR",
    country_long: "Iran",
    portCode: "IRBAZ",
    portName: "Anzali (IRBAZ), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "36.70052740000001",
    lng: "52.650192",
    country: "IR",
    country_long: "Iran",
    portCode: "IRBBL",
    portName: "Baboslar (IRBBL), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "32.6883054",
    lng: "51.5304082",
    country: "IR",
    country_long: "Iran",
    portCode: "IRBIK",
    portName: "Imam Khomeini Pt/ Mahshahr City (IRBIK), Khomeini Shahr, Iran",
    portContinent: "Middle East",
  },
  {
    lat: "32.6883054",
    lng: "51.5304082",
    country: "IR",
    country_long: "Iran",
    portCode: "IRBKK",
    portName: "Bandar-e Emam Khomeyni (IRBKK), Khomeini Shahr, Iran",
    portContinent: "Middle East",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "IRBKM",
    portName: "Imam Khomeini Port",
    portContinent: "Middle East",
  },
  {
    lat: "27.17375",
    lng: "56.280849",
    country: "IR",
    country_long: "Iran",
    portCode: "IRBND",
    portName: "Bandar Abbas (IRBND), Bandar-e-Abbas, Iran",
    portContinent: "Middle East",
  },
  {
    lat: "36.774965",
    lng: "53.9461749",
    country: "IR",
    country_long: "Iran",
    portCode: "IRBRG",
    portName: "Bandar Gaz (IRBRG), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "27.1141515",
    lng: "56.0614786",
    country: "IR",
    country_long: "Iran",
    portCode: "IRBSR",
    portName: "BANDAR SHAHID RAJAEE (IRBSR), Bandar-e-Abbas, Iran",
    portContinent: "Middle East",
  },
  {
    lat: "28.9144913",
    lng: "50.8279019",
    country: "IR",
    country_long: "Iran",
    portCode: "IRBUZ",
    portName: "Bushehr (IRBUZ), Bandar-e Bushehr, Iran",
    portContinent: "Middle East",
  },
  {
    lat: "29.5808374",
    lng: "50.51721",
    country: "IR",
    country_long: "Iran",
    portCode: "IRGNH",
    portName: "Ganaveh (IRGNH), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "26.6535714",
    lng: "55.8801898",
    country: "IR",
    country_long: "Iran",
    portCode: "IRHEJ",
    portName: "Hengam (IRHEJ), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "27.05927",
    lng: "56.4607919",
    country: "IR",
    country_long: "Iran",
    portCode: "IRHOR",
    portName: "Hormuz (IRHOR), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "29.8346391",
    lng: "50.2670044",
    country: "IR",
    country_long: "Iran",
    portCode: "IRIMH",
    portName: "Imam Hasan (IRIMH), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "25.6577006",
    lng: "57.7856677",
    country: "IR",
    country_long: "Iran",
    portCode: "IRJAK",
    portName: "Jask (IRJAK), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "29.2457199",
    lng: "50.3120667",
    country: "IR",
    country_long: "Iran",
    portCode: "IRKHK",
    portName: "Khark (IRKHK), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "30.4256219",
    lng: "48.1891185",
    country: "IR",
    country_long: "Iran",
    portCode: "IRKHO",
    portName: "Port of Khorramshahr",
    portContinent: "Middle East",
  },
  {
    lat: "32.6828641",
    lng: "59.8718605",
    country: "IR",
    country_long: "Iran",
    portCode: "IRKNR",
    portName: "Kangan (IRKNR), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "32.427908",
    lng: "53.688046",
    country: "IR",
    country_long: "Iran",
    portCode: "IRLVP",
    portName: "Lavan (IRLVP), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "30.5617605",
    lng: "49.18006640000001",
    country: "IR",
    country_long: "Iran",
    portCode: "IRMRX",
    portName: "Mahshahr (IRMRX), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "36.649364",
    lng: "51.4887307",
    country: "IR",
    country_long: "Iran",
    portCode: "IRNSH",
    portName: "Nowshahr (IRNSH), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "26.8118673",
    lng: "55.89132069999999",
    country: "IR",
    country_long: "Iran",
    portCode: "IRQSH",
    portName: "Qeshm Island (IRQSH), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "26.9546731",
    lng: "56.26797490000001",
    country: "IR",
    country_long: "Iran",
    portCode: "IRQSM",
    portName: "Queshm (IRQSM), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "36.9268274",
    lng: "50.6430658",
    country: "IR",
    country_long: "Iran",
    portCode: "IRRZR",
    portName: "Ramsar (IRRZR), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "32.427908",
    lng: "53.688046",
    country: "IR",
    country_long: "Iran",
    portCode: "IRSRP",
    portName: "Rajaei (IRSRP), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "32.427908",
    lng: "53.688046",
    country: "IR",
    country_long: "Iran",
    portCode: "IRTMB",
    portName: "Tombak (IRTMB), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "25.2927035",
    lng: "60.6495553",
    country: "IR",
    country_long: "Iran",
    portCode: "IRZBR",
    portName: "Chabahar (IRZBR), Iran, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "64.3219244",
    lng: "-22.0702847",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISAKR",
    portName: "Akranes (ISAKR), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.6825509",
    lng: "-18.0906858",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISAKU",
    portName: "Akureyri (ISAKU), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.9429732",
    lng: "-18.3517532",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISASS",
    portName: "Arskogssandur (ISASS), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.0349921",
    lng: "-14.8057022",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISBAK",
    portName: "Bakkafjorddur (ISBAK), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.5265799",
    lng: "-13.8182045",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISBGJ",
    portName: "Borgarfjordur Eystri (ISBGJ), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.6863785",
    lng: "-23.6006172",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISBIL",
    portName: "Bildudalur (ISBIL), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.66009249999999",
    lng: "-20.2796587",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISBLO",
    portName: "Blonduos (ISBLO), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.1517719",
    lng: "-23.261709",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISBOL",
    portName: "Bolungarvik (ISBOL), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.7926288",
    lng: "-14.0079386",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISBRE",
    portName: "Breiddalsvik (ISBRE), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.1081658",
    lng: "-21.7678836",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISBUD",
    portName: "Budardalur (ISBUD), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.97070029999999",
    lng: "-18.5326927",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISDAL",
    portName: "Dalvik (ISDAL), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.0714105",
    lng: "-14.0152873",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISESK",
    portName: "Eskifjordur (ISESK), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.929288",
    lng: "-14.0121128",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISFAS",
    portName: "Faskrudsfjordur (ISFAS), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.051771",
    lng: "-23.5137726",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISFLA",
    portName: "Flateyri (ISFLA), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.9504165",
    lng: "-18.1781268",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISGRE",
    portName: "Grenivik (ISGRE), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.9246686",
    lng: "-23.260406",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISGRF",
    portName: "Grundarfjordur (ISGRF), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.8441808",
    lng: "-22.4383818",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISGRI",
    portName: "Grindavik (ISGRI), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.3603796",
    lng: "-21.7758053",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISGRT",
    portName: "Grundartangi (ISGRT), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.5423498",
    lng: "-17.9961161",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISGRY",
    portName: "Grimsey (ISGRY), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.02910539999999",
    lng: "-21.9684626",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISHAF",
    portName: "Hafnarfjordur (ISHAF), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.0243307",
    lng: "-22.5933743",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISHEL",
    portName: "Helguvik (ISHEL), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.295113",
    lng: "-15.228306",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISHFN",
    portName: "Hornafjordur (ISHFN), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.8975231",
    lng: "-19.412579",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISHOF",
    portName: "Hofsos (ISHOF), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.9953605",
    lng: "-18.3837407",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISHRI",
    portName: "Hrisey (ISHRI), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.0449706",
    lng: "-17.3383443",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISHUS",
    portName: "Husavik (ISHUS), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.7021287",
    lng: "-21.6845875",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISHVK",
    portName: "Holmavik (ISHVK), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.3968239",
    lng: "-20.9393747",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISHVM",
    portName: "Hvammstangi (ISHVM), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.35652809999999",
    lng: "-21.7103544",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISHVR",
    portName: "Hvalfjordur (ISHVR), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.074878",
    lng: "-23.1250684",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISISA",
    portName: "Isafjordur (ISISA), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.9997549",
    lng: "-22.5582716",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISKEF",
    portName: "Keflavik (ISKEF), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.3022945",
    lng: "-16.4475814",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISKOP",
    portName: "Kopasker (ISKOP), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.1033023",
    lng: "-21.8832579",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISKOV",
    portName: "Kopavogur (ISKOV), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.15242549999999",
    lng: "-13.6849649",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISNES",
    portName: "Neskaupstadur (ISNES), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.9747338",
    lng: "-22.5201543",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISNJA",
    portName: "Njardvik (ISNJA), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.0714713",
    lng: "-18.6536984",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISOLF",
    portName: "Olafsfjordur (ISOLF), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.89604849999999",
    lng: "-23.7060263",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISOLV",
    portName: "Olafsvik (ISOLV), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.5978726",
    lng: "-24.0009195",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISPAT",
    portName: "Patreksfjordur (ISPAT), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.4527736",
    lng: "-15.9486838",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISRAU",
    portName: "Raufarhofn (ISRAU), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.146582",
    lng: "-21.9426354",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISREY",
    portName: "Reykjavik (ISREY), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.0354339",
    lng: "-14.2159928",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISRFJ",
    portName: "Reydarfjordur (ISRFJ), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.4450184",
    lng: "-22.2066025",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISRHA",
    portName: "Reykholar (ISRHA), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.9232612",
    lng: "-23.8206251",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISRIF",
    portName: "Rif (ISRIF), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.0377604",
    lng: "-22.7068419",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISSAN",
    portName: "Sandgerdi (ISSAN), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.7409444",
    lng: "-19.6404747",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISSAU",
    portName: "Saudarkrokur (ISSAU), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.2598494",
    lng: "-14.0101156",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISSEY",
    portName: "Seydisfjordur (ISSEY), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.1511755",
    lng: "-18.9112652",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISSIG",
    portName: "Siglufjordur (ISSIG), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.82419370000001",
    lng: "-20.3079618",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISSKA",
    portName: "Skagastrond (ISSKA), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.8335184",
    lng: "-13.8731793",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISSTD",
    portName: "Stodvarfjordur (ISSTD), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.01503",
    lng: "-22.12969",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISSTR",
    portName: "Straumsvik (ISSTR), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.0728985",
    lng: "-22.7357311",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISSTY",
    portName: "Stykkisholmur (ISSTY), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.13113",
    lng: "-23.5271664",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISSUD",
    portName: "Sudureyri (ISSUD), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.6267441",
    lng: "-23.82302",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISTAL",
    portName: "Talknafjordur (ISTAL), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.8746903",
    lng: "-23.4885462",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISTEY",
    portName: "Thingeyri (ISTEY), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.8554117",
    lng: "-21.387345",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISTHH",
    portName: "Thorlakshofn (ISTHH), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.2012629",
    lng: "-15.333006",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISTHO",
    portName: "Thorshofn (ISTHO), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.437679",
    lng: "-20.267321",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISVES",
    portName: "Vestmannaeyjar (ISVES), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.96666699999999",
    lng: "-22.366667",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISVOG",
    portName: "Vogar (ISVOG), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.746246",
    lng: "-14.86136",
    country: "IS",
    country_long: "Iceland",
    portCode: "ISVPN",
    portName: "Vopnafjordur (ISVPN), Iceland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "41.87194",
    lng: "12.56738",
    country: "IT",
    country_long: "Italy",
    portCode: "ITADI",
    portName: "Macchia (ITADI), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.3486827",
    lng: "14.9912195",
    country: "IT",
    country_long: "Italy",
    portCode: "ITAGP",
    portName: "Agropoli (ITAGP), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.3088512",
    lng: "13.5857528",
    country: "IT",
    country_long: "Italy",
    portCode: "ITAGR",
    portName: "Agregento",
    portContinent: "Europe",
  },
  {
    lat: "40.5579517",
    lng: "8.319294900000001",
    country: "IT",
    country_long: "Italy",
    portCode: "ITAHO",
    portName: "Alghero (ITAHO), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.9072727",
    lng: "8.6116796",
    country: "IT",
    country_long: "Italy",
    portCode: "ITALE",
    portName: "ALESSANDRIA",
    portContinent: "Europe",
  },
  {
    lat: "44.0494254",
    lng: "8.215611299999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITALL",
    portName: "Albenga (ITALL), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.4495955",
    lng: "12.6197253",
    country: "IT",
    country_long: "Italy",
    portCode: "ITANZ",
    portName: "Anzio (ITANZ), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.6158299",
    lng: "13.518915",
    country: "IT",
    country_long: "Italy",
    portCode: "ITAOI",
    portName: "Ancona",
    portContinent: "Europe",
  },
  {
    lat: "45.7349551",
    lng: "7.313076199999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITAOT",
    portName: "AOSTA",
    portContinent: "Europe",
  },
  {
    lat: "43.46328390000001",
    lng: "11.8796336",
    country: "IT",
    country_long: "Italy",
    portCode: "ITARE",
    portName: "Arezzo",
    portContinent: "Europe",
  },
  {
    lat: "42.8475303",
    lng: "13.5668302",
    country: "IT",
    country_long: "Italy",
    portCode: "ITASP",
    portName: "Ascoli Piceno",
    portContinent: "Europe",
  },
  {
    lat: "44.90075119999999",
    lng: "8.2064257",
    country: "IT",
    country_long: "Italy",
    portCode: "ITAST",
    portName: "ASTI",
    portContinent: "Europe",
  },
  {
    lat: "39.9334339",
    lng: "9.703558899999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITATX",
    portName: "Arbatax (ITATX), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ITAUG",
    portName: "Port of Augusta",
    portContinent: "Europe",
  },
  {
    lat: "40.914388",
    lng: "14.7906121",
    country: "IT",
    country_long: "Italy",
    portCode: "ITAVE",
    portName: "AVELLINO",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ITAVO",
    portName: "Port of Avola",
    portContinent: "Europe",
  },
  {
    lat: "44.4058612",
    lng: "8.6860167",
    country: "IT",
    country_long: "Italy",
    portCode: "ITAZA",
    portName: "Arenzano (ITAZA), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.3873974",
    lng: "2.168568",
    country: "ES",
    country_long: "Spain",
    portCode: "ITBAR",
    portName: "BARCELLONA",
    portContinent: "Europe",
  },
  {
    lat: "40.6327278",
    lng: "17.9417616",
    country: "IT",
    country_long: "Italy",
    portCode: "ITBDS",
    portName: "Brindisi (ITBDS), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.6982642",
    lng: "9.6772698",
    country: "IT",
    country_long: "Italy",
    portCode: "ITBGO",
    portName: "BERGAMO",
    portContinent: "Europe",
  },
  {
    lat: "45.56288420000001",
    lng: "8.0583397",
    country: "IT",
    country_long: "Italy",
    portCode: "ITBIE",
    portName: "Biella",
    portContinent: "Europe",
  },
  {
    lat: "45.61692679999999",
    lng: "9.420871199999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITBLL",
    portName: "bellusco",
    portContinent: "Europe",
  },
  {
    lat: "41.87194",
    lng: "12.56738",
    country: "IT",
    country_long: "Italy",
    portCode: "ITBLN",
    portName: "Nagnoli (ITBLN), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.5345216",
    lng: "11.2878593",
    country: "IT",
    country_long: "Italy",
    portCode: "ITBLQ",
    portName: "BOLOGNA (ITBLQ), Bologna, Italy",
    portContinent: "Europe",
  },
  {
    lat: "46.1424635",
    lng: "12.2167088",
    country: "IT",
    country_long: "Italy",
    portCode: "ITBLX",
    portName: "Belluno",
    portContinent: "Europe",
  },
  {
    lat: "45.5415526",
    lng: "10.2118019",
    country: "IT",
    country_long: "Italy",
    portCode: "ITBRC",
    portName: "BRESCIA (ITBRC), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.1171432",
    lng: "16.8718715",
    country: "IT",
    country_long: "Italy",
    portCode: "ITBRI",
    portName: "Bari (ITBRI), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.611892",
    lng: "8.8531265",
    country: "IT",
    country_long: "Italy",
    portCode: "ITBUA",
    portName: "BUSTO ARSIZIO (ITBUA), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.63592269999999",
    lng: "11.4167591",
    country: "IT",
    country_long: "Italy",
    portCode: "ITBVG",
    portName: "BENTIVOGLIO (ITBVG), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "46.4982953",
    lng: "11.3547582",
    country: "IT",
    country_long: "Italy",
    portCode: "ITBZO",
    portName: "Bolzano",
    portContinent: "Europe",
  },
  {
    lat: "45.7246412",
    lng: "9.0382719",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCAF",
    portName: "Cadorago  (ITCAF), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.2238411",
    lng: "9.1216613",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCAG",
    portName: "Cagliari (ITCAG), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.6984766",
    lng: "14.4898001",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCAS",
    portName: "Castellammare Di Sta (ITCAS), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.1396438",
    lng: "12.2464292",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCEZ",
    portName: "Cesena",
    portContinent: "Europe",
  },
  {
    lat: "39.5941841",
    lng: "16.5201016",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCGC",
    portName: "Corigliano Calabro (ITCGC), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.2190746",
    lng: "12.2786505",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCHI",
    portName: "Chioggia (ITCHI), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.347886",
    lng: "14.1635845",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCII",
    portName: "Chieti",
    portContinent: "Europe",
  },
  {
    lat: "39.10979409999999",
    lng: "8.369288899999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCLS",
    portName: "Calasetta (ITCLS), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.5602544",
    lng: "14.6627161",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCOB",
    portName: "Campobasso",
    portContinent: "Europe",
  },
  {
    lat: "41.5391677",
    lng: "12.7298539",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCPV",
    portName: "Campoverde (ITCPV), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.2213998",
    lng: "10.0359661",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCRA",
    portName: "Massa Carrara",
    portContinent: "Europe",
  },
  {
    lat: "45.401001",
    lng: "11.9407789",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCRD",
    portName: "CAMIN - RIONE DI PADOVA (ITCRD), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.133249",
    lng: "10.0226511",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCRE",
    portName: "Cremona (ITCRE), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.0013359",
    lng: "18.4259106",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCRQ",
    portName: "Castro Marina (ITCRQ), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.079686",
    lng: "17.1243379",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCRV",
    portName: "Port of Crotone",
    portContinent: "Europe",
  },
  {
    lat: "45.5238021",
    lng: "8.902366299999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCRZ",
    portName: "Casorezzo",
    portContinent: "Europe",
  },
  {
    lat: "41.0723484",
    lng: "14.3311337",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCST",
    portName: "Caserta",
    portContinent: "Europe",
  },
  {
    lat: "37.4997177",
    lng: "15.0927369",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCTA",
    portName: "Port of Catania",
    portContinent: "Europe",
  },
  {
    lat: "40.913692",
    lng: "8.708592",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCTK",
    portName: "Castelsardo (ITCTK), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.4901115",
    lng: "14.0628928",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCTS",
    portName: "Caltanissetta",
    portContinent: "Europe",
  },
  {
    lat: "44.3844766",
    lng: "7.5426711",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCUN",
    portName: "Cuneo",
    portContinent: "Europe",
  },
  {
    lat: "42.0924239",
    lng: "11.7954132",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCVV",
    portName: "Civitavecchia",
    portContinent: "Europe",
  },
  {
    lat: "40.9075976",
    lng: "14.2928248",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCXR",
    portName: "Casoria (ITCXR), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.02123479999999",
    lng: "8.3371719",
    country: "IT",
    country_long: "Italy",
    portCode: "ITCZ2",
    portName: "Casorzo",
    portContinent: "Europe",
  },
  {
    lat: "45.7753929",
    lng: "13.6026211",
    country: "IT",
    country_long: "Italy",
    portCode: "ITDNO",
    portName: "Duino (ITDNO), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.5099321",
    lng: "14.262851",
    country: "IT",
    country_long: "Italy",
    portCode: "ITENO",
    portName: "Enna",
    portContinent: "Europe",
  },
  {
    lat: "43.6220339",
    lng: "13.3702811",
    country: "IT",
    country_long: "Italy",
    portCode: "ITFAL",
    portName: "Falconara (ITFAL), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.2440575",
    lng: "14.480186",
    country: "IT",
    country_long: "Italy",
    portCode: "ITFBZ",
    portName: "Fossacesia (ITFBZ), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.8034274",
    lng: "12.2518928",
    country: "IT",
    country_long: "Italy",
    portCode: "ITFCO",
    portName: "Fiumicino (ITFCO), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.7695604",
    lng: "11.2558136",
    country: "IT",
    country_long: "Italy",
    portCode: "ITFLR",
    portName: "Firenze / Florence",
    portContinent: "Europe",
  },
  {
    lat: "44.1689028",
    lng: "8.3416211",
    country: "IT",
    country_long: "Italy",
    portCode: "ITFNL",
    portName: "Finale Ligure (ITFNL), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.4621984",
    lng: "15.5446302",
    country: "IT",
    country_long: "Italy",
    portCode: "ITFOG",
    portName: "Foggia",
    portContinent: "Europe",
  },
  {
    lat: "41.2559961",
    lng: "13.6068672",
    country: "IT",
    country_long: "Italy",
    portCode: "ITFOM",
    portName: "Formia (ITFOM), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.55247900000001",
    lng: "7.721793399999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITFOS",
    portName: "Fossano (ITFOS), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.2227398",
    lng: "12.0407312",
    country: "IT",
    country_long: "Italy",
    portCode: "ITFRL",
    portName: "Forli",
    portContinent: "Europe",
  },
  {
    lat: "41.6396009",
    lng: "13.3426341",
    country: "IT",
    country_long: "Italy",
    portCode: "ITFRO",
    portName: "Frosinone",
    portContinent: "Europe",
  },
  {
    lat: "44.8381237",
    lng: "11.619787",
    country: "IT",
    country_long: "Italy",
    portCode: "ITFRR",
    portName: "Ferrara",
    portContinent: "Europe",
  },
  {
    lat: "45.043786",
    lng: "9.8095008",
    country: "IT",
    country_long: "Italy",
    portCode: "ITFSS",
    portName: "Fossadello di Caorso (ITFSS), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.2982491",
    lng: "12.7625847",
    country: "IT",
    country_long: "Italy",
    portCode: "ITFSV",
    portName: "Fossato Di Vico (ITFSV), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.2107299",
    lng: "13.5714285",
    country: "IT",
    country_long: "Italy",
    portCode: "ITGAE",
    portName: "Gaeta (ITGAE), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.9961235",
    lng: "9.625291299999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITGAI",
    portName: "Golfo Aranci (ITGAI), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.3333333",
    lng: "26.5",
    country: "TR",
    country_long: "Turkey",
    portCode: "ITGAL",
    portName: "Port of Gallipoli",
    portContinent: "Europe",
  },
  {
    lat: "37.0741526",
    lng: "14.2403537",
    country: "IT",
    country_long: "Italy",
    portCode: "ITGEA",
    portName: "Gela (ITGEA), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.4619904",
    lng: "12.2171672",
    country: "IT",
    country_long: "Italy",
    portCode: "ITGHE",
    portName: "Porto Marghera",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ITGIA",
    portName: "Port of Giardini",
    portContinent: "Europe",
  },
  {
    lat: "38.4262258",
    lng: "15.8988669",
    country: "IT",
    country_long: "Italy",
    portCode: "ITGIT",
    portName: "Gioia Tauro",
    portContinent: "Europe",
  },
  {
    lat: "44.5875818",
    lng: "7.661827199999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITGNL",
    portName: "Genola",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ITGOA",
    portName: "PORT OF GENOA",
    portContinent: "Europe",
  },
  {
    lat: "42.7635254",
    lng: "11.1123634",
    country: "IT",
    country_long: "Italy",
    portCode: "ITGRS",
    portName: "GROSSETO",
    portContinent: "Europe",
  },
  {
    lat: "45.9401812",
    lng: "13.6201754",
    country: "IT",
    country_long: "Italy",
    portCode: "ITGZA",
    portName: "Gorizia",
    portContinent: "Europe",
  },
  {
    lat: "42.3592899",
    lng: "10.9208465",
    country: "IT",
    country_long: "Italy",
    portCode: "ITIDG",
    portName: "Giglio Porto (ITIDG), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.87458400000001",
    lng: "8.0078099",
    country: "IT",
    country_long: "Italy",
    portCode: "ITIMP",
    portName: "Port of Imperia",
    portContinent: "Europe",
  },
  {
    lat: "41.5960411",
    lng: "14.2331612",
    country: "IT",
    country_long: "Italy",
    portCode: "ITISE",
    portName: "ISERNIA",
    portContinent: "Europe",
  },
  {
    lat: "40.7502875",
    lng: "13.8903541",
    country: "IT",
    country_long: "Italy",
    portCode: "ITLAE",
    portName: "Lacco Ameno (ITLAE), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.3498479",
    lng: "13.3995091",
    country: "IT",
    country_long: "Italy",
    portCode: "ITLAQ",
    portName: "LAQUILA",
    portContinent: "Europe",
  },
  {
    lat: "40.3515155",
    lng: "18.1750161",
    country: "IT",
    country_long: "Italy",
    portCode: "ITLCC",
    portName: "Lecce",
    portContinent: "Europe",
  },
  {
    lat: "45.8565698",
    lng: "9.397670399999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITLCO",
    portName: "Lecco",
    portContinent: "Europe",
  },
  {
    lat: "43.8429197",
    lng: "10.5026977",
    country: "IT",
    country_long: "Italy",
    portCode: "ITLCV",
    portName: "Lucca",
    portContinent: "Europe",
  },
  {
    lat: "37.9996877",
    lng: "12.3320708",
    country: "IT",
    country_long: "Italy",
    portCode: "ITLEV",
    portName: "Levanzo (ITLEV), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.1017094",
    lng: "13.9373306",
    country: "IT",
    country_long: "Italy",
    portCode: "ITLIC",
    portName: "Licata (ITLIC), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.8660738",
    lng: "12.8687414",
    country: "IT",
    country_long: "Italy",
    portCode: "ITLIU",
    portName: "Linosa (ITLIU), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.553465",
    lng: "10.3018798",
    country: "IT",
    country_long: "Italy",
    portCode: "ITLIV",
    portName: "Port of Livorno/Leghorn",
    portContinent: "Europe",
  },
  {
    lat: "35.5086218",
    lng: "12.59292",
    country: "IT",
    country_long: "Italy",
    portCode: "ITLMP",
    portName: "Lampedusa (ITLMP), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ITLTN",
    portName: "Latina",
    portContinent: "Europe",
  },
  {
    lat: "45.1564168",
    lng: "10.7913751",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMAN",
    portName: "Mantova",
    portContinent: "Europe",
  },
  {
    lat: "37.6550933",
    lng: "12.5899615",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMAZ",
    portName: "Mazara Del Vallo (ITMAZ), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.2984268",
    lng: "13.4534767",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMCA",
    portName: "MACERATA",
    portContinent: "Europe",
  },
  {
    lat: "44.0359679",
    lng: "10.0438256",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMDC",
    portName: "Marina Di Carrara (ITMDC), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.89444659999999",
    lng: "11.6082237",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMDN",
    portName: "St Maria Maddalena (ITMDN), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.6307347",
    lng: "15.9165105",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMFR",
    portName: "Manfredonia (ITMFR), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.4642035",
    lng: "9.189982",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMIL",
    portName: "MILANO",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ITMLZ",
    portName: "Melilli Oil Terminal",
    portContinent: "Europe",
  },
  {
    lat: "45.8050468",
    lng: "13.5331727",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMNF",
    portName: "Monfalcone (ITMNF), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.647128",
    lng: "10.9252269",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMOD",
    portName: "Modena",
    portContinent: "Europe",
  },
  {
    lat: "41.2027767",
    lng: "16.5987187",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMOL",
    portName: "Molfetta (ITMOL), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.7981074",
    lng: "12.4372236",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMRA",
    portName: "Marsala (ITMRA), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.7424975",
    lng: "10.2331746",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMRP",
    portName: "Marina Di Campo (ITMRP), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.03544369999999",
    lng: "10.1393221",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMSA",
    portName: "MASSA",
    portContinent: "Europe",
  },
  {
    lat: "44.2213998",
    lng: "10.0359661",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMSC",
    portName: "MASSA CARRARA",
    portContinent: "Europe",
  },
  {
    lat: "38.1894294",
    lng: "15.5574158",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMSN",
    portName: "Port of Messina",
    portContinent: "Europe",
  },
  {
    lat: "40.666379",
    lng: "16.6043199",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMTR",
    portName: "MATERA",
    portContinent: "Europe",
  },
  {
    lat: "45.6300625",
    lng: "8.7255307",
    country: "IT",
    country_long: "Italy",
    portCode: "ITMXP",
    portName: "MALPENSA",
    portContinent: "Europe",
  },
  {
    lat: "40.8517983",
    lng: "14.26812",
    country: "IT",
    country_long: "Italy",
    portCode: "ITNAP",
    portName: "Napoli",
    portContinent: "Europe",
  },
  {
    lat: "45.15155379999999",
    lng: "10.3828899",
    country: "IT",
    country_long: "Italy",
    portCode: "ITNGO",
    portName: "CANNETO SULL OGLIO",
    portContinent: "Europe",
  },
  {
    lat: "44.2070526",
    lng: "8.4141335",
    country: "IT",
    country_long: "Italy",
    portCode: "ITNLI",
    portName: "Noli (ITNLI), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.9270884",
    lng: "14.528886",
    country: "IT",
    country_long: "Italy",
    portCode: "ITNOL",
    portName: "NOLA (ITNOL), Naples, Italy",
    portContinent: "Europe",
  },
  {
    lat: "45.0312516",
    lng: "12.1553988",
    country: "IT",
    country_long: "Italy",
    portCode: "ITNPO",
    portName: "Cavanella Po",
    portContinent: "Europe",
  },
  {
    lat: "45.44692999999999",
    lng: "8.622161199999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITNVR",
    portName: "NOVARA (ITNVR), Novara, Italy",
    portContinent: "Europe",
  },
  {
    lat: "45.0714086",
    lng: "11.1338669",
    country: "IT",
    country_long: "Italy",
    portCode: "ITOGA",
    portName: "Ostiglia (ITOGA), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.8080597",
    lng: "9.0851765",
    country: "IT",
    country_long: "Italy",
    portCode: "ITOMO",
    portName: "Como",
    portContinent: "Europe",
  },
  {
    lat: "45.3117004",
    lng: "9.0191613",
    country: "IT",
    country_long: "Italy",
    portCode: "ITOPM",
    portName: "Casorate Primo",
    portContinent: "Europe",
  },
  {
    lat: "28.5982285",
    lng: "-81.3508746",
    country: "US",
    country_long: "United States",
    portCode: "ITOTA",
    portName: "PRATO",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ITPAD",
    portName: "PADAVA",
    portContinent: "Europe",
  },
  {
    lat: "45.1847248",
    lng: "9.1582069",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPAV",
    portName: "Pavia",
    portContinent: "Europe",
  },
  {
    lat: "42.7749094",
    lng: "10.3890951",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPAZ",
    portName: "Porto Azzurro (ITPAZ), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.8896348",
    lng: "16.4405872",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPCZ",
    portName: "Piacenza (ITPCZ), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.4064349",
    lng: "11.8767611",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPDA",
    portName: "PADOVA (ITPDA), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.8483773",
    lng: "12.8153949",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPE4",
    portName: "Sesto Al Reghena",
    portContinent: "Europe",
  },
  {
    lat: "43.1107168",
    lng: "12.3908279",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPEG",
    portName: "Perugia",
    portContinent: "Europe",
  },
  {
    lat: "37.2881801",
    lng: "13.5271724",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPEM",
    portName: "Porto Empedocle (ITPEM), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.9124756",
    lng: "12.9155494",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPES",
    portName: "Pesaro",
    portContinent: "Europe",
  },
  {
    lat: "42.8110525",
    lng: "10.3135253",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPFE",
    portName: "Porto Ferraio (ITPFE), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.9303475",
    lng: "10.9078587",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPIS",
    portName: "Pistoia",
    portContinent: "Europe",
  },
  {
    lat: "40.03930800000001",
    lng: "15.2909364",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPLN",
    portName: "Palinuro (ITPLN), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.4619904",
    lng: "12.2171672",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPMA",
    portName: "Marghera",
    portContinent: "Europe",
  },
  {
    lat: "44.801485",
    lng: "10.3279036",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPMF",
    portName: "Parma",
    portContinent: "Europe",
  },
  {
    lat: "38.1795458",
    lng: "13.0989603",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPMO",
    portName: "Palermo (ITPMO), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.1297613",
    lng: "14.7826208",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPNE",
    portName: "BENEVENTO",
    portContinent: "Europe",
  },
  {
    lat: "45.8326016",
    lng: "13.2077046",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPNG",
    portName: "Porto Nogaro (ITPNG), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.785262",
    lng: "11.9918779",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPNL",
    portName: "Pantelleria (ITPNL), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.87194",
    lng: "12.56738",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPO9",
    portName: "Campione (ITPO9), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.0403484",
    lng: "12.2191884",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPOQ",
    portName: "Porto Viro (ITPOQ), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.6827195",
    lng: "15.1321785",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPPL",
    portName: "Portopalo (ITPPL), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.96263980000001",
    lng: "12.6551362",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPRD",
    portName: "Pordenone",
    portContinent: "Europe",
  },
  {
    lat: "40.5532009",
    lng: "14.222154",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPRJ",
    portName: "Capri (ITPRJ), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.7578412",
    lng: "14.0150996",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPRO",
    portName: "Procida (ITPRO), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.7228386",
    lng: "10.4016888",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPSA",
    portName: "Pisa",
    portContinent: "Europe",
  },
  {
    lat: "45.43581529999999",
    lng: "9.303493399999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPSB",
    portName: "PESCHIERA BORROMEO",
    portContinent: "Europe",
  },
  {
    lat: "42.4617902",
    lng: "14.2160898",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPSR",
    portName: "Pescara (ITPSR), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.4374939",
    lng: "11.1149496",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPSS",
    portName: "Porto Santo Stefano (ITPSS), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.3031559",
    lng: "9.2097879",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPTF",
    portName: "Port of Portofino",
    portContinent: "Europe",
  },
  {
    lat: "40.8283713",
    lng: "8.3417006",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPTO",
    portName: "Porto Torres (ITPTO), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.2063711",
    lng: "8.4047163",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPVE",
    portName: "Porto Vesme (ITPVE), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.0488357",
    lng: "12.3639697",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPVT",
    portName: "Porto Levante (ITPVT), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.7944542",
    lng: "8.7838655",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPZF",
    portName: "POZZOLO FORMIGARO (ITPZF), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.7298607",
    lng: "14.8491127",
    country: "IT",
    country_long: "Italy",
    portCode: "ITPZL",
    portName: "POZZALLO (ITPZL), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.2982629",
    lng: "16.2537357",
    country: "IT",
    country_long: "Italy",
    portCode: "ITQCS",
    portName: "Cosenza",
    portContinent: "Europe",
  },
  {
    lat: "38.90979189999999",
    lng: "16.5876516",
    country: "IT",
    country_long: "Italy",
    portCode: "ITQCZ",
    portName: "Catanzaro (ITQCZ), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.0092694",
    lng: "10.1006832",
    country: "IT",
    country_long: "Italy",
    portCode: "ITQMM",
    portName: "Marina Di Massa (ITQMM), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.32024210000001",
    lng: "9.3264377",
    country: "IT",
    country_long: "Italy",
    portCode: "ITQNU",
    portName: "NUORO",
    portContinent: "Europe",
  },
  {
    lat: "39.906182",
    lng: "8.5883863",
    country: "IT",
    country_long: "Italy",
    portCode: "ITQOS",
    portName: "Oristano (ITQOS), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.6404067",
    lng: "15.8056041",
    country: "IT",
    country_long: "Italy",
    portCode: "ITQPO",
    portName: "Potenza",
    portContinent: "Europe",
  },
  {
    lat: "40.7259269",
    lng: "8.5556826",
    country: "IT",
    country_long: "Italy",
    portCode: "ITQSS",
    portName: "Sassari",
    portContinent: "Europe",
  },
  {
    lat: "44.4183598",
    lng: "12.2035294",
    country: "IT",
    country_long: "Italy",
    portCode: "ITRAN",
    portName: "Ravenna",
    portContinent: "Europe",
  },
  {
    lat: "45.8891984",
    lng: "10.8430696",
    country: "IT",
    country_long: "Italy",
    portCode: "ITRDG",
    portName: "Riva Dek Garda (ITRDG), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.3614219",
    lng: "9.1437445",
    country: "IT",
    country_long: "Italy",
    portCode: "ITREC",
    portName: "Recco (ITREC), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.123297",
    lng: "15.652206",
    country: "IT",
    country_long: "Italy",
    portCode: "ITREG",
    portName: "Port of Reggio Calabria",
    portContinent: "Europe",
  },
  {
    lat: "36.9269273",
    lng: "14.7255129",
    country: "IT",
    country_long: "Italy",
    portCode: "ITRGA",
    portName: "Ragusa",
    portContinent: "Europe",
  },
  {
    lat: "42.8201095",
    lng: "10.4227992",
    country: "IT",
    country_long: "Italy",
    portCode: "ITRMA",
    portName: "Rio Marina (ITRMA), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.0678288",
    lng: "12.5695158",
    country: "IT",
    country_long: "Italy",
    portCode: "ITRMI",
    portName: "Rimini (ITRMI), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.6989932",
    lng: "10.6296859",
    country: "IT",
    country_long: "Italy",
    portCode: "ITRNE",
    portName: "REGGIO NELL'EMILIA",
    portContinent: "Europe",
  },
  {
    lat: "41.9027835",
    lng: "12.4963655",
    country: "IT",
    country_long: "Italy",
    portCode: "ITROM",
    portName: "ROME",
    portContinent: "Europe",
  },
  {
    lat: "44.8470779",
    lng: "8.8150467",
    country: "IT",
    country_long: "Italy",
    portCode: "ITROV",
    portName: "RIVALTA SCRIVIA (ITROV), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "28.5982285",
    lng: "-81.3508746",
    country: "US",
    country_long: "United States",
    portCode: "ITRRG",
    portName: "Prato",
    portContinent: "Europe",
  },
  {
    lat: "42.404509",
    lng: "12.8567281",
    country: "IT",
    country_long: "Italy",
    portCode: "ITRTI",
    portName: "Rieti",
    portContinent: "Europe",
  },
  {
    lat: "44.2599872",
    lng: "9.4227486",
    country: "IT",
    country_long: "Italy",
    portCode: "ITRTS",
    portName: "Riva Trigoso (ITRTS), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.6529827",
    lng: "10.7806381",
    country: "IT",
    country_long: "Italy",
    portCode: "ITRUB",
    portName: "RUBIERA (ITRUB), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.0698118",
    lng: "11.7902158",
    country: "IT",
    country_long: "Italy",
    portCode: "ITRVO",
    portName: "Rovigo",
    portContinent: "Europe",
  },
  {
    lat: "40.68244079999999",
    lng: "14.7680961",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSAL",
    portName: "Salerno",
    portContinent: "Europe",
  },
  {
    lat: "42.9609785",
    lng: "13.8746469",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSDB",
    portName: "San Benedetto Del Tr (ITSDB), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.7307015",
    lng: "8.636597199999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSEE",
    portName: "Sesto Calende",
    portContinent: "Europe",
  },
  {
    lat: "43.7197926",
    lng: "13.2152224",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSEG",
    portName: "Senigallia (ITSEG), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.5999938",
    lng: "14.0153557",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSIC",
    portName: "Sicily",
    portContinent: "Europe",
  },
  {
    lat: "37.0908343",
    lng: "15.2758481",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSIR",
    portName: "Port of Siracusa",
    portContinent: "Europe",
  },
  {
    lat: "38.5674187",
    lng: "14.8337879",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSLA",
    portName: "Salina (ITSLA), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.2512005",
    lng: "15.7180247",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSLL",
    portName: "Scilla (ITSLL), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.0878487",
    lng: "16.370611",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSLN",
    portName: "Soveria Mannelli (ITSLN), Naples, Italy",
    portContinent: "Europe",
  },
  {
    lat: "43.31880899999999",
    lng: "11.3307574",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSNA",
    portName: "Siena",
    portContinent: "Europe",
  },
  {
    lat: "46.1698583",
    lng: "9.8787674",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSND",
    portName: "Sondrio",
    portContinent: "Europe",
  },
  {
    lat: "38.1514581",
    lng: "13.082736",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSNR",
    portName: "Terrasini (ITSNR), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.101111",
    lng: "15.2702961",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSPA",
    portName: "Santa Panagia (ITSPA), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ITSPE",
    portName: "Port of La Spezia",
    portContinent: "Europe",
  },
  {
    lat: "43.81596709999999",
    lng: "7.7760567",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSRE",
    portName: "Port of Sanremo",
    portContinent: "Europe",
  },
  {
    lat: "45.252308",
    lng: "11.3132704",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSSF",
    portName: "Santo Stefano (ITSSF), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.5328245",
    lng: "9.2256875",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSSG",
    portName: "Sesto San Giovanni",
    portContinent: "Europe",
  },
  {
    lat: "41.238987",
    lng: "9.1880794",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSTE",
    portName: "Santa Teresa Gallura (ITSTE), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.8320966",
    lng: "11.2042694",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSTF",
    portName: "Sesto Fiorentino",
    portContinent: "Europe",
  },
  {
    lat: "45.3946945",
    lng: "9.2462404",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSUL",
    portName: "Sesto Ulteriano",
    portContinent: "Europe",
  },
  {
    lat: "44.30975189999999",
    lng: "8.4882487",
    country: "IT",
    country_long: "Italy",
    portCode: "ITSVN",
    portName: "Port of Savona",
    portContinent: "Europe",
  },
  {
    lat: "40.478639",
    lng: "17.219975",
    country: "IT",
    country_long: "Italy",
    portCode: "ITTAR",
    portName: "Port of Taranto",
    portContinent: "Europe",
  },
  {
    lat: "40.7825918",
    lng: "14.4141114",
    country: "IT",
    country_long: "Italy",
    portCode: "ITTDG",
    portName: "Port of Torre del Greco",
    portContinent: "Europe",
  },
  {
    lat: "42.6611431",
    lng: "13.6986639",
    country: "IT",
    country_long: "Italy",
    portCode: "ITTEO",
    portName: "Teramo",
    portContinent: "Europe",
  },
  {
    lat: "42.5636168",
    lng: "12.6426604",
    country: "IT",
    country_long: "Italy",
    portCode: "ITTER",
    portName: "Terni",
    portContinent: "Europe",
  },
  {
    lat: "45.005969",
    lng: "12.2080923",
    country: "IT",
    country_long: "Italy",
    portCode: "ITTLP",
    portName: "Taglio Di Po (ITTLP), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.7540553",
    lng: "14.4509125",
    country: "IT",
    country_long: "Italy",
    portCode: "ITTOA",
    portName: "Torre Annunziata (ITTOA), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.0135338",
    lng: "12.5110379",
    country: "IT",
    country_long: "Italy",
    portCode: "ITTPS",
    portName: "Port of Trapani",
    portContinent: "Europe",
  },
  {
    lat: "37.984023",
    lng: "13.6961892",
    country: "IT",
    country_long: "Italy",
    portCode: "ITTRI",
    portName: "Termini Imerese (ITTRI), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.0703393",
    lng: "7.686864",
    country: "IT",
    country_long: "Italy",
    portCode: "ITTRN",
    portName: "Torino",
    portContinent: "Europe",
  },
  {
    lat: "45.6495264",
    lng: "13.7768182",
    country: "IT",
    country_long: "Italy",
    portCode: "ITTRS",
    portName: "Trieste",
    portContinent: "Europe",
  },
  {
    lat: "46.0747793",
    lng: "11.1217486",
    country: "IT",
    country_long: "Italy",
    portCode: "ITTRT",
    portName: "Trento",
    portContinent: "Europe",
  },
  {
    lat: "45.6668893",
    lng: "12.2430437",
    country: "IT",
    country_long: "Italy",
    portCode: "ITTRV",
    portName: "Treviso",
    portContinent: "Europe",
  },
  {
    lat: "46.0710668",
    lng: "13.2345794",
    country: "IT",
    country_long: "Italy",
    portCode: "ITUDN",
    portName: "Udine",
    portContinent: "Europe",
  },
  {
    lat: "45.60315430000001",
    lng: "13.7667974",
    country: "IT",
    country_long: "Italy",
    portCode: "ITUGG",
    portName: "Muggia (ITUGG), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.2587293",
    lng: "13.6988924",
    country: "IT",
    country_long: "Italy",
    portCode: "ITURC",
    portName: "Scauri Alto (ITURC), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.82059890000001",
    lng: "8.8250576",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVAR",
    portName: "Varese",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ITVCE",
    portName: "Port of Venice",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ITVDA",
    portName: "Vada Harbor",
    portContinent: "Europe",
  },
  {
    lat: "44.2647844",
    lng: "8.432131",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVDL",
    portName: "Port of Vado Ligure",
    portContinent: "Europe",
  },
  {
    lat: "43.7898216",
    lng: "7.598656099999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVEN",
    portName: "Cala Del Forte (ITVEN), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.8657267",
    lng: "10.2513103",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVIA",
    portName: "Viareggio (ITVIA), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.5454787",
    lng: "11.5354214",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVIC",
    portName: "Vicenza",
    portContinent: "Europe",
  },
  {
    lat: "36.9520998",
    lng: "14.5372653",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVIH",
    portName: "Vittoria",
    portContinent: "Europe",
  },
  {
    lat: "42.4206766",
    lng: "12.107669",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVIT",
    portName: "Viterbo",
    portContinent: "Europe",
  },
  {
    lat: "45.32022720000001",
    lng: "8.418573499999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVRL",
    portName: "VERCELLI",
    portContinent: "Europe",
  },
  {
    lat: "45.43838419999999",
    lng: "10.9916215",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVRN",
    portName: "VERONA (ITVRN), Verona, Italy",
    portContinent: "Europe",
  },
  {
    lat: "44.3594336",
    lng: "8.577312599999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVRZ",
    portName: "Varazze (ITVRZ), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.21674580000001",
    lng: "15.6362381",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVSG",
    portName: "Villa San Giovanni (ITVSG), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.1045588",
    lng: "14.7058711",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVSO",
    portName: "Vasto (ITVSO), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.6757774",
    lng: "16.0983488",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVVA",
    portName: "Port of Vibo Valentia",
    portContinent: "Europe",
  },
  {
    lat: "45.6424642",
    lng: "10.5005307",
    country: "IT",
    country_long: "Italy",
    portCode: "ITVVV",
    portName: "Vobarno",
    portContinent: "Europe",
  },
  {
    lat: "45.6741436",
    lng: "8.740076199999999",
    country: "IT",
    country_long: "Italy",
    portCode: "ITZHA",
    portName: "Casorate Sempione",
    portContinent: "Europe",
  },
  {
    lat: "45.4969717",
    lng: "10.6053754",
    country: "IT",
    country_long: "Italy",
    portCode: "ITZTI",
    portName: "Sirmione (ITZTI), Italy, Med",
    portContinent: "Europe",
  },
  {
    lat: "17.874371",
    lng: "-77.562241",
    country: "JM",
    country_long: "Jamaica",
    portCode: "JMALP",
    portName: "Alligator Pond (JMALP), Jamaica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.4928457",
    lng: "-77.656476",
    country: "JM",
    country_long: "Jamaica",
    portCode: "JMFMH",
    portName: "Falmouth (JMFMH), Jamaica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "47.7960842",
    lng: "-122.4964845",
    country: "US",
    country_long: "United States",
    portCode: "JMKIN",
    portName: "Port of Kingston",
    portContinent: "North America",
  },
  {
    lat: "18.4504186",
    lng: "-78.17639369999999",
    country: "JM",
    country_long: "Jamaica",
    portCode: "JMLUC",
    portName: "Lucea (JMLUC), Jamaica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.4762228",
    lng: "-77.8938895",
    country: "JM",
    country_long: "Jamaica",
    portCode: "JMMBJ",
    portName: "Montego Bay (JMMBJ), Jamaica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.4034755",
    lng: "-76.938379",
    country: "JM",
    country_long: "Jamaica",
    portCode: "JMORC",
    portName: "Oracabessa (JMORC), Jamaica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.109581",
    lng: "-77.297508",
    country: "JM",
    country_long: "Jamaica",
    portCode: "JMPEV",
    portName: "Esquivel (JMPEV), Jamaica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.1712764",
    lng: "-76.44763209999999",
    country: "JM",
    country_long: "Jamaica",
    portCode: "JMPOT",
    portName: "Port Antonio (JMPOT), Jamaica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.4611634",
    lng: "-77.4111701",
    country: "JM",
    country_long: "Jamaica",
    portCode: "JMPRH",
    portName: "Port Rhoades (JMPRH), Jamaica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.9367669",
    lng: "-76.8411038",
    country: "JM",
    country_long: "Jamaica",
    portCode: "JMPRO",
    portName: "Port Royal (JMPRO), Jamaica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.4744708",
    lng: "-77.46732899999999",
    country: "JM",
    country_long: "Jamaica",
    portCode: "JMRIB",
    portName: "Rio Bueno (JMRIB), Jamaica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.2291677",
    lng: "-78.1393397",
    country: "JM",
    country_long: "Jamaica",
    portCode: "JMSLM",
    portName: "Savanna La Mar (JMSLM), Jamaica, LatAm",
    portContinent: "North America",
  },
  {
    lat: "33.60175964155174",
    lng: "-111.2727571007847",
    country: "US",
    country_long: "United States",
    portCode: "JMSRI",
    portName: "Salt River",
    portContinent: "North America",
  },
  {
    lat: "13.0033234",
    lng: "76.1003894",
    country: "IN",
    country_long: "India",
    portCode: "JOAHI",
    portName: "AL HASSAN IND.",
    portContinent: "Asia",
  },
  {
    lat: "31.9539494",
    lng: "35.910635",
    country: "JO",
    country_long: "Jordan",
    portCode: "JOAMM",
    portName: "Amman",
    portContinent: "Asia",
  },
  {
    lat: "29.5320522",
    lng: "35.0063209",
    country: "JO",
    country_long: "Jordan",
    portCode: "JOAQB",
    portName: "AQABA",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "JOAQF",
    portName: "AL QASTEL FREE ZONE",
    portContinent: "Asia",
  },
  {
    lat: "29.5320522",
    lng: "35.0063209",
    country: "JO",
    country_long: "Jordan",
    portCode: "JOAQJ",
    portName: "Al'Aqabah",
    portContinent: "Asia",
  },
  {
    lat: "25.3323584",
    lng: "55.4831661",
    country: "AE",
    country_long: "United Arab Emirates",
    portCode: "JOASF",
    portName: "AL SHAIDEYAH FREE ZONE",
    portContinent: "Asia",
  },
  {
    lat: "31.9492135",
    lng: "35.9055451",
    country: "JO",
    country_long: "Jordan",
    portCode: "JODUL",
    portName: "Dulail QIZ",
    portContinent: "Asia",
  },
  {
    lat: "32.341673",
    lng: "36.2020028",
    country: "JO",
    country_long: "Jordan",
    portCode: "JOOMF",
    portName: "Al Mafraq",
    portContinent: "Asia",
  },
  {
    lat: "32.5568095",
    lng: "35.846887",
    country: "JO",
    country_long: "Jordan",
    portCode: "JOQIR",
    portName: "Irbid",
    portContinent: "Asia",
  },
  {
    lat: "31.8694268",
    lng: "36.0064582",
    country: "JO",
    country_long: "Jordan",
    portCode: "JOSAH",
    portName: "Sahab",
    portContinent: "Asia",
  },
  {
    lat: "32.0870816",
    lng: "36.21311439999999",
    country: "JO",
    country_long: "Jordan",
    portCode: "JOZAR",
    portName: "ZARKA FREE ZONE",
    portContinent: "Asia",
  },
  {
    lat: "32.0870816",
    lng: "36.21311439999999",
    country: "JO",
    country_long: "Jordan",
    portCode: "JOZFZ",
    portName: "Zarka Free Zone",
    portContinent: "Asia",
  },
  {
    lat: "31.5830728",
    lng: "131.4055785",
    country: "JP",
    country_long: "Japan",
    portCode: "JPABU",
    portName: "ABURATSU",
    portContinent: "Asia",
  },
  {
    lat: "35.4827827",
    lng: "139.6146102",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAFG",
    portName: "Akitafunagawa (JPAFG), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAII",
    portName: "Aio (JPAII), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.803968",
    lng: "134.4683474",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAIO",
    portName: "Aioi (JPAIO), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "40.9604366",
    lng: "140.4338661",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAKD",
    portName: "Akadomari (JPAKD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "43.0519506",
    lng: "144.8473693",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAKE",
    portName: "Akkeshi (JPAKE), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAKI",
    portName: "Aikakita (JPAKI), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.0963896",
    lng: "139.071784",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAMI",
    portName: "Atami (JPAMI), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.0391525",
    lng: "138.1140545",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAMM",
    portName: "Suwa (JPAMM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAMU",
    portName: "Amura (JPAMU), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAMX",
    portName: "Amagasakinishinomiya (JPAMX), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAMY",
    portName: "Amiya (JPAMY), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.7503334",
    lng: "129.8778888",
    country: "JP",
    country_long: "Japan",
    portCode: "JPANE",
    portName: "Anegasaki (JPANE), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.7357118",
    lng: "132.4816635",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAOS",
    portName: "Aoshima (JPAOS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.8230704",
    lng: "138.7768965",
    country: "JP",
    country_long: "Japan",
    portCode: "JPARA",
    portName: "Arari (JPARA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.5134176",
    lng: "133.6399307",
    country: "JP",
    country_long: "Japan",
    portCode: "JPASK",
    portName: "Akasaki (JPASK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.726954",
    lng: "135.3041271",
    country: "JP",
    country_long: "Japan",
    portCode: "JPASZ",
    portName: "Ashiya (JPASZ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.6367515",
    lng: "139.6020523",
    country: "JP",
    country_long: "Japan",
    portCode: "JPATM",
    portName: "Atsumi (JPATM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.4637934",
    lng: "139.2382176",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAWS",
    portName: "Awashima (JPAWS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "JPAXT",
    portName: "Akita",
    portContinent: "Asia",
  },
  {
    lat: "33.7345537",
    lng: "135.5157676",
    country: "JP",
    country_long: "Japan",
    portCode: "JPAYU",
    portName: "Ayukawa (JPAYU), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.2844614",
    lng: "131.4907093",
    country: "JP",
    country_long: "Japan",
    portCode: "JPBEP",
    portName: "Beppu (JPBEP), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.600715",
    lng: "140.1062566",
    country: "JP",
    country_long: "Japan",
    portCode: "JPCHB",
    portName: "Port of Chiba",
    portContinent: "Asia",
  },
  {
    lat: "35.3338221",
    lng: "139.4036897",
    country: "JP",
    country_long: "Japan",
    portCode: "JPCHG",
    portName: "Chigasaki (JPCHG), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.6762552",
    lng: "134.9230002",
    country: "JP",
    country_long: "Japan",
    portCode: "JPEGS",
    portName: "Eigashima (JPEGS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.4492931",
    lng: "137.4128453",
    country: "JP",
    country_long: "Japan",
    portCode: "JPENA",
    portName: "Ena (JPENA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.5277225",
    lng: "132.9745309",
    country: "JP",
    country_long: "Japan",
    portCode: "JPETM",
    portName: "Etomo (JPETM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "40.6561385",
    lng: "140.5028078",
    country: "JP",
    country_long: "Japan",
    portCode: "JPFGS",
    portName: "FUGUSAKI (JPFGS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.5307238",
    lng: "134.3378462",
    country: "JP",
    country_long: "Japan",
    portCode: "JPFKD",
    portName: "Fukuda (JPFKD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.7018615",
    lng: "128.7577605",
    country: "JP",
    country_long: "Japan",
    portCode: "JPFKE",
    portName: "Fukue (JPFKE), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.0641386",
    lng: "136.219623",
    country: "JP",
    country_long: "Japan",
    portCode: "JPFKJ",
    portName: "Fukui (JPFKJ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.3839903",
    lng: "129.8218953",
    country: "JP",
    country_long: "Japan",
    portCode: "JPFKM",
    portName: "Fukushima (Nagasaki) (JPFKM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "41.4833932",
    lng: "140.2514294",
    country: "JP",
    country_long: "Japan",
    portCode: "JPFKU",
    portName: "Fukushima (Hokkaido) (JPFKU), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.4859845",
    lng: "133.3624239",
    country: "JP",
    country_long: "Japan",
    portCode: "JPFKY",
    portName: "FUKUYAMA, HIROSHIMA (JPFKY), Hiroshima, Japan",
    portContinent: "Asia",
  },
  {
    lat: "35.6945485",
    lng: "139.9827277",
    country: "JP",
    country_long: "Japan",
    portCode: "JPFNB",
    portName: "Port of Funabashi",
    portContinent: "Asia",
  },
  {
    lat: "45.4156923",
    lng: "141.0182915",
    country: "JP",
    country_long: "Japan",
    portCode: "JPFND",
    portName: "Funadomari (JPFND), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "39.9041497",
    lng: "139.9438054",
    country: "JP",
    country_long: "Japan",
    portCode: "JPFNS",
    portName: "Funakoshi (JPFNS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.5901838",
    lng: "130.4016888",
    country: "JP",
    country_long: "Japan",
    portCode: "JPFUK",
    portName: "Fukuoka",
    portContinent: "Asia",
  },
  {
    lat: "34.8259551",
    lng: "137.2197862",
    country: "JP",
    country_long: "Japan",
    portCode: "JPGAM",
    portName: "Gamagori (JPGAM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.965664",
    lng: "138.7944857",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHAD",
    portName: "Heda (JPHAD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.3199066",
    lng: "136.7033655",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHAH",
    portName: "Hashima (JPHAH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.8798679",
    lng: "130.603654",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHBI",
    portName: "Hibikinada",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "JPHBK",
    portName: "HIBIKISHINKO",
    portContinent: "Asia",
  },
  {
    lat: "34.2279008",
    lng: "134.3945656",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHEA",
    portName: "Hiketa (JPHEA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.7729073",
    lng: "139.0413137",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHGH",
    portName: "Higashihazu (JPHGH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.4264138",
    lng: "132.7433115",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHGR",
    portName: "Higashihiroshima",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHGU",
    portName: "Hirugaura (JPHGU), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "40.5122055",
    lng: "141.4882743",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHHE",
    portName: "Hachinohe (JPHHE), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.7152468",
    lng: "134.8680023",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHHR",
    portName: "Higashiharima (JPHHR), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHIB",
    portName: "Hibi (JPHIB), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.39665060000001",
    lng: "140.5347934",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHIC",
    portName: "Hitachinaka (JPHIC), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.3852894",
    lng: "132.4553055",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHIJ",
    portName: "Port of Hiroshima",
    portContinent: "Asia",
  },
  {
    lat: "34.8154176",
    lng: "134.6855509",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHIM",
    portName: "Himeji (JPHIM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.7258104",
    lng: "134.2621146",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHIN",
    portName: "Hinase (JPHIN), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.3352157",
    lng: "139.3494949",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHIU",
    portName: "Hiratsuka (JPHIU), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHJO",
    portName: "Hojo (JPHJO), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "41.7686961",
    lng: "140.7290599",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHKD",
    portName: "HAKODATE (JPHKD), Hakodate, Japan",
    portContinent: "Asia",
  },
  {
    lat: "33.9618201",
    lng: "131.9422491",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHKR",
    portName: "Hikari (JPHKR), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHKS",
    portName: "Hakatashima (JPHKS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.606533",
    lng: "130.405531",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHKT",
    portName: "Hakata (JPHKT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHMC",
    portName: "Hamochi (JPHMC), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.8993937",
    lng: "132.0802638",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHMD",
    portName: "Hamada (JPHMD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.7244961",
    lng: "131.6453198",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHMH",
    portName: "Himeshima (JPHMH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.70950759999999",
    lng: "135.5830017",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHMM",
    portName: "Hama (JPHMM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.7470525",
    lng: "137.6290693",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHMN",
    portName: "Hamana (JPHMN), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.1102606",
    lng: "139.6235333",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHNK",
    portName: "Hanasaki (JPHNK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "40.8222197",
    lng: "140.7473524",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHOA",
    portName: "AOMORI",
    portContinent: "Asia",
  },
  {
    lat: "34.0517251",
    lng: "131.5627309",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHOF",
    portName: "Hofu (JPHOF), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.8579518",
    lng: "134.5453787",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHOG",
    portName: "Hyogo-ken",
    portContinent: "Asia",
  },
  {
    lat: "43.2203266",
    lng: "142.8634737",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHOO",
    portName: "HOKKAIDO",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHRH",
    portName: "Hirahata (JPHRH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHRM",
    portName: "Haramachi (JPHRM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.2631179",
    lng: "130.3009057",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHSG",
    portName: "Saga (JPHSG), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHSH",
    portName: "Hashi (JPHSH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.4230672",
    lng: "131.6722509",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHSM",
    portName: "Hososhima (JPHSM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.1425805",
    lng: "140.9956828",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHSN",
    portName: "Hisanohama (JPHSN), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.59912730000001",
    lng: "140.6504712",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHTC",
    portName: "Hitachi (JPHTC), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.5903415",
    lng: "130.4414465",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHTD",
    portName: "Hakata",
    portContinent: "Asia",
  },
  {
    lat: "34.6539413",
    lng: "129.4579726",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHTK",
    portName: "Hitakatsu (JPHTK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "24.4728533",
    lng: "123.8215894",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHTO",
    portName: "Hatoma (JPHTO), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHUD",
    portName: "Hoda (JPHUD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.9790381",
    lng: "138.2571348",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHUT",
    portName: "Futami (JPHUT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.233089",
    lng: "132.522762",
    country: "JP",
    country_long: "Japan",
    portCode: "JPHYR",
    portName: "Hyakunoura (JPHYR), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.2869435",
    lng: "140.4245362",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIAK",
    portName: "Ibaraki",
    portContinent: "Asia",
  },
  {
    lat: "35.4978821",
    lng: "140.1148672",
    country: "JP",
    country_long: "Japan",
    portCode: "JPICH",
    portName: "Ichihara (JPICH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.7145684",
    lng: "130.2720139",
    country: "JP",
    country_long: "Japan",
    portCode: "JPICO",
    portName: "Ichikikushikino (JPICO), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "JPICW",
    portName: "Ishikariwan Shinko",
    portContinent: "Asia",
  },
  {
    lat: "35.51487",
    lng: "137.8217006",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIDA",
    portName: "Iida (JPIDA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.9602876",
    lng: "133.2833918",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIHA",
    portName: "Niihama (JPIHA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIHO",
    portName: "Ihota (JPIHO), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.8215568",
    lng: "135.4283797",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIKA",
    portName: "Ikeda (JPIKA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.3858621",
    lng: "129.419131",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIKK",
    portName: "Ikitsuki (JPIKK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.4886038",
    lng: "132.3542025",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIKT",
    portName: "Ikata (JPIKT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.0662024",
    lng: "132.9977906",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIMB",
    portName: "Imabari (JPIMB), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.2650014",
    lng: "129.8804221",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIMI",
    portName: "Imari (JPIMI), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIMM",
    portName: "Imi (JPIMM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.9372942",
    lng: "135.6869899",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIMT",
    portName: "Imazato (JPIMT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.7847339",
    lng: "139.0261692",
    country: "JP",
    country_long: "Japan",
    portCode: "JPINR",
    portName: "Inatori (JPINR), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "24.3959337",
    lng: "124.2457883",
    country: "JP",
    country_long: "Japan",
    portCode: "JPISG",
    portName: "Ishigaki (JPISG), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "43.1714217",
    lng: "141.3155371",
    country: "JP",
    country_long: "Japan",
    portCode: "JPISI",
    portName: "ISHIKARI (JPISI), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.434332",
    lng: "141.3028682",
    country: "JP",
    country_long: "Japan",
    portCode: "JPISM",
    portName: "Ishinomaki (JPISM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.3872963",
    lng: "141.0804719",
    country: "JP",
    country_long: "Japan",
    portCode: "JPISX",
    portName: "Isozaki (JPISX), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.0390497",
    lng: "137.862814",
    country: "JP",
    country_long: "Japan",
    portCode: "JPITW",
    portName: "Itoigawa (JPITW), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.896957",
    lng: "129.988934",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIWA",
    portName: "Iwamatsu (JPIWA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.1667451",
    lng: "132.2191641",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIWK",
    portName: "Iwakuni (JPIWK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.9804152",
    lng: "133.5490295",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIYM",
    portName: "IYOMISHIMA (JPIYM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.88749749999999",
    lng: "135.5134103",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIZH",
    portName: "Izuhara (JPIZH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIZM",
    portName: "Izumi (JPIZM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.2481406",
    lng: "136.7802427",
    country: "JP",
    country_long: "Japan",
    portCode: "JPIZW",
    portName: "Inazawa (JPIZW), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.4947725",
    lng: "133.6924155",
    country: "JP",
    country_long: "Japan",
    portCode: "JPJKT",
    portName: "Kotoura (JPJKT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.1478952",
    lng: "138.236135",
    country: "JP",
    country_long: "Japan",
    portCode: "JPJTS",
    portName: "Joetsu (JPJTS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.2714293",
    lng: "135.0793351",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKAA",
    portName: "Kada (JPKAA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKAC",
    portName: "Kasaura (JPKAC), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.4500971",
    lng: "129.9682676",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKAR",
    portName: "Karatsu (JPKAR), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.0490693",
    lng: "140.3791577",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKAU",
    portName: "Kasumigaura (JPKAU), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.0026262",
    lng: "131.8774582",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKAZ",
    portName: "Kaizaki (JPKAZ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "45.3555318",
    lng: "141.0333773",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKBK",
    portName: "Kafuka (JPKBK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.6833736",
    lng: "139.6522588",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKBT",
    portName: "Kobato (JPKBT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.5475515",
    lng: "133.6742092",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKCZ",
    portName: "Kochi (JPKCZ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKEC",
    portName: "Kechi (JPKEC), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.7569193",
    lng: "134.84136",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKGA",
    portName: "Kakogawa (JPKGA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKGE",
    portName: "Kumage (JPKGE), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.7287624",
    lng: "130.4700958",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKGX",
    portName: "Koga (JPKGX), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.9161244",
    lng: "139.0363708",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKIJ",
    portName: "Niigata (JPKIJ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.53767759999999",
    lng: "140.1395164",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKIK",
    portName: "Kikuma (JPKIK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKIN",
    portName: "Kinwan (JPKIN), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "39.9645564",
    lng: "139.7834306",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKIR",
    portName: "Kitaura (JPKIR), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "39.27580460000001",
    lng: "141.8857275",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKIS",
    portName: "Kamaishi (JPKIS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.8834976",
    lng: "130.8751773",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKKJ",
    portName: "Port of Kitakyushu",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKKM",
    portName: "Katakami (JPKKM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.7407317",
    lng: "130.7633282",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKKX",
    portName: "Kikaishima (JPKKX), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKMD",
    portName: "Komoda (JPKMD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKME",
    portName: "Kamae (JPKME), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "JPKMI",
    portName: "Miyazaki",
    portContinent: "Asia",
  },
  {
    lat: "32.8032164",
    lng: "130.7079369",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKMJ",
    portName: "Kumamoto (JPKMJ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.6663153",
    lng: "139.0402073",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKMO",
    portName: "Kamo (JPKMO), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.6954378",
    lng: "139.7675549",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKND",
    portName: "Kanda (JPKND), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKNH",
    portName: "Kunehama (JPKNH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKNN",
    portName: "Kin (JPKNN), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.5632285",
    lng: "131.7319983",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKNS",
    portName: "Kuniasaki (JPKNS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKNU",
    portName: "Kinuura (JPKNU), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "26.2671905",
    lng: "127.7910006",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKNX",
    portName: "Nakagususku (JPKNX), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "28.1575649",
    lng: "129.3180297",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKNY",
    portName: "Koniya (JPKNY), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.5610309",
    lng: "136.6566475",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKNZ",
    portName: "Kanazawa (JPKNZ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "10.8683349",
    lng: "13.8825012",
    country: "CM",
    country_long: "Cameroon",
    portCode: "JPKOB",
    portName: "KOZA",
    portContinent: "Asia",
  },
  {
    lat: "24.3466078",
    lng: "123.9805756",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKOH",
    portName: "Kohama (JPKOH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.5968539",
    lng: "130.5571392",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKOJ",
    portName: "Kagoshima (JPKOJ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "JPKOK",
    portName: "Port of Kokura",
    portContinent: "Asia",
  },
  {
    lat: "34.0047412",
    lng: "134.5907301",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKOM",
    portName: "Komatsushima (JPKOM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKOR",
    portName: "Koura (JPKOR), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKRB",
    portName: "Karubi (JPKRB), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.7719864",
    lng: "137.1751527",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKRC",
    portName: "Kuramai (JPKRC), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.2489485",
    lng: "132.5655271",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKRE",
    portName: "Kure (JPKRE), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.2138991",
    lng: "139.7192223",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKRH",
    portName: "Kurihama (JPKRH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "30.3038208",
    lng: "130.4414465",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKRU",
    portName: "Kurii (JPKRU), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.5071564",
    lng: "133.5073882",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKSA",
    portName: "Kasaoka (JPKSA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.8814174",
    lng: "137.2099714",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKSB",
    portName: "Kusubo (JPKSB), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKSE",
    portName: "Kamoise (JPKSE), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.9656835",
    lng: "140.6447937",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKSM",
    portName: "Kashima (JPKSM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.1946076",
    lng: "130.9687836",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKSR",
    portName: "Kishira (JPKSR), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.9796072",
    lng: "134.322473",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKSX",
    portName: "Kosuki (JPKSX), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKTG",
    portName: "Kutsugata (JPKTG), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKTK",
    portName: "Kataku (JPKTK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "39.2867738",
    lng: "141.1131535",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKTN",
    portName: "Kitakami (JPKTN), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.0138304",
    lng: "131.8695466",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKUD",
    portName: "Kudamatsu (JPKUD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.09004710000001",
    lng: "132.0880724",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKUG",
    portName: "Kugui (JPKUG), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "42.9849413",
    lng: "144.381804",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKUH",
    portName: "Kushiro (JPKUH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKWI",
    portName: "Kawanoishi (JPKWI), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "43.1800349",
    lng: "141.4862698",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKWM",
    portName: "Kawashimo (JPKWM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.5308325",
    lng: "139.7029125",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKWS",
    portName: "Kawasaki (JPKWS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.7773743",
    lng: "136.9004122",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKWX",
    portName: "Kowa (JPKWX), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.371918",
    lng: "138.5590073",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKWZ",
    portName: "Kashiwazaki (JPKWZ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.3782477",
    lng: "130.8522618",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKYA",
    portName: "Kanoya (JPKYA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.8792471",
    lng: "132.7369299",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKZK",
    portName: "Kozukushi (JPKZK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.38730510000001",
    lng: "139.9144368",
    country: "JP",
    country_long: "Japan",
    portCode: "JPKZU",
    portName: "Port of Kisarazu",
    portContinent: "Asia",
  },
  {
    lat: "35.4747759",
    lng: "135.3861501",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMAI",
    portName: "Maizuru (JPMAI), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.2893547",
    lng: "133.797643",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMAR",
    portName: "Marugame (JPMAR), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMGU",
    portName: "Magusa (JPMGU), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.5676935",
    lng: "133.3053178",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMIH",
    portName: "Mihonoseki (JPMIH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMII",
    portName: "Miike (JPMII), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.637423",
    lng: "136.9056142",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMIZ",
    portName: "Mizushima (JPMIZ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.3938003",
    lng: "135.291153",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMJR",
    portName: "Mitajiri (JPMJR), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.2728662",
    lng: "130.2966917",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMKK",
    portName: "Makurazaki (JPMKK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.7945327",
    lng: "139.8496062",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMKW",
    portName: "Mikawa (JPMKW), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.1184912",
    lng: "138.9184977",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMKX",
    portName: "Mishima (JPMKX), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.828778",
    lng: "131.2321399",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMMD",
    portName: "Minamidaito (JPMMD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.7828143",
    lng: "131.9741954",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMMI",
    portName: "Misumi (JPMMI), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMMT",
    portName: "Mimitsu (JPMMT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "24.7673666",
    lng: "125.3246769",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMMY",
    portName: "Miyakojima (JPMMY), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.9032695",
    lng: "130.9687836",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMOJ",
    portName: "Moji/Kitakyushu",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMSX",
    portName: "Misaki (JPMSX), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.5780574",
    lng: "135.5517138",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMTB",
    portName: "Matsubara (JPMTB), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "42.3151792",
    lng: "140.9737666",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMUR",
    portName: "Muroran (JPMUR), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.647202",
    lng: "139.7409333",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMXT",
    portName: "Mita (JPMXT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.630612",
    lng: "141.1193048",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMYI",
    portName: "Miyagi (JPMYI), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.8393515",
    lng: "132.7653057",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMYJ",
    portName: "Matsuyama (JPMYJ), Matsuyama, Japan",
    portContinent: "Asia",
  },
  {
    lat: "39.6394645",
    lng: "141.9462611",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMYK",
    portName: "Miyako (JPMYK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPMZS",
    portName: "Mizusaki (JPMZS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.3806116",
    lng: "136.2783094",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNAG",
    portName: "Nagahama (JPNAG), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "26.2125758",
    lng: "127.6790208",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNAH",
    portName: "Naha (JPNAH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.4043476",
    lng: "141.1677836",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNAN",
    portName: "NAKANOSEKI (JPNAN), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.1702232",
    lng: "138.2420864",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNAO",
    portName: "Naoetsu (JPNAO), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.4597802",
    lng: "133.9956414",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNAS",
    portName: "Naoshima (JPNAS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNAZ",
    portName: "Naze (JPNAZ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.5823201",
    lng: "131.6651407",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNBS",
    portName: "Nobeokashinko (JPNBS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.2123197",
    lng: "130.6677418",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNEJ",
    portName: "Nejime (JPNEJ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "43.3301005",
    lng: "145.5828182",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNEM",
    portName: "Nemuro (JPNEM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.5497044",
    lng: "139.5728531",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNEZ",
    portName: "Nezugaseki (JPNEZ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.18145060000001",
    lng: "136.9065571",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNGO",
    portName: "Nagoya, Aichi",
    portContinent: "Asia",
  },
  {
    lat: "32.74567620000001",
    lng: "129.8684394",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNGS",
    portName: "Port of Nagasaki",
    portContinent: "Asia",
  },
  {
    lat: "40.86446129999999",
    lng: "141.1287144",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNHJ",
    portName: "Noheji (JPNHJ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "39.2030128",
    lng: "139.9077246",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNIK",
    portName: "Nikaho (JPNIK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.2023821",
    lng: "133.651925",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNIO",
    portName: "Nio (JPNIO), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.1209813",
    lng: "132.9538608",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNIT",
    portName: "Namikata (JPNIT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.434254",
    lng: "134.8809459",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNKD",
    portName: "Nakada (JPNKD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "21.416778",
    lng: "-157.8051015",
    country: "US",
    country_long: "United States",
    portCode: "JPNKO",
    portName: "NANKO",
    portContinent: "Asia",
  },
  {
    lat: "34.6917217",
    lng: "135.4910214",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNKS",
    portName: "Nakanoshima (JPNKS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNKX",
    portName: "Nakanosaku (JPNKX), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.5823201",
    lng: "131.6651407",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNOB",
    portName: "Nobeoka (JPNOB), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.7766588",
    lng: "140.3187418",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNRI",
    portName: "NARITA (CHIBA)",
    portContinent: "Asia",
  },
  {
    lat: "37.4912143",
    lng: "139.6547895",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNST",
    portName: "Nishikata (JPNST), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.0956068",
    lng: "138.8635399",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNUM",
    portName: "Numazu (JPNUM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.7662504",
    lng: "135.6280175",
    country: "JP",
    country_long: "Japan",
    portCode: "JPNWA",
    portName: "Nyugawa (JPNWA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.3133985",
    lng: "140.5748789",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOAR",
    portName: "Oarai (JPOAR), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "41.5267934",
    lng: "140.9074974",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOAX",
    portName: "Oma (JPOAX), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.4956278",
    lng: "135.7467206",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOBM",
    portName: "Obama (JPOBM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "39.0819002",
    lng: "141.7084933",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOFU",
    portName: "OFUNATO",
    portContinent: "Asia",
  },
  {
    lat: "38.3799373",
    lng: "141.4489692",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOGH",
    portName: "Oginohama (JPOGH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.2396084",
    lng: "131.6095148",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOIT",
    portName: "Oita (JPOIT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "26.3343533",
    lng: "127.8056058",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOKA",
    portName: "Okinawa",
    portContinent: "Asia",
  },
  {
    lat: "34.6555312",
    lng: "133.919795",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOKJ",
    portName: "Okayama (JPOKJ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.983063",
    lng: "-84.2162225",
    country: "US",
    country_long: "United States",
    portCode: "JPOMA",
    portName: "AOMI",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOMD",
    portName: "Omodaka (JPOMD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.6379987",
    lng: "138.1280864",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOMZ",
    portName: "Omaezaki (JPOMZ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.9424912",
    lng: "140.8924708",
    country: "JP",
    country_long: "Japan",
    portCode: "JPONA",
    portName: "Onahama",
    portContinent: "Asia",
  },
  {
    lat: "33.9751379",
    lng: "131.1615173",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOND",
    portName: "Onoda (JPOND), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.44111960000001",
    lng: "141.4443913",
    country: "JP",
    country_long: "Japan",
    portCode: "JPONG",
    portName: "Onagawa (JPONG), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPONK",
    portName: "Oniki (JPONK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPONX",
    portName: "Onomichiitozaki (JPONX), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.6937249",
    lng: "135.5022535",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOSA",
    portName: "Osaka",
    portContinent: "Asia",
  },
  {
    lat: "34.2376953",
    lng: "132.2221691",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOTK",
    portName: "Otake (JPOTK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "43.1902372",
    lng: "140.994172",
    country: "JP",
    country_long: "Japan",
    portCode: "JPOTR",
    portName: "Otaru (JPOTR), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPRYO",
    portName: "Ryotsu (JPRYO), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.919495",
    lng: "133.1811444",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSAJ",
    portName: "Saijo (JPSAJ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.545369",
    lng: "133.222937",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSAK",
    portName: "Port of Sakai",
    portContinent: "Asia",
  },
  {
    lat: "34.1192617",
    lng: "133.6435293",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSAN",
    portName: "Sanbonmatsu (JPSAN), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.5107919",
    lng: "135.4560949",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSBK",
    portName: "Sakaisenboku (JPSBK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.4955583",
    lng: "131.0454228",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSBS",
    portName: "Shibushi (JPSBS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.268195",
    lng: "140.869418",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSDJ",
    portName: "Sendai, Miyagi (JPSDJ), Sendai, Japan",
    portContinent: "Asia",
  },
  {
    lat: "38.268195",
    lng: "140.869418",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSDS",
    portName: "Sendaishinko",
    portContinent: "Asia",
  },
  {
    lat: "35.153462",
    lng: "136.829495",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSEI",
    portName: "Seibu (JPSEI), Nagoya, Japan",
    portContinent: "Asia",
  },
  {
    lat: "31.8132856",
    lng: "130.3034644",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSEN",
    portName: "Satsumasendai",
    portContinent: "Asia",
  },
  {
    lat: "34.0550099",
    lng: "131.8061149",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSHA",
    portName: "Shunan",
    portContinent: "Asia",
  },
  {
    lat: "33.95752969999999",
    lng: "130.9411759",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSHI",
    portName: "SHIMONOSKI",
    portContinent: "Asia",
  },
  {
    lat: "35.4538613",
    lng: "139.6405308",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSHJ",
    portName: "Shinko (JPSHJ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.6390004",
    lng: "139.8265777",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSHK",
    portName: "Shinkiba",
    portContinent: "Asia",
  },
  {
    lat: "34.9755668",
    lng: "138.3826773",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSHO",
    portName: "Shizuoka",
    portContinent: "Asia",
  },
  {
    lat: "33.95752969999999",
    lng: "130.9411759",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSHS",
    portName: "Shimonoseki (JPSHS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSIG",
    portName: "Shigei (JPSIG), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.489471",
    lng: "139.000443",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSKB",
    portName: "Shibukawa (JPSKB), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.316426",
    lng: "133.8604948",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSKD",
    portName: "Sakaide (JPSKD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.9147809",
    lng: "139.8366435",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSKT",
    portName: "Sakata (JPSKT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.9804152",
    lng: "133.5490295",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSKZ",
    portName: "Shikokuchuo",
    portContinent: "Asia",
  },
  {
    lat: "32.7882308",
    lng: "130.3708414",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSMB",
    portName: "Shimabara (JPSMB), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.6796171",
    lng: "138.9451459",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSMD",
    portName: "Shimoda (JPSMD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.8639362",
    lng: "130.9914922",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSMJ",
    portName: "Shinmonji (JPSMJ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.5396534",
    lng: "133.2316204",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSMN",
    portName: "Sakaiminato (JPSMN), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.1088224",
    lng: "135.142559",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSMT",
    portName: "Shimotsu (JPSMT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.8285111",
    lng: "-122.2493833",
    country: "US",
    country_long: "United States",
    portCode: "JPSMZ",
    portName: "Shimizu",
    portContinent: "Asia",
  },
  {
    lat: "35.662344",
    lng: "139.8073929",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSOH",
    portName: "Shiohama (JPSOH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "42.5513683",
    lng: "141.3558132",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSRO",
    portName: "Shiraoi (JPSRO), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.148959",
    lng: "138.4265901",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSRS",
    portName: "Shirose (JPSRS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.1804356",
    lng: "129.7157996",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSSB",
    portName: "Sasebo (JPSSB), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSSM",
    portName: "Shishimi (JPSSM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSSN",
    portName: "Sasuna (JPSSN), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.400844",
    lng: "133.2829397",
    country: "JP",
    country_long: "Japan",
    portCode: "JPSUZ",
    portName: "Susaki (JPSUZ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.2722762",
    lng: "133.7532274",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTAD",
    portName: "Tadotsu (JPTAD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.342542",
    lng: "134.0465405",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTAK",
    portName: "Takamatsu (JPTAK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "26.197383",
    lng: "127.364322",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTAS",
    portName: "Takeshiki (JPTAS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.0783098",
    lng: "136.7925906",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTBM",
    portName: "Tobishima (JPTBM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.6393356",
    lng: "135.4901378",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTBN",
    portName: "Tachibana (JPTBN), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.9068469",
    lng: "130.8323418",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTBT",
    portName: "Tobata (JPTBT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "40.77555",
    lng: "140.5178518",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTGO",
    portName: "Tagonoura (JPTGO), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.7688528",
    lng: "137.3916837",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTHS",
    portName: "TOYOHASHI",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTIS",
    portName: "Teishi (JPTIS), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTKC",
    portName: "Takanokuchi (JPTKC), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.2251765",
    lng: "133.6615184",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTKM",
    portName: "Takuma (JPTKM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.0703652",
    lng: "134.5549537",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTKS",
    portName: "TOKUSHIMA (JPTKS), Tokushima, Japan",
    portContinent: "Asia",
  },
  {
    lat: "27.7904264",
    lng: "128.9668067",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTKU",
    portName: "Takuno (JPTKU), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "40.7762627",
    lng: "-73.9542598",
    country: "US",
    country_long: "United States",
    portCode: "JPTKY",
    portName: "Tokuyama",
    portContinent: "Asia",
  },
  {
    lat: "40.7290299",
    lng: "-73.9854663",
    country: "US",
    country_long: "United States",
    portCode: "JPTMI",
    portName: "Port of Tsukumi",
    portContinent: "Asia",
  },
  {
    lat: "42.63124639999999",
    lng: "141.6031222",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTMK",
    portName: "Tomakomai",
    portContinent: "Asia",
  },
  {
    lat: "34.3914873",
    lng: "133.3053178",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTNI",
    portName: "Tsuneishi (JPTNI), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.4818168",
    lng: "136.8432542",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTOB",
    portName: "Toba (JPTOB), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "42.913886",
    lng: "143.6932779",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTOK",
    portName: "Tokachi (JPTOK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.7822435",
    lng: "137.1044051",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTOS",
    portName: "Toyamashinko",
    portContinent: "Asia",
  },
  {
    lat: "35.7262664",
    lng: "139.7168209",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTOX",
    portName: "Toshima/Tokyo (JPTOX), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.6958223",
    lng: "137.2137211",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTOY",
    portName: "Toyoma",
    portContinent: "Asia",
  },
  {
    lat: "35.6459465",
    lng: "136.0556972",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTRG",
    portName: "Tsuruga",
    portContinent: "Asia",
  },
  {
    lat: "34.7661005",
    lng: "134.7906014",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTSG",
    portName: "Takasago (JPTSG), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.0756731",
    lng: "131.8252971",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTXD",
    portName: "Tokuyamakudamatsu (JPTXD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.6168116",
    lng: "139.7957807",
    country: "JP",
    country_long: "Japan",
    portCode: "JPTYO",
    portName: "Port of Tokyo",
    portContinent: "Asia",
  },
  {
    lat: "36.07070849999999",
    lng: "132.9757465",
    country: "JP",
    country_long: "Japan",
    portCode: "JPUAO",
    portName: "Urago (JPUAO), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.9515554",
    lng: "131.2468161",
    country: "JP",
    country_long: "Japan",
    portCode: "JPUBJ",
    portName: "Ube (JPUBJ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.7527782",
    lng: "131.43124",
    country: "JP",
    country_long: "Japan",
    portCode: "JPUCH",
    portName: "Uchiumi (JPUCH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.3039172",
    lng: "136.8029671",
    country: "JP",
    country_long: "Japan",
    portCode: "JPUCN",
    portName: "Uchinomi (JPUCN), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "42.344302",
    lng: "140.6187099",
    country: "JP",
    country_long: "Japan",
    portCode: "JPUCU",
    portName: "Uchiura (JPUCU), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.8479628",
    lng: "138.8095665",
    country: "JP",
    country_long: "Japan",
    portCode: "JPUGU",
    portName: "Ugusu (JPUGU), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.6900806",
    lng: "135.1956311",
    country: "JP",
    country_long: "Japan",
    portCode: "JPUKB",
    portName: "Kobe",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPUNO",
    portName: "Uno (JPUNO), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "35.2483823",
    lng: "139.7102847",
    country: "JP",
    country_long: "Japan",
    portCode: "JPURG",
    portName: "Uraga (JPURG), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.621607",
    lng: "134.1577866",
    country: "JP",
    country_long: "Japan",
    portCode: "JPUSH",
    portName: "Ushimado (JPUSH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPUSJ",
    portName: "Usujiri (JPUSJ), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.1260482",
    lng: "131.805109",
    country: "JP",
    country_long: "Japan",
    portCode: "JPUSK",
    portName: "Usuki (JPUSK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.2235707",
    lng: "132.560384",
    country: "JP",
    country_long: "Japan",
    portCode: "JPUWA",
    portName: "Uwajima (JPUWA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.2303678",
    lng: "135.1707405",
    country: "JP",
    country_long: "Japan",
    portCode: "JPWAK",
    portName: "Wakayama (JPWAK), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.8993416",
    lng: "130.7462671",
    country: "JP",
    country_long: "Japan",
    portCode: "JPWAM",
    portName: "Wakamatsu/Kitakyushu (JPWAM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "38.4173631",
    lng: "141.3670247",
    country: "JP",
    country_long: "Japan",
    portCode: "JPWAT",
    portName: "Watanoha (JPWAT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "37.3904768",
    lng: "136.8992581",
    country: "JP",
    country_long: "Japan",
    portCode: "JPWJM",
    portName: "Wajima (JPWJM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPWKI",
    portName: "Wakimisaki (JPWKI), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPWKT",
    portName: "Wakatsu (JPWKT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "31.6953495",
    lng: "130.611835",
    country: "JP",
    country_long: "Japan",
    portCode: "JPWMT",
    portName: "Wakimoto (JPWMT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPYAM",
    portName: "Yamagawa (JPYAM), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.9638862",
    lng: "132.1016377",
    country: "JP",
    country_long: "Japan",
    portCode: "JPYAN",
    portName: "Yanai (JPYAN), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "32.5083376",
    lng: "130.6018162",
    country: "JP",
    country_long: "Japan",
    portCode: "JPYAT",
    portName: "Yatsushiro",
    portContinent: "Asia",
  },
  {
    lat: "34.9651064",
    lng: "136.6244121",
    country: "JP",
    country_long: "Japan",
    portCode: "JPYKK",
    portName: "Yokkaichi",
    portContinent: "Asia",
  },
  {
    lat: "35.449818",
    lng: "139.6649408",
    country: "JP",
    country_long: "Japan",
    portCode: "JPYOK",
    portName: "Port of Yokohama",
    portContinent: "Asia",
  },
  {
    lat: "35.2361655",
    lng: "139.729659",
    country: "JP",
    country_long: "Japan",
    portCode: "JPYOS",
    portName: "Port of Yokosuka",
    portContinent: "Asia",
  },
  {
    lat: "36.204824",
    lng: "138.252924",
    country: "JP",
    country_long: "Japan",
    portCode: "JPYOT",
    portName: "Yotsukura (JPYOT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "39.4676212",
    lng: "141.9490579",
    country: "JP",
    country_long: "Japan",
    portCode: "JPYQD",
    portName: "Yamada (JPYQD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.9592854",
    lng: "135.1182368",
    country: "JP",
    country_long: "Japan",
    portCode: "JPYRA",
    portName: "Yura (JPYRA), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.7709132",
    lng: "138.2520084",
    country: "JP",
    country_long: "Japan",
    portCode: "JPYSD",
    portName: "Yoshida (JPYSD), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "33.4628782",
    lng: "132.4233981",
    country: "JP",
    country_long: "Japan",
    portCode: "JPYWH",
    portName: "Yawatahama (JPYWH), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.8755613",
    lng: "135.7076676",
    country: "JP",
    country_long: "Japan",
    portCode: "JPYWT",
    portName: "Yawata (JPYWT), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "34.8668816",
    lng: "138.3233812",
    country: "JP",
    country_long: "Japan",
    portCode: "JPYZU",
    portName: "Yaizu (JPYZU), Japan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "0.5142774999999999",
    lng: "35.2697802",
    country: "KE",
    country_long: "Kenya",
    portCode: "KEEDL",
    portName: "Eldoret",
    portContinent: "Africa",
  },
  {
    lat: "-1.3237858",
    lng: "36.8999616",
    country: "KE",
    country_long: "Kenya",
    portCode: "KEEMB",
    portName: "EMBAKASI (KEEMB), Nairobi, Kenya",
    portContinent: "Africa",
  },
  {
    lat: "-0.0917016",
    lng: "34.7679568",
    country: "KE",
    country_long: "Kenya",
    portCode: "KEKIS",
    portName: "KISUMU (KEKIS), Kenya, Africa",
    portContinent: "Africa",
  },
  {
    lat: "1.019089",
    lng: "35.0023048",
    country: "KE",
    country_long: "Kenya",
    portCode: "KEKTL",
    portName: "KITALE",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "KELAU",
    portName: "Port of Lamu",
    portContinent: "Africa",
  },
  {
    lat: "-4.058055599999999",
    lng: "39.6813889",
    country: "KE",
    country_long: "Kenya",
    portCode: "KEMBA",
    portName: "Port of Mombasa",
    portContinent: "Africa",
  },
  {
    lat: "-3.2191864",
    lng: "40.1168906",
    country: "KE",
    country_long: "Kenya",
    portCode: "KEMYD",
    portName: "Port of Malindi",
    portContinent: "Africa",
  },
  {
    lat: "-1.2920659",
    lng: "36.8219462",
    country: "KE",
    country_long: "Kenya",
    portCode: "KENBO",
    portName: "NAIROBI (KENBO), Nairobi, Kenya",
    portContinent: "Africa",
  },
  {
    lat: "-0.3030988",
    lng: "36.080026",
    country: "KE",
    country_long: "Kenya",
    portCode: "KENUU",
    portName: "Nakuru",
    portContinent: "Africa",
  },
  {
    lat: "-0.7171778",
    lng: "36.4310251",
    country: "KE",
    country_long: "Kenya",
    portCode: "KERNA",
    portName: "NAIVASHA",
    portContinent: "Africa",
  },
  {
    lat: "42.8746212",
    lng: "74.5697617",
    country: "KG",
    country_long: "Kyrgyzstan",
    portCode: "KGFRU",
    portName: "BISHKEK",
    portContinent: "Central Asia",
  },
  {
    lat: "11.6153795",
    lng: "102.9836882",
    country: "KH",
    country_long: "Cambodia",
    portCode: "KHKKZ",
    portName: "Koh Kong (KHKKZ), Cambodia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "10.6267867",
    lng: "103.5115545",
    country: "KH",
    country_long: "Cambodia",
    portCode: "KHKOS",
    portName: "Sihanoukville ,KH (KHKOS), Sihanoukville ,KH, Cambodia",
    portContinent: "South East Asia",
  },
  {
    lat: "1.3015584",
    lng: "103.8623134",
    country: "SG",
    country_long: "Singapore",
    portCode: "KHKPC",
    portName: "KAMPONG CLAM",
    portContinent: "South East Asia",
  },
  {
    lat: "11.5563738",
    lng: "104.9282099",
    country: "KH",
    country_long: "Cambodia",
    portCode: "KHPNH",
    portName: "Port of Phnom Penh",
    portContinent: "South East Asia",
  },
  {
    lat: "10.6267867",
    lng: "103.5115545",
    country: "KH",
    country_long: "Cambodia",
    portCode: "KHSCH",
    portName: "Sihanoukville",
    portContinent: "South East Asia",
  },
  {
    lat: "-10.447525",
    lng: "105.690449",
    country: "CX",
    country_long: "Christmas Island",
    portCode: "KICXI",
    portName: "CHRISTMAS ISLAND",
    portContinent: "Oceania",
  },
  {
    lat: "3.8743",
    lng: "-159.3191",
    country: "KI",
    country_long: "Kiribati",
    portCode: "KITNV",
    portName: "Tabuaeran (KITNV), Kiribati, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "1.358982",
    lng: "172.9328451",
    country: "KI",
    country_long: "Kiribati",
    portCode: "KITRW",
    portName: "Tarawa (KITRW), Tarawa, Kiribati",
    portContinent: "Oceania",
  },
  {
    lat: "-11.5652319",
    lng: "43.2726992",
    country: "KM",
    country_long: "Comoros",
    portCode: "KMHAH",
    portName: "Hahaya Apt/Moroni",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "KMLOG",
    portName: "LONGONI",
    portContinent: "Africa",
  },
  {
    lat: "-12.1695811",
    lng: "44.40047",
    country: "KM",
    country_long: "Comoros",
    portCode: "KMMUT",
    portName: "Mutsamudu, Anjouan",
    portContinent: "Africa",
  },
  {
    lat: "-11.7172157",
    lng: "43.2473146",
    country: "KM",
    country_long: "Comoros",
    portCode: "KMYVA",
    portName: "Moroni (KMYVA), Moroni, Comoros",
    portContinent: "Africa",
  },
  {
    lat: "17.3026058",
    lng: "-62.7176924",
    country: "KN",
    country_long: "Saint Kitts and Nevis",
    portCode: "KNBAS",
    portName: "Basseterre, Saint Kitts  (KNBAS), Saint Kitts and Nevis, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.1553558",
    lng: "-62.57960259999999",
    country: "KN",
    country_long: "Saint Kitts and Nevis",
    portCode: "KNCHA",
    portName: "Nevis (KNCHA), Saint Kitts and Nevis, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.1374798",
    lng: "-62.6236633",
    country: "KN",
    country_long: "Saint Kitts and Nevis",
    portCode: "KNNEV",
    portName: "CHARLESTOWN (KNNEV), Saint Kitts and Nevis, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.357822",
    lng: "-62.782998",
    country: "KN",
    country_long: "Saint Kitts and Nevis",
    portCode: "KNSKB",
    portName: "Saint Kitts",
    portContinent: "North America",
  },
  {
    lat: "41.7669671",
    lng: "129.723402",
    country: "KP",
    country_long: "North Korea",
    portCode: "KPCHO",
    portName: "Port of Chongjin",
    portContinent: null,
  },
  {
    lat: "38.0375021",
    lng: "125.7079231",
    country: "KP",
    country_long: "North Korea",
    portCode: "KPHAE",
    portName: "Port of Haeju",
    portContinent: null,
  },
  {
    lat: "39.8554302",
    lng: "127.6752642",
    country: "KP",
    country_long: "North Korea",
    portCode: "KPHGM",
    portName: "Port of Hungnam",
    portContinent: null,
  },
  {
    lat: "38.7522606",
    lng: "125.3246769",
    country: "KP",
    country_long: "North Korea",
    portCode: "KPNAM",
    portName: "Port of Nampo",
    portContinent: null,
  },
  {
    lat: "40.668162",
    lng: "129.1902694",
    country: "KP",
    country_long: "North Korea",
    portCode: "KPSON",
    portName: "Port of Songjin",
    portContinent: null,
  },
  {
    lat: "39.1538529",
    lng: "127.4460001",
    country: "KP",
    country_long: "North Korea",
    portCode: "KPWON",
    portName: "Port of Wonsan",
    portContinent: null,
  },
  {
    lat: "36.96094840000001",
    lng: "127.0447308",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRANJ",
    portName: "Anjeong (KRANJ), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "36.097524",
    lng: "114.3930629",
    country: "CN",
    country_long: "China",
    portCode: "KRANY",
    portName: "ANYANG",
    portContinent: "Asia",
  },
  {
    lat: "36.789796",
    lng: "127.0018494",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRASA",
    portName: "ASAN",
    portContinent: "Asia",
  },
  {
    lat: "37.0079695",
    lng: "127.2796786",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRASG",
    portName: "ANSEONG",
    portContinent: "Asia",
  },
  {
    lat: "37.3218778",
    lng: "126.8308848",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRASN",
    portName: "Ansan",
    portContinent: null,
  },
  {
    lat: "37.5034138",
    lng: "126.7660309",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRBCH",
    portName: "BUCHEON",
    portContinent: "Asia",
  },
  {
    lat: "37.5034138",
    lng: "126.7660309",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRBCN",
    portName: "Bucheon (KRBCN), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "35.07817259999999",
    lng: "128.8330003",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRBNP",
    portName: "Busan New Port (KRBNP), Busan, South Korea",
    portContinent: null,
  },
  {
    lat: "37.5069818",
    lng: "126.7217739",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRBUK",
    portName: "Bupyong/Incheon",
    portContinent: null,
  },
  {
    lat: "36.5573058",
    lng: "127.5321341",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRCHE",
    portName: "CHEONGWON",
    portContinent: "Asia",
  },
  {
    lat: "35.1330251",
    lng: "128.7100381",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRCHF",
    portName: "Jinhae (KRCHF), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "38.146609",
    lng: "127.3132256",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRCHL",
    portName: "CHEORWON",
    portContinent: "Asia",
  },
  {
    lat: "37.8813153",
    lng: "127.7299707",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRCHN",
    portName: "CHUNCHEON",
    portContinent: "Asia",
  },
  {
    lat: "36.9910113",
    lng: "127.9259497",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRCHU",
    portName: "CHUNGIU",
    portContinent: "Asia",
  },
  {
    lat: "36.6424341",
    lng: "127.4890319",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRCJJ",
    portName: "CHEONGIU",
    portContinent: "Asia",
  },
  {
    lat: "36.6424341",
    lng: "127.4890319",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRCOJ",
    portName: "CHEONGJU",
    portContinent: "Asia",
  },
  {
    lat: "37.9034112",
    lng: "127.0605075",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRDDC",
    portName: "Dongducheon",
    portContinent: "Asia",
  },
  {
    lat: "36.8936109",
    lng: "126.6283278",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRDJN",
    portName: "Dangjin (KRDJN), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "35.3211394",
    lng: "126.9881673",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRDMY",
    portName: "DAMYANG",
    portContinent: "Asia",
  },
  {
    lat: "36.9396792",
    lng: "127.6905018",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRESG",
    portName: "Eumseong-gun",
    portContinent: null,
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "KRGIN",
    portName: "Gyeongin",
    portContinent: "Asia",
  },
  {
    lat: "35.8036079",
    lng: "126.8808872",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRGJE",
    portName: "GIMJE",
    portContinent: "Asia",
  },
  {
    lat: "36.1398393",
    lng: "128.1135947",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRGMC",
    portName: "GIMCHEON",
    portContinent: "Asia",
  },
  {
    lat: "37.4784878",
    lng: "126.8642888",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRGMG",
    portName: "Gwangmyeong",
    portContinent: "Asia",
  },
  {
    lat: "37.6152464",
    lng: "126.7156325",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRGMP",
    portName: "GIMPO",
    portContinent: "Asia",
  },
  {
    lat: "37.8315403",
    lng: "127.5098827",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRGPG",
    portName: "GAPYEONG",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "KRGRI",
    portName: "GURI",
    portContinent: "Asia",
  },
  {
    lat: "37.3616703",
    lng: "126.9351741",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRGUN",
    portName: "GUNPO",
    portContinent: "Asia",
  },
  {
    lat: "37.429246",
    lng: "126.9874451",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRGWC",
    portName: "GWACHEON",
    portContinent: "Asia",
  },
  {
    lat: "37.65835990000001",
    lng: "126.8320201",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRGYA",
    portName: "GOYANG",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "KRHAN",
    portName: "HAMAN",
    portContinent: "Asia",
  },
  {
    lat: "37.6969518",
    lng: "127.8886827",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRHNC",
    portName: "HONGCHEON",
    portContinent: "Asia",
  },
  {
    lat: "37.5392646",
    lng: "127.2148919",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRHNM",
    portName: "HANAM",
    portContinent: "Asia",
  },
  {
    lat: "37.1994932",
    lng: "126.8311887",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRHWA",
    portName: "HWASEONG",
    portContinent: "Asia",
  },
  {
    lat: "37.2719952",
    lng: "127.4348221",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRICE",
    portName: "Icheon",
    portContinent: null,
  },
  {
    lat: "37.4594575",
    lng: "126.6256454",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRICH",
    portName: "Port of Incheon",
    portContinent: null,
  },
  {
    lat: "37.4601908",
    lng: "126.4406957",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRICN",
    portName: "Incheon Intl Apt/Seoul",
    portContinent: "Asia",
  },
  {
    lat: "37.4562557",
    lng: "126.7052062",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRINC",
    portName: "Incheon (KRINC), Incheon, South Korea",
    portContinent: null,
  },
  {
    lat: "37.73809800000001",
    lng: "127.0336819",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRJNB",
    portName: "UIJEONGBU",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "KRJNJ",
    portName: "JEONJI",
    portContinent: "Asia",
  },
  {
    lat: "34.9406968",
    lng: "127.6958882",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRKAN",
    portName: "Gwangyang",
    portContinent: null,
  },
  {
    lat: "34.8824091",
    lng: "128.6231029",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRKHN",
    portName: "Gohyeon (KRKHN), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "36.10226400000001",
    lng: "129.3273683",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRKPO",
    portName: "Port of Pohang",
    portContinent: null,
  },
  {
    lat: "1.4856295",
    lng: "33.9331874",
    country: "UG",
    country_long: "Uganda",
    portCode: "KRKUI",
    portName: "Kumi",
    portContinent: null,
  },
  {
    lat: "36.119485",
    lng: "128.3445734",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRKUM",
    portName: "GUMI",
    portContinent: "Asia",
  },
  {
    lat: "35.9698329",
    lng: "126.6172135",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRKUV",
    portName: "Port of Gunsan (Kunsan)",
    portContinent: null,
  },
  {
    lat: "34.9406968",
    lng: "127.6958882",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRKWA",
    portName: "Kwangyang",
    portContinent: null,
  },
  {
    lat: "37.4171413",
    lng: "127.2561413",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRKWJ",
    portName: "Gwangju,Gyeounggi",
    portContinent: null,
  },
  {
    lat: "35.1595454",
    lng: "126.8526012",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRKWU",
    portName: "GWANGIU",
    portContinent: "Asia",
  },
  {
    lat: "34.9406968",
    lng: "127.6958882",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRKWY",
    portName: "Kwangyang",
    portContinent: "Asia",
  },
  {
    lat: "35.213516",
    lng: "128.581433",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRMAS",
    portName: "Masan (KRMAS), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "34.8118351",
    lng: "126.3921664",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRMOK",
    portName: "Mokpo (KRMOK), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "37.5518827",
    lng: "129.1151799",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRMUK",
    portName: "Mukho (KRMUK), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "35.416357",
    lng: "127.3904877",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRNWN",
    portName: "Namwon (KRNWN), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "37.6360028",
    lng: "127.2165279",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRNYJ",
    portName: "NAMYAMGJU",
    portContinent: "Asia",
  },
  {
    lat: "37.6360028",
    lng: "127.2165279",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRNYU",
    portName: "Namyangju",
    portContinent: "Asia",
  },
  {
    lat: "34.8941936",
    lng: "128.6885165",
    country: "KR",
    country_long: "South Korea",
    portCode: "KROKP",
    portName: "Okpo (KROKP), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "35.4346298",
    lng: "129.3146114",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRONS",
    portName: "Onsan (KRONS), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "37.1498096",
    lng: "127.0772212",
    country: "KR",
    country_long: "South Korea",
    portCode: "KROSN",
    portName: "Osan",
    portContinent: null,
  },
  {
    lat: "47.62439209999999",
    lng: "-122.3561658",
    country: "US",
    country_long: "United States",
    portCode: "KRPJU",
    portName: "PAJU",
    portContinent: "Asia",
  },
  {
    lat: "37.8949148",
    lng: "127.2003551",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRPOC",
    portName: "Pocheon",
    portContinent: "Asia",
  },
  {
    lat: "36.9921075",
    lng: "127.1129451",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRPTK",
    portName: "Pyeongtaek (KRPTK), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "35.1035355",
    lng: "129.0423688",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRPUS",
    portName: "Port of Busan",
    portContinent: null,
  },
  {
    lat: "35.9190079",
    lng: "128.2829738",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRSEJ",
    portName: "Seongju-gun",
    portContinent: "Asia",
  },
  {
    lat: "37.566535",
    lng: "126.9779692",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRSEL",
    portName: "Seoul (KRSEL), Seoul, South Korea",
    portContinent: null,
  },
  {
    lat: "37.4449168",
    lng: "127.1388684",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRSGM",
    portName: "Seongnam",
    portContinent: null,
  },
  {
    lat: "37.3798877",
    lng: "126.8031025",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRSHE",
    portName: "SIHEUNG",
    portContinent: null,
  },
  {
    lat: "37.3798877",
    lng: "126.8031025",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRSIG",
    portName: "SIHEUNG",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "KRSJG",
    portName: "SEJONG",
    portContinent: "Asia",
  },
  {
    lat: "37.4449168",
    lng: "127.1388684",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRSSN",
    portName: "SEONGNAM",
    portContinent: "Asia",
  },
  {
    lat: "35.1984659",
    lng: "128.7027178",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRSSP",
    portName: "Seongsan (KRSSP), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "37.2635727",
    lng: "127.0286009",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRSUO",
    portName: "Suwon",
    portContinent: "Asia",
  },
  {
    lat: "37.2635727",
    lng: "127.0286009",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRSWN",
    portName: "SUWON",
    portContinent: "Asia",
  },
  {
    lat: "35.8714354",
    lng: "128.601445",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRTAE",
    portName: "DAEGU",
    portContinent: "Asia",
  },
  {
    lat: "35.8714354",
    lng: "128.601445",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRTDAE",
    portName: "DAEGU",
    portContinent: null,
  },
  {
    lat: "37.5247192",
    lng: "129.1142915",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRTGH",
    portName: "Donghae (KRTGH), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "36.9395813",
    lng: "126.4343234",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRTSN",
    portName: "Daesan (KRTSN), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "34.8544227",
    lng: "128.433182",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRTYG",
    portName: "Tongyeong (KRTYG), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "35.5192983",
    lng: "129.3734649",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRUSN",
    portName: "Port of Ulsan",
    portContinent: null,
  },
  {
    lat: "37.344701",
    lng: "126.9683104",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRUWN",
    portName: "UIWANG",
    portContinent: "Asia",
  },
  {
    lat: "37.3422186",
    lng: "127.9201621",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRWJU",
    portName: "WONJU",
    portContinent: "Asia",
  },
  {
    lat: "37.7852875",
    lng: "127.0458453",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRYAN",
    portName: "YANGJU",
    portContinent: "Asia",
  },
  {
    lat: "36.592881",
    lng: "127.2923268",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRYGI",
    portName: "Yeongi",
    portContinent: null,
  },
  {
    lat: "37.2980237",
    lng: "127.6371628",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRYJU",
    portName: "Yeoju",
    portContinent: null,
  },
  {
    lat: "37.2410864",
    lng: "127.1775537",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRYNG",
    portName: "Yongin",
    portContinent: null,
  },
  {
    lat: "35.1165463",
    lng: "129.0966359",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRYOG",
    portName: "Yongdang Dong (KRYOG), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "34.7458867",
    lng: "127.751222",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRYOS",
    portName: "Port of Yeosu",
    portContinent: null,
  },
  {
    lat: "37.4912195",
    lng: "127.4875607",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRYPG",
    portName: "Yangpyeong-gun",
    portContinent: "Asia",
  },
  {
    lat: "37.4912195",
    lng: "127.4875607",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRYPY",
    portName: "YANGPYEONG",
    portContinent: "Asia",
  },
  {
    lat: "35.2285451",
    lng: "128.8893517",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRYSN",
    portName: "GIMHAE",
    portContinent: "Asia",
  },
  {
    lat: "37.7852875",
    lng: "127.0458453",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRYYU",
    portName: "Yangju-si",
    portContinent: "Asia",
  },
  {
    lat: "35.0645029",
    lng: "126.9864799",
    country: "KR",
    country_long: "South Korea",
    portCode: "KRZAD",
    portName: "Hwasun (KRZAD), South Korea, Asia",
    portContinent: null,
  },
  {
    lat: "29.3365728",
    lng: "47.6755291",
    country: "KW",
    country_long: "Kuwait",
    portCode: "KWJAH",
    portName: "Al Jahra (KWJAH), Kuwait, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "29.31166",
    lng: "47.481766",
    country: "KW",
    country_long: "Kuwait",
    portCode: "KWKWI",
    portName: "Kuwait (KWKWI), Kuwait, Kuwait",
    portContinent: "Middle East",
  },
  {
    lat: "29.083461",
    lng: "48.07347619999999",
    country: "KW",
    country_long: "Kuwait",
    portCode: "KWMEA",
    portName: "Mina Al Ahmadi (KWMEA), Kuwait, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "29.008169",
    lng: "48.161171",
    country: "KW",
    country_long: "Kuwait",
    portCode: "KWMIB",
    portName: "Mina Abd Allah (KWMIB), Kuwait, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "29.31166",
    lng: "47.481766",
    country: "KW",
    country_long: "Kuwait",
    portCode: "KWMIS",
    portName: "Mina Saud (KWMIS), Kuwait, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "29.0447222",
    lng: "48.1591667",
    country: "KW",
    country_long: "Kuwait",
    portCode: "KWSAA",
    portName: "Port of Shuaiba",
    portContinent: "Middle East",
  },
  {
    lat: "29.3472451",
    lng: "47.9160209",
    country: "KW",
    country_long: "Kuwait",
    portCode: "KWSWK",
    portName: "Port of Shuwaikh",
    portContinent: "Middle East",
  },
  {
    lat: "19.7235328",
    lng: "-79.8016569",
    country: "KY",
    country_long: "Cayman Islands",
    portCode: "KYCYB",
    portName: "CAYMAN BRAC",
    portContinent: "North America",
  },
  {
    lat: "19.3221698",
    lng: "-81.2408689",
    country: "KY",
    country_long: "Cayman Islands",
    portCode: "KYGCM",
    portName: "GRAND CAYMAN",
    portContinent: "North America",
  },
  {
    lat: "19.2869323",
    lng: "-81.3674389",
    country: "KY",
    country_long: "Cayman Islands",
    portCode: "KYGEC",
    portName: "Georgetown, Grand Cayman (KYGEC), Caymen Islands, LatAm",
    portContinent: "North America",
  },
  {
    lat: "43.65880790000001",
    lng: "51.1974563",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZAAU",
    portName: "Port of Aktau",
    portContinent: "Central Asia",
  },
  {
    lat: "50.2839339",
    lng: "57.166978",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZABE",
    portName: "AKTOBE",
    portContinent: "Central Asia",
  },
  {
    lat: "43.2220146",
    lng: "76.8512485",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZALA",
    portName: "ALMATY",
    portContinent: "Central Asia",
  },
  {
    lat: "51.16052269999999",
    lng: "71.4703558",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZAST",
    portName: "ASTANA",
    portContinent: "Central Asia",
  },
  {
    lat: "44.53919339999999",
    lng: "50.2535786",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZBTN",
    portName: "Bautino (KZBTN), Kazakhstan, Med",
    portContinent: "Central Asia",
  },
  {
    lat: "47.79636559999999",
    lng: "67.7020019",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZDZN",
    portName: "ZHEZKAZGAN",
    portContinent: "Central Asia",
  },
  {
    lat: "47.12244889999999",
    lng: "51.83044870000001",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZGUW",
    portName: "Atyrau (KZGUW), Kazakhstan, Med",
    portContinent: "Central Asia",
  },
  {
    lat: "49.8046835",
    lng: "73.1093826",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZKGF",
    portName: "KARAGANDA",
    portContinent: "Central Asia",
  },
  {
    lat: "53.2871307",
    lng: "69.40444939999999",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZKOV",
    portName: "KOKSHETAU",
    portContinent: "Central Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "KZKSN",
    portName: "KUSTAJAN",
    portContinent: "Central Asia",
  },
  {
    lat: "43.1796495",
    lng: "51.6769027",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZKUR",
    portName: "Kuryk (KZKUR), Kazakhstan, Med",
    portContinent: "Central Asia",
  },
  {
    lat: "53.0583997",
    lng: "158.6320734",
    country: "RU",
    country_long: "Russia",
    portCode: "KZPPK",
    portName: "PETROPAVLOVSK",
    portContinent: "Central Asia",
  },
  {
    lat: "52.2873032",
    lng: "76.9674023",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZPWQ",
    portName: "Port of Pavlodar",
    portContinent: "Central Asia",
  },
  {
    lat: "42.34168469999999",
    lng: "69.59010099999999",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZSHY",
    portName: "SHYMKENT",
    portContinent: "Central Asia",
  },
  {
    lat: "42.8983715",
    lng: "71.3979891",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZTAR",
    portName: "TARAZ",
    portContinent: "Central Asia",
  },
  {
    lat: "45.0177112",
    lng: "78.38044169999999",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZTDK",
    portName: "TALDY-KURGAN",
    portContinent: "Central Asia",
  },
  {
    lat: "49.97492949999999",
    lng: "82.6017244",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZUKK",
    portName: "UST-KAMENOGORSK",
    portContinent: "Central Asia",
  },
  {
    lat: "51.227821",
    lng: "51.3865431",
    country: "KZ",
    country_long: "Kazakhstan",
    portCode: "KZURA",
    portName: "URALSK",
    portContinent: "Central Asia",
  },
  {
    lat: "20.262817",
    lng: "100.4340367",
    country: "LA",
    country_long: "Laos",
    portCode: "LAHOE",
    portName: "Huay Xay",
    portContinent: "South East Asia",
  },
  {
    lat: "15.1171823",
    lng: "105.8159045",
    country: "LA",
    country_long: "Laos",
    portCode: "LAPKZ",
    portName: "Pakxe",
    portContinent: "South East Asia",
  },
  {
    lat: "16.5720526",
    lng: "104.7686868",
    country: "LA",
    country_long: "Laos",
    portCode: "LASAV",
    portName: "Savannakhet",
    portContinent: "South East Asia",
  },
  {
    lat: "17.9036075",
    lng: "102.7093827",
    country: "LA",
    country_long: "Laos",
    portCode: "LATHG",
    portName: "Thanaleng",
    portContinent: "South East Asia",
  },
  {
    lat: "17.4030206",
    lng: "104.8337879",
    country: "LA",
    country_long: "Laos",
    portCode: "LATHK",
    portName: "Thakhek",
    portContinent: "South East Asia",
  },
  {
    lat: "17.9757058",
    lng: "102.6331035",
    country: "LA",
    country_long: "Laos",
    portCode: "LAVTE",
    portName: "Vientiane",
    portContinent: "South East Asia",
  },
  {
    lat: "10.4113797",
    lng: "107.136224",
    country: "VN",
    country_long: "Vietnam",
    portCode: "LAVTO",
    portName: "Vang Tao",
    portContinent: "South East Asia",
  },
  {
    lat: "34.0046888",
    lng: "36.2110399",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LBBAA",
    portName: "BAALBACK",
    portContinent: "Middle East",
  },
  {
    lat: "33.9027573",
    lng: "35.5176967",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LBBEY",
    portName: "Port of Beirut",
    portContinent: "Middle East",
  },
  {
    lat: "33.8967855",
    lng: "35.5174387",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LBBFZ",
    portName: "BEIRUT FREE ZONE",
    portContinent: "Middle East",
  },
  {
    lat: "34.1230021",
    lng: "35.6519282",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LBBYL",
    portName: "BYBLOS",
    portContinent: "Middle East",
  },
  {
    lat: "34.3239545",
    lng: "35.7294505",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LBCHK",
    portName: "Chekka (LBCHK), Lebanon, Med",
    portContinent: "Middle East",
  },
  {
    lat: "33.9329151",
    lng: "35.5892181",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LBDBA",
    portName: "Dbaiyeh (LBDBA), Lebanon, Med",
    portContinent: "Middle East",
  },
  {
    lat: "34.1230021",
    lng: "35.6519282",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LBJBE",
    portName: "JBEIL",
    portContinent: "Middle East",
  },
  {
    lat: "34.4503139",
    lng: "35.8306935",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LBKYE",
    portName: "Port of Tripoli",
    portContinent: "Middle East",
  },
  {
    lat: "33.8462557",
    lng: "35.90195190000001",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LBQZQ",
    portName: "ZAHLE",
    portContinent: "Middle East",
  },
  {
    lat: "33.5570691",
    lng: "35.372948",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LBSAA",
    portName: "SAIDA",
    portContinent: "Middle East",
  },
  {
    lat: "33.5570691",
    lng: "35.372948",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LBSDN",
    portName: "Sidon (LBSDN), Lebanon, Med",
    portContinent: "Middle East",
  },
  {
    lat: "34.2795888",
    lng: "35.6640062",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LBSEL",
    portName: "Selaata (LBSEL), Lebanon, Med",
    portContinent: "Middle East",
  },
  {
    lat: "33.2704888",
    lng: "35.2037641",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LBSUR",
    portName: "TYRE",
    portContinent: "Middle East",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "LBZHR",
    portName: "Zahrani (LBZHR), Lebanon, Med",
    portContinent: "Middle East",
  },
  {
    lat: "14.0101094",
    lng: "-60.98746869999999",
    country: "LC",
    country_long: "Saint Lucia",
    portCode: "LCCAS",
    portName: "Port Castries",
    portContinent: "North America",
  },
  {
    lat: "13.720608",
    lng: "-60.94964329999999",
    country: "LC",
    country_long: "Saint Lucia",
    portCode: "LCVIF",
    portName: "Vieux Fort (LCVIF), Saint Lucia, LatAm",
    portContinent: "North America",
  },
  {
    lat: "6.9404319",
    lng: "79.8464095",
    country: "LK",
    country_long: "Sri Lanka",
    portCode: "LKCMB",
    portName: "Port of Colombo",
    portContinent: "Asia",
  },
  {
    lat: "6.032894799999999",
    lng: "80.2167912",
    country: "LK",
    country_long: "Sri Lanka",
    portCode: "LKGAL",
    portName: "Port of Galle",
    portContinent: "Asia",
  },
  {
    lat: "6.1428829",
    lng: "81.12123079999999",
    country: "LK",
    country_long: "Sri Lanka",
    portCode: "LKHBA",
    portName: "Hambantota (LKHBA), Sri Lanka, Asia",
    portContinent: "Asia",
  },
  {
    lat: "6.947606",
    lng: "79.8538779",
    country: "LK",
    country_long: "Sri Lanka",
    portCode: "LKJCT",
    portName: "Jaya Container Terminal",
    portContinent: "Asia",
  },
  {
    lat: "8.5873638",
    lng: "81.2152121",
    country: "LK",
    country_long: "Sri Lanka",
    portCode: "LKTRR",
    portName: "Port of Trincomalee",
    portContinent: "Asia",
  },
  {
    lat: "33.3602081",
    lng: "-91.1089026",
    country: "US",
    country_long: "United States",
    portCode: "LRGRE",
    portName: "Port of Greenville",
    portContinent: "Africa",
  },
  {
    lat: "4.3688889",
    lng: "-7.728611099999999",
    country: "LR",
    country_long: "Liberia",
    portCode: "LRHAR",
    portName: "Port of Cape Palmas",
    portContinent: "Africa",
  },
  {
    lat: "6.343720299999999",
    lng: "-10.7929537",
    country: "LR",
    country_long: "Liberia",
    portCode: "LRMLW",
    portName: "Port of Monrovia",
    portContinent: "Africa",
  },
  {
    lat: "5.858353",
    lng: "-10.0497437",
    country: "LR",
    country_long: "Liberia",
    portCode: "LRUCN",
    portName: "Port of Buchanan",
    portContinent: "Africa",
  },
  {
    lat: "-29.8224312",
    lng: "27.2388161",
    country: "LS",
    country_long: "Lesotho",
    portCode: "LSMFC",
    portName: "MAFETENG",
    portContinent: "Africa",
  },
  {
    lat: "-29.3150767",
    lng: "27.4869229",
    country: "LS",
    country_long: "Lesotho",
    portCode: "LSMSU",
    portName: "Maseru",
    portContinent: "Africa",
  },
  {
    lat: "56.0527929",
    lng: "21.1303829",
    country: "LT",
    country_long: "Lithuania",
    portCode: "LTBOT",
    portName: "Butinge (LTBOT), Lithuania, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.2881309",
    lng: "23.9577279",
    country: "LT",
    country_long: "Lithuania",
    portCode: "LTKDN",
    portName: "Kedainiai  (LTKDN), Lithuania, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.70329479999999",
    lng: "21.1442794",
    country: "LT",
    country_long: "Lithuania",
    portCode: "LTKLJ",
    portName: "Klaipeda State Seaport",
    portContinent: "Europe",
  },
  {
    lat: "54.8985207",
    lng: "23.9035965",
    country: "LT",
    country_long: "Lithuania",
    portCode: "LTKUN",
    portName: "KAUNAS",
    portContinent: "Europe",
  },
  {
    lat: "55.7347915",
    lng: "24.3574773",
    country: "LT",
    country_long: "Lithuania",
    portCode: "LTPNV",
    portName: "PANEVEZYS",
    portContinent: "Europe",
  },
  {
    lat: "55.9349085",
    lng: "23.3136823",
    country: "LT",
    country_long: "Lithuania",
    portCode: "LTSQQ",
    portName: "Siauliai",
    portContinent: "Europe",
  },
  {
    lat: "54.6871555",
    lng: "25.2796514",
    country: "LT",
    country_long: "Lithuania",
    portCode: "LTVNO",
    portName: "VILNIUS",
    portContinent: "Europe",
  },
  {
    lat: "49.5192118",
    lng: "6.1002461",
    country: "LU",
    country_long: "Luxembourg",
    portCode: "LUBET",
    portName: "BETTEMBOURG (LUBET), Luxembourg, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.815273",
    lng: "6.129582999999999",
    country: "LU",
    country_long: "Luxembourg",
    portCode: "LULUX",
    portName: "Luxembourg",
    portContinent: "Europe",
  },
  {
    lat: "49.7004722",
    lng: "6.4813556",
    country: "LU",
    country_long: "Luxembourg",
    portCode: "LUMRT",
    portName: "Mertert",
    portContinent: "Europe",
  },
  {
    lat: "57.02629169999999",
    lng: "24.128268",
    country: "LV",
    country_long: "Latvia",
    portCode: "LVBMT",
    portName: "Baltmarine Terminal, Riga",
    portContinent: "Europe",
  },
  {
    lat: "55.874736",
    lng: "26.536179",
    country: "LV",
    country_long: "Latvia",
    portCode: "LVDGP",
    portName: "DAUGAVPILS",
    portContinent: "Europe",
  },
  {
    lat: "56.5046678",
    lng: "21.010806",
    country: "LV",
    country_long: "Latvia",
    portCode: "LVLPX",
    portName: "Liepaja (LVLPX), Latvia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.330777",
    lng: "23.102146",
    country: "LV",
    country_long: "Latvia",
    portCode: "LVMRX",
    portName: "Mersrags (LVMRX), Latvia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.9575584",
    lng: "24.097531",
    country: "LV",
    country_long: "Latvia",
    portCode: "LVRIX",
    portName: "Freeport of Riga",
    portContinent: "Europe",
  },
  {
    lat: "57.48392459999999",
    lng: "22.7649233",
    country: "LV",
    country_long: "Latvia",
    portCode: "LVROJ",
    portName: "Roja (LVROJ), Latvia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.7581391",
    lng: "24.3543455",
    country: "LV",
    country_long: "Latvia",
    portCode: "LVSAL",
    portName: "Salacgriva (LVSAL), Latvia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.31780339999999",
    lng: "24.4075717",
    country: "LV",
    country_long: "Latvia",
    portCode: "LVSKU",
    portName: "Port of Skulte",
    portContinent: "Europe",
  },
  {
    lat: "57.4008333",
    lng: "21.5394444",
    country: "LV",
    country_long: "Latvia",
    portCode: "LVVNT",
    portName: "Port of Ventspils",
    portContinent: "Europe",
  },
  {
    lat: "31.7579946",
    lng: "25.0792189",
    country: "LY",
    country_long: "Libya",
    portCode: "LYBAR",
    portName: "Bardiyah (LYBAR), Libya, Med",
    portContinent: "North Africa",
  },
  {
    lat: "32.1080993",
    lng: "20.0730038",
    country: "LY",
    country_long: "Libya",
    portCode: "LYBEN",
    portName: "Benghazi (LYBEN), Libya, Med",
    portContinent: "North Africa",
  },
  {
    lat: "32.755613",
    lng: "22.6377432",
    country: "LY",
    country_long: "Libya",
    portCode: "LYDRX",
    portName: "Derna (LYDRX), Libya, Med",
    portContinent: "North Africa",
  },
  {
    lat: "32.6509505",
    lng: "14.2678989",
    country: "LY",
    country_long: "Libya",
    portCode: "LYKHM",
    portName: "Khoms",
    portContinent: "North Africa",
  },
  {
    lat: "32.6509505",
    lng: "14.2678989",
    country: "LY",
    country_long: "Libya",
    portCode: "LYKHO",
    portName: "Khoms (LYKHO), Libya, Med",
    portContinent: "North Africa",
  },
  {
    lat: "30.41",
    lng: "19.57",
    country: "LY",
    country_long: "Libya",
    portCode: "LYLMQ",
    portName: "Marsa Brega",
    portContinent: "North Africa",
  },
  {
    lat: "32.8911858",
    lng: "13.1914343",
    country: "LY",
    country_long: "Libya",
    portCode: "LYMEL",
    portName: "Mellitah (LYMEL), Libya, Med",
    portContinent: "North Africa",
  },
  {
    lat: "26.3351",
    lng: "17.228331",
    country: "LY",
    country_long: "Libya",
    portCode: "LYMHR",
    portName: "Marsa Al Hariga (LYMHR), Libya, Med",
    portContinent: "North Africa",
  },
  {
    lat: "32.3255884",
    lng: "15.0992556",
    country: "LY",
    country_long: "Libya",
    portCode: "LYMRA",
    portName: "Misurata",
    portContinent: "North Africa",
  },
  {
    lat: "30.4808006",
    lng: "18.5463165",
    country: "LY",
    country_long: "Libya",
    portCode: "LYRLA",
    portName: "Ras Lanuf (LYRLA), Libya, Med",
    portContinent: "North Africa",
  },
  {
    lat: "32.8953986",
    lng: "21.9611919",
    country: "LY",
    country_long: "Libya",
    portCode: "LYSOU",
    portName: "Soussah (LYSOU), Libya, Med",
    portContinent: "North Africa",
  },
  {
    lat: "31.189689",
    lng: "16.5701927",
    country: "LY",
    country_long: "Libya",
    portCode: "LYSRT",
    portName: "Sirte (LYSRT), Libya, Med",
    portContinent: "North Africa",
  },
  {
    lat: "32.8279425",
    lng: "13.3818345",
    country: "LY",
    country_long: "Libya",
    portCode: "LYTAG",
    portName: "Tajoura (LYTAG), Libya, Med",
    portContinent: "North Africa",
  },
  {
    lat: "34.4503139",
    lng: "35.8306935",
    country: "LB",
    country_long: "Lebanon",
    portCode: "LYTIP",
    portName: "Port of Tripoli",
    portContinent: "North Africa",
  },
  {
    lat: "32.0681759",
    lng: "23.941751",
    country: "LY",
    country_long: "Libya",
    portCode: "LYTOB",
    portName: "Tobruk (LYTOB), Libya, Med",
    portContinent: "North Africa",
  },
  {
    lat: "32.7630282",
    lng: "12.7364962",
    country: "LY",
    country_long: "Libya",
    portCode: "LYZAW",
    portName: "Az Zawiyah (LYZAW), Libya, Med",
    portContinent: "North Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "LYZUA",
    portName: "Port of Zuara",
    portContinent: "North Africa",
  },
  {
    lat: "30.4277547",
    lng: "-9.5981072",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAAGA",
    portName: "Agadir",
    portContinent: "North Africa",
  },
  {
    lat: "35.2445589",
    lng: "-3.9317468",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAAHU",
    portName: "Al Hoceima (MAAHU), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "35.4646127",
    lng: "-6.0308654",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAASI",
    portName: "Asilah (MAASI), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "33.6037308",
    lng: "-7.5965129",
    country: "MA",
    country_long: "Morocco",
    portCode: "MACAS",
    portName: "Port of Casablanca",
    portContinent: "North Africa",
  },
  {
    lat: "33.2316326",
    lng: "-8.500711599999999",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAELJ",
    portName: "El Jadida (MAELJ), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "31.5084926",
    lng: "-9.7595041",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAESS",
    portName: "Essaouira (MAESS), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "27.8521664",
    lng: "-12.1632718",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAEUN",
    portName: "Laayoune (MAEUN), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "34.0181246",
    lng: "-5.0078451",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAFEZ",
    portName: "Fez/Fes",
    portContinent: "North Africa",
  },
  {
    lat: "31.791702",
    lng: "-7.092619999999999",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAJFL",
    portName: "Jorf Lasfar (MAJFL), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "35.1744271",
    lng: "-6.1473964",
    country: "MA",
    country_long: "Morocco",
    portCode: "MALAR",
    portName: "LARACHE",
    portContinent: "North Africa",
  },
  {
    lat: "33.8730164",
    lng: "-5.5407299",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAMEK",
    portName: "Meknes",
    portContinent: "North Africa",
  },
  {
    lat: "35.733333",
    lng: "-5.633332999999999",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAMEL",
    portName: "MELLOUSSA",
    portContinent: "North Africa",
  },
  {
    lat: "33.6835086",
    lng: "-7.384854699999999",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAMOH",
    portName: "Mohammedia (MAMOH), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "35.1686165",
    lng: "-2.9275836",
    country: "MA",
    country_long: "Morocco",
    portCode: "MANDR",
    portName: "Nador (MANDR), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "34.2540503",
    lng: "-6.5890166",
    country: "MA",
    country_long: "Morocco",
    portCode: "MANNA",
    portName: "Kenitra",
    portContinent: "North Africa",
  },
  {
    lat: "34.681962",
    lng: "-1.900155",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAOUD",
    portName: "oujda",
    portContinent: "North Africa",
  },
  {
    lat: "31.791702",
    lng: "-7.092619999999999",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAPTM",
    portName: "Tanger Med (MAPTM), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "31.6294723",
    lng: "-7.981084500000001",
    country: "MA",
    country_long: "Morocco",
    portCode: "MARAK",
    portName: "Marrakech",
    portContinent: "North Africa",
  },
  {
    lat: "33.9715904",
    lng: "-6.8498129",
    country: "MA",
    country_long: "Morocco",
    portCode: "MARBA",
    portName: "Rabat",
    portContinent: "North Africa",
  },
  {
    lat: "32.3008151",
    lng: "-9.2272033",
    country: "MA",
    country_long: "Morocco",
    portCode: "MASFI",
    portName: "Safi (MASFI), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "29.3701124",
    lng: "-10.1759285",
    country: "MA",
    country_long: "Morocco",
    portCode: "MASII",
    portName: "Sidi Ifni (MASII), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "27.9375382",
    lng: "-12.9262851",
    country: "MA",
    country_long: "Morocco",
    portCode: "MATFY",
    portName: "Tarfaya (MATFY), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "35.7594651",
    lng: "-5.833954299999999",
    country: "MA",
    country_long: "Morocco",
    portCode: "MATNG",
    portName: "Tangier",
    portContinent: "North Africa",
  },
  {
    lat: "28.488889",
    lng: "-11.334167",
    country: "MA",
    country_long: "Morocco",
    portCode: "MATTA",
    portName: "Port of Tan Tan",
    portContinent: "North Africa",
  },
  {
    lat: "35.5888995",
    lng: "-5.3625516",
    country: "MA",
    country_long: "Morocco",
    portCode: "MATTU",
    portName: "Tetouan",
    portContinent: "North Africa",
  },
  {
    lat: "23.7221111",
    lng: "-15.9347384",
    country: "N/A",
    country_long: "N/A",
    portCode: "MAVIL",
    portName: "Dakhla (MAVIL), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "35.0998833",
    lng: "-2.2876645",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAZSA",
    portName: "Saidia (MAZSA), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "33.8509477",
    lng: "-7.060599799999999",
    country: "MA",
    country_long: "Morocco",
    portCode: "MAZSB",
    portName: "Skhirat (MAZSB), Morocco, Med",
    portContinent: "North Africa",
  },
  {
    lat: "45.998916",
    lng: "6.460177",
    country: "FR",
    country_long: "France",
    portCode: "MBPOT",
    portName: "POINTE BLANCHE",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MCMON",
    portName: "Port Hercules",
    portContinent: "Europe",
  },
  {
    lat: "45.4785114",
    lng: "28.1994643",
    country: "MD",
    country_long: "Moldova",
    portCode: "MDGIU",
    portName: "Giurgiulesti (MDGIU), Moldova, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.0104529",
    lng: "28.8638103",
    country: "MD",
    country_long: "Moldova",
    portCode: "MDKIV",
    portName: "CHISINAU",
    portContinent: "Europe",
  },
  {
    lat: "42.09222219999999",
    lng: "19.0844444",
    country: "ME",
    country_long: "Montenegro",
    portCode: "MEBAR",
    portName: "Port of Bar",
    portContinent: "Europe",
  },
  {
    lat: "42.2911489",
    lng: "18.840295",
    country: "ME",
    country_long: "Montenegro",
    portCode: "MEBUD",
    portName: "Budva (MEBUD), Montenegro, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.4572478",
    lng: "18.5314753",
    country: "ME",
    country_long: "Montenegro",
    portCode: "MEHNO",
    portName: "Hercegnovi (MEHNO), Montenegro, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.424662",
    lng: "18.771234",
    country: "ME",
    country_long: "Montenegro",
    portCode: "MEKOT",
    portName: "Kotor (MEKOT), Montenegro, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.4304196",
    lng: "19.2593642",
    country: "ME",
    country_long: "Montenegro",
    portCode: "METGD",
    portName: "PODGORICA",
    portContinent: "Europe",
  },
  {
    lat: "42.4349809",
    lng: "18.7066398",
    country: "ME",
    country_long: "Montenegro",
    portCode: "METIV",
    portName: "Tivat (METIV), Montenegro, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.9310884",
    lng: "19.2147632",
    country: "ME",
    country_long: "Montenegro",
    portCode: "MEULC",
    portName: "Ulcinj (MEULC), Montenegro, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.4540571",
    lng: "18.5789692",
    country: "ME",
    country_long: "Montenegro",
    portCode: "MEZEL",
    portName: "Zelenika (MEZEL), Montenegro, Med",
    portContinent: "Europe",
  },
  {
    lat: "18.0675189",
    lng: "-63.0824656",
    country: "MF",
    country_long: "Saint Martin",
    portCode: "MFMAR",
    portName: "Marigot (MFMAR), Saint Martin, LatAm",
    portContinent: "North America",
  },
  {
    lat: "-14.9061237",
    lng: "50.2785486",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGANM",
    portName: "Port of Antalaha",
    portContinent: "Africa",
  },
  {
    lat: "-12.3231349",
    lng: "49.2942833",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGDIE",
    portName: "Antsiranna (MGDIE)/Diego Suarez, Madagascar, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-25.0667273",
    lng: "46.9630766",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGEHL",
    portName: "Ehola (MGEHL), Madagascar, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-25.0225309",
    lng: "46.9853688",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGFTU",
    portName: "FORT DAUPHIN (MGFTU), Madagascar, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-15.6921494",
    lng: "46.3336857",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGMJN",
    portName: "Majunga (MGMJN), Madagascar, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-21.2245142",
    lng: "48.3464426",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGMNJ",
    portName: "Port of Mananjary",
    portContinent: "Africa",
  },
  {
    lat: "-20.2904186",
    lng: "44.2999955",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGMOQ",
    portName: "Port of Morondava",
    portContinent: "Africa",
  },
  {
    lat: "-13.3150186",
    lng: "48.2592606",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGNOS",
    portName: "NOSSI BE (MGNOS), Madagascar, Africa",
    portContinent: "Africa",
  },
  {
    lat: "43.387847",
    lng: "4.803224999999999",
    country: "FR",
    country_long: "France",
    portCode: "MGPSL",
    portName: "Port of Port Saint Louis",
    portContinent: "Africa",
  },
  {
    lat: "-23.3516191",
    lng: "43.6854936",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGTLE",
    portName: "Tulear (MGTLE), Madagascar, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-18.1442811",
    lng: "49.3957836",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGTMM",
    portName: "Tamatave (MGTMM), Toamasina, Madagascar",
    portContinent: "Africa",
  },
  {
    lat: "-18.8791902",
    lng: "47.5079055",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGTNR",
    portName: "ANTANANARIVO (MGTNR), Antananarivo, Madagascar",
    portContinent: "Africa",
  },
  {
    lat: "-18.1206797",
    lng: "49.4024588",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGTOA",
    portName: "Port of Toamasina",
    portContinent: "Africa",
  },
  {
    lat: "-13.3702827",
    lng: "50.0033677",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGVOH",
    portName: "VOHEMAR (MGVOH), Madagascar, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-22.1432288",
    lng: "48.00598249999999",
    country: "MG",
    country_long: "Madagascar",
    portCode: "MGWVK",
    portName: "Manakara (MGWVK), Madagascar, Africa",
    portContinent: "Africa",
  },
  {
    lat: "10.304809",
    lng: "169.9416549",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHAIM",
    portName: "Ailuk Island (MHAIM), Marshall Islands, usa",
    portContinent: "Oceania",
  },
  {
    lat: "7.0833333",
    lng: "171.6833332",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHAMR",
    portName: "Arno (MHAMR), Marshall Islands, usa",
    portContinent: "Oceania",
  },
  {
    lat: "11.5226384",
    lng: "165.5661513",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHBII",
    portName: "Enyu Airfield (MHBII), Marshall Islands, usa",
    portContinent: "Oceania",
  },
  {
    lat: "11.4653625",
    lng: "162.189",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHENT",
    portName: "Enewetak Island (MHENT), Marshall Islands, usa",
    portContinent: "Oceania",
  },
  {
    lat: "8.716667",
    lng: "167.733333",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHKWA",
    portName: "Port of Kwajalein",
    portContinent: "Oceania",
  },
  {
    lat: "9.945",
    lng: "169.1541667",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHLIK",
    portName: "Likiep Island (MHLIK), Marshall Islands, usa",
    portContinent: "Oceania",
  },
  {
    lat: "7.066667",
    lng: "171.266667",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHMAJ",
    portName: "Port of Majuro",
    portContinent: "Oceania",
  },
  {
    lat: "8.7735171",
    lng: "171.0312989",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHMAV",
    portName: "Maloelap Island (MHMAV), Marshall Islands, usa",
    portContinent: "Oceania",
  },
  {
    lat: "6.1177778",
    lng: "171.7355556",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHMIJ",
    portName: "Mili Island (MHMIJ), Marshall Islands, usa",
    portContinent: "Oceania",
  },
  {
    lat: "10.2862061",
    lng: "170.8709023",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHMJB",
    portName: "Mejit Island (MHMJB), Marshall Islands, usa",
    portContinent: "Oceania",
  },
  {
    lat: "7.9761111",
    lng: "168.1402778",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHNMU",
    portName: "Namu (MHNMU), Marshall Islands, usa",
    portContinent: "Oceania",
  },
  {
    lat: "8.7814708",
    lng: "167.7373396",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHQEE",
    portName: "Ebeye",
    portContinent: "Oceania",
  },
  {
    lat: "11.3603915",
    lng: "166.7798172",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHRNP",
    portName: "Rongelap Island (MHRNP), Marshall Islands, usa",
    portContinent: "Oceania",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "MHUTK",
    portName: "Utirik Island (MHUTK), Marshall Islands, usa",
    portContinent: "Oceania",
  },
  {
    lat: "9.448085299999999",
    lng: "170.0534923",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHWTE",
    portName: "Wotje Island (MHWTE), Marshall Islands, usa",
    portContinent: "Oceania",
  },
  {
    lat: "10.1",
    lng: "165.9833333",
    country: "MH",
    country_long: "Marshall Islands",
    portCode: "MHWTO",
    portName: "Wotho Island (MHWTO), Marshall Islands, usa",
    portContinent: "Oceania",
  },
  {
    lat: "41.9981294",
    lng: "21.4254355",
    country: "MK",
    country_long: "North Macedonia",
    portCode: "MKSKP",
    portName: "Skopje",
    portContinent: "Europe",
  },
  {
    lat: "12.5953663",
    lng: "-8.0247674",
    country: "ML",
    country_long: "Mali",
    portCode: "MLBKO",
    portName: "BAMAKO (MLBKO), Bamako, Mali",
    portContinent: "North Africa",
  },
  {
    lat: "14.4393446",
    lng: "-11.4467589",
    country: "ML",
    country_long: "Mali",
    portCode: "MLKYS",
    portName: "KAYES (MLKYS), Kayes, Mali",
    portContinent: "North Africa",
  },
  {
    lat: "11.3223834",
    lng: "-5.6983979",
    country: "ML",
    country_long: "Mali",
    portCode: "MLSIK",
    portName: "SIKASSO (MLSIK), Sikasso, Mali",
    portContinent: "North Africa",
  },
  {
    lat: "19.330538",
    lng: "72.8155814",
    country: "IN",
    country_long: "India",
    portCode: "MMBSX",
    portName: "Port of Bassein",
    portContinent: "South East Asia",
  },
  {
    lat: "19.4212029",
    lng: "93.5458489",
    country: "MM",
    country_long: "Myanmar (Burma)",
    portCode: "MMKYP",
    portName: "Kyaukpyu (MMKYP), Myanmar [Burma], Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "16.840939",
    lng: "96.173526",
    country: "MM",
    country_long: "Myanmar (Burma)",
    portCode: "MMRGN",
    portName: "Port of Yangon",
    portContinent: "South East Asia",
  },
  {
    lat: "16.6335252",
    lng: "96.27198899999999",
    country: "MM",
    country_long: "Myanmar (Burma)",
    portCode: "MMTLA",
    portName: "Thilawa",
    portContinent: "South East Asia",
  },
  {
    lat: "16.840939",
    lng: "96.173526",
    country: "MM",
    country_long: "Myanmar (Burma)",
    portCode: "MMYAN",
    portName: "Yangon",
    portContinent: "South East Asia",
  },
  {
    lat: "47.88639879999999",
    lng: "106.9057439",
    country: "MN",
    country_long: "Mongolia",
    portCode: "MNULN",
    portName: "Ulan Bator",
    portContinent: "Asia",
  },
  {
    lat: "43.7152148",
    lng: "111.9041048",
    country: "MN",
    country_long: "Mongolia",
    portCode: "MNZUU",
    portName: "Zamyn-Uud",
    portContinent: "Asia",
  },
  {
    lat: "22.198745",
    lng: "113.543873",
    country: "MO",
    country_long: "Macao",
    portCode: "MOMFM",
    portName: "Macau",
    portContinent: "Asia",
  },
  {
    lat: "15.1850483",
    lng: "145.7467259",
    country: "MP",
    country_long: "Northern Mariana Islands",
    portCode: "MPSPN",
    portName: "SAIPAN (MPSPN), Northern Mariana Islands, Asia",
    portContinent: "Oceania",
  },
  {
    lat: "14.6060576",
    lng: "-61.06988399999999",
    country: "MQ",
    country_long: "Martinique",
    portCode: "MQFDF",
    portName: "Port of Fort-de-France",
    portContinent: "North America",
  },
  {
    lat: "20.9425179",
    lng: "-17.0362272",
    country: "MR",
    country_long: "Mauritania",
    portCode: "MRNDB",
    portName: "Port of Nouadhibou",
    portContinent: "North Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MRNKC",
    portName: "Port of Nouakchott",
    portContinent: "North Africa",
  },
  {
    lat: "16.7909044",
    lng: "-62.2106241",
    country: "MS",
    country_long: "Montserrat",
    portCode: "MSBRD",
    portName: "Brades sea",
    portContinent: "North America",
  },
  {
    lat: "-33.9816553",
    lng: "151.2403567",
    country: "AU",
    country_long: "Australia",
    portCode: "MSLTB",
    portName: "Port of Little Bay",
    portContinent: "North America",
  },
  {
    lat: "16.742498",
    lng: "-62.187366",
    country: "MS",
    country_long: "Montserrat",
    portCode: "MSMNI",
    portName: "Montserrat",
    portContinent: "North America",
  },
  {
    lat: "50.3754565",
    lng: "-4.1426565",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "MSPLY",
    portName: "Port of Plymouth",
    portContinent: "North America",
  },
  {
    lat: "35.9483715",
    lng: "14.4109558",
    country: "MT",
    country_long: "Malta",
    portCode: "MTBUG",
    portName: "Bugibba (MTBUG), Malta, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.888093",
    lng: "14.4068357",
    country: "MT",
    country_long: "Malta",
    portCode: "MTDIS",
    portName: "Malta Freeport Distripark (MTDIS), Malta, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.937496",
    lng: "14.375416",
    country: "MT",
    country_long: "Malta",
    portCode: "MTMAL",
    portName: "Malta",
    portContinent: "Europe",
  },
  {
    lat: "35.8411699",
    lng: "14.5393097",
    country: "MT",
    country_long: "Malta",
    portCode: "MTMAR",
    portName: "Marsaxlokk",
    portContinent: "Europe",
  },
  {
    lat: "35.9189327",
    lng: "14.3617343",
    country: "MT",
    country_long: "Malta",
    portCode: "MTMGR",
    portName: "Mgarr (MTMGR), Malta, Med",
    portContinent: "Europe",
  },
  {
    lat: "35.8917469",
    lng: "14.5100038",
    country: "MT",
    country_long: "Malta",
    portCode: "MTMLA",
    portName: "Port of Valletta",
    portContinent: "Europe",
  },
  {
    lat: "35.8895125",
    lng: "14.3951953",
    country: "MT",
    country_long: "Malta",
    portCode: "MTMRF",
    portName: "Cirkewwa (MTMRF), Malta, Med",
    portContinent: "Europe",
  },
  {
    lat: "42.9738332",
    lng: "-73.8054215",
    country: "US",
    country_long: "United States",
    portCode: "MTMSX",
    portName: "Marsamxett (MTMSX), Malta, Med",
    portContinent: "Europe",
  },
  {
    lat: "-20.1608912",
    lng: "57.5012222",
    country: "MU",
    country_long: "Mauritius",
    portCode: "MUPLU",
    portName: "Port Louis",
    portContinent: "Africa",
  },
  {
    lat: "-19.6795306",
    lng: "63.42869289999999",
    country: "MU",
    country_long: "Mauritius",
    portCode: "MUPMA",
    portName: "PORT MATHURIN (MUPMA), Mauritius, Africa",
    portContinent: "Africa",
  },
  {
    lat: "4.179413800000001",
    lng: "73.5052975",
    country: "MV",
    country_long: "Maldives",
    portCode: "MVMLE",
    portName: "Port of Male",
    portContinent: "Asia",
  },
  {
    lat: "-15.7666707",
    lng: "35.0167866",
    country: "MW",
    country_long: "Malawi",
    portCode: "MWBLZ",
    portName: "Blantyre (MWBLZ), Blantyre, Malawi",
    portContinent: "Africa",
  },
  {
    lat: "-9.7037655",
    lng: "33.2700253",
    country: "MW",
    country_long: "Malawi",
    portCode: "MWCTP",
    portName: "Chitipa",
    portContinent: "Africa",
  },
  {
    lat: "-13.9626121",
    lng: "33.7741195",
    country: "MW",
    country_long: "Malawi",
    portCode: "MWLLW",
    portName: "LILONGWE (MWLLW), Lilongwe, Malawi",
    portContinent: "Africa",
  },
  {
    lat: "-11.4389649",
    lng: "34.0084395",
    country: "MW",
    country_long: "Malawi",
    portCode: "MWZZU",
    portName: "MZUZU",
    portContinent: "Africa",
  },
  {
    lat: "23.634501",
    lng: "-102.552784",
    country: "MX",
    country_long: "Mexico",
    portCode: "MX000",
    portName: "Mexican port",
    portContinent: "North America",
  },
  {
    lat: "19.4968732",
    lng: "-99.72326729999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MX001",
    portName: "EDO MEXICO",
    portContinent: "North America",
  },
  {
    lat: "20.6243088",
    lng: "-103.3212248",
    country: "MX",
    country_long: "Mexico",
    portCode: "MX7TL",
    portName: "Tlaquepaque",
    portContinent: "North America",
  },
  {
    lat: "16.8531086",
    lng: "-99.8236533",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXACA",
    portName: "Port of Acapulco",
    portContinent: "North America",
  },
  {
    lat: "19.6420614",
    lng: "-98.9107373",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXACM",
    portName: "Acolman",
    portContinent: "North America",
  },
  {
    lat: "19.5562275",
    lng: "-99.2674713",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXADZ",
    portName: "ATIZAPAN DE ZARAGOZA",
    portContinent: "North America",
  },
  {
    lat: "21.8852562",
    lng: "-102.2915677",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXAGU",
    portName: "Aguascalientes",
    portContinent: "North America",
  },
  {
    lat: "25.4546718",
    lng: "-100.850791",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXAHO",
    portName: "Arteaga",
    portContinent: "North America",
  },
  {
    lat: "19.043829",
    lng: "-98.0437894",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXAMC",
    portName: "Amozoc",
    portContinent: "North America",
  },
  {
    lat: "20.5476288",
    lng: "-104.0466211",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXAME",
    portName: "Ameca",
    portContinent: "North America",
  },
  {
    lat: "25.776468",
    lng: "-100.1858743",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXAPD",
    portName: "Apodaca",
    portContinent: "North America",
  },
  {
    lat: "19.9727119",
    lng: "-99.1734048",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXAPX",
    portName: "Apaxco",
    portContinent: "North America",
  },
  {
    lat: "20.705519",
    lng: "-102.346916",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXARA",
    portName: "Arandas",
    portContinent: "North America",
  },
  {
    lat: "19.0294848",
    lng: "-98.77841",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXATL",
    portName: "Atlautla",
    portContinent: "North America",
  },
  {
    lat: "22.4445185",
    lng: "-97.8881124",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXATM",
    portName: "Puerto de Altamira",
    portContinent: "North America",
  },
  {
    lat: "32.4065312",
    lng: "-97.2116782",
    country: "US",
    country_long: "United States",
    portCode: "MXAVD",
    portName: "Port of Alvarado",
    portContinent: "North America",
  },
  {
    lat: "19.7240495",
    lng: "-98.75824929999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXAXA",
    portName: "Axapusco",
    portContinent: "North America",
  },
  {
    lat: "19.129935",
    lng: "-98.802258",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXAYA",
    portName: "Ayapango",
    portContinent: "North America",
  },
  {
    lat: "19.5562275",
    lng: "-99.2674713",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXAZA",
    portName: "Atizapan de Zaragoza",
    portContinent: "North America",
  },
  {
    lat: "19.2081099",
    lng: "-104.6826949",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXBDN",
    portName: "Barra De Navidad (MXBDN), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "19.106273",
    lng: "-96.10632040000002",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXBDR",
    portName: "Boca Del Rio (MXBDR), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "19.6326046",
    lng: "-99.1090105",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXBEZ",
    portName: "Coacalco de Berriozabal",
    portContinent: "North America",
  },
  {
    lat: "21.2",
    lng: "-91.96666669999999",
    country: "N/A",
    country_long: "N/A",
    portCode: "MXCAT",
    portName: "Cayo Arcas Terminal",
    portContinent: "North America",
  },
  {
    lat: "19.5610249",
    lng: "-98.8995504",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCCC",
    portName: "Chiconcuac de Juarez",
    portContinent: "North America",
  },
  {
    lat: "25.9543957",
    lng: "-100.166333",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCDF",
    portName: "Cienega de Flores",
    portContinent: "North America",
  },
  {
    lat: "25.6748291",
    lng: "-100.2155063",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCDG",
    portName: "CIUDAD GUADELOUPE",
    portContinent: "North America",
  },
  {
    lat: "19.4143591",
    lng: "-98.9062442",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCDJ",
    portName: "Chicoloapan de Juarez",
    portContinent: "North America",
  },
  {
    lat: "37.8881751",
    lng: "-4.7793835",
    country: "ES",
    country_long: "Spain",
    portCode: "MXCDU",
    portName: "CORDOBA",
    portContinent: "North America",
  },
  {
    lat: "22.0025712",
    lng: "-99.0018424",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCDV",
    portName: "CIUDAD VALLES",
    portContinent: "North America",
  },
  {
    lat: "27.4827729",
    lng: "-109.930367",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCEN",
    portName: "CIUDAD DE OBREGON (SONORA)",
    portContinent: "North America",
  },
  {
    lat: "20.8593042",
    lng: "-90.3972052",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCET",
    portName: "Calastun (MXCET), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "19.5490592",
    lng: "-98.8831506",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCHI",
    portName: "Chiautla",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MXCHT",
    portName: "Cuauhtemoc",
    portContinent: "North America",
  },
  {
    lat: "31.6903638",
    lng: "-106.4245478",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCJS",
    portName: "Ciudad Juarez",
    portContinent: "North America",
  },
  {
    lat: "20.5279612",
    lng: "-100.8112885",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCLY",
    portName: "Celaya",
    portContinent: "North America",
  },
  {
    lat: "18.6504879",
    lng: "-91.80745859999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCME",
    portName: "Port of Ciudad del Carmen",
    portContinent: "North America",
  },
  {
    lat: "19.4314047",
    lng: "-98.95820479999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCMH",
    portName: "Chimalhuacan",
    portContinent: "North America",
  },
  {
    lat: "25.9543957",
    lng: "-100.166333",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCNF",
    portName: "CIENEGA DE FLORES",
    portContinent: "North America",
  },
  {
    lat: "23.634501",
    lng: "-102.552784",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCNT",
    portName: "Cantarell (MXCNT), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MXCOA",
    portName: "Puerto de Coatzacoalcos",
    portContinent: "North America",
  },
  {
    lat: "19.2314995",
    lng: "-98.8666032",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCOC",
    portName: "Cocotitlan",
    portContinent: "North America",
  },
  {
    lat: "19.2452342",
    lng: "-103.7240868",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCOL",
    portName: "COLIMA",
    portContinent: "North America",
  },
  {
    lat: "18.714029",
    lng: "-87.708057",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCOM",
    portName: "Costa Maya (MXCOM), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "19.7765924",
    lng: "-99.20928959999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCOY",
    portName: "Coyotepec",
    portContinent: "North America",
  },
  {
    lat: "19.8301251",
    lng: "-90.5349087",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCPE",
    portName: "Port of Campeche",
    portContinent: "North America",
  },
  {
    lat: "19.7758168",
    lng: "-98.57593020000002",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCSG",
    portName: "CIUDAD SAHAGUN",
    portContinent: "North America",
  },
  {
    lat: "22.8788929",
    lng: "-109.9074626",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCSL",
    portName: "Port of Cabo San Lucas",
    portContinent: "North America",
  },
  {
    lat: "18.8126047",
    lng: "-98.9548261",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCTL",
    portName: "Cuautla",
    portContinent: "North America",
  },
  {
    lat: "18.5001889",
    lng: "-88.296146",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCTM",
    portName: "Chetumal (MXCTM), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "19.6726588",
    lng: "-99.1648692",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCTN",
    portName: "Cuautitlan",
    portContinent: "North America",
  },
  {
    lat: "24.8684207",
    lng: "-103.6972217",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCUE",
    portName: "CUENCAME",
    portContinent: "North America",
  },
  {
    lat: "24.8090649",
    lng: "-107.3940117",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCUL",
    portName: "CULIACAN",
    portContinent: "North America",
  },
  {
    lat: "21.0417033",
    lng: "-86.8740062",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCUN",
    portName: "Cancun (MXCUN), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "19.6528089",
    lng: "-99.22313869999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCUT",
    portName: "Cuautitlan Izcalli",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MXCUU",
    portName: "Chihuahua",
    portContinent: "North America",
  },
  {
    lat: "18.9242095",
    lng: "-99.22156590000002",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCVC",
    portName: "Cuernavaca",
    portContinent: "North America",
  },
  {
    lat: "23.7369164",
    lng: "-99.14111539999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCVM",
    portName: "Ciudad Victoria",
    portContinent: "North America",
  },
  {
    lat: "20.4229839",
    lng: "-86.9223432",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXCZM",
    portName: "Port of Cozumel",
    portContinent: "North America",
  },
  {
    lat: "19.0834735",
    lng: "-96.1467337",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXDBT",
    portName: "Dos Bocas (MXDBT), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "24.0277202",
    lng: "-104.6531759",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXDGO",
    portName: "DURANGO (DURANGO)",
    portContinent: "North America",
  },
  {
    lat: "25.8002233",
    lng: "-100.3122083",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXECB",
    portName: "Escobedo",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MXELS",
    portName: "El Salto",
    portContinent: "North America",
  },
  {
    lat: "19.6057728",
    lng: "-99.03651119999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXEPC",
    portName: "Ecatepec",
    portContinent: "North America",
  },
  {
    lat: "25.8002233",
    lng: "-100.3122083",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXESC",
    portName: "ESCOBEDO",
    portContinent: "North America",
  },
  {
    lat: "31.8667427",
    lng: "-116.5963713",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXESE",
    portName: "Puerto de Ensenada",
    portContinent: "North America",
  },
  {
    lat: "31.896313",
    lng: "-116.6996104",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXESZ",
    portName: "El Sauzal (MXESZ), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "19.6057728",
    lng: "-99.03651119999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXETP",
    portName: "ECATEPEC",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MXFRN",
    portName: "Port of Frontera",
    portContinent: "North America",
  },
  {
    lat: "20.6864334",
    lng: "-103.318211",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXGDL",
    portName: "GUADALAJARA (MXGDL), Guadalajara, Mexico",
    portContinent: "North America",
  },
  {
    lat: "25.2583781",
    lng: "-99.68147479999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXGET",
    portName: "General Teran",
    portContinent: "North America",
  },
  {
    lat: "29.05252029999999",
    lng: "-118.2760503",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXGLP",
    portName: "Guadalupe",
    portContinent: "North America",
  },
  {
    lat: "25.5891476",
    lng: "-103.485862",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXGMP",
    portName: "GOMEZ PALACIO",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MXGNL",
    portName: "Garcia",
    portContinent: "North America",
  },
  {
    lat: "25.5891476",
    lng: "-103.485862",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXGOM",
    portName: "Gomez Palacio",
    portContinent: "North America",
  },
  {
    lat: "21.0190145",
    lng: "-101.2573586",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXGUA",
    portName: "GUANAJUATO",
    portContinent: "North America",
  },
  {
    lat: "27.9591758",
    lng: "-114.056646",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXGUB",
    portName: "Guerero Negro (MXGUB), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "27.9178651",
    lng: "-110.9089378",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXGYM",
    portName: "Puerto de Guaymas",
    portContinent: "North America",
  },
  {
    lat: "20.0910963",
    lng: "-98.7623874",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXHDG",
    portName: "HIDALGO",
    portContinent: "North America",
  },
  {
    lat: "19.8277305",
    lng: "-99.2037508",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXHHA",
    portName: "Huehuetoca",
    portContinent: "North America",
  },
  {
    lat: "19.1589453",
    lng: "-98.40471629999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXHJZ",
    portName: "Huejotzingo",
    portContinent: "North America",
  },
  {
    lat: "29.0729673",
    lng: "-110.9559192",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXHMO",
    portName: "Hermosillo",
    portContinent: "North America",
  },
  {
    lat: "19.3602606",
    lng: "-99.3510312",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXHXC",
    portName: "Huixquilucan",
    portContinent: "North America",
  },
  {
    lat: "20.6786652",
    lng: "-101.3544964",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXIRP",
    portName: "Irapuato",
    portContinent: "North America",
  },
  {
    lat: "21.2321799",
    lng: "-86.73409339999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXISJ",
    portName: "Isla Mujeres (MXISJ), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "19.750852",
    lng: "-99.0930528",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXJAL",
    portName: "Jaltenco",
    portContinent: "North America",
  },
  {
    lat: "19.9516359",
    lng: "-99.536755",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXJIL",
    portName: "Jilotepec",
    portContinent: "North America",
  },
  {
    lat: "18.8954874",
    lng: "-99.1732936",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXJIU",
    portName: "Jiutepec",
    portContinent: "North America",
  },
  {
    lat: "20.3475108",
    lng: "-103.1942224",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXJLM",
    portName: "Ixtlahuacan de los Membrillos",
    portContinent: "North America",
  },
  {
    lat: "19.9516359",
    lng: "-99.536755",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXJPC",
    portName: "Jilotepec",
    portContinent: "North America",
  },
  {
    lat: "20.5085984",
    lng: "-103.1697093",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXJUL",
    portName: "Juanacatlan",
    portContinent: "North America",
  },
  {
    lat: "25.6516471",
    lng: "-100.1059194",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXJUZ",
    portName: "Ciudad Benito Juarez ï¼ˆJuarez, NL)",
    portContinent: "North America",
  },
  {
    lat: "19.0414398",
    lng: "-98.2062727",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXK6R",
    portName: "Puebla",
    portContinent: "North America",
  },
  {
    lat: "-16.489689",
    lng: "-68.11929359999999",
    country: "BO",
    country_long: "Bolivia",
    portCode: "MXLAP",
    portName: "Port of La Paz",
    portContinent: "North America",
  },
  {
    lat: "22.8905327",
    lng: "-109.9167371",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXLCB",
    portName: "Los Cabos (MXLCB), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "21.1250077",
    lng: "-101.6859605",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXLEN",
    portName: "LEON,MX",
    portContinent: "North America",
  },
  {
    lat: "42.0254298",
    lng: "-3.7600156",
    country: "ES",
    country_long: "Spain",
    portCode: "MXLER",
    portName: "LERMA",
    portContinent: "North America",
  },
  {
    lat: "25.7904657",
    lng: "-108.985882",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXLMM",
    portName: "LOS MOCHIS",
    portContinent: "North America",
  },
  {
    lat: "26.9080378",
    lng: "-101.4215236",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXLOV",
    portName: "MONCLOVA",
    portContinent: "North America",
  },
  {
    lat: "26.0117564",
    lng: "-111.3477531",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXLTO",
    portName: "Loreto (MXLTO), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "17.927",
    lng: "-102.169",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXLZC",
    portName: "Puerto de Lazaro Cardenas",
    portContinent: "North America",
  },
  {
    lat: "25.8690294",
    lng: "-97.50273759999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXMAM",
    portName: "MATAMOROS",
    portContinent: "North America",
  },
  {
    lat: "20.9673702",
    lng: "-89.5925857",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXMDA",
    portName: "Merida (MXMDA), Merida, Mexico",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MXMEL",
    portName: "Melchor Ocampo",
    portContinent: "North America",
  },
  {
    lat: "19.4326077",
    lng: "-99.133208",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXMEX",
    portName: "Mexico City",
    portContinent: "North America",
  },
  {
    lat: "20.9673702",
    lng: "-89.5925857",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXMID",
    portName: "MERIDA",
    portContinent: "North America",
  },
  {
    lat: "22.3384943",
    lng: "-97.87006389999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXMIR",
    portName: "Miramar (MXMIR), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "19.7059504",
    lng: "-101.1949825",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXMLM",
    portName: "MORELIA",
    portContinent: "North America",
  },
  {
    lat: "20.1264558",
    lng: "-101.1933423",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXMOR",
    portName: "MOROLEON",
    portContinent: "North America",
  },
  {
    lat: "-31.5914564",
    lng: "-52.6484299",
    country: "BR",
    country_long: "Brazil",
    portCode: "MXMRE",
    portName: "Port of Morro Redondo",
    portContinent: "North America",
  },
  {
    lat: "17.9939225",
    lng: "-94.5466067",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXMTT",
    portName: "Port of Minatitlan",
    portContinent: "North America",
  },
  {
    lat: "25.6866142",
    lng: "-100.3161126",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXMTY",
    portName: "Monterrey",
    portContinent: "North America",
  },
  {
    lat: "32.6245389",
    lng: "-115.4522623",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXMXL",
    portName: "Mexicali",
    portContinent: "North America",
  },
  {
    lat: "23.2494148",
    lng: "-106.4111425",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXMZT",
    portName: "Puerto de Mazatlan",
    portContinent: "North America",
  },
  {
    lat: "18.0714601",
    lng: "-94.40829529999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXNAN",
    portName: "Port of Nanchital",
    portContinent: "North America",
  },
  {
    lat: "19.4737133",
    lng: "-99.2336657",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXNDJ",
    portName: "Naucalpan de Juarez",
    portContinent: "North America",
  },
  {
    lat: "19.3994934",
    lng: "-98.9896643",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXNHY",
    portName: "Nezahualcoyotl",
    portContinent: "North America",
  },
  {
    lat: "27.4779362",
    lng: "-99.549573",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXNLD",
    portName: "NUEVO LAREDO (MXNLD), Nuevo Laredo, Mexico",
    portContinent: "North America",
  },
  {
    lat: "31.3011855",
    lng: "-110.9381047",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXNOG",
    portName: "NOGALES (MEXICO)",
    portContinent: "North America",
  },
  {
    lat: "19.6162033",
    lng: "-99.30011809999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXNRO",
    portName: "Nicolas Romero",
    portContinent: "North America",
  },
  {
    lat: "27.4827729",
    lng: "-109.930367",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXOBR",
    portName: "Obregon",
    portContinent: "North America",
  },
  {
    lat: "18.8504744",
    lng: "-97.1036396",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXORI",
    portName: "ORIZABA (VERACRUZ)",
    portContinent: "North America",
  },
  {
    lat: "19.6988525",
    lng: "-98.75751",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXOTU",
    portName: "Otumba",
    portContinent: "North America",
  },
  {
    lat: "19.2388424",
    lng: "-97.7677154",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXP7E",
    portName: "Jose Chiapa",
    portContinent: "North America",
  },
  {
    lat: "20.1010608",
    lng: "-98.7591311",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPAC",
    portName: "Pachuca de Soto",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MXPAJ",
    portName: "Port of Pajaritos",
    portContinent: "North America",
  },
  {
    lat: "19.5563141",
    lng: "-98.8555736",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPAP",
    portName: "Papalotla",
    portContinent: "North America",
  },
  {
    lat: "19.0414398",
    lng: "-98.2062727",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPBC",
    portName: "Puebla",
    portContinent: "North America",
  },
  {
    lat: "20.6359618",
    lng: "-87.0631162",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPCM",
    portName: "Port of Playa del Carmen",
    portContinent: "North America",
  },
  {
    lat: "19.704346",
    lng: "-98.8347275",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPDP",
    portName: "San Martin de las Piramides",
    portContinent: "North America",
  },
  {
    lat: "15.8719795",
    lng: "-97.0767365",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPES",
    portName: "Puerto Escondido (MXPES), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "21.2811908",
    lng: "-89.66516279999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPGO",
    portName: "Puerto de Progreso",
    portContinent: "North America",
  },
  {
    lat: "24.2711935",
    lng: "-110.3220155",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPIC",
    portName: "Port of Pichilingue",
    portContinent: "North America",
  },
  {
    lat: "14.7193519",
    lng: "-92.4255775",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPMD",
    portName: "Puerto Chiapas",
    portContinent: "North America",
  },
  {
    lat: "20.8478084",
    lng: "-86.87553419999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPMS",
    portName: "Puerto Morelos",
    portContinent: "North America",
  },
  {
    lat: "15.6680076",
    lng: "-96.49131369999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPNG",
    portName: "Puerto Angel (MXPNG), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "31.3268218",
    lng: "-113.5311843",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPPE",
    portName: "Puerto Penasco (MXPPE), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "25.592172",
    lng: "-99.99619469999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPSQ",
    portName: "Nuevo Leon",
    portContinent: "North America",
  },
  {
    lat: "20.6315981",
    lng: "-87.07819099999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPVN",
    portName: "Calica (MXPVN), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "20.653407",
    lng: "-105.2253316",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPVR",
    portName: "Puerto Vallarta",
    portContinent: "North America",
  },
  {
    lat: "15.8719795",
    lng: "-97.0767365",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXPXM",
    portName: "Puerto Escondido",
    portContinent: "North America",
  },
  {
    lat: "20.5887932",
    lng: "-100.3898881",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXQRO",
    portName: "QUERETARO (MXQRO), Queretaro, Mexico",
    portContinent: "North America",
  },
  {
    lat: "26.0508406",
    lng: "-98.29789509999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXREX",
    portName: "Reynosa",
    portContinent: "North America",
  },
  {
    lat: "25.5411499",
    lng: "-100.9483074",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXRMA",
    portName: "RAMOS ARIZPE",
    portContinent: "North America",
  },
  {
    lat: "32.3661011",
    lng: "-117.0617553",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXRST",
    portName: "Rosarito Terminal",
    portContinent: "North America",
  },
  {
    lat: "42.3709599",
    lng: "-5.029949300000001",
    country: "ES",
    country_long: "Spain",
    portCode: "MXSAH",
    portName: "Sahagun",
    portContinent: "North America",
  },
  {
    lat: "19.73204",
    lng: "-99.067544",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSAN",
    portName: "Nextlalpan",
    portContinent: "North America",
  },
  {
    lat: "25.9662105",
    lng: "-100.2908632",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSAV",
    portName: "Salinas Victoria",
    portContinent: "North America",
  },
  {
    lat: "-51.5032499",
    lng: "-58.9947612",
    country: "FK",
    country_long: "Falkland Islands (Islas Malvinas)",
    portCode: "MXSCR",
    portName: "Port of San Carlos",
    portContinent: "North America",
  },
  {
    lat: "16.1842839",
    lng: "-95.20876249999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSCX",
    portName: "Puerto de Salina Cruz",
    portContinent: "North America",
  },
  {
    lat: "31.0250709",
    lng: "-114.8407776",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSFH",
    portName: "San Felipe (MXSFH), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "21.0170828",
    lng: "-101.8492577",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSFR",
    portName: "SAN FRANSISCO DEL RINCON (GUANAJUATO)",
    portContinent: "North America",
  },
  {
    lat: "20.952141",
    lng: "-101.4282369",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSIL",
    portName: "Silao",
    portContinent: "North America",
  },
  {
    lat: "-40.5107919",
    lng: "-73.4128272",
    country: "CL",
    country_long: "Chile",
    portCode: "MXSJC",
    portName: "San Juan de la Costa",
    portContinent: "North America",
  },
  {
    lat: "20.998668",
    lng: "-100.386287",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSJI",
    portName: "San Jose Iturbide",
    portContinent: "North America",
  },
  {
    lat: "20.3951106",
    lng: "-99.9856344",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSJO",
    portName: "San Juan del Rio",
    portContinent: "North America",
  },
  {
    lat: "20.3951106",
    lng: "-99.9856344",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSJR",
    portName: "SAN JUAN DEL RIO",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MXSLO",
    portName: "San Lorenzo",
    portContinent: "North America",
  },
  {
    lat: "22.1564699",
    lng: "-100.9855409",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSLP",
    portName: "San Luis Potosi",
    portContinent: "North America",
  },
  {
    lat: "25.4383234",
    lng: "-100.973678",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSLW",
    portName: "Saltillo",
    portContinent: "North America",
  },
  {
    lat: "40.9701039",
    lng: "-5.663539699999999",
    country: "ES",
    country_long: "Spain",
    portCode: "MXSMC",
    portName: "Salamanca",
    portContinent: "North America",
  },
  {
    lat: "21.8610731",
    lng: "-102.3211294",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSMI",
    portName: "Port of Isla San Marcos",
    portContinent: "North America",
  },
  {
    lat: "25.7493469",
    lng: "-100.2868973",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSND",
    portName: "San Nicolas de los Garza",
    portContinent: "North America",
  },
  {
    lat: "25.6573447",
    lng: "-100.4017501",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSPG",
    portName: "San Pedro Garza Garcia",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MXSQE",
    portName: "Santiago de QuerÃ©taro",
    portContinent: "North America",
  },
  {
    lat: "20.7414547",
    lng: "-100.447453",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSRJ",
    portName: "Santa Rosa Jauregui",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MXSRL",
    portName: "Port of Santa Rosalia",
    portContinent: "North America",
  },
  {
    lat: "19.5497882",
    lng: "-98.9160986",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXSSA",
    portName: "Atenco",
    portContinent: "North America",
  },
  {
    lat: "-26.928572",
    lng: "-49.36531489999999",
    country: "BR",
    country_long: "Brazil",
    portCode: "MXSTC",
    portName: "Santa Catarina",
    portContinent: "North America",
  },
  {
    lat: "-33.4488897",
    lng: "-70.6692655",
    country: "CL",
    country_long: "Chile",
    portCode: "MXSTG",
    portName: "Santiago",
    portContinent: "North America",
  },
  {
    lat: "22.211374",
    lng: "-97.85869199999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTAM",
    portName: "Puerto de Tampico",
    portContinent: "North America",
  },
  {
    lat: "19.7122732",
    lng: "-98.96830729999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTCA",
    portName: "Tecamac",
    portContinent: "North America",
  },
  {
    lat: "18.9173829",
    lng: "-103.8738031",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTCM",
    portName: "TECOMAN",
    portContinent: "North America",
  },
  {
    lat: "32.563609",
    lng: "-116.6256752",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTCT",
    portName: "Tecate",
    portContinent: "North America",
  },
  {
    lat: "19.1576292",
    lng: "-98.85993719999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTDA",
    portName: "Tenango del Aire",
    portContinent: "North America",
  },
  {
    lat: "19.5345279",
    lng: "-99.19073639999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTDB",
    portName: "Tlalnepantla de Baz",
    portContinent: "North America",
  },
  {
    lat: "19.2826098",
    lng: "-99.6556653",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTDL",
    portName: "Toluca de Lerdo",
    portContinent: "North America",
  },
  {
    lat: "20.2006949",
    lng: "-104.0451326",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTEC",
    portName: "Tecolotlan (MXTEC), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "19.7485555",
    lng: "-99.175823",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTEO",
    portName: "Teoloyucan",
    portContinent: "North America",
  },
  {
    lat: "20.8098057",
    lng: "-102.7652326",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTEP",
    portName: "Tepatitlan",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MXTEQ",
    portName: "Tequila",
    portContinent: "North America",
  },
  {
    lat: "19.6860799",
    lng: "-98.87163609999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTEU",
    portName: "Teotihuacan",
    portContinent: "North America",
  },
  {
    lat: "19.5918565",
    lng: "-98.91306399999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTEY",
    portName: "Tezoyuca",
    portContinent: "North America",
  },
  {
    lat: "16.7516009",
    lng: "-93.1029939",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTGZ",
    portName: "TUXTLA GUTIERREZ",
    portContinent: "North America",
  },
  {
    lat: "32.5422546",
    lng: "-116.9717004",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTIJ",
    portName: "TIJUANA (MXTIJ), Tijuana, Mexico",
    portContinent: "North America",
  },
  {
    lat: "19.8405004",
    lng: "-98.9801651",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTIZ",
    portName: "Tizayuca",
    portContinent: "North America",
  },
  {
    lat: "19.2790911",
    lng: "-99.2114234",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTJT",
    portName: "TLALPAN",
    portContinent: "North America",
  },
  {
    lat: "19.5345279",
    lng: "-99.19073639999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTLA",
    portName: "TLALNEPANTLA",
    portContinent: "North America",
  },
  {
    lat: "19.2826098",
    lng: "-99.6556653",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTLC",
    portName: "Toluca",
    portContinent: "North America",
  },
  {
    lat: "19.6392125",
    lng: "-99.1668646",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTLN",
    portName: "Tultitlan",
    portContinent: "North America",
  },
  {
    lat: "20.6243088",
    lng: "-103.3212248",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTLQ",
    portName: "TLAQUEPAQUE",
    portContinent: "North America",
  },
  {
    lat: "20.4736865",
    lng: "-103.4479314",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTMO",
    portName: "Tlajomulco de Zuniga",
    portContinent: "North America",
  },
  {
    lat: "24.26694",
    lng: "-98.8362755",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTMP",
    portName: "Tamaulipas",
    portContinent: "North America",
  },
  {
    lat: "20.6237413",
    lng: "-103.2413723",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTNL",
    portName: "Tonala",
    portContinent: "North America",
  },
  {
    lat: "19.9344504",
    lng: "-105.2516629",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTOM",
    portName: "Tomatlan (MXTOM), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "20.6237413",
    lng: "-103.2413723",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTON",
    portName: "TONALA",
    portContinent: "North America",
  },
  {
    lat: "25.6006925",
    lng: "-109.0503685",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTPB",
    portName: "Puerto de Topolobampo",
    portContinent: "North America",
  },
  {
    lat: "19.6860054",
    lng: "-99.1260866",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTPC",
    portName: "Tultepec",
    portContinent: "North America",
  },
  {
    lat: "21.5041651",
    lng: "-104.8945887",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTPQ",
    portName: "TEPIC",
    portContinent: "North America",
  },
  {
    lat: "19.5759021",
    lng: "-98.8169822",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTPW",
    portName: "Tepetlaoxtoc",
    portContinent: "North America",
  },
  {
    lat: "19.7185096",
    lng: "-99.2065202",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTPZ",
    portName: "Tepotzotlan",
    portContinent: "North America",
  },
  {
    lat: "25.5428443",
    lng: "-103.4067861",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTRC",
    portName: "Torreon",
    portContinent: "North America",
  },
  {
    lat: "25.6866142",
    lng: "-100.3161126",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTTE",
    portName: "Monterrey",
    portContinent: "North America",
  },
  {
    lat: "20.9561149",
    lng: "-97.40633509999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTUX",
    portName: "Puerto de Tuxpan",
    portContinent: "North America",
  },
  {
    lat: "19.205225",
    lng: "-98.80044889999999",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTVZ",
    portName: "Tlalmanalco",
    portContinent: "North America",
  },
  {
    lat: "19.318154",
    lng: "-98.2374954",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTXL",
    portName: "Tlaxcala",
    portContinent: "North America",
  },
  {
    lat: "19.5060382",
    lng: "-98.8831503",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXTXO",
    portName: "Texcoco",
    portContinent: "North America",
  },
  {
    lat: "32.4518877",
    lng: "-114.771711",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXUAC",
    portName: "San Luis Rio Colorado",
    portContinent: "North America",
  },
  {
    lat: "38.1040864",
    lng: "-122.2566367",
    country: "US",
    country_long: "United States",
    portCode: "MXVAL",
    portName: "VALLEJO",
    portContinent: "North America",
  },
  {
    lat: "19.2924017",
    lng: "-98.9403667",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXVDC",
    portName: "Valle de Chalco Solidaridad",
    portContinent: "North America",
  },
  {
    lat: "19.173773",
    lng: "-96.1342241",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXVER",
    portName: "Port of Veracruz",
    portContinent: "North America",
  },
  {
    lat: "19.7287602",
    lng: "-99.4617111",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXVIL",
    portName: "Villa del Carbon",
    portContinent: "North America",
  },
  {
    lat: "17.989456",
    lng: "-92.9475061",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXVSA",
    portName: "Villahermosa",
    portContinent: "North America",
  },
  {
    lat: "17.6625661",
    lng: "-101.58734",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXXIH",
    portName: "Ixtapa (MXXIH), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "26.7033549",
    lng: "-109.5315193",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXYAV",
    portName: "Yavaros (MXYAV), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "21.277726",
    lng: "-89.7055608",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXYUK",
    portName: "Port of Yukalpeten",
    portContinent: "North America",
  },
  {
    lat: "20.6719563",
    lng: "-103.416501",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXZAP",
    portName: "Zapopan",
    portContinent: "North America",
  },
  {
    lat: "25.5859879",
    lng: "-99.99681670000001",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXZCA",
    portName: "Cadereyta Jimenez",
    portContinent: "North America",
  },
  {
    lat: "22.7709249",
    lng: "-102.5832539",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXZCL",
    portName: "Zacatecas",
    portContinent: "North America",
  },
  {
    lat: "19.3090538",
    lng: "-98.9079782",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXZIP",
    portName: "Ixtapaluca",
    portContinent: "North America",
  },
  {
    lat: "19.1138094",
    lng: "-104.3384616",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXZLO",
    portName: "Puerto de Manzanillo",
    portContinent: "North America",
  },
  {
    lat: "19.26244",
    lng: "-98.8969427",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXZMB",
    portName: "Chalco",
    portContinent: "North America",
  },
  {
    lat: "19.6915425",
    lng: "-100.546488",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXZMF",
    portName: "CIUDAD HIDALGO",
    portContinent: "North America",
  },
  {
    lat: "20.7794845",
    lng: "-105.5269376",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXZPA",
    portName: "Punta De Mita (MXZPA), Mexico, LatAm",
    portContinent: "North America",
  },
  {
    lat: "32.3661011",
    lng: "-117.0617553",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXZRO",
    portName: "Rosarito",
    portContinent: "North America",
  },
  {
    lat: "19.8039297",
    lng: "-99.0930528",
    country: "MX",
    country_long: "Mexico",
    portCode: "MXZUM",
    portName: "Zumpango de Ocampo",
    portContinent: "North America",
  },
  {
    lat: "5.402693",
    lng: "100.2782329",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYAIR",
    portName: "Air Itam/Penang",
    portContinent: "South East Asia",
  },
  {
    lat: "6.1263074",
    lng: "100.3671695",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYAOR",
    portName: "Alor Setar",
    portContinent: "South East Asia",
  },
  {
    lat: "5.980408",
    lng: "116.0734568",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYBKI",
    portName: "Kota Kinabalu, Sabah",
    portContinent: "South East Asia",
  },
  {
    lat: "3.171322",
    lng: "113.0419069",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYBTU",
    portName: "Bintulu, Sarawak",
    portContinent: "South East Asia",
  },
  {
    lat: "5.438030899999999",
    lng: "100.388192",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYBWH",
    portName: "BUTTERWORTH",
    portContinent: "South East Asia",
  },
  {
    lat: "4.597479",
    lng: "101.090106",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYIPH",
    portName: "Ipoh",
    portContinent: "South East Asia",
  },
  {
    lat: "1.492659",
    lng: "103.7413591",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYJHB",
    portName: "Johor Bahru",
    portContinent: "South East Asia",
  },
  {
    lat: "4.57679",
    lng: "113.9871398",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYKBA",
    portName: "Kuala Baram (MYKBA), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "1.553504",
    lng: "110.3592927",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYKCH",
    portName: "Kuching, Sarawak",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MYKEM",
    portName: "Kemaman Port",
    portContinent: "South East Asia",
  },
  {
    lat: "4.5817951",
    lng: "103.4585587",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYKET",
    portName: "Kertih Port",
    portContinent: "South East Asia",
  },
  {
    lat: "3.0738379",
    lng: "101.5183469",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYKSL",
    portName: "Selangor",
    portContinent: "South East Asia",
  },
  {
    lat: "3.9662707",
    lng: "103.4195347",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYKUA",
    portName: "Kuantan (Tanjong Gelang)",
    portContinent: "South East Asia",
  },
  {
    lat: "6.883057099999999",
    lng: "116.8466165",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYKUD",
    portName: "Kudat (MYKUD), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "3.139003",
    lng: "101.686855",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYKUL",
    portName: "Kuala Lumpur",
    portContinent: "South East Asia",
  },
  {
    lat: "4.686116",
    lng: "118.2511462",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYKUN",
    portName: "Kunak (MYKUN), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "5.2944376",
    lng: "115.2492503",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYLBU",
    portName: "Labuan (MYLBU), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "5.024206",
    lng: "118.3307461",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYLDU",
    portName: "Lahad Datu (MYLDU), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "6.3423662",
    lng: "99.7311151",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYLGK",
    portName: "Langkawi (MYLGK), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "2.999852",
    lng: "101.39283",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYLPK",
    portName: "Port klang north",
    portContinent: "South East Asia",
  },
  {
    lat: "4.236302",
    lng: "100.6322198",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYLUM",
    portName: "Lumut (MYLUM), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "2.189594",
    lng: "102.2500868",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYMEA",
    portName: "Melaka (MYMEA), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "2.189594",
    lng: "102.2500868",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYMKZ",
    portName: "Malacca Port",
    portContinent: "South East Asia",
  },
  {
    lat: "2.0630519",
    lng: "102.5848717",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYMUA",
    portName: "MUAR",
    portContinent: "South East Asia",
  },
  {
    lat: "4.399493",
    lng: "113.9913832",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYMYY",
    portName: "Miri (MYMYY), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "2.999852",
    lng: "101.39283",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYPBK",
    portName: "PELABUHAN KELANG",
    portContinent: "South East Asia",
  },
  {
    lat: "2.52254",
    lng: "101.7962932",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYPDI",
    portName: "Port Dickson",
    portContinent: "South East Asia",
  },
  {
    lat: "5.414130699999999",
    lng: "100.3287506",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYPEN",
    portName: "Penang (Georgetown) (MYPEN), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MYPGK",
    portName: "Pangkur",
    portContinent: "South East Asia",
  },
  {
    lat: "1.470288",
    lng: "103.9029689",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYPGU",
    portName: "Pasir Gudang, Johor",
    portContinent: "South East Asia",
  },
  {
    lat: "3.1278871",
    lng: "101.5944885",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYPJA",
    portName: "Petaling Jaya",
    portContinent: "South East Asia",
  },
  {
    lat: "2.999852",
    lng: "101.39283",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYPKG",
    portName: "Port Klang (Pelabuhan Klang) (MYPKG), Kelang, Malaysia",
    portContinent: "South East Asia",
  },
  {
    lat: "2.131703",
    lng: "111.5237279",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYSAR",
    portName: "Sarikei (MYSAR), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "2.287284",
    lng: "111.830535",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYSBW",
    portName: "Sibu",
    portContinent: "South East Asia",
  },
  {
    lat: "5.839443999999999",
    lng: "118.1171729",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYSDK",
    portName: "Sandakan (MYSDK), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "5.627232999999999",
    lng: "117.127534",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYTEL",
    portName: "TELUPID",
    portContinent: "South East Asia",
  },
  {
    lat: "5.329624",
    lng: "103.1370142",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYTGG",
    portName: "Kuala Terengganu (MYTGG), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "1.467222",
    lng: "104.004945",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYTLA",
    portName: "Tanjung Langsat (MYTLA), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0.9254021",
    lng: "118.1005632",
    country: "ID",
    country_long: "Indonesia",
    portCode: "MYTMP",
    portName: "Tanjung Manis",
    portContinent: "South East Asia",
  },
  {
    lat: "1.3621902",
    lng: "103.5533181",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYTPP",
    portName: "Tanjung Pelepas Port",
    portContinent: "South East Asia",
  },
  {
    lat: "4.244651",
    lng: "117.8911861",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYTWU",
    portName: "Tawau (MYTWU), Malaysia, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "2.999852",
    lng: "101.39283",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYWSP",
    portName: "Port klang west",
    portContinent: "South East Asia",
  },
  {
    lat: "2.999852",
    lng: "101.39283",
    country: "MY",
    country_long: "Malaysia",
    portCode: "MYXPQ",
    portName: "Port Klang (Ferry Port)",
    portContinent: "South East Asia",
  },
  {
    lat: "-16.2355363",
    lng: "39.901778",
    country: "MZ",
    country_long: "Mozambique",
    portCode: "MZANO",
    portName: "Porto De Angoche (MZANO), Mozambique, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-19.8315949",
    lng: "34.8370183",
    country: "MZ",
    country_long: "Mozambique",
    portCode: "MZBEW",
    portName: "Port of Beira",
    portContinent: "Africa",
  },
  {
    lat: "-23.8662153",
    lng: "35.3826756",
    country: "MZ",
    country_long: "Mozambique",
    portCode: "MZINH",
    portName: "Inhambane (MZINH), Mozambique, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-25.9239093",
    lng: "32.465802",
    country: "MZ",
    country_long: "Mozambique",
    portCode: "MZMAT",
    portName: "Matola (MZMAT), Mozambique, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-23.8606069",
    lng: "35.3468034",
    country: "MZ",
    country_long: "Mozambique",
    portCode: "MZMAX",
    portName: "Maxixe (MZMAX), Mozambique, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-14.5656065",
    lng: "40.6854309",
    country: "MZ",
    country_long: "Mozambique",
    portCode: "MZMNC",
    portName: "Port of Nacala",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MZMPM",
    portName: "Port of Maputo",
    portContinent: "Africa",
  },
  {
    lat: "-11.3535985",
    lng: "40.3522402",
    country: "MZ",
    country_long: "Mozambique",
    portCode: "MZMZB",
    portName: "Mocimboa Da Praia (MZMZB), Mozambique, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-16.5638987",
    lng: "36.6093926",
    country: "MZ",
    country_long: "Mozambique",
    portCode: "MZMZQ",
    portName: "Mocambique (MZMZQ), Mozambique, Africa",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MZPOL",
    portName: "Port of Pemba",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "MZTET",
    portName: "TETE",
    portContinent: "Africa",
  },
  {
    lat: "-17.8502636",
    lng: "36.9218584",
    country: "MZ",
    country_long: "Mozambique",
    portCode: "MZUEL",
    portName: "Port of Quelimane",
    portContinent: "Africa",
  },
  {
    lat: "-26.6447222",
    lng: "15.1444444",
    country: "NA",
    country_long: "Namibia",
    portCode: "NALUD",
    portName: "Port of Luderitz",
    portContinent: "Africa",
  },
  {
    lat: "-22.5608807",
    lng: "17.0657549",
    country: "NA",
    country_long: "Namibia",
    portCode: "NAWDH",
    portName: "Windhoek",
    portContinent: "Africa",
  },
  {
    lat: "-22.954037",
    lng: "14.4909943",
    country: "NA",
    country_long: "Namibia",
    portCode: "NAWVB",
    portName: "Port of Walvis Bay",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NCNOU",
    portName: "NoumÃ©a",
    portContinent: "Oceania",
  },
  {
    lat: "-22.3202074",
    lng: "166.8241629",
    country: "NC",
    country_long: "New Caledonia",
    portCode: "NCPNY",
    portName: "PRONY (NCPNY), New Caledonia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-20.904305",
    lng: "165.618042",
    country: "NC",
    country_long: "New Caledonia",
    portCode: "NCVAV",
    portName: "VAVOUTO (NCVAV), New Caledonia, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "13.5115963",
    lng: "2.1253854",
    country: "NE",
    country_long: "Niger",
    portCode: "NENIM",
    portName: "NIAMEY (NENIM), Niamey, Niger",
    portContinent: "North Africa",
  },
  {
    lat: "-29.040835",
    lng: "167.954712",
    country: "NF",
    country_long: "Norfolk Island",
    portCode: "NFNLK",
    portName: "Norfolk Island",
    portContinent: "Oceania",
  },
  {
    lat: "4.7231169",
    lng: "6.7788461",
    country: "NG",
    country_long: "Nigeria",
    portCode: "NGABO",
    portName: "Abonnema",
    portContinent: "Africa",
  },
  {
    lat: "6.45528",
    lng: "3.3640841",
    country: "NG",
    country_long: "Nigeria",
    portCode: "NGAPP",
    portName: "Port of Apapa",
    portContinent: "Africa",
  },
  {
    lat: "4.4295108",
    lng: "7.195191299999999",
    country: "NG",
    country_long: "Nigeria",
    portCode: "NGBON",
    portName: "Bonny (NGBON), Nigeria, Africa",
    portContinent: "Africa",
  },
  {
    lat: "4.9757165",
    lng: "8.3417006",
    country: "NG",
    country_long: "Nigeria",
    portCode: "NGCBQ",
    portName: "Port of Calabar",
    portContinent: "Africa",
  },
  {
    lat: "5.5990514",
    lng: "5.202045099999999",
    country: "NG",
    country_long: "Nigeria",
    portCode: "NGESC",
    portName: "Escravos Oil Terminal",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NGKOK",
    portName: "Port of Koko",
    portContinent: "Africa",
  },
  {
    lat: "6.5243793",
    lng: "3.3792057",
    country: "NG",
    country_long: "Nigeria",
    portCode: "NGLOS",
    portName: "Lagos (NGLOS), Lagos, Nigeria",
    portContinent: "Africa",
  },
  {
    lat: "4.8004132",
    lng: "7.0119958",
    country: "NG",
    country_long: "Nigeria",
    portCode: "NGOKW",
    portName: "Okwori (NGOKW), Nigeria, Africa",
    portContinent: "Africa",
  },
  {
    lat: "4.6980615",
    lng: "7.1730329",
    country: "NG",
    country_long: "Nigeria",
    portCode: "NGONN",
    portName: "Port of Onne",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NGPEN",
    portName: "Port of Pennington",
    portContinent: "Africa",
  },
  {
    lat: "4.847222599999999",
    lng: "6.974603999999999",
    country: "NG",
    country_long: "Nigeria",
    portCode: "NGPHC",
    portName: "Port of Port Harcourt",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NGSPL",
    portName: "Port of Sapele",
    portContinent: "Africa",
  },
  {
    lat: "6.4343451",
    lng: "3.3562494",
    country: "NG",
    country_long: "Nigeria",
    portCode: "NGTIN",
    portName: "Tincan/Lagos",
    portContinent: "Africa",
  },
  {
    lat: "5.5141291",
    lng: "5.7216451",
    country: "NG",
    country_long: "Nigeria",
    portCode: "NGWAR",
    portName: "Port of Warri",
    portContinent: "Africa",
  },
  {
    lat: "12.0111402",
    lng: "-83.7703693",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NIBEF",
    portName: "Port of Bluefields",
    portContinent: "North America",
  },
  {
    lat: "12.6234721",
    lng: "-87.1273253",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NICHI",
    portName: "Chinandega",
    portContinent: "North America",
  },
  {
    lat: "12.4907414",
    lng: "-87.1784334",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NICIO",
    portName: "Port of Corinto",
    portContinent: "North America",
  },
  {
    lat: "11.9937647",
    lng: "-83.6920126",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NIELB",
    portName: "El Bluff Port",
    portContinent: "North America",
  },
  {
    lat: "11.9344073",
    lng: "-85.9560005",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NIGRA",
    portName: "Granada (NIGRA), Nicaragua, LatAm",
    portContinent: "North America",
  },
  {
    lat: "12.0962646",
    lng: "-85.3704606",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NIJGP",
    portName: "JUIGALPA",
    portContinent: "North America",
  },
  {
    lat: "11.8495208",
    lng: "-86.19805889999999",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NIJIN",
    portName: "Jinotepe",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NILEO",
    portName: "Leon",
    portContinent: "North America",
  },
  {
    lat: "11.9704485",
    lng: "-86.0886366",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NIMAS",
    portName: "Masaya",
    portContinent: "North America",
  },
  {
    lat: "12.1149926",
    lng: "-86.2361744",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NIMGA",
    portName: "MANAGUA (NIMGA), Managua, Nicaragua",
    portContinent: "North America",
  },
  {
    lat: "12.9290069",
    lng: "-85.9151211",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NIMTG",
    portName: "Matagalpa",
    portContinent: "North America",
  },
  {
    lat: "13.408266",
    lng: "-83.5643063",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NIPRI",
    portName: "Prinzapolka (NIPRI), Nicaragua, LatAm",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NIPSN",
    portName: "Port of Sandino",
    portContinent: "North America",
  },
  {
    lat: "14.0391161",
    lng: "-83.39496319999999",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NIPUZ",
    portName: "Puerto Cabezas",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NIRAM",
    portName: "RAMA",
    portContinent: "North America",
  },
  {
    lat: "12.1718394",
    lng: "-83.0411385",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NIRNI",
    portName: "Corn Island (NIRNI), Nicaragua, LatAm",
    portContinent: "North America",
  },
  {
    lat: "11.4326663",
    lng: "-85.8230251",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NIRVV",
    portName: "Rivas",
    portContinent: "North America",
  },
  {
    lat: "26.132576",
    lng: "-97.6311006",
    country: "US",
    country_long: "United States",
    portCode: "NISBT",
    portName: "San Benito",
    portContinent: "North America",
  },
  {
    lat: "12.8599699",
    lng: "-86.0962783",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NISEB",
    portName: "SEBACO",
    portContinent: "North America",
  },
  {
    lat: "11.263406",
    lng: "-85.8639766",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NISJS",
    portName: "Port of San Juan del Sur",
    portContinent: "North America",
  },
  {
    lat: "12.2722898",
    lng: "-86.05296039999999",
    country: "NI",
    country_long: "Nicaragua",
    portCode: "NITPA",
    portName: "Tipitapa",
    portContinent: "North America",
  },
  {
    lat: "52.2621639",
    lng: "4.7619222",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLAAM",
    portName: "Aalsmeer (NLAAM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.87033659999999",
    lng: "4.6702024",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLABL",
    portName: "Alblasserdam (NLABL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2567715",
    lng: "6.4596001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLADU",
    portName: "Aduard (NLADU), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9543595",
    lng: "4.962428099999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLAED",
    portName: "Ameide (NLAED), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.3507849",
    lng: "5.2647016",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLAER",
    portName: "Almere (NLAER), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.7454906",
    lng: "5.220875599999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLAIJ",
    portName: "Andijk (NLAIJ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.5609159",
    lng: "4.7338008",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLAKL",
    portName: "Akersloot (NLAKL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.0493736",
    lng: "5.8341466",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLAKR",
    portName: "Akkrum (NLAKR), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.6323813",
    lng: "4.753375399999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLALK",
    portName: "Alkmaar (NLALK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.3670267",
    lng: "6.668491899999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLALM",
    portName: "Almelo (NLALM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1561113",
    lng: "5.387826599999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLAME",
    portName: "Amersfoort (NLAME), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NLAMS",
    portName: "Port of Amsterdam",
    portContinent: "Europe",
  },
  {
    lat: "52.2942064",
    lng: "4.841993599999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLAMV",
    portName: "Amstelveen (NLAMV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7481635",
    lng: "5.222762299999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLAMZ",
    portName: "Ammerzoden (NLAMZ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.8616158",
    lng: "4.8238091",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLANP",
    portName: "Anna Paulowna (NLANP), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.86573929999999",
    lng: "4.997499299999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLARK",
    portName: "Arkel (NLARK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5011231",
    lng: "3.6759015",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLARM",
    portName: "Arnemuiden (NLARM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9851034",
    lng: "5.898729599999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLARN",
    portName: "Arnhem (NLARN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.4870604",
    lng: "4.7560638",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLASD",
    portName: "Assendelft (NLASD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.21318249999999",
    lng: "5.2864096",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBAA",
    portName: "Baarn (NLBAA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.8972249",
    lng: "5.5798871",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBAL",
    portName: "Balk (NLBAL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.29497749999999",
    lng: "5.8788175",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBDA",
    portName: "Birdaard (NLBDA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.48698419999999",
    lng: "4.6574468",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBEV",
    portName: "Beverwijk (NLBEV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.3136515",
    lng: "6.1209199",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBFD",
    portName: "Belfeld (NLBFD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2753715",
    lng: "5.8398886",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBHM",
    portName: "Bartlehiem (NLBHM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6749765",
    lng: "3.7526485",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBHS",
    portName: "Burghsluis (NLBHS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.8686496",
    lng: "5.45515",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBKH",
    portName: "Bakhuizen (NLBKH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0094799",
    lng: "4.5384196",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBLW",
    portName: "Bleiswijk (NLBLW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.132633",
    lng: "5.291265999999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBNH",
    portName: "Boarnsterhim (NLBNH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.2252021",
    lng: "6.0128381",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBNU",
    portName: "Asselt (NLBNU), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.45523559999999",
    lng: "5.692692999999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBNZ",
    portName: "Biddinghuizen (NLBNZ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.06125309999999",
    lng: "5.521696299999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBOL",
    portName: "Bolsward (NLBOL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.0340446",
    lng: "5.810983999999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBON",
    portName: "Born (NLBON), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8749478",
    lng: "4.282547000000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBOT",
    portName: "Rotterdam Botlek (NLBOT), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.6984453",
    lng: "5.2496041",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBOV",
    portName: "Bovenkarspel (NLBOV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5719149",
    lng: "4.768323",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBRD",
    portName: "Breda (NLBRD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.902582",
    lng: "4.1636846",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBRI",
    portName: "Brielle (NLBRI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.6741159",
    lng: "4.805778399999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBRL",
    portName: "Broek Op Langedijk (NLBRL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7253972",
    lng: "3.9121993",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBRO",
    portName: "Brouwershaven Harbour",
    portContinent: "Europe",
  },
  {
    lat: "51.8515088",
    lng: "4.5485809",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBRR",
    portName: "Barendrecht (NLBRR), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.3945258",
    lng: "3.5558783",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBRS",
    portName: "Port of Breskens",
    portContinent: "Europe",
  },
  {
    lat: "52.25",
    lng: "5.366667",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBSB",
    portName: "Spakenburg (NLBSB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6629014",
    lng: "4.094781",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBSE",
    portName: "Bruinisse (NLBSE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0742845",
    lng: "4.6584762",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBSK",
    portName: "Boskoop (NLBSK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8823027",
    lng: "5.517890299999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBUW",
    portName: "Beneden Leeuwen (NLBUW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.7260165",
    lng: "5.962129399999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBZL",
    portName: "Blokzijl (NLBZL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.49457580000001",
    lng: "4.2871622",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBZM",
    portName: "Bergen Op Zoom (NLBZM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.958372",
    lng: "5.745654099999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLBZW",
    portName: "Boornzwaag (NLBZW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.6613567",
    lng: "6.7410616",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLCOE",
    portName: "Coevorden (NLCOE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5993241",
    lng: "3.8500147",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLCOL",
    portName: "Colijnsplaat (NLCOL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9561076",
    lng: "5.240044800000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLCUB",
    portName: "Culemborg (NLCUB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7289266",
    lng: "5.879208999999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLCUY",
    portName: "Cuijk (NLCUY), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.507755",
    lng: "6.259666999999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDAL",
    portName: "Dalfsen (NLDAL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7152812",
    lng: "4.2788659",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDBM",
    portName: "Den Bommel (NLDBM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2660751",
    lng: "6.1552165",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDEV",
    portName: "Deventer (NLDEV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.01157689999999",
    lng: "4.3570677",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDFT",
    portName: "Delft (NLDFT), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6103141",
    lng: "4.2724967",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDHE",
    portName: "De Heen (NLDHE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.8883194",
    lng: "4.932442",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDHK",
    portName: "De Haukes (NLDHK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.95628079999999",
    lng: "4.7607972",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDHR",
    portName: "Den Helder (NLDHR), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.454533",
    lng: "4.907321899999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDIL",
    portName: "Den Ilp (NLDIL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.63547519999999",
    lng: "4.371084199999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDIN",
    portName: "Dinteloord (NLDIN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.00376199999999",
    lng: "6.0648636",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDJJ",
    portName: "Gorredijk (NLDJJ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2390773",
    lng: "4.7939868",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDKW",
    portName: "De Kwakel (NLDKW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.1966063",
    lng: "5.6449476",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDNR",
    portName: "Dronrijp (NLDNR), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0142878",
    lng: "6.1397816",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDOE",
    portName: "Doesburg (NLDOE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.96469949999999",
    lng: "6.2937736",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDOI",
    portName: "Doetinchem (NLDOI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.32554529999999",
    lng: "6.000146099999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDOK",
    portName: "Dokkum (NLDOK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.81329789999999",
    lng: "4.6900929",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDOR",
    portName: "Port of Dordrecht",
    portContinent: "Europe",
  },
  {
    lat: "53.1068114",
    lng: "6.1010398",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDRA",
    portName: "Drachten (NLDRA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9621243",
    lng: "5.8119397",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDRL",
    portName: "Driel (NLDRL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.70855239999999",
    lng: "4.8034905",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDRM",
    portName: "Drimmelen (NLDRM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.5346819",
    lng: "5.7218086",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDRO",
    portName: "Dronten (NLDRO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.5563611",
    lng: "4.8468049",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDRP",
    portName: "De Rijp (NLDRP), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0190867",
    lng: "6.0597786",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDSG",
    portName: "De Steeg (NLDSG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8892306",
    lng: "5.6660108",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDST",
    portName: "Deest (NLDST), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.84241609999999",
    lng: "6.3305685",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDVB",
    portName: "Dieverbrug (NLDVB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.33102719999999",
    lng: "6.924459799999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLDZL",
    portName: "Ports of Delfzijl",
    portContinent: "Europe",
  },
  {
    lat: "52.543102",
    lng: "5.0195658",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLEDM",
    portName: "Edam (NLEDM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.4385887",
    lng: "6.8354937",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLEEM",
    portName: "Port of Eemshaven",
    portContinent: "Europe",
  },
  {
    lat: "52.87337489999999",
    lng: "5.822318999999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLEHU",
    portName: "Echtenerbrug (NLEHU), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.42314229999999",
    lng: "5.462289699999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLEIN",
    portName: "Eindhoven (NLEIN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.4492631",
    lng: "5.834519299999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLELB",
    portName: "Elburg (NLELB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.7075663",
    lng: "5.274119600000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLENK",
    portName: "Enkhuizen (NLENK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2215372",
    lng: "6.8936619",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLENS",
    portName: "Enschede (NLENS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7300332",
    lng: "5.3240218",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLEPL",
    portName: "Empel (NLEPL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.13226179999999",
    lng: "5.9413515",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLEWO",
    portName: "Earnewald (NLEWO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.1888206",
    lng: "5.5564857",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLFRK",
    portName: "Franeker (NLFRK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7590047",
    lng: "5.738560100000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGAV",
    portName: "Grave (NLGAV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.6112646",
    lng: "6.674113999999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGBG",
    portName: "Gramsbergen (NLGBG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8820038",
    lng: "5.2910872",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGDM",
    portName: "Geldermalsen (NLGDM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6978545",
    lng: "5.9726751",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGEN",
    portName: "Gennep (NLGEN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.7401779",
    lng: "6.0779779",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGHO",
    portName: "Giethoorn (NLGHO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7784317",
    lng: "5.934798799999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGIB",
    portName: "Giesbeek (NLGIB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.9645319",
    lng: "5.5433936",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGME",
    portName: "Gaastmeer (NLGME), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7359282",
    lng: "5.089432299999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGND",
    portName: "Genderen (NLGND), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NLGOE",
    portName: "GOES",
    portContinent: "Europe",
  },
  {
    lat: "53.01019909999999",
    lng: "5.7705574",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGOJ",
    portName: "Goingarijp (NLGOJ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2370207",
    lng: "6.5872492",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGOO",
    portName: "Goor (NLGOO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8372247",
    lng: "4.9758292",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGOR",
    portName: "GORINCHEM (NLGOR), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0115205",
    lng: "4.7104633",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGOU",
    portName: "Gouda (NLGOU), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7935836",
    lng: "4.277584",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGOW",
    portName: "Goudswaard (NLGOW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7792843",
    lng: "4.6180902",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGRA",
    portName: "S Gravendeel (NLGRA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8018021",
    lng: "5.3354909",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGRM",
    portName: "Rossum (NLGRM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9246393",
    lng: "4.825302100000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGRO",
    portName: "Groot Ammers (NLGRO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2193835",
    lng: "6.566501799999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGRQ",
    portName: "Groningen (NLGRQ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0012836",
    lng: "4.1670315",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGRZ",
    portName: "S Gravenzande (NLGRZ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7023659",
    lng: "4.8499781",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLGTB",
    portName: "Geertruidenberg (NLGTB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.3873878",
    lng: "4.6462194",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHAA",
    portName: "Haarlem (NLHAA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0704978",
    lng: "4.3006999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHAG",
    portName: "Den Haag (NLHAG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.4491458",
    lng: "4.0043985",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHAN",
    portName: "Hansweert (NLHAN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.1746381",
    lng: "5.4251517",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHAR",
    portName: "Harlingen (NLHAR), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.59450649999999",
    lng: "6.100492699999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHAS",
    portName: "Hasselt (NLHAS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.74600770000001",
    lng: "5.2600561",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHDL",
    portName: "Hedel (NLHDL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.9715521",
    lng: "5.605224",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHEG",
    portName: "Heeg (NLHEG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8116663",
    lng: "4.341733200000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHEK",
    portName: "Nieuw Beijerland (NLHEK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7285836",
    lng: "5.160875",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHES",
    portName: "Heusden (NLHES), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8176296",
    lng: "5.2116242",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHFN",
    portName: "Haaften (NLHFN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.3821295",
    lng: "4.7542684",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHFW",
    portName: "Halfweg (NLHFW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2574121",
    lng: "6.7927725",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHGL",
    portName: "Hengelo (NLHGL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1688532",
    lng: "4.5828795",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHGM",
    portName: "Hoogmade (NLHGM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.825556",
    lng: "4.838056",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHGS",
    portName: "Hardinxveld (NLHGS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8615899",
    lng: "4.3580323",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHGV",
    portName: "Hoogvliet (NLHGV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.84239729999999",
    lng: "4.6395056",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHIA",
    portName: "Hendrik Ido Ambacht (NLHIA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2956262",
    lng: "4.579176299999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHIL",
    portName: "Hillegom (NLHIL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.9927516",
    lng: "5.8820568",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHKD",
    portName: "Haskerdijken (NLHKD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7102808",
    lng: "4.087928199999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHKI",
    portName: "Herkingen (NLHKI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.3051209",
    lng: "5.784383",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHLL",
    portName: "Hallum (NLHLL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.4792547",
    lng: "5.657009599999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHLM",
    portName: "Helmond (NLHLM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.9426846",
    lng: "5.401254199999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHLP",
    portName: "Hindeloopen (NLHLP), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.3885691",
    lng: "4.6895554",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHLR",
    portName: "Haarlemmerliede (NLHLR), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.734558",
    lng: "4.895934899999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHNK",
    portName: "Hank (NLHNK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NLHOX",
    portName: "HORN",
    portContinent: "Europe",
  },
  {
    lat: "52.3422025",
    lng: "5.6367423",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHRD",
    portName: "Harderwijk (NLHRD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.6423654",
    lng: "5.0602124",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHRN",
    portName: "Hoorn (NLHRN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.9605613",
    lng: "5.920521700000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHRV",
    portName: "Heerenveen (NLHRV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.81731809999999",
    lng: "5.393049500000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHRW",
    portName: "Heerewaarden (NLHRW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8318632",
    lng: "4.13181",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHSL",
    portName: "Port of Hellevoetsluis",
    portContinent: "Europe",
  },
  {
    lat: "51.5135768",
    lng: "6.0854637",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHTB",
    portName: "MEERLO-WANSUM (NLHTB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2994652",
    lng: "5.2433925",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHUI",
    portName: "Huizen (NLHUI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8715098",
    lng: "5.0740528",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHUK",
    portName: "Heukelum (NLHUK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9806322",
    lng: "4.1341847",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHVH",
    portName: "Hoek Van Holland (NLHVH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.1634743",
    lng: "6.7563197",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLHZD",
    portName: "Hoogezand (NLHZD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0177649",
    lng: "5.0403003",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLIJI",
    portName: "Ijsselstein (NLIJI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.4569544",
    lng: "4.606013799999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLIJM",
    portName: "Ijmuiden (NLIJM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.5653152",
    lng: "5.932572299999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLISM",
    portName: "Ijsselmuiden (NLISM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.9019538",
    lng: "5.702896",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLITR",
    portName: "Itteren (NLITR), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.965521",
    lng: "5.797177899999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLJOU",
    portName: "Joure (NLJOU), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5706508",
    lng: "3.7022927",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKAD",
    portName: "Kamperland (NLKAD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2160589",
    lng: "4.5594046",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKAG",
    portName: "Kaag (NLKAG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9143534",
    lng: "4.5962325",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKAI",
    portName: "Krimpen (NLKAI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.5579645",
    lng: "5.914461699999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKAM",
    portName: "Kampen (NLKAM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.91448399999999",
    lng: "5.4482493",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKDM",
    portName: "Koudum (NLKDM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2384184",
    lng: "4.751948899999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKDS",
    portName: "Kudelstaart (NLKDS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.662505",
    lng: "5.899954999999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKGB",
    portName: "Kraggenburg (NLKGB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.7948147",
    lng: "4.8900181",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKHR",
    portName: "Kolhorn (NLKHR), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.5786035",
    lng: "5.7591097",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKHV",
    portName: "Ketelhaven (NLKHV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2801424",
    lng: "6.147745899999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKLM",
    portName: "Kollum (NLKLM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5578335",
    lng: "3.8031359",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKOG",
    portName: "Kortgene (NLKOG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7711528",
    lng: "5.3380468",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKRD",
    portName: "Kerkdriel (NLKRD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8967207",
    lng: "4.6249309",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKRP",
    portName: "Krimpen Aan De Lek (NLKRP), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.132633",
    lng: "5.291265999999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKSL",
    portName: "Kessel (NLKSL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.22991469999999",
    lng: "5.09164",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKTH",
    portName: "Kortenhoef (NLKTH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.715556",
    lng: "4.893333",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLKZV",
    portName: "Keizersveer (NLKZV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.4295674",
    lng: "4.913812099999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLAM",
    portName: "Landsmeer (NLLAM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.4053662",
    lng: "6.212048299999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLAN",
    portName: "Lauwersoog (NLLAN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1614115",
    lng: "6.415598600000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLCH",
    portName: "Lochem (NLLCH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0798176",
    lng: "4.3996122",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLDS",
    portName: "Leidschendam (NLLDS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9021429",
    lng: "4.6904306",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLEK",
    portName: "Lekkerkerk (NLLEK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.51853699999999",
    lng: "5.471422",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLEY",
    portName: "Lelystad (NLLEY), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.95837179999999",
    lng: "5.723091",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLGW",
    portName: "Langweer (NLLGW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1601144",
    lng: "4.4970097",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLID",
    portName: "Leiden (NLLID), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9919862",
    lng: "5.0458469",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLKL",
    portName: "Lopikerkapel (NLLKL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.8449464",
    lng: "5.710553",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLMR",
    portName: "Lemmer (NLLMR), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.861895",
    lng: "6.1185933",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLOB",
    portName: "Lobith (NLLOB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1164315",
    lng: "6.019008599999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLON",
    portName: "Loenen (NLLON), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8943133",
    lng: "5.096927",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLRD",
    portName: "Leerdam (NLLRD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5197504",
    lng: "5.5945613",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLSH",
    portName: "Lieshout (NLLSH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8009283",
    lng: "5.4591853",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLTO",
    portName: "Lithoijen (NLLTO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9877312",
    lng: "6.0199814",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLTU",
    portName: "Lathum (NLLTU), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2012334",
    lng: "5.7999133",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLWR",
    portName: "Leeuwarden (NLLWR), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9633397",
    lng: "5.0363307",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLXM",
    portName: "Lexmond (NLLXM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7102543",
    lng: "4.7042699",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLLZA",
    portName: "Lage Zwaluwe (NLLZA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5701118",
    lng: "6.034809999999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMAA",
    portName: "Maashees (NLMAA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.05611140000001",
    lng: "5.403212799999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMAK",
    portName: "Makkum (NLMAK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.7115592",
    lng: "5.864558799999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMAN",
    portName: "Marknesse (NLMAN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9617905",
    lng: "5.421033",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMAU",
    portName: "Maurik (NLMAU), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.7674467",
    lng: "5.1069179",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMDM",
    portName: "Medemblik (NLMDM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.6921234",
    lng: "6.1937187",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMEP",
    portName: "Meppel (NLMEP), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.4987962",
    lng: "3.610998",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMID",
    portName: "Middelburg (NLMID), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7532936",
    lng: "4.1646715",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMIH",
    portName: "Middelharnis (NLMIH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9184658",
    lng: "4.9899362",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMKK",
    portName: "Meerkerk (NLMKK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.45899259999999",
    lng: "5.1032057",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMKN",
    portName: "Marken (NLMKN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.3039412",
    lng: "6.2715022",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMKZ",
    portName: "Munnekezijl (NLMKZ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7247379",
    lng: "5.9156076",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMLA",
    portName: "Middelaar (NLMLA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8633",
    lng: "6.0475",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMLR",
    portName: "Millingen (NLMLR), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7968546",
    lng: "4.485201600000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMNH",
    portName: "Mijnsheerenland (NLMNH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.4555687",
    lng: "5.039231600000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMNN",
    portName: "Monnickendam (NLMNN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0362127",
    lng: "4.9518586",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMNT",
    portName: "Montfoort (NLMNT), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6745104",
    lng: "4.6062531",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMOE",
    portName: "Port of Moerdijk",
    portContinent: "Europe",
  },
  {
    lat: "51.1404101",
    lng: "5.888583",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMSB",
    portName: "Maasbracht (NLMSB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9226067",
    lng: "4.254565599999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMSL",
    portName: "Maassluis (NLMSL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1319071",
    lng: "5.0171019",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMSS",
    portName: "Maarssen (NLMSS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.8513682",
    lng: "5.6909725",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMST",
    portName: "Maastricht (NLMST), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.96327890000001",
    lng: "4.031126",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMSV",
    portName: "Rotterdam Maasvlakte (NLMSV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.32869720000001",
    lng: "5.0683449",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMUD",
    portName: "Muiden (NLMUD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.92687369999999",
    lng: "7.018000300000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLMUS",
    portName: "Musselkanaal (NLMUS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2952549",
    lng: "5.160423799999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNAA",
    portName: "Naarden (NLNAA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2601767",
    lng: "5.0449932",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNDB",
    portName: "Nederhorst Den Berg (NLNDB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.259826",
    lng: "5.9893002",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNER",
    portName: "Neer (NLNER), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7793516",
    lng: "5.1077955",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNEV",
    portName: "Veen (NLNEV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7964381",
    lng: "5.6712102",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNFK",
    portName: "Niftrik (NLNFK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2738533",
    lng: "5.0285536",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNGV",
    portName: "Nigtevecht (NLNGV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6496411",
    lng: "4.0004632",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNIE",
    portName: "Nieuwerkerk (NLNIE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8455159",
    lng: "5.8205144",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNIJ",
    portName: "Port of Nijmegen",
    portContinent: "Europe",
  },
  {
    lat: "52.2224835",
    lng: "5.4835625",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNKK",
    portName: "Nijkerk (NLNKK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8872759",
    lng: "4.6858964",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNLK",
    portName: "Nieuw Lekkerland (NLNLK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.0646944",
    lng: "6.9462204",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNPK",
    portName: "Nieuwe Pekela (NLNPK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.25890039999999",
    lng: "4.867513499999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNSA",
    portName: "Nes Aan De Amstel (NLNSA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.69865739999999",
    lng: "4.8108679",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNSW",
    portName: "Noord Scharwoude (NLNSW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6331438",
    lng: "3.7007154",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNTJ",
    portName: "Neeltje Jans (NLNTJ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.734768",
    lng: "4.438213699999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNUD",
    portName: "Numansdorp (NLNUD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.37477190000001",
    lng: "5.769895",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNUN",
    portName: "Nunspeet (NLNUN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.02482080000001",
    lng: "5.0918192",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNWG",
    portName: "Nieuwegein (NLNWG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1500559",
    lng: "4.777359",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNWK",
    portName: "Nieuwkoop (NLNWK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2448575",
    lng: "6.974548599999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNWO",
    portName: "Nieuwolda (NLNWO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9347419",
    lng: "4.8692024",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLNWP",
    portName: "Nieuwpoort (NLNWP), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2947295",
    lng: "4.915337",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOAI",
    portName: "Ouderkerk Aan Den Ij (NLOAI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0456583",
    lng: "6.123997",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOBE",
    portName: "Olburgen (NLOBE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8189297",
    lng: "4.427236199999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOBL",
    portName: "Oud Beijerland (NLOBL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5832716",
    lng: "4.5275943",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOBO",
    portName: "Oudenbosch (NLOBO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6909767",
    lng: "4.2126832",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLODT",
    portName: "Oude Tonge (NLODT), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.12554859999999",
    lng: "5.9969873",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOGA",
    portName: "Oudega (NLOGA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1862262",
    lng: "4.4748104",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOGE",
    portName: "Oegstgeest (NLOGE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6824978",
    lng: "4.345384",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOGP",
    portName: "Ooltgensplaat (NLOGP), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.03897509999999",
    lng: "4.8479885",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOHI",
    portName: "Oudeschild (NLOHI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.0806327",
    lng: "5.7936461",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOHW",
    portName: "Jirnsum (NLOHW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.8201219",
    lng: "5.974834899999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOLK",
    portName: "Oldemarkt (NLOLK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.3797827",
    lng: "6.1604468",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOMH",
    portName: "Oostmahorn (NLOMH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6410202",
    lng: "4.8616901",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOOS",
    portName: "Oosterhout (NLOOS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.09073739999999",
    lng: "5.1214201",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLORJ",
    portName: "Utrecht (NLORJ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7611801",
    lng: "5.5140482",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOSS",
    portName: "Oss (NLOSS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.91135629999999",
    lng: "4.0946488",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOVN",
    portName: "Oostvoorne (NLOVN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.214425",
    lng: "4.6402743",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOWG",
    portName: "Oude Wetering (NLOWG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.8087816",
    lng: "5.9211295",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLOZL",
    portName: "Ossenzijl (NLOZL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8424811",
    lng: "4.6941834",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLPAP",
    portName: "Papendrecht (NLPAP), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8887319",
    lng: "4.3890747",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLPER",
    portName: "Pernis (NLPER), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.61678879999999",
    lng: "4.167949999999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLPLP",
    portName: "Sint Philipsland (NLPLP), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8880305",
    lng: "6.0447439",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLPNN",
    portName: "Pannerden (NLPNN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8053785",
    lng: "4.5664733",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLPTK",
    portName: "Puttershoek (NLPTK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.49663839999999",
    lng: "4.947797599999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLPUM",
    portName: "Purmerend (NLPUM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.25793030000001",
    lng: "4.5574834",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLQDG",
    portName: "Lisse (NLQDG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6973443",
    lng: "4.8742288",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLRAA",
    portName: "Raamsdonksveer (NLRAA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7967784",
    lng: "5.6515008",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLRAS",
    portName: "Ravenstein (NLRAS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2018801",
    lng: "4.6312247",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLRAV",
    portName: "Roelofarendsveen (NLRAV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1892775",
    lng: "4.4431763",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLRBG",
    portName: "Rijnsburg (NLRBG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9163268",
    lng: "5.9568715",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLREN",
    portName: "Angeren (NLREN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.96213969999999",
    lng: "5.571115499999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLRHE",
    portName: "Rhenen (NLRHE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8563609",
    lng: "4.4254804",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLRHO",
    portName: "Rhoon (NLRHO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8702526",
    lng: "4.6022335",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLRID",
    portName: "Ridderkerk (NLRID), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.535849",
    lng: "4.465321299999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLROO",
    portName: "Roosendaal (NLROO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.904422",
    lng: "4.2471328",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLROZ",
    portName: "Rozenburg (NLROZ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2620371",
    lng: "4.7127066",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLRSH",
    portName: "Rijsenhout (NLRSH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.198906",
    lng: "4.6716523",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLRSW",
    portName: "Rijnsaterwoude (NLRSW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9496008",
    lng: "4.145263",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLRTM",
    portName: "Port of Rotterdam",
    portContinent: "Europe",
  },
  {
    lat: "52.0459962",
    lng: "4.7235288",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLRUW",
    portName: "Reeuwijk (NLRUW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.193109",
    lng: "4.582368799999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLRWI",
    portName: "Rijpwetering (NLRWI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7385339",
    lng: "4.2412443",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSAT",
    portName: "Stad Aant Haringvlie (NLSAT), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.9430397",
    lng: "5.7776424",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSBG",
    portName: "Scharsterbrug (NLSBG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.680119",
    lng: "5.0113884",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSCA",
    portName: "Sprang Capelle (NLSCA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1024015",
    lng: "4.3021834",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSCE",
    portName: "Scheveningen (NLSCE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.7880912",
    lng: "4.8043998",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSCH",
    portName: "Schagen (NLSCH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.91695989999999",
    lng: "4.398818599999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSCI",
    portName: "Schiedam Harbour",
    portContinent: "Europe",
  },
  {
    lat: "51.6133003",
    lng: "4.5135592",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSDB",
    portName: "Standdaarbuiten (NLSDB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9525204",
    lng: "4.8432204",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSHH",
    portName: "Schoonhoven (NLSHH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8248681",
    lng: "4.773162399999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSLD",
    portName: "Sliedrecht (NLSLD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8848162",
    lng: "4.6050565",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSLI",
    portName: "Slikkerveer (NLSLI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.8945213",
    lng: "5.6457171",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSLT",
    portName: "Sloten (NLSLT), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.27770289999999",
    lng: "3.8366881",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSLU",
    portName: "Sluiskil (NLSLU), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.1763506",
    lng: "6.9723944",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSMD",
    portName: "Scheemda (NLSMD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.9243196",
    lng: "5.7406279",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSNG",
    portName: "Sint Nicolaasga (NLSNG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.0337476",
    lng: "5.6556473",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSNK",
    portName: "Sneek (NLSNK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5998713",
    lng: "4.103318499999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSNN",
    portName: "Sint Annaland (NLSNN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.4135122",
    lng: "4.6907362",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSPD",
    portName: "Spaarndam (NLSPD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6139275",
    lng: "4.4427261",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSPG",
    portName: "Stampersgat (NLSPG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.85615019999999",
    lng: "4.2972181",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSPI",
    portName: "Spijkenisse (NLSPI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7357017",
    lng: "3.844652299999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSRD",
    portName: "Scharendijke (NLSRD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.89989139999999",
    lng: "4.74488",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSRE",
    portName: "Streefkerk (NLSRE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.80618699999999",
    lng: "4.0244063",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSTD",
    portName: "Stellendam (NLSTD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5812395",
    lng: "4.3155991",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSTE",
    portName: "Steenbergen (NLSTE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.9919853",
    lng: "6.9462217",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSTK",
    portName: "Stadskanaal (NLSTK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.7868939",
    lng: "6.1180686",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSTW",
    portName: "Steenwijk (NLSTW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.2276878",
    lng: "3.7999937",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSVG",
    portName: "Sas Van Gent (NLSVG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5861025",
    lng: "4.0122135",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSVN",
    portName: "Stavenisse (NLSVN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.13123",
    lng: "5.8456817",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSVW",
    portName: "Stevensweert (NLSVW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.810891",
    lng: "4.9511788",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSWK",
    portName: "Sleeuwijk (NLSWK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.22954559999999",
    lng: "6.046671",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLSWM",
    portName: "Swalmen (NLSWM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6443817",
    lng: "4.7512386",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLTER",
    portName: "Terheijden (NLTER), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.0547626",
    lng: "4.7977149",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLTEX",
    portName: "Texel (NLTEX), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0299823",
    lng: "4.1677757",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLTHD",
    portName: "Ter Heijde (NLTHD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.1626128",
    lng: "5.8427213",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLTHN",
    portName: "Thorn (NLTHN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5332946",
    lng: "4.2162383",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLTHO",
    portName: "Tholen (NLTHO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8876176",
    lng: "5.4278765",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLTIE",
    portName: "Tiel (NLTIE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.852073",
    lng: "6.1032046",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLTKM",
    portName: "Tolkamer (NLTKM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.560596",
    lng: "5.0919143",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLTLB",
    portName: "Tilburg (NLTLB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2984985",
    lng: "7.0294484",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLTMZ",
    portName: "Termunterzijl (NLTMZ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.33401569999999",
    lng: "3.8226897",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLTNZ",
    portName: "Zeeland Seaports",
    portContinent: "Europe",
  },
  {
    lat: "52.8771642",
    lng: "7.059231199999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLTPH",
    portName: "Ter Apel (NLTPH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.0410043",
    lng: "5.7814",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLTRO",
    portName: "Terherne (NLTRO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.3978747",
    lng: "5.3466786",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLTSL",
    portName: "Terschelling (NLTSL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2446266",
    lng: "4.8317337",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLUIT",
    portName: "Uithoorn (NLUIT), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.66305329999999",
    lng: "5.598946499999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLURK",
    portName: "Urk (NLURK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.5312254",
    lng: "4.7120459",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLUTG",
    portName: "Uitgeest (NLUTG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9902756",
    lng: "5.1030332",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLVAN",
    portName: "Vianen (NLVAN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.1062782",
    lng: "6.8750998",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLVDM",
    portName: "Veendam (NLVDM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6157885",
    lng: "5.5392399",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLVEG",
    portName: "Veghel (NLVEG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.4520591",
    lng: "4.630587",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLVEL",
    portName: "Velsen (NLVEL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.3703748",
    lng: "6.172403099999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLVEN",
    portName: "Venlo (NLVEN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.6809113",
    lng: "5.949371",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLVHV",
    portName: "Vollenhove (NLVHV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.2150916",
    lng: "4.9348068",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLVIV",
    portName: "Vinkeveen (NLVIV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9120668",
    lng: "4.3494366",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLVLA",
    portName: "Port of Vlaardingen",
    portContinent: "Europe",
  },
  {
    lat: "51.4536672",
    lng: "3.5709125",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLVLI",
    portName: "Vlissingen (NLVLI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.295963",
    lng: "5.0683373",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLVLL",
    portName: "Vlieland (NLVLL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.49686939999999",
    lng: "5.0727015",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLVOD",
    portName: "Volendam (NLVOD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.12378959999999",
    lng: "4.4385976",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLVOS",
    portName: "Voorschoten (NLVOS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8782381",
    lng: "4.4330984",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWAL",
    portName: "Rotterdam Waalhaven (NLWAL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.5178843",
    lng: "4.7866064",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWAM",
    portName: "Westknollendam (NLWAM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5363948",
    lng: "6.075652799999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWAS",
    portName: "Wanssum",
    portContinent: "Europe",
  },
  {
    lat: "51.9756003",
    lng: "5.338449499999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWBD",
    portName: "Wijk Bij Duurstede (NLWBD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8136739",
    lng: "5.7528948",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWCH",
    portName: "Wijchen (NLWCH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8170069",
    lng: "5.0037508",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWCM",
    portName: "Woudrichem (NLWCM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5167534",
    lng: "3.9964784",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWED",
    portName: "Wemeldinge (NLWED), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7444609",
    lng: "5.204966",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWEL",
    portName: "Well (NLWEL), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.963807",
    lng: "5.9689837",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWEV",
    portName: "Westervoort (NLWEV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2908134",
    lng: "6.0847402",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWGE",
    portName: "Westergeest (NLWGE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.1865585",
    lng: "6.6518275",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWHZ",
    portName: "Waterhuizen (NLWHZ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6919469",
    lng: "4.4379073",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWIS",
    portName: "Willemstad (NLWIS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8081653",
    lng: "4.891848",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWKD",
    portName: "Werkendam (NLWKD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.98031659999999",
    lng: "5.449592399999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWKU",
    portName: "Workum (NLWKU), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6878954",
    lng: "5.0574822",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWLK",
    portName: "Waalwijk (NLWLK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1956103",
    lng: "4.9041875",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWLN",
    portName: "Wilnis (NLWLN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.4986623",
    lng: "4.8124621",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWMO",
    portName: "Wormer (NLWMO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.32960199999999",
    lng: "6.5251767",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWNS",
    portName: "Winsum (NLWNS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0798287",
    lng: "4.8627239",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWOR",
    portName: "Woerden (NLWOR), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.685585",
    lng: "4.9495377",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWPI",
    portName: "Waspik (NLWPI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.1519991",
    lng: "5.8468646",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWRA",
    portName: "Wergea (NLWRA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.93437789999999",
    lng: "5.0282699",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWRG",
    portName: "Den Oever (NLWRG), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1942139",
    lng: "4.499200099999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWRM",
    portName: "Warmond (NLWRM), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.2439415",
    lng: "5.7142222",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWRT",
    portName: "Weert (NLWRT), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.49035019999999",
    lng: "4.798067400000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWRV",
    portName: "Wormerveer (NLWRV), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.14249840000001",
    lng: "7.0367877",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWSC",
    portName: "Winschoten (NLWSC), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.3814114",
    lng: "4.0276265",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWSO",
    portName: "Walsoorden (NLWSO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.3080507",
    lng: "5.0406217",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWSP",
    portName: "Weesp (NLWSP), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.1500391",
    lng: "5.898157299999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLWTN",
    portName: "Warten (NLWTN), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.49292920000001",
    lng: "4.0486617",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLYSK",
    portName: "Yerseke (NLYSK), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.4420399",
    lng: "4.8291992",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZAA",
    portName: "Zaandam (NLZAA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2182273",
    lng: "6.160893799999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZAC",
    portName: "Augustinusga (NLZAC), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.1985586",
    lng: "5.0897784",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZAQ",
    portName: "Loosdrecht (NLZAQ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.181315",
    lng: "5.9624494",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZAY",
    portName: "Herten (NLZAY), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7683561",
    lng: "5.1584525",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZBA",
    portName: "Nederhemert (NLZBA), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.2118299",
    lng: "6.092282099999999",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZBB",
    portName: "Kootstertille (NLZBB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.8110945",
    lng: "4.9948975",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZBD",
    portName: "Middenmeer (NLZBD), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.1704058",
    lng: "6.8621731",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZBO",
    portName: "Zuidbroek (NLZBO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9244201",
    lng: "4.4777325",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZBW",
    portName: "Rotterdam Vondeling (NLZBW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8080071",
    lng: "5.1358349",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZCH",
    portName: "Zuilichem (NLZCH), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.331128",
    lng: "5.540495",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZEW",
    portName: "Zeewolde (NLZEW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.31284040000001",
    lng: "4.6175817",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZHO",
    portName: "Zwaanshoek (NLZHO), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.0116076",
    lng: "4.5876983",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZHZ",
    portName: "Zevenhuizen (NLZHZ), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6501218",
    lng: "3.9184977",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZIE",
    portName: "Port of Zierikzee",
    portContinent: "Europe",
  },
  {
    lat: "51.8135539",
    lng: "5.250773300000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZLB",
    portName: "Zaltbommel (NLZLB), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.142736",
    lng: "6.196058400000001",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZUT",
    portName: "Zutphen (NLZUT), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.25449380000001",
    lng: "6.0307255",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZWE",
    portName: "Zwaagwesteinde (NLZWE), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8105978",
    lng: "4.6272719",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZWI",
    portName: "Zwijndrecht (NLZWI), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.50308",
    lng: "6.0909531",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZWO",
    portName: "Port of Zwolle",
    portContinent: "Europe",
  },
  {
    lat: "52.6408691",
    lng: "6.0703159",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZWS",
    portName: "Zwartsluis (NLZWS), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.8835357",
    lng: "4.2200928",
    country: "NL",
    country_long: "Netherlands",
    portCode: "NLZWW",
    portName: "Zwartewaal (NLZWW), Netherlands, Europe",
    portContinent: "Europe",
  },
  {
    lat: "61.33342399999999",
    lng: "7.8572474",
    country: "NO",
    country_long: "Norway",
    portCode: "NOAAN",
    portName: "Ardal (NOAAN), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOAAV",
    portName: "Port of Alvik",
    portContinent: "Europe",
  },
  {
    lat: "58.2389247",
    lng: "6.651987999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOABE",
    portName: "Abelnes (NOABE), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "69.31607989999999",
    lng: "16.1202284",
    country: "NO",
    country_long: "Norway",
    portCode: "NOADN",
    portName: "Port of Andenes",
    portContinent: "Europe",
  },
  {
    lat: "62.4707659",
    lng: "6.1526115",
    country: "NO",
    country_long: "Norway",
    portCode: "NOAES",
    portName: "Port of Alesund",
    portContinent: "Europe",
  },
  {
    lat: "60.4038603",
    lng: "5.0105072",
    country: "NO",
    country_long: "Norway",
    portCode: "NOAGO",
    portName: "Port of Agotnes",
    portContinent: "Europe",
  },
  {
    lat: "62.0359554",
    lng: "5.5310888",
    country: "NO",
    country_long: "Norway",
    portCode: "NOAHM",
    portName: "Aaheim (NOAHM), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOALF",
    portName: "Port of Alta",
    portContinent: "Europe",
  },
  {
    lat: "61.239558",
    lng: "7.7121033",
    country: "NO",
    country_long: "Norway",
    portCode: "NOARD",
    portName: "Port of Ardalstangen",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOARE",
    portName: "Port of Arendal",
    portContinent: "Europe",
  },
  {
    lat: "60.47202399999999",
    lng: "8.468945999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOASV",
    portName: "Austevollrhella (NOASV), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.7768873",
    lng: "6.896362300000001",
    country: "NO",
    country_long: "Norway",
    portCode: "NOAUK",
    portName: "Aukra (NOAUK), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.8555629",
    lng: "7.182045100000001",
    country: "NO",
    country_long: "Norway",
    portCode: "NOAUL",
    portName: "Port of Aurland",
    portContinent: "Europe",
  },
  {
    lat: "60.3966856",
    lng: "5.3190995",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBGO",
    portName: "Port of Bergen",
    portContinent: "Europe",
  },
  {
    lat: "59.66482769999999",
    lng: "5.6439488",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBJA",
    portName: "Bjoa (NOBJA), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "70.6345012",
    lng: "29.717845",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBJF",
    portName: "Port of Batsfjord",
    portContinent: "Europe",
  },
  {
    lat: "63.6862525",
    lng: "9.6623489",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBKS",
    portName: "Brekstad (NOBKS), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "68.3429338",
    lng: "16.831418",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBLL",
    portName: "Ballangen (NOBLL), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.4735577",
    lng: "12.2077354",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBNN",
    portName: "Port of Bronnoysund",
    portContinent: "Europe",
  },
  {
    lat: "68.5187389",
    lng: "17.0031191",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBOG",
    portName: "Bogen (NOBOG), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.2288176",
    lng: "5.4411258",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBOK",
    portName: "Bokn (NOBOK), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "67.289822",
    lng: "14.398237",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBOO",
    portName: "Port of Bodo",
    portContinent: "Europe",
  },
  {
    lat: "68.0755764",
    lng: "13.5481479",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBSD",
    portName: "Ballstad (NOBSD), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "69.5074434",
    lng: "17.9029236",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBTH",
    portName: "Botnhamn (NOBTH), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "69.9374457",
    lng: "22.04917",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBUF",
    portName: "Burfjord (NOBUF), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.03829099999999",
    lng: "5.6612724",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBUU",
    portName: "Bru (NOBUU), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.3117075",
    lng: "10.1863673",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBUV",
    portName: "Buvika (NOBUV), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.05548719999999",
    lng: "9.698005199999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBVK",
    portName: "Brevik (NOBVK), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.41988480000001",
    lng: "5.3044423",
    country: "NO",
    country_long: "Norway",
    portCode: "NOBVR",
    portName: "Breiviken (NOBVR), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.8327773",
    lng: "6.1841469",
    country: "NO",
    country_long: "Norway",
    portCode: "NODIR",
    portName: "Dirdal (NODIR), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "68.0446017",
    lng: "16.0795747",
    country: "NO",
    country_long: "Norway",
    portCode: "NODRG",
    portName: "Drag (NODRG), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.73236649999999",
    lng: "10.2212171",
    country: "NO",
    country_long: "Norway",
    portCode: "NODRM",
    portName: "Port of Drammen",
    portContinent: "Europe",
  },
  {
    lat: "58.99507699999999",
    lng: "5.6754907",
    country: "NO",
    country_long: "Norway",
    portCode: "NODUS",
    portName: "Dusavik (NODUS), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.9192893",
    lng: "7.443931999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOEDE",
    portName: "Eide (NOEDE), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.4514216",
    lng: "5.9998032",
    country: "NO",
    country_long: "Norway",
    portCode: "NOEGE",
    portName: "Port of Egersund",
    portContinent: "Europe",
  },
  {
    lat: "62.32621039999999",
    lng: "5.6416992",
    country: "NO",
    country_long: "Norway",
    portCode: "NOEGG",
    portName: "Eggesbones (NOEGG), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.25275019999999",
    lng: "10.7382776",
    country: "NO",
    country_long: "Norway",
    portCode: "NOEGV",
    portName: "Engelsviken (NOEGV), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.6822663",
    lng: "5.5224693",
    country: "NO",
    country_long: "Norway",
    portCode: "NOEKF",
    portName: "Eikefet (NOEKF), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.85428870000001",
    lng: "7.1376221",
    country: "NO",
    country_long: "Norway",
    portCode: "NOELN",
    portName: "Elnesvagen (NOELN), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.49981750000001",
    lng: "8.864431999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOEYD",
    portName: "Eydehavn (NOEYD), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.09492689999999",
    lng: "6.8050196",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFAN",
    portName: "Port of Farsund",
    portContinent: "Europe",
  },
  {
    lat: "67.2591843",
    lng: "15.3916322",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFAU",
    portName: "Fauske (NOFAU), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.5389233",
    lng: "5.2650631",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFAY",
    portName: "Flatoy (NOFAY), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.5677511",
    lng: "8.5674697",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFDL",
    portName: "Flatdal",
    portContinent: "Europe",
  },
  {
    lat: "60.7788987",
    lng: "4.714893099999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFED",
    portName: "Fedje (NOFED), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.2971823",
    lng: "6.6599809",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFFD",
    portName: "Port of Flekkefjord",
    portContinent: "Europe",
  },
  {
    lat: "59.1127677",
    lng: "6.0004545",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFIS",
    portName: "Fiska (NOFIS), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.47202399999999",
    lng: "8.468945999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFKS",
    portName: "Ramber (NOFKS), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.0692274",
    lng: "7.9939876",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFLE",
    portName: "Flekkeroy (NOFLE), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "69.232038",
    lng: "17.9808555",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFNE",
    portName: "Finnsnes (NOFNE), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.5139344",
    lng: "13.0150135",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFNN",
    portName: "Fonnes (NOFNN), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "68.8654504",
    lng: "15.5590228",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFNS",
    portName: "Fornes (NOFNS), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.9855574",
    lng: "11.1072277",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFOL",
    portName: "Follafoss (NOFOL), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.3413767",
    lng: "5.631069",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFOS",
    portName: "Fosnavaag (NOFOS), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.22053689999999",
    lng: "10.9347012",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFRK",
    portName: "Fredrikstad",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOFRO",
    portName: "Port of Flora",
    portContinent: "Europe",
  },
  {
    lat: "62.4403456",
    lng: "6.271915",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFSA",
    portName: "Fiskarstranda (NOFSA), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "67.4347183",
    lng: "14.7630452",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFSV",
    portName: "Festvag (NOFSV), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.2011879",
    lng: "5.6227205",
    country: "NO",
    country_long: "Norway",
    portCode: "NOFUS",
    portName: "Fusa",
    portContinent: "Europe",
  },
  {
    lat: "70.9283943",
    lng: "28.0638803",
    country: "NO",
    country_long: "Norway",
    portCode: "NOGAM",
    portName: "Gamvik (NOGAM), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOGFR",
    portName: "Port of Borg",
    portContinent: "Europe",
  },
  {
    lat: "62.7402591",
    lng: "7.1808245",
    country: "NO",
    country_long: "Norway",
    portCode: "NOGJM",
    portName: "Gjemnes (NOGJM), Molde, Norway",
    portContinent: "Europe",
  },
  {
    lat: "66.8165148",
    lng: "13.9443152",
    country: "NO",
    country_long: "Norway",
    portCode: "NOGLO",
    portName: "Glomfjord (NOGLO), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.06175829999999",
    lng: "5.920929",
    country: "NO",
    country_long: "Norway",
    portCode: "NOGMH",
    portName: "Gjermundshamn (NOGMH), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.1008441",
    lng: "7.205894099999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOGNR",
    portName: "Geiranger (NOGNR), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.26605379999999",
    lng: "11.0348996",
    country: "NO",
    country_long: "Norway",
    portCode: "NOGRE",
    portName: "Greaker (NOGRE), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "69.3632653",
    lng: "17.0342662",
    country: "NO",
    country_long: "Norway",
    portCode: "NOGRY",
    portName: "Gryllefjord (NOGRY), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.3447019",
    lng: "8.5948668",
    country: "NO",
    country_long: "Norway",
    portCode: "NOGTD",
    portName: "Port of Grimstad",
    portContinent: "Europe",
  },
  {
    lat: "62.23612910000001",
    lng: "5.585098899999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOGUR",
    portName: "Gursken (NOGUR), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.13299639999999",
    lng: "11.3874569",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHAL",
    portName: "Port of Halden",
    portContinent: "Europe",
  },
  {
    lat: "59.41358100000001",
    lng: "5.267986899999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHAU",
    portName: "Port of Haugesund",
    portContinent: "Europe",
  },
  {
    lat: "70.9963358",
    lng: "24.6623873",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHAV",
    portName: "Port of Havoysund",
    portContinent: "Europe",
  },
  {
    lat: "69.7181583",
    lng: "29.9803421",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHBT",
    portName: "Hamnbukt (NOHBT), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.47202399999999",
    lng: "8.468945999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHEK",
    portName: "Hekkelstrand (NOHEK), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "68.1541872",
    lng: "14.2072535",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHEN",
    portName: "Henningsvar (NOHEN), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.9767825",
    lng: "12.2764108",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHEY",
    portName: "Heroy (NOHEY), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "70.66343820000002",
    lng: "23.6819665",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHFT",
    portName: "Port of Hammerfest",
    portContinent: "Europe",
  },
  {
    lat: "60.1662065",
    lng: "5.2718005",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHHA",
    portName: "Hufthamer (NOHHA), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.5260126",
    lng: "8.757208799999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHIT",
    portName: "Hitra (NOHIT), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.3555725",
    lng: "6.0750261",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHJO",
    portName: "Hjorungavag (NOHJO), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "70.4866764",
    lng: "22.1608225",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHKV",
    portName: "Hasvik (NOHKV), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.3112115",
    lng: "9.1378969",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHLA",
    portName: "Holla (NOHLA), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.7840427",
    lng: "5.8038168",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHLB",
    portName: "Hoylandsbygda (NOHLB), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.22513719999999",
    lng: "13.6061133",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHNE",
    portName: "Hemnesberget (NOHNE), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.4899189",
    lng: "10.315035",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHOL",
    portName: "Port of Holmestrand",
    portContinent: "Europe",
  },
  {
    lat: "59.417084",
    lng: "10.4832128",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHOR",
    portName: "Port of Horten",
    portContinent: "Europe",
  },
  {
    lat: "69.544373",
    lng: "17.6597238",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHOY",
    portName: "Husoy (NOHOY), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "68.7986342",
    lng: "16.5414503",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHRD",
    portName: "Port of Harstad",
    portContinent: "Europe",
  },
  {
    lat: "62.3717009",
    lng: "6.0287332",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHRI",
    portName: "Hareid (NOHRI), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.0850981",
    lng: "6.8696782",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHSY",
    portName: "Hellesylt (NOHSY), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.8588621",
    lng: "5.7474728",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHUS",
    portName: "Port of Husnes",
    portContinent: "Europe",
  },
  {
    lat: "70.9822909",
    lng: "25.9702074",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHVG",
    portName: "Honningsvaag (NOHVG), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOHVI",
    portName: "Havik",
    portContinent: "Europe",
  },
  {
    lat: "59.59874980000001",
    lng: "10.6560817",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHVT",
    portName: "Hvitsten (NOHVT), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "61.2200648",
    lng: "6.0757442",
    country: "NO",
    country_long: "Norway",
    portCode: "NOHYR",
    portName: "Port of Hoyanger",
    portContinent: "Europe",
  },
  {
    lat: "67.0365716",
    lng: "14.0291607",
    country: "NO",
    country_long: "Norway",
    portCode: "NOIDR",
    portName: "Inndyr (NOIDR), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.3853376",
    lng: "6.5547321",
    country: "NO",
    country_long: "Norway",
    portCode: "NOIKR",
    portName: "Ikornnes  (NOIKR), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.33742749999999",
    lng: "6.026626299999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOJEL",
    portName: "Jelsa (NOJEL), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.27600649999999",
    lng: "6.2525002",
    country: "NO",
    country_long: "Norway",
    portCode: "NOJON",
    portName: "Jondal (NOJON), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOKAS",
    portName: "Port of Karmsund",
    portContinent: "Europe",
  },
  {
    lat: "60.3756766",
    lng: "6.722479099999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKIV",
    portName: "Kinsarvik (NOKIV), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "70.94576959999999",
    lng: "27.3467912",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKJF",
    portName: "Kjollefjord (NOKJF), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "68.0965004",
    lng: "16.3751298",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKJK",
    portName: "Kjopsvik (NOKJK), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "69.7269192",
    lng: "30.0450432",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKKN",
    portName: "Port of Kirkenes",
    portContinent: "Europe",
  },
  {
    lat: "60.40843729999999",
    lng: "5.2276282",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKLE",
    portName: "Kleppesto (NOKLE), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.4770693",
    lng: "10.6944889",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKMO",
    portName: "Kambo (NOKMO), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.47202399999999",
    lng: "8.468945999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKMS",
    portName: "Kjopmannskjar (NOKMS), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.2823064",
    lng: "5.2801756",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKMY",
    portName: "Karmoy (NOKMY), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.5579186",
    lng: "4.8359387",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKON",
    portName: "Kollsnes (NOKON), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.2837067",
    lng: "5.306873899999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKOP",
    portName: "Port of Kopervik",
    portContinent: "Europe",
  },
  {
    lat: "61.1846111",
    lng: "7.2424074",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKPG",
    portName: "Kaupanger (NOKPG), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.8694218",
    lng: "9.4148661",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKRA",
    portName: "Port of Kragero",
    portContinent: "Europe",
  },
  {
    lat: "58.14182039999999",
    lng: "7.9956666",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKRS",
    portName: "Port of Kristiansand",
    portContinent: "Europe",
  },
  {
    lat: "60.369743",
    lng: "5.1511947",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKRV",
    portName: "Knarrevik (NOKRV), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.11033459999999",
    lng: "7.728078999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKSU",
    portName: "Port of Kristiansund",
    portContinent: "Europe",
  },
  {
    lat: "58.31217530000001",
    lng: "6.9617619",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKVD",
    portName: "KVINESDAL (NOKVD), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "70.4668463",
    lng: "24.0324861",
    country: "NO",
    country_long: "Norway",
    portCode: "NOKVS",
    portName: "Kvalsund (NOKVS), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.0004978",
    lng: "9.7447181",
    country: "NO",
    country_long: "Norway",
    portCode: "NOLAD",
    portName: "Langesund (NOLAD), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.44078469999999",
    lng: "6.1915121",
    country: "NO",
    country_long: "Norway",
    portCode: "NOLAG",
    portName: "Langevag (NOLAG), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOLAR",
    portName: "Port of Larvik",
    portContinent: "Europe",
  },
  {
    lat: "63.7463571",
    lng: "11.3004934",
    country: "NO",
    country_long: "Norway",
    portCode: "NOLEV",
    portName: "Port of Levanger",
    portContinent: "Europe",
  },
  {
    lat: "58.252637",
    lng: "8.3721451",
    country: "NO",
    country_long: "Norway",
    portCode: "NOLIL",
    portName: "Port of Lillesand",
    portContinent: "Europe",
  },
  {
    lat: "65.0875552",
    lng: "11.7116469",
    country: "NO",
    country_long: "Norway",
    portCode: "NOLKA",
    portName: "Leka (NOLKA), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "68.1465068",
    lng: "13.6096372",
    country: "NO",
    country_long: "Norway",
    portCode: "NOLKN",
    portName: "Leknes (NOLKN), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.1378983",
    lng: "7.070436099999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOLND",
    portName: "Lyngdal (NOLND), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.9614814",
    lng: "7.369632699999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOLNS",
    portName: "Lyngstad (NOLNS), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "68.41415429999999",
    lng: "15.9943948",
    country: "NO",
    country_long: "Norway",
    portCode: "NOLOD",
    portName: "Port of Lodingen",
    portContinent: "Europe",
  },
  {
    lat: "66.3676445",
    lng: "12.3600273",
    country: "NO",
    country_long: "Norway",
    portCode: "NOLOV",
    portName: "Lovund (NOLOV), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.88458579999999",
    lng: "9.866054799999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOLSU",
    portName: "Lysoysund (NOLSU), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "61.25651309999999",
    lng: "4.9630418",
    country: "NO",
    country_long: "Norway",
    portCode: "NOLUL",
    portName: "Lutelandet (NOLUL), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "78.22317220000001",
    lng: "15.626723",
    country: "SJ",
    country_long: "Svalbard and Jan Mayen",
    portCode: "NOLYR",
    portName: "Longyearbyen (NOLYR), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.91218499999999",
    lng: "10.6381846",
    country: "NO",
    country_long: "Norway",
    portCode: "NOLYS",
    portName: "Lysaker (NOLYS), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOMAN",
    portName: "Port of Mandal",
    portContinent: "Europe",
  },
  {
    lat: "61.93554330000001",
    lng: "5.113554199999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOMAY",
    portName: "Port of Maaloy",
    portContinent: "Europe",
  },
  {
    lat: "71.0357069",
    lng: "27.848941",
    country: "NO",
    country_long: "Norway",
    portCode: "NOMEH",
    portName: "Mehamn (NOMEH), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "68.5012102",
    lng: "14.800709",
    country: "NO",
    country_long: "Norway",
    portCode: "NOMEL",
    portName: "Melbu (NOMEL), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.836924",
    lng: "13.193401",
    country: "NO",
    country_long: "Norway",
    portCode: "NOMJF",
    portName: "Port of Mosjoen",
    portContinent: "Europe",
  },
  {
    lat: "70.6893659",
    lng: "23.600448",
    country: "NO",
    country_long: "Norway",
    portCode: "NOMLK",
    portName: "Melkoya (NOMLK), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOMLM",
    portName: "Port of Malm",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOMOL",
    portName: "Port of Molde",
    portContinent: "Europe",
  },
  {
    lat: "60.8063757",
    lng: "5.062825699999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOMON",
    portName: "Mongstad (NOMON), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.3137122",
    lng: "14.1419749",
    country: "NO",
    country_long: "Norway",
    portCode: "NOMQN",
    portName: "Port of Mo I Rana",
    portContinent: "Europe",
  },
  {
    lat: "62.3235068",
    lng: "5.675046699999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOMSD",
    portName: "Mjolstadneset (NOMSD), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "67.90018719999999",
    lng: "13.0465365",
    country: "NO",
    country_long: "Norway",
    portCode: "NOMSK",
    portName: "Moskenes (NOMSK), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOMSS",
    portName: "Port of Moss",
    portContinent: "Europe",
  },
  {
    lat: "68.91532149999999",
    lng: "15.0794821",
    country: "NO",
    country_long: "Norway",
    portCode: "NOMYR",
    portName: "Myre (NOMYR), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.3708869",
    lng: "6.145635",
    country: "NO",
    country_long: "Norway",
    portCode: "NONHS",
    portName: "Norheimsund (NONHS), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "71.169493",
    lng: "25.783164",
    country: "NO",
    country_long: "Norway",
    portCode: "NONOK",
    portName: "Port of Nordkapp",
    portContinent: "Europe",
  },
  {
    lat: "63.70660489999999",
    lng: "8.558439",
    country: "NO",
    country_long: "Norway",
    portCode: "NONSF",
    portName: "Nordskaget (NONSF), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "66.1980927",
    lng: "13.0181848",
    country: "NO",
    country_long: "Norway",
    portCode: "NONSN",
    portName: "Nesna (NONSN), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "68.4384984",
    lng: "17.4272612",
    country: "NO",
    country_long: "Norway",
    portCode: "NONVK",
    portName: "Port of Narvik",
    portContinent: "Europe",
  },
  {
    lat: "62.84792119999999",
    lng: "6.9586944",
    country: "NO",
    country_long: "Norway",
    portCode: "NONYH",
    portName: "Nyhamna (NONYH), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.20655490000001",
    lng: "5.572011",
    country: "NO",
    country_long: "Norway",
    portCode: "NONZC",
    portName: "Larsnes (NONZC), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.5416615",
    lng: "10.5575287",
    country: "NO",
    country_long: "Norway",
    portCode: "NONZH",
    portName: "Tofte (NONZH), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.9373124",
    lng: "6.917668",
    country: "NO",
    country_long: "Norway",
    portCode: "NOODD",
    portName: "Port of Odda",
    portContinent: "Europe",
  },
  {
    lat: "70.239572",
    lng: "22.3478534",
    country: "NO",
    country_long: "Norway",
    portCode: "NOOKF",
    portName: "Oksfjord (NOOKF), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.60436839999999",
    lng: "5.808004599999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOOLN",
    portName: "Olen (NOOLN), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.2999528",
    lng: "9.846820000000001",
    country: "NO",
    country_long: "Norway",
    portCode: "NOORK",
    portName: "Port of Orkanger",
    portContinent: "Europe",
  },
  {
    lat: "59.91084099999999",
    lng: "10.7616546",
    country: "NO",
    country_long: "Norway",
    portCode: "NOOSL",
    portName: "Port of Oslo",
    portContinent: "Europe",
  },
  {
    lat: "64.4662906",
    lng: "11.4955928",
    country: "NO",
    country_long: "Norway",
    portCode: "NOOSY",
    portName: "Port of Namsos",
    portContinent: "Europe",
  },
  {
    lat: "59.1385567",
    lng: "9.6555147",
    country: "NO",
    country_long: "Norway",
    portCode: "NOPOR",
    portName: "Porsgrunn (NOPOR), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.47202399999999",
    lng: "8.468945999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NORAF",
    portName: "Rafnes (NORAF), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.0128581",
    lng: "5.641952400000001",
    country: "NO",
    country_long: "Norway",
    portCode: "NORDB",
    portName: "Harastadvika (NORDB), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.33780089999999",
    lng: "6.2571987",
    country: "NO",
    country_long: "Norway",
    portCode: "NOREK",
    portName: "Rekefjord (NOREK), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "61.9829131",
    lng: "5.1351058",
    country: "NO",
    country_long: "Norway",
    portCode: "NORGJ",
    portName: "Raudeberg (NORGJ), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.71937699999999",
    lng: "9.223167700000001",
    country: "NO",
    country_long: "Norway",
    portCode: "NORIS",
    portName: "Port of Risor",
    portContinent: "Europe",
  },
  {
    lat: "67.095278",
    lng: "15.3877831",
    country: "NO",
    country_long: "Norway",
    portCode: "NOROG",
    portName: "Rognan (NOROG), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "68.9718812",
    lng: "15.6382706",
    country: "NO",
    country_long: "Norway",
    portCode: "NORSH",
    portName: "Risoyhamn (NORSH), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.8156024",
    lng: "5.268129600000001",
    country: "NO",
    country_long: "Norway",
    portCode: "NORUB",
    portName: "Rubbestadneset (NORUB), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.8620756",
    lng: "11.2373375",
    country: "NO",
    country_long: "Norway",
    portCode: "NORVK",
    portName: "Port of Rorvik and Vikna",
    portContinent: "Europe",
  },
  {
    lat: "70.64124699999999",
    lng: "23.6720798",
    country: "NO",
    country_long: "Norway",
    portCode: "NORYF",
    portName: "Rypefjord (NORYF), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.1313095",
    lng: "10.2165948",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSAD",
    portName: "Port of Sandefjord",
    portContinent: "Europe",
  },
  {
    lat: "58.8532585",
    lng: "5.7329455",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSAS",
    portName: "Port of Sandnes",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOSAT",
    portName: "SALTEN",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOSAU",
    portName: "Port of Sauda",
    portContinent: "Europe",
  },
  {
    lat: "68.95099019999999",
    lng: "16.7001967",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSAY",
    portName: "Sandsoy (NOSAY), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.3239479",
    lng: "10.4536633",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSDI",
    portName: "Sandviksberget (NOSDI), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.85262479999999",
    lng: "5.0330425",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSIP",
    portName: "Skipavik Gulen (NOSIP), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "69.49682609999999",
    lng: "17.4898286",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSJH",
    portName: "Senjehopen (NOSJH), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.4826418",
    lng: "6.816382",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSJO",
    portName: "Sjoholt (NOSJO), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.2089131",
    lng: "9.605752599999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSKE",
    portName: "SKIEN (NOSKE), Skien, Norway",
    portContinent: "Europe",
  },
  {
    lat: "68.56483829999999",
    lng: "14.9098717",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSKN",
    portName: "Port of Stokmarknes",
    portContinent: "Europe",
  },
  {
    lat: "62.5046328",
    lng: "6.6317301",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSKO",
    portName: "Skodje (NOSKO), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.1500822",
    lng: "5.2571151",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSKU",
    portName: "Skudeneshavn (NOSKU), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.7011075",
    lng: "11.1868821",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSKX",
    portName: "Skogn (NOSKX), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "71.11319019999999",
    lng: "25.8269877",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSKZ",
    portName: "Skarsvag (NOSKZ), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOSLA",
    portName: "Port of Sola",
    portContinent: "Europe",
  },
  {
    lat: "59.9138688",
    lng: "10.7522454",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSLH",
    portName: "Salthella (NOSLH), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.78694780000001",
    lng: "10.4931521",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSLM",
    portName: "Slemmestad (NOSLM), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "68.69588449999999",
    lng: "15.4051116",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSLX",
    portName: "Port of Sortland",
    portContinent: "Europe",
  },
  {
    lat: "61.2272256",
    lng: "7.0992124",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSOG",
    portName: "Sogndal (NOSOG), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "69.7883028",
    lng: "20.9463173",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSOJ",
    portName: "Sorkjosen (NOSOJ), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "69.6276918",
    lng: "18.0347937",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSOM",
    portName: "Sommaroy (NOSOM), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.4616559",
    lng: "6.3652624",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSPV",
    portName: "Spjelkavik (NOSPV), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOSRP",
    portName: "Port of Stord",
    portContinent: "Europe",
  },
  {
    lat: "66.0215534",
    lng: "12.6314628",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSSJ",
    portName: "Port of Sandnessjoen",
    portContinent: "Europe",
  },
  {
    lat: "60.47202399999999",
    lng: "8.468945999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSTB",
    portName: "Storabo (NOSTB), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.783266",
    lng: "6.469759300000001",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSTH",
    portName: "Steinshamn (NOSTH), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.8708606",
    lng: "11.2998864",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSTM",
    portName: "STRAUMEN",
    portContinent: "Europe",
  },
  {
    lat: "66.3382374",
    lng: "13.0141909",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSTO",
    portName: "Stokkvaagan (NOSTO), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.67351660000001",
    lng: "8.5661121",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSUN",
    portName: "Port of Sunndalsora",
    portContinent: "Europe",
  },
  {
    lat: "68.13218619999999",
    lng: "13.843216",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSUZ",
    portName: "Stamsund (NOSUZ), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "61.7685237",
    lng: "5.2955095",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSVE",
    portName: "Port of Svelgen",
    portContinent: "Europe",
  },
  {
    lat: "58.97208999999999",
    lng: "5.726882499999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSVG",
    portName: "Port of Stavanger",
    portContinent: "Europe",
  },
  {
    lat: "60.47202399999999",
    lng: "8.468945999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSVR",
    portName: "Sorvar (NOSVR), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.3930472",
    lng: "6.579782499999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOSYK",
    portName: "Sykkylven (NOSYK), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.9365251",
    lng: "5.575812900000001",
    country: "NO",
    country_long: "Norway",
    portCode: "NOTAE",
    portName: "Tananger",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NOTAU",
    portName: "Port of Tau",
    portContinent: "Europe",
  },
  {
    lat: "60.47202399999999",
    lng: "8.468945999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOTAV",
    portName: "Torangsvag (NOTAV), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "69.7796208",
    lng: "18.3996501",
    country: "NO",
    country_long: "Norway",
    portCode: "NOTMV",
    portName: "Tromvik (NOTMV), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.26756990000001",
    lng: "10.4075608",
    country: "NO",
    country_long: "Norway",
    portCode: "NOTON",
    portName: "Port of Tonsberg",
    portContinent: "Europe",
  },
  {
    lat: "69.6492047",
    lng: "18.9553238",
    country: "NO",
    country_long: "Norway",
    portCode: "NOTOS",
    portName: "Port of Tromso",
    portContinent: "Europe",
  },
  {
    lat: "66.634799",
    lng: "12.0252533",
    country: "NO",
    country_long: "Norway",
    portCode: "NOTRA",
    portName: "Traena (NOTRA), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.4428965",
    lng: "10.4067469",
    country: "NO",
    country_long: "Norway",
    portCode: "NOTRD",
    portName: "Port of Trondheim",
    portContinent: "Europe",
  },
  {
    lat: "58.6222843",
    lng: "8.9313989",
    country: "NO",
    country_long: "Norway",
    portCode: "NOTVE",
    portName: "Port of Tvedestrand",
    portContinent: "Europe",
  },
  {
    lat: "62.3433146",
    lng: "5.8488429",
    country: "NO",
    country_long: "Norway",
    portCode: "NOULS",
    portName: "Ulsteinvik (NOULS), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.72581289999999",
    lng: "9.599832",
    country: "NO",
    country_long: "Norway",
    portCode: "NOUTH",
    portName: "Uthaug (NOUTH), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.47202399999999",
    lng: "8.468945999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOVAE",
    portName: "Valsneset (NOVAE), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.47693700000001",
    lng: "5.740567",
    country: "NO",
    country_long: "Norway",
    portCode: "NOVAK",
    portName: "Port of Vaksdal",
    portContinent: "Europe",
  },
  {
    lat: "70.3706399",
    lng: "31.1095471",
    country: "NO",
    country_long: "Norway",
    portCode: "NOVAO",
    portName: "Port of Vardo",
    portContinent: "Europe",
  },
  {
    lat: "60.47202399999999",
    lng: "8.468945999999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOVAR",
    portName: "Petrojarl Varg Fpso (NOVAR), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.7239057",
    lng: "7.958944",
    country: "NO",
    country_long: "Norway",
    portCode: "NOVDL",
    portName: "Vistdal (NOVDL), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.5052057",
    lng: "5.4288001",
    country: "NO",
    country_long: "Norway",
    portCode: "NOVDO",
    portName: "Valestrandsfossen (NOVDO), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "70.0745056",
    lng: "29.7481181",
    country: "NO",
    country_long: "Norway",
    portCode: "NOVDS",
    portName: "Port of Vadso",
    portContinent: "Europe",
  },
  {
    lat: "63.7933506",
    lng: "11.4815726",
    country: "NO",
    country_long: "Norway",
    portCode: "NOVER",
    portName: "Port of Verdal",
    portContinent: "Europe",
  },
  {
    lat: "59.2947988",
    lng: "5.218739900000001",
    country: "NO",
    country_long: "Norway",
    portCode: "NOVMD",
    portName: "Vedavagen (NOVMD), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.94581789999999",
    lng: "7.017655899999999",
    country: "NO",
    country_long: "Norway",
    portCode: "NOVSM",
    portName: "Vikan (NOVSM), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.6259264",
    lng: "7.086719",
    country: "NO",
    country_long: "Norway",
    portCode: "NOVST",
    portName: "Vestnes (NOVST), Norway, Europe",
    portContinent: "Europe",
  },
  {
    lat: "26.4524746",
    lng: "87.27178099999999",
    country: "NP",
    country_long: "Nepal",
    portCode: "NPBIR",
    portName: "Biratnagar",
    portContinent: "Asia",
  },
  {
    lat: "27.0449005",
    lng: "84.86721709999999",
    country: "NP",
    country_long: "Nepal",
    portCode: "NPBRG",
    portName: "BIRGUNJ",
    portContinent: "Asia",
  },
  {
    lat: "27.0449005",
    lng: "84.86721709999999",
    country: "NP",
    country_long: "Nepal",
    portCode: "NPBSI",
    portName: "Birgunj ICD",
    portContinent: "Asia",
  },
  {
    lat: "27.7172453",
    lng: "85.3239605",
    country: "NP",
    country_long: "Nepal",
    portCode: "NPKTM",
    portName: "Kathmandu",
    portContinent: "Asia",
  },
  {
    lat: "28.2095831",
    lng: "83.9855674",
    country: "NP",
    country_long: "Nepal",
    portCode: "NPPKR",
    portName: "Pokhara",
    portContinent: "Asia",
  },
  {
    lat: "-0.5406599",
    lng: "166.9308159",
    country: "NR",
    country_long: "Nauru",
    portCode: "NRINU",
    portName: "Nauru Island (NRINU), Nauru, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-19.0737811",
    lng: "-169.8520818",
    country: "NU",
    country_long: "Niue",
    portCode: "NUIUE",
    portName: "Niue Island",
    portContinent: "Oceania",
  },
  {
    lat: "-36.85088270000001",
    lng: "174.7644881",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZAKL",
    portName: "Auckland",
    portContinent: "Oceania",
  },
  {
    lat: "-45.2478653",
    lng: "169.3843692",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZALR",
    portName: "ALEXANDRA",
    portContinent: "Oceania",
  },
  {
    lat: "39.3267715",
    lng: "-76.6744784",
    country: "US",
    country_long: "United States",
    portCode: "NZASG",
    portName: "Ashburton",
    portContinent: "Oceania",
  },
  {
    lat: "-46.2343847",
    lng: "169.7460167",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZBAL",
    portName: "Balclutha",
    portContinent: "Oceania",
  },
  {
    lat: "-41.5135459",
    lng: "173.9598069",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZBHE",
    portName: "BLENHEIM (NZBHE), Blenheim, New Zealand",
    portContinent: "Oceania",
  },
  {
    lat: "-46.597299",
    lng: "168.3302209",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZBLU",
    portName: "Bluff (NZBLU), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-37.8891864",
    lng: "175.466267",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZCGE",
    portName: "Cambridge, new zealand",
    portContinent: "Oceania",
  },
  {
    lat: "-43.5320214",
    lng: "172.6305589",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZCHC",
    portName: "CHRISTCHURCH (NZCHC), Blenheim, New Zealand",
    portContinent: "Oceania",
  },
  {
    lat: "-43.9271098",
    lng: "-176.4592091",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZCHT",
    portName: "CHATAM ISLANDS",
    portContinent: "Oceania",
  },
  {
    lat: "-45.0459401",
    lng: "169.1955689",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZCRO",
    portName: "CROMWELL",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "NZCSA",
    portName: "Chelsea (NZCSA), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-40.21029679999999",
    lng: "176.0942328",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZDAN",
    portName: "DANNEVIRKE",
    portContinent: "Oceania",
  },
  {
    lat: "-36.8246787",
    lng: "174.7985219",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZDEV",
    portName: "Devonport (NZDEV), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-45.8795455",
    lng: "170.5005957",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZDUD",
    portName: "Dunedin (NZDUD), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-36.1639848",
    lng: "175.3626406",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZGBS",
    portName: "Port Fitzroy (NZGBS), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-38.6640913",
    lng: "178.0227931",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZGIS",
    portName: "Gisborne",
    portContinent: "Oceania",
  },
  {
    lat: "-42.46142529999999",
    lng: "171.1985024",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZGMN",
    portName: "Greymouth (NZGMN), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NZGOR",
    portName: "Gore",
    portContinent: "Oceania",
  },
  {
    lat: "-39.6302214",
    lng: "176.8304088",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZHAS",
    portName: "HASTINGS, NZ",
    portContinent: "Oceania",
  },
  {
    lat: "-42.7156909",
    lng: "170.9683049",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZHKK",
    portName: "Hokitika",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "NZHLZ",
    portName: "HAMILTON (NZHLZ), Hamilton, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-36.8879591",
    lng: "174.9000073",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZHMB",
    portName: "Half Moon Bay (NZHMB), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-46.4178708",
    lng: "168.3614659",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZINV",
    portName: "Invarcargil",
    portContinent: "Oceania",
  },
  {
    lat: "-46.4178708",
    lng: "168.3614659",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZIVC",
    portName: "Invercargill",
    portContinent: "Oceania",
  },
  {
    lat: "-35.1088272",
    lng: "173.258714",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZKAT",
    portName: "Kaitaia",
    portContinent: "Oceania",
  },
  {
    lat: "-42.3994483",
    lng: "173.6799111",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZKBZ",
    portName: "Kaikura (NZKBZ), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-35.4059867",
    lng: "173.8032186",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZKKO",
    portName: "Kaikohe",
    portContinent: "Oceania",
  },
  {
    lat: "-36.4180983",
    lng: "174.8620054",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZKUI",
    portName: "Kawau (NZKUI), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NZLEV",
    portName: "LEVIN",
    portContinent: "Oceania",
  },
  {
    lat: "-43.6027462",
    lng: "172.7169812",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZLYT",
    portName: "Lyttelton",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "NZMAP",
    portName: "Marsden Point (NZMAP), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-36.0872415",
    lng: "174.5827153",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZMGW",
    portName: "Mangawhai (NZMGW), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-36.9265006",
    lng: "174.8127023",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZMKL",
    portName: "Metroport auckland (NZMKL), Auckland, New Zealand",
    portContinent: "Oceania",
  },
  {
    lat: "-37.6646852",
    lng: "176.2080948",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZMMU",
    portName: "Maunganui",
    portContinent: "Oceania",
  },
  {
    lat: "-40.9463736",
    lng: "175.667234",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZMRO",
    portName: "Masterton",
    portContinent: "Oceania",
  },
  {
    lat: "-37.8084687",
    lng: "175.7709978",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZMTA",
    portName: "Matamata",
    portContinent: "Oceania",
  },
  {
    lat: "-34.4753889",
    lng: "19.8960968",
    country: "ZA",
    country_long: "South Africa",
    portCode: "NZNPE",
    portName: "Napier",
    portContinent: "Oceania",
  },
  {
    lat: "-39.0571533",
    lng: "174.0794082",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZNPL",
    portName: "New Plymouth (NZNPL), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-41.2707886",
    lng: "173.2809786",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZNSN",
    portName: "Nelson (NZNSN), Nelson, New Zealand",
    portContinent: "Oceania",
  },
  {
    lat: "-45.0965979",
    lng: "170.9714456",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZOAM",
    portName: "Oamaru",
    portContinent: "Oceania",
  },
  {
    lat: "-36.9229519",
    lng: "174.7853602",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZONE",
    portName: "Onehunga (NZONE), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-35.314286",
    lng: "174.1182781",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZOPX",
    portName: "Opua (NZOPX), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-35.2821294",
    lng: "174.0910282",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZPAI",
    portName: "Paihia (NZPAI), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-41.2954812",
    lng: "174.0028153",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZPCN",
    portName: "Picton (NZPCN), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-40.3544628",
    lng: "175.6097478",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZPMR",
    portName: "Palmerston North",
    portContinent: "Oceania",
  },
  {
    lat: "-45.8178425",
    lng: "170.6188459",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZPOE",
    portName: "Port Chalmers",
    portContinent: "Oceania",
  },
  {
    lat: "-41.2653466",
    lng: "173.2774862",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZPON",
    portName: "Port Nelson",
    portContinent: "Oceania",
  },
  {
    lat: "-41.1380517",
    lng: "174.8472141",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZPRR",
    portName: "Porirua (NZPRR), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-45.8641938",
    lng: "170.5569173",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZRAV",
    portName: "Ravensbourne (NZRAV), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-46.3545151",
    lng: "168.0100598",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZRIV",
    portName: "Riverton (NZRIV), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-38.1445987",
    lng: "176.2377669",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZROT",
    portName: "Rotorua",
    portContinent: "Oceania",
  },
  {
    lat: "-35.259646",
    lng: "174.121693",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZRUS",
    portName: "Russell (NZRUS), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-47.0103212",
    lng: "167.8272285",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZSZS",
    portName: "Stewart Island (NZSZS), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-44.3903881",
    lng: "171.2372756",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZTIU",
    portName: "Timaru",
    portContinent: "Oceania",
  },
  {
    lat: "-44.24536759999999",
    lng: "171.2763124",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZTKA",
    portName: "TEMUKA (NZTKA), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-40.8305753",
    lng: "172.8927947",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZTKH",
    portName: "Tarakohe (NZTKH), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "51.57448430000298",
    lng: "-0.6092532116909238",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "NZTMZ",
    portName: "THAMES",
    portContinent: "Oceania",
  },
  {
    lat: "-37.6869653",
    lng: "176.1654272",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZTRG",
    portName: "Tauranga",
    portContinent: "Oceania",
  },
  {
    lat: "-38.6842862",
    lng: "176.0704465",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZTUO",
    portName: "Taupo",
    portContinent: "Oceania",
  },
  {
    lat: "-39.9330715",
    lng: "175.0286083",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZWAG",
    portName: "WANGANUI",
    portContinent: "Oceania",
  },
  {
    lat: "-36.6355537",
    lng: "174.7480379",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZWGA",
    portName: "Whangaparaoa (NZWGA), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-37.95853109999999",
    lng: "176.9822259",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZWHK",
    portName: "Whakatane (NZWHK), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-44.6942992",
    lng: "169.1417356",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZWKA",
    portName: "Wanaka",
    portContinent: "Oceania",
  },
  {
    lat: "-36.8000487",
    lng: "175.1009721",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZWKE",
    portName: "Waiheke Island (NZWKE), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "NZWKW",
    portName: "Waikawa (NZWKW), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-41.2923814",
    lng: "174.7787463",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZWLG",
    portName: "Wellington",
    portContinent: "Oceania",
  },
  {
    lat: "-35.7387978",
    lng: "174.3349242",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZWRE",
    portName: "Port of Whangarei",
    portContinent: "Oceania",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "NZWSZ",
    portName: "Port of Westport",
    portContinent: "Oceania",
  },
  {
    lat: "-36.8350132",
    lng: "175.7007296",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZWTZ",
    portName: "Whitianga (NZWTZ), New Zealand, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-45.0301511",
    lng: "168.6615141",
    country: "NZ",
    country_long: "New Zealand",
    portCode: "NZZQN",
    portName: "Queenstown",
    portContinent: "Oceania",
  },
  {
    lat: "19.6394818",
    lng: "57.67791059999999",
    country: "OM",
    country_long: "Oman",
    portCode: "OMDQM",
    portName: "DUQM (OMDQM), Oman, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "23.5880307",
    lng: "58.3828717",
    country: "OM",
    country_long: "Oman",
    portCode: "OMMCT",
    portName: "Port of Muscat",
    portContinent: "Middle East",
  },
  {
    lat: "23.6242499",
    lng: "58.51724919999999",
    country: "OM",
    country_long: "Oman",
    portCode: "OMMFH",
    portName: "Port of Mina al Fahal",
    portContinent: "Middle East",
  },
  {
    lat: "23.6286554",
    lng: "58.5659182",
    country: "OM",
    country_long: "Oman",
    portCode: "OMMNQ",
    portName: "MINA QABOOS (OMMNQ), Muscat, Oman",
    portContinent: "Middle East",
  },
  {
    lat: "23.5880307",
    lng: "58.3828717",
    country: "OM",
    country_long: "Oman",
    portCode: "OMOPQ",
    portName: "Muscat (OMOPQ), Oman, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "22.7002864",
    lng: "59.3713814",
    country: "OM",
    country_long: "Oman",
    portCode: "OMQAL",
    portName: "Port of Qalhat",
    portContinent: "Middle East",
  },
  {
    lat: "16.9414873",
    lng: "53.9953894",
    country: "OM",
    country_long: "Oman",
    portCode: "OMSLL",
    portName: "Port of Salalah",
    portContinent: "Middle East",
  },
  {
    lat: "24.4848505",
    lng: "56.6121145",
    country: "OM",
    country_long: "Oman",
    portCode: "OMSOH",
    portName: "Port of Sohar",
    portContinent: "Middle East",
  },
  {
    lat: "9.1983145",
    lng: "-77.9873008",
    country: "PA",
    country_long: "Panama",
    portCode: "PAACU",
    portName: "Achutupu (PAACU), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "8.2742896",
    lng: "-82.8600183",
    country: "PA",
    country_long: "Panama",
    portCode: "PAAML",
    portName: "Puerto Armuelles (PAAML), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PABLB",
    portName: "Balboa",
    portContinent: "North America",
  },
  {
    lat: "9.4047951",
    lng: "-82.269193",
    country: "PA",
    country_long: "Panama",
    portCode: "PABOC",
    portName: "Bocas Del Toro (PABOC), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "9.375435699999999",
    lng: "-79.8849825",
    country: "PA",
    country_long: "Panama",
    portCode: "PACCT",
    portName: "Colon Container Terminal",
    portContinent: "North America",
  },
  {
    lat: "9.359302",
    lng: "-79.89986080000001",
    country: "PA",
    country_long: "Panama",
    portCode: "PACFZ",
    portName: "COLON Free Zone (PACFZ), Colon, Panama",
    portContinent: "North America",
  },
  {
    lat: "8.9570626",
    lng: "-82.1441643",
    country: "PA",
    country_long: "Panama",
    portCode: "PACHG",
    portName: "Chiriqui Grande (PACHG), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "9.375435699999999",
    lng: "-79.8849825",
    country: "PA",
    country_long: "Panama",
    portCode: "PACOL",
    portName: "COLON CONTAINER TERMINAL",
    portContinent: "North America",
  },
  {
    lat: "9.368985",
    lng: "-79.879913",
    country: "PA",
    country_long: "Panama",
    portCode: "PACSO",
    portName: "Coco Solo",
    portContinent: "North America",
  },
  {
    lat: "9.350598999999999",
    lng: "-79.901527",
    country: "PA",
    country_long: "Panama",
    portCode: "PACTB",
    portName: "Cristobal (PACTB), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "8.0317688",
    lng: "-78.3159411",
    country: "PA",
    country_long: "Panama",
    portCode: "PAGHE",
    portName: "Garachine (PAGHE), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "19.1138094",
    lng: "-104.3384616",
    country: "MX",
    country_long: "Mexico",
    portCode: "PAMIT",
    portName: "Manzanillo",
    portContinent: "North America",
  },
  {
    lat: "19.6906698",
    lng: "-97.146034",
    country: "MX",
    country_long: "Mexico",
    portCode: "PAMNP",
    portName: "LAS MINAS",
    portContinent: "North America",
  },
  {
    lat: "9.1791225",
    lng: "-77.98243029999999",
    country: "PA",
    country_long: "Panama",
    portCode: "PAMPI",
    portName: "Mamitupu (PAMPI), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "9.3651477",
    lng: "-79.8811514",
    country: "PA",
    country_long: "Panama",
    portCode: "PAONX",
    portName: "Colon, Manzanillo International Terminal",
    portContinent: "North America",
  },
  {
    lat: "8.6274201",
    lng: "-79.0376672",
    country: "PA",
    country_long: "Panama",
    portCode: "PAOTD",
    portName: "Contadora (PAOTD), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "9.2979179",
    lng: "-82.42110249999999",
    country: "PA",
    country_long: "Panama",
    portCode: "PAPAM",
    portName: "Almirante (PAPAM), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "9.1056711",
    lng: "-79.4057845",
    country: "PA",
    country_long: "Panama",
    portCode: "PAPBE",
    portName: "Portobelo (PAPBE), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "7.8005358",
    lng: "-80.74638519999999",
    country: "PA",
    country_long: "Panama",
    portCode: "PAPBM",
    portName: "Las Minas (PAPBM), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "9.14380343698933",
    lng: "-79.72851610370265",
    country: "PA",
    country_long: "Panama",
    portCode: "PAPCN",
    portName: "Panama Canal",
    portContinent: "North America",
  },
  {
    lat: "8.2853585",
    lng: "-78.1792434",
    country: "PA",
    country_long: "Panama",
    portCode: "PAPLP",
    portName: "La Palma (PAPLP), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "8.9161541",
    lng: "-79.5996716",
    country: "PA",
    country_long: "Panama",
    portCode: "PAPPF",
    portName: "Panama Pacifico",
    portContinent: "North America",
  },
  {
    lat: "9.0668559",
    lng: "-79.3871594",
    country: "PA",
    country_long: "Panama",
    portCode: "PAPTY",
    portName: "Panama City (PAPTY), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "8.537981",
    lng: "-80.782127",
    country: "PA",
    country_long: "Panama",
    portCode: "PAROD",
    portName: "Rodman (PAROD), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "8.9477698",
    lng: "-79.5628779",
    country: "PA",
    country_long: "Panama",
    portCode: "PATBG",
    portName: "Taboguilla (PATBG), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "9.0674846",
    lng: "-79.3690831",
    country: "PA",
    country_long: "Panama",
    portCode: "PATOC",
    portName: "TOCUMEN",
    portContinent: "North America",
  },
  {
    lat: "9.1324305",
    lng: "-77.9267437",
    country: "PA",
    country_long: "Panama",
    portCode: "PAUTU",
    portName: "Ustupo (PAUTU), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "8.9149089",
    lng: "-79.701985",
    country: "PA",
    country_long: "Panama",
    portCode: "PAVAC",
    portName: "Port of Vacamonte",
    portContinent: "North America",
  },
  {
    lat: "8.8876607",
    lng: "-79.6421415",
    country: "PA",
    country_long: "Panama",
    portCode: "PAVCZ",
    portName: "Veracruz (PAVCZ), Panama, LatAm",
    portContinent: "North America",
  },
  {
    lat: "43.6207161",
    lng: "13.5087895",
    country: "IT",
    country_long: "Italy",
    portCode: "PEANC",
    portName: "Port of Ancon",
    portContinent: "South America",
  },
  {
    lat: "18.220833",
    lng: "-66.590149",
    country: "PR",
    country_long: "Puerto Rico",
    portCode: "PEATI",
    portName: "Puerto Atico",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PECAB",
    portName: "Puerto de Cabo Blanco",
    portContinent: "South America",
  },
  {
    lat: "-9.063336399999999",
    lng: "-78.589011",
    country: "PE",
    country_long: "Peru",
    portCode: "PECHM",
    portName: "Port of Chimbote",
    portContinent: "South America",
  },
  {
    lat: "-12.0508491",
    lng: "-77.1259843",
    country: "PE",
    country_long: "Peru",
    portCode: "PECLL",
    portName: "Port of Callao",
    portContinent: "South America",
  },
  {
    lat: "-6.4437428",
    lng: "-78.6559664",
    country: "PE",
    country_long: "Peru",
    portCode: "PECON",
    portName: "Puerto de Conchan",
    portContinent: "South America",
  },
  {
    lat: "-6.9263501",
    lng: "-79.8646835",
    country: "PE",
    country_long: "Peru",
    portCode: "PEEEN",
    portName: "Puerto de Eten",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PEGSM",
    portName: "Port of General San Martin",
    portContinent: "South America",
  },
  {
    lat: "-11.1227218",
    lng: "-77.61056789999999",
    country: "PE",
    country_long: "Peru",
    portCode: "PEHCO",
    portName: "Port of Huacho",
    portContinent: "South America",
  },
  {
    lat: "-10.1001585",
    lng: "-78.16926889999999",
    country: "PE",
    country_long: "Peru",
    portCode: "PEHUY",
    portName: "Puerto de Huarmey",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PEILQ",
    portName: "Port of Ilo",
    portContinent: "South America",
  },
  {
    lat: "-3.7436735",
    lng: "-73.25163260000001",
    country: "PE",
    country_long: "Peru",
    portCode: "PEIQT",
    portName: "Port of Iquitos",
    portContinent: "South America",
  },
  {
    lat: "-12.0463731",
    lng: "-77.042754",
    country: "PE",
    country_long: "Peru",
    portCode: "PELIM",
    portName: "Lima",
    portContinent: "South America",
  },
  {
    lat: "-42.0037422",
    lng: "-65.07406619999999",
    country: "AR",
    country_long: "Argentina",
    portCode: "PELOB",
    portName: "Puerto de Lobitos",
    portContinent: "South America",
  },
  {
    lat: "-11.9198981",
    lng: "-77.1298241",
    country: "PE",
    country_long: "Peru",
    portCode: "PELPP",
    portName: "Puerto de La Pampilla",
    portContinent: "South America",
  },
  {
    lat: "-17.0206337",
    lng: "-72.0171862",
    country: "PE",
    country_long: "Peru",
    portCode: "PEMLQ",
    portName: "Puerto de Mollendo",
    portContinent: "South America",
  },
  {
    lat: "-17.0054057",
    lng: "-72.1001434",
    country: "PE",
    country_long: "Peru",
    portCode: "PEMRI",
    portName: "Port of Matarani",
    portContinent: "South America",
  },
  {
    lat: "-7.4040051",
    lng: "-79.565078",
    country: "PE",
    country_long: "Peru",
    portCode: "PEPAC",
    portName: "Puerto de Pacasmayo",
    portContinent: "South America",
  },
  {
    lat: "-5.0938488",
    lng: "-81.0962172",
    country: "PE",
    country_long: "Peru",
    portCode: "PEPAI",
    portName: "Port of Paita",
    portContinent: "South America",
  },
  {
    lat: "-10.6784649",
    lng: "-77.8195739",
    country: "PE",
    country_long: "Peru",
    portCode: "PEPAM",
    portName: "Puerto de Paramonga",
    portContinent: "South America",
  },
  {
    lat: "-7.7001724",
    lng: "-79.4338188",
    country: "PE",
    country_long: "Peru",
    portCode: "PEPCH",
    portName: "Puerto Chicama (PEPCH), Peru, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PEPIO",
    portName: "Puerto de Pisco",
    portContinent: "South America",
  },
  {
    lat: "-12.0227931",
    lng: "-76.8947948",
    country: "PE",
    country_long: "Peru",
    portCode: "PEPMC",
    portName: "Pampa Melchorita (PEPMC), Peru, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PEPMT",
    portName: "Puerto de Pimentel",
    portContinent: "South America",
  },
  {
    lat: "-5.8277126",
    lng: "-81.029228",
    country: "PE",
    country_long: "Peru",
    portCode: "PEPUB",
    portName: "Port of Bayovar",
    portContinent: "South America",
  },
  {
    lat: "-9.2599058",
    lng: "-78.4936632",
    country: "PE",
    country_long: "Peru",
    portCode: "PESAM",
    portName: "Puerto de Samanco",
    portContinent: "South America",
  },
  {
    lat: "-33.3547911",
    lng: "-60.1703274",
    country: "AR",
    country_long: "Argentina",
    portCode: "PESNX",
    portName: "Port of San Nicolas",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PESUP",
    portName: "Port of Supe",
    portContinent: "South America",
  },
  {
    lat: "-8.2141205",
    lng: "-78.97689989999999",
    country: "PE",
    country_long: "Peru",
    portCode: "PESVY",
    portName: "Port of Salaverry",
    portContinent: "South America",
  },
  {
    lat: "-4.5733385",
    lng: "-81.2763158",
    country: "PE",
    country_long: "Peru",
    portCode: "PETYL",
    portName: "Port of Talara",
    portContinent: "South America",
  },
  {
    lat: "-16.5004126",
    lng: "-151.7414904",
    country: "PF",
    country_long: "French Polynesia",
    portCode: "PFBOB",
    portName: "Port of Bora-Bora",
    portContinent: "Oceania",
  },
  {
    lat: "-17.5334014",
    lng: "-149.5774979",
    country: "PF",
    country_long: "French Polynesia",
    portCode: "PFPPT",
    portName: "Port of Papeete",
    portContinent: "Oceania",
  },
  {
    lat: "-3.1538273",
    lng: "142.3639991",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGATP",
    portName: "AITAPE",
    portContinent: "Oceania",
  },
  {
    lat: "-5.2384267",
    lng: "154.6451287",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGBUA",
    portName: "BUKA ISLAND (PGBUA), Papua New Guinea, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-10.3157027",
    lng: "150.4587795",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGGUR",
    portName: "ALOTAU (PGGUR), Alotau, Papua New Guinea",
    portContinent: "Oceania",
  },
  {
    lat: "-6.2204747",
    lng: "155.6376301",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGKIE",
    portName: "Kieta (PGKIE), Papua New Guinea, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-5.5511652",
    lng: "150.1387297",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGKIM",
    portName: "Kimbe",
    portContinent: "Oceania",
  },
  {
    lat: "-8.8772848",
    lng: "147.7374471",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGKKD",
    portName: "KOKODA",
    portContinent: "Oceania",
  },
  {
    lat: "-2.0985176",
    lng: "137.6652051",
    country: "ID",
    country_long: "Indonesia",
    portCode: "PGKMA",
    portName: "Kerema (PGKMA), Papua New Guinea, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-6.314992999999999",
    lng: "143.95555",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGKUL",
    portName: "Kumul (PGKUL), Papua New Guinea, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-2.5781167",
    lng: "150.8086082",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGKVG",
    portName: "KAVIENG (PGKVG), Kavieng, Papua New Guinea",
    portContinent: "Oceania",
  },
  {
    lat: "-6.7155252",
    lng: "146.999905",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGLAE",
    portName: "Lae",
    portContinent: "Oceania",
  },
  {
    lat: "-6.314992999999999",
    lng: "143.95555",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGLNG",
    portName: "Lese (PGLNG), Papua New Guinea, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-3.0896243",
    lng: "152.5678548",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGLNV",
    portName: "LIHIR ISLAND (PGLNV), Papua New Guinea, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-6.1864979",
    lng: "155.5425651",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGLOL",
    portName: "LOLOHO",
    portContinent: "Oceania",
  },
  {
    lat: "-2.0298424",
    lng: "147.2743657",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGLOR",
    portName: "LORENGAU (PGLOR), Lorengau, Papua New Guinea",
    portContinent: "Oceania",
  },
  {
    lat: "-5.2218841",
    lng: "145.7856324",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGMAG",
    portName: "Madang",
    portContinent: "Oceania",
  },
  {
    lat: "-9.4333333",
    lng: "147.1",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGMTK",
    portName: "MOTUKEA ISLAND (PGMTK), Papua New Guinea, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-8.7613025",
    lng: "148.2377126",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGPNP",
    portName: "Popondetta",
    portContinent: "Oceania",
  },
  {
    lat: "-9.443800399999999",
    lng: "147.1802671",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGPOM",
    portName: "Port Moresby",
    portContinent: "Oceania",
  },
  {
    lat: "-4.1999648",
    lng: "152.1644612",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGRAB",
    portName: "Rabaul",
    portContinent: "Oceania",
  },
  {
    lat: "-8.883333",
    lng: "148.5",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGROR",
    portName: "OroBay",
    portContinent: "Oceania",
  },
  {
    lat: "-33.7313897",
    lng: "-70.742431",
    country: "CL",
    country_long: "Chile",
    portCode: "PGUBI",
    portName: "BUIN",
    portContinent: "Oceania",
  },
  {
    lat: "-6.1202907",
    lng: "141.2998457",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGUNG",
    portName: "Kiunga (PGUNG), Papua New Guinea, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-2.6896767",
    lng: "141.2998457",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGVAI",
    portName: "Vanimo (PGVAI), Papua New Guinea, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-3.5800229",
    lng: "143.6583166",
    country: "PG",
    country_long: "Papua New Guinea",
    portCode: "PGWWK",
    portName: "Wewak",
    portContinent: "Oceania",
  },
  {
    lat: "13.0570153",
    lng: "124.1225927",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHBAA",
    portName: "Balogo (PHBAA), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "9.3068402",
    lng: "123.3054467",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHBAI",
    portName: "DUMAGUETE",
    portContinent: "South East Asia",
  },
  {
    lat: "10.6840053",
    lng: "122.9563021",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHBCD",
    portName: "Bacolod (PHBCD), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "11.6079912",
    lng: "125.4328501",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHBGV",
    portName: "Port of Borongan",
    portContinent: "South East Asia",
  },
  {
    lat: "13.7564651",
    lng: "121.0583076",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHBLG",
    portName: "BATANGAS",
    portContinent: "South East Asia",
  },
  {
    lat: "11.0128292",
    lng: "123.986149",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHBOG",
    portName: "Bogo (PHBOG), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "11.9673753",
    lng: "121.924815",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHBOR",
    portName: "Boracay (PHBOR), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "8.9475377",
    lng: "125.5406234",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHBTC",
    portName: "BUTUAN",
    portContinent: "South East Asia",
  },
  {
    lat: "13.7564651",
    lng: "121.0583076",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHBTG",
    portName: "Batangas/Luzon",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PHBUG",
    portName: "Port of Bugo",
    portContinent: "South East Asia",
  },
  {
    lat: "8.9475377",
    lng: "125.5406234",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHBXU",
    portName: "BUTUAN",
    portContinent: "South East Asia",
  },
  {
    lat: "10.6521412",
    lng: "124.8525626",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHBYB",
    portName: "Baybay (PHBYB), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "13.9508989",
    lng: "120.8269231",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHCAA",
    portName: "Calaca (PHCAA), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "7.2046668",
    lng: "124.2310439",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHCBO",
    portName: "COTABATO",
    portContinent: "South East Asia",
  },
  {
    lat: "12.0675749",
    lng: "124.5929574",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHCBY",
    portName: "CALBAYOG",
    portContinent: "South East Asia",
  },
  {
    lat: "10.0174388",
    lng: "125.6642155",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHCDN",
    portName: "Cagdianao (PHCDN), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PHCEB",
    portName: "Port of Cebu",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PHCFA",
    portName: "CLARK",
    portContinent: "South East Asia",
  },
  {
    lat: "8.4542363",
    lng: "124.6318977",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHCGY",
    portName: "Cagayan de Oro, Mindanao",
    portContinent: "South East Asia",
  },
  {
    lat: "13.3770935",
    lng: "121.1645706",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHCLP",
    portName: "Calapan (PHCLP), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "17.9879438",
    lng: "120.487714",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHCMO",
    portName: "Port of Currimao",
    portContinent: "South East Asia",
  },
  {
    lat: "14.2855935",
    lng: "122.4693688",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHCPL",
    portName: "Capalonga (PHCPL), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "11.6103626",
    lng: "122.7154181",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHCSI",
    portName: "Port of Culasi",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PHCTS",
    portName: "CATABOLOGAN",
    portContinent: "South East Asia",
  },
  {
    lat: "11.4894843",
    lng: "124.3643423",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHCUG",
    portName: "CALUBIAN",
    portContinent: "South East Asia",
  },
  {
    lat: "14.4791297",
    lng: "120.8969634",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHCVE",
    portName: "CAVITE",
    portContinent: "South East Asia",
  },
  {
    lat: "7.3087068",
    lng: "125.6841178",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHDCT",
    portName: "Panabo City (PHDCT), Davao, Philippines",
    portContinent: "South East Asia",
  },
  {
    lat: "9.3068402",
    lng: "123.3054467",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHDGT",
    portName: "Port of Dumaguete",
    portContinent: "South East Asia",
  },
  {
    lat: "8.5142736",
    lng: "123.3373916",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHDPL",
    portName: "Dipolog",
    portContinent: "South East Asia",
  },
  {
    lat: "7.190708",
    lng: "125.455341",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHDVO",
    portName: "Davao, Mindanao",
    portContinent: "South East Asia",
  },
  {
    lat: "30.3931997",
    lng: "-97.7474845",
    country: "US",
    country_long: "United States",
    portCode: "PHECI",
    portName: "ESTANCIA",
    portContinent: "South East Asia",
  },
  {
    lat: "11.1905581",
    lng: "119.5082006",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHENI",
    portName: "El Nido (PHENI), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "6.1163861",
    lng: "125.171618",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHGES",
    portName: "Port of General Santos",
    portContinent: "South East Asia",
  },
  {
    lat: "6.1163861",
    lng: "125.171618",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHGSA",
    portName: "General Santos City",
    portContinent: "South East Asia",
  },
  {
    lat: "6.1163861",
    lng: "125.171618",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHGSC",
    portName: "General Santos",
    portContinent: "South East Asia",
  },
  {
    lat: "10.3708121",
    lng: "124.7533672",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHHIL",
    portName: "Hilongos (PHHIL), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "11.0897262",
    lng: "123.9457337",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHHYB",
    portName: "Hagnaya (PHHYB), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "8.228020899999999",
    lng: "124.2452422",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHIGN",
    portName: "ILIGAN",
    portContinent: "South East Asia",
  },
  {
    lat: "10.7201501",
    lng: "122.5621063",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHILO",
    portName: "Iloilo (PHILO), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "10.9179536",
    lng: "124.4473541",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHISL",
    portName: "Isabel (PHISL), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "9.6504905",
    lng: "124.3653473",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHJAG",
    portName: "Jagna (PHJAG), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "5.969181499999999",
    lng: "121.1252135",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHJOL",
    portName: "Jolo (PHJOL), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "6.553848299999999",
    lng: "124.0522756",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHKLM",
    portName: "Port of Kalamansig",
    portContinent: "South East Asia",
  },
  {
    lat: "33.5427189",
    lng: "-117.7853568",
    country: "US",
    country_long: "United States",
    portCode: "PHLGU",
    portName: "LAGUNA",
    portContinent: "South East Asia",
  },
  {
    lat: "14.5278776",
    lng: "120.5509358",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHLIM",
    portName: "Limay (PHLIM), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "8.348014899999999",
    lng: "124.2754988",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHLUG",
    portName: "Lugait (PHLUG), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "10.1315393",
    lng: "124.8411655",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHMAA",
    portName: "Port of Maasin",
    portContinent: "South East Asia",
  },
  {
    lat: "12.3691928",
    lng: "123.616353",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHMBT",
    portName: "Port of Masbate",
    portContinent: "South East Asia",
  },
  {
    lat: "13.1024111",
    lng: "120.7651284",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHMCD",
    portName: "Mercedes (PHMCD), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "8.4542363",
    lng: "124.6318977",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHMIC",
    portName: "CAGAYAN DE ORO",
    portContinent: "South East Asia",
  },
  {
    lat: "8.162410999999999",
    lng: "123.7741195",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHMIO",
    portName: "Ozamis",
    portContinent: "South East Asia",
  },
  {
    lat: "14.5833333",
    lng: "120.95",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHMNL",
    portName: "Port of Manila",
    portContinent: "South East Asia",
  },
  {
    lat: "14.6161111",
    lng: "120.9566667",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHMNN",
    portName: "Manila North Harbour (PHMNN), Manila, Philippines",
    portContinent: "South East Asia",
  },
  {
    lat: "14.5833333",
    lng: "120.95",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHMNS",
    portName: "Manila South Harbour",
    portContinent: "South East Asia",
  },
  {
    lat: "11.925186",
    lng: "121.9528174",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHMPH",
    portName: "Caticlan (PHMPH), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "14.554729",
    lng: "121.0244452",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHMTI",
    portName: "MAKATI",
    portContinent: "South East Asia",
  },
  {
    lat: "8.9714897",
    lng: "125.3518224",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHNAS",
    portName: "Nasipit (PHNAS), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "12.40467",
    lng: "122.0155491",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHODI",
    portName: "Odiongan (PHODI), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "14.8386303",
    lng: "120.2842023",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHOLO",
    portName: "Olongapo",
    portContinent: "South East Asia",
  },
  {
    lat: "11.0384275",
    lng: "124.6192702",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHORC",
    portName: "ORMOC",
    portContinent: "South East Asia",
  },
  {
    lat: "8.162410999999999",
    lng: "123.7741195",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHOZC",
    portName: "Ozamiz (PHOZC), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "7.3087068",
    lng: "125.6841178",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHPAN",
    portName: "Panabo",
    portContinent: "South East Asia",
  },
  {
    lat: "7.357676478587187",
    lng: "124.2393797833641",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHPLC",
    portName: "Polloc Harbor (PHPLC), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "14.737501",
    lng: "121.9245556",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHPLL",
    portName: "Polillo (PHPLL), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "11.0172133",
    lng: "124.4087307",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHPPN",
    portName: "Port of Palompon",
    portContinent: "South East Asia",
  },
  {
    lat: "9.7638429",
    lng: "118.7473298",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHPPS",
    portName: "PUERTO PRINCES",
    portContinent: "South East Asia",
  },
  {
    lat: "10.5204186",
    lng: "122.7961719",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHPUL",
    portName: "Port of Pulupandan",
    portContinent: "South East Asia",
  },
  {
    lat: "13.9084966",
    lng: "121.617417",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHQZT",
    portName: "Dalahican (PHQZT), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "12.5778554",
    lng: "122.269129",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHRLN",
    portName: "Romblon Terminal",
    portContinent: "South East Asia",
  },
  {
    lat: "10.1742503",
    lng: "118.8713314",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHSBG",
    portName: "Sabang (PHSBG), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "14.8156046",
    lng: "120.2834182",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHSFS",
    portName: "Port of Subic Bay",
    portContinent: "South East Asia",
  },
  {
    lat: "12.07044",
    lng: "121.392097",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHSMR",
    portName: "Semirara (PHSMR), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "12.879721",
    lng: "121.774017",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHSNG",
    portName: "Sangi (PHSNG), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "13.1390621",
    lng: "123.7437995",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHSOR",
    portName: "Legazpi (PHSOR), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "9.7600151",
    lng: "125.4821706",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHSUG",
    portName: "Surigao (PHSUG), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "11.2543388",
    lng: "124.9616867",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHTAC",
    portName: "Tacloban (PHTAC), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "9.672948",
    lng: "123.8730022",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHTAG",
    portName: "Tagbilaran (PHTAG), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PHTAN",
    portName: "Port of Tandayag",
    portContinent: "South East Asia",
  },
  {
    lat: "13.3240963",
    lng: "123.645139",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHTBC",
    portName: "Tabaco (PHTBC), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "9.9144971",
    lng: "123.9638531",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHTON",
    portName: "Tubigon (PHTON), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "9.475221399999999",
    lng: "125.7952667",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHTTO",
    portName: "Taganito (PHTTO), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "8.576953399999999",
    lng: "124.7851264",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHVNV",
    portName: "Villanueva (PHVNV), Philippines, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "13.5818072",
    lng: "124.2340835",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHVRC",
    portName: "Port of Virac",
    portContinent: "South East Asia",
  },
  {
    lat: "11.991053",
    lng: "120.2114673",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHXCN",
    portName: "Port of Coron",
    portContinent: "South East Asia",
  },
  {
    lat: "6.902646",
    lng: "122.074006",
    country: "PH",
    country_long: "Philippines",
    portCode: "PHZAM",
    portName: "Port of Zamboanga",
    portContinent: "South East Asia",
  },
  {
    lat: "24.7782039",
    lng: "67.3354398",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKBQM",
    portName: "Port Qasim (PKBQM), Karachi, Pakistan",
    portContinent: "Asia",
  },
  {
    lat: "25.1104955",
    lng: "62.339648",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKGWD",
    portName: "Port of Gwadar",
    portContinent: "Asia",
  },
  {
    lat: "33.6844202",
    lng: "73.04788479999999",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKISB",
    portName: "Islamabad",
    portContinent: "Asia",
  },
  {
    lat: "25.0537508",
    lng: "61.7707288",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKJIW",
    portName: "Jiwani (PKJIW), Pakistan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "24.8253514",
    lng: "66.9767619",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKKCT",
    portName: "KARACHI PICT",
    portContinent: "Asia",
  },
  {
    lat: "24.835865",
    lng: "66.9831639",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKKHI",
    portName: "Port of Karachi",
    portContinent: "Asia",
  },
  {
    lat: "24.8225808",
    lng: "66.9821035",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKKIA",
    portName: "Kiamari",
    portContinent: "Asia",
  },
  {
    lat: "31.5203696",
    lng: "74.35874729999999",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKLHE",
    portName: "Lahore",
    portContinent: "Asia",
  },
  {
    lat: "31.45036619999999",
    lng: "73.13496049999999",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKLYP",
    portName: "FAISALABAD",
    portContinent: "Asia",
  },
  {
    lat: "30.157458",
    lng: "71.5249154",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKMUX",
    portName: "Multan",
    portContinent: "Asia",
  },
  {
    lat: "25.2665929",
    lng: "64.6095785",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKORW",
    portName: "Ormara (PKORW), Pakistan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "24.8253514",
    lng: "66.9767619",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKPCT",
    portName: "Pakistan International Container Terminal/Karachi",
    portContinent: "Asia",
  },
  {
    lat: "34.0151366",
    lng: "71.5249154",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKPEW",
    portName: "PESHAWAR",
    portContinent: "Asia",
  },
  {
    lat: "33.6844202",
    lng: "73.04788479999999",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKPKT",
    portName: "ISLAMABAD",
    portContinent: "Asia",
  },
  {
    lat: "25.2509599",
    lng: "63.4154233",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKPSI",
    portName: "Pasni (PKPSI), Pakistan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "24.7663601",
    lng: "67.326336",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKQCT",
    portName: "Qasim International Container Terminal/Karachi",
    portContinent: "Asia",
  },
  {
    lat: "24.8253514",
    lng: "66.9767619",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKQFS",
    portName: "KARACHI PICT",
    portContinent: "Asia",
  },
  {
    lat: "33.5651107",
    lng: "73.0169135",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKRWP",
    portName: "Rawalpindi",
    portContinent: "Asia",
  },
  {
    lat: "32.4944991",
    lng: "74.5228916",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKSKT",
    portName: "SIALKOT",
    portContinent: "Asia",
  },
  {
    lat: "32.4404179",
    lng: "74.120255",
    country: "PK",
    country_long: "Pakistan",
    portCode: "PKWZA",
    portName: "Wazirabad",
    portContinent: "Asia",
  },
  {
    lat: "50.3045979",
    lng: "19.3880198",
    country: "PL",
    country_long: "Poland",
    portCode: "PL1KW",
    portName: "SLAWKOW (PL1KW), Poland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.13248859999999",
    lng: "23.1688403",
    country: "PL",
    country_long: "Poland",
    portCode: "PLBIA",
    portName: "Bialystok",
    portContinent: "Europe",
  },
  {
    lat: "52.1985472",
    lng: "20.6169567",
    country: "PL",
    country_long: "Poland",
    portCode: "PLBLO",
    portName: "Blonie",
    portContinent: "Europe",
  },
  {
    lat: "49.9881045",
    lng: "20.2256748",
    country: "PL",
    country_long: "Poland",
    portCode: "PLBRE",
    portName: "Brzezie",
    portContinent: "Europe",
  },
  {
    lat: "53.13248859999999",
    lng: "23.1688403",
    country: "PL",
    country_long: "Poland",
    portCode: "PLBYA",
    portName: "BYALYSTOK",
    portContinent: "Europe",
  },
  {
    lat: "51.2728265",
    lng: "16.708557",
    country: "PL",
    country_long: "Poland",
    portCode: "PLBZD",
    portName: "Brzeg Dolny",
    portContinent: "Europe",
  },
  {
    lat: "53.12348040000001",
    lng: "18.0084378",
    country: "PL",
    country_long: "Poland",
    portCode: "PLBZG",
    portName: "BYDGOSZCZ",
    portContinent: "Europe",
  },
  {
    lat: "50.3216897",
    lng: "19.1949126",
    country: "PL",
    country_long: "Poland",
    portCode: "PLDAB",
    portName: "Dabrowa Gornicza",
    portContinent: "Europe",
  },
  {
    lat: "54.4210664",
    lng: "16.410611",
    country: "PL",
    country_long: "Poland",
    portCode: "PLDAR",
    portName: "Darlowo (PLDAR), Poland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.3119631",
    lng: "17.0469138",
    country: "PL",
    country_long: "Poland",
    portCode: "PLGDK",
    portName: "GADKI (PLGDK), Poland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.35202520000001",
    lng: "18.6466384",
    country: "PL",
    country_long: "Poland",
    portCode: "PLGDN",
    portName: "Gdansk",
    portContinent: "Europe",
  },
  {
    lat: "54.5188898",
    lng: "18.5305409",
    country: "PL",
    country_long: "Poland",
    portCode: "PLGDY",
    portName: "Gdynia (PLGDY), Gdynia, Poland",
    portContinent: "Europe",
  },
  {
    lat: "50.29449229999999",
    lng: "18.6713802",
    country: "PL",
    country_long: "Poland",
    portCode: "PLGWC",
    portName: "Gliwice",
    portContinent: "Europe",
  },
  {
    lat: "54.60838039999999",
    lng: "18.8007998",
    country: "PL",
    country_long: "Poland",
    portCode: "PLHEL",
    portName: "Hel (PLHEL), Poland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.9356214",
    lng: "15.5061862",
    country: "PL",
    country_long: "Poland",
    portCode: "PLIEG",
    portName: "Zielona Gora",
    portContinent: "Europe",
  },
  {
    lat: "54.6957333",
    lng: "18.6788396",
    country: "PL",
    country_long: "Poland",
    portCode: "PLJAT",
    portName: "Jastarnia (PLJAT), Poland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.204987",
    lng: "19.2739314",
    country: "PL",
    country_long: "Poland",
    portCode: "PLJAW",
    portName: "Jaworzno",
    portContinent: "Europe",
  },
  {
    lat: "50.8660773",
    lng: "20.6285677",
    country: "PL",
    country_long: "Poland",
    portCode: "PLKLC",
    portName: "KIELCE",
    portContinent: "Europe",
  },
  {
    lat: "54.37999130000001",
    lng: "19.4441407",
    country: "PL",
    country_long: "Poland",
    portCode: "PLKMR",
    portName: "Krynica Morska (PLKMR), Poland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.06465009999999",
    lng: "19.9449799",
    country: "PL",
    country_long: "Poland",
    portCode: "PLKRK",
    portName: "Krakow",
    portContinent: "Europe",
  },
  {
    lat: "50.26489189999999",
    lng: "19.0237815",
    country: "PL",
    country_long: "Poland",
    portCode: "PLKTW",
    portName: "Katowice",
    portContinent: "Europe",
  },
  {
    lat: "51.74643349999999",
    lng: "20.776818",
    country: "PL",
    country_long: "Poland",
    portCode: "PLKZL",
    portName: "KOZIETULY",
    portContinent: "Europe",
  },
  {
    lat: "52.3979",
    lng: "17.22809",
    country: "PL",
    country_long: "Poland",
    portCode: "PLKZY",
    portName: "Kostrzyn (PLKZY), Poland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.7592485",
    lng: "19.4559833",
    country: "PL",
    country_long: "Poland",
    portCode: "PLLOD",
    portName: "LODZ (PLLOD), LÐ“Ñ–dz, Poland",
    portContinent: "Europe",
  },
  {
    lat: "51.2464536",
    lng: "22.5684463",
    country: "PL",
    country_long: "Poland",
    portCode: "PLLUL",
    portName: "LUBLIN",
    portContinent: "Europe",
  },
  {
    lat: "52.0227957",
    lng: "23.5341518",
    country: "PL",
    country_long: "Poland",
    portCode: "PLMAA",
    portName: "Malaszewicze",
    portContinent: "Europe",
  },
  {
    lat: "53.778422",
    lng: "20.4801193",
    country: "PL",
    country_long: "Poland",
    portCode: "PLOLS",
    portName: "OLSZTYN",
    portContinent: "Europe",
  },
  {
    lat: "50.6683223",
    lng: "17.9230651",
    country: "PL",
    country_long: "Poland",
    portCode: "PLOPO",
    portName: "OPOLE",
    portContinent: "Europe",
  },
  {
    lat: "51.9823186",
    lng: "20.8344906",
    country: "PL",
    country_long: "Poland",
    portCode: "PLOYM",
    portName: "TARCZYN",
    portContinent: "Europe",
  },
  {
    lat: "51.40517209999999",
    lng: "19.7030244",
    country: "PL",
    country_long: "Poland",
    portCode: "PLPIO",
    portName: "Piotrkow",
    portContinent: "Europe",
  },
  {
    lat: "51.40517209999999",
    lng: "19.7030244",
    country: "PL",
    country_long: "Poland",
    portCode: "PLPIT",
    portName: "PIOTRKOW TRYBUNALSKI",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PLPLC",
    portName: "Port of Police",
    portContinent: "Europe",
  },
  {
    lat: "52.5463446",
    lng: "19.7065364",
    country: "PL",
    country_long: "Poland",
    portCode: "PLPLO",
    portName: "Plock (PLPLO), Poland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "52.406374",
    lng: "16.9251681",
    country: "PL",
    country_long: "Poland",
    portCode: "PLPOZ",
    portName: "Poznan",
    portContinent: "Europe",
  },
  {
    lat: "52.1704725",
    lng: "20.8118862",
    country: "PL",
    country_long: "Poland",
    portCode: "PLPZK",
    portName: "PRUSZKOW (PLPZK), Poland, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.13248859999999",
    lng: "23.1688403",
    country: "PL",
    country_long: "Poland",
    portCode: "PLQYY",
    portName: "Bialystok",
    portContinent: "Europe",
  },
  {
    lat: "51.40272359999999",
    lng: "21.1471333",
    country: "PL",
    country_long: "Poland",
    portCode: "PLRDO",
    portName: "RADOM",
    portContinent: "Europe",
  },
  {
    lat: "50.0411867",
    lng: "21.9991196",
    country: "PL",
    country_long: "Poland",
    portCode: "PLRZE",
    portName: "RZESZOW",
    portContinent: "Europe",
  },
  {
    lat: "54.441581",
    lng: "18.5600956",
    country: "PL",
    country_long: "Poland",
    portCode: "PLSOP",
    portName: "SOPOT",
    portContinent: "Europe",
  },
  {
    lat: "50.51092",
    lng: "18.29987",
    country: "PL",
    country_long: "Poland",
    portCode: "PLSTO",
    portName: "STRZELCE OPOLSKIE",
    portContinent: "Europe",
  },
  {
    lat: "50.28626380000001",
    lng: "19.1040791",
    country: "PL",
    country_long: "Poland",
    portCode: "PLSWC",
    portName: "Sosnowiec",
    portContinent: "Europe",
  },
  {
    lat: "53.90711599999999",
    lng: "14.2739335",
    country: "PL",
    country_long: "Poland",
    portCode: "PLSWI",
    portName: "Port Handlowy Swinoujscie",
    portContinent: "Europe",
  },
  {
    lat: "51.5060881",
    lng: "19.030923",
    country: "PL",
    country_long: "Poland",
    portCode: "PLSZE",
    portName: "SEDZIEJOWICE",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PLSZZ",
    portName: "Port of Szczecin",
    portContinent: "Europe",
  },
  {
    lat: "53.0137902",
    lng: "18.5984437",
    country: "PL",
    country_long: "Poland",
    portCode: "PLTOR",
    portName: "TORUN",
    portContinent: "Europe",
  },
  {
    lat: "50.12180069999999",
    lng: "19.0200022",
    country: "PL",
    country_long: "Poland",
    portCode: "PLTYY",
    portName: "TYCHY",
    portContinent: "Europe",
  },
  {
    lat: "54.58608076309137",
    lng: "16.8538563759061",
    country: "PL",
    country_long: "Poland",
    portCode: "PLUST",
    portName: "Port Ustka",
    portContinent: "Europe",
  },
  {
    lat: "52.2296756",
    lng: "21.0122287",
    country: "PL",
    country_long: "Poland",
    portCode: "PLWAW",
    portName: "Warsaw (PLWAW), Warsaw, Poland",
    portContinent: "Europe",
  },
  {
    lat: "54.7966073",
    lng: "18.4175041",
    country: "PL",
    country_long: "Poland",
    portCode: "PLWLA",
    portName: "Port Wladyslawowo",
    portContinent: "Europe",
  },
  {
    lat: "51.1078852",
    lng: "17.0385376",
    country: "PL",
    country_long: "Poland",
    portCode: "PLWRO",
    portName: "WROCLAW (PLWRO), Wroclaw, Poland",
    portContinent: "Europe",
  },
  {
    lat: "46.7758459",
    lng: "-56.1806363",
    country: "PM",
    country_long: "Saint Pierre and Miquelon",
    portCode: "PMFSP",
    portName: "ST PIERRE (PMFSP), ST. PIERRE AND MIQUELON, usa",
    portContinent: "North America",
  },
  {
    lat: "18.4274454",
    lng: "-67.1540698",
    country: "PR",
    country_long: "Puerto Rico",
    portCode: "PRBQN",
    portName: "Port of Aguadilla",
    portContinent: "North America",
  },
  {
    lat: "18.3257869",
    lng: "-65.6523838",
    country: "PR",
    country_long: "Puerto Rico",
    portCode: "PRFAJ",
    portName: "Port of Fajardo",
    portContinent: "North America",
  },
  {
    lat: "17.9725145",
    lng: "-66.9086264",
    country: "PR",
    country_long: "Puerto Rico",
    portCode: "PRGUX",
    portName: "Port of Guanica",
    portContinent: "North America",
  },
  {
    lat: "18.0191314",
    lng: "-66.791842",
    country: "PR",
    country_long: "Puerto Rico",
    portCode: "PRGUY",
    portName: "Port of Guayanilla",
    portContinent: "North America",
  },
  {
    lat: "18.2184683",
    lng: "-67.15967859999999",
    country: "PR",
    country_long: "Puerto Rico",
    portCode: "PRMAZ",
    portName: "Port of Mayaguez",
    portContinent: "North America",
  },
  {
    lat: "17.9462376",
    lng: "-66.1920943",
    country: "PR",
    country_long: "Puerto Rico",
    portCode: "PRPJO",
    portName: "Jobos Bay",
    portContinent: "North America",
  },
  {
    lat: "17.9688139",
    lng: "-66.61395569999999",
    country: "PR",
    country_long: "Puerto Rico",
    portCode: "PRPSE",
    portName: "Port of Ponce",
    portContinent: "North America",
  },
  {
    lat: "18.463196",
    lng: "-66.113164",
    country: "PR",
    country_long: "Puerto Rico",
    portCode: "PRSJU",
    portName: "Port of San Juan",
    portContinent: "North America",
  },
  {
    lat: "37.0890719",
    lng: "-8.2478796",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTABF",
    portName: "Albufeira (PTABF), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "38.6635375",
    lng: "-27.2293969",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTADH",
    portName: "Angra Do Heroismo (PTADH), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "38.7010609",
    lng: "-9.230813699999999",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTAGS",
    portName: "Alges (PTAGS), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "38.6765238",
    lng: "-9.165104699999999",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTALM",
    portName: "Almada (PTALM), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "39.2591889",
    lng: "-8.5820055",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTAPC",
    portName: "Alpiarca",
    portContinent: "Europe",
  },
  {
    lat: "40.6405055",
    lng: "-8.6537539",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTAVE",
    portName: "Aveiro (PTAVE), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "38.8103737",
    lng: "-9.0994599",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTBBL",
    portName: "Bobadela",
    portContinent: "Europe",
  },
  {
    lat: "38.6608149",
    lng: "-9.0790724",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTBRO",
    portName: "Barreiro (PTBRO), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "32.7379818",
    lng: "-17.180948",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTCAL",
    portName: "Calheta (Island Sao) (PTCAL), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "38.5250292",
    lng: "-28.3213553",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTCDP",
    portName: "Cais Do Pico (PTCDP), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "32.7391153",
    lng: "-16.7382072",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTCNL",
    portName: "Canical",
    portContinent: "Europe",
  },
  {
    lat: "38.6703794",
    lng: "-9.1595477",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTCVP",
    portName: "Cova Da Piedade (PTCVP), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "39.4611239",
    lng: "-8.478759",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTENT",
    portName: "Entroncamento",
    portContinent: "Europe",
  },
  {
    lat: "40.7528385",
    lng: "-8.5709856",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTETR",
    portName: "Estarreja",
    portContinent: "Europe",
  },
  {
    lat: "40.1521398",
    lng: "-8.857583",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTFDF",
    portName: "FIGUEIRA DA FOZ (PTFDF), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "-8.657381899999999",
    lng: "121.0793705",
    country: "ID",
    country_long: "Indonesia",
    portCode: "PTFLW",
    portName: "Flores Island",
    portContinent: "Europe",
  },
  {
    lat: "32.6669328",
    lng: "-16.9240554",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTFNC",
    portName: "FUNCHAL (PTFNC), Funchal, Portugal",
    portContinent: "Europe",
  },
  {
    lat: "40.61842240000001",
    lng: "-8.7335245",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTGHE",
    portName: "Gafanha Da Encarnaca (PTGHE), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PTGRW",
    portName: "graciaso",
    portContinent: "Europe",
  },
  {
    lat: "38.5348447",
    lng: "-28.6299812",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTHOR",
    portName: "Horta (PTHOR), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "39.3782624",
    lng: "-31.1781975",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTLAJ",
    portName: "Lajes Das Flores (PTLAJ), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "41.1827778",
    lng: "-8.703055599999999",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTLEI",
    portName: "Leixoes",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PTLIS",
    portName: "Port of Lisbon",
    portContinent: "Europe",
  },
  {
    lat: "37.1027881",
    lng: "-8.6730275",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTLOS",
    portName: "Lagos (PTLOS), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "38.69686069999999",
    lng: "-9.314601399999999",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTOEI",
    portName: "Oeiras (PTOEI), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "29.7531235",
    lng: "-95.3801531",
    country: "US",
    country_long: "United States",
    portCode: "PTOPO",
    portName: "OPorto",
    portContinent: "Europe",
  },
  {
    lat: "38.696797",
    lng: "-9.2915896",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTPCS",
    portName: "Paco De Arcos (PTPCS), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "37.7493461",
    lng: "-25.7103849",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTPDL",
    portName: "Ponta Delgada (PTPDL), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "41.3803685",
    lng: "-8.7609294",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTPDV",
    portName: "Povoa De Varzim (PTPDV), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "39.3603291",
    lng: "-9.387622",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTPEN",
    portName: "Peniche (PTPEN), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "39.050411",
    lng: "-27.9732248",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTPRG",
    portName: "Praia (PTPRG), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "37.13617",
    lng: "-8.5376926",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTPRM",
    portName: "Portimao (PTPRM), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "38.7339162",
    lng: "-27.0646976",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTPRV",
    portName: "Praia Da Vitoria (PTPRV), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "33.0749387",
    lng: "-16.3500319",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTPXO",
    portName: "Porto Santo (PTPXO), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "39.45314",
    lng: "-31.129387",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTSCF",
    portName: "St Cruz Das Flores (PTSCF), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "39.0862753",
    lng: "-28.0156811",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTSCG",
    portName: "Santa Cruz da Graciosa",
    portContinent: "Europe",
  },
  {
    lat: "38.5260437",
    lng: "-8.8909328",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTSET",
    portName: "Setubal (PTSET), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "37.9571555",
    lng: "-8.860890699999999",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTSIE",
    portName: "Sines",
    portContinent: "Europe",
  },
  {
    lat: "38.6409678",
    lng: "-28.0303189",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTSJZ",
    portName: "Sao Jorge Island",
    portContinent: "Europe",
  },
  {
    lat: "27.5041264",
    lng: "-82.7144811",
    country: "US",
    country_long: "United States",
    portCode: "PTSMA",
    portName: "Santa Maria Island",
    portContinent: "Europe",
  },
  {
    lat: "37.780411",
    lng: "-25.4970466",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTSMI",
    portName: "Sao Miguel",
    portContinent: "Europe",
  },
  {
    lat: "38.7216415",
    lng: "-27.2205771",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTTER",
    portName: "TERCEIRA (PTTER), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "38.670973",
    lng: "-9.232308399999999",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTTRF",
    portName: "Trafaria (PTTRF), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "41.6918275",
    lng: "-8.8344101",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTVDC",
    portName: "Viana Do Castelo (PTVDC), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PTVEL",
    portName: "VELAS",
    portContinent: "Europe",
  },
  {
    lat: "37.7157269",
    lng: "-25.4345063",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTVFC",
    portName: "Vila Franca Do Campo (PTVFC), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "41.3714762",
    lng: "-8.3321648",
    country: "PT",
    country_long: "Portugal",
    portCode: "PTVIH",
    portName: "Vilarinho (PTVIH), Portugal, Europe",
    portContinent: "Europe",
  },
  {
    lat: "6.909223",
    lng: "134.1387934",
    country: "PW",
    country_long: "Palau",
    portCode: "PWANG",
    portName: "Angaur (PWANG), Palau, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "7.514979999999999",
    lng: "134.58252",
    country: "PW",
    country_long: "Palau",
    portCode: "PWBEA",
    portName: "BELAU",
    portContinent: "Oceania",
  },
  {
    lat: "7.4951158",
    lng: "134.6336896",
    country: "PW",
    country_long: "Palau",
    portCode: "PWMKK",
    portName: "Melekeok (PWMKK), Palau, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "7.3410628",
    lng: "134.4771596",
    country: "PW",
    country_long: "Palau",
    portCode: "PWROR",
    portName: "Koror (PWROR), Palau, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.219933",
    lng: "-57.54761449999999",
    country: "PY",
    country_long: "Paraguay",
    portCode: "PYASU",
    portName: "CAACUPEMI ASUNCION",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PYCAA",
    portName: "CAACUPï¿½",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "PYFNX",
    portName: "Fenix",
    portContinent: "South America",
  },
  {
    lat: "-25.2030941",
    lng: "-57.52436539999999",
    country: "PY",
    country_long: "Paraguay",
    portCode: "PYMRA",
    portName: "Colonia Mariano Roque Alonso",
    portContinent: "South America",
  },
  {
    lat: "-26.8753204",
    lng: "-58.328712",
    country: "PY",
    country_long: "Paraguay",
    portCode: "PYPIL",
    portName: "CAACUPEMI PILAR",
    portContinent: "South America",
  },
  {
    lat: "-25.5484672",
    lng: "-57.55174419999999",
    country: "PY",
    country_long: "Paraguay",
    portCode: "PYPSE",
    portName: "PUERTO SEGURO FLUVIAL (VILLETA)",
    portContinent: "South America",
  },
  {
    lat: "37.33874",
    lng: "-121.8852525",
    country: "US",
    country_long: "United States",
    portCode: "PYSJE",
    portName: "San Jose, PY",
    portContinent: "South America",
  },
  {
    lat: "-25.4456531",
    lng: "-57.54890260000001",
    country: "PY",
    country_long: "Paraguay",
    portCode: "PYTER",
    portName: "Terport (San Antonio), (PYTER), Paraguay, Sam",
    portContinent: "South America",
  },
  {
    lat: "-25.5070168",
    lng: "-57.5589382",
    country: "PY",
    country_long: "Paraguay",
    portCode: "PYVLL",
    portName: "Villeta (PYVLL), Paraguay, Sam",
    portContinent: "South America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "QAASN",
    portName: "Al Shaheen Terminal",
    portContinent: "Asia",
  },
  {
    lat: "25.2949584",
    lng: "51.5439245",
    country: "QA",
    country_long: "Qatar",
    portCode: "QADOH",
    portName: "Port of Doha",
    portContinent: "Asia",
  },
  {
    lat: "25.6666667",
    lng: "52.41666670000001",
    country: "N/A",
    country_long: "N/A",
    portCode: "QAHAL",
    portName: "Halul Island (QAHAL), Qatar, Meg",
    portContinent: "Asia",
  },
  {
    lat: "25.2608759",
    lng: "51.61384169999999",
    country: "QA",
    country_long: "Qatar",
    portCode: "QAHMD",
    portName: "Hamad (QAHMD), Qatar, Meg",
    portContinent: "Asia",
  },
  {
    lat: "24.9909255",
    lng: "51.5493483",
    country: "QA",
    country_long: "Qatar",
    portCode: "QAMES",
    portName: "Mesaieed (QAMES), Qatar, Meg",
    portContinent: "Asia",
  },
  {
    lat: "25.2660684",
    lng: "51.548807",
    country: "QA",
    country_long: "Qatar",
    portCode: "QAQAP",
    portName: "Qapco",
    portContinent: "Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "QAQCH",
    portName: "Qchem",
    portContinent: "Asia",
  },
  {
    lat: "25.354826",
    lng: "51.183884",
    country: "QA",
    country_long: "Qatar",
    portCode: "QAQLM",
    portName: "Qatalum (QAQLM), Qatar, Meg",
    portContinent: "Asia",
  },
  {
    lat: "25.8827849",
    lng: "51.526945",
    country: "QA",
    country_long: "Qatar",
    portCode: "QARLF",
    portName: "Port of Ras Laffan",
    portContinent: "Asia",
  },
  {
    lat: "24.9909255",
    lng: "51.5493483",
    country: "QA",
    country_long: "Qatar",
    portCode: "QAUMS",
    portName: "Umm Sa'id (Mesaieed)",
    portContinent: "Asia",
  },
  {
    lat: "-20.9357423",
    lng: "55.2903451",
    country: "RE",
    country_long: "Réunion",
    portCode: "RELPT",
    portName: "Le Port (RELPT), Reunion, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-20.9239278",
    lng: "55.2882602",
    country: "RE",
    country_long: "Réunion",
    portCode: "REPD2",
    portName: "Pointe des Gallets",
    portContinent: "Africa",
  },
  {
    lat: "-20.9239278",
    lng: "55.2882602",
    country: "RE",
    country_long: "Réunion",
    portCode: "REPDG",
    portName: "POINTE DES GALETS (REPDG), Reunion, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-21.115141",
    lng: "55.536384",
    country: "RE",
    country_long: "Réunion",
    portCode: "REREU",
    portName: "RÃ©union",
    portContinent: "Africa",
  },
  {
    lat: "44.0949683",
    lng: "28.6086623",
    country: "RO",
    country_long: "Romania",
    portCode: "ROAGI",
    portName: "Agigea (ROAGI), Romania, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.943161",
    lng: "24.96676",
    country: "RO",
    country_long: "Romania",
    portCode: "ROARW",
    portName: "Romania",
    portContinent: "Europe",
  },
  {
    lat: "44.0064498",
    lng: "23.0082893",
    country: "RO",
    country_long: "Romania",
    portCode: "ROBAB",
    portName: "Port of Basarabi",
    portContinent: "Europe",
  },
  {
    lat: "46.5670437",
    lng: "26.9145748",
    country: "RO",
    country_long: "Romania",
    portCode: "ROBCM",
    portName: "Bacau",
    portContinent: "Europe",
  },
  {
    lat: "45.2652463",
    lng: "27.9594714",
    country: "RO",
    country_long: "Romania",
    portCode: "ROBRA",
    portName: "Braila (ROBRA), Romania, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.6426802",
    lng: "25.5887252",
    country: "RO",
    country_long: "Romania",
    portCode: "ROBRV",
    portName: "Brasov",
    portContinent: "Europe",
  },
  {
    lat: "44.4267674",
    lng: "26.1025384",
    country: "RO",
    country_long: "Romania",
    portCode: "ROBUH",
    portName: "BUCHAREST",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ROCAF",
    portName: "Port of Calafat",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ROCAS",
    portName: "Port of Calarasi",
    portContinent: "Europe",
  },
  {
    lat: "44.3496457",
    lng: "28.0243953",
    country: "RO",
    country_long: "Romania",
    portCode: "ROCEV",
    portName: "Port of Cernavoda",
    portContinent: "Europe",
  },
  {
    lat: "45.179314",
    lng: "27.9300391",
    country: "RO",
    country_long: "Romania",
    portCode: "ROCHN",
    portName: "Chiscani (ROCHN), Romania, Med",
    portContinent: "Europe",
  },
  {
    lat: "46.7712101",
    lng: "23.6236353",
    country: "RO",
    country_long: "Romania",
    portCode: "ROCLJ",
    portName: "CLUJ",
    portContinent: "Europe",
  },
  {
    lat: "44.1598013",
    lng: "28.6348138",
    country: "RO",
    country_long: "Romania",
    portCode: "ROCND",
    portName: "Constanta",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "RODTS",
    portName: "Port of Drobeta Turnu Severin",
    portContinent: "Europe",
  },
  {
    lat: "45.4353208",
    lng: "28.0079945",
    country: "RO",
    country_long: "Romania",
    portCode: "ROGAL",
    portName: "Galati (ROGAL), Romania, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.8695755",
    lng: "25.9652557",
    country: "RO",
    country_long: "Romania",
    portCode: "ROGRG",
    portName: "Port of Giurgiu",
    portContinent: "Europe",
  },
  {
    lat: "45.2450161",
    lng: "28.1401604",
    country: "RO",
    country_long: "Romania",
    portCode: "ROMAC",
    portName: "Macin (ROMAC), Romania, Med",
    portContinent: "Europe",
  },
  {
    lat: "43.8152436",
    lng: "28.5741496",
    country: "RO",
    country_long: "Romania",
    portCode: "ROMAG",
    portName: "Mangalia (ROMAG), Romania, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ROMED",
    portName: "Port of Medgidia",
    portContinent: "Europe",
  },
  {
    lat: "45.943161",
    lng: "24.96676",
    country: "RO",
    country_long: "Romania",
    portCode: "ROMID",
    portName: "Midia (ROMID), Romania, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.1735518",
    lng: "28.4105479",
    country: "RO",
    country_long: "Romania",
    portCode: "ROMUR",
    portName: "Murfatlar (ROMUR), Romania, Med",
    portContinent: "Europe",
  },
  {
    lat: "44.0854664",
    lng: "26.6374829",
    country: "RO",
    country_long: "Romania",
    portCode: "ROOLT",
    portName: "Oltenita (ROOLT), Romania, Med",
    portContinent: "Europe",
  },
  {
    lat: "47.04650050000001",
    lng: "21.9189438",
    country: "RO",
    country_long: "Romania",
    portCode: "ROOMR",
    portName: "Oradea",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ROORV",
    portName: "Port of Orsova",
    portContinent: "Europe",
  },
  {
    lat: "44.2576259",
    lng: "28.5586109",
    country: "RO",
    country_long: "Romania",
    portCode: "ROOVI",
    portName: "Ovidiu (ROOVI), Romania, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.8609375",
    lng: "25.7885796",
    country: "RO",
    country_long: "Romania",
    portCode: "ROSFG",
    portName: "Sfantu Gheorghe (ROSFG), Romania, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.1716165",
    lng: "28.7914439",
    country: "RO",
    country_long: "Romania",
    portCode: "ROTCE",
    portName: "Tulcea (ROTCE), Romania, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.7488716",
    lng: "21.2086793",
    country: "RO",
    country_long: "Romania",
    portCode: "ROTSR",
    portName: "Timisoara",
    portContinent: "Europe",
  },
  {
    lat: "44.8125449",
    lng: "20.46123",
    country: "RS",
    country_long: "Serbia",
    portCode: "RSBEG",
    portName: "BeLgrad (RSBEG), Belgrade, Serbia",
    portContinent: "Europe",
  },
  {
    lat: "44.0127932",
    lng: "20.9114225",
    country: "RS",
    country_long: "Serbia",
    portCode: "RSKVG",
    portName: "Kragujevac",
    portContinent: "Europe",
  },
  {
    lat: "45.2396085",
    lng: "19.8227056",
    country: "RS",
    country_long: "Serbia",
    portCode: "RSNVS",
    portName: "Novi Sad (RSNVS), Serbia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "42.6629138",
    lng: "21.1655028",
    country: "N/A",
    country_long: "N/A",
    portCode: "RSPRN",
    portName: "PRISTINA",
    portContinent: "Europe",
  },
  {
    lat: "44.87063759999999",
    lng: "20.6479561",
    country: "RS",
    country_long: "Serbia",
    portCode: "RSPYJ",
    portName: "Pancevo (RSPYJ), Serbia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "44.755274",
    lng: "19.692337",
    country: "RS",
    country_long: "Serbia",
    portCode: "RSSAB",
    portName: "Sabac (RSSAB), Serbia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "45.202464",
    lng: "19.9335707",
    country: "RS",
    country_long: "Serbia",
    portCode: "RSSKA",
    portName: "Sremski Karlovci (RSSKA), Serbia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "44.979777",
    lng: "19.6102033",
    country: "RS",
    country_long: "Serbia",
    portCode: "RSSRM",
    portName: "Sremska Mitrovica (RSSRM), Serbia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "44.8935914",
    lng: "37.3158041",
    country: "RU",
    country_long: "Russia",
    portCode: "RUAAQ",
    portName: "Anapa (RUAAQ), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.4343234",
    lng: "39.9339462",
    country: "RU",
    country_long: "Russia",
    portCode: "RUAEL",
    portName: "Adler (RUAEL), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.5355756",
    lng: "40.5232671",
    country: "RU",
    country_long: "Russia",
    portCode: "RUARH",
    portName: "Port of Arkhangelsk",
    portContinent: "Europe",
  },
  {
    lat: "46.3667466",
    lng: "48.0077929",
    country: "RU",
    country_long: "Russia",
    portCode: "RUASF",
    portName: "Port of Astrakhan",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUAZO",
    portName: "Azov",
    portContinent: "Europe",
  },
  {
    lat: "43.1124743",
    lng: "132.3451658",
    country: "RU",
    country_long: "Russia",
    portCode: "RUBKN",
    portName: "Bolshoy Kamen (RUBKN), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.6591222",
    lng: "19.9228947",
    country: "RU",
    country_long: "Russia",
    portCode: "RUBLT",
    portName: "Baltiysk (RUBLT), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.6487513",
    lng: "142.176197",
    country: "RU",
    country_long: "Russia",
    portCode: "RUBOS",
    portName: "Boshnyakovo (RUBOS), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.932477",
    lng: "29.695974",
    country: "RU",
    country_long: "Russia",
    portCode: "RUBRK",
    portName: "Bronka (RUBRK), St. Petersburg, Russia",
    portContinent: "Europe",
  },
  {
    lat: "53.2635306",
    lng: "34.41611",
    country: "RU",
    country_long: "Russia",
    portCode: "RUBRY",
    portName: "BRYANSK",
    portContinent: "Europe",
  },
  {
    lat: "59.132333",
    lng: "37.9091811",
    country: "RU",
    country_long: "Russia",
    portCode: "RUCEE",
    portName: "Cherepovets (RUCEE), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.1644419",
    lng: "61.4368431",
    country: "RU",
    country_long: "Russia",
    portCode: "RUCEK",
    portName: "CHELYABINSK",
    portContinent: "Europe",
  },
  {
    lat: "54.5259614",
    lng: "15.2551187",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUCKV",
    portName: "Chekhov (RUCKV), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUDAZ",
    portName: "DALZAVOD",
    portContinent: "Europe",
  },
  {
    lat: "51.475113",
    lng: "140.769104",
    country: "RU",
    country_long: "Russia",
    portCode: "RUDKA",
    portName: "De Kastri (RUDKA), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "73.507735",
    lng: "80.53103019999999",
    country: "RU",
    country_long: "Russia",
    portCode: "RUDKS",
    portName: "Dikson (RUDKS), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.7336613",
    lng: "177.4968266",
    country: "RU",
    country_long: "Russia",
    portCode: "RUDYR",
    portName: "Anadyr (RUDYR), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.2374473",
    lng: "43.4596172",
    country: "RU",
    country_long: "Russia",
    portCode: "RUDZE",
    portName: "DZERZHINSK",
    portContinent: "Europe",
  },
  {
    lat: "56.8430993",
    lng: "60.64540859999999",
    country: "RU",
    country_long: "Russia",
    portCode: "RUEKA",
    portName: "EKATERINBURG",
    portContinent: "Europe",
  },
  {
    lat: "59.9310584",
    lng: "30.3609097",
    country: "RU",
    country_long: "Russia",
    portCode: "RUFCT",
    portName: "Saint Petersburg Fct Terminal",
    portContinent: "Europe",
  },
  {
    lat: "54.5259614",
    lng: "15.2551187",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUFKN",
    portName: "Fokino (RUFKN), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUFPT",
    portName: "Fish Port Terminal",
    portContinent: "Europe",
  },
  {
    lat: "54.5259614",
    lng: "15.2551187",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUGDX",
    portName: "Magadan (RUGDX), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "44.5620352",
    lng: "38.0904574",
    country: "RU",
    country_long: "Russia",
    portCode: "RUGDZ",
    portName: "Gelendzhik (RUGDZ), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.3268684",
    lng: "44.0058793",
    country: "RU",
    country_long: "Russia",
    portCode: "RUGOJ",
    portName: "Nizhny Novgorod (RUGOJ), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.09719819999999",
    lng: "56.6302371",
    country: "RU",
    country_long: "Russia",
    portCode: "RUGSA",
    portName: "Gorskaya (RUGSA), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.62607440000001",
    lng: "39.8844708",
    country: "RU",
    country_long: "Russia",
    portCode: "RUIAR",
    portName: "Yaroslavl (RUIAR), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.86186009999999",
    lng: "53.2324285",
    country: "RU",
    country_long: "Russia",
    portCode: "RUIJK",
    portName: "IZHEVSK",
    portContinent: "Europe",
  },
  {
    lat: "54.5259614",
    lng: "15.2551187",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUIKT",
    portName: "Irkutsk (RUIKT), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.0050671",
    lng: "40.9766453",
    country: "RU",
    country_long: "Russia",
    portCode: "RUIVO",
    portName: "IVANOVO",
    portContinent: "Europe",
  },
  {
    lat: "67.1508569",
    lng: "32.4133751",
    country: "RU",
    country_long: "Russia",
    portCode: "RUKAN",
    portName: "Kandalaksha (RUKAN), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.9959303",
    lng: "29.7655452",
    country: "RU",
    country_long: "Russia",
    portCode: "RUKDT",
    portName: "Kronshtadt (RUKDT), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.7104264",
    lng: "20.4522144",
    country: "RU",
    country_long: "Russia",
    portCode: "RUKGD",
    portName: "Kaliningrad",
    portContinent: "Europe",
  },
  {
    lat: "61.00605700000001",
    lng: "69.0312136",
    country: "RU",
    country_long: "Russia",
    portCode: "RUKHM",
    portName: "Khanty Mansiysk (RUKHM), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.5259614",
    lng: "15.2551187",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUKHV",
    portName: "Khabarovsk (RUKHV), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.8603977",
    lng: "37.3598916",
    country: "RU",
    country_long: "Russia",
    portCode: "RUKIM",
    portName: "Kimry (RUKIM), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.5135904",
    lng: "36.2636786",
    country: "RU",
    country_long: "Russia",
    portCode: "RUKLF",
    portName: "KALUGA",
    portContinent: "Europe",
  },
  {
    lat: "57.7677078",
    lng: "40.9262609",
    country: "RU",
    country_long: "Russia",
    portCode: "RUKMW",
    portName: "KOSTROMA",
    portContinent: "Europe",
  },
  {
    lat: "46.6355686",
    lng: "142.7830034",
    country: "RU",
    country_long: "Russia",
    portCode: "RUKOR",
    portName: "Korsakov",
    portContinent: "Europe",
  },
  {
    lat: "54.5259614",
    lng: "15.2551187",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUKOZ",
    portName: "Kozmino (RUKOZ), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.5259614",
    lng: "15.2551187",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUKRA",
    portName: "Krasnoyarsk (RUKRA), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.8263313",
    lng: "37.326297",
    country: "RU",
    country_long: "Russia",
    portCode: "RUKRS",
    portName: "Krasnogorsk (RUKRS), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.203772",
    lng: "50.1606382",
    country: "RU",
    country_long: "Russia",
    portCode: "RUKUF",
    portName: "Samara (RUKUF), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.6030012",
    lng: "49.6679437",
    country: "RU",
    country_long: "Russia",
    portCode: "RUKVX",
    portName: "KIROV",
    portContinent: "Europe",
  },
  {
    lat: "55.7878944",
    lng: "49.12332929999999",
    country: "RU",
    country_long: "Russia",
    portCode: "RUKZN",
    portName: "Kazan (RUKZN), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "43.5851814",
    lng: "45",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUKZP",
    portName: "Kavkaz (RUKZP), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "27.7608611",
    lng: "-82.6298206",
    country: "US",
    country_long: "United States",
    portCode: "RULED",
    portName: "Port of St. Petersburg",
    portContinent: "Europe",
  },
  {
    lat: "54.7903112",
    lng: "32.0503663",
    country: "RU",
    country_long: "Russia",
    portCode: "RULNX",
    portName: "SMOLENSK",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "RULOM",
    portName: "Port of Lomonosov",
    portContinent: "Europe",
  },
  {
    lat: "52.6121996",
    lng: "39.5981225",
    country: "RU",
    country_long: "Russia",
    portCode: "RULPK",
    portName: "LIPETSK",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUMAG",
    portName: "Magadansky, Port",
    portContinent: "Europe",
  },
  {
    lat: "42.9777885",
    lng: "47.5146913",
    country: "RU",
    country_long: "Russia",
    portCode: "RUMCX",
    portName: "Port of Makhachkala",
    portContinent: "Europe",
  },
  {
    lat: "68.97331129999999",
    lng: "33.0855848",
    country: "RU",
    country_long: "Russia",
    portCode: "RUMMK",
    portName: "Port of Murmansk",
    portContinent: "Europe",
  },
  {
    lat: "55.755826",
    lng: "37.6173",
    country: "RU",
    country_long: "Russia",
    portCode: "RUMOW",
    portName: "Moscow (RUMOW), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.3927214",
    lng: "59.06230669999999",
    country: "RU",
    country_long: "Russia",
    portCode: "RUMQF",
    portName: "MAGNITOGORSK",
    portContinent: "Europe",
  },
  {
    lat: "46.6774007",
    lng: "141.8559617",
    country: "RU",
    country_long: "Russia",
    portCode: "RUNEV",
    portName: "Nevelsk (RUNEV), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.1500944",
    lng: "140.718478",
    country: "RU",
    country_long: "Russia",
    portCode: "RUNIA",
    portName: "Nikolayevsk Na Amure (RUNIA), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.5259614",
    lng: "15.2551187",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUNJK",
    portName: "Nakhodka (RUNJK), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.0109034",
    lng: "38.2963063",
    country: "RU",
    country_long: "Russia",
    portCode: "RUNMK",
    portName: "NOVOMOSKOVSK",
    portContinent: "Europe",
  },
  {
    lat: "58.52556980000001",
    lng: "31.2741928",
    country: "RU",
    country_long: "Russia",
    portCode: "RUNVR",
    portName: "NOVGOROD",
    portContinent: "Europe",
  },
  {
    lat: "44.7180464",
    lng: "37.7770118",
    country: "RU",
    country_long: "Russia",
    portCode: "RUNVS",
    portName: "Novorossiysk",
    portContinent: "Europe",
  },
  {
    lat: "55.613196",
    lng: "51.8469974",
    country: "RU",
    country_long: "Russia",
    portCode: "RUNZK",
    portName: "NIZHNEKAMSK",
    portContinent: "Europe",
  },
  {
    lat: "54.9913545",
    lng: "73.3645204",
    country: "RU",
    country_long: "Russia",
    portCode: "RUOMS",
    portName: "Omsk (RUOMS), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.9832693",
    lng: "82.8963831",
    country: "RU",
    country_long: "Russia",
    portCode: "RUOVB",
    portName: "Novosibirsk (RUOVB), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.26747039999999",
    lng: "8.497066799999999",
    country: "NO",
    country_long: "Norway",
    portCode: "RUOYA",
    portName: "Olya (RUOYA), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUPEE",
    portName: "PERM",
    portContinent: "Europe",
  },
  {
    lat: "61.77816170000001",
    lng: "34.3640396",
    country: "RU",
    country_long: "Russia",
    portCode: "RUPES",
    portName: "PETROZAVODSK",
    portContinent: "Europe",
  },
  {
    lat: "53.2272903",
    lng: "45",
    country: "RU",
    country_long: "Russia",
    portCode: "RUPEZ",
    portName: "PENZA",
    portContinent: "Europe",
  },
  {
    lat: "52.76819039999999",
    lng: "41.50141379999999",
    country: "RU",
    country_long: "Russia",
    portCode: "RUPGN",
    portName: "Prigorodnoye (RUPGN), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.0583997",
    lng: "158.6320734",
    country: "RU",
    country_long: "Russia",
    portCode: "RUPKC",
    portName: "PETROPAVLOVSK",
    portContinent: "Europe",
  },
  {
    lat: "57.8166994",
    lng: "28.3344734",
    country: "RU",
    country_long: "Russia",
    portCode: "RUPKV",
    portName: "PSKOV",
    portContinent: "Europe",
  },
  {
    lat: "60.36627000000001",
    lng: "28.6061221",
    country: "RU",
    country_long: "Russia",
    portCode: "RUPRI",
    portName: "Primorsk (RUPRI), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "69.7029321",
    lng: "170.3070329",
    country: "RU",
    country_long: "Russia",
    portCode: "RUPWE",
    portName: "Pevek (RUPWE), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.768156",
    lng: "55.0969796",
    country: "RU",
    country_long: "Russia",
    portCode: "RUREN",
    portName: "ORENBURG",
    portContinent: "Europe",
  },
  {
    lat: "47.2357137",
    lng: "39.701505",
    country: "RU",
    country_long: "Russia",
    portCode: "RUROV",
    portName: "Rostov On Don (RUROV), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.5461754",
    lng: "46.0154123",
    country: "RU",
    country_long: "Russia",
    portCode: "RURTW",
    portName: "Saratov (RURTW), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.057486",
    lng: "38.8116968",
    country: "RU",
    country_long: "Russia",
    portCode: "RURYB",
    portName: "RYBINSK",
    portContinent: "Europe",
  },
  {
    lat: "54.6095418",
    lng: "39.7125857",
    country: "RU",
    country_long: "Russia",
    portCode: "RURZN",
    portName: "RYAZAN",
    portContinent: "Europe",
  },
  {
    lat: "59.1606074",
    lng: "57.5671883",
    country: "RU",
    country_long: "Russia",
    portCode: "RUSAK",
    portName: "Alexandrovsk (RUSAK), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.5661757",
    lng: "39.8505981",
    country: "RU",
    country_long: "Russia",
    portCode: "RUSEW",
    portName: "Severodvinsk (RUSEW), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.5259614",
    lng: "15.2551187",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUSHA",
    portName: "Shakhtersk (RUSHA), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.9460725",
    lng: "28.8669696",
    country: "RU",
    country_long: "Russia",
    portCode: "RUSKA",
    portName: "Slavyanka (RUSKA), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.5259614",
    lng: "15.2551187",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUSOG",
    portName: "Sovgavan (RUSOG), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.6720331",
    lng: "56.7557668",
    country: "RU",
    country_long: "Russia",
    portCode: "RUSOK",
    portName: "Solikamsk (RUSOK), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.5259614",
    lng: "15.2551187",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUSVE",
    portName: "Svetlaya (RUSVE), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "50.6683424",
    lng: "156.115192",
    country: "RU",
    country_long: "Russia",
    portCode: "RUSVK",
    portName: "Severo Kurilsk (RUSVK), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.2125752",
    lng: "38.9159658",
    country: "RU",
    country_long: "Russia",
    portCode: "RUTAG",
    portName: "Taganrog (RUTAG), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "45.2756589",
    lng: "37.3741527",
    country: "RU",
    country_long: "Russia",
    portCode: "RUTMR",
    portName: "Temryuk (RUTMR), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.2227256",
    lng: "68.2777646",
    country: "RU",
    country_long: "Russia",
    portCode: "RUTOX",
    portName: "Tobolsk (RUTOX), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "53.508525",
    lng: "49.41821960000001",
    country: "RU",
    country_long: "Russia",
    portCode: "RUTTI",
    portName: "TOLYATTI",
    portContinent: "Europe",
  },
  {
    lat: "44.0961624",
    lng: "39.0745411",
    country: "RU",
    country_long: "Russia",
    portCode: "RUTUA",
    portName: "Tuapse (RUTUA), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.73479099999999",
    lng: "55.9578555",
    country: "RU",
    country_long: "Russia",
    portCode: "RUUFA",
    portName: "UFA",
    portContinent: "Europe",
  },
  {
    lat: "57.52392979999999",
    lng: "38.3219031",
    country: "RU",
    country_long: "Russia",
    portCode: "RUUGC",
    portName: "Uglich (RUUGC), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.5259614",
    lng: "15.2551187",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUUGL",
    portName: "Uglegorsk (RUUGL), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "54.5259614",
    lng: "15.2551187",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUUKX",
    portName: "Ust Kut (RUUKX), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUULU",
    portName: "Ust'-Luga",
    portContinent: "Europe",
  },
  {
    lat: "54.3186575",
    lng: "48.397776",
    country: "RU",
    country_long: "Russia",
    portCode: "RUULY",
    portName: "ULYANOVSK",
    portContinent: "Europe",
  },
  {
    lat: "27.7676008",
    lng: "-82.64029149999999",
    country: "US",
    country_long: "United States",
    portCode: "RUVAO",
    portName: "St Petersburg",
    portContinent: "Europe",
  },
  {
    lat: "58.52556980000001",
    lng: "31.2741928",
    country: "RU",
    country_long: "Russia",
    portCode: "RUVND",
    portName: "VELIKIY NOVGOROD",
    portContinent: "Europe",
  },
  {
    lat: "48.5157396",
    lng: "135.1011554",
    country: "RU",
    country_long: "Russia",
    portCode: "RUVNN",
    portName: "Port of Vanino",
    portContinent: "Europe",
  },
  {
    lat: "48.708048",
    lng: "44.5133034",
    country: "RU",
    country_long: "Russia",
    portCode: "RUVOG",
    portName: "Volgograd (RUVOG), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "51.6683487",
    lng: "39.19192880000001",
    country: "RU",
    country_long: "Russia",
    portCode: "RUVOZ",
    portName: "VORONEZH",
    portContinent: "Europe",
  },
  {
    lat: "43.101871",
    lng: "131.8719025",
    country: "RU",
    country_long: "Russia",
    portCode: "RUVVO",
    portName: "Port of Vladivostok",
    portContinent: "Europe",
  },
  {
    lat: "60.70956219999999",
    lng: "28.749044",
    country: "RU",
    country_long: "Russia",
    portCode: "RUVYG",
    portName: "Vyborg (RUVYG), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "42.72805040000001",
    lng: "133.0830788",
    country: "RU",
    country_long: "Russia",
    portCode: "RUVYP",
    portName: "Vostochny Port",
    portContinent: "Europe",
  },
  {
    lat: "60.62526010000001",
    lng: "28.568049",
    country: "RU",
    country_long: "Russia",
    portCode: "RUVYS",
    portName: "Vysotsk (RUVYS), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.8430993",
    lng: "60.64540859999999",
    country: "RU",
    country_long: "Russia",
    portCode: "RUYEK",
    portName: "Yekaterinburg ,RU",
    portContinent: "Europe",
  },
  {
    lat: "46.7128863",
    lng: "38.2741151",
    country: "RU",
    country_long: "Russia",
    portCode: "RUYEY",
    portName: "Yeysk (RUYEY), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.64130059999999",
    lng: "43.390794",
    country: "RU",
    country_long: "Russia",
    portCode: "RUZAL",
    portName: "Zavolzhye (RUZAL), Russia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "RUZAR",
    portName: "Port of Zarubino",
    portContinent: "Europe",
  },
  {
    lat: "-1.9440727",
    lng: "30.0618851",
    country: "RW",
    country_long: "Rwanda",
    portCode: "RWKGL",
    portName: "KIGALI (RWKGL), Kigali, Rwanda",
    portContinent: "Africa",
  },
  {
    lat: "27.3457474",
    lng: "35.7243073",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SADHU",
    portName: "Duba (SADHU), Saudi Arabia, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "26.4206828",
    lng: "50.0887943",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SADMM",
    portName: "Dammam",
    portContinent: "Middle East",
  },
  {
    lat: "16.8893586",
    lng: "42.57056740000001",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SAGIZ",
    portName: "Jizan",
    portContinent: "Middle East",
  },
  {
    lat: "27.079984",
    lng: "49.583381",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SAJBI",
    portName: "Al Jubayl Industrial City",
    portContinent: "Middle East",
  },
  {
    lat: "21.485811",
    lng: "39.19250479999999",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SAJED",
    portName: "Jeddah",
    portContinent: "Middle East",
  },
  {
    lat: "26.9597709",
    lng: "49.5687416",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SAJUB",
    portName: "Al Jubail (SAJUB), Saudi Arabia, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "15.9777254",
    lng: "48.65907259999999",
    country: "YE",
    country_long: "Yemen",
    portCode: "SAJUT",
    portName: "Ju'aymah Crude and LPG Terminals",
    portContinent: "Middle East",
  },
  {
    lat: "22.4486944",
    lng: "39.1335549",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SAKAC",
    portName: "King Abdullah City",
    portContinent: "Middle East",
  },
  {
    lat: "22.7906701",
    lng: "39.0189623",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SARAB",
    portName: "Rabigh (SARAB), Saudi Arabia, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "28.0706964",
    lng: "48.6163371",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SARAM",
    portName: "Ras Al Mishab (SARAM), Saudi Arabia, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "28.4256618",
    lng: "48.4887224",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SARAR",
    portName: "Khafji (SARAR), Saudi Arabia, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "27.4983987",
    lng: "49.1868235",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SARAZ",
    portName: "Ras Al Khair (SARAZ), Saudi Arabia, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "26.641168",
    lng: "50.1573944",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SARTA",
    portName: "Port of Ras Tanura",
    portContinent: "Middle East",
  },
  {
    lat: "24.7135517",
    lng: "46.6752957",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SARUH",
    portName: "riyadh",
    portContinent: "Middle East",
  },
  {
    lat: "24.6447685",
    lng: "46.7435659",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SARYP",
    portName: "RIYADH DRY PORT (SARYP), Riyadh, Saudi Arabia",
    portContinent: "Middle East",
  },
  {
    lat: "23.9845482",
    lng: "38.2533052",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SAYBI",
    portName: "Yanbu Industrial (SAYBI), Saudi Arabia, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "24.0231757",
    lng: "38.1899782",
    country: "SA",
    country_long: "Saudi Arabia",
    portCode: "SAYNB",
    portName: "Yanbu (SAYNB), Saudi Arabia, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "-8.7678593",
    lng: "160.6960306",
    country: "SB",
    country_long: "Solomon Islands",
    portCode: "SBAKS",
    portName: "Auki (SBAKS), Solomon Islands, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-9.527336000000002",
    lng: "160.4893065",
    country: "SB",
    country_long: "Solomon Islands",
    portCode: "SBAOB",
    portName: "Aola Bay (SBAOB), Solomon Islands, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-8.1058258",
    lng: "156.8349986",
    country: "SB",
    country_long: "Solomon Islands",
    portCode: "SBGZO",
    portName: "Gizo (SBGZO), Solomon Islands, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-9.4456381",
    lng: "159.9728999",
    country: "SB",
    country_long: "Solomon Islands",
    portCode: "SBHIR",
    portName: "Honiara (SBHIR), Solomon Islands, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-10.4558259",
    lng: "161.9187146",
    country: "SB",
    country_long: "Solomon Islands",
    portCode: "SBIRA",
    portName: "Kirakira (SBIRA), Solomon Islands, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-8.2409868",
    lng: "157.199876",
    country: "SB",
    country_long: "Solomon Islands",
    portCode: "SBNOR",
    portName: "Noro, New Georgia",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "SBRIN",
    portName: "Ringgi Cove (SBRIN), Solomon Islands, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-11.6632521",
    lng: "160.2646431",
    country: "SB",
    country_long: "Solomon Islands",
    portCode: "SBRNL",
    portName: "Rannell Island (SBRNL), Solomon Islands, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-9.1015826",
    lng: "160.147069",
    country: "SB",
    country_long: "Solomon Islands",
    portCode: "SBTLG",
    portName: "Tulagi (SBTLG), Solomon Islands, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-8.497982799999999",
    lng: "157.7273222",
    country: "SB",
    country_long: "Solomon Islands",
    portCode: "SBVIU",
    portName: "Viru Harbour (SBVIU), Solomon Islands, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-9.079527299999999",
    lng: "159.2207029",
    country: "SB",
    country_long: "Solomon Islands",
    portCode: "SBXYA",
    portName: "Yandina (SBXYA), Solomon Islands, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-4.5837397",
    lng: "55.9386424",
    country: "SC",
    country_long: "Seychelles",
    portCode: "SCFRK",
    portName: "Fregate (SCFRK), Seychelles, Africa",
    portContinent: "Africa",
  },
  {
    lat: "33.7419683",
    lng: "-118.098453",
    country: "US",
    country_long: "United States",
    portCode: "SCMAW",
    portName: "Mahe",
    portContinent: "Africa",
  },
  {
    lat: "-4.6166667",
    lng: "55.4833333",
    country: "SC",
    country_long: "Seychelles",
    portCode: "SCPOV",
    portName: "Port of Port Victoria",
    portContinent: "Africa",
  },
  {
    lat: "-4.3325908",
    lng: "55.74672589999999",
    country: "SC",
    country_long: "Seychelles",
    portCode: "SCPRI",
    portName: "Praslin (SCPRI), Seychelles, Africa",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "SCVIC",
    portName: "VICTORIA, SC",
    portContinent: "Africa",
  },
  {
    lat: "15.5006544",
    lng: "32.5598994",
    country: "SD",
    country_long: "Sudan",
    portCode: "SDKRT",
    portName: "KHARTOUM (SDKRT), Khartoum, Sudan",
    portContinent: "North Africa",
  },
  {
    lat: "12.862807",
    lng: "30.217636",
    country: "SD",
    country_long: "Sudan",
    portCode: "SDMBH",
    portName: "Marsa Bashayer (SDMBH), Sudan, Meg",
    portContinent: "North Africa",
  },
  {
    lat: "19.5903471",
    lng: "37.1901616",
    country: "SD",
    country_long: "Sudan",
    portCode: "SDPZU",
    portName: "Port Sudan",
    portContinent: "North Africa",
  },
  {
    lat: "4.095271299999999",
    lng: "30.6775054",
    country: "SS",
    country_long: "South Sudan",
    portCode: "SDYEI",
    portName: "Yei",
    portContinent: "North Africa",
  },
  {
    lat: "57.9591741",
    lng: "12.1245139",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEAGN",
    portName: "Alvangen (SEAGN), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.9250391",
    lng: "14.2979949",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEAHU",
    portName: "Port of Ahus",
    portContinent: "Europe",
  },
  {
    lat: "58.783732",
    lng: "17.8420775",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEAKN",
    portName: "Ankarudden (SEAKN), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.245748",
    lng: "12.863881",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEANG",
    portName: "ANGELHOLM (SEANG), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.12816100000001",
    lng: "18.643501",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEANH",
    portName: "Angehamn (SEANH), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.29858319999999",
    lng: "18.0459442",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEARS",
    portName: "Arsta/Stockholm (SEARS), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.88942679999999",
    lng: "14.9109867",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEASD",
    portName: "Askersund (SEASD), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.4067279",
    lng: "18.8853608",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEBEG",
    portName: "Berg (Moja) (SEBEG), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.05",
    lng: "11.516667",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEBJH",
    portName: "Bjorholmen (SEBJH), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.291667",
    lng: "11.566667",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEBKA",
    portName: "Bokenas (SEBKA), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.3535256",
    lng: "17.9671845",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEBLV",
    portName: "Ballstaviken (SEBLV), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.3456844",
    lng: "11.343897",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEBML",
    portName: "Bohus Malmon (SEBML), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.73901410000001",
    lng: "17.308295",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEBNN",
    portName: "Bonan (SEBNN), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.8498817",
    lng: "12.0168595",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEBOH",
    portName: "Bohus (SEBOH), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.72103500000001",
    lng: "12.939819",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEBOS",
    portName: "BORAS",
    portContinent: "Europe",
  },
  {
    lat: "58.3569174",
    lng: "11.418697",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEBRO",
    portName: "Brofjorden (SEBRO), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.00616100000001",
    lng: "11.7507612",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEBSD",
    portName: "Berga Strand (SEBSD), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.02135",
    lng: "16.83698",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEDJP",
    portName: "Djupvik (SEDJP), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.6016667",
    lng: "11.8069444",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEDON",
    portName: "Donso (SEDON), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.39707249999999",
    lng: "18.0903005",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEDSM",
    portName: "Djursholm (SEDSM), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.37124859999999",
    lng: "16.5098045",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEEKT",
    portName: "ESKILSTUNA (SEEKT), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.166667",
    lng: "14.75",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEELL",
    portName: "Elleholm (SEELL), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.32932349999999",
    lng: "18.0685808",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEEST",
    portName: "ESTOCOLMO",
    portContinent: "Europe",
  },
  {
    lat: "59.7371524",
    lng: "19.1649867",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEFEJ",
    portName: "Fejan (SEFEJ), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.3763329",
    lng: "16.5550694",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEFIG",
    portName: "Figeholm (SEFIG), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.3548204",
    lng: "11.2647952",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEFIT",
    portName: "Fisketangen (SEFIT), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.59977559999999",
    lng: "11.2882238",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEFJA",
    portName: "Fjallbacka (SEFJA), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.5431511",
    lng: "16.5844219",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEFLI",
    portName: "Flivik (SEFLI), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.86126319999999",
    lng: "19.0539689",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEFSD",
    portName: "Farosund (SEFSD), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.6635126",
    lng: "18.9156633",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEFUD",
    portName: "Furusund (SEFUD), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.7624965",
    lng: "19.0366113",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEGDD",
    portName: "Graddo (SEGDD), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.121813",
    lng: "12.2197116",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEGEN",
    portName: "Getteron (SEGEN), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.2687803",
    lng: "16.4035388",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEGNG",
    portName: "Gronhogen (SEGNG), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.12816100000001",
    lng: "18.643501",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEGOO",
    portName: "Groto (SEGOO), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.7017303",
    lng: "11.9479464",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEGOT",
    portName: "Gothenburg, SE (Port of Goteborg)",
    portContinent: "Europe",
  },
  {
    lat: "58.214729",
    lng: "11.4178031",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEGRD",
    portName: "Grundsund (SEGRD), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.6748796",
    lng: "17.1412726",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEGVX",
    portName: "Port of Gavle",
    portContinent: "Europe",
  },
  {
    lat: "56.6743748",
    lng: "12.8577884",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHAD",
    portName: "Halmstad",
    portContinent: "Europe",
  },
  {
    lat: "60.12816100000001",
    lng: "18.643501",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHAH",
    portName: "Haraholmen (SEHAH), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.75645240000001",
    lng: "11.1804345",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHAV",
    portName: "Havstenssund (SEHAV), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.12816100000001",
    lng: "18.643501",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHBO",
    portName: "Hamburgon (SEHBO), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.3662135",
    lng: "17.8599926",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHBV",
    portName: "Hasselby (SEHBV), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.0381961",
    lng: "12.6943732",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHEL",
    portName: "Port of Helsingborg",
    portContinent: "Europe",
  },
  {
    lat: "57.6877267",
    lng: "11.6569545",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHHE",
    portName: "Heden (Hono) (SEHHE), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.6815391",
    lng: "11.6443403",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHKL",
    portName: "Klava (Hono) (SEHKL), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.05",
    lng: "11.533333",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHLB",
    portName: "Halsback (SEHLB), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.70738919999999",
    lng: "20.3639178",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHLD",
    portName: "Holmsund (SEHLD), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.1090944",
    lng: "15.4625203",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHLE",
    portName: "Hasslo (SEHLE), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.40986729999999",
    lng: "12.9560114",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHLK",
    portName: "Hollviksnas (SEHLK), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.6322698",
    lng: "17.9408714",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHND",
    portName: "Harnosand (SEHND), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.2006388",
    lng: "12.5553288",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHOG",
    portName: "Hoganas (SEHOG), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.6877267",
    lng: "11.6569545",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHRO",
    portName: "Rod (Hono) (SEHRO), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.366667",
    lng: "11.233333",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHS3",
    portName: "Hasselosund (SEHS3), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.73333299999999",
    lng: "11.65",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHSO",
    portName: "Halso (SEHSO), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.3369882",
    lng: "19.167396",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHUS",
    portName: "Husum (SEHUS), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.1232884",
    lng: "11.4435943",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEHVS",
    portName: "Hallevikstrand (SEHVS), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "61.64261140000001",
    lng: "17.0751266",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEIGG",
    portName: "Iggesund (SEIGG), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.78261370000001",
    lng: "14.1617876",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEJKG",
    portName: "Jonkoping",
    portContinent: "Europe",
  },
  {
    lat: "56.1566",
    lng: "15.5848",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEKAA",
    portName: "Port of Karlskrona",
    portContinent: "Europe",
  },
  {
    lat: "56.170303",
    lng: "14.863073",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEKAN",
    portName: "Karlshamn (SEKAN), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.10833330000001",
    lng: "11.3666667",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEKAR",
    portName: "Karingon (SEKAR), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.3100677",
    lng: "14.1089192",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEKHN",
    portName: "Kristinehamn (SEKHN), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.0293936",
    lng: "14.1566778",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEKID",
    portName: "KRISTIANSTAD",
    portContinent: "Europe",
  },
  {
    lat: "55.685039",
    lng: "14.2265929",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEKIK",
    portName: "Kivik (SEKIK), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.7503361",
    lng: "11.6505774",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEKKN",
    portName: "Knippla (SEKKN), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "SEKLR",
    portName: "Port of Kalmar",
    portContinent: "Europe",
  },
  {
    lat: "57.54738829999999",
    lng: "11.9430697",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEKLV",
    portName: "Kullavik (SEKLV), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.5120962",
    lng: "15.9945102",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEKOG",
    portName: "Koping (SEKOG), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.71894999999999",
    lng: "19.06578",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEKPS",
    portName: "Kapellskar (SEKPS), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.9543299",
    lng: "16.8867078",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEKRH",
    portName: "Karehamn (SEKRH), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.4021806",
    lng: "13.5114977",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEKSD",
    portName: "Karlstad (SEKSD), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.8703477",
    lng: "12.8300802",
    country: "SE",
    country_long: "Sweden",
    portCode: "SELAA",
    portName: "Landskrona (SELAA), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.3577557",
    lng: "11.449381",
    country: "SE",
    country_long: "Sweden",
    portCode: "SELAL",
    portName: "Lahalla (SELAL), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.743056",
    lng: "17.865556",
    country: "SE",
    country_long: "Sweden",
    portCode: "SELAT",
    portName: "Landsort (SELAT), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.95419750000001",
    lng: "19.0770722",
    country: "SE",
    country_long: "Sweden",
    portCode: "SELAU",
    portName: "Lauterhorn (SELAU), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.66944400000001",
    lng: "11.855556",
    country: "SE",
    country_long: "Sweden",
    portCode: "SELGD",
    portName: "Langedrag (SELGD), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.5724415",
    lng: "12.9147749",
    country: "SE",
    country_long: "Sweden",
    portCode: "SELIM",
    portName: "Limhamn (SELIM), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "61.2114046",
    lng: "17.1266474",
    country: "SE",
    country_long: "Sweden",
    portCode: "SELJU",
    portName: "Ljusne (SELJU), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.3",
    lng: "11.3666667",
    country: "SE",
    country_long: "Sweden",
    portCode: "SELKO",
    portName: "Lilla Korno (SELKO), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.584819",
    lng: "22.1567026",
    country: "SE",
    country_long: "Sweden",
    portCode: "SELLA",
    portName: "Lulea (SELLA), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.41080700000001",
    lng: "15.6213727",
    country: "SE",
    country_long: "Sweden",
    portCode: "SELPI",
    portName: "LINKOEPING,LinkÃ¶ping",
    portContinent: "Europe",
  },
  {
    lat: "60.12816100000001",
    lng: "18.643501",
    country: "SE",
    country_long: "Sweden",
    portCode: "SELVN",
    portName: "Lavon (SELVN), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.27557299999999",
    lng: "11.435558",
    country: "SE",
    country_long: "Sweden",
    portCode: "SELYS",
    portName: "Lysekil (SELYS), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.2821904",
    lng: "12.5021241",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEMLE",
    portName: "Molle (SEMLE), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.604981",
    lng: "13.003822",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEMMA",
    portName: "MALMOE (SEMMA), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.0415797",
    lng: "16.44312",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEMON",
    portName: "Monsteras (SEMON), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.2832576",
    lng: "21.4962857",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEMUN",
    portName: "Munksund (SEMUN), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.6304568",
    lng: "16.7977532",
    country: "SE",
    country_long: "Sweden",
    portCode: "SENAV",
    portName: "Navekvarn (SENAV), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "63.90081300000001",
    lng: "20.5519791",
    country: "SE",
    country_long: "Sweden",
    portCode: "SENFN",
    portName: "Norrfjarden (Savar) (SENFN), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.7595841",
    lng: "18.7013584",
    country: "SE",
    country_long: "Sweden",
    portCode: "SENOE",
    portName: "Norrtalje (SENOE), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.8957838",
    lng: "11.0086378",
    country: "SE",
    country_long: "Sweden",
    portCode: "SENOK",
    portName: "Nord Koster (SENOK), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.587745",
    lng: "16.192421",
    country: "SE",
    country_long: "Sweden",
    portCode: "SENRK",
    portName: "NorrkÃ¶ping,NORRKOEPING",
    portContinent: "Europe",
  },
  {
    lat: "58.933333",
    lng: "17.95",
    country: "SE",
    country_long: "Sweden",
    portCode: "SENRR",
    portName: "NORVIK",
    portContinent: "Europe",
  },
  {
    lat: "58.90292600000001",
    lng: "17.9465289",
    country: "SE",
    country_long: "Sweden",
    portCode: "SENYN",
    portName: "nynashamn",
    portContinent: "Europe",
  },
  {
    lat: "58.75284389999999",
    lng: "17.0091593",
    country: "SE",
    country_long: "Sweden",
    portCode: "SENYO",
    portName: "Nykoping (SENYO), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.12816100000001",
    lng: "18.643501",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEOC2",
    portName: "Bjornhuvudet (SEOC2), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.711741",
    lng: "11.6487886",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEOCO",
    portName: "Ockero (SEOCO), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.2752626",
    lng: "15.2134105",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEORB",
    portName: "OEREBRO",
    portContinent: "Europe",
  },
  {
    lat: "57.26569929999999",
    lng: "16.4473984",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEOSK",
    portName: "Oskarshamn  (SEOSK), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.4775863",
    lng: "17.3302781",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEOST",
    portName: "Ostrand (SEOST), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.6754851",
    lng: "17.0749546",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEOXE",
    portName: "OXELOSUND (SEOXE), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "65.316698",
    lng: "21.4800364",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEPIT",
    portName: "PITEA (SEPIT), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.12816100000001",
    lng: "18.643501",
    country: "SE",
    country_long: "Sweden",
    portCode: "SERAS",
    portName: "Ravsnas (SERAS), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.8062546",
    lng: "11.184965",
    country: "SE",
    country_long: "Sweden",
    portCode: "SERES",
    portName: "Reso (SERES), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.1749105",
    lng: "18.4821489",
    country: "SE",
    country_long: "Sweden",
    portCode: "SERNH",
    portName: "Port of Ronehamn",
    portContinent: "Europe",
  },
  {
    lat: "62.390811",
    lng: "17.306927",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESDL",
    portName: "Sundsvall (SESDL), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "64.750244",
    lng: "20.950917",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESFT",
    portName: "Port of Skelleftea",
    portContinent: "Europe",
  },
  {
    lat: "59.4166667",
    lng: "18.3666667",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESK2",
    portName: "Skarpo (SESK2), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "SESKG",
    portName: "Jï¿½NKï¿½PING",
    portContinent: "Europe",
  },
  {
    lat: "63.1913225",
    lng: "19.017326",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESKP",
    portName: "Skeppsmalen (SESKP), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.9383459",
    lng: "11.1791871",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESMD",
    portName: "Stromstad (SESMD), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.36332429999999",
    lng: "11.2367763",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESMO",
    portName: "Smogen (SESMO), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.4156537",
    lng: "16.6606198",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESMV",
    portName: "Simpevarp (SESMV), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.1428592",
    lng: "11.8220189",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESND",
    portName: "Svanesund (SESND), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "SESOE",
    portName: "SÃ¶dertÃ¤lje",
    portContinent: "Europe",
  },
  {
    lat: "56.0537433",
    lng: "14.5796878",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESOL",
    portName: "Solvesborg (SESOL), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "62.50601460000001",
    lng: "17.5073245",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESOR",
    portName: "Soraker (SESOR), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.3",
    lng: "11.3833333",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESRK",
    portName: "Stora Korno (SESRK), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.2911887",
    lng: "18.690457",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEST3",
    portName: "Stavsnas (SEST3), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.0678388",
    lng: "11.8294346",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESTE",
    portName: "Stenungsund (SESTE), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.9090611",
    lng: "17.9595929",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESTO",
    portName: "Ports of Stockholm",
    portContinent: "Europe",
  },
  {
    lat: "58.9555502",
    lng: "17.7871845",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESTV",
    portName: "Stora Vika (SESTV), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.6514933",
    lng: "18.9287599",
    country: "SE",
    country_long: "Sweden",
    portCode: "SESUS",
    portName: "Storugns (SESUS), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "60.12816100000001",
    lng: "18.643501",
    country: "SE",
    country_long: "Sweden",
    portCode: "SETCK",
    portName: "Tjocko (SETCK), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.61861099999999",
    lng: "11.772222",
    country: "SE",
    country_long: "Sweden",
    portCode: "SETGN",
    portName: "Tangen (Styrso) (SETGN), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.3762427",
    lng: "13.1574231",
    country: "SE",
    country_long: "Sweden",
    portCode: "SETRG",
    portName: "Trelleborg (SETRG), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.3498003",
    lng: "11.935649",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEUDD",
    portName: "Uddevalla (SEUDD), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "SEUME",
    portName: "UMEA (SEUME), UmeÐ“Ò, Sweden",
    portContinent: "Europe",
  },
  {
    lat: "57.107118",
    lng: "12.2520907",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEVAG",
    portName: "Varberg (SEVAG), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "61.1847212",
    lng: "17.1791565",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEVAL",
    portName: "Vallvik (SEVAL), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.6348",
    lng: "18.29484",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEVBY",
    portName: "Visby (SEVBY), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "56.942989",
    lng: "18.3091722",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEVDB",
    portName: "Vandburg (SEVDB), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.6528971",
    lng: "11.9105525",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEVFA",
    portName: "Vastra Frolunda (SEVFA), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.2635432",
    lng: "17.8876479",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEVRB",
    portName: "Varby (SEVRB), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "59.60990049999999",
    lng: "16.5448091",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEVST",
    portName: "Vasteras (SEVST), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "57.75771559999999",
    lng: "16.6369759",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEVVK",
    portName: "Vastervik (SEVVK), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "58.01666699999999",
    lng: "11.7",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEWAL",
    portName: "Wallhamn (SEWAL), Sweden, Europe",
    portContinent: "Europe",
  },
  {
    lat: "55.4295051",
    lng: "13.8200308",
    country: "SE",
    country_long: "Sweden",
    portCode: "SEYST",
    portName: "Port of Ystad",
    portContinent: "Europe",
  },
  {
    lat: "1.4491107",
    lng: "103.8184954",
    country: "SG",
    country_long: "Singapore",
    portCode: "SGSEM",
    portName: "Sembawang (SGSEM), Singapore, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "1.352083",
    lng: "103.819836",
    country: "SG",
    country_long: "Singapore",
    portCode: "SGSIN",
    portName: "Port of Singapore",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "SHSHN",
    portName: "Jamestown Harbour",
    portContinent: "Africa",
  },
  {
    lat: "46.23974949999999",
    lng: "15.2677063",
    country: "SI",
    country_long: "Slovenia",
    portCode: "SICJE",
    portName: "CELJE",
    portContinent: "Europe",
  },
  {
    lat: "45.5374048",
    lng: "13.6600802",
    country: "SI",
    country_long: "Slovenia",
    portCode: "SIIZO",
    portName: "Port of Izola",
    portContinent: "Europe",
  },
  {
    lat: "45.54805899999999",
    lng: "13.7301877",
    country: "SI",
    country_long: "Slovenia",
    portCode: "SIKOP",
    portName: "Port of Koper",
    portContinent: "Europe",
  },
  {
    lat: "46.2428344",
    lng: "14.3555417",
    country: "SI",
    country_long: "Slovenia",
    portCode: "SIKRJ",
    portName: "KRANJ",
    portContinent: "Europe",
  },
  {
    lat: "45.5082134",
    lng: "13.6031335",
    country: "SI",
    country_long: "Slovenia",
    portCode: "SILCJ",
    portName: "Lucija (SILCJ), Slovenia, Med",
    portContinent: "Europe",
  },
  {
    lat: "46.0569465",
    lng: "14.5057515",
    country: "SI",
    country_long: "Slovenia",
    portCode: "SILJA",
    portName: "Ljubljana",
    portContinent: "Europe",
  },
  {
    lat: "46.0569465",
    lng: "14.5057515",
    country: "SI",
    country_long: "Slovenia",
    portCode: "SILJU",
    portName: "LJUBLJANA",
    portContinent: "Europe",
  },
  {
    lat: "46.5546503",
    lng: "15.6458812",
    country: "SI",
    country_long: "Slovenia",
    portCode: "SIMBX",
    portName: "MARIBOR (SIMBX), Maribor, Slovenia",
    portContinent: "Europe",
  },
  {
    lat: "45.80108240000001",
    lng: "15.1710089",
    country: "SI",
    country_long: "Slovenia",
    portCode: "SINMO",
    portName: "NOVA MESTO",
    portContinent: "Europe",
  },
  {
    lat: "45.9549755",
    lng: "13.6493044",
    country: "SI",
    country_long: "Slovenia",
    portCode: "SINOG",
    portName: "NOVA GORICA",
    portContinent: "Europe",
  },
  {
    lat: "45.528319",
    lng: "13.5682895",
    country: "SI",
    country_long: "Slovenia",
    portCode: "SIPIR",
    portName: "Port of Piran",
    portContinent: "Europe",
  },
  {
    lat: "46.3919813",
    lng: "15.5727868",
    country: "SI",
    country_long: "Slovenia",
    portCode: "SISBA",
    portName: "SLOVENSKA BISTRICA",
    portContinent: "Europe",
  },
  {
    lat: "48.1354337",
    lng: "17.1495005",
    country: "SK",
    country_long: "Slovakia",
    portCode: "SKBTS",
    portName: "Port of Bratislava",
    portContinent: "Europe",
  },
  {
    lat: "47.9594567",
    lng: "17.6196051",
    country: "SK",
    country_long: "Slovakia",
    portCode: "SKDJA",
    portName: "DUNAJSKE STREDA (SKDJA), Slovakia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "49.21944980000001",
    lng: "18.7408001",
    country: "SK",
    country_long: "Slovakia",
    portCode: "SKILZ",
    portName: "Zilina",
    portContinent: "Europe",
  },
  {
    lat: "47.7625785",
    lng: "18.1294132",
    country: "SK",
    country_long: "Slovakia",
    portCode: "SKKNA",
    portName: "Komarno (SKKNA), Slovakia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "48.7163857",
    lng: "21.2610746",
    country: "SK",
    country_long: "Slovakia",
    portCode: "SKKSC",
    portName: "KOSICE (SKKSC), Kosice, Slovakia",
    portContinent: "Europe",
  },
  {
    lat: "57.3436767",
    lng: "28.3521426",
    country: "RU",
    country_long: "Russia",
    portCode: "SKOST",
    portName: "Ostrov",
    portContinent: "Europe",
  },
  {
    lat: "48.202103",
    lng: "17.6379821",
    country: "SK",
    country_long: "Slovakia",
    portCode: "SKSLV",
    portName: "SLADKOVICOVO (SKSLV), Slovakia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "47.799212",
    lng: "18.7152845",
    country: "SK",
    country_long: "Slovakia",
    portCode: "SKSRA",
    portName: "Sturovo (SKSRA), Slovakia, Europe",
    portContinent: "Europe",
  },
  {
    lat: "7.525702799999999",
    lng: "-12.5039922",
    country: "SL",
    country_long: "Sierra Leone",
    portCode: "SLBTE",
    portName: "Bonthe (SLBTE), Sierra Leone, Africa",
    portContinent: "Africa",
  },
  {
    lat: "8.490777399999999",
    lng: "-13.2128169",
    country: "SL",
    country_long: "Sierra Leone",
    portCode: "SLFNA",
    portName: "Port of Freetown",
    portContinent: "Africa",
  },
  {
    lat: "7.8070721",
    lng: "-12.3821875",
    country: "SL",
    country_long: "Sierra Leone",
    portCode: "SLGBK",
    portName: "Gbangbatok (SLGBK), Sierra Leone, Africa",
    portContinent: "Africa",
  },
  {
    lat: "8.5871363",
    lng: "-13.055859",
    country: "SL",
    country_long: "Sierra Leone",
    portCode: "SLPEP",
    portName: "Port of Pepel",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "SNDKR",
    portName: "Port of Dakar",
    portContinent: "North Africa",
  },
  {
    lat: "14.1652083",
    lng: "-16.0757749",
    country: "SN",
    country_long: "Senegal",
    portCode: "SNKLC",
    portName: "Port of Kaolack",
    portContinent: "North Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "SNLYN",
    portName: "Port of Lyndiane",
    portContinent: "North Africa",
  },
  {
    lat: "12.5877128",
    lng: "-16.2674763",
    country: "SN",
    country_long: "Senegal",
    portCode: "SNZIG",
    portName: "Port of Ziguinchor",
    portContinent: "North Africa",
  },
  {
    lat: "10.4388783",
    lng: "44.9995722",
    country: "SO",
    country_long: "Somalia",
    portCode: "SOBBO",
    portName: "Port of Berbera",
    portContinent: "Africa",
  },
  {
    lat: "11.2755407",
    lng: "49.1878994",
    country: "SO",
    country_long: "Somalia",
    portCode: "SOBSA",
    portName: "Bosaso (SOBSA), Somalia, Africa",
    portContinent: "Africa",
  },
  {
    lat: "9.562389",
    lng: "44.0770134",
    country: "SO",
    country_long: "Somalia",
    portCode: "SOHGA",
    portName: "HARGEISA",
    portContinent: "Africa",
  },
  {
    lat: "-0.3560455",
    lng: "42.5460573",
    country: "SO",
    country_long: "Somalia",
    portCode: "SOKMU",
    portName: "Port of Kismayu",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "SOMER",
    portName: "Port of Merca",
    portContinent: "Africa",
  },
  {
    lat: "2.023869",
    lng: "45.333168",
    country: "SO",
    country_long: "Somalia",
    portCode: "SOMGQ",
    portName: "Port of Mogadishu",
    portContinent: "Africa",
  },
  {
    lat: "5.5050681",
    lng: "-54.0793451",
    country: "SR",
    country_long: "Suriname",
    portCode: "SRABN",
    portName: "Albina (SRABN), Suriname, LatAm",
    portContinent: "South America",
  },
  {
    lat: "5.9475215",
    lng: "-56.9949837",
    country: "SR",
    country_long: "Suriname",
    portCode: "SRICK",
    portName: "Port of Nieuw Nickerie",
    portContinent: "South America",
  },
  {
    lat: "5.608084799999999",
    lng: "-54.398656",
    country: "SR",
    country_long: "Suriname",
    portCode: "SRMOJ",
    portName: "Moengo (SRMOJ), Suriname, LatAm",
    portContinent: "South America",
  },
  {
    lat: "5.8520355",
    lng: "-55.2038278",
    country: "SR",
    country_long: "Suriname",
    portCode: "SRPBM",
    portName: "Port of Paramaribo",
    portContinent: "South America",
  },
  {
    lat: "4.859363",
    lng: "31.57125",
    country: "SS",
    country_long: "South Sudan",
    portCode: "SSJUB",
    portName: "JUBA (SSJUB), Juba, South Sudan",
    portContinent: "Africa",
  },
  {
    lat: "4.859363",
    lng: "31.57125",
    country: "SS",
    country_long: "South Sudan",
    portCode: "SSJUM",
    portName: "JUBA",
    portContinent: "North Africa",
  },
  {
    lat: "6.807249400000001",
    lng: "29.6788877",
    country: "SS",
    country_long: "South Sudan",
    portCode: "SSRBK",
    portName: "Rumbek",
    portContinent: "Africa",
  },
  {
    lat: "1.6367611",
    lng: "7.417856999999999",
    country: "ST",
    country_long: "São Tomé and Príncipe",
    portCode: "STSAA",
    portName: "Santo Antonio (STSAA), Sao Tome and Principe, Africa",
    portContinent: "Africa",
  },
  {
    lat: "0.3301924",
    lng: "6.733343",
    country: "ST",
    country_long: "São Tomé and Príncipe",
    portCode: "STTMS",
    portName: "Sao Tome (STTMS), Sao Tome, Sao Tome and Principe",
    portContinent: "Africa",
  },
  {
    lat: "13.5752407",
    lng: "-89.8337305",
    country: "SV",
    country_long: "El Salvador",
    portCode: "SVAQJ",
    portName: "Port of Acajutla",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "SVCOL",
    portName: "COLON",
    portContinent: "North America",
  },
  {
    lat: "-2.2602831",
    lng: "-79.8783334",
    country: "EC",
    country_long: "Ecuador",
    portCode: "SVEPL",
    portName: "EL PEDREGAL",
    portContinent: "North America",
  },
  {
    lat: "13.6757837",
    lng: "-89.2894733",
    country: "SV",
    country_long: "El Salvador",
    portCode: "SVEZE",
    portName: "SANTA TECLA",
    portContinent: "North America",
  },
  {
    lat: "33.73954589643058",
    lng: "-118.2599686241527",
    country: "US",
    country_long: "United States",
    portCode: "SVLLD",
    portName: "Port of La Libertad",
    portContinent: "North America",
  },
  {
    lat: "16.6158906",
    lng: "120.3209373",
    country: "PH",
    country_long: "Philippines",
    portCode: "SVLUN",
    portName: "Port of La Union",
    portContinent: "North America",
  },
  {
    lat: "13.6929403",
    lng: "-89.2181911",
    country: "SV",
    country_long: "El Salvador",
    portCode: "SVSAL",
    portName: "San Salvador",
    portContinent: "North America",
  },
  {
    lat: "13.8758656",
    lng: "-89.3582599",
    country: "SV",
    country_long: "El Salvador",
    portCode: "SVSJO",
    portName: "SAN JUAN OPICO",
    portContinent: "North America",
  },
  {
    lat: "13.4697614",
    lng: "-89.0871808",
    country: "SV",
    country_long: "El Salvador",
    portCode: "SVSLT",
    portName: "San Luis Talpa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "SXGES",
    portName: "GALLIS BAY",
    portContinent: "North America",
  },
  {
    lat: "18.0675189",
    lng: "-63.0824656",
    country: "MF",
    country_long: "Saint Martin",
    portCode: "SXMAR",
    portName: "MARIGOT (SAINT MARTIN)",
    portContinent: "North America",
  },
  {
    lat: "18.0295839",
    lng: "-63.04713709999999",
    country: "SX",
    country_long: "Sint Maarten",
    portCode: "SXPHI",
    portName: "PHILIPSBURG (SXPHI), Sint Maarten, LatAm",
    portContinent: "North America",
  },
  {
    lat: "33.247252",
    lng: "35.693707",
    country: "N/A",
    country_long: "N/A",
    portCode: "SYBAN",
    portName: "Port of Banias",
    portContinent: "Middle East",
  },
  {
    lat: "35.5321529",
    lng: "35.7748042",
    country: "SY",
    country_long: "Syria",
    portCode: "SYLTK",
    portName: "Port of Lattakia",
    portContinent: "Middle East",
  },
  {
    lat: "34.8959276",
    lng: "35.8866517",
    country: "SY",
    country_long: "Syria",
    portCode: "SYTTS",
    portName: "Tartous (SYTTS), Syria, Med",
    portContinent: "Middle East",
  },
  {
    lat: "-26.5009481",
    lng: "31.3141291",
    country: "SZ",
    country_long: "Eswatini",
    portCode: "SZMAT",
    portName: "Matsapha",
    portContinent: "Africa",
  },
  {
    lat: "-26.5081999",
    lng: "31.3713164",
    country: "SZ",
    country_long: "Eswatini",
    portCode: "SZMTS",
    portName: "MANZINI",
    portContinent: "Africa",
  },
  {
    lat: "-26.3054482",
    lng: "31.1366715",
    country: "SZ",
    country_long: "Eswatini",
    portCode: "SZQMN",
    portName: "Mbabane",
    portContinent: "Africa",
  },
  {
    lat: "21.4674584",
    lng: "-71.13891009999999",
    country: "TC",
    country_long: "Turks and Caicos Islands",
    portCode: "TCGDT",
    portName: "Grand Turk Island",
    portContinent: "North America",
  },
  {
    lat: "21.7737654",
    lng: "-72.271908",
    country: "TC",
    country_long: "Turks and Caicos Islands",
    portCode: "TCPLS",
    portName: "Port of Providenciales",
    portContinent: "North America",
  },
  {
    lat: "8.5914775",
    lng: "16.0757749",
    country: "TD",
    country_long: "Chad",
    portCode: "TDMQQ",
    portName: "MOUNDOU (TDMQQ), Moundou, Chad",
    portContinent: "North Africa",
  },
  {
    lat: "12.1348457",
    lng: "15.0557415",
    country: "TD",
    country_long: "Chad",
    portCode: "TDNDJ",
    portName: "N'DJAMENA (TDNDJ), Ndjamena, Chad",
    portContinent: "North Africa",
  },
  {
    lat: "-49.3496422",
    lng: "70.2180039",
    country: "TF",
    country_long: "French Southern and Antarctic Lands",
    portCode: "TFPFR",
    portName: "PORT-AUX-FRANCAIS (TFPFR), French Southern and Antarctic Lands,",
    portContinent: "Antarctica",
  },
  {
    lat: "6.1429433",
    lng: "1.2622504",
    country: "TG",
    country_long: "Togo",
    portCode: "TGKPE",
    portName: "Kpeme (TGKPE), Togo, Africa",
    portContinent: "Africa",
  },
  {
    lat: "6.1256261",
    lng: "1.2254183",
    country: "TG",
    country_long: "Togo",
    portCode: "TGLFW",
    portName: "Port of Lome",
    portContinent: "Africa",
  },
  {
    lat: "14.3692325",
    lng: "100.5876634",
    country: "TH",
    country_long: "Thailand",
    portCode: "THAYU",
    portName: "Ayutthaya",
    portContinent: "South East Asia",
  },
  {
    lat: "13.6464047",
    lng: "100.5391363",
    country: "TH",
    country_long: "Thailand",
    portCode: "THBBT",
    portName: "Bangkok  BBT",
    portContinent: "South East Asia",
  },
  {
    lat: "13.5469861",
    lng: "100.9878319",
    country: "TH",
    country_long: "Thailand",
    portCode: "THBGP",
    portName: "Bangpakong (THBGP), Thailand, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "13.7037349",
    lng: "100.5754479",
    country: "TH",
    country_long: "Thailand",
    portCode: "THBKK",
    portName: "Port of Bangkok",
    portContinent: "South East Asia",
  },
  {
    lat: "13.7563309",
    lng: "100.5017651",
    country: "TH",
    country_long: "Thailand",
    portCode: "THBMT",
    portName: "Bangkok BMT",
    portContinent: "South East Asia",
  },
  {
    lat: "13.3611431",
    lng: "100.9846717",
    country: "TH",
    country_long: "Thailand",
    portCode: "THCHB",
    portName: "Chon Buri",
    portContinent: "South East Asia",
  },
  {
    lat: "15.1264018",
    lng: "105.4302383",
    country: "TH",
    country_long: "Thailand",
    portCode: "THCHM",
    portName: "Chong Mek",
    portContinent: "South East Asia",
  },
  {
    lat: "18.7883439",
    lng: "98.98530079999999",
    country: "TH",
    country_long: "Thailand",
    portCode: "THCNX",
    portName: "Chiang Mai",
    portContinent: "South East Asia",
  },
  {
    lat: "13.6904194",
    lng: "101.0779596",
    country: "TH",
    country_long: "Thailand",
    portCode: "THCSD",
    portName: "Chachoengsao",
    portContinent: "South East Asia",
  },
  {
    lat: "7.820216800000001",
    lng: "98.4048122",
    country: "TH",
    country_long: "Thailand",
    portCode: "THHKT",
    portName: "Port of Phuket",
    portContinent: "South East Asia",
  },
  {
    lat: "7.4287558",
    lng: "99.50447919999999",
    country: "TH",
    country_long: "Thailand",
    portCode: "THKAN",
    portName: "Port of Kantang",
    portContinent: "South East Asia",
  },
  {
    lat: "8.0862997",
    lng: "98.9062835",
    country: "TH",
    country_long: "Thailand",
    portCode: "THKBV",
    portName: "Port of Krabi",
    portContinent: "South East Asia",
  },
  {
    lat: "9.182964499999999",
    lng: "99.85728639999999",
    country: "TH",
    country_long: "Thailand",
    portCode: "THKHA",
    portName: "Port of Khanom",
    portContinent: "South East Asia",
  },
  {
    lat: "13.1499153",
    lng: "100.8084675",
    country: "TH",
    country_long: "Thailand",
    portCode: "THKSI",
    portName: "Ko Sichang (THKSI), Thailand, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "13.7189146",
    lng: "100.5671855",
    country: "TH",
    country_long: "Thailand",
    portCode: "THKTL",
    portName: "Klong toey",
    portContinent: "South East Asia",
  },
  {
    lat: "13.7189146",
    lng: "100.5671855",
    country: "TH",
    country_long: "Thailand",
    portCode: "THKTY",
    portName: "Khlong Toei (THKTY), Bangkok, Thailand",
    portContinent: "South East Asia",
  },
  {
    lat: "13.0579403",
    lng: "100.8919084",
    country: "TH",
    country_long: "Thailand",
    portCode: "THLCH",
    portName: "Port of Laem Chabang",
    portContinent: "South East Asia",
  },
  {
    lat: "13.7505781",
    lng: "100.7943199",
    country: "TH",
    country_long: "Thailand",
    portCode: "THLDK",
    portName: "Lat Krabang",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "THLKB",
    portName: "LAT LAKRABABG",
    portContinent: "South East Asia",
  },
  {
    lat: "13.68520947214067",
    lng: "100.783115658311",
    country: "TH",
    country_long: "Thailand",
    portCode: "THLKR",
    portName: "Lat Krabang (THLKR), Samut Prakan, Thailand",
    portContinent: "South East Asia",
  },
  {
    lat: "15.93358942004928",
    lng: "103.4492837629503",
    country: "N/A",
    country_long: "N/A",
    portCode: "THMEK",
    portName: "MEKLONG (THMEK), Thailand, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "17.8782803",
    lng: "102.7412638",
    country: "TH",
    country_long: "Thailand",
    portCode: "THNOK",
    portName: "Nong Khai",
    portContinent: "South East Asia",
  },
  {
    lat: "13.8140293",
    lng: "100.0372929",
    country: "TH",
    country_long: "Thailand",
    portCode: "THNPM",
    portName: "Nakhon Pathom",
    portContinent: "South East Asia",
  },
  {
    lat: "13.7226089",
    lng: "100.7110083",
    country: "TH",
    country_long: "Thailand",
    portCode: "THPAT",
    portName: "Bangkok PAT",
    portContinent: "South East Asia",
  },
  {
    lat: "12.6813957",
    lng: "101.2816261",
    country: "TH",
    country_long: "Thailand",
    portCode: "THPKN",
    portName: "Rayong (THPKN), Thailand, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "14.0208391",
    lng: "100.5250276",
    country: "TH",
    country_long: "Thailand",
    portCode: "THPTH",
    portName: "Pathum Thani",
    portContinent: "South East Asia",
  },
  {
    lat: "6.869516",
    lng: "101.2504562",
    country: "TH",
    country_long: "Thailand",
    portCode: "THPTN",
    portName: "Pattani (THPTN), Thailand, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "12.9235557",
    lng: "100.8824551",
    country: "TH",
    country_long: "Thailand",
    portCode: "THPYX",
    portName: "Pattaya",
    portContinent: "South East Asia",
  },
  {
    lat: "12.6813957",
    lng: "101.2816261",
    country: "TH",
    country_long: "Thailand",
    portCode: "THRYG",
    portName: "Port of Rayong",
    portContinent: "South East Asia",
  },
  {
    lat: "13.5954062",
    lng: "100.6072401",
    country: "TH",
    country_long: "Thailand",
    portCode: "THSAP",
    portName: "SAMUT PRAKAN",
    portContinent: "South East Asia",
  },
  {
    lat: "12.6736927",
    lng: "100.895357",
    country: "TH",
    country_long: "Thailand",
    portCode: "THSAT",
    portName: "Sattahip Comercial Port",
    portContinent: "South East Asia",
  },
  {
    lat: "13.5497754",
    lng: "100.2740821",
    country: "TH",
    country_long: "Thailand",
    portCode: "THSAU",
    portName: "Samut Sakhon (THSAU), Thailand, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "THSCS",
    portName: "Sahathai Coastal Seaport",
    portContinent: "South East Asia",
  },
  {
    lat: "13.5691273",
    lng: "100.817535",
    country: "TH",
    country_long: "Thailand",
    portCode: "THSCT",
    portName: "Siam Container Transport and Terminal",
    portContinent: "South East Asia",
  },
  {
    lat: "7.189765899999998",
    lng: "100.5953813",
    country: "TH",
    country_long: "Thailand",
    portCode: "THSGK",
    portName: "SONGKHLA",
    portContinent: "South East Asia",
  },
  {
    lat: "7.189765899999998",
    lng: "100.5953813",
    country: "TH",
    country_long: "Thailand",
    portCode: "THSGZ",
    portName: "Port of Songkhla",
    portContinent: "South East Asia",
  },
  {
    lat: "13.1662975",
    lng: "100.9314325",
    country: "TH",
    country_long: "Thailand",
    portCode: "THSIR",
    portName: "Sri Racha",
    portContinent: "South East Asia",
  },
  {
    lat: "13.6552381",
    lng: "100.5437766",
    country: "TH",
    country_long: "Thailand",
    portCode: "THSPS",
    portName: "Samut Prakan Sahathai Terminal",
    portContinent: "South East Asia",
  },
  {
    lat: "13.7422244",
    lng: "100.6011321",
    country: "TH",
    country_long: "Thailand",
    portCode: "THSRI",
    portName: "Sriracha Harbour",
    portContinent: "South East Asia",
  },
  {
    lat: "13.6230324",
    lng: "100.5419768",
    country: "TH",
    country_long: "Thailand",
    portCode: "THSSW",
    portName: "Suksawat Terminal",
    portContinent: "South East Asia",
  },
  {
    lat: "13.6552381",
    lng: "100.5437766",
    country: "TH",
    country_long: "Thailand",
    portCode: "THSTT",
    portName: "Sahathai Terminal",
    portContinent: "South East Asia",
  },
  {
    lat: "13.7563309",
    lng: "100.5017651",
    country: "TH",
    country_long: "Thailand",
    portCode: "THTCT",
    portName: "Bangkok TCT",
    portContinent: "South East Asia",
  },
  {
    lat: "13.6517076",
    lng: "100.5555743",
    country: "TH",
    country_long: "Thailand",
    portCode: "THTPT",
    portName: "Thai Connectivity Terminal",
    portContinent: "South East Asia",
  },
  {
    lat: "7.564483299999999",
    lng: "99.6239334",
    country: "TH",
    country_long: "Thailand",
    portCode: "THTST",
    portName: "Trang",
    portContinent: "South East Asia",
  },
  {
    lat: "13.7563309",
    lng: "100.5017651",
    country: "TH",
    country_long: "Thailand",
    portCode: "THTSU",
    portName: "Bangkok TST",
    portContinent: "South East Asia",
  },
  {
    lat: "13.5788434",
    lng: "100.591811",
    country: "TH",
    country_long: "Thailand",
    portCode: "THUCT",
    portName: "Unithai Container Terminal",
    portContinent: "South East Asia",
  },
  {
    lat: "10.0064044",
    lng: "98.6098158",
    country: "TH",
    country_long: "Thailand",
    portCode: "THUNN",
    portName: "Port of Ranong",
    portContinent: "South East Asia",
  },
  {
    lat: "9.134194899999999",
    lng: "99.3334198",
    country: "TH",
    country_long: "Thailand",
    portCode: "THURT",
    portName: "Surat Thani (THURT), Thailand, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "38.5597722",
    lng: "68.7870384",
    country: "TJ",
    country_long: "Tajikistan",
    portCode: "TJDYU",
    portName: "DUSHANBE",
    portContinent: "Central Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "TKNKU",
    portName: "Nukunonu",
    portContinent: "Oceania",
  },
  {
    lat: "-8.5568557",
    lng: "125.5603143",
    country: "TL",
    country_long: "Timor-Leste",
    portCode: "TLDIL",
    portName: "Dili (TLDIL), Dili, Timor-Leste",
    portContinent: "Asia",
  },
  {
    lat: "37.9600766",
    lng: "58.32606289999999",
    country: "TM",
    country_long: "Turkmenistan",
    portCode: "TMAGT",
    portName: "Ashgabat",
    portContinent: "Central Asia",
  },
  {
    lat: "37.9600766",
    lng: "58.32606289999999",
    country: "TM",
    country_long: "Turkmenistan",
    portCode: "TMASB",
    portName: "ASHKHABAD",
    portContinent: "Central Asia",
  },
  {
    lat: "41.5380937",
    lng: "52.5783004",
    country: "TM",
    country_long: "Turkmenistan",
    portCode: "TMBKH",
    portName: "Bekdash (TMBKH), Turkmenistan, Med",
    portContinent: "Central Asia",
  },
  {
    lat: "39.49047420000001",
    lng: "53.2869882",
    country: "TM",
    country_long: "Turkmenistan",
    portCode: "TMCHE",
    portName: "Cheleken (TMCHE), Turkmenistan, Med",
    portContinent: "Central Asia",
  },
  {
    lat: "40.0336591",
    lng: "52.9759299",
    country: "TM",
    country_long: "Turkmenistan",
    portCode: "TMKRW",
    portName: "Turkmenbashi (TMKRW), Turkmenistan, Med",
    portContinent: "Central Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "TMMYP",
    portName: "Mary",
    portContinent: "Central Asia",
  },
  {
    lat: "37.2767579",
    lng: "9.8641609",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNBIZ",
    portName: "Bizerte (TNBIZ), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "35.23369",
    lng: "11.1107854",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNCHB",
    portName: "Chebba (TNCHB), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "33.8075978",
    lng: "10.8451467",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNDJE",
    portName: "Djerba (TNDJE), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "33.888077",
    lng: "10.0975221",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNGAE",
    portName: "Gabes (TNGAE), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "36.8461602",
    lng: "11.0994667",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNKEL",
    portName: "Kelibia (TNKEL), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "35.6885837",
    lng: "10.8423248",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNKEM",
    portName: "Ksibet El Mediouni (TNKEM), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "36.8200203",
    lng: "10.3020843",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNLGN",
    portName: "LA GOULETTE (TNLGN), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "35.5024461",
    lng: "11.045721",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNMAD",
    portName: "Mahdia (TNMAD), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "34.5259235",
    lng: "10.4902451",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNMAH",
    portName: "Mahires (TNMAH), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "37.1432384",
    lng: "9.7846002",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNMBA",
    portName: "Menzel Bourguiba (TNMBA), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "36.4512893",
    lng: "10.7356634",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNNAB",
    portName: "NABEUL",
    portContinent: "North Africa",
  },
  {
    lat: "36.77159839999999",
    lng: "10.2768388",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNRDS",
    portName: "Rades (TNRDS), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "34.739822",
    lng: "10.7600196",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNSFA",
    portName: "Sfax",
    portContinent: "North Africa",
  },
  {
    lat: "35.8245029",
    lng: "10.634584",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNSUS",
    portName: "Sousse (TNSUS), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "34.2976247",
    lng: "10.0751331",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNTBX",
    portName: "Skhira (TNTBX), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "36.8064948",
    lng: "10.1815316",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNTUN",
    portName: "Tunis",
    portContinent: "North Africa",
  },
  {
    lat: "33.5041035",
    lng: "11.0881494",
    country: "TN",
    country_long: "Tunisia",
    portCode: "TNZRZ",
    portName: "Zarzis (TNZRZ), Tunisia, Med",
    portContinent: "North Africa",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "TOEUA",
    portName: "Eua Island (TOEUA), Tonga, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-18.6476779",
    lng: "-173.9836854",
    country: "TO",
    country_long: "Tonga",
    portCode: "TONEI",
    portName: "Port of Neiafu (Vavau)",
    portContinent: "Oceania",
  },
  {
    lat: "-19.811431",
    lng: "-174.3502613",
    country: "TO",
    country_long: "Tonga",
    portCode: "TOPAN",
    portName: "Port of Pangai",
    portContinent: "Oceania",
  },
  {
    lat: "-21.1393418",
    lng: "-175.204947",
    country: "TO",
    country_long: "Tonga",
    portCode: "TOTBU",
    portName: "Port of Nukualofa",
    portContinent: "Oceania",
  },
  {
    lat: "-18.622756",
    lng: "-173.9902982",
    country: "TO",
    country_long: "Tonga",
    portCode: "TOVAV",
    portName: "Vava'U",
    portContinent: "Oceania",
  },
  {
    lat: "36.9914194",
    lng: "35.3308285",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRADA",
    portName: "ADANA",
    portContinent: "Europe",
  },
  {
    lat: "41.173816",
    lng: "29.088682",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRADK",
    portName: "ANADOLUKAVAGI (TRADK), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.963745",
    lng: "35.243322",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRADY",
    portName: "Yumurtalic (TRADY), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.586764",
    lng: "26.923097",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRAKY",
    portName: "Akcay (TRAKY), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.544443",
    lng: "31.995408",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRALA",
    portName: "ALANYA",
    portContinent: "Europe",
  },
  {
    lat: "38.799561",
    lng: "26.97074",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRALI",
    portName: "Port of Aliaga",
    portContinent: "Europe",
  },
  {
    lat: "41.7470209",
    lng: "32.385537",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRAMA",
    portName: "Amasra (TRAMA), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.9767748",
    lng: "28.7110539",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRAMB",
    portName: "Ambarli",
    portContinent: "Europe",
  },
  {
    lat: "40.9767748",
    lng: "28.7110539",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRAMR",
    portName: "Ambarli",
    portContinent: "Europe",
  },
  {
    lat: "36.077286",
    lng: "32.832889",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRANA",
    portName: "Anamur (TRANA), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.43974",
    lng: "27.1455932",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRANC",
    portName: "ALSANCAK",
    portContinent: "Europe",
  },
  {
    lat: "39.9333635",
    lng: "32.8597419",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRANK",
    portName: "ANKARA",
    portContinent: "Europe",
  },
  {
    lat: "41.18269",
    lng: "31.38453",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRAPI",
    portName: "Alapli (TRAPI), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.720489",
    lng: "35.48259700000001",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRASR",
    portName: "Kayseri",
    portContinent: "Europe",
  },
  {
    lat: "40.9014284",
    lng: "27.4673351",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRASY",
    portName: "Asyaport",
    portContinent: "Europe",
  },
  {
    lat: "38.963745",
    lng: "35.243322",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRAUT",
    portName: "Autoport (TRAUT), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.8968908",
    lng: "30.7133233",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRAYT",
    portName: "Antalya (TRAYT), Antalya, Turkey",
    portContinent: "Europe",
  },
  {
    lat: "40.9831984",
    lng: "28.8536084",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRBAK",
    portName: "Bakirkoy (TRBAK), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.353707",
    lng: "27.971405",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRBDM",
    portName: "Bandirma (TRBDM), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.503055",
    lng: "26.980172",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRBHN",
    portName: "Burhaniye (TRBHN), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.9583172",
    lng: "29.0968982",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRBOS",
    portName: "Bostanci (TRBOS), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.1885281",
    lng: "29.0609636",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRBTZ",
    portName: "BURSA",
    portContinent: "Europe",
  },
  {
    lat: "37.02385599999999",
    lng: "27.4402076",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRBXN",
    portName: "Port of Bodrum",
    portContinent: "Europe",
  },
  {
    lat: "39.834843",
    lng: "26.0702",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRBZC",
    portName: "Bozcaada (TRBZC), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.3228017",
    lng: "26.3027607",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRCES",
    portName: "Port of Cesme",
    portContinent: "Europe",
  },
  {
    lat: "37.028765",
    lng: "35.8124309",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRCEY",
    portName: "Ceyhan (TRCEY), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.83520590000001",
    lng: "32.9888527",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRCID",
    portName: "Cide (TRCID), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.0481634",
    lng: "26.3013267",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRCKZ",
    portName: "Dardanelles Strait",
    portContinent: "Europe",
  },
  {
    lat: "39.0749",
    lng: "26.889199",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRDIK",
    portName: "Port of Dikili",
    portContinent: "Europe",
  },
  {
    lat: "40.776803",
    lng: "29.526321",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRDIL",
    portName: "Diliskelesi (TRDIL), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.756189",
    lng: "29.830918",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRDRC",
    portName: "Derince (TRDRC), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.84335799999999",
    lng: "36.221565",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRDYL",
    portName: "Dortyol (TRDYL), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.67712969999999",
    lng: "26.5557145",
    country: "TR",
    country_long: "Turkey",
    portCode: "TREDI",
    portName: "EDIRNE",
    portContinent: "Europe",
  },
  {
    lat: "41.279909",
    lng: "31.42026",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRERE",
    portName: "Eregli (TRERE), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "39.7667061",
    lng: "30.52563109999999",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRESK",
    portName: "ESKISEHIR",
    portContinent: "Europe",
  },
  {
    lat: "40.7654408",
    lng: "29.9408089",
    country: "TR",
    country_long: "Turkey",
    portCode: "TREYP",
    portName: "Evyap (TREYP), Izmit, Turkey",
    portContinent: "Europe",
  },
  {
    lat: "36.659246",
    lng: "29.126347",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRFET",
    portName: "Fethiye (TRFET), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.670378",
    lng: "26.757918",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRFOC",
    portName: "Foca (TRFOC), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.923207",
    lng: "28.051762",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRGCK",
    portName: "Gocek (TRGCK), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.8025157",
    lng: "29.4397941",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRGEB",
    portName: "Gebze (TRGEB), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.4139849",
    lng: "26.669997",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRGEL",
    portName: "Port of Gelibolu",
    portContinent: "Europe",
  },
  {
    lat: "40.43181999999999",
    lng: "29.155416",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRGEM",
    portName: "Gemlik",
    portContinent: "Europe",
  },
  {
    lat: "37.235031",
    lng: "27.60618",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRGUL",
    portName: "Port of Gulluk",
    portContinent: "Europe",
  },
  {
    lat: "41.005368",
    lng: "29.011491",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRHAY",
    portName: "Port of Haydarpasa",
    portContinent: "Europe",
  },
  {
    lat: "40.793768",
    lng: "29.626024",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRHER",
    portName: "Hereke (TRHER), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.39461800000001",
    lng: "41.419843",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRHOP",
    portName: "Hopa (TRHOP), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.963745",
    lng: "35.243322",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRIDS",
    portName: "Icdas (TRIDS), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.8775342",
    lng: "27.9874936",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRIGN",
    portName: "Igneada (TRIGN), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.584673",
    lng: "36.1756161",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRISK",
    portName: "Iskenderun",
    portContinent: "Europe",
  },
  {
    lat: "41.032875",
    lng: "28.993201",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRIST",
    portName: "Port of Istanbul",
    portContinent: "Europe",
  },
  {
    lat: "38.423734",
    lng: "27.142826",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRIZM",
    portName: "Izmir",
    portContinent: "Europe",
  },
  {
    lat: "40.7654408",
    lng: "29.9408089",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRIZT",
    portName: "Izmit",
    portContinent: "Europe",
  },
  {
    lat: "36.199913",
    lng: "29.63955",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRKAS",
    portName: "Kas (TRKAS), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.7654408",
    lng: "29.9408089",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRKCO",
    portName: "Kocaeli",
    portContinent: "Europe",
  },
  {
    lat: "41.171289",
    lng: "30.228028",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRKFK",
    portName: "Kefken (TRKFK), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.776382",
    lng: "29.737718",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRKFZ",
    portName: "Korfez (TRKFZ), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.9709798",
    lng: "28.68034",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRKMX",
    portName: "Kumport (TRKMX), Istanbul, Turkey",
    portContinent: "Europe",
  },
  {
    lat: "40.40929",
    lng: "27.303551",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRKRB",
    portName: "Karabiga (TRKRB), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.691274",
    lng: "29.616418",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRKRM",
    portName: "Karamursel (TRKRM), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.095615",
    lng: "30.692356",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRKSP",
    portName: "Karasu (TRKSP), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.857913",
    lng: "27.261015",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRKUS",
    portName: "Port of Kusadasi",
    portContinent: "Europe",
  },
  {
    lat: "40.9481578",
    lng: "29.1085314",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRKUY",
    portName: "Kucukyali (TRKUY), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.8746429",
    lng: "32.4931554",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRKYA",
    portName: "KONYA",
    portContinent: "Europe",
  },
  {
    lat: "40.345477",
    lng: "26.6843049",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRLAP",
    portName: "Lapseki (TRLAP), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.768561",
    lng: "29.91862",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRLMA",
    portName: "Limas (TRLMA), Izmit, Turkey",
    portContinent: "Europe",
  },
  {
    lat: "40.96836",
    lng: "28.6760842",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRMAD",
    portName: "Mardas",
    portContinent: "Europe",
  },
  {
    lat: "40.969332",
    lng: "27.95484",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRMAR",
    portName: "Marmara Ereglisi (TRMAR), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.8121041",
    lng: "34.6414811",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRMER",
    portName: "Mersin",
    portContinent: "Europe",
  },
  {
    lat: "40.9680824",
    lng: "28.6739898",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRMPT",
    portName: "Marport",
    portContinent: "Europe",
  },
  {
    lat: "36.8494873",
    lng: "28.2810707",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRMRM",
    portName: "Port of Marmaris",
    portContinent: "Europe",
  },
  {
    lat: "40.376973",
    lng: "28.883246",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRMUD",
    portName: "Mudanya (TRMUD), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "37.980774",
    lng: "38.7407793",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRNEM",
    portName: "Port of Nemrut Bay",
    portContinent: "Europe",
  },
  {
    lat: "40.9767748",
    lng: "28.7110539",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRPAM",
    portName: "Ambarli",
    portContinent: "Europe",
  },
  {
    lat: "40.879326",
    lng: "29.258135",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRPEN",
    portName: "Pendik (TRPEN), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.025511",
    lng: "40.517666",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRRIZ",
    portName: "Rize (TRRIZ), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.073678",
    lng: "28.247868",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRSIL",
    portName: "Silivri (TRSIL), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.615159",
    lng: "27.113649",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRSRK",
    portName: "Sarkoy (TRSRK), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.963745",
    lng: "35.243322",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRSRL",
    portName: "Saraylar (TRSRL), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.2797031",
    lng: "36.3360667",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRSSX",
    portName: "Samsun (TRSSX), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "36.319969",
    lng: "33.88792",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRTAS",
    portName: "Tasucu (TRTAS), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.9780919",
    lng: "27.511674",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRTEK",
    portName: "Port of Tekirdag",
    portContinent: "Europe",
  },
  {
    lat: "38.963745",
    lng: "35.243322",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRTGT",
    portName: "Toros Gubre (TRTGT), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "41.0332703",
    lng: "28.9252063",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRTOC",
    portName: "Topcular (TRTOC), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.8982305",
    lng: "29.3598782",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRTUZ",
    portName: "Tuzla (TRTUZ), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.9950872",
    lng: "39.78000249999999",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRTZX",
    portName: "Trabzon (TRTZX), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.654895",
    lng: "29.284186",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRYAL",
    portName: "Yalova (TRYAL), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "38.661288",
    lng: "39.77303",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRYAR",
    portName: "Yarimca (TRYAR), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.9776484",
    lng: "28.8188948",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRYES",
    portName: "Yesilkoy (TRYES), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.654895",
    lng: "29.284186",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRYLA",
    portName: "Yalova Town (TRYLA), Turkey, Med",
    portContinent: "Europe",
  },
  {
    lat: "40.768126",
    lng: "29.53270899999999",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRYPO",
    portName: "Yilport",
    portContinent: "Europe",
  },
  {
    lat: "40.990635",
    lng: "28.89614",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRZEY",
    portName: "Port of Zeytinburnu",
    portContinent: "Europe",
  },
  {
    lat: "41.45352099999999",
    lng: "31.78938",
    country: "TR",
    country_long: "Turkey",
    portCode: "TRZON",
    portName: "Port of Zonguldak",
    portContinent: "Europe",
  },
  {
    lat: "10.6617016",
    lng: "-61.5194431",
    country: "TT",
    country_long: "Trinidad and Tobago",
    portCode: "TTPOS",
    portName: "Port-of-Spain (TTPOS), Port of Spain, Trinidad and Tobago",
    portContinent: "North America",
  },
  {
    lat: "10.3155401",
    lng: "-61.45959930000001",
    country: "TT",
    country_long: "Trinidad and Tobago",
    portCode: "TTPTP",
    portName: "Pointe-a-Pierre Harbor",
    portContinent: "North America",
  },
  {
    lat: "10.4077274",
    lng: "-61.49243610000001",
    country: "TT",
    country_long: "Trinidad and Tobago",
    portCode: "TTPTS",
    portName: "Port of Point Lisas",
    portContinent: "North America",
  },
  {
    lat: "11.2336911",
    lng: "-60.69889089999999",
    country: "TT",
    country_long: "Trinidad and Tobago",
    portCode: "TTTAB",
    portName: "TABAGO",
    portContinent: "North America",
  },
  {
    lat: "-8.5211471",
    lng: "179.1961926",
    country: "TV",
    country_long: "Tuvalu",
    portCode: "TVFUN",
    portName: "Funafuti (TVFUN), Funafuti, Tuvalu",
    portContinent: "Oceania",
  },
  {
    lat: "22.9934184",
    lng: "120.1647112",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWAPG",
    portName: "Anping (TWAPG), Taiwan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.69781",
    lng: "120.960515",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWHOP",
    portName: "Hoping (TWHOP), Taiwan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "24.8138287",
    lng: "120.9674798",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWHSZ",
    portName: "HSIN-CHU (TWHSZ), Hsinchu, Taiwan",
    portContinent: "Asia",
  },
  {
    lat: "23.9830586",
    lng: "121.5997835",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWHUN",
    portName: "Hualien (TWHUN), Taiwan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.1275997",
    lng: "121.7391815",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWKEL",
    portName: "Keelung (Chilung)",
    portContinent: "Asia",
  },
  {
    lat: "22.59917964530427",
    lng: "120.2887677458799",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWKHH",
    portName: "Port of Kaohsiung",
    portContinent: "Asia",
  },
  {
    lat: "23.7485306",
    lng: "120.2562662",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWMLI",
    portName: "Mailiao (TWMLI), Taiwan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "23.564109",
    lng: "119.628184",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWMZG",
    portName: "Magong (TWMZG), Taiwan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.0169826",
    lng: "121.4627868",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWNTC",
    portName: "New Taipei City (TWNTC), New Taipei, Taiwan",
    portContinent: "Asia",
  },
  {
    lat: "24.594229",
    lng: "121.8533264",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWSUO",
    portName: "Suao (TWSUO), Taiwan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "25.0329636",
    lng: "121.5654268",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWTPE",
    portName: "TAIPEI (TWTPE), Taipei, Taiwan",
    portContinent: "Asia",
  },
  {
    lat: "24.1631651",
    lng: "120.6746691",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWTXG",
    portName: "Taichung",
    portContinent: "Asia",
  },
  {
    lat: "24.9553806",
    lng: "121.2300015",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWTYN",
    portName: "Taoyuan",
    portContinent: "Asia",
  },
  {
    lat: "38.6410451",
    lng: "-90.2449951",
    country: "US",
    country_long: "United States",
    portCode: "TWWTU",
    portName: "Wutu",
    portContinent: "Asia",
  },
  {
    lat: "22.8112691",
    lng: "120.2397336",
    country: "TW",
    country_long: "Taiwan",
    portCode: "TWYAN",
    portName: "Yung An (TWYAN), Taiwan, Asia",
    portContinent: "Asia",
  },
  {
    lat: "-3.3869254",
    lng: "36.6829927",
    country: "TZ",
    country_long: "Tanzania",
    portCode: "TZARK",
    portName: "Arusha",
    portContinent: "Africa",
  },
  {
    lat: "-1.3296409",
    lng: "31.8050125",
    country: "TZ",
    country_long: "Tanzania",
    portCode: "TZBKZ",
    portName: "Bukoba (TZBKZ), Tanzania, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-6.792354",
    lng: "39.2083284",
    country: "TZ",
    country_long: "Tanzania",
    portCode: "TZDAR",
    portName: "Dar es Salaam",
    portContinent: "Africa",
  },
  {
    lat: "-8.9175927",
    lng: "39.5123101",
    country: "TZ",
    country_long: "Tanzania",
    portCode: "TZKIM",
    portName: "Kilwa Masoko (TZKIM), Tanzania, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-9.987607599999999",
    lng: "39.6982485",
    country: "TZ",
    country_long: "Tanzania",
    portCode: "TZLDI",
    portName: "Lindi (TZLDI), Tanzania, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-2.4410474",
    lng: "32.9230569",
    country: "TZ",
    country_long: "Tanzania",
    portCode: "TZMWZ",
    portName: "Mwanza (TZMWZ), Tanzania, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-10.3360793",
    lng: "40.181944",
    country: "TZ",
    country_long: "Tanzania",
    portCode: "TZMYW",
    portName: "Mtwara (TZMYW), Tanzania, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-5.425380199999999",
    lng: "38.97593699999999",
    country: "TZ",
    country_long: "Tanzania",
    portCode: "TZPAN",
    portName: "Pangani (TZPAN), Tanzania, Africa",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "TZPMA",
    portName: "Pempa",
    portContinent: "Africa",
  },
  {
    lat: "-5.0888751",
    lng: "39.1023228",
    country: "TZ",
    country_long: "Tanzania",
    portCode: "TZTGT",
    portName: "Tanga",
    portContinent: "Africa",
  },
  {
    lat: "-4.8830487",
    lng: "29.667936",
    country: "TZ",
    country_long: "Tanzania",
    portCode: "TZTKQ",
    portName: "KIGOMA (TZTKQ), Kigoma, Tanzania",
    portContinent: "Africa",
  },
  {
    lat: "-6.1563171",
    lng: "39.1927234",
    country: "TZ",
    country_long: "Tanzania",
    portCode: "TZZNZ",
    portName: "Port of Zanzibar",
    portContinent: "Africa",
  },
  {
    lat: "46.1854516",
    lng: "30.3414908",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UABGD",
    portName: "Belgorod Dnestrovsky (UABGD), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "49.444433",
    lng: "32.059767",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UACKC",
    portName: "Cherkassy",
    portContinent: "Europe",
  },
  {
    lat: "48.2920787",
    lng: "25.9358367",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UACWC",
    portName: "Chernovtsy",
    portContinent: "Europe",
  },
  {
    lat: "48.464717",
    lng: "35.046183",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UADNK",
    portName: "Dnepropetrovsk (UADNK),Dniepropetrovsk, Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "49.9935",
    lng: "36.230383",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAHRK",
    portName: "Kharkov",
    portContinent: "Europe",
  },
  {
    lat: "50.4501",
    lng: "30.5234",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAIEV",
    portName: "Kiev (UAIEV), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "48.922633",
    lng: "24.711117",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAIFO",
    portName: "Ivano-Frankivs'k",
    portContinent: "Europe",
  },
  {
    lat: "46.2952236",
    lng: "30.6480855",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAILK",
    portName: "Chornomorsk",
    portContinent: "Europe",
  },
  {
    lat: "46.2952236",
    lng: "30.6480855",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAILY",
    portName: "Ilyichevsk",
    portContinent: "Europe",
  },
  {
    lat: "45.3501944",
    lng: "28.8501919",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAIZM",
    portName: "Izmail (UAIZM), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "45.357314",
    lng: "36.468293",
    country: "N/A",
    country_long: "N/A",
    portCode: "UAKEH",
    portName: "Kerch (UAKEH), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "46.635417",
    lng: "32.616867",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAKHE",
    portName: "Kherson Commerical Seaport",
    portContinent: "Europe",
  },
  {
    lat: "49.422983",
    lng: "26.987133",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAKHM",
    portName: "Khmelnytskyi",
    portContinent: "Europe",
  },
  {
    lat: "45.4650742",
    lng: "29.2480308",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAKIA",
    portName: "Kiliya (UAKIA), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "48.50793300000001",
    lng: "32.262317",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAKIR",
    portName: "Kirovohrad",
    portContinent: "Europe",
  },
  {
    lat: "49.9935",
    lng: "36.230383",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAKIV",
    portName: "KHARKIV",
    portContinent: "Europe",
  },
  {
    lat: "47.910483",
    lng: "33.391783",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAKWG",
    portName: "KRIVOY ROG",
    portContinent: "Europe",
  },
  {
    lat: "49.839683",
    lng: "24.029717",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UALVI",
    portName: "Lvov,L'viv",
    portContinent: "Europe",
  },
  {
    lat: "47.097133",
    lng: "37.543367",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAMPW",
    portName: "Mariupol (UAMPW), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "46.975033",
    lng: "31.994583",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UANIK",
    portName: "Nikolaev (UANIK), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "46.975033",
    lng: "31.994583",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UANLV",
    portName: "Nikolayev  (UANLV), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "48.379433",
    lng: "31.16558",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAOCT",
    portName: "Oktyabrsk (UAOCT), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "46.4852408",
    lng: "30.7480612",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAODS",
    portName: "Port of Odessa",
    portContinent: "Europe",
  },
  {
    lat: "47.8388",
    lng: "35.139567",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAOZH",
    portName: "Zaporozhye",
    portContinent: "Europe",
  },
  {
    lat: "49.58826699999999",
    lng: "34.551417",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAPLV",
    portName: "Poltava",
    portContinent: "Europe",
  },
  {
    lat: "47.56746",
    lng: "34.394815",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAPOL",
    portName: "Nikopol (UAPOL), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "50.6199",
    lng: "26.251617",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UARIV",
    portName: "Rivne",
    portContinent: "Europe",
  },
  {
    lat: "45.457057",
    lng: "28.2888529",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UARNI",
    portName: "Reni (UARNI), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "50.6199",
    lng: "26.251617",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UARWN",
    portName: "Rovno",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "UASVP",
    portName: "Port of Sevastopol",
    portContinent: "Europe",
  },
  {
    lat: "49.553517",
    lng: "25.594767",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UATNL",
    portName: "Ternopol",
    portContinent: "Europe",
  },
  {
    lat: "50.74723299999999",
    lng: "25.325383",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAUCK",
    portName: "Lutsk",
    portContinent: "Europe",
  },
  {
    lat: "50.9077",
    lng: "34.7981",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAUMY",
    portName: "Sumy",
    portContinent: "Europe",
  },
  {
    lat: "48.6208",
    lng: "22.287883",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAUZH",
    portName: "Uzhgorod",
    portContinent: "Europe",
  },
  {
    lat: "49.233083",
    lng: "28.468217",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAVIN",
    portName: "Vinnytsya",
    portContinent: "Europe",
  },
  {
    lat: "48.574041",
    lng: "39.307815",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAVSG",
    portName: "LUGANSK",
    portContinent: "Europe",
  },
  {
    lat: "45.40029939999999",
    lng: "29.5961736",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAVYL",
    portName: "Vylkove (UAVYL), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "UAYUZ",
    portName: "Pivdennyi",
    portContinent: "Europe",
  },
  {
    lat: "50.25465",
    lng: "28.658667",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAZKC",
    portName: "Zhytomyr",
    portContinent: "Europe",
  },
  {
    lat: "47.8388",
    lng: "35.139567",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAZPR",
    portName: "Zaporizhia (UAZPR), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "51.4982",
    lng: "31.28935",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAZUQ",
    portName: "Chernihiv",
    portContinent: "Europe",
  },
  {
    lat: "46.75152509999999",
    lng: "33.3678207",
    country: "UA",
    country_long: "Ukraine",
    portCode: "UAZUR",
    portName: "Nova Kakhovka (UAZUR), Ukraine, Med",
    portContinent: "Europe",
  },
  {
    lat: "0.0511839",
    lng: "32.463708",
    country: "UG",
    country_long: "Uganda",
    portCode: "UGEBB",
    portName: "ENTEBBE (UGEBB), Entebbe, Uganda",
    portContinent: "Africa",
  },
  {
    lat: "0.4478565999999999",
    lng: "33.2026122",
    country: "UG",
    country_long: "Uganda",
    portCode: "UGJIN",
    portName: "JINJA (UGJIN), Jinja, Uganda",
    portContinent: "Africa",
  },
  {
    lat: "0.3475964",
    lng: "32.5825197",
    country: "UG",
    country_long: "Uganda",
    portCode: "UGKLA",
    portName: "Kampala (UGKLA), Kampala, Uganda",
    portContinent: "Africa",
  },
  {
    lat: "0.3739085",
    lng: "32.9381321",
    country: "UG",
    country_long: "Uganda",
    portCode: "UGLUG",
    portName: "LUGAZI",
    portContinent: "Africa",
  },
  {
    lat: "41.49932",
    lng: "-81.6943605",
    country: "US",
    country_long: "United States",
    portCode: "UKCLV",
    portName: "CLEVELAND",
    portContinent: "Europe",
  },
  {
    lat: "19.279619",
    lng: "166.6499348",
    country: "N/A",
    country_long: "N/A",
    portCode: "UMAWK",
    portName: "Port of Wake Island",
    portContinent: "Oceania",
  },
  {
    lat: "26.9620053",
    lng: "-82.3525952",
    country: "US",
    country_long: "United States",
    portCode: "US2ND",
    portName: "ENGLEWOOD (US2ND), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "45.3070685",
    lng: "-69.03339",
    country: "US",
    country_long: "United States",
    portCode: "US8BL",
    portName: "Brownville,ME",
    portContinent: "North America",
  },
  {
    lat: "32.3795541",
    lng: "-86.2993273",
    country: "US",
    country_long: "United States",
    portCode: "USAA2",
    portName: "Alabama Pt",
    portContinent: "North America",
  },
  {
    lat: "44.3358836",
    lng: "-75.9177309",
    country: "US",
    country_long: "United States",
    portCode: "USAAX",
    portName: "Alexandria Bay, NY (USAAX), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "35.0843859",
    lng: "-106.650422",
    country: "US",
    country_long: "United States",
    portCode: "USABQ",
    portName: "Albuquerque",
    portContinent: "North America",
  },
  {
    lat: "32.4869911",
    lng: "-93.81814879999999",
    country: "US",
    country_long: "United States",
    portCode: "USACK",
    portName: "Nantucket Harbor",
    portContinent: "North America",
  },
  {
    lat: "34.1397292",
    lng: "-118.0353449",
    country: "US",
    country_long: "United States",
    portCode: "USADI",
    portName: "Arcadia",
    portContinent: "North America",
  },
  {
    lat: "61.1838744",
    lng: "-149.8697759",
    country: "US",
    country_long: "United States",
    portCode: "USADK",
    portName: "Port of Adak",
    portContinent: "North America",
  },
  {
    lat: "57.79000000000001",
    lng: "-152.4072222",
    country: "US",
    country_long: "United States",
    portCode: "USADQ",
    portName: "Port of Kodiak",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USAEI",
    portName: "Amelia (USAEI), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USAEK",
    portName: "City of Aberdeen Port",
    portContinent: "North America",
  },
  {
    lat: "41.929751",
    lng: "-88.8609238",
    country: "US",
    country_long: "United States",
    portCode: "USAET",
    portName: "Malta, IL (USAET), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "57.5004523",
    lng: "-134.582314",
    country: "US",
    country_long: "United States",
    portCode: "USAGN",
    portName: "Port of Angoon",
    portContinent: "North America",
  },
  {
    lat: "37.9838096",
    lng: "23.7275388",
    country: "GR",
    country_long: "Greece",
    portCode: "USAHN",
    portName: "ATHENS",
    portContinent: "North America",
  },
  {
    lat: "45.5399731",
    lng: "-122.4335957",
    country: "US",
    country_long: "United States",
    portCode: "USAIW",
    portName: "Fair View (USAIW), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "52.2099851",
    lng: "-174.2030272",
    country: "US",
    country_long: "United States",
    portCode: "USAKB",
    portName: "Atka (USAKB), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.9595078",
    lng: "-84.3879946",
    country: "US",
    country_long: "United States",
    portCode: "USAKD",
    portName: "Alexandria, KY (USAKD), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "42.6263032",
    lng: "-73.75803359999999",
    country: "US",
    country_long: "United States",
    portCode: "USALB",
    portName: "Port of Albany",
    portContinent: "North America",
  },
  {
    lat: "42.6183675",
    lng: "-82.531018",
    country: "US",
    country_long: "United States",
    portCode: "USALG",
    portName: "Algonac (USALG), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "45.8848164",
    lng: "-95.3776663",
    country: "US",
    country_long: "United States",
    portCode: "USALR",
    portName: "Alexandria ,MN (USALR), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "46.0899249",
    lng: "-118.2787851",
    country: "US",
    country_long: "United States",
    portCode: "USALW",
    portName: "Port of Walla Walla",
    portContinent: "North America",
  },
  {
    lat: "31.3112936",
    lng: "-92.4451371",
    country: "US",
    country_long: "United States",
    portCode: "USALX",
    portName: "Alexandria, LA (USALX), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USALZ",
    portName: "Alitak (USALZ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "61.24027779999999",
    lng: "-149.8861111",
    country: "US",
    country_long: "United States",
    portCode: "USANC",
    portName: "Port of Anchorage",
    portContinent: "North America",
  },
  {
    lat: "38.9635969",
    lng: "-76.478751",
    country: "US",
    country_long: "United States",
    portCode: "USANP",
    portName: "Port Annapolis",
    portContinent: "North America",
  },
  {
    lat: "41.1814388",
    lng: "-84.7405144",
    country: "US",
    country_long: "United States",
    portCode: "USANW",
    portName: "Antwerp, OH (USANW), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "33.3428193",
    lng: "-118.3282279",
    country: "US",
    country_long: "United States",
    portCode: "USAOU",
    portName: "Avalon (USAOU), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "45.0616794",
    lng: "-83.4327528",
    country: "US",
    country_long: "United States",
    portCode: "USAPN",
    portName: "Alpena (USAPN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "33.9842768",
    lng: "-88.48809899999999",
    country: "US",
    country_long: "United States",
    portCode: "USARY",
    portName: "Port of Amory",
    portContinent: "North America",
  },
  {
    lat: "41.8650534",
    lng: "-80.7898089",
    country: "US",
    country_long: "United States",
    portCode: "USASF",
    portName: "Port of Ashtabula",
    portContinent: "North America",
  },
  {
    lat: "46.1868251",
    lng: "-123.8592244",
    country: "US",
    country_long: "United States",
    portCode: "USAST",
    portName: "Port of Astoria",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USASX",
    portName: "Ashland Harbor",
    portContinent: "North America",
  },
  {
    lat: "33.7489954",
    lng: "-84.3879824",
    country: "US",
    country_long: "United States",
    portCode: "USATL",
    portName: "Atlanta",
    portContinent: "North America",
  },
  {
    lat: "44.2619309",
    lng: "-88.41538469999999",
    country: "US",
    country_long: "United States",
    portCode: "USATW",
    portName: "APPLETON",
    portContinent: "North America",
  },
  {
    lat: "30.267153",
    lng: "-97.7430608",
    country: "US",
    country_long: "United States",
    portCode: "USAUS",
    portName: "Austin",
    portContinent: "North America",
  },
  {
    lat: "33.4355977",
    lng: "-112.3496021",
    country: "US",
    country_long: "United States",
    portCode: "USAVD",
    portName: "Port of Avondale",
    portContinent: "North America",
  },
  {
    lat: "25.9564812",
    lng: "-80.1392121",
    country: "US",
    country_long: "United States",
    portCode: "USAVU",
    portName: "Aventura (USAVU), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "36.0775587",
    lng: "-86.0333222",
    country: "US",
    country_long: "United States",
    portCode: "USAWJ",
    portName: "Alexandria, TN  (USAWJ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.0889523",
    lng: "-82.61238720000001",
    country: "US",
    country_long: "United States",
    portCode: "USAXH",
    portName: "Alexandria, OH (USAXH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.8048355",
    lng: "-77.0469214",
    country: "US",
    country_long: "United States",
    portCode: "USAXR",
    portName: "Alexandria (USAXR), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.5884373",
    lng: "-73.65790799999999",
    country: "US",
    country_long: "United States",
    portCode: "USB2W",
    portName: "Long Beach, NY (USB2W), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.1534294",
    lng: "-74.5762713",
    country: "US",
    country_long: "United States",
    portCode: "USB59",
    portName: "Hamburg, NJ  (USB59), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "33.225724",
    lng: "-91.79755449999999",
    country: "US",
    country_long: "United States",
    portCode: "USB61",
    portName: "Hamburg, AR (USB61), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USBAF",
    portName: "Westfield (USBAF), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USBAL",
    portName: "Port of Baltimore",
    portContinent: "North America",
  },
  {
    lat: "40.6687141",
    lng: "-74.1143091",
    country: "US",
    country_long: "United States",
    portCode: "USBAY",
    portName: "Port of Bayonne",
    portContinent: "North America",
  },
  {
    lat: "26.7490152",
    lng: "-82.2616717",
    country: "US",
    country_long: "United States",
    portCode: "USBCG",
    portName: "Port of Boca Grande",
    portContinent: "North America",
  },
  {
    lat: "28.8628319",
    lng: "-96.0277567",
    country: "US",
    country_long: "United States",
    portCode: "USBCY",
    portName: "Port of Bay City",
    portContinent: "North America",
  },
  {
    lat: "41.1792258",
    lng: "-73.1894384",
    country: "US",
    country_long: "United States",
    portCode: "USBDB",
    portName: "Bridgeport (USBDB), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.1792258",
    lng: "-73.1894384",
    country: "US",
    country_long: "United States",
    portCode: "USBDR",
    portName: "Port of Bridgeport",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USBEB",
    portName: "Beaver (USBEB), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "42.1167065",
    lng: "-86.45418939999999",
    country: "US",
    country_long: "United States",
    portCode: "USBEH",
    portName: "Benton Harbor (USBEH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "44.4308989",
    lng: "-68.9904154",
    country: "US",
    country_long: "United States",
    portCode: "USBEL",
    portName: "Belfast Harbor",
    portContinent: "North America",
  },
  {
    lat: "60.7916994",
    lng: "-161.7484931",
    country: "US",
    country_long: "United States",
    portCode: "USBET",
    portName: "Port of Bethel",
    portContinent: "North America",
  },
  {
    lat: "35.4206477",
    lng: "-86.7026253",
    country: "US",
    country_long: "United States",
    portCode: "USBFM",
    portName: "Belfast, TN (USBFM), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "32.4315813",
    lng: "-80.6698286",
    country: "US",
    country_long: "United States",
    portCode: "USBFO",
    portName: "Beaufort (USBFO), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "44.8016128",
    lng: "-68.7712257",
    country: "US",
    country_long: "United States",
    portCode: "USBGR",
    portName: "Bangor Harbor",
    portContinent: "North America",
  },
  {
    lat: "44.3876119",
    lng: "-68.2039123",
    country: "US",
    country_long: "United States",
    portCode: "USBHB",
    portName: "Bar Harbor Harbor",
    portContinent: "North America",
  },
  {
    lat: "33.5185892",
    lng: "-86.8103567",
    country: "US",
    country_long: "United States",
    portCode: "USBHH",
    portName: "Birmingham",
    portContinent: "North America",
  },
  {
    lat: "42.5990714",
    lng: "-114.7594946",
    country: "US",
    country_long: "United States",
    portCode: "USBHL",
    portName: "Buhl",
    portContinent: "North America",
  },
  {
    lat: "33.5185892",
    lng: "-86.8103567",
    country: "US",
    country_long: "United States",
    portCode: "USBHM",
    portName: "Birmingham, AL",
    portContinent: "North America",
  },
  {
    lat: "45.6467627",
    lng: "-85.5520726",
    country: "US",
    country_long: "United States",
    portCode: "USBI2",
    portName: "Beaver Island (USBI2), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.172137",
    lng: "-71.5576045",
    country: "US",
    country_long: "United States",
    portCode: "USBID",
    portName: "Block Island (USBID), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "47.62487910000001",
    lng: "-122.5210008",
    country: "US",
    country_long: "United States",
    portCode: "USBII",
    portName: "Bainbridge Island (USBII), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "30.3960318",
    lng: "-88.88530779999999",
    country: "US",
    country_long: "United States",
    portCode: "USBIX",
    portName: "Biloxi Port",
    portContinent: "North America",
  },
  {
    lat: "30.3943588",
    lng: "-91.2537198",
    country: "US",
    country_long: "United States",
    portCode: "USBL6",
    portName: "Brusly (USBL6), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "48.7575426",
    lng: "-122.4953358",
    country: "US",
    country_long: "United States",
    portCode: "USBLI",
    portName: "Port of Bellingham",
    portContinent: "North America",
  },
  {
    lat: "36.1626638",
    lng: "-86.7816016",
    country: "US",
    country_long: "United States",
    portCode: "USBNA",
    portName: "Port of Nashville",
    portContinent: "North America",
  },
  {
    lat: "41.6258708",
    lng: "-87.1333676",
    country: "US",
    country_long: "United States",
    portCode: "USBNB",
    portName: "Burns Harbor (USBNB), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.0451698",
    lng: "-122.1379547",
    country: "US",
    country_long: "United States",
    portCode: "USBNC",
    portName: "Port of Benicia",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USBNP",
    portName: "Brayton Point (USBNP), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.9550296",
    lng: "-87.94006569999999",
    country: "US",
    country_long: "United States",
    portCode: "USBNV",
    portName: "BENSENVILLE",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USBOS",
    portName: "Port of Boston",
    portContinent: "North America",
  },
  {
    lat: "40.6781784",
    lng: "-73.9441579",
    country: "US",
    country_long: "United States",
    portCode: "USBOY",
    portName: "Brooklyn, Ny",
    portContinent: "North America",
  },
  {
    lat: "30.0793963",
    lng: "-94.0923294",
    country: "US",
    country_long: "United States",
    portCode: "USBPT",
    portName: "Port of Beaumont",
    portContinent: "North America",
  },
  {
    lat: "25.9508155",
    lng: "-97.4104615",
    country: "US",
    country_long: "United States",
    portCode: "USBRO",
    portName: "Port of Brownsville",
    portContinent: "North America",
  },
  {
    lat: "30.4346355",
    lng: "-91.2014966",
    country: "US",
    country_long: "United States",
    portCode: "USBTR",
    portName: "Port of Greater Baton Rouge",
    portContinent: "North America",
  },
  {
    lat: "42.88644679999999",
    lng: "-78.8783689",
    country: "US",
    country_long: "United States",
    portCode: "USBUF",
    portName: "Port of Buffalo",
    portContinent: "North America",
  },
  {
    lat: "40.1250725",
    lng: "-79.8664356",
    country: "US",
    country_long: "United States",
    portCode: "USBVN",
    portName: "Belle Vernon (USBVN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "45.1607987",
    lng: "-93.23494889999999",
    country: "US",
    country_long: "United States",
    portCode: "USBWS",
    portName: "Blaine (USBWS), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "45.2166751",
    lng: "-85.01394189999999",
    country: "US",
    country_long: "United States",
    portCode: "USBYZ",
    portName: "Boyne City (USBYZ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "30.3829771",
    lng: "-88.2383363",
    country: "US",
    country_long: "United States",
    portCode: "USC2N",
    portName: "Coden (USC2N), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.2619444",
    lng: "-95.8608333",
    country: "US",
    country_long: "United States",
    portCode: "USCBF",
    portName: "Council Bluffs, IA",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USCCN",
    portName: "Clinton (USCCN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.4807905",
    lng: "-82.6834453",
    country: "US",
    country_long: "United States",
    portCode: "USCD9",
    portName: "Cedar Point (USCD9), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCDE",
    portName: "Port of Camden",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCDV",
    portName: "Port of Cordova",
    portContinent: "North America",
  },
  {
    lat: "41.9626741",
    lng: "-80.553743",
    country: "US",
    country_long: "United States",
    portCode: "USCDY",
    portName: "Port of Conneaut",
    portContinent: "North America",
  },
  {
    lat: "35.2872952",
    lng: "-84.7582743",
    country: "US",
    country_long: "United States",
    portCode: "USCEH",
    portName: "Charleston, TN (USCEH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USCEN",
    portName: "Convent (USCEN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCGA",
    portName: "Port of Craig",
    portContinent: "North America",
  },
  {
    lat: "42.3670529",
    lng: "-71.0958314",
    country: "US",
    country_long: "United States",
    portCode: "USCGE",
    portName: "Port of Cambridge",
    portContinent: "North America",
  },
  {
    lat: "38.9351125",
    lng: "-74.90600529999999",
    country: "US",
    country_long: "United States",
    portCode: "USCGF",
    portName: "Cape May (USCGF), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.9896967",
    lng: "-76.93776",
    country: "US",
    country_long: "United States",
    portCode: "USCGS",
    portName: "College Park",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USCGX",
    portName: "Carthage (USCGX), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "35.0457984",
    lng: "-85.3093995",
    country: "US",
    country_long: "United States",
    portCode: "USCHA",
    portName: "Chattanooga (USCHA), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "45.64695630000001",
    lng: "-84.4744795",
    country: "US",
    country_long: "United States",
    portCode: "USCHG",
    portName: "Port of Cheboygan",
    portContinent: "North America",
  },
  {
    lat: "41.4174728",
    lng: "-88.21690269999999",
    country: "US",
    country_long: "United States",
    portCode: "USCHH",
    portName: "Channahon (USCHH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.7286146",
    lng: "-87.5355008",
    country: "US",
    country_long: "United States",
    portCode: "USCHI",
    portName: "Port of Chicago",
    portContinent: "North America",
  },
  {
    lat: "38.0293059",
    lng: "-78.47667810000002",
    country: "US",
    country_long: "United States",
    portCode: "USCHO",
    portName: "Charlottesville",
    portContinent: "North America",
  },
  {
    lat: "32.7814987",
    lng: "-79.9238235",
    country: "US",
    country_long: "United States",
    portCode: "USCHS",
    portName: "Port Charleston",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCHT",
    portName: "Port of Chester",
    portContinent: "North America",
  },
  {
    lat: "30.8774132",
    lng: "-84.2012897",
    country: "US",
    country_long: "United States",
    portCode: "USCIR",
    portName: "Cairo (USCIR), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "28.902479",
    lng: "-82.59260119999999",
    country: "US",
    country_long: "United States",
    portCode: "USCJF",
    portName: "Crystal River (USCJF), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCKL",
    portName: "Port of Clarkston",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCKV",
    portName: "Clarksville (USCKV), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCLE",
    portName: "Port of Cleveland",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCLT",
    portName: "CHARLOTTE (USCLT), Charlotte, United States of America",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCLY",
    portName: "Port of Clayton",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCMH",
    portName: "COLUMBUS (USCMH), Columbus, United States of America",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCMS",
    portName: "Camas (USCMS), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCMU",
    portName: "Cameron (USCMU), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCND",
    portName: "CANANDAIGUA",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCOB",
    portName: "Oregon International Port of Coos Bay",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCP4",
    portName: "Cherry Point (USCP4), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCP6",
    portName: "Cove Point (USCP6), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCPF",
    portName: "Chippewa Falls",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCQB",
    portName: "Cuba (Mo)  (USCQB), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCRM",
    portName: "Port of Crockett",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCRP",
    portName: "Port of Corpus Christi",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCRW",
    portName: "Charleston, WV (USCRW), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCSI",
    portName: "Charleston, IL (USCSI), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCSX",
    portName: "Port of Charlevoix",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCSZ",
    portName: "Charleston, AR (USCSZ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "39.09981399999999",
    lng: "-84.511977",
    country: "US",
    country_long: "United States",
    portCode: "USCVG",
    portName: "Port of Cincinnati",
    portContinent: "North America",
  },
  {
    lat: "41.49932",
    lng: "-81.6943605",
    country: "US",
    country_long: "United States",
    portCode: "USCWX",
    portName: "Cleveland (USCWX), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USCXE",
    portName: "Clarita",
    portContinent: "North America",
  },
  {
    lat: "32.7766642",
    lng: "-96.79698789999999",
    country: "US",
    country_long: "United States",
    portCode: "USDAL",
    portName: "Dallas",
    portContinent: "North America",
  },
  {
    lat: "39.7589478",
    lng: "-84.1916069",
    country: "US",
    country_long: "United States",
    portCode: "USDAY",
    portName: "DAYTON",
    portContinent: "North America",
  },
  {
    lat: "30.4263092",
    lng: "-88.89086379999999",
    country: "US",
    country_long: "United States",
    portCode: "USDBE",
    portName: "Diberville (USDBE), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.3717714",
    lng: "-75.2015627",
    country: "US",
    country_long: "United States",
    portCode: "USDBN",
    portName: "Dublin, PA (USDBN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "42.506348",
    lng: "-90.6677347",
    country: "US",
    country_long: "United States",
    portCode: "USDBQ",
    portName: "Dubuque (USDBQ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "32.085143",
    lng: "-98.3419882",
    country: "US",
    country_long: "United States",
    portCode: "USDBT",
    portName: "Dublin, TX (USDBT), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "39.5778901",
    lng: "-75.588815",
    country: "US",
    country_long: "United States",
    portCode: "USDCI",
    portName: "Delaware City (USDCI), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "33.7748275",
    lng: "-84.2963123",
    country: "US",
    country_long: "United States",
    portCode: "USDCU",
    portName: "Decatur (USDCU), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "39.2506633",
    lng: "-76.5205184",
    country: "US",
    country_long: "United States",
    portCode: "USDDQ",
    portName: "Dundalk (USDDQ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "39.7392358",
    lng: "-104.990251",
    country: "US",
    country_long: "United States",
    portCode: "USDEN",
    portName: "DENVER (USDEN), Denver, United States of America",
    portContinent: "North America",
  },
  {
    lat: "42.327535",
    lng: "-83.0412827",
    country: "US",
    country_long: "United States",
    portCode: "USDET",
    portName: "Detroit/Wayne County Port Authority",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USDKK",
    portName: "Dunkirk (USDKK), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USDLG",
    portName: "Port of Dillingham",
    portContinent: "North America",
  },
  {
    lat: "46.7585393",
    lng: "-92.10789489999999",
    country: "US",
    country_long: "United States",
    portCode: "USDLH",
    portName: "Port of Duluth",
    portContinent: "North America",
  },
  {
    lat: "34.4165505",
    lng: "-79.3711579",
    country: "US",
    country_long: "United States",
    portCode: "USDLN",
    portName: "Dillon, SC",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USDOG",
    portName: "Douglas (USDOG), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "33.4672256",
    lng: "-117.6981014",
    country: "US",
    country_long: "United States",
    portCode: "USDPT",
    portName: "Dana Point (USDPT), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "46.0205456",
    lng: "-83.7331233",
    country: "US",
    country_long: "United States",
    portCode: "USDRE",
    portName: "Drummond Island (USDRE), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USDRF",
    portName: "Drift River Marine Terminal",
    portContinent: "North America",
  },
  {
    lat: "41.5868353",
    lng: "-93.6249593",
    country: "US",
    country_long: "United States",
    portCode: "USDSM",
    portName: "Des Moines",
    portContinent: "North America",
  },
  {
    lat: "37.7159396",
    lng: "-121.9101221",
    country: "US",
    country_long: "United States",
    portCode: "USDU3",
    portName: "Dublin, CA (USDU3), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "32.5404447",
    lng: "-82.90375399999999",
    country: "US",
    country_long: "United States",
    portCode: "USDUI",
    portName: "Dublin, GA (USDUI), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "53.8945552",
    lng: "-166.5400929",
    country: "US",
    country_long: "United States",
    portCode: "USDUT",
    portName: "Dutch Harbor (USDUT), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.1056784",
    lng: "-80.6853433",
    country: "US",
    country_long: "United States",
    portCode: "USDUV",
    portName: "Dublin, VA (USDUV), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.5236437",
    lng: "-90.5776367",
    country: "US",
    country_long: "United States",
    portCode: "USDVN",
    portName: "Davenport (USDVN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.6127411",
    lng: "-71.474003",
    country: "US",
    country_long: "United States",
    portCode: "USDVV",
    portName: "Davisville (USDVV), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "39.7589478",
    lng: "-84.1916069",
    country: "US",
    country_long: "United States",
    portCode: "USDY2",
    portName: "Dayton (USDY2), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.6639916",
    lng: "-74.2107006",
    country: "US",
    country_long: "United States",
    portCode: "USEBH",
    portName: "Elizabeth (USEBH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "36.548434",
    lng: "-82.5618186",
    country: "US",
    country_long: "United States",
    portCode: "USECE",
    portName: "kingsport",
    portContinent: "North America",
  },
  {
    lat: "41.6683137",
    lng: "-87.44013489999999",
    country: "US",
    country_long: "United States",
    portCode: "USECH",
    portName: "Indiana Harbor",
    portContinent: "North America",
  },
  {
    lat: "58.21373699999999",
    lng: "-157.3742534",
    country: "US",
    country_long: "United States",
    portCode: "USEGX",
    portName: "Egegik (USEGX), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.8083216",
    lng: "-124.162891",
    country: "US",
    country_long: "United States",
    portCode: "USEKA",
    portName: "Humboldt Bay Harbor",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USEL3",
    portName: "Ellwood (USEL3), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "31.7618778",
    lng: "-106.4850217",
    country: "US",
    country_long: "United States",
    portCode: "USELP",
    portName: "El Paso, TX",
    portContinent: "North America",
  },
  {
    lat: "33.9191799",
    lng: "-118.4164652",
    country: "US",
    country_long: "United States",
    portCode: "USELS",
    portName: "El Segundo Off-Shore Oil Terminal",
    portContinent: "North America",
  },
  {
    lat: "60.55491139999999",
    lng: "-151.2597037",
    country: "US",
    country_long: "United States",
    portCode: "USENA",
    portName: "Port of Kenai",
    portContinent: "North America",
  },
  {
    lat: "64.5633957",
    lng: "-149.0938987",
    country: "US",
    country_long: "United States",
    portCode: "USENN",
    portName: "Port of Nenana",
    portContinent: "North America",
  },
  {
    lat: "42.5910555",
    lng: "-87.8212095",
    country: "US",
    country_long: "United States",
    portCode: "USENW",
    portName: "Port of Kenosha",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USEOM",
    portName: "Welcome (USEOM), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "47.8106521",
    lng: "-122.3773552",
    country: "US",
    country_long: "United States",
    portCode: "USEOW",
    portName: "Edmonds (USEOW), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USEPB",
    portName: "Galliano (USEPB), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USEPE",
    portName: "Empire (USEPE), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "44.9076241",
    lng: "-66.984036",
    country: "US",
    country_long: "United States",
    portCode: "USEPM",
    portName: "Port of Eastport",
    portContinent: "North America",
  },
  {
    lat: "42.1394958",
    lng: "-80.0870513",
    country: "US",
    country_long: "United States",
    portCode: "USERI",
    portName: "Port of Erie",
    portContinent: "North America",
  },
  {
    lat: "45.74524659999999",
    lng: "-87.06458010000001",
    country: "US",
    country_long: "United States",
    portCode: "USESC",
    portName: "Port of Escanaba",
    portContinent: "North America",
  },
  {
    lat: "38.624514",
    lng: "-90.1506465",
    country: "US",
    country_long: "United States",
    portCode: "USESL",
    portName: "East Saint Louis, IL",
    portContinent: "North America",
  },
  {
    lat: "42.0039178",
    lng: "-87.9703461",
    country: "US",
    country_long: "United States",
    portCode: "USEVI",
    portName: "Elk Grove Village",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USEVS",
    portName: "Stevenson (USEVS), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.9715592",
    lng: "-87.5710898",
    country: "US",
    country_long: "United States",
    portCode: "USEVV",
    portName: "Evansville (USEVV), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.5364846",
    lng: "-80.1844243",
    country: "US",
    country_long: "United States",
    portCode: "USEWC",
    portName: "Sewickley (USEWC), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.6895314",
    lng: "-74.1744624",
    country: "US",
    country_long: "United States",
    portCode: "USEWR",
    portName: "Newark(USEWR), New Jersey, United States",
    portContinent: "North America",
  },
  {
    lat: "58.4186817",
    lng: "-135.4422896",
    country: "US",
    country_long: "United States",
    portCode: "USEXI",
    portName: "Port of Excursion Inlet",
    portContinent: "North America",
  },
  {
    lat: "24.561146",
    lng: "-81.8012645",
    country: "US",
    country_long: "United States",
    portCode: "USEYW",
    portName: "Port of Key West",
    portContinent: "North America",
  },
  {
    lat: "-33.9608369",
    lng: "25.6022423",
    country: "ZA",
    country_long: "South Africa",
    portCode: "USEZA",
    portName: "Port of Elizabeth",
    portContinent: "North America",
  },
  {
    lat: "40.1795537",
    lng: "-74.85516439999999",
    country: "US",
    country_long: "United States",
    portCode: "USFAH",
    portName: "Fairless Hills (USFAH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.7014912",
    lng: "-71.1550451",
    country: "US",
    country_long: "United States",
    portCode: "USFAV",
    portName: "Port of Fall River",
    portContinent: "North America",
  },
  {
    lat: "33.5670562",
    lng: "-84.5810418",
    country: "US",
    country_long: "United States",
    portCode: "USFBN",
    portName: "Atlanta Fairburn",
    portContinent: "North America",
  },
  {
    lat: "37.5585465",
    lng: "-122.2710788",
    country: "US",
    country_long: "United States",
    portCode: "USFCT",
    portName: "Foster City, CA  (USFCT), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "48.84838389999999",
    lng: "-122.702968",
    country: "US",
    country_long: "United States",
    portCode: "USFDT",
    portName: "Alcoa Intalco Works",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USFEB",
    portName: "Port of Fernandina",
    portContinent: "North America",
  },
  {
    lat: "30.5229719",
    lng: "-87.9033261",
    country: "US",
    country_long: "United States",
    portCode: "USFH3",
    portName: "Fairhope (USFH3), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "26.1224386",
    lng: "-80.13731740000001",
    country: "US",
    country_long: "United States",
    portCode: "USFLL",
    portName: "Fort Lauderdale (USFLL), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USFLN",
    portName: "Franklin",
    portContinent: "North America",
  },
  {
    lat: "38.2009055",
    lng: "-84.87328350000001",
    country: "US",
    country_long: "United States",
    portCode: "USFNF",
    portName: "Frankfort (USFNF), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "29.10556",
    lng: "-90.19444",
    country: "US",
    country_long: "United States",
    portCode: "USFOC",
    portName: "Fourchon (USFOC), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.156998",
    lng: "-83.41687019999999",
    country: "US",
    country_long: "United States",
    portCode: "USFOT",
    portName: "Fostoria ,OH  (USFOT), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "28.9541368",
    lng: "-95.35966169999999",
    country: "US",
    country_long: "United States",
    portCode: "USFPO",
    portName: "Port Freeport",
    portContinent: "North America",
  },
  {
    lat: "27.4467056",
    lng: "-80.3256056",
    country: "US",
    country_long: "United States",
    portCode: "USFPR",
    portName: "Port of Fort Pierce",
    portContinent: "North America",
  },
  {
    lat: "41.7500431",
    lng: "-81.2739916",
    country: "US",
    country_long: "United States",
    portCode: "USFPT",
    portName: "Fairport Harbor",
    portContinent: "North America",
  },
  {
    lat: "48.5342662",
    lng: "-123.0171242",
    country: "US",
    country_long: "United States",
    portCode: "USFRD",
    portName: "Friday Harbor (USFRD), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.9181671",
    lng: "-78.1944446",
    country: "US",
    country_long: "United States",
    portCode: "USFRR",
    portName: "FRONT ROYAL (USFRR), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "44.4066108",
    lng: "-122.6488328",
    country: "US",
    country_long: "United States",
    portCode: "USFSE",
    portName: "Foster, OR (USFSE), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.6376043",
    lng: "-70.90364869999999",
    country: "US",
    country_long: "United States",
    portCode: "USFVH",
    portName: "Fairhaven (USFVH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "32.7554883",
    lng: "-97.3307658",
    country: "US",
    country_long: "United States",
    portCode: "USFWT",
    portName: "Fort Worth",
    portContinent: "North America",
  },
  {
    lat: "46.4734497",
    lng: "-117.6126556",
    country: "US",
    country_long: "United States",
    portCode: "USGAF",
    portName: "Port of Garfield",
    portContinent: "North America",
  },
  {
    lat: "36.0726354",
    lng: "-79.7919754",
    country: "US",
    country_long: "United States",
    portCode: "USGBO",
    portName: "GREENSBORO (USGBO), Greensboro, United States of America",
    portContinent: "North America",
  },
  {
    lat: "30.3571438",
    lng: "-87.1638574",
    country: "US",
    country_long: "United States",
    portCode: "USGBZ",
    portName: "Gulf Breeze (USGBZ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "33.376834",
    lng: "-79.2944964",
    country: "US",
    country_long: "United States",
    portCode: "USGGE",
    portName: "Port of Georgetown",
    portContinent: "North America",
  },
  {
    lat: "40.9276614",
    lng: "-100.1606978",
    country: "US",
    country_long: "United States",
    portCode: "USGGN",
    portName: "Gothenburg, NE (USGGN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "43.0630734",
    lng: "-86.22838639999999",
    country: "US",
    country_long: "United States",
    portCode: "USGHN",
    portName: "Port of Grand Haven",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USGHR",
    portName: "Port of Grays Harbor",
    portContinent: "North America",
  },
  {
    lat: "28.0222435",
    lng: "-81.7328567",
    country: "US",
    country_long: "United States",
    portCode: "USGIF",
    portName: "Winter Haven, Fl",
    portContinent: "North America",
  },
  {
    lat: "29.6516344",
    lng: "-82.32482619999999",
    country: "US",
    country_long: "United States",
    portCode: "USGIY",
    portName: "Gainesville",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USGLC",
    portName: "Gloucester Marine Terminal",
    portContinent: "North America",
  },
  {
    lat: "33.3602081",
    lng: "-91.1089026",
    country: "US",
    country_long: "United States",
    portCode: "USGLH",
    portName: "Port of Greenville",
    portContinent: "North America",
  },
  {
    lat: "42.5959288",
    lng: "-70.668933",
    country: "US",
    country_long: "United States",
    portCode: "USGLO",
    portName: "Gloucester Harbor",
    portContinent: "North America",
  },
  {
    lat: "29.3065514",
    lng: "-94.79707649999999",
    country: "US",
    country_long: "United States",
    portCode: "USGLS",
    portName: "Port of Galveston",
    portContinent: "North America",
  },
  {
    lat: "29.3791112",
    lng: "-90.2600786",
    country: "US",
    country_long: "United States",
    portCode: "USGM3",
    portName: "Golden Meadow (USGM3), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USGNU",
    portName: "Goodnews (USGNU), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "30.3631995",
    lng: "-89.0970289",
    country: "US",
    country_long: "United States",
    portCode: "USGPT",
    portName: "Port of Gulfport",
    portContinent: "North America",
  },
  {
    lat: "44.4757683",
    lng: "-88.05497659999999",
    country: "US",
    country_long: "United States",
    portCode: "USGRB",
    portName: "Port of Green Bay",
    portContinent: "North America",
  },
  {
    lat: "45.0522366",
    lng: "-82.4846115",
    country: "N/A",
    country_long: "N/A",
    portCode: "USGRL",
    portName: "Great Lakes (USGRL), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.15783769999999",
    lng: "-80.08867029999999",
    country: "US",
    country_long: "United States",
    portCode: "USGRO",
    portName: "Grove City, PA",
    portContinent: "North America",
  },
  {
    lat: "42.9633599",
    lng: "-85.6680863",
    country: "US",
    country_long: "United States",
    portCode: "USGRR",
    portName: "Grand rapids",
    portContinent: "North America",
  },
  {
    lat: "30.0476399",
    lng: "-90.6899483",
    country: "US",
    country_long: "United States",
    portCode: "USGRY",
    portName: "Port of Gramercy",
    portContinent: "North America",
  },
  {
    lat: "34.9387279",
    lng: "-82.2270568",
    country: "US",
    country_long: "United States",
    portCode: "USGSP",
    portName: "Greer",
    portContinent: "North America",
  },
  {
    lat: "58.4126017",
    lng: "-135.7389018",
    country: "US",
    country_long: "United States",
    portCode: "USGST",
    portName: "Gustavus Harbor",
    portContinent: "North America",
  },
  {
    lat: "34.3581473",
    lng: "-86.29470409999999",
    country: "US",
    country_long: "United States",
    portCode: "USGUE",
    portName: "Guntersville (USGUE), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "42.7899897",
    lng: "-91.1029068",
    country: "US",
    country_long: "United States",
    portCode: "USGUG",
    portName: "Guttenberg (USGUG), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "34.9387279",
    lng: "-82.2270568",
    country: "US",
    country_long: "United States",
    portCode: "USGXX",
    portName: "Greer, SC",
    portContinent: "North America",
  },
  {
    lat: "41.6020403",
    lng: "-87.3371523",
    country: "US",
    country_long: "United States",
    portCode: "USGYY",
    portName: "Gary Harbor (USGYY), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "21.5927614",
    lng: "-158.1034112",
    country: "US",
    country_long: "United States",
    portCode: "USHA7",
    portName: "Haleiwa (USHA7), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.0298687",
    lng: "-76.34522179999999",
    country: "US",
    country_long: "United States",
    portCode: "USHAX",
    portName: "Hampton (USHAX), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "43.8447369",
    lng: "-82.6513186",
    country: "US",
    country_long: "United States",
    portCode: "USHB4",
    portName: "Harbor Beach (USHB4), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.5556484",
    lng: "-75.98187720000001",
    country: "US",
    country_long: "United States",
    portCode: "USHBB",
    portName: "Hamburg, PA (USHBB), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.6044458",
    lng: "-95.65777109999999",
    country: "US",
    country_long: "United States",
    portCode: "USHBG",
    portName: "Hamburg, IA (USHBG), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "42.4641977",
    lng: "-83.8510786",
    country: "US",
    country_long: "United States",
    portCode: "USHBH",
    portName: "Hamburg, MI (USHBH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "43.3211788",
    lng: "-76.5788338",
    country: "US",
    country_long: "United States",
    portCode: "USHBP",
    portName: "Hannibal (USHBP), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "39.5492792",
    lng: "-76.09161700000001",
    country: "US",
    country_long: "United States",
    portCode: "USHDC",
    portName: "Havre De Grace (USHDC), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.0839883",
    lng: "-72.3559166",
    country: "US",
    country_long: "United States",
    portCode: "USHDH",
    portName: "Shelter Island Heigh (USHDH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "31.5604442",
    lng: "-91.40317100000001",
    country: "US",
    country_long: "United States",
    portCode: "USHEZ",
    portName: "Natchez (USHEZ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.7658043",
    lng: "-72.6733723",
    country: "US",
    country_long: "United States",
    portCode: "USHFD",
    portName: "Hartford, CT",
    portContinent: "North America",
  },
  {
    lat: "44.7330546",
    lng: "-93.9662153",
    country: "US",
    country_long: "United States",
    portCode: "USHHB",
    portName: "Hamburg, MN (USHHB), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "43.0256259",
    lng: "-74.9859889",
    country: "US",
    country_long: "United States",
    portCode: "USHHY",
    portName: "Herkimer",
    portContinent: "North America",
  },
  {
    lat: "26.1003547",
    lng: "-98.2630684",
    country: "US",
    country_long: "United States",
    portCode: "USHID",
    portName: "Hidalgo, Texas",
    portContinent: "North America",
  },
  {
    lat: "42.7875235",
    lng: "-86.1089301",
    country: "US",
    country_long: "United States",
    portCode: "USHLM",
    portName: "Holland (USHLM), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "44.3633173",
    lng: "-98.21425719999999",
    country: "US",
    country_long: "United States",
    portCode: "USHN9",
    portName: "Huron (USHN9), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "35.2194549",
    lng: "-75.6903492",
    country: "US",
    country_long: "United States",
    portCode: "USHNC",
    portName: "Hatteras (USHNC), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "58.11005059999999",
    lng: "-135.4445",
    country: "US",
    country_long: "United States",
    portCode: "USHNH",
    portName: "Port of Hoonah",
    portContinent: "North America",
  },
  {
    lat: "21.28371",
    lng: "-157.8405834",
    country: "US",
    country_long: "United States",
    portCode: "USHNL",
    portName: "Ala Wai Harbor/Honolulu",
    portContinent: "North America",
  },
  {
    lat: "59.2351471",
    lng: "-135.447332",
    country: "US",
    country_long: "United States",
    portCode: "USHNS",
    portName: "Port of Haines",
    portContinent: "North America",
  },
  {
    lat: "33.8984461",
    lng: "-88.99922670000001",
    country: "US",
    country_long: "United States",
    portCode: "USHOG",
    portName: "Houston, MS (USHOG), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "59.606914",
    lng: "-151.4252652",
    country: "US",
    country_long: "United States",
    portCode: "USHOM",
    portName: "Port of Homer",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USHOU",
    portName: "Port of Houston",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USHPS",
    portName: "Hampshire",
    portContinent: "North America",
  },
  {
    lat: "47.7510741",
    lng: "-120.7401386",
    country: "US",
    country_long: "United States",
    portCode: "USHQJ",
    portName: "Washington (USHQJ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "46.97704299999999",
    lng: "-123.8839028",
    country: "US",
    country_long: "United States",
    portCode: "USHQM",
    portName: "Hoquiam Harbor",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USHRY",
    portName: "Harvey",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USHSH",
    portName: "Hollis Harbor",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USHSI",
    portName: "Hastings (USHSI), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "34.7303688",
    lng: "-86.5861037",
    country: "US",
    country_long: "United States",
    portCode: "USHSV",
    portName: "HUNTSVILLE (USHSV), Huntsville, United States of America",
    portContinent: "North America",
  },
  {
    lat: "38.0608445",
    lng: "-97.92977429999999",
    country: "US",
    country_long: "United States",
    portCode: "USHTK",
    portName: "Hutchinson",
    portContinent: "North America",
  },
  {
    lat: "42.9708634",
    lng: "-82.42491419999999",
    country: "US",
    country_long: "United States",
    portCode: "USHUB",
    portName: "Port of Huron",
    portContinent: "North America",
  },
  {
    lat: "40.8094385",
    lng: "-73.8803315",
    country: "US",
    country_long: "United States",
    portCode: "USHUI",
    portName: "Hunts Point (USHUI), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "29.5957696",
    lng: "-90.71953479999999",
    country: "US",
    country_long: "United States",
    portCode: "USHUM",
    portName: "Houma (USHUM), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.2464593",
    lng: "-80.2114472",
    country: "US",
    country_long: "United States",
    portCode: "USHUP",
    portName: "Houston, PA (USHUP), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "34.7303688",
    lng: "-86.5861037",
    country: "US",
    country_long: "United States",
    portCode: "USHUV",
    portName: "HUNTSVILLE",
    portContinent: "North America",
  },
  {
    lat: "41.29187",
    lng: "-72.90323699999999",
    country: "US",
    country_long: "United States",
    portCode: "USHVN",
    portName: "Port of New Haven",
    portContinent: "North America",
  },
  {
    lat: "32.9748469",
    lng: "-97.34779569999999",
    country: "US",
    country_long: "United States",
    portCode: "USHXC",
    portName: "Haslet, Tx",
    portContinent: "North America",
  },
  {
    lat: "41.6315003",
    lng: "-70.2869656",
    country: "US",
    country_long: "United States",
    portCode: "USHYA",
    portName: "Hyannis Harbor",
    portContinent: "North America",
  },
  {
    lat: "55.2074214",
    lng: "-132.8275439",
    country: "US",
    country_long: "United States",
    portCode: "USHYG",
    portName: "Port of Hydaburg",
    portContinent: "North America",
  },
  {
    lat: "30.0035365",
    lng: "-91.81872849999999",
    country: "US",
    country_long: "United States",
    portCode: "USIBE",
    portName: "New Iberia (USIBE), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.7419352",
    lng: "-95.038313",
    country: "US",
    country_long: "United States",
    portCode: "USICL",
    portName: "Clarinda",
    portContinent: "North America",
  },
  {
    lat: "37.68717609999999",
    lng: "-97.33005299999999",
    country: "US",
    country_long: "United States",
    portCode: "USICT",
    portName: "Wichita, ks",
    portContinent: "North America",
  },
  {
    lat: "48.4779232",
    lng: "-120.1861956",
    country: "US",
    country_long: "United States",
    portCode: "USIHP",
    portName: "Winthrop (USIHP), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.785393",
    lng: "-76.22332020000002",
    country: "US",
    country_long: "United States",
    portCode: "USIIC",
    portName: "Saint Michaels (USIIC), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "39.7152763",
    lng: "-75.5298217",
    country: "US",
    country_long: "United States",
    portCode: "USILG",
    portName: "The Port of Wilmington",
    portContinent: "North America",
  },
  {
    lat: "39.7152763",
    lng: "-75.5298217",
    country: "US",
    country_long: "United States",
    portCode: "USILM",
    portName: "Port of Wilmington",
    portContinent: "North America",
  },
  {
    lat: "39.768403",
    lng: "-86.158068",
    country: "US",
    country_long: "United States",
    portCode: "USIND",
    portName: "Indianapolis",
    portContinent: "North America",
  },
  {
    lat: "48.6023149",
    lng: "-93.4040679",
    country: "US",
    country_long: "United States",
    portCode: "USINL",
    portName: "INTERNATIONAL FALLS",
    portContinent: "North America",
  },
  {
    lat: "45.9577506",
    lng: "-86.2462523",
    country: "US",
    country_long: "United States",
    portCode: "USISQ",
    portName: "Manistique (USISQ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "44.38357630000001",
    lng: "-89.8173465",
    country: "US",
    country_long: "United States",
    portCode: "USISW",
    portName: "Wisconsin Rapids",
    portContinent: "North America",
  },
  {
    lat: "19.7333333",
    lng: "-155.0666667",
    country: "US",
    country_long: "United States",
    portCode: "USITO",
    portName: "Hilo Harbor, Hawaii",
    portContinent: "North America",
  },
  {
    lat: "33.6845673",
    lng: "-117.8265049",
    country: "US",
    country_long: "United States",
    portCode: "USIVN",
    portName: "Irvine",
    portContinent: "North America",
  },
  {
    lat: "30.356184",
    lng: "-81.625047",
    country: "US",
    country_long: "United States",
    portCode: "USJAX",
    portName: "Port of Jacksonville",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USJEC",
    portName: "Port Liberte (USJEC), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.3255646",
    lng: "-85.6818939",
    country: "US",
    country_long: "United States",
    portCode: "USJFV",
    portName: "Port of Indiana-Jeffersonville",
    portContinent: "North America",
  },
  {
    lat: "40.3526079",
    lng: "-74.4401513",
    country: "US",
    country_long: "United States",
    portCode: "USJMB",
    portName: "Jamesburg",
    portContinent: "North America",
  },
  {
    lat: "37.8590937",
    lng: "-122.4852507",
    country: "US",
    country_long: "United States",
    portCode: "USJMC",
    portName: "Sausalito (USJMC), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "33.6188829",
    lng: "-117.9298493",
    country: "US",
    country_long: "United States",
    portCode: "USJNP",
    portName: "Newport Beach (USJNP), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "58.30049329999999",
    lng: "-134.4201306",
    country: "US",
    country_long: "United States",
    portCode: "USJNU",
    portName: "Port of Juneau",
    portContinent: "North America",
  },
  {
    lat: "41.525031",
    lng: "-88.0817251",
    country: "US",
    country_long: "United States",
    portCode: "USJOT",
    portName: "Joliet (USJOT), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "46.7792664",
    lng: "-90.78654120000002",
    country: "US",
    country_long: "United States",
    portCode: "USJPP",
    portName: "La Pointe (USJPP), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USKAE",
    portName: "Port of Kake",
    portContinent: "North America",
  },
  {
    lat: "46.0055378",
    lng: "-122.8474426",
    country: "US",
    country_long: "United States",
    portCode: "USKAM",
    portName: "Port of Kalama",
    portContinent: "North America",
  },
  {
    lat: "29.9960464",
    lng: "-93.3420996",
    country: "US",
    country_long: "United States",
    portCode: "USKBR",
    portName: "Hackberry (USKBR), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "39.11553139999999",
    lng: "-94.62678729999999",
    country: "US",
    country_long: "United States",
    portCode: "USKCK",
    portName: "KANSAS CITY, KS (USKCK), Kansas City, United States of America",
    portContinent: "North America",
  },
  {
    lat: "41.54016610000001",
    lng: "-82.74324690000002",
    country: "US",
    country_long: "United States",
    portCode: "USKDB",
    portName: "Lakeside Marblehead (USKDB), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.9270367",
    lng: "-73.9973608",
    country: "US",
    country_long: "United States",
    portCode: "USKG8",
    portName: "Kingston (USKG8), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "19.6419129",
    lng: "-155.9962255",
    country: "US",
    country_long: "United States",
    portCode: "USKHH",
    portName: "Port of Kailua Kona",
    portContinent: "North America",
  },
  {
    lat: "55.5520852",
    lng: "-133.081983",
    country: "US",
    country_long: "United States",
    portCode: "USKLW",
    portName: "Port of Klawock",
    portContinent: "North America",
  },
  {
    lat: "57.92332200000001",
    lng: "-152.5019368",
    country: "US",
    country_long: "United States",
    portCode: "USKOZ",
    portName: "Port of Ouzinkie",
    portContinent: "North America",
  },
  {
    lat: "54.1336126",
    lng: "-165.7753862",
    country: "US",
    country_long: "United States",
    portCode: "USKQA",
    portName: "Akutan (USKQA), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "34.8667459",
    lng: "-84.7454939",
    country: "US",
    country_long: "United States",
    portCode: "USKRA",
    portName: "CRANDALL, GA",
    portContinent: "North America",
  },
  {
    lat: "55.6867672",
    lng: "-132.5232037",
    country: "US",
    country_long: "United States",
    portCode: "USKTB",
    portName: "Port of Thorne Bay",
    portContinent: "North America",
  },
  {
    lat: "55.3516833",
    lng: "-131.6838562",
    country: "US",
    country_long: "United States",
    portCode: "USKTN",
    portName: "Port of Ketchikan",
    portContinent: "North America",
  },
  {
    lat: "55.0593868",
    lng: "-162.3130507",
    country: "US",
    country_long: "United States",
    portCode: "USKVC",
    portName: "Port of King Cove",
    portContinent: "North America",
  },
  {
    lat: "46.21712400000001",
    lng: "-119.111284",
    country: "US",
    country_long: "United States",
    portCode: "USKWC",
    portName: "Port of Kennewick",
    portContinent: "North America",
  },
  {
    lat: "20.0386951",
    lng: "-155.8301172",
    country: "US",
    country_long: "United States",
    portCode: "USKWH",
    portName: "Kawaihae Harbor, Hawaii",
    portContinent: "North America",
  },
  {
    lat: "59.75070770000001",
    lng: "-161.9020211",
    country: "US",
    country_long: "United States",
    portCode: "USKWN",
    portName: "Quinhagak (USKWN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "44.4583321",
    lng: "-87.50313969999999",
    country: "US",
    country_long: "United States",
    portCode: "USKWU",
    portName: "Kewaunee (USKWU), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "45.7774987",
    lng: "-84.72714649999999",
    country: "US",
    country_long: "United States",
    portCode: "USKWY",
    portName: "Mackinaw City (USKWY), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.4331631",
    lng: "-74.1995883",
    country: "US",
    country_long: "United States",
    portCode: "USKYP",
    portName: "Keyport (USKYP), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "36.171563",
    lng: "-115.1391009",
    country: "US",
    country_long: "United States",
    portCode: "USLAS",
    portName: "Las Vegas",
    portContinent: "North America",
  },
  {
    lat: "33.73954589643058",
    lng: "-118.2599686241527",
    country: "US",
    country_long: "United States",
    portCode: "USLAX",
    portName: "Port of Los Angeles",
    portContinent: "North America",
  },
  {
    lat: "30.2158233",
    lng: "-93.2497472",
    country: "US",
    country_long: "United States",
    portCode: "USLCH",
    portName: "Port of Lake Charles",
    portContinent: "North America",
  },
  {
    lat: "43.9552825",
    lng: "-86.4525831",
    country: "US",
    country_long: "United States",
    portCode: "USLDM",
    portName: "Ludington (USLDM), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.0405837",
    lng: "-84.5037164",
    country: "US",
    country_long: "United States",
    portCode: "USLEX",
    portName: "Lexington",
    portContinent: "North America",
  },
  {
    lat: "33.754185",
    lng: "-118.216458",
    country: "US",
    country_long: "United States",
    portCode: "USLGB",
    portName: "Port of Long Beach",
    portContinent: "North America",
  },
  {
    lat: "38.9464996",
    lng: "-77.1588685",
    country: "US",
    country_long: "United States",
    portCode: "USLGL",
    portName: "LANGLEY",
    portContinent: "North America",
  },
  {
    lat: "34.7444618",
    lng: "-92.2880157",
    country: "US",
    country_long: "United States",
    portCode: "USLIT",
    portName: "Little Rock",
    portContinent: "North America",
  },
  {
    lat: "41.673642",
    lng: "-88.0017261",
    country: "US",
    country_long: "United States",
    portCode: "USLMJ",
    portName: "Lemont (USLMJ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "29.6914805",
    lng: "-91.1999456",
    country: "US",
    country_long: "United States",
    portCode: "USLMO",
    portName: "Port of Morgan City",
    portContinent: "North America",
  },
  {
    lat: "41.8786351",
    lng: "-87.6250549",
    country: "US",
    country_long: "United States",
    portCode: "USLO9",
    portName: "Loop (USLO9), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "46.107284",
    lng: "-122.9418561",
    country: "US",
    country_long: "United States",
    portCode: "USLOG",
    portName: "Port of Longview",
    portContinent: "North America",
  },
  {
    lat: "41.4691176",
    lng: "-82.1760358",
    country: "US",
    country_long: "United States",
    portCode: "USLOR",
    portName: "Port of Lorain",
    portContinent: "North America",
  },
  {
    lat: "27.5035613",
    lng: "-99.5075519",
    country: "US",
    country_long: "United States",
    portCode: "USLRD",
    portName: "Laredo, US",
    portContinent: "North America",
  },
  {
    lat: "41.2147851",
    lng: "-79.3853218",
    country: "US",
    country_long: "United States",
    portCode: "USLRI",
    portName: "CLARION",
    portContinent: "North America",
  },
  {
    lat: "40.563123",
    lng: "-80.2083928",
    country: "US",
    country_long: "United States",
    portCode: "USLSL",
    portName: "leetsdale",
    portContinent: "North America",
  },
  {
    lat: "39.613321",
    lng: "-105.0166498",
    country: "US",
    country_long: "United States",
    portCode: "USLTN",
    portName: "Littleton",
    portContinent: "North America",
  },
  {
    lat: "30.0404796",
    lng: "-90.6989797",
    country: "US",
    country_long: "United States",
    portCode: "USLUH",
    portName: "Lutcher (USLUH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.2526647",
    lng: "-85.7584557",
    country: "US",
    country_long: "United States",
    portCode: "USLUI",
    portName: "Louisville (USLUI), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "30.35047509999999",
    lng: "-89.15281759999999",
    country: "US",
    country_long: "United States",
    portCode: "USLUP",
    portName: "Long Beach, MS (USLUP), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "46.42657089999999",
    lng: "-117.009569",
    country: "US",
    country_long: "United States",
    portCode: "USLUT",
    portName: "Port of Lewiston",
    portContinent: "North America",
  },
  {
    lat: "30.0565906",
    lng: "-91.7370595",
    country: "US",
    country_long: "United States",
    portCode: "USLUX",
    portName: "Loreauville (USLUX), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.7745565",
    lng: "-75.13934979999999",
    country: "US",
    country_long: "United States",
    portCode: "USLWP",
    portName: "Lewes (USLWP), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "43.9844072",
    lng: "-91.86932050000001",
    country: "US",
    country_long: "United States",
    portCode: "USLWS",
    portName: "Lewiston (USLWS), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "33.7739892",
    lng: "-85.88552299999999",
    country: "US",
    country_long: "United States",
    portCode: "USLXD",
    portName: "Alexandria, AL  (USLXD), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "48.3597197",
    lng: "-107.8742831",
    country: "US",
    country_long: "United States",
    portCode: "USLZT",
    portName: "Malta, MT  (USLZT), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.4167754",
    lng: "-3.7037902",
    country: "ES",
    country_long: "Spain",
    portCode: "USMAD",
    portName: "Madrid",
    portContinent: "North America",
  },
  {
    lat: "39.8192794",
    lng: "-75.4185272",
    country: "US",
    country_long: "United States",
    portCode: "USMAH",
    portName: "Marcus Hook (USMAH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "44.2444473",
    lng: "-86.324253",
    country: "US",
    country_long: "United States",
    portCode: "USMBL",
    portName: "Manistee Harbor",
    portContinent: "North America",
  },
  {
    lat: "31.9973456",
    lng: "-102.0779146",
    country: "US",
    country_long: "United States",
    portCode: "USMBS",
    portName: "MIDLAND",
    portContinent: "North America",
  },
  {
    lat: "45.8491796",
    lng: "-84.6189339",
    country: "US",
    country_long: "United States",
    portCode: "USMCD",
    portName: "Mackinac Island (USMCD), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "33.9802893",
    lng: "-118.4517449",
    country: "US",
    country_long: "United States",
    portCode: "USMDX",
    portName: "Marina Del Rey (USMDX), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "35.1175172",
    lng: "-90.0746162",
    country: "US",
    country_long: "United States",
    portCode: "USMEM",
    portName: "Port of Memphis",
    portContinent: "North America",
  },
  {
    lat: "44.977753",
    lng: "-93.2650108",
    country: "US",
    country_long: "United States",
    portCode: "USMES",
    portName: "Minneapolis (USMES), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.1511655",
    lng: "-88.73199790000001",
    country: "US",
    country_long: "United States",
    portCode: "USMET",
    portName: "Metropolis (USMET), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "25.9420377",
    lng: "-80.2456045",
    country: "US",
    country_long: "United States",
    portCode: "USMG3",
    portName: "Miami Gardens, FL (USMG3), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.70753939999999",
    lng: "-86.8950297",
    country: "US",
    country_long: "United States",
    portCode: "USMGC",
    portName: "Michigan City (USMGC), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "43.0801859",
    lng: "0.5659287",
    country: "FR",
    country_long: "France",
    portCode: "USMGP",
    portName: "USMGP",
    portContinent: "North America",
  },
  {
    lat: "39.629526",
    lng: "-79.95589679999999",
    country: "US",
    country_long: "United States",
    portCode: "USMGW",
    portName: "Morgantown (USMGW), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USMI3",
    portName: "Calcite (USMI3), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "25.7745435",
    lng: "-80.1708802",
    country: "US",
    country_long: "United States",
    portCode: "USMIA",
    portName: "Port of Miami",
    portContinent: "North America",
  },
  {
    lat: "36.8745146",
    lng: "-94.8774554",
    country: "US",
    country_long: "United States",
    portCode: "USMIO",
    portName: "Miami, OK  (USMIO), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "35.3712022",
    lng: "-120.8579694",
    country: "US",
    country_long: "United States",
    portCode: "USMJK",
    portName: "Morro Bay Harbor",
    portContinent: "North America",
  },
  {
    lat: "39.64923770000001",
    lng: "-81.8620804",
    country: "US",
    country_long: "United States",
    portCode: "USMJW",
    portName: "Malta, OH  (USMJW), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "39.0997265",
    lng: "-94.5785667",
    country: "US",
    country_long: "United States",
    portCode: "USMKC",
    portName: "Kansas City, MO (USMKC), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "43.0028228",
    lng: "-87.89113820000001",
    country: "US",
    country_long: "United States",
    portCode: "USMKE",
    portName: "Port of Milwaukee",
    portContinent: "North America",
  },
  {
    lat: "43.2341813",
    lng: "-86.24839209999999",
    country: "US",
    country_long: "United States",
    portCode: "USMKG",
    portName: "Muskegon Harbor",
    portContinent: "North America",
  },
  {
    lat: "42.9854395",
    lng: "-73.78682359999999",
    country: "US",
    country_long: "United States",
    portCode: "USMLQ",
    portName: "Malta, NY (USMLQ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "33.3992217",
    lng: "-110.8687232",
    country: "US",
    country_long: "United States",
    portCode: "USMMI",
    portName: "Miami, AZ (USMMI), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USMNM",
    portName: "Port of Marinette",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USMOB",
    portName: "Port of Mobile",
    portContinent: "North America",
  },
  {
    lat: "41.8999243",
    lng: "-83.3558898",
    country: "US",
    country_long: "United States",
    portCode: "USMOI",
    portName: "Port of Monroe",
    portContinent: "North America",
  },
  {
    lat: "45.0999849",
    lng: "-87.6306623",
    country: "US",
    country_long: "United States",
    portCode: "USMOY",
    portName: "Marinette (USMOY), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "-35.0091223",
    lng: "138.5551196",
    country: "AU",
    country_long: "Australia",
    portCode: "USMQM",
    portName: "MARION",
    portContinent: "North America",
  },
  {
    lat: "46.5436199",
    lng: "-87.3953713",
    country: "US",
    country_long: "United States",
    portCode: "USMQT",
    portName: "Port of Marquette",
    portContinent: "North America",
  },
  {
    lat: "34.7229391",
    lng: "-76.7260436",
    country: "US",
    country_long: "United States",
    portCode: "USMRH",
    portName: "Port of Morehead City",
    portContinent: "North America",
  },
  {
    lat: "33.5831662",
    lng: "-84.33936829999999",
    country: "US",
    country_long: "United States",
    portCode: "USMRW",
    portName: "MORROW",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USMRZ",
    portName: "Shell Oil Terminal Martinez",
    portContinent: "North America",
  },
  {
    lat: "34.6992579",
    lng: "-86.74833180000002",
    country: "US",
    country_long: "United States",
    portCode: "USMSN",
    portName: "MADISON (USMSN), Madison, United States of America",
    portContinent: "North America",
  },
  {
    lat: "44.9374831",
    lng: "-93.20099979999999",
    country: "US",
    country_long: "United States",
    portCode: "USMSP",
    portName: "Minneapolis/St Paul",
    portContinent: "North America",
  },
  {
    lat: "29.9371329",
    lng: "-90.061357",
    country: "US",
    country_long: "United States",
    portCode: "USMSY",
    portName: "Port of New Orleans",
    portContinent: "North America",
  },
  {
    lat: "55.1291667",
    lng: "-131.5722222",
    country: "US",
    country_long: "United States",
    portCode: "USMTM",
    portName: "Port of Metlakatla",
    portContinent: "North America",
  },
  {
    lat: "45.5016889",
    lng: "-73.567256",
    country: "CA",
    country_long: "Canada",
    portCode: "USMTR",
    portName: "MONTREAL",
    portContinent: "North America",
  },
  {
    lat: "44.0919393",
    lng: "-87.64730589999999",
    country: "US",
    country_long: "United States",
    portCode: "USMTW",
    portName: "Manitowoc Harbor",
    portContinent: "North America",
  },
  {
    lat: "41.424473",
    lng: "-91.0432051",
    country: "US",
    country_long: "United States",
    portCode: "USMUT",
    portName: "Muscatine (USMUT), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.9125992",
    lng: "-73.8370786",
    country: "US",
    country_long: "United States",
    portCode: "USMXH",
    portName: "Mount Vernon (USMXH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.2031265",
    lng: "-79.92616029999999",
    country: "US",
    country_long: "United States",
    portCode: "USMXX",
    portName: "Monongahela (USMXX), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "36.6116265",
    lng: "-121.8955095",
    country: "US",
    country_long: "United States",
    portCode: "USMY3",
    portName: "Monterey Harbor",
    portContinent: "North America",
  },
  {
    lat: "21.3249895",
    lng: "-158.1161614",
    country: "US",
    country_long: "United States",
    portCode: "USNAX",
    portName: "Kalaeloa Barbers Point Harbor, Oahu",
    portContinent: "North America",
  },
  {
    lat: "41.6362152",
    lng: "-70.93420499999999",
    country: "US",
    country_long: "United States",
    portCode: "USNBD",
    portName: "New Bedford (USNBD), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "42.6811436",
    lng: "-82.7368616",
    country: "US",
    country_long: "United States",
    portCode: "USNBT",
    portName: "New Baltimore (USNBT), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "30.0571083",
    lng: "-90.56420159999999",
    country: "US",
    country_long: "United States",
    portCode: "USNCM",
    portName: "Port of South Louisiana",
    portContinent: "North America",
  },
  {
    lat: "35.7595731",
    lng: "-79.01929969999999",
    country: "US",
    country_long: "United States",
    portCode: "USNCN",
    portName: "Chenega (USNCN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "30.0571083",
    lng: "-90.56420159999999",
    country: "US",
    country_long: "United States",
    portCode: "USNEW",
    portName: "Port of South Louisiana",
    portContinent: "North America",
  },
  {
    lat: "41.9939828",
    lng: "-73.2020577",
    country: "US",
    country_long: "United States",
    portCode: "USNFE",
    portName: "Norfolk,CT (USNFE), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "21.9546452",
    lng: "-159.3560305",
    country: "US",
    country_long: "United States",
    portCode: "USNIJ",
    portName: "Nawiliwili Harbor, Kauai",
    portContinent: "North America",
  },
  {
    lat: "60.68333329999999",
    lng: "-151.3788889",
    country: "US",
    country_long: "United States",
    portCode: "USNIK",
    portName: "Port of Nikiski",
    portContinent: "North America",
  },
  {
    lat: "41.5901015",
    lng: "-71.4042218",
    country: "US",
    country_long: "United States",
    portCode: "USNKT",
    portName: "Quonset Point",
    portContinent: "North America",
  },
  {
    lat: "42.1195426",
    lng: "-71.3250563",
    country: "US",
    country_long: "United States",
    portCode: "USNLK",
    portName: "Norfolk, MA (USNLK), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.3599258",
    lng: "-72.0923157",
    country: "US",
    country_long: "United States",
    portCode: "USNLO",
    portName: "Port of New London",
    portContinent: "North America",
  },
  {
    lat: "57.9926243",
    lng: "-160.6391037",
    country: "US",
    country_long: "United States",
    portCode: "USNNK",
    portName: "Port of Bristol Bay",
    portContinent: "North America",
  },
  {
    lat: "37.0870821",
    lng: "-76.4730122",
    country: "US",
    country_long: "United States",
    portCode: "USNNS",
    portName: "Port of Newport News",
    portContinent: "North America",
  },
  {
    lat: "43.0467301",
    lng: "-77.0952516",
    country: "US",
    country_long: "United States",
    portCode: "USNNY",
    portName: "Newark (USNNY), New York, United States of America",
    portContinent: "North America",
  },
  {
    lat: "41.4901024",
    lng: "-71.3128285",
    country: "US",
    country_long: "United States",
    portCode: "USNPO",
    portName: "Newport (USNPO), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "44.6211661",
    lng: "-124.0484236",
    country: "US",
    country_long: "United States",
    portCode: "USNPT",
    portName: "Port of Newport",
    portContinent: "North America",
  },
  {
    lat: "44.8008872",
    lng: "-74.9910336",
    country: "US",
    country_long: "United States",
    portCode: "USNRK",
    portName: "Norfolk, NY (USNRK), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "29.7332834",
    lng: "-93.8948078",
    country: "US",
    country_long: "United States",
    portCode: "USNSS",
    portName: "Sabine Pass (USNSS), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "34.1491718",
    lng: "-119.208719",
    country: "US",
    country_long: "United States",
    portCode: "USNTD",
    portName: "Port of Hueneme",
    portContinent: "North America",
  },
  {
    lat: "32.8771106",
    lng: "-80.0130514",
    country: "US",
    country_long: "United States",
    portCode: "USNTS",
    portName: "North Charleston",
    portContinent: "North America",
  },
  {
    lat: "36.0265423",
    lng: "-87.98392299999999",
    country: "US",
    country_long: "United States",
    portCode: "USNWJ",
    portName: "New Johnsonville (USNWJ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.6839717",
    lng: "-74.0062426",
    country: "US",
    country_long: "United States",
    portCode: "USNYC",
    portName: "Port of New York",
    portContinent: "North America",
  },
  {
    lat: "38.3386591",
    lng: "-87.3450132",
    country: "US",
    country_long: "United States",
    portCode: "USOA3",
    portName: "Oakland ,IN (USOA3), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.8357057",
    lng: "-96.4666634",
    country: "US",
    country_long: "United States",
    portCode: "USOAB",
    portName: "Oakland, NE  (USOAB), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.7956584",
    lng: "-122.2791769",
    country: "US",
    country_long: "United States",
    portCode: "USOAK",
    portName: "Port of Oakland",
    portContinent: "North America",
  },
  {
    lat: "41.3094375",
    lng: "-95.3957997",
    country: "US",
    country_long: "United States",
    portCode: "USOAU",
    portName: "Oakland ,IA  (USOAU), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "32.7494988",
    lng: "-117.2470353",
    country: "US",
    country_long: "United States",
    portCode: "USOB3",
    portName: "Ocean Beach (USOB3), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "42.03272339999999",
    lng: "-97.41375529999999",
    country: "US",
    country_long: "United States",
    portCode: "USOFK",
    portName: "Norfolk, NE  (USOFK), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "20.893013",
    lng: "-156.4661407",
    country: "US",
    country_long: "United States",
    portCode: "USOGG",
    portName: "Kahului Harbor, Maui",
    portContinent: "North America",
  },
  {
    lat: "44.718694",
    lng: "-75.455416",
    country: "US",
    country_long: "United States",
    portCode: "USOGS",
    portName: "Port of Ogdensburg",
    portContinent: "North America",
  },
  {
    lat: "40.6539546",
    lng: "-80.3878421",
    country: "US",
    country_long: "United States",
    portCode: "USOHV",
    portName: "Ohioview (USOHV), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "39.8311579",
    lng: "-90.55957079999999",
    country: "US",
    country_long: "United States",
    portCode: "USOIS",
    portName: "Meredoisa (USOIS), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "35.4675602",
    lng: "-97.5164276",
    country: "US",
    country_long: "United States",
    portCode: "USOKC",
    portName: "Oklahoma City",
    portContinent: "North America",
  },
  {
    lat: "34.0556679",
    lng: "-89.91647809999999",
    country: "US",
    country_long: "United States",
    portCode: "USOKJ",
    portName: "Oakland, MS (USOKJ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.0131529",
    lng: "-74.26431430000001",
    country: "US",
    country_long: "United States",
    portCode: "USOKL",
    portName: "Oakland, NJ (USOKL), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "44.5402221",
    lng: "-69.72199499999999",
    country: "US",
    country_long: "United States",
    portCode: "USOLB",
    portName: "Oakland ,ME  (USOLB), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "47.0493071",
    lng: "-122.903225",
    country: "US",
    country_long: "United States",
    portCode: "USOLM",
    portName: "Port of Olympia",
    portContinent: "North America",
  },
  {
    lat: "31.3403775",
    lng: "-110.9342532",
    country: "US",
    country_long: "United States",
    portCode: "USOLS",
    portName: "NOGALES, AZ",
    portContinent: "North America",
  },
  {
    lat: "41.2565369",
    lng: "-95.9345034",
    country: "US",
    country_long: "United States",
    portCode: "USOMA",
    portName: "OMAHA, NE  (USOMA), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "64.5027465",
    lng: "-165.422821",
    country: "US",
    country_long: "United States",
    portCode: "USOME",
    portName: "Port of Nome",
    portContinent: "North America",
  },
  {
    lat: "46.8710525",
    lng: "-89.31403019999999",
    country: "US",
    country_long: "United States",
    portCode: "USONG",
    portName: "Port of Ontonagon",
    portContinent: "North America",
  },
  {
    lat: "36.8484814",
    lng: "-76.3352202",
    country: "US",
    country_long: "United States",
    portCode: "USORF",
    portName: "Port of Norfolk",
    portContinent: "North America",
  },
  {
    lat: "30.0800773",
    lng: "-93.7293303",
    country: "US",
    country_long: "United States",
    portCode: "USORG",
    portName: "Port of Orange",
    portContinent: "North America",
  },
  {
    lat: "42.2680093",
    lng: "-71.87633699999999",
    country: "US",
    country_long: "United States",
    portCode: "USORH",
    portName: "WORCESTER, MA  (USORH), Worcester, United States of America",
    portContinent: "North America",
  },
  {
    lat: "28.5383832",
    lng: "-81.3789269",
    country: "US",
    country_long: "United States",
    portCode: "USORL",
    portName: "Orlando, Florida",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USOSW",
    portName: "Port of Oswego",
    portContinent: "North America",
  },
  {
    lat: "32.3792233",
    lng: "-86.3077368",
    country: "US",
    country_long: "United States",
    portCode: "USOTE",
    portName: "Montgomery (USOTE), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "48.521744",
    lng: "-122.612822",
    country: "US",
    country_long: "United States",
    portCode: "USOTS",
    portName: "Port of Anacortes",
    portContinent: "North America",
  },
  {
    lat: "66.8969151",
    lng: "-162.5930667",
    country: "US",
    country_long: "United States",
    portCode: "USOTZ",
    portName: "Port of Kotzebue",
    portContinent: "North America",
  },
  {
    lat: "43.9130453",
    lng: "-88.3323276",
    country: "US",
    country_long: "United States",
    portCode: "USOUS",
    portName: "Calumet Harbor (USOUS), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "42.4739705",
    lng: "-73.79234559999999",
    country: "US",
    country_long: "United States",
    portCode: "USOYF",
    portName: "Coeymans (USOYF), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "42.7284117",
    lng: "-73.69178509999999",
    country: "US",
    country_long: "United States",
    portCode: "USOYY",
    portName: "Troy (USOYY), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "43.995179",
    lng: "-92.38136209999999",
    country: "US",
    country_long: "United States",
    portCode: "USOZM",
    portName: "Olmsted (USOZM), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "26.7670074",
    lng: "-80.0511523",
    country: "US",
    country_long: "United States",
    portCode: "USPAB",
    portName: "Port of Palm Beach",
    portContinent: "North America",
  },
  {
    lat: "48.0007422",
    lng: "-122.2173458",
    country: "US",
    country_long: "United States",
    portCode: "USPAE",
    portName: "Port of Everett",
    portContinent: "North America",
  },
  {
    lat: "39.70421229999999",
    lng: "-86.39943869999999",
    country: "US",
    country_long: "United States",
    portCode: "USPAF",
    portName: "Plainfield, In",
    portContinent: "North America",
  },
  {
    lat: "37.0833893",
    lng: "-88.6000478",
    country: "US",
    country_long: "United States",
    portCode: "USPAH",
    portName: "Paducah (USPAH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "34.1477849",
    lng: "-118.1445155",
    country: "US",
    country_long: "United States",
    portCode: "USPAS",
    portName: "PASADENA",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USPAU",
    portName: "Port of Paulsboro",
    portContinent: "North America",
  },
  {
    lat: "40.5067723",
    lng: "-74.2654234",
    country: "US",
    country_long: "United States",
    portCode: "USPAY",
    portName: "Port of Perth Amboy",
    portContinent: "North America",
  },
  {
    lat: "40.69370989999999",
    lng: "-75.1901761",
    country: "US",
    country_long: "United States",
    portCode: "USPB6",
    portName: "PHILLIPSBURG",
    portContinent: "North America",
  },
  {
    lat: "26.7153424",
    lng: "-80.0533746",
    country: "US",
    country_long: "United States",
    portCode: "USPBI",
    portName: "West Palm Beach",
    portContinent: "North America",
  },
  {
    lat: "41.5119954",
    lng: "-82.93769189999999",
    country: "US",
    country_long: "United States",
    portCode: "USPCN",
    portName: "Port Clinton (USPCN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "28.6797169",
    lng: "-96.56025369999999",
    country: "US",
    country_long: "United States",
    portCode: "USPCR",
    portName: "Point Comfort",
    portContinent: "North America",
  },
  {
    lat: "28.3922182",
    lng: "-80.60771319999999",
    country: "US",
    country_long: "United States",
    portCode: "USPCV",
    portName: "Cape Canaveral (USPCV), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "45.6422482",
    lng: "-122.7514897",
    country: "US",
    country_long: "United States",
    portCode: "USPDX",
    portName: "Port of Portland",
    portContinent: "North America",
  },
  {
    lat: "-33.9608369",
    lng: "25.6022423",
    country: "ZA",
    country_long: "South Africa",
    portCode: "USPEB",
    portName: "Port Elizabeth",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USPEC",
    portName: "Port of Pelican",
    portContinent: "North America",
  },
  {
    lat: "26.0950632",
    lng: "-80.12440869999999",
    country: "US",
    country_long: "United States",
    portCode: "USPEF",
    portName: "Port Everglades (USPEF), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "30.1834527",
    lng: "-85.7305713",
    country: "US",
    country_long: "United States",
    portCode: "USPFN",
    portName: "Port Panama City",
    portContinent: "North America",
  },
  {
    lat: "26.1947962",
    lng: "-98.1836216",
    country: "US",
    country_long: "United States",
    portCode: "USPFX",
    portName: "Pharr",
    portContinent: "North America",
  },
  {
    lat: "47.8542606",
    lng: "-122.5837581",
    country: "US",
    country_long: "United States",
    portCode: "USPGH",
    portName: "Port of Port Gamble",
    portContinent: "North America",
  },
  {
    lat: "30.3474705",
    lng: "-88.50070989999999",
    country: "US",
    country_long: "United States",
    portCode: "USPGL",
    portName: "Port of Pascagoula",
    portContinent: "North America",
  },
  {
    lat: "39.9805657",
    lng: "-75.0896934",
    country: "US",
    country_long: "United States",
    portCode: "USPHL",
    portName: "Port of Philadelphia",
    portContinent: "North America",
  },
  {
    lat: "42.9708634",
    lng: "-82.42491419999999",
    country: "US",
    country_long: "United States",
    portCode: "USPHN",
    portName: "Port of Port Huron",
    portContinent: "North America",
  },
  {
    lat: "33.4483771",
    lng: "-112.0740373",
    country: "US",
    country_long: "United States",
    portCode: "USPHX",
    portName: "PHOENIX, AZ",
    portContinent: "North America",
  },
  {
    lat: "40.6936488",
    lng: "-89.5889864",
    country: "US",
    country_long: "United States",
    portCode: "USPIA",
    portName: "Peoria (USPIA), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "27.7608611",
    lng: "-82.6298206",
    country: "US",
    country_long: "United States",
    portCode: "USPIE",
    portName: "Port St. Petersburg",
    portContinent: "North America",
  },
  {
    lat: "42.8621042",
    lng: "-112.4506191",
    country: "US",
    country_long: "United States",
    portCode: "USPIH",
    portName: "POCATELLO",
    portContinent: "North America",
  },
  {
    lat: "40.4490629",
    lng: "-80.10905079999999",
    country: "US",
    country_long: "United States",
    portCode: "USPIT",
    portName: "Port of Pittsburgh",
    portContinent: "North America",
  },
  {
    lat: "29.1816126",
    lng: "-89.2575512",
    country: "US",
    country_long: "United States",
    portCode: "USPIW",
    portName: "Pilottown (USPIW), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.5795317",
    lng: "-74.1502007",
    country: "US",
    country_long: "United States",
    portCode: "USPIY",
    portName: "Staten Island, New York",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USPJI",
    portName: "Pritchard",
    portContinent: "North America",
  },
  {
    lat: "30.4474878",
    lng: "-91.20732609999999",
    country: "US",
    country_long: "United States",
    portCode: "USPLL",
    portName: "Port Allen (USPLL), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "42.5530753",
    lng: "-87.9334118",
    country: "US",
    country_long: "United States",
    portCode: "USPLP",
    portName: "PLEASANT PRAIRIE, WI",
    portContinent: "North America",
  },
  {
    lat: "27.6336443",
    lng: "-82.5614858",
    country: "US",
    country_long: "United States",
    portCode: "USPME",
    portName: "Port Manatee",
    portContinent: "North America",
  },
  {
    lat: "61.2680556",
    lng: "-149.9169444",
    country: "US",
    country_long: "United States",
    portCode: "USPMV",
    portName: "Port MacKenzie",
    portContinent: "North America",
  },
  {
    lat: "30.4064501",
    lng: "-87.2109004",
    country: "US",
    country_long: "United States",
    portCode: "USPNS",
    portName: "Port of Pensacola",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USPOA",
    portName: "Port of Port Arthur",
    portContinent: "North America",
  },
  {
    lat: "37.6144838",
    lng: "-93.4104749",
    country: "US",
    country_long: "United States",
    portCode: "USPOB",
    portName: "Bolivar (USPOB), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.6218238",
    lng: "-87.1563572",
    country: "US",
    country_long: "United States",
    portCode: "USPOJ",
    portName: "Port of Indiana - Burns Harbor",
    portContinent: "North America",
  },
  {
    lat: "29.9913244",
    lng: "-93.9585067",
    country: "US",
    country_long: "United States",
    portCode: "USPON",
    portName: "Port Neches",
    portContinent: "North America",
  },
  {
    lat: "41.7003713",
    lng: "-73.9209701",
    country: "US",
    country_long: "United States",
    portCode: "USPOU",
    portName: "Poughkeepsie (USPOU), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "46.6922264",
    lng: "-68.0452499",
    country: "US",
    country_long: "United States",
    portCode: "USPQI",
    portName: "Presque Isle (USPQI), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "46.2175127",
    lng: "-119.0814328",
    country: "US",
    country_long: "United States",
    portCode: "USPSC",
    portName: "Port of Pasco",
    portContinent: "North America",
  },
  {
    lat: "37.2279279",
    lng: "-77.40192669999999",
    country: "US",
    country_long: "United States",
    portCode: "USPSG",
    portName: "Port of Petersburg",
    portContinent: "North America",
  },
  {
    lat: "29.8118757",
    lng: "-85.30297399999999",
    country: "US",
    country_long: "United States",
    portCode: "USPSJ",
    portName: "Port of Port St. Joe",
    portContinent: "North America",
  },
  {
    lat: "35.1679231",
    lng: "-120.753423",
    country: "US",
    country_long: "United States",
    portCode: "USPSL",
    portName: "Port San Luis Harbor",
    portContinent: "North America",
  },
  {
    lat: "50.8197675",
    lng: "-1.0879769",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "USPSM",
    portName: "Port of Portsmouth",
    portContinent: "North America",
  },
  {
    lat: "28.7080461",
    lng: "-96.217467",
    country: "US",
    country_long: "United States",
    portCode: "USPSX",
    portName: "Palacios (USPSX), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.9464875",
    lng: "-73.0692732",
    country: "US",
    country_long: "United States",
    portCode: "USPTJ",
    portName: "Port Jefferson (USPTJ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "50.8197675",
    lng: "-1.0879769",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "USPTM",
    portName: "Port of Portsmouth",
    portContinent: "North America",
  },
  {
    lat: "47.735928",
    lng: "-122.6465372",
    country: "US",
    country_long: "United States",
    portCode: "USPUL",
    portName: "Poulsbo (USPUL), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.232417",
    lng: "-122.6366524",
    country: "US",
    country_long: "United States",
    portCode: "USPUM",
    portName: "Petaluma (USPUM), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "42.0547136",
    lng: "-70.1846012",
    country: "US",
    country_long: "United States",
    portCode: "USPVC",
    portName: "Port of Provincetown",
    portContinent: "North America",
  },
  {
    lat: "41.8239891",
    lng: "-71.4128343",
    country: "US",
    country_long: "United States",
    portCode: "USPVD",
    portName: "Port of Providence",
    portContinent: "North America",
  },
  {
    lat: "41.5224785",
    lng: "-72.01125979999999",
    country: "US",
    country_long: "United States",
    portCode: "USPVS",
    portName: "Preston, CT",
    portContinent: "North America",
  },
  {
    lat: "43.387926",
    lng: "-87.8667969",
    country: "US",
    country_long: "United States",
    portCode: "USPWA",
    portName: "Port Washington Marina",
    portContinent: "North America",
  },
  {
    lat: "45.6422482",
    lng: "-122.7514897",
    country: "US",
    country_long: "United States",
    portCode: "USPWM",
    portName: "Port of Portland",
    portContinent: "North America",
  },
  {
    lat: "47.5650067",
    lng: "-122.6269768",
    country: "US",
    country_long: "United States",
    portCode: "USPWT",
    portName: "Bremerton (USPWT), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "50.3754565",
    lng: "-4.1426565",
    country: "GB",
    country_long: "United Kingdom",
    portCode: "USPYM",
    portName: "Port of Plymouth",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USPYP",
    portName: "Port of Piney Point",
    portContinent: "North America",
  },
  {
    lat: "41.7286146",
    lng: "-87.5355008",
    country: "US",
    country_long: "United States",
    portCode: "USPZH",
    portName: "Port Chicago (USPZH), Chicago, United States of America",
    portContinent: "North America",
  },
  {
    lat: "27.499208",
    lng: "-80.34219840000002",
    country: "US",
    country_long: "United States",
    portCode: "USPZL",
    portName: "Saint Lucie",
    portContinent: "North America",
  },
  {
    lat: "40.665214",
    lng: "-73.9125304",
    country: "US",
    country_long: "United States",
    portCode: "USQBV",
    portName: "Brownsville,NY",
    portContinent: "North America",
  },
  {
    lat: "41.2147851",
    lng: "-79.3853218",
    country: "US",
    country_long: "United States",
    portCode: "USQCO",
    portName: "CLARION",
    portContinent: "North America",
  },
  {
    lat: "40.7282239",
    lng: "-73.7948516",
    country: "US",
    country_long: "United States",
    portCode: "USQEE",
    portName: "QUEENS",
    portContinent: "North America",
  },
  {
    lat: "42.7261309",
    lng: "-87.78285230000002",
    country: "US",
    country_long: "United States",
    portCode: "USRAC",
    portName: "Port of Racine",
    portContinent: "North America",
  },
  {
    lat: "33.8214309",
    lng: "-91.0196181",
    country: "US",
    country_long: "United States",
    portCode: "USRAE",
    portName: "Port of Rosedale",
    portContinent: "North America",
  },
  {
    lat: "35.7795897",
    lng: "-78.6381787",
    country: "US",
    country_long: "United States",
    portCode: "USRAG",
    portName: "RALEIGH",
    portContinent: "North America",
  },
  {
    lat: "35.7795897",
    lng: "-78.6381787",
    country: "US",
    country_long: "United States",
    portCode: "USRAL",
    portName: "Raleigh, NC",
    portContinent: "North America",
  },
  {
    lat: "37.4577509",
    lng: "-77.4218874",
    country: "US",
    country_long: "United States",
    portCode: "USRCH",
    portName: "Port of Richmond",
    portContinent: "North America",
  },
  {
    lat: "41.5061419",
    lng: "-88.11450359999999",
    country: "US",
    country_long: "United States",
    portCode: "USRCK",
    portName: "ROCKDALE",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USRD4",
    portName: "Rodeo (USRD4), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USRDB",
    portName: "Red Dog Harbor",
    portContinent: "North America",
  },
  {
    lat: "40.9788334",
    lng: "-72.64354349999999",
    country: "US",
    country_long: "United States",
    portCode: "USRHD",
    portName: "United Riverhead Terminal",
    portContinent: "North America",
  },
  {
    lat: "39.9885174",
    lng: "-75.1063473",
    country: "US",
    country_long: "United States",
    portCode: "USRI4",
    portName: "Port Richmond (USRI4), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.4577509",
    lng: "-77.4218874",
    country: "US",
    country_long: "United States",
    portCode: "USRIC",
    portName: "Port of Richmond",
    portContinent: "North America",
  },
  {
    lat: "42.8747106",
    lng: "-74.0460913",
    country: "US",
    country_long: "United States",
    portCode: "USRJN",
    portName: "Rotterdam Junction, NY (USRJN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USRKD",
    portName: "Port of Rockland",
    portContinent: "North America",
  },
  {
    lat: "41.4768217",
    lng: "-81.8497567",
    country: "US",
    country_long: "United States",
    portCode: "USRKY",
    portName: "Rocky River (USRKY), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "46.342921",
    lng: "-119.2700519",
    country: "US",
    country_long: "United States",
    portCode: "USRLD",
    portName: "Port of Benton",
    portContinent: "North America",
  },
  {
    lat: "38.9486757",
    lng: "-84.2799366",
    country: "US",
    country_long: "United States",
    portCode: "USRNH",
    portName: "New Richmond",
    portContinent: "North America",
  },
  {
    lat: "43.15657789999999",
    lng: "-77.6088465",
    country: "US",
    country_long: "United States",
    portCode: "USROC",
    portName: "Port of Rochester",
    portContinent: "North America",
  },
  {
    lat: "28.0205733",
    lng: "-97.0544341",
    country: "US",
    country_long: "United States",
    portCode: "USRQP",
    portName: "Rockport (USRQP), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "34.5400242",
    lng: "-112.4685025",
    country: "US",
    country_long: "United States",
    portCode: "USRSC",
    portName: "Prescott (USRSC), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "33.95260200000001",
    lng: "-84.5499327",
    country: "US",
    country_long: "United States",
    portCode: "USRTT",
    portName: "Marietta",
    portContinent: "North America",
  },
  {
    lat: "37.50783000000001",
    lng: "-122.2090609",
    country: "US",
    country_long: "United States",
    portCode: "USRWC",
    portName: "Port of Redwood City",
    portContinent: "North America",
  },
  {
    lat: "41.0545292",
    lng: "-76.23326759999999",
    country: "US",
    country_long: "United States",
    portCode: "USRWK",
    portName: "Berwick (USRWK), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "29.7332834",
    lng: "-93.8948078",
    country: "US",
    country_long: "United States",
    portCode: "USSAB",
    portName: "Port of Sabine Pass",
    portContinent: "North America",
  },
  {
    lat: "38.5653766",
    lng: "-121.5487862",
    country: "US",
    country_long: "United States",
    portCode: "USSAC",
    portName: "Port of West Sacramento",
    portContinent: "North America",
  },
  {
    lat: "32.7355086",
    lng: "-117.1771502",
    country: "US",
    country_long: "United States",
    portCode: "USSAN",
    portName: "Port of San Diego",
    portContinent: "North America",
  },
  {
    lat: "29.4251905",
    lng: "-98.4945922",
    country: "US",
    country_long: "United States",
    portCode: "USSAT",
    portName: "SAN ANTONIO, TX  (USSAT), San Antonio, United States of America",
    portContinent: "North America",
  },
  {
    lat: "32.1284294",
    lng: "-81.15145869999999",
    country: "US",
    country_long: "United States",
    portCode: "USSAV",
    portName: "Port of Savannah",
    portContinent: "North America",
  },
  {
    lat: "34.4035586",
    lng: "-119.6936253",
    country: "US",
    country_long: "United States",
    portCode: "USSBA",
    portName: "Santa Barbara Harbor",
    portContinent: "North America",
  },
  {
    lat: "41.2392227",
    lng: "-81.3459405",
    country: "US",
    country_long: "United States",
    portCode: "USSBH",
    portName: "STREETSBORO",
    portContinent: "North America",
  },
  {
    lat: "43.7508284",
    lng: "-87.71453",
    country: "US",
    country_long: "United States",
    portCode: "USSBM",
    portName: "Sheboygan (USSBM), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.3606736",
    lng: "-75.5993692",
    country: "US",
    country_long: "United States",
    portCode: "USSBY",
    portName: "Salisbury, MD",
    portContinent: "North America",
  },
  {
    lat: "37.9510351",
    lng: "-121.3266136",
    country: "US",
    country_long: "United States",
    portCode: "USSCK",
    portName: "Port of Stockton",
    portContinent: "North America",
  },
  {
    lat: "42.8208653",
    lng: "-82.486024",
    country: "US",
    country_long: "United States",
    portCode: "USSCL",
    portName: "St Clair (USSCL), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USSDP",
    portName: "Port of Sand Point",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USSEA",
    portName: "Port of Seattle",
    portContinent: "North America",
  },
  {
    lat: "45.548905",
    lng: "2.313835",
    country: "FR",
    country_long: "France",
    portCode: "USSEL",
    portName: "Selby (USSEL), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "44.9428975",
    lng: "-123.0350963",
    country: "US",
    country_long: "United States",
    portCode: "USSEM",
    portName: "Salem (USSEM), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "60.1174551",
    lng: "-149.4380213",
    country: "US",
    country_long: "United States",
    portCode: "USSEW",
    portName: "Seward Harbor",
    portContinent: "North America",
  },
  {
    lat: "37.79632",
    lng: "-122.39471",
    country: "US",
    country_long: "United States",
    portCode: "USSFO",
    portName: "Port of San Francisco",
    portContinent: "North America",
  },
  {
    lat: "43.0831301",
    lng: "-73.7845651",
    country: "US",
    country_long: "United States",
    portCode: "USSGA",
    portName: "Saratoga Springs",
    portContinent: "North America",
  },
  {
    lat: "37.2089572",
    lng: "-93.29229889999999",
    country: "US",
    country_long: "United States",
    portCode: "USSGF",
    portName: "Springfield, MO",
    portContinent: "North America",
  },
  {
    lat: "59.45027520000001",
    lng: "-135.3236819",
    country: "US",
    country_long: "United States",
    portCode: "USSGY",
    portName: "Port of Skagway",
    portContinent: "North America",
  },
  {
    lat: "39.02284849999999",
    lng: "-94.7151865",
    country: "US",
    country_long: "United States",
    portCode: "USSHC",
    portName: "Shawnee",
    portContinent: "North America",
  },
  {
    lat: "41.3164856",
    lng: "-73.0931641",
    country: "US",
    country_long: "United States",
    portCode: "USSHN",
    portName: "Shelton (USSHN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "32.5251516",
    lng: "-93.7501789",
    country: "US",
    country_long: "United States",
    portCode: "USSHV",
    portName: "Shreveport",
    portContinent: "North America",
  },
  {
    lat: "47.2943647",
    lng: "-91.257386",
    country: "US",
    country_long: "United States",
    portCode: "USSIB",
    portName: "Silver Bay (USSIB), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "47.1698197",
    lng: "-122.6026274",
    country: "US",
    country_long: "United States",
    portCode: "USSIC",
    portName: "Steilacoom (USSIC), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "57.05321929999999",
    lng: "-135.3345513",
    country: "US",
    country_long: "United States",
    portCode: "USSIT",
    portName: "Port of Sitka",
    portContinent: "North America",
  },
  {
    lat: "18.4655394",
    lng: "-66.1057355",
    country: "PR",
    country_long: "Puerto Rico",
    portCode: "USSJU",
    portName: "SAN  JUAN P.R.",
    portContinent: "North America",
  },
  {
    lat: "33.3648506",
    lng: "-92.7250061",
    country: "US",
    country_long: "United States",
    portCode: "USSK4",
    portName: "Smackover",
    portContinent: "North America",
  },
  {
    lat: "41.4555771",
    lng: "-82.7140383",
    country: "US",
    country_long: "United States",
    portCode: "USSKY",
    portName: "Port of Sandusky",
    portContinent: "North America",
  },
  {
    lat: "40.7607793",
    lng: "-111.8910474",
    country: "US",
    country_long: "United States",
    portCode: "USSLC",
    portName: "Salt Lake City",
    portContinent: "North America",
  },
  {
    lat: "33.7414085",
    lng: "-118.1047682",
    country: "US",
    country_long: "United States",
    portCode: "USSLH",
    portName: "Seal Beach (USSLH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USSLM",
    portName: "Port of Salem",
    portContinent: "North America",
  },
  {
    lat: "41.23453780000001",
    lng: "-80.4421859",
    country: "US",
    country_long: "United States",
    portCode: "USSMK",
    portName: "St Michael Harbor",
    portContinent: "North America",
  },
  {
    lat: "59.43853000000001",
    lng: "-151.7150486",
    country: "US",
    country_long: "United States",
    portCode: "USSOV",
    portName: "Port of Seldovia",
    portContinent: "North America",
  },
  {
    lat: "33.7360619",
    lng: "-118.2922461",
    country: "US",
    country_long: "United States",
    portCode: "USSPQ",
    portName: "San Pedro, Ca",
    portContinent: "North America",
  },
  {
    lat: "30.0571083",
    lng: "-90.56420159999999",
    country: "US",
    country_long: "United States",
    portCode: "USSRE",
    portName: "Port of South Louisiana",
    portContinent: "North America",
  },
  {
    lat: "44.46393399999999",
    lng: "-68.90386269999999",
    country: "US",
    country_long: "United States",
    portCode: "USSRP",
    portName: "Port of Searsport",
    portContinent: "North America",
  },
  {
    lat: "36.9741171",
    lng: "-122.0307963",
    country: "US",
    country_long: "United States",
    portCode: "USSRU",
    portName: "Santa Cruz Ca (USSRU), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "35.6709727",
    lng: "-80.4742261",
    country: "US",
    country_long: "United States",
    portCode: "USSRW",
    portName: "Salisbury, NC",
    portContinent: "North America",
  },
  {
    lat: "31.131813",
    lng: "-81.5368539",
    country: "US",
    country_long: "United States",
    portCode: "USSSI",
    portName: "Port of Brunswick",
    portContinent: "North America",
  },
  {
    lat: "37.0965278",
    lng: "-113.5684164",
    country: "US",
    country_long: "United States",
    portCode: "USSTG",
    portName: "Port of St George",
    portContinent: "North America",
  },
  {
    lat: "45.89448429999999",
    lng: "-122.8106642",
    country: "US",
    country_long: "United States",
    portCode: "USSTH",
    portName: "Port of St Helens",
    portContinent: "North America",
  },
  {
    lat: "38.6270025",
    lng: "-90.19940419999999",
    country: "US",
    country_long: "United States",
    portCode: "USSTL",
    portName:
      "St Louis (USSTL), United States of America, USA / Saint Louis MO",
    portContinent: "North America",
  },
  {
    lat: "44.9471819",
    lng: "-93.09496089999999",
    country: "US",
    country_long: "United States",
    portCode: "USSTP",
    portName: "Port of St Paul",
    portContinent: "North America",
  },
  {
    lat: "44.8341639",
    lng: "-87.3770419",
    country: "US",
    country_long: "United States",
    portCode: "USSUE",
    portName: "Sturgeon Bay (USSUE), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "42.3522866",
    lng: "-71.0504229",
    country: "US",
    country_long: "United States",
    portCode: "USSUF",
    portName: "SUFFOLK",
    portContinent: "North America",
  },
  {
    lat: "35.5785896",
    lng: "-82.58884239999999",
    country: "US",
    country_long: "United States",
    portCode: "USSUT",
    portName: "Sunny Point",
    portContinent: "North America",
  },
  {
    lat: "46.7899617",
    lng: "-90.8554573",
    country: "US",
    country_long: "United States",
    portCode: "USSUW",
    portName: "Port Superior",
    portContinent: "North America",
  },
  {
    lat: "35.2248034",
    lng: "-88.2492037",
    country: "US",
    country_long: "United States",
    portCode: "USSVH",
    portName: "Savannah, TN (USSVH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "29.04138842302225",
    lng: "-89.3320916028552",
    country: "US",
    country_long: "United States",
    portCode: "USSW8",
    portName: "Southwest Pass (USSW8), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USSWC",
    portName: "Port of Sandwich",
    portContinent: "North America",
  },
  {
    lat: "60.1047739",
    lng: "-149.4421037",
    country: "US",
    country_long: "United States",
    portCode: "USSWD",
    portName: "Seward (USSWD), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.4984032",
    lng: "-74.1008627",
    country: "US",
    country_long: "United States",
    portCode: "USSWF",
    portName: "Newburgh (USSWF), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "31.8559384",
    lng: "-106.6391577",
    country: "US",
    country_long: "United States",
    portCode: "USSXT",
    portName: "Santa Teresa, NM",
    portContinent: "North America",
  },
  {
    lat: "38.6270025",
    lng: "-90.19940419999999",
    country: "US",
    country_long: "United States",
    portCode: "USSYT",
    portName: "st. louis",
    portContinent: "North America",
  },
  {
    lat: "38.7627893",
    lng: "-93.73604979999999",
    country: "US",
    country_long: "United States",
    portCode: "USSZL",
    portName: "Warrensburg",
    portContinent: "North America",
  },
  {
    lat: "44.0055185",
    lng: "-91.4420883",
    country: "US",
    country_long: "United States",
    portCode: "UST2R",
    portName: "Trempealeau (UST2R), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "39.1677134",
    lng: "-120.1451812",
    country: "US",
    country_long: "United States",
    portCode: "USTAC",
    portName: "Tahoe City",
    portContinent: "North America",
  },
  {
    lat: "47.5230559",
    lng: "-90.91805599999999",
    country: "US",
    country_long: "United States",
    portCode: "USTCH",
    portName: "Taconite Harbor",
    portContinent: "North America",
  },
  {
    lat: "33.2098407",
    lng: "-87.56917349999999",
    country: "US",
    country_long: "United States",
    portCode: "USTCL",
    portName: "Port of Tuscaloosa",
    portContinent: "North America",
  },
  {
    lat: "27.6919746",
    lng: "-82.7234337",
    country: "US",
    country_long: "United States",
    portCode: "USTDE",
    portName: "Tierra Verde (USTDE), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.6528052",
    lng: "-83.5378674",
    country: "US",
    country_long: "United States",
    portCode: "USTDX",
    portName: "Toledo (USTDX), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "59.0618127",
    lng: "-160.3769037",
    country: "US",
    country_long: "United States",
    portCode: "USTGK",
    portName: "Togiak (USTGK), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "35.91537",
    lng: "-94.969956",
    country: "US",
    country_long: "United States",
    portCode: "USTGU",
    portName: "Tahlequah (USTGU), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "41.6248854",
    lng: "-71.2078236",
    country: "US",
    country_long: "United States",
    portCode: "USTIV",
    portName: "Tiverton Harbor",
    portContinent: "North America",
  },
  {
    lat: "47.2654682",
    lng: "-122.4123139",
    country: "US",
    country_long: "United States",
    portCode: "USTIW",
    portName: "Port of Tacoma",
    portContinent: "North America",
  },
  {
    lat: "57.78006979999999",
    lng: "-135.2193111",
    country: "US",
    country_long: "United States",
    portCode: "USTKE",
    portName: "Port of Tenakee Springs",
    portContinent: "North America",
  },
  {
    lat: "44.62387400000001",
    lng: "-123.931561",
    country: "US",
    country_long: "United States",
    portCode: "USTOL",
    portName: "Port of Toledo",
    portContinent: "North America",
  },
  {
    lat: "27.9503837",
    lng: "-82.4450219",
    country: "US",
    country_long: "United States",
    portCode: "USTPA",
    portName: "Tampa Port Authority",
    portContinent: "North America",
  },
  {
    lat: "27.7691937",
    lng: "-82.7689906",
    country: "US",
    country_long: "United States",
    portCode: "USTSU",
    portName: "Treasure Island (USTSU), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.2205824",
    lng: "-74.759717",
    country: "US",
    country_long: "United States",
    portCode: "USTTN",
    portName: "Port of Trenton",
    portContinent: "North America",
  },
  {
    lat: "40.5083408",
    lng: "-74.23554039999999",
    country: "US",
    country_long: "United States",
    portCode: "USTTV",
    portName: "Tottenville (Staten) (USTTV), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "32.2539787",
    lng: "-110.9741769",
    country: "US",
    country_long: "United States",
    portCode: "USTUC",
    portName: "Tuscon",
    portContinent: "North America",
  },
  {
    lat: "36.1539816",
    lng: "-95.99277500000001",
    country: "US",
    country_long: "United States",
    portCode: "USTUL",
    portName: "Tulsa, OK",
    portContinent: "North America",
  },
  {
    lat: "32.2539787",
    lng: "-110.9741769",
    country: "US",
    country_long: "United States",
    portCode: "USTUZ",
    portName: "TUCSON, AZ",
    portContinent: "North America",
  },
  {
    lat: "44.7630567",
    lng: "-85.62063169999999",
    country: "US",
    country_long: "United States",
    portCode: "USTVC",
    portName: "Traverse City (USTVC), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "48.11703869999999",
    lng: "-122.7604472",
    country: "US",
    country_long: "United States",
    portCode: "USTWD",
    portName: "Port Townsend (USTWD), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "44.26946119999999",
    lng: "-83.5146973",
    country: "US",
    country_long: "United States",
    portCode: "USTWY",
    portName: "Tawas City (USTWY), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "26.2034071",
    lng: "-98.23001239999999",
    country: "US",
    country_long: "United States",
    portCode: "USTXC",
    portName: "McAllen",
    portContinent: "North America",
  },
  {
    lat: "29.3703966",
    lng: "-94.89322849999999",
    country: "US",
    country_long: "United States",
    portCode: "USTXT",
    portName: "Port of Texas City",
    portContinent: "North America",
  },
  {
    lat: "35.9606384",
    lng: "-83.9207392",
    country: "US",
    country_long: "United States",
    portCode: "USTYS",
    portName: "Knoxville (USTYS), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "27.8339158",
    lng: "-97.06109939999999",
    country: "US",
    country_long: "United States",
    portCode: "USTZS",
    portName: "Port Aransas (USTZS), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "53.903426",
    lng: "-166.5287497",
    country: "US",
    country_long: "United States",
    portCode: "USUAA",
    portName: "Port of Dutch Harbor",
    portContinent: "North America",
  },
  {
    lat: "39.2437263",
    lng: "-76.52975789999999",
    country: "US",
    country_long: "United States",
    portCode: "USUFG",
    portName: "Port of Chesapeake",
    portContinent: "North America",
  },
  {
    lat: "42.3636331",
    lng: "-87.84479379999999",
    country: "US",
    country_long: "United States",
    portCode: "USUGN",
    portName: "Port of Waukegan",
    portContinent: "North America",
  },
  {
    lat: "39.9356016",
    lng: "-91.4098726",
    country: "US",
    country_long: "United States",
    portCode: "USUIN",
    portName: "Quincy Il (USUIN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "45.9188446",
    lng: "-119.2896953",
    country: "US",
    country_long: "United States",
    portCode: "USUMA",
    portName: "Port of Umatilla",
    portContinent: "North America",
  },
  {
    lat: "29.5427168",
    lng: "-90.3381361",
    country: "US",
    country_long: "United States",
    portCode: "USUOF",
    portName: "Cut Off (USUOF), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "33.836081",
    lng: "-81.1637245",
    country: "US",
    country_long: "United States",
    portCode: "USUSC",
    portName: "S. CAROLINA",
    portContinent: "North America",
  },
  {
    lat: "39.900076",
    lng: "-79.71643259999999",
    country: "US",
    country_long: "United States",
    portCode: "USUT2",
    portName: "Uniontown (USUT2), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.98532323157267",
    lng: "-73.66134697193269",
    country: "US",
    country_long: "United States",
    portCode: "USUXZ",
    portName: "Port Chester Harbor",
    portContinent: "North America",
  },
  {
    lat: "45.64329439999999",
    lng: "-122.7038706",
    country: "US",
    country_long: "United States",
    portCode: "USVAN",
    portName: "Port of Vancouver",
    portContinent: "North America",
  },
  {
    lat: "61.1109528",
    lng: "-146.4770541",
    country: "US",
    country_long: "United States",
    portCode: "USVDZ",
    portName: "Port of Valdez",
    portContinent: "North America",
  },
  {
    lat: "32.3526456",
    lng: "-90.877882",
    country: "US",
    country_long: "United States",
    portCode: "USVKS",
    portName: "Port of Vicksburg",
    portContinent: "North America",
  },
  {
    lat: "39.0345062",
    lng: "-84.3902174",
    country: "US",
    country_long: "United States",
    portCode: "USVKY",
    portName: "Silver Grove (USVKY), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.4848393",
    lng: "-75.8246563",
    country: "US",
    country_long: "United States",
    portCode: "USVMD",
    portName: "Vienna (USVMD), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "37.09024",
    lng: "-95.712891",
    country: "US",
    country_long: "United States",
    portCode: "USVN3",
    portName: "Venice (USVN3), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "45.05058",
    lng: "-93.06930080000001",
    country: "US",
    country_long: "United States",
    portCode: "USVSH",
    portName: "Vadnais Heights",
    portContinent: "North America",
  },
  {
    lat: "38.6270025",
    lng: "-90.19940419999999",
    country: "US",
    country_long: "United States",
    portCode: "USVTR",
    portName: "St louis, Mo",
    portContinent: "North America",
  },
  {
    lat: "39.3403942",
    lng: "-74.4773735",
    country: "US",
    country_long: "United States",
    portCode: "USVYA",
    portName: "Ventnor City (USVYA), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.9071923",
    lng: "-77.0368707",
    country: "US",
    country_long: "United States",
    portCode: "USWAS",
    portName: "WASHINGTON DC",
    portContinent: "North America",
  },
  {
    lat: "45.7277747",
    lng: "-121.4866994",
    country: "US",
    country_long: "United States",
    portCode: "USWEI",
    portName: "White Salmon (USWEI), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "21.4427921",
    lng: "-158.1870783",
    country: "US",
    country_long: "United States",
    portCode: "USWH7",
    portName: "Waianae (USWH7), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "46.4248295",
    lng: "-117.1115273",
    country: "US",
    country_long: "United States",
    portCode: "USWMA",
    portName: "Port of Wilma",
    portContinent: "North America",
  },
  {
    lat: "41.5264977",
    lng: "-70.6730857",
    country: "US",
    country_long: "United States",
    portCode: "USWOH",
    portName: "Woods Hole (USWOH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.7985699",
    lng: "-74.23908279999999",
    country: "US",
    country_long: "United States",
    portCode: "USWOR",
    portName: "West Orange",
    portContinent: "North America",
  },
  {
    lat: "41.1414717",
    lng: "-73.3579049",
    country: "US",
    country_long: "United States",
    portCode: "USWPT",
    portName: "Westport (USWPT), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "60.7745903",
    lng: "-148.6858408",
    country: "US",
    country_long: "United States",
    portCode: "USWQF",
    portName: "Port of Whittier",
    portContinent: "North America",
  },
  {
    lat: "47.3789852",
    lng: "-122.6426329",
    country: "US",
    country_long: "United States",
    portCode: "USWQU",
    portName: "Wauna Range (USWQU), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "56.4706104",
    lng: "-132.3815263",
    country: "US",
    country_long: "United States",
    portCode: "USWRG",
    portName: "Port of Wrangell",
    portContinent: "North America",
  },
  {
    lat: "38.861159",
    lng: "-90.0976069",
    country: "US",
    country_long: "United States",
    portCode: "USWRV",
    portName: "Wood River (USWRV), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "18.4655394",
    lng: "-66.1057355",
    country: "PR",
    country_long: "Puerto Rico",
    portCode: "USWSJ",
    portName: "San Juan",
    portContinent: "North America",
  },
  {
    lat: "38.7134516",
    lng: "-77.7952712",
    country: "US",
    country_long: "United States",
    portCode: "USWTO",
    portName: "Warrenton (USWTO), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "38.5503577",
    lng: "-82.7779437",
    country: "US",
    country_long: "United States",
    portCode: "USWUR",
    portName: "Wurtland (USWUR), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "45.5786027",
    lng: "-122.3805021",
    country: "US",
    country_long: "United States",
    portCode: "USWXG",
    portName: "Port of Camas-Washougal",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "USWYD",
    portName: "Wyandotte Harbor",
    portContinent: "North America",
  },
  {
    lat: "34.7659145",
    lng: "-84.7699385",
    country: "US",
    country_long: "United States",
    portCode: "USXDT",
    portName: "CHATSWORTH, GA",
    portContinent: "North America",
  },
  {
    lat: "36.188987",
    lng: "-95.74581700000002",
    country: "US",
    country_long: "United States",
    portCode: "USXEL",
    portName: "Catoosa (USXEL), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "39.4850848",
    lng: "-80.1425781",
    country: "US",
    country_long: "United States",
    portCode: "USXFI",
    portName: "Fairmont (USXFI), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "36.0395247",
    lng: "-114.9817213",
    country: "US",
    country_long: "United States",
    portCode: "USXGD",
    portName: "Henderson (USXGD), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "43.2994285",
    lng: "-74.21793260000001",
    country: "US",
    country_long: "United States",
    portCode: "USXJY",
    portName: "Orcas (USXJY), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "32.9090739",
    lng: "-96.4500618",
    country: "US",
    country_long: "United States",
    portCode: "USXLI",
    portName: "Rockwall, TX",
    portContinent: "North America",
  },
  {
    lat: "41.02141640646118",
    lng: "-73.53732648565779",
    country: "US",
    country_long: "United States",
    portCode: "USXLX",
    portName: "Stamford Harbor",
    portContinent: "North America",
  },
  {
    lat: "33.9182092",
    lng: "-78.01930109999999",
    country: "US",
    country_long: "United States",
    portCode: "USXMK",
    portName: "Southport (USXMK), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "36.5864488",
    lng: "-89.52785469999999",
    country: "US",
    country_long: "United States",
    portCode: "USXNW",
    portName: "New Madrid (USXNW), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "43.4220625",
    lng: "-123.2984101",
    country: "US",
    country_long: "United States",
    portCode: "USXOA",
    portName: "Oakland, OR  (USXOA), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "25.9428707",
    lng: "-80.1233802",
    country: "US",
    country_long: "United States",
    portCode: "USXSU",
    portName: "Sunny Isles (USXSU), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "35.5174913",
    lng: "-86.5804473",
    country: "US",
    country_long: "United States",
    portCode: "USXTN",
    portName: "Tiburon (USXTN), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "59.54533929999999",
    lng: "-139.7268441",
    country: "US",
    country_long: "United States",
    portCode: "USYAK",
    portName: "Port of Yakutat",
    portContinent: "North America",
  },
  {
    lat: "31.2914585",
    lng: "-105.860307",
    country: "US",
    country_long: "United States",
    portCode: "USYHO",
    portName: "Fort Hancock (USYHO), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "47.4473204",
    lng: "-122.4598502",
    country: "US",
    country_long: "United States",
    portCode: "USYVI",
    portName: "Vashon Island (USYVI), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "44.2230691",
    lng: "-95.4696578",
    country: "US",
    country_long: "United States",
    portCode: "USYXG",
    portName: "Walnut Grove (USYXG), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "32.3182314",
    lng: "-86.902298",
    country: "US",
    country_long: "United States",
    portCode: "USZAC",
    portName: "ALAMBAMA",
    portContinent: "North America",
  },
  {
    lat: "41.7453829",
    lng: "-70.618087",
    country: "US",
    country_long: "United States",
    portCode: "USZBG",
    portName: "Buzzards Bay (USZBG), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "33.225724",
    lng: "-91.79755449999999",
    country: "US",
    country_long: "United States",
    portCode: "USZDT",
    portName: "Hamburg, AR",
    portContinent: "North America",
  },
  {
    lat: "42.8125913",
    lng: "-70.87727509999999",
    country: "US",
    country_long: "United States",
    portCode: "USZGK",
    portName: "Newburyport (USZGK), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.1136928",
    lng: "-74.0376355",
    country: "US",
    country_long: "United States",
    portCode: "USZMQ",
    portName: "Manasquan (USZMQ), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "39.372243",
    lng: "-77.27098649999999",
    country: "US",
    country_long: "United States",
    portCode: "USZMR",
    portName: "Monrovia ,MD,United States",
    portContinent: "North America",
  },
  {
    lat: "40.7934322",
    lng: "-73.41512139999999",
    country: "US",
    country_long: "United States",
    portCode: "USZMV",
    portName: "Port of Melville",
    portContinent: "North America",
  },
  {
    lat: "46.518016",
    lng: "-95.37614649999999",
    country: "US",
    country_long: "United States",
    portCode: "USZNY",
    portName: "New York Mills, MN (USZNY), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.7472873",
    lng: "-80.3352364",
    country: "US",
    country_long: "United States",
    portCode: "USZPA",
    portName: "Patterson (USZPA), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "40.9009313",
    lng: "-73.3431727",
    country: "US",
    country_long: "United States",
    portCode: "USZZH",
    portName: "Northport (USZZH), United States of America, usa",
    portContinent: "North America",
  },
  {
    lat: "-32.522779",
    lng: "-55.765835",
    country: "UY",
    country_long: "Uruguay",
    portCode: "UYCAR",
    portName: "Carmelo (UYCAR), Uruguay, Sam",
    portContinent: "South America",
  },
  {
    lat: "-34.4737897",
    lng: "-57.84381509999999",
    country: "UY",
    country_long: "Uruguay",
    portCode: "UYCYR",
    portName: "Puerto de Colonia",
    portContinent: "South America",
  },
  {
    lat: "-33.1104819",
    lng: "-58.3146254",
    country: "UY",
    country_long: "Uruguay",
    portCode: "UYFZB",
    portName: "Puerto de Fray Bentos",
    portContinent: "South America",
  },
  {
    lat: "-34.8359146",
    lng: "-54.6451455",
    country: "UY",
    country_long: "Uruguay",
    portCode: "UYJIT",
    portName: "Port of Jose Ignacio",
    portContinent: "South America",
  },
  {
    lat: "33.73954589643058",
    lng: "-118.2599686241527",
    country: "US",
    country_long: "United States",
    portCode: "UYLAP",
    portName: "Port of La Paloma",
    portContinent: "South America",
  },
  {
    lat: "-32.522779",
    lng: "-55.765835",
    country: "UY",
    country_long: "Uruguay",
    portCode: "UYMER",
    portName: "Mercedes (UYMER), Uruguay, Sam",
    portContinent: "South America",
  },
  {
    lat: "-34.9022412",
    lng: "-56.2086178",
    country: "UY",
    country_long: "Uruguay",
    portCode: "UYMVD",
    portName: "Puerto de Montevideo",
    portContinent: "South America",
  },
  {
    lat: "-32.9809545",
    lng: "-58.04700080000001",
    country: "UY",
    country_long: "Uruguay",
    portCode: "UYNVN",
    portName: "Nuevo Berlin (UYNVN), Uruguay, Sam",
    portContinent: "South America",
  },
  {
    lat: "-33.8666667",
    lng: "-58.4166667",
    country: "UY",
    country_long: "Uruguay",
    portCode: "UYNVP",
    portName: "Puerto de Nueva Palmira",
    portContinent: "South America",
  },
  {
    lat: "-34.9363205",
    lng: "-54.9377826",
    country: "UY",
    country_long: "Uruguay",
    portCode: "UYPDP",
    portName: "Port of Punta del Este",
    portContinent: "South America",
  },
  {
    lat: "-32.3112903",
    lng: "-58.0757701",
    country: "UY",
    country_long: "Uruguay",
    portCode: "UYPDU",
    portName: "Port of Paysandu",
    portContinent: "South America",
  },
  {
    lat: "-34.8643645",
    lng: "-55.2716278",
    country: "UY",
    country_long: "Uruguay",
    portCode: "UYPIR",
    portName: "Piriapolis (UYPIR), Uruguay, Sam",
    portContinent: "South America",
  },
  {
    lat: "-34.2278778",
    lng: "-58.06559009999999",
    country: "UY",
    country_long: "Uruguay",
    portCode: "UYPTP",
    portName: "Punta Pereira (UYPTP), Uruguay, Sam",
    portContinent: "South America",
  },
  {
    lat: "-31.388028",
    lng: "-57.9612455",
    country: "UY",
    country_long: "Uruguay",
    portCode: "UYSTY",
    portName: "Salto (UYSTY), Uruguay, Sam",
    portContinent: "South America",
  },
  {
    lat: "41.2994958",
    lng: "69.2400734",
    country: "UZ",
    country_long: "Uzbekistan",
    portCode: "UZTAS",
    portName: "TASHKENT (UZTAS), Tashkent, Uzbekistan",
    portContinent: "Central Asia",
  },
  {
    lat: "13.0117059",
    lng: "-61.2272312",
    country: "VC",
    country_long: "Saint Vincent and the Grenadines",
    portCode: "VCBQU",
    portName: "Bequia (VCBQU), Saint Vincent and the Grenadines, LatAm",
    portContinent: "North America",
  },
  {
    lat: "12.7254927",
    lng: "-61.3239372",
    country: "VC",
    country_long: "Saint Vincent and the Grenadines",
    portCode: "VCCAN",
    portName: "Canouan (VCCAN), Saint Vincent and the Grenadines, LatAm",
    portContinent: "North America",
  },
  {
    lat: "13.1734705",
    lng: "-61.24448930000001",
    country: "VC",
    country_long: "Saint Vincent and the Grenadines",
    portCode: "VCCRP",
    portName:
      "Campden Park (VCCRP), Kingstown, Saint Vincent and the Grenadine",
    portContinent: "North America",
  },
  {
    lat: "17.7227672",
    lng: "-64.78223679999999",
    country: "VI",
    country_long: "U.S. Virgin Islands",
    portCode: "VCKGK",
    portName: "KINGSHILL",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "VCKTN",
    portName: "Port of Kingstown",
    portContinent: "North America",
  },
  {
    lat: "12.881024",
    lng: "-61.1834126",
    country: "VC",
    country_long: "Saint Vincent and the Grenadines",
    portCode: "VCMQS",
    portName: "Mustique (VCMQS), Saint Vincent and the Grenadines, LatAm",
    portContinent: "North America",
  },
  {
    lat: "12.5868094",
    lng: "-61.3963103",
    country: "VC",
    country_long: "Saint Vincent and the Grenadines",
    portCode: "VCPLI",
    portName: "Palm Island (VCPLI), Saint Vincent and the Grenadines, LatAm",
    portContinent: "North America",
  },
  {
    lat: "45.7514541",
    lng: "7.6680345",
    country: "IT",
    country_long: "Italy",
    portCode: "VCSVD",
    portName: "Saint Vincent",
    portContinent: "North America",
  },
  {
    lat: "9.9648655",
    lng: "-71.13296849999999",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEBAV",
    portName: "Bachaquero (VEBAV), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.4826633",
    lng: "-71.6376694",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEBJV",
    portName: "Bajo Grande (VEBJV), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "6.42375",
    lng: "-66.58973",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEBOC",
    portName: "Boca Grande (VEBOC), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "8.088279100000001",
    lng: "-63.5535552",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VECBL",
    portName: "Ciudad Bolivar (VECBL), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.3895862",
    lng: "-71.4692843",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VECBS",
    portName: "Cabimas (VECBS), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.4805937",
    lng: "-66.90360629999999",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VECCS",
    portName: "Caracas",
    portContinent: "South America",
  },
  {
    lat: "8.3663119",
    lng: "-62.6496813",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VECGU",
    portName: "Guayana City (VECGU), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.6768",
    lng: "-71.6376694",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VECHQ",
    portName: "Chiquinquira (VECHQ), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.9360102",
    lng: "-68.2772875",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VECHV",
    portName: "Chichiriviche (VECHV), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.5894439",
    lng: "-67.04943779999999",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VECLM",
    portName: "Catia La Mar (VECLM), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.431916",
    lng: "-64.18331599999999",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VECUM",
    portName: "Cumana (VECUM), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.6429802",
    lng: "-63.25624819999999",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VECUP",
    portName: "Carupano (VECUP), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "7.612897199999999",
    lng: "-66.1474752",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VECXA",
    portName: "Caicara Del Orinoco (VECXA), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.8958956",
    lng: "-64.0665046",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEEGU",
    portName: "El Guamache (VEEGU), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.4786328",
    lng: "-68.1163126",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEELP",
    portName: "El Palito (VEELP), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.5969183",
    lng: "-71.6414953",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEETV",
    portName: "El Tablazo/Maracaibo L",
    portContinent: "South America",
  },
  {
    lat: "11.6777778",
    lng: "-70.2005556",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEGUA",
    portName: "Guaranao",
    portContinent: "South America",
  },
  {
    lat: "11.6777778",
    lng: "-70.2005556",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEGUB",
    portName: "Punta Guaranao (VEGUB), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.584981",
    lng: "-62.30371759999999",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEGUI",
    portName: "Guiria (VEGUI), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.2434007",
    lng: "-64.5964316",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEGUT",
    portName: "Port of Guanta",
    portContinent: "South America",
  },
  {
    lat: "10.9970723",
    lng: "-63.91132959999999",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEIDM",
    portName: "Isla De Margarita",
    portContinent: "South America",
  },
  {
    lat: "8.4768986",
    lng: "-63.4862036",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEIND",
    portName: "Independencia (VEIND), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "6.42375",
    lng: "-66.58973",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEJOT",
    portName: "Jose Terminal (VEJOT), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.6012894",
    lng: "-66.9466783",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VELAG",
    portName: "Port of La Guaira",
    portContinent: "South America",
  },
  {
    lat: "10.1265448",
    lng: "-71.2458436",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VELGY",
    portName: "Lagunillas (VELGY), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.6428964",
    lng: "-71.6013251",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEMAR",
    portName: "Port of Maracaibo",
    portContinent: "South America",
  },
  {
    lat: "8.316666999999999",
    lng: "-62.8",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEMTV",
    portName: "Matanzas (VEMTV), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "45.4408474",
    lng: "12.3155151",
    country: "IT",
    country_long: "Italy",
    portCode: "VENVNT",
    portName: "VENICE",
    portContinent: "Europe",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "VEPBL",
    portName: "Port of Puerto Cabello",
    portContinent: "South America",
  },
  {
    lat: "11.6481819",
    lng: "-70.20511479999999",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEPCN",
    portName: "Punta Cardon (VEPCN), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.2007985",
    lng: "-64.6334643",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEPCZ",
    portName: "Puerto La Cruz (VEPCZ), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "11.711511",
    lng: "-70.1813018",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEPFI",
    portName: "Port of Punto Fijo",
    portContinent: "South America",
  },
  {
    lat: "7.514979999999999",
    lng: "134.58252",
    country: "PW",
    country_long: "Palau",
    portCode: "VEPLA",
    portName: "Palua",
    portContinent: "South America",
  },
  {
    lat: "10.9015165",
    lng: "-64.0857232",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEPPS",
    portName: "Punta De Piedras (VEPPS), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "10.5049651",
    lng: "-66.91118",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEPSU",
    portName: "Sucre (VEPSU), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "5.6614718",
    lng: "-67.58277439999999",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEPYH",
    portName: "Puerto Ayacucho (VEPYH), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "8.2750921",
    lng: "-62.76766329999999",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEPZO",
    portName: "Puerto Ordaz (VEPZO), Venezuela, LatAm",
    portContinent: "South America",
  },
  {
    lat: "-26.2914678",
    lng: "-80.0953942",
    country: "CL",
    country_long: "Chile",
    portCode: "VESFX",
    portName: "Port of San Felix",
    portContinent: "South America",
  },
  {
    lat: "10.1579312",
    lng: "-67.9972104",
    country: "VE",
    country_long: "Venezuela",
    portCode: "VEVLN",
    portName: "VALENCIA (VZ)",
    portContinent: "South America",
  },
  {
    lat: "18.0166835",
    lng: "-76.9748123",
    country: "JM",
    country_long: "Jamaica",
    portCode: "VGOP6",
    portName: "SPANISH TOWN",
    portContinent: "North America",
  },
  {
    lat: "18.4286115",
    lng: "-64.6184657",
    country: "VG",
    country_long: "British Virgin Islands",
    portCode: "VGRAD",
    portName: "Road Town (VGRAD), British Virgin Islands, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.4334704",
    lng: "-64.63327819999999",
    country: "VG",
    country_long: "British Virgin Islands",
    portCode: "VGTOV",
    portName: "TORTOLA",
    portContinent: "North America",
  },
  {
    lat: "18.4939059",
    lng: "-64.40228770000002",
    country: "VG",
    country_long: "British Virgin Islands",
    portCode: "VGVIJ",
    portName: "Port of Virgin Gorda",
    portContinent: "North America",
  },
  {
    lat: "18.3419004",
    lng: "-64.9307007",
    country: "VI",
    country_long: "U.S. Virgin Islands",
    portCode: "VICHA",
    portName:
      "Charlotte Amalie, Saint Thomas (VICHA), U.S Virgin Islands, LatA",
    portContinent: "North America",
  },
  {
    lat: "17.7466397",
    lng: "-64.7031977",
    country: "VI",
    country_long: "U.S. Virgin Islands",
    portCode: "VICTD",
    portName: "Christiansted, Saint Croix (VICTD), U.S Virgin Islands, LatAm",
    portContinent: "North America",
  },
  {
    lat: "18.331343",
    lng: "-64.79374969999999",
    country: "VI",
    country_long: "U.S. Virgin Islands",
    portCode: "VICZB",
    portName: "Cruz Bay, St. John",
    portContinent: "North America",
  },
  {
    lat: "17.7124746",
    lng: "-64.8815352",
    country: "VI",
    country_long: "U.S. Virgin Islands",
    portCode: "VIFRD",
    portName: "Frederiksted",
    portContinent: "North America",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "VIPAX",
    portName: "Port Alucroix",
    portContinent: "North America",
  },
  {
    lat: "18.3380965",
    lng: "-64.8940946",
    country: "VI",
    country_long: "U.S. Virgin Islands",
    portCode: "VISTT",
    portName: "Saint Thomas",
    portContinent: "North America",
  },
  {
    lat: "17.7245968",
    lng: "-64.83479919999999",
    country: "VI",
    country_long: "U.S. Virgin Islands",
    portCode: "VISTX",
    portName: "Saint Croix",
    portContinent: "North America",
  },
  {
    lat: "10.508928",
    lng: "107.1816257",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VN7BR",
    portName: "Ba Ria",
    portContinent: "South East Asia",
  },
  {
    lat: "11.3254024",
    lng: "106.477017",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNBDG",
    portName: "Binh Duong",
    portContinent: "South East Asia",
  },
  {
    lat: "13.8860211",
    lng: "109.1077389",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNBDH",
    portName: "BINH DINH",
    portContinent: "South East Asia",
  },
  {
    lat: "11.3254024",
    lng: "106.477017",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNBDU",
    portName: "BINH DUONG",
    portContinent: "South East Asia",
  },
  {
    lat: "10.9574128",
    lng: "106.8426871",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNBHA",
    portName: "Bien Hoa",
    portContinent: "South East Asia",
  },
  {
    lat: "11.9075641",
    lng: "109.1226018",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNBNO",
    portName: "Ba Ngoi (VNBNO), Vietnam, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "11.0903703",
    lng: "108.0720781",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNBTN",
    portName: "BINH THUAN",
    portContinent: "South East Asia",
  },
  {
    lat: "10.2433556",
    lng: "106.375551",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNBTR",
    portName: "BEN TRE",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "VNC8Q",
    portName: "Chu Lai",
    portContinent: "South East Asia",
  },
  {
    lat: "9.1526728",
    lng: "105.1960795",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNCAH",
    portName: "CA MAU",
    portContinent: "South East Asia",
  },
  {
    lat: "20.9722284",
    lng: "107.0143435",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNCAI",
    portName: "Cai Lan (VNCAI), Vietnam, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "22.6666369",
    lng: "106.2639852",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNCBG",
    portName: "CAO BANG",
    portContinent: "South East Asia",
  },
  {
    lat: "10.7708268",
    lng: "106.7853922",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNCLI",
    portName: "Cat lai",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "VNCLN",
    portName: "Cai Lan",
    portContinent: "South East Asia",
  },
  {
    lat: "14.058324",
    lng: "108.277199",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNCMT",
    portName: "Cai Mep (VNCMT), Vietnam, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "21.0718937",
    lng: "107.3201023",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNCPH",
    portName: "Cam Pha (VNCPH), Vietnam, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "VNDAD",
    portName: "Da Nang Port",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "VNDH2",
    portName: "DONG HA, ÄÃ´ng HÃ",
    portContinent: "South East Asia",
  },
  {
    lat: "10.8964757",
    lng: "106.7527418",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNDI2",
    portName: "Di An",
    portContinent: "South East Asia",
  },
  {
    lat: "11.9404192",
    lng: "108.4583132",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNDLI",
    portName: "DALAT",
    portContinent: "South East Asia",
  },
  {
    lat: "11.0686305",
    lng: "107.1675976",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNDNA",
    portName: "Dong Nai",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "VNDOH",
    portName: "DONG HOI, ÄÃ´ng Há»™i",
    portContinent: "South East Asia",
  },
  {
    lat: "15.4145694",
    lng: "108.8003634",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNDQT",
    portName: "Dung Quat Port",
    portContinent: "South East Asia",
  },
  {
    lat: "13.8078943",
    lng: "108.109375",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNGLI",
    portName: "Gia Lai",
    portContinent: "South East Asia",
  },
  {
    lat: "21.0277644",
    lng: "105.8341598",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNHAN",
    portName: "Hanoi",
    portContinent: "South East Asia",
  },
  {
    lat: "20.9385958",
    lng: "106.3206861",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNHDG",
    portName: "Haiduong",
    portContinent: "South East Asia",
  },
  {
    lat: "15.8800584",
    lng: "108.3380469",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNHIA",
    portName: "HOI AN",
    portContinent: "South East Asia",
  },
  {
    lat: "20.9711977",
    lng: "107.0448069",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNHON",
    portName: "Hon Gai (VNHON), Vietnam, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "20.8449115",
    lng: "106.6880841",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNHPH",
    portName: "Haiphong",
    portContinent: "South East Asia",
  },
  {
    lat: "10.6467507",
    lng: "106.7419209",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNHPP",
    portName: "TAN CANG HIEP PHUOC",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "VNHUI",
    portName: "HUE",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "VNIHV",
    portName: "VNIHV",
    portContinent: "South East Asia",
  },
  {
    lat: "10.913208",
    lng: "106.9028549",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNILB",
    portName: "Tan Cang - Long Binh",
    portContinent: "South East Asia",
  },
  {
    lat: "11.8337331",
    lng: "106.9948945",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNIPH",
    portName: "HO CHI MINH PHUOC LONG ICD",
    portContinent: "South East Asia",
  },
  {
    lat: "10.8236586",
    lng: "106.7540377",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNITX",
    portName: "ICD TRANSIMEX",
    portContinent: "South East Asia",
  },
  {
    lat: "12.2585098",
    lng: "109.0526076",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNKHI",
    portName: "Khanh Hoa",
    portContinent: "South East Asia",
  },
  {
    lat: "18.0926366",
    lng: "106.3404925",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNKYH",
    portName: "KY HA",
    portContinent: "South East Asia",
  },
  {
    lat: "18.0926366",
    lng: "106.3404925",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNKYQ",
    portName: "KY HA, Ká»³ HÃ",
    portContinent: "South East Asia",
  },
  {
    lat: "10.695572",
    lng: "106.2431205",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNLAN",
    portName: "LONG AN",
    portContinent: "South East Asia",
  },
  {
    lat: "16.0717704",
    lng: "108.1503823",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNLCB",
    portName: "LIEN CHIEU CITY",
    portContinent: "South East Asia",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "VNLMP",
    portName: "LEE MAN PORT",
    portContinent: "South East Asia",
  },
  {
    lat: "10.3765284",
    lng: "106.3438891",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNMUT",
    portName: "MY THO",
    portContinent: "South East Asia",
  },
  {
    lat: "19.3286153",
    lng: "105.8202234",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNNGH",
    portName: "NGHI SON",
    portContinent: "South East Asia",
  },
  {
    lat: "12.2529152",
    lng: "109.1899018",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNNHA",
    portName: "Nha Trang (VNNHA), Vietnam, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "20.4388225",
    lng: "106.1621053",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNNMD",
    portName: "Nam Dinh (VNNMD), Vietnam, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "11.6738767",
    lng: "108.8629572",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNNTN",
    portName: "NINH THUAN",
    portContinent: "South East Asia",
  },
  {
    lat: "10.7708268",
    lng: "106.7853922",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNOCL",
    portName: "Cat lai",
    portContinent: "South East Asia",
  },
  {
    lat: "15.1213873",
    lng: "108.8044145",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNOPC",
    portName: "QUANG NGAI",
    portContinent: "South East Asia",
  },
  {
    lat: "11.8337331",
    lng: "106.9948945",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNPHG",
    portName: "PHUOC LONG  (VNPHG), Vietnam, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "10.5905345",
    lng: "107.0480378",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNPHU",
    portName: "Phu My (VNPHU), Vietnam, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "10.832358",
    lng: "106.7559004",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNPX1",
    portName: "ICD Phuoc Long 1",
    portContinent: "South East Asia",
  },
  {
    lat: "10.834768",
    lng: "106.7617517",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNPXH",
    portName: "ICD Phuoc Long 3",
    portContinent: "South East Asia",
  },
  {
    lat: "13.9718356",
    lng: "108.0150796",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNPXU",
    portName: "PLEIKU CITY",
    portContinent: "South East Asia",
  },
  {
    lat: "15.5393538",
    lng: "108.019102",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNQNM",
    portName: "QUANG NAM",
    portContinent: "South East Asia",
  },
  {
    lat: "16.7403074",
    lng: "107.1854679",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNQUT",
    portName: "QUANG TRI",
    portContinent: "South East Asia",
  },
  {
    lat: "10.2901261",
    lng: "105.7517453",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNSAD",
    portName: "SA DEC",
    portContinent: "South East Asia",
  },
  {
    lat: "10.8230989",
    lng: "106.6296638",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNSGN",
    portName: "Port of Ho Chi Minh City",
    portContinent: "South East Asia",
  },
  {
    lat: "9.602521",
    lng: "105.9739049",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNSOA",
    portName: "SOC TRANG",
    portContinent: "South East Asia",
  },
  {
    lat: "10.6467507",
    lng: "106.7419209",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNSPC",
    portName: "Hiep Phuoc Port",
    portContinent: "South East Asia",
  },
  {
    lat: "10.8227606",
    lng: "106.7571883",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNSTR",
    portName: "Icd Sotrans",
    portContinent: "South East Asia",
  },
  {
    lat: "10.8214825",
    lng: "106.7586779",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNTAM",
    portName: "Icd Tanamexco",
    portContinent: "South East Asia",
  },
  {
    lat: "33.7456062",
    lng: "-117.9281682",
    country: "US",
    country_long: "United States",
    portCode: "VNTCG",
    portName: "Tan Cang",
    portContinent: "South East Asia",
  },
  {
    lat: "10.5339226",
    lng: "107.0343964",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNTCT",
    portName: "Cai Mep TICT",
    portContinent: "South East Asia",
  },
  {
    lat: "12.6661944",
    lng: "108.0382475",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNTHQ",
    portName: "Buon Ma Thuot",
    portContinent: "South East Asia",
  },
  {
    lat: "15.5638292",
    lng: "108.4821677",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNTMK",
    portName: "TAMKY",
    portContinent: "South East Asia",
  },
  {
    lat: "11.3351554",
    lng: "106.1098854",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNTNT",
    portName: "TAY NINH",
    portContinent: "South East Asia",
  },
  {
    lat: "10.5850216",
    lng: "107.1551514",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNTOT",
    portName: "Cai Mep",
    portContinent: "South East Asia",
  },
  {
    lat: "10.6033973",
    lng: "106.0493226",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNTTH",
    portName: "Tan Thanh",
    portContinent: "South East Asia",
  },
  {
    lat: "18.6795848",
    lng: "105.6813333",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNTVH",
    portName: "Vinh (VNTVH), Vietnam, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "13.7829673",
    lng: "109.2196634",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNUIH",
    portName: "Qui Nhon",
    portContinent: "South East Asia",
  },
  {
    lat: "10.0451618",
    lng: "105.7468535",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNVCA",
    portName: "Can Tho",
    portContinent: "South East Asia",
  },
  {
    lat: "10.7650738",
    lng: "106.7386581",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNVIC",
    portName: "Ho Chi Minh - VICT",
    portContinent: "South East Asia",
  },
  {
    lat: "10.021507",
    lng: "105.0910974",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNVKG",
    portName: "Rach Gia (VNVKG), Vietnam, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "10.239574",
    lng: "105.9571928",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNVLG",
    portName: "VINH LONG",
    portContinent: "South East Asia",
  },
  {
    lat: "10.4113797",
    lng: "107.136224",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNVTG",
    portName: "Ving tau",
    portContinent: "South East Asia",
  },
  {
    lat: "10.4113797",
    lng: "107.136224",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNVUT",
    portName: "Vung Tau (VNVUT), Vietnam, Asia",
    portContinent: "South East Asia",
  },
  {
    lat: "10.3759416",
    lng: "105.4185406",
    country: "VN",
    country_long: "Vietnam",
    portCode: "VNXLO",
    portName: "LONG XUYEN",
    portContinent: "South East Asia",
  },
  {
    lat: "17.7245968",
    lng: "-64.83479919999999",
    country: "VI",
    country_long: "U.S. Virgin Islands",
    portCode: "VTSIX",
    portName: "Saint Croix",
    portContinent: "South America",
  },
  {
    lat: "-16.2579095",
    lng: "167.9213877",
    country: "VU",
    country_long: "Vanuatu",
    portCode: "VUCCV",
    portName: "Craig Cove (VUCCV), Vanuatu, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-15.1496325",
    lng: "168.1222336",
    country: "VU",
    country_long: "Vanuatu",
    portCode: "VUMWF",
    portName: "Maewo (VUMWF), Vanuatu, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-23.9660408",
    lng: "-46.3019437",
    country: "BR",
    country_long: "Brazil",
    portCode: "VUSAN",
    portName: "Port of Santo",
    portContinent: "Oceania",
  },
  {
    lat: "-15.3003549",
    lng: "166.9182097",
    country: "VU",
    country_long: "Vanuatu",
    portCode: "VUSON",
    portName: "Espiritu Santo (VUSON), Vanuatu, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-17.7332512",
    lng: "168.3273245",
    country: "VU",
    country_long: "Vanuatu",
    portCode: "VUVLI",
    portName: "Port Vila (VUVLI), Vanuatu, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-14.2905101",
    lng: "-178.1388989",
    country: "WF",
    country_long: "Wallis and Futuna",
    portCode: "WFFUT",
    portName: "Futuna (WFFUT), Wallis and Futuna, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-13.2825091",
    lng: "-176.1764475",
    country: "WF",
    country_long: "Wallis and Futuna",
    portCode: "WFMAU",
    portName: "MATA'UTU  (WFMAU), Wallis and Futuna, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-14.2968356",
    lng: "-178.1581081",
    country: "WF",
    country_long: "Wallis and Futuna",
    portCode: "WFSIG",
    portName: "Sigave",
    portContinent: "Oceania",
  },
  {
    lat: "-25.274398",
    lng: "133.775136",
    country: "AU",
    country_long: "Australia",
    portCode: "WFWLS",
    portName: "Wallis (WFWLS), Wallis and Futuna, Australia",
    portContinent: "Oceania",
  },
  {
    lat: "-13.8265457",
    lng: "-171.7612702",
    country: "WS",
    country_long: "Samoa",
    portCode: "WSAPW",
    portName: "Port of Apia",
    portContinent: "Oceania",
  },
  {
    lat: "12.7854969",
    lng: "45.0186548",
    country: "YE",
    country_long: "Yemen",
    portCode: "YEADE",
    portName: "Port of Aden",
    portContinent: "Middle East",
  },
  {
    lat: "14.7634494",
    lng: "49.6044141",
    country: "YE",
    country_long: "Yemen",
    portCode: "YEASR",
    portName: "Ash Shihr Terminal",
    portContinent: "Middle East",
  },
  {
    lat: "13.9968809",
    lng: "48.184644",
    country: "YE",
    country_long: "Yemen",
    portCode: "YEBLH",
    portName: "Balhaf (YEBLH), Yemen, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "14.7909118",
    lng: "42.9708838",
    country: "YE",
    country_long: "Yemen",
    portCode: "YEHOD",
    portName: "Port of Hodeidah",
    portContinent: "Middle East",
  },
  {
    lat: "14.5203178",
    lng: "49.1513559",
    country: "YE",
    country_long: "Yemen",
    portCode: "YEMKX",
    portName: "Port of Mukalla",
    portContinent: "Middle East",
  },
  {
    lat: "13.3203021",
    lng: "43.2473146",
    country: "YE",
    country_long: "Yemen",
    portCode: "YEMOK",
    portName: "Mokha (YEMOK), Yemen, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "15.82001601946591",
    lng: "52.19612543795458",
    country: "YE",
    country_long: "Yemen",
    portCode: "YENIS",
    portName: "Port of Nishtun",
    portContinent: "Middle East",
  },
  {
    lat: "15.552727",
    lng: "48.516388",
    country: "YE",
    country_long: "Yemen",
    portCode: "YESAL",
    portName: "Saleef (YESAL), Yemen, Meg",
    portContinent: "Middle East",
  },
  {
    lat: "14.5300224",
    lng: "49.1357723",
    country: "YE",
    country_long: "Yemen",
    portCode: "YEZAM",
    portName: "Al Mukalla (YEZAM), Al Mukalla, Yemen",
    portContinent: "Middle East",
  },
  {
    lat: "-12.7313558",
    lng: "45.1658261",
    country: "YT",
    country_long: "Mayotte",
    portCode: "YTLON",
    portName: "LONGONI (YTLON), Mayotte, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-33.5864065",
    lng: "26.8851448",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZAAFD",
    portName: "Port Alfred (ZAAFD), South Africa, Africa",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ZACPT",
    portName: "Port of Cape Town",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ZADUR",
    portName: "Port of Durban",
    portContinent: "Africa",
  },
  {
    lat: "-33.0198434",
    lng: "27.903905",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZAELS",
    portName: "Port of East London",
    portContinent: "Africa",
  },
  {
    lat: "-26.2041028",
    lng: "28.0473051",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZAJNB",
    portName: "Johannesburg (ZAJNB), Johannesburg, South Africa",
    portContinent: "Africa",
  },
  {
    lat: "-34.0350856",
    lng: "23.0464693",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZAKNY",
    portName: "Knysna (ZAKNY), South Africa, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-32.7767557",
    lng: "18.1576908",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZALUI",
    portName: "Laaiplek (ZALUI), South Africa, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-34.1807403",
    lng: "22.1472129",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZAMZY",
    portName: "Port of Mossel Bay",
    portContinent: "Africa",
  },
  {
    lat: "0",
    lng: "0",
    country: "N/A",
    country_long: "N/A",
    portCode: "ZANGQ",
    portName: "Ngqura",
    portContinent: "Africa",
  },
  {
    lat: "-29.2415382",
    lng: "16.9004472",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZANOL",
    portName: "Port Nolloth (ZANOL), South Africa, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-31.0509031",
    lng: "30.2224227",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZAPED",
    portName: "Port Edward (ZAPED), South Africa, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-33.9608369",
    lng: "25.6022423",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZAPLZ",
    portName: "Port of Port Elizabeth",
    portContinent: "Africa",
  },
  {
    lat: "-25.7478676",
    lng: "28.2292712",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZAPRY",
    portName: "PRETORIA (ZAPRY), Pretoria, South Africa",
    portContinent: "Africa",
  },
  {
    lat: "-14.4285061",
    lng: "28.451357",
    country: "ZM",
    country_long: "Zambia",
    portCode: "ZAQKE",
    portName: "KABWE",
    portContinent: "Africa",
  },
  {
    lat: "-28.82875",
    lng: "32.06066",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZARCB",
    portName: "Port of Richards Bay",
    portContinent: "Africa",
  },
  {
    lat: "-33.01545249999999",
    lng: "17.9903201",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZASDB",
    portName: "Port of Saldanha",
    portContinent: "Africa",
  },
  {
    lat: "-34.1605375",
    lng: "24.824107",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZASFB",
    portName: "St Francis Bay (ZASFB), South Africa, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-34.1927646",
    lng: "18.4359075",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZASMN",
    portName: "Naval Base Simon's Town",
    portContinent: "Africa",
  },
  {
    lat: "-33.7979321",
    lng: "25.6717085",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZAZBA",
    portName: "Coega (ZAZBA), Port Elizabeth, South Africa",
    portContinent: "Africa",
  },
  {
    lat: "-34.0208739",
    lng: "18.3682641",
    country: "ZA",
    country_long: "South Africa",
    portCode: "ZAZCF",
    portName: "Hout Bay (ZAZCF), South Africa, Africa",
    portContinent: "Africa",
  },
  {
    lat: "-12.5419553",
    lng: "27.8545867",
    country: "ZM",
    country_long: "Zambia",
    portCode: "ZMCGJ",
    portName: "CHINGOLA",
    portContinent: "Africa",
  },
  {
    lat: "41.89968289999999",
    lng: "-87.67565189999999",
    country: "US",
    country_long: "United States",
    portCode: "ZMKAA",
    portName: "Kasama",
    portContinent: "Africa",
  },
  {
    lat: "-13.9778684",
    lng: "28.6848112",
    country: "ZM",
    country_long: "Zambia",
    portCode: "ZMKAM",
    portName: "Kapiri Mposhi",
    portContinent: "Africa",
  },
  {
    lat: "-12.8231947",
    lng: "28.2175744",
    country: "ZM",
    country_long: "Zambia",
    portCode: "ZMKIW",
    portName: "KITWE  (ZMKIW), Kitwe, Zambia",
    portContinent: "Africa",
  },
  {
    lat: "-15.3875259",
    lng: "28.3228165",
    country: "ZM",
    country_long: "Zambia",
    portCode: "ZMLUN",
    portName: "Lusaka (ZMLUN), Lusaka, Zambia",
    portContinent: "Africa",
  },
  {
    lat: "-12.5502705",
    lng: "28.2409671",
    country: "ZM",
    country_long: "Zambia",
    portCode: "ZMMFR",
    portName: "Mufulira",
    portContinent: "Africa",
  },
  {
    lat: "-13.6211552",
    lng: "29.3889082",
    country: "ZM",
    country_long: "Zambia",
    portCode: "ZMMKU",
    portName: "Mkushi",
    portContinent: "Africa",
  },
  {
    lat: "-11.8280231",
    lng: "31.4513314",
    country: "ZM",
    country_long: "Zambia",
    portCode: "ZMMPK",
    portName: "Mpika",
    portContinent: "Africa",
  },
  {
    lat: "-16.2803207",
    lng: "27.4732571",
    country: "ZM",
    country_long: "Zambia",
    portCode: "ZMMZE",
    portName: "Monze",
    portContinent: "Africa",
  },
  {
    lat: "-12.9906407",
    lng: "28.6498144",
    country: "ZM",
    country_long: "Zambia",
    portCode: "ZMNLA",
    portName: "Ndola",
    portContinent: "Africa",
  },
  {
    lat: "-12.1668712",
    lng: "26.3839765",
    country: "ZM",
    country_long: "Zambia",
    portCode: "ZMSLI",
    portName: "SOLWEZI",
    portContinent: "Africa",
  },
  {
    lat: "-20.015379",
    lng: "28.6225623",
    country: "ZW",
    country_long: "Zimbabwe",
    portCode: "ZWBUQ",
    portName: "Bulawayo (ZWBUQ), Bulawayo, Zimbabwe",
    portContinent: "Africa",
  },
  {
    lat: "-17.9186996",
    lng: "31.0988617",
    country: "ZW",
    country_long: "Zimbabwe",
    portCode: "ZWHRE",
    portName: "HARARE  (ZWHRE), Harare, Zimbabwe",
    portContinent: "Africa",
  },
];

export default ports;
