import { currentENVData } from "helpers";
import { authService } from "services";

const onboardUser = (user) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(user),
  };

  let url = `${currentENVData.transitURL}/partners/user/onboarding/complete`;

  return authService
    .apiGate(url, requestOptions)
    .then(authService.handleResponse);
};

export const partnerService = {
  onboardUser,
};
