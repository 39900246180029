import Header from "components/common/Header";
import React from "react";
import styled from "styled-components";

const Hero = () => {
  return (
    <Container className="bg-black relative overflow-hidden">
      <div className="absolute w-full h-full grid"
      >
        <div className="absolute left-0 top-0 w-full h-full bg-black/60 z-10"></div>
        <video
          src={"https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/about.mp4"}
          preload="auto"
          autoPlay
          muted
          loop={true}
          playsInline={true}
          className="max-w-none object-cover z-0 w-full h-full"
        ></video>
      </div>
      <div className="rounded-lg sm:py-4 mb-20 relative z-20">
        <Header />
        <div className="px-4">
          <div className="w-[90%] sm:w-4/5 m-auto text-white">
            <p className="font-catalina pt-20 sm:pt-60 text-[2.1rem] sm:text-7xl leading-tight">
              Accelerating Trade{" "}
              <br className="hidden sm:block" />
              in Africa with Technology
            </p>
            <p className="w-full sm:w-3/5 py-8 text-sm font-light">
              MVX is a game changing technology platform built to solve the
              fundamental problem of fragmented, inefficient and expensive
              process of freight shipping in Africa.{" "}
            </p>
            {/* <div className="flex flex-wrap items-center">
              <p className="text-sm w-full sm:w-auto font-light mr-4">
                Find out about our story.
              </p>
              <div className="flex w-full sm:w-auto pt-10 sm:pt-0 items-center font-bold cursor-pointer">
                <span className="material-icons mr-2">play_circle_filled</span>
                Watch video
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background-image: url(${(props) => props.url});
  min-height: calc(100vh - 100px);
  background-size: cover;
  background-position: center;
`;

export default Hero;
