import React, { useState } from "react";
import faqs from "variables/faq";

const FAQs = () => {
  const [active, setActive] = useState(0);

  return (
    <div className="bg-mvx-orange/[0.06] py-20">
      <div className="w-[90%] sm:w-3/5 m-auto">
        <p className="font-bold text-center text-xl sm:text-2xl mb-10">
          Frequently Asked Questions
        </p>
        {faqs.slice(0, 10).map((faq, index) => (
          <React.Fragment key={index}>
            <FAQ
              i={index}
              title={faq.question}
              description={faq.answer}
              active={active === index}
              setActive={setActive}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const FAQ = ({ i, title, description, active, setActive }) => {
  return (
    <div
      className="border-b border-mvx-blue/30 px-0 sm:px-4"
      onClick={() => setActive(i)}
    >
      <div
        className={`text-sm sm:text-base py-4 px-4 flex items-center justify-between font-bold select-none cursor-pointer ${
          active && "text-mvx-blue"
        }`}
      >
        {title}
        <div className="w-[40px] h-[40px] cursor-pointer flex items-center justify-center">
          <div className="w-5 h-5 rounded-full border border-mvx-blue flex items-center justify-center">
            {active ? (
              <span className="material-icons text-mvx-blue !text-sm">
                remove
              </span>
            ) : (
              <span className="material-icons text-mvx-blue !text-sm">add</span>
            )}
          </div>
        </div>
      </div>
      <div className={`w-4/5 overflow-hidden ${active ? "max-h-96 duration-500" : "max-h-0"}`}>
        <p className={`px-4 text-xs pb-6 text-gray-700`}>
          {description}
        </p>
      </div>
    </div>
  );
};

export default FAQs;
