import { useEffect, useState } from "react";
import { shipmentsService } from "services";

const useMovementsLocations = (movements) => {
  const [trackedMovementsLocation, setTrackedMovementsLocation] =
    useState(null);
  const [unTrackedMovementsLocation, setUnTrackedMovementsLocation] =
    useState(null);
  const [locations, setLocations] = useState(null);
  const [updatedLocations, setUpdatedLocations] = useState(null);

  const updateMovementsLocation = (data, _id, tracked = true) => {
    const locationTypeToUpdate = tracked
      ? setTrackedMovementsLocation
      : setUnTrackedMovementsLocation;

    locationTypeToUpdate((prev) => {
      if (prev?.length) {
        const updatedMovement = prev.find((obj) => obj._id === _id);

        if (updatedMovement) {
          const indexOfOld = prev.indexOf(updatedMovement);
          const oldArr = [...prev];
          oldArr[indexOfOld] = { _id, locations: data };
          return oldArr;
        } else {
          return [...prev, data]
            .filter((obj) => !!obj)
            .map((obj) => (obj._id ? obj : { _id, locations: obj }));
        }
      } else {
        return [prev, data]
          .filter((obj) => !!obj)
          .map((obj) => (obj._id ? obj : { _id, locations: obj }));
      }
    });
  };

  useEffect(() => {
    movements?.forEach(({ _id }) => {
      shipmentsService.FetchUntrackedMovementsLocations(
        _id,
        (data) => updateMovementsLocation(data, _id, false),
        () => {}
      );

      shipmentsService.FetchAutomaticMovementsLocations(
        _id,
        (data) => updateMovementsLocation(data, _id),
        () => {}
      );
    });
  }, [movements]);

  useEffect(() => {
    unTrackedMovementsLocation && setLocations(unTrackedMovementsLocation);
    trackedMovementsLocation && setLocations(trackedMovementsLocation);
  }, [unTrackedMovementsLocation, trackedMovementsLocation]);

  useEffect(() => {
    const locationUpdatesId = locations
      ?.filter((loc) => loc.lat && loc.lng)
      .map((loc) => loc._id);
    const movementsId = movements?.map((mvm) => mvm._id);

    const movementCurrentLocations = movementsId?.map((id) => {
      if (locationUpdatesId?.includes(id)) {
        const currLocation = Object?.values(
          locations?.find((loc) => loc?._id === id)?.locations || {}
        );
        const LatestLocation = currLocation[currLocation.length - 1];
        return { id, ...LatestLocation };
      } else {
        const currMovement = movements?.find((mvm) => mvm?._id === id);
        const staticData = {
          lat: currMovement?.pickup?.lat,
          lng: currMovement?.pickup?.lng,
          textAddress: currMovement?.pickup?.address,
          timestamp: currMovement?.createdAt,
        };
        return { id, ...staticData };
      }
    });

    setUpdatedLocations(movementCurrentLocations);
  }, [locations, movements]);

  return {
    updatedLocations,
  };
};

export default useMovementsLocations;
