import { financeConstant } from "constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalContainer from "./ModalContainer";

const FinanceRequestModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cancelFinReq = () =>
    dispatch({ type: financeConstant.SET_FINANCE_REQ_STARTED, payload: false });

  return (
    <ModalContainer closeModal={cancelFinReq}>
      {" "}
      <div
        className="bg-white rounded-[15px] pt-10 px-14 pb-20 text-sm w-[550px] md:w-[630px] -translate-y-12"
        onClick={(evt) => evt.stopPropagation()}
      >
        <h4 className="text-lg font-semibold text-center mb-[50px]">
          Select Finance Type
        </h4>
        <div
          className="border border-[#C1C7D0]/50 rounded-[10px] flex py-4 px-6 cursor-pointer transition-colors duration-300 hover:bg-[#e8effe]/50"
          onClick={() => {
            cancelFinReq();
            navigate("/dashboard/create-shipment");
          }}
        >
          <div>
            <p className="text-[#091D51] font-bold">
              For a new Shipment Request
            </p>
            <p className="text-[#6B778C] text-xs mt-[5px]">
              Create a new shipment and get it financed by MVX
            </p>
          </div>
          <span className="material-icons ml-auto text-[#091D51] self-center !text-2xl">
            trending_flat
          </span>
        </div>
        <div
          className="border border-[#C1C7D0]/50 rounded-[10px] flex py-4 px-6 cursor-pointer mt-5 transition-colors duration-300 hover:bg-[#e8effe]/50"
          onClick={() => {
            cancelFinReq();
            navigate("/dashboard/shipments-finance");
          }}
        >
          <div>
            <p className="text-[#091D51] font-bold">
              For an Existing Shipment Request
            </p>
            <p className="text-[#6B778C] text-xs mt-[5px]">
              Create a finance request for an existing shipment
            </p>
          </div>
          <span className="material-icons ml-auto text-[#091D51] self-center !text-2xl">
            trending_flat
          </span>
        </div>
      </div>
    </ModalContainer>
  );
};

export default FinanceRequestModal;
