import { partnerConstants } from "../constants";
import toast from "react-hot-toast";
import { partnerService } from "services";

function onboardPartner(user) {
  return dispatch => {
      dispatch(request());

      partnerService.onboardUser(user)
          .then(
              partner => {
                  if(user.complete) {
                      if(user.wca_number) {
                          toast.success("Partner request received successfully. We'll be in touch.");
                      } else {
                          toast.success(partner.message);
                      }
                  }
                  dispatch(success(partner.data));
              },
              error => {
                  if (error.message) {
                      toast.error(error.message);
                      dispatch(failure(error.message));
                  }
              }
          );
  };

  function request() { return { type: partnerConstants.ONBOARD_PARTNER_REQUEST }; }
  function success(partner) { return { type: partnerConstants.ONBOARD_PARTNER_SUCCESS, partner }; }
  function failure(error) { return { type: partnerConstants.ONBOARD_PARTNER_FAILURE, error }; }
}

function resetPartnerState() {
    return {
        type: partnerConstants.RESET_PARTNER_STATE,
    }
}

export const partnerActions = {
  onboardPartner,
  resetPartnerState,
}