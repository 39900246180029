import Header from "components/common/Header";
import SearchArticles from "components/common/SearchArticles";
import React from "react";
import styled from "styled-components";

const Hero = () => {
  return (
    <div className="p-0">
      <Header />
      <HeroContainer className="rounded-lg pt-8 pb-4 px-4">
        <div className="w-[90%] sm:w-[70%] m-auto pb-32 flex flex-col items-center">
          <p className="font-catalina text-[2.1rem] sm:text-5xl pt-20 sm:pt-44 pb-4 leading-10">
            Contact us anytime
          </p>
          <p className="pb-14 font-semibold">
            Search for anything about who we are or what we do.
          </p>
          <SearchArticles header={true} />
        </div>
      </HeroContainer>
    </div>
  );
};

const HeroContainer = styled.div`
  background: rgba(17, 82, 251, 0.08);
`;

export default Hero;
