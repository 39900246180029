import React, { useEffect, useRef, useState } from "react";
import { Logo } from "assets/arts";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "routes";
import MenuWithChild from "./MenuWithChild";
import { Twirl as Hamburger } from "hamburger-react";
import MenuItem from "./MenuItem";

const Header = () => {
  const [fixed, setFixed] = useState(0);
  const [mobileMenu, setMobileMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const header = useRef(null);

  const home = () => {
    navigate("/");
  };

  const enter = () => {
    navigate("/enter");
  };

  const register = () => {
    navigate("/register");
  };

  const handleScroll = () => {
    const position = window.pageYOffset;

    if (position >= 32) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [mobileMenu]);

  return (
    <div
      className={`w-full ${
        mobileMenu && "fixed h-screen bg-white top-0 left-0 z-[100]"
      }`}
    >
      <div
        ref={header}
        className={`relative w-full sm:w-[calc((80*(100%-2rem))/100+8rem)] m-auto pl-6 pr-4 sm:px-16 py-2 sm:py-4 bg-white/95 rounded-none sm:rounded-xl flex items-center justify-between z-20 ${
          !mobileMenu &&
          "top-0 sm:top-8 sm:left-[calc((100%-((80*(100%-2rem))/100+8rem))/2)]"
        } ${fixed ? "!top-0 sm:fixed" : "sm:absolute"}`}
      >
        <Logo onClick={home} className="h-[25px] w-auto cursor-pointer" />
        <div className="hidden sm:flex text-sm text-gray-700 self-stretch items-stretch">
          {routes.filter(r => !r.parent).map((route, index) =>
            route.children ? (
              <React.Fragment key={index}>
                <MenuWithChild route={route} />
              </React.Fragment>
            ) : (
              <div
                onClick={() => navigate(route.path)}
                className={`px-4 flex items-center cursor-pointer no-select ${
                  route.path === location.pathname && "font-bold"
                }`}
                key={index}
              >
                {route.name}
              </div>
            )
          )}
        </div>
        <AuthMenu enter={enter} register={register} />
        <div className="sm:hidden h-[48px] w-[48px]">
          <Hamburger
            color="#000"
            toggled={mobileMenu}
            toggle={setMobileMenu}
            size={24}
          />
        </div>
      </div>
      <MobileMenu
        mobileMenu={mobileMenu}
        enter={enter}
        setMobileMenu={setMobileMenu}
      />
    </div>
  );
};

const AuthMenu = ({ enter, mobileMenu, register }) => (
  <div className={`${!mobileMenu ? "hidden sm:flex" : "flex flex-wrap px-4"}`}>
    <button
      type="button"
      onClick={enter}
      className={`${
        mobileMenu
          ? "w-full shrink-0 h-[50px] my-4 text-mvx-orange"
          : "h-[40px]"
      } bg-transparent outline-0 rounded-xl px-6 text-sm font-bold`}
    >
      Sign in
    </button>
    <button
      type="button"
      onClick={register}
      className={`${
        mobileMenu ? "w-full shrink-0 h-[50px]" : "h-[40px]"
      } bg-mvx-orange text-white outline-0 rounded-xl px-6 text-sm font-bold`}
    >
      Sign up
    </button>
  </div>
);

const MobileMenu = ({ mobileMenu, enter, setMobileMenu }) => {
  const [showChildren, setShowChildren] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const register = () => {
    navigate("/register");
  };

  return (
    <>
      <div className={`${mobileMenu ? "block" : "hidden"} pt-6 px-2`}>
        {routes.filter(r => !r.parent).map((route, index) => (
          <div
            onClick={() => {
              route.children ? setShowChildren(route) : navigate(route.path);
            }}
            className={`px-4 py-2 flex items-center justify-between cursor-pointer no-select ${
              route.path === location.pathname && "font-bold"
            }`}
            key={index}
          >
            {route.name}
            {route.children && (
              <span className="material-icons !text-3xl !leading-4">
                keyboard_arrow_right
              </span>
            )}
          </div>
        ))}
        <AuthMenu enter={enter} mobileMenu={mobileMenu} register={register} />
      </div>
      <div
        className={`w-full h-screen fixed top-0 left-0 bg-white z-[120] ${
          showChildren ? "left-0" : "left-full"
        } ${mobileMenu ? "block" : "hidden"}`}
      >
        <div
          onClick={() => setShowChildren(false)}
          className="p-4 border-b border-gray-300 flex items-center font-bold"
        >
          <span className="material-icons !text-3xl !leading-4">
            keyboard_arrow_left
          </span>
          {showChildren.name}
        </div>
        <div className="w-[90%] m-auto pt-10">
          {showChildren?.children?.map((child, index) => (
            <MenuItem
              child={child}
              setMobileMenu={setMobileMenu}
              index={index}
              nowrap={true}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Header;
