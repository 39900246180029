import React from 'react';
import Select from 'react-select';

/**
 * @typedef {{value:string,label:string}} SelectOption 
 * @param {{
 * name?:string,
 * value: SelectOption[],
 * options: SelectOption[],
 * onChange: (newValue: import('react-select').MultiValue<SelectOption>, actionMeta: import('react-select').ActionMeta<SelectOption>)
 * }} props 
 * @returns 
 */
const ChipsInput = ({
  name,
  value,
  placeholder,
  options,
  onChange,
  onBlur,
  disabled,
}) => {
  const styles = {
    menu: (provided, state) => ({
      ...provided,
    }),
    control: (provided,state) => ({
      ...provided,
      height: 40,
      backgroundColor: state.isDisabled ? "#EEF7FF" : provided.backgroundColor,
      borderColor: "#DDD",
      borderRadius: 8,
      boxShadow: 'none',
      '&:hover': {
        borderColor: "#DDD",
      }
    }),
    input: (provided) => ({
      ...provided,
      paddingLeft: '0px',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '14px',
      color: '#A6A6A6',
    }),
    multiValue: (provided, state) => ({
      ...provided,
      borderRadius: 8,
      backgroundColor: state.isDisabled ? 'white' : '#EEF7FF',
      color: '#147dff',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#147dff',
    }),
    option: (provided) => ({
      ...provided,
      color: 'black',
      fontSize: '14px',
    }),
    indicatorSeparator: ()=>({
      display: 'none',
    })
  };

  return (
    <div>
      <Select
        isMulti
        name={name}
        value={value}
        placeholder={placeholder}
        options={options}
        styles={styles}
        onChange={onChange}
        onBlur={onBlur}
        isDisabled={disabled}
      />
    </div>
  )
}

export default ChipsInput
