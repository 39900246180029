import React from "react";
import MenuItem from "./MenuItem";

const Menu = ({ children, showMenu }) => (
  <div className="w-auto h-auto absolute top-full pt-6 block">
    <div className="bg-white shadow-lg rounded-2xl px-3 py-4">
      {children.map((child, index) => (
        <MenuItem child={child} index={index} showMenu={showMenu} />
      ))}
    </div>
  </div>
);

export default Menu;
