import { ExploreIcon } from "assets/arts";
import gotoBecomePartner from "helpers/gotoBecomePartner";
import React, { useState } from "react";
import styled from "styled-components";
import partner from "variables/partner";

const Categories = () => {
  const [categoryTab, setCategoryTab] = useState(0);

  return (
    <>
      <div className="w-full border-b border-gray px-4">
        <div className="w-full sm:w-4/5 m-auto">
          <span className="text-xs bg-[#F90000]/10 px-2.5 py-1.5 rounded-lg text-mvx-brown font-bold">
            Select Partner Type
          </span>
          <p className="font-catalina text-2xl sm:text-3xl mt-8 mb-14">
            Our Different Partner Categories
          </p>
        </div>
      </div>
      <div className="px-4">
        <CategoriesContainer className="pt-10 pb-20 mb-20 rounded-b-lg">
          <div className="w-[90%] sm:w-4/5 m-auto">
            <div className="w-full rounded-xl mt-8 flex p-4 sm:p-0 bg-white sm:bg-transparent overflow-x-auto">
              <div
                className={`px-8 sm:px-6 py-2 sm:py-1 mr-10 text-sm cursor-pointer select-none rounded-full flex items-center duration-200 ${
                  categoryTab === 0 && "bg-mvx-brown"
                }`}
                onClick={() => setCategoryTab(0)}
              >
                <span
                  className={`${
                    categoryTab === 0 ? "text-white font-bold" : "text-gray"
                  } whitespace-nowrap`}
                >
                  Finance
                </span>
              </div>
              <div
                className={`px-6 py-1 mr-10 text-sm cursor-pointer select-none rounded-full flex items-center duration-200 ${
                  categoryTab === 1 && "bg-mvx-brown"
                }`}
                onClick={() => setCategoryTab(1)}
              >
                <span
                  className={`${
                    categoryTab === 1 ? "text-white font-bold" : "text-gray"
                  } whitespace-nowrap`}
                >
                  Freight Forwarding
                </span>
              </div>
              <div
                className={`px-6 py-1 mr-10 text-sm cursor-pointer select-none rounded-full flex items-center duration-200 ${
                  categoryTab === 2 && "bg-mvx-brown"
                }`}
                onClick={() => setCategoryTab(2)}
              >
                <span
                  className={`${
                    categoryTab === 2 ? "text-white font-bold" : "text-gray"
                  } whitespace-nowrap`}
                >
                  Land Haulage
                </span>
              </div>
            </div>
            <div className="w-full flex flex-wrap sm:flex-nowrap items-center mt-12 pt-2 h-auto sm:h-[450px] overflow-y-hidden">
              <div className="w-full sm:w-3/5 mb-6 sm:mb-0 mr-0 sm:mr-20">
                <p className="text-gray-700 text-2xl sm:text-3xl font-bold mb-6">
                  {partner[categoryTab].tagLine}
                </p>
                <p className="text-gray-700 text-sm">
                  {partner[categoryTab].description}
                </p>
                <button
                  type="button"
                  onClick={gotoBecomePartner}
                  className="bg-mvx-blue2 text-white outline-0 rounded-xl h-[50px] px-8 text-sm mr-4 mt-10 flex items-center"
                >
                  Become a Partner
                  <ExploreIcon className="ml-2" />
                </button>
              </div>
              <PartnerImage
                url={partner[categoryTab].image}
                className="w-full sm:w-2/5 h-[300px] sm:h-full rounded-2xl"
              ></PartnerImage>
            </div>
          </div>
        </CategoriesContainer>
      </div>
    </>
  );
};

const CategoriesContainer = styled.div`
  background-color: rgba(255, 138, 0, 0.07);
`;

const PartnerImage = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
`;

export default Categories;
