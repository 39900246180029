import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const VideoShowcase = () => {
  const [scrollDir, setScrollDir] = useState("down");
  const [increaseDimensions, setIncreaseDimensions] = useState(false);
  const [pageSize, setPageSize] = useState(
    document.documentElement.clientWidth
  );
  const videoRef = useRef(null);
  const video = useRef(null);

  useEffect(() => {
    function handleResize() {
      setPageSize(document.documentElement.clientWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (pageSize > 640) {
      let lastScrollY = window.pageYOffset;

      const updateScrollDir = () => {
        const scrollY = window.pageYOffset;

        setScrollDir(scrollY > lastScrollY ? "down" : "up");

        const element = videoRef.current;
        const distanceVisible =
          window.innerHeight - element.offsetTop + window.pageYOffset;
        const percentageOffset = (distanceVisible / element.clientHeight) * 100;

        if (percentageOffset >= 40 && percentageOffset <= 120) {
          if (increaseDimensions === false && scrollY > lastScrollY) {
            setIncreaseDimensions(true);
          } else {
            video.current.pause();
            setIncreaseDimensions(false);
          }
        } else if (percentageOffset > 125) {
          video.current.pause();
          setIncreaseDimensions(false);
        }

        lastScrollY = scrollY > 0 ? scrollY : 0;
      };

      const onScroll = () => {
        window.requestAnimationFrame(updateScrollDir);
      };

      window.addEventListener("scroll", onScroll);

      return () => window.removeEventListener("scroll", onScroll);
    }

    // eslint-disable-next-line
  }, [scrollDir]);

  useEffect(() => {
    if (increaseDimensions === true) {
      const videoReference = video.current;

      videoReference.play();
      videoReference.currentTime = 2;
      const videoTimeout = setTimeout(() => {
        const element = videoRef.current;
        window.scroll({ top: element.offsetTop, left: 0, behavior: "smooth" });
      }, 1000);

      return () => {
        videoReference.pause();
        videoReference.currentTime = 0;
        clearTimeout(videoTimeout);
      }
    }
  }, [increaseDimensions]);

  return (
    <div
      ref={videoRef}
      className={`${
        increaseDimensions
          ? "w-full h-screen"
          : "w-[90%] sm:w-[calc(80%+2rem)] h-[300px] sm:h-[500px]"
      } m-auto sm:p-4 duration-300`}
    >
      <Video
        url={
          "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/landing.png"
        }
        className={`w-full h-full relative grid place-items-center bg-black rounded-2xl overflow-hidden`}
        style={{
          margin: "0 auto",
        }}
      >
        <video
          ref={video}
          src={
            "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/landing.mp4"
          }
          style={{
            position: "absolute",
            zIndex: 0,
            objectFit: "cover",
            width: "100%",
            height: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          muted
          loop
        ></video>
      </Video>
    </div>
  );
};

const Video = styled.div`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export default VideoShowcase;
