import { BellIcon } from "assets/arts";

const EmptyNotification = () => {
  return (
    <div className="flex flex-col gap-1 max-w-[230px] items-center text-center justify-center">
      <BellIcon width="50px" height="50px" />
      <p className="text-[#6b6b6b] font-bold text-lg">No notifications yet</p>
      <p className="text-[#b2b2b2] text-[13px] font-medium leading-5">
        When you get notifications, they'll show up here
      </p>
    </div>
  );
};
export default EmptyNotification;
