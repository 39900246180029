import { DeleteIcon } from "assets/arts";
import { useRef, useState } from "react";

const ShipmentPackages = ({ shipment, setShipment }) => {
  const defaultContainerObject = {
    unit: "ft",
  };
  const defaultNoncontainerObject = {
    length: { unit: "cm" },
    width: { unit: "cm" },
    height: { unit: "cm" },
    weight: { unit: "kg" },
  };

  const setShipmentItemValue = (shipmentKey, key, value, index) => {
    let collection = shipment[shipmentKey];

    if (value === "2x20") {
      collection[index] = {
        ...collection[index],
        value: 20,
        valueString: value,
      };
    } else {
      collection[index] = {
        ...collection[index],
        [key]: value,
      };
    }

    if (value !== "2x20") {
      collection[index] = Object.fromEntries(
        Object.entries(collection[index]).filter(
          (arr) => !arr.includes("valueString")
        )
      );
    }

    setShipment({
      ...shipment,
      [shipmentKey]: collection,
    });
  };

  const addPackage = () => {
    if (shipment.package_type === "containers") {
      setShipment({
        ...shipment,
        containers_size: [
          ...(shipment.containers_size || []),
          defaultContainerObject,
        ],
      });
    } else if (shipment.package_type === "non-containers") {
      setShipment({
        ...shipment,
        packages: [...(shipment.packages || []), defaultNoncontainerObject],
      });
    }
  };

  return (
    <>
      <p className="text-sm sm:text-xs font-bold mb-4 text-[#3E3E3E] package-type after:content-['*'] relative after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500">
        What are you shipping ?
      </p>
      <div className="flex gap-4 mb-4 shipment-packages">
        {shipment?.service_mode !== "air" && (
          <button
            onClick={() =>
              setShipment((s) => ({
                ...s,
                package_type: "containers",
                containers_size: [defaultContainerObject],
              }))
            }
            className={`${
              shipment.package_type === "containers"
                ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
                : "border-[#B2B2B2] text-[#B2B2B2] bg-white"
            } border px-10 md:px-14 text-center py-3 rounded-xl text-xs font-bold`}
          >
            Containers
          </button>
        )}
        <button
          onClick={() =>
            setShipment((s) => ({
              ...s,
              package_type: "non-containers",
              packages: [defaultNoncontainerObject],
            }))
          }
          className={`${
            shipment.package_type === "non-containers"
              ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
              : "border-[#B2B2B2] text-[#B2B2B2] bg-white"
          } mr-4 border px-10 md:px-14 text-center py-3 rounded-xl text-xs font-bold`}
        >
          Non-containers
        </button>
      </div>
      {shipment.package_type === "containers"
        ? shipment.containers_size?.map((shipmentPackage, index) => (
            <Container
              key={index}
              index={index}
              shipmentPackage={shipmentPackage}
              setShipment={setShipment}
              setShipmentItemValue={setShipmentItemValue}
              length={shipment.containers_size?.length}
            />
          ))
        : shipment.packages?.map((shipmentPackage, index) => (
            <Package
              key={index}
              index={index}
              shipmentPackage={shipmentPackage}
              setShipment={setShipment}
              setShipmentItemValue={setShipmentItemValue}
              length={shipment.packages?.length}
            />
          ))}
      {shipment.package_type && (
        <p
          className="text-[#1E9400] text-xs font-bold mt-4 mb-8 flex items-center select-none cursor-pointer"
          onClick={addPackage}
        >
          <span className="material-icons mr-1 !text-lg">add</span>Add Package
        </p>
      )}
    </>
  );
};

const Container = ({
  length,
  shipmentPackage,
  index,
  setShipment,
  setShipmentItemValue,
}) => {
  const [containerQtyErr, setContainerQtyErr] = useState({});

  const setContainerDetails = (value, unit, index) => {
    setShipmentItemValue("containers_size", "unit", unit, index);
    setShipmentItemValue("containers_size", "value", value, index);
  };

  const deleteContainer = () => {
    setShipment((shipment) => ({
      ...shipment,
      containers_size: shipment.containers_size.filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      <div key={index} className="flex flex-wrap gap-3">
        {/* <div
        key={index}
        className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  gap-4 mb-4 max-w-[350px] sm:max-w-none"
      > */}
        <div className="order-2">
          <p className="text-xs mb-2 relative after:content-['*'] after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500">
            Type
          </p>
          <div className="flex">
            <button
              onClick={() =>
                setShipmentItemValue(
                  "containers_size",
                  "package_type",
                  "standard",
                  index
                )
              }
              className={`${
                shipmentPackage?.package_type?.toLowerCase() === "standard"
                  ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
                  : "border-gray-400 text-[#B2B2B2] bg-white"
              } border px-3 md:px-6 text-center py-2 md:py-1.5 rounded-l-lg text-xs font-bold`}
            >
              Standard
            </button>
            <button
              onClick={() =>
                setShipmentItemValue(
                  "containers_size",
                  "package_type",
                  "highcube",
                  index
                )
              }
              className={`${
                shipmentPackage?.package_type?.toLowerCase() === "highcube"
                  ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
                  : "border-gray-400 text-[#B2B2B2] bg-white"
              } border px-3 md:px-6 text-center py-2 md:py-1.5 rounded-r-lg text-xs font-bold -ml-[1px]`}
            >
              Highcube
            </button>
          </div>
        </div>
        <div className="order-1">
          <p className="text-xs mb-2 relative after:content-['*'] after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500">
            Length
          </p>
          <div className="flex">
            <button
              onClick={() => setContainerDetails("20", "ft", index)}
              className={`${
                shipmentPackage?.value === "20"
                  ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
                  : "border-gray-400 text-[#B2B2B2] bg-white"
              } border px-3 md:px-6 text-center py-2 md:py-1.5 rounded-l-lg text-xs font-bold`}
            >
              20ft
            </button>
            <button
              onClick={() => setContainerDetails("40", "ft", index)}
              className={`${
                shipmentPackage?.value === "40"
                  ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
                  : "border-gray-400 text-[#B2B2B2] bg-white"
              } border px-3 md:px-6 text-center py-2 md:py-1.5 text-xs font-bold -ml-[1px]`}
            >
              40ft
            </button>
            <button
              onClick={() => setContainerDetails("2x20", "ft", index)}
              className={`${
                shipmentPackage?.value === 20
                  ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
                  : "border-gray-400 text-[#B2B2B2] bg-white"
              } border px-3 md:px-6 text-center p2-3 md:py-1.5 rounded-r-lg text-xs font-bold -ml-[1px]`}
            >
              2x20ft
            </button>
          </div>
        </div>
        <div className="order-3">
          <p className="text-xs mb-2 relative after:content-['*'] after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500">
            Quantity
          </p>
          <div>
            <input
              defaultValue={shipmentPackage?.quantity}
              onChange={(evt) => {
                const value = evt.target.value;
                let isNum = /^\d+$/.test(value);

                if (isNum) {
                  setContainerQtyErr((prev) => ({ ...prev, [index]: "" }));
                  setShipmentItemValue(
                    "containers_size",
                    "quantity",
                    value,
                    index
                  );
                } else {
                  setContainerQtyErr((prev) => ({ ...prev, [index]: true }));
                }
              }}
              placeholder="Enter quantity"
              className={`border-gray-400 placeholder:text-gray-300 outline-none text-left bg-white border py-2 md:py-1.5 px-3 rounded-lg text-xs font-bold`}
            />
            {containerQtyErr[index] && (
              <span className="text-[11.3px] text-mvx-red font-medium block translate-y-1">
                Quantity can only contain number(s)
              </span>
            )}
          </div>
        </div>
      </div>
      {length > 1 && (
        <p
          onClick={deleteContainer}
          className="flex items-center text-xs text-[#FF1B43] font-bold my-4 cursor-pointer select-none w-max"
        >
          <DeleteIcon className="mr-2 h-[15px] w-auto" />
          Remove Package
        </p>
      )}
    </>
  );
};

const Package = ({
  length,
  shipmentPackage,
  index,
  setShipment,
  setShipmentItemValue,
}) => {
  const [packagesDimensionsErr, setPackagesDimensionsErr] = useState({});
  const [packagesWeightsErr, setPackagesWeightErr] = useState({});
  const [packagesUnitsErr, setPackagesUnitErr] = useState({});
  const lengthInputElm = useRef();
  const widthInputElm = useRef();
  const heightInputElm = useRef();

  const deletePackage = () => {
    setShipment((shipment) => ({
      ...shipment,
      packages: shipment.packages.filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      <div className="mr-4">
        <p className="text-xs mb-2 relative after:content-['*'] after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500">
          Package Type
        </p>
        <div className="flex mb-4">
          <button
            onClick={() =>
              setShipmentItemValue("packages", "package_type", "boxes", index)
            }
            className={`${
              shipmentPackage.package_type === "boxes"
                ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
                : "border-gray-400 text-[#B2B2B2] bg-white"
            } border px-3.5 md:px-6 text-center py-2 md:py-1.5 rounded-l-lg text-xs font-bold`}
          >
            Boxes
          </button>
          <button
            onClick={() =>
              setShipmentItemValue("packages", "package_type", "crates", index)
            }
            className={`${
              shipmentPackage.package_type === "crates"
                ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
                : "border-gray-400 text-[#B2B2B2] bg-white"
            } border px-3.5 md:px-6 text-center py-2 md:py-1.5 text-xs font-bold -ml-[1px]`}
          >
            Crates
          </button>
          <button
            onClick={() =>
              setShipmentItemValue("packages", "package_type", "pallets", index)
            }
            className={`${
              shipmentPackage.package_type === "pallets"
                ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
                : "border-gray-400 text-[#B2B2B2] bg-white"
            } border px-3.5 md:px-6 text-center py-2 md:py-1.5 text-xs font-bold -ml-[1px]`}
          >
            Pallets
          </button>
          <button
            onClick={() =>
              setShipmentItemValue("packages", "package_type", "", index)
            }
            className={`${
              shipmentPackage.package_type === ""
                ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
                : "border-gray-400 text-[#B2B2B2] bg-white"
            } border px-3.5 md:px-6 text-center py-2 md:py-1.5 rounded-r-lg text-xs font-bold -ml-[1px]`}
          >
            Others
          </button>
        </div>
      </div>
      <div key={index} className="flex gap-4 flex-col lg:flex-row mb-4">
        <div>
          <p className="text-xs mb-2 relative after:content-['*'] after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500">
            Dimensions
          </p>
          <div className="flex max-w-[300px]">
            <input
              ref={lengthInputElm}
              className={`${
                shipmentPackage.package_type === "standard"
                  ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
                  : "border-gray-400"
              } w-full border px-3 text-left py-2 md:py-1.5 rounded-l-lg text-xs font-bold`}
              placeholder="Length"
              defaultValue={shipmentPackage?.length?.value}
              onChange={(evt) => {
                const value = evt.target.value;
                const isNum = /^\d+$/.test(value);
                const isHeightNum = /^\d+$/.test(
                  heightInputElm.current?.value || 10
                );
                const isWidthNum = /^\d+$/.test(
                  widthInputElm.current?.value || 10
                );

                if (isNum && isHeightNum && isWidthNum) {
                  setPackagesDimensionsErr((prev) => ({
                    ...prev,
                    [index]: "",
                  }));
                  setShipmentItemValue(
                    "packages",
                    "length",
                    { unit: "cm", value: evt.target.value },
                    index
                  );
                } else {
                  setPackagesDimensionsErr((prev) => ({
                    ...prev,
                    [index]: true,
                  }));
                }
              }}
            />
            <input
              ref={widthInputElm}
              className={`${
                shipmentPackage.package_type === "width"
                  ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
                  : "border-gray-400"
              } w-full border px-3 text-left py-2 md:py-1.5 text-xs font-bold -ml-[1px]`}
              placeholder="Width"
              defaultValue={shipmentPackage?.width?.value}
              onChange={(evt) => {
                const value = evt.target.value;
                const isNum = /^\d+$/.test(value);
                const isLengthNum = /^\d+$/.test(
                  lengthInputElm.current?.value || 10
                );
                const isHeightNum = /^\d+$/.test(
                  heightInputElm.current?.value || 10
                );

                if (isNum && isLengthNum && isHeightNum) {
                  setPackagesDimensionsErr((prev) => ({
                    ...prev,
                    [index]: "",
                  }));
                  setShipmentItemValue(
                    "packages",
                    "width",
                    { unit: "cm", value: evt.target.value },
                    index
                  );
                } else {
                  setPackagesDimensionsErr((prev) => ({
                    ...prev,
                    [index]: true,
                  }));
                }
              }}
            />
            <input
              ref={heightInputElm}
              className={`${
                shipmentPackage.package_type === "highcube"
                  ? "border-mvx-blue text-mvx-blue bg-[#E8F0FE]"
                  : "border-gray-400"
              } w-full border px-3 text-left py-2 md:py-1.5 rounded-r-lg text-xs font-bold -ml-[1px]`}
              placeholder="Height"
              defaultValue={shipmentPackage?.height?.value}
              onChange={(evt) => {
                const value = evt.target.value;
                const isNum = /^\d+$/.test(value);

                const isLengthNum = /^\d+$/.test(
                  lengthInputElm.current?.value || 10
                );
                const isWidthNum = /^\d+$/.test(
                  widthInputElm.current?.value || 10
                );

                if (isNum && isLengthNum && isWidthNum) {
                  setPackagesDimensionsErr((prev) => ({
                    ...prev,
                    [index]: "",
                  }));
                  setShipmentItemValue(
                    "packages",
                    "height",
                    { unit: "cm", value: evt.target.value },
                    index
                  );
                } else {
                  setPackagesDimensionsErr((prev) => ({
                    ...prev,
                    [index]: true,
                  }));
                }
              }}
            />
          </div>
          {packagesDimensionsErr[index] && (
            <span className="text-[11.3px] text-mvx-red font-medium block translate-y-1">
              Dimensions can only contain number(s)
            </span>
          )}
        </div>
        <div className="flex md:gap-4">
          <div>
            <p className="text-xs mb-2 relative after:content-['*'] after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500">
              Weight
            </p>
            <div className="flex">
              <input
                placeholder="Weight"
                defaultValue={shipmentPackage?.weight?.value}
                className={`w-11/12 md:w-full border-gray-400 placeholder:text-gray-300 outline-none text-left bg-white border py-1.5 px-3 rounded-lg text-xs font-bold`}
                onChange={(evt) => {
                  const value = evt.target.value;
                  let isNum = /^\d+$/.test(value);

                  if (isNum) {
                    setPackagesWeightErr((prev) => ({ ...prev, [index]: "" }));
                    setShipmentItemValue(
                      "packages",
                      "weight",
                      { unit: "kg", value: evt.target.value },
                      index
                    );
                  } else {
                    setPackagesWeightErr((prev) => ({
                      ...prev,
                      [index]: true,
                    }));
                  }
                }}
              />
              {packagesWeightsErr[index] && (
                <span className="text-[11.3px] text-mvx-red font-medium block translate-y-1">
                  Weight can only contain number(s)
                </span>
              )}
            </div>
          </div>
          <div>
            <p className="text-xs mb-2 relative after:content-['*'] after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500">
              Units
            </p>
            <div className="flex">
              <input
                defaultValue={shipmentPackage?.units}
                onChange={(evt) => {
                  const value = evt.target.value;
                  let isNum = /^\d+$/.test(value);

                  if (isNum) {
                    setPackagesUnitErr((prev) => ({ ...prev, [index]: "" }));
                    setShipmentItemValue(
                      "packages",
                      "units",
                      evt.target.value,
                      index
                    );
                  } else {
                    setPackagesUnitErr((prev) => ({ ...prev, [index]: true }));
                  }
                }}
                placeholder="Enter units"
                className={`w-11/12 md:w-full border-gray-400 placeholder:text-gray-300 outline-none text-left bg-white border py-1.5 px-3 rounded-lg text-xs font-bold`}
              />
              {packagesUnitsErr[index] && (
                <span className="text-[11.3px] text-mvx-red font-medium block translate-y-1">
                  Unit can only contain number(s)
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      
      {length > 1 && (
        <p
          onClick={deletePackage}
          className="flex items-center text-xs text-[#FF1B43] font-bold my-4 cursor-pointer select-none w-max"
        >
          <DeleteIcon className="mr-2 h-[15px] w-auto" />
          Remove Package
        </p>
      )}
    </>
  );
};

export default ShipmentPackages;
