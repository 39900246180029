import { MobileLocationTracker, MVXLogo, NavHamburgerMenu } from "assets/arts";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavLinksModal } from "./NavLinksModal";
import { appConstants } from "constants";
import RandomAddLogo from "./RandomAddLogo";

const MobileNav = ({ setModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toggleNavModal } = useSelector((state) => state.app);

  return (
    <>
      <RandomAddLogo />
      <nav className="md:hidden fixed top-0 w-full h-[80px] bg-white z-[1000]">
        <div className="flex items-center justify-between px-8 h-full">
          <span
            className="cursor-pointer"
            onClick={() => navigate("/dashboard/home")}
          >
            <MVXLogo />
          </span>

          <div className="flex gap-5">
            <span className="cursor-pointer" onClick={() => setModal(true)}>
              <MobileLocationTracker />
            </span>
            <span
              className="cursor-pointer"
              onClick={() =>
                dispatch({ type: appConstants.TOGGLE_NAV_MODAL, payload: true })
              }
            >
              <NavHamburgerMenu />
            </span>
          </div>
        </div>
      </nav>
      {toggleNavModal && (
        <div className="md:hidden">
          <NavLinksModal />
        </div>
      )}
    </>
  );
};

export default MobileNav;

export const GoBackInfo = ({ text, navigateTo }) => {
  const navigate = useNavigate();
  
  return (
    <div className="fixed top-[80px] left-0 w-full bg-[#f5f5f5] text-[#333] p-4 sm:hidden font-extrabold z-[2000]">
      {!!navigateTo ? (
        <button
          className="flex items-center gap-2"
          onClick={() => navigate(`/${navigateTo}`)}
        >
          <span className="material-icons">chevron_left</span>
          <span>{text}</span>
        </button>
      ) : (
        <button
          className="flex items-center gap-2"
          onClick={() => navigate(-1)}
        >
          <span className="material-icons">chevron_left</span>
          <span>{text}</span>
        </button>
      )}
    </div>
  );
};
