import { financeConstant } from "constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ActionButtons = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const startFinReq = () =>
    dispatch({ type: financeConstant.SET_FINANCE_REQ_STARTED, payload: true });

  const createShipment = () => {
    navigate("/dashboard/create-shipment");
  };


  return (
    <div className="flex">
      <button
        onClick={createShipment}
        className="mr-5 border border-mvx-blue px-6 pl-4 py-3 rounded-[6px] bg-mvx-blue text-white flex items-center text-xs"
      >
        <span className="material-icons mr-2 !text-2xl !leading-4">add</span>
        Create a Shipment
      </button>
      <button
        className="border border-gray-400 px-6 pl-4 py-3 rounded-[6px] text-gray flex items-center text-xs"
        onClick={startFinReq}
      >
        <span className="material-icons mr-2 !text-2xl !leading-4 text-gray-600">
          add
        </span>
        Request Finance
      </button>
    </div>
  );
};

export default ActionButtons;
