export const shipmentsConstant = {
  SHOW_LAP_HISTORY: "SHOW-LAP-HISTORY",
  ACTIVE_SHIPMENTS_REQUEST: "ACTIVE_SHIPMENTS_REQUEST",
  ACTIVE_SHIPMENTS_SUCCESS: "ACTIVE_SHIPMENTS_SUCCESS",
  ACTIVE_SHIPMENTS_FAILURE: "ACTIVE_SHIPMENTS_FAILURE",
  MOVEMENTS_REQUEST: "MOVEMENTS_REQUEST",
  MOVEMENTS_SUCCESS: "MOVEMENTS_SUCCESS",
  MOVEMENTS_FAILURE: "MOVEMENTS_FAILURE",
  UNTRACKED_MOVEMENTS_LOCATIONS_REQUEST:
    "UNTRACKED_MOVEMENTS_LOCATIONS_REQUEST",
  UNTRACKED_MOVEMENTS_LOCATIONS_SUCCESS:
    "UNTRACKED_MOVEMENTS_LOCATIONS_SUCCESS",
  UNTRACKED_MOVEMENTS_LOCATIONS_FAILURE:
    "UNTRACKED_MOVEMENTS_LOCATIONS_FAILURE",
  TRACKED_MOVEMENTS_LOCATIONS_REQUEST: "TRACKED_MOVEMENTS_LOCATIONS_REQUEST",
  TRACKED_MOVEMENTS_LOCATIONS_SUCCESS: "TRACKED_MOVEMENTS_LOCATIONS_SUCCESS",
  TRACKED_MOVEMENTS_LOCATIONS_FAILURE: "TRACKED_MOVEMENTS_LOCATIONS_FAILURE",
  CREATE_SHIPMENT_REQUEST: "CREATE_SHIPMENT_REQUEST",
  CREATE_SHIPMENT_SUCCESS: "CREATE_SHIPMENT_SUCCESS",
  CREATE_SHIPMENT_FAILURE: "CREATE_SHIPMENT_FAILURE",
  SAVE_SHIPMENT_REQ_REQUEST: "SAVE_SHIPMENT_REQ_REQUEST",
  SAVE_SHIPMENT_REQ_SUCCESS: "SAVE_SHIPMENT_REQ_SUCCESS",
  SAVE_SHIPMENT_REQ_FAILURE: "SAVE_SHIPMENT_REQ_FAILURE",
  FETCH_SAVED_SHIPMENT_REQ_REQUEST: "FETCH_SAVED_SHIPMENT_REQ_REQUEST",
  FETCH_SAVED_SHIPMENT_REQ_SUCCESS: "FETCH_SAVED_SHIPMENT_REQ_SUCCESS",
  FETCH_SAVED_SHIPMENT_REQ_FAILURE: "FETCH_SAVED_SHIPMENT_REQ_FAILURE",
  FETCH_SINGLE_SHIPMENT_REQUEST: "FETCH_SINGLE_SHIPMENT_REQUEST",
  FETCH_SINGLE_SHIPMENT_SUCCESS: "FETCH_SINGLE_SHIPMENT_SUCCESS",
  FETCH_SINGLE_SHIPMENT_FAILURE: "FETCH_SINGLE_SHIPMENT_FAILURE",
  ACCEPT_QUOTE_REQUEST: "ACCEPT_QUOTE_REQUEST",
  ACCEPT_QUOTE_SUCCESS: "ACCEPT_QUOTE_SUCCESS",
  ACCEPT_QUOTE_FAILURE: "ACCEPT_QUOTE_FAILURE",
  REJECT_QUOTE_REQUEST: "REJECT_QUOTE_REQUEST",
  REJECT_QUOTE_SUCCESS: "REJECT_QUOTE_SUCCESS",
  REJECT_QUOTE_FAILURE: "REJECT_QUOTE_FAILURE",
  FETCH_SHIPMENT_QUOTES_REQUEST: "FETCH_SHIPMENT_QUOTES_REQUEST",
  FETCH_SHIPMENT_QUOTES_SUCCESS: "FETCH_SHIPMENT_QUOTES_SUCCESS",
  FETCH_SHIPMENT_QUOTES_FAILURE: "FETCH_SHIPMENT_QUOTES_FAILURE",
  SET_FINANCE_REQ_SHIPMENT_ID: "SET_FINANCE_REQ_SHIPMENT_ID",
  START_ADD_PARTNER_REQUEST: "START_ADD_PARTNER_REQUEST",
  ADD_PARTNER_SUCCESS: "ADD_PARTNER_SUCCESS",
  ADD_PARTNER_FAILURE: "ADD_PARTNER_FAILURE",
};
