import React from "react";

const FinanceChoice = ({ value, onChange }) => {
  return (
    <>
      <p className="text-xs font-bold mb-2 text-[#3E3E3E] relative after:content-['*'] after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500 hidden sm:block">
        Would you be needing financing for your shipment ?
      </p>
      <p className="text-[13.5px] font-bold text-[#3e3e3e] -mt-3 mb-3 sm:hidden after:content-['*'] after:text-red-500 after:ml-1 ">
        Do you want MVX to finance this shipment?
      </p>

      <select
        required
        value={value || ""}
        onChange={(e) => {
          onChange(e.target.value);
          localStorage.setItem("financeShipment", e.target.value);
        }}
        className={`hidden sm:block appearance-none border border-gray-200 bg-[#F5F5F5] mb-8 rounded-md p-3 outline-0 w-full h-12 text-sm ${
          !value || value === "" ? "text-gray-400" : ""
        }`}
      >
        <option value="" disabled className="text-gray">
          Select choice
        </option>
        <option value="yes">Yes, I would</option>
        <option value="no">No, I wouldn't</option>
      </select>
    </>
  );
};

export default FinanceChoice;
