import React from "react";
import Slider from "react-slick";
import styledComponents from "styled-components";

var settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const MeetMVX = () => (
  <>
    <div className="pt-16 pb-6 w-[85%] sm:w-3/4 m-auto text-sm text-gray-600 font-light">
      <p className="font-catalina text-2xl text-center text-black font-normal mb-14">
        Meet MVX
      </p>
      <p className="mb-10">
        The answer to your global shipping and trade finance prayers.
      </p>
      <p className="mb-10">
        We’re a team of dedicated, proactive, and empathic people whose sole
        purpose is making your experience with trade smooth and hassle-free.
      </p>
      <p className="mb-10">
        From building simple solutions with technology to keeping you up-to-date
        on the status of your in-transit shipments. We’re with you at every
        stage of the journey;{" "}
        <b className="text-black font-bold">door to port</b>,{" "}
        <b className="text-black font-bold">door to door</b> and{" "}
        <b className="text-black font-bold">port to door</b>.
      </p>
    </div>
    <div className="w-[calc(7.5+85%)] ml-[7.5%] sm:w-[calc(87.5%)] sm:ml-[12.5%] mb-16 overflow-hidden">
      <Slider className="w-[calc(140%)]" {...settings}>
        <div className="pr-4 h-[150px] sm:h-[300px] w-2/5 shrink-0">
          <TeamImage
            url={require("assets/images/about/team/1.png")}
            className="bg-black h-full rounded-2xl"
          ></TeamImage>
        </div>
        <div className="pr-4 h-[150px] sm:h-[300px] w-2/5 shrink-0">
          <TeamImage
            url={require("assets/images/about/team/2.png")}
            className="bg-black h-full rounded-2xl"
          ></TeamImage>
        </div>
        <div className="pr-4 h-[150px] sm:h-[300px] w-2/5 shrink-0">
          <TeamImage
            url={require("assets/images/about/team/3.png")}
            className="bg-black h-full rounded-2xl"
          ></TeamImage>
        </div>
        {/* <div className="pr-4 h-[150px] sm:h-[300px] w-2/5 shrink-0">
          <TeamImage
            url={require("assets/images/about/team/4.png")}
            className="bg-black h-full rounded-2xl"
          ></TeamImage>
        </div> */}
        <div className="pr-4 h-[150px] sm:h-[300px] w-2/5 shrink-0">
          <TeamImage
            url={require("assets/images/about/team/5.png")}
            className="bg-black h-full rounded-2xl"
          ></TeamImage>
        </div>
        <div className="pr-4 h-[150px] sm:h-[300px] w-2/5 shrink-0">
          <TeamImage
            url={require("assets/images/about/team/6.png")}
            className="bg-black h-full rounded-2xl"
          ></TeamImage>
        </div>
      </Slider>
    </div>
    <div className="w-[85%] sm:w-3/4 m-auto mb-32">
      <p className="text-sm text-gray-600 font-light mb-10">
        Our technology provides transparency and reliability that enables
        businesses, both large corporates and SMES create an efficient supply
        chain network that runs smoothly with more control so that goods move
        seamlessly from port to manufacturer to customer and vice versa.
      </p>
      <p className="text-sm text-gray-600 font-light">
        Our platform creates a simplified freight ecosystem that connects cargo
        owners and shippers. Backed by data and artificial intelligence, we
        provide real time visibility into shipment status, optimizing pick-ups
        and deliveries, moving millions of cargo around the world through our
        connected network.
      </p>
    </div>
  </>
);

const TeamImage = styledComponents.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
`;

export default MeetMVX;
