import Numbers from "components/About/Numbers";
import Footer from "components/common/Footer";
import React from "react";
import Articles from "./Articles";
import Hero from "./Hero";

const Resources = () => {
  return (
    <>
      <Hero/>
      <Articles />
      <Numbers />
      <Footer />
    </>
  );
};

export default Resources;
