import React from "react";
import news from "variables/news";

const News = () => {
  return (
    <div className="px-4 mb-20">
      <div className="w-[90%] sm:w-4/5 m-auto bg-[#F90000] rounded-2xl p-4 sm:p-10">
        <p className="text-center font-bold text-white mb-8">MVX in the News</p>
        <div className="border border-white rounded-2xl flex flex-wrap sm:flex-nowrap justify-between px-0 sm:px-14">
          {news.map((n, index) => (
            <React.Fragment key={index}>
              <a
                href={n.url}
                target="_blank"
                rel="noreferrer"
                className="w-full flex items-center justify-center"
              >
                <div className="w-full sm:w-auto py-6 relative block sm:flex">
                  {n.logo}
                  {index !== news.length - 1 && (
                    <div className="w-3/5 left-[20%] sm:hidden block right-0 h-px absolute bottom-0 bg-white/50 self-stretch"></div>
                  )}
                </div>
              </a>
              {index !== news.length - 1 && (
                <div className="w-px min-h-12 bg-white/80 "></div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="w-full sm:w-4/5 m-auto mt-24 mb-6">
        <p className="font-catalina text-2xl sm:text-3xl text-center mb-4 px-4 sm:px-0">
          Rooted in Africa, Focused on the World
        </p>
        <div className="w-20 h-[3px] bg-mvx-blue m-auto mb-10"></div>
        <img
          src={
            "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/root.svg"
          }
          className="w-full h-auto"
          alt={"MVX Root"}
        />
      </div>
    </div>
  );
};

export default News;
