import React from "react";

const Loading = ({ size, color }) => (
  <div className="w-full h-full absolute left-0 top-0 flex items-center justify-center">
    <svg
      className={`animate-spin ${
        !size ? "h-5 w-5" : `h-${size} w-${size}`
      } text-${color || "white"}`}
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-0"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
);

export default Loading;




/*
  {modal && selectedQuote && (
        <ModalContainer closeModal={() => setModal(false)}>
          {modalPage ? (
            <div className="bg-white  w-[450px] mx-auto items-center rounded-[10px]">
              <div className="text-center py-[120px]">
                <span className="material-icons text-center !text-[80px] text-[#02C39A] mt-4 !leading-4 mr-1">
                  check_circle
                </span>
                <p className="mt-4 font-[12px] font-semibold items-center px-20">
                  Your finance request has been successfully submitted! We will
                  get back to you shortly.
                </p>
                <button className="bg-mvx-blue text-center py-2 w-[140px] px-6 text-white rounded-[10px] text-[16px] mb-2 mt-10">
                  Close
                </button>
              </div>
            </div>
          ) : (
            <div className="w-[607px] h-[600px] overflow-y-auto">
              <Document
                file={selectedQuote?.document?.path}
                loading={<Loading size={"[70px]"} />}
              >
                <Page pageNumber={1} />
              </Document>
            </div>
          )}
        </ModalContainer>
      )}
*/ 