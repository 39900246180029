import {
  AccountManagerIcon,
  MailIcon,
  PhoneIcon,
} from "assets/arts";
import CustomToast from "components/common/CustomToast";
import toast from "react-hot-toast";
import styledComponents from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getInitials } from "helpers";

const Sidebar = ({routes}) => {
  const navigate = useNavigate();
  const {
    user: { accountManager },
  } = useSelector((state) => state.auth);

  const showClickedInfo = () => {
    toast.custom((t) => (
      <CustomToast t={t} message="Phone number copied to clipboard!" type="" />
    ));
  };

  return (
    <div className="flex py-8 w-full h-full flex-col justify-between overflow-y-auto overflow-x-hidden !pb-32">
      <div className="w-full pl-6">{routes}</div>
      {accountManager && (
        <div className="w-full">
          <div className="w-[90%] m-auto shadow-lg border border-gray rounded-xl p-4 mt-10">
            <p className="text-xs font-bold text-center mb-6">
              Account Manager
            </p>
            <div
              className="relative flex justify-center h-20 w-20 items-center rounded-full mx-auto cursor-pointer"
              onClick={() => navigate("/dashboard/messages")}
            >
              {!accountManager.avatar && (
                <div className="bg-mvx-blue rounded-full flex items-center justify-center text-white text-2xl font-bold h-full w-full">
                  <h2>{getInitials(accountManager.name)}</h2>
                </div>
              )}
              {accountManager.avatar && (
                <AccountManagerImage
                  url={accountManager.avatar}
                  className="h-full w-full rounded-full m-auto"
                ></AccountManagerImage>
              )}
            </div>
            <div className="justify-center mt-6 text-center">
              <AccountManagerIcon
                className="mt-0.5 shrink-0 cursor-pointer inline-block"
                onClick={() => navigate("/dashboard/messages")}
              />
              <span className="ml-2 text-sm text-[#1C56F2] font-bold inline-block">
                {accountManager.name}
              </span>
            </div>
            <div
              className="flex justify-center items-center mt-4 text-center cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(accountManager.mobile);
                showClickedInfo();
              }}
            >
              <PhoneIcon className="w-[15px] h-auto" />
              <p className="ml-2 text-xs">
                {accountManager.mobile_extension}
                {accountManager.raw_mobile}
              </p>
            </div>
            <div className="flex items-start justify-center mt-4 w-full">
              <MailIcon className="mt-0.5 w-[20px] h-auto shrink-0" />
              <a
                href={`mailto:${accountManager.email}`}
                className="ml-2 max-w-[calc(100%-20px)] text-center shrink-0 text-xs text-mvx-blue underline break-words cursor-pointer"
              >
                {accountManager.email}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const AccountManagerImage = styledComponents.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
`;

export default Sidebar;
