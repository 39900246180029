import CustomToast from "components/common/CustomToast";
import { getShipmentStatus } from "helpers/getShipmentStatus";
import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { shipmentsService } from "services";
import { ShipmentLoader } from ".";
import { GoBackInfo } from "../common/MobileNav";
import ShipmentsError from "./ShipmentsError";

const ShipmentsFinance = () => {
  const [shipmentsLoading, setShipmentsLoading] = useState(false);
  const [shipments, setShipments] = useState([]);

  // ADD PAGINATION AND INFINITE SCROLL
  const fetchShipmentsWIthNoReq = async () => {
    setShipmentsLoading(true);
    try {
      const res = await shipmentsService.fetchShipmentForFinances();
      setShipments(res.data);
      setShipmentsLoading(false);
    } catch (error) {
      setShipmentsLoading(false);
      if (error.message) {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
      }
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("getFinance");
    fetchShipmentsWIthNoReq();
  }, []);

  return (
    <>
      <GoBackInfo text="Freight Finance" />
      <div className="-ml-4 -mr-14 sm:ml-0 sm:mt-3 md:mt-0 sm:-mr-10 md:-mr-8 lg:mr-0">
        <div className="mb-10 md:mb-24">
          <div className="h-full">
            <div className="mt-14 sm:mt-0 mb-5">
              <p
                className="hidden sm:flex items-center text-xs cursor-pointer w-max mb-2"
                onClick={() => navigate(-1)}
              >
                <span className="material-icons mr-2 !text-base">
                  keyboard_backspace
                </span>
                Close
              </p>
              <p className="text-base sm:text-xl font-bold">Your Shipments</p>
            </div>
            <div className="border border-gray rounded-xl p-6 px-5 sm:px-8 bg-white h-[calc(100vh-210px)] md:h-full md:min-h-[90%] overflow-scroll shipmentsHistoryContainer">
              <div className="flex gap-1 md:gap-0 px-4 sm:px-6 py-3 bg-[#F5F8FF] font-bold rounded-xl text-xs text-[#0E2B79] min-w-[730px]">
                <div className="shrink-0 min-w-[85px] sm:min-w-[100px] w-[15%]">
                  Date
                </div>
                <div className="shrink-0 min-w-[85px] sm:min-w-[100px] w-[15%]">
                  Shipment ID
                </div>
                <div className="pl-2 shrink-0 min-w-[170px] sm:min-w-[200px] w-[30%]">
                  Trip Route
                </div>
                <div className="pl-2 shrink-0 min-w-[85px] sm:min-w-[100px] w-1/5">
                  Status
                </div>
                <div className="shrink-0 min-w-[65px] sm:min-w-[80px] w-[10%]">
                  Action
                </div>
              </div>
              {/* <div className="flex px-6 py-3 bg-[#F5F8FF] font-bold rounded-xl text-xs text-[#0E2B79] mt-4 mb-3">
                <div className="shrink-0 min-w-[100px] w-[15%]">Date</div>
                <div className="shrink-0 min-w-[100px] w-[15%]">
                  Shipment ID
                </div>
                <div className="shrink-0 min-w-[200px] w-[35%]">Trip Route</div>
                <div className="shrink-0 min-w-[100px] w-[15%]">Status</div>
                <div className="shrink-0 min-w-[80px] w-[20%] text-center">
                  Action
                </div>
              </div> */}

              {shipmentsLoading ? (
                <>
                  <ShipmentLoader ellipsis={false} />
                  <ShipmentLoader ellipsis={false} />
                  <ShipmentLoader ellipsis={false} />
                </>
              ) : (
                <>
                  {!shipments.length ? (
                    <ShipmentsError />
                  ) : (
                    shipments?.map((shipment) => (
                      <FinanceShipmentInstance
                        key={shipment._id}
                        shipment={shipment}
                      />
                    ))
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// {
//   if (activeShipments.length === index + 1) {
//     return (
//       <div key={shipment._id}>
//         {/* ref={lastBookRef} */}
//         <FinanceShipmentInstance shipment={shipment} />
//       </div>
//     );
//   } else {
//     return (
//       <div key={shipment._id}>
//         <FinanceShipmentInstance shipment={shipment} />
//       </div>
//     );
//   }
// }

export default ShipmentsFinance;

const requestFinanceForPending = (id) => {
  localStorage.setItem("shipmentFinanceRequestId", id);
};

const FinanceShipmentInstance = ({ shipment }) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-start px-4 sm:px-6 py-4 border-b border-gray text-xs text-[#2B2B2B] relative gap-1 md:gap-0 min-w-[730px]">
      <div className="shrink-0 min-w-[85px] sm:min-w-[100px] w-[15%]">
        {moment(shipment.createdAt).format("ddd, Do MMM YYYY")}: <br />
        {moment(shipment.createdAt).format("h:mm:ss a")}
      </div>

      <div className="min-w-[85px] sm:min-w-[100px] w-[15%] font-bold">
        MVXT{shipment.mvxid || "-NIL"}
      </div>
      <div className="shrink-0 min-w-[170px] sm:min-w-[200px] w-[30%] font-bold flex">
        <div className="w-[40px] shrink-0 mr-[10px]">
          <div className="w-[2px] h-full m-auto py-[12.5px]">
            <div
              className={`w-full h-full ${
                getShipmentStatus(
                  shipment?.currentStatus || shipment?.status
                ) === "active"
                  ? "bg-[#40A81B]"
                  : "bg-[#ACACAC]"
              }`}
            ></div>
          </div>
        </div>
        <div className="w-full">
          <div className="pb-6 relative pt-1">
            {shipment.origin.address || "No origin address"}
            <div className="w-[40px] h-[25px] rounded-full absolute -left-[50px] top-0">
              <div
                className={`w-[25px] h-[25px] m-auto flex items-center justify-center rounded-full ${
                  getShipmentStatus(
                    shipment?.currentStatus || shipment?.status
                  ) === "active"
                    ? "bg-[#40A81B]/20"
                    : ""
                }`}
              >
                <div
                  className={`w-[15px] h-[15px] border-4 m-auto bg-white rounded-full ${
                    getShipmentStatus(
                      shipment?.currentStatus || shipment?.status
                    ) === "active"
                      ? "border-[#40A81B]"
                      : "border-[#ACACAC]"
                  }`}
                ></div>
              </div>
            </div>
          </div>
          <div className="relative">
            {shipment.destination.address || "No destination address"}
            <div className="w-[40px] h-full bg-white absolute -left-[50px] top-0">
              <div
                className={`w-[15px] h-[15px] m-auto border-4 bg-white ${
                  getShipmentStatus(
                    shipment?.currentStatus || shipment?.status
                  ) === "active"
                    ? "border-[#40A81B]"
                    : "border-[#ACACAC]"
                }`}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="shrink-0 min-w-[85px] sm:min-w-[100px] w-1/5 flex flex-col items-start">
        <div
          className={`${
            getShipmentStatus(shipment?.currentStatus || shipment?.status) ===
            "active"
              ? "bg-[#49D33D]/[0.18] text-[#40A81B]"
              : getShipmentStatus(
                  shipment?.currentStatus || shipment?.status
                ) === "completed"
              ? "bg-[#1152FB]/[0.07] text-[#1152FB]"
              : getShipmentStatus(
                  shipment?.currentStatus || shipment?.status
                ) === "cancelled"
              ? "bg-[#FF0000]/[0.08] text-[#FF0000]"
              : getShipmentStatus(
                  shipment?.currentStatus || shipment?.status
                ) === "pending"
              ? "bg-[#FF8A00]/[0.08] text-[#FF8A00]"
              : ""
          } font-bold px-4 py-1.5 rounded-full mb-2 capitalize`}
        >
          {getShipmentStatus(shipment?.currentStatus || shipment?.status)}
        </div>
      </div>
      <div className="shrink-0 min-w-[80px] w-[10%]">
        <button
          className="font-semibold border border-mvx-orange px-6 pl-4 py-3 rounded-[5px] bg-mvx-orange text-white flex items-center text-xs whitespace-nowrap"
          onClick={() => {
            requestFinanceForPending(shipment._id);
            navigate("/dashboard/finance-create-shipment");
          }}
        >
          Request Finance
        </button>
      </div>
    </div>
  );
};
