import React, { useEffect, useState } from "react";
import styled from "styled-components";
import products from "variables/products";

const Products = () => {
  const [product, setProduct] = useState(0);
  const [pageSize, setPageSize] = useState(
    document.documentElement.clientWidth
  );

  useEffect(() => {
    function handleResize() {
      setPageSize(document.documentElement.clientWidth);
    }

    window.addEventListener("resize", handleResize);
  });

  // useEffect(() => {
  //   if (pageSize > 640) {
  //     const processInterval = setInterval(() => {
  //       setProduct((step) => (step === 2 ? 0 : step + 1));
  //     }, 3000);

  //     return () => clearInterval(processInterval);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  return (
    <div className="px-4">
      <ProductsContainer className="bg-mvx-orange/10 pt-10 pb-20 mb-20 rounded-b-lg">
        <div className="w-[90%] sm:w-4/5 m-auto">
          <span className="text-sm bg-[#005ECD]/10 px-2.5 py-1.5 rounded-lg text-[#1261BF] font-bold">
            Our products
          </span>
          <p className="font-catalina text-2xl sm:text-3xl mt-4 mb-6">
            Tools so powerful, you don't <br className="hidden sm:block" />
            have to stress anymore
          </p>
          <div className="flex items-center">
            <div className="w-full sm:w-1/2 pr-0 sm:pr-[100px] shrink-0">
              {products.map((p, index) => (
                <React.Fragment key={index}>
                  <Product
                    index={index}
                    pageSize={pageSize}
                    product={p}
                    setProduct={setProduct}
                    active={product === index}
                  />
                </React.Fragment>
              ))}
            </div>
            <div className="w-1/2 hidden sm:block shrink-0">
              <img src={products[product].image} className="w-full h-auto" alt={products[product].title} />
            </div>
          </div>
        </div>
      </ProductsContainer>
    </div>
  );
};

const Product = ({ index, pageSize, product, setProduct, active }) => {
  return (
    <div className="border-b border-mvx-blue/30 pb-4">
      <div
        onClick={() => setProduct(index)}
        className={`py-4 px-4 flex items-center justify-between font-bold ${
          active && "text-mvx-blue"
        }`}
      >
        {product.title}
        <div className="w-[40px] h-[40px] cursor-pointer flex items-center justify-center">
          <div className="w-5 h-5 rounded-full border border-mvx-blue flex items-center justify-center">
            {active ? (
              <span className="material-icons text-mvx-blue !text-sm">
                remove
              </span>
            ) : (
              <span className="material-icons text-mvx-blue !text-sm">add</span>
            )}
          </div>
        </div>
      </div>
      <div
        className={`w-[90%] sm:w-4/5 overflow-hidden ${
          active ? "max-h-96 duration-500" : "max-h-0"
        }`}
      >
        <p className="px-4 text-xs w-full text-gray-700 pb-6">
          {product.description}{" "}
        </p>
        {pageSize < 640 ? (
          <div className="w-full sm:hidden block shrink-0 py-6">
            {/* {product.image} */}
            <img src={product.image} className="w-full h-auto" alt={product.title} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const ProductsContainer = styled.div`
  background-color: rgba(255, 138, 0, 0.07);
`;

export default Products;
