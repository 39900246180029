import React, { useEffect, useState } from "react";
import "styles/leaflet/leaflet.css";
import { Link, useNavigate } from "react-router-dom";
import FinanceActivities from "components/common/FinanceActivities";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CreditScoreEmpty } from "assets/arts";
import { useDispatch, useSelector } from "react-redux";
import { financeActions } from "actions";
import Skeleton from "components/common/Skeleton";
import { financeService } from "services";
import { financeConstant } from "constants";
import ShipmentLogo from "assets/images/dashboard/empty_state.svg";
import { GoBackInfo } from "../common/MobileNav";
import RandomAddLogo from "../common/RandomAddLogo";

const FinancePage = () => {
  const { userAnalytics } = useSelector((state) => state.analytics);
  const { financeProfile, financeRequests, financeRequestsLoading } =
    useSelector((state) => state.finance);
  const [activeTab, setActiveTab] = useState(0);
  const [creditScoreLoading, setCreditScoreLoading] = useState(false);
  const [creditScore, setCreditScore] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createShipment = () => navigate("/dashboard/create-shipment");
  const startFinanceReq = () =>
    dispatch({ type: financeConstant.SET_FINANCE_REQ_STARTED, payload: true });

  const fetchAllFinanceRequests = (filterObj = {}) =>
    dispatch(financeActions.fetchFinanceRequests(filterObj));

  const fetchCreditScore = async () => {
    setCreditScoreLoading(true);
    try {
      const result = await financeService.fetchCreditScore();
      if (result.data.creditScore) {
        setCreditScore(result.data.creditScore);
        setCreditScoreLoading(false);
      }
    } catch (error) {
      setCreditScore("error");
      setCreditScoreLoading(false);
    }
  };

  useEffect(() => {
    fetchCreditScore();
  }, []);

  useEffect(() => {
    switch (activeTab) {
      case 0:
        fetchAllFinanceRequests();
        break;
      case 1:
        fetchAllFinanceRequests({ status: "completed" });
        break;
      case 2:
        fetchAllFinanceRequests({ status: "active" });
        break;
      case 3:
        fetchAllFinanceRequests({ status: "rejected" });
        break;
      case 4:
        fetchAllFinanceRequests({ status: "pending" });
        break;

      default:
        break;
    }
    // eslint-disable-next-line
  }, [activeTab]);

  return (
    <div>
      <GoBackInfo text="Freight Finance" />
      <RandomAddLogo />
      <div className="mt-16 sm:mt-0"></div>
      <div className="-ml-4 -mr-14 sm:ml-0 sm:mt-3 md:mt-0 sm:-mr-10 md:-mr-8 lg:mr-0">
        <p
          className="hidden sm:flex items-center text-xs cursor-pointer w-max"
          onClick={() => navigate(-1)}
        >
          <span className="material-icons mr-2 !text-base">
            keyboard_backspace
          </span>
          Close
        </p>
        <div className="hidden sm:flex md:flex-row gap-3 items-center justify-between mb-10">
          <p className="text-xl font-bold ">Finance Your Goods</p>
          <div className="flex">
            <button
              className="mr-5 border border-mvx-orange px-6 pl-4 py-3 rounded-[6px] bg-mvx-orange text-white flex items-center text-xs"
              onClick={startFinanceReq}
            >
              <span className="material-icons mr-2 !text-2xl !leading-4">
                add
              </span>
              Request Finance
            </button>
            <button
              className="border border-gray-400 px-6 pl-4 py-3 rounded-[6px] text-gray flex items-center text-xs"
              onClick={createShipment}
            >
              <span className="material-icons mr-2 !text-2xl !leading-4 text-gray-600">
                add
              </span>
              Create a Shipment
            </button>
          </div>
        </div>
        {/* <KYCDialog
        bgImg={KYCImage}
        dialogText={
          <span>
            You have an offer letter from MVX for your finance request{" "}
            <b>MVXF00023</b>
          </span>
        }
        btnText={"View Offer"}
      /> */}
        <div
          className={`max-w-[450px] md:max-w-none flex md:flex-row flex-col justify-between gap-5 md:gap-7 ${
            financeProfile?.files?.length ? "" : "mb-16"
          }`}
        >
          <section className="md:w-1/3 w-full">
            {/* Active loan balance section */}
            <div className="w-full rounded-[8px] bg-white mr-4 border border-[#E9E9E9] p-[20px] pt-5 pr-4">
              <div className="text-sm font-bold flex items-center justify-between">
                <div className="flex text-[#474747] text-base font-semibold">
                  Active Loan Balance
                </div>
                {/* <div className="w-[30px] h-[30px] duration-300 select-none cursor-pointer hover:bg-black/[0.1] flex items-center justify-center rounded-full">
                <span className="material-icons">more_vert</span>
              </div> */}
              </div>
              <p className="text-[32px] text-mvx-gray font-semibold mt-3">
                ${userAnalytics?.activeBalance}
              </p>
              <p className="text-sm mt-4 text-mvx-light-gray">
                Credit Repaid:{" "}
                <span className="text-black font-semibold text-sm">
                  ${userAnalytics?.totalPaid}
                </span>
              </p>
              <p className="text-sm mt-2 text-mvx-light-gray">
                Next Payment Due:{" "}
                <span className=" text-black font-semibold text-sm">
                  {userAnalytics?.nextRepaymentDate ?? "No due payment"}
                </span>
              </p>
            </div>
            {/* Loan statistic section */}
            <div className="w-full mt-6 rounded-[10px] bg-white mr-4 border border-[#E9E9E9] p-[20px] pt-5 pr-4">
              <div className="text-sm font-bold flex items-center justify-between">
                <div className="flex  text-[#333333] text-base font-semibold mt-4">
                  Loan Statistic
                </div>
              </div>
              <div className="mt-10 w-[170px] md:w-3/5 flex items-center mx-auto mb-11">
                <CircularProgressbar
                  value={userAnalytics?.parcentage}
                  text={`${userAnalytics?.parcentage ?? 0}%`}
                  strokeWidth={12}
                  styles={buildStyles({
                    textColor: "#091D51",
                    pathColor: "#1152FA",
                    trailColor: "#E6E6E6",
                    rotation: 0.5 + (1 - 65 / 100) / 1,
                  })}
                />
              </div>
              <div className="flex flex-row justify-between">
                <p className="text-sm text-mvx-light-gray">
                  <span className="material-icons mr-2 !text-sm !leading-4 text-mvx-blue">
                    radio_button_checked
                  </span>
                  Total Paid
                </p>
                <span className="text-black text-sm mr-4 font-semibold">
                  ${userAnalytics?.totalPaid}
                </span>
              </div>
              <div className="flex flex-row justify-between mt-2">
                <p className="text-sm text-mvx-light-gray">
                  <span className="material-icons mr-2 !text-sm !leading-4 text-mvx-blue">
                    radio_button_unchecked
                  </span>
                  Balance Remaining{" "}
                </p>
                <span className="text-black font-semibold text-sm mr-4">
                  ${userAnalytics?.activeBalance}
                </span>
              </div>
              <hr className="p-0 container mt-5" />
              <div className="mt-[18px]">
                <div className="flex flex-row justify-between">
                  <p className="text-sm text-mvx-light-gray">Active Loans</p>
                  <span className="text-black text-sm font-semibold mr-4">
                    {userAnalytics?.loanCount
                      ? `${userAnalytics.loanCount} ${
                          userAnalytics.count === 1 ? "Request" : "Requests"
                        }`
                      : "No Request"}
                  </span>
                </div>
                <div className="flex flex-row justify-between mt-2">
                  <p className="text-sm   text-mvx-light-gray">
                    Next Payment Date
                  </p>
                  <span className="text-black text-sm  mr-4 font-semibold">
                    {userAnalytics?.nextRepaymentDate ?? "No due payment"}
                  </span>
                </div>
              </div>
              <div className="mt-6 mb-3">
                <Link
                  to="#"
                  className="text-mvx-blue  text-sm underline font-semibold invisible"
                >
                  View Payment History
                </Link>
              </div>
            </div>
          </section>

          <section className="md:w-2/3 w-full">
            <div className=" md:mt-0 mt-4 w-full rounded-[15px] bg-white md:mr-4 border border-[#E9E9E9] pt-6 px-4 pb-3 mb-[100px] h-[775px]">
              <div className="text-sm font-bold flex items-center justify-between">
                <div className="flex  text-mvx-gray text-base font-semibold mt-1">
                  Finance Activities
                </div>
              </div>
              <div className="flex flex-row items-center text-xs mr-4 mt-8 mb-[10px] text-[#0E2B79] font-medium text-center [&>*:not(:first-of-type)]:ml-[14px] overflow-auto financeTabs">
                <button
                  className={`-mr-4 p-1 px-4 rounded-full ${
                    activeTab === 0 ? "text-white bg-mvx-blue2 font-bold" : ""
                  }`}
                  onClick={() => setActiveTab(0)}
                >
                  All
                </button>
                <button
                  className={`-mr-4 p-1 px-4 rounded-full ${
                    activeTab === 1 ? "text-white bg-mvx-blue2 font-bold" : ""
                  }`}
                  onClick={() => setActiveTab(1)}
                >
                  Completed
                </button>
                <button
                  className={`-mr-4 p-1 px-4 rounded-full ${
                    activeTab === 2 ? "text-white bg-mvx-blue2 font-bold" : ""
                  }`}
                  onClick={() => setActiveTab(2)}
                >
                  Active
                </button>
                <button
                  className={`-mr-4 p-1 px-4 rounded-full ${
                    activeTab === 3 ? "text-white bg-mvx-blue2 font-bold" : ""
                  }`}
                  onClick={() => setActiveTab(3)}
                >
                  Rejected
                </button>
                <button
                  className={`-mr-4 p-1 px-4 rounded-full ${
                    activeTab === 4 ? "text-white bg-mvx-blue2 font-bold" : ""
                  }`}
                  onClick={() => setActiveTab(4)}
                >
                  Pending
                </button>
              </div>
              <hr className="mt-[1px]" />
              {!financeRequests && !financeRequestsLoading && (
                <div className="flex justify-center items-center mt-4 mb-8">
                  <div className="flex flex-col items-center text-center">
                    <img src={ShipmentLogo} alt="shipment logo" />
                    <p className="max-w-[300px] text-[#6b6b6b] mb-3 font-bold">
                      You currently do not have any finance request.
                    </p>
                  </div>
                </div>
              )}{" "}
              {financeRequests?.data?.length > 0 && !financeRequestsLoading && (
                <div className="h-[87%] overflow-y-auto financeRequests">
                  {financeRequests?.data?.map((data, idx) => (
                    <div
                      className={`border-b border-b-[#E9E9E9] pt-1 ${
                        idx ? "mt-[10px] py-5 " : "mt-6 py-5"
                      } ${
                        idx + 1 === financeRequests?.data?.length &&
                        "border-b-0"
                      }`}
                      key={data._id}
                    >
                      <FinanceActivities
                        createdAt={data?.createdAt}
                        currentStatus={data?.currentStatus}
                        mvxid={data?.shiment_details?.mvxid}
                      />
                    </div>
                  ))}
                </div>
              )}
              {financeRequestsLoading && (
                <>
                  <FinaceSpinner />
                  <FinaceSpinner />
                  <FinaceSpinner />
                  <FinaceSpinner />
                  <FinaceSpinner />
                </>
              )}
            </div>
          </section>
        </div>

        <div className="-mt-[30px] md:-mt-[45px] mb-8 space-y-2">
          <h3 className="text-xl font-bold">Credit Score Rating</h3>
          <p className="text-dark text-[12px] font-medium">
            Here, you see a breakdown of your credit-worthiness
          </p>
        </div>
        {creditScore === "error" && (
          <div className="bg-white h-[350px] flex flex-col justify-center items-center max-w-[450px] md:max-w-none w-full border mb-20 rounded-[8px]">
            <CreditScoreEmpty className="-translate-x-6 w-[150px] lg:w-[200px] h-[150px] lg:h-[200px]" />
            <p className="text-[#8F8F8F] text-sm -mt-4 w-60 text-center -translate-x-4">
              The credit score for your account is not available yet
            </p>
          </div>
        )}
        {creditScore !== "error" && (
          <div
            className={`max-w-[450px] md:max-w-none flex md:flex-row flex-col justify-between gap-5 md:gap-7`}
          >
            <div className="md:w-1/3 w-full">
              <Skeleton
                dataLoaded={!creditScoreLoading}
                className={`w-full !rounded-[8px] h-[481px]`}
              >
                <div className="w-full rounded-[8px] bg-white mr-4 border border-[#E9E9E9] p-6 pt-4 pr-4 pb-4 mt-4">
                  <div className="text-sm font-bold flex items-center justify-between">
                    <div className="flex text-mvx-gray  text-base font-semibold">
                      My Credit Score
                    </div>
                    <div className=" bg-mvx-light-yellow py-[5px] px-[17px] text-mvx-orange mr-4 justify-between rounded-[5px] font-semibold">
                      Fair
                    </div>
                  </div>
                  <hr className="text-dark mt-4" />
                  <div className="mt-11 w-[200px] flex items-center mx-auto mb-10">
                    <CircularProgressbar
                      value={(creditScore / 5.0) * 100}
                      text={`${Math.round(+creditScore * 10) / 10}/5.0`}
                      strokeWidth={12}
                      styles={buildStyles({
                        textColor: "#091D51",
                        pathColor: "#F3DC42",
                        trailColor: "#E6E6E6",
                        rotation: 0.5 + (1 - 65 / 100) / 1,
                      })}
                    />
                  </div>
                  <h3 className="mt-4 text-sm  font-semibold">
                    What does this mean for me?
                  </h3>
                  <p className="text-[13px]  mt-2 text-[#474747]">
                    Having a credit score between{" "}
                    <span className="font-semibold">3.0</span> and{" "}
                    <span className="font-semibold">
                      3.5 puts you at an <span className="font-semibold"></span>
                      average rating
                    </span>{" "}
                    or a<span className="font-semibold"> fair risk</span>{" "}
                    assessment level,{" "}
                    <span className="font-semibold">reducing </span>how much you
                    may able to access.
                  </p>
                </div>
              </Skeleton>
            </div>
            <div className="md:w-2/3 w-full translate-y-[18px]">
              <div className="w-full rounded-[8px] bg-white mr-4 border border-[#E9E9E9] p-6 pt-5 pr-4 pb-14">
                <p className="text-mvx-gray text-base font-semibold">
                  Credit Score Breakdown
                </p>
                <hr className="text-dark mt-4" />
                <div className="mt-[35px]">
                  <p className="text-sm mt-2 text-[#333333]">
                    To assess your credit score, MVX, though the help of our
                    partners, put together the{" "}
                    <span className="font-semibold">area weight</span> and{" "}
                    <span className="font-semibold">
                      ratings of your Credit history
                    </span>{" "}
                    with MVX,{" "}
                    <span className="font-semibold">
                      Credit Bureau History, Business History, Financial
                      Information, Integrity
                    </span>
                    , and{" "}
                    <span className="font-semibold">Regulatory Compliance</span>
                    .
                  </p>
                </div>
                <h3 className="mt-7 text-sm text-mvx-gray  font-semibold">
                  Performace Metrics
                </h3>
                <div className="hidden md:flex flex-row mr-4 mt-4 text-[#0E2B79] font-semibold">
                  <div className="bg-[#FF001D] rounded-bl-[6px] rounded-tl-[6px] px-[24px] py-2 text-white text-[10px]">
                    Very Poor = 1
                  </div>
                  <div className="bg-[#FF576A] px-8 py-2 text-white text-[10px]">
                    Poor = 2
                  </div>
                  <div className="bg-[#FFAB27] px-8 py-2 text-white text-[10px]">
                    Fair = 3
                  </div>
                  <div className="bg-[#00D067] px-8 py-2 text-white text-[10px]">
                    Good = 4
                  </div>
                  <div className="bg-[#00B459] rounded-br-[6px] rounded-tr-[6px] px-[24px] py-2 text-white text-[10px]">
                    Very Good = 5
                  </div>
                </div>
                <h3 className="mt-7 text-sm text-mvx-gray  font-semibold">
                  Tips on how to improve your credit score
                </h3>
                <ul className="list-disc  text-[13px] ml-8 mt-4">
                  <li>Don't Miss Payment</li>
                  <li>Catch Up on Past-Due Ammount</li>
                  <li>Consolidate your loan portfolio into a single account</li>
                  <li>Automate your repayment </li>
                  <li>
                    Reduce the number of your facilities to the barest minimum
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FinancePage;

const FinaceSpinner = () => (
  <div className="flex w-full h-24 justify-between items-center border-b border-gray">
    <div className="flex items-center gap-4 w-7/12">
      <span>
        <Skeleton className="h-8 w-7 rounded-md" />
      </span>
      <div className="space-y-4 w-full">
        <div>
          <Skeleton className="h-5 rounded-lg w-full" />
        </div>
        <div>
          <Skeleton className="h-3.5 rounded-lg w-[120px]" />
        </div>
      </div>
    </div>
    <div>
      <Skeleton className="rounded-lg w-[100px] h-8" />
    </div>
  </div>
);
