import ShipmentLogo from "assets/images/dashboard/empty_state.svg";
import { useNavigate } from "react-router-dom";

const ShipmentsError = () => {
  const navigate = useNavigate();
  const onNavigate = () => {
    navigate("/dashboard/create-shipment");
  };

  return (
    <div className="flex justify-center items-center mt-4 mb-8">
      <div className="flex flex-col items-center text-center">
        <img src={ShipmentLogo} alt="shipment logo" />
        <p className="max-w-xs text-[#6b6b6b] mb-3 font-medium">
          You currently do not have any shipment.
        </p>
        <button
          className="text-white text-sm bg-mvx-blue3 flex gap-2 rounded-md py-2 px-5 items-center"
          onClick={onNavigate}
        >
          <span className="material-icons">add</span>
          <span>Create a Shipment</span>
        </button>
      </div>
    </div>
  );
};

export default ShipmentsError;
