import ContactSales from "components/common/ContactSales";
import FAQ from "components/common/FAQ";
import Footer from "components/common/Footer";
import Newsletter from "components/common/Newsletter";
import React from "react";
import Hero from "./Hero";
import MeetMVX from "./MeetMVX";
import Numbers from "./Numbers";
import Story from "./Story";

const About = () => {
  return <div>
      <Hero />
      <Story />
      <MeetMVX />
      <Numbers />
      <ContactSales />
      <FAQ />
      <Newsletter />
      <Footer />
  </div>;
};

export default About;
