import OtpInput from "react-otp-input";
import styled from "styled-components";

const VerifyWrapper = styled.div`
  .otp-input {
    display: flex;
    width: max-content;
    margin: 0 auto 25px;
    justify-content: center;
    @media only screen and (max-width: 430px) {
      margin: 0 auto 15px;
      width: 100%;
    }
    @media only screen and (max-width: 360px) {
      transform: translateX(-7px);
    }
    


    & > input {
      background: #cee4f5;
      height: 62px;
      width: 55px !important;
      border-radius: 6px;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;

      @media only screen and (max-width: 600px) {
        height: 50px;
        width: 45px !important;
      }

      @media only screen and (max-width: 430px) {
        height: 45px;
        width: 40px !important;
        font-size: 14px;
      }
    }
  }
`;

const VerifyEmail = ({
  OTP,
  setOTP,
  loading,
  VerifyEmail,
  resendOTP,
  goBackFunc,
  type,
  email,
}) => {
  const openEmailApp = () =>
    (window.location.href =
      "mailto:user@example.com?subject=Subject&body=message%20goes%20here");

  return (
    <VerifyWrapper className="bg-[#FFFFFF] rounded-[20px]">
      <p className="font-bold mb-3 text-xl">Verify OTP code</p>
      <p className="mb-12 text-xs">
        Your are almost there! We have sent an email with the verification code
        to your email address {email}
      </p>
      <div className="w-full justify-center mb-6 text-sm">
        <OtpInput
          value={OTP}
          onChange={(value) => setOTP(value)}
          numInputs={6}
          isInputNum={true}
          className="otp-input flex justify-center"
          separator={
            <span
              className={`mx-[3px] ${
                type === "signUp" ? "475:mx-[-30px]" : "475:mx-[-20px]"
              }`}
            ></span>
          }
        />
      </div>
      {OTP}

      <button
        type="submit"
        className={`bg-mvx-blue shadow-sm outline-0 rounded w-full h-[50px] text-white text-md mb-3 font-bold ${
          loading ? "!bg-mvx-light-blue" : ""
        }`}
        disabled={loading}
        onClick={VerifyEmail}
      >
        {loading
          ? "Verifying..."
          : type === "reset-password"
          ? "Reset password"
          : "Verify Account"}
      </button>

      <button
        type="submit"
        className="outline-0 border rounded w-full h-[50px] text-[#6B778C] text-md mb-6 font-bold mb-14"
        disabled={loading}
        onClick={openEmailApp}
      >
        Open my email app
      </button>

      <p
        className={`w-max mx-auto text-[#474747] text-xs font-normal ${
          loading ? "pointer-events-none" : "pointer-events-auto"
        }`}
      >
        Didn’t receive code?{" "}
        <span onClick={resendOTP} className="text-mvx-blue cursor-pointer">
          Resend OTP
        </span>
        <span
          onClick={goBackFunc}
          className="cursor-pointer block mt-4 underline text-center"
        >
          {type === "reset-password" ? "Change email address" : "Go back"}
        </span>
      </p>
    </VerifyWrapper>
  );
};

export default VerifyEmail;
