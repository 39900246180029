import "./App.css";
import "styles/radio.css";
import "styles/illustration.css";
import "styles/fonts/catalina_rayden/font.css";
import "styles/fonts/montserrat/font.css";
import "styles/fonts/material_icons/font.css";
import { Route, Routes, Navigate } from "react-router-dom";
import ScrollToTop from "components/common/ScrollToTop";
import routes from "routes";
import { Toaster } from "react-hot-toast";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import ThemedStyleSheet from "react-with-styles/lib/ThemedStyleSheet";
import aphroditeInterface from "react-with-styles-interface-aphrodite";
import DefaultTheme from "react-dates/lib/theme/DefaultTheme";
import Dashboard from "components/Dashboard";
import { PrivateRoutes, PublicRoutes } from "layouts";

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    font: {
      ...DefaultTheme.reactDates.font,
      size: 13,
      input: {
        ...DefaultTheme.reactDates.font.input,
        size: 12,
      },
    },
    sizing: {
      ...DefaultTheme.reactDates.sizing,
      inputWidth: "100%",
    },
    color: {
      ...DefaultTheme.reactDates.color,
      border: "#e5e7eb",
      selected: {
        ...DefaultTheme.reactDates.color.selected,
        backgroundColor: "#005ECD",
        backgroundColor_active: "#005ECD",
        backgroundColor_hover: "#005ECD",
        borderColor: "#005ECD",
        borderColor_active: "#005ECD",
        borderColor_hover: "#005ECD",
      },
    },
    border: {
      ...DefaultTheme.reactDates.border,
      input: {
        ...DefaultTheme.reactDates.border.input,
        borderBottomFocused: "2px solid transparent",
      },
    },
  },
});

function App() {
  const authRoutes = ["/enter", "/register", "/forgot-password"];

  const getRoutes = (routes, parent) => {
    return routes
      .filter((r) => (parent ? r.parent === parent : !r.parent))
      .map((route, routeKey) => {
        let routesArray = [];

        if (route.children) {
          routesArray = route.children.map((child, key) => {
            return (
              <Route
                key={`${routeKey}_${key}`}
                path={child.path}
                element={child.component}
              />
            );
          });
        }
        if (route.path && route.children) {
          routesArray.push(
            <Route key={routeKey} path={route.path} element={route.component} />
          );
        }

        if (route.children) {
          return routesArray;
        } else {
          const isAuthPage = authRoutes.includes(route.path);
          return (
            <Route
              key={routeKey}
              path={route.path}
              element={
                isAuthPage ? (
                  <PublicRoutes>{route.component}</PublicRoutes>
                ) : (
                  route.component
                )
              }
            />
          );
        }
      });
  };

  return (
    <ScrollToTop>
      <Toaster position="top-right" />
      <Routes>
        {getRoutes(routes)}
        <Route
          path="/track-shipment"
          element={<Navigate replace to="/dashboard/shipment?track=true" />}
        />
        <Route
          path="dashboard"
          element={
            <PrivateRoutes>
              <Dashboard />
            </PrivateRoutes>
          }
        >
          {getRoutes(routes, "dashboard")}
        </Route>
      </Routes>
    </ScrollToTop>
  );
}

export default App;
