import {
  AccountManagerIcon,
  MobileBellIcon,
  MobileDashboadIcon,
  MobileFinanceIcon,
  MobileNoUserImage,
  MobileSettingsIcon,
  MobileShipmentIcon,
} from "assets/arts";
import ModalContainer from "components/common/modals/ModalContainer";
import { appConstants } from "constants";
import { getInitials } from "helpers";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AccountManagerImage } from "./Sidebar";

export const NavLinksModal = () => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accountManager } = user;
  
  const getCurrentRoute = (text) => {
    if (location.pathname.split("-")[0].substring(0, 25).includes(text)) {
      return true;
    } else {
      return false;
    }
  };

  const logOut = () => {
    dispatch({ type: "LOG_OUT" });
    setTimeout(() => {
      dispatch({ type: appConstants.TOGGLE_NAV_MODAL, payload: false });
    }, 3000);
  };

  return (
    <ModalContainer
      closeModal={() =>
        dispatch({ type: appConstants.TOGGLE_NAV_MODAL, payload: false })
      }
    >
      <div className="fixed inset-y-0 left-0 bg-white w-[85%] h-full flex flex-col py-9 pl-6 pr-7 gap-10 overflow-x-hidden overflow-y-auto mobileNav">
        <div className="flex gap-4 items-center h-28">
          <div className="relative rounded-full w-20 h-20 border border-gray-200 w-1/3">
            {!user?.avatar_url ? (
              <div className="w-full h-full rounded-full"> 
                <img
                  src={user?.avatar_url}
                  alt={user.first_name} 
                  className="w-full h-full object-cover"
                />
              </div>
            ) : (
              <div className="flex items-center justify-center h-full w-full rounded-full">
                <MobileNoUserImage width="55px" height="55px" color="red" />
              </div>
            )}
          </div>
          <div className="text-[#333] spaace-y-0.5 w-2/3">
            <p className="text-[20px] font-black capitalize leading-7">
              {user.company_name}
            </p>
            <p className="font-medium text-sm break-words">{user.email}</p>
            <p className="text-[#40a81b] font-bold">Active</p>
          </div>
        </div>
        <div>
          <button
            className="font-bold text-center py-3.5 border border-[#686868] rounded-md text-[#686868] flex items-center justify-center gap-4 w-full max-w-[350px]"
            onClick={() => {
              navigate("/dashboard/shipments-finance");
              dispatch({ type: appConstants.TOGGLE_NAV_MODAL, payload: false });
            }}
          >
            <span className="material-icons">add</span>
            <span>Request Finance</span>
          </button>
        </div>
        <div className="flex gap-3.5 flex-col">
          <DashBoardLink
            url="/dashboard/home"
            text="dashboard"
            routeText={getCurrentRoute("home")}
            icon={
              <MobileDashboadIcon
                stroke={getCurrentRoute("home") ? "#1c56f2" : "#686868"}
              />
            }
          />
          <DashBoardLink
            url="/dashboard/shipments"
            text="shipments"
            routeText={getCurrentRoute("shipment")}
            icon={
              <MobileShipmentIcon
                stroke={getCurrentRoute("shipment") ? "#1c56f2" : "#686868"}
              />
            }
          />
          <DashBoardLink
            url="/dashboard/finance"
            text="freight finance"
            routeText={getCurrentRoute("finance")}
            icon={
              <MobileFinanceIcon
                stroke={getCurrentRoute("finance") ? "#1c56f2" : "#686868"}
              />
            }
          />
          {/* <DashBoardLink
            url="/dashboard/messages"
            text="messages"
            routeText={getCurrentRoute("message")}
            icon={
              <MobileMessageIcon
                stroke={getCurrentRoute("message") ? "#1c56f2" : "#686868"}
              />
            }
          /> */}
          {/* <DashBoardLink
            url="/dashboard/referrals"
            text="Referrals"
            routeText={getCurrentRoute("referral")}
            icon={
              <MobileReferralIcon
                stroke={getCurrentRoute("referral") ? "#1c56f2" : "#686868"}
              />
            }
          /> */}
          {/* <DashBoardLink
            url="/dashboard/help"
            text="help center"
            routeText={getCurrentRoute("help")}
            icon={
              <MobileHelpIcon
                stroke={getCurrentRoute("help") ? "#1c56f2" : "#686868"}
              />
            }
          /> */}
        </div>
        <hr />
        <div className="flex flex-col gap-3.5">
          <DashBoardLink
            url="/dashboard/notifications"
            text="notifications"
            routeText={getCurrentRoute("notification")}
            icon={
              <MobileBellIcon
                stroke={getCurrentRoute("notification") ? "#1c56f2" : "#686868"}
              />
            }
          />
          <DashBoardLink
            url="/dashboard/profile-settings"
            text="settings"
            routeText={getCurrentRoute("profile")}
            icon={
              <MobileSettingsIcon
                stroke={getCurrentRoute("profile") ? "#1c56f2" : "#686868"}
              />
            }
          />
        </div>
        {accountManager && (
          <div className="border border-[#d0d0d0] rounded-lg px-4 py-3.5 flex gap-4 max-w-[400px]">
            <div className="rounded-full w-16 accountManager self-center shrink-0">
              <div className="h-16 w-full rounded-full">
                {!accountManager.avatar && (
                  <div className="bg-mvx-blue rounded-full flex items-center justify-center text-white text-2xl font-bold h-full w-full">
                    <h2>{getInitials(accountManager.name)}</h2>
                  </div>
                )}
                {accountManager.avatar && (
                  <AccountManagerImage
                    url={accountManager.avatar}
                    className="h-full w-full rounded-full m-auto"
                  ></AccountManagerImage>
                )}
              </div>
            </div>
            <div className="w-auto min-w-[100px] break-words shrink-1">
              <div>
                <p className="font-black text-[15px] text-[#333]">
                  Account Manager
                </p>
                <div
                  className="flex items-center gap-x-2 cursor-pointer"
                  onClick={() => navigate("/dashboard/messages")}
                >
                  <AccountManagerIcon width="23px" height="23px" />
                  <p className="text-[#1C56F2] text-[12px] sm:text-sm font-semibold break-words">
                    {accountManager.name}
                  </p>
                </div>
              </div>

              <a
                href={`mailto:${accountManager.email}`}
                className="text-[12px] text-mvx-blue underline cursor-pointer font-medium"
              >
                {accountManager.email}
              </a>
            </div>
          </div>
        )}
        <p
          className="flex items-center gap-3 cursor-pointer w-[250px]"
          onClick={logOut}
        >
          <span className="material-icons !text-[#f90000]">
            power_settings_new
          </span>
          <span className="text-[#f90000] font-semibold text-[18px]">
            Log out
          </span>
        </p>
      </div>
    </ModalContainer>
  );
};

const DashBoardLink = ({ url, icon, text, routeText }) => {
  const dispatch = useDispatch();
    
  return (
    <Link to={url} style={{ width: "250px" }}>
      <div 
        className="flex gap-3 items-center"
        onClick={() => 
          dispatch({ type: appConstants.TOGGLE_NAV_MODAL, payload: false })
        }
      >
        <span>{icon}</span>
        <span
          className={`text-[17px] capitalize ${
            routeText ? "text-mvx-blue font-bold" : "text-[#686868] font-medium"
          }`}
        >
          {text}
        </span>
      </div>
    </Link>
  );
};
