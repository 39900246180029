import React from "react";
import { useNavigate } from "react-router-dom";
import { getShipmentStatus } from "helpers/getShipmentStatus";
import moment from "moment";
import { useState } from "react";

const Shipment = ({ shipment, cancelShipment, deleteShipment }) => {
  const [mobileModal, setMobileModal] = useState(false);

  const shipmentStatus =
    shipment.status === "draft"
      ? "draft"
      : getShipmentStatus(shipment.currentStatus || shipment.status);
  const navigate = useNavigate();

  const gotoShipmentDetails = () => {
    if (shipment.status === "PENDING") {
      return;
    } else {
      navigate(`/dashboard/shipment/${shipment._id}/`);
    }
  };

  return (
    <div className="flex items-start px-4 sm:px-6 py-4 border-b border-gray text-xs text-[#2B2B2B] relative gap-1 md:gap-0 min-w-[800px] md:min-w-[730px]">
      <div className="shrink-0 min-w-[85px] sm:min-w-[100px] w-[15%]">
        {moment(shipment.createdAt).format("ddd, Do MMM YYYY")}: <br />
        {moment(shipment.createdAt).format("h:mm:ss a")}
      </div>

      <div
        onClick={() => navigate(`/dashboard/shipment/${shipment._id}/`)}
        className="shrink-0 hover:text-mvx-blue cursor-pointer min-w-[85px] sm:min-w-[100px] w-[15%] font-bold"
      >
        MVXT{shipment.mvxid || "-NIL"}
      </div>
      <div className="shrink-0 min-w-[170px] sm:min-w-[200px] w-[30%] font-bold flex">
        <div className="w-[40px] shrink-0 mr-[10px]">
          <div className="w-[2px] h-full m-auto py-[12.5px]">
            <div
              className={`w-full h-full ${
                shipmentStatus === "active" ? "bg-[#40A81B]" : "bg-[#ACACAC]"
              }`}
            ></div>
          </div>
        </div>
        <div className="w-full">
          <div className="pb-6 relative pt-1">
            {shipment.origin.address || "No origin address"}
            <div className="w-[40px] h-[25px] rounded-full absolute -left-[50px] top-0">
              <div
                className={`w-[25px] h-[25px] m-auto flex items-center justify-center rounded-full ${
                  shipmentStatus === "active" ? "bg-[#40A81B]/20" : ""
                }`}
              >
                <div
                  className={`w-[15px] h-[15px] border-4 m-auto bg-white rounded-full ${
                    shipmentStatus === "active"
                      ? "border-[#40A81B]"
                      : "border-[#ACACAC]"
                  }`}
                ></div>
              </div>
            </div>
          </div>
          <div className="relative">
            {shipment.destination.address || "No destination address"}
            <div className="w-[40px] h-full bg-white absolute -left-[50px] top-0">
              <div
                className={`w-[15px] h-[15px] m-auto border-4 bg-white ${
                  shipmentStatus === "active"
                    ? "border-[#40A81B]"
                    : "border-[#ACACAC]"
                }`}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="shrink-0 min-w-[85px] sm:min-w-[100px] w-1/5 flex flex-col items-start">
        <div
          className={`${
            shipmentStatus === "active"
              ? "bg-[#49D33D]/[0.18] text-[#40A81B]"
              : shipmentStatus === "completed"
              ? "bg-[#1152FB]/[0.07] text-[#1152FB]"
              : shipmentStatus === "cancelled"
              ? "bg-[#FF0000]/[0.08] text-[#FF0000]"
              : shipmentStatus === "pending"
              ? "bg-[#FF8A00]/[0.08] text-[#FF8A00]"
              : shipmentStatus === "draft"
              ? "bg-[#ff6f00]/[0.17] text-[#ff5900]"
              : ""
          } 
            font-bold px-4 py-1.5 rounded-full mb-2 capitalize`}
        >
          {shipmentStatus}
        </div>
        {shipmentStatus === "active" && (
          <p
            className="text-mvx-blue font-bold flex items-center select-none cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/dashboard/shipment/${shipment._id}/movements`);
            }}
          >
            <span className="underline">Track Shipment</span>
            <span className="material-icons text-mvx-blue !text-base">
              north_east
            </span>
          </p>
        )}
      </div>
      <div className="shrink-0 min-w-[65px] sm:min-w-[80px] w-[10%] text-center flex justify-center items-center">
        <span className="bg-mvx-blue px-4 py-1.5 text-white rounded-full font-bold">
          {shipment.movement_count || 0}
        </span>
      </div>
      <div className="shrink-0 min-w-[65px] sm:min-w-[80px] w-[10%] text-center">
        {/* desktop popup  */}
        <div className="hidden relative group w-[28px] h-[28px] md:flex items-center justify-center m-auto text-[#C4C4C4] duration-300 select-none cursor-pointer hover:bg-black/[0.05] rounded-full">
          <span className="material-icons">more_vert</span>
          <div className="hidden group-hover:block shadow-[3px_4px_15px_0_#0000001A] bg-white rounded-md py-1.5 absolute text-[12px] text-[#686868] font-medium w-36 z-10 top-full text-start -left-24 lg:-left-20">
            {shipment.status === "PENDING" ? (
              <p
                className="py-2  px-4 cursor-pointer border-grey-500 hover:bg-[#f1f1f1] w-max"
                onClick={() => navigate("/dashboard/create-shipment")}
              >
                Complete Shipment
              </p>
            ) : (
              <>
                <p
                  className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1]"
                  onClick={gotoShipmentDetails}
                >
                  View More Details
                </p>
                <p
                  className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1]"
                  onClick={(e) => {
                    cancelShipment(shipment);
                  }}
                >
                  Cancel Shipment
                </p>
                <p
                  className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1]"
                  onClick={(e) => {
                    deleteShipment(shipment);
                  }}
                >
                  Delete Shipment
                </p>
                <p className="py-2 px-3 hover:bg-[#f1f1f1]">Message</p>
              </>
            )}
          </div>
        </div>
        {/* mobile popup  */}
        <div
          className="md:hidden relative w-[28px] h-[28px] flex items-center justify-center m-auto text-[#C4C4C4] duration-300 select-none cursor-pointer hover:bg-black/[0.05] rounded-full"
          onClick={() => setMobileModal((prev) => !prev)}
        >
          <span className="material-icons">more_vert</span>
          {mobileModal && (
            <div className="shadow-[3px_4px_15px_0_#0000001A] bg-white rounded-md py-1.5 absolute text-[12px] text-[#686868] font-medium w-36 z-10 top-full text-start -left-24">
              {shipment.status === "PENDING" ? (
                <p
                  className="py-2  px-4 cursor-pointer border-grey-500 hover:bg-[#f1f1f1] w-max"
                  onClick={() => navigate("/dashboard/create-shipment")}
                >
                  Complete Shipment
                </p>
              ) : (
                <>
                  <p
                    className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1]"
                    onClick={gotoShipmentDetails}
                  >
                    View More Details
                  </p>
                  <p
                    className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1]"
                    onClick={(e) => {
                      cancelShipment(shipment);
                    }}
                  >
                    Cancel Shipment
                  </p>
                  <p
                    className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1]"
                    onClick={(e) => {
                      deleteShipment(shipment);
                    }}
                  >
                    Delete Shipment
                  </p>
                  <p className="py-2 px-3 hover:bg-[#f1f1f1]">Message</p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Shipment;
