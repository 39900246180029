import { useDispatch } from "react-redux";
import { authConstants } from "constants";
import { useNavigate } from "react-router-dom";

const ResetSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const gotoDashboard = () => {
    dispatch({
      type: authConstants.UPDATE_PASSWORD_STATES_TO_DEFAULT,
    });
    navigate("/dashboard/home");
  };

  return (
    <div className="flex flex-col items-center justify-center h-full gap-10 bg-white p-8 rounded-lg">
      <GreenCheck />
      <div className="text-[#333333] text-center max-w-[270px] space-y-2">
        <p className="text-3xl font-extrabold">Password reset successful</p>
        <p className="font-medium text-sm">
          You can now login into your account with your new password
        </p>
      </div>
      <div>
        <button
          className="bg-mvx-blue text-white text-[13px] font-bold px-7 py-3 tracking-wide rounded-md"
          onClick={gotoDashboard}
        >
          Return to dashboard
        </button>
      </div>
    </div>
  );
};
export default ResetSuccess;

export const GreenCheck = () => {
  return (
    <div className="rounded-full w-16 h-16 bg-[#00db8f] flex items-center justify-center shadow-2xl shadow-[#00db8f]/25">
      <div className="rounded-full w-7 h-7 bg-white flex items-center justify-center">
        <span className="material-icons !text-[20px] !text-[#00db8f] !font-bold">
          check
        </span>
      </div>
    </div>
  );
};
