import ModalContainer from "components/common/modals/ModalContainer";
import { useState, useMemo } from "react";
import { checkValidButton } from "helpers/validateUserFormPage";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "actions";
import { authConstants } from "constants";
import ResetSuccess from "./ResetSuccess";
import Loading from "components/common/Loading";

const PasswordPopUp = () => {
  const [toggleCurrentPassword, setToggleCurrentPassword] = useState(false);
  const [toggleNewPassword, setToggleNewPassword] = useState(false);
  const [toggleResetNewPassword, setToggleResetNewPassword] = useState(false);
  const [inputOneBlurFocus, setInputOneBlurFocus] = useState(false);
  const [inputTwoBlurFocus, setInputTwoBlurFocus] = useState(false);
  const [inputThreeBlurFocus, setInputThreeBlurFocus] = useState(false);
  const dispatch = useDispatch();
  const [passwordForm, setPasswordForm] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordRetype: "",
  });

  const { updateUserPasswordLoading, updateUserPasswordSuccess } = useSelector(
    (state) => state.auth
  );

  const togglePasswordModal = () => {
    dispatch({
      type: authConstants.TOGGLE_UPDATE_PASSWORD_MODAL,
      payload: false,
    });
    // dispatch(authActions.toggleUpdatePasswordModal());
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    setPasswordForm((passwords) => ({
      ...passwords,
      [name]: value,
    }));
  };

  const { oldPassword, newPassword, newPasswordRetype } = passwordForm;

  const checkFIlledInputs = useMemo(() => {
    if (!oldPassword || !newPassword || !newPasswordRetype) {
      return false;
    } else {
      return true;
    }
  }, [oldPassword, newPassword, newPasswordRetype]);

  const validatePasswords = useMemo(() => {
    if (newPasswordRetype.length > 0) {
      if (newPassword !== newPasswordRetype) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPasswordRetype]);

  const allowButtonClick = checkValidButton(
    checkFIlledInputs,
    validatePasswords
  );

  const closeModal = () => {
    dispatch(authActions.updatePasswordStateToDefault());
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (updateUserPasswordLoading) {
      return;
    }
    dispatch(
      authActions.updateUserPassword({
        password: newPassword,
        old_password: oldPassword,
      })
    );
  };

  return (
    <ModalContainer closeModal={closeModal}>
      <div className="flex flex-col items center justify-center bg-white rounded-2xl w-[485px] min-h-[530px]">
        {updateUserPasswordSuccess ? (
          <ResetSuccess />
        ) : (
          <div>
            <p className="text-center py-6 border-b border-[#dddddd] text-[#333333] font-bold bg-[#fbfbfb] text-lg rounded-t-2xl">
              Reset your password
            </p>
            <form onSubmit={onSubmit}>
              <div className="px-12 py-12 flex flex-col gap-5">
                {/* Old password */}
                <div className="flex flex-col gap-2.5 text-[#333333]">
                  <label
                    htmlFor="oldPassword"
                    className="font-semibold text-[13.5px] ml-1"
                  >
                    Old password
                  </label>
                  <div className="relative">
                    <input
                      id="oldPassword"
                      name="oldPassword"
                      value={oldPassword}
                      onChange={onChange}
                      onBlur={() => setInputOneBlurFocus(false)}
                      onFocus={() => setInputOneBlurFocus(true)}
                      type={toggleCurrentPassword ? "text" : "password"}
                      placeholder="Enter current password"
                      className={`bg-[#f5f5f5] border border-[#dddddd] px-4 py-2.5 rounded-md text-[12.75px] font-semibold outline-0 w-full focus-visible:border-mvx-blue placeholder:text-[#b2b2b2] placeholder:font-medium focus-visible:border-2 focus-visible:bg-[#fbfbfb] ${
                        toggleCurrentPassword
                          ? "tracking-normal"
                          : "tracking-widest placeholder:tracking-normal"
                      }`}
                    />
                    {oldPassword.length > 0 && (
                      <span
                        className={`absolute material-icons cursor-pointer right-5 top-3 !text-[20px] ${
                          inputOneBlurFocus
                            ? "!text-[#333333]"
                            : "!text-[#b2b2b2]"
                        }`}
                        onClick={() =>
                          setToggleCurrentPassword((prev) => !prev)
                        }
                      >
                        {toggleCurrentPassword
                          ? "visibility_off"
                          : "visibility"}
                      </span>
                    )}
                  </div>
                </div>
                {/* New Password */}
                <div className="flex flex-col gap-2.5 text-[#333333]">
                  <label
                    htmlFor="newPassword"
                    className="font-semibold text-[13.5px] ml-1"
                  >
                    New password
                  </label>
                  <div className="relative">
                    <input
                      id="newPassword"
                      name="newPassword"
                      value={newPassword}
                      onChange={onChange}
                      onBlur={() => setInputTwoBlurFocus(false)}
                      onFocus={() => setInputTwoBlurFocus(true)}
                      type={toggleNewPassword ? "text" : "password"}
                      placeholder="Enter new password"
                      className={`bg-[#f5f5f5] border border-[#dddddd] px-4 py-2.5 rounded-md text-[12.75px] font-semibold outline-0 w-full focus-visible:border-mvx-blue placeholder:text-[#b2b2b2] placeholder:font-medium focus-visible:border-2 focus-visible:bg-[#fbfbfb] ${
                        toggleNewPassword
                          ? ":tracking-normal"
                          : "tracking-widest placeholder:tracking-normal"
                      }`}
                    />
                    {newPassword.length > 0 && (
                      <span
                        className={`absolute material-icons cursor-pointer right-5 top-3 !text-[20px] ${
                          inputThreeBlurFocus
                            ? "!text-[#333333]"
                            : "!text-[#b2b2b2]"
                        }`}
                        onClick={() => setToggleNewPassword((prev) => !prev)}
                      >
                        {toggleNewPassword ? "visibility_off" : "visibility"}
                      </span>
                    )}
                  </div>
                </div>
                {/* Re-enter New Password */}
                <div className="flex flex-col gap-2.5 text-[#333333]">
                  <label
                    htmlFor="newPasswordRetype"
                    className="font-semibold text-[13.5px] ml-1"
                  >
                    Re-enter new password
                  </label>
                  <div className="relative">
                    <input
                      id="newPasswordRetype"
                      name="newPasswordRetype"
                      value={newPasswordRetype}
                      onChange={onChange}
                      onBlur={() => setInputThreeBlurFocus(false)}
                      onFocus={() => setInputThreeBlurFocus(true)}
                      type={toggleResetNewPassword ? "text" : "password"}
                      placeholder="Enter new password"
                      className={`bg-[#f5f5f5] border border-[#dddddd] px-4 py-2.5 rounded-md text-[12.75px] font-semibold outline-0 w-full focus-visible:border-mvx-blue placeholder:text-[#b2b2b2] placeholder:font-medium   focus-visible:border-2 focus-visible:bg-[#fbfbfb] ${
                        toggleResetNewPassword
                          ? "tracking-normal"
                          : "tracking-widest placeholder:tracking-normal"
                      } ${
                        !validatePasswords
                          ? "border-2 border-mvx-red text-mvx-red focus-visible:border-mvx-red"
                          : "border border-[#dddddd] focus-visible:border-mvx-blue"
                      }`}
                    />
                    {newPasswordRetype.length > 0 && (
                      <span
                        className={`absolute material-icons cursor-pointer right-5 top-3 !text-[20px] ${
                          inputTwoBlurFocus
                            ? "!text-[#333333]"
                            : "!text-[#b2b2b2]"
                        }`}
                        onClick={() =>
                          setToggleResetNewPassword((prev) => !prev)
                        }
                      >
                        {toggleResetNewPassword
                          ? "visibility_off"
                          : "visibility"}
                      </span>
                    )}
                    {!validatePasswords && (
                      <span className="text-mvx-red text-[12px] font-bold">
                        Please make sure your passwords match.
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="text-[13px] font-semibold flex justify-end gap-3 px-12 py-5 border-t border-[#dddddd] rounded-b-2xl bg-[#fbfbfb] mt-2">
                <button
                  className="text-[#333333] bg-[#f1f1f1] border border-[#b8b8b8b] rounded-md px-6 py-2.5"
                  onClick={togglePasswordModal}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className={`relative text-white rounded-md px-6 py-2.5 tracking-wide w-[195px] ${
                    allowButtonClick ? "bg-mvx-blue" : "bg-[#b8cbff]"
                  }`}
                  type="submit"
                  disabled={!allowButtonClick}
                >
                  {updateUserPasswordLoading ? <Loading /> : "Update password"}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </ModalContainer>
  );
};
export default PasswordPopUp;
