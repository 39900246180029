import { PartnerIcon } from "assets/arts";
import React from "react";

const Partner = ({ setModal }) => {
  return (
    <div className="px-0 sm:px-4 pb-20 mb-0 sm:mb-10 rounded-b-lg">
      <div className="w-full sm:w-4/5 m-auto">
        <p className="w-4/5 m-auto sm:w-full font-catalina text-2xl sm:text-3xl pt-2 sm:pt-0 pb-8 sm:pb-0 mt-4 mb-6">
          Or Become a Partner
        </p>
        <div className="flex flex-wrap sm:flex-nowrap items-center bg-[#1261BF]/10 rounded-2xl py-12 px-4 sm:px-8">
          <div className="w-full sm:w-1/2 order-2 sm:order-1 pr-0 sm:pr-[50px] shrink-0">
            <img
              src={
                "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/graphic.png"
              }
              className="w-full h-auto"
              alt="MVX Partner"
            />
          </div>
          <div className="w-full sm:w-1/2 order-1 sm:order-2 shrink-0 pb-10 sm:pb-0 px-4 sm:px-0">
            <p className="font-bold mb-2">
              Join us in solving supply chain problems on a global scale
            </p>
            <p>
              At MVX, we make it easy for you to move your goods across the
              world.
            </p>
            <button
              type="button"
              onClick={() => setModal("become-a-partner")}
              className="text-mvx-blue border border-mvx-blue outline-0 rounded-xl h-[50px] px-8 text-sm mr-4 mt-10 flex items-center"
            >
              Become a Partner
              <PartnerIcon className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
