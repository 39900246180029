/* eslint-disable no-unused-vars */
import {
  Customs2Art,
  Customs3Art,
  CustomsMenuIcon,
  DashboardBlueIcon,
  DashboardIcon,
  Finance1Art,
  Finance2Art,
  Finance3Art,
  FinanceBlueIcon,
  FinanceIcon,
  FreightFinanceMenuIcon,
  FreightForwardingMenuIcon,
  HaulageMenuIcon,
  HelpBlueIcon,
  HelpIcon,
  MessagesBlueIcon,
  MessagesIcon,
  PaymentsBlueIcon,
  PaymentsIcon,
  ReferralsBlueIcon,
  ReferralsIcon,
  ShipmentBlueIcon,
  ShipmentIcon,
} from "assets/arts";
import About from "components/About";
import Auth from "components/Auth";
import ForgotPassword from "components/Auth/ForgotPassword";
import Contact from "components/Contact";
import CustomerProfile from "components/Dashboard/CustomerProfile";
import DashboardLanding from "components/Dashboard/Landing";
import Messages from "components/Dashboard/Messages/Index";
import Shipments from "components/Dashboard/Shipments";
import CreateShipment from "components/Dashboard/Shipments/CreateShipment";
import Shipment from "components/Dashboard/Shipments/ShipmentInstance";
import FinancePage from "components/Dashboard/Finance/Finance";
import Home from "components/Home";
import Partners from "components/Partners";
import Resources from "components/Resources";
import Services from "components/Services";
import gotoAddShipment from "helpers/gotoAddShipment";
import gotoCreateFinanceRequest from "helpers/gotoCreateFinanceRequest";
import CreateShipmentFinance from "components/Dashboard/Finance/CreateShipment";
import ViewShipment from "components/Dashboard/Finance/ViewShipment";
import Agents from "components/Agents";
import PaymentInvoice from "components/Dashboard/Payments/PaymentInvoice";
import NotificationExpand from "components/Dashboard/NotificationExpand";
import ShipmentsFinance from "components/Dashboard/Shipments/ShipmentsFinance";
import MobilePasswordUpdate from "components/Dashboard/CustomerProfile/Customer/MobilePasswordUpdate";
import Feedback from "components/Dashboard/Feedback";

var routes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/about",
    name: "About",
    component: <About />,
  },
  {
    name: "Services",
    children: [
      {
        name: "Freight Forwarding",
        sub: "Easy Freight Forwarding Service",
        path: "/freight-forwarding",
        icon: <FreightForwardingMenuIcon />,
        iconColor: "blue",
        component: (
          <Services
            heroTitle="Easy Freight Forwarding Service"
            heroDescription="MVX allows you to ship your cargo by air or sea to anywhere in 
            the world. While our team gives you all the support you need, 
            our tech makes this process simple and transparent for you."
            heroMedia={{
              src: "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/servicepage/freight-forwarding-hero.mp4",
              type: "video",
            }}
            primaryButtonCta="Create a Shipment"
            ctaLocation={gotoAddShipment}
            details={[
              {
                title: "MVX Ships Fast",
                sub: "When you visit mvx.trade, in 5 minutes or less, you can book a shipment by yourself. That means you can be more productive for your business, you can  do the things that matter.",
                art: {
                  src: "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/servicepage/freight-forwarding1.mp4",
                  type: "video",
                },
              },
              {
                title: "MVX Means Easy",
                sub: "You can now ship your cargo around the world from the comfort of your couch. Whether by air or sea, freight forwarding becomes much more convenient with MVX. No middle-men, and no tedious paperwork.",
                art: {
                  src: "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/servicepage/freight-forwarding2.mp4",
                  type: "video",
                },
                flip: true,
              },
              {
                title: "MVX Gives Visibility",
                sub: "Thanks to our simple tech, from the moment you book your shipment to the point of successful delivery, you have full visibility of your cargo. We also send you regular status updates, and if you have any questions, your dedicated Operations Specialist is available to answer.",
                art: {
                  src: "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/servicepage/freight-forwarding3.mp4",
                  type: "video",
                },
              },
            ]}
          />
        ),
      },
      {
        name: "Freight Finance",
        sub: "Get finance for your freight forwarding needs",
        path: "/freight-finance",
        icon: <FreightFinanceMenuIcon />,
        iconColor: "orange",
        component: (
          <Services
            heroTitle="Get Access to Freight Finance in Minutes"
            heroDescription="MVX makes it easy to get funds for your business on time. With 
            no upfront fees and minimal paperwork, you have access to 
            purchase order financing, freight financing, and import financing."
            heroMedia={{
              src: require("assets/images/services/videos/freight-finance-hero.png"),
              type: "image",
            }}
            primaryButtonCta="Create Finance Request"
            ctaLocation={gotoCreateFinanceRequest}
            buttonColor="orange"
            tagLine="Why Freight Finance?"
            details={[
              {
                title: "We tailor the process to your need",
                sub: "Every application for financing is judged on its own merits at MVX. That means, we’re able to assess your particular needs and fit each transaction to your specific business objectives. You don’t even need collateral.",
                art: {
                  src: <Finance1Art className="w-full sm:w-auto h-auto" />,
                  type: "svg",
                  position: "end",
                },
              },
              {
                title: "We reduce the wait time",
                sub: "With MVX, you only need to provide minimal paperwork so the decision-making time is much shorter than with traditional banks. We do our due diligence, but also ensure that the process is speedy. So you’re assured of quicker response rates.",
                art: {
                  src: <Finance2Art className="w-full sm:w-auto h-auto" />,
                  type: "svg",
                },
                flip: true,
              },
              {
                title: "We handle your logistics needs end-to-end",
                sub: "After funding your trade transaction, we also handle the freight shipping to your desired destination. We have your cargo covered by air, sea, and overland haulage.",
                art: {
                  src: <Finance3Art className="h-full w-auto m-auto" />,
                  type: "svg",
                },
              },
            ]}
          />
        ),
      },
      {
        name: "Haulage",
        sub: "Overland Logistics You Can Rely On",
        path: "/haulage",
        icon: <HaulageMenuIcon />,
        iconColor: "blue",
        component: (
          <Services
            heroTitle="Overland Logistics You Can Rely On"
            heroDescription="Our network of trucking partners make last-mile shipping easy 
              and cost-effective for you. Now you can get your cargo safely 
              from warehouse to warehouse, port to warehouse, or warehouse 
              to port."
            showClients={false}
            buttonColor="orange"
            heroMedia={{
              src: "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/servicepage/haulage-hero.mp4",
              type: "video",
            }}
            primaryButtonCta="Create a Shipment"
            ctaLocation={gotoAddShipment}
            details={[
              {
                title: "MVX Offers the Best Rates",
                sub: "We work with various trucking companies, so we can offer you the best cross country trucking service, at fair rates. Once we get your shipment details, we send different rates, and you select the best one for you. It’s that easy.",
                art: {
                  src: "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/servicepage/haulage1.mp4",
                  type: "video",
                },
              },
              {
                title: "MVX has Trustworthy Truckers",
                sub: "We have trucking partners in Nigeria, Ghana, South Africa, Kenya, and Rwanda. All tested, trusted, and available to haul your cargo across the country. Swift and safe.",
                art: {
                  src: "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/servicepage/haulage2.mp4",
                  type: "video",
                },
                flip: true,
              },
              {
                title: "MVX Ensures End-to-End Visiblity",
                sub: "Receive regular real-time updates on your shipment, from the moment we pick-up till we deliver safely at your destination. Once you login, you can also monitor the status of your shipment on our easy-to-use dashboard.",
                art: {
                  src: "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/servicepage/haulage3.mp4",
                  type: "video",
                },
              },
            ]}
          />
        ),
      },
      {
        name: "Customs",
        sub: "Clear your cargo fast",
        path: "/customs",
        icon: <CustomsMenuIcon />,
        iconColor: "orange",
        component: (
          <Services
            heroTitle="Clear Your Cargo Fast"
            heroDescription="In a lot of Africa, the cargo clearing process is broken, causing costly 
            delays to businesses. At MVX, our team of professional customs 
            brokers saves you from the process. We’ll help you minimize cost and 
            maximize time."
            tagLine="Why MVX?"
            heroMedia={{
              src: require("assets/images/services/videos/customs-hero.png"),
              type: "image",
            }}
            primaryButtonCta="Create a Shipment"
            ctaLocation={gotoAddShipment}
            details={[
              {
                title: "Transparent Process",
                sub: "Once you reach out to us, our team lets you know all the documents you’d need to clear your shipment from customs. We also keep you updated at every step of the process.",
                art: {
                  src: require("assets/images/services/videos/customs1.png"),
                  type: "image",
                },
                flip: true,
              },
              {
                title: "Speedy Cargo Clearance",
                sub: "At an average of 5 days, we have one of the shorter clearance times in the region. We have a system that correctly classifies your goods and assesses your documents to make sure they’re acceptable. This cuts down the time your cargo has to spend at the port.",
                art: {
                  src: require("assets/images/services/videos/customs2.png"),
                  type: "image",
                  sub: (
                    <Customs2Art className="mt-2 ml-1 h-30 absolute left-0" />
                  ),
                },
              },
              {
                title: "End-to-End Logistics",
                sub: "Rather than having different parties handle the different stages of your shipping process, MVX takes care of it all. That means we handle your shipment from pick-up, clear it with customs, and ship to your destination. That way, you save money and eliminate the pesky problems that come up when you work with different parties.",
                art: {
                  src: require("assets/images/services/videos/customs3.png"),
                  type: "image",
                  sub: (
                    <Customs3Art className="absolute right-0 mt-2 mr-1 h-48 w-auto" />
                  ),
                },
                flip: true,
              },
            ]}
          />
        ),
      },
    ],
  },
  {
    path: "/resources",
    name: "Resources",
    component: <Resources />,
  },
  {
    path: "/partners",
    name: "Partners",
    component: <Partners />,
  },
  {
    path: "/speak-to-us",
    name: "Speak to us",
    component: <Contact />,
  },
  {
    path: "/agents",
    component: <Agents />,
  },
  {
    path: "/enter",
    component: <Auth path="login" />,
  },
  {
    path: "/register",
    component: <Auth path="signup" />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    parent: "dashboard",
    path: "home",
    name: "Dashboard",
    icon: {
      src: (props) =>
        props.isActive ? (
          <DashboardBlueIcon className="h-[21px] w-auto" />
        ) : (
          <DashboardIcon className="h-[21px] w-auto" />
        ),
      type: "svg",
    },
    component: <DashboardLanding />,
    children: [
      {
        parent: "dashboard",
        path: "profile-settings",
        name: "Profile Settings",
        component: <CustomerProfile />,
      },
      {
        parent: "dashboard",
        path: "profile-settings/update-password",
        name: "Update Password",
        component: <MobilePasswordUpdate />,
      },
      {
        parent: "dashboard",
        path: "notifications",
        name: "Notifications",
        component: <NotificationExpand />,
      },
    ],
  },
  {
    parent: "dashboard",
    path: "shipments",
    name: "Shipments",
    icon: {
      src: (props) =>
        props.isActive ? (
          <ShipmentBlueIcon className="h-[25px] w-auto" />
        ) : (
          <ShipmentIcon className="h-[25px] w-auto" />
        ),
      type: "svg",
    },
    component: <Shipments />,
    children: [
      {
        parent: "dashboard",
        path: "shipment/:shipmentId",
        name: "Shipment",
        component: <Shipment />,
      },

      {
        parent: "dashboard",
        path: "shipment/:shipmentId/:path",
        name: "Shipment Nested",
        component: <Shipment />,
      },
      {
        parent: "dashboard",
        path: "create-shipment",
        name: "Create Shipment",
        component: <CreateShipment />,
      },
      {
        parent: "dashboard",
        path: "shipments-finance",
        name: "Shipments Finance",
        component: <ShipmentsFinance />,
      },
    ],
  },
  {
    parent: "dashboard",
    path: "feedback/:shipmentId?",
    name: "User feedback",
    component: <Feedback />,
    visible: false,
    type: "fullpage"
  },
  {
    parent: "dashboard",
    path: "create-shipment",
    name: "Create Shipment",
    component: <CreateShipment />,
    visible: false,
  },
  {
    parent: "dashboard",
    path: "finance",
    name: "Finance",
    icon: {
      src: (props) =>
        props.isActive ? (
          <FinanceBlueIcon className="h-[23px] w-auto" />
        ) : (
          <FinanceIcon className="h-[23px] w-auto" />
        ),
      type: "svg",
    },
    component: <FinancePage />,
    children: [
      {
        parent: "dashboard",
        path: "finance-create-shipment",
        component: <CreateShipmentFinance />,
        visible: false,
      },
      {
        parent: "dashboard",
        path: "review-shipment",
        component: <ViewShipment />,
        visible: false,
      },
    ],
  },
  // {
  //   parent: "dashboard",
  //   path: "payments",
  //   name: "Payments",
  //   icon: {
  //     src: (props) =>
  //       props.isActive ? (
  //         <PaymentsBlueIcon className="w-[22px] h-auto opacity-70" />
  //       ) : (
  //         <PaymentsIcon className="w-[22px] h-auto opacity-70" />
  //       ),
  //     type: "svg",
  //   },
  //   component: <PaymentInvoice />,
  // },
  // {
  //   parent: "dashboard",
  //   path: "messages",
  //   name: "Messages",
  //   icon: {
  //     src: (props) =>
  //       props.isActive ? (
  //         <MessagesBlueIcon className="h-[21px] w-auto" />
  //       ) : (
  //         <MessagesIcon className="h-[21px] w-auto" />
  //       ),
  //     type: "svg",
  //   },
  //   component: <Messages />,
  // },
  // {
  //   parent: "dashboard",
  //   path: "referrals",
  //   name: "Referrals",
  //   icon: {
  //     src: (props) =>
  //       props.isActive ? (
  //         <ReferralsBlueIcon className="h-[22px] w-auto" />
  //       ) : (
  //         <ReferralsIcon className="h-[22px] w-auto" />
  //       ),
  //     type: "svg",
  //   },
  //   component: <DashboardLanding />,
  // },
  // {
  //   parent: "dashboard",
  //   path: "help",
  //   name: "Help Center",
  //   icon: {
  //     src: (props) =>
  //       props.isActive ? (
  //         <HelpBlueIcon className="h-[26px] w-auto" />
  //       ) : (
  //         <HelpIcon className="h-[26px] w-auto" />
  //       ),
  //     type: "svg",
  //   },
  //   component: <DashboardLanding />,
  // },
  {
    parent: "dashboard",
    path: "profile-settings",
    visible: false,
    component: <CustomerProfile />,
  },
  {
    parent: "dashboard",
    path: "notifications",
    visible: false,
    component: <NotificationExpand />,
  },

  {
    parent: "dashboard",
    path: "finance-create-shipment",

    component: <CreateShipmentFinance />,
    visible: false,
  },
  {
    parent: "dashboard",
    path: "review-shipment",
    component: <ViewShipment />,
    visible: false,
  },
];

export default routes;
