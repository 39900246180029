import { useState, useRef, useEffect, useMemo } from "react";
import { BusinessLogo, GreenCheckIcon } from "assets/arts";
import CompanyDocs from "./CompanyDocs";
import CountryCodeSelect from "components/common/CountryCodeSelect";
import Loading from "components/common/Loading";
import { useSelector, useDispatch } from "react-redux";
import Connect from "@mono.co/connect.js";
import { currentENVData } from "helpers";
import toast from "react-hot-toast";
import moment from "moment";
import CustomToast from "components/common/CustomToast";
import {
  checkEmaiiValidity,
  checkValidButton,
} from "helpers/validateUserFormPage";
import { financeActions, authActions } from "actions";
import { firebaseService } from "services";
import DeleteMvxAccModal from "./DeleteMvxAccModal";

const BusinessProfile = () => {
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const businessInfoDiv = useRef(null);
  const [showCompanyDocs, setShowCompanyDocs] = useState(false);
  const [showDeleteAccModal, setShowDeleteAccModal] = useState(false);
  const {
    financeProfile,
    updateFinanceProfileLoading: profileLoading,
    updateFinanceProfileImageLoading: imageLoading,
  } = useSelector((state) => state.finance);

  const { user } = useSelector((state) => state.auth);
  const [userImageReturnType, setUserImageReturnType] = useState();
  const [monoIDError, setMonoIDError] = useState("");
  const [companyProfile, setCompanyProfile] = useState({
    companyName: financeProfile?.companyName || "",
    companyAddress: financeProfile?.companyAddress || "",
    yearsOfOperation:
      moment().year() - moment(financeProfile?.companyAge).year(),
    companyRC: financeProfile?.companyRC,
    businessWebsite: financeProfile?.businessWebsite || "",
    directorName:
      `${financeProfile?.bvn.first_name} ${financeProfile?.bvn.last_name}` ||
      "",
    // directorName: financeProfile?.companyDirectors[0]?.name || "",
    directorEmail: financeProfile?.directorEmail || "",
    mobile:
      financeProfile?.bvn.phone_number ||
      user?.mobile.replace("+234", "0") ||
      "",
    directorBVN: financeProfile?.bvn.bvn || "",
  });

  const imageUploadPath = financeProfile?.companyName
    ? `companyImages/${financeProfile?.companyName.toLowerCase()}-${
        user?.user_id
      }`
    : `companyImages/${user?.user_id}`;

  const {
    companyName,
    companyAddress,
    yearsOfOperation,
    companyRC,
    businessWebsite,
    directorName,
    directorEmail,
    mobile,
    directorBVN,
  } = companyProfile;

  useEffect(() => {
    if (window.innerWidth > 1024) {
      businessInfoDiv.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (userImageReturnType && userImageReturnType !== "error") {
      const data = {
        businessWebsite,
        directorEmail,
        companyLogo: userImageReturnType,
      };
      dispatch(financeActions.updateFinanceProfileImage(data));
      setUserImageReturnType(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userImageReturnType]);

  useEffect(() => {
    if (monoIDError) {
      toast.custom((t) => (
        <CustomToast
          t={t}
          message={monoIDError + "! Please try again"}
          type="error"
        />
      ));
    }
  }, [monoIDError]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setCompanyProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkFIlledInputs = useMemo(() => {
    if (!businessWebsite || !directorEmail) {
      return false;
    } else {
      return true;
    }
  }, [businessWebsite, directorEmail]);

  const checkValidEmail = checkEmaiiValidity(directorEmail);

  const allowButtonClick = checkValidButton(checkFIlledInputs, checkValidEmail);

  const handleChangeDetails = (e) => {
    e.preventDefault();
    if (profileLoading) {
      return;
    }

    const data = {
      businessWebsite,
      directorEmail,
    };

    dispatch(financeActions.updateFinanceProfile(data));
  };

  const monoConnect = () => {
    const monoInstance = new Connect({
      onSuccess: ({ code }) => {
        dispatch(financeActions.saveMonoID({ monoId: code }));
      },
      onEvent: (eventName, data) => {
        if (eventName === "ERROR") setMonoIDError(data?.errorMessage);
      },
      key: currentENVData.monoKey,
    });
    monoInstance.setup();

    return monoInstance;
  };

  const handleImageUpload = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];

      firebaseService.uploadFile(
        `${imageUploadPath}/${file.name}`,
        file,
        setUserImageReturnType,
        undefined,
        undefined
      );
    }
  };

  const deactiveAccount = () => {
    dispatch(authActions.deactivateAccount());
  };

  const hideDeleteAccModal = () => {
    setShowDeleteAccModal(false);
  };

  return (
    <>
      <div ref={businessInfoDiv}>
        {showDeleteAccModal && (
          <DeleteMvxAccModal
            hideDeleteAccModal={hideDeleteAccModal}
            deactivateAccount={deactiveAccount}
          />
        )}
        <div className="lg:mt-16 flex flex-col sm:flex-row gap-8 max-w-[500px] sm:max-w-none">
          <div className="relative sm:w-[78%] lg:w-[70%] rounded-lg border border-transparent lg:border-[#dddddd] overflow-hiddden xl:w-[72%] order-2 sm:order-1">
            <p className="font-extrabold text-[17px] sm:text-base sm:font-bold rounded-t-lg border-b border-transparent lg:border-[#dddddd] bg-none lg:bg-[#fbfbfb] px-3 lg:py-5 lg:px-8 xl:px-10">
              Business details
            </p>
            <div className="px-3 lg:px-8 py-5 lg:py-10 xl:px-10">
              <form onSubmit={handleChangeDetails}>
                <div className="grid sm:grid-cols-2 sm:gap-x-5 xl:gap-x-14 gap-y-3">
                  {/* company name */}
                  <div>
                    <label
                      htmlFor="companyName"
                      className="block font-bold text-[13px]"
                    >
                      Legal business name
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      id="companyName"
                      readOnly
                      value={companyName}
                      onChange={onChange}
                      placeholder="Your business name"
                      className="capitalize px-5 py-6 sm:p-3.5 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                  outline-0 focus-visible:bg-[#e8f0fe]  focus-visible:border-2 focus-visible:border-mvx-blue
                  placeholder:text-[#b2b2b2] placeholder:font-medium"
                    />
                  </div>
                  {/* business address */}
                  <div>
                    <label
                      htmlFor="companyAddress"
                      className="block font-bold text-[13px]"
                    >
                      Business address
                    </label>
                    <input
                      type="text"
                      name="companyAddress"
                      id="companyAddress"
                      readOnly
                      value={companyAddress}
                      onChange={onChange}
                      placeholder="Your business address"
                      className="capitalize px-5 py-6 sm:p-3.5 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                  outline-0 focus-visible:bg-[#e8f0fe]  focus-visible:border-2 focus-visible:border-mvx-blue
                  placeholder:text-[#b2b2b2] placeholder:font-medium"
                    />
                  </div>
                  {/* years of operation */}
                  <div>
                    <label
                      htmlFor="yearsOfOperation"
                      className="block font-bold text-[13px]"
                    >
                      Years of operation
                    </label>
                    <input
                      type="text"
                      name="yearsOfOperation"
                      id="yearsOfOperation"
                      readOnly
                      value={yearsOfOperation}
                      onChange={onChange}
                      placeholder="Years of operation"
                      className="px-5 py-6 sm:p-3.5 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                  outline-0 focus-visible:bg-[#e8f0fe]  focus-visible:border-2 focus-visible:border-mvx-blue
                  placeholder:text-[#b2b2b2] placeholder:font-medium"
                    />
                  </div>
                  {/* CAC number */}
                  <div>
                    <label
                      htmlFor="companyRC"
                      className="block font-bold text-[13px]"
                    >
                      Registration number (CAC)
                    </label>
                    <input
                      type="text"
                      name="companyRC"
                      id="companyRC"
                      readOnly
                      value={companyRC}
                      onChange={onChange}
                      placeholder="CAC number"
                      className="px-5 py-6 sm:p-3.5 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 
                  text-[12.5px] font-semibold
                  outline-0 focus-visible:bg-[#e8f0fe]  focus-visible:border-2 focus-visible:border-mvx-blue
                  placeholder:text-[#b2b2b2] placeholder:font-medium"
                    />
                  </div>
                  {/* business website */}
                  <div>
                    <label
                      htmlFor="businessWebsite"
                      className="block font-bold text-[13px]"
                    >
                      Business website
                    </label>
                    <input
                      type="text"
                      name="businessWebsite"
                      id="businessWebsite"
                      value={businessWebsite}
                      onChange={onChange}
                      placeholder="Your business website"
                      className="px-5 py-6 sm:p-3.5 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                  outline-0 focus-visible:bg-[#e8f0fe]  focus-visible:border-2 focus-visible:border-mvx-blue
                  placeholder:text-[#b2b2b2] placeholder:font-medium"
                    />
                  </div>
                  {/* director name */}
                  <div>
                    <label
                      htmlFor="directorName"
                      className="block font-bold text-[13px]"
                    >
                      Director's name
                    </label>
                    <input
                      type="text"
                      name="directorName"
                      id="directorName"
                      readOnly
                      value={directorName}
                      onChange={onChange}
                      placeholder="DIrector's name"
                      className="px-5 py-6 sm:p-3.5 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                  outline-0 focus-visible:bg-[#e8f0fe]  focus-visible:border-2 focus-visible:border-mvx-blue
                  placeholder:text-[#b2b2b2] placeholder:font-medium"
                    />
                  </div>
                  {/* director email */}
                  <div>
                    <label
                      htmlFor="directorEmail"
                      className="block font-bold text-[13px]"
                    >
                      Director's email address
                    </label>
                    <input
                      type="email"
                      name="directorEmail"
                      id="directorEmail"
                      value={directorEmail}
                      onChange={onChange}
                      placeholder="Director's email address"
                      className="px-5 py-6 sm:p-3.5 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                  outline-0 focus-visible:bg-[#e8f0fe]  focus-visible:border-2 focus-visible:border-mvx-blue
                  placeholder:text-[#b2b2b2] placeholder:font-medium"
                    />
                    {!checkValidEmail && (
                      <span className="text-xs text-mvx-red font-bold">
                        Please enter a valid email address
                      </span>
                    )}
                  </div>
                  {/* phone number */}
                  <div>
                    <label
                      htmlFor="mobile"
                      className="block font-bold text-[13px]"
                    >
                      Phone number
                    </label>
                    <div className="flex items-center relative">
                      <div className="w-[80px] sm:w-[72px] lg:w-[83px] px-3 flex items-center justify-center absolute border-r-2 border-[#8f8f8f]">
                        <CountryCodeSelect
                          onChange={onChange}
                          setData={setCompanyProfile}
                          name="mobile_extension"
                          data={companyProfile.mobile_extension}
                        />
                      </div>

                      <input
                        type="tel"
                        name="mobile"
                        id="mobile"
                        readOnly
                        value={mobile}
                        onChange={onChange}
                        placeholder="Input phone number"
                        className="pr-5 py-6 sm:p-3.5 pl-24 sm:pl-24 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                    outline-0 focus-visible:bg-[#e8f0fe]  focus-visible:border-2 focus-visible:border-mvx-blue
                    placeholder:text-[#b2b2b2] placeholder:font-medium"
                      />
                    </div>
                  </div>
                  {/* director's BVN */}
                  <div>
                    <label
                      htmlFor="directorBVN"
                      className="block font-bold text-[13px]"
                    >
                      Director's BVN
                    </label>
                    <input
                      type="text"
                      name="directorBVN"
                      id="directorBVN"
                      readOnly
                      value={directorBVN}
                      onChange={onChange}
                      placeholder="Your bank verification number"
                      className="px-5 py-6 sm:p-3.5 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                  outline-0 focus-visible:bg-[#e8f0fe]  focus-visible:border-2 focus-visible:border-mvx-blue
                  placeholder:text-[#b2b2b2] placeholder:font-medium"
                    />
                  </div>
                  {/* link bank accout */}
                  <div className="self-center">
                    {financeProfile?.monoId && (
                      <p className="text-mvx-dark text-[13px] italic flex items-center gap-2 mb-2">
                        <GreenCheckIcon width={20} height={20} />
                        <span>Account Linked!</span>
                      </p>
                    )}
                    <div
                      className="flex gap-1 items-center font-extrabold text-mvx-blue text-[12.5px] cursor-pointer"
                      onClick={() => monoConnect().open()}
                    >
                      <span className="material-icons !font-bold !text-[18px] !text-mvx-blue -rotate-45">
                        link
                      </span>
                      <span>
                        {" "}
                        {financeProfile?.monoId
                          ? "Link Another Bank Account"
                          : "Link Your Bank Account"}
                      </span>
                    </div>
                  </div>
                </div>
                {/* save button */}
                <button
                  className={`relative px-5 py-3 rounded-md text-[12px] font-semibold text-white mt-5 w-[135px] h-[45px] ${
                    allowButtonClick ? "bg-mvx-blue" : "bg-[#b8cbff]"
                  }`}
                  type="submit"
                  disabled={!allowButtonClick}
                >
                  {profileLoading ? <Loading /> : " Save Changes"}
                </button>
              </form>
            </div>
            {!showCompanyDocs && (
              <div className="border border-[#dddddd] bg-white rounded-full w-8 h-8 absolute -bottom-3 left-2/4 hidden lg:flex items-center justify-center ">
                {/* {financeRequestsLoading ? (
                   <Loading color="mvx-blue" size={4} />
                 ) : ( */}
                <div
                  className="cursor-pointer hover:bg-mvx-blue hover:border-[#f9fbff] w-full h-full flex items-center justify-center rounded-full"
                  onClick={() => setShowCompanyDocs(true)}
                >
                  <span className="material-icons !font-semibold !text-[#8f8f8f] hover:!text-white">
                    expand_more
                  </span>
                </div>
                {/* )} */}
              </div>
            )}
          </div>
          <div className="order-1 sm:order-2 sm:w-[22%] lg:w-[30%] border border-transparent xl:w-[25%]">
            <div className="h-2/4 flex flex-col items-center justify-center border border-transparent lg:border-[#d6d6d6] rounded-md">
              <div className="space-y-8">
                <div className="flex flex-col gap-3 sm:gap-8 justify-center items-center text-center">
                  <div className="relative rounded-full w-32 h-32 sm:h-24 sm:w-24 lg:w-32 lg:h-32 border-gray-50">
                    {imageLoading && (
                      <div className="absolute w-full h-full rounded-full bg-[#000000]/30  flex items-center justify-center z-10">
                        <Loading color={"mvx-blue"} size={8} />
                      </div>
                    )}
                    {financeProfile?.companyLogo ? (
                      <div className="absolute w-full h-full rounded-full border border-gray-100">
                        <img
                          src={financeProfile.companyLogo}
                          alt="company logo"
                          className="w-full h-full rounded-full object-cover"
                        />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center h-full w-full bg-[#f1f1f1] rounded-full">
                        <BusinessLogo width="75px" height="75px" />
                      </div>
                    )}
                  </div>
                  <p className="sm:hidden font-black capitalize text-[#333] text-xl">
                    {user.company_name}
                  </p>
                  <input
                    type="file"
                    name="userImage"
                    id="userImage"
                    ref={fileInput}
                    className="hidden"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  <button
                    className="flex items-center py-3 px-7 sm:px-5 lg:px-7 gap-2 text-white font-semibold tracking-wide text-[13px] bg-mvx-blue rounded-md"
                    onClick={() => fileInput.current.click()}
                  >
                    <div className="sm:hidden lg:block">
                      <span className="material-icons !text-white !text-base">
                        add_a_photo
                      </span>
                    </div>
                    <span>Business logo</span>
                  </button>
                </div>
              </div>
            </div>
            <p className="hidden text-sm h-2/4 sm:flex items-center justify-center gap-1">
              <button
                className="bg-[#f1f1f1] border border-[#d6d6d6] px-10 sm:px-4 lg:px-10 py-2.5 rounded-md font-[12px] font-bold"
                onClick={() => setShowDeleteAccModal(true)}
              >
                Delete my MVX Account
              </button>
            </p>
          </div>
        </div>
      </div>
      {showCompanyDocs && <CompanyDocs />}
    </>
  );
};
export default BusinessProfile;
