import ActionButtons from "components/common/ActionButtons";
import Shipment from "./Shipment";
import { useState, useEffect, useRef, useCallback } from "react";
import { authService } from "services";
import { currentENVData } from "helpers";
import CustomToast from "components/common/CustomToast";
import toast from "react-hot-toast";
import Skeleton from "components/common/Skeleton";
import PopUpCalender from "./PopUpCalender";
import { getKnownDates } from "helpers/getfilterDates";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import ShipmentsError from "./ShipmentsError";
import Loading from "components/common/Loading";
import { renderMonthElement } from "helpers/renderMonthElement";
import { GoBackInfo } from "../common/MobileNav";
import RandomAddLogo from "../common/RandomAddLogo";
import { useScreensize } from "helpers/useScreensize";
import { useNavigate } from "react-router-dom";
import ModalContainer from "components/common/modals/ModalContainer";

const Shipments = () => {
  const navigate = useNavigate();
  const [screenSize] = useScreensize();
  const [userShipmentsLoading, setUserShipmentsLoading] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [userShipments, setUserShipments] = useState([]);
  const [wasLastList, setWasLastList] = useState(false);
  const [userShipmentsError, setUserShipmentsError] = useState("");
  const [currentFilter, setCurrentfilter] = useState("all");
  const [filterByDates, setFIlterByDates] = useState("");
  const [text, setText] = useState("Filter");
  const [openCalender, setOpenCalender] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showError, setShowError] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [showFilterTable, setShowFilterTable] = useState(false);
  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const toggleFilterTable = () => {
    setShowFilterTable((prevState) => !prevState);
  };

  const observer = useRef();

  // close customs calender popup
  const closePopUp = () => {
    setOpenCalender(false);
  };

  // set all states to default
  const setStatesToDefault = () => {
    setCurrPage(1);
    setPrevPage(0);
    setUserShipments([]);
    setWasLastList(false);
    setUserShipmentsError("");
    setUserShipmentsLoading(false);
    setFIlterByDates("");
    setShowFilterTable(false);
  };

  // query Shipments with known dates
  const queryUserShipmentsCustomDates = async (url, requestOptions) => {
    try {
      if (currentFilter === "all") {
        url = `shipment/users/filter?page=${currPage}&limit=10`;
        requestOptions = {
          method: "POST",
          body: JSON.stringify({
            start_date: moment(startDate?._d).format("DD-MM-YYYY"),
            end_date: moment(endDate?._d).format("DD-MM-YYYY"),
          }),
        };
      } else if (currentFilter === "draft") {
        url = `shipment/users/pending`;
        requestOptions = {
          method: "GET",
        };
      } else if (currentFilter === "active") {
        url = `shipment/users/filter?started=${true}&ended=${false}&page=${currPage}&limit=10`;
        requestOptions = {
          method: "POST",
          body: JSON.stringify({
            start_date: moment(startDate?._d).format("DD-MM-YYYY"),
            end_date: moment(endDate?._d).format("DD-MM-YYYY"),
          }),
        };
      } else if (currentFilter === "completed") {
        url = `shipment/users/filter?started=${true}&ended=${true}&page=${currPage}&limit=10`;
        requestOptions = {
          method: "POST",
          body: JSON.stringify({
            start_date: moment(startDate?._d).format("DD-MM-YYYY"),
            end_date: moment(endDate?._d).format("DD-MM-YYYY"),
          }),
        };
      } else if (currentFilter === "cancelled") {
        url = `shipment/users/filter?cancelled=true&page=${currPage}&limit=10`;
        requestOptions = {
          method: "POST",
          body: JSON.stringify({
            start_date: moment(startDate?._d).format("DD-MM-YYYY"),
            end_date: moment(endDate?._d).format("DD-MM-YYYY"),
          }),
        };
      } else if (currentFilter === "pending") {
        url = `shipment/users/filter?started=${false}&ended=${false}&page=${currPage}&limit=10`;
        requestOptions = {
          method: "POST",
          body: JSON.stringify({
            start_date: moment(startDate?._d).format("DD-MM-YYYY"),
            end_date: moment(endDate?._d).format("DD-MM-YYYY"),
          }),
        };
      }
      setUserShipmentsLoading(true);

      let pendingShipment;

      let response = await authService.apiGate(
        `${currentENVData.transitURL}/${url}`,
        requestOptions
      );

      if (currentFilter === "draft") {
        response.data = { ...response.data, status: "draft" };
      }

      setUserShipmentsLoading(false);
      setPrevPage(currPage);

      if (!Array.isArray(response.data)) {
        setUserShipments([response.data]);
        setWasLastList(true);
        setUserShipmentsLoading(false);
        return;
      }
      setUserShipments(
        pendingShipment
          ? [
              { ...pendingShipment, status: "draft" },
              ...userShipments,
              ...response.data,
            ]
          : [...userShipments, ...response.data]
      );

      let totalShipmentPages;
      if (response.total_pages) {
        totalShipmentPages = response.total_pages;
      } else {
        totalShipmentPages = response.pagination.number_of_pages;
      }

      if (currPage === totalShipmentPages) {
        setWasLastList(true);
        setUserShipmentsLoading(false);
        return;
      }
    } catch (error) {
      setUserShipmentsLoading(false);
      if (error.status === 404 || error.status === 400) {
        setUserShipmentsError(error.message);
      } else if (error.message) {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
      }
    }
  };

  // function that returns known dates
  const knownDates = getKnownDates(filterByDates);

  // super-function that query API with custom dates
  const getCustomDates = () => {
    if (startDate === null || endDate === null) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 1500);
      return;
    }
    setFIlterByDates("custom dates");
    setCurrPage(1);
    setPrevPage(0);
    setUserShipments([]);
    setWasLastList(false);
    setUserShipmentsError("");
    setUserShipmentsLoading(false);
    queryUserShipmentsCustomDates();
    closePopUp();
    
    setText(
      `${moment(startDate?._d).format("DD-MMM-YYYY")} to ${moment(
        endDate?._d
      ).format("DD-MMM-YYYY")}`
    );
  };

  useEffect(() => {
    if (!wasLastList && prevPage !== currPage) {
      if (filterByDates === "custom dates") {
        queryUserShipmentsCustomDates();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currPage, wasLastList, prevPage, currentFilter]);

  // useEffect(() => {
  //   if (currentFilter === "draft" && userShipments.length) {
  //     setUserShipments
  //   }
  // }, [currentFilter, userShipments]);

  // main function function that query API with known dates
  const queryUserShipments = async (url, requestOptions) => {
    try {
      if (currentFilter === "all") {
        if (knownDates?.start_date && knownDates?.end_date) {
          url = `shipment/users/filter?page=${currPage}&limit=10`;
          requestOptions = {
            method: "POST",
            body: JSON.stringify({
              start_date: knownDates?.start_date,
              end_date: knownDates?.ended,
            }),
          };
        } else {
          url = "shipment/users";
        }
      } else if (currentFilter === "draft") {
        url = `shipment/users/pending`;
        requestOptions = {
          method: "GET",
        };
      } else if (currentFilter === "active") {
        url = `shipment/users/filter?started=${true}&ended=${false}&page=${currPage}&limit=10`;
        requestOptions = {
          method: "POST",
          body: JSON.stringify({
            start_date: knownDates?.start_date,
            end_date: knownDates?.end_date,
          }),
        };
      } else if (currentFilter === "completed") {
        url = `shipment/users/filter?started=${true}&ended=${true}&page=${currPage}&limit=10`;
        requestOptions = {
          method: "POST",
          body: JSON.stringify({
            start_date: knownDates?.start_date,
            end_date: knownDates?.end_date,
          }),
        };
      } else if (currentFilter === "cancelled") {
        url = `shipment/users/filter?cancelled=true&page=${currPage}&limit=10`;
        requestOptions = {
          method: "POST",
          body: JSON.stringify({
            start_date: knownDates?.start_date,
            end_date: knownDates?.end_date,
          }),
        };
      } else if (currentFilter === "pending") {
        url = `shipment/users/filter?started=${false}&ended=${false}&page=${currPage}&limit=10`;
        requestOptions = {
          method: "POST",
          body: JSON.stringify({
            start_date: knownDates?.start_date,
            end_date: knownDates?.end_date,
          }),
        };
      }
      setUserShipmentsLoading(true);

      let pendingShipment;

      const response = await authService.apiGate(
        `${currentENVData.transitURL}/${url}`,
        requestOptions
      );

      if (currentFilter === "draft") {
        response.data = { ...response.data, status: "draft" };
      }

      setUserShipmentsLoading(false);

      setPrevPage(currPage);

      if (!Array.isArray(response.data)) {
        setUserShipments([response.data]);
        setWasLastList(true);
        setUserShipmentsLoading(false);
        return;
      }
      setUserShipments(
        pendingShipment
          ? [
              { ...pendingShipment, status: "draft" },
              ...userShipments,
              ...response.data,
            ]
          : [...userShipments, ...response.data]
      );
      setUserShipmentsError("");
      let totalShipmentPages;
      if (response.total_pages) {
        totalShipmentPages = response.total_pages;
      } else {
        totalShipmentPages = response.pagination.number_of_pages;
      }

      if (currPage === totalShipmentPages) {
        setWasLastList(true);
        setUserShipmentsLoading(false);
        return;
      }
    } catch (error) {
      setUserShipmentsLoading(false);

      if (error.status === 404 || error.status === 400) {
        setUserShipmentsError(error.message);
      } else if (error.message) {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
      }
    }
  };

  useEffect(() => {
    if (!wasLastList && prevPage !== currPage) {
      if (filterByDates !== "custom dates") {
        queryUserShipments();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currPage, wasLastList, prevPage, userShipments, filterByDates]);

  // get observer to implement infinite scroll
  const lastBookRef = useCallback(
    (node) => {
      if (userShipmentsLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !wasLastList) {
          setCurrPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [wasLastList, userShipmentsLoading]
  );

  /* 
    tab clicks for API calls
  */
  const fetchUserShipments = () => {
    setCurrentfilter("all");
    setStatesToDefault();
  };

  const fetchPendingShipments = () => {
    setCurrentfilter("pending");
    setStatesToDefault();
  };

  const fetchActiveShipments = () => {
    setCurrentfilter("active");
    setStatesToDefault();
  };

  const fetchCompletedShipments = () => {
    setCurrentfilter("completed");
    setStatesToDefault();
  };

  const fetchCancelledShipments = () => {
    setCurrentfilter("cancelled");
    setStatesToDefault();
  };

  const fetchDraftShipments = () => {
    setCurrentfilter("draft");
    setStatesToDefault();
  };

  const handleKnownDateClick = () => {
    setStatesToDefault();
    setStartDate(null);
    setEndDate(null);

    setTimeout(() => {
      setShowFilterTable(false);
    }, 500);
  };

  const handleCustomDateClick = () => {
    // setFIlterByDates("custom dates");
    setOpenCalender(true);

    setTimeout(() => {
      setShowFilterTable(false);
    }, 500);
  };

  const [showLoading, setShowLoading] = useState(false);

  // cancel shipment
  const cancelShipment = async (shipmentToCancel) => {
    setShowLoading(true);

    try {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({}),
      };

      const res = await authService.apiGate(
        `${currentENVData.transitURL}/shipment/users/cancel/${shipmentToCancel?._id}`,
        requestOptions
      );

      setShowLoading(false);
      setUserShipments((prevState) =>
        prevState.map((shipment) => {
          if (shipment?._id === shipmentToCancel?._id) {
            return res.data;
          } else {
            return shipment;
          }
        })
      );
      toast.custom((t) => (
        <CustomToast t={t} message={`${res.message}!`} type="" />
      ));
    } catch (error) {
      setShowLoading(false);
      toast.custom((t) => (
        <CustomToast t={t} message={`${error.message}!`} type="error" />
      ));
    }
  };

  // delete shipment
  const deleteShipment = async (shipmentToDelete) => {
    setShowLoading(true);

    try {
      const requestOptions = {
        method: "DELETE",
      };

      const res = await authService.apiGate(
        `${currentENVData.transitURL}/shipment/users/${shipmentToDelete?._id}`,
        requestOptions
      );

      setShowLoading(false);
      setUserShipments(
        userShipments.filter(
          (shipment) => shipment?._id !== shipmentToDelete?._id
        )
      );
      toast.custom((t) => (
        <CustomToast t={t} message={`${res.message}!`} type="" />
      ));
    } catch (error) {
      setShowLoading(false);
      toast.custom((t) => (
        <CustomToast t={t} message={`${error.message}!`} type="error" />
      ));
    }
  };

  return (
    <>
      <GoBackInfo text="Shipments" navigateTo="dashboard/home" />
      <RandomAddLogo />
      {showLoading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black/50 z-[1000]">
          <div className="relative w-24 h-24">
            <Loading size={"[70px]"} />
          </div>
        </div>
      )}
      {openCalender && (
        <ModalContainer closeModal={closePopUp}>
          <PopUpCalender onRequestClose={closePopUp} showError={showError}>
            <DateRangePicker
              startDate={startDate}
              startDateId="tata-start-date"
              endDate={endDate}
              endDateId="tata-end-date"
              onDatesChange={handleDatesChange}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
              isOutsideRange={() => false}
              startDatePlaceholderText="Enter Start Date"
              endDatePlaceholderText="Enter End Date"
              renderMonthElement={renderMonthElement}
              orientation="horizontal"
              numberOfMonths={screenSize < 918 ? 1 : 2}
            />

            <button
              className=" text-white text-sm bg-mvx-blue rounded-md border-2 border-mvx-blue tracking-wider font-medium flex items-center justify-center px-5 gap-3 justify-self-center h-12"
              onClick={getCustomDates}
            >
              <span>Filter</span>
              <span className="material-icons !text-base">arrow_forward</span>
            </button>
          </PopUpCalender>
        </ModalContainer>
      )}
      <div className="pb-0 sm:pb-6 -ml-4 -mr-14 sm:ml-0 sm:mt-3 md:mt-0 sm:-mr-10 md:-mr-8 lg:mr-0 overflow-y-scroll shipmentsContainer mb-6">
        <p
          className="hidden sm:flex items-center text-xs cursor-pointer w-max"
          onClick={() => navigate(-1)}
        >
          <span className="material-icons mr-2 !text-base">
            keyboard_backspace
          </span>
          Close
        </p>
        <div className="hidden sm:flex items-center gap-3 justify-between mb-6">
          <p className="text-xl font-bold">Shipments</p>
          <ActionButtons />
        </div>
        {/* margin to push down on mobile */}
        <div className="mt-16 sm:mt-0"></div>
        {/* new action tabs for mobile */}
        <div className="flex sm:hidden justify-between items-center mb-5">
          <p className="font-bold text-[#333]">Shipment History</p>
          <div className="relative">
            <div
              className="flex items-center gap-2 px-5 py-2 rounded-md border border-gray-500 text-[#686868] text-[13px] font-medium cursor-pointer"
              onClick={toggleFilterTable}
            >
              <span>{text}</span>

              <span
                className={` material-icons transition duration-200 ${
                  !showFilterTable ? " rotate-0" : "rotate-180"
                }`}
              >
                expand_more
              </span>
            </div>
            {showFilterTable && (
              <div
                className={`shadow-[3px_4px_15px_0_#0000001A] bg-white rounded-md py-1.5 absolute text-[12px] text-[#686868] font-medium w-[145px] z-10 top-12 ${
                  text === "Filter" && "!w-[132px] -left-6"
                }`}
              >
                <p
                  className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1]"
                  onClick={(e) => {
                    handleKnownDateClick();
                    setFIlterByDates("by days");
                    setText(e.target.textContent);
                  }}
                >
                  Last 7 days
                </p>
                <p
                  className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1]"
                  onClick={(e) => {
                    handleKnownDateClick();
                    setFIlterByDates("by month");
                    setText(e.target.textContent);
                  }}
                >
                  1 Month ago
                </p>
                <p
                  className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1]"
                  onClick={(e) => {
                    handleKnownDateClick();
                    setFIlterByDates("by year");
                    setText(e.target.textContent);
                  }}
                >
                  1 Year Ago
                </p>
                <p
                  className="py-2 px-3 cursor-pointer hover:bg-[#f1f1f1]"
                  onClick={(e) => {
                    handleCustomDateClick();
                    setText(e.target.textContent);
                  }}
                >
                  Custom Dates
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex sm:hidden text-[13px] items-center select-none mb-4 text-[#0e2b79] font-bold overflow-x-scroll mobileShipmentTabs">
          <span
            className={`rounded-full cursor-pointer px-5 py-2 capitalize ${
              currentFilter === "all" && "bg-mvx-blue text-white"
            }`}
            onClick={fetchUserShipments}
          >
            all
          </span>
          <span
            className={`rounded-full cursor-pointer px-5 py-2  capitalize ${
              currentFilter === "pending" && "bg-mvx-blue text-white"
            }`}
            onClick={fetchPendingShipments}
          >
            pending
          </span>

          <span
            className={`rounded-full cursor-pointer px-5 py-2 capitalize ${
              currentFilter === "active" && "bg-mvx-blue text-white"
            }`}
            onClick={fetchActiveShipments}
          >
            active
          </span>
          <span
            className={`rounded-full cursor-pointer px-5 py-2 capitalize ${
              currentFilter === "completed" && "bg-mvx-blue text-white"
            }`}
            onClick={fetchCompletedShipments}
          >
            completed
          </span>
          <span
            className={`rounded-full cursor-pointer px-5 py-2 capitalize ${
              currentFilter === "cancelled" && "bg-mvx-blue text-white"
            }`}
            onClick={fetchCancelledShipments}
          >
            cancelled
          </span>
          <span
            className={`rounded-full cursor-pointer px-5 py-2 capitalize ${
              currentFilter === "draft" && "bg-mvx-blue text-white"
            }`}
            onClick={fetchDraftShipments}
          >
            draft
          </span>
        </div>
        {/* mobile action tab ends with the above closing div  */}
        <div className="relative border border-gray rounded-xl p-6 px-5 sm:px-8 bg-white">
          <p className="hidden sm:block font-bold mb-4">Shipments History</p>
          <div className="overflow-scroll shipmentsHistoryContainer">
            <div className="hidden sm:flex justify-between border-b border-gray pb-4 mb-4 min-w-[800px] md:min-w-[730px] ">
              <div className="flex text-xs items-center select-none">
                <span
                  className={`rounded-full cursor-pointer px-4 py-1  capitalize ${
                    currentFilter === "all" && "bg-mvx-blue text-white"
                  }`}
                  onClick={fetchUserShipments}
                >
                  all
                </span>
                <span
                  className={`rounded-full cursor-pointer px-4 py-1  capitalize ${
                    currentFilter === "pending" && "bg-mvx-blue text-white"
                  }`}
                  onClick={fetchPendingShipments}
                >
                  pending
                </span>

                <span
                  className={`rounded-full cursor-pointer px-4 py-1 capitalize ${
                    currentFilter === "active" && "bg-mvx-blue text-white"
                  }`}
                  onClick={fetchActiveShipments}
                >
                  active
                </span>
                <span
                  className={`rounded-full cursor-pointer px-4 py-1 capitalize ${
                    currentFilter === "completed" && "bg-mvx-blue text-white"
                  }`}
                  onClick={fetchCompletedShipments}
                >
                  completed
                </span>
                <span
                  className={`rounded-full cursor-pointer px-4 py-1 capitalize ${
                    currentFilter === "cancelled" && "bg-mvx-blue text-white"
                  }`}
                  onClick={fetchCancelledShipments}
                >
                  cancelled
                </span>
                <span
                  className={`rounded-full cursor-pointer px-4 py-1 capitalize ${
                    currentFilter === "draft" && "bg-mvx-blue text-white"
                  }`}
                  onClick={fetchDraftShipments}
                >
                  draft
                </span>
              </div>

              <div className="relative">
                <div
                  className="flex items-center gap-2 px-5 py-2 rounded-md border border-gray-300 text-[#686868] text-[13px] font-medium cursor-pointer"
                  onClick={toggleFilterTable}
                >
                  <span>{text}</span>

                  <span
                    className={` material-icons transition duration-200 ${
                      !showFilterTable ? " rotate-0" : "rotate-180"
                    }`}
                  >
                    expand_more
                  </span>
                </div>
                {showFilterTable && (
                  <div
                    className={`shadow-[3px_4px_15px_0_#0000001A] bg-white rounded-md py-1.5 absolute text-[12px] text-[#686868] font-medium w-full z-[1000] top-14 ${
                      text === "Filter" && "!w-[135px] -left-7"
                    }`}
                  >
                    <p
                      className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1]"
                      onClick={(e) => {
                        handleKnownDateClick();
                        setFIlterByDates("by days");
                        setText(e.target.textContent);
                      }}
                    >
                      Last 7 days
                    </p>
                    <p
                      className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1]"
                      onClick={(e) => {
                        handleKnownDateClick();
                        setFIlterByDates("by month");
                        setText(e.target.textContent);
                      }}
                    >
                      1 Month ago
                    </p>
                    <p
                      className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1]"
                      onClick={(e) => {
                        handleKnownDateClick();
                        setFIlterByDates("by year");
                        setText(e.target.textContent);
                      }}
                    >
                      1 Year Ago
                    </p>
                    <p
                      className="py-2 px-3 cursor-pointer hover:bg-[#f1f1f1]"
                      onClick={(e) => {
                        handleCustomDateClick();
                        setText(e.target.textContent);
                      }}
                    >
                      Custom Dates
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex gap-1 md:gap-0 px-4 sm:px-6 py-3 bg-[#F5F8FF] font-bold rounded-xl text-xs text-[#0E2B79] min-w-[800px] md:min-w-[730px]">
              <div className="shrink-0 min-w-[85px] sm:min-w-[100px] w-[15%]">
                Date
              </div>
              <div className="shrink-0 min-w-[85px] sm:min-w-[100px] w-[15%]">
                Shipment ID
              </div>
              <div className="pl-2 shrink-0 min-w-[170px] sm:min-w-[200px] w-[30%]">
                Trip Route
              </div>
              <div className="pl-2 shrink-0 min-w-[85px] sm:min-w-[100px] w-1/5">
                Status
              </div>
              <div className="shrink-0 min-w-[65px] sm:min-w-[80px] w-[10%] text-center">
                Movements
              </div>
              <div className="shrink-0 min-w-[65px] sm:min-w-[80px] w-[10%] text-center">
                Action
              </div>
            </div>

            {userShipmentsError !== "" && !userShipments.length ? (
              <ShipmentsError />
            ) : (
              userShipments.map((shipment, index) => {
                if (userShipments.length === index + 1) {
                  return (
                    <div ref={lastBookRef} key={`${shipment.mvxid}+${index}`}>
                      <Shipment
                        shipment={shipment}
                        cancelShipment={cancelShipment}
                        deleteShipment={deleteShipment}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={`${shipment.mvxid}+${index}`}>
                      <Shipment
                        shipment={shipment}
                        cancelShipment={cancelShipment}
                        deleteShipment={deleteShipment}
                      />
                    </div>
                  );
                }
              })
            )}

            {userShipmentsLoading && userShipments.length === 0 ? (
              <>
                <ShipmentLoader />
                <ShipmentLoader />
                <ShipmentLoader />
                <ShipmentLoader />
                <ShipmentLoader />
                <ShipmentLoader />
                <ShipmentLoader />
              </>
            ) : (
              userShipmentsLoading &&
              userShipments.length > 0 && <ShipmentLoader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Shipments;

export const ShipmentLoader = ({ ellipsis = true }) => (
  <div className="flex gap-1 md:gap-0 min-w-[730px] h-24 border-b border-gray items-center w-full px-4 sm:px-6">
    <div className="shrink-0 min-w-[85px] sm:min-w-[100px] w-[15%] flex justify-start">
      <Skeleton className="shrink-0 w-10/12 h-5 rounded-lg"></Skeleton>
    </div>
    <div className="shrink-0 min-w-[85px] sm:min-w-[100px] w-[15%] flex justify-start">
      <Skeleton className="shrink-0 w-10/12 h-5 rounded-lg"></Skeleton>
    </div>
    <div className="shrink-0 min-w-[170px] sm:min-w-[200px] w-[30%] flex justify-start">
      <Skeleton className="shrink-0 w-11/12 h-5 rounded-lg"></Skeleton>
    </div>
    <div className="shrink-0 min-w-[85px] sm:min-w-[100px] w-1/5 flex justify-start">
      <Skeleton className="shrink-0 w-10/12 h-5 rounded-lg"></Skeleton>
    </div>
    <div className="shrink-0 min-w-[65px] sm:min-w-[80px] w-[10%] flex justify-center">
      <Skeleton className="shrink-0 w-5 h-5 rounded-lg"></Skeleton>
    </div>
    {ellipsis && (
      <div className="shrink-0 min-w-[65px] sm:min-w-[80px] w-[10%] flex justify-center">
        <span className="material-icons !text-gray-300 !font-bold">
          more_vert
        </span>
      </div>
    )}
  </div>
);
