import { capitalizeFirstLetter } from "./capitalizeFirstLetter";

export const checkNumberValidity = (phoneNumber) => {
  const regex = /^\d{10,11}$/;

  if (regex.test(phoneNumber)) {
    return true;
  } else {
    return false;
  }
};

export const checkValidButton = (paramOne, paramTwo) => {
  if (paramOne === true && paramTwo === true) {
    return true;
  } else {
    return false;
  }
};

export const checkEmaiiValidity = (email) => {
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (email.length < 1) {
    return true;
  } else if (email.length > 1 && regex.test(email)) {
    return true;
  } else if (email.length > 1 && !regex.test(email)) {
    return false;
  }
};

export const getPdfName = (text) => {
  const to_array = text.split(" ");
  const first_text = capitalizeFirstLetter(to_array[0]);
  const last_text = capitalizeFirstLetter(to_array[to_array.length - 1]);
  const formated_name = `${first_text}...${last_text}.pdf`;
  return formated_name;
};
