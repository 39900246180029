import { authActions } from "actions/authActions";
import { Logo } from "assets/arts";
import { authConstants } from "constants";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VerifyEmail from "./VerifyEmail";

const ForgotPassword = () => {
  const { register, handleSubmit, watch, getValues } = useForm({
    defaultValues: {
      password: "",
      "new-password": "",
      email: "",
    },
  });

  const [step, setStep] = useState(0);
  const [OTP, setOTP] = useState("");
  const [focusedInput, setFocusedInput] = useState();
  const { forgetPasswordSuccess, resetPasswordLoading, forgetPasswordLoading } =
    useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const resetInputsWatch = watch(["password", "new-password"]);

  const navigate = useNavigate();
  const home = () => navigate("/");
  const formEmail = getValues("email");
  const onFocus = (val) => {
    setFocusedInput(val);
  };

  const forgetPassword = (data) => {
    dispatch(authActions.forgotPassword({ email: data.email }));
  };

  const resendOTP = () => {
    formEmail &&
      dispatch(authActions.forgotPassword({ email: formEmail }, true));
  };

  const resetPassword = (credentials) => {
    dispatch(
      authActions.resetPassword({
        code: OTP,
        password: credentials.password,
      })
    );
  };

  const verifyCode = () => {
    OTP.length === 6 && setStep(2);
  };

  const goBackToEmail = () => {
    setStep(0);
    onFocus("email");
  };

  useEffect(() => {
    if (forgetPasswordSuccess) {
      setStep(1);
    }

    return () => {
      dispatch({ type: authConstants.FORGET_PASSWORD_SUCCESS, payload: "" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgetPasswordSuccess]);

  return (
    <div className="w-full min-h-screen flex bg-[#F5F5F5]">
      <div className="absolute left-0 top-10 cursor-pointer">
        <Logo className="h-[25px] w-auto ml-10 lg:ml-20" onClick={home} />
      </div>
      <div className="h-screen w-full flex items-center justify-center">
        <div className=" w-[87%] 700:w-3/5 lg:w-2/5 py-12 px-6 sm:p-12 rounded-2xl bg-white text-center shadow-sm"> . 
          {step !== 1 && (
            <p className="font-bold mb-3 text-xl">Reset Password</p>
          )}
          {step === 0 ? (
            <>
              <p className="mb-12 text-xs">
                Enter your email address so that we can verify that you have an
                MVX account
              </p>
              <form onSubmit={handleSubmit(forgetPassword)}>
                <p className="text-xs font-bold mb-2 text-left">
                  Email Address
                </p>
                <input
                  type="email"
                  className="border bg-[#F5F5F5] border-gray-200 mb-6 rounded p-3 outline-0 w-full h-[50px] text-sm"
                  placeholder="Your personal email address"
                  onFocus={() => onFocus("email")}
                  readOnly={focusedInput !== "email"}
                  required
                  {...register("email", { required: true })}
                />
                <button
                  type="submit"
                  disabled={false}
                  className={`disabled:opacity-70 bg-mvx-blue shadow-lg outline-0 rounded w-full h-[50px] text-white text-md mb-6 font-bold mb-14 ${
                    forgetPasswordLoading &&
                    "bg-mvx-light-blue pointer-events-none"
                  }`}
                >
                  {forgetPasswordLoading ? "Loading..." : "Continue"}
                </button>
              </form>
            </>
          ) : step === 1 ? (
            <VerifyEmail
              OTP={OTP}
              setOTP={setOTP}
              goBackFunc={goBackToEmail}
              resendOTP={resendOTP}
              VerifyEmail={verifyCode}
              type="reset-password"
              email={formEmail}
            />
          ) : (
            <>
              <p className="mb-12 text-xs">
                Enter your new password here and you’re all set up
              </p>
              <form onSubmit={handleSubmit(resetPassword)}>
                <p className="text-xs font-bold mb-2 text-left">
                  Enter new Password
                </p>
                <input
                  type="password"
                  className="border bg-[#F5F5F5] border-gray-200 mb-4 rounded p-3 outline-0 w-full h-[50px] text-sm"
                  placeholder="Enter password"
                  onFocus={() => onFocus("password")}
                  readOnly={focusedInput !== "password"}
                  required
                  {...register("password", { required: true })}
                />
                <p className="text-xs font-bold mb-2 text-left">
                  Re-enter new Password
                </p>
                <input
                  type="password"
                  className="border bg-[#F5F5F5] border-gray-200 mb-6 rounded p-3 outline-0 w-full h-[50px] text-sm"
                  placeholder="Enter password"
                  onFocus={() => onFocus("new-password")}
                  readOnly={focusedInput !== "new-password"}
                  required
                  {...register("new-password", { required: true })}
                />
                <span
                  className={`text-red-600 text-[12px] font-normal text-left translate-y-[-20px] ${
                    resetInputsWatch[0] !== resetInputsWatch[1]
                      ? "block"
                      : "hidden"
                  }`}
                >
                  Passwords do not match!
                </span>
                <button
                  type="submit"
                  className={`bg-mvx-blue shadow-lg outline-0 rounded w-full h-[50px] text-white text-md mb-6 font-bold mb-14 ${
                    resetInputsWatch[0] !== resetInputsWatch[1] &&
                    "pointer-events-none"
                  } ${
                    resetPasswordLoading &&
                    "bg-mvx-light-blue pointer-events-none"
                  }`}
                >
                  {resetPasswordLoading ? "Resetting..." : "Reset"}
                </button>

                <p
                  className="w-max mx-auto text-[#474747] text-xs font-normal cursor-pointer block mt-4 underline"
                  onClick={() => setStep(1)}
                >
                  Update OTP
                </p>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
