import Header from "components/common/Header";
import VideoShowcase from "components/common/VideoShowcase";
import gotoAddShipment from "helpers/gotoAddShipment";
import gotoCreateFinanceRequest from "helpers/gotoCreateFinanceRequest";
import React from "react";
import styled from "styled-components";

const Hero = ({ serviceRef }) => {
  const scrollDown = () =>
    serviceRef.current.scrollIntoView({ behavior: "smooth" });

  return (
    <div className="sm:p-4">
      <Header />
      <div className="px-4 sm:p-0">
        <HeroContainer
          url={require("assets/images/subtract.png")}
          className="rounded-lg py-0 sm:py-4"
        >
          <div className="w-[90%] sm:w-4/5 m-auto">
            <div className="font-catalina pt-20 sm:pt-44 text-[2.1rem] sm:text-7xl leading-tight sm:leading-[1.25]">
              <p className="whitespace-nowrap">
                Access <span className="text-mvx-blue2">Fast Finance</span>
              </p>
              <p className="whitespace-nowrap">
                and <span className="text-mvx-orange">Easy Shipping </span>
              </p>
              <p className="whitespace-nowrap">Around The World</p>
            </div>
            <p className="w-full sm:w-3/5 py-6 text-[0.9rem] sm:text-sm font-light">
              MVX offers you freight financing, freight tracking, door to door
              shipping and more, all at the tip of your fingers. We eliminate
              the headache of global shipping for you.
            </p>
            <div className="pt-6 pb-10 sm:pb-20 relative flex">
              <button
                type="button"
                onClick={gotoAddShipment}
                className="bg-mvx-orange text-white outline-0 rounded-xl h-[50px] px-6 sm:px-10 whitespace-nowrap text-sm mr-0 sm:mr-4 font-bold"
              >
                Move Cargo
              </button>
              <button
                type="button"
                onClick={gotoCreateFinanceRequest}
                className="bg-transparent text-mvx-blue2 outline-0 rounded-xl h-[50px] px-6 sm:px-10 whitespace-nowrap text-sm font-bold flex items-center"
              >
                Get Financed
                <span className="material-icons white-color">
                  keyboard_arrow_right
                </span>
              </button>
              <div
                onClick={scrollDown}
                className="w-auto h-auto hidden sm:flex flex-col items-center cursor-pointer absolute right-12 bottom-20 text-center"
              >
                <img
                  src={require("assets/images/arrow.png")}
                  className="w-[12px] mb-2"
                  alt="Scroll down"
                />
                <p className="font-light text-xs">Scroll down</p>
              </div>
            </div>
          </div>
          <VideoShowcase />
          <div className="w-[90%] sm:w-4/5 m-auto">
            <p className="pt-16 font-light mb-4">Trusted by global companies</p>
            <div className="flex flex-wrap justify-between pb-10 sm:pb-20">
              <div className="sm:mr-6 w-1/2 sm:w-auto shrink-0 mb-4 sm:mb-0">
                <p className="font-bold text-2xl text-mvx-blue">600+</p>
                <p className="text-sm">Happy Customers</p>
              </div>
              <div className="sm:mr-6 w-1/2 sm:w-auto shrink-0 mb-4 sm:mb-0">
                <p className="font-bold text-2xl text-mvx-blue">$20million+</p>
                <p className="text-sm">Value of Goods Moved</p>
              </div>
              <div className="sm:mr-6 w-1/2 sm:w-auto shrink-0 mb-4 sm:mb-0">
                <p className="font-bold text-2xl text-mvx-blue">9,000+</p>
                <p className="text-sm">Freight Partners</p>
              </div>
              <div className="sm:mr-6 w-1/2 sm:w-auto shrink-0 mb-4 sm:mb-0">
                <p className="font-bold text-2xl text-mvx-blue">$1.2million</p>
                <p className="text-sm">Value of shipments financed</p>
              </div>
            </div>
          </div>
        </HeroContainer>
      </div>
    </div>
  );
};

const HeroContainer = styled.div`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-color: rgba(255, 138, 0, 0.07);
  background-attachment: fixed;
  background-size: auto 100vh;
  animation-duration: 1.2s;
  animation-name: oscillate;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
`;

export default Hero;
