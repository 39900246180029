import CustomToast from "components/common/CustomToast";

import toast from "react-hot-toast";
import { authService } from "services";
import { authConstants } from "constants";

export const authActions = {
  forgotPassword,
  resetPassword,
  sendVerifyMail,
  verifyUser,
  updateUserProfile,
  updateUserProfileImage,
  updateUserPassword,
  fetchSubUserData,
  addNewSubUserData,
  editSubUserData,
  deleteSubUserData,
  deactivateAccount,
  resetLoginLoading,
  resetRegisterLoading,
  resetVerifySuccess,
  loginSuccess,
};

function resetLoginLoading() {
  return failure(authConstants.LOGIN_FAILURE, "manual set");
}

function resetRegisterLoading() {
  return failure(authConstants.REGISTER_FAILURE, "manual set");
}

function resetVerifySuccess() {
  return success(authConstants.VERIFY_SUCCESS, false);
}

function forgotPassword(email, sendSuccess = false) {
  return (dispatch) => {
    dispatch(request(authConstants.FORGET_PASSWORD_REQUEST));

    authService.forgotPassword(email).then(
      (res) => {
        dispatch(success(authConstants.FORGET_PASSWORD_SUCCESS, res.message));
        sendSuccess &&
          toast.custom((t) => <CustomToast t={t} message={res.message} />);
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(failure(authConstants.FORGET_PASSWORD_ERROR, error.message));
        }
      }
    );
  };
}

function resetPassword(credentials) {
  return (dispatch) => {
    dispatch(request(authConstants.RESET_REQUEST));

    authService.resetPassword(credentials).then(
      (res) => {
        dispatch(success(authConstants.RESET_SUCCESS, res.message));
        toast.custom((t) => <CustomToast t={t} message={res.message} />);
        window.location.href = "/enter";
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(failure(authConstants.RESET_ERROR, error.message));
        }
      }
    );
  };
}

function loginSuccess(data) {
  return success(authConstants.LOGIN_SUCCESS, data);
}

function sendVerifyMail(emailObj) {
  return (dispatch) => {
    dispatch(request(authConstants.SEND_VERIFY_REQUEST));

    authService.sendVerifyMail(emailObj).then(
      (res) => {
        dispatch(success(authConstants.SEND_VERIFY_SUCCESS, res.message));
        toast.custom((t) => <CustomToast t={t} message={res.message} />);
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(failure(authConstants.SEND_VERIFY_ERROR, error.message));
        }
      }
    );
  };
}

function verifyUser(otpObj, showSuccessMessage = true) {
  return (dispatch) => {
    dispatch(request(authConstants.VERIFY_REQUEST));

    authService.verifyUser(otpObj).then(
      (res) => {
        dispatch(success(authConstants.VERIFY_SUCCESS, res.message));
        showSuccessMessage &&
          toast.custom((t) => <CustomToast t={t} message={res.message} />);
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(failure(authConstants.VERIFY_ERROR, error.message));
        }
      }
    );
  };
}

function updateUserProfile(data) {
  return (dispatch) => {
    dispatch(request(authConstants.UPDATE_USER_REQUEST));

    authService.updateUserProfile(data).then(
      (res) => {
        dispatch(success(authConstants.UPDATE_USER_SUCCESS, res));
        toast.custom((t) => <CustomToast t={t} message={res.message} />);
      },
      (error) => {
        // if (error.status !== 404 && error.message) {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
        dispatch(failure(authConstants.UPDATE_USER_ERROR, error.message));
        // }
        // else {
        //   dispatch(failure(authConstants.UPDATE_USER_ERROR, error.message));
        // }
      }
    );
  };
}

function updateUserProfileImage(data) {
  return (dispatch) => {
    dispatch(request(authConstants.UPDATE_USERIMAGE_REQUEST));

    authService.updateUserProfileImage(data).then(
      (res) => {
        dispatch(success(authConstants.UPDATE_USERIMAGE_SUCCESS, res));
        toast.custom((t) => <CustomToast t={t} message={res.message} />);
      },
      (error) => {
        // if (error.status !== 404 && error.message) {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
        dispatch(failure(authConstants.UPDATE_USER_ERROR, error.message));
        // }
        // else {
        //   dispatch(failure(authConstants.UPDATE_USER_ERROR, error.message));
        // }
      }
    );
  };
}

function updateUserPassword(data) {
  return (dispatch) => {
    dispatch(request(authConstants.UPDATE_USER_PASSWORD_REQUEST));

    authService.updateUserPassword(data).then(
      (res) => {
        dispatch(
          success(authConstants.UPDATE_USER_PASSWORD_SUCCESS, res.message)
        );
        // toast.custom((t) => <CustomToast t={t} message={res.message} />);
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(
            failure(authConstants.UPDATE_USER_PASSWORD_ERROR, error.message)
          );
        }
      }
    );
  };
}

function fetchSubUserData() {
  return (dispatch) => {
    dispatch(request(authConstants.FETCH_SUBUSER_REQUEST));

    authService.fetchSubUserData().then(
      (res) => {
        dispatch(success(authConstants.FETCH_SUBUSER_SUCCESS, res));
      },
      (error) => {
        if (error.status !== 404 && error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
        } else {
          dispatch(failure(authConstants.FETCH_SUBUSER_ERROR, error.message));
        }
      }
    );
  };
}

function addNewSubUserData(data) {
  return (dispatch) => {
    dispatch(request(authConstants.ADD_NEW_SUBUSER_REQUEST));

    authService.addNewSubUserData(data).then(
      (res) => {
        dispatch(success(authConstants.ADD_NEW_SUBUSER_SUCCESS, res));
      },
      (error) => {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
        dispatch(failure(authConstants.ADD_NEW_SUBUSER_ERROR, error.message));
      }
    );
  };
}

function editSubUserData(data, subuserId) {
  return (dispatch) => {
    dispatch(request(authConstants.EDIT_SUBUSER_DATAILS_REQUEST));

    authService.editSubUserData(data, subuserId).then(
      (res) => {
        dispatch(success(authConstants.EDIT_SUBUSER_DATAILS_SUCCESS, res));
      },
      (error) => {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
        dispatch(
          failure(authConstants.EDIT_SUBUSER_DATAILS_ERROR, error.message)
        );
      }
    );
  };
}

function deleteSubUserData(subuserId) {
  return (dispatch) => {
    dispatch(request(authConstants.DELETE_SUBUSER_REQUEST));

    authService.deleteSubUserData(subuserId).then(
      (res) => {
        dispatch(success(authConstants.DELETE_SUBUSER_SUCCESS, res));
      },
      (error) => {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
        dispatch(failure(authConstants.DELETE_SUBUSER_ERROR, error.message));
      }
    );
  };
}

function deactivateAccount() {
  return (dispatch) => {
    dispatch(request(authConstants.DEACTIVATE_MVX_ACCOUNT));
    
    authService.deactivateAccount().then(
      (res) => {
        dispatch(success(authConstants.DEACTIVATE_MVX_ACCOUNT_SUCCESS, res));
      },
      (error) => {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
        dispatch(
          failure(authConstants.DEACTIVATE_MVX_ACCOUNT_ERROR, error.message)
        );
      }
    );
  };
}

function request(type) {
  return { type: type };
}
function success(type, data) {
  return { type: type, payload: data };
}
function failure(type, error) {
  return { type: type, payload: error };
}
