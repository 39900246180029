import { onValue, ref } from "firebase/database";
import { currentENVData } from "helpers";
import { authService } from "./authService";
import { firebaseService } from "./firebaseService";

export const shipmentsService = {
  getShipmentMovements,
  FetchUntrackedMovementsLocations,
  FetchAutomaticMovementsLocations,
  createShipment,
  fetchActiveShipments,
  saveShipmentRequest,
  fetchSavedShipmentRequest,
  fetchSingleShipment,
  acceptOrRejectQuote,
  fetchShipmentQuotes,
  fetchShipmentForFinances,
  addPartner,
};

/*
Todos
- update the below endpoint
{
	"started":true,
    "ended":false,
    "start_date": "03-06-2022",
    "end_date": "03-06-2022",
    "mvxid": "00029"
}
*/

async function fetchActiveShipments(filterObj = null) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(filterObj ?? {}),
  };
  const res = await authService.apiGate(
    `${currentENVData.transitURL}/shipment/users/filter?limit=100&ended=false`,
    requestOptions
  );
  return res;
}

async function getShipmentMovements(shipmentID) {
  const requestOptions = {
    method: "GET",
  };

  const res = await authService.apiGate(
    `${currentENVData.transitURL}/shipment/movement/user/tracking?shipment_id=${shipmentID}`,
    requestOptions
  );

  return res;
}

async function createShipment(shipment) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(shipment),
  };

  const res = await authService.apiGate(
    `${currentENVData.transitURL}/shipment/users`,
    requestOptions
  );
  return res;
}

async function fetchSavedShipmentRequest() {
  const requestOptions = {
    method: "GET",
  };

  const res = await authService.apiGate(
    `${currentENVData.transitURL}/shipment/users/pending`,
    requestOptions
  );

  return res;
}

async function saveShipmentRequest(shipment) {
  const data = shipment?._id
    ? { ...shipment, pending_shipment_id: shipment._id }
    : shipment;

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
  };

  const res = await authService.apiGate(
    `${currentENVData.transitURL}/shipment/users/v2/pending/check`,
    requestOptions
  );
  return res;
}

async function fetchSingleShipment(shipmentID) {
  const requestOptions = {
    method: "GET",
  };

  const res = await authService.apiGate(
    `${currentENVData.transitURL}/shipment/users/view/${shipmentID}`,
    requestOptions
  );

  return res;
}

async function acceptOrRejectQuote(quoteId, approve, reasonObj) {
  const reqObj = {
    quoteId,
    acceptanceStatus: approve ? "accepted" : "rejected",
  };
  const formatReqObj = () =>
    !!reasonObj ? { ...reqObj, ...reasonObj } : reqObj;

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(formatReqObj()),
  };

  const res = await authService.apiGate(
    `${currentENVData.transitURL}/shipment/users/quote/approval`,
    requestOptions
  );

  return res;
}

async function fetchShipmentQuotes(shipmentID) {
  const requestOptions = {
    method: "GET",
  };

  const res = await authService.apiGate(
    `${currentENVData.transitURL}/shipment/users/quotes?shipment_id=${shipmentID}`,
    requestOptions
  );

  return res;
}

async function fetchShipmentForFinances() {
  const requestOptions = {
    method: "GET",
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/trade/users/non_financed_shipments`,
    requestOptions
  );

  return res;
}

/*
-- you are to call them all and merge the movement info together
UNTRACK UPDATE
Collection name/ movement id / location datas

AUTOMATIC LOCATION UPDATE
Collection name / 
If land movement - device id / movement id/
ocean movement - ocean tracking id / movements/ locations
air movement - no air movement /
*/

function FetchUntrackedMovementsLocations(
  movementId,
  saveResult,
  unSubScribeFunc = false
) {
  const starCountRef = ref(
    firebaseService.db,
    "untracked_location_updates/" + movementId + "/locations"
  );
  const unSubScribe = onValue(starCountRef, (snapshot) => {
    const value = snapshot.val();
    saveResult(value);
  });
  unSubScribeFunc && unSubScribeFunc(unSubScribe);
}

function FetchAutomaticMovementsLocations(
  reqObj,
  saveResult,
  unSubScribeFunc = false
) {
  const starCountRef = ref(
    firebaseService.db,
    "location_updates/" + reqObj?.deviceId
      ? `${reqObj?.deviceId}/${reqObj?.movementId}`
      : `${reqObj?.oceanTrackingId}/${reqObj?.movementId}`
  );
  const unSubScribe = onValue(starCountRef, (snapshot) => {
    const value = snapshot.val();
    saveResult(value);
  });
  unSubScribeFunc && unSubScribeFunc(unSubScribe);
}

function addPartner(partner) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(partner),
  };

  let url =
    partner.category === "Finance"
      ? `${currentENVData.tradeURL}/partners/user/request`
      : `${currentENVData.transitURL}/partners/user/request`;

  return authService.apiGate(url, requestOptions).then((partner) => {
    return partner;
  });
}
