import FAQs from "components/common/FAQ";
import Footer from "components/common/Footer";
import React, { useRef } from "react";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import WhatNext from "./WhatNext";

const Agents = () => {
  const serviceRef = useRef(null);

  return (
    <>
      <Hero serviceRef={serviceRef} />
      <HowItWorks />
      <WhatNext />
      <FAQs />
      <Footer />
    </>
  );
};

export default Agents;
