import CustomToast from "components/common/CustomToast";
import { financeConstant } from "constants";
import toast from "react-hot-toast";
import { financeService } from "services";

export const financeActions = {
  saveMonoID,
  fetchFinanceProfile,
  fetchFinanceRequests,
  createNewFinanceRequest,
  verifyBVN,
  verifyCAC,
  updateFinanceProfile,
  updateFinanceProfileImage,
  uploadCACDOC,
  uploadMOADOC,
  uploadDirectorID,
  uploadAuditDOC,
  uploadNEPCDOC,
};

function saveMonoID(idObj) {
  return (dispatch) => {
    dispatch(request(financeConstant.SAVE_MONO_ID_REQUEST));
    financeService.saveMonoID(idObj).then(
      (res) => {
        toast.custom((t) => (
          <CustomToast t={t} message="Bank account linked successfully!" />
        ));
        dispatch(success(financeConstant.SAVE_MONO_ID_SUCCESS, res.data));
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(
            failure(financeConstant.SAVE_MONO_ID_FAILURE, error.message)
          );
        } 
      }
    );
  };
}

function fetchFinanceProfile() { 
  return (dispatch) => {
    dispatch(request(financeConstant.FINANCE_PROFILE_REQUEST));
    financeService.fetchFinanceProfile().then(
      (res) => {
        const { message, ...rest } = res;
        dispatch(success(financeConstant.FINANCE_PROFILE_SUCCESS, { ...rest }));
      },
      (error) => {
        if (error.message && error.status !== 400) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(
            failure(financeConstant.FINANCE_PROFILE_FAILURE, error.message)
          );
        }
      }
    );
  };
}

function fetchFinanceRequests(filterData) {
  return (dispatch) => {
    dispatch(request(financeConstant.FINANCE_REQUESTS_REQUEST));
    financeService.fetchFinanceRequests(filterData).then(
      (res) => {
        const { message, ...rest } = res;
        dispatch(
          success(financeConstant.FINANCE_REQUESTS_SUCCESS, { ...rest })
        );
      },
      (error) => {
        dispatch(
          failure(financeConstant.FINANCE_REQUESTS_FAILURE, error.message)
        );
      }
    );
  };
}

function createNewFinanceRequest(reqObj) {
  return (dispatch) => {
    dispatch(request(financeConstant.CREATE_FINANCE_REQUEST));
    financeService.createNewFinanceRequest(reqObj).then(
      (res) => {
        const { message, ...rest } = res;
        dispatch(success(financeConstant.CREATE_FINANCE_SUCCESS, { ...rest }));
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(
            failure(financeConstant.CREATE_FINANCE_FAILURE, error.message)
          );
        }
      }
    );
  };
}

function verifyBVN(bvn) {
  return (dispatch) => {
    dispatch(request(financeConstant.VERIFY_BVN_REQUEST));
    financeService.verifyBVN(bvn).then(
      (res) => {
        dispatch(success(financeConstant.VERIFY_BVN_SUCCESS, res.data));
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(failure(financeConstant.VERIFY_BVN_FAILURE, error.message));
        }
      }
    );
  };
}

function verifyCAC(cacObj) {
  return (dispatch) => {
    dispatch(request(financeConstant.VERIFY_CAC_REQUEST));
    financeService.verifyCAC(cacObj).then(
      (res) => {
        dispatch(success(financeConstant.VERIFY_CAC_SUCCESS, res.data));
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(failure(financeConstant.VERIFY_CAC_FAILURE, error.message));
        }
      }
    );
  };
}

function updateFinanceProfile(profile, showSuccessMessage = true) {
  return (dispatch) => {
    dispatch(request(financeConstant.UPDATE_FINANCE_PROFILE_REQUEST));
    financeService.updateFinanceProfile(profile).then(
      (res) => {
        const { message, ...rest } = res;
        showSuccessMessage &&
          toast.custom((t) => (
            <CustomToast t={t} message={res.message + "!"} />
          ));
        dispatch(
          success(financeConstant.UPDATE_FINANCE_PROFILE_SUCCESS, { ...rest })
        );
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(
            failure(
              financeConstant.UPDATE_FINANCE_PROFILE_FAILURE,
              error.message
            )
          );
        }
      }
    );
  };
}

function updateFinanceProfileImage(profileImage) {
  return (dispatch) => {
    dispatch(request(financeConstant.UPDATE_FINANCE_PROFILEIMAGE_REQUEST));
    financeService.updateFinanceProfileImage(profileImage).then(
      (res) => {
        const { message, ...rest } = res;
        toast.custom((t) => <CustomToast t={t} message={res.message + "!"} />);
        dispatch(
          success(financeConstant.UPDATE_FINANCE_PROFILEIMAGE_SUCCESS, {
            ...rest,
          })
        );
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(
            failure(
              financeConstant.UPDATE_FINANCE_PROFILEIMAGE_FAILURE,
              error.message
            )
          );
        }
      }
    );
  };
}

function uploadCACDOC(data) {
  return (dispatch) => {
    dispatch(request(financeConstant.UPLOAD_CACDOC_REQUEST));
    financeService.uploadCACDOC(data).then(
      (res) => {
        const { message, ...rest } = res;
        toast.custom((t) => <CustomToast t={t} message={res.message + "!"} />);
        dispatch(
          success(financeConstant.UPLOAD_CACDOC_SUCCESS, {
            ...rest,
          })
        );
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(failure(financeConstant.UPLOAD_CACDOC_ERROR, error.message));
        }
      }
    );
  };
}
function uploadMOADOC(data) {
  return (dispatch) => {
    dispatch(request(financeConstant.UPLOAD_MOADOC_REQUEST));
    financeService.uploadMOADOC(data).then(
      (res) => {
        const { message, ...rest } = res;
        toast.custom((t) => <CustomToast t={t} message={res.message + "!"} />);
        dispatch(
          success(financeConstant.UPLOAD_MOADOC_SUCCESS, {
            ...rest,
          })
        );
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(failure(financeConstant.UPLOAD_MOADOC_ERROR, error.message));
        }
      }
    );
  };
}
function uploadDirectorID(data) {
  return (dispatch) => {
    dispatch(request(financeConstant.UPLOAD_DIRECTORID_REQUEST));
    financeService.uploadDirectorID(data).then(
      (res) => {
        const { message, ...rest } = res;
        toast.custom((t) => <CustomToast t={t} message={res.message + "!"} />);
        dispatch(
          success(financeConstant.UPLOAD_DIRECTORID_SUCCESS, {
            ...rest,
          })
        );
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(
            failure(financeConstant.UPLOAD_DIRECTORID_ERROR, error.message)
          );
        }
      }
    );
  };
}
function uploadAuditDOC(data) {
  return (dispatch) => {
    dispatch(request(financeConstant.UPLOAD_AUDITDOC_REQUEST));
    financeService.uploadAuditDOC(data).then(
      (res) => {
        const { message, ...rest } = res;
        toast.custom((t) => <CustomToast t={t} message={res.message + "!"} />);
        dispatch(
          success(financeConstant.UPLOAD_AUDITDOC_SUCCESS, {
            ...rest,
          })
        );
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(
            failure(financeConstant.UPLOAD_AUDITDOC_ERROR, error.message)
          );
        }
      }
    );
  };
}
function uploadNEPCDOC(data) {
  return (dispatch) => {
    dispatch(request(financeConstant.UPLOAD_NEPCDOC_REQUEST));
    financeService.uploadNEPCDOC(data).then(
      (res) => {
        const { message, ...rest } = res;
        toast.custom((t) => <CustomToast t={t} message={res.message + "!"} />);
        dispatch(
          success(financeConstant.UPLOAD_NEPCDOC_SUCCESS, {
            ...rest,
          })
        );
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(
            failure(financeConstant.UPLOAD_NEPCDOC_ERROR, error.message)
          );
        }
      }
    );
  };
}

function request(type) {
  return { type: type };
}
function success(type, data) {
  return { type: type, payload: data };
}
function failure(type, error) {
  return { type: type, payload: error ?? "" };
}
