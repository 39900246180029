import { GoBackInfo } from "components/Dashboard/common/MobileNav";
import { useState, useMemo } from "react";
import { checkValidButton } from "helpers/validateUserFormPage";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "actions";
import { useNavigate } from "react-router-dom";
import ModalContainer from "components/common/modals/ModalContainer";
import ResetSuccess from "./ResetSuccess";
import { authConstants } from "constants";

const MobilePasswordUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateUserPasswordLoading, updateUserPasswordSuccess } = useSelector(
    (state) => state.auth
  );

  const [toggleCurrentPassword, setToggleCurrentPassword] = useState(false);
  const [toggleNewPassword, setToggleNewPassword] = useState(false);
  const [toggleResetNewPassword, setToggleResetNewPassword] = useState(false);
  const [inputOneBlurFocus, setInputOneBlurFocus] = useState(false);
  const [inputTwoBlurFocus, setInputTwoBlurFocus] = useState(false);
  const [inputThreeBlurFocus, setInputThreeBlurFocus] = useState(false);

  const [passwordForm, setPasswordForm] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordRetype: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;

    setPasswordForm((passwords) => ({
      ...passwords,
      [name]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (updateUserPasswordLoading) {
      return;
    }

    dispatch(
      authActions.updateUserPassword({
        password: newPassword,
        old_password: oldPassword,
      })
    );
  };

  const { oldPassword, newPassword, newPasswordRetype } = passwordForm;

  const checkFIlledInputs = useMemo(() => {
    if (!oldPassword || !newPassword || !newPasswordRetype) {
      return false;
    } else {
      return true;
    }
  }, [oldPassword, newPassword, newPasswordRetype]);

  const validatePasswords = useMemo(() => {
    if (newPasswordRetype.length > 0) {
      if (newPassword !== newPasswordRetype) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPasswordRetype]);

  const allowButtonClick = checkValidButton(
    checkFIlledInputs,
    validatePasswords
  );

  const closeSuccessModal = () => {
    dispatch({
      type: authConstants.UPDATE_PASSWORD_STATES_TO_DEFAULT,
    });
  };

  return (
    <>
      {updateUserPasswordSuccess && (
        <ModalContainer closeModal={closeSuccessModal}>
          <ResetSuccess />
        </ModalContainer>
      )}

      <GoBackInfo text="Back" navigateTo={""} />
      <div className="bg-white absolute w-[100vw] left-0 top-[100px] bottom-0 px-7">
        <p className="mt-16 font-black text-lg">Update your password</p>
        <form className="mt-6" onSubmit={onSubmit}>
          <div className="flex flex-col gap-5">
            {/* Old password */}
            <div className="flex flex-col gap-2.5 text-[#333333]">
              <label
                htmlFor="oldPassword"
                className="font-semibold text-[13.5px] ml-1"
              >
                Old password
              </label>
              <div className="relative">
                <input
                  id="oldPassword"
                  name="oldPassword"
                  value={oldPassword}
                  onChange={onChange}
                  onBlur={() => setInputOneBlurFocus(false)}
                  onFocus={() => setInputOneBlurFocus(true)}
                  type={toggleCurrentPassword ? "text" : "password"}
                  placeholder="Enter current password"
                  className={`bg-[#f5f5f5] border border-[#dddddd] p-3.5 rounded-md text-[13px] font-semibold outline-0 w-full focus-visible:border-mvx-blue placeholder:text-[#b2b2b2] placeholder:font-medium focus-visible:border-2 focus-visible:bg-[#fbfbfb] ${
                    toggleCurrentPassword
                      ? "tracking-normal"
                      : "tracking-widest placeholder:tracking-normal"
                  }`}
                />
                {oldPassword.length > 0 && (
                  <span
                    className={`absolute material-icons cursor-pointer right-5 top-3 !text-[20px] ${
                      inputOneBlurFocus ? "!text-[#333333]" : "!text-[#b2b2b2]"
                    }`}
                    onClick={() => setToggleCurrentPassword((prev) => !prev)}
                  >
                    {toggleCurrentPassword ? "visibility_off" : "visibility"}
                  </span>
                )}
              </div>
            </div>
            {/* New Password */}
            <div className="flex flex-col gap-2.5 text-[#333333]">
              <label
                htmlFor="newPassword"
                className="font-semibold text-[13.5px] ml-1"
              >
                New password
              </label>
              <div className="relative">
                <input
                  id="newPassword"
                  name="newPassword"
                  value={newPassword}
                  onChange={onChange}
                  onBlur={() => setInputTwoBlurFocus(false)}
                  onFocus={() => setInputTwoBlurFocus(true)}
                  type={toggleNewPassword ? "text" : "password"}
                  placeholder="Enter new password"
                  className={`bg-[#f5f5f5] border border-[#dddddd] p-3.5 rounded-md text-[13px] font-semibold outline-0 w-full focus-visible:border-mvx-blue placeholder:text-[#b2b2b2] placeholder:font-medium focus-visible:border-2 focus-visible:bg-[#fbfbfb] ${
                    toggleNewPassword
                      ? ":tracking-normal"
                      : "tracking-widest placeholder:tracking-normal"
                  }`}
                />
                {newPassword.length > 0 && (
                  <span
                    className={`absolute material-icons cursor-pointer right-5 top-3 !text-[20px] ${
                      inputThreeBlurFocus
                        ? "!text-[#333333]"
                        : "!text-[#b2b2b2]"
                    }`}
                    onClick={() => setToggleNewPassword((prev) => !prev)}
                  >
                    {toggleNewPassword ? "visibility_off" : "visibility"}
                  </span>
                )}
              </div>
            </div>
            {/* Re-enter New Password */}
            <div className="flex flex-col gap-2.5 text-[#333333]">
              <label
                htmlFor="newPasswordRetype"
                className="font-semibold text-[13.5px] ml-1"
              >
                Re-enter new password
              </label>
              <div className="relative">
                <input
                  id="newPasswordRetype"
                  name="newPasswordRetype"
                  value={newPasswordRetype}
                  onChange={onChange}
                  onBlur={() => setInputThreeBlurFocus(false)}
                  onFocus={() => setInputThreeBlurFocus(true)}
                  type={toggleResetNewPassword ? "text" : "password"}
                  placeholder="Enter new password"
                  className={`bg-[#f5f5f5] border border-[#dddddd] p-3.5 rounded-md text-[13px] font-semibold outline-0 w-full focus-visible:border-mvx-blue placeholder:text-[#b2b2b2] placeholder:font-medium   focus-visible:border-2 focus-visible:bg-[#fbfbfb] ${
                    toggleResetNewPassword
                      ? "tracking-normal"
                      : "tracking-widest placeholder:tracking-normal"
                  } ${
                    !validatePasswords
                      ? "border-2 border-mvx-red text-mvx-red focus-visible:border-mvx-red"
                      : "border border-[#dddddd] focus-visible:border-mvx-blue"
                  }`}
                />
                {newPasswordRetype.length > 0 && (
                  <span
                    className={`absolute material-icons cursor-pointer right-5 top-3 !text-[20px] ${
                      inputTwoBlurFocus ? "!text-[#333333]" : "!text-[#b2b2b2]"
                    }`}
                    onClick={() => setToggleResetNewPassword((prev) => !prev)}
                  >
                    {toggleResetNewPassword ? "visibility_off" : "visibility"}
                  </span>
                )}
                {!validatePasswords && (
                  <span className="text-mvx-red text-[12px] font-bold">
                    Please make sure your passwords match.
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-3 mt-5">
              <button
                className="py-4 text-center bg-mvx-blue text-white rounded-lg font-bold"
                type="submit"
                disabled={!allowButtonClick}
              >
                {updateUserPasswordLoading ? "Updating..." : "Update password"}
              </button>
              <button
                className="py-4 text-center bg-[#f1f1f1] border border-[#b8b8b8] text-[#333] rounded-lg font-bold"
                type="button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default MobilePasswordUpdate;
