import { authConstants } from "constants";

const initialState = {
  user: {},
  registerLoading: false,
  registerError: "",
  loginLoading: false,
  loginError: "",
  forgetPasswordLoading: false,
  forgetPasswordError: "",
  forgetPasswordSuccess: "",
  resetPasswordLoading: false,
  resetPasswordError: "",
  resetPasswordSuccess: "",
  sendVerifySuccess: "",
  sendVerifyLoading: false,
  sendVerifyError: "",
  toggleUpdatePasswordModal: false,
  toggleCreateSubUserModal: false,
  toggleEditSubUserModal: false,
  toggleDeleteSubUserModal: false,
  verifyUserSuccess: "",
  verifyUserLoading: false,
  verifyUserError: "",
  updateRequestLoading: false,
  updateRequestError: "",
  updateUserImageRequestLoading: false,
  updateUserImageRequestError: "",
  updateUserPasswordLoading: false,
  updateUserPasswordSuccess: "",
  updateUserPasswordError: "",
  fetchSubUserLoading: false,
  fetchSubUserSuccess: [],
  fetchSubUserError: "",
  addNewSubUserLoading: false,
  addNewSubUserSuccess: "",
  addNewSubUserError: "",
  subuserDataToEdit: null,
  subuserDataToDelete: null,
  editSubUserDetailsLoading: false,
  editSubUserDetailsSuccess: "",
  editSubUserDetailsError: "",
  deleteSubUserDetailsLoading: false,
  deleteSubUserDetailsSuccess: "",
  deleteSubUserDetailsError: "",
  deactivateAccountLoading: false,
  deactivateAccountSuccess: "",
};

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.REGISTER_REQUEST:
      return {
        ...state,
        registerLoading: true,
      };
    case authConstants.REGISTER_SUCCESS:
      return {
        ...state,
        registerLoading: false,
        user: action.payload,
      };
    case authConstants.REGISTER_FAILURE:
      return {
        ...state,
        registerLoading: false,
        registerError: action.payload,
      };
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        loginLoading: true,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        loginLoading: false,
        loginError: action.payload,
      };
    case authConstants.FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        forgetPasswordLoading: true,
      };
    case authConstants.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetPasswordLoading: false,
        forgetPasswordSuccess: action.payload,
      };
    case authConstants.FORGET_PASSWORD_ERROR:
      return {
        ...state,
        forgetPasswordLoading: false,
        resetError: action.payload,
      };
    case authConstants.RESET_REQUEST:
      return {
        ...state,
        resetPasswordLoading: true,
      };
    case authConstants.RESET_SUCCESS:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordSuccess: action.payload,
      };
    case authConstants.RESET_ERROR:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: action.payload,
      };
    case authConstants.VERIFY_REQUEST:
      return {
        ...state,
        verifyUserLoading: true,
      };
    case authConstants.VERIFY_SUCCESS:
      return {
        ...state,
        verifyUserLoading: false,
        verifyUserSuccess: action.payload,
      };
    case authConstants.VERIFY_ERROR:
      return {
        ...state,
        verifyUserLoading: false,
        verifyUserError: action.payload,
      };
    case authConstants.SEND_VERIFY_REQUEST:
      return {
        ...state,
        sendVerifyLoading: true,
      };
    case authConstants.SEND_VERIFY_SUCCESS:
      return {
        ...state,
        sendVerifyLoading: false,
        sendVerifySuccess: action.payload,
      };
    case authConstants.SEND_VERIFY_ERROR:
      return {
        ...state,
        sendVerifyLoading: false,
        sendVerifyError: action.payload,
      };
    case authConstants.UPDATE_USER_REQUEST:
      return {
        ...state,
        updateRequestLoading: true,
      };
    case authConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateRequestLoading: false,
        user: { ...state.user, ...action.payload.data },
      };
    case authConstants.UPDATE_USER_ERROR:
      return {
        ...state,
        updateRequestLoading: false,
        updateRequestError: action.payload,
      };
    case authConstants.UPDATE_USERIMAGE_REQUEST:
      return {
        ...state,
        updateUserImageRequestLoading: true,
      };
    case authConstants.UPDATE_USERIMAGE_SUCCESS:
      return {
        ...state,
        updateUserImageRequestLoading: false,
        user: { ...state.user, ...action.payload.data },
      };
    case authConstants.UPDATE_USERIMAGE_ERROR:
      return {
        ...state,
        updateUserImageRequestLoading: false,
        updateUserImageRequestError: action.payload,
      };
    case authConstants.TOGGLE_UPDATE_PASSWORD_MODAL:
      return {
        ...state,
        toggleUpdatePasswordModal: action.payload,
      };
    case authConstants.TOGGLE_CREATE_SUBUSER_MODAL:
      return {
        ...state,
        toggleCreateSubUserModal: !state.toggleCreateSubUserModal,
      };
    case authConstants.UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        updateUserPasswordLoading: true,
      };
    case authConstants.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        updateUserPasswordSuccess: action.payload,
        updateUserPasswordLoading: false,
      };
    case authConstants.UPDATE_USER_PASSWORD_ERROR:
      return {
        ...state,
        updateUserPasswordLoading: false,
        updateUserPasswordError: action.payload,
      };
    case authConstants.FETCH_SUBUSER_REQUEST:
      return {
        ...state,
        fetchSubUserLoading: true,
        fetchSubUserError: "",
      };
    case authConstants.FETCH_SUBUSER_SUCCESS:
      return {
        ...state,
        fetchSubUserSuccess: action.payload.data,
        fetchSubUserLoading: false,
        fetchSubUserError: "",
      };
    case authConstants.FETCH_SUBUSER_ERROR:
      return {
        ...state,
        fetchSubUserError: action.payload,
        fetchSubUserLoading: false,
        fetchSubUserSuccess: [],
      };
    case authConstants.ADD_NEW_SUBUSER_REQUEST:
      return {
        ...state,
        addNewSubUserLoading: true,
        addNewSubUserError: "",
        fetchSubUserError: "",
      };
    case authConstants.ADD_NEW_SUBUSER_SUCCESS:
      return {
        ...state,
        fetchSubUserSuccess: [
          ...state.fetchSubUserSuccess,
          action.payload.user,
        ].map((s) => ({
          ...s,
          id: s.id || s.user_id,
        })),
        addNewSubUserSuccess: action.payload.message,
        addNewSubUserLoading: false,
        addNewSubUserError: "",
      };
    case authConstants.ADD_NEW_SUBUSER_ERROR:
      return {
        ...state,
        addNewSubUserLoading: false,
        addNewSubUserError: action.payload.message,
      };
    case authConstants.EDIT_SUBUSER_DATAILS_REQUEST:
      return {
        ...state,
        editSubUserDetailsLoading: true,
        editSubUserDetailsError: "",
      };
    case authConstants.EDIT_SUBUSER_DATAILS_SUCCESS:
      return {
        ...state,
        fetchSubUserSuccess: state.fetchSubUserSuccess.map((subuser) => {
          if (subuser?.id === action.payload.data.user_id) {
            return { ...subuser, ...action.payload.data };
          } else {
            return subuser;
          }
        }),
        editSubUserDetailsSuccess: action.payload.message,
        editSubUserDetailsLoading: false,
        editSubUserDetailsError: "",
      };
    case authConstants.EDIT_SUBUSER_DATAILS_ERROR:
      return {
        ...state,
        editSubUserDetailsLoading: false,
        editSubUserDetailsError: action.payload.message,
      };
    case authConstants.DELETE_SUBUSER_REQUEST:
      return {
        ...state,
        deleteSubUserDetailsLoading: true,
        deleteSubUserDetailsError: "",
      };
    case authConstants.DELETE_SUBUSER_SUCCESS:
      return {
        ...state,
        fetchSubUserSuccess: state.fetchSubUserSuccess?.filter(
          (subuser) => subuser?.id !== state.subuserDataToDelete
        ),
        deleteSubUserDetailsSuccess: action.payload.message,
        deleteSubUserDetailsLoading: false,
        deleteSubUserDetailsError: "",
      };
    case authConstants.DELETE_SUBUSER_ERROR:
      return {
        ...state,
        deleteSubUserDetailsLoading: false,
        deleteSubUserDetailsError: action.payload.message,
      };
    case authConstants.DEACTIVATE_MVX_ACCOUNT:
      return {
        ...state,
        deactivateAccountLoading: true,
      };
    case authConstants.DEACTIVATE_MVX_ACCOUNT_SUCCESS:
      return {
        ...state,
        user: {},
        deactivateAccountSuccess: action.payload.message,
      };

    case authConstants.ACCOUNT_DELETION_COMPLETED:
      return {
        ...state,
        deactivateAccountSuccess: "",
      };

    case authConstants.UPDATE_PASSWORD_STATES_TO_DEFAULT:
      return {
        ...state,
        updateUserPasswordError: "",
        updateUserPasswordSuccess: "",
        toggleUpdatePasswordModal: action.payload,
      };
    case authConstants.UPDATE_SUBUSER_STATES_TO_DEFAULT:
      return {
        ...state,
        addNewSubUserSuccess: "",
        addNewSubUserError: "",
        toggleCreateSubUserModal: false,
        toggleEditSubUserModal: false,
        toggleDeleteSubUserModal: false,
        editSubUserDetailsSuccess: "",
        editSubUserDetailsError: "",
        deleteSubUserDetailsSuccess: "",
        deleteSubUserDetailsError: "",
        subuserDataToEdit: null,
        subuserDataToDelete: null,
      };
    case authConstants.SUBUSER_DATA_TO_EDIT:
      return {
        ...state,
        subuserDataToEdit: state.fetchSubUserSuccess?.find(
          (subuser) => subuser.id === action.payload
        ),
      };
    case authConstants.SUBUSER_DATA_TO_DELETE:
      return {
        ...state,
        subuserDataToDelete: action.payload,
      };
    case authConstants.TOGGLE_EDIT_SUBUSER_MODAL:
      return {
        ...state,
        toggleEditSubUserModal: !state.toggleEditSubUserModal,
      };
    case authConstants.TOGGLE_DELETE_SUBUSER_MODAL:
      return {
        ...state,
        toggleDeleteSubUserModal: !state.toggleDeleteSubUserModal,
      };
    default:
      return state;
  }
}
