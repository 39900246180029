import { InfoIcon } from "assets/arts";
import React from "react";

const AdditionalServices = ({ shipment, setShipment }) => (
  <>
    <p className="text-sm sm:text-xs font-bold mb-2 text-[#3E3E3E]">
      Additional Services
    </p>
    <p className="text-[13px] sm:text-xs italic mb-4 text-mvx-blue">
      Which of these services Would you also like us to handle for you?
    </p>
    <div className="mb-8">
      <Service
        title={"Customs"}
        name={"customs"}
        shipment={shipment}
        setShipment={setShipment}
      />
      <Service
        title={"Insurance"}
        name={"insurance"}
        shipment={shipment}
        setShipment={setShipment}
      />
      <Service
        title={"SON import Permit"}
        name={"son_inport_permit"}
        shipment={shipment}
        setShipment={setShipment}
      />
      <Service
        title={"Form M Processing"}
        name={"form_m_processing"}
        shipment={shipment}
        setShipment={setShipment}
      />
      <Service
        title={"Haulage"}
        name={"haulage"}
        shipment={shipment}
        setShipment={setShipment}
      />
    </div>
  </>
);

const Service = ({ shipment, setShipment, title, name }) => (
  <div className="relative flex items-center mb-2">
    <input
      type="checkbox"
      checked={shipment?.additional_services[name]}
      name={name}
      onChange={(e) =>
        setShipment({
          ...shipment,
          additional_services: {
            ...shipment.additional_services,
            [e.target.name]: e.target.checked,
          },
        })
      }
      className="radio"
    />
    <div className="w-[22px] h-[22px] order-1	shrink-0 rounded-full border-2 border-gray"></div>
    <label className="font-light text-sm ml-3 order-2">{title}</label>
    <InfoIcon className="order-3 ml-2 w-[15px] h-[15px] shrink-0 cursor-help" />
  </div>
);

export default AdditionalServices;
