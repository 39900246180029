const { currentENVData } = require("helpers");
const { authService } = require("./authService");

export const notificationsService = {
  getNotifications,
  markAllNotificationsAsRead,
};

async function getNotifications(pages, tab) {
  const requestOptions = {
    method: "GET",
  };
  let url;
  if (tab === 1) {
    url = " ";
  } else if (tab === 2) {
    url = "&read=false";
  } else {
    url = "&archived=false";
  }
  
  const res = await authService.apiGate(
    `${currentENVData.transitURL}/users/notifications?page=${pages}${url}`,
    requestOptions
  );

  return res;
}

async function markAllNotificationsAsRead() {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({}),
  };

  const res = await authService.apiGate(
    `${currentENVData.transitURL}/users/notification/mark/all`,
    requestOptions
  );
  return res;
}
