import { MapContainer, Marker, useMap } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { Icon, latLngBounds } from "leaflet";
import { PackageArrowIcon } from "assets/arts";
import React, { useEffect, useMemo, useState } from "react";
import { currentENVData } from "helpers";
import marker from "assets/images/dashboard/icons/location-marker.svg";
import { shipmentsService } from "services";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import moment from "moment";
import LapHistory from "./LapHistory";
import { useParams } from "react-router-dom";
import useMovementsLocations from "hooks/useMovementsLocations";

const mapMarker = new Icon({
  iconUrl: marker,
  iconSize: [43, 43],
});

function UpdateMapCentre({ mapCentre }) {
  const map = useMap();
  if (mapCentre.length) {
    map.setView(mapCentre, 18);
  }
  return null;
}

const Movements = () => {
  const [selectedMovementId, setSelectedMovementId] = useState("");
  const params = useParams();
  const [movements, setMovements] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [movementsLoading, setMovementsLoading] = useState(false);
  const [selectedMovementTab, setSelectedMovementTab] = useState(0);
  const [lapHistory, setLapHistory] = useState(false);

  const selectedMovement = movements?.find(
    (movement) => movement._id === selectedMovementId
  );
  const { updatedLocations } = useMovementsLocations(movements);

  const fetchMovements = async (id) => {
    try {
      setMovementsLoading(true);
      const res = await shipmentsService.getShipmentMovements(id);
      setMovements(res.data.movements);
    } catch (error) {
      setMovementsLoading(false);
      if (error.status !== 404 && error.message) {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message + "!"} type="error" />
        ));
      }
    }
  };

  useEffect(() => {
    const trackingID = localStorage.getItem("movementTrackingId");
    const wantedID = movements?.find((mvm) => mvm._id === trackingID);
    if (wantedID?._id) {
      setSelectedMovementId(trackingID);
    } else {
      movements?.length && setSelectedMovementId(movements[0]?._id);
    }
  }, [movements]);

  useEffect(() => {
    document.querySelector(".map-loc")?.scrollIntoView({ behavior: "smooth" });
  }, [selectedMovementId]);

  const latLngUpdt = useMemo(() => {
    if (updatedLocations?.length) {
      if (selectedMovementId) {
        const movementSelected = updatedLocations?.find(
          (loc) => loc.id === selectedMovementId
        );
        return [movementSelected?.lat ?? 10, movementSelected?.lng ?? 10];
      } else {
        return [10, 10];
      }
    } else {
      return [10, 10];
    }
  }, [updatedLocations, selectedMovementId]);

  useEffect(() => {
    if (params?.shipmentId) {
      fetchMovements(params?.shipmentId);
    }
  }, [params]);

  return (
    <>
      <div className="mt-[100px] md:mt-[120px] flex flex-col lg:flex-row mb-[110px] gap-7">
        <div className="bg-[#FBFBFB] inline-block border border-[#E9E9E9] rounded-[15px] py-4 pl-5 pr-4 w-[358px] h-max">
          <h3 className="text-[15px] font-medium text-[#0E2B79]">
            All movements ({movements?.length ?? 0})
          </h3>
          <div className="max-h-[500px] overflow-x-auto -ml-2">
            {movements?.map(({ mvxid, _id }, idx) => (
              <p
                key={_id}
                className={`flex text-xs font-medium py-4 px-2 mt-[18px] mb-2 cursor-pointer transition-[background] duration-500 ${
                  selectedMovementId === _id && "bg-[#e8effe]"
                } ${
                  idx + 1 !== movements.length
                    ? "relative after:absolute after:w-full after:h-[1px] after:bg-[#d6d6d6] after:left-0 after:-bottom-2"
                    : "mb-[13px]"
                }`}
                onClick={() => {
                  setSelectedMovementId(_id);
                  localStorage.setItem("movementTrackingId", _id);
                }}
              >
                <span className="mr-12">MVXM {mvxid}</span>
                <span className="text-mvx-blue flex text-xs font-bold mr-12 items-center">
                  <span>Track Shipment</span>
                  <PackageArrowIcon className="h-4 w-5 transition-all duration-300 [&>*]:fill-mvx-blue rotate-90" />
                </span>
              </p>
            ))}
          </div>
        </div>
        <div className="h-[600px] rounded-xl grow overflow-hidden relative">
          <MapContainer
            center={latLngUpdt}
            zoom={3}
            scrollWheelZoom={false}
            bounds={movements?.length ? latLngBounds([...movements]) : null}
          >
            <UpdateMapCentre mapCentre={latLngUpdt} />{" "}
            <ReactLeafletGoogleLayer
              apiKey={currentENVData.googleAPIKey}
              type={"roadmap"}
            />
            {updatedLocations &&
              updatedLocations
                ?.filter((l) => l.lat && l.lng)
                ?.map((loc) => (
                  <Marker
                    key={loc?.id}
                    position={[loc?.lat, loc?.lng]}
                    icon={mapMarker}
                  />
                ))}
          </MapContainer>

          {!!selectedMovement && (
            <div className="w-[96.4%] bg-white absolute left-3 right-2 bottom-4 z-[999] rounded-xl pt-4 pb-8 px-3">
              <div className="border-b border-[#d9dadb] font-medium text-[13px] flex pb-2 map-loc">
                <p
                  className={`ml-6 cursor-pointer after:transition-all after:duration-300 relative ${
                    !selectedMovementTab &&
                    "font-bold after:absolute after:-bottom-2 after:-left-[19px] after:w-[134%] after:h-1 after:bg-mvx-blue"
                  }`}
                  onClick={() => setSelectedMovementTab(0)}
                >
                  Movement Details
                </p>
                <p
                  className={`ml-9 cursor-pointer after:transition-all after:duration-300 relative ${
                    selectedMovementTab &&
                    "font-bold after:absolute after:-bottom-2 after:-left-[19px] after:w-[134%] after:h-1 after:bg-mvx-blue"
                  }`}
                >
                  Current Location
                </p>
              </div>

              {!selectedMovementTab ? (
                <div className="flex pt-5 pl-[23px] h-[165px]">
                  <div className="basis-[50%]">
                    <p className="text-xs">
                      <b>Shipment Source:</b>{" "}
                      {selectedMovement?.pickup?.address}
                    </p>
                    <p className="text-[11px] mt-[6px]">
                      Departure Time:{" "}
                      <span className="font-medium">
                        {moment(selectedMovement?.start_time)?.format(
                          "MMMM DD, YYYY: HH:mma"
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="basis-[50%] ml-8">
                    <p className="text-xs">
                      <b>Shipment Destination:</b>{" "}
                      {selectedMovement?.destination?.address}
                    </p>
                    <p className="text-[11px] mt-[6px]">
                      Estimated Arrival Time:{" "}
                      <span className="font-medium">
                        April 22, 2021: 17:30pm
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="flex pt-5 pl-[23px] h-[165px]">
                  <div className="basis-[50%]">
                    <p className="text-xs">
                      <b>Current Location:</b> Apapa Port, Lagos, Tincan Port,
                      Lagos
                    </p>
                    <p className="text-[11px] mt-1">
                      Arrival Time:{" "}
                      <span className="font-medium">
                        April 22, 2021: 17:30pm
                      </span>
                    </p>{" "}
                    <p className="text-[11px] mt-[6px]">
                      Loading Time:{" "}
                      <span className="font-medium">
                        April 22, 2021: 17:30pm
                      </span>
                    </p>
                    <p className="text-[11px] mt-[6px]">
                      Predicted Departure Date:{" "}
                      <span className="font-medium">
                        April 22, 2021: 17:30pm
                      </span>
                    </p>
                    <p
                      className="text-mvx-blue text-[13px] font-semibold mt-9 flex cursor-pointer w-max"
                      onClick={() => setLapHistory(true)}
                    >
                      {" "}
                      <span className="material-icons mr-[6px] !text-[16px] !font-bold">
                        north_east
                      </span>
                      <span>View Location History</span>
                    </p>
                  </div>
                  <div className="basis-[50%] ml-8">
                    <p className="text-xs flex">
                      <span className="material-icons mr-[6px] !text-[16px] !font-bold text-[#FFA80E]">
                        {" "}
                        inventory
                      </span>
                      <b>Container Event</b>
                    </p>
                    <p className="text-[11px] mt-[10px]">
                      Location Code{" "}
                      <span className="font-medium">POL (Port of Loading)</span>
                    </p>
                    <p className="text-[11px] mt-[6px]">
                      Port Name:{" "}
                      <span className="font-medium">LIANYUNGANG</span>
                    </p>
                    <p className="text-[11px] mt-[6px]">
                      Port Code: <span className="font-medium">CNLYG</span>
                    </p>
                    <p className="text-[11px] mt-[6px]">
                      Event Type:{" "}
                      <span className="font-medium">Ship interchange</span>
                    </p>
                    <p className="text-[11px] mt-[6px]">
                      Estimated Time:{" "}
                      <span className="font-medium">
                        April 22, 2021: 17:30pm
                      </span>
                    </p>
                    <p className="text-[11px] mt-[6px]">
                      Event Time:{" "}
                      <span className="font-medium">
                        April 22, 2021: 17:30pm
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {lapHistory && (
        <LapHistory
          data={selectedMovement}
          onRequestClose={() => setLapHistory(false)}
        />
      )}
    </>
  );
};

export default Movements;
