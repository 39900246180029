import Numbers from "components/About/Numbers";
import Footer from "components/common/Footer";
import Newsletter from "components/common/Newsletter";
import React from "react";
import Hero from "./Hero";
import ServiceDetails from "./ServiceDetails";

const Services = ({
  heroTitle,
  heroDescription,
  heroMedia,
  showClients,
  buttonColor,
  details,
  tagLine,
  ctaLocation,
  primaryButtonCta,
}) => {
  return (
    <>
      <Hero
        heroTitle={heroTitle}
        heroDescription={heroDescription}
        showClients={showClients}
        buttonColor={buttonColor}
        heroMedia={heroMedia}
        ctaLocation={ctaLocation}
        primaryButtonCta={primaryButtonCta}
      />
      <ServiceDetails
        details={details}
        buttonColor={buttonColor}
        tagLine={tagLine}
      />
      <Numbers />
      <Newsletter />
      <Footer />
    </>
  );
};

export default Services;
