import { InfoIcon } from "assets/arts";
import ModalContainer from "components/common/modals/ModalContainer";
import React, { useState } from "react";

const Incoterms = ({ shipment, setShipment }) => {
  const [viewIncoterms, setViewIncoterms] = useState(false);

  const setIncoterms = (event) => {
    const { value } = event.target;

    setShipment({
      ...shipment,
      incoterms: value,
    });
  };

  return (
    <>
      <p className="text-sm sm:text-xs font-bold mb-2 text-[#3E3E3E]">
        Incoterms
      </p>
      <p
        className="text-xs italic mb-4 text-mvx-blue cursor-pointer"
        onClick={() => setViewIncoterms(true)}
      >
        What are Incoterms?{" "}
        <InfoIcon className="order-3 ml-1 mb-[2px] w-[15px] h-[15px] shrink-0 inline-block cursor-help" />
      </p>
      <div className="flex flex-col sm:flex-row gap-3 flex-wrap mb-8">
        <Service
          title={"C & F"}
          value={"C&F"}
          shipment={shipment}
          setIncoterms={setIncoterms}
        />
        <Service
          title={"CIF"}
          value={"CIF"}
          shipment={shipment}
          setIncoterms={setIncoterms}
        />
        <Service
          title={"FCA"}
          value={"FCA"}
          shipment={shipment}
          setIncoterms={setIncoterms}
        />
        <Service
          title={"DDU"}
          value={"DDU"}
          shipment={shipment}
          setIncoterms={setIncoterms}
        />
        <Service
          title={"DDP"}
          value={"DDP"}
          shipment={shipment}
          setIncoterms={setIncoterms}
        />
        <Service
          title={"EXW"}
          value={"EXW"}
          shipment={shipment}
          setIncoterms={setIncoterms}
        />
        <Service
          title={"FOB"}
          value={"FOB"}
          shipment={shipment}
          setIncoterms={setIncoterms}
        />
      </div>
      {viewIncoterms && (
        <ModalContainer closeModal={() => setViewIncoterms(false)}>
          <div className="bg-white shadow rounded-[10px] pt-6 pb-4 px-6 w-[570px] h-[620px] overflow-y-auto">
            <div className="flex justify-between">
              <span className="text-lg font-semibold text-[#091D51]">What are Incoterms?</span>
              <span
                className="material-icons text-gray-600 -translate-y-1 !text-xl cursor-pointer"
                onClick={() => setViewIncoterms(false)}
              >
                close
              </span>
            </div>
            <p className="text-xs mt-2 mb-5 text-[#596882]">
              Incoterms are the selling terms that the buyer and seller of goods
              both agree to during international trade. It states when the
              seller’s costs and risks are transferred onto the buyer.
            </p>
            <div className="border-y pt-5 pb-6">
              <p className="text-[13px] font-semibold text-[#091D51]">
                CFR — Cost and Freight
              </p>
              <p className="text-xs mt-2 text-[#596882]">
                The seller delivers the goods on board the vessel. The seller
                must contract for and pay the costs and freight necessary to
                bring the goods to the named port of destination.
              </p>
            </div>
            <div className="border-y pt-5 pb-6">
              <p className="text-[13px] font-semibold text-[#091D51]">
                CIF — Cost, Insurance and Freight
              </p>
              <p className="text-xs mt-2 text-[#596882]">
                The seller delivers the goods on board the vessel. The seller
                must contract for and pay the costs and freight necessary to
                bring the goods to the named port of destination, including
                insurance cover against the buyer’s risk of loss/damage during
                carriage.
              </p>
            </div>
            <div className="border-y pt-5 pb-6">
              <p className="text-[13px] font-semibold text-[#091D51]">FCA — Free Carrier</p>
              <p className="text-xs mt-2 text-[#596882]">
                The seller delivers the goods to a carrier or another person
                nominated by the buyer, at the seller's premises or another
                named place.
              </p>
            </div>
            <div className="border-y pt-5 pb-6">
              <p className="text-[13px] font-semibold text-[#091D51]">
                DDU — Delivered Duty Paid
              </p>
              <p className="text-xs mt-2 text-[#596882]">
                Delivered duty unpaid simply means that it's the buyer
                responsibility to pay for any of the destination country's
                customs charges, duties, or taxes. These must all be paid in
                order for customs to release the shipment after it arrives.
              </p>
            </div>
            <div className="border-y pt-5 pb-6">
              <p className="text-[13px] font-semibold text-[#091D51]">
                DDP — Delivered Duty Paid
              </p>
              <p className="text-xs mt-2 text-[#596882]">
                The seller bears all the costs and risks involved in bringing
                the goods to the final destination. They clear and pay any
                duties on the products for export and import.
              </p>
            </div>
            <div className="border-y pt-5 pb-6">
              <p className="text-[13px] font-semibold text-[#091D51]">EXW — Ex Works</p>
              <p className="text-xs mt-2 text-[#596882]">
                ExWorks is when the seller places the goods at the disposal of
                the buyer at the seller’s premises or at another named place.
              </p>
            </div>
            <div className="border-t pt-5 pb-3">
              <p className="text-[13px] font-semibold text-[#091D51]">FOB — Free On Board</p>
              <p className="text-xs mt-2 text-[#596882]">
                The seller delivers the goods on board the vessel nominated by
                the buyer at the named port of shipment. The risk of loss/damage
                to the goods passes when the products are on the vessel
              </p>
            </div>
          </div>
        </ModalContainer>
      )}
    </>
  );
};

const Service = ({ shipment, value, setIncoterms, title }) => (
  <div
    className="relative flex items-center cursor-pointer h-max"
    onClick={() => document.getElementById("serv").focus()}
  >
    <input
      id="serv"
      type="radio"
      name={"incoterms"}
      value={value}
      onChange={setIncoterms}
      className="radio"
      checked={shipment.incoterms === value}
    />
    <div className="w-[22px] h-[22px] order-1	shrink-0 rounded-full border-2 border-gray"></div>
    <label className="font-light text-sm ml-3 order-2">{title}</label>
  </div>
);

export default Incoterms;
