import ModalContainer from "components/common/modals/ModalContainer";
import { SuccessCheckIcon } from "assets/arts";
import { useSelector, useDispatch } from "react-redux";
import { authConstants } from "constants";
import { useNavigate } from "react-router-dom";
import { GreenCheck } from "./ResetSuccess";

const DeleteMvxAccModal = ({ hideDeleteAccModal, deactivateAccount }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { deactivateAccountLoading, deactivateAccountSuccess } = useSelector(
    (state) => state.auth
  );

  const gotoHomePage = () => {
    dispatch(authConstants.ACCOUNT_DELETION_COMPLETED);
    navigate("/");
  };

  return (
    <ModalContainer>
      <div className="flex flex-col items center justify-center bg-white rounded-2xl w-[485px] h-[400px]">
        {deactivateAccountSuccess ===
        "Your account has been deactivated successfully" ? (
          <div className="flex flex-col items-center justify-center h-full gap-6">
            <GreenCheck />
            <div className="text-[#333333] text-center max-w-[290px] space-y-3 leading-8">
              <p className="text-[22px] font-black">
                Your account has been deleted successfully!
              </p>
            </div>
            <button
              className="bg-mvx-blue text-white text-[13px] font-bold px-7 py-3 tracking-wide rounded-md"
              onClick={gotoHomePage}
            >
              Return to home page
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-5 text-center">
            <SuccessCheckIcon width={124} height={124} />
            <p className="font-bold text-lg max-w-[250px] leading-8">
              Are you sure you want to delete your MVX accout?
            </p>
            <div className="flex gap-4 text-[13.5px] font-medium tracking-wide mt-2">
              <button
                className="bg-[#eeeeee] rounded-md py-3 px-7 text-[#333333]"
                onClick={hideDeleteAccModal}
              >
                No, Cancel
              </button>
              <button
                className="bg-mvx-blue rounded-md py-3 px-7 text-white"
                onClick={deactivateAccount}
              >
                {deactivateAccountLoading ? "Deleteing..." : "Yes, Delete"}
              </button>
            </div>
          </div>
        )}
      </div>
    </ModalContainer>
  );
};
export default DeleteMvxAccModal;
