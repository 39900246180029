import { ArrowCircleIcon, QuoteIcon } from "assets/arts";
import { getInitials } from "helpers";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import testimonials from "variables/testimonials";

var settings = {
  dots: false,
  infinite: true,
  autoplay: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 640,
      settings: "unslick",
    },
  ],
};

const CompaniesServed = ({ noMargin }) => {
  const [testimonial, setTestimonial] = useState(0);
  const [pageSize, setPageSize] = useState(
    document.documentElement.clientWidth
  );

  const slider = useRef(null);

  useEffect(() => {
    function handleResize() {
      setPageSize(document.documentElement.clientWidth);
    }

    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (pageSize > 640) {
      const processInterval = setInterval(() => {
        setTestimonial((testimonial) =>
          testimonial === testimonials.length - 1 ? 0 : testimonial + 1
        );
      }, 8000);

      return () => clearInterval(processInterval);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (slider?.current && document.documentElement.clientWidth > 640) {
      slider?.current?.slickGoTo(testimonial);
    }
  }, [testimonial]);

  return (
    <Container
      url={require("assets/images/subtract3.png")}
      className={`bg-mvx-blue2 pt-10 pb-20 relative ${
        !noMargin && "mb-10 sm:mb-20"
      }`}
    >
      <Subtract className="absolute w-full h-full top-0 left-0 z-0 bg-none sm:bg-[url('assets/images/subtract2.png')]"></Subtract>
      <div className="w-[90%] sm:w-4/5 m-auto relative">
        <p className="font-catalina text-3xl mt-4 mb-4 text-white">
          600+ Companies Served
        </p>
        <p className="text-sm mb-10 text-white">
          We do our job so well that over 80% of our customers have shipped with
          us <br className="hidden sm:block" />
          multiple times. Here's what some of our customers say about us.
        </p>
        <div className="flex flex-wrap items-center w-full sm:w-[calc(100%-50px)] m-auto min-h-[300px] bg-white rounded-xl py-10 pb-32 sm:pb-10">
          <div className="w-full sm:w-1/2 order-3	sm:order-1 flex sm:block flex-col items-center shrink-0 pl-4 sm:pl-32 px-4 sm:px-0">
            {testimonials[testimonial].logo}
            <div className="flex">
              <div className="w-12 h-12 shrink-0 rounded-full bg-black mr-3 text-white text-md font-bold flex items-center justify-center">
                {getInitials(testimonials[testimonial].client)}
              </div>
              <div>
                <p className="font-bold pt-1">
                  {testimonials[testimonial].client}
                </p>
                <p className="text-xs">
                  <span className="font-light">
                    {testimonials[testimonial].location?.state}
                  </span>
                  , <b>{testimonials[testimonial].location?.country}</b>
                </p>
              </div>
            </div>
          </div>
          <div className="w-2/5 h-px bg-[#BBB] m-auto order-2 mb-10 sm:hidden block"></div>
          <div className="w-full sm:w-1/2 order-1	sm:order-3 shrink-0 min-h-full border-l-0 sm:border-l border-gray-300 px-6 sm:px-20 pb-6 sm:pb-20 relative">
            <Slider ref={slider} {...settings}>
              {testimonials.map((t, index) => (
                <p
                  key={index}
                  className={`pt-10 pl-4 text-[#333333] ${
                    testimonial !== index ? "hidden sm:flex" : ""
                  }`}
                >
                  <QuoteIcon className="absolute w-12 -ml-4 -mt-4 h-auto" />
                  {t.message}
                </p>
              ))}
            </Slider>
            {pageSize > 640 && (
              <Arrows
                setTestimonial={setTestimonial}
                length={testimonials.length}
              />
            )}
          </div>
          {pageSize <= 640 && (
            <Arrows
              setTestimonial={setTestimonial}
              length={testimonials.length}
              large={true}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

const Arrows = ({ setTestimonial, length, large }) => {
  return (
    <div className="h-8 w-full absolute bottom-12 sm:bottom-4 left-0 pl-0 sm:pl-24 flex justify-center sm:justify-start">
      <ArrowCircleIcon
        onClick={() => setTestimonial((t) => (t === 0 ? length - 1 : t - 1))}
        className={`${
          large ? "h-10 w-10" : "h-7 w-7"
        } cursor-pointer hover:opacity-60 duration-300 mr-2 rotate-180`}
      />
      <ArrowCircleIcon
        onClick={() => setTestimonial((t) => (t === length - 1 ? 0 : t + 1))}
        className={`${
          large ? "h-10 w-10" : "h-7 w-7"
        } cursor-pointer hover:opacity-60 duration-300`}
      />
    </div>
  );
};

const Container = styled.div`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: auto 100%;
  animation-duration: 1.2s;
  animation-name: oscillate;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
`;

const Subtract = styled.div`
  background-repeat: no-repeat;
  background-size: auto 100%;
  animation-duration: 2s;
  animation-name: oscillate2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
`;

export default CompaniesServed;
