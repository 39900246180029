export const analyticsConstants = {
  USER_ANALYTICS_REQUEST: "USER_ANALYTICS_REQUEST",
  USER_ANALYTICS_SUCCESS: "USER_ANALYTICS_SUCCESS",
  USER_ANALYTICS_FAILURE: "USER_ANALYTICS_FAILURE",
  SHIPMENT_TRENDS_REQUEST: "SHIPMENT_TRENDS_REQUEST",
  SHIPMENT_TRENDS_SUCCESS: "SHIPMENT_TRENDS_SUCCESS",
  SHIPMENT_TRENDS_FAILURE: "SHIPMENT_TRENDS_FAILURE",
  SHIPMENT_COUNT_REQUEST: "SHIPMENT_COUNT_REQUEST",
  SHIPMENT_COUNT_SUCCESS: "SHIPMENT_COUNT_SUCCESS",
  SHIPMENT_COUNT_FAILURE: "SHIPMENT_COUNT_FAILURE",
};
