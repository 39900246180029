import React, { useState } from "react";

const NewsletterForm = ({ header }) => {
  const [focus, onFocus] = useState(null);
  const [subscribe, setSubscribe] = useState({});

  const onChange = (e) => {
    const { name, value } = e.target;

    setSubscribe((s) => ({
      ...s,
      [name]: value,
    }));
  };

  const submit = (e) => {
    e.preventDefault();
    //   dispatch(appActions.subscribeToNewsletter(subsFormcribe));
  };

  return (
    <form onSubmit={submit} className="w-full sm:w-[90%] flex flex-wrap sm:flex-nowrap">
      <input
        type="text"
        className={`${
          header
            ? "rounded-2xl bg-[#FFF]/70 placeholder:text-gray-400"
            : "rounded-lg bg-[#FFF]/50 placeholder:text-black"
        } py-3 px-6 mr-0 sm:mr-4 outline-0 w-full h-[50px] text-sm mb-6 sm:mb-0`}
        placeholder="Enter email address"
        name="email"
        value={subscribe.email || ""}
        onChange={onChange}
        onFocus={() => onFocus("email-input")}
        readOnly={focus !== "email-input"}
        required={true}
      />
      <button
        type="submit"
        disabled={!subscribe.email || subscribe.email === ""}
        className={`${
          header ? "rounded-2xl bg-[#1261BF]" : "rounded-lg bg-mvx-orange"
        } shadow-lg outline-0 w-full sm:w-[250px] h-[50px] text-white text-md font-bold disabled:opacity-70`}
      >
        Subscribe
      </button>
    </form>
  );
};

export default NewsletterForm;
