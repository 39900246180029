import { Logo } from "assets/arts";
import React from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import sanitize from "sanitize-html";

const ViewInstantQuote = () => {
  const app = useSelector((state) => state.app);

  return (
    <div className="w-full h-screen flex items-center justify-center overflow-y-auto py-20">
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[650px] bg-white m-auto overflow-hidden px-4 min-h-[300px] px-10 py-10"
      >
        <div className="flex">
          <div className="w-1/2 pr-6">
            <Logo className="h-6 w-auto" />
            <p className="mt-4 font-bold text-[0.7rem]">
              TRANSIT OPERATIONS -{" "}
              <span className="uppercase">
                {app.quoteRequestData.service_mode}
              </span>{" "}
              FREIGHT
            </p>
            <p className="text-[0.7rem] mb-6">
              Plot 19 Dr S. Ezukuse Close, Lekki Phase 1.
              <br />
              Lekki Phase 1,
              <br />
              Lagos
              <br />
              transit@mvx.trade
            </p>
            <p className="text-[0.7rem] mb-6">
              Pickup:{" "}
              <span className="font-bold">
                {app?.quoteRequestData?.origin?.address}
              </span>
              <br />
              Port of Loading:{" "}
              <span className="font-bold">
                {app?.quoteRequestData?.destinationPortName}
              </span>
              <br />
              Destination:{" "}
              <span className="font-bold">
                {app?.quoteRequestData?.destination?.address}
              </span>
              <br />
              Destination Port:{" "}
              <span className="font-bold">
                {app?.quoteRequestData?.originPortName}
              </span>
              <br />
              Cargo:{" "}
              <span className="font-bold">{`${
                app?.quoteRequestData?.package_type === "containers"
                  ? "Containerized"
                  : "Non-containerized"
              } shipment`}</span>
              {app?.quoteRequestData?.package_type === "containers" ? (
                <>
                  <br />
                  Equipment:{" "}
                  <span className="font-bold">{`${app?.quoteRequestData?.container_size?.qty} x ${app?.quoteRequestData?.container_size?.size}ft Containers`}</span>
                </>
              ) : (
                ""
              )}
            </p>
          </div>
          <div className="w-1/2 text-right pt-2">
            <p className="text-[0.7rem] mb-6 font-bold">
              Date <Moment format="d-MMM-YY" />
            </p>
            <p className="text-[0.7rem] mb-6">
              <span className="font-bold">Customer:</span>
              <br />
              {app.quoteRequestData.email}
              <br />
              {
                app.userLocation.address
                  ?.find((add) =>
                    add.types?.includes("administrative_area_level_1")
                  )
                  .address_components?.find((comp) =>
                    comp.types.includes("administrative_area_level_1")
                  ).long_name
              }
              {`, `}
              {
                app.userLocation.address
                  ?.find((add) => add.types?.includes("country"))
                  .address_components?.find((comp) =>
                    comp.types.includes("country")
                  ).long_name
              }
            </p>
          </div>
        </div>
        <div className="w-full mb-6">
          <p className="text-right font-bold text-[0.7rem] mb-2">Cost (USD)</p>
          <div className="w-full border border-gray text-xs">
            <div className="flex border-b border-gray bg-[#FAFAFA]">
              <div className="w-full p-4">Description/Origin charges</div>
              <div className="w-px border-r border-gray self-stretch"></div>
              <div className="w-32 shrink-0 p-4 text-center">Option 1</div>
              {app?.quote.data?.length > 1 ? (
                <>
                  <div className="w-px border-r border-gray self-stretch"></div>
                  <div className="w-32 shrink-0 p-4 text-center">Option 2</div>
                </>
              ) : (
                ""
              )}
            </div>
            {app.quote?.data?.[0]?.charges?.map((charge, index) => (
              <div className="flex border-b border-gray font-bold">
                <div className="w-full p-4">{charge.description}</div>
                <div className="w-px border-r border-gray self-stretch"></div>
                <div className="w-32 shrink-0 p-4 text-center">
                  {charge?.amount}
                </div>
                {app.quote?.data?.length > 1 ? (
                  <>
                    <div className="w-px border-r border-gray self-stretch"></div>
                    <div className="w-32 shrink-0 p-4 text-center">
                      {app.quote?.data?.[1].charges?.[index]?.amount}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
          <div className="flex border border-white font-bold">
            <div className="w-full border-r border-white py-2 pr-4 text-right text-xs">
              TOTAL (NGN):
            </div>
            <div className="w-32 shrink-0 py-2 text-center text-xs">
              1,020,000.00
            </div>
          </div>
        </div>
        <p className="text-sm mb-2">Note</p>
        <div
          className="border border-gray p-4 text-xs text-gray-500"
          dangerouslySetInnerHTML={{
            __html: sanitize(app.quote?.data?.[0]?.remarks),
          }}
        ></div>
      </div>
    </div>
  );
};

export default ViewInstantQuote;
