import jwtDecode from "jwt-decode";
import { authActions } from "actions/authActions";
import CustomToast from "components/common/CustomToast";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { authService } from "services";
import VerifyEmail from "./VerifyEmail";

const Login = ({ onFocus, focusedInput }) => {
  const [step, setStep] = useState(0);
  const [OTP, setOTP] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, getValues } = useForm();
  const { verifyUserLoading, verifyUserSuccess } = useSelector(
    (state) => state.auth
  );

  const forgotPassword = () => navigate("/forgot-password");

  const creds = {
    email: getValues("email"),
    password: getValues("password"),
  };

  const verifyUser = () => {
    if (OTP.length === 6) {
      dispatch(
        authActions.verifyUser({ email: creds.email, code: OTP }, false)
      );
    }
  };

  const loginUser = async (credentials = creds) => {
    setLoginLoading(true);
    try {
      const res = await authService.login(credentials);
      setLoginLoading(false);
      toast.custom((t) => <CustomToast t={t} message={"Login successful!"} />);
      dispatch(
        authActions.loginSuccess({
          ...jwtDecode(res._token)?.data,
          accessToken: res._token,
        })
      );
    } catch (error) {
      setLoginLoading(false);
      if (error.status === 409) {
        if (step) {
          toast.custom((t) => (
            <CustomToast t={t} message={"OTP resent successfully!"} />
          ));
        } else {
          setStep(1);
        }
      } else {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
        }
      }
    }
  };

  useEffect(() => {
    dispatch(authActions.resetLoginLoading());
    dispatch(authActions.resetVerifySuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (verifyUserSuccess && creds?.email && creds?.password) {
      loginUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyUserSuccess]);

  return (
    <>
      {!step ? (
        <>
          <p className="text-lg font-bold mb-6">Log in</p>
          <form onSubmit={handleSubmit(loginUser)}>
            <p className="text-xs font-bold mb-2">Email address</p>
            <input
              type="email"
              className="border bg-[#F5F5F5] border-gray-200 mb-4 rounded p-3 outline-0 w-full h-[50px] text-sm"
              placeholder="Email or Phone number"
              onFocus={() => onFocus("work-email")}
              onTouchStart={() => onFocus("work-email")}
              readOnly={focusedInput !== "work-email"}
              required
              {...register("email", { required: true })}
            />
            <p className="text-xs font-bold mb-2">Password</p>
            <input
              type="password"
              className="border bg-[#F5F5F5] border-gray-200 mb-6 rounded p-3 outline-0 w-full h-[50px] text-sm"
              placeholder="Password"
              onFocus={() => onFocus("password")}
              onTouchStart={() => onFocus("password")}
              readOnly={focusedInput !== "password"}
              required
              {...register("password", { required: true })}
            />
            <div className="flex items-center w-full justify-between mb-6 text-sm">
              <span
                onClick={forgotPassword}
                className={`text-mvx-blue cursor-pointer ${
                  loginLoading ? "pointer-events-none" : "pointer-events-auto"
                }`}
              >
                forgot password?
              </span>
            </div>
            <button
              type="submit"
              className={`bg-mvx-blue shadow-lg outline-0 rounded w-full h-[50px] text-white text-md mb-6 font-bold mb-14 ${
                loginLoading ? "!bg-mvx-light-blue" : ""
              }`}
              disabled={loginLoading}
            >
              {loginLoading ? "Logging in..." : "Log in"}
            </button>
          </form>
          <p className="w-full text-center m-auto text-sm text-gray-500">
            I don’t have an MVX account{" "}
            <Link to="/register">
              <span
                className={`text-mvx-blue cursor-pointer font-bold underline ml-1 ${
                  loginLoading ? "pointer-events-none" : "pointer-events-auto"
                }`}
              >
                Sign Up
              </span>
            </Link>
          </p>
        </>
      ) : (
        <VerifyEmail
          OTP={OTP}
          setOTP={setOTP}
          VerifyEmail={verifyUser}
          type="signUp"
          goBackFunc={() => setStep(0)}
          resendOTP={() => loginUser()}
          loading={verifyUserLoading}
          email={""}
        />
      )}
    </>
  );
};

export default Login;
