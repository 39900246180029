import { authService } from "./authService";
import Geocode from "react-geocode";
import { currentENVData } from "helpers";

export const appService = {
  contact,
  getLocation,
  getQuote,
  becomeAgent,
  fetchArticles,
};

function contact(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
  };

  let url = `${currentENVData.apiURL}/newsletter/contact`;

  return authService
    .apiGate(url, requestOptions)
    .then(authService.handleResponse)
    .then((res) => {
      return res;
    });
}

function getQuote(data) {
  if (data.package_type === "containers") {
    data = {
      ...data,
      load_type: "FCL",
    };
  }
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
  };

  let url = `${currentENVData.apiURL}/shipment/users/quote/estimation/${data.package_type}`;

  return authService
    .apiGate(url, requestOptions)
    .then(authService.handleResponse)
    .then((res) => {
      return res;
    });
}

function becomeAgent(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
  };

  let url = `${currentENVData.transitURL}/website/agent`;

  return authService
    .apiGate(url, requestOptions)
    .then(authService.handleResponse)
    .then((res) => {
      return res;
    });
}

function getLocation() {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({}),
  };

  let url = `https://www.googleapis.com/geolocation/v1/geolocate?key=${currentENVData.googleAPIKey}`;

  return authService
    .apiGate(url, requestOptions, true)
    .then(authService.handleResponse)
    .then(async (res) => {
      if (res.location) {
        Geocode.setApiKey(currentENVData.googleAPIKey);
        Geocode.setLanguage("en");

        const response = await Geocode.fromLatLng(
          res.location?.lat,
          res.location?.lng
        );

        return {
          countryCode: response.results?.find((c) =>
            c.address_components[0]?.types?.includes("country")
          )?.address_components[0]?.short_name,
          address: response.results,
        };
      } else {
        return {
          error: true,
          message: "No appropriate response",
        };
      }
    });
}

function fetchArticles(type) {
  const requestOptions = {
    method: "GET",
  };

  let url = `${currentENVData.transitURL}/newsletter/articles${
    type ? `?section=${type}` : ""
  }`;

  return authService
    .apiGate(url, requestOptions)
    .then(authService.handleResponse)
    .then((res) => {
      return res;
    });
}
