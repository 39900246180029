import React from "react";

const WhatNext = () => {
  return (
    <div className="bg-[#005ECD]/[0.04] pt-14 pb-6">
      <p className="text-center font-catalina mb-4 text-2xl sm:text-3xl w-4/5 m-auto sm:w-full">
        What happens next?
      </p>
      <div className="flex flex-wrap sm:flex-nowrap justify-between w-[80%] m-auto mb-4 sm:mb-6">
        <div className="w-full sm:w-1/3 mb-4 sm:mb-0 shrink-0 p-8 rounded-xl bg-white mr-6">
          <CardNumber number={1} />
          <p className="pt-4">
            We'll call you within 48 hours to share more information on the
            program.
          </p>
        </div>
        <div className="w-full sm:w-1/3 mb-4 sm:mb-0 shrink-0 p-8 rounded-xl bg-white mr-6">
          <CardNumber number={2} />
          <p className="pt-4">
            You share the details of the contact person at the business you're
            referring to us.
          </p>
        </div>
        <div className="w-full sm:w-1/3 shrink-0 p-8 rounded-xl bg-white">
          <CardNumber number={3} />
          <p className="pt-4">
            We'll reach out to them and create their shipment request.
          </p>
        </div>
      </div>
      <div className="flex flex-wrap sm:flex-nowrap justify-between w-[80%] m-auto mb-10">
        <div className="w-full sm:w-1/3 mb-4 sm:mb-0 shrink-0 p-8 rounded-xl bg-white mr-6">
          <CardNumber number={4} />
          <p className="pt-4">They'll make payment</p>
        </div>
        <div className="w-full sm:w-1/3 mb-4 sm:mb-0 shrink-0 p-8 rounded-xl bg-white mr-6">
          <CardNumber number={5} />
          <p className="pt-4">We'll pay you for your referral</p>
        </div>
        <div className="w-full sm:w-1/3 shrink-0 p-8 rounded-xl bg-white">
          <CardNumber number={6} />
          <p className="pt-4">We’ll ship their goods</p>
        </div>
      </div>
    </div>
  );
};

const CardNumber = ({ number }) => (
  <div className="w-[30px] h-[30px] pl-[3px] pt-[3px] rounded-full shrink-0 bg-[#000]">
    <div className="w-[27px] h-[27px] rounded-full shrink-0 bg-[#FF8A00] flex items-center justify-center text-white font-bold">
      {number}
    </div>
  </div>
);

export default WhatNext;
