import { shipmentsConstant } from "constants";

const initialState = {
  activeShipments: null,
  createShipmentsSuccess: false,
  createShipmentsLoading: false,
  createShipmentsError: "",
  activeShipmentsLoading: false,
  activeShipmentsError: "",
  shipmentsError: [],
  movements: [],
  movementsLoading: false,
  movementsError: [""],
  savedShipmentRequest: null,
  savedShipmentRequestLoading: false,
  savedShipmentRequestError: "",
  saveShipmentRequestSuccess: false,
  saveShipmentRequestLoading: false,
  saveShipmentRequestError: "",
  lapHistoryData: null,
  selectedShipmentDetails: {},
  selectedShipmentDetailsLoading: false,
  selectedShipmentDetailsError: "",
  quoteAccepted: false,
  acceptQuoteLoading: false,
  quoteRejected: false,
  rejectQuoteLoading: false,
  shipmentQuotes: null,
  shipmentQuotesLoading: false,
  shipmentQuotesError: false,
  financeReqShipmentId: "",
};

export function shipments(state = initialState, action) {
  switch (action.type) {
    case shipmentsConstant.ACTIVE_SHIPMENTS_REQUEST:
      return { ...state, activeShipmentsLoading: true };

    case shipmentsConstant.ACTIVE_SHIPMENTS_SUCCESS:
      return {
        ...state,
        activeShipments: action.payload,
        activeShipmentsLoading: false,
      };

    case shipmentsConstant.ACTIVE_SHIPMENTS_FAILURE:
      return {
        ...state,
        activeShipmentsError: action.payload,
        activeShipmentsLoading: false,
      };

    case shipmentsConstant.SHOW_LAP_HISTORY:
      return {
        ...state,
        lapHistoryData: action.payload,
      };
    case shipmentsConstant.MOVEMENTS_REQUEST:
      return { ...state, movementsLoading: true };

    case shipmentsConstant.MOVEMENTS_SUCCESS:
      return {
        ...state,
        movements: [...state.movements, action.payload].flat(),
        movementsLoading: false,
      };

    case shipmentsConstant.MOVEMENTS_FAILURE:
      if (state.movementsError.includes(action.payload)) {
        return {
          ...state,
          movementsLoading: false,
        };
      } else {
        return {
          ...state,
          movementsError: [...state.movementsError, action.payload],
          movementsLoading: false,
        };
      }
    case shipmentsConstant.CREATE_SHIPMENT_REQUEST:
      return { ...state, createShipmentsLoading: true };

    case shipmentsConstant.CREATE_SHIPMENT_SUCCESS:
      return {
        ...state,
        activeShipments: [...state?.activeShipments, action.payload].flat(),
      };

    case shipmentsConstant.CREATE_SHIPMENT_FAILURE:
      return {
        ...state,
        createShipmentsLoading: false,
        createShipmentsSuccess: false,
      };
    case shipmentsConstant.FETCH_SAVED_SHIPMENT_REQ_REQUEST:
      return { ...state, savedShipmentRequestLoading: true };
    case shipmentsConstant.FETCH_SAVED_SHIPMENT_REQ_SUCCESS:
      return {
        ...state,
        savedShipmentRequestLoading: false,
        savedShipmentRequest: action.payload,
      };
    case shipmentsConstant.FETCH_SAVED_SHIPMENT_REQ_FAILURE:
      return {
        ...state,
        savedShipmentRequestLoading: false,
        savedShipmentRequestError: action.payload,
      };
    case shipmentsConstant.SAVE_SHIPMENT_REQ_REQUEST:
      return { ...state, saveShipmentRequestLoading: true };
    case shipmentsConstant.SAVE_SHIPMENT_REQ_SUCCESS:
      return {
        ...state,
        saveShipmentRequestLoading: false,
        saveShipmentRequestSuccess: action.payload,
      };
    case shipmentsConstant.SAVE_SHIPMENT_REQ_FAILURE:
      return {
        ...state,
        saveShipmentRequestLoading: false,
        saveShipmentRequestError: action.payload,
      };
    case shipmentsConstant.FETCH_SINGLE_SHIPMENT_REQUEST:
      return { ...state, selectedShipmentDetailsLoading: true };
    case shipmentsConstant.FETCH_SINGLE_SHIPMENT_SUCCESS:
      return {
        ...state,
        selectedShipmentDetails: action.payload,
        selectedShipmentDetailsLoading: false,
      };
    case shipmentsConstant.FETCH_SINGLE_SHIPMENT_FAILURE:
      return {
        ...state,
        selectedShipmentDetailsError: action.payload,
        selectedShipmentDetailsLoading: false,
      };
    case shipmentsConstant.ACCEPT_QUOTE_REQUEST:
      return { ...state, acceptQuoteLoading: true };

    case shipmentsConstant.ACCEPT_QUOTE_SUCCESS:
      return {
        ...state,
        quoteAccepted: true,
        shipmentQuotes: action.payload,
        acceptQuoteLoading: false,
      };

    case shipmentsConstant.ACCEPT_QUOTE_FAILURE:
      return {
        ...state,
        quoteAccepted: false,
        acceptQuoteLoading: false,
      };
    case shipmentsConstant.REJECT_QUOTE_REQUEST:
      return { ...state, rejectQuoteLoading: true };

    case shipmentsConstant.REJECT_QUOTE_SUCCESS:
      return {
        ...state,
        quoteRejected: true,
        shipmentQuotes: action.payload,
        rejectQuoteLoading: false,
      };

    case shipmentsConstant.REJECT_QUOTE_FAILURE:
      return {
        ...state,
        quoteRejected: false,
        rejectQuoteLoading: false,
      };
    case shipmentsConstant.FETCH_SHIPMENT_QUOTES_REQUEST:
      return { ...state, shipmentQuotesLoading: true };

    case shipmentsConstant.FETCH_SHIPMENT_QUOTES_SUCCESS:
      return {
        ...state,
        shipmentQuotes: action.payload,
        shipmentQuotesLoading: false,
      };

    case shipmentsConstant.FETCH_SHIPMENT_QUOTES_FAILURE:
      return {
        ...state,
        shipmentQuotesError: action.payload,
        shipmentQuotesLoading: false,
      };
    case shipmentsConstant.SET_FINANCE_REQ_SHIPMENT_ID:
      return { ...state, financeReqShipmentId: action.payload };

    case shipmentsConstant.START_ADD_PARTNER_REQUEST:
      return {
        ...state,
        addingPartner: true,
      };
    case shipmentsConstant.ADD_PARTNER_SUCCESS:
      return {
        ...state,
        addingPartner: false,
        partner: action.partner,
      };
    case shipmentsConstant.ADD_PARTNER_FAILURE:
      return {
        ...state,
        addingPartner: false,
      };
    default:
      return state;
  }
}
