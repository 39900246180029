import React, { createRef, useEffect, useRef, useState } from "react";
import steps from "variables/steps";

const Steps = () => {
  const [step, setStep] = useState(0);
  const [pageSize, setPageSize] = useState(
    document.documentElement.clientWidth
  );
  const progressRef = useRef(null);
  const stepRefs = useRef([]);
  stepRefs.current = steps.map((e, i) => stepRefs.current[i] ?? createRef());

  useEffect(() => {
    function handleResize() {
      setPageSize(document.documentElement.clientWidth);
    }

    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    progressRef.current.style.height = `${stepRefs.current[step].current.clientHeight}px`;
    progressRef.current.style.top = `${stepRefs.current[step].current.offsetTop}px`;
  }, [step]);

  useEffect(() => {
    if (pageSize > 640) {
      const processInterval = setInterval(() => {
        setStep((step) => (step === 2 ? 0 : step + 1));
      }, 3000);

      return () => clearInterval(processInterval);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-[90%] sm:w-4/5 m-auto mb-10 sm:mb-32">
      <p className="font-catalina text-2xl sm:text-3xl mb-6 sm:mb-14 text-center">
        Become a Partner in 3 Easy Steps
      </p>
      <div className="w-full flex">
        <div className="w-[45%] hidden sm:block mr-0 sm:mr-20 h-full">
          {steps[step].image}
        </div>
        <div className="w-full sm:w-[55%] py-8 relative flex flex-col justify-center">
          <div
            ref={progressRef}
            className="absolute left w-[4px] bg-mvx-blue2 duration-200"
          ></div>
          {steps.map((stepInfo, index) => (
            <>
              <div
                ref={stepRefs.current[index]}
                key={index}
                onClick={() => setStep(index)}
                className={`pt-2 pb-3 sm:py-6 pl-6 sm:pl-10 cursor-pointer mb-4`}
              >
                <p
                  className={`text-lg sm:text-xl font-bold mb-4 ${
                    step === index ? "text-mvx-blue2" : "text-gray-700"
                  }`}
                >
                  {stepInfo.title}
                </p>
                <p className="text-xs sm:text-sm text-[#333333]">
                  {stepInfo.description}
                </p>
              </div>
              {step === index && (
                <div className="w-full sm:hidden block mb-6 mr-0 sm:mr-20 h-full">
                  {steps[step].image}
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;
