import React from "react";
import { CheckGrayIcon, CheckIcon } from "assets/arts";

const ProgressIndicator = ({ shipment }) => {
  const deliveryDetailsValid =
    shipment.origin?.lat &&
    shipment.destination?.lat &&
    shipment?.shipping_date;

  return (
    <div className="hidden md:4/12 lg:w-2/5 sticky top-0 self-start sm:flex z-50 bg-mvx-light-blue2">
      <div className="w-[40px] shrink-0">
        <div className="w-px h-full py-[12px] m-auto">
          <div className="w-px h-full bg-[#B7B7B7]"></div>
        </div>
      </div>
      <div className="w-full select-none py-3">
        <div className="text-xs mb-10 relative">
          <span
            className={`font-bold mr-2 ${
              !shipment?.service_type && "text-[#989898]"
            }`}
          >
            Step 1:
          </span>
          <span className={`${!shipment?.service_type && "text-[#989898]"}`}>
            Select service type
          </span>
          <div className="absolute bg-[#f6f8ff] w-[40px] h-[25px] -left-[40px] top-0">
            <div className="w-[20px] h-20px bg-[#f6f8ff] m-auto">
              {shipment.service_type ? (
                <CheckIcon className="w-auto h-[20px]" />
              ) : (
                <CheckGrayIcon className="w-auto h-[20px]" />
              )}
            </div>
          </div>
        </div>
        <div className="text-xs mb-10 relative">
          <span
            className={`font-bold mr-2 ${
              !shipment?.service_mode && "text-[#989898]"
            }`}
          >
            Step 2:
          </span>
          <span className={`${!shipment?.service_mode && "text-[#989898]"}`}>
            How would you like to move your goods?
          </span>
          <div className="absolute bg-[#f6f8ff] w-[40px] h-[30px] pt-[5px] -left-[40px] -top-[6px]">
            <div className="w-[20px] h-20px bg-[#f6f8ff] m-auto">
              {shipment.service_mode ? (
                <CheckIcon className="w-auto h-[20px]" />
              ) : (
                <CheckGrayIcon className="w-auto h-[20px]" />
              )}
            </div>
          </div>
        </div>
        <div className="text-xs relative">
          <span
            className={`font-bold mr-2 ${
              !deliveryDetailsValid && "text-[#989898]"
            }`}
          >
            Step 3:
          </span>
          <span className={`${!deliveryDetailsValid && "text-[#989898]"}`}>
            Enter delivery details
          </span>
          <div className="absolute bg-[#f6f8ff] w-[40px] h-[30px] pt-[5px] -left-[40px] -top-[6px]">
            <div className="w-[20px] h-20px bg-[#f6f8ff] m-auto">
              {deliveryDetailsValid ? (
                <CheckIcon className="w-auto h-[20px]" />
              ) : (
                <CheckGrayIcon className="w-auto h-[20px]" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressIndicator;
