import { appConstants } from "constants";

const initialState = { requestingLocation: false, toggleNavModal: false };

export function app(state = initialState, action) {
  switch (action.type) {
    case appConstants.LOCATION_REQUEST:
      return {
        ...state,
        requestingLocation: false,
      };
    case appConstants.LOCATION_SUCCESS:
      return {
        ...state,
        requestingLocation: false,
        userLocation: action.location,
      };
    case appConstants.LOCATION_FAILURE:
      return {
        ...state,
        requestingLocation: false,
      };
    case appConstants.CONTACT_REQUEST:
      return {
        ...state,
        sendingMessage: true,
      };
    case appConstants.CONTACT_SUCCESS:
      return {
        ...state,
        sendingMessage: false,
      };
    case appConstants.CONTACT_FAILURE:
      return {
        ...state,
        sendingMessage: false,
      };
    case appConstants.QUOTE_REQUEST:
      return {
        ...state,
        requestingQuote: true,
      };
    case appConstants.QUOTE_SUCCESS:
      return {
        ...state,
        requestingQuote: false,
        quote: action.quote,
        quoteRequestData: action.data,
      };
    case appConstants.QUOTE_FAILURE:
      return {
        ...state,
        requestingQuote: false,
      };
    case appConstants.BECOME_AGENT_REQUEST:
      return {
        ...state,
        becomingAgent: true,
        agent: null,
      };
    case appConstants.BECOME_AGENT_SUCCESS:
      return {
        ...state,
        becomingAgent: false,
        agent: action.agent,
      };
    case appConstants.BECOME_AGENT_FAILURE:
      return {
        ...state,
        becomingAgent: false,
      };
    case appConstants.ARTICLES_REQUEST:
      return {
        ...state,
        requestingArticles: true,
      };
    case appConstants.ARTICLES_SUCCESS:
      return {
        ...state,
        requestingArticles: false,
        articles: action.articles,
      };
    case appConstants.ARTICLES_FAILURE:
      return {
        ...state,
        requestingArticles: false,
      };
    case appConstants.TOGGLE_NAV_MODAL:
      return {
        ...state,
        toggleNavModal: action.payload,
      };
    default:
      return state;
  }
}
