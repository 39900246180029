import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shipmentActions, partnerActions } from "actions";
import { stripString } from "helpers";
import Select from "components/common/Select";
import { partnerCategories } from "variables/partners";
import ChipsInput from "../ChipsInput";
import countries from "variables/countries";
import Loading from "../Loading";

const BecomeAPartner = ({ closeModal }) => {
  const [partner, setPartner] = useState({ files: [] });
  const dispatch = useDispatch();
  const shipment = useSelector((state) => state.shipments);
  const partnerStore = useSelector((state) => state.partners);

  const modeOptions = [
    { label: "Air freight", value: "air" },
    { label: "Sea freight", value: "sea" },
  ];

  const countryOptions = useMemo(
    () =>
      countries.map((country) => ({
        label: country.name,
        value: country.name,
      })),
    []
  );

  const wcaOptions = [
    { label: "Yes, I am a WCA member", value: true },
    { label: "No, I am not a WCA member", value: false },
  ];

  const onChange = (e) => {
    const { name, value } = e.target;

    if (name === "wca_number" && value.length > 6) {
      return;
    }

    setPartner((state) => {
      if (name === "category") {
        if (value === "Finance") {
          delete state.contact_person;
        } else {
          delete state.threshold;
        }
      }

      return {
        ...state,
        [name]: value,
      };
    });
  };

  const resetForm = useCallback(() => {
    setPartner({ files: [] });
    dispatch(partnerActions.resetPartnerState());
  }, [dispatch]);

  useEffect(() => {
    if (shipment.partner) {
      if (partner.wca_partner?.value && partnerStore.onboardedPartner) {
        resetForm();
      }
      if (!partner.wca_partner?.value) {
        resetForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm, shipment.partner, partnerStore.onboardedPartner]);

  const isPartnerFormValid = useMemo(() => {
    return (
      partner.category?.value &&
      (partner.category?.value === "Freight Forwarding"
        ? partner.mode?.length
        : true) &&
      partner.name &&
      partner.email &&
      partner.raw_mobile &&
      partner.contact_person &&
      partner.wca_partner &&
      (partner.wca_partner?.value ? partner.wca_number?.length === 6 : true)
    );
  }, [partner]);

  const onSubmit = (e) => {
    e.preventDefault();

    console.log("Got here");

    const { mode, category, country, wca_partner, wca_number, ...payload } =
      partner;

    if (category?.value === "Freight Forwarding") {
      payload.partner_mode = mode.map((modeItem) => modeItem.value);
    }
    payload.category = category?.value;
    payload.country = country?.value;

    if (payload.category === "Finance") {
      payload.threshold.min = stripString(payload.threshold.min);
      payload.threshold.max = stripString(payload.threshold.max);
    }

    if (wca_partner?.value) {
      payload.wca_partner = true;
      payload.wca_number = wca_number;
    }

    dispatch(shipmentActions.addPartner(payload));
  };

  return (
    <div
      onClick={closeModal}
      className="w-screen h-screen flex items-center justify-center overflow-y-auto py-20"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[550px] bg-white m-auto px-4 min-h-[300px] px-10 py-10 rounded-xl"
      >
        <div>
          <div className="full-width full-height top-padding-100 bottom-padding-100 black-transparent">
            <form
              className="width-70-percent margin-auto white-color"
              onSubmit={onSubmit}
            >
              <p className="merriweather text-center text-md font-bold mb-4">
                Partner Interest Form
              </p>
              <div>
                <div className="mb-4">
                  <div className="mb-4">
                    <p className="text-xs mb-2 font-medium">Type of Partner</p>
                    <Select
                      name="category"
                      options={partnerCategories.map((category) => ({
                        label: category,
                        value: category,
                      }))}
                      placeholder="Type of Partner"
                      value={partner.category || ""}
                      onChange={(value) =>
                        setPartner({ ...partner, category: value })
                      }
                    />
                  </div>
                  {partner.category?.value === "Freight Forwarding" ? (
                    <div className="mb-4">
                      <p className="text-xs mb-2 font-medium">Mode</p>
                      <label>
                        <ChipsInput
                          name="mode"
                          placeholder="Mode"
                          onChange={(value) =>
                            setPartner({ ...partner, mode: value })
                          }
                          options={modeOptions}
                          value={partner.mode}
                        />
                      </label>
                    </div>
                  ) : null}
                  <div className="flex mb-4">
                    <div className="w-1/2 pr-2">
                      <p className="text-xs mb-2 font-medium">Full name</p>
                      <input
                        type="text"
                        name="contact_person"
                        value={partner.contact_person || ""}
                        onChange={onChange}
                        className="w-full h-[40px] rounded-md border border-gray outline-none px-2 text-sm"
                      />
                    </div>
                    <div className="w-1/2 pl-2">
                      <p className="text-xs mb-2 font-medium">Business name</p>
                      <input
                        type="text"
                        name="name"
                        value={partner.name || ""}
                        onChange={onChange}
                        className="w-full h-[40px] rounded-md border border-gray outline-none px-2 text-sm"
                      />
                    </div>
                  </div>
                  <div className="flex mb-4">
                    <div className="w-1/2 pr-2">
                      <p className="text-xs mb-2 font-medium">Email Address</p>
                      <input
                        type="email"
                        name="email"
                        value={partner.email || ""}
                        onChange={onChange}
                        required
                        className="w-full h-[40px] rounded-md border border-gray outline-none px-2 text-sm"
                      />
                    </div>
                    <div className="w-1/2 pl-2">
                      <p className="text-xs mb-2 font-medium">Phone Number</p>
                      <div className="flex h-[40px] border border-gray rounded-md items-center">
                        <input
                          list="extension"
                          name="mobile_extension"
                          onChange={onChange}
                          value={partner.mobile_extension || ""}
                          className="pl-1 w-[80px] border-r border-gray bg-white h-[30px] outline-none text-sm"
                        />
                        <datalist id="extension">
                          <option value="">Extension</option>
                          {countries.map((country, index) =>
                            country.codes
                              ? country.codes.map((code) => (
                                  <option
                                    value={code}
                                    key={`${index}-${code}`}
                                  >{`${country.name} - ${code}`}</option>
                                ))
                              : ""
                          )}
                        </datalist>
                        <input
                          type="number"
                          name="raw_mobile"
                          onChange={onChange}
                          value={partner.raw_mobile || ""}
                          className="px-2 w-full outline-none text-sm no-arrow"
                          placeholder="0803 000 0000"
                          required
                          autoComplete="new-password"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex mb-4">
                    <div className="w-1/2 pr-2">
                      <p className="text-xs mb-2 font-medium">Country</p>
                      <Select
                        name="country"
                        options={countryOptions}
                        value={partner.country || ""}
                        onChange={(value) =>
                          setPartner({ ...partner, country: value })
                        }
                      />
                    </div>
                    <div className="w-1/2 pl-2">
                      <p className="text-xs mb-2 font-medium">
                        Are you a WCA member?
                      </p>
                      <Select
                        name="wca_partner"
                        options={wcaOptions}
                        value={partner.wca_partner ?? ""}
                        onChange={(value) =>
                          setPartner({ ...partner, wca_partner: value })
                        }
                      />
                    </div>
                  </div>
                  {partner.wca_partner?.value ? (
                    <div className="flex mb-4">
                      <div className="w-1/2 pr-2">
                        <p className="text-xs mb-2 font-medium">
                          Your 6-digit WCA membership ID
                        </p>
                        <input
                          type="number"
                          name="wca_number"
                          value={partner.wca_number || ""}
                          onChange={onChange}
                          className="w-full h-[40px] rounded-md border border-gray outline-none px-2 text-sm"
                          required
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="w-full text-center mt-8">
                  <button
                    disabled={
                      !isPartnerFormValid ||
                      shipment.addingPartner ||
                      partnerStore.onboardingPartner
                    }
                    type="submit"
                    className="w-1/3 text-sm h-[50px] bg-[#005ECD] text-white items-center justify-center m-auto rounded-xl relative"
                  >
                    {shipment.addingPartner ||
                    partnerStore.onboardingPartner ? (
                      <Loading />
                    ) : (
                      "Become a partner"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeAPartner;
