import {
  AccessIcon,
  AffordableIcon,
  CXIcon,
  TechnologyIcon,
} from "assets/arts";
import gotoAddShipment from "helpers/gotoAddShipment";
import React from "react";

const WhyMVX = () => {
  return (
    <div className="border-b border-gray">
      <p className="text-center font-catalina mb-4 text-2xl sm:text-3xl w-4/5 m-auto sm:w-full">
        Here's why 600+ Companies Choose MVX
      </p>
      <div className="w-20 h-[3px] bg-mvx-blue m-auto mb-10"></div>
      <div className="flex flex-wrap sm:flex-nowrap justify-between w-[85%] m-auto mb-10">
        <div className="sm:w-[calc((100%-90px)/4)] w-full p-4 text-center shrink-0">
          <AccessIcon className="mb-4 m-auto" />
          <p className="text-sm mb-2 font-semibold">Access to global network</p>
          <p className="text-xs font-light text-gray-600">
            Our partnership with World Cargo Alliance (WCA) eliminates the
            headache of global shipping for businesses.
          </p>
        </div>
        <div className="sm:w-[calc((100%-90px)/4)] w-full p-4 text-center">
          <AffordableIcon className="mb-4 m-auto" />
          <p className="text-sm mb-2 font-semibold">
            Affordable freight shipping cost
          </p>
          <p className="text-xs font-light text-gray-600">
            Businesses that ship freight using MVX save up to 20%. In fact, when
            you book a shipment, you get flexible freight forwarding rates in
            Realtime.
          </p>
        </div>
        <div className="sm:w-[calc((100%-90px)/4)] w-full p-4 text-center">
          <TechnologyIcon className="mb-4 m-auto" />
          <p className="text-sm mb-2 font-semibold">Technology you can use</p>
          <p className="text-xs font-light text-gray-600">
            Our solutions are tailor made for your business needs. Book, track
            and collaborate from any device at your convenience.
          </p>
        </div>
        <div className="sm:w-[calc((100%-90px)/4)] w-full p-4 text-center">
          <CXIcon className="mb-4 m-auto" />
          <p className="text-sm mb-2 font-semibold">
            Superior customer experience
          </p>
          <p className="text-xs font-light text-gray-600">
            <span className="text-black font-normal">70% of our customers</span>{" "}
            say the most remarkable feature about our service is the experience.
            Our team is dedicated and ready to go the extra mile until your
            cargo has been safely delivered.
          </p>
        </div>
      </div>
      <div className="w-full text-center mb-20">
        <button
          type="button"
          onClick={gotoAddShipment}
          className="bg-mvx-blue2 text-white outline-0 rounded-xl h-[50px] px-10 text-sm m-auto"
        >
          Move Your Cargo
        </button>
      </div>
    </div>
  );
};

export default WhyMVX;
