import React from "react";

const ShipmentType = ({ shipment, onChange }) => {
  return (
    <>
      <p className="text-[13.5px] sm:text-xs font-bold mb-2 relative text-[#3E3E3E] after:content-['*'] after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500">
        What type of service are you looking for ?
      </p>
      <select
        required
        name="service_type"
        value={shipment.service_type || ""}
        onChange={onChange}
        className={`appearance-none border border-gray-200 bg-[#F5F5F5] mb-8 rounded-md p-3 outline-0 w-full h-12 text-sm ${
          !shipment.service_type ? "text-gray-400" : ""
        }`}
      >
        <option value="" className="text-gray">
          Select service
        </option>
        <option value="import">Import</option>
        <option value="export">Export</option>
        <option value="in-country haulage">In-Country Haulage</option>
        <option value="repair / return (export/re-import)">
          Repair / Return (Export/Re-import)
        </option>
      </select>
    </>
  );
};

export default ShipmentType;
