import EmptyNotification from "./EmptyNotification";
import Loading from "components/common/Loading";
import { useSelector, useDispatch } from "react-redux";
import { notificationsConstant } from "constants";
import { Notification } from "../common/Notifications";
import { notificationsActions } from "actions";
import CustomToast from "components/common/CustomToast";
import toast from "react-hot-toast";
import { useState, useEffect, useRef, useCallback } from "react";
import { authService } from "services";
import { currentENVData, truncateWord } from "helpers";
import { io } from "socket.io-client";
import { GoBackInfo } from "../common/MobileNav";
import { TickCircleIcon, ArchiveIcon } from "assets/arts";
import { useNavigate } from "react-router-dom";

const NotificationExpand = () => {
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [notification, setNotification] = useState([]);
  const [wasLastList, setWasLastList] = useState(false);
  const [notificationError, setNotificationError] = useState("");
  const [currentTab, setCurrentTab] = useState("all");
  const [showSuccessMessage, setShowSuccessMessage] = useState("");
  const [showMarkLoading, setShowMarkLoading] = useState(false);
  const navigate = useNavigate();

  const { toggleUpdateMobileNotificationModal } = useSelector(
    (state) => state.notifications
  );

  const socket = io(currentENVData.notificationsURL);
  // const socket = io();

  const dispatch = useDispatch();
  const observer = useRef();

  useEffect(() => {
    socket.on("connect", (socket) => {
      console.log("connected", socket);
    });

    socket.on("disconnect", (socket) => {
      console.log("Disconnect", socket);
    });

    socket.on("pong", (data) => {
      console.log(data, "pong detected");
      // toast.custom((t) => (
      //   <CustomToast t={t} message={`${error.message}!`} type="error" />
      // ));
    });

    socket.on("message", (data) => {
      console.log(data, "notified");
      // toast.custom((t) => (
      //   <CustomToast t={t} message={`${error.message}!`} type="error" />
      // ));
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("pong");
    };
  }, [socket]);

  // eslint-disable-next-line no-unused-vars
  const emitPing = () => {
    socket.emit("ping");
  };

  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage("");
      }, 3500);
    }
  }, [showSuccessMessage]);

  const { markAllNotificationsSuccess, showNotificationsResult } = useSelector(
    (state) => state.notifications
  );

  useEffect(() => {
    if (showNotificationsResult) {
      setTimeout(() => {
        dispatch({
          type: notificationsConstant.HIDE_NOTIFICATIONS_SUCCESS_MODAL,
        });
      }, 4000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotificationsResult]);

  // set all states to default
  const setStatesToDefault = () => {
    setCurrentPage(1);
    setPrevPage(0);
    setNotification([]);
    setWasLastList(false);
    setNotificationError("");
    setNotificationLoading(false);
  };

  const fetchAllNotifications = () => {
    setCurrentTab("all");
    setStatesToDefault();
  };

  const fetchUnreadNotifications = () => {
    setCurrentTab("unread");
    setStatesToDefault();
  };

  const fetchArchivedNotifications = () => {
    setCurrentTab("archived");
    setStatesToDefault();
  };

  // main function function that query API with known dates
  const queryUserNotifications = async (url) => {
    try {
      if (currentTab === "all") {
        url = `users/notifications?page=${currentPage}`;
      } else if (currentTab === "unread") {
        url = `users/notifications?page=${currentPage}&read=false`;
      } else if (currentTab === "archived") {
        url = `users/notifications?page=${currentPage}&archived=true`;
      }

      setNotificationLoading(true);

      const response = await authService.apiGate(
        `${currentENVData.transitURL}/${url}`,
        { method: "GET" }
      );

      setNotificationLoading(false);

      setPrevPage(currentPage);

      setNotification([...notification, ...response.data]);
      setNotificationError("");
      let totalNotifications;
      if (response.total_pages) {
        totalNotifications = response.total_pages;
      }

      if (currentPage === totalNotifications) {
        setWasLastList(true);
        setNotificationLoading(false);
        return;
      }
    } catch (error) {
      if (error.status === 404) {
        setNotificationLoading(false);
        setNotificationError(error.message);
      } else if (error.message) {
        setNotificationLoading(false);
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
      }
    }
  };

  useEffect(() => {
    if (!wasLastList && prevPage !== currentPage) {
      queryUserNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, wasLastList, prevPage, notification]);

  // get observer to implement infinite scroll
  const lastBookRef = useCallback(
    (node) => {
      if (notificationLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !wasLastList) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [wasLastList, notificationLoading]
  );

  const marKNotificationAsRead = async (notf) => {
    if (notf.read) {
      return;
    }

    try {
      setShowMarkLoading(true);

      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          read: true,
          activityId: notf._id,
        }),
      };
      const res = await authService.apiGate(
        `${currentENVData.transitURL}/users/notification/mark`,
        requestOptions
      );

      setNotification((prevState) =>
        prevState.map((notfic) => {
          if (notfic?._id === notf?._id) {
            return res.data;
          } else {
            return notfic;
          }
        })
      );
      setShowSuccessMessage(res.message);
      setShowMarkLoading(false);
    } catch (error) {
      setShowMarkLoading(false);
      toast.custom((t) => (
        <CustomToast t={t} message={`${error.message}!`} type="error" />
      ));
    }
  };

  const marKNotificationAsArchived = async (notf) => {
    if (notf.archived) {
      return;
    }

    try {
      setShowMarkLoading(true);

      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          archived: true,
          activityId: notf._id,
        }),
      };
      const res = await authService.apiGate(
        `${currentENVData.transitURL}/users/notification/archive`,
        requestOptions
      );

      setNotification((prevState) =>
        prevState.map((notfic) => {
          if (notfic?._id === notf?._id) {
            return res.data;
          } else {
            return notfic;
          }
        })
      );
      setShowSuccessMessage(res.message);
      setShowMarkLoading(false);
    } catch (error) {
      setShowMarkLoading(false);
      toast.custom((t) => (
        <CustomToast t={t} message={`${error.message}!`} type="error" />
      ));
    }
  };

  return (
    <>
      <GoBackInfo text="Back" navigateTo="dashboard/home" />
      {toggleUpdateMobileNotificationModal && (
        <MobileUpdateNotification
          markAsRead={(notf) => marKNotificationAsRead(notf)}
          markAsArchived={(notf) => marKNotificationAsArchived(notf)}
          showMarkLoading={showMarkLoading}
          showSuccessMessage={showSuccessMessage}
        />
      )}
      <div className="-ml-11 mt-6 sm:-mt-6 bg-white pt-6 pb-8 border border-gray -mr-20 h-[104%] pb-6">
        <p
          className="hidden sm:flex items-center text-xs cursor-pointer w-max sm:px-9"
          onClick={() => navigate(-1)}
        >
          <span className="material-icons mr-2 !text-base">
            keyboard_backspace
          </span>
          Close
        </p>
        <div className="flex justify-between items-end pr-5 sm:pr-11 mt-4 md:mt-0">
          <p className="font-bold text-xl px-5 sm:px-9">Notifications</p>
          {/* <button onClick={emitPing}>Emit Ping</button> */}
          <button
            className={`p-1.5 px-3 border border-gray-500 sm:border-gray-100 text-[#686868] text-[13px] font-medium rounded-md ${
              notification.length ? "cursor-pointer" : "cursor-auto"
            }`}
            onClick={() => {
              if (!notification.length) {
                return;
              } else {
                dispatch(notificationsActions.markAllNotificationsAsRead({}));
              }
            }}
          >
            Mark all as read
          </button>
        </div>
        <div className="mx-2 sm:mx-9 mt-6 sm:mt-10">
          <div className="w-full border-b border-gray-200 px-3 flex capitalize">
            <div
              className={`pb-2 px-4 cursor-pointer w-auto border-b-[3px] font-bold mr-6 text-[13px] ${
                currentTab === "all"
                  ? "border-mvx-blue text-mvx-blue"
                  : "text-[#AAA] border-white"
              }`}
              onClick={fetchAllNotifications}
            >
              <p className="flex gap-1.5 items-center">
                <span className="tracking-wide">all</span>
              </p>
            </div>
            <div
              className={`pb-2 px-4 cursor-pointer w-auto border-b-[3px] font-bold mr-6 text-[13px] ${
                currentTab === "unread"
                  ? "border-mvx-blue text-mvx-blue"
                  : "text-[#AAA] border-white"
              }`}
              onClick={fetchUnreadNotifications}
            >
              <p className="flex gap-1.5 items-center">
                <span className="tracking-wide">unread</span>

                {currentTab !== "archived" && (
                  <span
                    className={`w-4 h-4 bg-[#eee] text-[#999] text-[13px] font-bold flex items-center justify-center rounded-sm ${
                      notification?.filter((notf) => notf.read === false).length
                        ? "inline"
                        : "hidden"
                    }`}
                  >
                    {notification?.filter((notf) => notf.read === false).length}
                  </span>
                )}
              </p>
            </div>
            <div
              className={`pb-2 px-4 cursor-pointer w-auto border-b-[3px] font-bold mr-6 text-[13px] ${
                currentTab === "archived"
                  ? "border-mvx-blue text-mvx-blue"
                  : "text-[#AAA] border-white"
              }`}
              onClick={fetchArchivedNotifications}
            >
              <p className="flex gap-1.5 items-center">
                <span className="tracking-wide">archived</span>
              </p>
            </div>
          </div>
        </div>
        <div
          className={`mx-0 sm:mx-9 ${
            notification.length
              ? "h-[calc(100%-140px)]"
              : notificationError
              ? "h-[calc(100%-140px)]"
              : "h-2 "
          } overflow-y-auto flex items-center break-words justify-center overflow-x-hidden notfPage`}
        >
          {notificationError !== "" && !notification.length ? (
            <div className="flex items-center justify-center">
              <EmptyNotification />
            </div>
          ) : (
            <div className="h-[calc(100%-140px)] !h-full w-full">
              {notification?.map((notf, index) => {
                if (notification.length === index + 1) {
                  return (
                    <div ref={lastBookRef} key={notf._id}>
                      <Notification
                        content={notf.message}
                        type={notf.action_type}
                        read={notf.read}
                        archived={notf.archived}
                        time={notf.createdAt}
                        _id={notf._id}
                        markAsRead={() => marKNotificationAsRead(notf)}
                        markAsArchived={() => marKNotificationAsArchived(notf)}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={notf._id}>
                      <Notification
                        content={notf.message}
                        type={notf.action_type}
                        read={notf.read}
                        time={notf.createdAt}
                        _id={notf._id}
                        archived={notf.archived}
                        markAsRead={() => marKNotificationAsRead(notf)}
                        markAsArchived={() => marKNotificationAsArchived(notf)}
                      />
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
        {notificationLoading && (
          <div className="h-16 relative flex justify-center items-center">
            <Loading color="mvx-blue" size={7} />
          </div>
        )}
        {showMarkLoading && (
          <div className="h-16 relative flex justify-center items-center">
            <Loading color="mvx-blue" size={7} />
          </div>
        )}
        {showNotificationsResult && (
          <div className="h-16 relative ">
            <SuccessFulNotifications text={markAllNotificationsSuccess} />
          </div>
        )}
        {showSuccessMessage && (
          <div className="h-16 relative ">
            <SuccessFulNotifications text={showSuccessMessage} />
          </div>
        )}
      </div>
    </>
  );
};
export default NotificationExpand;

export const SuccessFulNotifications = ({ text }) => {
  return (
    <div className="border-y border-gray-100 mx-4 sm:mx-9 py-4 px-6">
      <p className="text-base sm:text-[15px] font-bold text-[#00db8f]">
        {text}
      </p>
    </div>
  );
};

const MobileUpdateNotification = ({
  markAsArchived,
  markAsRead,
  showMarkLoading,
  showSuccessMessage,
}) => {
  const {
    mobileNotificationToUpdate,
    markAllNotificationsSuccess,
    showNotificationsResult,
  } = useSelector((state) => state.notifications);

  const dispatch = useDispatch();
  const notificationToUpdate = () => {
    dispatch({
      type: notificationsConstant.MOBILE_NOTIFICATION_TO_UPDATE,
      payload: { toggleModal: false, notification: null },
    });
  };

  return (
    <div
      className="sm:hidden bg-black fixed inset-0 bg-opacity-40 z-[3000] flex items-end"
      onClick={notificationToUpdate}
    >
      <div
        className="bg-white rounded-t-md py-1.5 text-sm font-bold tracking-wide w-full h-[250px] flex flex-col gap-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="h-1.5 w-16 rounded-lg bg-[#e5e5e5] justify-self-center self-center mt-2"></div>
        <p className="px-8 font-bold text-base text-[#686868]">
          {truncateWord(mobileNotificationToUpdate.type, 3)}
        </p>
        <div className="">
          <p
            className={`py-3 px-8 cursor-pointer border-b border-grey-500  flex gap-3 items-center ${
              mobileNotificationToUpdate.archived
                ? "cursor-default"
                : "cursor-pointer hover:bg-[#e5e5e5] hover:text-[#333]"
            }`}
            // onClick={markAsArchived}
            onClick={() => markAsArchived(mobileNotificationToUpdate)}
          >
            <ArchiveIcon width="20px" height="20px" />
            <span>Archive message</span>
          </p>

          <p
            className={`py-3 px-8 cursor-pointer flex gap-3 items-center ${
              mobileNotificationToUpdate.read
                ? "cursor-default"
                : "cursor-pointer hover:bg-[#e5e5e5] hover:text-[#333]"
            }`}
            // onClick={markAsRead}
            onClick={() => markAsRead(mobileNotificationToUpdate)}
          >
            <TickCircleIcon width="20px" height="20px" />
            <span>Mark chat as read</span>
          </p>
        </div>
        {showMarkLoading && (
          <div className="h-16 relative flex justify-center items-center">
            <Loading color="mvx-blue" size={7} />
          </div>
        )}
        {showNotificationsResult && (
          <div className="h-16 relative ">
            <SuccessFulNotifications text={markAllNotificationsSuccess} />
          </div>
        )}
        {showSuccessMessage && (
          <div className="h-16 relative ">
            <SuccessFulNotifications text={showSuccessMessage} />
          </div>
        )}
      </div>
    </div>
  );
};
