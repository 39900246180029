import { Link } from "react-router-dom";
import styledComponents from "styled-components";

const KYC = styledComponents.div`
background-image: url(${(props) => props.url});
background-size: 100% auto;
background-repeat: no-repeat;
background-position: left bottom;
`;

const KYCDialog = ({ bgImg, dialogText, btnText }) => {
  return (
    <KYC
      url={bgImg}
      className="relative bg-[#32936F] py-8 sm:py-3 sm:px-6 rounded-xl mb-9 flex flex-col sm:flex-row gap-6 justify-between items-center border border-gray"
    >
      <p className="text-[14.5px] text-white py-0.5 max-w-[320px] sm:max-w-none sm:w-3/4 leading-[24px] text-center sm:text-start font-medium">
        {dialogText}
      </p>
      <div className="flex self-center items-center">
        <Link className="ml-10" to="/dashboard/profile-settings">
          <button className="bg-white px-6 shrink-0 py-3 rounded-[10px] text-gray flex items-center text-[15px] font-medium">
            {btnText}
          </button>
        </Link>
        <div className="absolute right-3 top-1 sm:right-auto sm:top-auto sm:relative h-10 w-10 flex items-center justify-center bg-transparent hover:bg-white/20 select-none cursor-pointer duration-300 ml-2 rounded-full">
          <span className="material-icons text-white">close</span>
        </div>
      </div>
    </KYC>
  );
};

export default KYCDialog;
