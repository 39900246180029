import { appActions } from "actions";
import { CalendarIcon, ChatIcon } from "assets/arts";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import countries from "variables/countries";
import CountryCodeSelect from "./CountryCodeSelect";
import Loading from "./Loading";

const ContactSales = () => {
  const app = useSelector((state) => state.app);
  const [contact, setContact] = useState({});
  const dispatch = useDispatch();

  const onChange = (e) => {
    const { name, value } = e.target;

    setContact((contact) => ({
      ...contact,
      [name]: value,
    }));
  };

  const sendContact = (e) => {
    e.preventDefault();

    dispatch(appActions.contact(contact));
  };

  useEffect(() => {
    if (!app.sendingMessage) {
      setContact((contact) => ({ extension: contact.extension }));
    }
  }, [app.sendingMessage]);

  return (
    <div className="flex flex-wrap sm:flex-nowrap items-center w-[85%] sm:w-4/5 m-auto my-28">
      <div className="w-full sm:w-1/2 mr-0 sm:mr-20 mb-10 sm:mb-0">
        <p className="font-bold text-xl sm:text-2xl mb-4">Contact Sales</p>
        <p className="text-xs font-light text-gray-600 mb-10">
          Interested to talk more about how{" "}
          <span className="font-bold text-black">MVX</span> can help you grow
          your business? We are happy to hear from you. Drop us your details and
          a member of our Sales Team will be in touch shortly.
        </p>
        <div
          id="my_custom_link"
          className="pt-4 pb-6 px-10 rounded-xl shadow-md mb-4 cursor-pointer"
        >
          <ChatIcon className="h-10 w-auto" />
          <p className="font-bold py-4">Start an instant chat</p>
          <p className="font-light text-xs">
            Start an instant chat with one of our support team members to get
            prompt assistance.
          </p>
          <div className="flex items-center text-mvx-blue font-bold text-sm cursor-pointer mt-4">
            Open Chat<span className="material-icons ml-2">arrow_upward</span>
          </div>
        </div>
        <div className="pt-4 pb-6 px-10 rounded-xl shadow-md">
          <CalendarIcon className="h-10 w-auto" />
          <p className="font-bold py-4">Schedule a call</p>
          <p className="font-light text-xs">
            Schedule a call with us and one of our experts would reach out to
            you shortly.
          </p>
          <div className="flex items-center text-mvx-blue font-bold text-sm cursor-pointer mt-4">
            Schedule on Calendly
            <span className="material-icons ml-2">arrow_upward</span>
          </div>
        </div>
      </div>
      <div className="w-full sm:w-1/2">
        <SalesForm
          onSubmit={sendContact}
          className="min-h-[400px] rounded-2xl pt-10 pb-6 px-6"
        >
          <div className="flex flex-wrap mb-6">
            <div className="w-1/2 shrink-0 pr-2">
              <input
                type="text"
                className="border border-gray-200 mb-4 rounded-md p-3 outline-0 w-full h-14 text-sm"
                required={true}
                name="first_name"
                value={contact.first_name || ""}
                placeholder="First Name"
                onChange={onChange}
              />
            </div>
            <div className="w-1/2 shrink-0 pl-2">
              <input
                type="text"
                className="border border-gray-200 mb-4 rounded-md p-3 outline-0 w-full h-14 text-sm"
                placeholder="Last Name"
                required={true}
                name="last_name"
                value={contact.last_name || ""}
                onChange={onChange}
              />
            </div>
            <div className="w-full shrink-0">
              <input
                type="text"
                className="border border-gray-200 mb-4 rounded-md p-3 outline-0 w-full h-14 text-sm"
                placeholder="Work Email"
                required={true}
                name="email"
                value={contact.email || ""}
                onChange={onChange}
              />
            </div>
            <div className="w-full shrink-0 h-14 border border-gray-200 mb-4 rounded-md bg-white flex items-center">
              <CountryCodeSelect
                name="extension"
                onChange={onChange}
                data={contact.extension}
                setData={setContact}
              />
              <div className="h-3/5 w-px bg-gray-300 ml-2"></div>
              <input
                type="text"
                className="py-3 pr-3 pl-4 outline-0 text-sm"
                placeholder="Phone Number"
                required={true}
                name="mobile"
                value={contact.mobile || ""}
                onChange={onChange}
              />
            </div>
            <div className="w-full shrink-0">
              <select
                required
                name="industry"
                value={contact.industry || ""}
                onChange={onChange}
                className={`appearance-none border border-gray-200 mb-4 rounded-md p-3 outline-0 w-full h-14 text-sm ${
                  !contact.industry || contact.industry === ""
                    ? "text-gray-400"
                    : ""
                }`}
              >
                <option value="" className="text-gray">
                  Industry
                </option>
                <option>Oil and Gas</option>
                <option>Power</option>
                <option>Construction</option>
                <option>Engineering</option>
                <option>Tech / IT</option>
                <option>FMCG</option>
                <option>Agriculture</option>
                <option>Manufacturing</option>
                <option>Pharmaceuticals</option>
                <option>Minerals / Metals</option>
                <option>Others</option>
              </select>
            </div>
            <div className="w-full shrink-0">
              <select
                required
                name="country"
                value={contact.country || ""}
                onChange={onChange}
                className={`appearance-none border border-gray-200 mb-4 rounded-md p-3 outline-0 w-full h-14 text-sm ${
                  !contact.country || contact.country === ""
                    ? "text-gray-400"
                    : ""
                }`}
              >
                <option value="" className="text-gray">
                  Country
                </option>
                {countries.map((country, index) => (
                  <option key={index}>{country.name}</option>
                ))}
              </select>
            </div>
            <div className="w-full shrink-0">
              <input
                type="text"
                className="border border-gray-200 mb-4 rounded-md p-3 outline-0 w-full h-14 text-sm"
                placeholder="Message"
                required={true}
                name="message"
                value={contact.message || ""}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="bg-[#1261BF] shadow-lg outline-0 rounded-lg w-1/2 h-12 text-white m-auto mb-6 relative"
            >
              <span className={`${app.sendingMessage && "invisible"}`}>
                Submit
              </span>
              {app.sendingMessage && <Loading />}
            </button>
          </div>
        </SalesForm>
      </div>
    </div>
  );
};

const SalesForm = styled.form`
  background-color: rgba(239, 239, 239, 0.8);
`;

export default ContactSales;
