import React from "react";
import ContactSales from "components/common/ContactSales";
import FAQs from "components/common/FAQ";
import Footer from "components/common/Footer";
import Hero from "./Hero";

const Contact = () => {
  return (
    <>
      <Hero/>
      <ContactSales />
      <FAQs />
      <Footer />
    </>
  );
};

export default Contact;
