import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Menu from "./Menu";

const MenuWithChild = ({ route }) => {
  const location = useLocation();
  const [menu, showMenu] = useState(null);

  return (
    <div
      onMouseOver={() => showMenu(true)}
      onMouseOut={() => showMenu(null)}
      className="px-4 flex items-center cursor-pointer no-select relative"
    >
      <span
        className={`${
          route.children.find((r) => location.pathname === r.path) && "font-bold"
        }`}
      >
        {route.name}
      </span>
      {menu && <Menu children={route.children} showMenu={showMenu} />}
    </div>
  );
};

export default MenuWithChild;
