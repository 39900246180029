export const financeConstant = {
  SAVE_MONO_ID_REQUEST: "SAVE_MONO_ID_REQUEST",
  SAVE_MONO_ID_SUCCESS: "SAVE_MONO_ID_SUCCESS",
  SAVE_MONO_ID_FAILURE: "SAVE_MONO_ID_FAILURE",
  FINANCE_PROFILE_REQUEST: "FINANCE_PROFILE_REQUEST",
  FINANCE_PROFILE_SUCCESS: "FINANCE_PROFILE_SUCCESS",
  FINANCE_PROFILE_FAILURE: "FINANCE_PROFILE_FAILURE",
  FINANCE_REQUESTS_REQUEST: "FINANCE_REQUESTS_REQUEST",
  FINANCE_REQUESTS_SUCCESS: "FINANCE_REQUESTS_SUCCESS",
  FINANCE_REQUESTS_FAILURE: "FINANCE_REQUESTS_FAILURE",
  CREATE_FINANCE_REQUEST: "CREATE_FINANCE_REQUEST",
  CREATE_FINANCE_SUCCESS: "CREATE_FINANCE_SUCCESS",
  CREATE_FINANCE_FAILURE: "CREATE_FINANCE_FAILURE",
  VERIFY_BVN_REQUEST: "VERIFY_BVN_REQUEST",
  VERIFY_BVN_SUCCESS: "VERIFY_BVN_SUCCESS",
  VERIFY_BVN_FAILURE: "VERIFY_BVN_FAILURE",
  VERIFY_CAC_REQUEST: "VERIFY_CAC_REQUEST",
  VERIFY_CAC_SUCCESS: "VERIFY_CAC_SUCCESS",
  VERIFY_CAC_FAILURE: "VERIFY_CAC_FAILURE",
  UPDATE_FINANCE_PROFILE_REQUEST: "UPDATE_FINANCE_PROFILE_REQUEST",
  UPDATE_FINANCE_PROFILE_SUCCESS: "UPDATE_FINANCE_PROFILE_SUCCESS",
  UPDATE_FINANCE_PROFILE_FAILURE: "UPDATE_FINANCE_PROFILE_FAILURE",
  FETCH_FINANCE_REQS_REQUEST: "FETCH_FINANCE_REQS_REQUEST",
  FETCH_FINANCE_REQS_SUCCESS: "FETCH_FINANCE_REQS_SUCCESS",
  FETCH_FINANCE_REQS_FAILURE: "FETCH_FINANCE_REQS_FAILURE",
  UPDATE_FINANCE_PROFILEIMAGE_REQUEST: "UPDATE_FINANCE_PROFILEIMAGE_REQUEST",
  UPDATE_FINANCE_PROFILEIMAGE_SUCCESS: "UPDATE_FINANCE_PROFILEIMAGE_SUCCESS",
  UPDATE_FINANCE_PROFILEIMAGE_FAILURE: "UPDATE_FINANCE_PROFILEIMAGE_FAILURE",
  UPLOAD_CACDOC_REQUEST: "UPLOAD_CACDOC_REQUEST",
  UPLOAD_CACDOC_SUCCESS: "UPLOAD_CACDOC_SUCCESS",
  UPLOAD_CACDOC_ERROR: "UPLOAD_CACDOC_ERROR",
  UPLOAD_MOADOC_REQUEST: "UPLOAD_MOADOC_REQUEST",
  UPLOAD_MOADOC_SUCCESS: "UPLOAD_MOADOC_SUCCESS",
  UPLOAD_MOADOC_ERROR: "UPLOAD_MOADOC_ERROR",
  UPLOAD_AUDITDOC_REQUEST: "UPLOAD_AUDITDOC_REQUEST",
  UPLOAD_AUDITDOC_SUCCESS: "UPLOAD_AUDITDOC_SUCCESS",
  UPLOAD_AUDITDOC_ERROR: "UPLOAD_AUDITDOC_ERROR",
  UPLOAD_DIRECTORID_REQUEST: "UPLOAD_DIRECTORID_REQUEST",
  UPLOAD_DIRECTORID_SUCCESS: "UPLOAD_DIRECTORID_SUCCESS",
  UPLOAD_DIRECTORID_ERROR: "UPLOAD_DIRECTORID_ERROR",
  UPLOAD_NEPCDOC_REQUEST: "UPLOAD_NEPCDOC_REQUEST",
  UPLOAD_NEPCDOC_SUCCESS: "UPLOAD_NEPCDOC_SUCCESS",
  UPLOAD_NEPCDOC_ERROR: "UPLOAD_NEPCDOC_ERROR",
  SET_FINANCE_REQ_STARTED: "SET_FINANCE_REQ_STARTED",
  SHIPMENT_TO_REQUEST_FINANCE: "SHIPMENT_TO_REQUEST_FINANCE",
  SHIPMENT_TO_PREVIEW: "SHIPMENT_TO_PREVIEW",
  IS_FINAL_SUCCESS_REQUEST: "IS_FINAL_SUCCESS_REQUEST",
  IS_FINAL_SUCCESS_REQUEST_DONE: "IS_FINAL_SUCCESS_REQUEST_DONE",
};
