export const appConstants = {
  CONTACT_REQUEST: "CONTACT_MVX_REQUEST",
  CONTACT_SUCCESS: "CONTACT_MVX_SUCCESS",
  CONTACT_FAILURE: "CONTACT_MVX_FAILURE",
  QUOTE_REQUEST: "QUOTE_MVX_REQUEST",
  QUOTE_SUCCESS: "QUOTE_MVX_SUCCESS",
  QUOTE_FAILURE: "QUOTE_MVX_FAILURE",
  BECOME_AGENT_REQUEST: "BECOME_AGENT_MVX_REQUEST",
  BECOME_AGENT_SUCCESS: "BECOME_AGENT_MVX_SUCCESS",
  BECOME_AGENT_FAILURE: "BECOME_AGENT_MVX_FAILURE",
  ARTICLES_REQUEST: "ARTICLES_MVX_REQUEST",
  ARTICLES_SUCCESS: "ARTICLES_MVX_SUCCESS",
  ARTICLES_FAILURE: "ARTICLES_MVX_FAILURE",
  LOCATION_REQUEST: "MVX_USER_LOCATION_REQUEST",
  LOCATION_SUCCESS: "MVX_USER_LOCATION_SUCCESS",
  LOCATION_FAILURE: "MVX_USER_LOCATION_FAILURE",
  TOGGLE_NAV_MODAL: "TOGGLE_NAV_MODAL",
};
