import { shipmentActions } from "actions";
import { PackageArrowIcon } from "assets/arts";
import Ellipsis from "components/common/Ellipsis";
import Skeleton from "components/common/Skeleton";
import { GoBackInfo } from "components/Dashboard/common/MobileNav";
import RandomAddLogo from "components/Dashboard/common/RandomAddLogo";
import { getShipmentStatus } from "helpers/getShipmentStatus";
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Movements from "./Movements";
import Quotes from "./Quotes";
import Status from "./Status";

const Shipment = () => {
  const [packageClicked, setPackageClicked] = useState("0");
  const dispatch = useDispatch();
  const { selectedShipmentDetails, selectedShipmentDetailsLoading: loading } =
    useSelector((state) => state.shipments);
  const isContainerType =
    selectedShipmentDetails?.package_type === "containers";
  const params = useParams();
  const navigate = useNavigate();

  const shipmentStatus = getShipmentStatus(
    selectedShipmentDetails?.currentStatus ||
      selectedShipmentDetails?.status ||
      ""
  );

  const handlePackageToggle = (index) => {
    if (packageClicked === index) {
      return setPackageClicked("0");
    }
    setPackageClicked(index);
  };

  const updateURL = (nestedPath = "") =>
    navigate(`/dashboard/shipment/${params?.shipmentId}/${nestedPath}`);

  useEffect(() => {
    document.querySelector(".shipment-details").scrollIntoView();

    if (!params?.path || params?.path === "status") {
      dispatch(shipmentActions.fetchSingleShipment(params?.shipmentId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (params?.path === "movements") {
      if (params?.shipmentId !== selectedShipmentDetails?._id) {
        dispatch(shipmentActions.fetchSingleShipment(params?.shipmentId));
      }
    } else {
      if (Object.keys(selectedShipmentDetails).length === 0) {
        dispatch(shipmentActions.fetchSingleShipment(params?.shipmentId));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, selectedShipmentDetails]);

  return (
    <>
      <GoBackInfo text="Shipments" navigateTo="dashboard/shipments" />
      <RandomAddLogo />
      <div className="-ml-4 -mr-14 sm:ml-0 sm:mt-3 md:mt-0 sm:-mr-10 md:-mr-8 lg:mr-0">
        <div className="mt-16 sm:mt-0"></div>
        <p
          className="hidden sm:flex items-center text-xs cursor-pointer w-max pb-2"
          onClick={() => navigate(-1)}
        >
          <span className="material-icons mr-2 !text-base">
            keyboard_backspace
          </span>
          Close
        </p>
        <p className="text-sm flex items-center shipment-details">
          Shipments
          <span className="material-icons white-color">
            keyboard_arrow_right
          </span>
          <span className="font-bold text-mvx-blue">
            {selectedShipmentDetails?.mvxid
              ? `MVXT${selectedShipmentDetails?.mvxid}`
              : ""}
          </span>
        </p>
        <div className="bg-[#E4EBFD] rounded-xl py-8 px-4 md:px-8 lg:px-12 mt-6 flex gap-2 mb-4">
          <div className="flex flex-col sm:flex-row mb-2 gap-2 w-8/12 md:w-9/12">
            <div className="w-full md:w-1/2 space-y-1">
              <div className="flex items-center shrink-0">
                <span className="material-icons text-sm mr-1 text-[#FFA80E]">
                  location_on
                </span>
                <p className="text-sm font-bold whitespace-nowrap">Ship From</p>
                <div className="hidden w-full sm:flex h-[3px] ml-2 mr-2">
                  <div className="w-full h-[3px] bg-mvx-blue mr-2"></div>
                  <Ellipsis width={"10px"} />
                  <Ellipsis width={"10px"} />
                  <Ellipsis width={"4px"} />
                </div>
              </div>
              <div className="w-10/12 md:w-9/12 text-[12.5px] sm:text-sm font-light leading-5 pl-1">
                <Skeleton
                  dataLoaded={!loading}
                  className="mt-1 w-full h-[18px] !rounded-[6px] !bg-[#688EF6]/10"
                >
                  {selectedShipmentDetails?.origin?.address}
                </Skeleton>
                {loading && (
                  <Skeleton
                    dataLoaded={!loading}
                    className="mt-[9px] w-36 h-[18px] !rounded-[6px] !bg-[#688EF6]/10"
                  />
                )}
              </div>
            </div>
            <div className="sm:hidden w-[3px] ml-2 h-[30px] mb-3">
              <div className="w-full h-full bg-mvx-blue"></div>
              <Ellipsis width={"10px"} />
              <Ellipsis width={"10px"} />
              <Ellipsis width={"4px"} />
            </div>
            <div className="w-full md:w-1/2 space-y-1">
              <div className="flex items-center shrink-0">
                <span className="material-icons text-sm mr-1 text-[#AF00D4]">
                  location_on
                </span>
                <p className="text-sm font-bold whitespace-nowrap">Ship To</p>
              </div>

              <div className="w-10/12 md:w-9/12 text-[12.5px] sm:text-sm font-light leading-5 pl-1">
                <Skeleton
                  dataLoaded={!loading}
                  className="mt-1 w-full h-[18px] !rounded-[6px] !bg-[#688EF6]/10"
                >
                  {selectedShipmentDetails?.destination?.address}
                </Skeleton>
                {loading && (
                  <Skeleton
                    dataLoaded={!loading}
                    className="mt-[9px] w-36 h-[18px] !rounded-[6px] !bg-[#688EF6]/10"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col text-[13px] md:text-sm shrink-0 w-4/12 md:w-3/12 items-end md:items-start">
            <p className="pl-2 md:pl-0 font-bold mb-2 whitespace-nowrap">
              Shipment Status
            </p>
            <button
              className={`${
                shipmentStatus === "active"
                  ? "bg-[#49D33D]/[0.18] text-[#40A81B]"
                  : shipmentStatus === "completed"
                  ? "bg-[#1152FB]/[0.07] text-[#1152FB]"
                  : shipmentStatus === "cancelled"
                  ? "bg-[#FF0000]/[0.08] text-[#FF0000]"
                  : shipmentStatus === "pending"
                  ? "bg-[#FF8A00]/[0.08] text-[#FF8A00]"
                  : ""
              } font-bold px-4 mf:px-6 py-1.5 rounded-full mb-2 capitalize`}
            >
              <Skeleton
                dataLoaded={!loading}
                className={`mt-1 w-[70px] h-[18px] !rounded-[6px] !bg-black/5 ${
                  shipmentStatus === "active"
                    ? "bg-[#49D33D]/10"
                    : shipmentStatus === "completed"
                    ? "bg-[#1152FB]/10"
                    : shipmentStatus === "cancelled"
                    ? "bg-[#FF0000]/10"
                    : shipmentStatus === "pending"
                    ? "bg-[#FF8A00]/10"
                    : ""
                } mb-1`}
              >
                {shipmentStatus}
              </Skeleton>
            </button>
          </div>
        </div>
        <div className="relative font-medium">
          <div
            className={`shipmentInstanceTabs py-3 bg-transparent md:bg-[#EFEFEF] flex gap-3 md:gap-4 absolute w-full md:w-[calc(100%+5.5rem)] lg:w-[calc(100%+7.5rem)] md:-left-10 md:pl-10 overflow-auto ${
              !loading ? "pointer-events-auto" : "pointer-events-none"
            }`}
          >
            <Tab
              name="Details"
              setURL={() => updateURL()}
              isCurrentPath={!params?.path}
            />
            <Tab
              name="Quote"
              setURL={() => updateURL("quotes")}
              isCurrentPath={params?.path === "quotes"}
            />
            <Tab
              name="Status Update"
              setURL={() => updateURL("status")}
              isCurrentPath={params?.path === "status"}
            />
            <Tab
              name="Movements"
              setURL={() => updateURL("movements")}
              isCurrentPath={params?.path === "movements"}
            />
            {/* <Tab
              name="Messages"
              setURL={() => updateURL("messages")}
              isCurrentPath={params?.path === "messages"}
            /> */}
          </div>
        </div>

        {!params?.path && (
          <div className="my-[100px] md:my-[120px] flex flex-col md:flex-row gap-6 lg:gap-8">
            <table className="bg-[#FBFBFB] inline-block border border-[#E9E9E9] rounded-2xl max-w-[440px]">
              <thead>
                <tr>
                  <th
                    className="p-6 px-9 text-[13.5px] text-left text-mvx-blue md:text-[#333]"
                    colSpan="2"
                  >
                    Shipment Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="font-medium text-[12.5px] border-[#E9E9E9] border-t">
                  <td className="py-6 pl-8 md:pl-6 lg:pl-9 w-[220px] relative before:absolute before:h-2 before:w-2 before:bg-mvx-blue before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                    <span className="ml-[14px]">Service Type</span>
                  </td>
                  <td className="py-6 pl-8 md:pl-6 lg:pl-9 w-[220px] border-l border-[#E9E9E9] capitalize">
                    <Skeleton
                      dataLoaded={!loading}
                      className="mt-1 w-28 h-[17px] !rounded-[5px] !bg-black/5"
                    >
                      {selectedShipmentDetails?.service_type}
                    </Skeleton>
                  </td>
                </tr>
                <tr className="font-medium text-[12.5px] border-[#E9E9E9] border-t">
                  <td className="py-6 pl-8 md:pl-6 lg:pl-9 w-[220px] relative before:absolute before:h-2 before:w-2 before:bg-mvx-blue before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                    <span className="ml-[14px]">Service Mode</span>
                  </td>
                  <td className="py-6 pl-9 w-[220px] border-l border-[#E9E9E9] capitalize">
                    <Skeleton
                      dataLoaded={!loading}
                      className="mt-1 w-28 h-[17px] !rounded-[5px] !bg-black/5"
                    >
                      {selectedShipmentDetails?.service_mode}
                    </Skeleton>
                  </td>
                </tr>
                <tr className="font-medium text-[12.5px] border-[#E9E9E9] border-t">
                  <td className="py-6 pl-8 md:pl-6 lg:pl-9 w-[220px] relative before:absolute before:h-2 before:w-2 before:bg-mvx-blue before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                    <span className="ml-[14px]">Shipping Type</span>
                  </td>
                  <td className="py-6 pl-8 md:pl-6 lg:pl-9 w-[220px] border-l border-[#E9E9E9] capitalize">
                    <Skeleton
                      dataLoaded={!loading}
                      className="mt-1 w-28 h-[17px] !rounded-[5px] !bg-black/5"
                    >
                      {selectedShipmentDetails?.package_type}
                    </Skeleton>
                  </td>
                </tr>
                <tr className="font-medium text-[12.5px] border-[#E9E9E9] border-t">
                  <td className="py-6 pl-8 md:pl-6 lg:pl-9 w-[220px] relative before:absolute before:h-2 before:w-2 before:bg-mvx-blue before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                    <span className="ml-[14px]">Incoterms</span>
                  </td>
                  <td className="py-6 pl-8 md:pl-6 lg:pl-9 w-[220px] border-l border-[#E9E9E9]">
                    <Skeleton
                      dataLoaded={!loading}
                      className="mt-1 w-28 h-[17px] !rounded-[5px] !bg-black/5"
                    >
                      {selectedShipmentDetails?.incoterms ?? "None"}
                    </Skeleton>
                  </td>
                </tr>
                <tr className="font-medium text-[12.5px] border-[#E9E9E9] border-t">
                  <td className="py-6 pl-8 md:pl-6 lg:pl-9 w-[220px] relative before:absolute before:h-2 before:w-2 before:bg-mvx-blue before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                    <span className="ml-[14px]">Date Created</span>
                  </td>
                  <td className="py-6 pl-8 md:pl-6 lg:pl-9 w-[220px] border-l border-[#E9E9E9]">
                    <Skeleton
                      dataLoaded={!loading}
                      className="mt-1 w-28 h-[17px] !rounded-[5px] !bg-black/5"
                    >
                      {moment(selectedShipmentDetails?.createdAt).format(
                        "DD-MM-YYYY"
                      )}
                    </Skeleton>
                  </td>
                </tr>
                <tr className="font-medium text-[12.5px] border-[#E9E9E9] border-t">
                  <td className="py-6 pl-8 md:pl-6 lg:pl-9 w-[220px] relative before:absolute before:h-2 before:w-2 before:bg-mvx-blue before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                    <span className="ml-[14px]">Estimated Delivery Date</span>
                  </td>
                  <td className="py-6 pl-8 md:pl-6 lg:pl-9 w-[220px] border-l border-[#E9E9E9]">
                    <Skeleton
                      dataLoaded={!loading}
                      className="mt-1 w-28 h-[17px] !rounded-[5px] !bg-black/5"
                    >
                      {moment(selectedShipmentDetails?.receivedTime).format(
                        "DD-MM-YYYY"
                      )}
                    </Skeleton>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="bg-[#FBFBFB] inline-block border border-[#E9E9E9] rounded-2xl w-full max-w-[440px] md:max-w-none lg:w-7/12 xl:w-6/12 h-max overflow-hidden">
              <p className="p-6 px-9 pb-4 text-[13.5px] text-left font-bold text-mvx-blue md:text-[#333]">
                All Packages (
                {isContainerType
                  ? (!loading &&
                      selectedShipmentDetails?.containers_size?.length) ||
                    0
                  : (!loading && selectedShipmentDetails?.packages?.length) ||
                    0}
                )
              </p>

              {isContainerType
                ? !loading &&
                  selectedShipmentDetails?.containers_size?.map((pkg, idx) => (
                    <Package
                      key={idx}
                      pkg={pkg}
                      active={packageClicked === idx}
                      onToggle={() => handlePackageToggle(idx)}
                      isContainerType={true}
                      index={idx}
                    />
                  ))
                : !loading &&
                  selectedShipmentDetails?.packages?.map((pkg, idx) => (
                    <Package
                      key={idx}
                      pkg={pkg}
                      active={packageClicked === idx}
                      onToggle={() => handlePackageToggle(idx)}
                      isContainerType={false}
                      index={idx}
                    />
                  ))}
            </div>
          </div>
        )}
        {params?.path === "quotes" && <Quotes />}
        {params?.path === "status" && <Status />}
        {params?.path === "movements" && <Movements />}
      </div>
    </>
  );
};

const Tab = ({ name, isCurrentPath, setURL }) => {
  return (
    <div
      className={`px-6 md:px-10 py-3 text-[13.5px] md:text-sm duration-200 rounded-full cursor-pointer select-none whitespace-nowrap ${
        isCurrentPath ? "bg-mvx-blue text-white !font-bold" : ""
      }`}
      onClick={setURL}
    >
      {name}
    </div>
  );
};

const Package = ({ pkg, active, onToggle, isContainerType, index }) => {
  const packagesElm = useRef();

  return (
    <div className="text-[12.5px] font-medium">
      <p
        className="font-bold py-6 px-9 flex justify-between cursor-pointer"
        onClick={onToggle}
      >
        <span>Package {index + 1}</span>
        <PackageArrowIcon
          className={`h-5 w-5 transition-all duration-300 ${
            active ? "[&>*]:!fill-mvx-blue" : "rotate-180"
          } [&>*]:fill-black`}
        />
      </p>
      <div
        className={`border-t border-[#E9E9E9] transition-all duration-300 ${
          active
            ? `h-[${packagesElm?.current?.scrollHeight}] border-b`
            : "h-0 overflow-hidden"
        }`}
        ref={packagesElm}
      >
        <div className="[&>*]:pb-6 [&>*:last-of-type]:pb-0 py-6 px-9">
          <p className="flex justify-between relative before:absolute before:h-2 before:w-2 before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
            <span className="ml-[14px]">Container</span>
            <span>{isContainerType ? "Yes" : "No"}</span>
          </p>
          {isContainerType ? (
            <>
              <p className="flex justify-between relative before:absolute before:h-2 before:w-2 before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                <span className="ml-[14px]">Length</span>
                <span>
                  {pkg?.value} {pkg?.unit}
                </span>
              </p>
              <p className="flex justify-between capitalize relative before:absolute before:h-2 before:w-2 before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                <span className="ml-[14px]">Type</span>
                <span>{pkg?.package_type}</span>
              </p>
              <p className="flex justify-between capitalize relative before:absolute before:h-2 before:w-2 before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                <span className="ml-[14px]">Quantity</span>
                <span>{pkg?.quantity}</span>
              </p>
            </>
          ) : (
            <>
              <p className="flex justify-between relative before:absolute before:h-2 before:w-2 before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                <span className="ml-[14px]">Length</span>
                <span>
                  {pkg?.length?.value} {pkg?.length?.unit}
                </span>
              </p>
              <p className="flex justify-between relative before:absolute before:h-2 before:w-2 before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                <span className="ml-[14px]">Width</span>
                <span>
                  {pkg?.width?.value} {pkg?.width?.unit}
                </span>
              </p>
              <p className="flex justify-between relative before:absolute before:h-2 before:w-2 before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                <span className="ml-[14px]">Weight</span>
                <span>
                  {pkg?.weight?.value} {pkg?.weight?.unit}
                </span>
              </p>
              <p className="flex justify-between capitalize relative before:absolute before:h-2 before:w-2 before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                <span className="ml-[14px]">Type</span>
                <span>{pkg?.package_type}</span>
              </p>
              <p className="flex justify-between capitalize relative before:absolute before:h-2 before:w-2 before:bg-[#C1C7D0] before:rounded-full before:translate-y-[5px]">
                <span className="ml-[14px]">Units</span>
                <span>{pkg?.units}</span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Shipment;
