import CustomToast from "components/common/CustomToast";
import { notificationsConstant } from "constants";
import toast from "react-hot-toast";
import { notificationsService } from "services";

export const notificationsActions = {
  getNotifications,
  markAllNotificationsAsRead,
};

function getNotifications(pages, tab) {
  return (dispatch) => {
    dispatch(request(notificationsConstant.NOTIFICATIONS_REQUEST));
    notificationsService.getNotifications(pages, tab).then(
      (res) => {
        dispatch(success(notificationsConstant.NOTIFICATIONS_SUCCESS, res));
      },
      (error) => {
        if (error.status !== 404 && error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
          dispatch(
            failure(
              notificationsConstant.NOTIFICATIONS_FAILURE_INFO,
              error.message
            )
          );
        } else {
          dispatch(
            failure(notificationsConstant.NOTIFICATIONS_FAILURE, error.message)
          );
        }
      }
    );
  };
}

function markAllNotificationsAsRead() {
  return (dispatch) => {
    dispatch(request(notificationsConstant.MARK_ALL_NOTIFICATIONS_REQUEST));
    notificationsService.markAllNotificationsAsRead().then(
      (res) => {
        dispatch(
          success(notificationsConstant.MARK_ALL_NOTIFICATIONS_SUCCESS, res)
        );
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
        }
      }
    );
  };
}

function request(type) {
  return { type: type };
}
function success(type, data) {
  return { type: type, payload: data };
}
function failure(type, error) {
  return { type: type, payload: error };
}
