import React, { useState } from "react";
import styled from "styled-components";
import CountryCodeSelect from "components/common/CountryCodeSelect";
import { appActions } from "actions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/common/Loading";
import { LightBulbIcon } from "assets/arts";
import { useEffect } from "react";

const HowItWorks = () => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const [quote, setQuote] = useState({});

  const onChange = (e, parent, val, child) => {
    const { name, value } = e.target;

    if (parent) {
      setQuote((q) => ({
        ...q,
        [parent]: {
          ...q[parent],
          [child || name]: val || value,
        },
      }));
    } else {
      setQuote((quote) => ({
        ...quote,
        [name]: value,
      }));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(appActions.becomeAgent(quote));
  };

  useEffect(() => {
    if (!app.becomingAgent && app.agent) {
      setQuote({});
    }
    // eslint-disable-next-line
  }, [app.becomingAgent]);

  return (
    <div className="px-4">
      <div className="pt-14 sm:pt-24 bg-mvx-orange/[0.06] pb-10 sm:pb-20 rounded-b-lg">
        <div className="w-[90%] sm:w-[80%] m-auto">
          <div className="flex flex-wrap sm:flex-nowrap">
            <p className="mb-10 sm:mb-0 text-5xl sm:text-7xl font-bold mr-20 shrink-0 whitespace-nowrap">
              How it
              <br />
              works
            </p>
            <div>
              <p className="text-sm sm:ml-[5%] sm:ml-0 sm:pr-[calc(5%+1rem)] sm:pr-0">
                MVX is a tech-powered freight forwarding and freight finance
                company that primarily serves businesses in Africa.
              </p>
              <br className="hidden sm:block" />
              <p className="text-sm sm:ml-[5%] sm:ml-0 sm:pr-[calc(5%+1rem)] sm:pr-0 mt-4 mb-8">
                Become an MVX Agent and you can earn up to ₦500,000 every month,
                in referral bonuses. All you need to do is refer businesses that
                ship goods around the world. You get 30% of our profit on the
                first shipment.
              </p>
              <button
                type="button"
                className={`rounded-lg bg-mvx-blue2 shadow-lg outline-0 w-full sm:w-[200px] h-[50px] text-white text-sm font-bold flex items-center justify-center`}
              >
                Get Started
                <span className="material-icons white-color">
                  keyboard_arrow_right
                </span>
              </button>
            </div>
          </div>
          <div className="w-full flex flex-wrap sm:flex-nowrap pt-20">
            <AgentForm
              onSubmit={onSubmit}
              className="w-full sm:w-3/5 min-h-[400px] rounded-xl mr-0 sm:mr-6 p-6"
            >
              <p className="font-bold mb-6">Become an MVX Agent</p>
              <div className="flex flex-wrap mb-6">
                <div className="w-full sm:w-1/2 shrink-0 pr-0 sm:pr-2">
                  <p className="text-xs mb-2 font-bold">Full Name</p>
                  <input
                    type="text"
                    className="border border-gray-200 mb-4 rounded-lg p-3 outline-0 w-full h-12 text-sm"
                    placeholder="Enter Your Full Name"
                    required={true}
                    name="name"
                    onChange={onChange}
                    value={quote.name || ""}
                  />
                </div>
                <div className="w-full sm:w-1/2 shrink-0 pr-0 sm:pl-2">
                  <p className="text-xs mb-2 font-bold">Phone Number</p>
                  <div className="w-full shrink-0 h-12 border border-gray-200 rounded-md bg-white flex items-center">
                    <CountryCodeSelect
                      name="mobile_extension"
                      onChange={onChange}
                      data={quote.mobile_extension}
                      setData={setQuote}
                    />
                    <div className="h-3/5 w-px bg-gray-300"></div>
                    <input
                      type="text"
                      className="py-3 pr-3 pl-2 outline-0 text-sm w-full"
                      placeholder="8000000000"
                      required={true}
                      name="raw_mobile"
                      value={quote.raw_mobile || ""}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="w-full sm:w-1/2 shrink-0 pr-0 sm:pr-2">
                  <p className="text-xs mb-2 font-bold">Email Address</p>
                  <input
                    type="email"
                    className="border border-gray-200 mb-4 rounded-lg p-3 outline-0 w-full h-12 text-sm"
                    placeholder="Enter Your Email Address"
                    required={true}
                    name="email"
                    onChange={onChange}
                    value={quote.email || ""}
                  />
                </div>
                <div className="w-full sm:w-1/2 shrink-0 pr-0 sm:pl-2">
                  <p className="text-xs mb-2 font-bold">Occupation</p>
                  <input
                    type="occupation"
                    className="border border-gray-200 mb-4 rounded-lg p-3 outline-0 w-full h-12 text-sm"
                    placeholder="Tell us What you do"
                    required={true}
                    name="occupation"
                    onChange={onChange}
                    value={quote.occupation || ""}
                  />
                </div>
                <div className="w-full shrink-0 pr-0">
                  <p className="text-xs mb-2 font-bold">
                    Where did you Hear About us?
                  </p>
                  <select
                    required
                    name="source"
                    value={quote.source || ""}
                    onChange={onChange}
                    className={`appearance-none border border-gray-200 mb-4 rounded-md p-3 outline-0 w-full h-12 text-sm ${
                      !quote.source || quote.source === ""
                        ? "text-gray-400"
                        : ""
                    }`}
                  >
                    <option value="">Select Option</option>
                    <option value="word of mouth">Word of Mouth</option>
                    <option value="social media">Social Media</option>
                    <option value="google search">Google Search</option>
                    <option value="internet ad">Internet ad</option>
                    <option value="others">Others</option>
                  </select>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="bg-[#1261BF] shadow-lg outline-0 rounded-lg w-full sm:w-1/2 h-12 text-white m-auto mb-6 relative"
                >
                  <span className={`${app.becomingAgent && "invisible"}`}>
                    Become an Agent
                  </span>
                  {app.becomingAgent && <Loading />}
                </button>
              </div>
            </AgentForm>
            <div className="w-full sm:w-2/5 shrink-0 flex flex-col">
              <Graphic
                url={require("assets/images/agents/referral-graphic.png")}
                className="w-full h-full bg-black rounded-xl mb-6 shrink-1"
              ></Graphic>
              <div className="w-full border border-[#FF8A00] bg-[#FF8A00]/[0.15] rounded-xl p-6">
                <div className="flex items-center mb-2">
                  <LightBulbIcon className="mr-4" />
                  <p className="font-bold">Important Note</p>
                </div>
                <p className="text-sm">
                  It is very important to note that if the referred business
                  hasn’t paid for their shipment, the referral isn’t valid, so
                  we can’t pay you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AgentForm = styled.form`
  background-color: rgba(239, 239, 239, 0.8);
`;

const Graphic = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
`;

export default HowItWorks;
