import CustomToast from "components/common/CustomToast";
import toast from "react-hot-toast";
import { shipmentsService } from "services";
import { shipmentsConstant } from "../constants";
import { partnerActions } from "./partnerActions";

export const shipmentActions = {
  fetchMovements,
  setShipmentSuccess,
  fetchActiveShipments,
  saveShipmentRequest,
  fetchSavedShipmentRequest,
  fetchSingleShipment,
  showLapHistory,
  acceptQuote,
  rejectQuote,
  fetchShipmentQuotes,
  addPartner,
};

function showLapHistory(data) {
  return (dispatch) => {
    dispatch(success(shipmentsConstant.SHOW_LAP_HISTORY, data));
  };
}

function fetchActiveShipments() {
  return (dispatch) => {
    dispatch(request(shipmentsConstant.ACTIVE_SHIPMENTS_REQUEST));

    shipmentsService.fetchActiveShipments().then(
      (res) => {
        dispatch(success(shipmentsConstant.ACTIVE_SHIPMENTS_SUCCESS, res.data));
      },
      (error) => {
        if (error.status !== 404 && error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message} type="error" />
          ));
        } else {
          dispatch(
            failure(shipmentsConstant.ACTIVE_SHIPMENTS_FAILURE, error.message)
          );
        }
      }
    );
  };
}

function fetchMovements(shipmentsIDs) {
  return (dispatch) => {
    dispatch(request(shipmentsConstant.MOVEMENTS_REQUEST));

    shipmentsService.getShipmentMovements(shipmentsIDs).then(
      (res) => {
        dispatch(
          success(shipmentsConstant.MOVEMENTS_SUCCESS, res.data.movements)
        );
      },
      (error) => {
        if (error.status !== 404 && error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
        }
        dispatch(failure(shipmentsConstant.MOVEMENTS_FAILURE, error.message));
      }
    );
  };
}

function setShipmentSuccess(shipment) {
  return success(shipmentsConstant.CREATE_SHIPMENT_SUCCESS, shipment);
}

function saveShipmentRequest(shipment) {
  return (dispatch) => {
    dispatch(request(shipmentsConstant.SAVE_SHIPMENT_REQ_REQUEST));

    shipmentsService.saveShipmentRequest(shipment).then(
      (res) => {
        toast.custom((t) => (
          <CustomToast t={t} message={"Shipment request saved!"} />
        ));
        dispatch(success(shipmentsConstant.SAVE_SHIPMENT_REQ_SUCCESS, true));
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(
            failure(shipmentsConstant.SAVE_SHIPMENT_REQ_FAILURE, error.message)
          );
        }
      }
    );
  };
}

function fetchSavedShipmentRequest() {
  return (dispatch) => {
    dispatch(request(shipmentsConstant.FETCH_SAVED_SHIPMENT_REQ_REQUEST));

    shipmentsService.fetchSavedShipmentRequest().then(
      (res) => {
        dispatch(
          success(shipmentsConstant.FETCH_SAVED_SHIPMENT_REQ_SUCCESS, res.data)
        );
      },
      (error) => {
        if (error.message) {
          dispatch(
            failure(
              shipmentsConstant.FETCH_SAVED_SHIPMENT_REQ_FAILURE,
              error.message
            )
          );
        }
      }
    );
  };
}

function fetchSingleShipment(shipmentId) {
  return (dispatch) => {
    dispatch(request(shipmentsConstant.FETCH_SINGLE_SHIPMENT_REQUEST));

    shipmentsService.fetchSingleShipment(shipmentId).then(
      (res) => {
        dispatch(
          success(shipmentsConstant.FETCH_SINGLE_SHIPMENT_SUCCESS, res.data)
        );
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(
            failure(
              shipmentsConstant.FETCH_SINGLE_SHIPMENT_FAILURE,
              error.message
            )
          );
        }
      }
    );
  };
}

const updateShipmentQuotesState = (getState, data) => {
  const quotesState = getState().shipments.shipmentQuotes;
  const oldQuoteObj = quotesState.find((quote) => quote._id === data._id);
  const oldQuoteIndex = quotesState.indexOf(oldQuoteObj);
  const newArr = [...quotesState];
  newArr[oldQuoteIndex] = data;
  return newArr;
};

function acceptQuote(quoteId) {
  return (dispatch, getState) => {
    dispatch(request(shipmentsConstant.ACCEPT_QUOTE_REQUEST));

    shipmentsService.acceptOrRejectQuote(quoteId, true).then(
      (res) => {
        dispatch(
          success(
            shipmentsConstant.ACCEPT_QUOTE_SUCCESS,
            updateShipmentQuotesState(getState, res.data)
          )
        );
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(
            failure(shipmentsConstant.ACCEPT_QUOTE_FAILURE, error.message)
          );
        }
      }
    );
  };
}

function rejectQuote(quoteId, reasonObj) {
  return (dispatch, getState) => {
    dispatch(request(shipmentsConstant.REJECT_QUOTE_REQUEST));

    shipmentsService.acceptOrRejectQuote(quoteId, false, reasonObj).then(
      (res) => {
        dispatch(
          success(
            shipmentsConstant.REJECT_QUOTE_SUCCESS,
            updateShipmentQuotesState(getState, res.data)
          )
        );
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(
            failure(shipmentsConstant.REJECT_QUOTE_FAILURE, error.message)
          );
        }
      }
    );
  };
}

function fetchShipmentQuotes(shipmentId) {
  return (dispatch) => {
    dispatch(request(shipmentsConstant.FETCH_SHIPMENT_QUOTES_REQUEST));

    shipmentsService.fetchShipmentQuotes(shipmentId).then(
      (res) => {
        dispatch(
          success(shipmentsConstant.FETCH_SHIPMENT_QUOTES_SUCCESS, res.data)
        );
      },
      (error) => {
        if (error.message && error.status !== 404) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
        }
        dispatch(
          failure(
            shipmentsConstant.FETCH_SHIPMENT_QUOTES_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

function addPartner(partner) {
  let data = {
    ...partner,
    mobile:
      partner.mobile_extension && partner.mobile_extension !== ""
        ? partner.mobile_extension.replace(/\s/g, "") +
          parseInt(partner.raw_mobile, 10)
        : partner.raw_mobile,
  };

  return (dispatch) => {
    dispatch(request());

    shipmentsService.addPartner(data).then(
      (partnerResponse) => {
        console.log(partnerResponse);
        if (data.wca_partner) {
          dispatch(
            partnerActions.onboardPartner({
              partner_id: partnerResponse.data._id,
              complete: true,
              ...(data?.files?.length && { files: data.files }),
              wca_partner: data.wca_partner,
              wca_number: data.wca_number,
            })
          );
        } else {
          toast.custom((t) => (
            <CustomToast t={t} message={partnerResponse.message} />
          ));
        }
        dispatch(success(partnerResponse));
      },
      (error) => {
        console.log(error);

        if (error.status !== 404) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
        }

        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };

  function request() {
    return { type: shipmentsConstant.START_ADD_PARTNER_REQUEST };
  }
  function success(partner) {
    return { type: shipmentsConstant.ADD_PARTNER_SUCCESS, partner };
  }
  function failure(error) {
    return { type: shipmentsConstant.ADD_PARTNER_FAILURE, error };
  }
}

function request(type) {
  return { type: type };
}
function success(type, data) {
  return { type: type, payload: data };
}
function failure(type, error) {
  return { type: type, payload: error };
}
