import React from "react";
import ServiceDetail from "./ServiceDetail";

const ServiceDetails = ({ details, tagLine, buttonColor }) => {
  return (
    <div className="mb-10">
      {tagLine && <p className="text-center font-catalina text-2xl sm:text-3xl mb-6">{tagLine}</p>}
      {details?.map((detail, index) => (
        <ServiceDetail
          index={index}
          art={detail.art}
          title={detail.title}
          sub={detail.sub}
          buttonColor={buttonColor}
          flip={detail.flip}
        />
      ))}
    </div>
  );
};

export default ServiceDetails;
