import React from "react";
import styledComponents from "styled-components";

const Story = () => (
  <div className="py-16">
    <div className="w-full sm:w-4/5 m-auto relative pt-32">
      <AboutStory
        url={require("assets/images/about/story.png")}
        className="w-full absolute top-0 left-0 rounded-t-3xl sm:rounded-3xl"
      ></AboutStory>
      <div className="bg-white shadow-md w-[90%] m-auto relative rounded-xl px-4 sm:px-14 py-16 pb-4 sm:pb-16">
        <p className="font-catalina text-2xl sm:text-3xl mb-14 text-center">
          The Origin Story
        </p>
        <p className="font-catalina text-xl sm:text-2xl mb-10 text-center">
          “Blue Economy Is the New Frontier of African Renaissance… Africa’s
          ocean economy is worth over $400 billion”.{" "}
        </p>
        <div className="text-sm mb-10 text-gray-500 font-light">
          <p className="mb-10">
            Tonye always had a relationship with the shipping industry. His
            grandfather worked most of his life at a Port in Southern Nigeria,
            and his father runs a shipping business. So even during family
            dinners as a boy, he had a front-row seat to the pains and
            frustrations of the shipping industry.
          </p>
          <p className="mb-10">
            After college, he co-founded a design and web development agency,
            but it failed after a few years. So, Tonye reluctantly went back to
            his roots to join a family-owned logistics company as a Strategist,
            working out of shipping containers.
          </p>
          <p className="mb-10">
            In 2017, his boss returned from a trip to Sydney and couldn’t stop
            talking about a Blue Economy presentation by Gunter Pauli that he’d
            attended. Tonye was curious about the Blue Economy, so he Googled
            it. The first result was the “Blue Economy Is the New Frontier of
            African Renaissance… Africa’s ocean economy is worth over $400
            billion”. It made no sense to Tonye why there weren’t significant
            investments in that space. This could potentially impact millions of
            people!
          </p>
          <p className="mb-10">
            He looked up tourism, renewable energy & fishing but couldn’t figure
            out where he’d fit in. Then, he researched shipping; it just felt
            right. At that point, he knew he had a chance to solve the problems
            he’d spent his entire life hearing about. He knew he had to do
            something; he had to start MVX.
          </p>
        </div>
      </div>
    </div>
  </div>
);

const AboutStory = styledComponents.div`
  height: 350px;
  background: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
`;

export default Story;
