import { shipmentActions } from "actions";
import CustomToast from "components/common/CustomToast";
import Loading from "components/common/Loading";
import ModalContainer from "components/common/modals/ModalContainer";
import { shipmentsConstant } from "constants";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { shipmentsService } from "services";
import { GoBackInfo } from "../common/MobileNav";
import MobileShipProgIndicator from "../common/MobileShipProgIndicator";
import ProgressIndicator from "../common/ProgressIndicator";
import AdditionalServices from "./CreateShipment/AdditionalServices";
import Description from "./CreateShipment/Description";
import FinanceChoice from "./CreateShipment/FinanceChoice";
import Incoterms from "./CreateShipment/Incoterms";
import Location from "./CreateShipment/Location";
import ShipmentMode from "./CreateShipment/ShipmentMode";
import ShipmentPackages from "./CreateShipment/ShipmentPackages";
import ShipmentType from "./CreateShipment/ShipmentType";

/*
TODOS
- current shipment ID
- incoterms popup
- add regex for forms
- save and exit doesn't close the second time
*/

const CreateShipment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const {
    saveShipmentRequestSuccess,
    saveShipmentRequestLoading,
    // savedShipmentRequest
  } = useSelector((state) => state.shipments);
  const [finance, setFinance] = useState(null);
  const [createShipmentsLoading, setCreateShipmentsLoading] = useState(false);
  const [createShipmentsSuccess, setCreateShipmentsSuccess] = useState(false);

  const [modal, setModal] = useState(false);
  const [shipment, setShipment] = useState({
    additional_services: {
      customs: false,
      insurance: false,
      son_inport_permit: false,
      form_m_processing: false,
      haulage: false,
    },
    containers_size: [],
    package_type: "",
  });
  const [modeEmpty, setModeEmpty] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;

    setShipment((shipment) => ({
      ...shipment,
      [name]: value,
    }));
  };

  const errorAlert = (elm, msg) => {
    toast.custom((t) => <CustomToast t={t} message={msg} type="error" />);
    document.querySelector(elm).scrollIntoView({ behavior: "smooth" });
  };

  const createShipment = async (shipment) => {
    setCreateShipmentsLoading(true);
    try {
      const res = await shipmentsService.createShipment(shipment);

      finance === "yes" &&
        localStorage.setItem("shipmentFinanceRequestId", res.data._id);

      setCreateShipmentsSuccess(true);
      setCreateShipmentsLoading(false);
    } catch (error) {
      if (error.message) {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message + "!"} type="error" />
        ));
      }
      setCreateShipmentsLoading(false);
    }
  };

  const onSubmit = (type = "") => {
    // required values check start
    if (!shipment?.service_mode) {
      setModeEmpty(true);
      return;
    }

    if (!shipment.package_type) {
      errorAlert(".package-type", "Please choose a package type!");
      return;
    }

    if (shipment.package_type === "containers") {
      const filledContainerFields = shipment.containers_size?.every(
        (size) =>
          size?.package_type && size?.unit && size?.value && size?.quantity
      );
      if (!filledContainerFields) {
        errorAlert(".shipment-packages", "Please fill all container details!");
        return;
      }
    } else if (shipment.package_type === "non-containers") {
      const noEmptyfields = shipment?.packages.every(
        (pkg) =>
          (pkg?.package_type || pkg?.package_type === "") &&
          pkg?.length?.value &&
          pkg?.width?.value &&
          pkg?.height?.value &&
          pkg?.weight?.value
      );
      if (!noEmptyfields) {
        errorAlert(
          ".shipment-packages",
          "Please fill all non container details!"
        );
        return;
      }
    }

    if (!shipment?.description) {
      errorAlert(".shipment-description", "Please add description!");
      return;
    }

    if (!shipment?.origin || !shipment?.destination) {
      errorAlert(
        ".address-errors",
        "Please add pickup and destination location!"
      );
      return;
    }

    if (!shipment?.shipping_date) {
      errorAlert(".address-errors", "Please add pickup date!");
      return;
    }

    if (shipment?.origin?.address === shipment?.destination?.address) {
      errorAlert(
        ".address-errors",
        "Pickup and Destination address cannot be the same!"
      );
      return;
    }
    // required values check
    const { package_type, containers_size, packages, ...rest } = shipment;
    const finalRequestData =
      package_type === "containers"
        ? { ...rest, package_type, containers_size }
        : { ...rest, package_type, packages };

    if (type !== "save") {
      createShipment(shipment);
      // dispatch({type: shipmentsConstant.CREATE_SHIPMENT_FAILURE});

      dispatch({
        type: shipmentsConstant.FETCH_SAVED_SHIPMENT_REQ_SUCCESS,
        payload: null,
      });
      localStorage.removeItem("financeShipment");
    } else {
      dispatch(
        shipmentActions.saveShipmentRequest({
          ...finalRequestData,
          mobile: user?.mobile,
          source: "shipment",
        })
      );
    }
  };

  useEffect(() => {
    localStorage.removeItem("moveCargo");
    dispatch(shipmentActions.fetchSavedShipmentRequest());
    if (shipment?.service_mode === "air") {
      setShipment((prev) => ({ ...prev, package_type: "non-containers" }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (saveShipmentRequestSuccess) {
      dispatch({
        type: shipmentsConstant.SAVE_SHIPMENT_REQ_SUCCESS,
        payload: false,
      });
      navigate("/dashboard/shipments");
    }

    if (createShipmentsSuccess) {
      setModal(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveShipmentRequestSuccess, createShipmentsSuccess]);

  // SAVED REQUEST AUTO POPULATE
  // useEffect(() => {
  //   const financeStatus = localStorage.getItem("financeShipment");

  //   if (savedShipmentRequest?.service_type) {
  //     setShipment((prev) => ({
  //       ...prev,
  //       service_type: savedShipmentRequest.service_type,
  //     }));

  //     financeStatus && setFinance(financeStatus);
  //   }

  //   if (savedShipmentRequest) {
  //     setShipment((prev) => ({
  //       ...prev,
  //       service_mode: savedShipmentRequest?.service_mode,
  //       additional_services: {
  //         ...savedShipmentRequest?.additional_services,
  //       },
  //       package_type: savedShipmentRequest?.package_type,
  //       shipment_description: savedShipmentRequest?.shipment_description,
  //       incoterms: savedShipmentRequest?.incoterms,
  //       origin: savedShipmentRequest?.origin,
  //       destination: savedShipmentRequest?.destination,
  //       shipping_date: savedShipmentRequest?.shipping_date,
  //       description: savedShipmentRequest?.description,
  //     }));
  //   }

  //   if (savedShipmentRequest?.package_type === "non-containers") {
  //     setShipment((prev) => ({
  //       ...prev,
  //       packages: savedShipmentRequest.packages,
  //     }));
  //   } else {
  //     setShipment((prev) => ({
  //       ...prev,
  //       containers_size: savedShipmentRequest?.containers_size,
  //     }));
  //   }
  // }, [savedShipmentRequest]);

  return (
    <>
      <GoBackInfo text="Create a Shipment" />
      <div className="-ml-4 -mr-14 sm:ml-0 sm:mt-3 md:mt-0 sm:-mr-10 md:-mr-8 lg:mr-0">
        <div className="w-full mb-4 hidden sm:block">
          <p
            className="flex items-center text-xs cursor-pointer inline w-max"
            onClick={() => navigate(-1)}
          >
            <span className="material-icons mr-2 !text-base">
              keyboard_backspace
            </span>
            Close
          </p>
        </div>
        <p className="hidden sm:block text-xl font-bold mb-6">
          Create Shipment
        </p>
        {/* margin to push down on mobile */}
        <div className="mt-32 sm:mt-0"></div>
        <div className="w-full flex gap-6 md:gap-10 lg:gap-20">
          <div className="w-full max-w-[450px] sm:max-w-none sm:w-8/12 md:3/5 shrink-0">
            <ShipmentType shipment={shipment} onChange={onChange} />
            {shipment.service_type ? (
              <>
                <FinanceChoice value={finance} onChange={setFinance} />
                <div className="space-x-5 sm:hidden mb-5 text-[15px]">
                  <button
                    className={`border border-[#989898] bg-white text-[#989898] rounded-lg px-6 py-2  ${
                      finance === "yes" &&
                      "!border-mvx-blue bg-[#e8f0fe] !text-mvx-blue"
                    }`}
                    onClick={() => {
                      setFinance("yes");
                      localStorage.setItem("financeShipment", "yes");
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className={`border border-[#989898] bg-white text-[#989898] rounded-lg px-6 py-2 ${
                      finance === "no" &&
                      "!border-mvx-blue bg-[#e8f0fe] !text-mvx-blue"
                    }`}
                    onClick={() => {
                      setFinance("no");
                      localStorage.setItem("financeShipment", "no");
                    }}
                  >
                    No
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
            {finance ? (
              <>
                <ShipmentMode
                  shipment={shipment}
                  setShipment={setShipment}
                  notSelected={modeEmpty}
                  setModeEmpty={setModeEmpty}
                />
                <AdditionalServices
                  shipment={shipment}
                  setShipment={setShipment}
                />
                <ShipmentPackages
                  shipment={shipment}
                  setShipment={setShipment}
                />
                <Description shipment={shipment} onChange={onChange} />
                <Incoterms shipment={shipment} setShipment={setShipment} />
                <Location shipment={shipment} setShipment={setShipment} />
              </>
            ) : (
              ""
            )}
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-6 w-full pb-20">
              <button
                disabled={createShipmentsLoading || saveShipmentRequestLoading}
                onClick={onSubmit}
                className="disabled:bg-[#688EF6] relative disabled:border-[#688EF6] w-full border border-mvx-blue px-6 pl-4 py-3 h-14 sm:h-12 rounded-md bg-mvx-blue text-white text-[15px] sm:text-sm font-medium sm:font-normal"
              >
                {createShipmentsLoading ? <Loading size={5} /> : "Submit"}
              </button>
              <button
                disabled={createShipmentsLoading || saveShipmentRequestLoading}
                onClick={() => onSubmit("save")}
                className="w-full border border-gray-400 px-6 pl-4 py-3 h-14 sm:h-12 rounded-md text-gray text-[15px] sm:text-sm font-medium sm:font-normal relative"
              >
                {saveShipmentRequestLoading ? (
                  <Loading size={5} color="mvx-blue" />
                ) : (
                  "Save and exit"
                )}
              </button>
            </div>
          </div>

          {/* STEP UPDATE */}
          <MobileShipProgIndicator shipment={shipment} />
          <ProgressIndicator shipment={shipment} />
        </div>

        {modal && (
          <ModalContainer closeModal={() => setModal(false)}>
            <div onClick={e => e.stopPropagation()} className="bg-white max-w-[320px] md:max-w-[600px] mx-auto items-center rounded-[10px]">
              <div className="text-center py-[50px] md:py-[100px]">
                <span className="material-icons text-center !text-[80px] text-[#02C39A] mt-4 !leading-4 mr-1">
                  check_circle
                </span>
                <p className="mt-4 font-[12px] font-semibold items-center px-8 md:px-20">
                  Your shipment has been successfully created! We will get back
                  to you shortly.
                </p>
                <button
                  onClick={() =>
                    finance === "yes"
                      ? navigate("/dashboard/finance-create-shipment")
                      : navigate("/dashboard/shipments")
                  }
                  className="bg-mvx-blue text-center min-w-[30%] py-3 px-6 text-white rounded-[10px] text-[16px] mb-2 mt-10"
                >
                  {finance === "yes" ? "Proceed to Finance" : "Close"}
                </button>
              </div>
            </div>
          </ModalContainer>
        )}
      </div>
    </>
  );
};

export default CreateShipment;
