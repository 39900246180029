import { useState, useEffect } from "react";
import LoginAndSecurity from "./LoginAndSecurity";
import Profile from "./Profile";
import { GoBackInfo } from "../common/MobileNav";
import BusinessProfile from "./Customer/BusinessProfile";
import CompanyDocs from "./Customer/CompanyDocs";
import { useSelector } from "react-redux";
import PersonProfile from "./Customer/PersonProfile";
import { useScreensize } from "helpers/useScreensize";
import { useNavigate } from "react-router-dom";

const CustomerProfile = () => {
  const navigate = useNavigate();  
  const [screenSize] = useScreensize();
  const { financeProfile } = useSelector((state) => state.finance);

  const [activeTab, setActiveTab] = useState(0);

  const listener = () => {
    if (
      screenSize > 1024 &&
      activeTab !== 0 &&
      activeTab !== 1 &&
      activeTab !== 2
    ) {
      setActiveTab(0);
    }

    if (
      screenSize < 1024 &&
      activeTab !== 3 &&
      activeTab !== 4 &&
      activeTab !== 5 &&
      activeTab !== 6
    ) {
      setActiveTab(3);
    }
  };

  useEffect(() => {
    listener();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize]);

  const handleSetActiveTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <GoBackInfo text="Back" navigateTo="dashboard/home" />
      <div className="-ml-10 -mt-6 bg-white pt-6 pb-8 -mr-20">
        <p
          className="px-9 hidden sm:flex items-center text-xs cursor-pointer w-max mb-2"
          onClick={() => navigate(-1)}
        >
          <span className="material-icons mr-2 !text-base">
            keyboard_backspace
          </span>
          Close
        </p>
        <p className="hidden sm:block mt-4 md:mt-0 font-bold text-xl px-9">
          Settings
        </p>
        <div className="lg:flex gap-3 bg-[#FAFAFA] my-5 lg:py-3 px-9 overflow hidden">
          <Tab
            text="Profile"
            index={0}
            setActiveTab={() => handleSetActiveTab(0)}
            active={activeTab}
          />
          <Tab
            text="Login and Security"
            index={1}
            setActiveTab={() => handleSetActiveTab(1)}
            active={activeTab}
          />
          {/* <Tab
            text="Sub-User"
            index={2}
            setActiveTab={() => handleSetActiveTab(2)}
            active={activeTab}
          /> */}
        </div>
        <div className="flex bg-transparent sm:bg-[#FAFAFA] lg:hidden mt-14 sm:mt-4 mb-3 md:px-4 py-3 overflow-auto mobileSettingsTab">
          <Tab
            text="Profile"
            index={3}
            setActiveTab={() => handleSetActiveTab(3)}
            active={activeTab}
          />
          {Object.keys(financeProfile || {})?.length > 0 && (
            <Tab
              text="Company Details"
              index={4}
              setActiveTab={() => handleSetActiveTab(4)}
              active={activeTab}
            />
          )}
          {Object.keys(financeProfile || {})?.length > 0 && (
            <Tab
              text="Company Documents"
              index={5}
              setActiveTab={() => handleSetActiveTab(5)}
              active={activeTab}
            />
          )}
          <Tab
            text="Login and Security"
            index={6}
            setActiveTab={() => handleSetActiveTab(6)}
            active={activeTab}
          />
        </div>
        <div className="mx-6 lg:mx-10 my-10 sm:mt-6 ">
          {activeTab === 0 && <Profile />}
          {activeTab === 1 && <LoginAndSecurity />}
          {/* {activeTab === 2 && <SubUsers />} */}
          {/* {activeTab === 3 && <Profile />} */}
          {activeTab === 3 && <PersonProfile />}
          {activeTab === 4 && <BusinessProfile />}
          {activeTab === 5 && <CompanyDocs />}
          {activeTab === 6 && <LoginAndSecurity />}
        </div>
      </div>
    </>
  );
};

export default CustomerProfile;

const Tab = ({ text, active, index, setActiveTab }) => {
  return (
    <p
      className={`whitespace-nowrap px-9 py-3.5 tracking-wide text-center font-bold text-sm cursor-pointer ${
        active === index
          ? "rounded-full bg-mvx-blue text-white"
          : "text-[#333333]"
      }`}
      onClick={() => setActiveTab(index)}
    >
      {text}
    </p>
  );
};
