import Header from "components/common/Header";
import React from "react";
import styled from "styled-components";

const Hero = () => {
  return (
    <div className="px-4 pb-24 border-b border-gray-300">
      <Header />
      <HeroContainer className="rounded-b-lg pt-8 pb-6 px-4">
        <div className="w-[90%] sm:w-[70%] m-auto pb-32 flex flex-col items-center">
          <p className="font-catalina text-[2.1rem] sm:text-5xl text-center pt-20 sm:pt-44 pb-4 leading-10">
            You Can Earn up to ₦500,000
            <br />
            On the side. Every month.
          </p>
          <p className="pb-14">
            Become an MVX Agent and you can earn up to{" "}
            <span className="font-semibold">₦500,000</span> every month, in
            referral bonuses.
          </p>
          <button
            type="button"
            className={`rounded-xl bg-[#5D2A42] shadow-lg outline-0 w-full sm:w-[200px] h-[50px] text-white text-md font-bold flex items-center justify-center`}
          >
            Get Started
            <span className="material-icons white-color">
              keyboard_arrow_right
            </span>
          </button>
        </div>
        <div className="w-[calc(100%+4rem)] flex items-center -ml-8">
          <img
            src={require("assets/images/agents/1.png")}
            className="w-1/3 mb-2"
            alt="Scroll down"
          />
          <img
            src={require("assets/images/agents/2.png")}
            className="w-1/3 mb-2"
            alt="Scroll down"
          />
          <img
            src={require("assets/images/agents/3.png")}
            className="w-1/3 mb-2"
            alt="Scroll down"
          />
        </div>
      </HeroContainer>
    </div>
  );
};

const HeroContainer = styled.div`
  background: rgba(168, 183, 171, 0.15);
`;

export default Hero;
