import { partnerConstants } from "../constants";

const initialState = {
  fetchingPartner: false,
};

export function partners(state = initialState, action) {
  switch (action.type) {

    case partnerConstants.ONBOARD_PARTNER_REQUEST:
      return {
          ...state,
          onboardingPartner: true,
      };
    case partnerConstants.ONBOARD_PARTNER_SUCCESS:
      return {
        ...state,
        onboardingPartner: false,
        onboardedPartner: action.partner,
        onboardingPartnerError: null,
      };
    case partnerConstants.ONBOARD_PARTNER_FAILURE:
      return {
        ...state,
        onboardingPartner: false,
        onboardedPartner: null,
        onboardingPartnerError: action.error,
    };
    case partnerConstants.RESET_PARTNER_STATE:
      return initialState;
    default: {
      return state;
    }
  }
}