import { useRef, useEffect } from "react";
import InputAudit from "./CustomerDocs/InputAudit";
import InputCac from "./CustomerDocs/InputCac";
import InputDirectorId from "./CustomerDocs/InputDirectorId";
import InputMoa from "./CustomerDocs/InputMoa";
import InputNepc from "./CustomerDocs/InputNepc";

const CompanyDocs = () => {
  const companyDocs = useRef(null);

  useEffect(() => {
    if (window.innerWidth > 1024) {
      companyDocs.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div className="px-3 lg:px-0 mt-6 sm:mt-8 lg:mt-16" ref={companyDocs}>
      <div className="space-y-2 text-[#333333]">
        <p className="font-extrabold text-lg">Company Documents</p>
        <p className="text-[13.5px] font-medium">Please upload all documents</p>
      </div>
      <div className="my-12 flex flex-col sm:flex-row gap-10 sm:flex-wrap">
        <InputCac />
        <InputMoa />
        <InputDirectorId />
        <InputAudit />
        <InputNepc />
      </div>
    </div>
  );
};
export default CompanyDocs;
