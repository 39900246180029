import CustomToast from "components/common/CustomToast";
import { appConstants } from "constants";
import toast from "react-hot-toast";
import { appService } from "services";

export const appActions = {
  contact,
  getLocation,
  getQuote,
  becomeAgent,
  fetchArticles,
};

function getLocation() {
  return (dispatch) => {
    dispatch(request());

    appService.getLocation().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };

  function request() {
    return { type: appConstants.LOCATION_REQUEST };
  }
  function success(location) {
    return { type: appConstants.LOCATION_SUCCESS, location };
  }
  function failure(error) {
    return { type: appConstants.LOCATION_FAILURE, error };
  }
}

function contact(data) {
  return (dispatch) => {
    dispatch(request());

    appService.contact(data).then(
      (res) => {
        toast.custom((t) => <CustomToast t={t} message={res.message} />);

        dispatch(success());
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };

  function request() {
    return { type: appConstants.CONTACT_REQUEST };
  }
  function success() {
    return { type: appConstants.CONTACT_SUCCESS };
  }
  function failure(error) {
    return { type: appConstants.CONTACT_FAILURE, error };
  }
}

function getQuote(data) {
  return (dispatch) => {
    dispatch(request());

    appService.getQuote(data).then(
      (res) => {
        dispatch(success(res, data));
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(failure(error.message));
        }
      }
    );
  };

  function request() {
    return { type: appConstants.QUOTE_REQUEST };
  }
  function success(res, data) {
    return { type: appConstants.QUOTE_SUCCESS, quote: res, data };
  }
  function failure(error) {
    return { type: appConstants.QUOTE_FAILURE, error };
  }
}

function becomeAgent(data) {
  let agent = {
    ...data,
    mobile:
      data.mobile_extension && data.mobile_extension !== ""
        ? data.mobile_extension.replace(/\s/g, "") +
          parseInt(data.raw_mobile, 10)
        : data.raw_mobile,
  };

  return (dispatch) => {
    dispatch(request());

    appService.becomeAgent(agent).then(
      (res) => {
        toast.custom((t) => <CustomToast t={t} message={res.message} />);

        dispatch(success(res));
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(failure(error.message));
        }
      }
    );
  };

  function request() {
    return { type: appConstants.BECOME_AGENT_REQUEST };
  }
  function success(res) {
    return { type: appConstants.BECOME_AGENT_SUCCESS, agent: res };
  }
  function failure(error) {
    return { type: appConstants.BECOME_AGENT_FAILURE, error };
  }
}

function fetchArticles(type) {
  return (dispatch) => {
    dispatch(request());

    appService.fetchArticles(type).then(
      (res) => {
        dispatch(success(res.data));
      },
      (error) => {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
          dispatch(failure(error.message));
        }
      }
    );
  };

  function request() {
    return { type: appConstants.ARTICLES_REQUEST };
  }
  function success(articles) {
    return { type: appConstants.ARTICLES_SUCCESS, articles };
  }
  function failure(error) {
    return { type: appConstants.ARTICLES_FAILURE, error };
  }
}
