const envData = () => {
  const envData = {
    googleAPIKey: process.env.REACT_APP_GOOGLE_API_KEY,
    currentInstance: process.env.REACT_APP_INSTANCE,
  };

  if (process.env.REACT_APP_INSTANCE === "staging") {
    return {
      ...envData,
      apiURL: process.env.REACT_APP_STAGING_TRANSIT_URL,
      transitURL: process.env.REACT_APP_STAGING_TRANSIT_URL,
      apiKey: process.env.REACT_APP_STAGING_API_KEY,
      tradeURL: process.env.REACT_APP_STAGING_TRADE_URL,
      notificationsURL: process.env.REACT_APP_STAGING_NOTIFICATIONS_URL,
      monoKey: process.env.REACT_APP_STAGING_MONO_KEY
    };
  } else if (process.env.REACT_APP_INSTANCE === "production") {
    return {
      ...envData,
      apiURL: process.env.REACT_APP_PROD_TRANSIT_URL,
      transitURL: process.env.REACT_APP_PROD_TRANSIT_URL,
      apiKey: process.env.REACT_APP_PROD_API_KEY,
      tradeURL: process.env.REACT_APP_PROD_TRADE_URL,
      notificationsURL: process.env.REACT_APP_PROD_NOTIFICATIONS_URL,
      monoKey: process.env.REACT_APP_PROD_MONO_KEY
    };
  }
};

export const currentENVData = { ...envData() };
