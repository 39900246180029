/* eslint-disable */
import gotoAddShipment from "helpers/gotoAddShipment";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const ServiceDetail = ({ index, title, sub, art, buttonColor, flip }) => {
  buttonColor = buttonColor || "blue2";
  const [focus, setFocus] = useState(false);
  const serviceVideo = useRef(null);

  useEffect(() => {
    if (art.type === "video") {
      if (focus === true) {
        serviceVideo?.current?.play();
      } else {
        serviceVideo?.current?.pause();
      }
    }
  }, [focus]);

  return (
    <Container
      onMouseOver={() => setFocus(true)}
      onMouseOut={() => setFocus(false)}
      key={index}
      className={`w-full sm:w-[calc(80%+4rem)] p-6 flex-wrap sm:flex-nowrap sm:p-8 m-auto flex items-center rounded-2xl mb-8 ${buttonColor}`}
    >
      <div className={`w-full sm:w-1/2 mb-10 sm:mb-0 ${flip ? "order-1 sm:order-2 ml-0 sm:ml-24" : "mr-0 sm:mr-24"}`}>
        <p className="font-bold text-xl mb-4">{title}</p>
        <p className="text-sm mb-10">{sub}</p>
        <button
          type="button"
          onClick={gotoAddShipment}
          className={`bg-mvx-${buttonColor} text-white outline-0 rounded-xl h-[50px] px-10 text-sm font-bold mr-4 flex items-center`}
        >
          Create a Shipment
          <span className="material-icons white-color">
            keyboard_arrow_right
          </span>
        </button>
      </div>
      <div
        className={`w-full sm:w-1/2 h-[270px] sm:h-[450px]  rounded-2xl relative overflow-hidden ${
          flip && "order-2 sm:order-1"
        } ${art.type === "video" && "bg-black"}`}
      >
        <div
          className={`w-14 h-14 absolute left-5 bottom-5 z-10 flex items-center justify-center text-white duration-300 ${
            focus ? "opacity-100" : "opacity-40"
          }`}
        >
          <span className={`material-icons ${focus && "bounceAlpha2"}`}>
            arrow_forward_ios
          </span>
          <span className={`material-icons -ml-4 ${focus && "bounceAlpha"}`}>
            arrow_forward_ios
          </span>
        </div>
        <div
          style={{
            position: "absolute",
            top: "-50%",
            left: "-50%",
            width: "200%",
            height: "200%",
          }}
        >
          {art.type === "video" && (
            <video
              src={art.src}
              preload="auto"
              muted
              loop={true}
              playsInline={true}
              ref={serviceVideo}
              className="absolute top-0 bottom-0 right-0 left-0 m-auto min-h-1/2 min-w-1/2"
            ></video>
          )}
          {art.type === "image" && (
            <ServiceImage
              className="w-1/2 h-1/2 center-item absolute"
              url={art.src}
            >
              {art?.sub ? (
                <div className="w-full h-full relative">{art?.sub}</div>
              ) : (
                ""
              )}
            </ServiceImage>
          )}
          {art.type === "svg" && (
            <div
              className={`w-1/2 h-1/2 center-item absolute flex items-center ${
                art.position === "end" && "justify-end"
              }`}
            >
              {art.src}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  &.blue2:hover {
    background: rgba(0, 94, 205, 0.05);
  }
  &.orange:hover {
    background: rgba(255, 138, 0, 0.05);
  }
`;

const ServiceImage = styled.div`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export default ServiceDetail;
