import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const LocationSearchInput = ({ bg, border, name, setData, savedAddress }) => {
  const [address, setAddress] = useState("");

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        setData((data) => ({
          ...data,
          [name]: { address, details: results },
        }));

        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setData((data) => ({
          ...data,
          [name]: { ...data[name], ...latLng },
        }));
      })
      .catch((error) => console.error("Error", error));
  };

  useEffect(() => {
    savedAddress && setAddress(savedAddress);
  }, [savedAddress]);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="w-full relative z-[100]">
          <input
            {...getInputProps({
              placeholder: "Search location ...",
              className: "location-search-input",
            })}
            className={`w-full h-12 text-sm p-3 outline-0 ${
              bg ? `bg-[${bg}]` : `bg-white`
            }
            ${border ? `border border-${border}` : ""} ${
              loading || suggestions?.length > 0 ? "rounded-t-lg" : "rounded-lg"
            }`}
          />
          {(loading || suggestions?.length > 0) && (
            <div
              className={`absolute top-full z-[200] bg-white rounded-b-lg w-full min-h-[200px] text-xs`}
            >
              {loading && (
                <div className="text-center h-[150px] flex items-center justify-center">
                  Loading...
                </div>
              )}
              {!loading &&
                suggestions?.map((suggestion, index) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      key={index}
                      {...getSuggestionItemProps(suggestion, {
                        className: `${className} p-2`,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
