import {
  AirGreenIcon,
  AirIcon,
  BargingGreenIcon,
  BargingIcon,
  CheckIcon,
  OceanGreenIcon,
  OceanIcon,
  RoadGreenIcon,
  RoadIcon,
} from "assets/arts";
import React, { useEffect, useState } from "react";

const ShipmentMode = ({ shipment, setShipment, notSelected, setModeEmpty }) => {
  const [modeFocus, setModeFocus] = useState(null);

  useEffect(() => {
    notSelected &&
      document
        .querySelector(".shipment-mode")
        .scrollIntoView({ behavior: "smooth" });
  }, [notSelected]);

  return (
    <>
      <p className="text-sm sm:text-xs font-bold mb-4 text-[#3E3E3E] shipment-mode after:content-['*'] relative after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500">
        How would you like to move your goods ?
      </p>

      <div className="flex mb-3 sm:mb-8 text-sm gap-3 sm:gap-6">
        <Mode
          title="air"
          icon={({ active }) => (
            <AirIcon
              className={`h-[16px] sm:h-[20px] mdlg:h-[24px] duration-300 center-item w-auto ${
                active ? "hidden" : "block"
              }`}
            />
          )}
          activeIcon={({ active }) => (
            <AirGreenIcon
              className={`h-[16px] sm:h-[20px] lg:h-[24px] duration-300 w-auto ${
                active ? "block" : "hidden"
              }`}
            />
          )}
          shipment={shipment}
          setShipment={setShipment}
          focus={modeFocus}
          setFocus={setModeFocus}
          setModeEmpty={setModeEmpty}
        />
        <Mode
          title="ocean"
          icon={({ active }) => (
            <OceanIcon
              className={`h-[16px] sm:h-[20px] lg:h-[24px] duration-300 center-item w-auto ${
                active ? "hidden" : "block"
              }`}
            />
          )}
          activeIcon={({ active }) => (
            <OceanGreenIcon
              className={`h-[16px] sm:h-[20px] lg:h-[24px] duration-300 w-auto ${
                active ? "block" : "hidden"
              }`}
            />
          )}
          shipment={shipment}
          setShipment={setShipment}
          focus={modeFocus}
          setModeEmpty={setModeEmpty}
          setFocus={setModeFocus}
        />
        <Mode
          title="road"
          icon={({ active }) => (
            <RoadIcon
              className={`h-[16px] sm:h-[20px] lg:h-[24px] duration-300 center-item w-auto ${
                active ? "hidden" : "block"
              }`}
            />
          )}
          activeIcon={({ active }) => (
            <RoadGreenIcon
              className={`h-[16px] sm:h-[20px] lg:h-[24px] duration-300 w-auto ${
                active ? "block" : "hidden"
              }`}
            />
          )}
          shipment={shipment}
          setShipment={setShipment}
          focus={modeFocus}
          setModeEmpty={setModeEmpty}
          setFocus={setModeFocus}
        />
        <Mode
          title="barging"
          icon={({ active }) => (
            <BargingIcon
              className={`h-[16px] sm:h-[20px] lg:h-[24px] duration-300 center-item w-auto ${
                active ? "hidden" : "block"
              }`}
            />
          )}
          activeIcon={({ active }) => (
            <BargingGreenIcon
              className={`h-[16px] sm:h-[20px] lg:h-[24px] duration-300 w-auto ${
                active ? "block" : "hidden"
              }`}
            />
          )}
          shipment={shipment}
          setShipment={setShipment}
          focus={modeFocus}
          setModeEmpty={setModeEmpty}
          setFocus={setModeFocus}
        />
      </div>
      <span
        className={`text-[12px] sm:translate-y-[-27px] text-red-500 font-medium block mb-4 sm:mb-0 ${
          notSelected ? "visible" : "invisible"
        }`}
      >
        Please select shipment mode
      </span>
    </>
  );
};

const Mode = ({
  title,
  icon,
  activeIcon,
  focus,
  setFocus,
  shipment,
  setShipment,
  setModeEmpty,
}) => {
  return (
    <div
      onMouseOver={() => setFocus(title)}
      onMouseOut={() => setFocus(null)}
      onClick={() => {
        setShipment((s) => ({ ...s, service_mode: title }));
        setModeEmpty(false);
      }}
      className={`w-1/4 h-[75px] sm:h-[100px] lg:h-[130px] relative flex flex-col items-center justify-center cursor-pointer border rounded-lg bg-white duration-300 gap-1.5 sm:gap-4 ${
        (focus ? focus : shipment.service_mode) === title
          ? "border-[#02C39A]"
          : "border-gray"
      }`}
    >
      <div className="h-[18px] sm:h-[25px] w-auto relative">
        {icon({
          active: (focus ? focus : shipment.service_mode) === title,
        })}
        {activeIcon({
          active: (focus ? focus : shipment.service_mode) === title,
        })}
      </div>
      <p
        className={`capitalize text-xs sm:text-sm lg:text-base ${
          (focus ? focus : shipment.service_mode) === title
            ? "text-[#02C39A]"
            : "text-[#989898]"
        }`}
      >
        {title}
      </p>

      <div
        className={`w-[24px] h-[17px] sm:h-[24px] absolute -right-[12px] -bottom-[3px] sm:-bottom-[12px] duration-300 ${
          (focus ? focus : shipment.service_mode) === title
            ? "opacity-100"
            : "opacity-0"
        }`}
      >
        <CheckIcon className="w-auto h-[18px] sm:h-[20px] lg:h-[24px]" />
      </div>
    </div>
  );
};

export default ShipmentMode;
