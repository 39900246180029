import moment from "moment";

export const getKnownDates = (filterText) => {
  let dateObject;
  if (filterText === "") {
    dateObject = {
      start_date: "",
      end_date: "",
    };
    return dateObject;
  }
  if (filterText === "by year") {
    dateObject = {
      start_date: moment().subtract(1, "years").format("DD-MM-YYYY"),
      end_date: moment().format("DD-MM-YYYY"),
    };
    return dateObject;
  }
  if (filterText === "by month") {
    dateObject = {
      start_date: moment().subtract(1, "months").format("DD-MM-YYYY"),
      end_date: moment().format("DD-MM-YYYY"),
    };
    return dateObject;
  }
  if (filterText === "by days") {
    dateObject = {
      start_date: moment().subtract(7, "days").format("DD-MM-YYYY"),
      end_date: moment().format("DD-MM-YYYY"),
    };
    return dateObject;
  }
};
