import { financeConstant } from "constants";

const initialState = {
  saveMonoIDLoading: false,
  saveMonoIDError: "",
  financeProfile: null,
  financeProfileLoading: false,
  financeProfileError: "",
  newFinanceRequestData: null,
  newFinanceRequestLoading: false,
  newFinanceRequestError: "",
  newFinanceRequestSuccess: false,
  verifyBVNLoading: false,
  verifyBVNError: "",
  verifyCACLoading: false,
  verifyCACError: "",
  updateFinanceProfileLoading: false,
  updateFinanceProfileError: "",
  updateFinanceProfileImageLoading: false,
  updateFinanceProfileImageError: "",
  uploadCACDOCLoading: false,
  uploadCACDOCError: false,
  uploadMOADOCLoading: false,
  uploadMOADOCError: false,
  uploadDirectorIDLoading: false,
  uploadDirectorIDError: false,
  uploadAuditLoading: false,
  uploadAuditError: false,
  uploadNEPCDOCLoading: false,
  uploadNEPCDOCError: false,
  financeRequests: null,
  financeRequestsLoading: false,
  financeRequestsError: "",
  financeRedStarted: false,
  shipmentToRequestFinance: null,
  shipmentToPreview: null,
  finalSuccessRequest: "",
};

export function finance(state = initialState, action) {
  switch (action.type) {
    case financeConstant.SAVE_MONO_ID_REQUEST:
      return { ...state, saveMonoIDLoading: true };

    case financeConstant.SAVE_MONO_ID_SUCCESS:
      return {
        ...state,
        saveMonoIDLoading: false,
        financeProfile: action.payload,
      };

    case financeConstant.SAVE_MONO_ID_FAILURE:
      return {
        ...state,
        saveMonoIDLoading: false,
        saveMonoIDError: action.payload,
      };

    case financeConstant.FINANCE_PROFILE_REQUEST:
      return { ...state, financeProfileLoading: true };

    case financeConstant.FINANCE_PROFILE_SUCCESS:
      return {
        ...state,
        financeProfile: action.payload,
        financeProfileLoading: false,
      };

    case financeConstant.FINANCE_PROFILE_FAILURE:
      return {
        ...state,
        financeProfileLoading: false,
        financeProfileError: action.payload,
      };
    case financeConstant.FINANCE_REQUESTS_REQUEST:
      return {
        ...state,
        financeRequests: null,
        financeRequestsLoading: true,
        financeRequestsError: "",
      };

    case financeConstant.FINANCE_REQUESTS_SUCCESS:
      return {
        ...state,
        financeRequests: action.payload,
        financeRequestsLoading: false,
      };

    case financeConstant.FINANCE_REQUESTS_FAILURE:
      return {
        ...state,
        financeRequestsLoading: false,
        financeRequestsError: action.payload,
      };
    case financeConstant.CREATE_FINANCE_REQUEST:
      return { ...state, newFinanceRequestLoading: true };

    case financeConstant.CREATE_FINANCE_SUCCESS:
      return {
        ...state,
        newFinanceRequestData: action.payload,
        newFinanceRequestLoading: false,
        newFinanceRequestSuccess: true,
      };

    case financeConstant.CREATE_FINANCE_FAILURE:
      return {
        ...state,
        newFinanceRequestLoading: false,
        newFinanceRequestError: action.payload,
      };
    case financeConstant.VERIFY_BVN_REQUEST:
      return { ...state, verifyBVNLoading: true };

    case financeConstant.VERIFY_BVN_SUCCESS:
      return {
        ...state,
        financeProfile: action.payload,
        verifyBVNLoading: false,
      };

    case financeConstant.VERIFY_BVN_FAILURE:
      return {
        ...state,
        verifyBVNLoading: false,
        verifyBVNError: action.payload,
      };

    case financeConstant.VERIFY_CAC_REQUEST:
      return { ...state, verifyCACLoading: true };

    case financeConstant.VERIFY_CAC_SUCCESS:
      return {
        ...state,
        financeProfile: action.payload,
        verifyCACLoading: false,
      };

    case financeConstant.VERIFY_CAC_FAILURE:
      return {
        ...state,
        verifyCACLoading: false,
        verifyCACError: action.payload,
      };
    case financeConstant.UPDATE_FINANCE_PROFILE_REQUEST:
      return {
        ...state,
        updateFinanceProfileLoading: true,
      };
    case financeConstant.UPDATE_FINANCE_PROFILE_SUCCESS:
      return {
        ...state,
        updateFinanceProfileLoading: false,
        financeProfile: { ...action.payload },
      };
    case financeConstant.UPDATE_FINANCE_PROFILE_FAILURE:
      return {
        ...state,
        updateFinanceProfileLoading: false,
      };
    case financeConstant.UPDATE_FINANCE_PROFILEIMAGE_REQUEST:
      return {
        ...state,
        updateFinanceProfileImageLoading: true,
      };
    case financeConstant.UPDATE_FINANCE_PROFILEIMAGE_SUCCESS:
      return {
        ...state,
        updateFinanceProfileImageLoading: false,
        financeProfile: { ...state.financeProfile, ...action.payload },
      };
    case financeConstant.UPDATE_FINANCE_PROFILEIMAGE_FAILURE:
      return {
        ...state,
        updateFinanceProfileImageLoading: false,
      };
    case financeConstant.UPLOAD_CACDOC_REQUEST:
      return {
        ...state,
        uploadCACDOCLoading: true,
        uploadCACDOCError: "",
      };
    case financeConstant.UPLOAD_CACDOC_SUCCESS:
      return {
        ...state,
        uploadCACDOCLoading: false,
        financeProfile: action.payload,
      };
    case financeConstant.UPLOAD_CACDOC_ERROR:
      return {
        ...state,
        uploadCACDOCLoading: false,
        uploadCACDOCError: action.payload,
      };
    case financeConstant.UPLOAD_MOADOC_REQUEST:
      return {
        ...state,
        uploadMOADOCLoading: true,
        uploadMOADOCError: "",
      };
    case financeConstant.UPLOAD_MOADOC_SUCCESS:
      return {
        ...state,
        uploadMOADOCLoading: false,
        financeProfile: action.payload,
      };
    case financeConstant.UPLOAD_MOADOC_ERROR:
      return {
        ...state,
        uploadMOADOCLoading: false,
        uploadMOADOCError: action.payload,
      };
    case financeConstant.UPLOAD_DIRECTORID_REQUEST:
      return {
        ...state,
        uploadDirectorIDLoading: true,
        uploadDirectorIDError: "",
      };
    case financeConstant.UPLOAD_DIRECTORID_SUCCESS:
      return {
        ...state,
        uploadDirectorIDLoading: false,
        financeProfile: action.payload,
      };
    case financeConstant.UPLOAD_DIRECTORID_ERROR:
      return {
        ...state,
        uploadDirectorIDLoading: false,
        uploadDirectorIDError: action.payload,
      };
    case financeConstant.UPLOAD_AUDITDOC_REQUEST:
      return {
        ...state,
        uploadAuditLoading: true,
        uploadAuditError: "",
      };
    case financeConstant.UPLOAD_AUDITDOC_SUCCESS:
      return {
        ...state,
        uploadAuditLoading: false,
        financeProfile: action.payload,
      };
    case financeConstant.UPLOAD_AUDITDOC_ERROR:
      return {
        ...state,
        uploadAuditDOCLoading: false,
        uploadAuditDOCError: action.payload,
      };
    case financeConstant.UPLOAD_NEPCDOC_REQUEST:
      return {
        ...state,
        uploadNEPCDOCLoading: true,
        uploadAuditError: "",
      };
    case financeConstant.UPLOAD_NEPCDOC_SUCCESS:
      return {
        ...state,
        uploadNEPCDOCLoading: false,
        financeProfile: action.payload,
      };
    case financeConstant.UPLOAD_NEPCDOC_ERROR:
      return {
        ...state,
        uploadNEPCDOCLoading: false,
        financeProfile: action.payload,
      };
    case financeConstant.SET_FINANCE_REQ_STARTED:
      return {
        ...state,
        financeRedStarted: action.payload,
      };
    case financeConstant.SHIPMENT_TO_REQUEST_FINANCE:
      return {
        ...state,
        shipmentToRequestFinance: action.payload,
      };
    case financeConstant.SHIPMENT_TO_PREVIEW:
      return {
        ...state,
        shipmentToPreview: action.payload,
      };
    case financeConstant.IS_FINAL_SUCCESS_REQUEST:
      return {
        ...state,
        finalSuccessRequest: "successful",
      };
    case financeConstant.IS_FINAL_SUCCESS_REQUEST_DONE:
      return {
        ...state,
        finalSuccessRequest: "",
      };
    default:
      return state;
  }
}
