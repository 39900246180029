import React from "react";

const Description = ({ shipment, onChange }) => {
  return (
    <>
      <p className="text-sm sm:text-xs font-bold mb-3 sm:mb-2 text-[#3E3E3E] shipment-description after:content-['*'] after:top-[3px] relative after:absolute after:text-[13px] after:ml-1 after:text-red-500">
        Description
      </p>
      <textarea
        name="description"
        onChange={onChange}
        value={shipment?.description || ""}
        className="w-full bg-[#F5F5F5] placeholder:text-[#BBBBBB] mb-4 sm:mb-8 rounded-xl border border-[#DDDDDD] resize-none outline-none p-4 text-sm min-h-[8rem]"
        placeholder="In a few words, tell us what you're shipping"
      ></textarea>
    </>
  );
};

export default Description;
