import moment from "moment";
import React from "react";
import Note from "../../assets/images/N.png";

const FinanceActivities = ({ createdAt, currentStatus, mvxid }) => {
  const status = currentStatus?.toLowerCase();
  return (
    <div className="flex flex-row justify-between">
      <div className="w-2/3 pr-4">
        <div className="text-[14px] flex flex-row">
          <img
            alt="MVX Activity"
            src={Note}
            className="w-[27px] h-[28px] !leading-4"
          />
          <span className="ml-[10px] text-[14px] font-medium">
            Freight finance requested for MVXT{mvxid}
          </span>
        </div>

        <p className="text-[10px] font-medium text-mvx-light-gray  ml-[37px]">
          Date: {moment(createdAt).format("DD/MM/YYYY")}
        </p>
      </div>
      {status === "pending" ? (
        <div className="bg-[#FF8A002E] flex items-center py-[8px] px-[15px] rounded-[40px] text-[14px] font-semibold text-[#FF8A00] self-center capitalize">
          {status}
        </div>
      ) : status === "completed" ? (
        <div className="bg-mvx-light-green flex items-center text-[#40A81B] py-[8px] px-[15px] rounded-[40px] text-[14px] font-semibold self-center capitalize">
          {status}
        </div>
      ) : status === "rejected" ? (
        <div className="bg-[#f5c2c2] flex items-center text-[#F90000] py-[8px] px-[15px] rounded-[40px] text-[14px] font-semibold self-center capitalize">
          {status}
        </div>
      ) : (
        <div className="flex items-center text-mvx-blue py-[8px] px-[15px] rounded-[40px] text-[14px] font-semibold bg-[#F5F8FF] self-center capitalize">
          {status}
        </div>
      )}
    </div>
  );
};

export default FinanceActivities;
