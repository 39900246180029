import { notificationsConstant } from "constants";

const initialState = {
  notifications: [],
  isNoMoreNotifications: false,
  notificationsLoading: false,
  notificationsError: "",
  totalActivities: 0,
  notificationsTab: 1,
  // showNotificationsResult: false,
  markAllNotificationsSuccess: "",
  toggleUpdateMobileNotificationModal: false,
  mobileNotificationToUpdate: null,
};

export function notifications(state = initialState, action) {
  switch (action.type) {
    case notificationsConstant.CHANGE_NOTIFICATION_TABS:
      return {
        notificationsTab: action.payload,
      };
    case notificationsConstant.NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notificationsLoading: true,
        notificationsError: "",
      };
    case notificationsConstant.NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsLoading: false,
        notifications: [...state.notifications, ...action.payload.data],
        isNoMoreNotifications:
          action.payload.total_pages === action.payload.currentPage,
        totalActivities: action.payload.total_activities,
      };
    case notificationsConstant.NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notificationsLoading: false,
        notificationsError: action.payload,
      };
    case notificationsConstant.MARK_ALL_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notificationsLoading: true,
      };
    case notificationsConstant.MARK_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsLoading: false,
        showNotificationsResult: true,
        markAllNotificationsSuccess:
          action.payload.message && "All activities marked as read.",
      };
    case notificationsConstant.HIDE_NOTIFICATIONS_SUCCESS_MODAL:
      return {
        ...state,
        showNotificationsResult: false,
      };
    case notificationsConstant.NOTIFICATIONS_FAILURE_INFO:
      return {
        ...state,
        notificationsLoading: false,
      };
    case notificationsConstant.SET_NOTIFICATION_TO_EMPTY:
      return {
        ...state,
        notifications: [],
      };
    case notificationsConstant.MOBILE_NOTIFICATION_TO_UPDATE:
      return {
        ...state,
        toggleUpdateMobileNotificationModal: action.payload.toggleModal,
        mobileNotificationToUpdate: action.payload.notification,
      };
    default:
      return state;
  }
}
