import { authActions } from "actions/authActions";
import CustomToast from "components/common/CustomToast";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { authService } from "services";
import countries from "variables/countries";
import VerifyEmail from "./VerifyEmail";

const Signup = ({ onFocus, focusedInput }) => {
  const [step, setStep] = useState(0);
  const [OTP, setOTP] = useState("");
  const [signUpData, setSignupData] = useState(null);
  const dispatch = useDispatch();
  const [registerLoading, setRegisterLoading] = useState(false);
  const { verifyUserLoading, verifyUserSuccess } = useSelector(
    (state) => state.auth
  );
  const { register, handleSubmit, getValues } = useForm({
    defaultValues: { business_industry: "" },
  });

  const signUpUser = async (userObj) => {
    setRegisterLoading(true);
    try {
      await authService.register(userObj);
      setStep(1);
      setRegisterLoading(false);
    } catch (error) {
      setRegisterLoading(false);
      if (error.status === 409) {
        if (step) {
          toast.custom((t) => (
            <CustomToast t={t} message={"OTP resent successfully!"} />
          ));
        } else {
          setStep(1);
        }
      } else {
        if (error.message) {
          toast.custom((t) => (
            <CustomToast t={t} message={error.message + "!"} type="error" />
          ));
        }
      }
    }
  };

  const goBackToEmail = () => {
    setStep(0);
    onFocus("work-email");
  };

  const resendOTP = () => {
    signUpData?.email && signUpUser(signUpData);
  };

  const registerUser = (data) => {
    if (step === 0) {
      const _data = {
        ...data,
        mobile: data.mobile_extension + data.raw_mobile,
      };

      signUpUser(_data);
      setSignupData(_data);
      return;
    }

    if (step === 1) {
      signUpUser(signUpData);
    }
  };

  const verifyUser = () => {
    if (OTP.length === 6) {
      dispatch(
        authActions.verifyUser({ email: getValues("email"), code: OTP })
      );
    }
  };

  useEffect(() => {
    dispatch(authActions.resetVerifySuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (verifyUserSuccess) {
      window.location.href = "/enter";
    }
  }, [verifyUserSuccess]);

  return (
    <>
      {!step ? (
        <>
          {" "}
          <p className="text-lg font-bold mb-6">Sign up</p>
          <form onSubmit={handleSubmit(registerUser)}>
            <div className="flex flex-col gap-4 md:gap-3 md:flex-row mb-4 md:mb-4">
              <div className="w-full md:w-1/2">
                <p className="text-xs font-bold mb-2">First name</p>
                <input
                  type="text"
                  className="border bg-[#F5F5F5] border-gray-200 rounded p-3 outline-0 w-full h-[50px] text-sm"
                  placeholder="First Name"
                  onFocus={() => onFocus("first-name")}
                  onTouchStart={() => onFocus("first-name")}
                  readOnly={focusedInput !== "first-name"}
                  required
                  {...register("first_name", { required: true })}
                />
              </div>
              <div className="w-full md:w-1/2">
                <p className="text-xs font-bold mb-2">Last name</p>
                <input
                  type="text"
                  className="border bg-[#F5F5F5] border-gray-200 rounded p-3 outline-0 w-full h-[50px] text-sm"
                  placeholder="Last Name"
                  onFocus={() => onFocus("last-name")}
                  onTouchStart={() => onFocus("last-name")}
                  readOnly={focusedInput !== "last-name"}
                  required
                  {...register("last_name", { required: true })}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 md:gap-3 mb-3 md:mb-4">
              <div className="w-full md:w-1/2">
                <p className="text-xs font-bold mb-2">Business Name</p>
                <input
                  type="text"
                  className="border bg-[#F5F5F5] border-gray-200 rounded p-3 outline-0 w-full h-[50px] text-sm"
                  placeholder="Enter business name"
                  onFocus={() => onFocus("company-name")}
                  onTouchStart={() => onFocus("company-name")}
                  readOnly={focusedInput !== "company-name"}
                  required
                  {...register("company_name", { required: true })}
                />
              </div>
              <div className="w-full md:w-1/2">
                <p className="text-xs font-bold mb-2">Industry</p>
                <select
                  className="border bg-[#F5F5F5] border-gray-200 rounded p-3 outline-0 w-full h-[50px] text-sm appearance-none invalid:!text-gray-400"
                  // onFocus={() => onFocus("business_industry")}
                  // readOnly={focusedInput !== "business_industry"}
                  // placeholder="Select business industry"
                  required
                  {...register("business_industry", { required: true })}
                >
                  <option value="" disabled className="!text-gray-400">
                    Select business industry
                  </option>
                  <option value="Energy (Oil and Gas)">
                    Energy (Oil and Gas){" "}
                  </option>
                  <option value="Power">Power</option>
                  <option value="Construction">Construction</option>
                  <option value="Engineering">Engineering</option>
                  <option value="FMCG">FMCG</option>
                  <option value="Tech/IT">Tech/IT</option>
                  <option value="Agriculture">Agriculture</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Pharmaceuticals">Pharmaceuticals</option>
                  <option value="Minerals/Metal">Minerals/Metal</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="my-4 w-full">
              <p className="text-xs font-bold mb-2">Work email</p>
              <input
                type="email"
                className="border bg-[#F5F5F5] border-gray-200 rounded p-3 outline-0 w-full h-[50px] text-sm"
                placeholder="Work Email"
                onFocus={() => onFocus("work-email")}
                onTouchStart={() => onFocus("work-email")}
                readOnly={focusedInput !== "work-email"}
                required
                {...register("email", { required: true })}
              />
            </div>
            <p className="text-xs font-bold my-2">Phone number</p>
            <div className="w-full shrink-0 h-14 border border-gray-200 mb-4 rounded-md bg-white flex items-center bg-[#F5F5F5]">
              <select
                className={`h-full border-none bg-transparent pl-[10px] w-24 appearance-none outline-0 text-sm`}
                {...register("mobile_extension", { required: true })}
              >
                <option value="+234">+234</option>
                {countries.map((t, i) => (
                  <optgroup key={i} label={t.name}>
                    {t.codes.map((o, j) => (
                      <option value={`${o}`} key={j}>
                        {o}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
              <div className="h-3/5 w-px bg-gray-300 ml-2"></div>
              <input
                type="tel"
                className="py-3 pr-3 pl-4 outline-0 text-sm bg-inherit"
                placeholder="Phone Number"
                required
                {...register("raw_mobile", { required: true })}
              />
            </div>
            <div className="w-full">
              <p className="text-xs font-bold mb-2">Password</p>
              <input
                type="password"
                className="border bg-[#F5F5F5] border-gray-200 mb-4 rounded p-3 outline-0 w-full h-[50px] text-sm"
                placeholder="Enter password"
                onFocus={() => onFocus("password")}
                onTouchStart={() => onFocus("password")}
                readOnly={focusedInput !== "password"}
                required
                {...register("password", { required: true })}
              />
            </div>
            <p className="text-xs mt-2 mb-8 text-gray-500">
              By clicking continue, you confirm you have read and agreed our{" "}
              <span
                className={`text-mvx-blue cursor-pointer ${
                  registerLoading
                    ? "pointer-events-none"
                    : "pointer-events-auto"
                }`}
              >
                Terms & Conditions
              </span>
            </p>
            <button
              type="submit"
              className={`bg-mvx-blue shadow-lg outline-0 rounded w-full h-[50px] text-white text-md mb-3 font-bold ${
                registerLoading ? "!bg-mvx-light-blue" : ""
              }`}
              disabled={registerLoading}
            >
              {registerLoading ? "Signing up..." : "Sign up"}
            </button>
          </form>
          <p className="w-full text-center text-sm mt-2 mb-4 text-gray-500">
            I have an MVX account{" "}
            <Link
              to="/enter"
              className={`${
                registerLoading ? "pointer-events-none" : "pointer-events-auto"
              }`}
            >
              <span className="text-mvx-blue cursor-pointer font-bold underline ml-1">
                Login
              </span>
            </Link>
          </p>
        </>
      ) : (
        <VerifyEmail
          OTP={OTP}
          setOTP={setOTP}
          VerifyEmail={verifyUser}
          type="signUp"
          goBackFunc={goBackToEmail}
          resendOTP={resendOTP}
          loading={(registerLoading && !step) || verifyUserLoading}
          email={signUpData?.email}
        />
      )}
    </>
  );
};

export default Signup;
