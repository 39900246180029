export const notificationsConstant = {
  NOTIFICATIONS_REQUEST: "NOTIFICATIONS_REQUEST",
  NOTIFICATIONS_SUCCESS: "NOTIFICATIONS_SUCCESS",
  NOTIFICATIONS_FAILURE: "NOTIFICATIONS_FAILURE",
  NOTIFICATIONS_FAILURE_INFO: "NOTIFICATIONS_FAILURE_INFO",
  SET_NOTIFICATION_TO_EMPTY: "SET_NOTIFICATION_TO_EMPTY",
  CHANGE_NOTIFICATION_TABS: "CHANGE_NOTIFICATION_TABS",
  MARK_ALL_NOTIFICATIONS_REQUEST: "MARK_ALL_NOTIFICATIONS_REQUEST",
  MARK_ALL_NOTIFICATIONS_SUCCESS: "MARK_ALL_NOTIFICATIONS_SUCCESS",
  HIDE_NOTIFICATIONS_SUCCESS_MODAL: "HIDE_NOTIFICATIONS_SUCCESS_MODAL",
  MOBILE_NOTIFICATION_TO_UPDATE: "MOBILE_NOTIFICATION_TO_UPDATE",
};
