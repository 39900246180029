import { appConstants } from "constants";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const RandomAddLogo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <div
      className={`fixed right-[30px] bottom-[150px] flex justify-center items-center w-14 h-14 rounded-full sm:hidden bg-mvx-blue z-[15000] !text-white !font-bold cursor-pointer ${
        location.pathname.includes("create-shipment") && "hidden"
      }`}
      onClick={() => {
        navigate("/dashboard/create-shipment");
        dispatch({ type: appConstants.TOGGLE_NAV_MODAL, payload: false });
      }}
    >
      <span className="material-icons">add</span>
    </div>
  );
};

export default RandomAddLogo;
