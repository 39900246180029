import React, { useEffect, useMemo, useRef, useState } from "react";
import { MapContainer } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import AutocompleteInput from "components/common/AutocompleteInput";
import { SingleDatePicker } from "react-dates";
import { currentENVData } from "helpers";
import moment from "moment";
import RoutingMachine from "components/common/RoutingMachine";

// TODO : fix some locations without routing
const Location = ({ shipment, setShipment }) => {
  const [pickupDate, setPickupDate] = useState(null);
  const [focused, setFocused] = useState(false);

  const rMachine = useRef();

  const pointsToUse = useMemo(() => {
    return [
      [shipment?.origin?.lat ?? 0, shipment?.origin?.lng ?? 0],
      [shipment?.destination?.lat ?? 0, shipment?.destination?.lng ?? 0],
    ];
  }, [shipment]);

  useEffect(() => {
    if (pickupDate) {
      setFocused(false);
    }
  }, [pickupDate]);

  useEffect(() => {
    if (shipment?.shipping_date) {
      setPickupDate(moment(shipment?.shipping_date));
    }
  }, [shipment]);

  useEffect(() => {
    if (rMachine.current) {
      rMachine.current.setWaypoints(pointsToUse);
    }
  }, [pointsToUse, rMachine]);

  return (
    <>
      <div className="bg-black w-full h-[320px] mb-8 rounded-xl overflow-hidden relative">
        <MapContainer
          center={[51.505, -0.09]}
          zoom={13}
          zoomControl={false}
          scrollWheelZoom={false}
        >
          <ReactLeafletGoogleLayer
            apiKey={currentENVData.googleAPIKey}
            type={"roadmap"}
          />
          {(shipment?.origin || shipment?.destination) && (
            <RoutingMachine ref={rMachine} waypoints={pointsToUse} />
          )}
        </MapContainer>
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-4 sm:gap-8 mb-4 address-errors">
        <div className="w-full">
          <p className="text-xs font-bold mb-2 text-[#3E3E3E] relative after:content-['*'] after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500">
            Pickup address
          </p>
          <div className="flex">
            <AutocompleteInput
              bg="#F5F5F5"
              border="#DDDDDD"
              name="origin"
              savedAddress={shipment?.origin?.address}
              setData={setShipment}
            />
          </div>
        </div>
        <div className="w-full">
          <p className="text-xs font-bold mb-2 text-[#3E3E3E] relative after:content-['*'] after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500">
            Destination
          </p>
          <div className="flex">
            <AutocompleteInput
              bg="#F5F5F5"
              savedAddress={shipment?.destination?.address}
              border="#DDDDDD"
              name="destination"
              setData={setShipment}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-4 sm:gap-8 sm:flex-row">
        <div className="w-full date-picker">
          <p className="text-xs font-bold mb-2 text-[#3E3E3E] relative after:content-['*'] after:top-[3px] after:absolute after:text-[13px] after:ml-1 after:text-red-500">
            Pickup date
          </p>
          <div className="flex mb-8 w-full">
            <SingleDatePicker
              date={pickupDate} // momentPropTypes.momentObj or null
              onDateChange={(date) => {
                setPickupDate(date);
                setShipment((s) => ({ ...s, shipping_date: date?.format() }));
              }} // PropTypes.func.isRequired
              focused={focused} // PropTypes.bool
              onFocusChange={({ focused }) => setFocused(true)} // PropTypes.func.isRequired
              id="mvx_unique_id"
              placeholder="Select Date"
              numberOfMonths={1}
              hideKeyboardShortcutsPanel={true}
              showClearDate={true}
            />
          </div>
        </div>
        <div className="w-full"></div>
      </div>
    </>
  );
};

export default Location;
