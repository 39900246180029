import {
  Facebook,
  Instagram,
  LinkedIn,
  Logo,
  LogoBlack,
  Medium,
  Twitter,
} from "assets/arts";
import gotoAddShipment from "helpers/gotoAddShipment";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Footer = () => {
  const navigate = useNavigate();

  const home = () => navigate("/");
  const about = () => navigate("/about");
  const partners = () => navigate("/partners");
  const careers = () => {
    window.open("https://mvxchange-limited.breezy.hr/", "_blank");
  };
  const freightForwarding = () => navigate("/freight-forwarding");
  const customs = () => navigate("/customs");
  const freightFinance = () => navigate("/freight-finance");
  const haulage = () => navigate("/haulage");
  const trackShipment = () => {
    window.open("https://app.mvx.trade/track-shipment", "_blank");
  };
  const feedback = () => navigate("/speak-to-us");
  const referral = () => {
    window.open("https://app.mvx.trade/referral", "_blank");
  };
  const privacy = () => {
    window.open("https://app.mvx.trade/privacy-policy", "_blank");
  };
  const cookies = () => {
    window.open("https://app.mvx.trade/privacy-policy#cookies", "_blank");
  };
  const terms = () => {
    window.open("https://app.mvx.trade/terms-and-conditions", "_blank");
  };

  return (
    <FooterContainer className="pt-20">
      <div className="w-[90%] sm:w-4/5 m-auto">
        <div className="flex flex-wrap sm:flex-nowrap justify-center sm:justify-between px-4 sm:px-0 m-auto pb-10 sm:pb-20 border-b border-gray mb-10 sm:mb-20">
          <div className="pr-0 sm:pr-20 mb-10 sm:mb-0 text-center sm:text-left">
            <p className="text-2xl font-bold mb-2">
              Ready to Unlock Supply Chain Opportunities?
            </p>
            <p className="font-light text-sm">
              Join 300+ top brands that have trusted us with their freight
              forwarding
            </p>
          </div>
          <button
            type="button"
            onClick={gotoAddShipment}
            className="bg-mvx-blue2 text-white outline-0 rounded-xl h-[50px] px-10 text-sm"
          >
            Get started
          </button>
        </div>
        <div className="flex flex-wrap sm:flex-nowrap pb-10 sm:pb-14 border-b border-gray">
          <div className="w-[90%] m-auto sm:w-[25%] shrink-0 order-2 sm:order-1">
            <Logo className="h-[25px] hidden sm:block w-auto mb-4" />
            <div className="flex sm:block">
              <p className="font-semibold text-xs mb-2 w-1/2 sm:w-full">
                T: +2347040119703
              </p>
              <p className="font-semibold text-xs mb-4 w-1/2 sm:w-full">
                E: hello@mvx.trade
              </p>
            </div>
            <div className="text-center sm:text-left pt-6 sm:pt-0">
              <p className="font-semibold text-xs mb-2">Austin:</p>
              <p className="font-light text-xs mb-4">
                7000 North Mopac Expressway,
                <br />
                Austin, TX, 78731
                <br />
                T: +1 512 514 6221
              </p>
              <p className="font-semibold text-xs mb-2">Lagos:</p>
              <p className="font-light text-xs mb-4">
                19 Dr. S Ezekuse Close,
                <br />
                Lekki Phase 1, Lagos
              </p>
            </div>
          </div>
          <div className="w-[90%] m-auto sm:w-full flex flex-wrap sm:flex-nowrap order-1 sm:order-2">
            <div className="w-1/2 sm:w-1/4 shrink-0 mb-10 sm:mb-0">
              <p className="text-md font-bold text-mvx-blue2 mb-4">Home</p>
              <p
                onClick={home}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Home
              </p>
              <p
                onClick={about}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                About
              </p>
              <p
                onClick={partners}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Partners
              </p>
              <p
                onClick={careers}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Careers
              </p>
            </div>
            <div className="w-1/2 sm:w-1/4 shrink-0 mb-10 sm:mb-0">
              <p className="text-md font-bold text-mvx-blue2 mb-4">Services</p>
              <p
                onClick={freightForwarding}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Freight Forwarding
              </p>
              <p
                onClick={customs}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Customs
              </p>
              <p
                onClick={freightFinance}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Freight Finance
              </p>
              <p
                onClick={haulage}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Land Haulage
              </p>
              <p
                onClick={trackShipment}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Track Shipment
              </p>
            </div>
            <div className="w-1/2 sm:w-1/4 shrink-0 mb-10 sm:mb-0">
              <p className="text-md font-bold text-mvx-blue2 mb-4">Resources</p>
              <p
                id="my_custom_link"
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Help & Support
              </p>
              <p
                onClick={feedback}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Feedback
              </p>
              <p
                onClick={referral}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Referral
              </p>
            </div>
            <div className="w-1/2 sm:w-1/4 shrink-0 mb-10 sm:mb-0">
              <p className="text-md font-bold text-mvx-blue2 mb-4">Legal</p>
              <p
                onClick={privacy}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Privacy policy
              </p>
              <p
                onClick={cookies}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Cookie policy
              </p>
              <p
                onClick={terms}
                className="text-xs mb-4 cursor-pointer font-light"
              >
                Terms & Conditions
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap sm:flex-nowrap justify-between pt-8 pb-14">
          <div className="flex flex-wrap sm:flex-nowrap items-center w-full sm:w-auto">
            <LogoBlack className="h-[25px] m-auto sm:mr-6 mb-6 sm:mb-0" />
            <div className="flex items-center justify-center w-full sm:w-auto mb-6 sm:mb-0">
              &copy;
              <p className="text-xs ml-0 sm:ml-2 text-center sm:text-left">
                MVX Incorporated
              </p>
            </div>
          </div>
          <div className="flex items-center w-full sm:w-auto justify-center sm:justify-start">
            <a
              href="https://facebook.com/mvxhq/"
              rel="noreferrer"
              target="_blank"
            >
              <Facebook className="w-auto h-[15px] cursor-pointer mr-4" />
            </a>
            <a
              href="https://www.instagram.com/mvx_hq/"
              rel="noreferrer"
              target="_blank"
            >
              <Instagram className="w-auto h-[15px] cursor-pointer mr-4" />
            </a>
            <a
              href="https://mvx-hq.medium.com/"
              rel="noreferrer"
              target="_blank"
            >
              <Medium className="w-auto h-[15px] cursor-pointer mr-4" />
            </a>
            <a
              href="https://www.linkedin.com/company/mvx-hq/"
              rel="noreferrer"
              target="_blank"
            >
              <LinkedIn className="w-auto h-[15px] cursor-pointer mr-4" />
            </a>
            <a
              href="https://twitter.com/mvx_hq"
              rel="noreferrer"
              target="_blank"
            >
              <Twitter className="w-auto h-[15px] cursor-pointer" />
            </a>
          </div>
        </div>
      </div>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  background: rgb(0 94 205 / 0.05);
`;

export default Footer;
