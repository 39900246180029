import moment from "moment";

export const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
  let i;
  let beforeNow = [];
  for (i = moment().year(); i >= moment().year() - 50; i--) {
    beforeNow.push(
      <option value={i} key={`year-${i}`}>
        {i}
      </option>
    );
  }
  let afterNow = [];
  for (i = moment().year(); i <= moment().year() + 50; i++) {
    afterNow.push(
      <option value={i} key={`year-${i}`}>
        {i}
      </option>
    );
  }
  afterNow.reverse().pop();

  return (
    <div className="flex items-center justify-center gap-2 border border-gray-100 rounded-sm">
      <div>
        <select
          className="appearance-none font-bold text-[13.5px] bg-transparent outline-0 w-[100px]"
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label, value) => (
            <option value={value} className="text-[13px] font-medium p-1">
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select
          className="appearance-none text-[13px] bg-transparent w-[70px]  font-bold text-sm outline-0"
          value={month.year()}
          onChange={(e) => onYearSelect(month, e.target.value)}
        >
          {afterNow}
          {beforeNow}
        </select>
      </div>
    </div>
  );
};
