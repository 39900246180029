import { Stears, Techcabal, Techcrunch, Techpoint } from "assets/arts";

let news = [
  {
    url: "https://techcrunch.com/2021/08/05/nigerian-digital-freight-provider-mvx-lands-1-3m-to-help-shippers-move-cargoes-faster/",
    logo: <Techcrunch className="px-4 h-[32px] w-auto m-auto" />,
  },
  {
    url: "https://techpoint.africa/2021/08/05/mvx-1-3m-funding",
    logo: <Techpoint className="px-4 h-[65px] w-auto m-auto" />,
  },
  {
    url: "https://www.stearsng.com/premium/article/nigerias-uber-for-ships-pivots-bets-on-international-trade/",
    logo: <Stears className="px-4 h-[40px] w-auto m-auto" />,
  },
  {
    url: "https://techcabal.com/2021/08/06/nigerias-mvx-secures-1-3m-to-simplify-cargo-shipment-in-africa/",
    logo: <Techcabal className="px-4 h-[60px] w-auto m-auto" />,
  },
];

export default news;
