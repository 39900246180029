import PasswordPopUp from "./Customer/PasswordPopUp";
import { useDispatch, useSelector } from "react-redux";
import { authConstants } from "constants";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoginAndSecurity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { toggleUpdatePasswordModal } = useSelector((state) => state.auth);

  const togglePasswordModal = () => {
    dispatch({
      type: authConstants.TOGGLE_UPDATE_PASSWORD_MODAL,
      payload: true,
    });
  };

  useEffect(() => {
    dispatch({ type: authConstants.UPDATE_SUBUSER_STATES_TO_DEFAULT });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mt-12 mb-16 h-[calc(100vh-400px)]">
        <div className="text-[#333333] rounded-lg border border-[#dddddd] w-8/12 mx-auto hidden sm:block">
          <p className="font-bold rounded-t-lg border-b border-[#dddddd] bg-[#fbfbfb] py-5 px-8">
            Login and Security
          </p>
          <div className="px-8 pt-10 pb-16 space-y-5">
            <div className="space-y-1">
              <p className="font-bold text-[14.5px]">Password</p>
              <p className="font-medium text-[13px]">
                Protect your MVX account by using a strong password
              </p>
            </div>
            <button
              className="text-center px-6 py-2.5 text-[13px] font-medium bg-mvx-blue rounded-md text-white"
              onClick={togglePasswordModal}
            >
              Update Password
            </button>
          </div>
        </div>
        <div className="sm:hidden flex flex-col gap-4 text-[#333] mx-3 h-[50vh]">
          <p className="font-black text-lg">Password</p>
          <p className="font-medium text-[15px] max-w-[300px] leading-7">
            Protect your MVX account by using a strong password
          </p>
          <button
            className="text-center px-6 py-3.5 font-bold bg-mvx-blue rounded-md text-white w-[250px]"
            onClick={() =>
              navigate("/dashboard/profile-settings/update-password")
            }
          >
            Update Password
          </button>
        </div>
      </div>
      {toggleUpdatePasswordModal && <PasswordPopUp />}
    </>
  );
};

export default LoginAndSecurity;
