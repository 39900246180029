import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import {
  app,
  auth,
  analytics,
  notifications,
  shipments,
  partners,
  finance,
} from "reducers";

const appReducer = combineReducers({
  app,
  auth,
  analytics,
  notifications,
  shipments,
  partners,
  finance,
});

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "counter",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
