import { shipmentActions } from "actions";
import { VerifyAlertIcon, FileIcon, QuotesErrorIcon } from "assets/arts";
import Loading from "components/common/Loading";
import ModalContainer from "components/common/modals/ModalContainer";
import { shipmentsConstant } from "constants";
import { downloadURLFile } from "helpers";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Todos
/*
error on document load add error check,
 -- on error on page load
 fetch latest data on tab switch
*/

const Quotes = () => {
  const [active, setActive] = useState(0);
  const dispatch = useDispatch();
  const {
    quoteAccepted,
    acceptQuoteLoading,
    quoteRejected,
    rejectQuoteLoading,
    shipmentQuotes,
    shipmentQuotesLoading,
  } = useSelector((state) => state.shipments);
  const [modal, setModal] = useState(false);
  const [modalPage, setModalPage] = useState(0);
  const [quoteClickedType, setQuoteClickedType] = useState("");
  const [documentReady, setDocumentReady] = useState(false);
  const [selectQuoteID, setSelectedQouteID] = useState("");
  const [rejectValue, setRejectValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [rejectValueNotSelected, setRejectValueNotSelected] = useState(false);
  const [customReason, setCustomReason] = useState("");
  const documentWrapperRef = useRef();
  const params = useParams();
  const selectedQuote = useMemo(
    () => shipmentQuotes?.find((quote) => quote._id === selectQuoteID),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectQuoteID]
  );

  const acceptQuote = () =>
    dispatch(shipmentActions.acceptQuote(selectQuoteID));
  const rejectQuote = () =>
    dispatch(
      shipmentActions.rejectQuote(selectQuoteID, {
        rejectReasonUser: {
          reason: rejectValue,
          comment: customReason ?? "",
        },
      })
    );

  const resetModal = () => {
    setModal(false);
    setModalPage(0);
    setRejectValue("");
    setCustomReason("");
  };

  const notInteractedWith =
    selectedQuote?.acceptanceStatus !== "accepted" &&
    selectedQuote?.acceptanceStatus !== "rejected";

  const filteredQuotes = useMemo(() => {
    switch (active) {
      case 1:
        return shipmentQuotes?.filter(
          (quote) => quote.acceptanceStatus === "accepted"
        );

      case 2:
        return shipmentQuotes?.filter(
          (quote) => quote.acceptanceStatus === "rejected"
        );

      default:
        return shipmentQuotes?.filter(
          (quote) =>
            quote.acceptanceStatus !== "accepted" &&
            quote.acceptanceStatus !== "rejected"
        );
    }
  }, [active, shipmentQuotes]);

  const handleQuoteActionFirstStage = () => {
    quoteClickedType === "accept" ? acceptQuote() : setModalPage(2);
  };

  useEffect(() => {
    dispatch(shipmentActions.fetchShipmentQuotes(params?.shipmentId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, active]);

  useEffect(() => {
    if (quoteAccepted) {
      setModalPage(2);
      dispatch({ type: shipmentsConstant.ACCEPT_QUOTE_FAILURE });
    }

    if (quoteRejected) {
      setModalPage(3);
      dispatch({ type: shipmentsConstant.REJECT_QUOTE_FAILURE });
      setRejectValue("");
      setCustomReason("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteAccepted, quoteRejected]);

  return (
    <>
      <div className="mt-20 mb-[120px] min-h-[400px]">
        <div className="border-b border-[#c8c4c4] flex text-[#0E2B79] text-[13px] pt-8 pb-[17px] font-medium">
          <button
            className={`transition-[background] duration-200 py-[6px] px-4 rounded-full ${
              active === 0 && "bg-mvx-blue text-white font-semibold"
            }`}
            onClick={() => setActive(0)}
          >
            New Quotes
          </button>
          <button
            className={`ml-2 transition-[background] duration-200 py-[6px] px-4 rounded-full ${
              active === 1 && "bg-mvx-blue text-white font-semibold"
            }`}
            onClick={() => setActive(1)}
          >
            Accepted Quotes
          </button>
          <button
            className={`ml-2 transition-[background] duration-200 py-[6px] px-4 rounded-full ${
              active === 2 && "bg-mvx-blue text-white font-semibold"
            }`}
            onClick={() => setActive(2)}
          >
            Rejected Quotes
          </button>
        </div>

        <div
          className={`text-center flex-wrap mt-10 grid grid-cols-3 justify-items-start ${
            shipmentQuotesLoading && "relative pt-32"
          }`}
        >
          {shipmentQuotesLoading ? (
            <Loading size={12} color={"mvx-blue"} />
          ) : (
            <>
              {filteredQuotes?.length ? (
                filteredQuotes?.map((quote, idx) => {
                  const index = idx + 1;
                  return (
                    <div key={quote._id}>
                      <div className="w-[300px] h-[200px] grid place-items-center rounded-[8px] border-[2px] border-dashed bg-white">
                        <span className="font-medium mb-6">
                          <FileIcon className="w-[45px] mx-auto mb-2" />
                          {/* Tungrus...Freight.pdf */}
                          <p className="text-[15px]">
                            {quote.document.filename}
                          </p>
                        </span>
                      </div>
                      <p className="text-base font-semibold mt-4">
                        Quote #
                        {index >= 10
                          ? "0" + index
                          : index >= 100
                          ? index
                          : "00" + index}
                      </p>
                      <p className="text-[13px] text-gray-500 mt-[6px] mb-2">
                        {moment(quote.createdAt).format("ddd, Do MMMM, YYYY")}
                      </p>
                      <p
                        className="underline text-mvx-blue font-semibold text-[13px] cursor-pointer"
                        onClick={() => {
                          setSelectedQouteID(quote._id);
                          setModal(true);
                        }}
                      >
                        View Quote
                        <span className="material-icons !text-base translate-y-1 translate-x-1">
                          north_east
                        </span>
                      </p>
                    </div>
                  );
                })
              ) : (
                <div className="col-span-3 w-full grid place-items-center mt-2">
                  <QuotesErrorIcon className="w-20 h-20" />
                  <p className="text-sm mt-6 text-[#6B6B6B]">
                    You don’t have any{" "}
                    {active === 2
                      ? "rejected"
                      : active === 1
                      ? "accepted"
                      : "new"}{" "}
                    quote
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {modal && selectedQuote && (
        <ModalContainer closeModal={resetModal}>
          {modalPage ? (
            <div className="bg-white w-[600px] mx-auto items-center rounded-[10px]">
              {modalPage === 1 && (
                <div className="text-center py-[90px]">
                  <VerifyAlertIcon className="mx-auto h-32 w-32" />
                  <p className="mt-4 text-lg font-semibold items-center px-20">
                    {quoteClickedType === "accept"
                      ? "Are you sure you want to accept this offer?"
                      : "Are you sure you want to reject this offer?"}
                  </p>

                  <div className="flex mt-14 mb-2 justify-center">
                    <button
                      className="bg-mvx-blue text-center py-2 w-[180px] text-white rounded-[10px] text-[16px] relative"
                      onClick={handleQuoteActionFirstStage}
                      disabled={acceptQuoteLoading}
                    >
                      {quoteClickedType === "accept" ? (
                        <>
                          {acceptQuoteLoading ? (
                            <Loading size={"6"} />
                          ) : (
                            "Yes, Accept"
                          )}
                        </>
                      ) : (
                        "Yes, Reject"
                      )}
                    </button>
                    <button
                      className="text-center py-2 w-[180px] text-[#8F8F8F] border border-[#6B6B6B] rounded-[10px] text-[16px] ml-[18px]"
                      onClick={resetModal}
                      disabled={acceptQuoteLoading}
                    >
                      No, Cancel
                    </button>
                  </div>
                </div>
              )}
              {modalPage === 2 && quoteClickedType === "accept" && (
                <div className="text-center py-[100px]">
                  <span
                    className={`material-icons text-center !text-[80px] !text-[#02C39A] mt-4 !leading-4 mr-1`}
                  >
                    check_circle
                  </span>
                  <p className="mt-4 text-lg font-semibold items-center px-20">
                    You have accepted our quote offer
                  </p>

                  <button
                    className="bg-mvx-blue text-center py-3 w-[160px] px-6 text-white rounded-[10px] text-[16px] mb-2 mt-14"
                    onClick={resetModal}
                  >
                    Close
                  </button>
                </div>
              )}
              {modalPage === 2 && quoteClickedType === "reject" && (
                <div
                  className={`text-center py-[35px] px-8 ${
                    rejectQuoteLoading && "pointer-events-none"
                  }`}
                >
                  <p className="mt-4 text-lg font-semibold items-center px-20 mb-8">
                    Select rejection reason
                  </p>

                  <div
                    className="border-b border-gray-400/30 flex items-center pb-[14px] cursor-pointer"
                    onClick={() => setRejectValue("Quote is too high")}
                  >
                    <span
                      className={`material-icons mr-2 !text-base !font-semibold ${
                        rejectValue === "Quote is too high"
                          ? "text-mvx-blue"
                          : "text-mvx-light-gray"
                      }`}
                    >
                      {rejectValue === "Quote is too high"
                        ? "radio_button_checked"
                        : "radio_button_unchecked"}
                    </span>
                    <span className="text-[13px] translate-y-[1px] -ml-[2px] text-[#091D51]">
                      Quote is too high
                    </span>
                  </div>
                  <div
                    className="border-b border-gray-400/30 flex items-center py-[14px] cursor-pointer"
                    onClick={() =>
                      setRejectValue("Quote does not capture what I want")
                    }
                  >
                    <span
                      className={`material-icons mr-2 !text-base !font-semibold ${
                        rejectValue === "Quote does not capture what I want"
                          ? "text-mvx-blue"
                          : "text-mvx-light-gray"
                      }`}
                    >
                      {rejectValue === "Quote does not capture what I want"
                        ? "radio_button_checked"
                        : "radio_button_unchecked"}
                    </span>
                    <span className="text-[13px] translate-y-[1px] -ml-[2px] text-[#091D51]">
                      Quote does not capture what I want
                    </span>
                  </div>
                  <div
                    className="border-b border-gray-400/30 flex items-center py-[14px] cursor-pointer"
                    onClick={() =>
                      setRejectValue(`I don’t understand the quote`)
                    }
                  >
                    <span
                      className={`material-icons mr-2 !text-base !font-semibold ${
                        rejectValue === `I don’t understand the quote`
                          ? "text-mvx-blue"
                          : "text-mvx-light-gray"
                      }`}
                    >
                      {rejectValue === `I don’t understand the quote`
                        ? "radio_button_checked"
                        : "radio_button_unchecked"}
                    </span>
                    <span className="text-[13px] translate-y-[1px] -ml-[2px] text-[#091D51]">
                      I don’t understand the quote
                    </span>
                  </div>
                  <div
                    className={`flex items-center py-[14px] cursor-pointer ${
                      rejectValue !== "custom" && "border-b border-gray-400/30"
                    }`}
                    onClick={() => setRejectValue("custom")}
                  >
                    <span
                      className={`material-icons mr-2 !text-base !font-semibold ${
                        rejectValue === "custom"
                          ? "text-mvx-blue"
                          : "text-mvx-light-gray"
                      }`}
                    >
                      {rejectValue === "custom"
                        ? "radio_button_checked"
                        : "radio_button_unchecked"}
                    </span>
                    <span className="text-[13px] translate-y-[1px] -ml-[2px] text-[#091D51]">
                      Custom
                    </span>
                  </div>
                  {rejectValue === "custom" && (
                    <textarea
                      className={`border border-[#DDDDDD] bg-[#F5F5F5] h-[129px] py-[16px] px-[20px] w-full rounded-[10px] text-[13px] resize-none focus:outline-none ${
                        customReason ? "text-[#091D51]" : "text-[#BBBBBB]"
                      }`}
                      placeholder="Enter reason here"
                      defaultValue={customReason}
                      onChange={(evt) => setCustomReason(evt.target.value)}
                    ></textarea>
                  )}
                  <span
                    className={`text-[13px] mt-[5px] text-red-500 font-medium block text-left translate-y-1 ${
                      rejectValueNotSelected ? "visible" : "invisible"
                    }`}
                  >
                    Please select a reason
                  </span>
                  <div className="flex mt-10 mb-4 justify-center">
                    <button
                      className={`text-center py-3 basis-[50%] text-white rounded-[10px] text-[16px] relative ${
                        customReason ||
                        (rejectValue !== "custom" && rejectValue)
                          ? "bg-mvx-blue"
                          : "bg-[#688EF6]"
                      }`}
                      onClick={rejectQuote}
                      disabled={
                        customReason ||
                        (rejectValue !== "custom" && rejectValue) ||
                        rejectQuoteLoading
                          ? false
                          : true
                      }
                    >
                      <>
                        {rejectQuoteLoading ? (
                          <Loading size={"6"} />
                        ) : (
                          "Submit Reason"
                        )}
                      </>
                    </button>
                    <button
                      className="text-center py-3 basis-[50%] text-[#8F8F8F] border border-[#6B6B6B] rounded-[10px] text-[16px] ml-[18px]"
                      onClick={resetModal}
                      disabled={rejectQuoteLoading}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              {modalPage === 3 && (
                <div className="text-center py-[100px]">
                  <span
                    className={`material-icons text-center !text-[80px] !text-red-500 mt-4 !leading-4 mr-1`}
                  >
                    cancel
                  </span>
                  <p className="mt-4 text-lg font-semibold items-center px-20">
                    You have rejected our quote offer
                  </p>

                  <button
                    className="bg-mvx-blue text-center py-3 w-[160px] px-6 text-white rounded-[10px] text-[16px] mt-14"
                    onClick={resetModal}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div
              className="w-[607px] h-[600px] overflow-y-auto relative"
              ref={documentWrapperRef}
            >
              <Document
                file={selectedQuote?.document?.path}
                loading={<Loading size={"[70px]"} />}
                onLoadSuccess={() => setDocumentReady(true)}
              >
                <span
                  className={`material-icons !text-[27px] !font-semibold cursor-pointer rounded-md p-1 bg-gray-700 text-white fixed hover:bg-gray-600 hover:shadow-lg focus:bg-gray-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-600 active:shadow-lg transition duration-150 ease-in-out${
                    documentWrapperRef.current &&
                    `top-[${documentWrapperRef.current.offsetTop}px] left-[${documentWrapperRef.current.offsetWidth}px]`
                  } z-40 border`}
                  onClick={() =>
                    downloadURLFile(
                      selectedQuote?.document?.path,
                      selectedQuote?.document?.filename
                    )
                  }
                >
                  download
                </span>
                <Page pageNumber={1} />
                {documentReady && (
                  <>
                    {!notInteractedWith ? (
                      <div className="flex justify-center font-semibold pt-4 pb-[25px] bg-white w-full sticky bottom-0 left-0">
                        <button
                          className="font-semibold rounded-[10px] border border-[#6B6B6B] py-[13px] px-[35px] text-[#6B6B6B] text-base"
                          onClick={() => setModal(false)}
                        >
                          Close Quote
                        </button>
                      </div>
                    ) : (
                      <div
                        className={`flex justify-center font-semibold pt-4 pb-[25px] bg-white w-full sticky bottom-0 left-0`}
                      >
                        <button
                          className="rounded-[10px] border bg-[#40A81B] py-[13px] w-32 text-white text-base"
                          onClick={() => {
                            setQuoteClickedType("accept");
                            setModalPage(1);
                          }}
                        >
                          Accept
                        </button>
                        <button
                          className="rounded-[10px] border border-[#F90000] py-[13px] w-32 text-[#6B6B6B] text-base text-[F90000] ml-3"
                          onClick={() => {
                            setQuoteClickedType("reject");
                            setModalPage(1);
                          }}
                        >
                          Reject
                        </button>
                      </div>
                    )}
                  </>
                )}
              </Document>
            </div>
          )}
        </ModalContainer>
      )}
    </>
  );
};

export default Quotes;
