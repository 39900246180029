/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { MapContainer, Marker, useMap } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import "styles/leaflet/leaflet.css";
import {
  ActiveShipmentIcon,
  MapEmptyIcon,
  MobileNoTrackerIcon,
  OutstandingPaymentsIcon,
  TotalLoansIcon,
} from "assets/arts";
import Trend from "./Trend";
import KYCImage from "assets/images/dashboard/kyc.svg";
import ActionButtons from "components/common/ActionButtons";
import KYCDialog from "components/Dashboard/common/KYCDialog";
import { currentENVData, isDateType } from "helpers";
import Skeleton from "components/common/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { shipmentActions } from "actions";
import { Icon } from "leaflet";
import styled from "styled-components";

import marker from "assets/images/dashboard/icons/location-marker.svg";
import { Link, useNavigate } from "react-router-dom";
import useMovementsLocations from "hooks/useMovementsLocations";
import { GoBackInfo } from "../common/MobileNav";
import RandomAddLogo from "../common/RandomAddLogo";
import TileBg from "assets/images/dashboard/tile.svg";

const mapMarker = new Icon({
  iconUrl: marker,
  iconSize: [40, 40],
});

const DashboardLanding = () => {
  const {
    userAnalytics,
    analyticsLoading,
    shipmentTrendsLoading,
    shipmentCount,
    shipmentCountLoading,
  } = useSelector((state) => state.analytics);
  const { user } = useSelector((state) => state.auth);
  const { activeShipments, movements, movementsLoading } = useSelector(
    (state) => state.shipments
  );
  const { updatedLocations } = useMovementsLocations(movements);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mapIndex, setMapIndex] = useState(0);
  const [clickShipments, setClickShipments] = useState(1);
  const [clickPayments, setClickPayments] = useState(1);
  const [clickLoans, setClickLoans] = useState(1);

  const currentMovement = (id) => movements?.find((mvm) => mvm._id === id);
  const currentShipment = (id) =>
    activeShipments?.find((shpmt) => shpmt._id === id);

  const trackShipment = () => {
    localStorage.setItem("movementTrackingId", movements[mapIndex]?._id);
    navigate(
      `/dashboard/shipment/${movements[mapIndex]?.shipment_id}/movements`
    );
  };

  const latLngUpdt = useMemo(() => {
    if (updatedLocations?.length) {
      return [
        updatedLocations[mapIndex].lat ?? 10,
        updatedLocations[mapIndex].lng ?? 10,
      ];
    } else {
      return [10, 10];
    }
  }, [updatedLocations, mapIndex]);

  useEffect(() => {
    if (activeShipments?.length) {
      const shipmentsWithMovementsIDs = activeShipments
        .filter((obj) => {
          if (obj.movement_count) {
            return obj._id;
          }
        })
        .map((obj) => obj._id);

      shipmentsWithMovementsIDs.forEach((id) =>
        dispatch(shipmentActions.fetchMovements(id))
      );
    }
  }, [activeShipments]);

  return (
    <>
      <GoBackInfo text="Dashboard" navigateTo="dashboard/home" />
      <RandomAddLogo />
      <div className="-ml-4 -mr-14 sm:ml-0 sm:mt-3 md:mt-0 sm:-mr-10 md:-mr-8 lg:mr-0">
        <div className="hidden sm:flex items-center gap-5 justify-between mb-8">
          <p className="text-xl font-bold">
            Welcome{user?.company_name && ", " + user?.company_name + "."}
          </p>
          <ActionButtons />
        </div>
        <div className="mt-16 sm:mt-0"></div>
        <KYCDialog
          bgImg={KYCImage}
          dialogText={
            "You are currently using a basic account, update your details to enjoy better and more personalized rates"
          }
          btnText={"Update KYC"}
        />
        <p className="sm:hidden text-xl font-bold mb-8">
          Welcome{user?.company_name && ", " + user?.company_name + "."}
        </p>
        <div className="overflow-y-hidden xl:overflow-visible flex pb-14 dashboardCardsContainer relative sm:pb-10">
          <Skeleton
            dataLoaded={!shipmentCountLoading}
            className={
              "h-[145px] lg:h-[150px] w-auto md:w-1/3 mr-4 lg:w-1/3 shrink-0 sm:shrink"
            }
          >
            <Tile url={TileBg} position="top right 50px" className="w-[250px] md:w-1/3 mr-4 lg:w-1/3 h-[145px] lg:h-[150px] rounded-2xl shadow-xl bg-[#1C56F2] text-white p-6 pt-4 pr-4 shrink-0 sm:shrink">
              <div className="text-sm font-bold flex items-start justify-between">
                <div className="flex items-center">
                  <ActiveShipmentIcon className="mr-2" />
                  {clickShipments === 1 && "Total Shipments"}
                  {clickShipments === 2 && "Pending Shipments"}
                  {clickShipments === 3 && "Active Shipments"}
                  {clickShipments === 4 && "Completed Shipments"}
                </div>
                <div className="relative group w-[30px] h-[30px] duration-300 select-none cursor-pointer hover:bg-white/[0.1] flex items-center justify-center rounded-full">
                  <span className="material-icons">more_vert</span>
                  <div className="absolute z-[1000] hidden group-hover:block shadow-[3px_4px_15px_0_#0000001A] bg-white rounded-md py-1.5 text-[12.75px] text-[#686868] font-medium w-[180px] top-full -left-7">
                    <p
                      className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1] hover:font-semibold"
                      onClick={() => setClickShipments(1)}
                    >
                      Total Shipments
                    </p>

                    <p
                      className="py-2 px-3 cursor-pointer hover:bg-[#f1f1f1] border-b border-grey-500 hover:font-semibold"
                      onClick={() => setClickShipments(2)}
                    >
                      Pending Shipments
                    </p>
                    <p
                      className="py-2 px-3 cursor-pointer hover:bg-[#f1f1f1] border-b border-grey-500 hover:font-semibold"
                      onClick={() => setClickShipments(3)}
                    >
                      Active Shipments
                    </p>
                    <p
                      className="py-2 px-3 cursor-pointer hover:bg-[#f1f1f1] hover:font-semibold"
                      onClick={() => setClickShipments(4)}
                    >
                      Completed Shipments
                    </p>
                  </div>
                </div>
              </div>
              <p className="text-2xl font-bold mt-4">
                {clickShipments === 1 && shipmentCount?.total_shipments}
                {clickShipments === 2 && shipmentCount?.pending_shipments}
                {clickShipments === 3 && shipmentCount?.active_shipments}
                {clickShipments === 4 && shipmentCount?.completed_shipments}
              </p>
            </Tile>
          </Skeleton>
          <Skeleton
            dataLoaded={!analyticsLoading}
            className={
              "h-[145px] lg:h-[150px] w-auto md:w-1/3 mr-4 lg:w-1/3 shrink md:shrink-0"
            }
          >
            <Tile url={TileBg} position="top left 40px" className="w-[250px] md:w-1/3 mr-4 lg:h-[150px] lg:w-1/3 h-[145px] rounded-2xl bg-white border border-[#E9E9E9] p-6 pt-4 pr-4 shrink-0 sm:shrink">
              <div className="text-sm font-bold flex items-start justify-between">
                <div className="flex items-center">
                  <TotalLoansIcon className="mr-2" />
                  {clickLoans === 1 && "Total Freight Loans"}
                  {clickLoans === 2 && "Pending Loans"}
                  {clickLoans === 3 && "Paid Loans"}
                </div>
                <div className="relative group w-[30px] h-[30px] duration-300 select-none cursor-pointer hover:bg-white/[0.1] flex items-center justify-center rounded-full">
                  <span className="material-icons">more_vert</span>
                  <div className="absolute z-[1000] hidden group-hover:block shadow-[3px_4px_15px_0_#0000001A] bg-white rounded-md py-1.5 text-[12.75px] text-[#686868] font-medium w-[180px] top-full -left-7">
                    <p
                      className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1] hover:font-semibold"
                      onClick={() => setClickLoans(1)}
                    >
                      Total Freight Loans
                    </p>
                    <p
                      className="py-2 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1] hover:font-semibold"
                      onClick={() => setClickLoans(2)}
                    >
                      Pending Loans
                    </p>
                    <p
                      className="py-2 px-3 cursor-pointer hover:bg-[#f1f1f1] hover:font-semibold"
                      onClick={() => setClickLoans(3)}
                    >
                      Paid Loans
                    </p>
                  </div>
                </div>
              </div>
              <p className="text-2xl font-bold mt-4">
                ${clickLoans === 1 && userAnalytics?.totalAmountRecieved}
                {clickLoans === 2 && userAnalytics?.activeBalance}
                {clickLoans === 3 && userAnalytics?.totalPaid}
              </p>
            </Tile>
          </Skeleton>
          <Skeleton
            dataLoaded={!analyticsLoading}
            className={
              "h-[145px] lg:h-[150px] w-[250px] md:w-1/3 lg:w-1/3 shrink-0 md:shrink-1 sm:shrink-2"
            }
          >
            <Tile url={TileBg} position="top left 40px" className="h-[145px] lg:h-[150px] w-[250px] shrink-0 md:shrink-1 md:w-1/3 lg:w-1/3  rounded-2xl bg-[#FF8A00]/[0.38] p-6 pt-4 pr-4 sm:shrink-2">
              <div className="text-sm font-bold flex items-start justify-between">
                <div className="flex items-center">
                  <OutstandingPaymentsIcon className="mr-2" />
                  {clickPayments === 1 && "Total Shipment Payment"}
                  {clickPayments === 2 && "Loan Payments"}
                </div>
                <div className="w-[30px] h-[30px] duration-300 select-none cursor-pointer hover:bg-black/[0.1] flex items-center justify-center rounded-full relative group">
                  <span className="material-icons">more_vert</span>
                  <div className="absolute z-[1000] hidden group-hover:block shadow-[3px_4px_15px_0_#0000001A] bg-white rounded-md py-1.5 text-[12.75px] text-[#686868] font-medium w-44 top-full right-0 last:border-0">
                    <p
                      className="py-2.5 px-3 cursor-pointer border-b border-grey-500 hover:bg-[#f1f1f1] hover:font-semibold"
                      onClick={() => setClickPayments(1)}
                    >
                      Total Shipment Payment
                    </p>

                    <p
                      className="py-2.5 px-3 cursor-pointer hover:bg-[#f1f1f1]  hover:font-semibold"
                      onClick={() => setClickPayments(2)}
                    >
                      Loan Payments
                    </p>
                  </div>
                </div>
              </div>
              <p className="text-2xl font-bold mt-4">
                ${clickPayments === 1 && userAnalytics?.activeBalance}
                {clickPayments === 2 && userAnalytics?.totalPaid}
              </p>
              <p className="text-[0.7rem] text-[#777] font-bold mt-2 font-light">
                Next Payment Date:{" "}
                <span
                  className={`font-bold text-black text-[${
                    !isDateType(userAnalytics?.nextRepaymentDate) && "10px"
                  }]`}
                >
                  {userAnalytics.nextRepaymentDate ?? "No repayment"}
                </span>
              </p>
            </Tile>
          </Skeleton>
        </div>

        <p className="font-extrabold text-lg sm:hidden text-[#333] mb-6">
          Track Shipments
        </p>
        <div>
          <Skeleton
            dataLoaded={updatedLocations}
            className={"w-full h-[350px] sm:h-[320px]"}
          >
            <div
              className={`w-full h-[400px] sm:h-[370px] rounded-xl overflow-hidden relative ${
                updatedLocations?.length
                  ? ""
                  : "bg-white flex flex-col items-center justify-center gap-2.5 text-center pt-[70px] pb-16"
              }`}
            >
              {updatedLocations?.length ? (
                <>
                  <MapContainer
                    center={latLngUpdt}
                    zoom={18}
                    scrollWheelZoom={true}
                    renderer={mapIndex}
                  >
                    <UpdateMapCentre mapCentre={latLngUpdt} />{" "}
                    <ReactLeafletGoogleLayer
                      apiKey={currentENVData.googleAPIKey}
                      type={"roadmap"}
                    />
                    <Marker position={latLngUpdt} icon={mapMarker} />
                  </MapContainer>

                  <div
                    className="w-[50px] h-[50px] bg-black/40 cursor-pointer absolute left-8 bottom-8 z-[999] rounded-xl flex items-center justify-center"
                    onClick={() =>
                      updatedLocations.length && mapIndex === 0
                        ? setMapIndex(updatedLocations.length - 1)
                        : setMapIndex((prev) => prev - 1)
                    }
                  >
                    <span className="material-icons text-white">west</span>
                  </div>
                  <div
                    className="w-[50px] h-[50px] bg-black/40 cursor-pointer absolute right-8 bottom-8 z-[999] rounded-xl flex items-center justify-center"
                    onClick={() =>
                      mapIndex === updatedLocations.length - 1
                        ? setMapIndex(0)
                        : setMapIndex((prev) => prev + 1)
                    }
                  >
                    <span className="material-icons text-white">east</span>
                  </div>
                </>
              ) : (
                <>
                  <MapEmptyIcon className="hidden sm:block w-[85px] h-[85px] -mb-4" />
                  <MobileNoTrackerIcon className="sm:hidden w-[55px] h-[55px]" />
                  <p className="hidden sm:block text-sm text-[#474747] max-w-[370px] font-medium leading-6">
                    You currently don’t have any active shipments. You will be
                    able to view live shipment information here
                  </p>
                  <p className="sm:hidden text-sm text-[#474747] max-w-[200px] font-medium">
                    You currently do not have any shipments created.
                  </p>
                  <Link
                    to={"/dashboard/create-shipment"}
                    className="hidden sm:block text-[13px] text-white bg-mvx-blue rounded-md py-[13px] px-[19px]"
                  >
                    Create a shipment
                  </Link>
                  <Link
                    to={"/dashboard/create-shipment"}
                    className="sm:hidden text-[13.5px] text-mvx-blue underline font-medium"
                  >
                    Create Shipment
                  </Link>
                </>
              )}
            </div>
          </Skeleton>

          {updatedLocations?.length > 0 && (
            <div className="!mt-6 flex flex-col gap-4 sm:gap-4 md:gap-0 md:flex-row w-full justify-between bg-white border border-gray py-4 px-8 rounded-xl">
              <div className="font-medium space-y-1.5">
                <p className="font-medium md:font-normal text-[14.5px] md:text-sm mb-2 skeleton-inline">
                  Shipment ID:{" "}
                  <span className="font-bold text-mvx-blue">
                    MVXT
                    {
                      currentShipment(
                        currentMovement(updatedLocations[mapIndex]?.id)
                          ?.shipment_id
                      )?.mvxid
                    }
                  </span>
                </p>{" "}
                <p className="font-medium md:font-normal text-[13.5px] md:text-[12.5px] skeleton-inline leading-3">
                  Movement ID:{" "}
                  <span className="font-bold text-mvx-blue">
                    MVXT{currentMovement(updatedLocations[mapIndex]?.id)?.mvxid}
                  </span>
                </p>{" "}
                <p className="text-[13.5px] md:text-[12.25px]">
                  Current Movement Location:{" "}
                  {updatedLocations[mapIndex]?.textAddress}; <br />
                  Latitude {updatedLocations[mapIndex]?.lat}Â° N, {"   "}
                  {updatedLocations[mapIndex]?.lng}Â° W
                </p>
              </div>
              <button
                className="bg-mvx-orange px-10 font-semibold shrink-0 py-3 rounded-lg text-white flex items-center text-xs self-center"
                onClick={trackShipment}
              >
                Track Shipment
              </button>
            </div>
          )}
        </div>

        <Skeleton
          dataLoaded={!shipmentTrendsLoading}
          className={"hidden sm:block w-full h-[450px] !rounded-xl mb-10 mt-12"}
        >
          <Trend />
        </Skeleton>
      </div>
    </>
  );
};

function UpdateMapCentre(props) {
  const map = useMap();
  map.panTo(props.mapCentre);
  return null;
}

const Tile = styled.div`
  background-image: url(${(props) => props.url});
  background-position: ${(props) => props.position};
  background-size: auto 100%;
  background-repeat: no-repeat;
`;

export default DashboardLanding;
