import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const MenuItem = ({ child, index, nowrap, showMenu, setMobileMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const gotoService = () => {
    showMenu && showMenu(null);
    setMobileMenu && setMobileMenu(null);
    navigate(child.path);
  };

  return (
    <Container
      key={index}
      onClick={gotoService}
      className={`flex py-3 items-center duration-300 pl-2 pr-4 rounded-2xl ${
        child.iconColor
      } ${location.pathname === child.path && "border active"}`}
    >
      <div
        className={`w-10 h-10 mr-4 shrink-0 rounded-full flex items-center justify-center`}
      >
        {child.icon}
      </div>
      <div className={`${!nowrap && "whitespace-nowrap"}`}>
        <p className="font-bold text-md">{child.name}</p>
        <p className="text-xs text-gray-500">{child.sub}</p>
      </div>
    </Container>
  );
};

const Container = styled.div`
  &.blue > div:first-of-type,
  &.blue:hover {
    background: rgba(17, 82, 251, 0.08);
  }
  &.orange > div:first-of-type,
  &.orange:hover {
    background: rgba(255, 138, 0, 0.08);
  }
  &.blue.active {
    border-color: rgba(17, 82, 251, 0.1);
  }
  &.orange.active {
    border-color: rgba(255, 138, 0, 0.2);
  }
`;

export default MenuItem;
