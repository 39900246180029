import CustomToast from "components/common/CustomToast";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import toast from "react-hot-toast";

const firebaseConfig = {
  apiKey: "AIzaSyAbLIqCPBlO-wo4wqxWQDBO5UW6CsadTf4",
  authDomain: "mvxchange-fb-staging.firebaseapp.com",
  databaseURL: "https://mvxchange-fb-staging.firebaseio.com",
  projectId: "mvxchange-fb-staging",
  storageBucket: "mvxchange-fb-staging.appspot.com",
  messagingSenderId: "191408714565",
  appId: "1:191408714565:web:15e9031971dab0e049c42c",
  measurementId: "G-05CYFCGZZ5",
};

initializeApp(firebaseConfig);
const db = getDatabase();
const storage = getStorage();

// THIS FUNC IS MEANT TO BE USED IN A COMPONENT
async function uploadFile(
  filePathAndName,
  file,
  setReturnType,
  progressRef,
  setProgressFunc
) {
  const storageRef = ref(storage, filePathAndName);

  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress =
        (snapshot.bytesTransferred / snapshot.totalBytes) * progressRef;
      setProgressFunc && setProgressFunc({ progress });
    },
    (error) => {
      setReturnType("error");
      toast.custom((t) => (
        <CustomToast
          t={t}
          message={`Upload fail! Reupload ${file.name}.`}
          type="error"
        />
      ));
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>
        setReturnType(downloadURL)
      );
    }
  );
}

export const firebaseService = { db, uploadFile };
