import {
  BigDutchman,
  BUA,
  DormanLong,
  Kobo,
  LadGroup,
  ServiceHeroIcon,
} from "assets/arts";
import Header from "components/common/Header";
import React from "react";
import styled from "styled-components";

const Hero = ({
  heroTitle,
  heroDescription,
  heroMedia,
  showClients,
  buttonColor,
  ctaLocation,
  redBg,
  primaryButtonCta,
  noSecondaryButton,
}) => {
  buttonColor = buttonColor ? `bg-mvx-${buttonColor}` : "bg-mvx-blue2";
  return (
    <div className="sm:p-4">
      <Header />
      <div className="px-4 sm:p-0">
        <HeroContainer className={`rounded-lg py-0 sm:py-4 mb-10 sm:mb-14 ${redBg && "red-bg"}`}>
          <div className="w-[90%] sm:w-4/5 m-auto">
            <div
              className={`w-full pt-20 sm:pt-36 pb-16 flex flex-wrap sm:flex-nowrap items-center ${
                showClients !== false && "border-b border-gray"
              }`}
            >
              <div className="w-full sm:w-3/5 pr-0 sm:pr-14">
                <p className="font-catalina text-[2.1rem] sm:text-6xl leading-tight">
                  {heroTitle}
                </p>
                <p className="w-full py-6 text-sm font-light">
                  {heroDescription}
                </p>
                <div className="pt-6 relative flex flex-wrap sm:flex-nowrap mb-10 sm:mb-0">
                  <button
                    type="submit"
                    onClick={ctaLocation}
                    className={`${buttonColor} text-white outline-0 rounded-xl h-[50px] px-10 text-sm font-bold mr-4 flex items-center whitespace-nowrap`}
                  >
                    {primaryButtonCta || "Create a Shipment"}
                    <span className="material-icons white-color">
                      keyboard_arrow_right
                    </span>
                  </button>
                  {!noSecondaryButton && (
                    <button
                      id="my_custom_link"
                      type="submit"
                      className="bg-transparent text-mvx-blue2 outline-0 rounded-xl h-[50px] px-10 text-sm font-bold flex items-center"
                    >
                      or Talk to an Expert
                      <span className="material-icons white-color">
                        keyboard_arrow_right
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <div className="w-full sm:w-2/5 h-[270px] sm:h-[450px] bg-black rounded-2xl relative overflow-hidden">
                <div
                  className="grid w-full h-full"
                >
                  <HeroImageBg
                    url={require("assets/images/subtract4.png")}
                    className="w-full h-full absolute z-20"
                  >
                    <ServiceHeroIcon className="absolute z-20 h-6 sm:h-8 w-auto bottom-6 left-6" />
                  </HeroImageBg>
                  {heroMedia.type === "video" ? (
                    <video
                      src={heroMedia.src}
                      preload="auto"
                      autoPlay
                      muted
                      loop={true}
                      playsInline={true}
                      className="max-w-none object-cover z-0 w-full h-full"
                    ></video>
                  ) : (
                    <HeroImage
                      className="w-full h-full absolute"
                      url={heroMedia.src}
                    ></HeroImage>
                  )}
                </div>
              </div>
            </div>
            {showClients !== false && (
              <>
                <p className="pt-12 font-light text-sm mb-4">
                  Join over <span className="font-bold">300 businesses</span>{" "}
                  who have saved an average of{" "}
                  <span className="font-bold">200+ hours</span> by
                  <br />
                  trusting us to ship{" "}
                  <span className="font-bold">5000+ containers</span> around the
                  world.
                </p>
                <div className="flex flex-wrap sm:flex-nowrap items-center sm:justify-between pb-20 w-full sm:w-4/5 pt-10">
                  <a href="https://www.perfettivanmelle.com/">
                    <img
                      src={require("assets/images/services/clients/perfetti.png")}
                      alt="MVX Client"
                      className="mr-6 sm:mr-10 mb-6 sm:mb-0 h-[35px] sm:h-[45px]"
                    />
                  </a>
                  <a href="https://buagroup.com/">
                    <BUA className="mr-6 sm:mr-10 mb-6 sm:mb-0 h-[40px] w-auto" />
                  </a>
                  <a href="https://www.kobo360.com/">
                    <Kobo className="mr-6 sm:mr-10 mb-6 sm:mb-0 h-[30px] sm:h-[35px] w-auto" />
                  </a>
                  <a href="https://ladgroup.org/">
                    <LadGroup className="mr-6 sm:mr-10 mb-6 sm:mb-0 h-[50px] sm:h-[55px] w-auto" />
                  </a>
                  <a href="https://www.dormanlongeng.com/">
                    <DormanLong className="mr-6 sm:mr-10 mb-6 sm:mb-0 h-[35px] sm:h-[35px] w-auto" />
                  </a>
                  <a href="https://www.bigdutchman.com/">
                    <BigDutchman className="mr-6 sm:mr-10 mb-6 sm:mb-0 h-[45px] sm:h-[45px] w-auto" />
                  </a>
                </div>
              </>
            )}
          </div>
        </HeroContainer>
      </div>
    </div>
  );
};

const HeroContainer = styled.div`
  &:not(.red-bg) {
    background-color: rgba(255, 138, 0, 0.07);
  }
  &.red-bg {
    background-color: rgba(249, 0, 0, 0.1);
  }
`;

const HeroImage = styled.div`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const HeroImageBg = styled.div`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  animation-duration: 1.2s;
  animation-name: oscillate;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
`;

export default Hero;
