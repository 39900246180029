import { appActions } from "actions";
import Loading from "components/common/Loading";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Article from "./Article";

const Articles = () => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const [section, setSection] = useState(0);

  useEffect(() => {
    dispatch(appActions.fetchArticles());

    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full">
      <div className="w-full h-[50px] overflow-x-auto	sm:overflow-hidden bg-mvx-orange/10 sm:rounded-b-2xl mb-10 flex items-center justify-start px-10 sm:px-0 sm:justify-center">
        <p
          className={`px-6 py-2 whitespace-nowrap cursor-pointer text-xs font-semibold rounded-full mr-10 ${
            section === 0 ? "bg-white text-mvx-blue2" : ""
          }`}
          onClick={() => setSection(0)}
        >
          All
        </p>
        <p
          className={`px-6 py-2 whitespace-nowrap cursor-pointer text-xs font-semibold rounded-full mr-10 ${
            section === 1 ? "bg-white text-mvx-blue2" : ""
          }`}
          onClick={() => setSection(1)}
        >
          Logistics
        </p>
        <p
          className={`px-6 py-2 whitespace-nowrap cursor-pointer text-xs font-semibold rounded-full mr-10 ${
            section === 2 ? "bg-white text-mvx-blue2" : ""
          }`}
          onClick={() => setSection(2)}
        >
          Trade
        </p>
        <p
          className={`px-6 py-2 whitespace-nowrap cursor-pointer text-xs font-semibold rounded-full mr-10 ${
            section === 3 ? "bg-white text-mvx-blue2" : ""
          }`}
          onClick={() => setSection(3)}
        >
          Finance
        </p>
        <p
          className={`px-6 py-2 whitespace-nowrap cursor-pointer text-xs font-semibold rounded-full ${
            section === 4 ? "bg-white text-mvx-blue2" : ""
          }`}
          onClick={() => setSection(4)}
        >
          Company news
        </p>
      </div>
      <div className="w-[85%] sm:w-4/5 m-auto p-0 sm:p-4">
        <p className="text-2xl font-bold">All Topics</p>

        {!app.requestingArticles && app.articles ? (
          app.articles.map((article, index) => (
            <React.Fragment key={index}>
              <Article
                title={article.title}
                body={article.body}
                createdAt={article.created_at * 1000}
                url={article.url}
              />
            </React.Fragment>
          ))
        ) : (
          <div className="h-60 w-full relative">
            <Loading size={8} color="black" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Articles;
