import { useState, useRef, useMemo, useEffect } from "react";
import BusinessProfile from "./BusinessProfile";
import Loading from "components/common/Loading";
import CountryCodeSelect from "components/common/CountryCodeSelect";
import { useSelector, useDispatch } from "react-redux";
import { authActions, financeActions } from "actions";
import {
  checkEmaiiValidity,
  checkNumberValidity,
  checkValidButton,
} from "helpers/validateUserFormPage";
import { firebaseService } from "services";

const PersonProfile = () => {
  const fileInput = useRef(null);
  const { user, updateRequestLoading, updateUserImageRequestLoading } =
    useSelector((state) => state.auth);

  const { financeProfile } = useSelector((state) => state.finance);
  const dispatch = useDispatch();
  const [showBusinessProfile, setShowBusinessProfile] = useState(false);
  const [userProfile, setUserProfile] = useState({
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    email: user?.email ?? "",
    // address: user?.address || "",
    raw_mobile: user?.mobile.replace("+234", "0") || "",
    company_name: user?.company_name || "",
    business_industry: user?.business_industry || "",
    // business_industry: "",
  });

  const [userImageReturnType, setUserImageReturnType] = useState();

  const imageUploadPath = `userImages/${user?.first_name.toLowerCase()}-${
    user?.user_id
  }`;
  const onChange = (e) => {
    const { name, value } = e.target;

    setUserProfile((profile) => ({
      ...profile,
      [name]: value,
    }));
  };

  const appendPrefix = () => {
    const getStringId = String(user?.user_id);
    if (getStringId.length === 1) {
      return "00";
    } else if (getStringId.length === 2) {
      return "0";
    } else {
      return "";
    }
  };

  const {
    first_name,
    last_name,
    email,
    // address,
    raw_mobile,
    company_name,
    business_industry,
  } = userProfile;

  const checkFIlledInputs = useMemo(() => {
    if (
      !first_name ||
      !last_name ||
      !email ||
      // !address ||
      !raw_mobile ||
      !company_name ||
      !business_industry
    ) {
      return false;
    } else {
      return true;
    }
  }, [
    first_name,
    last_name,
    email,
    // address,
    raw_mobile,
    company_name,
    business_industry,
  ]);
  
  const checkValidNumber = checkNumberValidity(raw_mobile);
  const checkValidEmail = checkEmaiiValidity(email);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (updateRequestLoading) {
      return;
    }
    const data = {
      ...userProfile,
      user_id: user?.user_id,
      mobile:
        userProfile.mobile_extension + userProfile.raw_mobile.replace(/^0/, ""),
    };
    
    dispatch(authActions.updateUserProfile(data));
  };

  useEffect(() => {
    dispatch(financeActions.fetchFinanceProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userImageReturnType && userImageReturnType !== "error") {
      dispatch(
        authActions.updateUserProfileImage({ avatar_url: userImageReturnType })
      );
      setUserImageReturnType(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userImageReturnType]);

  const handleImageUpload = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];

      firebaseService.uploadFile(
        `${imageUploadPath}/${file.name}`,
        file,
        setUserImageReturnType,
        undefined,
        undefined
      );
    }
  };

  const firstBtnCheck = checkValidButton(checkFIlledInputs, checkValidNumber);

  const allowButtonClick = () => {
    if (firstBtnCheck === true && checkValidEmail === true) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row gap-8 max-w-[500px] sm:max-w-none">
        <div className="relative sm:w-[78%] lg:w-[70%] rounded-lg border border-transparent lg:border-[#dddddd] overflow-hiddden xl:w-[72%] order-2 sm:order-1">
          <p className="font-extrabold text-[17px] sm:text-base sm:font-bold rounded-t-lg border-b border-transparent lg:border-[#dddddd] bg-none lg:bg-[#fbfbfb] px-3 lg:py-5 lg:px-8 xl:px-10">
            Profile details
          </p>
          <div className="px-3 lg:px-8 py-5 lg:py-10 xl:px-10">
            <form onSubmit={handleSubmit}>
              <div className="grid sm:grid-cols-2 sm:gap-x-5 xl:gap-x-14 gap-y-3">
                {/* first name */}
                <div>
                  <label
                    htmlFor="first_name"
                    className="block font-bold text-[13px]"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    value={first_name}
                    onChange={onChange}
                    placeholder="Your first name"
                    className="px-5 py-6 sm:p-3.5 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                  outline-0 focus-visible:bg-[#e8f0fe] focus-visible:border-2 focus-visible:border-mvx-blue placeholder:text-[#b2b2b2] placeholder:font-medium"
                  />
                </div>
                {/* last name */}
                <div>
                  <label
                    htmlFor="last_name"
                    className="block font-bold text-[13px]"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    value={last_name}
                    onChange={onChange}
                    placeholder="Your last name"
                    className="px-5 py-6 sm:p-3.5 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                  outline-0 focus-visible:bg-[#e8f0fe]  focus-visible:border-2 focus-visible:border-mvx-blue
                  placeholder:text-[#b2b2b2] placeholder:font-medium"
                  />
                </div>
                {/* email */}
                <div>
                  <label
                    htmlFor="email"
                    className="block font-bold text-[13px]"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={onChange}
                    placeholder="Your personal email address"
                    className="px-5 py-6 sm:p-3.5 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                  outline-0 focus-visible:bg-[#e8f0fe]  focus-visible:border-2 focus-visible:border-mvx-blue
                  placeholder:text-[#b2b2b2] placeholder:font-medium"
                  />
                  {!checkValidEmail && (
                    <span className="text-xs text-mvx-red font-bold">
                      Please enter a valid email address
                    </span>
                  )}
                </div>
                {/* address
                <div>
                  <label
                    htmlFor="address"
                    className="block font-bold text-[13px]"
                  >
                    House Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    onChange={onChange}
                    value={address}
                    placeholder="Your house address"
                    className="px-5 py-6 sm:p-3.5 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                    outline-0 focus-visible:bg-[#e8f0fe]  focus-visible:border-2 focus-visible:border-mvx-blue
                    placeholder:text-[#b2b2b2] placeholder:font-medium"
                  />
                </div> */}
                {/* company name  */}
                <div>
                  <label
                    htmlFor="company_name"
                    className="block font-bold text-[13px]"
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    name="company_name"
                    id="company_name"
                    value={company_name}
                    onChange={onChange}
                    placeholder="Your company name"
                    className="px-5 py-6 sm:p-3.5 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                  outline-0 focus-visible:bg-[#e8f0fe] focus-visible:border-2 focus-visible:border-mvx-blue placeholder:text-[#b2b2b2] placeholder:font-medium"
                  />
                </div>
                {/* business industry  */}
                <div>
                  <label
                    htmlFor="business_industry"
                    className="block font-bold text-[13px]"
                  >
                    Business Industry
                  </label>
                  <select
                    name="business_industry"
                    id="business_industry"
                    value={business_industry}
                    onChange={onChange}
                    className="flex items-center justify-center appearance-none px-5 sm:px-3.5 h-12 sm:h-11 bg-[#f5f5f5] border border-[#dddddd] rounded-md my-2 w-full text-[12.5px] font-semibold
                  outline-0 focus-visible:bg-[#e8f0fe] focus-visible:border-2 focus-visible:border-mvx-blue"
                  >
                    <option value="" disabled className="!text-gray-500">
                      --Select business industry--
                    </option>
                    <option value="Energy (Oil and Gas)">
                      Energy (Oil and Gas)
                    </option>
                    <option value="Power">Power</option>
                    <option value="Construction">Construction</option>
                    <option value="Engineering">Engineering</option>
                    <option value="FMCG">FMCG</option>
                    <option value="Tech/IT">Tech/IT</option>
                    <option value="Agriculture">Agriculture</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Pharmaceuticals">Pharmaceuticals</option>
                    <option value="Minerals/Metal">Minerals/Metal</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                {/* phone number */}
                <div>
                  <label
                    htmlFor="raw_mobile"
                    className="block font-bold text-[13px]"
                  >
                    Phone Number
                  </label>
                  <div className="flex items-center relative">
                    <div className="w-[80px] sm:w-[72px] lg:w-[83px] px-3 flex items-center justify-center absolute border-r-2 border-[#8f8f8f]">
                      <CountryCodeSelect
                        onChange={onChange}
                        setData={setUserProfile}
                        name="mobile_extension"
                        data={userProfile.mobile_extension}
                      />
                    </div>

                    <input
                      type="tel"
                      name="raw_mobile"
                      id="raw_mobile"
                      placeholder="Input phone number"
                      value={raw_mobile}
                      maxLength={12}
                      onChange={onChange}
                      className={`pr-5 py-6 sm:p-3.5 pl-24 sm:pl-20 bg-[#f5f5f5] rounded-md my-2 w-full h-11 text-[12.5px] font-semibold
                    outline-0 focus-visible:border-2 
                    placeholder:text-[#b2b2b2] placeholder:font-medium lg:pl-24 ${
                      !checkValidNumber
                        ? "border-2 border-mvx-red text-mvx-red focus-visible:border-mvx-red focus-visible:bg-[#fff3f3]"
                        : "border border-[#dddddd] focus-visible:border-mvx-blue focus-visible:bg-[#e8f0fe]"
                    }`}
                    />
                  </div>
                  {!checkValidNumber && (
                    <span className="text-xs text-mvx-red font-bold">
                      Please enter phone number correctly
                    </span>
                  )}
                </div>
              </div>
              <button
                className={`relative px-5 py-3 rounded-md text-[12px] font-semibold text-white mt-5 w-[135px] h-[45px] ${
                  allowButtonClick() ? "bg-mvx-blue" : "bg-[#b8cbff]"
                }`}
                type="submit"
                disabled={!allowButtonClick()}
              >
                {updateRequestLoading ? <Loading /> : " Save Changes"}
              </button>
            </form>
          </div>
          {Object?.keys(financeProfile || {})?.length > 0 && (
            // !financeRequestsError &&
            // financeRequests?.data &&
            // financeRequests?.totalFinances > 0 && (
            <div className="relative">
              {!showBusinessProfile && (
                <div className="border border-[#dddddd] bg-white rounded-full w-8 h-8 absolute -bottom-3 left-2/4 hidden lg:flex items-center justify-center ">
                  {/* {financeRequestsLoading ? (
                      <Loading color="mvx-blue" size={4} />
                    ) : ( */}
                  <div
                    className="cursor-pointer hover:bg-mvx-blue hover:border-[#f9fbff] w-full h-full flex items-center justify-center rounded-full"
                    onClick={() => setShowBusinessProfile(true)}
                  >
                    <span className="material-icons !font-semibold !text-[#8f8f8f] hover:!text-white">
                      expand_more
                    </span>
                  </div>
                  {/* )} */}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="order-1 sm:order-2 sm:w-[22%] lg:w-[30%] border border-transparent lg:border lg:border-[#d6d6d6] rounded-md xl:w-[25%]">
          <div className="h-4/5 flex flex-col items-center justify-center lg:border-b border-transparent sm:border-[#d6d6d6]">
            <div className="space-y-8">
              <div className="flex flex-col gap-4 justify-center items-center text-center">
                <div className="relative rounded-full w-32 h-32 sm:h-24 sm:w-24 lg:w-32 lg:h-32 border border-gray-50">
                  {updateUserImageRequestLoading && (
                    <div className="absolute w-full h-full rounded-full bg-[#000000]/30  flex items-center justify-center z-10">
                      <Loading color={"mvx-blue"} size={8} />
                    </div>
                  )}
                  {user?.avatar_url ? (
                    <div className="absolute w-full h-full rounded-full ">
                      <img
                        src={user?.avatar_url}
                        alt={user.first_name}
                        className="w-full h-full rounded-full object-cover"
                      />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-full w-full bg-[#f1f1f1] rounded-full">
                      <span className="material-icons !text-6xl !text-[#b2b2b2]">
                        person
                      </span>
                    </div>
                  )}

                  <div className="flex sm:hidden absolute rounded-full w-6 h-6 top-24 right-1 bg-white lg:flex items-center justify-center">
                    <div className="bg-[#40a81b] rounded-full w-4 h-4"></div>
                  </div>
                </div>

                <div className="space-y-1">
                  <p className="text-[15px] font-bold">
                    {user?.first_name} {user?.last_name}
                  </p>
                  <p className="text-xs font-semibold">{user.email}</p>
                </div>
                <input
                  type="file"
                  id="userImage"
                  ref={fileInput}
                  className="hidden"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
                <button
                  className="flex items-center py-3 px-6 gap-2 text-white font-semibold tracking-wide text-[13px] bg-mvx-blue rounded-md"
                  onClick={() => {
                    document.getElementById("userImage").click();
                  }}
                >
                  <div className="sm:hidden lg:block">
                    <span className="material-icons !text-white !text-base">
                      add_a_photo
                    </span>
                  </div>
                  <span>{user?.avatar_url ? "Change" : "Upload"} photo</span>
                </button>
                <div className="sm:hidden py-4 px-7 border border-[#ddd] rounded-lg flex gap-4 items-center mt-2">
                  <div className="border-r border-[#ddd] pr-7 text-[15px]">
                    <p className="font-semibold text-[#333]">CustomerID:</p>

                    <p className="text-mvx-blue font-semibold">
                      MVXP{appendPrefix()}
                      {user?.user_id}
                    </p>
                  </div>
                  <p className="text-[15px] h-1/5 flex items-center justify-center gap-1 pl-2">
                    <span className="text-[#333333] font-medium">Status: </span>

                    <span className="text-[#40a81b] font-semibold">
                      {" "}
                      Active
                    </span>
                  </p>
                </div>
              </div>
              <p className="hidden sm:block text-sm text-center">
                <span className="text-[#333333] font-medium">
                  Customer ID:{" "}
                </span>
                <span className="text-mvx-blue font-semibold">
                  MVXP{appendPrefix()}
                  {user?.user_id}
                </span>
              </p>
            </div>
          </div>
          <p className="invisible sm:visible text-sm h-1/5 flex items-start lg:items-center justify-center gap-1">
            <span className="text-[#333333] font-medium">Status: </span>

            <span className="text-[#40a81b] font-semibold"> Active</span>
          </p>
        </div>
      </div>
      {showBusinessProfile && <BusinessProfile />}
    </>
  );
};
export default PersonProfile;
