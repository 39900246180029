/* eslint-disable */
import React, { useState, useEffect } from "react";
import "styles/leaflet/leaflet.css";
import { Link, useNavigate } from "react-router-dom";
import ModalContainer from "../../../components/common/modals/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import { financeActions } from "actions";
import { financeConstant } from "constants";
import { GoBackInfo } from "../common/MobileNav";

const ViewShipment = () => {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  const {
    cacValueName,
    directorValueName,
    moaValueName,
    statementValueName,
    phone_number,
    dirName,
    ...shipmentToPreview
  } = JSON.parse(localStorage.getItem("financeRequestData"));
  const {
    financeProfile,
    newFinanceRequestLoading,
    updateFinanceProfileLoading,
    newFinanceRequestData,
  } = useSelector((state) => state.finance);

  const dispatch = useDispatch();

  const submitRequest = () => {
    dispatch(
      financeActions.createNewFinanceRequest({
        ...shipmentToPreview,
        saveAndContinue: true,
        saveAndExit: false,
      })
    );
  };

  useEffect(() => {
    document.querySelector(".container").scrollIntoView();
  }, []);

  useEffect(() => {
    if (newFinanceRequestData) {
      setModal(true);
      dispatch({
        type: financeConstant.CREATE_FINANCE_SUCCESS,
        payload: null,
      });
    }
  }, [newFinanceRequestData]);

  const closeModal = () => {
    localStorage.removeItem("financeRequestData");
    navigate("/dashboard/finance");
  };

  return (
    <>
      <GoBackInfo text="Freight Finance" />
      <div className="-ml-4 -mr-14 sm:ml-0 sm:mt-3 md:mt-0 sm:-mr-10 md:-mr-8 lg:mr-0">
        <div className="container px-6 py-4 pl-0">
          <div className="mt-12 sm:mt-0"></div>

          <div className="hidden sm:block w-full mb-4">
            <p
              className="flex items-center text-xs cursor-pointer w-max"
              onClick={() => navigate(-1)}
            >
              <span className="material-icons mr-2 !text-base">
                keyboard_backspace
              </span>
              Close
            </p>
          </div>
          <div className="flex sm:flex-row flex-col items-start md:items-center justify-between mb-6">
            <h3 className="text-lg md:text-[24px] font-bold">
              Finance Your Shipment
            </h3>
          </div>
          <div className="md:9/12 lg:w-2/3 w-full mt-8">
            <div className="text-[15px] md:text-[16px] font-semibold">
              Do you consent to data sharing?
            </div>
            <p className="text-mvx-light-gray text-[14px] mt-2 font-medium">
              Yes
            </p>

            {/* payment tenure */}
            <section className="mt-6">
              <h3 className="text-[15px] md:text-[16px] font-Montserrat font-[600]">
                Repayment Tenure
              </h3>
              <p className="text-mvx-light-gray font-Montserrat text-[14px] mt-2 font-medium">
                {shipmentToPreview.installmentalPlan}
              </p>
              <p className="text-mvx-light-blue text-[14px] mt-1">
                Repayment interest:{" "}
                <span className="font-bold">2.5%-5% per month</span>{" "}
                {/* <span className="material-icons !text-[21px] text-mvx-dark leading-4 cursor-help ml-[2px] translate-y-[5px]">
                  info
                </span> */}
              </p>
            </section>
            {/* Form */}
            <section className="mt-6 text-[18px] font-semibold w-full md:w-5/6">
              <h3 className="text-[17px] md:text-[18px] font-[600]">
                Personal Details
              </h3>
              <div className="flex flex-col sm:flex-row  justify-between">
                <div className="w-full md:w-[550px] mt-3">
                  <div className="text-[13.5px] md:text-[14px]" htmlFor="bvn">
                    Bank Verification Number
                  </div>
                  <p className="text-mvx-light-gray text-[13px] md:text-[12px] mt-3 font-medium">
                    {shipmentToPreview.bvn}
                  </p>
                </div>
                <div className="w-full md:w-[550px] mt-3">
                  <div className="text-[13.5px] md:text-[14px]" htmlFor="bvn">
                    Personal Address
                  </div>
                  <p className="text-mvx-light-gray font-Montserrat text-[12px] mt-3 font-medium">
                    {shipmentToPreview.address}
                  </p>
                </div>
              </div>
              <h3 className="text-[17px] md:text-[18px] font-Montserrat font-[600] mt-7">
                Company Details
              </h3>
              <div className="font-Montserrat flex flex-col sm:flex-row justify-between">
                <div className="w-full md:w-[550px] mt-3">
                  <div className="text-[13.5px] md:text-[14px]" htmlFor="bvn">
                    Years of Operations
                  </div>
                  <p className="text-mvx-light-gray font-Montserrat text-[13px] md:text-[12px] mt-3 font-medium">
                    {shipmentToPreview.operationYears} Years
                  </p>
                </div>
                <div className="w-full md:w-[550px] mt-3">
                  <div className="text-[13.5px] md:text-[14px]" htmlFor="bvn">
                    Registration Number (RC)
                  </div>
                  <p className="text-mvx-light-gray font-Montserrat text-[13px] md:text-[12px] mt-3 font-medium">
                    {shipmentToPreview.companyRC}
                  </p>
                </div>
              </div>
              <div className="font-Montserrat flex flex-col sm:flex-row justify-between">
                <div className="w-full md:w-[550px] mt-3">
                  <div className="text-[14px]" htmlFor="bvn">
                    Director's Name
                  </div>
                  <p className="text-mvx-light-gray font-Montserrat text-[13px] md:text-[12px] mt-3 font-medium">
                    {dirName}
                  </p>
                </div>
                <div className="w-full md:w-[550px] mt-3">
                  <div className="text-[13.5px] md:text-[14px]" htmlFor="bvn">
                    Director's Email
                  </div>
                  <p className="text-mvx-light-gray font-Montserrat text-[13px] md:text-[12px] mt-3 font-medium">
                    {shipmentToPreview.directorEmail}
                  </p>
                </div>
              </div>
              <div className="font-Montserrat flex flex-col sm:flex-row justify-between">
                <div className="w-full md:w-[550px] mt-3">
                  <div className="text-[14px]" htmlFor="bvn">
                    Business Website
                  </div>
                  <p className="text-mvx-light-gray font-Montserrat text-[13px] md:text-[12px] mt-3 font-medium">
                    {shipmentToPreview.businessWebsite}
                  </p>
                </div>
                <div className="w-full md:w-[550px] mt-3">
                  <div className="text-[13.5px] md:text-[14px]" htmlFor="bvn">
                    Business Address
                  </div>
                  <p className="text-mvx-light-gray font-Montserrat text-[13px] md:text-[12px] mt-3 font-medium">
                    {shipmentToPreview.businessAddress}
                  </p>
                </div>
              </div>
              <div className="font-Montserrat flex-col flex sm:flex-row justify-between">
                <div className="w-full md:w-[550px] mt-4">
                  <div className="text-[13.5px] md:text-[14px]" htmlFor="bvn">
                    Director’s Phone Number
                  </div>
                  <p className="text-mvx-light-gray font-Montserrat text-[13px] text-[12px] mt-3 font-medium">
                    {phone_number}
                  </p>
                </div>
                <div className="w-full md:w-[550px] mt-4">
                  <div className="text-[13.5px] md:text-[14px]" htmlFor="bvn">
                    Linked Bank Account
                  </div>
                  <p className="text-mvx-gray text-[13px] md:text-[12px] italic font-medium">
                    <span
                      className={`material-icons !text-[24px] text-[${
                        financeProfile?.monoId ? "#02C39A" : "#f90000"
                      }] mt-2 !leading-4 translate-y-[6px] mr-[2px]`}
                    >
                      {financeProfile?.monoId ? "check_circle" : "cancel"}
                    </span>

                    {financeProfile?.monoId
                      ? "Account Linked!"
                      : "Account Not Linked"}
                  </p>
                </div>
              </div>
              <h3 className="font-Montserrat text-[17px] md:text-[18px] mt-8">
                Documents
              </h3>
              <div className="font-Montserrat flex flex-col sm:flex-row  justify-between">
                <div className="w-full md:w-[550px] mt-3">
                  <label className="text-[13.5px] md:text-[14px]" htmlFor="bvn">
                    CAC Certificate
                  </label>
                  <div className="text-[#3E3E3E] text-[13px] md:text-[12px] mt-3 font-medium italic">
                    {cacValueName ?? "Not uploaded"}
                  </div>
                </div>
                <div className="w-full md:w-[550px] mt-3">
                  <label className="text-[13.5px] md:text-[14px]" htmlFor="bvn">
                    MOA Document
                  </label>
                  <div className="text-[#3E3E3E] text-[13px] md:text-[12px] mt-3 font-medium italic">
                    {moaValueName ?? "Not uploaded"}
                  </div>
                </div>
              </div>
              <div className="font-Montserrat flex flex-col sm:flex-row  justify-between">
                <div className="w-full md:w-[550px] mt-3">
                  <label className="text-[13.5px] md:text-[14px]" htmlFor="bvn">
                    Bank Statement
                  </label>
                  <div className="text-[#3E3E3E] text-[13px] md:text-[12px] mt-3 font-medium italic">
                    {statementValueName ?? "Not uploaded"}
                  </div>
                </div>
                <div className="w-full md:w-[550px] mt-3">
                  <label className="text-[13.5px] md:text-[14px]" htmlFor="bvn">
                    Company Director's ID
                  </label>
                  <div className="text-[#3E3E3E] text-[13px] md:text-[12px] mt-3 font-medium italic">
                    {directorValueName ?? "Not uploaded"}
                  </div>
                </div>
              </div>
              <div className="font-Montserrat flex flex-col sm:flex-row items-start mt-8 md:mt-12 w-full mb-24 gap-5">
                <button
                  disabled={newFinanceRequestLoading}
                  onClick={submitRequest}
                  className="w-full sm:w-1/2 bg-mvx-blue text-center py-3 px-6 text-white rounded-[10px] text-[16px] mb-2 md:mb-0 font-normal"
                >
                  {newFinanceRequestLoading || updateFinanceProfileLoading
                    ? "Submitting..."
                    : "Submit"}
                </button>
                <Link
                  to="/dashboard/finance"
                  className={`border-mvx-light-gray text-center border-[1px] py-3 w-full sm:w-1/2 px-6 text-dark rounded-[10px] text-[16px] font-normal ${
                    (newFinanceRequestLoading || updateFinanceProfileLoading) &&
                    "pointer-events-none"
                  }`}
                >
                  Exit
                </Link>
              </div>
            </section>
          </div>
          {modal && (
            <ModalContainer closeModal={closeModal}>
              <div className="bg-white w-5/6 md:w-[600px] mx-auto items-center rounded-[10px]">
                <div className="text-center py-12 md:py-[120px]">
                  <span className="material-icons text-center !text-[80px] text-[#02C39A] mt-4 !leading-4 mr-1">
                    check_circle
                  </span>
                  <p className="mt-4 font-[12px] font-semibold items-center px-8 md:px-20">
                    Your finance request has been successfully submitted! We
                    will get back to you shortly.
                  </p>
                  <button
                    onClick={closeModal}
                    className="bg-mvx-blue text-center py-2 w-[140px] px-6 text-white rounded-[10px] text-[16px] mb-2 mt-10"
                  >
                    Close
                  </button>
                </div>
              </div>
            </ModalContainer>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewShipment;
