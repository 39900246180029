import ModalContainer from "components/common/modals/ModalContainer";
import momemt from "moment";
import carLogo from "assets/images/dashboard/car.jpg";

const HistoryInfo = ({ keyName, value, size }) => {
  return (
    <p className={`text-[${size}px]`}>
      <span className="text-[#3e3e3e] capitalize">{keyName}:</span>{" "}
      <span className="font-bold">{value}</span>
    </p>
  );
};

const HistoryTime = ({ keyName, value, size }) => {
  return (
    <p className={`text-[${size}px]`}>
      <span className="text-[#3e3e3e] capitalize">{keyName}:</span>{" "}
      <span className="font-bold">
        {momemt(value).format("MMMM Do, YYYY: hh:mma")}
      </span>
    </p>
  );
};

const LapHistory = ({ data, onRequestClose }) => {
  return (
    <ModalContainer closeModal={onRequestClose}>
      <div className="h-[90vh] min-w-[320px] max-w-[450px] shadow-[0px_2px_12px_0px_#00000026] bg-white overflow-y-auto relative rounded-[12px] m-auto">
        <div className="flex justify-between px-7 py-4 border-b border-[#BEBEBE]">
          <p className="font-bold text-md">Location History</p>
          <span
            className="material-icons cursor-pointer hover:text-[#ACACAC]"
            onClick={onRequestClose}
          >
            close
          </span>
        </div>
        <div className="shrink-0 font-medium flex p-7 text-sm">
          <div className="w-[40px] shrink-0 mr-[10px]">
            <div className="w-[2px] h-full m-auto py-[12.5px]">
              <div className={`w-full h-full bg-[#40A81B]`}></div>
            </div>
          </div>
          <div className="w-full">
            <div className="pb-2 relative">
              {/* {lapHistoryData.origin.address} */}
              <div className="w-[40px] h-[25px] rounded-full absolute -left-[50px] top-0">
                <div
                  className={`w-[25px] h-[25px] m-auto flex items-center justify-center rounded-full bg-[#40A81B]/20`}
                >
                  <div
                    className={`w-[15px] h-[15px] border-[3px] m-auto bg-white rounded-full border-[#40A81B]`}
                  ></div>
                </div>
              </div>
            </div>
            <div className="relative">
              {/* {lapHistoryData.destination.address} */}

              <div className="w-[40px] h-full bg-white absolute -left-[50px] top-0">
                <div
                  className={`w-[15px] h-[15px] m-auto border-[3px] bg-white border-[#40A81B]`}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <div className="flex gap-3 px-12">
            <div className="rounded-full bg-mvx-blue3 w-[28px] h-[28px] p-2 flex justify-center items-center text-white">
              <span className="material-icons !text-base">flag</span>
            </div>
            <div>
              <p className="text-sm">
                <span className="font-bold">Source:</span>{" "}
                <span className="text-[#3E3E3E]">
                  {/* {lapHistoryData.origin.address} */}
                </span>
              </p>
              <small className="text-xs">
                APM terminal, Apapa - Lagos, Nigeria
              </small>
            </div>
          </div>
          <div className="flex px-8 gap-2">
            <div className="self-center">
              <img src={carLogo} alt="car logo" className="w-5 h-5" />
            </div>
            <div className="border-l-4 border-mvx-blue3 py-3 px-5">
              <p className="text-mvx-blue3 text-sm mb-2 font-medium">
                Event History
              </p>
              <div className="space-y-1">
                <HistoryInfo
                  keyName="location code"
                  value="POL (Port of Landing)"
                  size={13}
                />
                <HistoryInfo
                  keyName="port name"
                  value="LIANYUNGANG"
                  size={13}
                />
                <HistoryInfo keyName="port code" value="CNLYG" size={13} />
                <HistoryInfo
                  keyName="event type"
                  value="Ship interchange"
                  size={13}
                />
                <HistoryTime
                  keyName="estimated time"
                  value={new Date()}
                  size={13}
                />
                <HistoryTime
                  keyName="event time"
                  value={new Date("2022-10-26")}
                  size={13}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default LapHistory;
