import { useSelector } from "react-redux";
import { authService } from "services";

export const PrivateRoutes = ({ children }) => {
  const { accessToken } = useSelector((state) => state.auth);

  console.log("Private: ", accessToken, authService.loggedIn());

  if (!accessToken && !authService.loggedIn()) {
    const intendedUrl = encodeURIComponent(window.location.href);
    window.open(`/enter${intendedUrl ? `?redirect=${intendedUrl}` : ""}`, "_self");
    return;
  }
  return children;
};
