import React from 'react';
import ReactSelect from 'react-select';

/**
 * @typedef {{value:string,label:string}} SelectOption 
 * @param {{
 * name?:string;
 * value: SelectOption;
 * options: SelectOption[];
 * onChange: (newValue: SelectOption, actionMeta: import('react-select').ActionMeta<SelectOption>);
 * onInputChange?: (newValue: string, actionMeta: import('react-select').InputActionMeta) => void
 * placeholder?: string;
 * }} props 
 * @returns 
 */
const Select = ({
  name,
  value,
  options,
  disabled,
  onChange,
  onInputChange,
  onBlur,
  placeholder = "",
}) => {
  const styles = {
    menu: (provided, state) => ({
      ...provided,
    }),
    control: (provided,state) => ({
      ...provided,
      height: 40,
      backgroundColor: state.isDisabled ? "#EEF7FF" : provided.backgroundColor,
      borderColor: "#DDD",
      borderRadius: 8,
      boxShadow: 'none',
      '&:hover': {
        borderColor: "#DDD",
      }
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '14px',
      paddingLeft: '0px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '14px',
      color: 'black',
    }),
    option: (provided) => ({
      ...provided,
      color: 'black',
      fontSize: '14px',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '14px',
      color: '#A6A6A6',
    }),
    indicatorSeparator: ()=>({
      display: 'none',
    })
  };

  return (
    <div>
      <ReactSelect
        name={name}
        value={value}
        options={options}
        styles={styles}
        form="required"
        onChange={onChange}
        onInputChange={onInputChange}
        onBlur={onBlur}
        placeholder={placeholder}
        isDisabled={disabled}
      />
    </div>
  )
}

export default Select
