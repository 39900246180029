import React from "react";
import Moment from "react-moment";
import sanitize from "sanitize-html";
import styledComponents from "styled-components";

const Article = ({ createdAt, title, body, url }) => {
  const openArticle = () => {
    window.open(url, "_blank");
  };

  return (
    <div className="w-full flex flex-wrap sm:flex-nowrap py-8 border-b border-gray">
      <div className="w-full pr-0 sm:pr-16 sm:order-1 order-2">
        <div className="w-full hidden sm:block">
          <ArticleAuthor createdAt={createdAt} />
        </div>
        <p className="text-2xl font-bold mt-6 sm:mt-0">{title}</p>
        <ArticleContainer
          className="text-xs pt-4 leading-5 text-justify text-gray-500"
          dangerouslySetInnerHTML={{
            __html: sanitize(body),
          }}
        ></ArticleContainer>
        <span
          onClick={openArticle}
          className="text-mvx-blue2 cursor-pointer font-semibold text-xs"
        >
          read more...
        </span>
      </div>
      <div className="w-full sm:w-1/4 sm:order-2 order-1">
        <div className="w-full sm:hidden block mb-2">
          <ArticleAuthor createdAt={createdAt} />
        </div>
        <ArticleImage
          url={require("assets/images/article.png")}
          className="w-full h-[180px] shrink-0 bg-black rounded-2xl"
        ></ArticleImage>
      </div>
    </div>
  );
};

const ArticleAuthor = ({ createdAt }) => (
  <p className="font-light text-xs">
    <span className="font-bold text-black">Published:</span>{" "}
    <Moment format="dddd, D.MM.YY">{createdAt}</Moment>{" "}
    <span className="italic">by MVX</span>
  </p>
);

const ArticleImage = styledComponents.div`
    background-image: url(${(props) => props.url});
    background-position: center;
    background-size: cover;
`;

const ArticleContainer = styledComponents.div`
    & > p {
      margin-bottom: 20px;
    }
`;

export default Article;
