import { analyticsConstants } from "constants";

const initialState = {
  userAnalytics: {},
  analyticsLoading: false,
  analyticsError: "",
  shipmentTrends: {},
  shipmentTrendsLoading: false,
  shipmentTrendsError: "",
  shipmentCount: {},
  shipmentCountLoading: false,
  shipmentCountError: "",
};

export function analytics(state = initialState, action) {
  switch (action.type) {
    case analyticsConstants.USER_ANALYTICS_REQUEST:
      return {
        ...state,
        analyticsLoading: true,
      };
    case analyticsConstants.USER_ANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsLoading: false,
        userAnalytics: action.payload,
      };
    case analyticsConstants.USER_ANALYTICS_FAILURE:
      return {
        ...state,
        analyticsError: action.payload,
        analyticsLoading: false,
      };
    case analyticsConstants.SHIPMENT_TRENDS_REQUEST:
      return {
        ...state,
        shipmentTrendsLoading: true,
      };
    case analyticsConstants.SHIPMENT_TRENDS_SUCCESS:
      return {
        ...state,
        shipmentTrendsLoading: false,
        shipmentTrends: action.payload,
      };
    case analyticsConstants.SHIPMENT_TRENDS_FAILURE:
      return {
        ...state,
        shipmentTrendsError: action.payload,
        shipmentTrendsLoading: false,
      };
    case analyticsConstants.SHIPMENT_COUNT_REQUEST:
      return { ...state, shipmentCountLoading: true };

    case analyticsConstants.SHIPMENT_COUNT_SUCCESS:
      return {
        ...state,
        shipmentCountLoading: false,
        shipmentCount: action.payload,
      };

    case analyticsConstants.SHIPMENT_COUNT_FAILURE:
      return {
        ...state,
        shipmentCountLoading: false,
        shipmentCountError: action.payload,
      };
    default:
      return state;
  }
}
