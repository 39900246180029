import { analyticsActions, financeActions, shipmentActions } from "actions";
import {
  BlueLogoutIcon,
  BlueSettingsIcon,
  LogoutIcon,
  SettingsIcon,
  SidebarIcon,
} from "assets/arts";
import Loading from "components/common/Loading";
import FinanceRequestModal from "components/common/modals/FinanceRequestModal";
import ModalContainer from "components/common/modals/ModalContainer";
import { getInitials } from "helpers";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { shipmentsService } from "services";
import MobileNav from "./common/MobileNav";
import Notifications from "./common/Notifications";
import Sidebar from "./common/Sidebar";
import routes from "routes";

const Dashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { activeShipments } = useSelector((state) => state.shipments);
  const { financeRedStarted } = useSelector((state) => state.finance);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchingError, setSearchingError] = useState("");
  const [movements, setMovements] = useState(null);
  const [step, setStep] = useState(0);

  const topShipments = useMemo(() => {
    if (activeShipments) {
      const derivedShip = [...activeShipments];
      const byBiggersMovements = derivedShip.sort(
        (obj1, obj2) => obj2.movement_count - obj1.movement_count
      );
      byBiggersMovements.length = 5;
      return byBiggersMovements;
    }

    return null;
  }, [activeShipments]);

  const logOut = () => dispatch({ type: "LOG_OUT" });

  const startTracking = async (shipmentMvxId = id) => {
    const mvxId = shipmentMvxId?.replace(/\D/g, "");
    setSearching(true);
    try {
      const res = await shipmentsService.fetchActiveShipments({
        search: mvxId,
      });
      const shipment = res?.data[0];
      if (shipment?.movement_count) {
        const res = await shipmentsService.getShipmentMovements(shipment._id);
        setMovements(res?.data?.movements);
        setStep(1);
        setSearching(false);
      } else {
        setSearchingError("No movements available");
        setSearching(false);
      }
    } catch (error) {
      setSearchingError(error?.message);
      setSearching(false);
    }
  };

  const isActive = (route) =>
    matchPath(`${route?.parent}/${route?.path}`, location?.pathname) ||
    route?.children?.find((r) =>
      matchPath(`${route?.parent}/${r?.path}`, location?.pathname)
    );

  const activeRoute = () => {
    for (let i = 0; i < routes.length; i++) {
      let r = routes[i];
      
      if (matchPath(`${r?.parent}/${r?.path}`, location?.pathname)) {
        return r;
      } else if (
        r?.children?.find((childroute) =>
          matchPath(`${r?.parent}/${childroute?.path}`, location?.pathname)
        )
      ) {
        return r?.children?.find((childroute) =>
          matchPath(`${r?.parent}/${childroute?.path}`, location?.pathname)
        );
      }
    }
    routes.forEach((r) => () => {});
  };

  const getRoutes = (routes) => {
    return routes
      .filter((r) => r.parent === "dashboard" && r.visible !== false)
      .map((route, routeKey) => {
        return (
        <Link to={route.path} key={routeKey}>
          <div
            className={`${
              isActive(route) ? "text-mvx-blue font-bold" : "text-[#686868]"
            } w-full py-3 pl-3 pr-6 select-none cursor-pointer text-sm flex items-center relative`}
          >
            <div className="w-[30px] mr-2 flex justify-center">
              {route.icon?.type === "image" ? (
                <img src={route.icon?.src} alt={route.name} />
              ) : (
                route.icon?.src({
                  isActive: isActive(route),
                })
              )}
            </div>
            {route.name}
            <SidebarIcon
              className={`absolute right-0 center-item-vertically w-[12px] h-auto duration-200 ${
                isActive(route) ? "right-0" : "-right-[12px]"
              }`}
            />
          </div>
        </Link>
      )});
  };

  const resetModal = () => {
    setStep(0);
    setSearchingError("");
    setModal(false);
  };

  const selectedMovement = (shipmentID, movementId) => {
    resetModal();
    localStorage.setItem("movementTrackingId", movementId);
    navigate(`/dashboard/shipment/${shipmentID}/movements`);
  };

  useEffect(() => {
    dispatch(analyticsActions.getUserAnalytics());
    dispatch(analyticsActions.getShipmentTrends());
    dispatch(shipmentActions.fetchActiveShipments());
    // dispatch(notificationsActions.getNotifications(1, 1));
    dispatch(analyticsActions.getShipmentCount());
    dispatch(financeActions.fetchFinanceProfile());

    if (location.pathname === "/dashboard") {
      navigate("/dashboard/home", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  // TODO -> fix the first loading 3 then after page change 4
  return (
    <>
      <div className="h-screen w-full flex fixed">
        <MobileNav setModal={setModal} />
        <div
          className={`${
            activeRoute()?.type !== "fullpage" ? "h-full" : "h-fit"
          } hidden z-30 md:block md:w-[22%] lg:w-1/6 flex flex-col shrink-0`}
        >
          <div className="mt-2.5 w-full p-2 pl-6">
            <div className="border hover:border-b-white hover:rounded-b-none relative group cursor-pointer border-gray-300 hover:border-gray-200 rounded-lg bg-white h-[60px] py-4 px-3 w-full flex items-center">
              <div
                className={`w-[38px] h-[38px] mr-2 shrink-0 rounded-full flex items-center justify-center text-white text-sm font-bold`}
                style={{
                  background: user?.avatar_url
                    ? `url(${user.avatar_url})`
                    : "#1C56F2",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {!user?.avatar_url && getInitials(user?.company_name)}
              </div>
              <p className="w-full text-sm font-bold hidden xl:block text-ellipsis overflow-hidden whitespace-nowrap">
                {user?.company_name}
              </p>
              <span className="material-icons shrink-0 transition-all duration-300 group-hover:rotate-180">
                keyboard_arrow_down
              </span>
              <div className="bg-white text-[15px] font-[500] shadow-lg rounded-[7px] absolute left-0 top-full rounded-t-none border border-gray-200 py-3 px-3 z-[1000000] w-[calc(100%+2px)] -ml-px transition-all duration-300 invisible opacity-0 group-hover:visible group-hover:opacity-100">
                <Link
                  to="/dashboard/profile-settings"
                  className="hover:text-mvx-blue text-[#686868] text-sm group/menu transition flex items-center mb-2 font-semibold"
                >
                  <SettingsIcon className="mr-3 block group-hover/menu:hidden" />
                  <BlueSettingsIcon className="mr-3 hidden group-hover/menu:block" />
                  Settings
                </Link>
                <p
                  className="cursor-pointer mt-[6px] hover:text-mvx-blue text-[#686868] text-sm group/menu transition flex items-center font-semibold"
                  onClick={logOut}
                >
                  <LogoutIcon className="mr-3 block group-hover/menu:hidden" />
                  <BlueLogoutIcon className="mr-3 hidden group-hover/menu:block" />
                  Log out
                </p>
              </div>
            </div>
          </div>
          {activeRoute()?.type !== "fullpage" && (
            <Sidebar routes={getRoutes(routes)} />
          )}
        </div>
        <div className="mt-12 relative z-40 md:mt-0 w-full md:w-[78%] lg:w-5/6 h-screen flex flex-col">
          <div className="w-full py-2 shrink-0">
            {/* <div className="fixed w-[100vw] z-[1000] h-[60px] pr-20 flex justify-end items-center"> */}
            <div className="h-[60px] hidden md:pr-10 lg:pr-20 md:flex justify-end items-center">
              {/* <div className="h-[40px] w-1/2 bg-[#F5F8FF] rounded-2xl mr-6 flex items-center">
                <div className="h-full w-[40px] ml-2 flex items-center justify-center">
                  <span className="material-icons text-gray-400">search</span>
                </div>
                <input
                  type="text"
                  className="h-full w-full outline-none bg-transparent placeholder:text-sm pb-0.5"
                  placeholder="Search"
                />
              </div> */}
              <button
                className="h-[40px] flex items-center select-none mr-6 whitespace-nowrap text-xs border border-gray-400 rounded-full px-6"
                onClick={() => setModal(true)}
              >
                <div className="w-[26px] h-[26px] shrink-0 bg-[#F90000]/[0.1] mr-2 rounded-full flex items-center justify-center">
                  <span className="material-icons !text-xl text-[#F90000]">
                    my_location
                  </span>
                </div>
                <span>Track Shipment</span>
              </button>
              <Notifications />
            </div>
          </div>
          {activeRoute()?.type !== "fullpage" && (
            <div className="h-full bg-[#1152FB]/[0.04] rounded-tl-3xl overflow-y-auto pt-6 pb-[60px] md:pb-20 lg:pb-[40px] pl-10 pr-20 outlet">
              <Outlet />
            </div>
          )}
        </div>
        {activeRoute()?.type === "fullpage" && (
          <div className="h-[calc(100%-(60px+1rem))] top-[calc(60px+1rem)] z-20 w-full absolute overflow-y-auto">
            <Outlet />
          </div>
        )}
        {financeRedStarted && <FinanceRequestModal />}
      </div>
      {/* <MobileShipmentFinder /> */}
      {/* Track Shipment modal */}
      {modal && (
        <ModalContainer closeModal={resetModal}>
          <div
            onClick={(e) => e.stopPropagation()}
            className={`mt-[20vh] bg-white rounded-[10px] pt-6 px-8 sm:px-16 pb-10 sm:pb-20 text-sm -translate-y-28 relative w-[300px] sm:w-[430px] max-h-[550px] overflow-y-auto`}
          >
            <h4 className="text-sm font-semibold text-center mb-[50px]">
              {step ? (
                <span>
                  <span className="font-medium">Shipment ID:</span> MVXT
                  {id?.replace(/\D/g, "")}
                </span>
              ) : (
                " Enter Your Shipment ID"
              )}
              <span
                className="material-icons absolute top-3 right-5 text-gray-600 self-center !text-2xl cursor-pointer"
                onClick={resetModal}
              >
                close
              </span>
            </h4>

            {topShipments && !step && (
              <>
                <p className={`font-bold mb-[10px] text-xs`}>Top Shipments</p>
                {topShipments?.map((ship, idx) => (
                  <div
                    className={`border border-[#C1C7D0]/50 rounded-[10px] w-full flex justify-between items-center py-[10px] pl-4 pr-[18px] cursor-pointer transition-colors duration-300 hover:bg-[#e8effe]/50 ${
                      idx !== 0 && "mt-4"
                    } ${searching && "pointer-events-none"}`}
                    key={ship._id}
                    onClick={() => {
                      setSearchingError("");
                      startTracking(ship?.mvxid);
                    }}
                  >
                    <p className="text-[#091D51] font-bold text-xs">
                      MVXT{ship.mvxid}
                    </p>
                    <span className="material-icons ml-auto text-[#091D51] self-center !text-2xl">
                      trending_flat
                    </span>
                  </div>
                ))}
              </>
            )}

            <p
              className={`font-bold ${
                step
                  ? "mb-[15px] text-[13px] -mt-7"
                  : `mb-[10px] text-xs ${topShipments && "mt-5"}`
              }`}
            >
              {step ? (
                <span>Movements ({movements?.length})</span>
              ) : (
                "Or Enter Shipment ID"
              )}
            </p>
            {!step ? (
              <>
                <input
                  type="text"
                  className="border bg-[#F5F5F5] border-gray-200 mb-4 rounded-[10px] p-3 outline-0 w-full h-[50px] text-sm"
                  placeholder="e.g MVXT0001 or 0001"
                  onInput={(evt) => {
                    setId(evt.target.value);
                    setSearchingError("");
                  }}
                  disabled={searching}
                />
                <span
                  className={`text-[13px] -translate-y-3 text-red-500 font-medium block ${
                    searchingError ? "visible" : "invisible"
                  }`}
                >
                  {searchingError}
                </span>

                <button
                  className={`relative text-white rounded-[10px] block w-full h-12 mt-5 ${
                    id.length >= 8 || searching ? "bg-mvx-blue" : "bg-[#688EF6]"
                  }`}
                  disabled={id.length < 8 || searching}
                  onClick={startTracking}
                >
                  {searching ? <Loading /> : "Search ID"}
                </button>
              </>
            ) : (
              <>
                {movements?.map((mvm, idx) => (
                  <div
                    className={`border border-[#C1C7D0]/50 rounded-[10px] w-full flex justify-between items-center py-[10px] pl-4 pr-[18px] cursor-pointer transition-colors duration-300 hover:bg-[#e8effe]/50 ${
                      idx !== 0 && "mt-4"
                    }`}
                    key={mvm._id}
                    onClick={() => selectedMovement(mvm.shipment_id, mvm._id)}
                  >
                    <p className="text-[#091D51] font-bold text-xs">
                      MVXM{mvm.mvxid}
                    </p>
                    <span className="material-icons ml-auto text-[#091D51] self-center !text-2xl">
                      trending_flat
                    </span>
                  </div>
                ))}
              </>
            )}
          </div>
        </ModalContainer>
      )}
    </>
  );
};

export default Dashboard;