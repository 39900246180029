var products = [
  {
    title: "Intuitive Dashboard",
    description:
      "Our simple, powerful & intuitive dashboard helps businesses digitize, track and automate their freight management and trade finance processes.",
    image:
      "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/products/freight-finance.svg",
  },
  {
    title: "Partner Management Application",
    description:
      "The MVX Partner Management Application gives local and international freight forwarders the freedom to leverage our network to access more businesses and opportunities across Africa. Providing them with access and data to make informed business decisions in selected markets where we operate.",
    image:
      "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/products/pms.svg",
  },
  {
    title: "Tania by MVX",
    description:
      "Tania by MVX is a simple freight management system that gives you the freedom to create and manage shipment requests, receive shipment updates, accept or reject quotes, and gain real-time visibility on your shipments. All at your convenience, from Whatsapp.",
    image:
      "https://storage.googleapis.com/mvxchange-fb.appspot.com/transit_website/new_website/products/tania.svg",
  },
];

export default products;
