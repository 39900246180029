import { Logo } from "assets/arts";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Login from "./Login";
import Signup from "./Signup";

const Auth = ({ path }) => {
  const [focusedInput, setFocusedInput] = useState();
  const navigate = useNavigate();

  const onFocus = (val) => {
    setFocusedInput(val);
  };

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="w-full min-h-screen flex pl-0 md:pl-20 bg-[#F5F5F5]">
      <AuthGraphic
        url={require("assets/images/auth-graphic.png")}
        className="hidden md:block md:w-1/3 lg:w-1/2 mr-24 relative"
      >
        <div className="absolute left-0 top-10 cursor-pointer">
          <Logo className="h-[25px] w-auto" onClick={goHome} />
        </div>
      </AuthGraphic>
      <div className="w-full md:w-[calc(50%+7.5rem)] h-screen bg-white overflow-y-auto">
        <div className="w-full max-w-[500px] md:max-w-none py-16 px-8 md:px-12 lg:px-20 text-left mx-auto">
          <p className="text-2xl font-bold">
            Ship <span className="text-mvx-blue">Faster</span>,
          </p>
          <p className="text-2xl font-bold mb-8">
            Ship <span className="text-mvx-orange">Smoother</span>
          </p>
          {path === "login" && (
            <Login onFocus={onFocus} focusedInput={focusedInput} />
          )}
          {path === "signup" && (
            <Signup onFocus={onFocus} focusedInput={focusedInput} />
          )}
        </div>
      </div>
    </div>
  );
};

const AuthGraphic = styled.div`
  background: url(${(props) => props.url});
  background-position: top right;
  background-size: 90% auto;
  background-repeat: no-repeat;
`;
export default Auth;
