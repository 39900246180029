import React, { useEffect, useMemo, useState } from "react";
import "styles/leaflet/leaflet.css";
import Connect from "@mono.co/connect.js";
import { useNavigate } from "react-router-dom";
import { currentENVData } from "helpers";
import { useDispatch, useSelector } from "react-redux";
import { financeConstant } from "constants";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { financeActions } from "actions";
import { useForm } from "react-hook-form";
import { financeService, firebaseService } from "services";
import moment from "moment";
import { GoBackInfo } from "../common/MobileNav";
import Loading from "components/common/Loading";

const CreateShipment = () => {
  const [fetchedRequest, setFetchedRequest] = useState(null);
  const [showDoc, setShowDoc] = useState(false);
  const [repaymentTag, setRepaymentTag] = useState(0);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [monoIDError, setMonoIDError] = useState("");
  const [repaymentTagError, setRepaymentTagError] = useState(false);
  const [CACUploadStatus, setCACUploadStatus] = useState();
  const [CACReturnType, setCACReturnType] = useState();
  const [MOAUploadStatus, setMOAUploadStatus] = useState();
  const [MOAReturnType, setMOAReturnType] = useState();
  const [bankStatementUploadStatus, setBankStatementUploadStatus] = useState();
  const [bankStatementReturnType, setBankStatementReturnType] = useState();
  const [directorIDUploadStatus, setDirectorIDUploadStatus] = useState();
  const [directorIDReturnType, setDirectorIDReturnType] = useState();
  const [consentError, setConsentError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [requestDocuments, setRequestDocuments] = useState(null);
  const [formButtonState, setFormButtonState] = useState("");
  const [bvnInputError, setBvnInputError] = useState("");
  const [RCInputError, setRCInputError] = useState("");
  const [bvnNumber, setBvnNumber] = useState("");
  const [rcNumber, setRcNumber] = useState("");

  const shipmentRequestId = localStorage.getItem("shipmentFinanceRequestId");
  const {
    saveMonoIDLoading,
    financeProfile,
    newFinanceRequestLoading,
    newFinanceRequestData,
  } = useSelector((state) => state.finance);
  const documentsUploadURL = `financeRequestDocuments/${financeProfile?._id}`;

  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, getValues } = useForm();

  const validateNumberFields = (value, stringLength, name, setError) => {
    let isNum = /^\d+$/.test(value);
    if (!isNum && value?.length < stringLength) {
      setError(
        `${name} must only be numbers and must be ${stringLength} characters!`
      );
    } else if (isNum && value?.length >= stringLength) {
      setError("");
    } else {
      if (!isNum) setError(`${name} must only be numbers!`);
      if (value?.length < stringLength)
        setError(`${name} must be ${stringLength} characters!`);
    }
  };

  const requestInProgress = useMemo(() => {
    if (
      saveMonoIDLoading ||
      (MOAUploadStatus && !MOAReturnType) ||
      (CACUploadStatus && !CACReturnType) ||
      (bankStatementUploadStatus && !bankStatementReturnType) ||
      (directorIDUploadStatus && !directorIDReturnType) ||
      newFinanceRequestLoading
    ) {
      return true;
    } else if (
      !saveMonoIDLoading ||
      (MOAReturnType && MOAUploadStatus) ||
      (CACUploadStatus && CACReturnType) ||
      (bankStatementUploadStatus && bankStatementReturnType) ||
      (directorIDUploadStatus && directorIDReturnType) ||
      !newFinanceRequestLoading
    ) {
      return false;
    }
  }, [
    MOAReturnType,
    MOAUploadStatus,
    CACUploadStatus,
    CACReturnType,
    bankStatementUploadStatus,
    bankStatementReturnType,
    directorIDUploadStatus,
    directorIDReturnType,
    newFinanceRequestLoading,
    saveMonoIDLoading,
  ]);

  const repaymentTagsStyles = (value) => {
    if (value && value === repaymentTag) {
      switch (value) {
        case 30:
        case 60:
        case 90:
        case 120:
          return "text-mvx-light-blue border-mvx-light-blue bg-mvx-light-blue2";
        default:
          break;
      }
    } else {
      return "border-mvx-light-gray hover:text-white hover:bg-mvx-light-blue hover:border-mvx-light-blue";
    }
  };

  const monoConnect = () => {
    const monoInstance = new Connect({
      onSuccess: ({ code }) => {
        dispatch(financeActions.saveMonoID({ monoId: code }));
      },
      onEvent: (eventName, data) => {
        if (eventName === "ERROR") setMonoIDError(data?.errorMessage);
      },
      key: currentENVData.monoKey,
    });
    monoInstance.setup();

    return monoInstance;
  };

  const makeFinanceRequest = (values, hideMessage = false) => {
    dispatch(financeActions.createNewFinanceRequest(values));
    const updateValues = getValues(["businessWebsite", "directorEmail"]);
    /*
      directorEmail: obj?.directorEmail,
      directorName: obj?.companyDirectors ? obj.companyDirectors[0].name : "",
      businessAddress: obj?.companyAddress,
      directorPhone: obj?.directorPhone,
    */
    dispatch(
      financeActions.updateFinanceProfile(
        {
          businessWebsite: updateValues[0] ?? "",
          directorEmail: updateValues[1] ?? "",
        },
        false
      )
    );
  };

  const cacValueName = document.getElementById("cac-input")?.files[0]?.name;
  const moaValueName = document.getElementById("moa-input")?.files[0]?.name;
  const directorValueName =
    document.getElementById("directorID-input")?.files[0]?.name;
  const statementValueName =
    document.getElementById("statement-input")?.files[0]?.name;
  const cacInput = document.getElementById("cac-name");
  const moaInput = document.getElementById("moa-name");
  const directorIdInput = document.getElementById("directorID-name");
  const bankStatementInput = document.getElementById("statement-name");

  const handleSubmitFunc = (values) => {
    const _values = {
      shipmentId: shipmentRequestId,
      financeProfile: financeProfile?._id,
      installmentalPlan: `${repaymentTag} days`,
      repaymentFormat: {
        period: "days",
        value: repaymentTag,
      },
      directorEmail: values?.directorEmail,
      businessWebsite: values?.businessWebsite,
      documentUploaded: [
        {
          title: "CAC CERTIFICATE",
          url:
            CACReturnType ||
            fetchedRequest?.documentUploaded?.find(
              (doc) => doc.title === "CAC CERTIFICATE"
            )?.url,
        },
        {
          title: "MOA DOCUMENT",
          url:
            MOAReturnType ||
            fetchedRequest?.documentUploaded?.find(
              (doc) => doc.title === "MOA DOCUMENT"
            )?.url,
        },
        {
          title: "BANK STATEMENTS",
          url:
            bankStatementReturnType ||
            fetchedRequest?.documentUploaded?.find(
              (doc) => doc.title === "BANK STATEMENTS"
            )?.url,
        },
        {
          title: "COMPANY DIRECTOR ID",
          url:
            directorIDReturnType ||
            fetchedRequest?.documentUploaded?.find(
              (doc) => doc.title === "DIRECTOR ID"
            )?.url,
        },
      ].filter((obj) => obj.url && obj.url !== "error"),
      monoId: financeProfile?.monoId,
    };

    // Empty values check start
    if (!termsAccepted) {
      document
        .querySelector(".share-data")
        .scrollIntoView({ behavior: "smooth" });
      setConsentError(true);
      return;
    }

    if (!repaymentTag) {
      setRepaymentTagError(true);
      document
        .querySelector(".repayment-tenure")
        .scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (
      (!financeProfile?.CacValidateStatus ||
        !financeProfile?.BvnValidateStatus) &&
      formButtonState !== "save&exitOne" &&
      formButtonState !== "save&exitTwo"
    ) {
      toast.custom((t) => (
        <CustomToast
          t={t}
          message="Cannot save request, invalid BVN or RC!"
          type="error"
        />
      ));
      return;
    }

    if (!financeProfile?.monoId) {
      toast.custom((t) => (
        <CustomToast
          t={t}
          message="Please link your bank account!"
          type="error"
        />
      ));
      return;
    }

    if (formButtonState === "save&continue") {
      const data = {
        ..._values,
        saveAndExit: true,
      };
      makeFinanceRequest(data, true);
      return;
    }
    if (formButtonState === "save&exitOne") {
      const data = {
        ..._values,
        saveAndContinue: false,
        saveAndExit: true,
      };
      makeFinanceRequest(data);
    }
    if (formButtonState === "save&preview") {
      if (Object.keys(_values?.documentUploaded).length < 4) {
        toast.custom((t) => (
          <CustomToast
            t={t}
            message="Please upload all documents!"
            type="error"
          />
        ));
      } else {
        localStorage.setItem(
          "financeRequestData",
          JSON.stringify({
            ..._values,
            bvn: bvnNumber,
            address: getValues("address"),
            operationYears: getValues("operationYears"),
            companyRC: rcNumber,
            businessAddress: getValues("businessAddress"),
            cacValueName: shortenDocumentName(cacValueName),
            moaValueName: shortenDocumentName(moaValueName),
            directorValueName: shortenDocumentName(directorValueName),
            statementValueName: shortenDocumentName(statementValueName),
            phone_number: values?.directorPhone,
            dirName: values?.directorName,
          })
        );
        navigate("/dashboard/review-shipment");
      }
    }
    if (formButtonState === "save&exitTwo") {
      const data = {
        ..._values,
        saveAndContinue: false,
        saveAndExit: true,
      };
      makeFinanceRequest(data);
    }
  };

  useEffect(() => {
    const resetFinReqData = () => {
      dispatch({
        type: financeConstant.CREATE_FINANCE_SUCCESS,
        payload: null,
      });
    };

    if (formButtonState === "save&continue") {
      if (newFinanceRequestData) {
        setShowDoc(true);
        resetFinReqData();
      }
    }

    if (
      formButtonState === "save&exitOne" ||
      formButtonState === "save&exitTwo"
    ) {
      if (newFinanceRequestData) {
        toast.custom((t) => (
          <CustomToast t={t} message="Finance request saved!" />
        ));
        navigate("/dashboard/finance");
        resetFinReqData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formButtonState, newFinanceRequestData]);

  const updateInputFields = (obj) => {
    setTermsAccepted(true);
    const bvnElm = document.querySelector(".bvn-input");
    const rcElm = document.querySelector(".rc-input");

    // update document
    setBvnNumber(obj?.bvn?.bvn ?? "");
    setRcNumber(obj?.companyRC ?? "");
    bvnElm.value = obj?.bvn?.bvn ?? "";
    rcElm.value = obj?.companyRC ?? "";

    reset({
      address: obj?.bvn?.address?.toLowerCase(),
      operationYears: isNaN(+obj?.companyAge)
        ? moment().year() - moment(obj?.companyAge).year() || ""
        : obj?.companyAge,
      directorEmail: obj?.directorEmail,
      directorName: `${obj?.bvn?.first_name ?? ""} ${
        obj?.bvn?.last_name ?? ""
      }`.toLowerCase(),
      businessWebsite: obj?.businessWebsite,
      businessAddress: obj?.companyAddress?.toLowerCase(),
      directorPhone: obj?.bvn?.phone_number,
    });
  };

  const fetchFinanceRequest = async () => {
    try {
      const {
        data: { shipment_details, ...rest },
      } = await financeService.fetchAFinanceRequest(shipmentRequestId);
      setFetchedRequest({ ...rest });
    } catch (error) {}
  };

  useEffect(() => {
    fetchFinanceRequest();
    financeActions.fetchFinanceProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchedRequest) {
      setRepaymentTag(parseInt(fetchedRequest?.installmentalPlan));
      fetchedRequest?.documentUploaded?.length &&
        setRequestDocuments(fetchedRequest?.documentUploaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedRequest]);

  // documents and repaymentTenure from fetched request
  useEffect(() => {
    financeProfile && updateInputFields(financeProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financeProfile, fetchedRequest]);

  useEffect(() => {
    document.querySelector(".container").scrollIntoView();

    if (repaymentTag) {
      setRepaymentTagError("");
    }
  }, [repaymentTag]);

  useEffect(() => {
    if (monoIDError) {
      toast.custom((t) => (
        <CustomToast
          t={t}
          message={monoIDError + "! Please try again"}
          type="error"
        />
      ));
    }
  }, [monoIDError]);

  const shortenDocumentName = (docName) => {
    const splitted = docName?.split(".");

    const extensionType = splitted[splitted?.length - 1];

    const thrRestText = splitted?.slice(0, -1)?.join("");

    return `${thrRestText?.match(/.{2,}/g).join("")}...${extensionType}`;
  };

  useEffect(() => {
    // Update all file inputs so file name is visible after upload
    if (CACReturnType && CACReturnType !== "error") {
      cacInput.value = shortenDocumentName(cacValueName);
    }

    if (MOAReturnType && MOAReturnType !== "error") {
      moaInput.value = shortenDocumentName(moaValueName);
    }

    if (directorIDReturnType && directorIDReturnType !== "error") {
      directorIdInput.value = shortenDocumentName(directorValueName);
    }

    if (bankStatementReturnType && bankStatementReturnType !== "error") {
      bankStatementInput.value = shortenDocumentName(statementValueName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    CACReturnType,
    MOAReturnType,
    directorIDReturnType,
    bankStatementReturnType,
  ]);

  return (
    <>
      <GoBackInfo text="Freight Finance" />
      <div className="-ml-4 -mr-14 sm:ml-0 sm:mt-3 md:mt-0 sm:-mr-10 md:-mr-8 lg:mr-0">
        <div className="container py-4 sm:pt-0 pl-0">
          <div className="hidden w-full mb-4">
            <p
              className="flex items-center text-xs cursor-pointer w-max"
              onClick={() => navigate(-1)}
            >
              <span className="material-icons mr-2 !text-base">
                keyboard_backspace
              </span>
              Close
            </p>
          </div>
          {/* <div className="flex md:flex-row flex-col items-center justify-between mb-6"> */}
          <p
            className="hidden sm:flex items-center text-xs cursor-pointer w-max"
            onClick={() => navigate(-1)}
          >
            <span className="material-icons mr-2 !text-base">
              keyboard_backspace
            </span>
            Close
          </p>
          <h3 className="hidden sm:block text-[22px] font-bold mb-3">
            Finance Your Shipment
          </h3>
          {/* </div> */}
          <div className="max-w-[750px] w-full">
            <div className="mt-14 sm:mt-0 text-mvx-dark text-[15px] sm:text-base font-semibold mb-4 share-data">
              Finance Your Shipment
            </div>
            <div className="md:px-4 md:py-4 p-3 flex border-[1px] border-mvx-light-blue bg-[#e1eaff] rounded-[10px] items-start max-w-[450px] md:max-w-[500px]">
              <div>
                <input
                  type="checkbox"
                  className="mr-4"
                  checked={termsAccepted}
                  onChange={(evt) => {
                    setTermsAccepted(evt.target.checked);
                    setConsentError(false);
                  }}
                />
              </div>
              <div className="space-y-2">
                <p className="text-[15px] sm:text-[17px] font-semibold">
                  I consent to having my data shared with MVX’s partners
                </p>
                <p className="text-mvx-gray text-[13px]">
                  We provide, through our finance partners, credit facilities
                  for your shipping. This affords you the opportunity to focus
                  on growing your business while you pay back later through
                  flexible payment options
                </p>
              </div>
            </div>
            <span
              className={`text-[13px] mt-[5px] text-red-500 font-medium block ${
                consentError ? "visible" : "invisible"
              }`}
            >
              Please consent to data sharing
            </span>
            {/* payment tenure */}
            <section className="mt-4 sm:mt-10 repayment-tenure">
              <h3 className="text-sm sm:text-base text-mvx-dark  font-semibold">
                Select your repayment tenure
              </h3>
              <div className="flex gap-4 md:gap-6 md:w-[450px] mt-[6px] w-full overflow-auto repaymentTenureTabs">
                <div
                  onClick={() => setRepaymentTag(30)}
                  className={`rounded-[10px] cursor-pointer text-[13px] font-medium border-[1px] px-4 py-2 items-center whitespace-nowrap ${repaymentTagsStyles(
                    30
                  )}`}
                >
                  30 Days
                </div>
                <div
                  onClick={() => setRepaymentTag(60)}
                  className={`rounded-[10px] cursor-pointer text-[13px] font-medium border-[1px] px-4 py-2 items-center whitespace-nowrap ${repaymentTagsStyles(
                    60
                  )}`}
                >
                  60 Days
                </div>
                <div
                  onClick={() => setRepaymentTag(90)}
                  className={`rounded-[10px] cursor-pointer text-[13px] font-medium border-[1px] py-2 px-4 items-center whitespace-nowrap ${repaymentTagsStyles(
                    90
                  )}`}
                >
                  90 Days
                </div>
                <div
                  onClick={() => setRepaymentTag(120)}
                  className={`rounded-[10px] cursor-pointer text-[13px] font-medium border-[1px] px-4 py-2 items-center whitespace-nowrap ${repaymentTagsStyles(
                    120
                  )}`}
                >
                  120 Days
                </div>
              </div>
              <span
                className={`text-[13px] mt-[5px] text-red-500 font-medium block ${
                  repaymentTagError ? "visible" : "invisible"
                }`}
              >
                Please select your repayment tenure
              </span>

              <p className="text-mvx-light-blue text-[14px] mt-2 flex items-center">
                Repayment interest:
                <span className="font-bold ml-1">2.5%-5% per month</span>
                {/* <span
                  className="material-icons !text-[21px] text-mvx-dark leading-4 cursor-help ml-1"
                  title="Your payment interest is based on 4.5%-5% per year"
                >
                  info
                </span> */}
              </p>
            </section>
            {/* Form */}
            <form
              className="mt-5 md:mt-9 mb-24"
              onSubmit={handleSubmit(handleSubmitFunc)}
            >
              <h3 className="text-[15px] md:text-[18px] font-semibold">
                Personal Details
              </h3>
              <div className="form flex gap-3 sm:gap-6 md:gap-9 lg:gap-12 gap-y-3 flex-col sm:flex-row w-full max-w-[450px] sm:max-w-[700px] py-4">
                <div className="w-full">
                  <label
                    className="text-[13.5px] md:text-[14px] font-semibold"
                    htmlFor="bvn"
                  >
                    Bank Verification Number
                  </label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter your BVN"
                      onInput={(evt) =>
                        validateNumberFields(
                          evt.target.value,
                          11,
                          "BVN",
                          setBvnInputError
                        )
                      }
                      onBlur={(evt) => {
                        if (!bvnInputError) {
                          evt.target.value &&
                            dispatch(
                              financeActions.verifyBVN(evt.target.value)
                            );
                        }
                      }}
                      maxLength={11}
                      className={`${
                        financeProfile?.BvnValidateStatus
                          ? "bg-[#dedcdc] pointer-events-none"
                          : "bg-[#F5F5F5]"
                      } px-3 text-[13px] mt-2 border-[#DDDDDD] border-[1px] h-12 md:h-10 w-full rounded-[10px] font-medium focus:outline-none bvn-input`}
                      required={
                        financeProfile?.BvnValidateStatus ? false : true
                      }
                      readOnly={
                        financeProfile?.BvnValidateStatus ? true : false
                      }
                    />
                    <span
                      className={`text-[13px] text-red-500 translate-y-[5px] font-medium block ${
                        bvnInputError ? "visible" : "invisible"
                      }`}
                    >
                      {bvnInputError}
                    </span>
                  </div>
                </div>
                <div className="w-full">
                  <label
                    className="text-[13.5px] md:text-[14px] font-semibold"
                    htmlFor="personal-address"
                  >
                    Personal Address
                  </label>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter your house address"
                      className="bg-[#F5F5F5] px-3 text-[13px] mt-2 border-[#DDDDDD] border-[1px] h-12 md:h-10 w-full rounded-[10px] font-medium focus:outline-none capitalize"
                      required
                      {...register("address", { required: true })}
                    />
                  </div>
                </div>
              </div>
              <h3 className="text-[15px] md:text-[18px] font-semibold mt-5 md:mt-10">
                Company Details
              </h3>
              <div className="form flex gap-3 sm:gap-6 md:gap-9 lg:gap-12 gap-y-3 flex-col sm:flex-row w-full max-w-[450px] sm:max-w-[700px] py-4">
                <div className="w-full">
                  <label
                    className="text-[13.5px] font-semibold"
                    htmlFor="cacNumber"
                  >
                    Registration Number (RC)
                  </label>
                  <div>
                    <input
                      type="text"
                      placeholder="RC Number"
                      className={`${
                        financeProfile?.CacValidateStatus
                          ? "bg-[#dedcdc] pointer-events-none"
                          : "bg-[#F5F5F5]"
                      } px-3 text-[13px] mt-2 border-[#DDDDDD] border-[1px] h-12 md:h-10 w-full font-medium rounded-[10px] focus:outline-none rc-input`}
                      onInput={(evt) =>
                        validateNumberFields(
                          evt.target.value,
                          7,
                          "RC number",
                          setRCInputError
                        )
                      }
                      onBlur={(evt) => {
                        if (!RCInputError) {
                          evt.target.value &&
                            dispatch(
                              financeActions.verifyCAC({
                                rc_number: evt.target.value,
                                company_name:
                                  financeProfile?.companyName ??
                                  user.company_name,
                              })
                            );
                        }
                      }}
                      maxLength={7}
                      required={
                        financeProfile?.CacValidateStatus ? false : true
                      }
                      readOnly={
                        financeProfile?.CacValidateStatus ? true : false
                      }
                    />
                    <span
                      className={`text-[13px] text-red-500 translate-y-[5px] font-medium block ${
                        RCInputError ? "visible" : "invisible"
                      }`}
                    >
                      {RCInputError}
                    </span>
                  </div>
                </div>
                <div className="w-full">
                  <label
                    className="text-[13.5px] text-[14px] font-semibold"
                    htmlFor="operationYears"
                  >
                    Years of Operations
                  </label>
                  <div>
                    <input
                      type="text"
                      placeholder="Years of operation"
                      className="bg-[#F5F5F5] px-3 text-[13px] mt-2 border border-[#DDDDDD] h-12 md:h-10 w-full font-medium rounded-[10px] focus:outline-none"
                      required
                      {...register("operationYears", { required: true })}
                    />
                  </div>
                </div>
              </div>
              <div className="form flex gap-3 sm:gap-6 md:gap-9 lg:gap-12 flex-col sm:flex-row w-full max-w-[450px] sm:max-w-[700px] pt-0 sm:py-4">
                <div className="w-full">
                  <label
                    className="text-[13.5px] md:text-[14px] font-semibold"
                    htmlFor="directorName"
                  >
                    Director's Name
                  </label>
                  <div>
                    <input
                      readOnly
                      type="text"
                      className="bg-[#dedcdc] px-3 text-[13px] mt-2 border-[#DDDDDD] border-[1px] h-12 md:h-10 w-full font-medium rounded-[10px] focus:outline-none capitalize"
                      {...register("directorName")}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <label
                    className="text-[13.5px] md:text-[14px] font-semibold"
                    htmlFor="directorEmail"
                  >
                    Director's Email
                  </label>
                  <div>
                    <input
                      type="text"
                      className="bg-[#F5F5F5] px-3 text-[13px] mt-2 border-[#DDDDDD] border-[1px] h-12 md:h-10 w-full font-medium rounded-[10px] focus:outline-none"
                      required
                      {...register("directorEmail", { required: true })}
                    />
                  </div>
                </div>
              </div>
              <div className="form flex gap-3 sm:gap-6 md:gap-9 lg:gap-12 flex-col sm:flex-row w-full max-w-[450px] sm:max-w-[700px] py-4">
                <div className="w-full">
                  <label
                    className="text-[13.5px] md:text-[14px] font-semibold"
                    htmlFor="businessWebsite"
                  >
                    Business Website(Optional)
                  </label>
                  <div>
                    <input
                      type="text"
                      className="bg-[#F5F5F5] px-3 text-[13px] mt-2 border-[#DDDDDD] border-[1px] h-12 md:h-10 w-full font-medium rounded-[10px] focus:outline-none"
                      {...register("businessWebsite")}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <label
                    className="text-[13.5px] md:text-[14px] font-semibold"
                    htmlFor="businessAddress"
                  >
                    Business Address
                  </label>
                  <div>
                    <input
                      type="text"
                      className="bg-[#F5F5F5] px-3 text-[13px] mt-2 border-[#DDDDDD] border-[1px] h-12 md:h-10 w-full font-medium rounded-[10px] focus:outline-none capitalize"
                      required
                      {...register("businessAddress", { required: true })}
                    />
                  </div>
                </div>
              </div>
              <div className="form flex gap-3 sm:gap-6 md:gap-9 lg:gap-12 flex-col sm:flex-row w-full max-w-[450px] sm:max-w-[700px] pt-0 sm:py-4">
                <div className="w-full">
                  <label
                    className="text-[13.5px] md:text-[14px] font-semibold"
                    htmlFor="directorPhone"
                  >
                    Director’s phone number
                  </label>
                  <div>
                    <input
                      type="tel"
                      readOnly
                      className="px-3 bg-[#dedcdc] text-[13px] mt-2 border-[#DDDDDD] border-[1px] h-12 md:h-10 w-full font-medium rounded-[10px] focus:outline-none"
                      {...register("directorPhone")}
                    />
                  </div>
                </div>
                <div className="w-full">
                  {financeProfile?.monoId && (
                    <p className="text-mvx-dark text-[13px] italic">
                      <span className="material-icons !text-[24px] text-[#02C39A] mt-5 !leading-4 translate-y-[6px] mr-[2px]">
                        check_circle
                      </span>
                      Account Linked!
                    </p>
                  )}
                  <p
                    className={`text-mvx-blue text-[15px] font-bold cursor-pointer mt-[2px] w-max relative ${
                      financeProfile?.monoId ? "" : "translate-y-9"
                    } ${saveMonoIDLoading && "pointer-events-none"}`}
                    onClick={() => monoConnect().open()}
                  >
                    <span className="material-icons !text-[20px] text-mvx-blue !leading-4 mr-[2px] rotate-[310deg] translate-y-[5px]">
                      link
                    </span>

                    {financeProfile?.monoId
                      ? "Link Another Bank Account"
                      : "Link Your Bank Account"}
                    {saveMonoIDLoading && (
                      <span className="absolute inline-block h-4 w-4 ml-3 translate-y-1">
                        <Loading size={4} color={"mvx-blue"} />
                      </span>
                    )}
                  </p>
                </div>
              </div>
              {!showDoc && (
                <div
                  className={`form flex gap-3 sm:gap-6 md:gap-9 lg:gap-12 flex-col sm:flex-row w-full max-w-[450px] sm:max-w-[700px] mt-12 mb-6 ${
                    requestInProgress && "pointer-events-none"
                  }`}
                >
                  <button
                    className={`${
                      requestInProgress ? "bg-[#688ef6]" : "bg-mvx-blue"
                    } py-3 w-full px-6 text-[#F5F5F5] text-center rounded-[10px] text-[16px]`}
                    type="submit"
                    onClick={() => {
                      setFormButtonState("save&continue");
                    }}
                  >
                    {newFinanceRequestLoading &&
                    formButtonState === "save&continue"
                      ? "Saving..."
                      : "Save and Continue"}
                  </button>
                  <button
                    className="border-[#DDDDDD] border-[1px] py-3 w-full px-6 text-dark rounded-[10px] text-[16px]"
                    type="submit"
                    onClick={() => {
                      setFormButtonState("save&exitOne");
                    }}
                  >
                    {newFinanceRequestLoading &&
                    formButtonState === "save&exitOne"
                      ? "Saving..."
                      : "Save and Exit"}
                  </button>
                </div>
              )}

              {showDoc && (
                <>
                  {" "}
                  <h3 className="text-base md:text-[18px] font-semibold mt-14">
                    Company Documents
                  </h3>
                  <div className="form flex gap-3 gap-y-0 sm:gap-6 md:gap-9 lg:gap-12 flex-col sm:flex-row w-full max-w-[450px] sm:max-w-[700px] mt-1">
                    <div className="w-full mt-3">
                      <label
                        className="text-[13.5px] md:text-[14px] font-semibold"
                        htmlFor="cac-input"
                      >
                        CAC Certificate
                      </label>
                      <label className="">
                        <input
                          type="text"
                          placeholder="CAC certificate upload"
                          id="cac-name"
                          className="bg-[#F5F5F5] px-3 text-[13px] mt-2 border-[#DDDDDD] border-[1px] h-12 md:h-10 w-full font-semibold rounded-[10px] focus:outline-none relative z-20"
                          disabled
                        />
                        <button
                          className="text-[12px] float-right -mt-9 md:-mt-8 rounded-[20px] py-[3px] px-[10px] text-[#333] mr-5 bg-[white] relative z-20"
                          type="button"
                          onClick={() =>
                            document.getElementById("cac-input").click()
                          }
                        >
                          Upload file
                        </button>
                      </label>
                      <span
                        className={`mb-[-5px] h-6 rounded-[8px] block relative z-10 bottom-5 transition-all duration-500 ${
                          CACUploadStatus?.progress
                            ? `w-[${CACUploadStatus.progress}px]`
                            : "w-0"
                        } ${
                          CACReturnType === "error"
                            ? "bg-red-500"
                            : "bg-green-600"
                        }`}
                      ></span>
                      <input
                        type="file"
                        className="hidden"
                        accept=".pdf,.doc,.docx"
                        id="cac-input"
                        onChange={(evt) => {
                          if (evt.target.files.length) {
                            const file = evt.target.files[0];
                            firebaseService.uploadFile(
                              `${documentsUploadURL}/${file.name}`,
                              file,
                              setCACReturnType,
                              document.getElementById("cac-name").offsetWidth,
                              setCACUploadStatus
                            );
                          }
                        }}
                      />
                    </div>

                    <div className="w-full sm:mt-3">
                      <label
                        className="text-[13.5px] md:text-[14px] font-semibold"
                        htmlFor="moa-input"
                      >
                        MOA Document
                      </label>
                      <label>
                        <input
                          type="text"
                          id="moa-name"
                          placeholder="MOA document upload"
                          className="bg-[#F5F5F5] px-3 text-[13px] mt-2 border-[#DDDDDD] border-[1px] h-12 md:h-10 w-full font-semibold rounded-[10px] focus:outline-none relative z-20"
                          disabled
                        />
                        <button
                          className="text-[12px] float-right -mt-9 md:-mt-8 rounded-[20px] py-[3px] px-[10px] text-[#333] mr-5  bg-[white] relative z-20"
                          type="button"
                          onClick={() =>
                            document.getElementById("moa-input").click()
                          }
                        >
                          Upload file
                        </button>
                      </label>
                      <span
                        className={`mb-[-5px] h-6 rounded-[8px] block relative z-10 bottom-5 transition-all duration-500 ${
                          MOAUploadStatus?.progress
                            ? `w-[${MOAUploadStatus.progress}px]`
                            : "w-0"
                        } ${
                          MOAReturnType === "error"
                            ? "bg-red-500"
                            : "bg-green-600"
                        }`}
                      ></span>
                      <input
                        type="file"
                        className="hidden"
                        accept=".pdf,.doc,.docx"
                        id="moa-input"
                        onChange={(evt) => {
                          if (evt.target.files.length) {
                            const file = evt.target.files[0];
                            firebaseService.uploadFile(
                              `${documentsUploadURL}/${file.name}`,
                              file,
                              setMOAReturnType,
                              document.getElementById("moa-name").offsetWidth,
                              setMOAUploadStatus
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form flex gap-3 gap-y-0 sm:gap-6 md:gap-9 lg:gap-12 flex-col sm:flex-row w-full max-w-[450px] sm:max-w-[700px] pb-4 sm:py-4">
                    <div className="w-full">
                      <label
                        className="text-[13.5px] md:text-[14px] font-semibold"
                        htmlFor="statement-input"
                      >
                        Bank Statement
                      </label>
                      <label>
                        <input
                          type="text"
                          placeholder="Bank statement upload"
                          id="statement-name"
                          className="bg-[#F5F5F5] px-3 text-[13px] mt-2 border-[#DDDDDD] border-[1px] h-12 md:h-10 w-full font-semibold rounded-[10px] focus:outline-none relative z-20"
                          disabled
                        />
                        <button
                          className="text-[12px] float-right -mt-9 md:-mt-8 rounded-[20px] py-[3px] px-[10px] text-[#333] mr-5 bg-[white] relative z-20"
                          type="button"
                          onClick={() =>
                            document.getElementById("statement-input").click()
                          }
                        >
                          Upload file
                        </button>
                      </label>
                      <span
                        className={`mb-[-5px] h-6 rounded-[8px] block relative z-10 bottom-5 transition-all duration-500 ${
                          bankStatementUploadStatus?.progress
                            ? `w-[${bankStatementUploadStatus.progress}px]`
                            : "w-0"
                        } ${
                          bankStatementReturnType === "error"
                            ? "bg-red-500"
                            : "bg-green-600"
                        }`}
                      ></span>
                      <input
                        type="file"
                        className="hidden"
                        id="statement-input"
                        accept=".pdf,.doc,.docx"
                        onChange={(evt) => {
                          if (evt.target.files.length) {
                            const file = evt.target.files[0];
                            firebaseService.uploadFile(
                              `${documentsUploadURL}/${file.name}`,
                              file,
                              setBankStatementReturnType,
                              document.getElementById("statement-name")
                                .offsetWidth,
                              setBankStatementUploadStatus
                            );
                          }
                        }}
                      />
                    </div>

                    <div className="w-full">
                      <label className="text-[13.5px] md:text-[14px] font-semibold">
                        Company Director's ID
                      </label>
                      <label>
                        <input
                          type="text"
                          placeholder="Director's ID upload"
                          id="directorID-name"
                          className="bg-[#F5F5F5] px-3 text-[13px] mt-2 border-[#DDDDDD] border-[1px] h-12 md:h-10 w-full font-semibold rounded-[10px] focus:outline-none relative z-20"
                          disabled
                        />
                        <button
                          className="text-[12px] float-right -mt-9 md:-mt-8 rounded-[20px] py-[3px] px-[10px] text-[#333] mr-5  bg-[white] relative z-20"
                          type="button"
                          onClick={() =>
                            document.getElementById("directorID-input").click()
                          }
                        >
                          Upload file
                        </button>
                      </label>
                      <span
                        className={`mb-[-5px] h-6 rounded-[8px] block relative z-10 bottom-5 transition-all duration-500 ${
                          directorIDUploadStatus?.progress
                            ? `w-[${directorIDUploadStatus.progress}px]`
                            : "w-0"
                        } ${
                          directorIDReturnType === "error"
                            ? "bg-red-500"
                            : "bg-green-600"
                        }`}
                      ></span>
                      <input
                        type="file"
                        className="hidden"
                        id="directorID-input"
                        accept=".pdf,.doc,.docx"
                        onChange={(evt) => {
                          if (evt.target.files.length) {
                            const file = evt.target.files[0];
                            firebaseService.uploadFile(
                              `${documentsUploadURL}/${file.name}`,
                              file,
                              setDirectorIDReturnType,
                              document.getElementById("directorID-name")
                                .offsetWidth,
                              setDirectorIDUploadStatus
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {showDoc && (
                <div
                  className={`form flex gap-3 sm:gap-6 md:gap-9 lg:gap-12 flex-col sm:flex-row w-full max-w-[450px] sm:max-w-[700px] mt-6 ${
                    requestInProgress && "pointer-events-none"
                  }`}
                >
                  <button
                    className={`${
                      requestInProgress ? "bg-mvx-light-blue" : "bg-mvx-blue"
                    } py-3 w-full px-6 text-[#F5F5F5] text-center rounded-[10px] text-[16px]`}
                    type="submit"
                    onClick={() => setFormButtonState("save&preview")}
                  >
                    Save and Review
                  </button>
                  <button
                    className="border-[#DDDDDD] border-[1px] py-3 w-full px-6 text-dark rounded-[10px] text-[16px]"
                    type="submit"
                    onClick={() => setFormButtonState("save&exitTwo")}
                  >
                    {newFinanceRequestLoading &&
                    formButtonState === "save&exitTwo"
                      ? "Saving..."
                      : "Save and Exit"}
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateShipment;
