import { NotificationShipIcon } from "assets/arts";
import { currentENVData, truncateWord } from "helpers";
import moment from "moment";
import { useState, useEffect, useRef, useCallback } from "react";
import { authService } from "services";
import { useDispatch } from "react-redux";
import io from "socket.io-client";
import { useNavigate } from "react-router-dom";
import EmptyNotification from "../NotificationExpand/EmptyNotification";
import Loading from "components/common/Loading";
import { ArchiveIcon, TickCircleIcon } from "assets/arts";
import CustomToast from "components/common/CustomToast";
import toast from "react-hot-toast";
import { SuccessFulNotifications } from "../NotificationExpand";
import { notificationsConstant } from "constants";
// import { notificationsActions } from "actions";
// import { notificationsConstant } from "constants";

const socket = io(currentENVData.notificationsURL);

const Notifications = () => {
  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected", socket);
    });
    socket.on("new_activity", (data) => {
      console.log("new activity", data);
    });
  }, []);

  const navigate = useNavigate();
  const observer = useRef();
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [isNewMessages, setIsNewMessages] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [notification, setNotification] = useState([]);
  const [wasLastList, setWasLastList] = useState(false);
  const [notificationError, setNotificationError] = useState("");
  const [currentTab, setCurrentTab] = useState("All");
  const [showSuccessMessage, setShowSuccessMessage] = useState("");
  const [showMarkLoading, setShowMarkLoading] = useState(false);

  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage("");
      }, 5000);
    }
  }, [showSuccessMessage]);
  
  // set all states to default
  const setStatesToDefault = () => {
    setCurrentPage(1);
    setPrevPage(0);
    setNotification([]);
    setWasLastList(false);
    setNotificationError("");
    setNotificationLoading(false);
  };

  // main function function that query API with known dates
  const queryUserNotifications = async (url) => {
    try {
      if (currentTab === "All") {
        url = `users/notifications?page=${currentPage}`;
      } else if (currentTab === "Unread") {
        url = `users/notifications?page=${currentPage}&read=false`;
      } else if (currentTab === "Archived") {
        url = `users/notifications?page=${currentPage}&archived=true`;
      }

      setNotificationLoading(true);

      const response = await authService.apiGate(
        `${currentENVData.transitURL}/${url}`,
        { method: "GET" }
      );

      setNotificationLoading(false);

      setPrevPage(currentPage);

      setNotification([...notification, ...response.data]);
      setNotificationError("");
      let totalNotifications;
      if (response.total_pages) {
        totalNotifications = response.total_pages;
      }

      if (currentPage === totalNotifications) {
        setWasLastList(true);
        setNotificationLoading(false);
        return;
      }
    } catch (error) {
      setNotificationLoading(false);

      if (error.status === 404 || error.status === 400) {
        setNotificationError(error.message);
      } else if (error.message) {
        toast.custom((t) => (
          <CustomToast t={t} message={error.message} type="error" />
        ));
      }
    }
  };

  // TODO
  // mark as read and mark as archive not updating once marked.
  useEffect(() => {
    if (notification.length && currentTab === "All") {
      const bool = notification?.some((not) => !not.read);
      setIsNewMessages(bool);
    }
  }, [notification, currentTab]);

  useEffect(() => {
    if (!wasLastList && prevPage !== currentPage) {
      queryUserNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, wasLastList, prevPage, notification]);

  // get observer to implement infinite scroll
  const lastBookRef = useCallback(
    (node) => {
      if (notificationLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !wasLastList) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [wasLastList, notificationLoading]
  );

  const marKNotificationAsRead = async (notf) => {
    if (notf.read) {
      return;
    }

    try {
      setShowMarkLoading(true);

      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          read: true,
          activityId: notf._id,
        }),
      };
      const res = await authService.apiGate(
        `${currentENVData.transitURL}/users/notification/mark`,
        requestOptions
      );

      setShowMarkLoading(false);
      setNotification((prevState) =>
        prevState.map((notfic) => {
          if (notfic?._id === notf?._id) {
            return res.data;
          } else {
            return notfic;
          }
        })
      );
      setShowSuccessMessage(res.message);
    } catch (error) {
      setShowMarkLoading(false);
      toast.custom((t) => (
        <CustomToast t={t} message={`${error.message}!`} type="error" />
      ));
    }
  };

  const marKNotificationAsArchived = async (notf) => {
    if (notf.archived) {
      return;
    }

    try {
      setShowMarkLoading(true);

      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          archived: true,
          activityId: notf._id,
        }),
      };
      const res = await authService.apiGate(
        `${currentENVData.transitURL}/users/notification/archive`,
        requestOptions
      );

      setShowMarkLoading(false);
      setNotification((prevState) =>
        prevState.map((notfic) => {
          if (notfic?._id === notf?._id) {
            return res.data;
          } else {
            return notfic;
          }
        })
      );
      setShowSuccessMessage(res.message);
    } catch (error) {
      setShowMarkLoading(false);
      toast.custom((t) => (
        <CustomToast t={t} message={`${error.message}!`} type="error" />
      ));
    }
  };

  return (
    <div className="hidden w-[35px] h-[35px] relative bg-[#F8FAFF] select-none cursor-pointer rounded-full sm:flex items-center justify-center mr-4">
      <span
        className={`material-icons text-mvx-blue relative ${
          isNewMessages &&
          "after:absolute after:top-[1px] after:right-[2px] after:h-[9px] after:w-[9px] after:bg-red-500 after:rounded-full after:border-[1.5px] after:border-white"
        }`}
      >
        notifications
      </span>

      <div className="showMiniNotfDiv absolute center-item w-[60px] h-[60px] z-[9000]">
        <div className="miniNotf w-[500px] h-[70vh] cursor-default bg-white shadow-lg border border-gray-100 absolute top-full rounded-lg right-0 ">
          <div className="h-[110px] fixed bg-white z-5 w-[498px]">
            <div className="flex items-center justify-between p-6">
              <p className="whitespace-nowrap text-lg font-bold">
                Notifications
              </p>
              <p className="whitespace-nowrap flex items-center text-xs cursor-pointer">
                <span
                  className="underline"
                  onClick={() => navigate("/dashboard/notifications")}
                >
                  View all notifications now
                </span>
                <span className="material-icons ml-1 !text-sm">
                  open_in_new
                </span>
              </p>
            </div>
            <div className="w-full border-b border-gray-200 px-6 flex">
              <Tab
                title={"All"}
                tab={"All"}
                active={currentTab === "All"}
                setTab={setCurrentTab}
                setStatesToDefault={setStatesToDefault}
              />
              <Tab
                title={"Unread"}
                tab={"Unread"}
                active={currentTab === "Unread"}
                setTab={setCurrentTab}
                unread={
                  notification?.filter((notf) => notf.read === false).length
                }
                setStatesToDefault={setStatesToDefault}
              />
              <Tab
                title={"Archived"}
                tab={"Archived"}
                active={currentTab === "Archived"}
                setTab={setCurrentTab}
                setStatesToDefault={setStatesToDefault}
              />
            </div>
          </div>
          <div
            className="h-[calc(100%-85px)] relative top-[109px]"
            id="scrollDiv"
          >
            <div
              className={`overflow-y-auto  ${
                notification.length
                  ? "h-[calc(100%-85px)]"
                  : notificationError
                  ? "h-[calc(100%-85px)]"
                  : "h-2 "
              }`}
              id="scrollDiv"
            >
              {notificationError !== "" && notification.length === 0 ? (
                <div className="h-full flex items-center justify-center">
                  <EmptyNotification />
                </div>
              ) : (
                <div className="">
                  {notification?.map((notf, index) => {
                    if (notification.length === index + 1) {
                      return (
                        <div ref={lastBookRef} key={notf._id}>
                          <Notification
                            content={truncateWord(notf.message, 6)}
                            type={notf.action_type}
                            read={notf.read}
                            time={notf.createdAt}
                            _id={notf._id}
                            archived={notf.archived}
                            markAsRead={() => marKNotificationAsRead(notf)}
                            markAsArchived={() =>
                              marKNotificationAsArchived(notf)
                            }
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div key={notf._id}>
                          <Notification
                            content={truncateWord(notf.message, 6)}
                            type={notf.action_type}
                            read={notf.read}
                            time={notf.createdAt}
                            _id={notf._id}
                            archived={notf.archived}
                            markAsRead={() => marKNotificationAsRead(notf)}
                            markAsArchived={() =>
                              marKNotificationAsArchived(notf)
                            }
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>

            {notificationLoading && (
              <div className="h-16 relative flex justify-center items-center">
                <Loading color="mvx-blue" size={7} />
              </div>
            )}
            {showMarkLoading && (
              <div className="h-16 relative flex justify-center items-center">
                <Loading color="mvx-blue" size={7} />
              </div>
            )}
            {showSuccessMessage && (
              <div className="h-16 relative bg-[#fbfbfb] flex justify-center items-center">
                <SuccessFulNotifications text={showSuccessMessage} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Tab = ({
  title,
  active,
  tab,
  setTab,
  unread,
  setStatesToDefault,
}) => {
  return (
    <div
      onClick={() => {
        setTab(tab);
        setStatesToDefault();
      }}
      className={`pb-2 px-4 cursor-pointer w-auto border-b-[3px] font-bold mr-6 text-[13px] ${
        active ? "border-mvx-blue text-mvx-blue" : "text-[#AAA] border-white"
      }`}
    >
      <p className="flex gap-1.5 items-center">
        <span className="tracking-wide">{title}</span>
        {title !== "Archived" && (
          <span
            className={`w-4 h-4 bg-[#eee] text-[#999] text-[13px] font-bold flex items-center justify-center rounded-sm ${
              unread ? "inline" : "hidden"
            }`}
          >
            {unread}
          </span>
        )}
      </p>
    </div>
  );
};

export const Notification = ({
  content,
  type,
  read,
  time,
  archived,
  _id,
  markAsRead,
  markAsArchived,
}) => {
  const dispatch = useDispatch();
  const notificationToUpdate = () => {
    dispatch({
      type: notificationsConstant.MOBILE_NOTIFICATION_TO_UPDATE,
      payload: {
        toggleModal: true,
        notification: { type, read, archived, _id },
      },
    });
    // dispatch({type:notificationToUpdate.})
  };
  return (
    <div
      className={`notificationDiv relative px-8 py-5 flex hover:!bg-[#fbfbfb] cursor-pointer border-b border-[#eee] ${
        !read ? "bg-[#ecf1ff]" : "bg-white"
      }`}
    >
      <div
        className="absolute top-0 left-0 bottom-0 right-0"
        onClick={notificationToUpdate}
      ></div>
      <NotificationShipIcon className="mr-4 w-[30px] h-auto shrink-0" />
      <div className="w-full space-y-1">
        <p className="mb-1 text-[13.75px] font-bold text-[#333] w-10/12 tracking-wide">
          {content}
        </p>
        <p className="text-[13.5px] font-bold text-mvx-blue mb-1">{type}</p>
        <p className="text-xs text-[#333333]">
          {moment(time).startOf().fromNow()}
        </p>
        <div className="absolute notfAction right-8 top-2 h-8 w-8 rounded-full hover:bg-[#eee] p-1.5 group hidden sm:block">
          <span className="material-icons !font-semibold !text-[20px] ">
            more_vert
          </span>
          <div className="absolute z-[1000] hidden group-hover:block shadow-[3px_4px_15px_0_#0000001A] bg-white rounded-md py-1.5 text-[12.5px] text-[#686868] font-bold w-40 top-full -left-28 tracking-wide">
            {/* {archived === false && ( */}
            <p
              className={`py-2.5 px-3 cursor-pointer border-b border-grey-500  flex gap-1 items-center ${
                archived
                  ? "cursor-default"
                  : "cursor-pointer hover:bg-[#f1f1f1]"
              }`}
              onClick={markAsArchived}
              // onClick={() => markAsArchived(_id, "archive")}
            >
              <ArchiveIcon width="18px" height="18px" />
              <span>Archive message</span>
            </p>

            <p
              className={`py-2.5 px-3 cursor-pointer flex gap-1 items-center ${
                read ? "cursor-default" : "cursor-pointer hover:bg-[#f1f1f1]"
              }`}
              onClick={markAsRead}
              // onClick={() => markAsRead(_id, "read")}
            >
              <TickCircleIcon width="18px" height="18px" />
              <span>Mark as read</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
