import {
  GlobalReachIcon,
  IncreasedRevenueIcon,
  LargerMarketIcon,
} from "assets/arts";
import React from "react";

const Offering = () => (
  <div className="w-[85%] sm:w-full m-auto">
    <p className="text-center font-catalina mb-4 text-2xl sm:text-3xl">
      Here's why 300+ Companies Choose MVX
    </p>
    <div className="w-20 h-[3px] bg-mvx-blue m-auto mb-10"></div>
    <div className="flex flex-wrap sm:flex-nowrap justify-between w-full sm:w-4/5 m-auto mb-20">
      <div className="w-full sm:w-[calc((100%-90px)/3)] p-4 pb-8 sm:p-4 text-center">
        <IncreasedRevenueIcon className="mb-4 m-auto" />
        <p className="text-sm mb-2 font-semibold">Increased Revenue</p>
        <p className="text-xs font-light text-gray-600">
          By becoming an MVX partner, we connect you with our customers’ trade
          demands, and increasing your revenue in the process
        </p>
      </div>
      <div className="w-full sm:w-[calc((100%-90px)/3)] p-4 pb-8 text-center">
        <LargerMarketIcon className="mb-4 m-auto" />
        <p className="text-sm mb-2 font-semibold">Larger Market</p>
        <p className="text-xs font-light text-gray-600">
          MVX helps you expand the business services by giving you access to
          more customers.
        </p>
      </div>
      <div className="w-full sm:w-[calc((100%-90px)/3)] p-4 pb-8 text-center">
        <GlobalReachIcon className="mb-4 m-auto" />
        <p className="text-sm mb-2 font-semibold">Global Reach</p>
        <p className="text-xs font-light text-gray-600">
          Through our technology, MVX exposes your business and services to a
          global audience and connect you to our customers.
        </p>
      </div>
    </div>
  </div>
);

export default Offering;
