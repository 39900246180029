import {
  CustomsIcon,
  CustomsIconWhite,
  ExploreIcon,
  FreightFinanceIcon,
  FreightFinanceIconWhite,
  FreightForwardingIcon,
  FreightForwardingIconWhite,
  HaulageIcon,
  HaulageIconWhite,
} from "assets/arts";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick/lib/slider";
import styled from "styled-components";
import services from "variables/services";

var settings = {
  dots: false,
  infinite: true,
  vertical: true,
  // fade: true,
  speed: 300,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 640,
      settings: "unslick",
    },
  ],
};

const Services = ({ serviceRef }) => {
  const navigate = useNavigate();
  const [serviceTab, setServiceTab] = useState(0);
  const slider = useRef(null);

  useEffect(() => {
    if (slider?.current && document.documentElement.clientWidth > 640) {
      slider?.current?.slickGoTo(serviceTab);
    }
  }, [serviceTab]);

  const exploreMore = (link) => {
    navigate(link);
  };

  return (
    <div ref={serviceRef} className="pt-10 sm:pt-20">
      <div className="px-4 border-t border-gray">
        <ServicesContainer className=" pt-10 pb-10 sm:pb-20 mb-10 sm:mb-20 rounded-b-lg">
          <div className="w-[90%] sm:w-4/5 m-auto">
            <span className="text-sm bg-[#005ECD]/10 px-2.5 py-1.5 rounded-lg text-[#1261BF] font-bold">
              Our services
            </span>
            <p className="font-catalina text-2xl sm:text-3xl mt-4">
              We are your all-in-one platform powering{" "}
              <br className="hidden sm:block" />
              cross-border trade
            </p>
            <div className="w-full py-2 px-2 sm:px-4 rounded-xl overflow-x-auto	sm:overflow-hidden bg-white mt-8 flex justify-between">
              <div
                className={`px-4 sm:px-8 py-2 sm:py-4 text-sm sm:text-md text-sm sm:text-md cursor-pointer select-none rounded-full flex items-center duration-200 ${
                  serviceTab === 0 && "bg-gray-700"
                }`}
                onClick={() => setServiceTab(0)}
              >
                {serviceTab === 0 ? (
                  <FreightForwardingIconWhite className="mr-3" />
                ) : (
                  <FreightForwardingIcon className="mr-3" />
                )}
                <span
                  className={`${
                    serviceTab === 0 ? "text-white" : "text-gray"
                  } font-bold whitespace-nowrap`}
                >
                  Freight Forwarding
                </span>
              </div>
              <div
                className={`px-4 sm:px-8 py-2 sm:py-4 text-sm sm:text-md cursor-pointer select-none rounded-full flex items-center duration-200 ${
                  serviceTab === 1 && "bg-gray-700"
                }`}
                onClick={() => setServiceTab(1)}
              >
                {serviceTab === 1 ? (
                  <HaulageIconWhite className="mr-3 h-[15px] w-auto" />
                ) : (
                  <HaulageIcon className="mr-3 h-[15px] w-auto" />
                )}
                <span
                  className={`${
                    serviceTab === 1 ? "text-white" : "text-gray"
                  } font-bold whitespace-nowrap`}
                >
                  Haulage
                </span>
              </div>
              <div
                className={`px-4 sm:px-8 py-2 sm:py-4 text-sm sm:text-md cursor-pointer select-none rounded-full flex items-center duration-200 ${
                  serviceTab === 2 && "bg-gray-700"
                }`}
                onClick={() => setServiceTab(2)}
              >
                {serviceTab === 2 ? (
                  <FreightFinanceIconWhite className="mr-3" />
                ) : (
                  <FreightFinanceIcon className="mr-3" />
                )}
                <span
                  className={`${
                    serviceTab === 2 ? "text-white" : "text-gray"
                  } font-bold whitespace-nowrap`}
                >
                  Freight Financing
                </span>
              </div>
              <div
                className={`px-4 sm:px-8 py-2 sm:py-4 text-sm sm:text-md cursor-pointer select-none rounded-full flex items-center duration-200 ${
                  serviceTab === 3 && "bg-gray-700"
                }`}
                onClick={() => setServiceTab(3)}
              >
                {serviceTab === 3 ? (
                  <CustomsIconWhite className="mr-3" />
                ) : (
                  <CustomsIcon className="mr-3" />
                )}
                <span
                  className={`${
                    serviceTab === 3 ? "text-white" : "text-gray"
                  } font-bold whitespace-nowrap`}
                >
                  Customs Clearance
                </span>
              </div>
            </div>
            <Slider className="" {...settings} ref={slider}>
              {services?.map((service, index) => (
                <div className="w-full" key={index}>
                  <div
                    className={`w-full block sm:flex items-center mt-12 pt-2 h-auto sm:h-[400px] overflow-y-hidden overflow-hidden relative ${
                      serviceTab !== index ? "hidden sm:flex" : ""
                    }`}
                  >
                    <div className="w-full z-20 sm:w-2/5 mr-20 pl-0 sm:pl-6 mb-10 sm:mb-0">
                      <p className="text-gray-700 text-sm">
                        {service.description}
                      </p>
                      <button
                        type="button"
                        onClick={() => exploreMore(service.link)}
                        className="bg-mvx-orange cursor-pointer text-white outline-0 rounded-xl h-[50px] px-8 text-sm mr-4 mt-6 whitespace-nowrap sm:mt-10 flex items-center"
                      >
                        Explore More
                        <ExploreIcon className="ml-2" />
                      </button>
                    </div>
                    <div className="relative sm:absolute right w-full h-auto sm:h-[400px] shrink-0">
                      <img
                        src={service.image}
                        alt={service.title}
                        className="sm:h-full sm:w-auto h-auto w-full float-right"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </ServicesContainer>
      </div>
    </div>
  );
};

const ServicesContainer = styled.div`
  background-color: rgba(127, 178, 133, 0.1);
`;

export default Services;
