import { currentENVData } from "helpers";
import { authService } from "./authService";

export const financeService = {
  saveMonoID,
  fetchFinanceProfile,
  fetchFinanceRequests,
  createNewFinanceRequest,
  verifyBVN,
  verifyCAC,
  updateFinanceProfile,
  updateFinanceProfileImage,
  uploadCACDOC,
  uploadMOADOC,
  uploadDirectorID,
  uploadAuditDOC,
  uploadNEPCDOC,
  fetchCreditScore,
  fetchAFinanceRequest,
};

async function saveMonoID(monoIdObj) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(monoIdObj),
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/users/verify/save-mono-id`,
    requestOptions
  );

  return res;
}

async function fetchFinanceProfile() {
  const requestOptions = {
    method: "GET",
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/users/verify/finance-profile`,
    requestOptions
  );
  return res;
}

async function fetchFinanceRequests(filterData) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(filterData),
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/trade/users/filter`,
    requestOptions
  );

  return res;
}

async function createNewFinanceRequest(reqData) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(reqData),
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/trade/users/create_finance`,
    requestOptions
  );
  return res;
}

async function verifyBVN(bvn) {
  const requestOptions = {
    method: "GET",
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/users/verify/verify-bvn/${bvn}`,
    requestOptions
  );
  return res;
}

async function verifyCAC(reqData) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(reqData),
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/users/verify/verify-cac`,
    requestOptions
  );
  return res;
}

async function updateFinanceProfile(obj) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(obj),
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/trade/users/finance-profile`,
    requestOptions
  );
  return res;
}

async function updateFinanceProfileImage(profileImage) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(profileImage),
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/trade/users/finance-profile`,
    requestOptions
  );
  return res;
}

async function uploadCACDOC(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/trade/users/finance-profile`,
    requestOptions
  );
  return res;
}

async function uploadMOADOC(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/trade/users/finance-profile`,
    requestOptions
  );
  return res;
}

async function uploadDirectorID(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/trade/users/finance-profile`,
    requestOptions
  );
  return res;
}

async function uploadAuditDOC(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/trade/users/finance-profile`,
    requestOptions
  );
  return res;
}

async function uploadNEPCDOC(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
  };

  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/trade/users/finance-profile`,
    requestOptions
  );
  return res;
}

async function fetchCreditScore() {
  const requestOptions = {
    method: "GET",
  };
  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/users/verify/credit-score`,
    requestOptions
  );
  return res;
}

async function fetchAFinanceRequest(shipmentId) {
  const requestOptions = {
    method: "GET",
  };
  const res = await authService.apiGate(
    `${currentENVData.tradeURL}/trade/users/${shipmentId}`,
    requestOptions
  );
  return res;
}
