/* eslint-disable */
import { RateIcon, RateIconFilled } from "assets/arts";
import React, { useState } from "react";
import Rating from "react-rating";

const Feedback = () => {
  const [rating, setRating] = useState(false);
  const [hoverRating, setHoverRating] = useState(false);
  const [submittingReview, setSubmittingReview] = useState(false);

  const rates = {
    1: "Poor",
    2: "Fair",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };

  return (
    <div className="h-full w-full flex items-center justify-center">
      {!rating ? (
        <div className="w-full flex flex-col items-center justify-center">
          <p className="text-2xl font-bold w-1/3 text-center mb-6">
            How was your shipment request experience with MVX?
          </p>
          <Rating
            initialRating={rating}
            emptySymbol={<RateIcon />}
            fullSymbol={<RateIconFilled />}
            fractions={2}
            onChange={(value) => setRating(value)}
            onHover={(value) => setHoverRating(value)}
          />
          <div className="h-10">
            {(hoverRating || rating) && (
              <p className="text-md w-1/3 font-semibold text-center mt-4 mb-24">
                {rates[Math.ceil(hoverRating || rating)]}
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col items-center justify-center">
          <p className="text-2xl font-bold w-1/3 text-center mb-4">
            Share feedback about this shipment
          </p>
          <p className="text-sm w-[40%] text-center mb-10">
            This feedback is just for this shipment. Tell us about your
            experience with the entire process of this shipment request.
          </p>
          <div className="w-1/3">
            <p className="mb-2 font-bold text-sm">Review your shipment</p>
            <textarea
              className={`border border-[#DFE1E6] bg-[#FFF] h-[140px] py-[16px] px-[20px] w-full rounded-[2px] text-[13px] resize-none focus:outline-none text-[#091D51] mb-4`}
              placeholder="Enter reason here"
            ></textarea>
            <div className="text-right">
              <button
                type="submit"
                className={`bg-mvx-blue shadow-lg outline-0 rounded-[10px] min-w-[40%] h-[40px] text-white text-sm mb-6 mb-14 ${
                  submittingReview ? "!bg-mvx-light-blue" : ""
                }`}
                disabled={submittingReview}
              >
                {submittingReview ? "Sumbitting..." : "Rate this shipment"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Feedback;
