var partner = [
  {
    title: "Finance",
    tagLine: "Offer Credit at the Best Rates to our over 600+ Customers",
    description: `We connect our financing partners with pre-vetted credit-worthy customers and 
      bankable transactions. In turn, they provide financing for trade transactions to our 
      clients then receive the repayments once the financing matures.`,
    image: require("assets/images/partner/finance.png"),
  },
  {
    title: "Freight Forwarding",
    tagLine: "Boost Your Business by Joining our Pool of Freight Forwarders",
    description: `We connect our financing partners with pre-vetted credit-worthy customers and 
      bankable transactions. In turn, they provide financing for trade transactions to our 
      clients then receive the repayments once the financing matures.`,
    image: require("assets/images/partner/freight-forwarding.png"),
  },
  {
    title: "Land Haulage",
    tagLine: "Boost Your Business by Joining our Pool of Haulage Partners",
    description: `We connect our financing partners with pre-vetted credit-worthy customers and 
      bankable transactions. In turn, they provide financing for trade transactions to our 
      clients then receive the repayments once the financing matures.`,
    image: require("assets/images/partner/haulage.png"),
  },
];

export default partner;
