import React from "react";
import NewsletterForm from "./NewsletterForm";

const Newsletter = () => {
  return (
    <div className="py-20">
      <div className="w-[85%] sm:w-4/5 p-6 sm:p-14 bg-mvx-blue rounded-2xl m-auto">
        <p className="text-white text-xl sm:text-2xl font-bold mb-6">
          Newsletter Subscription
        </p>
        <NewsletterForm />
      </div>
    </div>
  );
};

export default Newsletter;
