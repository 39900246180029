import { useState, useRef, useEffect } from "react";
import { DocumentUpload, DocumentUploadSuccess } from "assets/arts";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getPdfName } from "helpers/validateUserFormPage";
import { financeActions } from "actions";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import OpenPdf from "../OpenPdf";

const InputCac = () => {
  const fileInput = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const { financeProfile, uploadCACDOCLoading } = useSelector(
    (state) => state.finance
  );
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const formatted_name = getPdfName(user.company_name);

  const uploadTask = useRef(null);
  const cancelUpload = () => {
    uploadTask.current.cancel();
  };

  const [error, setError] = useState("");
  const [prog, setProg] = useState(0);
  const [url, setUrl] = useState("");
  const [openPdf, setOpenPdf] = useState(false);

  useEffect(() => {
    if (url === "" && !uploadCACDOCLoading) {
      return;
    } else if (url !== "" && prog === 100) {
      dispatch(
        financeActions.uploadCACDOC({
          documentUploaded: [
            {
              title: "CAC CERTIFICATE",
              url: url,
            },
          ],
        })
      );
      setUrl("");
      setProg(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, uploadCACDOCLoading, prog]);

  useEffect(() => {
    if (error && error?.message?.includes("User canceled")) {
      toast.custom((t) => (
        <CustomToast t={t} message="Upload cancelled by user." type="error" />
      ));
    } else if (error) {
      toast.custom((t) => (
        <CustomToast
          t={t}
          message="Upload failed! Reupload file."
          type="error"
        />
      ));
    }
  }, [error]);

  const uploadFile = (file) => {
    const storage = getStorage();
    const documentUploadPath = `companyDocs/cacDocs/${formatted_name}-${Date.now()}`;
    const storageRef = ref(storage, documentUploadPath);

    uploadTask.current = uploadBytesResumable(storageRef, file);

    uploadTask.current.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProg(progress);
      },
      (error) => {
        setError(error);
        setTimeout(() => {
          setProg(0);
          setError("");
        }, 500);
      },
      () => {
        getDownloadURL(uploadTask.current.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
        });
      }
    );
  };

  const checkCacDoc = financeProfile?.documentUploaded?.find(
    (doc) => doc?.title === "CAC CERTIFICATE"
  );

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      uploadFile(file);
    }
  };

  const handleChange = function (e) {
    const file = e.target.files[0];
    if (file) {
      uploadFile(file);
    }
  };

  useEffect(() => {
    setProg(0);
    setUrl("");
  }, []);

  const closePdf = () => {
    setOpenPdf(false);
  };

  return (
    <div className="flex flex-col gap-4 text-center">
      {openPdf && <OpenPdf link={checkCacDoc?.url} closePdf={closePdf} />}
      <div
        className="relative border-2 border-dashed border-[#d6d6d6] rounded-lg flex items-center justify-center w-full max-w-[500px] min-h-[230px] sm:min-h-[0px] sm:h-40 sm:w-60"
        onDragEnter={handleDrag}
      >
        <div className="flex items-center justify-center flex-col">
          {uploadCACDOCLoading && (
            <div className="text-[#333333] flex items-center justify-center flex-col text-center gap-2">
              <div className="relative w-10 h-10">
                <CircularProgressbar
                  value={100}
                  text={`${100}%`}
                  strokeWidth={11}
                  styles={buildStyles({
                    textSize: "24px",
                    textColor: "#091D51",
                    pathColor: "#1152FA",
                    trailColor: "#E6E6E6",
                    rotation: 0.5 + (1 - 65 / 100) / 1,
                  })}
                />
              </div>
              <p className="font-medium text-sm">Uploading file...</p>
            </div>
          )}
          {prog > 0 && !url && (
            <div className="text-[#333333] flex items-center justify-center flex-col text-center space-y-1.5">
              <div className="relative w-10 h-10">
                <CircularProgressbar
                  value={prog}
                  text={`${parseInt(prog)}%`}
                  strokeWidth={11}
                  styles={buildStyles({
                    textSize: "24px",
                    textColor: "#091D51",
                    pathColor: "#1152FA",
                    trailColor: "#E6E6E6",
                    rotation: 0.5 + (1 - 65 / 100) / 1,
                  })}
                />
              </div>
              <p className="font-medium text-sm">Uploading file...</p>

              <button
                className="font-bold px-5 py-1.5 rounded-md bg-[#f5f5f5] text-[13px]"
                onClick={cancelUpload}
              >
                Cancel
              </button>
            </div>
          )}

          {!uploadCACDOCLoading && checkCacDoc && (
            <div className=" flex flex-col justify-center text-center items-center space-y-1">
              <DocumentUploadSuccess width={36} height={36} />
              <p
                className="hidden md:block font-bold text-[12.5px] text-[#333333] cursor-pointer"
                onClick={() => setOpenPdf(true)}
              >
                {formatted_name}
              </p>
              <p className="md:hidden font-bold text-[12.5px] text-[#333333]">
                {formatted_name}
              </p>
            </div>
          )}
          {prog === 0 && !checkCacDoc && !uploadCACDOCLoading && (
            <div
              className={`${
                prog > 0 && !url && uploadCACDOCLoading && !checkCacDoc
                  ? "hidden"
                  : "flex flex-col justify-center text-center items-center "
              }`}
            >
              <DocumentUpload width={36} height={36} />
              <input
                type="file"
                ref={fileInput}
                className="hidden"
                id="cac-input"
                accept=".pdf,.doc,.docx"
                onChange={handleChange}
              />
              <p
                className="cursor-pointer pt-1 font-semibold text-[13px] underline text-[#333333]"
                onClick={() => document.getElementById("cac-input").click()}
              >
                Select a file to upload
              </p>
              <p className="text-[12px] text-[#999999]">or drag and drop</p>
              {dragActive && (
                <div
                  className="absolute w-full h-full"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </div>
          )}
        </div>
      </div>
      <p className="font-semibold text-[15px]">CAC Certificate</p>
    </div>
  );
};
export default InputCac;
