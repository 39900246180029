import { CheckIcon, WhiteCheckIcon } from "assets/arts";

const MobileShipProgIndicator = ({ shipment }) => {
  const deliveryDetailsValid =
    shipment.origin?.lat &&
    shipment.destination?.lat &&
    shipment?.shipping_date;

  return (
    <div className="fixed w-full flex items-center overflow-x-scroll sm:hidden bg-white py-4 px-3 top-[134px] left-0 gap-3 mobileShipmentProgress select-none z-[1000]">
      <div
        className={`flex items-center gap-2 py-2 px-5 rounded-2xl whitespace-nowrap ${
          !shipment.service_type ? "bg-mvx-blue" : "bg-white"
        }`}
      >
        <span
          className={`text-[#022b79 text-[13.5px] break-keep  ${
            !shipment?.service_type
              ? "font-medium text-white"
              : "font-bold text-[#0e2b79]"
          }`}
        >
          Select service type
        </span>

        <span>
          {shipment.service_type ? (
            <CheckIcon className="w-auto h-[15px]" />
          ) : (
            <WhiteCheckIcon className="w-auto h-[15px]" />
          )}
        </span>
      </div>
      <div
        className={`flex items-center gap-2 py-2 px-5 rounded-2xl whitespace-nowrap ${
          !shipment.service_mode ? "bg-mvx-blue" : "bg-white"
        }`}
      >
        <span
          className={`text-[#022b79 text-[13.5px] break-keep  ${
            !shipment?.service_mode
              ? "font-medium text-white"
              : "font-bold text-[#0e2b89]"
          }`}
        >
          How would you like to move your goods?
        </span>

        <span>
          {shipment.service_mode ? (
            <CheckIcon className="w-auto h-[15px]" />
          ) : (
            <WhiteCheckIcon className="w-auto h-[15px]" />
          )}
        </span>
      </div>
      <div
        className={`flex items-center gap-2 py-2 px-5 rounded-2xl whitespace-nowrap ${
          !deliveryDetailsValid ? "bg-mvx-blue" : "bg-white"
        }`}
      >
        <span
          className={`text-[#022b79 text-[13.5px] break-keep  ${
            !deliveryDetailsValid
              ? "font-medium text-white"
              : "font-bold text-[#0e2b89]"
          }`}
        >
          How would you like to move your goods?
        </span>

        <span>
          {deliveryDetailsValid ? (
            <CheckIcon className="w-auto h-[15px]" />
          ) : (
            <WhiteCheckIcon className="w-auto h-[15px]" />
          )}
        </span>
      </div>
    </div>
  );
};

export default MobileShipProgIndicator;
