let faqs = [
  {
    question: "What services does MVX offer?",
    answer:
      "MVX offers shipping and freight handling services across borders and within Nigeria including customs brokerage, truck and barge haulage, Door-to-Door delivery, Import and Export clearance.",
    category: "platform",
  },
  {
    question: "I have goods that I want to export, how do I ship to my buyer?",
    answer:
      "Visit mvx.trade, tap on “Book a Shipment” and fill in all the details. That takes you less than 5 minutes. Within 3 hours, our agent sends a response with comprehensive rates and you choose the best cost option for you. Then we pick up, inspect your cargo, and our team of trade experts handle customs clearance. We move your cargo to its destination while sending you real-time updates.",
    category: "web",
  },
  {
    question: "When can I get a quote?",
    answer:
      "Our agents are available Mondays to Saturdays, from 9 AM to 6 PM. So, once your request comes in, you can get a quote within 3 hours.",
    category: "web",
  },
  {
    question: "Does MVX ship to China and India?",
    answer:
      "Yes we do. MVX ships from Nigeria, Ghana, South Africa, Kenya, Tanzania and Rwanda to everywhere in the world, including China and India.",
    category: "web",
  },
  {
    question: "Can I send my equipment for repairs in Europe and return it?",
    answer:
      "With MVX, you can. We’ll pick up your equipment from your location in Nigeria, Ghana, South Africa, Kenya, Tanzania and Rwanda. Then we’ll ship to the repair destination, and when it’s all done, we’ll ship it back to your location. It’s that simple.",
    category: "web",
  },
  {
    question: "How long will it take for my shipment to be delivered?",
    answer:
      "For international freight forwarding, it takes an average of 4 days to clear the goods at the sea or air port. That plus the amount of time the vessel takes to travel to the shipping destination. Land haulage, on the other hand, does not need any port clearance. So it only depends on the distance from the pickup point to the destination.",
    category: "web",
  },
  {
    question: "What documentation do you need to help me export my goods?",
    answer:
      "NXP form, NESS payment receipt, Packing list, Commercial invoice, Ocean/Air waybill, Certificate of Origin, Movement certificate, phytosanitary and fumigation certificates (if agro commodity).",
    category: "web",
  },
  {
    question: "What documentation do you need to help me import my goods?",
    answer:
      "FORM M, Original Ocean Bill of Laden or Airway Bill, Packing list, Commercial Invoice , Duty Exemption Certificate (if applicable), PAAR, SONCAP (if applicable), Analysis/Manufacturing Certificate, etc",
    category: "web",
  },
  {
    question: "What is Door-to-Door Delivery?",
    answer:
      "Door-to-Door delivery means shipping from the door of the supplier to the door of the consignee.",
    category: "freight-forwarding",
  },
  {
    question: "What documents are required for Import shipment?",
    answer:
      "Typically, basic documents required for Imports includes Bill of Lading / Airway Bill, Commercial Invoice, Packing List, Insurance certificate, e-Form M",
    category: "freight-forwarding",
  },
  {
    question: "What is e-Form M?",
    answer:
      "Form M is a document issued by the central bank of Nigeria as an approval or permit to import goods. Every goods that is Imported into Nigeria must have an e-form M number which must precede the date goods is shipped onboard.",
    category: "freight-forwarding",
  },
  {
    question: "What does Ship onboard mean?",
    answer:
      "This means that cargo is received by shipping line and placed onboard a named vessel. The date the cargo is placed onboard is called shipped onboard date.",
    category: "freight-forwarding",
  },
  {
    question: "What is Bill of Lading?",
    answer:
      "This is a document issued by a shipping company which serves as evidence or cargo ownership. This document is used to take delivery from shipping at the destination port.",
    category: "freight-forwarding",
  },
  {
    question: "What is Airway Bill?",
    answer:
      "Airway Bill is a document issued by an Air cargo carrier and serves same purpose as the Bill of Lading.",
    category: "freight-forwarding",
  },
  {
    question: "Between Air and ocean freight, which is better and safer?",
    answer:
      "Air and ocean freight are very safe means of transportation. Your choice will be determined by your budget and urgent need, as air freight is faster and more expensive than ocean freight.",
    category: "freight-forwarding",
  },
  {
    question: "What is Incoterms?",
    answer:
      "These are sets of rules issued by the International chamber of commerce which defines the responsibilities and obligations of seller and buyer in supply chain. They are 11 sets of rules on the recent incoterm 2020.",
    category: "freight-forwarding",
  },
  {
    question: "How can I package my goods for safe delivery?",
    answer:
      "Goods can be securely packed on wooden crates/pallets/boxes. Goods can also be securely packed in a container.",
    category: "freight-forwarding",
  },
  {
    question:
      "What is the difference between containerized and breakbulk cargo?",
    answer:
      "Containerized cargo are packed and shipped in a container while Breakbulk cargo are shipped without containers, typically breakbulk cargoes cannot fit into a container.",
    category: "freight-forwarding",
  },
  {
    question: "What is compliance in freight forwarding?",
    answer:
      "Compliance means strict adherence to national and international regulations, conventions and policy guiding the movement of goods from on country to another such as proper classification of goods.",
    category: "freight-forwarding",
  },
  {
    question: "What is customs duty and taxes?",
    answer:
      "These are Taxes and fees determined by the customs of a particular country for cargo moving in and out the country. Rate of duty and taxes is applied to different cargo such as raw material, semi-finished and finished goods.",
    category: "freight-forwarding",
  },
  {
    question:
      "How does a Nigerian importer know the rate applicable to his goods?",
    answer:
      "Customs Harmonised classification booklet is the best guide to finding applicable rate to cargo been imported. This is issued by the Nigeria customs service.",
    category: "freight-forwarding",
  },
  {
    question:
      "Can an importer procure marine insurance policy outside Nigeria?",
    answer:
      "For every cargo destined for Nigeria, the Importer must process a marine Insurance policy with an insurance company domiciled in Nigeria.",
    category: "freight-forwarding",
  },
  {
    question: "Why does an Importer need an Insurance policy?",
    answer:
      "An Insurance policy helps to mitigate risk of loss of cargo in the process of shipping from Point A to B. in the event of loss the insurance policy guarantees recovery of the cargo in the same value.",
    category: "freight-forwarding",
  },
  {
    question: "Who is a consignee and Shipper?",
    answer:
      "In shipping, a consignee is the Importer (or Buyer) while the shipper is the exporter (or seller)",
    category: "freight-forwarding",
  },
];

export default faqs;
